import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs } from "antd";
import { ContextAPI } from "../../../ContextAPI";
import { platformApi } from '../../../api';

import InvoiceTable from './InvoiceTable';

const { Item } = Menu;
const { Search } = Input;
const { Title } = Typography;

const SaleSparesInvoice = () => {

  const history = useHistory();
  const { loginCredintials } = useContext(ContextAPI);
  const [dataSource, setData] = useState([]);
  const [editable, setEditable] = useState(false);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [printAccess, setPrintAccess] = useState(false);
  const [editableData, setEditableData] = useState({})
  const [redirect, setRedirect] = useState(false);
  const [selected, setSelected] = useState();
  const [spinning, setSpinning] = useState(false);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [search, setSearch] = useState('');
  const [gst, setGst] = useState();

  useEffect(() => {
    // console.log("Login", loginCredintials.branch[0].gst)
    setGst(loginCredintials.branch[0].gst)
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "counter_sale") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
          if (each.access.print) {
            setPrintAccess(true);
          }
        }
      });
    setPageData();
    //getSSI();
  }, []);

  useEffect(() => {

    setPageData(search);
  }, [page, limit, search]);

  const getMenu = () => {
    let size = count / 10 + (count % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };
  const menu = <Menu>{getMenu()}</Menu>;

  const setPageData = (search, val) => {
    setSpinning(true)
    platformApi
      .post("/api/saleSpareInvoice/get", {
        page: val ? val : page,
        size: limit,
        // branch: loginCredintials.branch.id,
        searchString: search,
      })
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(response.data.saleSpareInvoice);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch sale spare invoice", 2);
          }
        } else {
          message.error("Unable to fetch sale spare invoice", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Vehicle Sale spare invoice : ", error);
        message.error("Unable to fetch sale spare invoice", 2);
      });
  };

  const getSSI = () => {
    setSpinning(true)
    platformApi.get("/api/saleSpareInvoice").then((result) => {
      setSpinning(false)
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          console.log(response, 'res')
          setData(response.data.saleshareInvoice);
          setCount(response.data.count);
        } else {
          message.error('Unable to fetch vehicles', 2);
        }
      } else {
        message.error('Unable to fetch vehicles', 2);
      }
    })
      .catch((err) => {
        setSpinning(false)
        console.error("SSI Fetch Error: ", err);
        message.error("Couldn't Fetch SSI");
      });
  }


  const deleteData = (id) => {
    setSpinning(true)
    let temp = page != 1 && Tasks.length === 1;
    // if (page != 1 && Tasks.length === 1) {
    //   setPage(1)
    // }
    platformApi.delete("/api/saleSpareInvoice/" + id).then(result => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          message.success("SSI Deleted Successfully")
          if (temp) {
            setPage(1)
            setPageData(null, 1)
            // getSSI()
          }
          else {
            setPageData()
            // getSSI()
          }
        }
        else {
          setSpinning(false)
          message.error("Couldn't Remove SSI");
        }
      }
      else {
        setSpinning(false)
        message.error("Couldn't Remove SSI");
      }
    }).catch((err) => {
      setSpinning(false)
      console.error("SSI Delete Error: ", err);
      message.error("Unable to Delete SSI");
    });
  }

  return (
    <>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Counter Sale[{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Counter Sale "
          onSearch={(event) => {
            console.log(event)
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button
          onClick={() => {
            history.push({
              pathname: '/autoadmin/counter_sale/:id',
              state: {
                gst: gst
              }
            })
            // history.push('/autoadmin/sale_spares_invoice/:id');
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Counter Sale
        </Button>
      </div>
      <InvoiceTable
        gst={gst}
        dataSource={dataSource}
        spinner={spinning}
        deleteData={deleteData}
        setEditableData={setEditableData}
        setEditable={setEditable}
        modify={modifyAccess}
        setModifyAccess={setModifyAccess}
        redirectPage={(id, editable) => {
          setSelected(id);
          setRedirect(true);
          setEditable(editable);
        }}
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          current: page,
          total: count,
          showQuickJumper: true,
        }}
        deleteAccess={deleteAccess}
        printAccess={printAccess}
      />
    </>
  )
}

export default SaleSparesInvoice;