import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Upload,
  Icon,
  Typography,
  message,
  Button,
  Popconfirm,
} from "antd";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils";

let urls =
  process.env.NODE_ENV === "development" ? "http://localhost:4000/" : "/";
const { Item } = Form;

const { Text, Title } = Typography;

const InsuranceModal = ({
  form,
  open,
  close,
  data,
  editable: editData,
  emitData,
  modify,
  setImg,
}) => {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(editData);
  const [logo, setLogo] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [formData, setFormData] = useState(null);

  const { getFieldDecorator } = form;

  useEffect(() => {
    setEditable(editData);
    if (data) {
      setLogo(data.logo);
      form.setFieldsValue({
        name: data.name,
      });
    }
  }, [data, editData]);

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null && !error.NAME) {
        setLoading(true);
        const name = form.getFieldValue("name");
        const insurance = { name };
        data ? (insurance.id = data.id) : {};
        if (formData) {
          formData.append("id", insurance.name);
          platformApi
            .post("/api/upload/image", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              let { data } = res;
              if (data.code === 200) {
                insurance.logo = data.response.data.Location;
                setRemoveLogo(false);
                resolveData(insurance);
              } else {
                message.error("Upload Not Done");
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error on Insurance Form : ", error);
              message.error("Unable to upload image");
            });
        } else {
          resolveData(insurance);
        }
      } else {
        message.error("Complete All Mandatory Tasks", 2);
      }
    });
  };

  const resolveData = (insurance) => {
    if (removeLogo) {
      insurance.logo = null;
    }
    // console.log("Insurance", insurance)
    emitData(insurance);
    setLoading(false);
    clearFieldsAndClose();
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      setLogo(null);
      setImgLoading(true);
      setImg(true);
      // setLoading(true)
    } else if (info.file.status === "done") {
      let formData = new FormData();
      formData.append("profile", info.file.originFileObj);
      formData.append("master", "Company Master");
      formData.append("module", "Insurance Master");
      formData.append("type", "logo");
      // setLoading(false)
      setImgLoading(false);
      setImg(false);
      getBase64(info.file.originFileObj, (url) => {
        setLogo(url);
      });
      setFormData(formData);
    }
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const clearFieldsAndClose = () => {
    form.resetFields();
    setLogo(null);
    setError({});
    setImgLoading(false);
    setFormData(null);
    setLoading(false);
    setRemoveLogo(false);
    close();
  };

  return (
    <Modal
      title={<Title level={4}>Insurance Details</Title>}
      visible={open}
      onCancel={clearFieldsAndClose}
      okText={!editable ? "Modify" : loading ? "Saving" : "Save"}
      okButtonProps={{
        loading,
        disabled: (!editable && !modify) || imgLoading,
      }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item
              required
              validateStatus={error.NAME && error.NAME.type}
              help={error.NAME && error.NAME.message}
              label={<Text>Insurance Name</Text>}
              colon={false}
            >
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Enter Insurance Name" }],
              })(
                <Input
                  disabled={!editable}
                  placeholder="Insurance Name"
                  pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                  onKeyUp={(e) => {
                    if (e.target.value) {
                      form.setFieldsValue({
                        name: formatValue(e, "firstCaps"),
                      });
                    }
                  }}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        NAME: {
                          type: "error",
                          message: "Enter Valid Name",
                        },
                      });
                    } else {
                      delete error.NAME;
                      setError(error);
                    }
                  }}
                />
              )}
            </Item>
          </Col>
          <Col span={10}>
            <Item label={<Text>Insurance Logo</Text>} colon={false}>
              {getFieldDecorator("dragger", {
                valuepropname: "fileList",
                getvaluefromevent: normFile,
                rules: [{ required: false, message: "Logo Required" }],
              })(
                <Upload.Dragger
                  disabled={!editable}
                  listType="picture-card"
                  showUploadList={false}
                  name="files"
                  action={`${urls}api/upload/mocky`}
                  onChange={(e) => handleUpload(e)}
                  accept=".jpg,.png,.jpeg,.gif"
                >
                  {logo ? (
                    <img style={{ maxWidth: "100%" }} src={logo} />
                  ) : (
                    <div>
                      <p className="ant-upload-drag-icon">
                        <Icon type={imgLoading ? "loading" : "inbox"} />
                      </p>
                      {imgLoading ? (
                        <p className="ant-upload-text">loading</p>
                      ) : (
                        <p className="ant-upload-hint">
                          Click or drag file to this area to upload
                        </p>
                      )}
                    </div>
                  )}
                </Upload.Dragger>
              )}
            </Item>
            {data && data.logo && data.logo.length > 0 && editable ? (
              <Popconfirm
                title="Do you want to delete this Logo?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  form.setFieldsValue({ dragger: null });
                  setRemoveLogo(true);
                  setLogo(null);
                }}
              >
                <Button type="danger" style={{ marginTop: ".5rem" }}>
                  <Icon type="delete" />
                </Button>
              </Popconfirm>
            ) : null}
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WrappedInsuranceModal = Form.create({ name: "normal_login" })(
  InsuranceModal
);

export default WrappedInsuranceModal;
