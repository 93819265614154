import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs, Form, Select } from "antd";
import { WhatsAppOutlined } from "@ant-design/icons";
import moment from "moment";
import { DatePicker, Space } from 'antd';
import axios from "axios";
// import "./index.less";

// Components
import FollowUpTable from './FollowUpTable'
import { platformApi } from "../../../api";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs
const { MonthPicker } = DatePicker;


const ServiceFollowUpHome = ({ form }) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

    const Month = [
        { id: 0, title: "January" },
        { id: 1, title: "February" },
        { id: 2, title: "March" },
        { id: 3, title: "April" },
        { id: 4, title: "May" },
        { id: 5, title: "June" },
        { id: 6, title: "July" },
        { id: 7, title: "August" },
        { id: 8, title: "September" },
        { id: 9, title: "October" },
        { id: 10, title: "November" },
        { id: 11, title: "December" }
    ]
    const date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    firstDay = firstDay.toISOString()
    lastDay = lastDay.toISOString()
    const [firstDate, setFirstDate] = useState(firstDay);
    const [lastDate, setLastDate] = useState(lastDay)
    const [month, setMonth] = useState([]);
    const [selectDate, setSelectDate] = useState(moment(new Date()));
    const [limit, setLimit] = useState(10);
    const [active, setActive] = useState("1")
    const [dataSource, setData] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [search, setSearch] = useState({ searchString: "" });
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [filterData, setFilterData] = useState({})
    const [tab, setTab] = useState("PENDING")
    const [loading, setLoading] = useState({ print: false, sms: false, whatsApp: false });
    const [smsData, setSmsData] = useState([]);


    const [curYear, setCurYear] = useState(new Date().getFullYear());

    const getMenu = () => {
        let size = count / 10 + (count % 10 ? 1 : 0);
        let menus = [];
        menus.push(10);
        size = size <= 10 ? size : 10;
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
                {menu}
            </Item>
        ));
    };

    useEffect(() => {


        let temp = [];
        const d = new Date();
        var total = d.getMonth()
        setFieldsValue({
            month: Month[total].title
        })

        var date1 = moment(new Date(date.getFullYear(), date.getMonth(), 25)).format("MMM Do YY")
        var date2 = moment(new Date()).format("MMM Do YY")


        if (date2 >= date1) {
            if (total > 0) {
                if (total == 11) {
                    let prev = d.getMonth() - 2
                    for (let i = prev; i <= total; i++) {
                        temp.push(Month[i]);
                    }
                }
                else if (total === 0) {
                    for (let i = 0; i <= 2; i++) {
                        temp.push(Month[i])
                    }
                }
                else if (total >= 2) {
                    let prev = d.getMonth() - 1;
                    for (let i = prev; i <= total + 1; i++) {
                        temp.push(Month[i])
                    }
                }
                setMonth(temp);
            }
            else {
                setMonth(Month[total])
            }
        }
        else {
            if (total > 0) {
                if (total >= 2) {
                    let prev = d.getMonth() - 2

                    for (let i = prev; i <= total; i++) {
                        temp.push(Month[i])
                    }
                    setMonth(temp)
                }
                else if (total == 1) {
                    let prev = d.getMonth() - 1
                    for (let i = prev; i <= total; i++) {
                        temp.push(Month[i])
                    }
                    setMonth(temp)
                }
            }
            else {
                setMonth(Month[total])
            }
        }

    }, [])

    useEffect(() => {
        if (selectDate == null) return setSelectDate(moment(new Date()));
        const month = selectDate.month();
        const year = selectDate.year();
        let firstDay = new Date(year, month, 1);
        let lastDay = new Date(year, month + 1, 0);

        firstDay = firstDay.toISOString()
        lastDay = lastDay.toISOString()
        setFirstDate(firstDay)
        setLastDate(lastDay)
    }, [selectDate])

    const menu = <Menu>{getMenu()}</Menu>;
    useEffect(() => {
        const requestBody = {
            fromDate: firstDate,
            toDate: lastDate,
            page: page,
            size: limit,
            status: tab,
            searchString: search.searchString,
        };
        if (tab === "PENDING" || tab === "ALL") return getBeforeAndAfterWarranty(requestBody);
        setPageData(search);
    }, [tab, page, limit, firstDate, lastDate, search]);

    const setPageData = (search, status) => {
        setSpinning(true);
        // if (!searches) {
        if (!status) {
            status = tab
        }
        let obj = {
            page: page,
            size: limit,
            searchString: search.searchString,
            fromDate: firstDate && firstDate,
            toDate: lastDate && lastDate,
            status
        }
        platformApi
            .post("/api/vehicle/getReminder", obj)
            .then((result) => {
                setSpinning(false);
                const { data } = result;
                if (data.code === 200) {
                    const { response } = data;
                    if (response.code === 200) {
                        console.log("response.data", response.data.SoldVehicle)
                        setData(response.data.SoldVehicle);
                        setCount(response.data.count)
                        let temp = [];
                        for (let i = 0; i < response.data.SoldVehicle.length; i++) {
                            const element = response.data.SoldVehicle[i];
                            let tempData = {
                                modelName: "",
                                regNo: "",
                                serviceNo: "",
                                serviceType: "",
                                serviceKms: "",
                                serviceDate: "",
                                phone: "",
                            };
                            tempData.modelName = `${element.vehicle.modelCode}-${element.vehicle.modelName}`;
                            tempData.regNo = element.registerNo;
                            tempData.customerId = element.customer[0]?.customer.id;
                            tempData.customer = element.customer[0]?.customer?.name;
                            tempData.phone = element.customer[0]?.customer?.contacts[0]?.phone;
                            if (element.activeService) {
                                tempData.serviceNo = element.activeService.serviceNo;
                                tempData.serviceType = element.activeService.serviceType;
                                tempData.serviceKms = element.activeService.serviceKms;
                                tempData.serviceDate = moment(element.activeService.serviceDate).format("DD/MM/YYYY");
                            } else {
                                element.services.map((item) => {
                                    if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                                        tempData.serviceNo = item.serviceNo;
                                        tempData.serviceType = item.serviceType;
                                        tempData.serviceKms = item.serviceKms;
                                        tempData.serviceDate = moment(item.serviceDate).format("DD/MM/YYYY");
                                    }
                                });
                            }
                            temp.push(tempData);
                        }
                        setSmsData(temp);
                    }
                    else {
                        message.error("Unable to fetch Vehicles", 2);
                    }
                } else {
                    message.error("Unable to fetch Vehicles", 2);
                }
            })
            .catch((error) => {
                setSpinning(false);
                console.error("Error on Quotation : ", error);
                message.error("Unable to fetch Quotation", 2);
            });
    };

    const getBeforeAndAfterWarranty = (requestBody) => {
        setSpinning(true);
        axios
            .all([
                platformApi.post("/api/vehicle/getReminder", requestBody),
                platformApi.post("/api/vehicle/beyondData", requestBody),
            ])
            .then(
                axios.spread((beforeWarranty, afterWarranty) => {
                    const responses = [beforeWarranty, afterWarranty];
                    const vehicleData = [];
                    const totalSmsData = [];
                    let totalCount = 0;

                    console.log("   ---  "+responses)

                    for (const response of responses) {
                        const { data } = response;
                        if (data.code === 200) {
                            const { response } = data;
                            if (response.code === 200) {
                                vehicleData.push(...response.data.SoldVehicle);
                                totalCount += response.data.count;
                                let temp = [];
                                for (let i = 0; i < response.data.SoldVehicle.length; i++) {
                                    const element = response.data.SoldVehicle[i];
                                    let tempData = {
                                        modelName: "",
                                        regNo: "",
                                        serviceNo: "",
                                        serviceType: "",
                                        serviceKms: "",
                                        serviceDate: "",
                                        phone: "",
                                    };
                                    tempData.modelName = `${element.vehicle.modelCode}-${element.vehicle.modelName}`;
                                    tempData.regNo = element.registerNo;
                                    tempData.customerId = element.customer[0]?.customer?.id;
                                    tempData.customer = element.customer[0]?.customer?.name;
                                    tempData.phone = element.customer[0]?.customer?.contacts[0]?.phone;
                                    if (element.activeService) {
                                        tempData.serviceNo = element.activeService.serviceNo;
                                        tempData.serviceType = element.activeService.serviceType;
                                        tempData.serviceKms = element.activeService.serviceKms;
                                        tempData.serviceDate = moment(element.activeService.serviceDate).format("DD/MM/YYYY");
                                    } else {
                                        element.services.map((item) => {
                                            if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                                                tempData.serviceNo = item.serviceNo;
                                                tempData.serviceType = item.serviceType;
                                                tempData.serviceKms = item.serviceKms;
                                                tempData.serviceDate = moment(item.serviceDate).format("DD/MM/YYYY");
                                            }
                                        });
                                    }
                                    temp.push(tempData);
                                }
                                totalSmsData.push(...temp);
                            } else {
                                message.error("Unable to fetch Vehicles", 2);
                            }
                        } else {
                            message.error("Unable to fetch Vehicles", 2);
                        }
                    }
                    setSpinning(false);
                    setData(vehicleData);
                    setSmsData(totalSmsData);
                    setCount(totalCount);
                })
            ).catch(err => {
                console.log(err);
                setSpinning(false);
            })
    }

    const disabledDate = (current) => {
        // Can not select days before today and today
        // const currentDay = moment(new Date().getDate()).day();
        const nextMonth = moment(new Date()).add(1, "month")
        const currentDate = new Date().getDate();
        if (currentDate >= 25) {
            return current && current > nextMonth.endOf("month");
        }
        return current && current > nextMonth.startOf("month");
    }

    const setVehicleAfterWarranty = (requestBody) => {
        setSpinning(true);
        platformApi
            .post("/api/vehicle/beyondData", requestBody)
            .then((result) => {
                setSpinning(false);
                const { data } = result;
                if (data.code === 200) {
                    const { response } = data;
                    if (response.code === 200) {
                        setData((prevData) => ([...prevData, ...response.SoldVehicle]));
                        setCount(prevCount => (response.count + prevCount))
                        let temp = [];
                        for (let i = 0; i < response.SoldVehicle.length; i++) {
                            const element = response.SoldVehicle[i];
                            let tempData = {
                                modelName: "",
                                regNo: "",
                                serviceNo: "",
                                serviceType: "",
                                serviceKms: "",
                                serviceDate: "",
                                phone: "",
                            };
                            tempData.modelName = `${element.vehicle.modelCode}-${element.vehicle.modelName}`;
                            tempData.regNo = element.registerNo;
                            tempData.customerId = element.customer[0]?.customer.id;
                            tempData.customer = element.customer[0]?.customer?.name;
                            tempData.phone = element.customer[0]?.customer?.contacts[0]?.phone;
                            if (element.activeService) {
                                tempData.serviceNo = element.activeService.serviceNo;
                                tempData.serviceType = element.activeService.serviceType;
                                tempData.serviceKms = element.activeService.serviceKms;
                                tempData.serviceDate = moment(element.activeService.serviceDate).format("DD/MM/YYYY");
                            } else {
                                element.services.map((item) => {
                                    if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                                        tempData.serviceNo = item.serviceNo;
                                        tempData.serviceType = item.serviceType;
                                        tempData.serviceKms = item.serviceKms;
                                        tempData.serviceDate = moment(item.serviceDate).format("DD/MM/YYYY");
                                    }
                                });
                            }
                            temp.push(tempData);
                        }
                        setSmsData(prevData => ([...prevData, ...temp]));
                    }
                    else {
                        message.error("Unable to fetch Vehicles", 2);
                    }
                } else {
                    message.error("Unable to fetch Vehicles", 2);
                }
            })
            .catch((error) => {
                setSpinning(false);
                console.error("Error on Quotation : ", error);
                message.error("Unable to fetch Quotation", 2);
            });
    }

    const handleTabChange = (activeKey) => {
        switch (activeKey) {
            case "1":
                setPageData({ searchString: "" }, "PENDING")
                setTab("PENDING")
                setPage(1)
                setActive("1")
                break;
            case "2":
                setPageData({ searchString: "" }, "BOOKED")
                setTab("BOOKED")
                setPage(1)
                setActive("2")
                break;
            case "3":
                setPageData({ searchString: "" }, "INPROGRESS");
                setTab("INPROGRESS")
                setPage(1)
                setActive("3")
                break;
            case "4":
                setPageData({ searchString: "" }, "COMPLETED");
                setTab("COMPLETED")
                setPage(1)
                setActive("4")
                break;
            case "5":
                setPageData({ searchString: "" }, "ALL")
                setTab("ALL")
                setPage(1)
                setActive("5")
                break;
            default:
                setPageData({ searchString: "" }, "PENDING");
                setTab("PENDING")
                setPage(1)
                setActive("1")
        }
    }

    const sendMessage = (type) => {
        let data = {
            smsData: smsData,
        };
        if (type === "SMS") {
            data.type = type;
            setLoading({ ...loading, sms: true });
            platformApi
                .post("api/sendSms/serviceReminder", data)
                .then((result) => {
                    setLoading({ ...loading, sms: false });
                    const { data } = result;
                    if (data.code === 200) {
                        message.success("Message sent successfully");
                    } else if (data.code === 401) {
                        message.error(data.message);
                    } else {
                        message.error("Unable to send SMS");
                    }
                })
                .catch((error) => {
                    setLoading({ ...loading, sms: false });
                    message.error("Unable to send SMS");
                });
        } else if (type === "WhatsApp") {
            data.type = type;
            setLoading({ ...loading, whatsApp: true });
            platformApi
                .post("api/sendSms/serviceReminder", data)
                .then((result) => {
                    setLoading({ ...loading, whatsApp: false });
                    const { data } = result;
                    if (data.code === 200) {
                        message.success("WhatsApp Message sent successfully");
                    } else if (data.code === 400) {
                        message.error(data.message);
                    } else if (data.code === 401) {
                        message.error(data.message);
                    } else {
                        message.error("Unable to send WhatsApp SMS");
                    }
                })
                .catch((error) => {
                    setLoading({ ...loading, whatsApp: false });
                    message.error("Unable to send SMS");
                });
        }
    };

    return (
        <>
            <div className="accessories-head">
                <Title style={{ width: "60%" }} level={4}>
                    Service Reminder [{count}]
                    <span style={{ margin: ".5rem" }}>
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <Button>
                                {limit} &nbsp;
                                <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </span>
                </Title>
                <MonthPicker
                    defaultValue={selectDate}
                    value={selectDate}
                    disabledDate={disabledDate}
                    onChange={e => {
                        if (e) {
                            setSelectDate(e);
                        }
                    }}
                    placeholder="Select month"
                />
                {/* <Form.Item style={{ margin: "0", width: "10rem" }}>
                    {getFieldDecorator("month")
                        (
                            <Select dropdownStyle={{
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}

                                showSearch
                                showAction={["click", "focus"]}
                                placeholder="Month"
                                onChange={selectMonth}
                            >

                                {month.length && month.map((obj) => (
                                    <Select.Option key={obj.id} value={obj.id}>
                                        {obj.title} - {curYear}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                </Form.Item> */}
                {
                    tab === "PENDING" && (
                        <div style={{ display: "flex", gap: 10 }}>
                            <Button loading={loading.sms} onClick={() => sendMessage("SMS")}>
                                <Icon type="message" />
                            </Button>
                            <Button loading={loading.whatsApp} onClick={() => sendMessage("WhatsApp")}>
                                <WhatsAppOutlined />
                            </Button>
                        </div>
                    )
                }
                <Search
                    style={{ width: "30%" }}
                    placeholder="Search Vehicle/Phone"
                    onSearch={(event) => {
                        let searches = { searchString: event };
                        setPage(1)
                        setSearch(searches);
                    }}
                    onChange={(event) => {
                        if (event.target.value === "") {
                            setSearch({ searchString: "" })
                            setPage(1)
                        }
                    }}
                />
            </div>
            <Tabs defaultActiveKey={active} onChange={handleTabChange}>
                {/* <TabPane
                    tab={
                        <span>
                            Quoted
                        </span>
                    }
                    key="1"
                >
                    <FollowUpTable
                        spinner={spinning}
                        dataSource={dataSource}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            total: count,
                            current: page,
                            showQuickJumper: true,
                        }}
                        firstDate={firstDate}
                        lastDate={lastDate}
                    />
                </TabPane> */}
                <TabPane
                    disabled={spinning}
                    tab={
                        <span>
                            Pending
                        </span>
                    }
                    key="1"
                >
                    <FollowUpTable
                        spinner={spinning}
                        dataSource={dataSource}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            total: count,
                            current: page,
                            showQuickJumper: true,
                        }}
                        firstDate={firstDate}
                        lastDate={lastDate}
                    />
                </TabPane>
                {/* <TabPane
                    disabled={spinning}
                    tab={
                        <span>
                            Booked
                        </span>
                    }
                    key="2"
                >
                    <FollowUpTable
                        spinner={spinning}
                        dataSource={dataSource}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            total: count,
                            current: page,
                            showQuickJumper: true,
                        }}
                        firstDate={firstDate}
                        lastDate={lastDate}
                    />
                </TabPane> */}
                <TabPane
                    disabled={spinning}
                    tab={
                        <span>
                            In Progress
                        </span>
                    }
                    key="3"
                >
                    <FollowUpTable
                        spinner={spinning}
                        dataSource={dataSource}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            total: count,
                            current: page,
                            showQuickJumper: true,
                        }}
                        firstDate={firstDate}
                        lastDate={lastDate}
                    />
                </TabPane>
                <TabPane
                    disabled={spinning}
                    tab={
                        <span>
                            Completed
                        </span>
                    }
                    key="4"
                >
                    <FollowUpTable
                        spinner={spinning}
                        dataSource={dataSource}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            total: count,
                            current: page,
                            showQuickJumper: true,
                        }}
                        firstDate={firstDate}
                        lastDate={lastDate}
                    />
                </TabPane>
                <TabPane
                    disabled={spinning}
                    tab={
                        <span>
                            All
                        </span>
                    }
                    key="5"
                >
                    <FollowUpTable
                        spinner={spinning}
                        dataSource={dataSource}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            total: count,
                            current: page,
                            showQuickJumper: true,
                        }}
                        firstDate={firstDate}
                        lastDate={lastDate}
                    />
                </TabPane>
            </Tabs>
        </>
    );
}
const WrappedServiceFollowUpHome = Form.create("service_followUpHome")(
    ServiceFollowUpHome
);

export default WrappedServiceFollowUpHome;
