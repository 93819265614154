import React, { useState, useEffect } from 'react'
import { Card, List, Row, Col, message, Tag } from 'antd'
import moment from 'moment';
import {
  EyeFilled,
  EditFilled,
} from '@ant-design/icons';
import '../style.less'

const QuotationActivity = (props) => {
  const { item, openEditor } = props
  return (
    <div className="activity-card">
      <table>
        {/* First Row */}
        <tr>
          <td>
            <strong>Quotation Activity </strong>
          </td>
          <td rowSpan={4} className="vat bb-none">
            {
              item.remarks && item.remarks.length ?
                <>
                  <strong>Remarks:</strong><br />
                  {item.remarks}
                </>
                : null}
            <br />
            <br />
            {
              item.quotation.vehicle.length ?
                <><strong>Vehicles: </strong>
                  {
                    item.quotation.vehicle.map(vehicle => <Tag>{vehicle.vehicleDetail.modelName}</Tag>)
                  }
                </>
                : null
            }
            {
              item.createdBy &&
              <div>
                <strong>Employee:</strong> {item.createdBy.profile.employeeName}
              </div>
            }
          </td>
          <td align="center">
            <strong>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY HH:mm') : ' -'}</strong>
          </td>
        </tr>
        {/* Second Row */}
        <tr>
          <td rowSpan={2}>
            {
              item.quotation.sms.length ?
                <><strong>Phone No: </strong> {item.quotation.sms[0].phone}<br /></> : null
            }
            {
              (item.quotation.quotationId) ?
                <>
                  <strong>Quotation ID: </strong> {item.quotation.quotationId}<br />
                </>
                : null
            }
            {
              item.quotation.leadSource ?
                <><strong>Lead Source: </strong>{item.quotation.leadSource}<br /></>
                : null
            }
            {
              item.quotation.sms.length ?
                <><strong>SMS: </strong>
                  {
                    item.quotation.sms[0].smsStatus
                      ?
                      item.quotation.sms[0].smsStatus
                      :
                      item.quotation.sentStatus ? "Sent" : "Not Sent"
                  }<br /></> : null
            }
          </td>
          {/* <td align="center">
             {(item.quotationState || true)?
                <strong>
              Quoted{item.quotationState}
                    </strong>
          :null
            }
          </td> */}
          <td align="center" rowSpan={2}>
            {
              item.enquiryType ?
                <strong className={`center pa ${item.enquiryType}`}>{item.enquiryType}</strong> : null
            }
          </td>
        </tr>
        {/* Third Row */}
        <tr>
        </tr>
        <tr>
          <td>
            {
              item.scheduleDateAndTime ?
                <>
                  <strong>FollowUp Date: </strong>{moment(item.scheduleDateAndTime).format("DD-MM-YYYY")}<br />
                  <strong>FollowUp Time: </strong>{moment(item.scheduleDateAndTime).format("HH:mm")}
                </>
                : null
            }
          </td>
          <td>
            <Row>
              <Col className="center pointer icon" onClick={() => openEditor(item.id)} span={12}>
                <EyeFilled />
              </Col>
              <Col className="bl center pointer icon" onClick={() => openEditor(item.id, true)} span={12}>
                <EditFilled />
              </Col>
            </Row>
          </td>
        </tr>
      </table>
    </div>
  )
}

export default QuotationActivity;
