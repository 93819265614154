import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Row,
  Typography,
  Col,
  Input,
  Form,
  DatePicker,
  Radio,
  Select,
  message,
  Table,
  Divider,
  Popconfirm,
  Button,
  Icon,
  Empty,
  Tag,
  Tabs
} from "antd";
import moment from "moment";
import "./index.less";

import { formatValue } from "../../../../../../utils"
import { withRouter, Link } from "react-router-dom";
import { ContextAPI } from "../../../../../../ContextAPI";
import { platformApi } from "../../../../../../api";

const { Item } = Form;
const { Title, Text } = Typography;
const { Option } = Select;
const { TabPane } = Tabs;

const CustomerDetailsUpdateForm = (props) => {
  let { cusId, setCusId } = props;
  const [referredList, setReferredList] = useState([]);
  const [gstType, setGstType] = useState(true);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [country, setCountry] = useState([]);
  const [date, setDate] = useState("default");
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const [dataSource, setDataSource] = useState([]);
  const [editPhone, setEditPhone] = useState("");
  const [error, setError] = useState({});
  const [checkAll, setCheckAll] = useState(true);
  const [checkNameNo, setCheckNameNo] = useState(true);
  const [gstName, setGstName] = useState('');
  const [primaryNumberCheck, setPrimaryNumberCheck] = useState(false)
  const [gstStatus, setGstStatus] = useState('')

  const types = [
    { key: "Primary", title: "Primary" },
    { key: "Alternate", title: "Alternate" },
  ];
  const { loginCredintials } = useContext(ContextAPI);

  const column = [
    {
      title: <Text strong>Phone Number</Text>,
      dataIndex: "phone",
      key: "phone",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Type</Text>,
      dataIndex: "type",
      key: "type",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Validity</Text>,
      dataIndex: "valid",
      key: "valid",
      render: (record) => (record ? "Valid" : "Invalid"),
    },
    {
      title: <Text strong>DND</Text>,
      dataIndex: "DND",
      key: "DND",
      render: (record) => (record ? "Yes" : "No"),
    },

    {
      title: <Text strong>Action</Text>,
      render: (Data) => (
        <div>
          {Data.id ? (
            <span>
              <Icon
                onClick={(event) => {
                  event.stopPropagation();
                  setEditPhone(Data.id);
                  form.setFieldsValue({
                    phone: Data.phone,
                    type: Data.type,
                    valid: Data.valid,
                    dnd: Data.DND,
                  });
                }}
                type="edit"
              />
              <Divider type="vertical" />
            </span>
          ) : (
              <span />
            )}
          {
            dataSource.length === 1 && data ?
              <span></span>
              :
              <Popconfirm
                title="Do you want to delete this Mobile Number?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  deleteData(Data);
                }}
              >
                <Icon type="delete" onClick={(event) => event.stopPropagation()} />
              </Popconfirm>
          }

        </div>
      ),
    },
  ];

  const deleteData = (dData) => {
    if (dData.id) {
      platformApi.delete(`api/customer/phone/${dData.id}`).then((res) => {
        if (res.data.response.code === 200) {
          setDataSource([]);
          let data = dataSource.filter((data) => data.phone !== dData.phone);
          setDataSource(data);
        } else {
          message.error("Phone Not Deleted");
        }
      });
    } else {
      setDataSource([]);
      const data = dataSource.filter((data) => data.phone !== dData.phone);
      setDataSource(data);
    }
  };

  const salutations = [
    { key: "Mr", title: "Mr" },
    { key: "Mrs", title: "Mrs" },
    { key: "Ms", title: "Ms" },
    { key: "Dr", title: "Dr" },
    { key: "Prof", title: "Prof" },
    { key: "Rev", title: "Rev" },
  ];

  const customerTypes = [
    { key: "Sales Customer", title: "Sales Customer" },
    { key: "Service Customer", title: "Service Customer" },
    { key: "Non Customer", title: "Non Customer" },
  ];
  const customerGrouping = [
    { key: "Individual", title: "Individual" },
    { key: "Non-Individual", title: "Non-Individual" },
  ];

  const { form, open, close, data, setData } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const contactData = () => {
    if (dataSource.length === 0) {
      setError({
        ...error,
        CONTACTS: {
          type: "error",
          message: "Enter Atleast One Contact",
        },
      });
    } else {
      delete error.CONTACTS;
      setError(error);
    }
  }
  function checkGST(gst) {
    platformApi.post('/api/gstVerify', { gst })
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (response.data.data.error) {
              message.error('GST Invalid')
              setGstName('')
              setGstStatus('')
              return false;
            }
            else {
              message.success('GST Verified')
              delete error.GST;
              setError(error);
              setGstName(response.data.data.taxpayerInfo.tradeNam)
              setGstStatus(response.data.data.taxpayerInfo.sts)
              return true;
            }
          }
        }
      })
  }

  useEffect(() => {
    numberCheck(dataSource)
  }, [dataSource])

  const numberCheck = (data) => {
    let flag = false
    data.map(each => {
      if (each.type === "Primary") {
        setPrimaryNumberCheck(true);
        flag = true;
        form.setFieldsValue({
          type: 'Alternate'
        })
      }
    })
    if (!flag) {
      setPrimaryNumberCheck(false)
      form.setFieldsValue({
        type: 'Primary'
      })
    }
  }

  const handleSubmit = () => {
    form.validateFields(async (validationError) => {
      if (
        validationError === null &&
        (JSON.stringify({}) === JSON.stringify(error) || error.length == 0) && !error.EMAIL
      ) {
        setLoading(true);
        if (dataSource.length == 0) {
          message.error("Enter Phone Number");
          setLoading(false)
        }
        else if ((dataSource.length !== 0) || (!checkAll) && (form.getFieldValue("name"))) {
          const salutation = form.getFieldValue("salutation");
          const name = form.getFieldValue("name");
          const fatherName = form.getFieldValue("fatherName");
          const gender = form.getFieldValue("gender");
          let dob = form.getFieldValue("dateOfBirth");
          const dateOfBirth = dob ? dob.format("DD-MM-YYYY") : undefined;
          const country = form.getFieldValue("country");
          const district = form.getFieldValue("district");
          const state = form.getFieldValue("state");
          const street = form.getFieldValue("street");
          const locality = form.getFieldValue("locality");
          const pincode = form.getFieldValue("pincode");
          const customerType = form.getFieldValue("customerType");
          const GSTNo = form.getFieldValue("GSTNo") || null;
          const GSTType = form.getFieldValue("GSTType");
          const customerGrouping = form.getFieldValue("customerGrouping");
          const refferedBy = form.getFieldValue("referredBy") || 0;
          const reffered = form.getFieldValue("referred");
          const email = form.getFieldValue('email');
          let customerId = "";
          const IDbranch = loginCredintials.branch.id;
          if (!data) {
            await platformApi.post("/api/idGenerate/customer").then((res) => {
              let { data } = res;
              if (data.code === 200) {
                let { response } = data;
                customerId = response.data;
              } else {
                message.error("Couldn't fetch id");
              }
            });
          } else {
            customerId = cusId;
          }
          const customer = {
            customerId,
            salutation,
            name,
            fatherName,
            gender,
            contacts: dataSource,
            dateOfBirth,
            address: {
              district,
              state,
              country,
              street,
              locality,
              pincode,
            },
            customerType,
            GSTNo,
            GSTType,
            customerGrouping,
            refferedBy,
            reffered,
            IDbranch,
            email
          };
          data ? (customer.id = data.id) : {};
          setLoading(false);
          props.emitData(customer);
          props.close();
          form.resetFields();
          setCusId("");
          setLoading(false);
          setState([]);
          setDistrict([]);
          setDataSource([]);
          setCheckAll(true);
          setCheckNameNo(true);
        }
        else if (!form.getFieldValue("name")) {
          message.error("Enter Phone Number");
          setLoading(false)
        }
        else {
          message.error("Enter Phone Number");
          setLoading(false)
        }
      }
    }
    );
  };
  useEffect(() => {
    if (open) {
      platformApi
        .get("/api/csc/country")
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setCountry(data.data);
          }
        })
        .catch((error) => {
          console.error("Error on Customer form : ", error);
          message.error("Unable to fetch country", 2);
        });
    }
  }, [open]);

  const setReferredCustomer = (id) => {
    platformApi.get("api/customer/reffered/" + id).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setReferredList(response.data);
        }
      }
    });
  };

  useEffect(() => {
    setEditable(props.editable);
    if (data) {
      data.phone &&
        setDataSource([
          {
            id: "",
            phone: data.phone,
            type: "Primary",
            valid: true,
            DND: false,
          },
        ]);
      data && handleCustomerType(data.customerType)
      data && data.contacts && setDataSource(data.contacts);
      numberCheck(data && data.contacts)
      data &&
        data.address &&
        handleStateChange(data.address && data.address.state && data.address.state.id, "dataSet");
      data &&
        data.address &&
        handleCountryChange(data.address && data.address.country && data.address.country.id, "dataSet");
      setCusId(data.customerId);
      let dob = undefined;
      if (data.dateOfBirth) {
        dob = moment(data.dateOfBirth);
      }
      data.GSTType === null || data.GSTType === "Unregistered" || data.GSTType === "UNREGISTERED" ? setGstType(true) : setGstType(false)
      form.setFieldsValue({
        salutation: data.salutation || undefined,
        name: data.name,
        fatherName: data.fatherName,
        gender: data.gender,
        dateOfBirth: dob,
        country:
          (data.address && data.address.country && data.address.country.id) || undefined,
        state: (data.address && data.address.state && data.address.state.id) || undefined,
        district:
          (data.address && data.address.district && data.address.district.id) || undefined,
        locality: data.locality || (data.address && data.address.locality),
        street: data.address && data.address.street,
        pincode: data.address && data.address.pincode,
        customerType: data.customerType,
        GSTNo: data.GSTNo,
        GSTType: data.GSTType || "Unregistered",
        customerGrouping: data.customerGrouping || undefined,
        referredBy: data.refferedBy && data.refferedBy.id,
        referred: data.reffered && data.reffered,
        email: data.email
      });
    }
  }, [props.editable, data]);

  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // const filterMethodReferred = (input, option) =>
  //   option.props.children.toString().indexOf(input) >= 0;

  const handleCountryChange = (id, type) => {
    if (type !== "dataSet") {
      form.setFieldsValue({
        district: undefined,
        state: undefined
      })
    }
    platformApi
      .post("api/csc/states", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setState(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Customer form : ", error);
        message.error("Unable to fetch state", 2);
      });
  };

  const handleStateChange = (id, type) => {
    if (type !== "dataSet") {
      form.setFieldsValue({
        district: undefined,
      })
    }
    platformApi
      .post("api/csc/cities", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setDistrict(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Customer form : ", error);
        message.error("Unable to fetch cities", 2);
      });
  };
  const dateChange = (e) => {
    if (e) {
      setDate(moment(e).format('DD-MM-YYYY'))
    }
  };

  const editingPhone = () => {
    if (form.getFieldValue("phone").length === 10 && !error.PNO) {
      const obj = {
        phone: form.getFieldValue("phone"),
        type: form.getFieldValue("type"),
        valid: true,
        DND: form.getFieldValue("dnd"),
        id: editPhone,
      };
      let i = 0;
      dataSource.map((element, index) => {
        if (element.id === editPhone) {
          i = index;
        }
      });
      setDataSource([]);
      const data = dataSource;
      data[i] = obj;
      setDataSource(data);
      form.setFieldsValue({ phone: undefined, dnd: undefined });
      form.setFieldsValue({ type: undefined });

      setEditPhone("");
    } else {
      message.error("Enter Valid Phone Number");
    }
  };
  const handleCustomerType = (id) => {
    if (id === "Service Customer") {
      setCheckNameNo(true);
      setCheckAll(false);
      setError([])
      form.resetFields()
    }
    if (id === "Sales Customer") {
      setCheckNameNo(true);
      setCheckAll(true);
    }
    if (id === "Non Customer") {
      setCheckNameNo(false);
      setCheckAll(false);
      setError([])
      form.resetFields()
    }
  };
  const clearFields = () => {
    form.resetFields();
    setDataSource([]);
    setCusId("");
    setState([]);
    setDistrict([]);
    setError({});
    setGstType(true);
    setCheckNameNo(true);
    setCheckAll(true);
    setGstName('')
  };
  return (
    <Modal
      title={<Title level={4}>Customer Details</Title>}
      visible={open}
      onCancel={() => {
        clearFields();
        setLoading(false);

        close();
      }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      okButtonProps={
        !editable ? { loading, style: { display: "none" } } : { loading }
      }
      cancelText={editable ? "Cancel" : "Close"}
      okText="Save"
      wrapClassName="customer-modal"
    >
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              Update Customer Details
            </span>
          }
          key="1"
        >
          <Form>
            {/* <Row>
              <Item>
                {data ? <Text strong>Customer ID:{cusId}</Text> : <div></div>}
              </Item>
            </Row> */}
            <Row type="flex" justify="space-around">

              <Col span={11}>
                <Row type="flex" justify="space-between">
                  <Col span={4}>
                    <Item label="Salutation" colon={false}>
                      {getFieldDecorator("salutation", {
                        rules: [
                          { required: false, message: "Select Salutation" },
                        ],
                      })(
                        <Select showAction={["click", "focus"]} disabled={!editable} placeholder="Sal.">
                          {salutations.map((sal) => (
                            <Select.Option key={sal.key}>{sal.title}</Select.Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>

                  <Col span={19}>
                    <Item
                      label="Customer Name"
                      colon={false}
                      validateStatus={error.NAME && error.NAME.type}
                      help={error.NAME && error.NAME.message}
                    // required
                    >
                      {getFieldDecorator("name", {
                        rules: [
                          {
                            required: false,
                            message: "Enter Customer  Name",
                          },
                        ],
                      })(
                        <Input
                          disabled={!editable}
                          onKeyUp={e =>
                            form.setFieldsValue({
                              name: formatValue(e, "allCaps")
                            })}
                          placeholder="Customer Name"
                          pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                          onInput={(event) => {
                            if (!event.target.checkValidity()) {
                              setError({
                                ...error,
                                NAME: {
                                  type: "error",
                                  message: "Enter Valid Name",
                                },
                              });
                            } else {
                              delete error.NAME;
                              setError(error);
                            }
                          }}
                        />
                      )}
                    </Item>
                  </Col>
                </Row>
                <Item
                  label={(
                    <Text style={{ fontSize: '15px' }}>
                      <span style={{ color: 'crimson' }}> * </span>
                  Contacts
                    </Text>
                  )}
                  colon={false}
                // validateStatus={error.CONTACTS && error.CONTACTS.type}
                // help={error.CONTACTS && error.CONTACTS.message}
                >
                  {getFieldDecorator("contact", {
                    rules: [
                      {
                        required: false,
                        message: "Enter Contacts",
                      },
                    ],
                  })(
                    <Table
                      locale={{
                        emptyText: (
                          <Empty
                            imageStyle={{
                              height: 25,
                              fontSize: 30,
                            }}
                            description={(
                              <Text disabled strong>
                                No Contacts
                              </Text>
                            )}
                            image={<Icon type="contacts" />}
                          />
                        ),
                      }}
                      rowKey={(record) => record.id}
                      pagination={false}
                      size="small"
                      columns={column.filter(
                        (columns) =>
                          columns.title.props.children !== "Action" || editable
                      )}
                      style={{ cursor: "pointer" }}
                      dataSource={dataSource}
                    />
                  )}
                </Item>
                <Row type="flex" justify="space-between">
                  <Col span={10}>
                    <Item
                      label="Phone"
                      colon={false}
                      validateStatus={error.PNO && error.PNO.type}
                      help={error.PNO && error.PNO.message}
                    // required
                    >
                      {getFieldDecorator("phone", {
                        rules: [{ required: false, message: "Enter Phone" }],
                      })(
                        <Input
                          onWheel={(event) => event.currentTarget.blur()}
                          disabled={!editable}
                          placeholder="Phone"
                          pattern="^[0-9]{10}$"
                          onKeyUp={e =>
                            form.setFieldsValue({
                              phone: formatValue(e, "onlyNo")
                            })}
                          onChange={(event) => {
                            if (!event.target.checkValidity()) {
                              setError({
                                ...error,
                                PNO: {
                                  type: "error",
                                  message: "Enter Valid Phone Number",
                                },
                              });
                            } else {
                              delete error.PNO;
                              setError(error);
                            }
                            if (dataSource.length > 0) {
                              if (event.target.checkValidity()) {
                                for (let i = 0; i < dataSource.length; i = i + 1) {
                                  if (dataSource[i].phone == event.target.value) {
                                    setError({
                                      ...error,
                                      PNO: {
                                        type: "error",
                                        message: "Phone Number already exists"
                                      }
                                    });
                                  } else {
                                    delete error.PNO;
                                    setError(error)
                                  }
                                }
                              }
                            }
                          }}
                          addonBefore="+91"
                          maxLength={10}
                        />
                      )}
                    </Item>
                  </Col>
                  <Col span={6}>
                    <Item label="Type" colon={false}>
                      {getFieldDecorator("type", {
                        rules: [{ required: false, message: "Enter type" }],
                      })(
                        <Select showAction={["click", "focus"]} placeholder="Type" disabled={!editable}>
                          {
                            primaryNumberCheck
                              ?
                              <Select.Option key={types[1].key} value={types[1].key}>
                                {types[1].title}
                              </Select.Option>
                              :
                              <Select.Option key={types[0].key} value={types[0].key}>
                                {types[0].title}
                              </Select.Option>
                          }
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={6}>
                    <Item label="DND" colon={false}>
                      {getFieldDecorator("dnd", {
                        rules: [{ required: false, message: "Enter DND" }]
                      })(
                        <Select showAction={["click", "focus"]} placeholder="DND" disabled={!editable}>
                          <Select.Option key={true} value={true}>
                            Yes
                      </Select.Option>
                          <Select.Option key={false} value={false}>
                            No
                      </Select.Option>
                        </Select>
                      )}
                    </Item>
                  </Col>
                </Row>

                {!editPhone ? (
                  <Button 
                    style={{ marginTop: "3vh" }}
                    type="primary"
                    disabled={!editable}
                    onClick={() => {
                      if (form.getFieldValue("phone").length === 10 && !error.PNO) {
                        const obj = {
                          id: "",
                          phone: form.getFieldValue("phone"),
                          type: form.getFieldValue("type"),
                          valid: true,
                          DND: form.getFieldValue("dnd"),
                        };
                        if (obj.type === "Primary") {
                          types.shift();
                        }
                        setDataSource([...dataSource, obj]);
                        // contactData()
                        form.setFieldsValue({ phone: undefined });
                        form.setFieldsValue({ type: "Alternate" });
                      } else {
                        message.error("Enter valid Phone number");
                      }
                    }}
                  >
                    Add Contact
                  </Button>
                ) : (
                    <Button
                      style={{ marginTop: "3vh" }}
                      type="primary"
                      onClick={() => editingPhone()}
                    >
                      Save
                    </Button>
                  )}
              </Col>
            </Row>
          </Form>
        </TabPane>
      </Tabs>
    </Modal>
  );
};
const WrappedCustomerUpdateModal = Form.create({ name: "customer_update_details" })(
  CustomerDetailsUpdateForm
);
export default withRouter(WrappedCustomerUpdateModal);
