import React from "react";
import { Table } from "antd";
import moment from "moment";
export default function DigitalLeadsTable(props) {
  const { pagination, dataSource, spinner } = props;
  
  const column = [
    {
      title:"S.No",
      width:'7%',
      render:(value,data,index)=><span>{(pagination.current-1)*10 + index+1}</span>
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "customerName",
      width:'15%',
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      key: "phonenumber",
      width:'15%',
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Scheduled Date and Time",
      dataIndex: "scheduleDateAndTime",
      key: "scheduleDateAndTime",
      render: (record) => <span>{record ? moment(new Date(record)).format("DD/MM/YYYY HH:mm") : "-"}</span>,
    },
    {
      title: "Enquiry Type",
      dataIndex: "enquiryType",
      key: "type",
      render: (record) => <span>{record}</span>,
    },
    {
      title:"Date",
      dataIndex:"createdAt",
      key:"createdAt",
      render: (record) => <span>{record ? moment(new Date(record)).format("DD/MM/YYYY HH:mm") : null}</span>,
    }

  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      loading={spinner}
    />
  );
}