import React, { useEffect, useState } from "react";
import { Col, Form, Input, Radio, Row, Icon, Collapse, Modal, Tag } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Text from "antd/lib/typography/Text";
const { Item } = Form;
const { Panel } = Collapse;

const WhatsupTemplate = (props) => {
  const [lengends, setLengents] = useState([]);
  const [name, setName] = useState(props.data?.name || "");
  const [text, setText] = useState(props.data?.text || "");

  useEffect(() => {
    if (props.data) {
      props.data.legends && setLengents(JSON.parse(props.data.legends));
      setName(props.data?.name);
      setText(props.data?.text);
    }
  }, [props.data, props.edit]);

  const handleSubmit = () => {
    props.data["name"] = name;
    props.data["text"] = text;
    props.updateTemplate(props.data, props.index);
  };

  return (
    <div>
      <Modal
        title={"Add Template"}
        onOk={() => (!props.edit ? props.setEdit(true) : handleSubmit())}
        onCancel={() => {
          props.close();
        }}
        visible={props.visible}
        okText={props.edit ? "Save" : "Modify"}
        wrapClassName="Template-modal"
      >
        <Form>
          <Row justify="center" type="flex">
            <Text strong style={{ marginBottom: "15px" }}>
              {props.data ? props.data.name + " - " + props.data.submodule : ""}
            </Text>
          </Row>
          <Row justify="center" type="flex">
            <Col span={24}>
              <Item label="Template Name" colon={false}>
                <Input
                  placeholder="Template Name"
                  disabled={true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Item>
            </Col>
          </Row>
          <Row justify="center" type="flex">
            <Col span={24}>
              <Item label="Whatsup Text" colon={false}>
                <TextArea
                  disabled={!props.edit}
                  placeholder="Whatsup Text"
                  autoSize={{ minRows: 3 }}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                ></TextArea>
              </Item>
            </Col>
          </Row>
          <Row>
            <Col span={15}>
              <Item>
                <Collapse
                  destroyInactivePanel={true}
                  bordered={false}
                  expandIcon={({ isActive }) => (
                    <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                  )}
                >
                  <Panel
                    style={{
                      borderBottom: "none",
                    }}
                    header={<Text strong>Legends</Text>}
                  >
                    {lengends.map((text, index) => (
                      <div key={index}> {text} </div>
                    ))}
                  </Panel>
                </Collapse>
              </Item>
            </Col>

            <Col span={9}>
              <Item
                label="Whatsapp Status"
                colon={false}
                style={{ textAlign: "center" }}
              >
                {/* <Radio.Group value={props.data?.status} buttonStyle="solid">
                  <Radio.Button value="YES">Yes</Radio.Button>
                  <Radio.Button value="NO">No</Radio.Button>
                </Radio.Group> */}
                {props.data?.status === "approved" && (
                  <Tag color="#87d068">{props.data?.status}</Tag>
                )}
                {props.data?.status === "pending" && (
                  <Tag color="#F7CD2E">{props.data?.status}</Tag>
                )}
                {props.data?.status === "rejected" && (
                  <Tag color="#cd201f">{props.data?.status}</Tag>
                )}
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default WhatsupTemplate;
