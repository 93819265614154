import React, { useState, useEffect } from 'react';
import {
	Modal,
	Input,
	Form,
	Row,
	Col,
	Upload,
	Icon,
	Typography,
	message,
	Button,
	Popconfirm,
	Select,
} from 'antd';

import { platformApi } from '../../../../api';
import { formatValue } from '../../../../utils';
import { UploadOutlined } from '@ant-design/icons';
let urls = process.env.NODE_ENV === 'development' ? 'http://localhost:4000/' : '/';

const { Item } = Form;

const { Text, Title } = Typography;
const ACCEPTED_FORMATS = ['image/jpeg', 'image/jpg', 'image/png', 'application/pdf'];
const MODULES = [
	{ id: 1, value: 'Brochure' },
	{ id: 2, value: 'Parts Manual' },
	{ id: 3, value: 'Service Manual' },
];
const UploadFormModel = ({
	form,
	open,
	close,
	data,
	editable: editData,
	emitData,
	modify,
	setImg,
	setSpinning,
	setPrimaryData,
}) => {
	const [error, setError] = useState({});
	const [loading, setLoading] = useState(false);
	const [editable, setEditable] = useState(true);
	const [logo, setLogo] = useState(null);
	const [imgLoading, setImgLoading] = useState(false);
	const [formData, setFormData] = useState(null);
	const [removeLogo, setRemoveLogo] = useState(false);
	const [loader, setLoader] = useState(false);
	const [fileUrl, setFileUrl] = useState(null);
	const [fileName, setFileName] = useState('');
	const [module, setModule] = useState('');
	const [selectedFile, setSelectedFile] = useState(null);

	const dummyRequest = ({ file, onSuccess }) => {
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
	};

	const handleSubmit = () => {
		if (selectedFile != null) {
			setFileUrl(null);
			const formData = new FormData();
			formData.append('file', selectedFile.file.originFileObj);
			formData.append('fileName', fileName);
			formData.append('module', module);

			setSpinning(true);
			platformApi
				.post('/api/fileUpload/generate/file', formData, {
					headers: { 'Content-Type': 'multipart/form-data' },
				})
				.then((res) => {
					setSpinning(false);
					if (res.data.code == 200) {
						message.success('File Uploaded SuccessFully');
						setFileUrl(res.data?.response?.url);
						setPrimaryData();
					} else {
						message.error('Error in Uploading File');
					}
					setLoader(false);
				})
				.catch((err) => {
					setLoader(false);
					setSpinning(false);
					message.error('Error in Uploading File');
				});
		} else {
			setLoader(false);
			message.error('File Not Uploaded Properly');
		}
		clearFieldsAndClose();
	};

	const clickToCopy = () => {
		navigator.clipboard.writeText(fileUrl);
		message.success('Copied To ClipBoard');
	};

	const clearFieldsAndClose = () => {
		form.resetFields();
		setLogo(null);
		setError({});
		setImgLoading(false);
		setFormData(null);
		setRemoveLogo(false);
		close();
	};

	const beforeUpload = (file) => {
		// file type check
		if (!ACCEPTED_FORMATS.includes(file.type)) {
			message.error('You can only upload JPG/PNG/pdf file!', 3);
		}
		// file size check
		const isLt2M = file.size / 1024 / 1024 < 10;
		if (!isLt2M) {
			message.error('Image must smaller than 10MB!', 3);
		}
		return isLt2M;
	};

	return (
		<Modal
			title={<Title level={4}>Create Link</Title>}
			visible={open}
			onCancel={clearFieldsAndClose}
			okText={!editable ? 'Modify' : loading ? 'Saving' : 'Save'}
			okButtonProps={{
				loading,
				disabled: (!editable && !modify) || imgLoading,
			}}
			onOk={() => (editable ? handleSubmit() : setEditable(true))}
		>
			<Form>
				<Row>
					<Col>
						<Input
							disabled={!editable}
							placeholder='File Name'
							pattern='^^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$'
							onChange={(event) => setFileName(event.target.value)}
							value={fileName}
						/>
					</Col>
					<Col>
						<Form.Item>
							<Select
								dropdownStyle={{
									overflowY: 'auto',
									overflowX: 'hidden',
								}}
								onChange={(event) => {
									console.log(event);
									setModule(event);
								}}
								// value={module}
								disabled={!editable}
								showAction={['click', 'focus']}
								placeholder='Module'
							>
								{MODULES.map((module) => {
									return (
										<Select.Option key={module.id} value={module.value}>
											{module.value}
										</Select.Option>
									);
								})}
							</Select>
						</Form.Item>
					</Col>
					<Col>
						<Upload
							beforeUpload={(e) => beforeUpload(e)}
							onChange={(fileData) => {
								console.log({ fileData });
								setSelectedFile(fileData);
							}}
							multiple={false}
							accept='.pdf,.doc,.docx,.jpg,.jpeg'
							showUploadList={true}
							customRequest={dummyRequest}
							listType='text'
						>
							<Button size={'large'} icon={<UploadOutlined />} loading={loader}>
								Click to Upload
							</Button>
							{/* <span style={{ marginLeft: '-20px', display: 'block', marginTop: '2s0px' }}>
								*(Accepts only .pdf,.jpg,.jpeg files)
							</span> */}
						</Upload>
					</Col>
				</Row>
				{/* <div>
					{fileUrl && (
						<div className='copy'>
							<input
								value={fileUrl}
								style={{
									width: '500px',
									height: 'auto',
									marginRight: '18px',
									padding: '5px',
									outline: 'none',
								}}
								disabled={true}
							/>
							<button className='uploadBtn' onClick={() => clickToCopy()}>
								Click To Copy
							</button>
						</div>
					)}
				</div> */}
			</Form>
		</Modal>
	);
};

const WrappedUploadFormModel = Form.create({ name: 'normal_login' })(UploadFormModel);

export default WrappedUploadFormModel;
