import React from "react";
import { Table, Divider, Popconfirm, Typography } from "antd";

const { Text } = Typography;

export default function GroupTable(props) {
  const { pagination, dataSource, deleteData, openModal, refreshData, loading ,modify,deleteAccess} = props;

  const column = [
    {
      title: "Group Name",
      dataIndex: "groupName",
      key: "groupName",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "SMS Members",
      dataIndex: "count",
      key: "count",
      render: (record, data) => <span>{record}</span>,
    },
    {
      title: "Whatsapp Members",
      dataIndex: "whatsappMemberCount",
      key: "whatsappMemberCount",
      // render: (record, data) => <span>{record}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {
            modify ? 
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(data.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
              <span></span>
          } 
          <Divider type="vertical" />
          
          { deleteAccess ? 
          <Popconfirm
            title="Do you want to delete this Group?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              deleteData(data.id);
              event.stopPropagation();
            }}
          >
            <span
              onClick={(event) => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
          :
          <span></span>
          }
           {
            !modify && !deleteAccess
            ?
            <Text strong disabled>No Actions Given</Text>
            :
            <span></span>
          }
          <Divider type="vertical" />
          <span
            onClick={(event) => {
              event.stopPropagation();
              refreshData(data.id)
            }}
            className="linkylink"
          >
            Refresh
          </span>

          {/* :
              <span></span>
          } */}
         
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      loading={loading}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props.id, false),
      })}
    />
  );
}
