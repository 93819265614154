import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Tooltip,
  Dropdown,
  Menu,
  Icon,
  message,
} from "antd";
import { platformApi } from "../../../api";
import { ContextAPI } from "../../../ContextAPI";
import { withRouter } from "react-router-dom";
import { CloudFilled, LeftOutlined } from "@ant-design/icons";

// Components
import VehicleTable from "./VehicleTable";
import WrappedVehicleModal from "./VehicleModal";
import WrappedAdvancedFilter from "./AdvancedFilters";
import ImportExportData from "./ImportExportData";
import { getUUID } from '../../../_helpers/utils'

const { Title, Text } = Typography;
const { Search } = Input;
const { Item } = Menu;

const VehicleMaster = (props) => {
  const [file, setfile] = useState(false);
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(null);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [exportFile, setExportFile] = useState("");
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [upload, setUpload] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  //filters
  const [filter, setFilter] = useState([]);
  const [filteropen, setFilteropen] = useState(false);
  const [vehicleModalKey, setVehicleModalKey] = useState('')

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const deleteData = (id) => {
    let temp = page != 1 && dataSource.length === 1;
    setSpinning(true);
    platformApi
      .delete(`/api/vehicleMaster/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Deleted Sucessfully", 2);
            if (temp) {
              setPage(1);
              setPageData(null, 1)
            }
            else {
              setPageData();
            }

          } else {
            message.error("Unable to delete Vehicle", 2);
          }
        } else {
          message.error("Unable to delete Vehicle", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to delete Vehicle", 2);
        console.error("Error on Vehicle : ", error);
      });
  };
  const setPageData = (search, val) => {
    setSpinning(true);
    platformApi
      .post("/api/vehicleMaster/get", {
        page: val ? val : page,
        size: limit,
        searchString: search,
        // status: search && search.vehiclestatus
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            response.data.VehicleMaster.sort((a, b) => {
              if (a.vehicleStatus === "AVAILABLE") {
                if (b.vehicleStatus === "AVAILABLE") return 0;
                else return -1;
              } else if (b.vehicleStatus === "AVAILABLE") return 1;
              else return 0;
            });
            // console.log(response.data)
            setData(response.data.VehicleMaster);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch vehicles", 2);
          }
        } else {
          message.error("Unable to fetch vehicles", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to fetch vehicles", 2);
        console.error("Error on Vehicle Master : ", error);
      });
  };
  useEffect(() => {
    platformApi.get("/api/vehicleMaster/data/export").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        setExportFile(`${ENDPOINT}${data.response}`);
      }
    });
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "vehicle_master") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPageData();
  }, []);

  useEffect(() => {
    setPageData(search);
  }, [search, page, limit]);

  const addData = (vehicle) => {
    setSpinning(true);
    var dataObj = JSON.parse(vehicle.get("dataObj"));
    if (dataObj.id) {
      platformApi
        .put(`/api/vehicleMaster/${dataObj.id}`, vehicle, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            setAddFlag(false)
            setVehicleModalKey(getUUID())
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === vehicle.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              // vehicle&&vehicle.price&&vehicle.price[0]&&(vehicle.dependency=true);
              message.success("Vehicle saved successfully", 2);
            } else {
              message.error("Unable to save vehicle", 2);
            }
            setPageData();
          } else {
            message.error("Unable to save vehicle", 2);
          }
        })
        .catch((error) => {
          message.error("Unable to save vehicle", 2);
          console.error("Error on Vehicle Master : ", error);
          setSpinning(false);
        });
    }
    else {
      platformApi
        .post("/api/vehicleMaster/", vehicle, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            setAddFlag(false)
            setVehicleModalKey(getUUID())
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Vehicle added successfully", 2);
            } else if (response.code == 400) {
              message.error("Vehicle code with name already exists", 2);
            } else {
              message.error("Unable to add Vehicle", 2);
            }
            setPageData();
          } else {
            message.error("Unable to add Vehicle", 2);
          }
        })
        .catch((error) => {
          message.error("Unable to add Vehicle", 2);
          console.error("Error on Vehicle : ", error);
          setSpinning(false);
        });
    }
  };
  const uploadCSV = (event) => {
    if (event.file.status === "uploading") {
      setfile(true);
    }
    if (event.file.status === "done") {
      setSpinning(true);
      setUpload(true);
      setCsvModal(false);
      setfile(false);
      const formData = new FormData();
      formData.append("VEHICLEMASTER", event.file.originFileObj);
      platformApi
        .post("/api/vehicleMaster/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setUpload(false);
          setSpinning(false);
          setPageData();
          if (res.data.code === 200) {
            message.success("Import Data Done");
            window.location.reload();
            setPageData(null)
          } else {
            message.error("Cannot Import Data");
          }
        })
        .catch((error) => {
          setSpinning(false);
        });
    }
  };
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Vehicle Master [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Vehicle Name"
          onSearch={(event) => {
            // let searches = { name: event };
            setPage(1);
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null);
              setPage(1);
            }
          }}
        />
        {/* <div style={{ width: "20%", textAlign: "center" }}> */}
        {/* <Button
            type="link"
            onClick={() => {
              filteropen ? setFilteropen(false) : setFilteropen(true);
            }}
          >
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button> */}
        {/* </div> */}
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Vehicle
        </Button>
      </div>
      <div className="accessories-head" style={{ margin: "0 81% 2%" }}>
        <Button
          onClick={() => {
            setCsvModal(true);
          }}
          type="primary"
          disabled={upload}
          disabled={!createAccess}
        >
          Import / Export CSV
        </Button>
      </div>
      <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        close={setFilteropen}
        id={filter}
        setId={setFilter}
        setData={setData}
        setCount={setCount}
        page={page}
        setPage={setPage}
        setPageData={setPageData}
        limit={limit}
        setSearch={setSearch}
      />

      <VehicleTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          current: page,
          showQuickJumper: true,
          defaultCurrent: 1,
          total: count,
        }}
        openModal={(index, editable) => {
          setSelected(index);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{
          spinning,
        }}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />

      <WrappedVehicleModal
        key={vehicleModalKey}
        data={dataSource[dataSource.findIndex((data) => data.id === selected)]}
        emitData={(data) => addData(data)}
        open={addFlag}
        editable={editFlag}
        close={() => {
          dataSource[dataSource.findIndex((data) => data.id === selected)];
          setSelected(null);
          setAddFlag(false);
          // setPageData();
        }}
        modify={modifyAccess}
      />
      <ImportExportData
        upload={(event) => uploadCSV(event)}
        open={csvModal}
        setfile={setfile}
        close={() => {
          if (file) {
            window.location.reload();
          }
          setCsvModal(false)
        }}
        exportFile={exportFile}
        createAccess={createAccess}
      />
    </div>
  );
};

export default withRouter(VehicleMaster);
