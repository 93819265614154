import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { message, Spin } from "antd";
import Details from "./Details";
import "./whatsappProfile.less";
import { platformApi } from "../../../../api";

const WhatsupProfile = (props) => {
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({
    name: "",
    email: "",
    address: "",
    description: "",
    image: "",
    websites: [],
  });

  useEffect(() => {
    setLoading(true);
    platformApi
      .get("api/whatsapp/profile")
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.response.data);
          message.success("Fetching data success", 2);
        } else {
          message.error("something went wrong", 2);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error("something went wrong", 2);
      });
  }, []);

  return (
    <>
      {loading ? (
        <Spin
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "8rem",
            justifyContent: "center",
          }}
        />
      ) : (
        <div className="whatsapp_profile">
          <Details
            loading={loading}
            setLoading={setLoading}
            phone={phone}
            data={data}
            setData={setData}
          />
        </div>
      )}
    </>
  );
};

export default withRouter(WhatsupProfile);
