import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Result,
  Tabs
} from "antd";
import { platformApi } from "../../../api";
import BookingRegisterTable from "./BookingRegisterTable";
import BookingRegisterModal from "./BookingRegisterModal";
import { ContextAPI } from "../../../ContextAPI";
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs
//default data sturucture
const defaultData = {
  bookingId: null,
  customer: null,
  customerId: null,
  customerName: null,
  customerFatherName: null,
  customerAddress: null,
  customerPhone: null,
  customerLocality: null,
  customerCountry: null,
  customerState: null,
  customerCity: null,
  customerPincode: null,
  customerEmail: null,
  customerDob: null,
  customerGender: null,
  IDbranch: null,
  nomineeName: null,
  nomineeAge: null,
  relationship: null,
  vehicle: null,
  rto: null,
  rtoCode: null,
  rtoArea: null,
  exchangeVehicleName: null,
  exchangeVehiclePrice: null,
  price: null,
  accessories: [],
  quotation: [],
  color: null,
  loan: null,
  authentication: null,
  pdf: "example",
  pdfSigned: "example",
  executive: null,
  remarks: null,
  bookingStatus: null,
  authorisedTime: null,
  refferedBy: null,
};
export default function Bookingregister() {
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);

  const [spinning, setSpinning] = useState(false);

  const [bookingId, setBookingId] = useState("");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookingsData, setBookingsData] = useState([]);
  const [bFlag, setBFlag] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(null);
  const [search, setSearch] = useState(null);
  const [tab, setTab] = useState(null)
  const { loginCredintials } = useContext(ContextAPI);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [gst, setGst] = useState();
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };
  // 
  useEffect(() => {
    setGst(loginCredintials.branch[0].gst)
    loginCredintials.roleAccess &&
        loginCredintials.roleAccess.map((each) => {
            if (each.subModule === "booking_register") {
                if (each.access.create) {
                    setCreateAccess(true);
                }
                if (each.access.update) {
                    setModifyAccess(true);
                }
                if (each.access.delete) {
                    setDeleteAccess(true);
                }
            }
        });
    // getAllBooking();
    //getPSI();
}, []);


  useEffect(() => {
    getAllBooking();
  }, []);

  const getAllBooking = (value = null, status, val) => {
    setSpinning(true);
    if (!status) {
      status = tab
    }
    platformApi
      .post("/api/booking/get", {
        page: val ? val : page,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: value,
        status
      })
      .then((res) => {
        setSpinning(false);
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            console.log(JSON.stringify(response.data.booking));
            setBookingsData(response.data.booking);
            setCount(response.data.count);
          } else {
            message.error("Failed to fetch booking");
          }
        } else {
          message.error("Failed to fetch booking");
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on booking : ", error);
        message.error("Failed to fetch booking");
      });
  };

  useEffect(() => {
    getAllBooking(search);
  }, [page, limit, search]);

  const getSelectedBooking = (id) => {
    platformApi
      .get(`/api/booking/${id}`)
      .then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            //to remove customer verification on modify
            if (editFlag && addFlag) {
              response.data.authentication = {
                afterVerification: null,
                beforeVerification: null,
                digital: null,
                id: response.data.authentication.id,
                manual: null,
                verifiedAt: null,
              }
            }

            setSelectedBooking(response.data);
          }
        }
      })
      .catch((error) => {
        console.error("Error on booking : ", error);
      });
  };

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && bookingsData.length === 1;
    platformApi
      .delete("/api/booking/" + id)
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1);
              getAllBooking(null, null, 1);
            }
            else {
              getAllBooking();
            }
            message.success("Deleted Sucessfully", 2);
          } else {
            message.error("Unable to delete Booking", 2);
          }
        } else {
          message.error("Unable to delete booking", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on booking : ", error);
        message.error("Unable to delete booking", 2);
      });
  };

  const addData = (bookingRegister, showMessage = false) => {
    setSpinning(true);
    if (bookingRegister.id) {
      platformApi
        .put(`/api/booking/${bookingRegister.id}`, bookingRegister)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setBookingId(response.data.bookingId);
              setSelectedBooking(response.data);
              // if(JSON.stringify(bookingRegister)===JSON.stringify(response.data))
              showMessage && message.success("Booking saved successfully", 1);
            } else {
              message.error("Unable to save booking", 1);
            }
          } else {
            message.error("Unable to save booking", 1);
          }
        })
        .catch((err) => {
          setSpinning(false);
          message.error("Unable to save booking", 1);
          console.error("Error on booking : ", err);
        });
    } else {
      platformApi
        .post("/api/booking/create", bookingRegister)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = res.data;
            if (response.code === 200) {
              setSelectedBooking(response.data);
              if (response.data.bookingStatus === "ACCEPTED")
                sendConfirmSMS(response.data);
              message.success("Booking added successfully");
            } else {
              message.error("Error in booking creation");
            }
          } else {
            message.error("Error in booking creation");
          }
        })
        .catch((err) => {
          console.error("Error on booking : ", err);
        });
    }
  };

  const sendConfirmSMS = (data) => {
    const {
      bookingId,
      branch,
      executive: {
        profile: { employeeName },
      },
      authentication,
      customer: { name, contacts },
      vehicle: { modelName },
    } = data;
    const phone = contacts[0].phone;
    const smsdata = {
      pdflink:
        authentication.afterVerification || authentication.beforeVerification,
      cname: name,
      vname: modelName,
      bkid: bookingId,
      slex: employeeName,
      clink: "-",
      dlr: branch.name,
    };
    platformApi
      .post("/api/sendSms/bookingconfirm", {
        phone,
        smsdata,
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          message.success("Message sent successfully");
        } else if (data.code === 401) {
          message.error(data.message);
        } else {
          message.error("Unable to send SMS");
        }
      });
  };

  const setBooking = () => {
    platformApi.post("/api/idGenerate/booking").then((res) => {
      let { data } = res;
      if (data.code === 200) {

        let { response } = data;
        setBookingId(data.response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
  };

  const handleTabChange = (activeKey) => {
    switch (activeKey) {
      case "1": getAllBooking(null, "ALL")
        setTab("ALL")
        setPage(1)
        break;
      case "2": getAllBooking(null, "PENDING")
        setTab("PENDING")
        setPage(1)
        break;
      case "3": getAllBooking(null, "ACCEPTED")
        setTab("ACCEPTED")
        setPage(1)
        break;
      case "4": getAllBooking(null, "REJECTED")
        setTab("REJECTED")
        setPage(1)
        break;
      default: getAllBooking(null, null)
        setTab("ALL")
        setPage(1)
    }
  }

  const menu = <Menu>{getMenu()}</Menu>;
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Booking Register [{count}]
          <span style={{ margin: ".5rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Booking"
          onSearch={(event) => {
            setPage(1);
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null);
              setPage(1);
            }
          }}
        />
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
            setBooking();
            setSelectedBooking(defaultData);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Booking
        </Button>
      </div>

      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane
          tab={
            <span>
              All Bookings
            </span>
          }
          key="1"
        >
          <BookingRegisterTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              getSelectedBooking(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            onDelete={(id) => deleteData(id)}
            dataSource={bookingsData}
            spinner={{
              spinning,
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              Pending Bookings
            </span>
          }
          key="2"
        >
          <BookingRegisterTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              getSelectedBooking(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            onDelete={(id) => deleteData(id)}
            dataSource={bookingsData}
            spinner={{
              spinning,
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              Completed Bookings
            </span>
          }
          key="3"
        >
          <BookingRegisterTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              getSelectedBooking(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            onDelete={(id) => deleteData(id)}
            dataSource={bookingsData}
            spinner={{
              spinning,
            }}
            loginCredintials={loginCredintials}
            modify={modifyAccess}
            deleteAccess={deleteAccess}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              Rejected Bookings
            </span>
          }
          key="4"
        >
          <BookingRegisterTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              getSelectedBooking(id);
              setEditFlag(editable);
              setAddFlag(true);
            }}
            onDelete={(id) => deleteData(id)}
            dataSource={bookingsData}
            spinner={{
              spinning,
            }}
           
          />
        </TabPane>
      </Tabs>

      <BookingRegisterModal
        id={bookingId}
        currentData={selectedBooking}
        emitData={(data, showMessage) => addData(data, showMessage)}
        editable={editFlag}
        open={addFlag}
        setBFlag={setBFlag}
        bFlag={bFlag}
        clearBookingData={(data) => setSelectedBooking(data)}
        close={() => {
          setAddFlag(false);
          setBFlag(false);
          getAllBooking();
        }}
      />
    </div>
  );
}
