import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Input,
  Popconfirm,
  Divider,
  Upload,
  Icon,
  Modal,
  Checkbox,
  message,
} from "antd";
import { platformApi } from "../../../../../api";
import { ENDPOINT } from "../../../../../constants";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;

const Observations = ({
  form,
  validityRef,
  data,
  clearRef,
  id,
  open,
  editable,
  recvData,
}) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [fileList, setFileList] = useState([]);
  const [RHSfileList, setRHSFileList] = useState([]);
  const [RearfileList, setRearFileList] = useState([]);
  const [LHSfileList, setLHSFileList] = useState([]);
  const [TopfileList, setTopFileList] = useState([]);

  //FORM DATA
  const [FrontForm, setFrontForm] = useState("");
  const [RHSForm, setRHSForm] = useState("");
  const [LHSForm, setLHSForm] = useState("");
  const [RearForm, setRearForm] = useState("");
  const [TopForm, setTopForm] = useState("");

  //CHECKBOX STATES

  const [checkedList, setCheckedList] = useState([]);
  const [indeterminate, setIndeterminate] = useState(true);
  const [checkAll, setCheckAll] = useState(false);

  const [PcheckedList, setPCheckedList] = useState([]);
  const [Pindeterminate, setPIndeterminate] = useState(true);
  const [PcheckAll, setPCheckAll] = useState(false);

  const documents = ["RC", "Insurance", "Pollution", "Service Book"];
  const parts = ["Key", "Spare Tyres", "Toolkit"];

  //IMAGES
  const [frontImg, setFront] = useState();
  const [RHSImg, setRhs] = useState();
  const [LHSImg, setLhs] = useState();
  const [RearImg, setRear] = useState();
  const [TopImg, setTop] = useState();

  const [frontVisible, setFrontVisible] = useState(false);

  useEffect(() => {
    if (recvData.vehicle) {
      setFront(recvData.vehicleImage.frontView);
      setRear(recvData.vehicleImage.rearView);
      setRhs(recvData.vehicleImage.rhsView);
      setLhs(recvData.vehicleImage.lhsView);
      setTop(recvData.vehicleImage.topView);

      let docResults = [];
      let partsResults = [];

      if (recvData.documents.pollution) docResults.push("Pollution");
      if (recvData.documents.serviceBook) docResults.push("Service Book");
      if (recvData.documents.rc) docResults.push("RC");
      if (recvData.documents.insurance) docResults.push("Insurance");

      if (recvData.parts.key) partsResults.push("Key");
      if (recvData.parts.toolkit) partsResults.push("Toolkit");

      setCheckedList([...docResults]);
      setPCheckedList([...partsResults]);

      // setCheckedList(docResults);

      let formData = {
        fuel: recvData.fuelLevel,
        oil: recvData.oilLevel,
        others: recvData.others,
      };

      form.setFieldsValue(formData);
    }
  }, [recvData, editable]);

  const checkChange = (checked) => {
    setCheckedList(checked);
    setIndeterminate(!!checked.length && checked.length < checkedList.length);
    setCheckAll(checked.length === checkedList.length);
  };
  const PcheckChange = (checked) => {
    setPCheckedList(checked);
    setPIndeterminate(!!checked.length && checked.length < PcheckedList.length);
    setPCheckAll(checked.length === PcheckedList.length);
  };

  // const checkAllChange = (e) => {
  //   setCheckedList(e.target.checked ? checkedList : []);
  //   setIndeterminate(false);
  //   setCheckAll(e.target.checked);
  // };
  // const PcheckAllChange = (e) => {
  //   setPCheckedList(e.target.checked ? PcheckedList : []);
  //   setPIndeterminate(false);
  //   setPCheckAll(e.target.checked);
  // };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => setPreviewVisible(false);

  const handlePreview = async (file) => {

    // if (file.substring(0, 19) === "https://nandiyamaha") {
    //   setPreviewImage(file), setPreviewVisible(true);
    // } else {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview), setPreviewVisible(true);
    // }
  };

  const handleChange = ({ fileList }, type, e) => {
    if (type === "Front") {
      setFileList(fileList);
      const formData = new FormData();
      formData.append("profile", e.file.originFileObj);
      formData.append("master", "Transaction Master");
      formData.append("module", "Job Order");
      formData.append("id", id);
      formData.append("type", "front");
      platformApi
        .post("/api/upload/image", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.front = data.data.Location;
            setFrontForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          console.error("Error on Observation Form : ", error);
          message("Unable to upload image");
        });
    }
    if (type === "RHS") {
      setRHSFileList(fileList);
      const RHSformData = new FormData();
      RHSformData.append("profile", e.file.originFileObj);
      RHSformData.append("master", "Transaction Master");
      RHSformData.append("module", "Job Order");
      RHSformData.append("id", id);
      RHSformData.append("type", "rhs");
      platformApi
        .post("/api/upload/image", RHSformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.RHS = data.data.Location;
            setRHSForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          console.error("Error on Observation Form : ", error);
          message("Unable to upload image");
        });
    }
    if (type === "Rear") {
      setRearFileList(fileList);
      const RearformData = new FormData();
      RearformData.append("profile", e.file.originFileObj);
      RearformData.append("master", "Transaction Master");
      RearformData.append("module", "Job Order");
      RearformData.append("id", id);
      RearformData.append("type", "rear");
      platformApi
        .post("/api/upload/image", RearformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.rear = data.data.Location;
            setRearForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          console.error("Error on Observation Form : ", error);
          message("Unable to upload image");
        });
    }
    if (type === "LHS") {
      setLHSFileList(fileList);
      const LHSformData = new FormData();
      LHSformData.append("profile", e.file.originFileObj);
      LHSformData.append("master", "Transaction Master");
      LHSformData.append("module", "Job Order");
      LHSformData.append("id", id);
      LHSformData.append("type", "lhs");
      platformApi
        .post("/api/upload/image", LHSformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.LHS = data.data.Location;
            setLHSForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          console.error("Error on Observation Form : ", error);
          message("Unable to upload image");
        });
    }
    if (type === "Top") {
      setTopFileList(fileList);
      const TopformData = new FormData();
      TopformData.append("profile", e.file.originFileObj);
      TopformData.append("master", "Transaction Master");
      TopformData.append("module", "Job Order");
      TopformData.append("id", id);
      TopformData.append("type", "top");
      platformApi
        .post("/api/upload/image", TopformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.top = data.data.Location;
            setTopForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          console.error("Error on Observation Form : ", error);
          message("Unable to upload image");
        });
    }
  };

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  useEffect(() => {
    if (data.fuelLevel)
      form.setFieldsValue({
        fuel: data.fuelLevel,
        oil: data.oilLevel,
        others: data.others,
      });

    if (data.fileList != undefined) setFileList(data.fileList);
    if (data.RHSfileList != undefined) setRHSFileList(data.RHSfileList);
    if (data.RearfileList != undefined) setRearFileList(data.RearfileList);
    if (data.LHSfileList != undefined) setLHSFileList(data.LHSfileList);
    if (data.TopfileList != undefined) setTopFileList(data.TopfileList);
    if (data.checkedList != undefined) setCheckedList(data.checkedList);
    if (data.PcheckedList != undefined) setPCheckedList(data.PcheckedList);
  }, [open]);
  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
    };
    validityRef.current = () => validatePage();
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });
  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.fileList = fileList;
      data.RHSfileList = RHSfileList;
      data.RearfileList = RearfileList;
      data.LHSfileList = LHSfileList;
      data.TopfileList = TopfileList;
      data.fuelLevel = parseFloat(getFieldValue("fuel"));
      data.oilLevel = parseFloat(getFieldValue("oil"));
      data.others = getFieldValue("others");
      data.checkedList = checkedList;
      data.PcheckedList = PcheckedList;
      data.front = FrontForm;
      data.rhs = RHSForm;
      data.lhs = LHSForm;
      data.top = TopForm;
      data.rear = RearForm;
    }
    return isValid;
  };
  const column = [
    {
      title: "Observations",
      dataIndex: "observation",
      key: "observation",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(record.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          <Divider type="vertical" />
          <Popconfirm
            title="Do you want to delete this HSN Code?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              props.delete(record.id);
            }}
          >
            <span
              className="linkylink"
              onClick={(event) => event.stopPropagation()}
            >
              Delete
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const { getFieldDecorator, getFieldValue } = form;
  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={6}>Job Order No. : {id}</Col>
        <Col span={2}></Col>
        <Col span={4}></Col>
        <Col span={4}></Col>
        <Col span={4}>
          Date : {new Date().toLocaleDateString()}
          <br />
          Time : {new Date().toLocaleTimeString()}
        </Col>
      </Row>
      <br />
      <Row type="flex" justify="space-between">
        <Col span={4}>
          {editable ? (
            <div className="clearfix">
              Front View
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                action={`${ENDPOINT}api/upload/mocky`}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={(e) => {
                  handleChange(e, "Front", e);
                }}
                disabled={!editable}
              >
                {fileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          ) : (
              <div className="clearFix">
                Front View
                <br />
                <img
                  src={frontImg}
                  alt="Front View"
                  style={{ width: "100%" }}
                  onClick={(e) => setFrontVisible(true)}
                />
                <Modal
                  visible={frontVisible}
                  footer={null}
                  onCancel={(e) => setFrontVisible(false)}
                >
                  <img alt="example" style={{ width: "100%" }} src={frontImg} />
                </Modal>
              </div>
            )}
        </Col>
        <Col span={4}>
          {editable ? (
            <div className="clearfix">
              RHS View
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                action={`${ENDPOINT}api/upload/mocky`}
                listType="picture-card"
                fileList={RHSfileList}
                onPreview={handlePreview}
                onChange={(e) => {
                  handleChange(e, "RHS", e);
                }}
                disabled={!editable}
              >
                {RHSfileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          ) : (
              <div className="clearFix">
                RHS View
                <br />
                <img
                  src={RHSImg}
                  alt="RHS View"
                  style={{ width: "100%" }}
                  onClick={(e) => setFrontVisible(true)}
                />
                <Modal
                  visible={frontVisible}
                  footer={null}
                  onCancel={(e) => setFrontVisible(false)}
                >
                  <img alt="example" style={{ width: "100%" }} src={RHSImg} />
                </Modal>
              </div>
            )}
        </Col>
        <Col span={4}>
          {editable ? (
            <div className="clearfix">
              Rear View
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                action={`${ENDPOINT}api/upload/mocky`}
                listType="picture-card"
                fileList={RearfileList}
                onPreview={handlePreview}
                onChange={(e) => {
                  handleChange(e, "Rear", e);
                }}
                disabled={!editable}
              >
                {RearfileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          ) : (
              <div className="clearFix">
                Rear View
                <br />
                <img
                  src={RearImg}
                  alt="Rear View"
                  style={{ width: "100%" }}
                  onClick={(e) => setFrontVisible(true)}
                />
                <Modal
                  visible={frontVisible}
                  footer={null}
                  onCancel={(e) => setFrontVisible(false)}
                >
                  <img alt="example" style={{ width: "100%" }} src={RearImg} />
                </Modal>
              </div>
            )}
        </Col>
        <Col span={4}>
          {editable ? (
            <div className="clearfix">
              LHS View
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                action={`${ENDPOINT}api/upload/mocky`}
                listType="picture-card"
                fileList={LHSfileList}
                onPreview={handlePreview}
                onChange={(e) => {
                  handleChange(e, "LHS", e);
                }}
                disabled={!editable}
              >
                {LHSfileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          ) : (
              <div className="clearFix">
                LHS View
                <br />
                <img
                  src={LHSImg}
                  alt="LHS View"
                  style={{ width: "100%" }}
                  onClick={(e) => setFrontVisible(true)}
                />
                <Modal
                  visible={frontVisible}
                  footer={null}
                  onCancel={(e) => setFrontVisible(false)}
                >
                  <img alt="example" style={{ width: "100%" }} src={LHSImg} />
                </Modal>
              </div>
            )}
        </Col>
        <Col span={4}>
          {editable ? (
            <div className="clearfix">
              Top View
              <Upload
                // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                action={`${ENDPOINT}api/upload/mocky`}
                listType="picture-card"
                fileList={TopfileList}
                onPreview={handlePreview}
                onChange={(e) => {
                  handleChange(e, "Top", e);
                }}
                disabled={!editable}
              >
                {TopfileList.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={previewVisible}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: "100%" }}
                  src={previewImage}
                />
              </Modal>
            </div>
          ) : (
              <div className="clearFix">
                Top View
                <br />
                <img
                  src={TopImg}
                  alt="Top View"
                  style={{ width: "100%" }}
                  onClick={(e) => setFrontVisible(true)}
                />
                <Modal
                  visible={frontVisible}
                  footer={null}
                  onCancel={(e) => setFrontVisible(false)}
                >
                  <img alt="example" style={{ width: "100%" }} src={TopImg} />
                </Modal>
              </div>
            )}
        </Col>
      </Row>
      <br />
      Checksheet :
      <br />
      <br />
      <Row type="flex" justify="space-between">
        <Col span={11}>
          Documents :
          <br />
          <br />
          <div>
            {/* <div style={{ borderBottom: "1px solid #E9E9E9" }}>
              <Checkbox
                indeterminate={indeterminate}
                onChange={checkAllChange}
                checked={checkAll}
              >
                Check All
              </Checkbox>
            </div>
            <br /> */}
            <CheckboxGroup
              options={documents}
              value={checkedList}
              onChange={checkChange}
              disabled={!editable}
            />
          </div>
        </Col>
        <Col span={11}>
          Parts :
          <br />
          <br />
          <div>
            {/* <div style={{ borderBottom: "1px solid #E9E9E9" }}>
              <Checkbox
                indeterminate={indeterminate}
                onChange={checkAllChange}
                checked={checkAll}
              >
                Check All
              </Checkbox>
            </div>
            <br /> */}
            <CheckboxGroup
              options={parts}
              value={PcheckedList}
              onChange={PcheckChange}
              disabled={!editable}
            />
          </div>
          <br />
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Form.Item colon={false} label={<Text>Fuel Level</Text>}>
                {getFieldDecorator(
                  "fuel",
                  {
                    rules: [
                      {
                        required: true,
                        message: "Please Enter Fuel Level",
                      },
                    ],
                  }
                )(<Input
                  type="number"
                  disabled={!editable}
                  placeholder="Fuel Level" />)}
              </Form.Item>
            </Col>
            <Col span={11}>
              <Form.Item colon={false} label={<Text>Oil Level</Text>}>
                {getFieldDecorator(
                  "oil",
                  {
                    rules: [
                      {
                        required: false,
                        message: "Please Eneter Oil Level",
                      },
                    ],
                  }
                )(<Input disabled={!editable} placeholder="Oil Level" />)}
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={11}></Col>
      </Row>
      <Form.Item colon={false} label={<Text>Others</Text>}>
        {getFieldDecorator(
          "others"
          //  {
          //   rules: [
          //     {
          //       required: false,
          //       message: "Please input your Model!",
          //     },
          //   ],
          // }
        )(<TextArea disabled={!editable} rows={4} />)}
      </Form.Item>
    </Form>
  );
};

const WrappedObservations = Form.create()(Observations);

export default WrappedObservations;
