import React from "react";
import { Table, Divider, Popconfirm, Typography } from "antd";
import { useHistory } from "react-router-dom";
const { Text } = Typography
export default function VPITable(props) {
  const { pagination, dataSource, openModal, spinner, modify, deleteAccess } = props;
  const history = useHistory();
  const column = [
    {
      title: "VP Invoice",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Vehicle List",
      dataIndex: "purchaseChallan",
      key: "list",
      render: (records) => (
        <span>
          {records &&
            records.vehicleDetail &&
            records.vehicleDetail.map((record, index) => (
              <span>
                {index > 0 ? " , " : ""}
                {record.vehicle && record.vehicle.modelCode} - {record.vehicle && record.vehicle.modelName}
              </span>
            ))}
        </span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "purchaseChallan",
      key: "purchaseChallan",
      render: (record) => <span>{record.vehicleDetail?.length}</span>,
    },
    {
      title: "Supplier",
      dataIndex: "purchaseChallan",
      key: "supplier",
      render: (record) => <span>{record && record?.supplier?.name}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {
            modify ?
            <span
              onClick={(event) => {
                event.stopPropagation();
                // setModifyAccess(true)
                history.push({
									pathname: `/autoadmin/vehicle_purchase_invoice/${data.id}`,
									state: {
										editable: true,
									},
								});
              }}
              className="linkylink"
            >
              Modify
            </span>: <span></span>
          }
          {
            deleteAccess ?
              <Popconfirm
                title="Do you want to delete this Record?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  props.delete(data.id);
                }}
              >
                <span
                  onClick={(event) => event.stopPropagation()}
                  className="linkylink"
                >
                  <Divider type="vertical" />
                  Delete
            </span>
              </Popconfirm>
              :
              <span></span>
          }
          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }
        </div>
      ),
    },
  ];

  return (
		<Table
			rowKey={(record) => record.id}
			pagination={pagination}
			columns={column}
			style={{ cursor: "pointer" }}
			tableLayout="fixed"
			dataSource={dataSource}
			onRow={(props) => ({
				onClick: () => {
					history.push({
						pathname: `/autoadmin/vehicle_purchase_invoice/${props.id}`,
						state: {
							editable: false,
						},
					});
					// history.push(`/autoadmin/sale_spares_invoice/${record.id}`)
				},
			})}
			// onRow={(props) => ({
			// 	onClick: () => openModal(props.id, false),
			// })}
			loading={spinner}
		/>
	);
}
