import React, { useState, useEffect } from "react";
import {
    Form,
    Modal,
    Input,
    Upload,
    Icon,
    Typography,
    Row,
    message,
    Col,
    Spin
} from "antd";

// import "./index.less";
import imagePlaceHolder from "../../../../../../assets/vehiclelogo.png"

const { Title, Text } = Typography;
const { Item } = Form;

const ImageViewModal = ({
    form,
    open,
    close,
    viewData,
    editable
}) => {
    const { getFieldDecorator } = form;
    const [url, setUrl] = useState(null);
    useEffect(() => {
        if (viewData) {
            console.log("Data", viewData)
            setUrl(viewData.url);
            form.setFieldsValue({
                color: viewData.color,
                code: viewData.code,
            });
        }
    }, [viewData]);
    const clearFields = () => {
        setUrl();
        form.resetFields();
    };
    return (
        <div>
            <Modal
                wrapClassName="vehicle-form"
                layout="horizontal"
                visible={open}
                title={<Title level={4}>Vehicle Image Modal</Title>}
                onCancel={() => {
                    clearFields();
                    close();
                }}
                okButtonProps={
                    !editable && { style: { display: "none" } }
                }
                okText={"Save"}
                cancelText={editable ? "Cancel" : "Close"}
            >
                <Form>
                    <Row type="flex" justify="space-around" align="middle">
                        <Col span={8}>
                            <Item>
                                {
                                    url && (
                                        <img width="100%" src={url ? url : imagePlaceHolder} />
                                    )
                                }
                            </Item>
                        </Col>

                        <Col span={12}>
                            <Row>
                                <Item
                                    label={<Text>Color Name</Text>}
                                    colon={false}
                                >
                                    {getFieldDecorator("color", {
                                        rules: [{ required: true, message: "Enter Color Name" }],
                                    })(
                                        <Input
                                            disabled={true}
                                            placeholder="Color Name"
                                        />
                                    )}
                                </Item>
                            </Row>
                            <Row>
                                <Item
                                    label={<Text>Color Code</Text>}
                                    colon={false}
                                >
                                    {getFieldDecorator("code", {
                                        rules: [{ required: true, message: "Enter Color Code" }],
                                    })(
                                        <Input
                                            disabled={true}
                                            placeholder="Color Code"
                                        />
                                    )}
                                </Item>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Modal>
        </div>
    )
}
const WrappedImageViewModal = Form.create()(
    ImageViewModal
);

export default WrappedImageViewModal;