import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Table,
  Empty,
  Typography,
  Icon,
  Popconfirm,
  Divider,
  Button,
  message,
} from "antd";
import FormItem from "antd/lib/form/FormItem";
import _ from "lodash";
import { platformApi } from "../../../../../api";
import { formatValue } from "../../../../../utils"

const { Text } = Typography;

function SupplierContacts({
  form,
  validityRef,
  data,
  clearRef,
  editable,
  values,
  setData,
  error,
  setError,
  delData,
  setDelData
}) {
  const { getFieldDecorator } = form;
  const [dataSource, setDataSource] = useState([]);
  const [editPhone, setEditPhone] = useState(null);
  // const [error, setError] = useState({});
  const name = form.getFieldsValue().name
  const designation = form.getFieldsValue().designation
  const number = form.getFieldsValue().number

  if (name == undefined || name == null) {
    delete error.NAME
  }
  if (designation == undefined || designation == null) {
    delete error.DESIGNATION
  }
  if (number == undefined || number == null) {
    delete error.PNO
  }

  useEffect(() => {
    if (data.contact) {
      console.log(data.contact)
      setDataSource(data.contact);
    }
    clearRef.current = () => {
      form.resetFields();
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  }, []);


  const validatePage = () => {
    setError({});
    return true;
  };

  const addData = () => {
    form.validateFields((validationError) => {
      if (validationError === null && !error.PNO) {
        let dataObj = form.getFieldsValue();
        dataObj.valid = true;
        dataObj.whatsapp = true;
        if (values) {
          dataObj.id = "";
        }
        let dataContacts = dataSource;
        if (editPhone) {
          let index = 0;
          dataContacts.map((e, i) => {
            if (e.id === editPhone) {
              index = i;
            }
          });
          dataObj.id = editPhone;
          dataContacts[index] = dataObj;
          setEditPhone(null);
        } else {
          dataContacts.push(dataObj);
        }

        setDataSource([]);
        setDataSource(dataContacts);
        let dataNew = data;
        dataNew.contact = dataContacts;
        setData([]);
        setData(dataNew);
        form.setFieldsValue({
          name: undefined,
          designation: undefined,
          number: undefined,
        });
      }
    });
  };

  const deleteData = (dataDelete) => {
    if (!dataDelete.id) {
      setDataSource([]);
      const dataContacts = dataSource.filter(
        (d) => d.number !== dataDelete.number
      );
      setDataSource(dataContacts);
      let dataNew = data;
      dataNew.contact = dataContacts;
      setData([]);
      setData(dataNew);
    } else {
      // let temp = dataSource;
      let dataContacts = dataSource.filter(
        (data) => data.number !== dataDelete.number
      );
      setDataSource(dataContacts);
      setDelData([dataDelete]);
      let dataNew = data;
      dataNew.contact = dataContacts;
      setData([]);
      setData(dataNew);
      // platformApi.delete(`/api/supplier/phone/${dataDelete.id}`).then((res) => {
      //   if (res.data.response.code === 200) {
      //     setDataSource([]);
      //     let dataContacts = dataSource.filter(
      //       (data) => data.number !== dataDelete.number
      //     );
      //     setDataSource(dataContacts);
      //     let dataNew = data;
      //     dataNew.contact = dataContacts;
      //     setData([]);
      //     setData(dataNew);
      //   } else {
      //     message.error("Phone number is not deleted");
      //   }
      // });
    }
  };

  const column = [
    {
      title: <Text strong>Name</Text>,
      dataIndex: "name",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Designation</Text>,
      dataIndex: "designation",
      key: "designation",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Phone Number</Text>,
      dataIndex: "number",
      key: "phone",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Valid</Text>,
      dataIndex: "valid",
      key: "valid",
      render: (record) => (record ? "yes" : "no")
    },
    {
      title: <Text strong>Whatsapp</Text>,
      dataIndex: "whatsapp",
      key: "whatsapp",
      render: (record) => (record ? "yes" : "no"),
    },
    {
      title: <Text strong>Action</Text>,
      render: (data) => (
        <div>
          {data.id ? (
            <span>
              <Icon
                onClick={(event) => {
                  event.stopPropagation();
                  setEditPhone(data.id);
                  form.setFieldsValue({
                    number: data.number,
                    designation: data.designation,
                    name: data.name,
                  });
                }}
                type="edit"
              />
              <Divider type="vertical" />
            </span>
          ) : (
              <span />
            )}
          <Popconfirm
            title="Do you want to delete this Mobile Number?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              // let temp = dataSource.filter((val) => val.phone !== data.phone);
              // setDataSource(temp);
              // setDelData([data]);
              deleteData(data);
            }}
          >
            <Icon type="delete" onClick={(event) => event.stopPropagation()} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        locale={{
          emptyText: (
            <Empty
              imageStyle={{ height: 25, fontSize: 30 }}
              description={
                <Text disabled strong>
                  No Contacts
                </Text>
              }
              image={<Icon type="contacts" />}
            />
          ),
        }}
        rowKey={(record) => record.id}
        pagination={false}
        size="small"
        columns={column.filter(
          (columns) => columns.title.props.children !== "Action" || !editable
        )}
        style={{ cursor: "pointer", marginTop: "5vh" }}
        dataSource={dataSource}
      />
      <Form
        style={{
          marginTop: "3vh",
          marginBottom: "3vh",
          display: !editable ? "" : "none",
        }}
      >
        <Row gutter={16}>
          <Col span={7}>
            <Form.Item
              validateStatus={error.NAME && error.NAME.type}
              help={error.NAME && error.NAME.message}
              label="Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Enter your Name!",
                  },
                ],
              })(
                <Input
                  pattern="^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      name: formatValue(e, "allCaps")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        NAME: {
                          type: "error",
                          message: "Enter Valid Name"
                        }
                      })
                    } else {
                      delete error.NAME
                      setError(error)
                    }
                  }}
                  maxLength={30}
                  placeholder="Name"
                  disabled={editable} />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              validateStatus={error.DESIGNATION && error.DESIGNATION.type}
              help={error.DESIGNATION && error.DESIGNATION.message}
              label="Designation">
              {getFieldDecorator("designation", {
                rules: [
                  {
                    required: false,
                    message: "Enter your Designation!",
                  },
                ],
              })(
                <Input
                  pattern="^^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      designation: formatValue(e, "allCaps")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        DESIGNATION: {
                          type: "error",
                          message: "Enter Valid Designation"
                        }
                      })
                    } else {
                      delete error.DESIGNATION
                      setError(error)
                    }
                  }}
                  maxLength={30}
                  placeholder="Designation"
                  disabled={editable}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              label="Phone Number"
              validateStatus={error.PNO && error.PNO.type}
              help={error.PNO && error.PNO.message}
              colon={false}
              required
            >
              {getFieldDecorator("number", {
                rules: [
                  {
                    required: true,
                    message: "Enter your Phone Number!",
                  },
                ],
              })(
                <Input
                  placeholder="Phone Number"
                  disabled={editable}
                  onWheel={(event) => event.currentTarget.blur()}
                  addonBefore="+91"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      number: formatValue(e, "onlyNo")
                    })}
                  pattern="^[0-9]{10}$"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        PNO: {
                          type: "error",
                          message: "Enter Valid Phone Number",
                        },
                      });
                    } else {
                      delete error.PNO;
                      setError(error);
                    }
                  }}
                  maxLength={10}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={2}>
            <Button
              type="primary"
              style={{ marginTop: "42px" }}
              onClick={() => { !error.DESIGNATION && !error.NAME ? addData() : message.error("Enter Proper Inputs") }}
            >
              Save
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

const WrappedSupplierContactsForm = Form.create({
  name: "SupplierContactsForm",
})(SupplierContacts);
export default WrappedSupplierContactsForm;
