import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import {
  Button,
  Input,
  Typography,
  Dropdown,
  Tooltip,
  Row,
  Menu,
  Col,
  Icon,
  message,
} from "antd";
import { Tabs } from "antd";
const { TabPane } = Tabs;
import { LeftOutlined, MessageOutlined, UserOutlined, HistoryOutlined,UserDeleteOutlined } from "@ant-design/icons";

import "./index.less";
import WrappedForm from "./EmployeeForm";
import EmployeeTable from "./EmployeeTable";
import InActiveEmployeeTable from "./EmployeeTable/inactiveTable";

import { platformApi } from "../../../api";
import WrappedAdvancedFilter from "./AdvancedFilters";
import { ContextAPI } from "../../../ContextAPI";
import { setConfig } from "react-hot-loader";

import WrappedEmployeeTerminate from "./EmployeeTerminate";
const { Search } = Input;
const { Title } = Typography;
const { Item } = Menu;
const Employee = (props) => {
  const [visible, setVisible] = useState(false);
  const [limit, setLimit] = useState(10);
  const [dataSource, setdataSource] = useState([]);
  const [activedataSource, setActivedataSource] = useState([]);
  const [inActivedataSource, setInActivedataSource] = useState([]);

  const [filteropen, setFilteropen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [empId, setEmpId] = useState("");
  const [loggedIn, setLoggedIn] = useState("");
  const [filter, setFilter] = useState([]);
  const [search, setSearch] = useState("");
  const [spinning, setSpinning] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [createAccess, setCreateAccess] = useState(false);
  const [printAccess, setPrintAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const [assign, setAssign] = useState(false);
  const [assignData, setAssignData] = useState(-1);
  const [newEmployee, setNewEmployee] = useState('')
  useEffect(() => {
    setPrimaryData();
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "employee") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.print) {
            setPrintAccess(true);
          }
        }
      });
  }, []);

  useEffect(() => {
    setPrimaryData(search);
  }, [page, limit, search]);

  const setEmployeeId = () => {
    platformApi.post("/api/idGenerate/employee").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        setEmpId(response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
  };
  const setPrimaryData = (string) => {
    setSpinning(true)
    platformApi
      .post("/api/user/get", {
        branch: loginCredintials.branch.id,
        searchString: string,
      })
      .then((res) => {
        setSpinning(false)
        const { data } = res;
        if (data.code === 200) {


          let activeUserData = [...data.response.data.users];
          activeUserData = activeUserData.filter(i => i.status == true)
          let inactiveData = [...data.response.data.users];
          inactiveData = inactiveData.filter(i => i.status == false)
          setActivedataSource(activeUserData)
          setInActivedataSource(inactiveData)
          setdataSource(data.response.data.users);
          setLoggedIn(data.response.data.user);
          setCount(data.response.data.count);
        }
      })
      .catch((err) => {
        setSpinning(false)
        console.error("User Fetch Error: ", err);
        message.error("Couldn't Fetch Employees");
      });
  };

  const handleTabChange = (activeKey) => {
    console.log({activeKey});
    setSearch('');
    setPage(1);
    setLimit(10);
  };

  const getMenu = () => {
    let size = count / 10 + (count % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  return (
		<div>
			<div className='accessories-head'>
				<Title style={{ width: '60%' }} level={4}>
					<Tooltip placement='topLeft' title={'Back to Company Master'}>
						<Button
							style={{ margin: '0 20px' }}
							onClick={() => props.history.push('/autoadmin/company')}
						>
							<LeftOutlined />
						</Button>
					</Tooltip>
					Employee [{count}]
					<span style={{ marginLeft: '1rem' }}>
						<Dropdown overlay={menu} trigger={['click']}>
							<Button>
								{limit}
								&nbsp;
								<Icon type='down' />
							</Button>
						</Dropdown>
					</span>
				</Title>
				<Search
					placeholder='Search Employee'
					className='searchbar-div-manufacturer'
					style={{ width: '30%' }}
					onSearch={(event) => {
						setPage(1);
						setSearch(event);
					}}
					onChange={(event) => {
						if (event.target.value === '') {
							setSearch(null);
							setPage(1);
						}
					}}
				/>
				{/* <div style={{ width: "20%", textAlign: "center" }}>
          <Button
            type="link"
            onClick={() => {
              filteropen ? setFilteropen(false) : setFilteropen(true);
            }}
          >
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button>
        </div> */}
				<Button
					type='primary'
					className='add-manufacturer-button'
					onClick={() => {
						setEmployeeId();
						setVisible(true);
						setModalData(null);
					}}
					disabled={!createAccess}
					style={{ margin: '0 3%', width: '20%' }}
				>
					Add Employee
				</Button>
			</div>
			<WrappedForm
				empId={empId}
				setEmpId={setEmpId}
				visible={visible}
				modalData={modalData}
				setModalData={setModalData}
				setVisible={setVisible}
				setdataSource={setdataSource}
				dataSource={dataSource}
				loginCredintials={loginCredintials}
				setCount={setCount}
				count={count}
				setAssign={setAssign}
				setAssignData={setAssignData}
				newEmployee={newEmployee}
			/>
			<div>
				<WrappedAdvancedFilter
					filteropen={filteropen}
					searchName={search}
					close={setFilteropen}
					id={filter}
					setId={setFilter}
					setData={setdataSource}
					setPrimaryData={setPrimaryData}
				/>

				{/* new update */}

				<Tabs defaultActiveKey='1' onChange={handleTabChange}>
					<TabPane
						tab={
							<span>
								<UserOutlined />
								Active Employee
							</span>
						}
						key='1'
					>
						<EmployeeTable
							pagination={{
								onChange: (page) => {
									setPage(page);
								},
								pageSize: limit,
								defaultCurrent: 1,
								current: page,
								total: activedataSource.length,
								showQuickJumper: true,
							}}
							spinner={{
								spinning,
							}}
							dataSource={activedataSource}
							setModalData={setModalData}
							setVisible={setVisible}
							setdataSource={setActivedataSource}
							setCount={setCount}
							count={activedataSource.length}
							setPrimaryData={setPrimaryData}
							loggedIn={loggedIn}
							printAccess={printAccess}
						/>
					</TabPane>
					<TabPane
						tab={
							<span>
								<UserDeleteOutlined />
								InActive Employee
							</span>
						}
						key='2'
					>
						<InActiveEmployeeTable
							pagination={{
								onChange: (page) => {
									setPage(page);
								},
								pageSize: limit,
								defaultCurrent: 1,
								current: page,
								total: inActivedataSource.length,
								showQuickJumper: true,
							}}
							spinner={{
								spinning,
							}}
							dataSource={inActivedataSource}
							setModalData={setModalData}
							setVisible={setVisible}
							setdataSource={setInActivedataSource}
							setCount={setCount}
							count={inActivedataSource.length}
							setPrimaryData={setPrimaryData}
							loggedIn={loggedIn}
							printAccess={printAccess}
						/>
					</TabPane>
				</Tabs>

				{/* end new update */}

				<WrappedEmployeeTerminate
					data={dataSource[dataSource.findIndex((obj) => assignData === obj.id)]}
					modify={false}
					open={assign}
					close={() => {
						setAssign(false);
						setAssignData(null);
					}}
					editable={true}
					employee={dataSource.filter((obj) => assignData !== obj.id && obj.status === true)}
					setNewEmployee={setNewEmployee}
				/>
			</div>
		</div>
	);
};
export default withRouter(Employee);
