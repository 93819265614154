import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs } from "antd";
import { ContextAPI } from "../../../ContextAPI";
import { platformApi } from '../../../api';
import EstimateTable from './EstimateTable';
const { Item } = Menu;
const { Search } = Input;
const { Title } = Typography;
const { TabPane } = Tabs;
const Estimate = () => {
    const history = useHistory();
    const [editFlag, setEditFlag] = useState(false);
    const [selected, setSelected] = useState(-1);
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [spinning, setSpinning] = useState(false);
    const [editable, setEditable] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [createAccess, setCreateAccess] = useState(false);
    const [modifyAccess, setModifyAccess] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);
    const [printAccess, setPrintAccess] = useState(false);
    const [tab, setTab] = useState("PENDING")
    const [active, setActive] = useState("1")
    const { loginCredintials } = useContext(ContextAPI)
    const [gst, setGst] = useState();

    useEffect(() => {
        setGst(loginCredintials.branch[0].gst)
        loginCredintials.roleAccess &&
            loginCredintials.roleAccess.map((each) => {
                if (each.subModule === 'estimate') {
                    if (each.access.create) {
                        setCreateAccess(true);
                    }
                    if (each.access.update) {
                        setModifyAccess(true);
                    }
                    if (each.access.delete) {
                        setDeleteAccess(true);
                    }
                    if (each.access.print) {
                        setPrintAccess(true);
                    }
                }
            })
    }, []);
    useEffect(() => {
        setPrimaryData(search);
    }, [page, limit, search]);

    const getMenu = () => {
        let size = count / 10 + (count % 10) ? 1 : 0;
        const menus = [];
        menus.push(10);
        size = size <= 10 ? size : 10;
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
                {menu}
            </Item> 
        ));
    };
    const menu = <Menu>{getMenu()}</Menu>;

    const setPrimaryData = (search, status, val) => {
        if (!status) {
            status = tab
        }
        setSpinning(true)
        platformApi
            .post("/api/estimate/get", {
                page: val ? val : page,
                size: limit,
                searchString: search,
                status,
            })
            .then((result) => {
                setSpinning(false);
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        console.log(response.data)
                        console.log(response.data)
                        setDataSource(response.data.Estimate);
                        setCount(response.data.count);
                    } else {
                        message.error("Unable to fetch Estimates", 2);
                    }
                } else {
                    message.error("Unable to fetch Estimates", 2);
                }
            })
            .catch((error) => {
                console.error("Error on Vehicle Estimates : ", error);
                message.error("Unable to fetch Estimates", 2);
            });
    };

    const handleTabChange = (activeKey) => {
        switch (activeKey) {
            case "1":
                setPrimaryData(null, "PENDING")
                setTab("PENDING")
                setPage(1)
                setActive("1")
                break;
            case "2":
                setPrimaryData(null, "APPROVED")
                setTab("APPROVED")
                setPage(1)
                setActive("2")
                break;
            case "3":
                setPrimaryData(null, "REJECTED")
                setTab("REJECTED")
                setPage(1)
                setActive("3")
                break;
            default:
                // setPrimaryData(null, null)
                setTab("PENDING")
                setPage(1)
                setActive("4")
        }
    }
    const deleteData = (id) => {
        setSpinning(true)
        let temp = page != 1 && dataSource.length === 1;
        platformApi.delete("/api/estimate/" + id).then(result => {
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    message.success("Estimate Deleted Successfully")
                    if (temp) {
                        setPage(1)
                        setPrimaryData(null, 1)
                    }
                    else {
                        setPrimaryData()
                    }
                }
                else {
                    setSpinning(false)
                    message.error("Couldn't Remove Estimate");
                }
            }
            else {
                setSpinning(false)
                message.error("Couldn't Remove Estimate");
            }
        }).catch((err) => {
            setSpinning(false)
            console.error("Estimate Delete Error: ", err);
            message.error("Unable to Delete Estimate");
        });
    }
    return (
        <div>
            <div className="accessories-head">
                <Title style={{ width: "60%" }} level={4}>
                    Estimate [{count}]
                  <span style={{ marginLeft: "1rem" }}>
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <Button>
                                {limit} &nbsp;
                    <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </span>
                </Title>
                <Search
                    style={{ width: "30%" }}
                    placeholder="Search Estimate"
                    onSearch={(event) => {
                        console.log("event", event)
                        setPage(1)
                        setSearch(event);
                    }}
                    onChange={(event) => {
                        if (event.target.value === "") {
                            setSearch(null)
                            setPage(1)
                        }
                    }}
                />
                <Button
                    onClick={() => {
                        history.push({
                            pathname: '/autoadmin/estimate/:id',
                            state: {
                                gst: gst 
                            }
                        })
                    }}
                    style={{ margin: "0 3%", width: "20%" }}
                    type="primary"
                    disabled={!createAccess}
                >
                    Add Estimate
                </Button>
            </div>

            <Tabs activeKey={active} onChange={handleTabChange}>
                <TabPane
                    tab={
                        <span>
                            Pending
                    </span>
                    }
                    key="1"
                >
                    {/* {setPrimaryData(null, "ALL")} */}
                    <EstimateTable
                        // tab={0}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            defaultCurrent: 1,
                            current: page,
                            total: count,
                            showQuickJumper: true,
                        }}
                        gst={gst}
                        dataSource={dataSource}
                        spinner={spinning}
                        modify={modifyAccess}
                        redirectPage={(id, editable) => {
                            setSelected(id);
                            setRedirect(true);
                            setEditable(editable);
                        }}
                        deleteData={deleteData}
                        deleteAccess={deleteAccess}
                        printAccess={printAccess}
                    />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            Approved
                        </span>
                    }
                    key="2"
                >
                    {/* {setPrimaryData(null, "ACTIVE")} */}
                    <EstimateTable
                        // tab={1}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            defaultCurrent: 1,
                            current: page,
                            total: count,
                            showQuickJumper: true,
                        }}
                         gst={gst}
                        dataSource={dataSource}
                        spinner={spinning}
                        modify={modifyAccess}
                        redirectPage={(id, editable) => {
                            setSelected(id);
                            setRedirect(true);
                            setEditable(editable);
                        }}
                        deleteData={deleteData}
                    />
                </TabPane>
                <TabPane
                    tab={
                        <span>
                            Rejected
                        </span>
                    }
                    key="3"
                >
                    {/* {setPrimaryData(null, "ACTIVE")} */}
                    <EstimateTable
                        // tab={2}
                        pagination={{
                            onChange: (page) => {
                                setPage(page);
                            },
                            pageSize: limit,
                            defaultCurrent: 1,
                            current: page,
                            total: count,
                            showQuickJumper: true,
                        }}
                        gst={gst}
                        dataSource={dataSource}
                        spinner={spinning}
                        modify={modifyAccess}
                        redirectPage={(id, editable) => {
                            setSelected(id);
                            setRedirect(true);
                            setEditable(editable);
                        }}
                        deleteData={deleteData}
                    />
                </TabPane>
            </Tabs>

        </div>
    )
}
export default Estimate;