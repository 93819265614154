import React, { useState, useContext, useEffect } from "react";
import { Form, Icon, Input, Button, Checkbox, Alert, message } from "antd";

import { platformApi, setAccessToken } from "../../api";
import "./login.less";
import { ContextAPI } from "../../ContextAPI"; import { } from "react";
import logo from "../../assets/logo-04.png"
import { useBranch } from "../../ContextAPI/branch";

const Login = ({ form, history }) => {
  const [loader, setLoader] = useState(false);
  const { setLoginCredintials } = useContext(ContextAPI);
  const { setSelectBranch }= useBranch()

  useEffect(() => {
    platformApi.get("/api/user/count").then(res => {
      if (!res.data.count) {
        history.push("/get-started")
      }
    })
    if (localStorage.getItem('token')) {
      history.push('/autoadmin')
    }
  }, [])

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      const username = form.getFieldValue("username");
      const password = form.getFieldValue("password");

      if (validationError === null) {
        setLoader(true);
        platformApi
          .post("/api/user/login", { phone: username, password })
          .then((res) => {
            setLoader(false);
            let { data } = res;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                setAccessToken(response.data.token);
                let obj = response.data.user.department;
                platformApi.get("/api/user/currentUser").then((result) => {
                  let { data } = result;
                  if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                      if (response.data.profile)
                        obj = { ...obj, ...response.data.profile, employeeId: response.data.id }
                      obj.profilePicture = response.data.profilePicture
                      obj.phone2 = response.data.phone2
                      let branch = obj.branch[0];
                      branch = [branch]
                      setSelectBranch(branch)
                      setLoginCredintials(obj);
                      history.push("/autoadmin");
                    }
                  }
                });
              } else if (response.code === 403) {
                message.error("Invalid Username or Password", 1);
              } else if (response.code === 404) {
                message.error('This User is no Longer Active', 2)
              } else {
                message.error("Connection Error", 1);
              }
            }
          })
          .catch((err) => {
            setLoader(false);
            message.error("Connection Error", 1);
            console.error("Error on Authentication : ", err);
          });
      }
    });
  };

  return (
    <div className="login">
      <div className="loginform">
        <div className="login__foreground">
          <span><img style={{ width: '100%' }} src={logo}></img></span>
          <div className="login__title">
            <span>Login</span>
          </div>
        </div>
        <div className="login-form">
          <Form className="login-form">
            <Form.Item>
              {form.getFieldDecorator("username", {
                rules: [
                  { required: true, message: "Please type your username!" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Mobile"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item style={{ marginTop: "1rem" }}>
              {form.getFieldDecorator("password", {
                rules: [
                  { required: true, message: "Please type your Password!" },
                ],
              })(
                <Input.Password
                  prefix={
                    <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Password"
                  size="large"
                />
              )}
            </Form.Item>
            <Form.Item>
              {form.getFieldDecorator("remember", {
                valuePropName: "checked",
                initialValue: true,
              })(<Checkbox>Remember me</Checkbox>)}
              <Button type="link" className="login-form-forgot" href="/forgotpassword">
                Forgot password
                </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={() => handleSubmit()}
                size="large"
                loading={loader}
              >
                Sign In
                </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
};

const WrappedLoginForm = Form.create({ name: "login" })(Login);
export default WrappedLoginForm;
