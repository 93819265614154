import React, { useState } from "react";
import {
  Table,
  Popconfirm,
  Input,
  Select,
  DatePicker,
  Divider,
  Icon,
  Row,
  Col,
  Form,
  message,
} from "antd";
import { useEffect } from "react";
import moment from "moment";
import "./index.less";
import { formatValue } from "../../../../../../../utils";
import { platformApi } from "../../../../../../../api";

const { Item } = Form;
function ChallanTable({
  toDelete,
  dataSource,
  delete: deleteData,
  loading,
  editData,
  clearRef,
  addVehicle,
  error,
  setError,
  open,
  form,
  manufacturer,
  setLocationDisabled
}) {

  const [chassisNoValidation, setChassisNoValidation] = useState({});
  const [engineNoValidation, setEngineNoValidation] = useState({});

  const [data, setData] = useState([]);
  const [colors, setColor] = useState([]);
  // const [manufacturer, setManufacturer] = useState([]);
  const [vehicles, setVehicle] = useState([]);
  const [vehiclecolor, setVehiclecolor] = useState([]);
  const [manId, setManId] = useState(null)
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const chassisNoPattern = /^[A-Z0-9]{17}$/
  const engineNoPattern = /^[a-zA-Z0-9]{5}$/

  useEffect(() => {
    clearRef.current = () => {
      setColor([]);
      // setManufacturer([]);
      setVehiclecolor([]);
      setVehicle([]);
      setData([]);
      resetFields();
    };
    return () => {
      clearRef.current = null;
    };
  });

  const handleChange = (event, index, type) => {
    let data = dataSource;
    //to restrict special characters
    if (event.target) {
      let { target: { value } } = event;
      value = formatValue(event, "code")
      data[index][type] = value;
    }
    else {
      data[index][type] = event;
    }
    // editData([...data]);
    setData([...data])
  };

  const validateChassisNo = ( record, chassisNo, index) => {
    if(record.vehicle?.id == null) return;
    const vehicleId = record.vehicle.id
    if (!chassisNoPattern.test(chassisNo)) return setChassisNoValidation(prev => ({...prev, [vehicleId] : "Enter Valid Chassis Number"}));
    let arr = data;
    // platformApi.post(`/api/purchaseChallan/frameNumber`, { chassisNo, manufacturer: manId }).then((result) => {
    //   let { data } = result;
    //   if (data.code === 200) {
    //     let { response } = data;
    //     if (response.code === 200) {
    //       const date = new Date(response.data).toLocaleDateString("en-us", {
    //         month: "short",
    //         year: "numeric",
    //       });
    //       handleChange(date, index, "manMonthYear");
    //       if (index === dataSource.length - 1) {
    //         addVehicle()
    //       }
    //       setChassisNoValidation(prev => ({...prev, [vehicleId] : false}));
    //     }
    //     else if (response.code === 401) {
    //       setChassisNoValidation(prev => ({...prev, [vehicleId] : "Chassis Number Already Exists"}));
    //     }
    //   }
    // })
    platformApi.get(`/api/purchaseInvoice/checkChassisNo/${chassisNo}`).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.data === true) {
          setChassisNoValidation(prev => ({...prev, [vehicleId] : "Chassis Number Already Exists"}));
        }
        else  {
          setChassisNoValidation(prev => ({...prev, [vehicleId] : false}));
        }
      }
    })
  }

  const validateEngineNo = ( record, engineNo, index) => {
    if(record.vehicle?.id == null) return;
    const vehicleId = record.vehicle.id
    if(!engineNoPattern.test(engineNo)) return setEngineNoValidation(prev => ({...prev, [vehicleId]: "Invalid Engine No"}));
    platformApi
      .post(`/api/vehicle/checkEngineNo`, { engineNo })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if(response.code === 200) setEngineNoValidation(prev => ({...prev, [vehicleId]: false}));
          else setEngineNoValidation(prev => ({...prev, [vehicleId]: "Engine Number already exists"}))
        }
      });
  }

  useEffect(() => {
    if (data.length > 1) {
      setLocationDisabled(true)
    }
    else {
      setLocationDisabled(false)
    }

  }, [data])

  useEffect(() => {
    setData(dataSource);

    if (
      dataSource &&
      dataSource[0] &&
      dataSource[0].vehicle &&
      dataSource[0].vehicle.manufacturer &&
      dataSource[0].vehicle.manufacturer.id
    ) {
      let Color = colors;
      setFieldsValue({ manufacturer: dataSource[0].vehicle.manufacturer.name });
      handleManufacturerChange(dataSource[0].vehicle.manufacturer.id);
      dataSource.forEach((datum, index) => {
        Color[index] = datum.vehicle.image;
        setColor([...Color]);
      });
    }
  }, [dataSource]);


  const handleManufacturerChange = (id) => {
    // let vehicle = vehicles;
    setManId(id)
    let models = [];
    platformApi.get(`/api/vehicleMaster/man/${id}`).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setVehicle(response.data);
          response.data.map((model) => {
            if (model.vehicleStatus === "AVAILABLE") {
              models.push(model);
            }
          });
          setVehicle(models);
        }
      }
    });
  };
  const handleMonthChange = (id, index) => {
    const date = new Date(id).toLocaleDateString("en-us", {
      month: "short",
      year: "numeric",
    });
    handleChange(date, index, "manMonthYear");
  };
  const handleModelChange = (id, index) => {
    let Color = colors;
    vehicles.some((vehicle) => {
      if (vehicle.id === id) {
        handleChange(vehicle, index, "vehicle");
        Color[index] = vehicle.image;
        setColor([...Color]);
      }
    });
  };
  const handleColorChange = (id, index) => {
    vehiclecolor.some((color) => {
      if (color.id === id) {
        handleChange(color, index, "color");
      }
    });
  };
  const disableMonth = (current) => {
    const date = moment(Date.now());
    if (date <= current) {
      return true;
    } else return false;
  };

  const column = [
    {
      title: "Model",
      dataIndex: "vehicle",
      key: "vehicle",
      width: 200,
      render: (data, record, index) => (
        <Select
          className="ChallanTable-Manufacturers"
          dropdownStyle={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
          onChange={(event) => {
            handleModelChange(event, index);
          }}
          showSearch
          defaultValue={
            data && data.modelName ? data.modelCode + " - " + data.modelName : undefined
          }
          disabled={!toDelete || !(vehicles.length >= 1)}
          placeholder="Model"
          filterOption={(input, option) =>
            option.props.children.some(
              (child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
          }
        >
          {vehicles.map((vehicle) => (
            <Select.Option key={vehicle.id}>
              {vehicle.modelCode} - {vehicle.modelName}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      width: 100,
      render: (data, record, index) => (
        <Select
          className="ChallanTable-Manufacturers"
          dropdownStyle={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
          onFocus={() => {
            setVehiclecolor(colors[index]);
          }}
          onChange={(event) => {
            handleColorChange(event, index);
          }}
          disabled={!toDelete || !colors[index]}
          placeholder="Color"
          defaultValue={record.color ? record.color.color : undefined}
        >
          {vehiclecolor.map((color) => (
            <Select.Option key={color.id} value={color.id}>
              {color.color}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: " Chassis No",
      dataIndex: "chassisNo",
      key: "chassisNo",
      width: 160,
      render: (data, record, index) => (
        <Form.Item
          validateStatus={`${chassisNoValidation[record.vehicle.id] ? "error" : "success"}`}
          help={chassisNoValidation[record.vehicle.id]}
				>
          <Input
            style={{ textTransform: "uppercase" }}
            key="chassisNo"
            pattern="^[A-Z0-9]{17}$"
            onInput={(event) => {
              handleChange(event, index, "chassisNo");
              validateChassisNo(record, event.target.value, index);
            }}
            onWheel={(event) => event.currentTarget.blur()}
            maxLength={17}
            // disabled={!toDelete}
            disabled={!toDelete || !(vehicles.length >= 1)}
            placeholder="Chassis No"
            value={data}
          />
        </Form.Item>
      ),
    },
    {
      title: "Engine No",
      dataIndex: "engineNo",
      key: "engineNo",
      width: 160,
      render: (data, record, index) => (
        <Form.Item
          validateStatus={`${engineNoValidation[record.vehicle?.id] ? "error" : "success"}`}
          help={engineNoValidation[record.vehicle?.id]}
				>
          <Input
            style={{ textTransform: "uppercase" }}
            key="engineNo"
            onChange={(event) => {
              handleChange(event, index, "engineNo");
              // validateEngineNo(record, event.target.value, index)
            }}
            maxLength={17}
            onWheel={(event) => event.currentTarget.blur()}
            disabled={!toDelete}
            placeholder="Engine No"
            value={data}
          />
        </Form.Item>
      ),
    },
    {
      title: "Key no",
      dataIndex: "keyNo",
      key: "keyNo",
      width: 160,
      render: (data, record, index) => {
        return <Input
          style={{ textTransform: "uppercase" }}
          key="keyNo"
          onChange={(event) => {
            handleChange(event, index, "keyNo");
          }}
          onWheel={(event) => event.currentTarget.blur()}
          disabled={!toDelete}
          placeholder="Key No"
          value={data}
          maxLength={17}
        />
      },
    },
    // {
    //   title: "Quantity",
    //   dataIndex: "quantity",
    //   key: "quantity",
    //   width: 100,
    //   render: (data, record, index) => (
    //     <Form.Item>
    //       <Input
    //         key="quantity"
    //         onWheel={(event) => event.currentTarget.blur()}
    //         disabled={!toDelete}
    //         onChange={(event) => {
    //           handleChange(event, index, "quantity");
    //         }}
    //         placeholder="Quantity"
    //         value={data}
    //       />
    //     </Form.Item>
    //   )
    // },
    {
      title: "Battery No",
      dataIndex: "batteryNo",
      key: "batteryNo",
      width: 160,
      render: (data, record, index) => (
        <Input
          style={{ textTransform: "uppercase" }}
          key="batteryNo"
          onChange={(event) => {
            handleChange(event, index, "batteryNo");
          }}
          onWheel={(event) => event.currentTarget.blur()}
          disabled={!toDelete}
          value={data}
          placeholder="Battery No"
          maxLength={17}
        />
      ),
    },
    {
      title: "WRC",
      dataIndex: "warrantyBookNo",
      key: "warrantyBookNo",
      width: 160,
      render: (data, record, index) => (
        <Input
          style={{ textTransform: "uppercase" }}
          key="warrantyBookNo"
          onChange={(event) => {
            handleChange(event, index, "warrantyBookNo");
          }}
          onWheel={(event) => event.currentTarget.blur()}
          disabled={!toDelete}
          placeholder="Warranty Book No"
          value={data}
          maxLength={17}
        />
      ),
    },
    {
      title: "MFG Month",
      dataIndex: "manMonthYear",
      key: "manMonthYear",
      width: 100,
      render: (data, record, index) => (
        <DatePicker.MonthPicker
          onFocus={() => {
            // if (index === dataSource.length - 1) {
            //   addVehicle();
            // }
          }}
          onChange={(event) => {
            handleMonthChange(event, index);
          }}
          disabledDate={disableMonth}
          style={{ width: "100%" }}
          format="MMM-YYYY"
          disabled={true}
          placeholder="Month"
          value={data ? moment(data, "MMM-YYYY") : null}
        />
      ),
    },
    {
      key: "actions",
      width: 30,
      render: (data1, data2, index) =>
        toDelete && (
          <div>
            <Popconfirm
              title="Are you sure delete this Vehicle?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => { }}
              onConfirm={(event) => {
                if (
                  dataSource.length === 1 &&
                  dataSource.length - 1 === index
                ) {
                  message.error("Vehicle cannot be deleted as there is only one vehicle", 2);
                } else {
                  deleteData(data1.id, index);
                }
              }}
            >
              <Icon type="delete" />
            </Popconfirm>
          </div>
        ),
    },
  ];

  return (
    <Form>
      <span>Manufacturer</span>
      <Row>
        <Col span={4}>
          <Item>
            {getFieldDecorator("manufacturer")(
              <Select
                showSearch={true}
                disabled={!toDelete}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={handleManufacturerChange}
                initialValue={
                  data
                    ? data[0]
                      ? data[0].vehicle
                        ? data[0].vehicle.manufacturer
                          ? data[0].vehicle.manufacturer.name
                          : undefined
                        : undefined
                      : undefined
                    : undefined
                }
                placeholder="Manufacturer"
              >
                {manufacturer.map((manufacturer) => (
                  <Select.Option key={manufacturer.id} value={manufacturer.id}>
                    {manufacturer.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
      </Row>
      <span>Vehicle Details</span>
      <Table
        size="small"
        loading={loading}
        pagination={false}
        scroll={{ y: 160 }}
        className="Challantable"
        columns={column.filter((obj) => obj.title !== "Action" || toDelete)}
        style={{ cursor: "pointer" }}
        dataSource={data}
      />
    </Form>
  );
}
const WrappedChallanTable = Form.create()(ChallanTable);

export default WrappedChallanTable;
