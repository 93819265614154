import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";

const { Text } = Typography
const VehiclePurchaseChallan = ({
  dataSource,
  openModal,
  delete: deleteData,
  spinner,
  modify,
  deleteAccess
}) => {
  const column = [
    {
      title: <span> VP Challan</span>,
      dataIndex: "challanNo",
      key: "vpchallan",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <span>Vehicle List</span>,
      dataIndex: "vehicleDetail",
      key: "vehiclelist",
      render: (records) => (
        <span>
          {records &&
            records.map((record, index) => (
              <span>
                {index > 0 ? " , " : ""}
                {record.vehicle && record.vehicle.modelCode} - {record.vehicle && record.vehicle.modelName}
              </span>
            ))}
        </span>
      ),
    },
    {
      title: <span>Quantity</span>,
      dataIndex: "vehicleDetail",
      key: "qty",
      render: (record) => <span>{record && record.length}</span>,
    },
    {
      title: <span>Supplier</span>,
      dataIndex: "supplier",
      key: "supplier",
      render: (record) => <span>{record && record.name}</span>,
    },
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div>
          {
            modify ?
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  openModal(data.id, true);
                }}
                className="linkylink"
              >
                Modify
          </span>
              : <span></span>
          }
          {
            deleteAccess ?
              <Popconfirm
                title="Are you sure delete this Vehicle?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  deleteData(data.id);
                }}
              >
                <span
                  className="linkylink"
                  onClick={(event) => event.stopPropagation()}
                >
                  <Divider type="vertical" />
                  Delete
            </span>
              </Popconfirm>
              :
              <span></span>
          }
          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }



        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      columns={column}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => {
          openModal(props.id, false);
        },
      })}
      loading={spinner}
    />
  );
};

export default VehiclePurchaseChallan;
