import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs } from "antd";
import { ContextAPI } from "../../../ContextAPI";
import { platformApi } from "../../../api";
const { Title, Text } = Typography;
const { Search } = Input;
const { Item } = Menu;
import InspectionTable from "./InspectionTable"
const FinalInspection = () => {
    const history = useHistory();
    const { loginCredintials } = useContext(ContextAPI)
    const [spinning, setSpinning] = useState(false);
    const [createAccess, setCreateAccess] = useState(false);
    const [modifyAccess, setModifyAccess] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [search, setSearch] = useState('');
    const [branch, setBranch] = useState([]);

    const getMenu = () => {
        const size = count / 10 + (count % 10) ? 1 : 0;
        const menus = [];
        menus.push(10);
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item onClick={() => setLimit(menu)} key={menu}>
                {menu}
            </Item>
        ));
    };
    const menu = <Menu>{getMenu()}</Menu>;

    useEffect(() => {
        let temp = []
        loginCredintials.roleAccess &&
            loginCredintials.roleAccess.map((each) => {
                if (each.subModule === 'final_inspection') {
                    if (each.access.create) {
                        setCreateAccess(true);
                    }
                    if (each.access.update) {
                        setModifyAccess(true);
                    }
                    if (each.access.delete) {
                        setDeleteAccess(true);
                    }
                }
            })
        for (let i = 0; i < loginCredintials.branch.length; i++) {
            const element = loginCredintials.branch[i];
            temp.push(element.id)
        }
        setBranch(temp)
        setPrimaryData(search);

    }, []);


    useEffect(() => {
        setPrimaryData(search);
    }, [page, limit, branch]);


    const setPrimaryData = () => {
        setSpinning(true);
        let obj = {
            page: page,
            size: limit,
            filter: {},
            searchString: search,
            status: "Final Inspection",
            branch: branch && branch
        };
        platformApi
            .post("/api/jobOrder/get", obj)
            .then((res) => {
                setSpinning(false);
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        console.log("Response",response.data.jobOrder)
                        setDataSource(response.data.jobOrder);
                        setCount(response.data.count);
                    } else {
                        message.error("Failed to fetch Job Order");
                    }
                } else {
                    message.error("Failed to fetch Job Order");
                }
            })
            .catch((error) => {
                setSpinning(false);
                console.error("Error on Job Order : ", error);
                message.error("Failed to fetch Job Order");
            });
    }

    return (
        <div>
            <div className="accessories-head">
                <Title style={{ width: "60%" }} level={4}>
                    Final Inspection [{count}]
                  <span style={{ marginLeft: "1rem" }}>
                        <Dropdown overlay={menu} trigger={["click"]}>
                            <Button>
                                {limit} &nbsp;
                    <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </span>
                </Title>
                <Button
                    onClick={() => {
                        history.push('/autoadmin/final_inspection/:id')
                    }}
                    style={{ margin: "0 3%", width: "20%" }}
                    type="primary"
                    disabled={!createAccess}
                >
                    Add FinalInspection
                </Button>
            </div>
            <InspectionTable
                pagination={{
                    onChange: (page) => {
                        setPage(page);
                    },
                    pageSize: limit,
                    defaultCurrent: 1,
                    current: page,
                    total: count,
                    showQuickJumper: true
                }}
                dataSource={dataSource}
                spinner={{ spinning }}
            />
        </div>
    )
}
export default FinalInspection;