import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";

const { Text } = Typography;

const ChallanTable = ({pagination}) => {
  const columns = [
    {
      title: <span>Vehicle Name</span>,
      dataIndex: "vehicleDetail.vehicle",
      key: "modalName",
      render: (vehicle) => <span>{vehicle.modelName}</span>,
    },
    {
      title: <span>Registration No</span>,
      dataIndex: "vehicleDetail.booking.customer.purchasedVehicle",
      key: "registationNumber",
      render: (vehicle) => <span>{vehicle.registerNo}</span>,
    },
    {
      title: <span>Owner</span>,
      dataIndex: "vehicleDetail.booking.customer",
      key: "ownerName",
      render: (customer) => <span>{customer.name}</span>,
    },
    {
      title: <span>Actions</span>,
      render: (data) => (
        <div>
          <span
            onClick={(event) => {
              event.stopPropogation();
            }}
            className="linkylink"
          >
            Modify
          </span>

          <Divider />

          <Popconfirm
            title="Do you want to delete this Chalan?"
            okText="Yes"
            cancelText="No"
            onConfirm={(event) => {
              event.stopPropagation();
            }}
            onCancel={(event) => {
              event.stopPropagation();
            }}
          >
            <span
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              Delete
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return <Table rowKey={(record) => record.id}
  pagination={pagination} columns={columns} />;
};
export default ChallanTable;
