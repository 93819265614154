import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Input,
  Modal,
  Button,
  Select,
  Form,
  Col,
  Row,
  DatePicker,
  Checkbox,
  Tag,
  Icon,
  message,
  Tabs,
  Table
} from 'antd';
import { Link } from "react-router-dom";
import moment from 'moment'
import './index.less';
import { ContextAPI } from '../../../../../ContextAPI';
import { platformApi } from '../../../../../api';

const { Title } = Typography;
const { Item } = Form;
const { TabPane } = Tabs;
const SparesModal = (props) => {
  const { form, open, close, data, editable, branch, getPSI } = props
  const { getFieldDecorator, getFieldValue } = form;
  const [dataSource, setData] = useState(null);
  const [HistoryData, setHistoryData] = useState([]);
  const user = useContext(ContextAPI);
  useEffect(() => {
    if (data) {
      console.log("recieved", data)
      // console.log("DataSource", dataSource)
      // console.log("branch", data && data.branch)
      form.setFieldsValue({
        partName: data.partNo.partName,
        partNumber: data.partNo.partNumber,
      });
      setData(data.branch)
      platformApi.get(`/api/sparesInventory/getSparesHistory/${data.partNo.id}`)
        .then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              console.log("HistoryData", response.data)
              let historyData = []
              if (response.data && response.data.length > 0) {
                for (let i = 0; i < response.data.length; i++) {
                  let hisObj = response.data[i];
                  hisObj["linkObj"] = {}
                  if (hisObj.sparesMaterialSale) {
                    let pathId = hisObj.sparesMaterialSale.id
                    hisObj["linkObj"]["link"] = `/autoadmin/material_issue/${pathId}`
                    hisObj["linkObj"]["id"] = hisObj.sparesMaterialSale.job?.jobNo
                  } else if (hisObj.sparesSale) {
                    if (hisObj.sparesSale.jobOrder) {
                      let pathId = hisObj.sparesSale.id
                      hisObj["linkObj"]["link"] = `/autoadmin/job_invoice/${pathId}`
                      hisObj["linkObj"]["id"] = hisObj.sparesSale.invoiceNumber
                    }
                    else {
                      let pathId = hisObj.sparesSale.id
                      hisObj["linkObj"]["link"] = `/autoadmin/counter_sale/${pathId}`
                      hisObj["linkObj"]["id"] = hisObj.sparesSale.invoiceNumber
                    }
                  }
                  else if (hisObj.sparesPurchase) {
                    let pathId = hisObj.sparesPurchase.id
                    hisObj["linkObj"]["link"] = `/autoadmin/purchase_spares_invoice/${pathId}`
                    hisObj["linkObj"]["id"] = hisObj.sparesPurchase.invoiceNumber
                  }
                  historyData.push(hisObj)
                }
              }
              setHistoryData(response.data)
            }
          }
        })
        .catch((err) => {
          console.log(err);
          console.error("Couldn't get Part History: ", err);
          message.error("Couldn't get Part History");
        });
    }

  }, [open])

  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branch",
      key: "branch",
      render: (record) => { return <span>{record && record.name}</span> },
    },
    {
      title: "Physical Qty",
      render: (record, data) => {
        return (user && user.loginCredintials && user.loginCredintials.role == "CEO" ? <Input placeholder="Physical Quantity" name="phyQuantity" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.phyQuantity} /> : <span>{record.phyQuantity}</span>)
      },
    },
    {
      title: "Accounting Qty",
      render: (record, data) => {
        return (user && user.loginCredintials && user.loginCredintials.role == "CEO" ?
          <Input placeholder="Accounting Quantity" name="accQuantity" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.accQuantity} />
          : <span>{record.accQuantity ? record.accQuantity : 0}</span>)
      },
    },
    {
      title: "Bin Location",
      render: (record, data) =>
        <Input placeholder="Bin Number" name="binNum" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.binNum} />
    },
    {
      title: "Min Stock",
      render: (record) => {
        // <Input placeholder="Min Stock" value={record}/>
        return (
          <Input placeholder="Min Stock" name="minStock" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.minStock} />
        )
      },
    },
    {
      title: "Max Stock",
      render: (record) => {
        return (
          <Input placeholder="Max Stock" name="maxStock" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.maxStock} />
        )
      },
    },
    {
      title: "Reorder Level",
      render: (record) => {
        return (<Input placeholder="Reorder Level" name="reorderLevel" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.reorderLevel} />)
      },
    },
    {
      title: "Reorder Qty",
      dataIndex: "",
      key: "",
      render: (record) => {
        return (
          <Input placeholder="reorderQuantity" name="reorderQuantity" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.reorderQuantity} />
        )
      },
    },
  ]

  const editStocks = (id, e) => {
    let data = [...dataSource]
    for (let item of data) {
      if (item.sparesId == id) {
        item[e.target.name] = e.target.value
        break
      }
    }
    console.log("data", data)
    setData(data)
  }

  const handleSubmit = () => {
    for (let item of data.branch) {
      let updateData = {
        id: item.sparesId,
        binNum: item.binNum,
        reorderQuantity: item.reorderQuantity,
        maxStock: item.maxStock,
        minStock: item.minStock,
        reorderLevel: item.reorderLevel,
        phyQuantity: item.phyQuantity,
        accQuantity: item.accQuantity,
      }
      platformApi.put("/api/sparesInventory/" + updateData.id, updateData).then(result => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            // message.success("Spares Inventory Modified");
            getPSI()
            close()
          }
        }
      }).catch((err) => {
        setSpinning(false)
        console.error("SparesInventory Add Error: ", err);
        message.error("Couldn't Add SparesInventory");
      });
    }
  }

  const HistoryColumn = [
    {
      title: "Transcation Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record, data) => (<span>{record && moment(new Date(record)).format("DD-MM-YYYY")}</span>
      )
    },
    /* {
       title: "Part Details",
       dataIndex: "Part",
       key: "Part",
       render: record => <span disabled="true">{
         `${record.partNumber} - ${record.partName}`
       }</span>
     },*/
    {
      title: "Transaction Type",
      dataIndex: "type",
      key: "type",
      render: (record, data) => (<span>{record}</span>)
    },
    {
      title: "Transaction Link",
      dataIndex: "linkObj",
      key: "linkObj",
      render: (record, data) => (<Link to={record?.link} className="linkylink">{record && record.id ? record.id : "-"}</Link>)
    },
    {
      title: "Physical Quantity",
      dataIndex: "x",
      key: "x",
      render: (data, record) => {
        return (
          <div style={{textAlign: "center"}}>
            {
              record?.sparesMaterialSale &&
              record?.sparesMaterialSale && record?.sparesMaterialSale?.parts.map(item => {
                if (record.status === "True") {
                  if (item.part.id === record.Part.id) {
                    return (
                      // changes Done
                      // <span>
                      //   {item.quantity + record.Quantity}
                      // </span>
                      <span style={{color: record?.color ? record.color : ""}}>
                        {item.quantity}
                      </span>
                    )
                  }
                }
                else {
                  if (item.part.id === record.Part.id) {
                    return (
                      <span style={{color: record?.color ? record.color : ""}}>
                        {item.quantity}
                      </span>
                    )
                  }
                }

              })
            }
            {
              record?.sparesSale &&
              record?.sparesSale && record?.sparesSale?.saleItemInvoice.map(item => {
                if (item.partNumber)
                  if (item.partNumber.id === record.Part.id) {
                    return (
                      <span style={{color: record?.color ? record.color : ""}}>
                        {item.quantity}
                      </span>
                    )
                  }
              })
            }
            {
              record?.sparesPurchase &&
              record?.sparesPurchase && record?.sparesPurchase?.purchaseItemInvoice.map(item => {
                if (item.partNumber)
                  if (item.partNumber.id === record.Part.id) {
                    return (
                      <span style={{color: record?.color ? record.color : ""}}>
                        {item.quantity}
                      </span>
                    )
                  }
              })
            }
          </div>
        )
      }
    },
    {
      title: "Account Quantity",
      dataIndex: "x",
      key: "x",
      render: (data, record) => {
        return (
          <div style={{textAlign: "center"}}>
            {
              record?.sparesMaterialSale &&
              <span>
                -
              </span>
            }
            {
              record?.sparesSale &&
              record?.sparesSale && record?.sparesSale?.saleItemInvoice.map(item => {
                if (item.partNumber)
                  if (item.partNumber.id === record.Part.id) {
                    return (
                      <span style={{color: record?.color ? record.color : ""}}>
                        {item.quantity}
                      </span>
                    )
                  }
              })
            }
            {
              record?.sparesPurchase &&
              record?.sparesPurchase && record?.sparesPurchase?.purchaseItemInvoice.map(item => {
                if (item.partNumber)
                  if (item.partNumber.id === record.Part.id) {
                    return (
                      <span style={{color: record?.color ? record.color : ""}}>
                        {item.quantity}
                      </span>
                    )
                  }
              })
            }
          </div>
        )
      }
    },
    // {
    //   title: "JOB Card",
    //   dataIndex: "sparesMaterialSale",
    //   key: "sparesMaterialSale",
    //   render: (record, data) => (<Link to={record?.link} className={record && record.job ? "linkylink" : ""}>{record && record.job ? record.job.jobNo : "-"}</Link>)
    // },
    // {
    //   title: "Sale Invoice",
    //   dataIndex: "sparesSale",
    //   key: "sparesSale",
    //   render: (record, data) => (<Link to={record?.link} className={record && record.invoiceNumber ? "linkylink" : ""}>{record && record.invoiceNumber ? record.invoiceNumber : "-"}</Link>)
    // },
    // {
    //   title: "Purchase Invoice",
    //   dataIndex: "sparesPurchase",
    //   key: "sparesPurchase",
    //   render: (record, data) => (<Link to={record?.link} className={record && record.invoiceNumber ? "linkylink" : ""}>{record && record.invoiceNumber ? record.invoiceNumber : "-"}</Link>)
    // },
    // {
    //   title: "Quantity",
    //   dataIndex: "x",
    //   key: "x",
    //   render: (data, record) => {
    //     return (
    //       <>
    //         {
    //           record?.sparesMaterialSale &&
    //           record?.sparesMaterialSale && record?.sparesMaterialSale?.parts.map(item => {
    //             if (record.status === "True") {
    //               if (item.part.id === record.Part.id) {
    //                 return (
    //                   // changes Done
    //                   <span>
    //                     {item.quantity + record.Quantity}
    //                   </span>
    //                 )
    //               }
    //             }
    //             else {
    //               if (item.part.id === record.Part.id) {
    //                 return (
    //                   <span style={{ backgroundColor: "red", padding: '5px' }}>
    //                     {item.quantity}
    //                   </span>
    //                 )
    //               }
    //             }

    //           })
    //         }
    //         {
    //           record?.sparesSale &&
    //           record?.sparesSale && record?.sparesSale?.saleItemInvoice.map(item => {
    //             if (item.partNumber)
    //               if (item.partNumber.id === record.Part.id) {
    //                 return (
    //                   <span>
    //                     {item.quantity}
    //                   </span>
    //                 )
    //               }
    //           })
    //         }
    //         {
    //           record?.sparesPurchase &&
    //           record?.sparesPurchase && record?.sparesPurchase?.purchaseItemInvoice.map(item => {
    //             if (item.partNumber)
    //               if (item.partNumber.id === record.Part.id) {
    //                 return (
    //                   <span>
    //                     {item.quantity}
    //                   </span>
    //                 )
    //               }
    //           })
    //         }
    //       </>
    //     )
    //   }
    // },

  ];

  return (
    <>
      <Modal
        wrapClassName="pmc-form"
        layout="horizontal"
        visible={open}
        title={<Title level={4}>Spares Inventory</Title>}
        onCancel={() => {
          // document.getElementById('resetForm').reset();
          close();
          setData(null)
          form.resetFields()
          getPSI()
        }}
        //  okButtonProps={{ disabled: !editable && !modify }}
        //  okText={editable ? "Save" : "Modify"}
        style={{ width: '100%' }}
        onOk={() => (handleSubmit())}
      >
        <Tabs defaultActiveKey="1">
          <TabPane
            tab={
              <span>
                Spares Details
              </span>
            }
            key="1"
          >
            <Form>
              <Row>
                <Col span={8}>
                  <Form.Item
                    className="form-element"
                    label="Part No"
                  // name="partNo"
                  >
                    {getFieldDecorator("partNumber", {
                    })(
                      <Input placeholder="Part No" disabled={!editable} />
                    )}
                  </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={12}>
                  <Form.Item
                    className="form-element"
                    label="Part Name"
                    name="partName"
                  >
                    {getFieldDecorator("partName", {
                    })(

                      <Input style={{ width: '180%' }} placeholder="Part Name" disabled={!editable} />
                    )}
                  </Form.Item>
                </Col>

              </Row>
              <div id="resetForm">
                <Table
                  style={{ marginTop: '1rem' }}
                  columns={columns}
                  tableLayout="fixed"
                  dataSource={dataSource}
                />
              </div>

            </Form>
          </TabPane>
          {
            HistoryData.length &&
            <TabPane tab={<span>Spares History</span>} key="2">
              <Form>
                <Row>
                  <Col span={8}>
                    <Form.Item
                      className="form-element"
                      label="Part No"
                    // name="partNo"
                    >
                      {getFieldDecorator("partNumber", {
                      })(
                        <Input placeholder="Part No" disabled={!editable} />
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={1} />
                  <Col span={12}>
                    <Form.Item
                      className="form-element"
                      label="Part Name"
                      name="partName"
                    >
                      {getFieldDecorator("partName", {
                      })(

                        <Input style={{ width: '180%' }} placeholder="Part Name" disabled={!editable} />
                      )}
                    </Form.Item>
                  </Col>

                </Row>

              </Form>
              <br />

              <Table
                pagination={{
                  pageSize: 5,
                  defaultCurrent: 1,
                  showQuickJumper: true
                }}
                style={
                  {
                    marginBottom: "1rem"
                  }
                }
                rowKey={(record) => record.id}

                columns={HistoryColumn}
                dataSource={HistoryData}
              // pagination={false}
              />
            </TabPane>
          }
        </Tabs>

      </Modal>
    </>
  )
}
const WrappedSparesModal = Form.create()(
  SparesModal
);

export default WrappedSparesModal;