import React, { useEffect, useState } from "react";
import {
  Form,
  Typography,
  Select,
  Radio,
  Row,
  Col,
  DatePicker,
  Input,
  Button,
  message,
} from "antd";
import { platformApi } from "../../../../../api";
import AccessoriesTable from "./AccessoriesTable";
import moment from "moment";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;
const { Group } = Radio;

const VehicleForm = ({ form, validityRef, clearRef }) => {
  const [vehicleModel, setVehicleModel] = useState([]);
  const [Colors, setColor] = useState([]);
  const [Insurances, setInsurance] = useState([]);

  const { getFieldDecorator } = form;

  const insuranceData = [
    { title: "1+5", key: "insurance1plus5" },
    { title: "5+5", key: "insurance5plus5" },
    { title: "1+5 Zero Dep", key: "insurance1plus5ZD" },
    { title: "5+5 Zero Dep", key: "insurance5plus5ZD" },
  ];

  useEffect(() => {
    validityRef.current = () => {
      return true;
    };
    clearRef.current = () => {
      form.resetFields();
    };
    return () => {
      clearRef.current = null;
      validityRef.current = null;
    };
  }, []);

  useEffect(() => {
    platformApi
      .get("/api/vehicleMaster")
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setVehicleModel(response.data);
          } else {
            message.error("Unable to Vehicle");
          }
        } else {
          message.error("Unable to Vehicle");
        }
      })
      .catch((error) => {
        console.error("Error on Vehicle Form : ", error);
        message.error("Unable to Vehicle");
      });
  }, []);

  const handleModelChange = (id) => {
    const index = vehicleModel.findIndex((model) => model.id === id);
    let { image: colors, price: prices } = vehicleModel[index];
    let insTypes = [];
    insuranceData.forEach((insData) => {
      prices[0][insData.key] &&
        insTypes.push({ ...insData, price: prices[0][insData.key] });
    });
    setInsurance(insTypes);

    setColor(colors);
  };

  return (
    <Form>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Model Code</Text>} colon={false}>
            {getFieldDecorator("modelCode")(
              <Select onChange={handleModelChange} placeholder="Model Code">
                {vehicleModel.map((model) => (
                  <Option key={model.id} value={model.id}>
                    {model.modelCode} - {model.modelName}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Color</Text>} colon={false}>
            {getFieldDecorator("color")(
              <Select placeholder="Color">
                {Colors.map((color) => (
                  <Option key={color.id} value={color.id}>
                    {color.color}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Chassis Number</Text>} colon={false}>
            {getFieldDecorator("chassisNumber")(
              <Select placeholder="Chassis Number"></Select>
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Engine Number</Text>} colon={false}>
            {getFieldDecorator("engineNumber")(
              <Select placeholder="Engine Number"></Select>
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>MFG</Text>} colon={false}>
            {getFieldDecorator("MFG")(
              <DatePicker.MonthPicker
                style={{ width: "100%" }}
                placeholder="MFG"
                allowClear={false}
                disabledDate={(moment) => moment._d > Date.now()}
              ></DatePicker.MonthPicker>
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Insurance Type</Text>} colon={false}>
            {getFieldDecorator("insuranceType")(
              <Select placeholder="Insurance Type">
                {Insurances.map((insurance) => (
                  <Option key={insurance.key} value={insurance.key}>
                    {insurance.title} (&#8377;
                    {insurance.price})
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Vaahaan Application Number</Text>} colon={false}>
            {getFieldDecorator("vahaanApplicationNo")(
              <Input placeholder="Vaahan Number" />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Item>
                <Text>Temp Reg : </Text>
              </Item>
            </Col>
            <Col span={14}>
              <Item>
                {getFieldDecorator("tempRegister")(
                  <Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Group>
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Item>
                <Text>Selection No : </Text>
              </Item>
            </Col>
            <Col span={14}>
              <Item>
                {getFieldDecorator("choiceOfNumber")(
                  <Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Group>
                )}
              </Item>
            </Col>
          </Row>
        </Col>
        <Col span={11}>
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Item>
                <Text>RTO Reg : </Text>
              </Item>
            </Col>
            <Col span={14}>
              <Item>
                {getFieldDecorator("RTO")(
                  <Group>
                    <Radio value="yes">Yes</Radio>
                    <Radio value="no">No</Radio>
                  </Group>
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Item>
                <Text>Number Plate: </Text>
              </Item>
            </Col>
            <Col span={14}>
              <Item>
                {getFieldDecorator("numberPlate")(
                  <Group>
                    <Radio value={true}>Yes</Radio>
                    <Radio value={false}>No</Radio>
                  </Group>
                )}
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Row>
            <Item label={<Text>Number Plate Charge</Text>} colon={false}>
              {getFieldDecorator("numPlateCharge")(
                <Input placeholder="Number Plate Charges" />
              )}
            </Item>
          </Row>
          <Row>
            <Item label={<Text>Temporary Reg. Charge</Text>} colon={false}>
              {getFieldDecorator("numPlateCharge")(
                <Input placeholder="Number Plate Charges" />
              )}
            </Item>
          </Row>
        </Col>
        <Col span={11}>
          <Item
            label={
              <span>
                <Text> Accessories List </Text>
                <Button shape="round" size="small" icon="plus">
                  Add
                </Button>
              </span>
            }
            colon={false}
          >
            <AccessoriesTable />
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedVehicleForm = Form.create()(VehicleForm);

export default WrappedVehicleForm;
