import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, message } from "antd";
import { platformApi } from "../../../../api";
const { Item } = Form;
const { Option } = Select;
function AdvancedFilters({
  form,
  filteropen,
  close,
  setCount,
  searchName,
  setId,
  id,
  setData,
  page,
  limit,
  setPageData,
  setSearch,
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [model, setModels] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const status = [
    { key: "AVAILABLE", title: "Available" },
    { key: "NOTAVAILABLE", title: "Not Available" },
  ];
  const Filters = [
    { id: "1", name: "Name" },
    // { id: "2", name: "Part Number" },
    // { id: "3", name: "Part Status" },
    // { id: "5", name: "Manufacturer" },
    // { id: "4", name: "Vehicles Associated" },
    // { id: "6", name: "Category" },
    // { id: "7", name: "BIN Number" },
  ];
  const selectFilter = (id) => {
    setId(id);
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const searchValue = () => {
    setId([]);
    resetFields();
    setPageData();
    setSearch([]);
  };
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
  };
  useEffect(() => {
    if (filteropen) {
      setFieldsValue({ selectfilter: id });
    }
  }, [filteropen]);
  // useEffect(() => {
  //   if (filteropen) {
  //     handleSubmit();
  //   }
  // }, [page, limit]);
  // useEffect(() => {
  //   if(id.includes("1")){
  //     setFieldsValue({ Name: searchName.name });
  //   }
  // }, [id, searchName, filteropen]);
  useEffect(() => {
    if (id.includes("4") && !id.includes("5")) {
      platformApi.get(`/api/vehicleMaster`).then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setModels(response.data);
          }
        }
      });
    }
    if (id.includes("5")) {
      platformApi.get("/api/manufacturer").then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setManufacturer(response.data);
          }
        }
      });
    }
  }, [id]);
  const branchSelect = (id) => {
  };
  const handleManufacturer = (id, key) => {
    platformApi.get(`/api/vehicleMaster/man/${key.key}`).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          setModels(response.data);
        }
      }
    });
  };
  const handleSubmit = () => {
    const data = {
      name: getFieldValue("Name"),
      number: getFieldValue("number"),
      status: getFieldValue("partStatus"),
      modelname: getFieldValue("vehicle"),
      manufacturer: getFieldValue("manufacturer"),
      category: getFieldValue("category"),
      binNumber:getFieldValue("bin")
    };
    setSearch(data);
    setPageData(data);
    // platformApi
    //   .post(`/api/partsMaster/search`, 
    //   {
    //     name: getFieldValue("Name"),
    //     binNumber:getFieldValue("bin"),
    //     size: limit,
    //     from: (page - 1) * limit,
    //   }
    //   )
    //   .then((result) => {
    //     let { data } = result;
    //     if (data.code === 200) {
    //       let { response } = data;
    //       if (response.code === 200) {
    //         setData(response.data.parts);
    //         setCount(response.data.count);
    //         message.success("Filtered successfully", 2);
    //       } else {
    //         message.error("Unable to fetch", 2);
    //       }
    //     } else {
    //       message.error("Unable to fetch", 2);
    //     }
    //   })
    //   .catch((error) => {
    //     console.error("Error in filtering : ", error);
    //     message.error("Unable to fetch", 2);
    //   });
  };
  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <Row type="flex" justify="start">
                  {id.map((element) =>
                    element === "1" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("Name")(
                                <Input placeholder="Parts Name and Number" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "2" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("number")(
                                <Input placeholder="Part Number" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "3" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("partStatus")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Part Status"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                >
                                  {status.map((status) => (
                                    <Select.Option
                                      value={status.key}
                                      key={status.key}
                                    >
                                      {status.title}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "4" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("vehicle")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Vehicle Associated"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                  mode="multiple"
                                >
                                  {model.map((vehicle) => (
                                    <Select.Option
                                      value={vehicle.modelName}
                                      key={vehicle.id}
                                    >
                                      {vehicle.modelName}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "5" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("manufacturer")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Manufacturer"
                                  onChange={(event, key) =>
                                    handleManufacturer(event, key)
                                  }
                                  filterOption={filterMethod}
                                  showSearch
                                >
                                  {manufacturer.map((manufacturer) => (
                                    <Select.Option
                                      value={manufacturer.name}
                                      key={manufacturer.id}
                                    >
                                      {manufacturer.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "7" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("bin")(
                                <Input placeholder="BIN Number" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("category")(
                                <Input placeholder="Category" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex" justify="space-between">
                  <Col span={19}>
                    <Item colon={false}>
                      {getFieldDecorator("selectfilter")(
                        <Select
                          placeholder="Select the Filter"
                          onChange={selectFilter}
                          mode="multiple"
                          showAction={["click","focus"]}
                        >
                          {Filters.map((type) => (
                            <Option key={type.id}>{type.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={5} style={{ marginTop: "0.2rem" }}>
                    <Button type="primary" onClick={handleSubmit}>
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="end"
                  style={{ marginRight: "0.79rem" }}
                >
                  <Button
                    type="ghost"
                    style={{ width: "4.5rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
