import React, { useState, useEffect, useContext } from 'react';
import {
  Typography,
  Input,
  Modal,
  Button,
  Select,
  Form,
  Col,
  Row,
  Icon,
  message,
  Tabs,
  Table,
  Popover
} from 'antd';
import moment from "moment";

import { InfoCircleOutlined } from "@ant-design/icons";
import './vehicleModal.less';
import { platformApi } from '../../../../../api';
import imagePlaceHolder from "../../../../../assets/vehicle-placeholder.jpg";
import WrappedImageViewModal from './ImageViewModal';
const { Title, Text } = Typography;
const { Item } = Form;
const { Option } = Select;
const VehicleModal = (props) => {
  const { form, open, close, data, editable, setSup, setSupData } = props
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const [dataSource, setDataSource] = useState([]);
  const [branches, setBranch] = useState([]);
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [view, setView] = useState(false);
  const [viewData, setViewData] = useState({});

  const renderColumns = () => {
    const columns = [
      {
        title: "MFG Date",
        dataIndex: "manMonthYear",
        key: "mfgDate",
        width:120,
        render: (record) => {
          return <span>{record}</span>
        },
      },
      {
        title: "Chassis No",
        dataIndex: "chassisNo",
        key: "chassisNo",
        width: 220,
        render: (record, data) => {

          return <span>{record?.toUpperCase()}</span>
        }
      },
      {
        title: "Engine No",
        dataIndex: "engineNo",
        key: "engineNo",
        width: 180,
        render: (engineNo, data) => {
          return <span>{engineNo?.toUpperCase()}</span>

        },
      },
      {
        title: "Status",
        dataIndex: "Status",
        key: "status",
        width:120,
        render: (record, data) => <span>{record}</span>,
      },
      {
        title: "Location",
        dataIndex: "branch",
        key: "branch",
        width:120,
        render: (record, data) => <span>{record?.name}</span>,
      },

      {
        title: 'Action',
        dataIndex: 'remove', 
        key: 'x',
        width: 80,
        render: (data, record) => (
          <Form.Item>
            {record?.vehiclePurchase &&
              <Popover placement="bottom" content={
                <div>
                  {
                    record?.vehiclePurchase?.invoiceDate && <p>Invoice Date:{moment(record.vehiclePurchase.invoiceDate).format("DD-MM-YYYY")}</p>
                  }
                  {
                    record.vehiclePurchase?.invoiceNo && <p>Bill No(VPI):{record.vehiclePurchase?.invoiceNo}</p>
                  }
                  {
                    record.vehiclePurchase.purchaseChallan && <p className="linkylink" onClick={() => {
                      setSupData(record.vehiclePurchase.purchaseChallan?.supplier?.id)
                      setSup(true)
                    }}>Supplier :{record.vehiclePurchase?.purchaseChallan?.supplier.name}</p>
                  }
                </div>
              }>
                <InfoCircleOutlined />
              </Popover>
            }
          </Form.Item>
        ),
      },
    ]
    return columns;
  }

  useEffect(() => {
    platformApi.get("/api/user/branch").then((result) => {
      let { data } = result;
      if (data.code === 200) {
        setBranch(data.data);
      }
    });
    if (!data) {
      platformApi.get("/api/user/currentUser").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setFieldsValue({ branch: "ALL" });
          }
        }
      });
    }
  }, [open])

  useEffect(() => {
    if (data) {
      platformApi.post('/api/vehicleInventory/getvehicles', data)
        .then(res => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setDataSource(response.data);
              setVehicleDetail({
                vehicle: response.data[0].vehicle,
                color: response.data[0].color
              })
            }
          }
        })
    }
  }, [data])

  const changeBranch = (value) => {
    if (value) {
      const temp = { ...data }
      temp.branch = value
      platformApi.post('/api/vehicleInventory/getvehicles', temp)
        .then(res => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setDataSource(response.data);
              setVehicleDetail({
                vehicle: response.data[0]?.vehicle,
                color: response.data[0]?.color
              })
            }
          }
        })
    }
  }

  const clearFields = () => {
    setDataSource([])
    setBranch([])
    setVehicleDetail({});
    close();
  }

  return (
    <>
      <Modal
        wrapClassName="vehicle-form"
        layout="horizontal"
        visible={open}
        title={<Title level={4}>Vehicle Inventory</Title>}
        onCancel={() => {
          clearFields();
          // close();
        }}
        // style={{width:'200px'}}
        // onOk={() => (editable ? handleSubmit() : setEditable(true))}
        okButtonProps={
          !editable && { style: { display: "none" } }
        }
        okText={"Save"}
        cancelText={editable ? "Cancel" : "Close"}
      >
        <Form>
          <div className="form-container">
            <div className="form-column">
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Item
                    className="form-element"
                    label="Model"
                    name="modelCode"
                  >


                    <Input placeholder="Model" disabled={!editable} value={`${dataSource.length && dataSource[0]?.vehicle?.modelCode} - ${dataSource.length && dataSource[0]?.vehicle?.modelName}`} />

                  </Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Item className="material-form-element" label={<Text>Branch</Text>}>
                    {getFieldDecorator("branch", {
                      rules: [
                        {
                          required: false,
                          message: "Select Branch",
                        },
                      ],
                    })(
                      <Select
                        showAction={["click", "focus"]}
                        showSearch
                        filterOption={null}
                        // disabled={!editable}
                        placeholder="ALL"
                        onSelect={changeBranch}
                      >
                        {branches.map((obj) => (
                          <Option key={obj.id}>{obj.name}</Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
              <Row gutter={[8, 8]}>
                <Col span={24}>
                  <Item
                    className="form-element"
                    label="Stock"
                    name="stcok"
                  >
                    <Input placeholder="Stock" disabled={!editable} value={dataSource.length} />

                  </Item>
                </Col>
              </Row>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <img
                src={dataSource.length ? dataSource[0].color.url : imagePlaceHolder}
                style={{ width: "250px", height: '150px' }}
                onClick={() => {
                  setView(true)
                  let data = {
                    url: dataSource.length ? dataSource[0].color.url : '',
                    color: dataSource.length && dataSource[0].color?.color,
                    code: dataSource.length && dataSource[0].color?.code
                  }
                  setViewData(data)
                }}
              />
              <div style={{ display: 'flex', justifyContent: 'center', margin: '10px', flexDirection: 'column' }}>
                <h4>Color Code:{dataSource.length && dataSource[0].color?.code}</h4>
                <h4>Color Name: {dataSource.length && dataSource[0].color?.color}</h4>
              </div>
            </div>
          </div>
          <Table
            style={{ marginTop: '1rem' }}
            columns={renderColumns()}
            // tableLayout="fixed"
            dataSource={dataSource}
          />
        </Form>
      </Modal>
      <WrappedImageViewModal
        open={view}
        close={() => {
          setView(false);
          setViewData({})
        }}
        viewData={viewData}
        editable={false}
      />
    </>
  )
}

const WrappedVehicleModal = Form.create()(
  VehicleModal
);

export default WrappedVehicleModal;