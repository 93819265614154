import React, { useState, useEffect } from 'react';
import { withRouter, useLocation } from "react-router-dom";
import { Typography, message, Carousel, Button, Form, Row, Col, Input, Tooltip, Card } from "antd";
import { platformApi } from '../../api'
import {
    LeftOutlined
} from '@ant-design/icons';
import './style.less'
import Moment from 'moment'
import CustomerInfo from '../FollowUp/CustomerInfo';
import ActivityLog from '../FollowUp/ActivityLog';
import VehicleInfo from './VehicleInfo';
const ServiceFollowUp = (props) => {
    const location = useLocation();

    const { Title, Text } = Typography;
    const [customerId, setCustomerId] = useState(null);
    const [customerIds, setCustomerIds] = useState(null);
    const [customerPhone, setCustomerPhone] = useState(null);
    const [allCustomers, setCustomers] = useState([])
    const [customerDetails, setCustomerDetails] = useState(null);
    const [mergedVehicles, setMergedVehicles] = useState([]);
    const [mergedPurchasedVehicle, setMergedPurchasedVehicle] = useState([]);
    const [activities, setActivities] = useState([]);
    const [followUpDate, setFollowUpDate] = useState();
    const [status, setStatus] = useState('');

    useEffect(() => {
        settingDetails();
        return () => {
            clearingState();
        }
    }, [location])

    const settingDetails = () => {
        const { phoneNo, fupDate } = props.match.params
        setCustomerPhone(phoneNo)
        setFollowUpDate(fupDate);
        getCustomersByPhone(phoneNo)
    }

    const clearingState = () => {
        setActivities([])
    }

    const getCustomersByPhone = (phoneNo = customerPhone) => {
        platformApi.get(`/api/customer/phoneNo/${phoneNo}`)
            .then(res => {
                const { data: { response: { data: { customers } } } } = res;
                setCustomers(customers)
                if (customers.length) {
                    let allCustomerIDS = customers.map(customer => customer.id)
                    setCustomerIds(allCustomerIDS)
                    setCustomerId(customers[0].id);
                    getCustomerInfo(customers[0].id)
                    getMergedInfo(allCustomerIDS)
                    getActivityByCustomer(allCustomerIDS)
                }
            })
    }

    const getMergedInfo = (ids = customerIds) => {
        platformApi.post('/api/vehicle/customerVehicle', {
            customer: ids
        }).then(res => {
            const { data: { response: { data } } } = res
            console.log("Response", data)
            setMergedVehicles([data[0]])
        })

        // platformApi.post('/api/customer/merge', {
        //     ids
        // }).then(res => {
        //     const { data: { response: { data } } } = res
        //     data.purchasedVehicle = data.purchasedVehicle.filter(vehicle => data.quotation.every(quo => Moment(vehicle.dateOfSale).isBefore(quo.createdAt)))
        //     setMergedPurchasedVehicle(data.purchasedVehicle);
        //     setMergedQuotations(data.quotation)
        // })
    }

    const updateCustomerData = (customer) => {
        platformApi
            .put(`/api/customer/${customer.id}`, customer)
            .then((result) => {
                const { data } = result;
                if (data.code === 200) {
                    const { response } = data;
                    if (response.code === 200) {
                        getCustomerInfo();
                        message.success("Customer updated successfully", 2);
                        // window.location.reload();
                    } else {
                        message.error("Unable to update customer", 2);
                    }
                } else if (data.code === 500 && data.err.code === 500) {
                    message.error("Customer name already exists", 2);
                } else {
                    message.error("Unable to update customer", 2);
                }
            })
            .catch((error) => {
                message.error("Unable to update customer", 2);
                console.error("Error on customer : ", error);
            });
    };

    const getCustomerInfo = (custId = customerId) => {
        let customer = custId
        if (customer) {
            platformApi.get(`/api/customer/${customer}`).then(result => {
                const { data } = result.data.response;
                // console.log("Customer Data", data)
                setCustomerDetails(data);
            })
        }
    }

    const getActivityByCustomer = (ids = customerIds, limit = 15, offset = 0) => {
        platformApi.post('/api/activity/customers', {
            limit,
            offset,
            ids
        }).then(res => {
            const { data: { response: { data } } } = res
            console.log("ActivityData", data)
            const temp = data.filter(item => {
                return item.interactionType === 'Service Followup Call' || item.interactionType === 'Service Followup Message'
            })
            console.log("Temp", temp)
            setActivities(temp)
        }).catch(err => message.error("Unable to get Activities"))
    }
    const changeFollowUp = (next = false) => {
        platformApi.post("/api/vehicle/scheduled", { fupDateTime: followUpDate, next }).then((res) => {
            const { data: { response: { data } } } = res
            if (data.length) {
                const quotationInfo = data[0];
                // console.log("quotationInfo", quotationInfo)
                const phno = (quotationInfo.customer.contacts.filter(phno => phno.type === "Primary"))[0].phone
                props.history.push(`/autoadmin/follow-ups/${phno}/fupdate/${quotationInfo.scheduleDateAndTime}`)
                message.success("FollowUp Fetched !!!")
            }
            else {
                if (next)
                    message.error("No Next FollowUp Exist")
                else
                    message.error("No Previous FollowUp Exist")
            }
        })
    }
    return (
        <div className="follow-ups">
            <Title style={{ width: "60%" }} level={4}>
                <Tooltip placement="topLeft" title={"Back"}>
                    <Button
                        style={{ margin: "0 20px" }}
                        onClick={() => props.history.push('/autoadmin/service-followups')}
                    >
                        <LeftOutlined />
                    </Button>
                </Tooltip>
            Service Follow-Ups
          </Title>
            <Row type='flex' justify='space-between' gutter={[16, 16]}>
                {/* <Col span={24}>
                    <Row type='flex' justify='space-between' >
                        <Col>
                            <Button
                                style={{ margin: "0 20px" }}
                                onClick={() => changeFollowUp()}
                            >
                                Previous
                            </Button>
                        </Col>
                        <Col>
                            <Button
                                style={{ margin: "0 20px" }}
                                onClick={() => changeFollowUp(true)}
                            >
                                Next
                            </Button>
                        </Col>
                    </Row>
                </Col> */}
                <Col span={24}>
                    <Card>
                        <Row type='flex' justify='space-between' gutter={[16, 16]}>
                            <Col span={8}>
                                <Carousel
                                    initialSlide={0}
                                    beforeChange={(from, to) => {
                                        if (allCustomers.length) {
                                            setCustomerId(prevId => allCustomers[to].id)
                                            getCustomerInfo(allCustomers[to].id)
                                        }
                                    }
                                    }
                                >
                                    {
                                        allCustomers.map(customer => {
                                            return (customerDetails) ? <CustomerInfo
                                                updateCustomerData={updateCustomerData}
                                                customer={customerDetails}
                                                enquiryType={
                                                    // currentQuotation.enquiryType ||
                                                    "-"}
                                                setCustomer={setCustomerDetails} /> : null
                                        })
                                    }
                                </Carousel>
                            </Col>
                            <Col span={16}>
                                {(customerDetails) ?
                                    <VehicleInfo
                                        customerId={customerId}
                                        getCustomerInfo={getCustomerInfo}
                                        getActivityByCustomer={getActivityByCustomer}
                                        dataSource={mergedVehicles}
                                        customerDetail={customerDetails}
                                    /> : null}
                            </Col>
                        </Row>
                    </Card>
                </Col>
                {/* <Col span={24}>

                </Col> */}
                <Col span={24}>
                    <ActivityLog
                        customerId={customerId}
                        dataSource={activities}
                        setDataSource={setActivities}
                        getActivityByCustomer={getActivityByCustomer}
                    />
                </Col>
            </Row>
        </div>
    )
}
export default withRouter(ServiceFollowUp);