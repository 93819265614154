import React, { useState } from "react";
import { Form, Icon, Input, Button, Checkbox, Alert, message } from "antd";
import { Redirect, withRouter } from "react-router-dom"
import { platformApi, setAccessToken } from "../../../api";
import "./index.less";

const SendSMSLink = (props) => {
  const { form } = props
  const [loader, setLoader] = useState(false);

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      const phone = form.getFieldValue("mobileNo");
      if (validationError === null) {
        setLoader(true);
        platformApi.post('/api/user/sendForgotPassword', { phone })
          .then(res => {
            setLoader(false)
            let { data } = res;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                message.success('SMS link Sent to your Registered Mobile Number');
                form.setFieldsValue({ mobileNo: undefined })
              }
              if (response.code === 400) {
                message.error('User Not Registered Using this Phone Number');
                form.setFieldsValue({ mobileNo: undefined })
              }
            }
          })
        // props.history.push('/reset_password')
      }
    });
  };

  return (
    <div className="login">
      <div className="loginform">
        <div className="login__foreground">
          <div className="login__title">
            <span>Forgot Password</span>
          </div>
        </div>
        <div className="login-form">
          <Form className="login-form">
            <Form.Item>
              {form.getFieldDecorator("mobileNo", {
                rules: [
                  { required: true, message: "Please type your Phone Number!" },
                ],
              })(
                <Input
                  prefix={
                    <Icon type="phone" style={{ color: "rgba(0,0,0,.25)" }} />
                  }
                  placeholder="Mobile"
                  size="large"
                  type="number"
                />
              )}
            </Form.Item>
            <Form.Item>
              <Button type="link" className="login-form-forgot" href="">
                Contact Admin
              </Button>
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                onClick={() => handleSubmit()}
                size="large"
                loading={loader}
              >
                Send SMS Link
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

const WrappedSendOTPForm = Form.create({ name: "Password" })(SendSMSLink);
export default WrappedSendOTPForm;
