import React from "react";
import { Table, Typography } from "antd";

const { Text } = Typography;

const PriceTable = ({ dataSource }) => {
  const columns = [
    {
      title: <Text strong>Type</Text>, 
      key: "type",
      dataIndex: "type",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Price</Text>,
      key: "price",
      dataIndex: "price",
      render: (record) => <span>&#8377; {record}</span>,
    },
  ];

  return (
    <Table
      size="small"
      pagination={false}
      dataSource={dataSource}
      columns={columns}
    />
  );
};

export default PriceTable;
