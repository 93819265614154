import React from "react";
import { Table, Divider, Popconfirm, Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
import moment from 'moment'
const { Text } = Typography;

const AccessoriesTable = ({
  pagination,
  dataSource,
  openModal,
  spinner,
  delete: deleteData,
  history,
  modify,
  deleteAccess,
}) => {
  const { goBack } = history;

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      width:"11rem",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (record) => <span>&#8377; {record}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {modify ? (
            <span
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, true);
              }}
              className="linkylink"
            >
              Modify
            </span>
          ) : (
            <span></span>
          )}
          {deleteAccess ? (
            <Popconfirm
              title="Are you sure delete this Accesory?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                <Divider type="vertical" />
                Delete
              </span>
            </Popconfirm>
          ) : (
            <span></span>
          )}
          {!modify && !deleteAccess ? (
            <Text strong disabled>
              No Actions Given
            </Text>
          ) : (
            <span></span>
          )}
          {/* <Divider type="vertical" />
          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      style={{ cursor: "pointer" }}
      onRow={(props) => ({
        onClick: () => {
          openModal(props.id, false);
        },
      })}
      loading={spinner}
    />
  );
};

export default withRouter(AccessoriesTable);
