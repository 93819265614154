import React from "react";
import { Table, Divider, Popconfirm } from "antd";

export default function VehicleSaleInvoiceTable(props) {
  const { dataSource } = props;

  const column = [
    {
      title: "Invoice No.",
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      render: record => <span>{record}</span>
    },
    {
      title: "Vehicle Name",
      dataIndex: "vehicleName",
      key: "vehicleName",
      render: record => <span>{record}</span>
    },
    {
      title: "Registration No.",
      dataIndex: "registration",
      key: "registration",
      render: record => <span>{record}</span>
    },
    {
      title: "Owner",
      dataIndex: "owner",
      key: "owner",
      render: record => <span>{record}</span>
    },
    {
      title: "Action",
      render: data => (
        <div>
          <span
            onClick={event => {
              event.stopPropagation();
              // openModal(data.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span>

          <Divider type="vertical" />

          <Popconfirm
            title="Do you want to delete this Booking Register?"
            okText="Yes"
            cancelText="No"
            onCancel={event => {
              event.stopPropagation();
            }}
            onConfirm={event => {
              event.stopPropagation();
              // props.delete(data.id);
            }}
          >
            <span
              onClick={event => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
        </div>
      )
    }
  ];
  return (
    <div>
      <Table
        rowKey={record => record.id}
        // pagination={pagination}
        columns={column}
        tableLayout="auto"
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        onRow={props => ({
          // onClick: () => openModal(props.id, false),
        })}
        // loading={spinner}
      />
    </div>
  );
}
