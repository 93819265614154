import React, { useState, useEffect } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import {
    Modal,
    Select,
    Form,
    Row,
    Col,
    Typography,
    message,
    Button,
} from "antd";
import { platformApi } from "../../../../api";
import './index.less'

const { Text, Title } = Typography;
const { Option } = Select;

const VehicleInspection = ({
    form,
    open,
    close,
    data,
    editData,
    editable,
    modify,
}) => {
    const history = useHistory();
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [error, setError] = useState({});
    const [status, setStatus] = useState('');
    const StatusType = [
        { key: "Ready for Delivery", title: "Ready for Delivery" },
        { key: "Pending", title: "Pending" },
        { key: "Network Needed", title: "Network Needed" }
    ];

    //Edit Data
    useEffect(() => {
        if (data) {
            console.log("editData", data)
        }

    }, [data]);
    const handleStatus = (event) => {
        setStatus(event)
        setFieldsValue({
            status: event
        })
    }
    const handleSubmit = () => {
        form.validateFields(async (validationError) => {
            if (validationError === null) {
                if (data) {
                    console.log("submit", data.id, getFieldValue("status"), status)
                    // platformApi
                    //     .put(`/api/jobOrder/setMech/${data.id}`, {
                    //         status: getFieldValue("status") ? getFieldValue("status") : status
                    //     })
                    //     .then((res) => {
                    //         let { data } = res;
                    //         if (data.code === 200) {
                    //             let { response } = data;
                    //             if (response.code === 200) {
                    //                 message.success("Job Order Updated successfully", 2);
                    //                 close();
                    //             } else {
                    //                 message.error("Unable to update Job Order", 2);
                    //             }
                    //         } else {
                    //             message.error("Unable to update Job Order", 2);
                    //         }
                    //     })
                    //     .catch((err) => {
                    //         message.error("Unable to update Job Order", 2);
                    //         console.error("Error on Job Order : ", err);
                    //     });
                }
            }
        });
    };
    const clearFieldsAndClose = () => {
        form.resetFields();
        close();
        history.push('/autoadmin/job_order');
    };
    return (
        <Modal
            title={<Title level={4}>Vehicle Inspection</Title>}
            visible={open}
            onCancel={clearFieldsAndClose}
            okText={!editable ? "Modify" : "Save"}
            okButtonProps={{ disabled: (!editable && !modify) }}
            onOk={() => (editable ? handleSubmit() : setEditable(true))}
        >
            <Form className="purchase-form">
                <Row>
                    <Col span={12}>
                        <Text strong>
                            JOB NO&nbsp;&nbsp;&nbsp;: {data && data.jobNo}
                        </Text>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between">
                    <Col span={24}>
                        <Form.Item
                            className="purchase-form-element"
                            colon={false}
                            required
                            validateStatus={error.STATUS && error.STATUS.type}
                            help={error.STATUS && error.STATUS.message}
                            label={<Text>Status</Text>}
                            colon={false}
                        >
                            {getFieldDecorator("status", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Status",
                                    },
                                ],
                            })(
                                <Select
                                    dropdownStyle={{
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                    onChange={(event) => {
                                        handleStatus(event)
                                    }}
                                    showSearch
                                    showAction={["click", "focus"]}
                                    disabled={!editable}
                                    placeholder="Status"
                                >
                                    {StatusType.map((item) => (
                                        <Select.Option key={item.key} value={item.title}>
                                            {item.title}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const WrappedVehicleInspection = Form.create("vehicle_inspection")(
    VehicleInspection
);

export default WrappedVehicleInspection;