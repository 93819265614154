import React, { useEffect, useState } from "react";
import {
    Form,
    Select,
    Typography,
    Row,
    Col,
    Button, 
    Input,
    DatePicker,
    message,
    Carousel
} from "antd";
import moment from "moment";
import './index.less'
const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;
import { CheckCircleTwoTone, RightOutlined, LeftOutlined, CloudUploadOutlined } from '@ant-design/icons';
const contentStyle = {
    height: '320px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    width: 'auto'
};
const SampleNextArrow = props => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                color: 'black',
                fontSize: '19px',
                cursor: 'pointer',
                lineHeight: '1.5715',
                border: 'solid 1px black',
                borderRadius: '8%'
            }}
            onClick={onClick}
        >
            <RightOutlined />
        </div>
    )
}

const SamplePrevArrow = props => {
    const { className, style, onClick } = props
    return (
        <div
            className={className}
            style={{
                ...style,
                color: 'black',
                fontSize: '19px',
                cursor: 'pointer',
                lineHeight: '1.5715',
                border: 'solid 1px black',
                borderRadius: '8%'

            }}
            onClick={onClick}
        >
            <LeftOutlined />
        </div>
    )
}
const settings = {
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />
}

const VehicleList = ({
    form,
    editable,
    recvData,
    Vehicle,
    data,
    clearRef,
    onSelect
}) => {
    const { getFieldDecorator, getFieldValue } = form;
    useEffect(() => {
        if (Vehicle) {
            // console.log("Get Props:", Vehicle);
        }
    }, [Vehicle])
    return ( 
        <div className="carousal">
            {Vehicle &&
                Vehicle.length === 1 ?
                Vehicle.map((item, index) => {
                    return (
                        <div key={index}>
                            <Row>
                                <Col span={8}>
                                    <div className="Vehicle-Image">
                                        <img src={item.color && item.color.url} alt="VehicleImage" />
                                        <div style={{ display: 'flex', justifyContent: 'center', padding: '12px 40px' }}>
                                            <span style={{
                                                border: '1px solid lightgrey',
                                                padding: '12px',
                                                backgroundColor: '#6C7B8A',
                                                color: '#fff'
                                            }}>
                                                Color Code:{item.color && item.color.code}
                                            </span>
                                        </div>
                                    </div>
                                </Col>
                                <Col span={16}>
                                    <div className="VehicleData">
                                        <Form>
                                            <Row gutter={[16, 16]}>
                                                <Col span={12}> 
                                                    <Form.Item
                                                        colon={false}
                                                        label="Model"
                                                    >
                                                        <Input placeholder="Model"
                                                            disabled={true}
                                                            value={item.vehicle && item.vehicle.modelName}
                                                        >
                                                        </Input>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        colon={false}
                                                        label="Vehicle No"
                                                    >
                                                        <Input placeholder="Vehicle No."
                                                            disabled={true}
                                                            value={item.registerNo && item.registerNo}
                                                        >
                                                        </Input>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        colon={false}
                                                        label="Chassis No"
                                                    >
                                                        <Input placeholder="ChasisNo."
                                                            disabled={true}
                                                            value={item.chassisNo && item.chassisNo}
                                                        />

                                                    </Form.Item>
                                                </Col>
                                                <Col span={12}>
                                                    <Form.Item
                                                        colon={false}
                                                        label="EngineNo"
                                                    >
                                                        <Input placeholder="EngineNo."
                                                            value={item.engineNo && item.engineNo}
                                                            disabled={true}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                            <Row gutter={[16, 16]}>
                                                <Col span={12}>
                                                    <Form.Item
                                                        colon={false}
                                                        label={
                                                            <Text>
                                                                Date of Sale
                                                                </Text>
                                                        }
                                                    >
                                                        <DatePicker
                                                            disabled={true}
                                                            allowClear
                                                            format="DD-MM-YYYY"
                                                            value={item.dateOfSale ? moment(new Date(item.dateOfSale && item.dateOfSale)) : null}
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col span={12}>
                                                    <Form.Item
                                                        colon={false}
                                                        label={
                                                            <Text>
                                                                MFG [MM-YYYY]
                                                            </Text>
                                                        }
                                                    >
                                                        <DatePicker
                                                            disabled={true}
                                                            allowClear
                                                            format="MMM-YYYY"
                                                            value={item.mfg ? moment(item.mfg, "MMM-YYYY") : null}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )
                })
                :
                <Carousel arrows {...settings} dots={false} >
                    {
                        Vehicle &&
                        Vehicle.length > 1 &&
                        Vehicle.map((item, index) => {
                            return (
                                <div key={index}>
                                    <Row>
                                        <Col span={8}>
                                            <div className="Vehicle-Image">
                                                <img src={item.color && item.color.url} alt="VehicleImage" />
                                                <div>
                                                    <span style={{
                                                        border: '1px solid lightgrey',
                                                        padding: '12px',
                                                        backgroundColor: '#6C7B8A',
                                                        color: '#fff'
                                                    }}>
                                                        Color Code:{item.color && item.color.code}
                                                    </span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col span={16}>
                                            <div className="VehicleData">
                                                <Form>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                colon={false}
                                                                label="Model"
                                                            >
                                                                <Input placeholder="Model"
                                                                    disabled={true}
                                                                    value={item.vehicle && item.vehicle.modelName}
                                                                >
                                                                </Input>
                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                colon={false}
                                                                label="Vehicle No"
                                                            >
                                                                <Input placeholder="Vehicle No."
                                                                    disabled={true}
                                                                    value={item.registerNo && item.registerNo}
                                                                >
                                                                </Input>
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                colon={false}
                                                                label="ChasisNo"
                                                            >
                                                                <Input placeholder="Chassis No."
                                                                    disabled={true}
                                                                    value={item.chassisNo && item.chassisNo}
                                                                />

                                                            </Form.Item>
                                                        </Col>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                colon={false}
                                                                label="EngineNo"
                                                            >
                                                                <Input placeholder="Engine No."
                                                                    value={item.engineNo && item.engineNo}
                                                                    disabled={true}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                    <Row gutter={[16, 16]}>
                                                        <Col span={12}>
                                                            <Form.Item
                                                                colon={false}
                                                                label={
                                                                    <Text>
                                                                        Date of Sale
                                                                    </Text>
                                                                }
                                                            >
                                                                <DatePicker
                                                                    disabled={true}
                                                                    allowClear
                                                                    format="DD-MM-YYYY"
                                                                    value={moment(new Date(item.dateOfSale && item.dateOfSale))}
                                                                />
                                                            </Form.Item>
                                                        </Col>

                                                        <Col span={12}>
                                                            <Form.Item
                                                                colon={false}
                                                                label={
                                                                    <Text>
                                                                        MFG [MM-YYYY]
                                                                    </Text>
                                                                }
                                                            >
                                                                <DatePicker
                                                                    disabled={true}
                                                                    allowClear
                                                                    format="MMM-YYYY"
                                                                    value={item.mfg ? moment(item.mfg, "MMM-YYYY") : null}
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Form>

                                                {editable ? <Button type="primary" style={{ float: 'right', marginTop: '25px' }} onClick={() => {
                                                    onSelect(item)
                                                }}>
                                                    Select Vehicle
                                                </Button> : null}
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )
                        })
                    }
                </Carousel>
            }
        </div>
    )
}
const WrappedVehicleList = Form.create()(VehicleList);

export default WrappedVehicleList; 