import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { message, Tooltip, Button, Typography } from "antd";
import { LeftOutlined } from "@ant-design/icons";
const { Title } = Typography;
import { platformApi } from "../../../../api";
import WrappedMaterialForm from "./MaterialForm";
const MaterialIssueForm = (props) => {
  const { redirect } = props;
  const [editable, setEditable] = useState(false);
  const [editData, setEditData] = useState();
  const [modifyType, setModifyType] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [jobNo, setJobNo] = useState([]);
  const [partsData, setPartsData] = useState([]);
  const [mechanic, setMechanic] = useState([]);
  const [initialData, setInitialData] = useState({})
  let url = location.pathname;
  let id = url.substring(url.lastIndexOf("/") + 1);

  useEffect(() => {
    if (id != ":id") {
      setEditable(true);
    } else {
      setEditable(false);
    }
    getJobNo();
    getPartsDetails();
    getMechanic();
    getSelectedMaterial();
    if (id != ":id") {
      platformApi
        .get(`/api/materialIssue/${id}`)
        .then((res) => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setInitialData(response.data);
            } else {
              message.error("Unable to fetch material Issue Item", 2);
            }
          } else {
            message.error("Unable to fetch material Issue Item", 2);
          }
        })
        .catch((error) => {
          console.error("Error on material Issue Item : ", error);
          message.error("Unable to fetch  material Issue Item", 2);
        });
    }
  }, []);

  const getJobNo = () => {
    platformApi
      .post("/api/jobOrder/getJobNo", {
        size: 100,
        page: 1,
      })
      .then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setJobNo(response.data);
          } else {
            message.error("Failed to fetch Job Order");
          }
        } else {
          message.error("Failed to fetch Job Order");
        }
      })
      .catch((error) => {
        console.error("Error on Job Order : ", error);
        message.error("Failed to fetch Job Order");
      });
  };
  const getPartsDetails = () => {
    platformApi
      .post(`/api/sparesInventory/branch/get`, {
        page: 1,
        size: 100,
        searchString: "",
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            let parts = [];
            // console.log("response", response.data)

            for (let item of response.data) {
              if (item.phyQuantity > 0) {
                parts.push(item.partNo);
              }
            }
            // console.log("parts", parts)
            setPartsData(parts);
          }
        } else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        message.error("Unable to fetch Parts", 2);
      });
  };
  // code have to change(Mechanic)
  const getMechanic = () => {
    platformApi
      .get("/api/user")
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let mechanic = data.data.users.filter((item) => {
            return (
              item.profile &&
              item.profile.department.role === "Mechanic" &&
              item.status === true
            );
          });
          setMechanic(mechanic);
          // setMechanic(data.data.users);
        }
      })
      .catch((error) => {
        console.error("Error on MaterialIssue form : ", error);
        message.error("Unable to fetch joborder", 2);
      });
  };
  const getSelectedMaterial = () => {
    if (id != ":id") {
      platformApi
        .get(`/api/materialIssue/${id}`)
        .then((res) => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setEditData(response.data);
            } else {
              message.error("Unable to fetch material Issue Item", 2);
            }
          } else {
            message.error("Unable to fetch material Issue Item", 2);
          }
        })
        .catch((error) => {
          console.error("Error on material Issue Item : ", error);
          message.error("Unable to fetch  material Issue Item", 2);
        });
    }
  };
  return (
    <>
      <Title level={4}>
        <Tooltip placement="topLeft" title={"Back"}>
          <Button
            style={{ marginRight: "1rem" }}
            onClick={() => history.push("/autoadmin/material_issue")}
          >
            <LeftOutlined />
          </Button>
        </Tooltip>
        Material Issue
      </Title>
      <WrappedMaterialForm
        editable={editable}
        modifyType={modifyType}
        jobNo={jobNo}
        setJobNo={setJobNo}
        partsData={partsData}
        setPartsData={setPartsData}
        mechanic={mechanic}
        setMechanic={setMechanic}
        id={id}
        onEdit={(editable) => {
          setEditable(editable);
          setModifyType(!editable);
        }}
        editData={editData}
        initialData={initialData}
      />
    </>
  );
};
export default MaterialIssueForm;
