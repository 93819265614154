import React, { useState } from "react";
import "./index.css";
import { message, Modal, Typography } from "antd";
import { platformApi } from "../../../../api";

const { Text, Paragraph, Title } = Typography;

function Enquiry() {
  const key = "updatable";
  const [name, setname] = useState("");
  const [number, setnumber] = useState("");
  const [open, setopen] = useState(false);
  const submitValue = (e) => {
    e.preventDefault();
    const regName = /^[a-zA-Z ]*$/;
    const phoneno = /^\d{10}$/;
    if (!regName.test(name) || name === "") {
      message.error("Invalid name");
      setname("");
      setnumber("");
    } else if (phoneno.test(number)) {
      setopen(true);
    } else {
      message.error("Invalid Number", 2);
      setname("");
      setnumber("");
    }
  };
  const handlesubmit = () => {
    message.loading({ content: "Sending...", key });
    platformApi.post("/api/idGenerate/customer").then((result) => {
      const { data } = result;
      if (data.code === 200) {
        const customerId = data.response.data;
        const frmdetails = {
          name,
          phone: number,
          customerId,
        };
        platformApi
          .post("/api/enquiry", frmdetails)
          .then((res) => {
            if (res.data.response.code === 400) {
              message.error("Enquiry with phone number and name already exists", 2)
            }
            else if (res.data.response.code === 200) {
              message.success("Sent!", 2);
            }
            setname("");
            setnumber("");
            setopen(false);
          })
          .catch((err) => {
            message.error("Unable to send the data ", 2);
            console.error("Error in details : ", err);
          });
      }
    });
  };
  return (
    <div className="Enquiry">
      <div className="Enquiry-title">Enquiry Form</div>
      <div className="Enquiry-description">
        Kindly fill your details in the form below, so a Nandi Yamaha
        spokesperson can get in touch with you.
      </div>
      <div className="Enquiry-form">
        <form>
          <div className="inputs">
            <p htmlFor="name" id="name">
              Name
            </p>
            <input
              type="text"
              name="name"
              value={name}
              onChange={(e) => setname(e.target.value)}
            />
          </div>
          <div className="inputs">
            <p id="mobilenumber" htmlFor="mobilenumber">
              Mobile Number
            </p>
            <input
              type="text"
              name="mobilenumber"
              value={number}
              maxLength={10}
              onChange={(e) => setnumber(e.target.value)}
            />
          </div>
          <div className="inputs">
            <p id="submit" htmlFor="submit" />
            <input name="submit" type="submit" onClick={submitValue} />
          </div>
        </form>
        <Modal
          visible={open}
          onOk={handlesubmit}
          onCancel={() => {
            setopen(false);
            setname("");
            setnumber("");
            message.error("Message not sent..", 2);
          }}
          title={<Title level={4}>Awesome!!</Title>}
        >
          <Paragraph>
            Our experts are on it We will send some recommendations for your
            preferences as soon as possible
          </Paragraph>
          <div className="modalDisplay">
            <div>
              <Text type="secondary" strong>
                Name
              </Text>{" "}
              : <Text strong>{name}</Text>
            </div>
            <div>
              <Text type="secondary" strong>
                Number
              </Text>{" "}
              : <Text strong>{number}</Text>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default Enquiry;
