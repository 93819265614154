import React, { useContext, useState, useEffect } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
} from "antd";
import { platformApi } from "../../../../api";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../../ContextAPI";
import { getUUID } from '../../../../_helpers/utils'
const{Text}=Typography;

const PMCTable = ({
  dataSource,
  pagination,
  spinner,
  modify,
  deleteData,
  setVisible,
  setEditable,
  setEditableData,
  deleteAccess,
  modifyAccess  
}) => {
  const [tableKey, setTableKey] = useState(getUUID())

  const columns = [
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      render: (record) => (
        <span>
          {record && record}
        </span>
      ),
    },
    {
      title: "Service KM",
      dataIndex: "serviceKm",
      key: "serviceKm",
      render: (record) => <span>{record && record}</span>,
    },
    {
      title: "Vehicle Suitable",
      dataIndex: "vehicleSuitable",
      key: "vehicleSuitable",
      render: (item) => { return item.length > 2 ? <span><div style={{ maxHeight: '60px', overflow: 'hidden' }}>{item && item.map((record) => <span>{record.modelCode}-{record.modelName}<br /></span>)}</div>...</span> : item && item.map((record) => <span>{record.modelCode}-{record.modelName}<br /></span>) }
    },
    {
      title: "Parts Consumed",
      dataIndex: "partsConsumable",
      key: "partsConsumable",
      render: (item) => {
        return item.length > 2 ?
          <span><div style={{ maxHeight: '60px', overflow: 'hidden' }}>{item && item.map((record) => <span>{record.partNumber.partNumber}-{record.partNumber.partName}<br /></span>)}</div>...</span>
          : item && item.map((record) => <span>{record.partNumber.partNumber}-{record.partNumber.partName}<br /></span>)
      }
    },
    {
      title: "Job Code",
      dataIndex: "jobCodes",
      key: "jobCodes",
      render: (item) => {
        return item.length > 2 ?
          <span><div style={{ maxHeight: '60px', overflow: 'hidden' }}>{item && item.map((record) => <span>{record.jobCode.code}<br /></span>)}</div>...</span>
          : item && item.map((record) => <span>{record.jobCode.code}<br /></span>)
      }
    },
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div>{modifyAccess?
          
          <span
            onClick={(event) => {
              event.stopPropagation();
              setEditable(true)
              setVisible(true)
              setEditableData(data)
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
          <span></span>
    }
          {deleteAccess?
            <Popconfirm
              title="Are you sure delete this PMC?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                <Divider type="vertical" />
                  Delete
                 </span>
            </Popconfirm>
            :
            <span></span>
          }
          {
            !modifyAccess && !deleteAccess
            ?
            <Text strong disabled> No Access Given</Text>
            :
            <span></span>
          }
        </div>
      ),
    },
  ];
  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={columns}
      tableLayout="auto"
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      loading={spinner}
      onRow={(data) => ({
        onClick: () => {
          setVisible(true)
          setEditableData(data)
        }
      })}
    />
  )
}

export default withRouter(PMCTable)