import React, { useEffect, useState } from 'react';
//import SettingsIcon from '@material-ui/icons/Settings';
import DonutLargeIcon from '@material-ui/icons/DonutLarge';
import ChatIcon from '@material-ui/icons/Chat';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Avatar, IconButton } from '@material-ui/core';
import './Sidebar.css'
import { SearchOutlined } from '@material-ui/icons';
import SidebarChat from './SidebarChat/SidebarChat';
import { platformApi } from "../../../../../../api";
import avatarhead from "../../../../../../assets/avataricon.png";

const Sidebar = (props) => {

    const { onFetchChat, setUsrId, usrId } = props
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState(null);

    useEffect(() => {
        platformApi.get("/api/whatsapp/getChatList").then(res => {
            let { data } = res;
            setUsers(data.msg.data)
            // console.log(data.msg.data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    // every 7 secs update
    useEffect(() => {

        const id = setInterval(() => {
            platformApi.get("/api/whatsapp/getChatList").then(res => {
                let { data } = res;
                setUsers(data.msg.data)
                // console.log(data.msg.data)
            }).catch(err => {
                console.log(err)
            })
        }, 7000);
        return () => clearInterval(id);

    }, [users])




    const searchFilter = (e) => {
        let search = e.target.value;
        setSearch(search)
        platformApi.post("/api/whatsapp/search/filter",
            {
                searchString: search
            }
        ).then(res => {
            let { data } = res;
            if (data.msg) {
                setUsers(data.msg.data)
            }
        }).catch((err) => {
            console.log("Something went wrong!!")
        })
    }



    // useEffect(() => {
    //     let id;
    //     if (search) {
    //         id = setInterval(() => {
    //             platformApi.post("/api/whatsapp/search/filter",
    //                 {
    //                     searchString: search
    //                 }
    //             ).then(res => {
    //                 let { data } = res;
    //                 if (data.msg) {
    //                     setUsers(data.msg.data)
    //                 }
    //             }).catch((err) => {
    //                 console.log("Something went wrong!!")
    //             })
    //         }, 7000);
    //     }
    //     return () => clearInterval(id);
    // }, [search])



    return (
        <div className="sidebar" style={{
            marginTop: '-1.5rem'
        }}>
            <div className="sidebar-header">
                <IconButton>
                    {/* <Avatar src={avatarhead} /> */}
                </IconButton>

                {/* <div className="sidebar-right">
                    <IconButton>
                        <ChatIcon />
                    </IconButton>
                    <IconButton>
                        <MoreVertIcon />
                    </IconButton>
                </div> */}
            </div>
            <div className="sidebar-search">
                <div className="search-container">
                    <SearchOutlined />
                    <input
                        style={{ border: 'none', marginLeft: '10px', outline: "none" }}
                        placeholder="Search or start a new chat"
                        type="text"
                        onChange={searchFilter}
                    />
                </div>
            </div>
            <div style={{
                backgroundColor: '#ffffff',
                flexDirection: 'column',
                width: '317px',
                overflowY: 'scroll',
                height: '30rem'
            }}>

                {users.map((data) => {
                    return <SidebarChat
                        onFetchChat={onFetchChat}
                        setUsrId={setUsrId}
                        data={data}
                        usrId={usrId}
                    />
                })}
            </div>

        </div>
    )
}

export default Sidebar
