import React, { useEffect, useState } from 'react';
import moment from "moment";
import { message, Table } from "antd";
import { platformApi } from "../../../../api"
import WhatsappMessageModal from './WhatsappMessageModal/whatsappMessageModal';


const whatsappHistoryLogs = () => {

    const [data, setData] = useState([])
    const [spinning, setSpinning] = useState(true);
    const [limit, setLimit] = useState(10)
    const [visible, setVisible] = useState(false)
    const [message, setMessage] = useState("")

    useEffect(() => {
        setSpinning(true)
        platformApi.get("/api/whatsapp/whatsappLogStatus")
            .then(response => {
                if (response.data.code === 200) {
                    setData(response.data.response.data)
                    setSpinning(false)
                } else {
                    setSpinning(false)
                    message.error("Unable to fetch whstapp logs", 2)
                }
            })
            .catch(err => {
                setSpinning(false)
                message.error("Unable to fetch whstapp logs", 2)
            })
    }, [])

    const columns = [
        {
            title: "Sent On",
            dataIndex: "sentTime",
            key: "sentTime",
            render: (text) => (
                <span>{text ? moment(new Date(text)).format("DD/MM/YYYY") : "  -  "} </span>
            ),
        },
        {
            title: "Sent Time",
            dataIndex: "sentTime",
            key: "sentTime",
            render: (text) => (
                <span>{text ? moment(new Date(text)).format("hh:mm:A") : "  -  "} </span>
            ),
        },
        {
            title: "Module",
            dataIndex: "module",
            key: "module",
        },
        {
            title: "Type",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Contact Number",
            dataIndex: "destination",
            key: "destination",
        },
        {
            title: "Delivery Status",
            dataIndex: "status",
            key: "status",
        },

        {
            title: "Delivery Date",
            dataIndex: "deliveredTime",
            key: "deliveredTime",
            render: (text) => (
                <span>{text ? moment(new Date(text)).format("DD/MM/YYYY") : "  -  "} </span>
            ),
        },

        {
            title: "Delivery Time",
            dataIndex: "deliveredTime",
            key: "deliveredTime",
            render: (text) => (
                <span>{text ? moment(new Date(text)).format("hh:mm:A") : "  -  "} </span>
            ),
        },
        // {
        //     title: "Message",
        //     render: (data) => {
        //         return (
        //             <span
        //                 onClick={(e) => {
        //                     e.stopPropagation();
        //                     props.openData(data.id, true);
        //                 }}
        //                 className="linkylink"
        //             >
        //                 View
        //             </span>
        //         );
        //     },
        // },
    ];



    return (
        <>
            <Table
                columns={columns}
                dataSource={data}
                loading={spinning}
                style={{ cursor: "pointer" }}
                pagination={{
                    pageSize: limit,
                    defaultCurrent: 1,
                    showQuickJumper: true
                }}
                onRow={(record) => ({
                    onClick: () => {
                        setVisible(true)
                        setMessage(record.text)
                    }
                })}
            />

            <WhatsappMessageModal
                message={message}
                close={() => setVisible(false)}
                open={visible}
            />
        </>
    )
}

export default whatsappHistoryLogs
