import React, { useState, useEffect } from 'react'
import { Card, List, Row, Col, message, Tag } from 'antd'
import moment from 'moment';
import {
  EyeFilled,
  EditFilled,
} from '@ant-design/icons';
import '../style.less'

const BookingActivity = (props) => {
  const { item, openEditor } = props
  return (
    <div className="activity-card">
      <table>
        {/* First Row */}
        <tr>
          <td>
            <strong>Booking Activity </strong>
          </td>
          <td rowSpan={4} className="vat bb-none">
            {
              item.remarks && item.remarks.length ?
                <>
                  <strong>Remarks:</strong><br />
                  {item.remarks}
                </>
                : null}
            <br />
            <br />
            <Card style={{ maxHeight: '150px' }}>
              <Row type="flex" direction="column" justify="space-between" gutter={[16, 8]}>
                <Col span={7}>
                  <Card >
                    <span><img src={(item.booking.color) ? item.booking.color.url : ""} width="40%" height="auto" alt="" /></span>

                  </Card>
                </Col>
                <Col span={13} className="fs11">
                  {(item.booking.vehicle) ? <strong>{item.booking.vehicle.modelName} - {item.booking.vehicle.modelCode}</strong> : null}<br />
                  {(item.booking.color) ? <><strong>Color Code: </strong>{item.booking.color.code}</> : null}
                </Col>
              </Row>
            </Card>
            <br />
            {
              item.createdBy &&
              <div>
                <strong>Employee:</strong> {item.createdBy.profile.employeeName}
              </div>
            }
          </td>
          <td align="center">
            <strong>{item.createdAt ? moment(item.createdAt).format('DD-MM-YYYY HH:mm') : ' -'}</strong>
          </td>
        </tr>
        {/* Second Row */}
        <tr>
          <td rowSpan={2}>
            {
              item.booking.sms ?
                <> <strong>Phone No: </strong>{item.booking.sms.phone}<br /></> : null
            }
            {
              (item.booking.bookingId) ?
                <>
                  <strong>Booking ID: </strong> {item.booking.bookingId}<br />
                </>
                : null
            }
            <strong>Customer Authorisation: </strong>
            {(item.booking.authentication && item.booking.authentication?.verifiedAt) ?
              <>Verified<br /></>
              :
              <>Not verified<br /></>
            }
            {
              item.booking.sms ?
                <><strong>SMS: </strong> {item.booking.sms.smsStatus}<br /></> : null
            }
          </td>
          <td align="center">
            {
              item.booking.bookingStatus ?
                <><strong>Supervisor Authorisation: </strong> {item.booking.bookingStatus}<br /></> : null
            }
          </td>
        </tr>
        {/* Third Row */}
        <tr>
          <td align="center">
            {
              item.enquiryType ?
                <strong className={`center pa ${item.enquiryType}`}>{item.enquiryType}</strong> : null
            }
          </td>
        </tr>
        <tr>
          <td>
            {
              item.scheduleDateAndTime ?
                <>
                  <strong>FollowUp Date: </strong>{moment(item.scheduleDateAndTime).format("DD-MM-YYYY")}<br />
                  <strong>FollowUp Time: </strong>{moment(item.scheduleDateAndTime).format("HH:mm")}
                </>
                : null
            }
          </td>
          <td>
            <Row>
              <Col className="center pointer icon" onClick={() => openEditor(item.id)} span={12}>
                <EyeFilled />
              </Col>
              <Col className="bl center pointer icon" onClick={() => openEditor(item.id, true)} span={12}>
                <EditFilled />
              </Col>
            </Row>
          </td>
        </tr>
      </table>
    </div>
  )
}

export default BookingActivity;
