import React, { useState, useEffect, useRef } from "react";

import {
  Modal,
  Typography,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Input,
  Button,
  message,
} from "antd";
import WrappedPartsTable from "../PartsTable";
import { platformApi } from "../../../../api";
// import "./index.less";
import moment from "moment";
const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;

function MaterialsForm({
  editable: editData,
  form,
  open,
  close,
  data,
  emitData,
}) {
  const ref = useRef();
  const [partData, setPartData] = useState([]);
  const [editable, setEditable] = useState(null);
  const [partLoading, setpartLoading] = useState(false);
  const [date, setDate] = useState("default");
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  const [error, setError] = useState({});
  const [editPart, setEditPart] = useState("");
  const [jobnum, setJobnum] = useState([]);
  const [part, setPart] = useState([]);
  const [mechanic, setMechanic] = useState([]);

  const tabledata = {
    part: "",
    description: "",
    quantity: "",
    mrp: "",
    issuetype: "",
  };

  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;

  useEffect(() => {
    if (open) {
      setEditable(editData);
      addParts(tabledata);
      if (!data)
        platformApi
          .get("/api/jobOrder")
          .then((result) => {
            const { data } = result;
            if (data.code === 200) {
              setJobnum(data.response.data);
            }
          })
          .catch((error) => {
            console.error("Error on MaterialIssue form : ", error);
            message.error("Unable to fetch joborder", 2);
          });
      platformApi
        .get("/api/user")
        .then((result) => {
          let { data } = result;
          if (data.code === 200) {
            setMechanic(data.data.users);
          }
        })
        .catch((error) => {
          console.error("Error on MaterialIssue form : ", error);
          message.error("Unable to fetch joborder", 2);
        });
      if (data) {
        let dummypart = [];
        let dummyarray = [];
        form.setFieldsValue({
          customer: data.job.customer.name,
          vehicle: data.job.vehicle.registerNo,
          model: data.job.vehicle.vehicle.modelName,
          engineNo: data.job.vehicle.engineNo,
          chassisNo: data.job.vehicle.chassisNo,
          issueDate: moment(date.issueDate),
          mechanic: data.mechanic,
          job: data.job.jobNo,
        });
        data.parts.map((part) => {
          dummypart = {
            ...part.part,
          };
          dummypart.quantity = part.quantity;
          dummypart.description = part.description;
          dummypart.issueType = part.issueType;
          dummypart.id = part.id;
          dummypart.mrpquantity = part.part.mrp * part.quantity;
          dummyarray.push(dummypart);
        });
        setPartData(dummyarray);
      }
    }
  }, [open]);

  // useEffect(() => {

  // }, [data]);
  const addParts = (data) => {
    setPartData([...partData, data]);
  };
  const clearFields = () => {
    ref.current();
    resetFields();
    setPartData([]);
  };
  const modifyData = () => {
    addParts(tabledata);
    setEditable(true);
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const handleJoborderChange = (id) => {
    const index = jobnum.findIndex((obj) => obj.id === id);
    form.setFieldsValue({
      customer: jobnum[index].customer.name,
      vehicle: jobnum[index].vehicle.registerNo,
      model: jobnum[index].vehicle.vehicle.modelName,
      engineNo: jobnum[index].vehicle.engineNo,
      chassisNo: jobnum[index].vehicle.chassisNo,
    });
  };
  const handleSubmit = () => {
    const parts = partData.filter((mockChallan) => {
      return mockChallan.part;
    });
    validateFields((validationError) => {
      if (validationError === null) {
        if (parts[0]) {
          const job = form.getFieldValue("job");
          const mechanic = form.getFieldValue("mechanic");
          let dob = form.getFieldValue("issueDate");
          const issueDate = dob.format("DD-MM-YYYY");
          const issue = {
            job,
            issueDate,
            parts,
            mechanic,
          };
          data ? (parts.id = data.id) : (parts.id = null);
          emitData(issue);
          clearFields();
          close();
        } else {
          message.error("Select atleast one Part", 2);
        }
      }
    });
  };
  const dateChange = (e) => {
    if (e) {
      const date = new Date(e._d);
      setDate(date.toLocaleString());
    }
  };

  const deleteData = (id, index) => {
    if (id) {
      setpartLoading(true);
      platformApi
        .delete("api/materialIssue/number/" + id)
        .then((result) => {
          setpartLoading(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setPartData(partData.filter((chData) => chData.id !== id));
              message.success("Parts Deleted Successfully", 2);
            } else {
              message.error("Unable to delete Parts");
            }
          } else {
            message.error("Unable to delete Parts");
          }
        })
        .catch((error) => {
          console.error("Error on Parts Purchase Challan : ", error);
          message.error("Unable to delete Parts");
          setpartLoading(false);
        });
    } else {
      setPartData(partData.filter((chData, ind) => ind !== index));
    }
  };
  return (
    <div>
      <Modal
        style={{ top: "2rem" }}
        width={"70vw"}
        title={<Title level={4}>Material Issue</Title>}
        visible={open}
        okText={editable ? "Save" : "Modify"}
        onCancel={() => {
          clearFields();
          close();
        }}
        onOk={() => (editable ? handleSubmit() : modifyData())}
      >
        <Form>
          <Row>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Item label="Job No" colon={false}>
                  {getFieldDecorator("job", {
                    rules: [{ required: true, message: "Enter jobNo" }],
                  })(
                    <Select
                      disabled={!editable}
                      onChange={handleJoborderChange}
                      placeholder="jobNo"
                      showSearch
                      filterOption={filterMethod}
                    >
                      {jobnum.map((code) => (
                        <Select.Option key={code.id}>
                          {code.jobNo}{" "}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
              {/* <Col span={7}>
                <Item label="Mechanic" colon={false}>
                  {getFieldDecorator("mechanic", {
                    rules: [{ required: false, message: "Enter mechanic" }],
                  })(
                    <Select placeholder="mechanic" disabled={!editable}>
                      {mechanic.map((obj) => (
                        <Select.Option key={obj.id}>
                          {obj.profile.employeeName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col> */}
              <Col span={7}>
                <Item label="Customer" colon={false}>
                  {getFieldDecorator("customer", {
                    rules: [{ required: true, message: "Enter customer" }],
                  })(<Input placeholder="customer" disabled />)}
                </Item>
              </Col>
              {/* <Col span={5}>
                <Item label="issueDate" colon={false}>
                  {getFieldDecorator("issueDate", {
                    rules: [{ required: true, message: "Enter issueDate" }],
                  })(
                    <DatePicker
                      style={{ width: "100%" }}
                      size={date}
                      format="DD/MM/YYYY"
                      disabled={!editable}
                      allowClear
                      onChange={(e) => dateChange(e)}
                      disabledDate={(moment) => Date.now() < moment._d}
                    />
                  )}
                </Item>
              </Col> */}
              <Col span={7}>
                <Item label="Chassis Num" colon={false}>
                  {getFieldDecorator("chassisNo", {
                    rules: [{ required: true, message: "Enter chassisNo" }],
                  })(<Input placeholder="Enter chassisNo" disabled />)}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Item label="Vehicle" colon={false}>
                  {getFieldDecorator("vehicle", {
                    rules: [{ required: true, message: "Enter vehicle" }],
                  })(<Input placeholder="vehicle" disabled />)}
                </Item>
              </Col>
              <Col span={7}>
                <Item label="Model" colon={false}>
                  {getFieldDecorator("model", {
                    rules: [{ required: true, message: "Enter model" }],
                  })(<Input placeholder="model" disabled />)}
                </Item>
              </Col>
              <Col span={7}>
                <Item label="Engine Num" colon={false}>
                  {getFieldDecorator("engineNo", {
                    rules: [{ required: true, message: "engineNo" }],
                  })(<Input placeholder="engineNo" disabled />)}
                </Item>
              </Col>
            </Row>
          </Row>
          <Row>
            <Item colon={false}>
              <WrappedPartsTable
                open={open}
                clearRef={ref}
                loading={{ spinning: partLoading }}
                delete={deleteData}
                toDelete={editable}
                dataSource={partData}
                editData={setPartData}
                date={date}
                mechanic={mechanic}
                addParts={() => addParts(tabledata)}
              />
            </Item>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

const WrappedMaterialsForm = Form.create()(MaterialsForm);

export default WrappedMaterialsForm;
