import React, { createContext, useContext, useEffect } from "react";
import { useLocalStorage } from "../utils/hooks";

const BranchContext = createContext({
  selectBranch: [],
  setSelectBranch: () => {},
});

export const useBranch = () => {
    return useContext(BranchContext);
};

const BranchProvider = (props) => {
    const [selectBranch, setSelectBranch] = useLocalStorage("branch");

    const variables = {
      selectBranch,
      setSelectBranch,
    };

    return <BranchContext.Provider value={variables}>{props.children}</BranchContext.Provider>;
};

export default BranchProvider;