import React, { useState, useEffect } from "react";
import { Form,Row,Col,Select, Button,Input,Slider,message} from "antd";
import { platformApi } from "../../../../api";
const {Item}=Form;
const {Option}=Select;
function AdvancedFilters({form,filteropen,close,searchName,setId,id,setData,setPrimaryData}) {
    const {
        getFieldDecorator,
        setFieldsValue,
        getFieldValue,
        validateFields,
        resetFields,
      } = form;
      const [model, setModels] = useState([]);
      const Group = [
        { key: "SERVICE", title: "Service" },
        { key: "PERIODICMAINTANANCE", title: "Periodic Maintenance" },
        { key: "ENGINE", title: "Engine" },
        { key: "ELECTRICAL", title: "Electrical" },
        { key: "FRAME", title: "Frame" },
      ];
      const Filters = [
          { id: "1", name:'Job Code' },
          { id: "2", name:'Group'},
          { id: "3", name:'Vehicles Associated'},
        //   { id: "4", name:'Price Range' },
        ];

        //price range

        function formatter(value) {
            return `${value*1000}`;
          }
          const marks = {
            0: '₹25000',
            25: '₹50000',
            50: '₹75000',
            75:'₹100000',
            100: '₹125000',
          };
        const selectFilter=(id)=>{
           setId(id);
        }
        const searchValue=()=>{
            setId([]);
            resetFields();
            setPrimaryData();
        }
        const deleteFilter=(element)=>{
            let changedfilter=[];
            id.map((filter)=>{
                if(filter!==element){
                    changedfilter.push(filter);
                }
            })
           setId(changedfilter);
           setFieldsValue({selectfilter:changedfilter,})
        }
        useEffect(() => {
            setFieldsValue({selectfilter:id})
        }, [filteropen]);
   
        useEffect(()=>{
                if(id.includes("3"))
                {
                    platformApi.get(`/api/vehicleMaster`).then((res) => {
                        const { data } = res;
                        if (data.code === 200) {
                          const { response } = data;
                          if (response.code === 200) {
                            setModels(response.data);
                          }
                        }
                      });
                }
        },[id])

        const handleSubmit=()=>{
            const data={
                code:getFieldValue('jobcode'),
                group:getFieldValue('group'),
                vehiclemodel:getFieldValue('vehicles'),
            }
        platformApi
        .post("/api/jobCode/search", data)
        .then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setData(response.data);
              message.success("Filtered successfully", 2);
            } 
            else {
              message.error("Unable to fetch", 2);
            }
          } 
          else {
            message.error("Unable to fetch", 2);
          }
        })
        .catch((error) => {
          console.error("Error in filtering : ", error);
          message.error("Unable to fetch", 2);
        });
        }
        const branchSelect=(id)=>{
        }
        const filterMethod = (input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    
    return (
        <div >
            {filteropen&&
           <div className="AdvancedFilters">
                <Form >
                    <Row type="flex" justify="space-between">
                    <Col span={14}>
                        <Row type="flex" justify="start">
                            {id.map((element)=>
                            element==="1"?
                            <Col span={8}>
                                <Item colon={false}>
                                    <Row type="flex"> 
                                        <Col span={20}>
                                        {getFieldDecorator("jobcode")(
                                            <Input
                                                placeholder="Job Code"
                                            />
                                        )}
                                        </Col>
                                        <Col span={2}>
                                            <Button type="ghost" shape="circle"size="small" onClick={()=>deleteFilter(element)}>&#10005;</Button>
                                        </Col>
                                    </Row>
                                </Item>
                            </Col>
                        :
                        element==="2"?
                        <Col span={8}>
                            <Item colon={false}>
                                <Row type="flex"> 
                                    <Col span={20}>
                                    {getFieldDecorator("group")(
                                       <Select 
                                       placeholder="Group"
                                       onChange={branchSelect}
                                       filterOption={filterMethod}
                                       showSearch
                                       showAction={["click","focus"]}
                                   >
                                      {Group.map((group)=>(
                                       <Select.Option value={group.key} key={group.key}>
                                           {group.title}
                                       </Select.Option>
                                      ))
                                      }
                                   </Select>
                                    )}
                                    </Col>
                                    <Col span={2}>
                                        <Button type="ghost" shape="circle"size="small" onClick={()=>deleteFilter(element)}>&#10005;</Button>
                                    </Col>
                                </Row>
                        
                            </Item>
                        </Col>
                        :
                        element==="3"?
                        <Col span={8}>
                                <Item colon={false}>
                                    <Row type="flex"> 
                                        <Col span={20}>
                                        {getFieldDecorator("vehicles")(
                                            <Select 
                                            placeholder="Vehicles Associated"
                                            onChange={branchSelect}
                                            filterOption={filterMethod}
                                            showSearch
                                            showAction={["click","focus"]}
                                            >
                                              {model.map((model)=>(
                                                <Select.Option value={model.modelName} key={model.id}>
                                                    {model.modelName}
                                                </Select.Option>
                                                ))
                                                }
                                            </Select>
                                        )}
                                        </Col>
                                        <Col span={2}>
                                            <Button type="ghost" shape="circle"size="small" onClick={()=>deleteFilter(element)}>&#10005;</Button>
                                        </Col>
                                    </Row>
                                </Item>
                        </Col>
                           
                            :
                            <Col span={14}>
                                <Item colon={false}>
                                    <Row type="flex"> 
                                    <Col span={20} style={{padding:"0px 20px"}} className="RangeSlider">
                                        {getFieldDecorator("Pricerange")(
                                            <Slider onChange={branchSelect} range marks={marks} tipFormatter={formatter} defaultValue={[25,50]} />
                                        )}
                                        </Col>
                                        <Col span={2}>
                                            <Button type="ghost" shape="circle"size="small" style={{marginLeft:"0.5rem"}} onClick={()=>deleteFilter(element)}>&#10005;</Button>
                                        </Col>
                                    </Row>
                                </Item>
                            </Col>
                           
                            )}
                        </Row>
                    </Col>
                    <Col span={10}>
                       <Row type="flex" justify="space-between">
                           <Col span={19}>
                                <Item colon={false}>
                                    {getFieldDecorator("selectfilter")(
                                    <Select
                                        placeholder="Select the Filter"
                                        onChange={selectFilter}
                                        mode="multiple"
                                        showAction={["click","focus"]}
                                    >
                                        {Filters.map((type) => (
                                        <Option key={type.id}>{type.name}</Option>
                                        ))}
                                    </Select>
                                    )}
                                </Item>
                           </Col>
                           <Col span={5} style={{marginTop:"0.2rem"}}>
                                <Button type="primary" onClick={handleSubmit}>
                                    Search
                                </Button>
                           </Col>
                           
                       </Row>
                       <Row type="flex" justify="end" style={{marginRight:"0.79rem"}}>
                           <Button type="ghost" style={{width:"4.5rem"}} onClick={searchValue}>
                                Clear
                            </Button>
                       </Row>
                      </Col>
                    </Row>    
                </Form>
           </div>
            }
        </div>
    )
}

const WrappedAdvancedFilter = Form.create()(
    AdvancedFilters
  );  
export default WrappedAdvancedFilter;
