import React, { useContext, useState, useEffect } from "react";
import minimize from "../../../../assets/minimize.svg"
import edit from "../../../../assets/edit.svg"
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
  Modal,
  Form,
  Select,
  Input,
  Icon,
} from "antd";
import "./index.less";
import { platformApi } from "../../../../api";
import { range } from "lodash";
import WrappedTargetModal from "../TargetModal";
const { Title } = Typography
const { Item } = Form
const { Option } = Select

const TargetForm = ({
  dataSource,
  visible,
  setVisible,
  emitData,
  editableData,
  editable,
  setEditable,
  setEditableData,
  setDataSource,
  form
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  const [employees, setEmployees] = useState([])
  const [oneEmployee, setOneEmployee] = useState({})
  const [groupInputs, setGroupInputs] = useState([])
  const [targetDataList, setTargetDataList] = useState([])
  const [targetModal, setTargetModal] = useState(false)
  const [deleteData, setDeleteData] = useState([])
  const [editTarget, setEditTarget] = useState(false)
  const [editTargetData, setEditTargetData] = useState({})
  useEffect(() => {
    if (editableData.employee) {
      let list = [...editableData.targetData]
      setTargetDataList(list)
      setFieldsValue({ "employee": editableData.employee.profile?.employeeId })
      setOneEmployee(editableData.employee)
    }
  }, [editableData])
  useEffect(() => {
    platformApi
      .post("/api/user/get/employee")
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          // console.log(data.response.data.users)
          let employees = [];
          employees = data.response.data.users.filter(i => {
            return i.status === true
          })
          setEmployees(employees);
        }
      })
      .catch((err) => {
        console.error("User Fetch Error: ", err);
        message.error("Couldn't Fetch Employees");
      });
    // setTargetDataList([...targetDataList,TargetData])
  }, [])

  const setEmployeeData = (data) => {
    if (data == undefined) {
      setOneEmployee({})
      return
    }
    for (let item of employees) {
      if (data == item.profile.employeeId) {
        setOneEmployee(item)
        return
      }
    }
  }
  const checkEmployee = (id) => {
    for (let item of dataSource) {
      if (id === item.employee.profile.employeeId) {
        return false
      }
    }
    return true
  }

  const removeTargetData = (data) => {
    if (data.id) {
      deleteData.push(data)
      setDeleteData(deleteData)
    }
    var filteredAry = targetDataList.filter(item => { return item.targetType != data.targetType })
    setTargetDataList(null)
    setTargetDataList([...filteredAry])
  }

  const clearFields = () => {
    setVisible(false)
    setTargetDataList([])
    form.resetFields()
    setOneEmployee({})
    setEditableData([])
    setEditable(false)
    setDataSource([...dataSource])
    setDeleteData([])
  }
  const handleSubmit = () => {
    if (!editable) {
      setEditable(true)
      return
    }
    validateFields(err => {
      if (err == null) {
        if (Object.keys(editableData).length > 0) {
          let Data = {
            id: editableData.id,
            employee: oneEmployee.id,
            targetData: targetDataList,
            deleteData: deleteData
          }
          emitData(Data)
          clearFields()
        }
        else {
          let valid = true
          for (let item of dataSource) {
            if (item.employee.id == oneEmployee.id) {
              valid = false
            }
          }
          if (valid) {
            let Data = {
              employee: oneEmployee.id,
              targetData: targetDataList
            }
            emitData(Data)
            clearFields()
          }
          else {
            message.error("Employee Already Added")
          }
        }
      }
    })
  }

  return (
    <Modal
      wrapClassName="target-form"
      visible={visible}
      title="Target Master"
      okButtonProps={{ disabled: Object.keys(oneEmployee).length > 0 && targetDataList.length > 0 ? false : true }}
      onCancel={() => { clearFields() }}
      onOk={handleSubmit}
      okText={editable ? "OK" : "Modify"}
    >
      <Form>
        <Row>
          <Col span={10}>
            <Item label="Employee ID" >
              {getFieldDecorator("employee", {
                rules: [{ required: true, message: "Select Employee ID" }],
              })(
                <Select showAction={["click", "focus"]} placeholder="Employee ID" disabled={!editable || Object.keys(editableData).length > 0} onChange={setEmployeeData} allowClear>
                  {employees.map(item => { return checkEmployee(item.profile.employeeId) ? <Option value={item.profile.employeeId} key={item.profile.employeeId}>{item.profile.employeeId + " - " + item.profile.employeeName}</Option> : null })}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={2} />
          <Col span={7} style={{ marginLeft: '1rem' }}>
            <Item
              label="Department"
            >
              <h3 > {oneEmployee.profile != undefined ? oneEmployee.profile.department.role : ""}</h3>
            </Item>
          </Col>
          <Col span={2} />
          {editable ? targetDataList.length < 3 ? < Col span={2}>
            <Item>
              <Button type="primary" style={{ marginTop: '2.5rem' }} disabled={Object.keys(oneEmployee).length > 0 ? false : true}
                onClick={() => { setTargetModal(true) }}> Add</Button>
            </Item>
          </Col> : null : null}
        </Row>
        <WrappedTargetModal
          setTargetModal={setTargetModal}
          targetModal={targetModal}
          targetDataList={targetDataList}
          setTargetDataList={setTargetDataList}
          editTarget={editTarget}
          setEditTarget={setEditTarget}
          editTargetData={editTargetData}
          setEditTargetData={setEditTargetData}
        />
        {targetDataList.length > 0 ? <span> <Divider /><Title level={4}>Target Data :</Title></span> : <span></span>}
        {/* {targetDataList!=null&&targetDataList.map((item)=><span>{item.targetType}</span>)} */}
        <div style={{ maxHeight: '300px', overflowY: 'scroll' }}>
          {targetDataList != null && targetDataList.map((item) => {
            return (
              <span key={item.targetType}>
                <Row>
                  <Col span={editable ? 22 : 24}>
                    <Row>
                      <Col span={7}>
                        <Item label="Target Type" >
                          <Select showAction={["click", "focus"]} disabled value={item.targetType} placeholder="Select Target Type"  >
                            <Option value="Sales">Sales</Option>
                            <Option value="Mechanical">Mechanical</Option>
                            <Option value="Accessories">Accessories</Option>
                          </Select>
                        </Item>
                      </Col>
                      <Col span={1} />
                      <Col span={7} >
                        <Item
                          label="Timeline"
                        >
                          <Select disabled value={item.timeline} showAction={["click", "focus"]} placeholder="Select Timeline">
                            <Option value="Monthly">Monthly</Option>
                            <Option value="Yealry">Yealry</Option>
                          </Select>
                        </Item>
                      </Col>
                      <Col span={1} />
                      <Col span={7}>
                        <Item
                          label="Unit/Value"
                        >
                          <Select disabled value={item.value} showAction={["click", "focus"]} placeholder="Select Unit" >
                            <Option value="Units">Units</Option>
                            <Option value="Value">Value</Option>
                          </Select>
                        </Item>
                      </Col>
                    </Row>
                    <Row>
                      {Object.keys(item.monthly).length > 0 ?
                        <span>
                          <Row>
                            <Col span={3}>
                              <Item label="January">
                                <Input readOnly value={item.monthly.Jan} placeholder="Jan" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>

                              <Item label="February">
                                <Input readOnly value={item.monthly.Feb} placeholder="Feb" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="March">
                                <Input readOnly value={item.monthly.Mar} placeholder="Mar" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="April">
                                <Input readOnly value={item.monthly.Apr} placeholder="Apr" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="May">
                                <Input readOnly value={item.monthly.May} placeholder="May" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="June">
                                <Input readOnly value={item.monthly.Jun} placeholder="Jun" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                          </Row>
                          <Row>
                            <Col span={3}>
                              <Item label="July">
                                <Input readOnly value={item.monthly.Jul} placeholder="Jul" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="August">
                                <Input readOnly value={item.monthly.Aug} placeholder="Aug" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="September">
                                <Input readOnly value={item.monthly.Sep} placeholder="Sept" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="October">
                                <Input readOnly value={item.monthly.Oct} placeholder="Oct" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="November">
                                <Input readOnly value={item.monthly.Nov} placeholder="Nov" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                            <Col span={3}>
                              <Item label="December">
                                <Input readOnly value={item.monthly.Dec} placeholder="Dec" />
                              </Item></Col>
                            <Col span={1}>
                            </Col>
                          </Row></span>
                        : <span></span>}
                      {Object.keys(item.yearly).length > 0 ? Object.keys(item.yearly).map(key => {
                        return (<span key={key}><Col span={3}>
                          <Item label={key}>
                            <Input readOnly value={item.yearly[key]} />
                          </Item>
                        </Col><Col span={1}></Col></span>)
                      }) : <span></span>}
                    </Row>
                  </Col>
                  {editable ? <Col span={2}>
                    <Row>
                      <Icon component={edit} onClick={() => { setTargetModal(true); setEditTarget(true); setEditTargetData(item) }} style={{ marginTop: '8rem', fontSize: '15px', cursor: 'pointer', color: 'blue' }} />
                    </Row>
                    <Row>
                      <Popconfirm
                        title="Are you sure remove this Target Data?"
                        okText="Yes"
                        cancelText="No"
                        onCancel={(event) => {
                          event.stopPropagation();
                        }}
                        onConfirm={(event) => {
                          event.stopPropagation();
                          removeTargetData(item)
                        }}
                      >
                        <span
                          onClick={(event) => event.stopPropagation()}
                        >
                          <Icon component={minimize} style={{ marginTop: '2rem', fontSize: '15px', cursor: 'pointer', color: 'orangered' }} />
                        </span>
                      </Popconfirm>
                    </Row>
                  </Col> : null}
                </Row>
                <Divider />
              </span>
            )
          }
          )
          }
        </div>
      </Form>
    </Modal >
  )
}

const WrappedTargetForm = Form.create({ name: "TargetForm" })(TargetForm);
export default WrappedTargetForm