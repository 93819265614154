import React from "react";
import { withRouter, Route } from "react-router-dom";

// Form and Table Imports
import SaleType from "./SaleType";

function Sales() {
  return (
    <div>
      <Route exact path="/autoadmin/sales">
        <SaleType />
      </Route>
      <Route path="/autoadmin/sales/:cusId">
        <SaleType />
      </Route>
    </div>
  );
}

export default withRouter(Sales);