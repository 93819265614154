import React, { useState } from "react";
import { Typography, Input, Button, Dropdown, Menu, Icon } from "antd";
import ChallanTable from "./ChallanTable";
import ChallanModal from "./ChallanModal";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

export default function SalesChallan() {
  const [dataSource, setData] = useState([]);
  const [addFlag, setAddFlag] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [count,setCount]=useState(0);
  const getMenu = () => {
    const size = dataSource.length / 10 + (dataSource.length % 10) ? 1 : 0;
    let menus = [];
    menus.push(10);
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map(menu => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  return (
    <div>
      <div className="page-head">
        <Title style={{ width: "60%" }} level={4}>
          Sale Challan
          <span style={{ marginLeft: "1rem" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button>
            {limit} &nbsp;
            <Icon type="down" />
          </Button>
        </Dropdown>
      </span>
        </Title>
        <Search style={{ width: "30%" }} placeholder="Search Challan" />
        <Button
          onClick={() => {
            setAddFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
        >
          Add New Challan
        </Button>
      </div>
      
      <ChallanTable 
      pagination={{
        onChange: (page) => {
          setPage(page);
        },
        pageSize: limit,
        defaultCurrent: 1,
        total: count,
        showQuickJumper: true,
      }}
      dataSource={dataSource}/>
      <ChallanModal
        open={addFlag}
        close={() => {
          setAddFlag(false);
        }}
      />
    </div>
  );
}
