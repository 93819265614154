import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
    Typography,
    Input,
    Button,
    Select,
    Form,
    Col,
    Row,
    DatePicker,
    message,
    Table,
    Radio,
    Popconfirm
} from 'antd';
import moment from 'moment';
import { platformApi } from '../../../../../api';
import { getUUID } from '../../../../../_helpers/utils';
import GSTTable from './GSTTable';
import { GST18, GST28, GST12, GST5 } from '../../../../../utils';
import './index.less';

const { Title } = Typography;
const { Option } = Select;
const { Group } = Radio;
const InvoiceForm = (props) => {

    const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];
    const history = useHistory();
    const location = useLocation();
    const {
        supplier,
        partsData,
        form,
        id,
        editData,
        editable,
        onEdit,
        modifyType,
        setSearch,
        setPartSearch
    } = props
    const { getFieldDecorator } = form;
    const partItemData = {
        "id": getUUID(),
        "partNumber": {},
        "hsn": {},
        "quantity": '',
        "unitRate": '',
        "discount": '',
        "rate": '',
        "igst": 0,
        "cgst": 0,
        "sgst": 0,
        "gstRate": 0,
        "grandTotal": 0,
        "igstAmount": 0,
        "cgstAmount": 0,
        "sgstAmount": 0,
        "discountAmount": 0,
        "discountPercent": 0,
        "remove": ''
    }

    const [selectedPart, setSelectedPart] = useState("");
    const [itemList, setItemList] = useState([partItemData])
    const [selectItemObj, setSelectItemObj] = useState({});
    const [invoiceNumber, setInvoiceNumber] = useState();
    const [gst, setGst] = useState();
    const [invoiceDate, setInvoiceDate] = useState();
    const [supplierID, setSupplier] = useState();
    const [discountLevel, setDiscountLevel] = useState();
    const [tax, setTax] = useState('Tax Inclusive');
    const [discountType, setDiscountType] = useState('At Transaction Level');
    const [quantity, setquantity] = useState();
    const [unitRate, setUnitRate] = useState();
    const [rate, setRate] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0)
    const [discount, setDiscount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [grandTtl, setGrandTotal] = useState(0);
    const [adjustments, setAdjustments] = useState(0);
    const [totalInvoice, setTotalInvoice] = useState();
    const [stateName, setStateName] = useState();
    const [SGST, setSGST] = useState();
    const [CGST, setCGST] = useState();
    const [IGST, setIGST] = useState();
    const [GSTRate18, setGSTRate18] = useState(false);
    const [GSTRate28, setGSTRate28] = useState(false);
    const [GSTRate12, setGSTRate12] = useState(false);
    const [GSTRate5, setGSTRate5] = useState(false);
    const [SGSTAmount, setSGSTAmount] = useState();
    const [CGSTAmount, setCGSTAmount] = useState();
    const [IGSTAmount, setIGSTAmount] = useState();
    const [TCS, setTCS] = useState(0);
    const [deleteItem, setDeleteItem] = useState(false);
    const [formSubmit, setFormSubmit] = useState(false);
    const [branch, setBranch] = useState('');
    const [error, setError] = useState({});
    const partNumberSelectRef = useRef(null);
    const [btnFlag, setBtnFlag] = useState(false)
    const [deletePartData, setDeletePartData] = useState([])

    const partsRef = {};
    let totalAdjustments = 0;
    let tcs = 0;
    let sgstAmt = 0;
    let cgstAmt = 0;
    let igstAmt = 0;

    const roundOf = (number) => {
      if (number == null) return number;
      const roundedNumber = parseFloat(number).toFixed(2);
      return parseFloat(roundedNumber);
    };

    useEffect(() => {
        platformApi.get("/api/user/currentUser").then((result) => {
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    setBranch(response.data.profile.branch[0].id)
                }
            }
        });
    }, [])

    useEffect(() => {
        if (location.state) {
            setGst(location.state.gst)
        }
    }, [location.state])

    useEffect(() => {
        if (editData) {
            setInvoiceNumber(editData.invoiceNumber || null);
            setInvoiceDate(editData && editData.invoiceDate ? moment(new Date(editData.invoiceDate && editData.invoiceDate)).format('YYYY-MM-DD') : null);
            setDiscountType(editData.discountType);
            setTax(editData.itemRate);
            setTCS(editData.tcs);
            setAdjustments(editData.adjustment);
            setSupplier(editData && editData.supplier ? editData.supplier.name : '');
            setStateName(editData && editData.supplier.GSTIN ? editData.supplier.GSTIN : "");
            if (editData.discountType === 'At Transaction Level') {
                if (editData.discountPercent !== 0) {
                    setDiscountAmount(editData.discountPercent);
                    setDiscountLevel('percent');
                }
                if (editData.discountRate !== 0) {
                    setDiscountAmount(editData.discountRate);
                    setDiscountLevel('rupee');
                }
            }
            setTotalDiscount(editData.totalDiscount);
            setTotalInvoice(editData.totalInvoice);
            form.setFieldsValue({
                totalInvoice: editData.totalInvoice
            });

            const updateItemList = [...editData.purchaseItemInvoice]

            for (let i = 0; i < updateItemList.length; i++) {
                updateItemList[i].rate = (updateItemList[i].quantity * updateItemList[i].unitRate)
                // setSelectedPart(updateItemList[i].partName)
                if (updateItemList[i].gstRate === GST28) {
                    setGSTRate28(true)
                }
                if (updateItemList[i].gstRate === GST18) {
                    setGSTRate18(true)
                }
                if (updateItemList[i].gstRate === GST12) {
                    setGSTRate12(true)
                }
                if (updateItemList[i].gstRate === GST5) {
                    setGSTRate5(true)
                }
            }
            setItemList([...updateItemList, partItemData]);
        }

    }, [editData]);


    useEffect(() => {
      const newItemList = [...itemList];
      if (unitRate == null || quantity == null) return;
      for (const index in newItemList) {
        if (newItemList[index].quantity < 1 || newItemList[index].unitRate < 1) continue;
        newItemList[index].rate = roundOf(newItemList[index].quantity * newItemList[index].unitRate);
        newItemList[index].igstAmount = getGSTRateFromPrice({
          price: parseFloat(newItemList[index].rate),
          gstPercentage: newItemList[index].igst,
        });
        const gstByTwo = parseFloat(newItemList[index].igstAmount / 2)
        newItemList[index].sgstAmount = roundOf(gstByTwo);
        newItemList[index].cgstAmount = roundOf(gstByTwo);
        sgstAmt = newItemList[index].sgstAmount;
        cgstAmt = newItemList[index].cgstAmount;
        igstAmt = newItemList[index].igstAmount;
      }
      setSGSTAmount(sgstAmt);
      setIGSTAmount(igstAmt);
      setCGSTAmount(cgstAmt);
      setItemList(newItemList);
    }, [unitRate, quantity]);

    useEffect(() => {
        let val = 0;
        let discountedPrice = 0;
        let grandTotal = 0;
        let newItemList = [...itemList]

        // Transaction level Dicount Calculation
        if (discountType === 'At Transaction Level') {
            // for (let i = 0; i < newItemList.length; i++) {
            //     let total = parseFloat(newItemList[i].unitRate * newItemList[i].quantity)
            //     let gstRateValue = (newItemList[i].gstRate / 100) + parseInt(1);
            //     if (discountAmount > 0 && newItemList[i].unitRate > 0 && newItemList[i].quantity) {
            //         if (tax === 'Tax Exclusive') {
            //             if (discountLevel === 'percent') {
            //                 discountedPrice = (parseFloat(discountAmount) / 100) * (total);
            //                 val = val + discountedPrice
            //                 val = Math.round(val)
            //                 setTotalDiscount(val || 0);
            //             } else if (discountLevel === 'rupee') {
            //                 val = val + parseFloat(discountAmount)
            //                 setTotalDiscount(val || 0);
            //             }
            //         }
            //         else if (tax === 'Tax Inclusive') {
            //             if (discountLevel === 'percent') {
            //                 discountedPrice = parseFloat(total / gstRateValue) * (parseFloat(discountAmount) / 100);
            //                 val = val + discountedPrice
            //                 val = Math.round(val)
            //                 setTotalDiscount(val || 0);
            //             } else if (discountLevel === 'rupee') {
            //                 discountedPrice = parseFloat(total / gstRateValue) - discountAmount;
            //                 val = val + parseFloat(discountAmount)
            //                 setTotalDiscount(val || 0);
            //             }
            //         }
            //     }
            // }
            for (let i = 0; i < newItemList.length; i++) {
                let total = parseFloat(newItemList[i].unitRate * newItemList[i].quantity)
                if (total > 0) {
                    grandTotal = (parseFloat(grandTotal) + parseFloat(total));
                    grandTotal = roundOf(grandTotal);
                }
            }
            if (discountLevel === 'percent') {
                discountedPrice = (parseFloat(discountAmount) / 100) * (grandTotal);
                val = val + discountedPrice
                val = Math.round(val)
                setTotalDiscount(val || 0);
            } else if (discountLevel === 'rupee') {
                val = val + parseFloat(discountAmount)
                setTotalDiscount(val || 0);
            }
        }
        // Item level Discount calculation 
        if (discountType === 'At Item Level') {
            for (let i = 0; i < newItemList.length; i++) {
                let total = newItemList[i].unitRate * newItemList[i].quantity;
                let gstRateValue = (newItemList[i].gstRate / 100) + parseInt(1);
                if (newItemList[i].discount > 0) {
                    if (tax === 'Tax Exclusive') {
                        if (discountLevel === 'percent') {
                            discountedPrice = (parseFloat(newItemList[i].discount) / 100) * (total);
                            val = val + discountedPrice
                            setTotalDiscount(val || 0);
                        } else if (discountLevel === 'rupee') {
                            val = val + parseFloat(newItemList[i].discount)
                            setTotalDiscount(val || 0);
                        }
                    }
                    else if (tax === 'Tax Inclusive') {
                        if (discountLevel === 'percent') {
                            discountedPrice = parseFloat(total / gstRateValue) * (parseFloat(newItemList[i].discount) / 100);
                            val = val + discountedPrice
                            setTotalDiscount(val || 0);
                        } else if (discountLevel === 'rupee') {
                            discountedPrice = parseFloat(total / gstRateValue) - newItemList[i].discount;
                            val = val + parseFloat(newItemList[i].discount)
                            setTotalDiscount(val || 0);
                        }
                    }
                }
            }
        }
        setItemList(newItemList)
    }, [discountLevel, discountAmount, discountType, discount, quantity, unitRate, rate, grandTtl]);

    useEffect(() => {
        //Calucate TAX at transaction level
        calculateTransactionTax();

        //Calucate TAX at Item level
        calculateItemTax();

        //calulate TCS
        if (TCS > 0) {
            let total = form.getFieldValue('totalInvoice');
            tcs = total * (TCS / 100);
            total = parseFloat(total) + parseFloat(tcs);
            total = roundOf(total);
            setTotalInvoice(total);
            form.setFieldsValue({
                totalInvoice: total
            })
        }
        //calculate adjustment
        if (adjustments > 0) {
            let total = form.getFieldValue('totalInvoice');
            totalAdjustments = (parseFloat(total) + parseFloat(adjustments));
            setTotalInvoice(roundOf(totalAdjustments));
            form.setFieldsValue({
                totalInvoice: totalAdjustments
            })
        }
        if (adjustments < 0) {
            let total = form.getFieldValue('totalInvoice');
            totalAdjustments = (parseFloat(total) - (-parseFloat(adjustments)));
            setTotalInvoice(roundOf(totalAdjustments));
            form.setFieldsValue({
                totalInvoice: totalAdjustments
            })
        }
    }, [unitRate, quantity, rate, tax, TCS, totalInvoice, adjustments, discountAmount, discount, grandTtl, discountLevel, discountType, deleteItem]);

    useEffect(() => {
        if (itemList && itemList?.length > 0 && (itemList[itemList?.length - 1].partNumber.partNumber || itemList[itemList?.length - 1].quantity?.length > 0)) {
            handleAdd();
            setSearch("")
        }
        const selectItem = {};
        for (const item of itemList) {
          selectItem[item.partNumber.id] = item.partNumber;
        }
        setSelectItemObj(selectItem);
    }, [itemList])

    const getGSTRateFromPrice = ({ price = "", gstPercentage = "" }) => {
      if (price == null || gstPercentage == null) return null;
      const gstRate = parseFloat(price) - parseFloat(price) * (100 / (100 + parseFloat(gstPercentage)));
      const netRate = price - gstRate;
      return roundOf(gstRate);
    };

    const calculateGSTAmount = ({ price = "", gstPercentage = "" }) => {
      if (price == null || gstPercentage == null) return null;
      const gstRate = (price * gstPercentage) / 100;
      const netRate = price + gstRate;
      return roundOf(gstRate);
    };

    const calculateTransactionTax = () => {
      if (discountType !== "At Transaction Level") return;
      const newItemList = [...itemList];
      let grandTotal = 0;
      let totalGstAmount = 0;
      if (tax === "Tax Inclusive") {
        for (const index in newItemList) {
          if (newItemList[index].rate > 0 && newItemList[index].gstRate > 0) {
            let total = parseFloat(newItemList[index].rate);
            let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
            // taxInclusive = (parseFloat(total) - parseFloat(((total) / gstRateValue))).toFixed(2);
            const gstAmount = getGSTRateFromPrice({
              price: parseFloat(newItemList[index].rate),
              gstPercentage: newItemList[index].igst,
            });
            newItemList[index].igstAmount = gstAmount;
            totalGstAmount += parseFloat(gstAmount);
            const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
            newItemList[index].sgstAmount = gstByTwo;
            newItemList[index].cgstAmount = gstByTwo;
            sgstAmt = newItemList[index].sgstAmount;
            cgstAmt = newItemList[index].cgstAmount;
            igstAmt = newItemList[index].igstAmount;
            setSGSTAmount(sgstAmt);
            setIGSTAmount(igstAmt);
            setCGSTAmount(cgstAmt);
            newItemList[index].grandTotal = total;
          }
          grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
        }
        if (totalDiscount > 0 && !isNaN(totalDiscount)) {
          if (discountLevel === "percent") {
            const totalPriceWithoutGST = parseFloat(grandTotal) - parseFloat(totalGstAmount);
            const discountPrice = (totalPriceWithoutGST * parseFloat(discountAmount)) / 100;
            grandTotal = totalPriceWithoutGST - discountPrice + totalGstAmount;
            setTotalDiscount(roundOf(discountPrice));
          } else {
            grandTotal = parseFloat(grandTotal) - parseFloat(discountAmount);
          }
        }
        setGrandTotal(roundOf(grandTotal));
        setTotalInvoice(roundOf(grandTotal));
        form.setFieldsValue({
          totalInvoice: roundOf(grandTotal),
        });
      }
      if (tax === "Tax Exclusive") {
        for (const index in newItemList) {
          if (newItemList[index].rate > 0 && newItemList[index].gstRate > 0) {
            let total = roundOf(newItemList[index].rate);
            let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
            // taxExlusive = (total * (newItemList[index].gstRate / 100)).toFixed(2);
            newItemList[index].igstAmount = calculateGSTAmount({
              price: roundOf(newItemList[index].rate),
              gstPercentage: newItemList[index].igst,
            });
            const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
            newItemList[index].sgstAmount = gstByTwo;
            newItemList[index].cgstAmount = gstByTwo;
            sgstAmt = newItemList[index].sgstAmount;
            cgstAmt = newItemList[index].cgstAmount;
            igstAmt = newItemList[index].igstAmount;
            if (totalDiscount > 0 && !isNaN(totalDiscount)) {
              total =
                discountLevel === "percent"
                  ? total - total * (parseFloat(discountAmount) / 100)
                  : total - parseFloat(discountAmount);
            }
            total = total + parseFloat(newItemList[index].igstAmount);
            setSGSTAmount(sgstAmt);
            setIGSTAmount(igstAmt);
            setCGSTAmount(cgstAmt);
            newItemList[index].grandTotal = total;
          }
          grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
        }
        setGrandTotal(grandTotal);
        setTotalInvoice(grandTotal);
        form.setFieldsValue({
          totalInvoice: grandTotal,
        });
      }
      setItemList(newItemList);
    };

    const calculateItemTax = () => {
      if (discountType !== "At Item Level") return;
      let taxExlusive = 0;
      let taxInclusive = 0;
      let newItemList = [...itemList];
      let grandTotal = 0;
      if (tax === "Tax Inclusive") {
        for (const index in newItemList) {
          if (newItemList[index].rate > 0 && newItemList[index].gstRate > 0) {
            let total = newItemList[index].rate;
            let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
            // taxInclusive = (parseFloat(total) - parseFloat(((total) / gstRateValue))).toFixed(2);
            newItemList[index].igstAmount = getGSTRateFromPrice({
              price: parseFloat(newItemList[index].rate),
              gstPercentage: newItemList[index].igst,
            });
            const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
            newItemList[index].sgstAmount = gstByTwo;
            newItemList[index].cgstAmount = gstByTwo;
            sgstAmt = newItemList[index].sgstAmount;
            cgstAmt = newItemList[index].cgstAmount;
            igstAmt = newItemList[index].igstAmount;
            if (newItemList[index].discount > 0 && !isNaN(newItemList[index].discount)) {
              if (discountLevel === "percent") {
                const priceWithoutGST = parseFloat(total) - parseFloat(newItemList[index].igstAmount);
                const discountPrice = (priceWithoutGST * parseFloat(newItemList[index].discount)) / 100;
                total = parseFloat(total) - discountPrice;
              } else {
                total = total - parseFloat(newItemList[index].discount);
              }
            }
            setSGSTAmount(sgstAmt);
            setIGSTAmount(igstAmt);
            setCGSTAmount(cgstAmt);
            newItemList[index].grandTotal = parseFloat(total);
          }
          grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
        }
        setGrandTotal(grandTotal);
        setTotalInvoice(grandTotal);
        form.setFieldsValue({
          totalInvoice: grandTotal,
        });
      }
      if (tax === "Tax Exclusive") {
        for (const index in newItemList) {
          if (newItemList[index].rate > 0 && newItemList[index].gstRate > 0) {
            let total = parseFloat(newItemList[index].rate);
            let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
            // taxExlusive = (total * (newItemList[index].gstRate / 100)).toFixed(2);
            newItemList[index].igstAmount = calculateGSTAmount({
              price: parseFloat(newItemList[index].rate),
              gstPercentage: newItemList[index].igst,
            });
            const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
            newItemList[index].sgstAmount = gstByTwo;
            newItemList[index].cgstAmount = gstByTwo;
            sgstAmt = newItemList[index].sgstAmount;
            cgstAmt = newItemList[index].cgstAmount;
            igstAmt = newItemList[index].igstAmount;
            if (newItemList[index].discount > 0 && !isNaN(newItemList[index].discount)) {
              total =
                discountLevel === "percent"
                  ? total - total * (parseFloat(newItemList[index].discount) / 100)
                  : total - parseFloat(newItemList[index].discount);

              // total = total + parseFloat((newItemList[index].gstRate / 100) * newItemList[index].rate)
            }
            total = total + parseFloat(newItemList[index].igstAmount);
            setSGSTAmount(sgstAmt);
            setIGSTAmount(igstAmt);
            setCGSTAmount(cgstAmt);
            newItemList[index].grandTotal = total;
          }
          grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
          setGrandTotal(grandTotal);
          setTotalInvoice(grandTotal);
          form.setFieldsValue({
            totalInvoice: grandTotal,
          });
        }
      }
      setItemList(newItemList);
    };

    const renderColumns = () => {
        const columns = [
          {
            title: "Part Details",
            dataIndex: "partNumber",
            key: "partNumber",
            width: 230,
            render: (record, data, key) => (
              <Form.Item>
                <Select
                  dropdownStyle={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  onChange={(event) => {
                    setPartSearch(true);
                    handlePartDetail(event, data);
                  }}
                  onSearch={(event) => {
                    setSearch(event);
                    if (partsData?.length === 1) {
                      handlePartDetail(partsData[0].partNumber, data);
                      partsRef[key].focus();
                    }
                  }}
                  value={
                    record?.partNumber == undefined
                      ? "Part Number"
                      : `${record.partNumber} - ${record.partName}`
                  }
                  disabled={editable}
                  showSearch
                  showAction={["click", "focus"]}
                  placeholder="Part Number"
                  filterOption={(input, option) =>
                    option.props.children.some(
                      (child) =>
                        child.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    )
                  }
                >
                  {partsData.map((part) => {
                    if (part.id in selectItemObj) return null;
                    return (
                      <Select.Option key={part.id} value={part.partNumber}>
                        {part.partNumber} - {part.partName}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            ),
          },
          {
            title: "HSN",
            dataIndex: "hsn",
            key: "hsn",
            width: 130,
            render: (record, index) => (
              <Form.Item>
                <span>{record && record.code}</span>
              </Form.Item>
            ),
          },
          {
            title: "GST%",
            dataIndex: "gstRate",
            key: "gstRate",
            width: 120,
            render: (data, record) => (
              <Form.Item>
                <span>{record && record.gstRate}</span>
              </Form.Item>
            ),
          },
          {
            title: "Quantity",
            dataIndex: "quantity",
            key: "quantity",
            width: 100,
            render: (data, record, index) => (
              <Form.Item>
                <Input
                  key="quantity"
                  disabled={editable}
                  onChange={(event) => {
                    handleQuantity(event, record);
                  }}
                  ref={(el) => (partsRef[index] = el)}
                  placeholder="quantity"
                  value={record && record.quantity}
                />
              </Form.Item>
            ),
          },
          {
            title: "Unit rate",
            dataIndex: "unitRate",
            key: "unitRate",
            width: 100,
            render: (data, record, index) => (
              <Form.Item>
                <Input
                  key="unitRate"
                  disabled={editable}
                  onChange={(event) => {
                    handleUnitRate(event, record);
                  }}
                  placeholder="Unit Rate"
                  value={record && record.unitRate}
                />
              </Form.Item>
            ),
          },
          {
            title: "Discount",
            dataIndex: "discount",
            key: "discount",
            width: 100,
            render: (data, record) => (
              <Form.Item>
                <Input
                  key="discount"
                  onChange={(event) => {
                    handleDiscount(event, record);
                  }}
                  disabled={editable}
                  placeholder="Discount"
                  value={record && record.discount}
                />
              </Form.Item>
            ),
          },
          {
            title: "Gross Amount",
            dataIndex: "rate",
            key: "rate",
            width: 120,
            render: (data, record) => (
              <Form.Item>
                <Input
                  key="rate"
                  disabled={editable}
                  placeholder="Rate"
                  value={record && record.rate}
                />
              </Form.Item>
            ),
          },
          {
            title: "Action",
            dataIndex: "remove",
            key: "x",
            width: 100,
            render: (data, record) => (
              <Form.Item>
                {itemList.length >= 1 ? (
                  <Popconfirm
                    title="Sure to delete?"
                    onConfirm={() => {
                      handleDelete(record.id);
                    }}
                  >
                    <a style={{ color: "#6c7b8a" }}>Delete</a>
                  </Popconfirm>
                ) : null}
              </Form.Item>
            ),
          },
        ];

        return columns.filter(col => (discountType === "At Transaction Level") ? col.dataIndex !== 'discount' : col);
    }

    const handleSubmit = () => {
        setFormSubmit(true)
        form.validateFields((validationError) => {
            let totalSGSTAmt = 0;
            let totalCGSTAmt = 0;
            let totalIGSTAmt = 0;
            let newItemList = [...itemList];
            // eslint-disable-next-line for-direction
            for (let i = 0; i < (newItemList.length); i++) {
                if (newItemList[i].sgstAmount > 0 && newItemList[i].cgstAmount > 0 && newItemList[i].igstAmount > 0) {
                    totalSGSTAmt = totalSGSTAmt + parseFloat(newItemList[i].sgstAmount);
                    totalCGSTAmt = totalCGSTAmt + parseFloat(newItemList[i].cgstAmount);
                    totalIGSTAmt = totalIGSTAmt + parseFloat(newItemList[i].igstAmount);
                }
                newItemList[i].discountPercent = discountLevel === "percent" ? newItemList[i].discount : 0
                newItemList[i].discountRate = discountLevel === "rupee" ? newItemList[i].discount : 0
            }
            if (newItemList.length > 1) {
                newItemList = newItemList.filter(item => {
                    return item.partNumber && item.quantity && item.hsn && item.rate && item.igst && item.cgst && item.sgst
                })
            }
            // if (newItemList.length > 1) {
            //     newItemList = newItemList.slice(0, newItemList.length - 1)
            // }
            setItemList(newItemList)
            const data = {
                invoiceNumber,
                invoiceDate: invoiceDate,
                currentDate: moment(Date.now()),
                supplierName: modifyType ? (editData && editData.supplier && editData.supplier.id) : supplierID,
                itemrate: tax,
                discountType,
                discountPercent: discountLevel === "percent" ? discountAmount : 0,
                discountRate: discountLevel === "rupee" ? discountAmount : 0,
                cgst: stateName.slice(0, 2) === gst.slice(0, 2) ? totalCGSTAmt : null,
                igst: stateName.slice(0, 2) !== gst.slice(0, 2) ? totalIGSTAmt : null,
                sgst: stateName.slice(0, 2) === gst.slice(0, 2) ? totalSGSTAmt : null,
                tcs: TCS,
                totalDiscount,
                totalInvoice,
                adjustments,
                purchaseItemInvoice: newItemList,
                branch: branch,
                deletePartData: deletePartData
            };
            if (validationError === null || itemList.length >= 1) {
                setBtnFlag(true)
                if (!modifyType) {
                    platformApi.post("/api/purchaseSpareInvoice/", data).then((result) => {
                        let { data } = result;
                        if (data.code === 200) {
                            let { response } = data;
                            if (response.code === 200) {
                                message.success("PSI Added Successfully");
                                history.push('/autoadmin/purchase_spares_invoice');
                            }
                        }
                    })
                        .catch((err) => {
                            message.error("Couldn't Add PSI");
                        }
                        );
                } else {
                    platformApi.put(`/api/purchaseSpareInvoice/${id}`, data).then((result) => {
                        let { data } = result;
                        if (data.code === 200) {
                            let { response } = data;
                            if (response.code === 200) {
                                message.success("PSI Modified Successfully");
                                history.push('/autoadmin/purchase_spares_invoice');
                            }
                        }
                    })
                        .catch((err) => {
                            console.error("PSI Add Error: ", err);
                            message.error("Couldn't update PSI");
                        }
                        );
                }
            }
            else {
                message.error('select atleast one item list')
            }
        })
    }

    const handleCancel = () => {
        form.resetFields();
        setInvoiceDate(null);
        // Setflag(false);
        setInvoiceNumber('');
        setSupplier('');
        setGrandTotal(0);
        setAdjustments(0);
        setTCS(0);
        setDiscountAmount(0);
        setSelectedPart('');
        setStateName("");
        setGst("");
        setDeletePartData([]);
        setItemList([partItemData]);
        history.push('/autoadmin/purchase_spares_invoice');
    }

    const handleAdd = () => {
        let newArr = [...itemList, partItemData];
        setItemList(newArr);
    }

    const handleDelete = (selectedId) => {
        let grandTotal = 0;
        const deleteSource = itemList.filter(item => item.id == selectedId);
        const updatedDataSource = itemList.filter(item => item.id !== selectedId);
        for (let i = 0; i < updatedDataSource.length; i++) {
            grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(updatedDataSource[i].grandTotal));
            setGrandTotal(grandTotal);
            setTotalInvoice(grandTotal);
            form.setFieldsValue({
                totalInvoice: grandTotal
            })
        }

        for (let i = 0; i < deleteSource.length; i++) {
            if (deleteSource[i].partNumber.number || deleteSource[i].partNumber.hsn) {
                deletePartData.push(deleteSource[i].partNumber.id)
            }
        }
        setDeletePartData(deletePartData)
        setItemList(updatedDataSource)
    }

    const handleInvoiceDate = (e) => {
        if (e) {
            const date = new Date(e._d);
            setInvoiceDate(moment(date));
        }
    }

    const handleSupplierChange = (supplierID) => {
        setSupplier(supplierID)
        supplier.map((item) => {
            if (item.id === supplierID) {
                setStateName(item.GSTIN)
            }
        })
    }

    const handlePartDetail = (event, data) => {
        const newItemList = [...itemList];
        setSelectedPart(event)
        if (event !== undefined) {
            partsData.map(part => {
                if (part.partNumber === event) {
                    for (let item of newItemList) {
                        if (item.id === data.id) {
                            item.partNumber = part
                            item.hsn = part.hsn
                            item.cgst = part.hsn.cgst
                            item.igst = part.hsn.igst
                            item.sgst = part.hsn.sgst
                            item.gstRate = part.hsn.cgst + part.hsn.sgst
                            item.unitRate = null
                            item.quantity = 1
                            setquantity(1)
                            setUnitRate(null);
                            if (item.gstRate === GST18) {
                                setGSTRate18(true)
                            }
                            if (item.gstRate === GST28) {
                                setGSTRate28(true)
                            }
                            if (item.gstRate === GST12) {
                                setGSTRate12(true)
                            }
                            if (item.gstRate === GST5) {
                                setGSTRate5(true)
                            }
                            setCGST(part.hsn.cgst),
                                setSGST(part.hsn.sgst),
                                setIGST(part.hsn.igst)
                        }
                    }
                }
            })
        }
        // partNumberSelectRef?.current.blur()
        setItemList(newItemList)
        // getParts();
    }

    const handleQuantity = (event, record) => {
        setquantity(event.target.value);
        let newItemList = [...itemList];
        for (let item of newItemList) {
            if (item.id === record.id) {
                item.quantity = event.target.value
                break;
            }
        }
        setItemList(newItemList);
    }
    const handleDiscount = (event, record) => {
        setDiscount(event.target.value)
        let newItemList = [...itemList];
        for (let item of newItemList) {
            if (item.id === record.id) {
                item.discount = event.target.value
                break;
            }
        }
    }

    const handleUnitRate = (event, record) => {
        setUnitRate(event.target.value)
        let newItemList = [...itemList];
        for (let item of newItemList) {
            if (item.id === record.id) {
                item.unitRate = event.target.value
                item.rate = (item.quantity * item.unitRate)
                break;
            }
        }
    }

    const validateEmptyField = (value) => {
        if (value === '' || value === undefined) {
            return false
        }
        return true;
    }

    return (
        <>
            <Form className="purchase-form">
                <Row>
                    <Col span={12}>
                        <Form.Item
                            className="purchase-form-element"
                            label="Current Date"
                            name="currentDate"
                        >
                            <DatePicker
                                disabled={true}
                                placeholder={moment(Date.now()).format('LL')}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="purchase-form-element"
                            label="Invoice Date"
                            name="invoiceDate"
                            required
                            validateStatus={formSubmit && (validateEmptyField(invoiceDate) ? 'success' : 'error')}
                            help={formSubmit && !validateEmptyField(invoiceDate) && 'Enter Invoice Date'}
                        >
                            <DatePicker
                                disabled={editable}
                                format="DD/MM/YYYY"
                                allowClear
                                format={dateFormatList}
                                value={invoiceDate ? moment(invoiceDate) : null}
                                onChange={(e) => handleInvoiceDate(e)}
                                disabledDate={(moment) => Date.now() < moment._d}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            className="purchase-form-element"
                            label="Invoice Number"
                            name="invoiceNumber"
                            required
                            validateStatus={formSubmit && (validateEmptyField(invoiceNumber) ? 'success' : 'error')}
                            help={formSubmit && !validateEmptyField(invoiceNumber) && 'Enter Invoice Number'}
                        >
                            <Input
                                placeholder="Enter invoice number"
                                className="form-input"
                                disabled={editable}
                                onChange={(e) => setInvoiceNumber(e.target.value)}
                                value={invoiceNumber}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="purchase-form-element"
                            label="Supplier"
                            name="supplier"
                            required
                            validateStatus={formSubmit && (validateEmptyField(supplierID) ? 'success' : 'error')}
                            help={formSubmit && !validateEmptyField(supplierID) && 'Select Supplier'}
                        >
                            <Select
                                dropdownStyle={{
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                }}
                                // value={editable ? editData && editData.supplier.name : supplierName}
                                value={supplierID}
                                onChange={(value) => {
                                    handleSupplierChange(value);
                                }}
                                showAction={["click", "focus"]}
                                showSearch
                                disabled={editable}
                                placeholder="Supplier"
                                filterOption={(input, option) =>
                                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {supplier.map((supply) => (
                                    <Select.Option key={supply.id} value={supply.id}>
                                        {supply.name}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={6}>
                        <Form.Item
                            className="form-element-type"
                            label="Item Rates Are"
                            name="itemRates"
                            validateStatus={formSubmit && (validateEmptyField(tax) ? 'success' : 'error')}
                            help={formSubmit && (!validateEmptyField(tax) && 'Select Item rates')}
                        >
                            <Select
                                value={tax}
                                showSearch
                                showAction={["click", "focus"]}
                                style={{ width: 200 }}
                                placeholder='Select Item Rate'
                                disabled={editable}
                                onChange={(value) => setTax(value)}
                            >
                                <Option value="Tax Inclusive">Tax Inclusive</Option>
                                <Option value="Tax Exclusive">Tax Exclusive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className="form-element-type"
                            label="Discount Type"
                            name="dicountType"
                        >
                            <Select
                                showSearch
                                showAction={["click", "focus"]}
                                style={{ width: 200 }}
                                disabled={editable}
                                placeholder="Select Discount Type"
                                onChange={(value) => setDiscountType(value)}
                                value={discountType}
                            >
                                <Option value="At Transaction Level">At Transaction Level</Option>
                                <Option value="At Item Level">At Item Level</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={6}>
                        <Form.Item
                            className="form-element-type"
                            label="Discount"
                            name="discountLevel"
                        >
                            <Group
                                onChange={(e) => { setDiscountLevel(e.target.value) }}
                                value={discountLevel}
                                disabled={editable}>
                                <Radio value="percent"> % </Radio>
                                <Radio value="rupee"> Rs </Radio>
                            </Group>
                        </Form.Item>
                    </Col>
                    {
                        (discountType === "At Transaction Level") &&
                        <Col span={6}>
                            <Form.Item
                                className="form-element-type"
                                label={discountLevel === "percent" ? "Discount Percent" : "Discount Rupee"}
                                name="discountAmount"
                                onChange={(e) => setDiscountAmount(e.target.value)}
                                rules={[{}]}
                            >
                                <Input
                                    disabled={editable}
                                    placeholder={discountLevel === "percent" ? "Discount Percent" : "Discount Rupee"}
                                    value={(discountType === "At Transaction Level" && discountAmount !== 0) ? discountAmount : discountAmount}
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Table
                    className="parts-invoice-table"
                    rowKey={(record) => { record.id }}
                    columns={renderColumns()}
                    dataSource={itemList}
                    tableLayout="fixed"
                    pagination={false}
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}

                />
                <br />
                {/* <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item
                          className="form-element"
                          label="CGST"
                          >
                            {
                                getFieldDecorator("cgst", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter CGST"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="CGST"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-element"
                            label="SGST"
                        >
                            {
                                getFieldDecorator("sgst", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter SGST"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="SGST"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-element"
                            label="Part Charge"
                        >
                            {
                                getFieldDecorator("partCharge", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Part Charge"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Part Charge"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>*/}
                <Row> 
                    <Col span={8}>
                        <Form.Item
                            className="form-element"
                            label="Total Discount"
                            name="totalDiscount"
                            rules={[{}]}
                        >
                            <Input
                                placeholder="Total Discount"
                                value={totalDiscount.toFixed(2)}
                                disabled={true}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <div className="gst-table">
                            <Title level={4} className="gst-title">GST Data</Title>
                            <GSTTable
                                gst={gst}
                                SGST={SGST}
                                CGST={CGST}
                                IGST={IGST}
                                itemList={itemList}
                                GSTRate18={GSTRate18}
                                GSTRate28={GSTRate28}
                                GSTRate5={GSTRate5}
                                GSTRate12={GSTRate12}
                                stateName={stateName}
                            />
                        </div>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            className="form-element"
                            label="Grand Total"
                            name="grandTotal"
                        >
                            <Input
                                disabled={editable}
                                placeholder="Grand Total"
                                value={grandTtl}
                            />
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="TCS"
                            name="tcs"
                            onInput={(e) => setTCS(e.target.value)}
                            rules={[{}]}

                        >
                            <Input
                                disabled={editable}
                                placeholder="TCS"
                                value={TCS}
                            />
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="Adjustments"
                            name="adjustments"
                            onInput={e => setAdjustments(e.target.value)}
                            rules={[{}]}
                        >
                            <Input
                                disabled={editable}
                                placeholder='Adjustment'
                                value={adjustments}
                            />
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="Total Invoice"
                            name="totalInvoice"
                            rules={[{}]}
                        >
                            {getFieldDecorator("totalInvoice", {
                                rules: [{ required: false, message: "" }],
                            })(

                                <Input
                                    disabled={editable}
                                    placeholder="Total Invoice"
                                // value={totalInvoice}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ float: "right" }}>
                    <Button
                        style={{ marginRight: "1rem" }}
                        size="large"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        disabled={btnFlag}
                        size="large"
                        onClick={() => (!editable ? handleSubmit() : onEdit(false))}
                    >
                        {!editable ? 'Save' : 'Modify'}
                    </Button>
                </div>
            </Form>
        </>
    )
}

const WrappedInvoiceForm = Form.create()(
    InvoiceForm
);

export default WrappedInvoiceForm;