import React from "react";
import { Table, Divider, Popconfirm, Button,Typography } from "antd";
import { hasId, modules } from "../../../../JSONFiles/Submodule.json";
const {Text}=Typography;
const FrameNumberTable = (props) => {
  const {
    pagination,
    dataSource,
    openModal,
    spinner,
    history,
    deleteData,
    modify,
    deleteAccess
  } = props;

  const column = [
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      render: (record) => <span>{record && record.name}</span>,
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Input Value",
      dataIndex: "inputValue",
      key: "inputValue",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Inferred Field",
      dataIndex: "inferredField",
      key: "inferredField",
      render: (record, data) => <span>{record}</span>,
    },
    {
      title: "Target Value",
      dataIndex: "targetValue",
      key: "targer",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>{modify?
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(data, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
          <span></span>
         }
          <Divider type="vertical" />
        { deleteAccess?
          <Popconfirm
            title="Do you want to delete this Frame Number?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              deleteData(data.id);
            }}
          >
            <span
              onClick={(event) => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
          :
          <span></span>
    }
    {
      !modify && !deleteAccess
      ?
      <Text strong disabled> No Access Given</Text>
      :
      <span></span>
    }
          {/* <Divider type="vertical" /> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      loading={spinner}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props, false),
      })}
    />
  );
};

export default FrameNumberTable;
