import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Tooltip, Dropdown, Menu, Icon, message } from "antd";
import "./index.less";
import { platformApi } from "../../../api";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';
// Import Table and Form
import InsuranceTable from "./Insurance Table";
import WrappedInsuranceModal from "./Insurance Form";
import { ContextAPI } from "../../../ContextAPI";
import { set } from "lodash";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

const Insurance = (props) => {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const [img, setImg] = useState(false);
  
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "insurance") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPrimaryData();
  }, []);


  useEffect(() => {
    setPrimaryData(search);
  }, [page, limit, search]);


  const setPrimaryData = (string, val) => {
    setSpinning(true);
    platformApi
      .post("/api/insurance/get", {
        page: val ? val : page,
        size: limit,
        searchString: string,
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.insurance);
            setCount(response.data.count);
          } else {
            message.error("Failed to fetch Insurance");
          }
        } else {
          message.error("Failed to fetch Insurance");
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Insurance : ", error);
        message.error("Failed to fetch Insurance");
      });
  }

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete(`/api/insurance/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;

        if (data.code === 200) {
          const { response } = data;
          if (response.code === 400) {
            message.error("Alert:This Insurance is already in use.");
          }
          else {
            if (response.code === 200) {
              setData(dataSource.filter((obj) => id !== obj.id));
              setCount(count - 1);
              if (temp) {
                setPage(page - 1)
                setPrimaryData(null, 1)
              }
              else {
                setPrimaryData();
              }
              message.success("Deleted Sucessfully", 2);
            } else {
              message.error("Unable to delete Insurance", 2);
            }
          }
        } else {
          message.error("Unable to delete Insurance", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Insurance : ", error);
        message.error("Unable to delete Insurance", 2);
      });
  };


  const addData = (insurance) => {
    setSpinning(true);
    if (insurance.id) {
      platformApi
        .put(`/api/insurance/${insurance.id}`, insurance)
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === insurance.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success("Insurance saved successfully", 2);
            } else {
              message.error("Unable to save insurance", 2);
            }
          } else {
            message.error("Unable to save insurance", 2);
          }
        })
        .catch((err) => {
          setSpinning(false);
          message.error("Unable to save insurance", 2);
          console.error("Error on insurance : ", err);
        });
    } else {
      platformApi
        .post("/api/insurance/", insurance)
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = res.data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Insurance added successfully");
            } else {
              message.error("Error in insurance creation");
            }
          } else {
            message.error("Error in insurance creation");
          }
        })
        .catch((err) => {
          console.error("Error on insurance : ", err);
        });
    }
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Insurance Master [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Insurance"
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Insurance
        </Button>
      </div>

      <InsuranceTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          current: page,
          total: count,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{
          spinning,
        }}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <WrappedInsuranceModal
        data={dataSource[dataSource.findIndex((obj) => selected === obj.id)]}
        emitData={(data) => addData(data)}
        editable={editFlag}
        open={addFlag}
        setImg={setImg}
        close={() => {
          if (img) {
            window.location.reload();
          }
          setSelected(null);
          setAddFlag(false);
        }}
        modify={modifyAccess}
      />
    </div>
  );
}


export default withRouter(Insurance)