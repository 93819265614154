import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import {
    Typography,
    Input,
    Button,
    Select,
    Form,
    Col,
    Row,
    DatePicker,
    message,
    Table,
    Radio,
    Popconfirm
} from 'antd';
import moment from 'moment';
import { platformApi } from '../../../../../api';
import { getUUID } from '../../../../../_helpers/utils';
import { GST18, GST28, GST12, GST5 } from '../../../../../utils';
import GSTTable from './GSTTable';

const { Title, Text } = Typography;
const { Option } = Select;
const { Group } = Radio;
const EstimateForm = (props) => {
    const {
        form,
        estimateNo,
        id,
        jobNo,
        setJobNo,
        partsData,
        setPartsData,
        jobCode,
        setJobCode,
        editData,
        editable,
        onEdit,
        modifyType,
    } = props

    console.log(modifyType, editable);
    // console.log("edit"+editable);
    const partItemData = {
        "id": getUUID(),
        "partNumber": {},
        "code": '',
        "hsn": {},
        "quantity": '',
        "unitRate": '',
        "discount": '',
        "rate": '',
        "igst": 0,
        "cgst": 0,
        "sgst": 0,
        "gstRate": 0,
        "grandTotal": 0,
        "igstAmount": 0,
        "cgstAmount": 0,
        "sgstAmount": 0,
        "discountAmount": 0,
        "discountPercent": 0,
        "remove": '',
    }

    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const history = useHistory();
    const location = useLocation();
    const [model, setModel] = useState();
    const [branch, setBranch] = useState('');
    const [gst, setGst] = useState("");
    const [stateName, setStateName] = useState("");
    const [selectedPart, setSelectedPart] = useState("");
    const [itemList, setItemList] = useState([partItemData])
    const [selectItemObj, setSelectItemObj] = useState({})
    const [discountType, setDiscountType] = useState('');
    const [discountLevel, setDiscountLevel] = useState('TransactionLevel');
    const [recPart, setRecPart] = useState('')
    const [quantity, setquantity] = useState();
    const [unitRate, setUnitRate] = useState();
    const [rate, setRate] = useState(0);
    const [discountAmount, setDiscountAmount] = useState(0)
    const [discount, setDiscount] = useState(0);
    const [totalDiscount, setTotalDiscount] = useState(0);
    const [grandTtl, setGrandTotal] = useState(0);
    const [SGST, setSGST] = useState();
    const [CGST, setCGST] = useState();
    const [IGST, setIGST] = useState();
    const [GSTRate18, setGSTRate18] = useState(false);
    const [GSTRate28, setGSTRate28] = useState(false);
    const [GSTRate12, setGSTRate12] = useState(false);
    const [GSTRate5, setGSTRate5] = useState(false);
    const [SGSTAmount, setSGSTAmount] = useState();
    const [CGSTAmount, setCGSTAmount] = useState();
    const [IGSTAmount, setIGSTAmount] = useState();
    const [deleteItem, setDeleteItem] = useState(false);
    const [dataTemp, setDataTemp] = useState([]);
    const [deleteData1, setDeleteData1] = useState([])
    const [deleteData2, setDeleteData2] = useState([])
    const [pid, setPid] = useState([])
    const [jid, setJid] = useState([])
    const [pBranch, setPBranch] = useState('')
    const [btnFlag, setBtnFlag] = useState(false)
    // const partRef = useRef(null);
    const partsRef = {};
    let sgstAmt = 0;
    let cgstAmt = 0;
    let igstAmt = 0;

    useEffect(() => {
        if (location.state) {
            setGst(location.state.gst)
        }
    }, [location.state])

    //EditData
    useEffect(() => {
        if (editData) {
            console.log("editData", editData)
            setDiscountLevel(editData.discountLevel);
            setDiscountType(editData.discountType);
            setGrandTotal(editData.estTotalAmount ? editData.estTotalAmount : 0)
            setStateName(editData.jobOrder.customer && editData.jobOrder.customer.GSTNo)

            if (editData.discountLevel === 'TransactionLevel') {
                if (editData.discountPercent !== 0) {
                    setDiscountAmount(editData.discountPercent);
                    setDiscountType('percent');
                }
                if (editData.discountRate !== 0) {
                    setDiscountAmount(editData.discountRate);
                    setDiscountType('rupee');
                }
            }

            const updateItemList = [...editData.estimateItemInvoice]

            for (let i = 0; i < updateItemList.length; i++) {
                if (updateItemList[i].jobCode) {
                    updateItemList[i].partNumber = { ...updateItemList[i] }
                    updateItemList[i].rate = (updateItemList[i].quantity * updateItemList[i].unitRate)
                    updateItemList[i].hsn = updateItemList[i].sac,
                        updateItemList[i].cgst = updateItemList[i].sac.cgst
                    updateItemList[i].sgst = updateItemList[i].sac.sgst
                    updateItemList[i].igst = updateItemList[i].sac.igst
                    updateItemList[i].gstRate = updateItemList[i].sac.cgst + updateItemList[i].sac.sgst
                    // updateItemList[i].unitRate = updateItemList[i].vehicleModel ? jobCodeItemList[j].vehicleModel[0].price ? jobCodeItemList[j].vehicleModel[0].price : 1 : 1
                    updateItemList[i].gstRate = updateItemList[i].igst
                    setUnitRate(updateItemList[i].unitRate)
                    if (updateItemList[i].gstRate === GST28) {
                        setGSTRate28(true)
                    }
                    if (updateItemList[i].gstRate === GST18) {
                        setGSTRate18(true)
                    }
                    if (updateItemList[i].gstRate === GST12) {
                        setGSTRate12(true)
                    }
                    if (updateItemList[i].gstRate === GST5) {
                        setGSTRate5(true)
                    }
                }
                else {
                    updateItemList[i].rate = (updateItemList[i].quantity * updateItemList[i].unitRate)
                    updateItemList[i].gstRate = updateItemList[i].igst
                    setUnitRate(updateItemList[i].unitRate)
                    setSelectedPart(updateItemList[i].partName)
                    if (updateItemList[i].gstRate === GST28) {
                        setGSTRate28(true)
                    }
                    if (updateItemList[i].gstRate === GST18) {
                        setGSTRate18(true)
                    }
                    if (updateItemList[i].gstRate === GST12) {
                        setGSTRate12(true)
                    }
                    if (updateItemList[i].gstRate === GST5) {
                        setGSTRate5(true)
                    }
                }
            }
            setItemList(updateItemList);
            if (!jobNo.some(tempItem => editData.jobOrder.id === tempItem.id)) {
                platformApi.get("/api/jobOrder/" + editData.jobOrder.id).then((res) => {
                    let { data } = res;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            setJobNo([...jobNo, response.data])
                            form.setFieldsValue({
                                branch: response.data.branch?.name
                            })
                        }
                    }
                })
            }
            setPBranch(editData.jobOrder.branch && editData.jobOrder.branch.id)
            setFieldsValue({
                JobNo: editData.jobOrder.id,
                discountType: editData.discountType ? editData.discountType : "",
                discountLevel: editData.discountLevel ? editData.discountLevel : "",
                labourCharge: editData.labourCharge ? editData.labourCharge : 0,
                consumableCharge: editData.consumableCharge ? editData.consumableCharge : 0,
                partCharge: editData.partCharge ? editData.partCharge : 0,
                // consumableCharge: editData.consumableCharge ? editData.consumableCharge : 0,
                totalDiscount: editData.totalDiscount ? editData.totalDiscount : 0,
                sgst: editData.sgstAmount ? editData.sgstAmount : 0,
                cgst: editData.cgstAmount ? editData.cgstAmount : 0,
                igst: editData.igstAmount ? editData.igstAmount : 0,
                totalAmount: editData.estTotalAmount ? editData.estTotalAmount : 0
            })
            for (let item of editData.estimateItemInvoice) {
                if (item.jobCode) {
                    jid.push(item.id)
                }
                else {
                    pid.push(item.id)
                }
            }
            setPid(pid)
            setJid(jid)
        }

    }, [editData]);

    //setBranch
    useEffect(() => {
        platformApi.get("/api/user/currentUser").then((result) => {
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    setBranch(response.data.profile.branch[0].id)
                    setPBranch(response.data.profile.branch[0].id)
                }
            }
        });
        setFieldsValue({
            discountLevel: discountLevel,
        })
    }, [])
    //Props

    useEffect(() => {
        if (location.state.jobNo) {
            setFieldsValue({
                JobNo: location.state.jobNo
            })
            if (!jobNo.some(tempItem => location.state.jobNo === tempItem.id)) {
                platformApi.get("/api/jobOrder/" + location.state.jobNo).then((res) => {
                    let { data } = res;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            setStateName(response.data.customer && response.data.customer?.GSTNo)
                            setJobNo([...jobNo, response.data])
                            form.setFieldsValue({
                                branch: response.data.branch?.name
                            })
                        }
                    }
                })
            }
        }
        // getJobOrder(location.state.jobNo)
        // fetchJobDetails(location.state.jobNo && location.state.jobNo)
    }, [location.state, jobNo])

    useEffect(() => {
        if (partsData || jobCode) {
            // console.log("PartsData", partsData)
            let temp = [];
            partsData.map(item => {
                temp.push({
                    label: `${item.partNumber} - ${item.partName}`,
                    value: item.partNumber,
                    id: item.id,
                })
            })
             //console.log("JobCode", jobCode)

            jobCode.map(item => {
                temp.push({
                    label: item.code,
                    value: item.code,
                    id: item.id,
                    consumable: item.consumable
                    
                })
            })
            setDataTemp(temp)
        }
    }, [partsData, jobCode])


    const getJobCodes = () => {
        platformApi.get('/api/jobCode/').then((res) => {
            const { data } = res;
            if (data.code === 200) {
                setJobCode(data.response.data);
            }
        }).catch((err) => {
            console.error("JobCode Fetch Error: ", err);
            message.error("Couldn't Fetch JobCodes");
        });
    }

    const getParts = () => {
        // platformApi
        //     .post(`/api/sparesInventory/branch/get`, {
        //         page: 1,
        //         size: 100,
        //         searchString: ""
        //     })
        //     .then((res) => {
        //         const { data } = res;
        //         if (data.code === 200) {
        //             const { response } = data;
        //             if (response.code === 200) {
        //                 let parts = []
        //                 for (let i = 0; i < response.data.length; i++) {
        //                     const element = response.data[i];
        //                     if (element.phyQuantity > 0) {
        //                         parts.push(element.partNo)
        //                     }
        //                 }
        //                 setPartsData(parts)
        //             }
        //         }
        //         else {
        //             message.error("Unable to fetch Parts", 2);
        //         }
        //     })
        //     .catch((error) => {
        //         console.error("Error on Parts Master : ", error);
        //         message.error("Unable to fetch Parts", 2);
        //     });
        let data = {
            module: 'partsMasters',
            size: 100,
            column: 'partNumber',
            fields: ['partNumber', 'partName']
        }
        platformApi.post('/api/options/get', data).then((res) => {
            const { data } = res;
            setPartsData(data.response);
        }).catch((err) => {
            console.error("Part Fetch Error: ", err);
            message.error("Couldn't Fetch Parts");
        });
    }

    const getJobOrder = (value) => {
        setFieldsValue({
            JobNo: value
        })
        let grandTotal = 0;
        let TotalCgst = 0;
        let TotalSgst = 0;
        let TotalIgst = 0;
        let pCharge = 0;
        let labourCharge = 0;
        let consumableCharge = 0;
        let estTotal = 0;
        let temp = 0;
        platformApi.get('/api/materialIssue/jobMaterial/' + value)
            .then((resp) => {
                let { data } = resp;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        if (response.data) {
                            let partItemList = [];
                            // console.log("response.data", response.data)
                            if (response.data.length && response.data[0].parts) {
                                for (let i = 0; i < response.data.length; i++) {
                                    const element = response.data[i];
                                    partItemList.push(...element.parts)
                                }
                                console.log(partItemList)
                                // partItemList = [...response.data[0].parts]
                            }
                            else {
                                message.info("No Materials in this JobOrder")
                            }
                            for (let i = 0; i < partItemList.length; i++) {
                                const item = { ...partItemList[i].part }
                                if (!dataTemp.some(tempItem => item.partNumber === tempItem.value)) {
                                    platformApi.get("/api/partsMaster/" + item.id).then((res) => {
                                        let { data } = res;
                                        if (data.code === 200) {
                                            let { response } = data;
                                            if (response.code === 200) {
                                                const temp = {
                                                    label: `${response.data.partNumber} - ${response.data.partName}`,
                                                    value: response.data.partNumber,
                                                    id: response.data.id,
                                                }
                                                setDataTemp([...dataTemp, temp])
                                            }
                                        }
                                    })
                                }

                                partItemList[i].partNumber = item
                                partItemList[i].partNumber.partName = partItemList[i].partName
                                partItemList[i].hsn = item.hsn
                                partItemList[i].cgst = item.hsn && item.hsn.cgst
                                partItemList[i].sgst = item.hsn && item.hsn.sgst
                                partItemList[i].igst = item.hsn && item.hsn.igst
                                partItemList[i].gstRate = item.hsn && item.hsn.igst
                                // partItemList[i].quantity = partItemList[i].quantity
                                partItemList[i].unitRate = partItemList[i].mrp
                                partItemList[i].rate = (partItemList[i].quantity * partItemList[i].unitRate)
                                partItemList[i].sgstAmount = parseFloat((partItemList[i].sgst / 100) * partItemList[i].rate).toFixed(2);
                                partItemList[i].igstAmount = parseFloat((partItemList[i].igst / 100) * partItemList[i].rate).toFixed(2);
                                partItemList[i].cgstAmount = parseFloat((partItemList[i].cgst / 100) * partItemList[i].rate).toFixed(2);

                                setSelectedPart(item.partNumber)

                                let total = parseFloat(partItemList[i].rate)
                                // let finTotal = parseFloat(partItemList[i].rate)
                                // let gstRateValue = (partItemList[i].gstRate / 100) + parseInt(1);
                                // taxExlusive = (total * (partItemList[i].gstRate / 100)).toFixed(2);
                                // total = (parseFloat(total) + parseFloat(taxExlusive)).toFixed(2);
                                // partItemList[i].grandTotal = finTotal

                                partItemList[i].grandTotal = total
                                if (partItemList[i].gstRate === GST28) {
                                    setGSTRate28(true)
                                }
                                if (partItemList[i].gstRate === GST18) {
                                    setGSTRate18(true)
                                }
                                if (partItemList[i].gstRate === GST12) {
                                    setGSTRate12(true)
                                }
                                if (partItemList[i].gstRate === GST5) {
                                    setGSTRate5(true)
                                }
                            }
                            let updateItemList = [...partItemList]

                            // console.log("updateItemList", updateItemList)
                            for (let i = 0; i < updateItemList.length; i++) {
                                grandTotal = parseFloat(grandTotal) + parseFloat(updateItemList[i].grandTotal);
                                grandTotal = grandTotal.toFixed(2)
                                estTotal = parseFloat(estTotal) + parseFloat(updateItemList[i].grandTotal);
                                estTotal = estTotal.toFixed(0)
                                TotalCgst = parseFloat(TotalCgst) + parseFloat(updateItemList[i].cgstAmount);
                                TotalCgst = TotalCgst.toFixed(2)
                                TotalSgst = parseFloat(TotalSgst) + parseFloat(updateItemList[i].sgstAmount);
                                TotalSgst = TotalSgst.toFixed(2)
                                TotalIgst = parseFloat(TotalIgst) + parseFloat(updateItemList[i].igstAmount);
                                TotalIgst = TotalIgst.toFixed(2)

                                if (updateItemList[i].rate) {
                                    if (updateItemList[i].partNumber.code || updateItemList[i].jobCode) {
                                        if (updateItemList[i].partNumber?.consumable || updateItemList[i].jobCode?.consumable) {
                                            consumableCharge = parseFloat(consumableCharge) + parseFloat(updateItemList[i].rate)
                                        } else {
                                            labourCharge = parseFloat(labourCharge) + parseFloat(updateItemList[i].rate)
                                        }
                                    }
                                    else if (updateItemList[i].partNumber.hsn) {
                                        pCharge = parseFloat(pCharge) + parseFloat(updateItemList[i].rate)
                                    }
                                }
                                let roundoff = grandTotal.split('.');
                                if (roundoff[1] === "00") {
                                    temp = "0.00"
                                }
                                else if (parseInt(roundoff[1]) >= 50) {
                                    temp = 100 - parseInt(roundoff[1])
                                    temp = `+0.${temp}`
                                }
                                else if (parseInt(roundoff[1]) < 50) {
                                    temp = 100 - parseInt(roundoff[1])
                                    temp = `-0.${parseInt(roundoff[1])}`
                                }
                            }
                            form.setFieldsValue({
                                grantTotal: estTotal,
                                totalInvoice: estTotal,
                                partCharge: pCharge,
                                labourCharge: labourCharge,
                                consumableCharge: consumableCharge,
                                cgst: TotalCgst,
                                sgst: TotalSgst,
                                igst: TotalIgst,
                                roundoff: temp
                            })
                            // setGrandTotal(grandTotal);
                            // setTotalInvoice(estTotal);
                            // setInitialData(updateItemList)
                            setItemList([...updateItemList, partItemData]);
                        }

                    }
                }
            })
            .catch((error) => {
                message.error("Unable to get Materials");
                console.error("Error on get Materials : ", error);
            })
        platformApi.get('/api/jobOrder/' + value).then((res) => {
            let { data } = res;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    if (response.data) {
                        console.log("Response", response.data)
                        setStateName(response.data.customer && response.data.customer.GSTNo)
                        setPBranch(response.data.branch && response.data.branch.id)
                        setJobNo([...jobNo, response.data])
                        setModel(response.data.vehicle && response.data.vehicle?.vehicle.id)
                        form.setFieldsValue({
                            branch: response.data.branch?.name
                        })
                    }
                }
            }
        })
        // getJobNo();
    }
    //itemList
    useEffect(() => {
        // console.log("ItemList", itemList)
        if (itemList && itemList?.length > 0 &&
            (itemList[itemList?.length - 1].partNumber?.partNumber
                ||
                itemList[itemList?.length - 1].partNumber?.code
                || itemList[itemList?.length - 1].quantity?.length > 0
            )
        ) {
            // console.log("handle Add")
            handleAdd();
        }
        const selectItem = {}
        for(const item of itemList){
            selectItem[item.partNumber.id] = item.partNumber

        }
        setSelectItemObj(selectItem)
    }, [itemList])

    //Quantity
    useEffect(() => {
        let num = 0;
        let newItemList = [...itemList];
        if (unitRate > 0 && quantity > 0) {
            if (newItemList.length > 0) {
                for (let i = 0; i < newItemList.length; i++) {
                    if (newItemList[i].quantity > 0 && newItemList[i].unitRate > 0) {
                        newItemList[i].rate = (parseFloat(newItemList[i].quantity) * parseFloat(newItemList[i].unitRate)).toFixed(2);
                        newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
                        newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
                        newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
                        sgstAmt = newItemList[i].sgstAmount;
                        cgstAmt = newItemList[i].cgstAmount;
                        igstAmt = newItemList[i].igstAmount;
                    }
                }
            }
            setSGSTAmount(sgstAmt)
            setIGSTAmount(igstAmt)
            setCGSTAmount(cgstAmt)
            setItemList(newItemList)
            setquantity(undefined)
            // setUnitRate(undefined)
        }

    }, [unitRate, quantity])

    //PMC
    useEffect(() => {
        let grandTotal = 0;
        let TotalCgst = 0;
        let TotalSgst = 0;
        let TotalIgst = 0;
        let pCharge = 0;
        let taxExlusive = 0;
        let labourCharge = 0;
        let consumableCharge = 0;
        let estTotal = 0;
        let temp = 0;
        if (recPart === 'yes') {
            const jobId = jobNo.filter(item => {
                return item.id === getFieldValue("JobNo")
            })
            if (jobId.length) {
                platformApi.post('/api/pmc/getCodes', {
                    model: jobId[0].vehicle?.vehicle.id,
                    kms: jobId[0].kms,
                    serviceNo: jobId[0].serviceNo,
                    serviceType: jobId[0].serviceType
                })
                    .then((res) => {
                        let { data } = res;
                        if (data.code === 200) {
                            let { response } = data;
                            if (response.code === 200) {
                                console.log("response", response.data)
                                if (response.data.length && (response.data[0].jobCodes.length || response.data[0].partsConsumable.length)) {
                                    let jobCodeItemList = [];
                                    let partItemList = [];
                                    if (response.data[0].jobCodes) {
                                        jobCodeItemList = [...response.data[0].jobCodes]
                                    }
                                    if (response.data[0].partsConsumable) {
                                        partItemList = [...response.data[0].partsConsumable]
                                    }

                                    for (let i = 0; i < partItemList.length; i++) {
                                        const item = { ...partItemList[i].partNumber }
                                        if (!dataTemp.some(tempItem => item.partNumber === tempItem.value)) {
                                            platformApi.get("/api/partsMaster/" + item.id).then((res) => {
                                                let { data } = res;
                                                if (data.code === 200) {
                                                    let { response } = data;
                                                    if (response.code === 200) {
                                                        const temp = {
                                                            label: `${response.data.partNumber} - ${response.data.partName}`,
                                                            value: response.data.partNumber,
                                                            id: response.data.id,
                                                        }
                                                        setDataTemp([...dataTemp, temp])
                                                    }
                                                }
                                            })
                                        }
                                        partItemList[i].partNumber = item
                                        partItemList[i].hsn = item.hsn
                                        partItemList[i].cgst = item.hsn && item.hsn.cgst
                                        partItemList[i].sgst = item.hsn && item.hsn.sgst
                                        partItemList[i].igst = item.hsn && item.hsn.igst
                                        partItemList[i].gstRate = item.hsn && item.hsn.igst
                                        partItemList[i].unitRate = item.mrp ? item.mrp : 1
                                        partItemList[i].rate = (partItemList[i].quantity * partItemList[i].unitRate)
                                        partItemList[i].sgstAmount = parseFloat((partItemList[i].sgst / 100) * partItemList[i].rate).toFixed(2);
                                        partItemList[i].igstAmount = parseFloat((partItemList[i].igst / 100) * partItemList[i].rate).toFixed(2);
                                        partItemList[i].cgstAmount = parseFloat((partItemList[i].cgst / 100) * partItemList[i].rate).toFixed(2);
                                        setSelectedPart(item.partNumber)
                                        // setUnitRate(item.mrp) 

                                        let total = parseFloat(partItemList[i].rate)
                                        let finTotal = parseFloat(partItemList[i].rate)

                                        let gstRateValue = (partItemList[i].gstRate / 100) + parseInt(1);
                                        taxExlusive = (total * (partItemList[i].gstRate / 100)).toFixed(2);
                                        total = (parseFloat(total) + parseFloat(taxExlusive)).toFixed(2);

                                        // partItemList[i].grandTotal = total
                                        partItemList[i].grandTotal = finTotal
                                        if (partItemList[i].gstRate === GST28) {
                                            setGSTRate28(true)
                                        }
                                        if (partItemList[i].gstRate === GST18) {
                                            setGSTRate18(true)
                                        }
                                        if (partItemList[i].gstRate === GST12) {
                                            setGSTRate12(true)
                                        }
                                        if (partItemList[i].gstRate === GST5) {
                                            setGSTRate5(true)
                                        }

                                        // setSGSTAmount(sgstAmt)
                                        // setIGSTAmount(igstAmt)
                                        // setCGSTAmount(cgstAmt)
                                    }

                                    for (let j = 0; j < jobCodeItemList.length; j++) {
                                        jobCodeItemList[j].partNumber = { ...jobCodeItemList[j] }
                                        jobCodeItemList[j].hsn = jobCodeItemList[j].jobCode.sac
                                        jobCodeItemList[j].cgst = jobCodeItemList[j].jobCode.sac.cgst
                                        jobCodeItemList[j].sgst = jobCodeItemList[j].jobCode.sac.sgst
                                        jobCodeItemList[j].igst = jobCodeItemList[j].jobCode.sac.igst
                                        jobCodeItemList[j].gstRate = jobCodeItemList[j].jobCode.sac.cgst + jobCodeItemList[j].jobCode.sac.sgst
                                        jobCodeItemList[j].unitRate = jobCodeItemList[j]?.jobCode?.vehicleModel[0]?.price,

                                            setSelectedPart(jobCodeItemList[j].jobCode.code)
                                        jobCodeItemList[j].rate = (parseFloat(jobCodeItemList[j].quantity) * parseFloat(jobCodeItemList[j].unitRate)).toFixed(2);
                                        jobCodeItemList[j].sgstAmount = parseFloat((jobCodeItemList[j].sgst / 100) * jobCodeItemList[j].rate).toFixed(2);
                                        jobCodeItemList[j].igstAmount = parseFloat((jobCodeItemList[j].igst / 100) * jobCodeItemList[j].rate).toFixed(2);
                                        jobCodeItemList[j].cgstAmount = parseFloat((jobCodeItemList[j].cgst / 100) * jobCodeItemList[j].rate).toFixed(2);

                                        let total = parseFloat(jobCodeItemList[j].rate)

                                        // let gstRateValue = (jobCodeItemList[j].gstRate / 100) + parseInt(1);
                                        // taxExlusive = (total * (jobCodeItemList[j].gstRate / 100)).toFixed(2);
                                        // total = (parseFloat(total) + parseFloat(taxExlusive)).toFixed(2);

                                        total = total + parseFloat((jobCodeItemList[j].gstRate / 100) * total)

                                        jobCodeItemList[j].grandTotal = total

                                        if (jobCodeItemList[j].gstRate === GST28) {
                                            setGSTRate28(true)
                                        }
                                        if (jobCodeItemList[j].gstRate === GST18) {
                                            setGSTRate18(true)
                                        }
                                        if (jobCodeItemList[j].gstRate === GST12) {
                                            setGSTRate12(true)
                                        }
                                        if (jobCodeItemList[j].gstRate === GST5) {
                                            setGSTRate5(true)
                                        }
                                    }
                                    let updateItemList = [...partItemList, ...jobCodeItemList]

                                    for (let i = 0; i < updateItemList.length; i++) {
                                        grandTotal = parseFloat(grandTotal) + parseFloat(updateItemList[i].grandTotal);
                                        grandTotal = grandTotal.toFixed(2)
                                        estTotal = parseFloat(estTotal) + parseFloat(updateItemList[i].grandTotal);
                                        estTotal = estTotal.toFixed(0)
                                        TotalCgst = parseFloat(TotalCgst) + parseFloat(updateItemList[i].cgstAmount);
                                        TotalCgst = TotalCgst.toFixed(2)
                                        TotalSgst = parseFloat(TotalSgst) + parseFloat(updateItemList[i].sgstAmount);
                                        TotalSgst = TotalSgst.toFixed(2)
                                        TotalIgst = parseFloat(TotalIgst) + parseFloat(updateItemList[i].igstAmount);
                                        TotalIgst = TotalIgst.toFixed(2)

                                        if (updateItemList[i].rate) {
                                            if (updateItemList[i].partNumber.code || updateItemList[i].jobCode) {
                                                if (updateItemList[i].partNumber?.consumable || updateItemList[i].jobCode?.consumable) {
                                                    consumableCharge = parseFloat(consumableCharge) + parseFloat(updateItemList[i].rate);
                                                }else {
                                                    labourCharge = parseFloat(labourCharge) + parseFloat( updateItemList[i].rate );
                                                }
                                            }
                                            else if (updateItemList[i].partNumber.hsn) {
                                                pCharge = parseFloat(pCharge) + parseFloat(updateItemList[i].rate)
                                            }

                                        }
                                        console.log("Grand Total:", grandTotal)
                                        // setGrandTotal(grandTotal);
                                    }
                                    let roundoff = grandTotal.split('.');
                                    if (roundoff[1] === "00") {
                                        temp = "0.00"
                                    }
                                    else if (parseInt(roundoff[1]) >= 50) {
                                        temp = 100 - parseInt(roundoff[1])
                                        temp = `+0.${temp}`
                                    }
                                    else if (parseInt(roundoff[1]) < 50) {
                                        temp = 100 - parseInt(roundoff[1])
                                        temp = `-0.${parseInt(roundoff[1])}`
                                    }

                                    form.setFieldsValue({
                                        totalAmount: estTotal,
                                        cgst: TotalCgst,
                                        sgst: TotalSgst,
                                        igst: TotalIgst,
                                        partCharge: pCharge,
                                        labourCharge: labourCharge,
                                        consumableCharge: consumableCharge,
                                        roundoff: temp
                                    })
                                    setItemList([...updateItemList, partItemData]);
                                }
                                else {
                                    message.info("No Recommended Parts and Job Codes");
                                }
                            }
                            else {
                                message.error("No Recommended Parts and Job Codes");
                            }
                        }
                        else {
                            message.error("No Recommended Parts and Job Codes");
                        }
                    })
                    .catch((error) => {
                        console.error("Error on PMC : ", error);
                        message.error("Failed to fetch Parts and Job Codes");
                    });
            }
        }
    }, [recPart])

    //Discount 
    useEffect(() => {
        let val = 0;
        let discountedPrice = 0;
        let newItemList = [...itemList]
        // Transaction level Dicount Calculation
        if (discountLevel === 'TransactionLevel') {
            for (let i = 0; i < newItemList.length; i++) {
                let total = parseFloat(newItemList[i].unitRate * newItemList[i].quantity)
                let gstRateValue = (newItemList[i].gstRate / 100) + parseInt(1);
                if (discountAmount > 0 && newItemList[i].unitRate > 0 && newItemList[i].quantity) {
                    if (discountType === 'percent') {
                        discountedPrice = (parseFloat(discountAmount) / 100) * (total);
                        val = val + discountedPrice
                        val = Math.round(val)
                        setTotalDiscount(val || 0);
                        setFieldsValue({
                            totalDiscount: val
                        })
                    } else if (discountType === 'rupee') {
                        val = val + parseFloat(discountAmount)
                        setTotalDiscount(val || 0);
                        setFieldsValue({
                            totalDiscount: val
                        })
                    }
                }
            }
        }
        // Item level Discount calculation 
        if (discountLevel === 'ItemLevel') {
            for (let i = 0; i < newItemList.length; i++) {
                let total = newItemList[i].unitRate * newItemList[i].quantity;
                let gstRateValue = (newItemList[i].gstRate / 100) + parseInt(1);
                if (newItemList[i].discount > 0) {
                    if (discountType === 'percent') {
                        discountedPrice = (parseFloat(newItemList[i].discount) / 100) * (total);
                        val = val + discountedPrice
                        setTotalDiscount(val || 0);
                        setFieldsValue({
                            totalDiscount: val
                        })
                    } else if (discountType === 'rupee') {
                        val = val + parseFloat(newItemList[i].discount)
                        setTotalDiscount(val || 0);
                        setFieldsValue({
                            totalDiscount: val
                        })
                    }
                }
            }
        }
        setItemList(newItemList)
    }, [discountLevel, discountAmount, discountType, discount, quantity, unitRate, rate, grandTtl]);


    //Render Column
    const renderColumns = () => {
        const columns = [
            {
                title: "Details",
                dataIndex: "partNumber",
                key: "partNumber",
                width: 220,
                render: (record, data, key) => (
                    <Form.Item>
                        <Select
                            dropdownStyle={{
                                overflowY: "auto",
                                overflowX: "hidden",
                            }}
                            onChange={(event) => {
                                handlePartDetail(event, data)
                            }}
                            onSearch={async (event) => {
                                const search = {
                                    module: "partsMasters",
                                    column: "partNumber",
                                    searchString: event,
                                    searchColumns: ["partNumber", "partName"],
                                    setValue: setPartsData
                                };
                                searchParts(search)
                                const searchjobCode = {
                                    module: "jobCodes",
                                    column: "code",
                                    searchString: event,
                                    searchColumns: ["code"],
                                    setValue: setJobCode
                                };
                                searchJobCode(searchjobCode)
                                if (dataTemp?.length === 1) {
                                    console.log("dataTemp", dataTemp)
                                    handlePartDetail(dataTemp[0].value, data)
                                    partsRef[key].focus()
                                }
                            }}
                            onFocus={() => { console.log("Focus") }}
                            value={
                                (record?.partNumber == undefined) ?
                                    record?.code ? record.code
                                        : record?.jobCode
                                            ? record.jobCode.code
                                            : "Part Number"
                                    : `${record.partNumber} - ${record.partName}`}
                            disabled={editable}
                            showSearch
                            showAction={["click", "focus"]}
                            placeholder="Part Number/Job Code"
                        // filterOption={(input, option) =>
                        //     option.props.children.some(
                        //         (child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        //     )
                        // }
                        >
                            {dataTemp.map((part) => {
                                if(part.id in selectItemObj) return null;
                                return(
                               <Select.Option key={part.id} value={part.value}>
                                    {part.label}
                                    
                                </Select.Option>
                                )
                                })}
                        </Select>
                    </Form.Item>
                )
            },
            {
                title: "HSN",
                dataIndex: "hsn",
                key: "hsn",
                width: 130,
                render: (record, index) => (
                    <Form.Item>
                        <span>{record && record.code}</span>
                    </Form.Item>
                )
            },
            {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
                width: 100,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="quantity"
                            disabled={editable}
                            onChange={(event) => {
                                handlequantity(event, record)
                            }}
                            ref={el => partsRef[index] = el}
                            placeholder="quantity"
                            value={record && record.quantity}
                        />
                    </Form.Item>
                )
            },
            {
                title: "Unit rate",
                dataIndex: "unitRate",
                key: "unitRate",
                width: 100,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="unitRate"
                            disabled={editable}
                            onChange={(event) => {
                                handleUnitrate(event, record)
                            }}
                            placeholder="Unit Rate"
                            value={record && record.unitRate}
                        />
                    </Form.Item>
                ),
            },
            {
                title: "Amount",
                dataIndex: "rate",
                key: "rate",
                width: 120,
                render: (data, record) => (
                    <Form.Item>
                        <Input
                            key="rate"
                            disabled={editable}
                            placeholder="Amount"
                            value={record && record.rate}
                        />
                    </Form.Item>
                ),
            },
            {
                title: "GST%",
                dataIndex: "gstRate",
                key: "gstRate",
                width: 120,
                render: (data, record) => (
                    <Form.Item>
                        <span>{record && record.gstRate}</span>
                    </Form.Item>
                ),
            },
            {
                title: "Discount",
                dataIndex: "discount",
                key: "discount",
                width: 100,
                render: (data, record) => (
                    <Form.Item>
                        <Input
                            key="discount"
                            onChange={(event) => {
                                handleDiscount(event, record)
                            }}
                            disabled={editable}
                            placeholder="Discount"
                            value={record && record.discount}
                        />
                    </Form.Item>
                ),
            },
            {
                title: 'Action',
                dataIndex: 'remove',
                key: 'x',
                width: 100,
                render: (data, record) => (
                    <Form.Item>
                        {
                            itemList.length >= 1 ? (
                                <Popconfirm title="Sure to delete?" onConfirm={() => { handleDelete(record.id) }}>
                                    <a style={{ color: '#6c7b8a' }}>Delete</a>
                                </Popconfirm>
                            ) : null
                        }
                    </Form.Item>
                ),
            },
        ];
        return columns.filter(col => (discountLevel === "TransactionLevel") ? col.dataIndex !== 'discount' : col);
    }

    //Handle PartDetail
    const handlePartDetail = async (event, data) => {
        setSelectedPart(event)
        const newItemList = [...itemList];
        if (event !== undefined) {
            partsData.map(part => {
                if (part.partNumber === event) {
                    for (let item of newItemList) {
                        if (item.id === data.id) {
                            item.partNumber = part,
                                item.value = part,
                                item.hsn = part.hsn,
                                item.cgst = part.hsn.cgst
                            item.igst = part.hsn.igst
                            item.sgst = part.hsn.sgst
                            item.gstRate = part.hsn.cgst + part.hsn.sgst
                            item.unitRate = part.mrp
                            item.quantity = 1
                            setquantity(1)
                            setUnitRate(part.mrp)
                            if (item.gstRate === GST18) {
                                setGSTRate18(true)
                            }
                            if (item.gstRate === GST28) {
                                setGSTRate28(true)
                            }
                            if (item.gstRate === GST12) {
                                setGSTRate12(true)
                            }
                            if (item.gstRate === GST5) {
                                setGSTRate5(true)
                            }
                            setCGST(part.hsn.cgst),
                                setSGST(part.hsn.sgst),
                                setIGST(part.hsn.igst)

                        }
                    }
                }
            })
            jobCode.map(part => {
                if (part.code === event) {
                    for (let item of newItemList) {
                        if (item.id === data.id) {
                            item.partNumber = part,
                                item.hsn = part.sac,
                                item.cgst = part.sac.cgst
                            item.igst = part.sac.igst
                            item.sgst = part.sac.sgst
                            item.gstRate = part.sac.cgst + part.sac.sgst
                            let price = part.vehicleModel.filter(item => item.vehicle.id === model)
                            item.unitRate = price.length && price[0].price
                            // item.unitRate = part.vehicleModel[0].price
                            item.quantity = 1
                            setquantity(1)
                            setUnitRate(price.length && price[0].price)
                            if (item.gstRate === GST18) {
                                setGSTRate18(true)
                            }
                            if (item.gstRate === GST28) {
                                setGSTRate28(true)
                            }
                            if (item.gstRate === GST12) {
                                setGSTRate12(true)
                            }
                            if (item.gstRate === GST5) {
                                setGSTRate5(true)
                            }
                            setCGST(part.sac.cgst),
                                setSGST(part.sac.sgst),
                                setIGST(part.sac.igst)
                        }
                    }
                }
            })
        }
        console.log("newItemList", newItemList)
        setItemList(newItemList)
        getParts();
        getJobCodes();
    }

    //Calculation
    useEffect(() => {
        //Calucate TAX at transaction level
        if (discountLevel === "TransactionLevel") {
            calculateTransactionTax();
        }
        //Calucate TAX at Item level
        else if (discountLevel === "ItemLevel") {
            calculateItemTax();
        }

    }, [unitRate, quantity, rate, discountAmount, discount, grandTtl, discountLevel, discountType, deleteItem]);

    //TranscationMax
    const calculateTransactionTax = () => {
        let taxExclusive = 0;
        let taxInclusive = 0;
        let newItemList = [...itemList];
        let grandTotal = 0;
        let TotalCgst = 0;
        let TotalSgst = 0;
        let TotalIgst = 0;
        let pCharge = 0;
        let labourCharge = 0;
        let consumableCharge = 0;
        let estTotal = 0;
        let temp = 0;

        for (let i = 0; i < newItemList.length; i++) {
            if (newItemList[i].rate > 0 && newItemList[i].gstRate > 0) {
                let total = parseFloat(newItemList[i].rate)
                let gstRateValue = (newItemList[i].gstRate / 100) + parseInt(1);
                if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
                    taxExclusive = (total * (newItemList[i].gstRate / 100)).toFixed(2);
                    if (totalDiscount > 0 && !isNaN(totalDiscount)) {
                        if (discountType === 'percent') {
                            total = total - (total * (parseFloat(discountAmount) / 100))
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        else {
                            total = total - parseFloat(discountAmount);
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        total = total + parseFloat((newItemList[i].gstRate / 100) * total)
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        newItemList[i].grandTotal = total
                    }
                    else {
                        // total = (parseFloat(total) + parseFloat(taxExlusive)).toFixed(2);
                        total = total + parseFloat((newItemList[i].gstRate / 100) * total)
                        newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
                        newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
                        newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
                        sgstAmt = newItemList[i].sgstAmount;
                        cgstAmt = newItemList[i].cgstAmount;
                        igstAmt = newItemList[i].igstAmount;
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        newItemList[i].grandTotal = total
                    }
                }
                else if (newItemList[i].partNumber.partNumber || newItemList[i].partNumber.hsn) {
                    // taxInclusive = (parseFloat(total) - parseFloat(((total) / gstRateValue))).toFixed(2);
                    if (totalDiscount > 0 && !isNaN(totalDiscount)) {
                        if (discountType === 'percent') {
                            // console.log("total", total)
                            // let val1 = total / gstRateValue;
                            // let val2 = (total / gstRateValue) * (parseFloat(discountAmount) / 100)
                            // total = (
                            //     parseFloat(val1) - parseFloat(val2)
                            // )
                            // total = total - parseFloat(val2)
                            total = ((total / gstRateValue) - ((total / gstRateValue) * (parseFloat(discountAmount) / 100))) * (newItemList[i].gstRate / 100) + ((total / gstRateValue) - ((total / gstRateValue) * (parseFloat(discountAmount) / 100)))
                            console.log(total)
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        else {
                            // total = (total / gstRateValue) - parseFloat(discountAmount)
                            // total = total - parseFloat(discountAmount)
                            total = (total / gstRateValue) - parseFloat(discountAmount)
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        // console.log("Total", total)
                        // total = total * gstRateValue
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        newItemList[i].grandTotal = total
                    }
                    else {
                        let Fintotal = parseFloat(total)
                        total = (parseFloat(total) - parseFloat(taxInclusive)).toFixed(2);
                        newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
                        newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
                        newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
                        sgstAmt = newItemList[i].sgstAmount;
                        cgstAmt = newItemList[i].cgstAmount;
                        igstAmt = newItemList[i].igstAmount;
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        // newItemList[i].grandTotal = total
                        newItemList[i].grandTotal = Fintotal
                    }
                }
            }
        }

        for (let i = 0; i < newItemList.length; i++) {
            grandTotal = parseFloat(grandTotal) + parseFloat(newItemList[i].grandTotal);
            grandTotal = grandTotal.toFixed(2)
            estTotal = parseFloat(estTotal) + parseFloat(newItemList[i].grandTotal);
            TotalCgst = parseFloat(TotalCgst) + parseFloat(newItemList[i].cgstAmount);
            TotalCgst = TotalCgst.toFixed(2)
            TotalSgst = parseFloat(TotalSgst) + parseFloat(newItemList[i].sgstAmount);
            TotalSgst = TotalSgst.toFixed(2)
            TotalIgst = parseFloat(TotalIgst) + parseFloat(newItemList[i].igstAmount);
            TotalIgst = TotalIgst.toFixed(2)

            if (newItemList[i].rate) {
                if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
                    if (newItemList[i].partNumber?.consumable || newItemList[i].jobCode?.consumable) {
                        consumableCharge = parseFloat(consumableCharge) + parseFloat(newItemList[i].rate)
                    }else {
                        labourCharge = parseFloat(labourCharge) + parseFloat(newItemList[i].rate);
                    }
                    // console.log("consumableCharge", consumableCharge)
                }
                else if (newItemList[i].partNumber.hsn) {
                    pCharge = parseFloat(pCharge) + parseFloat(newItemList[i].rate)
                }
            }
            setGrandTotal(grandTotal);
        }

        let roundoff = grandTotal.split('.');
        if (roundoff[1] === "00") {
            temp = "0.00"
        }
        else if (parseInt(roundoff[1]) >= 50) {
            temp = 100 - parseInt(roundoff[1])
            temp = `+0.${temp}`
        }
        else if (parseInt(roundoff[1]) < 50) {
            temp = 100 - parseInt(roundoff[1])
            temp = `-0.${parseInt(roundoff[1])}`
        }
        estTotal = estTotal.toFixed(0)
        // console.log("TranscationTemp", consumableCharge)
        form.setFieldsValue({
            totalAmount: estTotal,
            cgst: TotalCgst,
            sgst: TotalSgst,
            igst: TotalIgst,
            partCharge: pCharge,
            labourCharge: labourCharge,
            consumableCharge: consumableCharge,
            roundoff: temp
        })
        setItemList(newItemList)


    }
    //ItemMax
    const calculateItemTax = () => {
        let taxExclusive = 0;
        let taxInclusive = 0;
        let newItemList = [...itemList];
        let grandTotal = 0;
        let pCharge = 0;
        let labourCharge = 0;
        let consumableCharge = 0;
        let TotalCgst = 0;
        let TotalSgst = 0;
        let TotalIgst = 0;
        let estTotal = 0;
        let temp;

        for (let i = 0; i < newItemList.length; i++) {
            if (newItemList[i].rate > 0 && newItemList[i].gstRate > 0) {
                let total = parseFloat(newItemList[i].rate)
                let gstRateValue = (newItemList[i].gstRate / 100) + parseInt(1);
                // taxExlusive = (total * (newItemList[i].gstRate / 100)).toFixed(2);
                if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
                    taxExclusive = (total * (newItemList[i].gstRate / 100)).toFixed(2);
                    if (newItemList[i].discount > 0 && !isNaN(newItemList[i].discount)) {
                        if (discountType === 'percent') {
                            total = total - (total * (parseFloat(newItemList[i].discount) / 100))
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        else {
                            total = total - parseFloat(newItemList[i].discount);
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        total = total + parseFloat((newItemList[i].gstRate / 100) * total)
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        newItemList[i].grandTotal = total
                    }
                    else {
                        let Fintotal = parseFloat(total)
                        total = (parseFloat(total) - parseFloat(taxInclusive)).toFixed(2);
                        total = total + parseFloat((newItemList[i].gstRate / 100) * total)
                        newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
                        newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
                        newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
                        sgstAmt = newItemList[i].sgstAmount;
                        cgstAmt = newItemList[i].cgstAmount;
                        igstAmt = newItemList[i].igstAmount;
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        // newItemList[i].grandTotal = total
                        newItemList[i].grandTotal = Fintotal

                    }
                }
                else if (newItemList[i].partNumber.partNumber || newItemList[i].partNumber.hsn) {
                    taxInclusive = (parseFloat(total) - parseFloat(((total) / gstRateValue))).toFixed(2);
                    if (newItemList[i].discount > 0 && !isNaN(newItemList[i].discount)) {
                        if (discountType === 'percent') {
                            total = total - (total * (parseFloat(newItemList[i].discount) / 100))
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        else {
                            total = total - parseFloat(newItemList[i].discount);
                            newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
                            newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
                            newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
                            sgstAmt = newItemList[i].sgstAmount;
                            cgstAmt = newItemList[i].cgstAmount;
                            igstAmt = newItemList[i].igstAmount;
                        }
                        // total = total + parseFloat((newItemList[i].gstRate / 100) * total)
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        newItemList[i].grandTotal = total
                    }
                    else {
                        let Fintotal = parseFloat(total)
                        total = (parseFloat(total) + parseFloat(taxInclusive)).toFixed(2);
                        newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
                        newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
                        newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
                        sgstAmt = newItemList[i].sgstAmount;
                        cgstAmt = newItemList[i].cgstAmount;
                        igstAmt = newItemList[i].igstAmount;
                        setSGSTAmount(sgstAmt)
                        setIGSTAmount(igstAmt)
                        setCGSTAmount(cgstAmt)
                        // newItemList[i].grandTotal = total
                        newItemList[i].grandTotal = Fintotal
                    }
                }
            }
        }

        for (let i = 0; i < newItemList.length; i++) {
            grandTotal = (parseFloat(grandTotal) + parseFloat(newItemList[i].grandTotal)).toFixed(2);
            estTotal = parseFloat(estTotal) + parseFloat(newItemList[i].grandTotal);
            TotalCgst = parseFloat(TotalCgst) + parseFloat(newItemList[i].cgstAmount);
            TotalCgst = TotalCgst.toFixed(2)
            TotalSgst = parseFloat(TotalSgst) + parseFloat(newItemList[i].sgstAmount);
            TotalSgst = TotalSgst.toFixed(2)
            TotalIgst = parseFloat(TotalIgst) + parseFloat(newItemList[i].igstAmount);
            TotalIgst = TotalIgst.toFixed(2)

            if (newItemList[i].rate) {
                if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
                    if (newItemList[i].partNumber?.consumable || newItemList[i].jobCode?.consumable) {
                        consumableCharge = parseFloat(consumableCharge) + parseFloat(newItemList[i].rate)
                    }else {
                        labourCharge = parseFloat(labourCharge) + parseFloat(newItemList[i].rate)
                    }
                }
                else if (newItemList[i].partNumber.hsn) {
                    pCharge = parseFloat(pCharge) + parseFloat(newItemList[i].rate)
                }

            }
            setGrandTotal(grandTotal);

        }
        let roundoff = grandTotal.split('.');
        if (parseInt(roundoff[1]) >= 50) {
            temp = 100 - parseInt(roundoff[1])
            temp = `+0.${temp}`
        }
        else if (parseInt(roundoff[1]) < 50) {
            temp = 100 - parseInt(roundoff[1])
            temp = `-0.${parseInt(roundoff[1])}`
        }
        estTotal = estTotal.toFixed(0)
        form.setFieldsValue({
            totalAmount: estTotal,
            cgst: TotalCgst,
            sgst: TotalSgst,
            igst: TotalIgst,
            partCharge: pCharge,
            labourCharge: labourCharge,
            consumableCharge: consumableCharge,
            roundoff: temp
        })
        setItemList(itemList)

    }
    //Handle Add
    const handleAdd = () => {
        let newArr = [...itemList, partItemData];
        setItemList(newArr);
    }
    //Handle UnitRate
    const handleUnitrate = (event, record) => {
        setUnitRate(event.target.value)
        let newItemList = [...itemList];
        for (let item of newItemList) {
            if (item.id === record.id) {
                item.unitRate = event.target.value
                item.rate = (item.quantity * item.unitRate)
                break;
            }
        }
    }
    //Handle Discount
    const handleDiscount = (event, record) => {
        setDiscount(event.target.value)
        let newItemList = [...itemList];
        for (let item of newItemList) {
            if (item.id === record.id) {
                item.discount = event.target.value
                break;
            }
        }
    }
    //Handle Quantity
    const handlequantity = (event, record) => {
        setquantity(event.target.value);
        let newItemList = [...itemList];
        for (let item of newItemList) {
            if (item.id === record.id) {
                item.quantity = event.target.value
                break;
            }
        }
        setItemList(newItemList);
    }
    //Handle Cancel
    const handleCancel = () => {
        form.resetFields();
        setGrandTotal(0);
        setDiscountAmount(0);
        setSelectedPart('');
        setItemList([partItemData]);
        setRecPart('');
        setJobNo([]);
        setPartsData([]);
        setJobCode([]);
        setDeleteData1([])
        setDeleteData2([])
        setStateName("");
        // setJobid('');
        setGst("");
        history.push('/autoadmin/estimate');
    }
    //Handle Submit
    const handleSubmit = () => {
        form.validateFields((validationError) => {
            let totalSGSTAmt = 0;
            let totalCGSTAmt = 0;
            let totalIGSTAmt = 0;
            let newItemList = [...itemList];
            // eslint-disable-next-line for-direction
            for (let i = 0; i < (newItemList.length); i++) {
                if (newItemList[i].sgstAmount > 0 && newItemList[i].cgstAmount > 0 && newItemList[i].igstAmount > 0) {
                    totalSGSTAmt = totalSGSTAmt + parseFloat(newItemList[i].sgstAmount);
                    totalCGSTAmt = totalCGSTAmt + parseFloat(newItemList[i].cgstAmount);
                    totalIGSTAmt = totalIGSTAmt + parseFloat(newItemList[i].igstAmount);
                }
                newItemList[i].discountPercent = discountType === "percent" ? newItemList[i].discount : 0
                newItemList[i].discountRate = discountType === "rupee" ? newItemList[i].discount : 0
            }
            if (newItemList.length > 1) {
                newItemList = newItemList.filter(item => {
                    return item.partNumber && item.hsn && item.rate && item.igst && item.cgst && item.sgst
                })
                // newItemList = newItemList.slice(0, newItemList.length - 1)
            }

            setItemList(newItemList)
            const data = {
                estimateNo: estimateNo,
                jobOrder: getFieldValue("JobNo"),
                discountLevel,
                discountType,
                currentDate: moment(Date.now()),
                discountPercent: discountType === "percent" ? discountAmount : 0,
                discountRate: discountType === "rupee" ? discountAmount : 0,
                cgstAmount: stateName &&
                    stateName?.slice(0, 2) === gst && gst.slice(0, 2)
                    ? totalCGSTAmt
                    : totalCGSTAmt,
                sgstAmount: stateName &&
                    stateName?.slice(0, 2) === gst && gst.slice(0, 2)
                    ? totalSGSTAmt
                    : totalSGSTAmt,
                igstAmount: stateName &&
                    stateName?.slice(0, 2) !== gst && gst.slice(0, 2)
                    ? totalIGSTAmt
                    : null,
                labourCharge: getFieldValue("labourCharge") ? getFieldValue("labourCharge") : 0,
                consumableCharge: getFieldValue("consumableCharge") ? getFieldValue("consumableCharge") : 0,
                partCharge: getFieldValue("partCharge") ? getFieldValue("partCharge") : 0,
                estTotalAmount: getFieldValue("totalAmount") ? getFieldValue("totalAmount") : grandTtl,
                totalDiscount,
                estimateItemInvoice: newItemList,
                branch: branch,
                deleteData1: deleteData1,
                deleteData2: deleteData2
            };
            // console.log("handleSubmit", data)
            if (validationError === null) {
                setBtnFlag(true)
                if (!modifyType) {
                    platformApi.post("/api/estimate/", data).then((result) => {
                        let { data } = result;
                        if (data.code === 200) {
                            let { response } = data;
                            if (response.code === 200) {
                                message.success("Estimate Added Successfully");
                                history.push('/autoadmin/estimate');
                            }
                        }
                    }).catch((err) => {
                        console.log(err)
                        message.error("Couldn't Add Estimate");
                    });
                }
                else {

                    platformApi.put(`/api/estimate/${id}`, data).then((result) => {
                        let { data } = result;
                        if (data.code === 200) {
                            let { response } = data;
                            if (response.code === 200) {
                                message.success("Estimate Modified Successfully");
                                history.push('/autoadmin/estimate');
                            }
                        }
                        else {
                            message.error("Estimate Modified Failed");
                            setBtnFlag(false)
                        }
                    }).catch((err) => {
                        console.error("Estimate Add Error: ", err);
                        message.error("Couldn't update Estimate");
                        setBtnFlag(false)
                    });
                }
            }
            else {
                message.error('select atleast one item list')
            }
        })
    }

    //Handle Delete
    const handleDelete = (selectedId) => {
        let grandTotal = 0;
        let TotalCgst = 0;
        let TotalSgst = 0;
        let TotalIgst = 0;
        const updatedDataSource = itemList.filter(item => item.id !== selectedId);
        for (let i = 0; i < updatedDataSource.length; i++) {
            grandTotal = (parseFloat(grandTotal) + parseFloat(updatedDataSource[i].grandTotal)).toFixed(2);
            setGrandTotal(grandTotal);
            TotalCgst = parseFloat(TotalCgst) + parseFloat(updatedDataSource[i].cgstAmount).toFixed(2);
            TotalSgst = parseFloat(TotalSgst) + parseFloat(updatedDataSource[i].sgstAmount).toFixed(2);
            TotalIgst = parseFloat(TotalIgst) + parseFloat(updatedDataSource[i].igstAmount).toFixed(2);
            form.setFieldsValue({
                totalAmount: grandTotal,
                cgst: TotalCgst,
                sgst: TotalSgst,
                igst: TotalIgst
            })
        }
        deleteParts(selectedId)
        setItemList(updatedDataSource)
    }

    const deleteParts = (e) => {
        if (pid.indexOf(e) >= 0) {
            deleteData1.push(e)
        }
        else if (jid.indexOf(e) >= 0) {
            deleteData2.push(e)
        }
        setDeleteData1(deleteData1)
        setDeleteData2(deleteData2)
    }
    //Searches
    const searchJobNo = (search) => {
        platformApi
            .post("/api/options/get/", {
                module: search.module,
                column: search.column,
                searchString: search.searchString,
                fields: search.fields,
                size: 100,
                page: 1,
                except: null,
            })
            .then((res) => {
                search.setValue(res.data.response);
            });
    }

    const searchParts = async (search) => {
        // const res = await platformApi
        //     .post("/api/options/get/", {
        //         module: search.module,
        //         column: search.column,
        //         searchString: search.searchString,
        //         fields: search.fields,
        //         size: 100,
        //         page: 1,
        //         except: null,
        //         branch: pBranch
        //     })
        // let parts = [];
        // // console.log("Response", res.data)
        // for (let i = 0; i < res.data.response?.length; i++) {
        //     const element = res.data.response[i];
        //     parts.push(element.partNo)
        // }
        // search.setValue(parts)
        // return 1
        // .then((res) => {
        //     let parts = [];
        //     for (let i = 0; i < res.data.response.length; i++) {
        //         const element = res.data.response[i];
        //         parts.push(element.partNo)
        //     }
        //     setPartsData(parts)
        //     return parts;
        // });

        const res = await platformApi
            .post("/api/options/get/", {
                module: search.module,
                column: search.column,
                searchString: search.searchString,
                fields: search.fields,
                size: 100,
                page: 1,
                except: null,
            })
        search.setValue(res.data.response);
        return 1
    }

    const searchJobCode = (search) => {
        platformApi
            .post("/api/options/get/", {
                module: search.module,
                column: search.column,
                searchString: search.searchString,
                fields: search.fields,
                size: 100,
                page: 1,
                except: null,
            })
            .then((res) => {
                search.setValue(() => {
                    return res.data.response;
                });
            });
    }

    const getJobNo = () => {
        platformApi
            .post("/api/jobOrder/getJobNo", {
                size: 100,
                page: 1,
            })
            .then((res) => {
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setJobNo(response.data);
                    } else {
                        message.error("Failed to fetch Job Order");
                    }
                }
                else {
                    message.error("Failed to fetch Job Order");
                }
            })
            .catch((error) => {
                console.error("Error on Job Order : ", error);
                message.error("Failed to fetch Job Order");
            });
    }

    return (
        <div>
            <Form className="purchase-form">
                <Row>
                    <Col span={12}>
                        <Text strong>
                            Estimate No&nbsp;&nbsp;&nbsp;:{!editData ? estimateNo : editData.estimateNo}
                        </Text>
                        <Form.Item className="purchase-form-element" label={<Text strong> Estimate Status&nbsp;&nbsp;&nbsp;</Text>} >
                            <div style={{ marginLeft: '20px' }}>
                                <Text strong>
                                    {editData && editData.estimateStatus ? editData.estimateStatus : "-"}
                                </Text>
                            </div>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="purchase-form-element"
                            label="Date & Time"
                            name="currentDate"
                        >
                            <DatePicker
                                disabled={true}
                                placeholder={moment(Date.now()).format('LL')}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item colon={false} className="purchase-form-element" label={<Text>Ass.Job No</Text>}>
                            {getFieldDecorator("JobNo", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Select Job No",
                                    },
                                ],
                            })(
                                <Select
                                    showAction={["click", "focus"]}
                                    showSearch
                                    filterOption={null}
                                    disabled={editable}
                                    placeholder="Job No"
                                    onSearch={(e) => {
                                        const search = {
                                            module: "jobOrders",
                                            column: "jobNo",
                                            searchString: e,
                                            searchColumns: ["jobNo"],
                                            setValue: setJobNo
                                        };
                                        searchJobNo(search);
                                    }}
                                    onSelect={getJobOrder}
                                >
                                    {jobNo.length && jobNo.map((item) => (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.jobNo}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item colon={false} className="purchase-form-element" label={<Text>Branch</Text>}>
                            {getFieldDecorator("branch", {
                                rules: [
                                    {
                                        required: false,
                                        message: "Select Job No",
                                    },
                                ],
                            })(
                                <Input
                                    disabled={true}
                                    placeholder={"Branch"}
                                />
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            className="form-element-type"
                            label={<Text strong>Discount Type?</Text>}

                        >
                            {
                                getFieldDecorator("discountType", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Select discountType"
                                        }
                                    ]
                                })(
                                    <Group
                                        // onChange={(value) => setDiscountType(value)}
                                        onChange={(e) => {
                                            setFieldsValue({
                                                discountType: e.target.value
                                            })
                                            setDiscountType(e.target.value)
                                        }}
                                        // value={discountLevel}
                                        disabled={editable}>
                                        <Radio value="percent">Percentage(%) </Radio>
                                        <Radio value="rupee"> Amount (Rs) </Radio>
                                    </Group>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-element-type"
                            label={<Text strong>Add Recommended Parts & Services?</Text>}
                        >
                            {
                                getFieldDecorator("recPart", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Select Recommended Parts & Services"
                                        }
                                    ]
                                })(
                                    <Group
                                        onChange={(e) => {
                                            setFieldsValue({
                                                recPart: e.target.value
                                            })
                                            setRecPart(e.target.value)
                                        }}
                                        // value={discountLevel}
                                        disabled={editable}>
                                        <Radio value="yes">Yes </Radio>
                                        <Radio value="no"> No </Radio>
                                    </Group>
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item
                            className="form-element-type"
                            label="Discount Level"
                            name="dicountLevel"
                        >
                            {
                                getFieldDecorator("discountLevel", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Select Discount Level"
                                        }
                                    ]
                                })(
                                    <Select
                                        showSearch
                                        showAction={["click", "focus"]}
                                        style={{ width: 200 }}
                                        disabled={editable}
                                        placeholder="Select Discount Level"
                                        onChange={(e) => {
                                            setDiscountLevel(e)
                                            setFieldsValue({
                                                discountLevel: e
                                            })
                                        }}
                                    // onChange={(value) => setDiscountType(value)}
                                    // value={discountType}
                                    >
                                        <Option value="TransactionLevel">At Transaction Level</Option>
                                        {/* <Option value="ItemLevel">At Item Level</Option> */}
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    {
                        (discountLevel === "TransactionLevel") &&
                        <Col span={6}>
                            <Form.Item
                                className="form-element-type"
                                label={discountType === "percent" ? "Discount Percent" : "Discount Rupee"}
                                name="discountAmount"
                                onChange={(e) => setDiscountAmount(e.target.value)}
                                rules={[{}]}
                            >
                                <Input
                                    disabled={editable}
                                    placeholder={discountType === "percent" ? "Discount Percent" : "Discount Rupee"}
                                    value={(discountLevel === "TransactionLevel" && discountAmount !== 0) ? discountAmount : discountAmount}
                                />
                            </Form.Item>
                        </Col>
                    }
                </Row>
                <Table
                    className="parts-invoice-table"
                    rowKey={(record) => { record.id }}
                    columns={renderColumns()}
                    dataSource={itemList}
                    tableLayout="fixed"
                    pagination={false}
                    style={{ marginTop: '1rem', marginBottom: '1rem' }}
                />
                <br />
                <Row>
                    <Col span={6}></Col>
                    <Col span={8}>
                        <div className="gst-table">
                            <Title level={4} className="gst-title">GST Data</Title>
                            <GSTTable
                                gst={gst}
                                SGST={SGST}
                                CGST={CGST}
                                IGST={IGST}
                                itemList={itemList}
                                GSTRate18={GSTRate18}
                                GSTRate28={GSTRate28}
                                GSTRate5={GSTRate5}
                                GSTRate12={GSTRate12}
                            />
                        </div>
                    </Col>
                    <Col span={6}></Col>
                </Row>
                <br />
                <Row gutter={16}>
                    <Col span={7}>
                        <Form.Item
                            className="form-element"
                            label="Labour Charge"
                        >
                            {
                                getFieldDecorator("labourCharge", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Labour Charge"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Labour Charge"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="CGST"
                        >
                            {
                                getFieldDecorator("cgst", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter  CGST"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="CGST"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="Total Discount"
                        >
                            {
                                getFieldDecorator("totalDiscount", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Total Discount"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Total Discount"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            className="form-element"
                            label="Consumable Charge"
                        >
                            {
                                getFieldDecorator("consumableCharge", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Consumable Charge"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Consumable Charge"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="SGST"
                        >
                            {
                                getFieldDecorator("sgst", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter SGST"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="SGST"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="Round Off"
                        >
                            {
                                getFieldDecorator("roundoff", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Roundoff"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Round Off"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={7}>
                        <Form.Item
                            className="form-element"
                            label="Part Charge"
                        >
                            {
                                getFieldDecorator("partCharge", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Part Charge"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Part Charge"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>

                        {/* <Form.Item
                            className="form-element"
                            label="IGST"
                        >
                            {
                                getFieldDecorator("igst", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter IGST"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="IGST"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item> */}

                    </Col>
                </Row>
                <Row>
                    <Col span={12}></Col>
                    <Col span={12}>
                        <Form.Item
                            className="form-element"
                            label={<Text strong>Total Estimated Amount</Text>}
                        >
                            {
                                getFieldDecorator("totalAmount", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Estimated Amount"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Estimated Amount"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                    </Col>
                </Row>
                <div style={{ float: "right" }}>
                    <Button
                        style={{ marginRight: "1rem" }}
                        size="large"
                        onClick={() => handleCancel()}
                    >
                        Cancel
                    </Button>
                    {!editable ?
                        <Button
                            type="primary"
                            size="large"
                            disabled={btnFlag}
                            onClick={() => handleSubmit()}
                        >
                            {"Save"}
                        </Button> :
                        ((editData?.estimateStatus === "PENDING") && <Button
                            type="primary"
                            size="large"
                            disabled={btnFlag}
                            onClick={() => onEdit(false)}
                        >
                            {"Modify"}
                        </Button>)
                    }
                </div>
            </Form>
        </div>
    )
}
const WrappedEstimateForm = Form.create()(
    EstimateForm
);

export default WrappedEstimateForm;