import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../ContextAPI";
import {
  Button,
  Input,
  Typography,
  Dropdown,
  Tooltip,
  Row,
  Menu,
  Col,
  Icon,
  message,
  Divider,
} from "antd";
import {
  LeftOutlined
} from '@ant-design/icons';
import "./index.less"
import { platformApi } from "../../../api";
import EmployeeTableSales from "./EmployeeTableSales";
import WrappedTargetForm from "./TargetForm";
const { Search } = Input;
const { Title } = Typography;
const { Item } = Menu;

const SalesTargets = (props) => {
  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dataSource, setdataSource] = useState([]);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const [loggedIn, setLoggedIn] = useState("");
  const [filter, setFilter] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [targetForm, setTargetForm] = useState(false)
  const [editable, setEditable] = useState(false)
  const [editableData, setEditableData] = useState({})
  // const [modify, setModify] = useState(false)

  useEffect(() => {
    //setPrimaryData();
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "sales_targets") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
      
    // platformApi.get("/api/salesTarget/").then((res) => {
    //   setSpinning(false)
    //   const { data } = res;
    //   if (data.code === 200) {
    //     setdataSource(data.response.data);
    //     setLoggedIn(data.response.data.user);
    //     setCount(data.response.data.length);
    //     // message.success(data.response.message)
    //   }
    // })
    //   .catch((err) => {
    //     setSpinning(false)
    //     console.error("Employee Fetch Error: ", err);
    //     message.error("Couldn't Fetch Employees");
    //   });
  }, []);

  useEffect(() => {
    setPrimaryData(search);
  }, [page, limit, search]);

  const setPrimaryData = (string, val) => {
    setSpinning(true);
    platformApi
      .post("/api/salesTarget/get", {
        page: val ? val : page,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: string,
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          setdataSource(data.response.data.salesTarget);
          setLoggedIn(data.response.data.user);
          // message.success(data.response.msg)
          setCount(data.response.data.count);
        }
      })
      .catch((err) => {
        setSpinning(false)
        console.error("User Fetch Error: ", err);
        message.error("Couldn't Fetch Employees");
      });
  };



  const getTarget = () => {
    setSpinning(true)
    platformApi.get("/api/salesTarget/").then((res) => {
      setSpinning(false)
      const { data } = res;
      if (data.code === 200) {
        setdataSource(data.response.data);
        setLoggedIn(data.response.data.user);
        setCount(data.response.data.length);
      }
    })
      .catch((err) => {
        setSpinning(false)
        console.error("Employee Fetch Error: ", err);
        message.error("Couldn't Fetch Employees");
      });
  }
  const addTarget = (data) => {
    if (data.id) {

      platformApi.put("/api/salesTarget/" + data.id, data).then(result => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            message.success("Sales Target Modified Successfully")
            getTarget()
          }
        }
      }).catch((err) => {
        setSpinning(false)
        console.error("Target Add Error: ", err);
        message.error("Couldn't Add Targets");
      });
    }
    else {
      platformApi.post("/api/salesTarget/", data).then(result => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            message.success("Sales Target Added Successfully")
            getTarget()
          }
          else {
            setSpinning(false)
            message.error("Couldn't Add Targets");
          }
        } else {
          setSpinning(false)
          message.error("Couldn't Add Targets");
        }
      }).catch((err) => {
        setSpinning(false)
        console.error("Target Add Error: ", err);
        message.error("Couldn't Add Targets");
      });
    }
  }

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(1)
    // }
    platformApi.delete("/api/salesTarget/" + id).then(result => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          message.success("Sales Target Deleted Successfully")
          if (temp) {
            setPage(1)
            setPrimaryData(null, 1)
            // getTarget()
          }
          else {
            setPrimaryData()
            // getTarget()
          }
        }
        else {
          setSpinning(false)
          message.error("Couldn't Remove Targets");
        }
      }
      else {
        setSpinning(false)
        message.error("Couldn't Remove Targets");
      }
    }).catch((err) => {
      setSpinning(false)
      console.error("Target Add Error: ", err);
      message.error("Couldn't Add Targets");
    });
  }

  const getMenu = () => {
    let size = count / 10 + (count % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };
  const menu = <Menu>{getMenu()}</Menu>;

  return (
    <div>
      <div className="sales-target-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Sales Targets [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>

        <WrappedTargetForm
          visible={targetForm}
          dataSource={dataSource}
          setDataSource={setdataSource}
          setVisible={setTargetForm}
          emitData={addTarget}
          editableData={editableData}
          editable={editable}
          setEditable={setEditable}
          setEditableData={setEditableData}
          getTarget={getTarget}
        />
        <Search
          placeholder="Search User"
          className="searchbar-div-manufacturer"
          style={{ width: "30%" }}
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button
          type="primary"
          className="add-target-button"
          // disabled={!createAccess}
          style={{ margin: "0 3%", width: "20%" }}
          onClick={() => { setEditable(true); setTargetForm(true) }}
          disabled={!createAccess}
        >
          Add Target
        </Button>


      </div>
      {/* <Divider /> */}
      <div>
        <EmployeeTableSales
          dataSource={dataSource}
          spinner={spinning}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: limit,
            defaultCurrent: 1,
            current: page,
            total: count,
            showQuickJumper: true,
          }}
          // modify={modify}
          deleteData={deleteData}
          setVisible={setTargetForm}
          setEditableData={setEditableData}
          setEditable={setEditable}
          deleteAccess={deleteAccess}
          modifyAccess={modifyAccess}
        />

      </div>
    </div>
  );
}
export default withRouter(SalesTargets)