import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, Button, Modal } from "antd";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils"

// import "./index.less";
const { Item } = Form;
const { Option } = Select;
const FrameModal = ({
  form,
  addData,
  modalData,
  create,
  setSelected,
  open,
  setOpen,
  editable,
  setEditable
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [modify, setModify] = useState(false);
  const [manufacturer, setManufacturer] = useState([]);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false)


  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  useEffect(() => {
    platformApi.get("/api/manufacturer/branch").then((res) => {
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          setManufacturer(response.data);
        }
      }
    });
  }, [])

  useEffect(() => {
    if (modalData) {
      data.id = modalData.id;
      data.position = modalData.position
      form.setFieldsValue({
        manufacturer: modalData.manufacturer.id,
        position: modalData.position,
        inputValue: modalData.inputValue,
        targetValue: modalData.targetValue,
        inferredField: modalData.inferredField
      })
    }
  }, [open]);

  useEffect(() => {
    if (modalData) {
      setModify(true);
    }
  }, [modalData]);

  const targetValue =
    data && data.inferredField === 'Month' ?
      [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
      ]
      :
      [
        "2000", "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"
      ]


  const clearFields = () => {
    resetFields();
    setModify(false);
    setSelected(null);
    setData({})
  };
  const handleSubmit = () => {
    setModify(false);
    let isValid;
    validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      setLoading(true)
      addData(data);
      setOpen(false);
      setLoading(false)
      clearFields();
    }
  };
  return (
    <Modal
      title="Frame Number"
      visible={open}
      onCancel={() => {
        setOpen(false);
        clearFields();
      }}
      okButtonProps={{ loading }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      okText={editable ? 'Save' : 'Modify'}
    >
      <div>
        <Form>
          <Row gutter={16}>
            <Col span={12}>
              <Item colon={false} label="Manufacturer">
                {getFieldDecorator("manufacturer", {
                  rules: [{ required: true, message: "Select Manufacturer" }],
                })(
                  <Select
                    placeholder="Select a Manufacturer"
                    filterOption={filterMethod}
                    showAction={["click", "focus"]}
                    showSearch
                    onChange={(id) => setData({ ...data, manufacturer: id })}
                    disabled={!editable}
                  >
                    {manufacturer.map((m) => (
                      <Option key={m.id}>{m.name}</Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item colon={false} label="Position">
                {getFieldDecorator("position", {
                  rules: [{ required: true, message: "Select Position" }],
                })(
                  <Select
                    disabled={!editable}
                    showAction={["click", "focus"]}
                    placeholder="Select a Position"
                    onChange={(id) => { setData({ ...data, position: id }), form.setFieldsValue({ targetValue: undefined }) }}
                  >
                    <Option key={9}>9</Option>
                    <Option key={10}>10</Option>
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Item colon={false} label="Input Value">
                {getFieldDecorator("inputValue", {
                  rules: [{ required: true, message: "Enter Input Value" }],
                })(
                  <Input
                    disabled={!editable}
                    maxLength={1}
                    onChange={(e) => setData({ ...data, inputValue: e.target.value.toUpperCase() })}
                    placeholder="Input Value"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        inputValue: e.target.value.toUpperCase()
                      })}
                  />
                )}
              </Item>
            </Col>
            <Col span={12}>
              <Item colon={false} label="Inferred Field">
                {getFieldDecorator("inferredField", {
                  rules: [{ required: true, message: "Select Inferred Field" }],
                })(
                  <Select
                    disabled={!editable}
                    placeholder="Select Inferred Field"
                    showAction={["click", "focus"]}
                    onChange={(id) => { setData({ ...data, inferredField: id }), form.setFieldsValue({ targetValue: undefined }) }}
                  >
                    <Option key="Month">Month</Option>
                    <Option key="Year">Year</Option>
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>

            <Col span={12}>
              <Item colon={false} label="Target Value">
                {getFieldDecorator("targetValue", {
                  rules: [{ required: true, message: "Select Target Value" }],
                })(
                  <Select
                    placeholder="Select a Target Value"
                    filterOption={filterMethod}
                    showSearch
                    showAction={["click", "focus"]}
                    onChange={(id) => setData({ ...data, targetValue: id })}
                    disabled={!(data && data.inferredField)}
                  >
                    {targetValue.map((m) => (
                      <Option key={m}>{m}</Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>



        </Form>
      </div>
    </Modal>
  );
};
const WrappedModalForm = Form.create()(FrameModal);

export default WrappedModalForm;
