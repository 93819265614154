import React, { useState, useEffect } from "react";
import moment from "moment";
import minimize from "../../../../../assets/minimize.svg";
import upload from "../../../../../assets/upload.svg";
import {
  Input,
  Modal,
  Button,
  Select,
  Form,
  Col,
  Row,
  DatePicker,
  Icon,
  message,
  Upload,
  Table,
  Divider,
  Popconfirm,
} from "antd";
import { platformApi } from "../../../../../api";
import "./index.less";
import { add } from "lodash";
import Title from "antd/lib/skeleton/Title";
const { Option } = Select;
const { Item } = Form;

let urls = process.env.NODE_ENV === "development" ? "http://localhost:4000/" : "/";
console.log(urls);


const InsuranceForm = ({
  form,
  insModal,
  setData,
  setInsModal,
  formData,
  insuranceData,
  setFormData,
  dataSource,
  setDataSource,
  editable,
  modifyData,
  removedData,
  setRemovedData,
  setUploading,
  uploading,
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  const [formDataList, setFormDataList] = useState([]);
  const [insurances, setInsurances] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [pdf, setPdf] = useState(false);
  const [insurancePair, setInsurancePair] = useState({});
  const [PDFdata, setPDFdata] = useState(null);
  const [url, setUrl] = useState();
  const [visiblePDF, setVisiblePDF] = useState(false);
  // const [uploading, setUploading] = useState(false)
  const [newData, setNewData] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [required, setRequired] = useState(false);

  useEffect(() => {
    platformApi.get("/api/insurance").then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setInsurances(response.data);
          for (let item of response.data) {
            insurancePair[item.id] = item.name;
          }
          setInsurancePair(insurancePair);
        }
      }
    });
  }, []);

  const columns = [
    {
      title: "Insurer",
      dataIndex: modifyData && modifyData.insurance ? "insurance" : "insurer",
      key: "insurer",
      // render: (record, data) => <span>{record ? insurancePair[record] : data && data.insurance && data.insurance.name}</span>
      render: (record, data) => (
        <span>
          {record
            ? insurancePair[record]
            : data && data.insurance && data.insurance.name}
        </span>
      ),
    },
    {
      title: "Policy No",
      dataIndex: "policyNumber",
      key: "policyNumber",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Type",
      dataIndex: "insuranceType",
      key: "insuranceType",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Valid From",
      dataIndex: "validFrom",
      key: "validFrom",
      render: (record) => <span>{moment(record).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Valid To",
      dataIndex: "validTo",
      key: "validTo",
      render: (record) => <span>{moment(record).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Actions",
      render: (record) => (
        <span>
          {record && record.file && record.file.url ? (
            // <a className="linkylink"
            //  download="pdf"
            //  href={record.file.url}
            //  target="_blank"
            // >View</a>
            <a
              className="linkylink"
              // onClick={() => { showUrlPDF(record.file.url) }}
              target="_blank"
              href={record.file.url}
            >
              View
            </a>
          ) : (
            <span
              className="linkylink"
              onClick={() => {
                showPDF(record.pdf.file.originFileObj);
              }}
            >
              View
            </span>
          )}

          {editable ? (
            <>
              <Divider type="vertical" />
              <Popconfirm
                title="Are you sure remove this Insurance?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  if (record && record.id) {
                    // removeData(data)
                    // alert("Old Data")
                    event.stopPropagation();
                    // removedData.push(record)
                    setRemovedData([...removedData, record]);
                    removeInsurance(record);
                  } else {
                    event.stopPropagation();
                    removeInsurance(record);
                  }
                }}
              >
                <span
                  className="linkylink"
                  onClick={(event) => event.stopPropagation()}
                >
                  <Icon style={{ marginLeft: "0.5rem" }} component={minimize} />
                </span>
              </Popconfirm>
            </>
          ) : null}
        </span>
      ),
    },
  ];

  // const normFile = (e) => {
  //   if (Array.isArray(e)) {
  //     return e;
  //   }
  //   return e && e.fileList;
  // };

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      let files = [...info.fileList];
      setFileList(files.slice(-1));
      setPdf(true);
      setUploading(true);
    }
    if (info.file.status === "done") {
      setPdf(false);
      setUploading(false);
      // formData.append("profile", info.file);
      formData.append("module", "Vehicle");
      formData.append("master", "Transaction Master");
      if (editable) {
        formData.append("type", "Insurance-Update");
      } else {
        formData.append("type", "Insurance");
      }
      formData.append(dataSource.length - 1, info.file.originFileObj);
      // platformApi
      //   .post("/api/upload/", formData, {
      //     headers: { "Content-Type": "multipart/form-data" },
      //   })
      //   .then((res) => {
      //     let { data } = res;
      //     if (data.code === 200) {
      //       // data.rear = data.data.Location;
      //       setImageUrl(data.response.data.Location);
      //     } else {
      //       message.error("Upload Not Done");
      //     }
      //   })
      //   .catch((error) => {
      //     console.error("Error on Observation Form : ", error);
      //     message("Unable to upload image");
      //   });

      addInsurance();
    }
  };

  useEffect(() => {
    if (modifyData && modifyData.length > 0) {
      setNewData(modifyData);
    }
  }, [modifyData]);

  const handleDelete = (data) => {
    let filteredData = fileList.filter((item) => {
      return item !== data;
    });
    setFileList(filteredData);
    setFieldsValue({ pdf: undefined });
  };
  useEffect(() => {
    if (fileList.length === 0) {
      setFieldsValue({ pdf: undefined });
    }
  }, [fileList]);

  const getBase64 = (pdf, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(pdf);
  };

  const showPDF = (data) => {
    getBase64(data, (url) => {
      // var fileURL = URL.createObjectURL(url);
      // window.open(fileURL)
      // setUrl(url)
      setPDFdata(data);
      let pdfWindow = window.open("");
      pdfWindow.document.write(
        "<title>" +
        data.name +
        '</title><iframe type="application/pdf"  frameBorder="0"  width="100%" height="100%" title="' +
        data.name +
        '" src="' +
        url +
        '"></iframe>'
      );
      // setVisiblePDF(true)
    });
  };

  // const showUrlPDF=(url)=>{
  //     setUrl(url)
  //     setVisiblePDF(true)
  //     setPDFdata([])
  // }

  const clearFields = () => {
    form.resetFields();
    setFileList([]);
    // document.querySelector('.anticon-delete').click()
    // setFieldsValue({pdf:null})
  };

  const removeInsurance = (data) => {
    if (data.id) {
      // alert("olddata")
      let filteredAry = modifyData.filter(function (e) {
        return e !== data;
      });
      setNewData(filteredAry);
    } else {
      formData.delete(dataSource.indexOf(data));
      var filteredAry = dataSource.filter(function (e) {
        return e !== data;
      });
      setDataSource(filteredAry);
      setData(filteredAry);
      setFormDataList(filteredAry);
    }
  };
  const addInsurance = () => {
    validateFields((err) => {
      if (err === null) {
        let valid = true;
        let error;
        // if(editable){
        //     for (let item of modifyData){
        //       let { insurance,policyNumber } = item
        //       if (insurance.id == getFieldValue('insurer')) {
        //         error = 'This Insurance Name is already uploaded'
        //         valid = false
        //       }
        //       else if (policyNumber == getFieldValue('policyNumber')) {
        //         error = 'This Policy number is already uploaded'
        //         valid = false
        //       }

        //     }
        // }
        // for (let item of dataSource) {
        //   let { insurer, policyNumber } = item
        //   if (insurer == getFieldValue('insurer')) {
        //     error = 'This Insurance Name is already added'
        //     setFieldsValue({ "pdf": undefined })
        //     setFieldsValue({insurer:''})
        //     valid = false
        //   }
        //   else if (policyNumber == getFieldValue('policyNumber')) {
        //     error = 'This Policy number is already added'
        //     setFieldsValue({ "pdf": undefined })
        //     setFieldsValue({policyNumber:''})
        //     valid = false
        //   }
        // }
        // for (let item of newData) {
        //   let { insurance, policyNumber } = item
        //   if (insurance.id == getFieldValue('insurer')) {
        //     error = 'This Insurance Name is already added'
        //     setFieldsValue({ "pdf": undefined })
        //     // setFileList([])
        //     setFieldsValue({insurer:''})
        //     valid = false
        //   }
        //   else if (policyNumber == getFieldValue('policyNumber')) {
        //     error = 'This Policy number is already added'
        //     setFieldsValue({ "pdf": undefined })
        //     // setFileList([])
        //     setFieldsValue({policyNumber:''})
        //     valid = false
        //   }
        // }

        if (valid) {
          let from = moment(new Date(getFieldValue("validFrom"))).format(
            "DD-MM-YYYY"
          );
          let to = moment(new Date(getFieldValue("validTo"))).format(
            "DD-MM-YYYY"
          );

          console.log(
            from,
            to,
            getFieldValue("validFrom"),
            getFieldValue("validTo")
          );

          let data = {
            id: "",
            insurer: getFieldValue("insurer"),
            policyNumber: getFieldValue("policyNumber"),
            insuranceType: getFieldValue("insuranceType"),
            validFrom: moment(getFieldValue("validFrom")._d),
            validTo: moment(getFieldValue("validTo")._d),
            pdf: getFieldValue("pdf"),
            url: imageUrl,
          };

          console.log(data);

          //Storing form data inside a list
          setFormDataList([...insuranceData, data]);

          //Pushing data in normal list
          dataSource.push(data);

          setDataSource(dataSource);

          setFileList([]);
          form.resetFields();
        } else {
          message.error(error);
        }
      }
    });
  };

  const onOk = () => {
    if (dataSource.length == 0) {
      message.error("Add a insurance data before submit!!!");
    } else {
      formData.append("module", "Vehicle");
      formData.append("master", "Transaction Master");
      if (editable) {
        formData.append("type", "Insurance-Update");
      } else {
        formData.append("type", "Insurance");
      }
      for (let item of dataSource) {
        formData.append(dataSource.indexOf(item), item.pdf.file.originFileObj);
      }
      setData(formDataList);
      form.resetFields();
      setFileList([]);
      setInsModal(false);
    }
  };

  const clearAll = () => {
    setInsModal(false);
    form.resetFields();
    setFileList([]);
    setData([]);
    for (let i = 0; i < dataSource.length; i++) {
      formData.delete(i);
    }
    setDataSource([]);
  };

  const searchInsurance = (search) => {
    // let data = { module: 'partsMasters', size: 100, column: 'number', fields: ['number', 'name'], searchString: e }
    platformApi
      .post("/api/options/get", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 100,
        searchColumns: search.searchColumns,
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          search.setValue(data.response);
        }
      })
      .catch((err) => {
        console.error("Part Fetch Error: ", err);
        message.error("Couldn't Fetch Parts");
      });
  };

  useEffect(() => {
    if (getFieldValue("insurer") && getFieldValue("insurer").length > 0) {
      setRequired(true);
    } else {
      setRequired(false);
    }
  }, [getFieldValue("insurer")]);

  const checkInsurance = (data) => {
    let error = "";
    for (let item of dataSource) {
      let { insurer, policyNumber } = item;
      if (insurer === getFieldValue("insurer")) {
        error = "This Insurance Name is already added";
        setFieldsValue({ pdf: undefined });
        setFieldsValue({ insurer: undefined });
        // error = ""
        // valid = false
      }
    }
    for (let item of newData) {
      let { insurance, policyNumber } = item;
      if (insurance.id === getFieldValue("insurer")) {
        error = "This Insurance Name is already added";
        setFieldsValue({ pdf: undefined });
        // setFileList([])
        setFieldsValue({ insurer: undefined });
        // valid = false
      }
    }
    if (error.length > 0) {
      message.warning(error);
    }
  };

  return (
    // <Modal
    //   wrapClassName="insurance-form"
    //   title="Add Insurance"
    //   visible={insModal}
    //   onOk={() => onOk()}
    //   okText="Save"
    //   okButtonProps={{ disabled: dataSource.length === 0 ? true : false }}
    //   onCancel={() => { clearAll() }}
    // >
    <>
      <Form>
        <h3>Adding New Insurance Docs:</h3>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <Table
              dataSource={
                modifyData && Object.keys(modifyData).length > 0
                  ? [...newData, ...dataSource]
                  : dataSource
              }
              columns={columns}
            />
          </Col>
        </Row>
        {/* <Divider /> */}
        {editable ? (
          <>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Item label="Insurer">
                  {getFieldDecorator("insurer", {
                    rules: [{ required: required, message: "Select Insurer" }],
                  })(
                    <Select
                      placeholder="Insurer"
                      showSearch
                      filterOption={null}
                      onSelect={(data) => {
                        checkInsurance(data);
                      }}
                      onSearch={(e) => {
                        const search = {
                          module: "insurances",
                          column: "name",
                          fields: ["name"],
                          searchString: e,
                          searchColumns: ["name"],
                          setValue: setInsurances,
                        };
                        searchInsurance(search);
                      }}
                      allowClear
                    >
                      {insurances.map((item) => (
                        <Option value={item.id} key={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={7}>
                <Item label="Policy No">
                  {getFieldDecorator("policyNumber", {
                    rules: [
                      { required: required, message: "Enter Policy Number" },
                    ],
                  })(<Input placeholder="Policy No" />)}
                </Item>
              </Col>
              <Col span={7}>
                <Item label="Insurance Type">
                  {getFieldDecorator("insuranceType", {
                    rules: [
                      { required: required, message: "Select Insurance Type" },
                    ],
                  })(
                    <Select allowClear placeholder="Insurance Type">
                      <Option value="1+5">1+5</Option>
                      <Option value="5+5">5+5</Option>
                      <Option value="1+5 Zero Dep">1+5 Zero Dep</Option>
                      <Option value="5+5 Zero Dep">5+5 Zero Dep</Option>
                      <Option value="1+1">1+1</Option>
                      <Option value="1+1 Zero Dep">1+1 Zero Dep</Option>
                      <Option value="1 Yr OD">1 Yr OD</Option>
                      <Option value="1 Yr OD Zero Dep">1 Yr OD Zero Dep</Option>
                      <Option value="3rd Party">3rd Party</Option>
                    </Select>
                  )}
                </Item>
              </Col>
            </Row>

            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Item label="Valid From">
                  {getFieldDecorator("validFrom", {
                    rules: [
                      { required: required, message: "Select Valid From Date" },
                    ],
                  })(
                    <DatePicker
                      format="DD-MM-YYYY"
                      disabledDate={(moment) =>
                        // moment._d < new Date() ||
                        getFieldValue("validTo")
                          ? moment._d >= getFieldValue("validTo")
                          : false
                      }
                      placeholder="Valid From"
                    />
                  )}
                </Item>
              </Col>
              <Col span={7}>
                <Item label="Valid To">
                  {getFieldDecorator("validTo", {
                    rules: [
                      { required: required, message: "Select Valid To Date" },
                    ],
                  })(
                    <DatePicker
                      format="DD-MM-YYYY"
                      disabledDate={(moment) =>
                        // moment._d < new Date() ||
                        getFieldValue("validFrom")
                          ? moment._d <= getFieldValue("validFrom")._d
                          : false
                      }
                      placeholder="Valid To"
                    />
                  )}
                </Item>
              </Col>
              <Col span={7}>
                <Item label="Documents">
                  {/* rules: [{ required: pdf && getFieldValue('pdf')!=undefined ? false:true, message: "Choose PDF file" }], */}
                  {getFieldDecorator("pdf", {
                    rules: [{ required: required, message: "Choose PDF file" }],
                  })(
                    <Upload
                      action={`${urls}api/upload/mocky`}
                      onChange={handleUpload}
                      onRemove={(data) => handleDelete(data)}
                      fileList={fileList}
                      accept=".pdf"
                    >
                      <Button
                        disabled={
                          getFieldValue("insurer") &&
                            getFieldValue("policyNumber") &&
                            getFieldValue("insuranceType") &&
                            getFieldValue("validFrom") &&
                            getFieldValue("validTo")
                            ? uploading
                              ? true
                              : false
                            : true
                        }
                        type="primary"
                        ghost
                      >
                        <Icon component={upload} /> Upload & Save
                      </Button>
                    </Upload>
                  )}
                </Item>
              </Col>
            </Row>
          </>
        ) : null}

        {/* <Divider /> */}
        {/* <Row>
          <Col style={{ float: 'right', marginTop: "15px" }}>
            <Button style={{ marginRight: '1rem' }} onClick={() => { clearFields() }}>Clear</Button>
            <Button type="primary" disabled={uploading} onClick={() => { addInsurance() }}>Add</Button>
          </Col>
        </Row> */}
      </Form>
      {/* Showing PDF File... */}
      {PDFdata != null ? (
        <Modal
          wrapClassName="pdf-form"
          title={PDFdata.name}
          visible={visiblePDF}
          onCancel={() => {
            setVisiblePDF(false);
            setUrl(null);
          }}
          footer={null}
        >
          <Row>
            <Col>
              {/* <a href={url}>click</a> */}
              <iframe
                type="application/pdf"
                target="_blank"
                frameBorder="0"
                style={{
                  border: "none",
                  width: "100%",
                  height: "25rem",
                  padding: "10%",
                }}
                title={PDFdata.name}
                src={url}
              ></iframe>
            </Col>
          </Row>
        </Modal>
      ) : (
        <span></span>
      )}
      {/* </Modal> */}
    </>
  );
};

const WrappedInsuranceForm = Form.create()(InsuranceForm);

export default WrappedInsuranceForm;
