import React, { useState, useEffect, useContext } from "react";
import {
  Tabs,
  Typography,
  Input,
  Button,
  Menu,
  Dropdown,
  Icon,
  Collapse,
  message,
} from "antd";
import TaskTable from "./TaskTable";
import GroupTable from "./GroupTable";
import WrappedGroupForm from "./GroupForm";
import VehicleSaleModal from "./TaskForm";
import { platformApi } from "../../../api";
import "./index.less";
import { ContextAPI } from "../../../ContextAPI";
import WhatsappPromo from "./WhatsappPromotions/index";
const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Text, Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const Promotions = (props) => {
  const [Tasks, setData] = useState([]);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [Groups, setGroups] = useState([]);
  const [taskCount, setTaskCount] = useState(0);
  const [groupCount, setGroupCount] = useState(0);
  const [TaskTables, setTaskTables] = useState(true);
  const [displayGroup, setDisplayGroup] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [filter, setFilter] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [flag, setFlag] = useState(false);
  const [id, setId] = useState();
  const [modify, setModify] = useState(-1);
  const { type } = props
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);

  useEffect(() => {
    setFlag(false);
    if (type === "tasks") {
      setTaskTables(true);
      setDisplayGroup(false);
    }
    else {
      setTaskTables(false);
      setDisplayGroup(false);
    }
  }, type)
  // 
  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "promotion") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    // setPageData();
    // if (props.customerId) {
    //   setAddFlag(true);
    //   setSelected({ ...props.cusData });
    // }
  }, []);

  const getMenu = () => {
    let count = TaskTables ? taskCount : groupCount;
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };
  const deleteGroup = (id) => {
    setSpinning(true);
    let temp = page != 1 && Tasks.length === 1;
    // if (page != 1 && Tasks.length === 1) {
    //   setPage(1)
    // }
    platformApi
      .delete(`/api/promotions/group/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Customer deleted successfully", 2);
            setGroups(Groups.filter((obj) => id !== obj.id));
          } else {
            message.error("Unable to delete customer", 2);
          }
        } else {
          message.error("Unable to delete customer", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to delete customer", 2);
        console.error("Error on customer : ", error);
      });
  };

  useEffect(() => {
    // platformApi.post("/api/idGenerate/promotions").then((res) => {
    //   let { data } = res;
    //   if (data.code === 200) {
    //     let { response } = data;
    //     setId(response.data);
    //   } else {
    //     message.error("Couldn't fetch id");
    //   }
    // });

    setSpinning(true);
    setLoading(true);
    platformApi
      .get("/api/promotions")
      .then((res) => {
        setSpinning(false);
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(response.data);
            setTaskCount(response.data.length);
          } else {
            message.error("Failed to fetch Tasks");
          }
        } else {
          message.error("Failed to fetch Tasks");
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Tasks : ", error);
        message.error("Failed to fetch Tasks");
      });
    platformApi
      .get("/api/promotions/group/")
      .then((res) => {
        setLoading(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            console.log(response.data);
            setGroups(response.data);
            setGroupCount(response.data.length);
          } else {
            message.error("Unable to fetch Groups", 2);
          }
        } else {
          message.error("Unable to fetch Groups", 2);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error on MaterilIssue : ", err);
        message.error("Unable to fetch Groups", 2);
      });
  }, []);


  const refreshData = (id) => {
    // setSpinning(true);
    setLoading(true)
    platformApi.get(`/api/promotions/refresh/${id}`)
      .then((res) => {
        const { data } = res
        if (data.code === 200) {
          const { response } = data
          if (response.code === 200) {
            message.success("Group refreshed successfully")
          }
          else {
            message.error("Error occurred in refreshing")
          }
        }
        else {
          message.error("Error occurred in refreshing")
        }
      })
      .catch(err => {
        message.error("Error occurred in refershing")
      })
    platformApi
      .get("/api/promotions/group/")
      .then((res) => {
        setLoading(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setGroups(response.data);
            setGroupCount(response.data.length);
          } else {
            message.error("Unable to fetch Groups", 2);
          }
        } else {
          message.error("Unable to fetch Groups", 2);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error on MaterilIssue : ", err);
        message.error("Unable to fetch Groups", 2);
      });
  }


  const deleteData = (id) => {
    setSpinning(true);
    platformApi
      .delete("/api/promotions/" + id)
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(Tasks.filter((obj) => id !== obj.id));
            message.success("Deleted Sucessfully", 2);
          } else {
            message.error("Unable to delete Task", 2);
          }
        } else {
          message.error("Unable to delete Task", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Task : ", error);
        message.error("Unable to delete Task", 2);
      });
  };

  const addData = (purchaseReturn) => {
    setSpinning(true);
    if (purchaseReturn.id) {
      platformApi
        .put(`/api/promotions/${purchaseReturn.id}`, purchaseReturn)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              Tasks.some((obj, index) => {
                if (obj.id === purchaseReturn.id) Tasks[index] = response.data;
              });
              setData([]);
              setData(Tasks);
              setFlag(false);
              message.success("Task saved successfully", 2);
            } else {
              message.error("Unable to save Task", 2);
            }
          } else {
            message.error("Unable to save Task", 2);
          }
        })
        .catch((err) => {
          setSpinning(false);
          message.error("Unable to save Task", 2);
          console.error("Error on Task : ", err);
        });
    } else
      platformApi
        .post("/api/promotions/", purchaseReturn)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = res.data;
            if (response.code === 200) {
              setData([...Tasks, response.data]);
              // setData(response.data);
              setTaskCount(taskCount + 1);
              setFlag(false);

              message.success("Task added successfully");
            } else {
              message.error("Error in Task creation");
            }
          } else {
            message.error("Error in Task creation");
          }
        })
        .catch((err) => {
          console.error("Error on Task : ", err);
        });
  };
  const addGroup = (group) => {
    if (group.id) {
      setSpinning(true);
      platformApi
        .put(`/api/promotions/group/${group.id}`, group)
        .then((res) => {
          // setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              // Groups.some((obj, index) => {
              //   if (obj.id === group.id) Groups[index] = response.body;
              // });
              // setGroups([]);
              // setGroups(Groups);
              platformApi
                .get("/api/promotions/group/")
                .then((res) => {
                  setSpinning(false);
                  const { data } = res;
                  if (data.code === 200) {
                    const { response } = data;
                    if (response.code === 200) {
                      setDisabled(false);

                      setGroups(response.data);
                      setGroupCount(response.data.length);
                    } else {
                      message.error("Unable to fetch Groups", 2);
                    }
                  } else {
                    message.error("Unable to fetch Groups", 2);
                  }
                })
                .catch((err) => {
                  setSpinning(false);
                  console.error("Error on Group : ", err);
                  message.error("Unable to fetch Groups", 2);
                });
              message.success("Group Modified Succesfully", 2);
              setFlag(false);
            } else {
              message.error("Unable to modify ");
            }
          } else {
            message.error("Unable to modify ");
          }
        });
    } else {
      setSpinning(true);
      platformApi.post(`/api/promotions/group`, group).then((res) => {
        // setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            platformApi
              .get("/api/promotions/group/")
              .then((res) => {
                setSpinning(false);
                const { data } = res;
                if (data.code === 200) {
                  const { response } = data;
                  if (response.code === 200) {
                    setGroups(response.data);
                    setGroupCount(response.data.length);
                  } else {
                    message.error("Unable to fetch Groups", 2);
                  }
                } else {
                  message.error("Unable to fetch Groups", 2);
                }
              })
              .catch((err) => {
                setSpinning(false);
                console.error("Error on MaterilIssue : ", err);
                message.error("Unable to fetch Groups", 2);
              });
            message.success("Group Added Succesfully", 2);
            setDisabled(false);
            setFlag(false);
          } else {
            message.error("Unable to fetch ");
          }
        } else {
          message.error("Unable to fetch ");
        }
      });
    }
  };

  const addTasks = () => {
    setModalVisible(true);
    setEditFlag(true);
  };

  const menu = <Menu>{getMenu()}</Menu>;

  return (
    // <div style={{ backgroundColor: "white" }}>
    <div>


      {type !== "promowhatsapp" && <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          {TaskTables ? "Task Table" : "Group Table"}
          [{TaskTables ? taskCount : groupCount}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        {!flag && <Search
          style={{ width: "30%" }}
          placeholder={TaskTables ? "Search Task" : "Search Group"}
        />}
        <Button
          onClick={() => {
            setFlag(true);
            TaskTables ? setEditFlag(true) : setDisabled(true);
            TaskTables ? setModalVisible(true) : setDisplayGroup(true);
            // TaskTables ? addTasks : setDisplayGroup(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          {TaskTables ? "Schedule Task" : "Create Group"}
        </Button>
      </div>
      }



      {type === "tasks" ? <div>{!modalVisible && (
        <TaskTable
          pagination={{
            defaultCurrent: 1,
            total: taskCount,
            showQuickJumper: true,
          }}
          dataSource={Tasks}
          loading={spinning}
          delete={(id) => deleteData(id)}
          openModal={(id, edit) => {
            setSelected(id);
            setEditFlag(edit);
            setModalVisible(true);
          }}
          deleteAccess={deleteAccess}
          modify={modifyAccess}
        />
      )}
        <VehicleSaleModal
          data={Tasks[Tasks.findIndex((obj) => selected === obj.id)]}
          // id={id}
          setSelected={setSelected}
          setFlag={setFlag}
          emitData={(data) => addData(data)}
          editable={editFlag}
          open={modalVisible}
          close={() => setModalVisible(false)}
        /> </div>
        :

        type === "promowhatsapp"
          ? <WhatsappPromo /> :
          <div>{displayGroup ? (
            <WrappedGroupForm
              filter={filter}
              setFilter={setFilter}
              setDisplayGroup={setDisplayGroup}
              displayGroup={displayGroup}
              setDisabled={setDisabled}
              setFlag={setFlag}
              data={Groups[Groups.findIndex((obj) => modify === obj.id)]}
              addData={addGroup}
              setModify={setModify}
              editable={disabled}
            />
          ) : (
            <GroupTable
              pagination={{
                defaultCurrent: 1,
                total: groupCount,
                showQuickJumper: true,
              }}
              loading={loading || spinning}
              dataSource={Groups}
              refreshData={refreshData}
              deleteData={deleteGroup}
              openModal={(id, edit) => {
                setModify(id);
                setDisplayGroup(true);
                setDisabled(edit);
              }}
              deleteAccess={deleteAccess}
              modify={modifyAccess}
            />
          )}
          </div>
      }






    </div>
  );
};

export default Promotions;
