import React, { useState, useEffect, useContext } from "react";
import {
  Modal,
  Row,
  Typography,
  Col,
  Input,
  Form,
  DatePicker,
  TimePicker,
  Select,
  message,
} from "antd";
import moment from "moment";

import { platformApi } from "../../../../../../api";
import { withRouter, Link } from "react-router-dom";
import { ContextAPI } from "../../../../../../ContextAPI";

// import "./index.less";

const { Item } = Form;
const { Title, Text } = Typography;
const { Option } = Select;

const RescheduleForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [editable, setEditable] = useState(props.editable);
  const { loginCredintials } = useContext(ContextAPI);

  const { form, open, close, data, setData } = props;
  const { getFieldDecorator, getFieldValue } = form;

  useEffect(() => {
    platformApi.get("/api/promotions/campaign").then((res) => {
      setCampaigns(res.data.response.data);
    });
  }, []);

  const handleSubmit = () => {
    // setLoading(true);
    // form.validateFields(async (validationError) => {
    //   if (
    //     validationError === null &&
    //     JSON.stringify({}) === JSON.stringify(error)
    //   ) {
    //     if (dataSource.length !== 0) {
    const date = form.getFieldValue("date");
    const time = form.getFieldValue("time");
    const name = form.getFieldValue("reschdeuleName");
    const campaign = form.getFieldValue("campaign");
    const reData = {
      date,
      time,
      name,
      campaign,
    };
    message.success("Rescheduled Promotion");
    props.emitData(reData);
    form.resetFields();
    props.close();
    //     } else {
    //       message.error("Enter Phone Number");
    //     }
    //   }
    // });
  };

  const clearFields = () => {
    form.resetFields();
  };

  const dateChange = (date, dateString) => {
  };

  const timeChange = (time, timeString) => {
  };

  return (
    <Modal
      title={<Title level={4}>Reschedule</Title>}
      visible={open}
      onCancel={() => {
        clearFields();
        close();
      }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      //   okButtonProps={
      //     !editable ? { loading, style: { display: "none" } } : { loading }
      //   }
      //   cancelText={editable ? "Cancel" : "Close"}
      cancelText="Cancel"
      //   okText={cType ? "Save" : "Convert"}
      okText="Reschedule"
    // wrapClassName="customer-modal"
    >
      <Form>
        <Row type="flex" justify="space-around">
          <Col span={11}>
            <Item label="Date" colon={false}>
              {getFieldDecorator("date", {
                rules: [{ required: true, message: "Select Date" }],
              })(<DatePicker format="DD/MM/YYYY" onChange={dateChange} />)}
            </Item>
          </Col>
          <Col span={11}>
            <Item label="Time" colon={false}>
              {getFieldDecorator("time", {
                rules: [{ required: true, message: "Select Time" }],
              })(
                <TimePicker
                  onChange={timeChange}
                  defaultOpenValue={moment("00:00", "HH:mm")}
                  format="HH:mm"
                />
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-around">
          <Col span={11}>
            <Item label="Reschedule Promotion Name" colon={false}>
              {getFieldDecorator("reschdeuleName", {
                rules: [
                  {
                    required: true,
                    message: "Enter Reschedule Promotion Name",
                  },
                ],
              })(<Input placeholder="Reschedule Promotion Name" />)}
            </Item>
          </Col>
          <Col span={11}>
            <Item label="Campaign" colon={false}>
              {getFieldDecorator("campaign", {
                rules: [{ required: true, message: "Enter Campaign" }],
              })(
                <Select showSearch placeholder="Campaign">
                  {campaigns.map((grp) => (
                    <Option key={grp.id}>{grp.name}</Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>
      </Form>
      <br />
    </Modal>
  );
};
const WrappedReschduleForm = Form.create({ name: "reschedule_form" })(
  RescheduleForm
);
export default withRouter(WrappedReschduleForm);
