import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Icon,
  Button,
  List,
  Typography,
  Row,
  Col,
  Empty,
  Popconfirm,
  message
} from "antd";

import { Carousel } from "react-responsive-carousel";

import WrappedImageUpload from "./ImagesModal";

import "./carousel.less";

import { platformApi } from "../../../../../api";

import imagePlaceHolder from "../../../../../assets/vehicle-placeholder.jpg";

const { Title, Text } = Typography;

const ImagesViewModal = ({
  editable,
  open = false,
  close,
  data,
  vehicleDetail,
  setImageUploadData,
  deleteImage,
  addImage,
  editImage
}) => {
  const [addFlags, setAddFlags] = useState(false);
  const [current, setCurrent] = useState(1);
  const [dataSource, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [editFlag, setEditFlag] = useState(false)

  const handleSubmit = () => {
    clearFields();

    // addImage(dataSource)
    close();
  };

  useEffect(() => {
    if (open && data) {
      console.log("getData", data)
      setData([]);
      setData(data);
    }
  }, [open, data]);

  const clearFields = () => {
    setData([]);
  };

  // const editData = (data, id) => {
  //   dataSource.some((obj, index) => {
  //     if (obj.id === id) dataSource[index] = data;
  //   });
  //   setData([]);
  //   setData(dataSource);
  // };

  // const addData = (data) => {
  //   setData([...dataSource, data]);
  // };

  const removeFromSpaces = (url, callBack) => {
    platformApi
      .post("/api/upload/deleteFile", {
        url,
      })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          callBack();
        }
      })
      .catch((error) => {
        console.error("Error on Files View Modal : ", error);
        message.error("Unable to delete this File", 2);
      });
  };

  // const deleteData = (image) => {
  //   const pieces = image.url.split("/");
  //   if (image.url) {
  //     let url;
  //     if (pieces[3] != "nandiyamaha")
  //       url = pieces.reduce((prev, current, index) => {
  //         if (index > 2) return prev + "/" + current;
  //         return "";
  //       });
  //     else
  //       url = pieces.reduce((prev, current, index) => {
  //         if (index > 3) return prev + "/" + current;
  //         return "";
  //       });
  //     removeFromSpaces(url.slice(1), () => removeFromDB(image));
  //   } else {
  //     removeFromDB(image);
  //   }
  // };

  // const removeFromDB = (image) => {
  //   if (image.id != "") {
  //     platformApi
  //       .delete(`/api/vehicleMaster/image/${image.id}`)
  //       .then((result) => {
  //         const { data } = result;
  //         if (data.code === 200) {
  //           setData(dataSource.filter((data) => data.id !== image.id));
  //           message.success("Image deleted successfully", 2);
  //         }
  //       });
  //   } else {
  //     setData(dataSource.filter((data) => data.url !== image.url));
  //     message.success("Image deleted successfully", 2);
  //   }
  // };


  return (
    <Modal
      title={<Text strong>Images</Text>}
      visible={open}
      onCancel={() => {
        close();
        clearFields(); 
      }}
      wrapClassName="parts-modal"
      footer={[<Button onClick={handleSubmit} key="images-modal-button">Close</Button>]}
    >
      <Row type="flex" justify="space-around" align="middle" >
        <Col span={14}>
          {dataSource.length ? (
            dataSource.length === 1 ?
              <Row
                type="flex"
                align="middle"
                style={{ height: "250px", justifyContent: 'center', backgroundColor: "#fff" }}
                key={`datasource0`}
              >
                <img
                  src={dataSource[0].url || imagePlaceHolder}
                  onError={(event) => (event.target.src = imagePlaceHolder)}
                  style={{ width: "250px", height: '180px' }}
                />
              </Row> :
              <Carousel
                showArrows={false}
                showIndicators={false}
                showThumbs={false}
                infiniteLoop
                transitionTime={750}
                autoPlay={false}
                showStatus={false}
                selectedItem={current - 1}
              >
                {dataSource.map((item, index) => (
                  <Row
                    type="flex"
                    align="middle"
                    style={{ height: "250px", justifyContent: 'center', backgroundColor: "#fff" }}
                    key={`datasource${index}`}
                  >
                    <img
                      src={item.url || imagePlaceHolder}
                      onError={(event) => (event.target.src = imagePlaceHolder)}
                      style={{ width: "250px", height: '180px' }}
                    />
                  </Row>
                ))}
              </Carousel>
          ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )}
        </Col>

        <Col span={8}>
          <List
            size="small"
            pagination={{
              pageSize: 1,
              onChange: (page) => setCurrent(page),
            }}
            rowKey={(record) => record.id}
            header={
              <Row type="flex" align="middle" justify="space-between">
                <Col span={12}>
                  <Title level={4}>Vehicles</Title>
                </Col>
                <Col span={4}>
                  {editable && (
                    <Button
                      size="small"
                      shape="round"
                      icon="plus"
                      onClick={() => setAddFlags(true)}
                    >
                      Add
                    </Button>
                  )}
                </Col>
              </Row>
            }
            itemLayout="vertical"
            dataSource={dataSource}
            renderItem={(item, index) => (
              <List.Item
                actions={[
                  editable && (
                    <Popconfirm
                      title="Do you want to delete this Image?"
                      onConfirm={() => deleteImage(item, current)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Icon type="delete" />
                    </Popconfirm>
                  ),
                  editable && (
                    <Icon
                      type="edit"
                      onClick={() => {
                        console.log(item.id)
                        setSelected(item.id);
                        setEditFlag(true)
                        setAddFlags(true);
                      }}
                    />
                  ),
                ]}
              >
                <List.Item.Meta
                  title={
                    <span>
                      <Text strong>Name: </Text>
                      <Text>{item.color}</Text>
                    </span>
                  }
                  description={
                    <span>
                      <Text strong>Code: </Text>
                      <Text> {item.code || "NA"}</Text>
                    </span>
                  }
                />
              </List.Item>
            )}
          />
        </Col>
      </Row>
      <WrappedImageUpload
        open={addFlags}
        data={dataSource[dataSource.findIndex((data) => data.id === selected)]}
        close={() => {
          setAddFlags(false);
          setSelected(null);
        }}
        emitData={(data, oldData) =>
          oldData ? editImage(data, oldData) : addImage(data)
        }
        vehicleDetail={vehicleDetail}
        setImageUploadData={setImageUploadData}
        editFlag={editFlag}
      />
    </Modal>
  );
};

export default ImagesViewModal;
