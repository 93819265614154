import React, { useEffect, useState, useContext } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  Typography,
  Button,
  Upload,
  Card,
  message,
  Icon,
} from "antd";
import { platformApi } from "../../../../api";
import { ContextAPI } from "../../../../ContextAPI";
import { withRouter } from "react-router-dom";
import Department from "../../../../assets/Department.svg";
import Employee from "../../../../assets/Employee.svg";
import Financier from "../../../../assets/Financier.svg";
import SalesTarget from "../../../../assets/sales-target.svg";
import Insurance from "../../../../assets/insurance.svg";
import Maintenance from "../../../../assets/maintenance.svg";
import Manufacturers from "../../../../assets/Manufacturers.svg";
import HSN from "../../../../assets/HSN.svg";
import SAC from "../../../../assets/SAC.svg";
import VehicleMaster from "../../../../assets/VehicleMaster.svg";
import VehiclePrice from "../../../../assets/VehiclePrice.svg";
import Accessories from "../../../../assets/Accessories.svg";
import Spareparts from "../../../../assets/Spareparts.svg";
import Jobcode from "../../../../assets/Jobcode.svg";
import SMS from "../../../../assets/SMS.svg";
import IDgenearator from "../../../../assets/IDgenearator.svg";
import RTO from "../../../../assets/rto.svg";
import Framenumber from "../../../../assets/Framenumber.svg";
import SupplierMaster from "../../../../assets/suppliermaster.svg";
import Whatsapp from "../../../../assets/whatsapp.svg";
import Uploads from "../../../../assets/upload.svg"

function CompanyMaster(props) {
  const { loginCredintials } = useContext(ContextAPI);

  const pageRoute = (param) => {
    props.history.push(`/autoadmin/${param}`);
  };
  function checkDropdownList(type) {
    let bool = false;
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        console.log(each.subModule);
        if (each.subModule === type && each.access.read) {
          bool = true;
        }
      });
    return bool;
  }

  return (
    <div style={{ background: "#f4f7fb", padding: "30px" }}>
      <Row gutter={26}>
        {checkDropdownList("department") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("department");
              }}
              // title="Department"
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Department
                  style={{ height: "70px", marginTop: "30px" }}
                ></Department>
              }
            >
              Department
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("employee") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("employee");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={<Employee style={{ height: "70px", marginTop: "30px" }} />}
            >
              Employee
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("financier") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("financier");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Financier style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Financier
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("manufacturer") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("manufacturer");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Manufacturers style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Manufacturer
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("hsn") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("hsn");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={<HSN style={{ height: "70px", marginTop: "30px" }} />}
            >
              HSN
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("sac") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("sac");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={<SAC style={{ height: "70px", marginTop: "30px" }} />}
            >
              SAC
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("vehicle_master") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("vehicle_master");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <VehicleMaster style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Vehicle Master
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("vehicle_price") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("vehicle_price");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <VehiclePrice style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Vehicle Price
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {/* {
          checkDropdownList('accessories') ?
            <Col span={6}>
              <Card
                style={{ cursor: 'pointer', marginTop: '20px' }}
                onClick={() => { pageRoute('accessories') }}
                bordered={true}
                bodyStyle={{ textAlign: 'center', fontSize: '16px', color: 'rgb(108, 123, 138)' }}
                cover={
                  <Accessories style={{ height: '70px', marginTop: '30px' }} />
                }
              >
                Accessories
              </Card>
            </Col>
            : <span></span>
        } */}
        {checkDropdownList("parts_master") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("parts_master");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Spareparts style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Parts Master
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("job_code") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("job_code");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={<Jobcode style={{ height: "70px", marginTop: "30px" }} />}
            >
              Job Code
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("sms") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("sms");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={<SMS style={{ height: "70px", marginTop: "30px" }} />}
            >
              SMS
            </Card>
          </Col>
        ) : (
          <span></span>
        )}

        {/* whatsup */}
        {checkDropdownList("sms") && (
          <Col span={6}>
            <Card
              style={{ marginTop: "20px", cursor: "pointer" }}
              onClick={() => pageRoute("whatsup")}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Whatsapp
                  style={{
                    height: "70px",
                    marginTop: "30px",
                    color: "rgba(108, 123, 138, 0.863)",
                  }}
                />
              }
            >
              Whatsapp
            </Card>
          </Col>
        )}

        {checkDropdownList("supplier_master") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("supplier_master");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <SupplierMaster style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Supplier Master
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("idgenerator") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("idgenerator");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <IDgenearator style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              ID Generator
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("rtomaster") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("rtomaster");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={<RTO style={{ height: "70px", marginTop: "30px" }} />}
            >
              RTO Master
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("frame_number") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("frame_number");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Framenumber
                  style={{ height: "70px", marginTop: "30px", fill: "#006a71" }}
                />
              }
            >
              Frame Number
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("insurance") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("insurance");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Insurance
                  style={{ height: "70px", marginTop: "30px", fill: "#006a71" }}
                />
              }
            >
              Insurance Master
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("sales_targets") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("sales_targets");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <SalesTarget
                  style={{ height: "70px", marginTop: "30px", fill: "#006a71" }}
                />
              }
            >
              Sales Targets
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {checkDropdownList("pmc") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("pmc");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Maintenance style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              PMC
            </Card>
          </Col>
        ) : (
          <span></span>
        )}
        {/* whatsapp  */}
        {checkDropdownList("Whatsapp") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("Whatsapp");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Maintenance style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Whatsapp
            </Card>
          </Col>
        ) : (
          <span></span>
        )}

        {/* upload */}

{/* todo upload access have to check */}
        {checkDropdownList("pmc") ? (
          <Col span={6}>
            <Card
              style={{ cursor: "pointer", marginTop: "20px" }}
              onClick={() => {
                pageRoute("upload");
              }}
              bordered={true}
              bodyStyle={{
                textAlign: "center",
                fontSize: "16px",
                color: "rgb(108, 123, 138)",
              }}
              cover={
                <Uploads style={{ height: "70px", marginTop: "30px" }} />
              }
            >
              Upload
            </Card>
          </Col>
        ) : (
          <span></span>
        )}

      </Row>
    </div>
  );
}

export default withRouter(CompanyMaster);
