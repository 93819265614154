import React, { useState, useEffect, useContext } from "react";
import {
  Input,
  Menu,
  Button,
  Icon,
  Dropdown,
  Typography,
  message,
  Tooltip,
} from "antd";
import { withRouter } from "react-router-dom";
import EmployeeTable from "./EmployeeTable";
import WrappedForm from "../../../Employee/EmployeeForm";
import { platformApi } from "../../../../../api";
import { ContextAPI } from "../../../../../ContextAPI";
import { LeftOutlined } from "@ant-design/icons";
const { Title } = Typography;
const { Item } = Menu;
const { Search } = Input;

const SpecificDepartment = (props) => {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [user, setUser] = useState(null);
  const [selected, setSelected] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [empId, setEmpId] = useState("");
  const [dataSource, setData] = useState([]);
  const { loginCredintials } = useContext(ContextAPI);

  const getMenu = () => {
    let size = dataSource.length / 10 + (dataSource.length % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };

  const setEmployeeId = () => {
    platformApi.post("/api/idGenerate/employee").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        setEmpId(response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const deleteData = (id) => {
    setSpinning(true);
    platformApi
      .delete(`/api/user/${id}`)
      .then((res) => {
        setSpinning(false);
        if (res.data.code === 200) {
          setData(dataSource.filter((obj) => id !== obj.id));
          message.success("Employee Deleted Successfully");
        } else {
          message.error("Employee couldnt be deleted");
        }
      })
      .catch((err) => {
        setSpinning(false);
        message.error("Employee couldnt be deleted");
        console.error("Delete Employee Error :", err);
      });
  };

  const setPrimaryData = (string) => {
    setSpinning(true);
    platformApi
      .post(`/api/department/getUser`, { id: props.id, searchString: string })
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.getDepartment);
            setUser(response.data.user);
          } else {
            message.error("Unable to fetch Employee");
          }
        } else {
          message.error("Unable to fetch Employee");
        }
      })
      .catch((error) => {
        message.error("Unable to fetch Employee");
        console.error("Error on Employee ", error);
        setSpinning(false);
      });
  };

  useEffect(() => {
    setPrimaryData();
  }, []);

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Department"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/department")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          {props.deptType} [{dataSource.length}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%", marginLeft: "26%" }}
          placeholder="Search Users"
          onSearch={(event) => {
            setPrimaryData(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setPrimaryData();
            }
          }}
        />
        <Button
          onClick={() => {
            setAddFlag(true), setEmployeeId();
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
        >
          Add Employee
        </Button>
      </div>
      <div style={{ margin: ".5rem" }}></div>
      <EmployeeTable
        user={user}
        delete={deleteData}
        pagination={{
          pageSize: limit,
          defaultCurrent: 1,
          total: dataSource.length,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setAddFlag(true);
          setSelected(id);
        }}
        dataSource={dataSource}
        spinner={{ spinning }}
      />
      <WrappedForm
        dept={props.id}
        visible={addFlag}
        modalData={
          dataSource[dataSource.findIndex((obj) => selected === obj.id)]
        }
        setModalData={(val) => {
          setSelected(val);
        }}
        empId={empId}
        setEmpId={setEmpId}
        setVisible={(addFlag) => {
          setAddFlag(addFlag);
          if (!addFlag) setSelected(null);
        }}
        setdataSource={setData}
        dataSource={dataSource}
        loginCredintials={loginCredintials}
      />
    </div>
  );
};

export default withRouter(SpecificDepartment);
