import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Dropdown, Menu, Icon, message } from "antd";
import { platformApi } from "../../../../../api";

import { ContextAPI } from "../../../../../ContextAPI";
import SalesTable from "./SalesTable";
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

export default function SalesHome() {
  const [dataSource, setDataSource] = useState([]);
  const [addFlag, setAddFlag] = useState(false);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    platformApi.get('/api/sales/lead')
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setDataSource(response.data)
          }
          else {
            message.error("Couldn't fetch leads");
          }
        }
      })
      .catch(err => {
        message.error('Lead fetch error', err);
      })
  }, [])

  const getMenu = () => {
    const size = dataSource.length / 10 + (dataSource.length % 10) ? 1 : 0;
    let menus = [];
    menus.push(10);
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map(menu => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };
  const menu = <Menu>{getMenu()}</Menu>;
  return (
    <div>
      <div className="page-head">
        <Title style={{ width: "60%" }} level={4}>
          Sale Lead
        </Title>
        <Search style={{ width: "30%" }} placeholder="Search Lead" />
        <Button
          onClick={() => {
            // setAddFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
        >
          Upload Lead
        </Button>
      </div>
      <div style={{ margin: ".5rem" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button>
            {limit} &nbsp;
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
      <SalesTable
        dataSource={dataSource}
      />

    </div>
  )
}
