import React, { useEffect, useState } from 'react'
import { Row, Col, Form, Input, Typography, Button, Upload, Icon, message } from 'antd'
import { platformApi } from '../../../../../api';
import { withRouter } from "react-router-dom";
import { Divider } from 'antd';
import { formatValue } from '../../../../../utils'
import { ENDPOINT } from '../../../../../constants';


const { Title } = Typography

function CompanySettings(props) {
  const { getFieldDecorator } = props.form;
  const [imageUrl, setImageUrl] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [error, setError] = useState({});
  const [formData, setFormData] = useState(null);
  const [saveButton, setSaveButton] = useState(false)
  const [branchCount, setBranchCount] = useState(null)

  useEffect(() => {
    props.validityRef.current = handleSubmit;
    return () => {
      props.validityRef.current = null;
    };
  });
  useEffect(() => {
    platformApi.post("/api/company/branches/get", { page: 1, size: 1 }).then(res => {
      let data = res.data.response.data.branch;
      setBranchCount(data.length)
      if (data.length) {
        data.map((e, index) => {
          if (e.address) {
            e.address.state = e.address.state.id;
            e.address.country = e.address.country.id;
            e.address.district = e.address.district.id;
          }
        });
        props.setEditable(true)
      }
      props.setDataSource(data)
    })
  }, [])

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error('Image must smaller than 10MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = (info) => {
    if (info.file.status === 'uploading') {
      setLoading(true)
      return;
    }
    if (info.file.status === 'done') {
      setLoading(false)
      getBase64(info.file.originFileObj, (url) => {
        setImageUrl(url);
      });
      const formData = new FormData();
      formData.append("profile", info.file.originFileObj);
      formData.append("master", "Company");
      formData.append("module", "Company Settings");
      formData.append("type", "Company Logo");
      setFormData(formData)
    }
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const changePage = (pos) => {
    const { setCurrent, current } = props
    if (pos === "prev") {
      setCurrent(current - 1)
    }
    else {
      setCurrent(current + 1)
    }
  }

  const handleSubmit = (pos) => {

    props.form.validateFields(validationError => {
      if (
        validationError === null &&
        !error.WEB &&
        !error.EMAIL &&
        !error.CIN &&
        !error.PAN
      ) {
        setSaveButton(true)
        setButtonLoading(true);
        let allData = props.form.getFieldsValue()
        props.emitData(allData, "company")
        if (formData) {
          formData.append("id", allData.name)
          platformApi
            .post("/api/upload/image", formData, {
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then(res => {
              message.success(
                `Image file uploaded successfully`
              );
              let { data } = res
              if (data.code === 200) {
                allData.logo = data.response.data.Location;
                platformApi.post(`/api/company`, allData)
                  .then(res => {
                    let { data } = res;
                    if (data.code === 200) {
                      message.success('Company created Successfully');
                      // props.history.push('/autoadmin')
                      setButtonLoading(false)
                      changePage(pos)
                    }
                  })
                  .catch(err => {
                    setButtonLoading(false)
                    setSaveButton(false)
                  })
              }
            })
            .catch(err => {
              setSaveButton(false)
            })
        }
        else {
          platformApi.post(`/api/company/`, allData)
            .then(res => {
              let { data } = res;
              if (data.code === 200) {
                message.success('Company Saved Successfully');
                // props.history.push('/autoadmin')
                changePage(pos)
              }
              else {
                message.error('Company Settings not saved');
              }
            })
            .catch(err => {
              setSaveButton(false)
            })
        }
      }
      else {
        message.error('Enter all Input Fields');
      }
    })

  }

  const uploadButton = (
    <div>
      <Icon type={loading ? 'loading' : 'plus'} />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <div>
      <Form>
        <Row style={{ marginTop: '2%' }}>
          <h3>Company Logo :</h3>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={`${ENDPOINT}api/upload/mocky`}
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
        </Row>

        <Row gutter={66} style={{ marginTop: '1%' }}>
          <Col span={10}>
            <Form.Item
              label="Company Name"
              validateStatus={error.CNAME && error.CNAME.type}
              help={error.CNAME && error.CNAME.message}>
              {getFieldDecorator(`name`, {
                rules: [
                  {
                    required: true,
                    message: "Enter a Valid Company Name"
                  }
                ]
              })(
                <Input
                  placeholder="Company Name"
                  onKeyUp={(e) =>
                    props.form.setFieldsValue({
                      name: formatValue(e, "allCaps"),
                    })
                  }
                  pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        CNAME: {
                          type: "error",
                          message: "Enter a Valid Name",
                        },
                      });
                    } else {
                      delete error.CNAME;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="Website"
              help={error["WEB"] && error["WEB"].message}
              validateStatus={error["WEB"] && error["WEB"].type}
            >
              {getFieldDecorator(`website`, {
                rules: [
                  {
                    required: true,
                    message: "Enter a Valid Website"
                  }
                ]
              })(
                <Input
                  pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                  placeholder="Website"
                  onInput={event => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        WEB: {
                          type: "error",
                          message: "Enter a Valid Website"
                        }
                      });
                    else {
                      delete error.WEB;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={66} style={{ marginTop: '0%' }}>
          <Col span={10}>
            <Form.Item
              label="E-mail"
              help={error["EMAIL"] && error["EMAIL"].message}
              validateStatus={error["EMAIL"] && error["EMAIL"].type}
            >
              {getFieldDecorator(`email`, {
                rules: [
                  {
                    required: true,
                    message: "Enter a Valid Email Address"
                  }
                ]
              })(
                <Input
                  placeholder="E-mail"
                  onKeyUp={e =>
                    props.form.setFieldsValue({
                      'email': formatValue(e, "toLowerCase")
                    })}
                  pattern="^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z_-]+?(\.[a-zA-Z]{2,3}){1,2}$"
                  onInput={event => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        EMAIL: {
                          type: "error",
                          message: "Enter a Valid Email Address"
                        }
                      });
                    else {
                      delete error.EMAIL;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              label="CIN"
              help={error["CIN"] && error["CIN"].message}
              validateStatus={error["CIN"] && error["CIN"].type}
            >
              {getFieldDecorator(`cin`, {
                rules: [
                  {
                    required: true,
                    message: "Enter a Valid CIN!"
                  }
                ]
              })(
                <Input
                  placeholder="CIN"
                  pattern="^[a-zA-Z]{1}[0-9]{5}[a-zA-Z]{2}[0-9]{4}[a-zA-Z]{3}[0-9]{6}$"
                  onKeyUp={event => {
                    props.form.setFieldsValue({
                      cin: formatValue(event, "toUpperCase")
                    })
                  }}
                  onInput={event => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        CIN: {
                          type: "error",
                          message: "Enter a Valid CIN"
                        }
                      });
                    else {
                      delete error.CIN;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={66} style={{ marginTop: '1%' }}>
          <Col span={10}>
            <Form.Item
              label="PAN Number"
              validateStatus={error["PAN"] && error["PAN"].type}
              help={error["PAN"] && error["PAN"].message}
            >
              {getFieldDecorator(`pan`, {
                rules: [
                  {
                    required: true,
                    message: "Enter a Valid PAN Number!"
                  }
                ]
              })(
                <Input
                  style={{ textTransform: "uppercase" }}
                  placeholder="PAN Number"
                  maxLength={10}
                  onKeyUp={event => {
                    props.form.setFieldsValue({
                      pan: formatValue(event, "toUpperCase")
                    })
                  }}
                  pattern="^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$"
                  onInput={event => {
                    if (!event.target.checkValidity())
                      setError({
                        ...error,
                        PAN: {
                          type: "error",
                          message: "Enter a Valid PAN Number"
                        }
                      });
                    else {
                      delete error.PAN;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={10}>
            <Button type="primary" onClick={() => props.addBranch(true)} style={{ marginTop: '40px' }}>Add Branch</Button>
          </Col>

        </Row>
        <Divider />

        <Row gutter={20}>
          <Col span={22} />
          <Col span={2}>
            <Button type="primary"
              loading={buttonLoading}
              disabled={!(props.branch || branchCount)}
              onClick={() => { handleSubmit("next") }}>
              Next
        </Button>
          </Col>
        </Row>
      </Form>

    </div>
  )
}

const WrappedCompanySettings = Form.create({ name: "companysettings" })(
  CompanySettings
);

export default withRouter(WrappedCompanySettings);