import React, { useState, useEffect, useContext } from "react";
import {
  Col,
  Row,
  Card,
  Table,
  Button,
  message,
  Select,
  Empty,
  Typography,
  Icon,
} from "antd";
import { EyeFilled, EditFilled, WhatsAppOutlined } from "@ant-design/icons";
import { ContextAPI } from "../../../ContextAPI";
import { platformApi } from "../../../api";
import WrappedMessageInterface from "./MessageInterface";
import WrappedCallingInterface from "./CallingInterface";
import WrappedWhatsAppInterface from "./WhatsAppInterface";
import AttachQuotation from "./AttachQuotation";
import QuotationModal from "../../TransactionMasters/Quotations/QuotationsForm";
import WrappedAddActivityModal from "./AddActivityModal";
import BookingRegisterModal from "../../TransactionMasters/BookingRegister/BookingRegisterModal";
import "./style.less";
import moment from "moment";
import { custom } from "@hapi/joi";

const QuotationInfo = (props) => {
  const {
    dataSource,
    getCustomerInfo,
    getActivityByCustomer,
    linkQuotation,
    customerDetail,
    createQuotation,
    customerId,
  } = props;
  const { Text, Title } = Typography;
  const [callModal, setCallModal] = useState(false);
  const [quotationModal, setQuotationModal] = useState(false);
  const [quotationDetail, setQuotationDetail] = useState(null);
  const [quotationEditable, setQuotationEditable] = useState(false);
  const [messageModal, setMessageModal] = useState(false);
  const [whatsAppMessageModal, setWhatsAppMessageModal] = useState(false);
  const [activityModal, setActivityModal] = useState(false);
  const [attachQuotationModal, setAttachQuotationModal] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [bookingModal, setBookingModal] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const [view, setView] = useState(false);

  const column = [
    {
      title: <Text strong>Quotation No</Text>,
      dataIndex: "quotationId",
      key: "quotationId",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Vehicle</Text>,
      dataIndex: "vehicle",
      key: "vehicle",
      render: (record) => (
        <span>
          {record && record.length ? record[0].vehicleDetail.modelName : "-"}
        </span>
      ),
    },
    {
      title: <Text strong>Created On</Text>,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => (
        <span>{moment(new Date(record)).format("DD/MM/YYYY")}</span>
      ),
    },
    {
      title: <Text strong>Action</Text>,
      dataIndex: "id",
      align: "center",
      key: "id",
      render: (record) => (
        <EyeFilled
          style={{ textAlign: "center" }}
          onClick={() => viewQuotation(record)}
        />
      ),
    },
  ];
  const viewQuotation = (quotationId) => {
    getQuotation(quotationId);
    setQuotationModal(true);
    setView(true);
  };

  const getQuotation = (quotationId) => {
    return new Promise(async (resolve, reject) => {
      await platformApi.get(`/api/quotation/${quotationId}`).then((res) => {
        const {
          data: {
            response: { data },
          },
        } = res;
        setQuotationDetail(data);
        resolve(data);
      });
    });
  };

  useEffect(() => {
    platformApi.get("/api/customer/" + customerId).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          let customer = {
            customer: response.data,
            executive: {
              id: loginCredintials.employeeId,
            },
            branch: loginCredintials.branch[0],
            accessories: [],
          };
          // setQuotationDetail(customer)
          setSelectedBooking(customer);
        }
      }
    });
  }, [customerId, quotationModal, bookingModal]);

  const createBooking = (bookingRegister, showMessage = false) => {
    if (bookingRegister.id) {
      platformApi
        .put(`/api/booking/${bookingRegister.id}`, bookingRegister)
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setBookingId(response.data.bookingId);
              setSelectedBooking(response.data);
              getActivityByCustomer();
              // if(JSON.stringify(bookingRegister)===JSON.stringify(response.data))
              showMessage && message.success("Booking saved successfully", 1);
            } else {
              message.error("Unable to save booking", 1);
            }
          } else {
            message.error("Unable to save booking", 1);
          }
        })
        .catch((err) => {
          message.error("Unable to save booking", 1);
          console.error("Error on booking : ", err);
        });
    } else {
      platformApi
        .post("/api/booking/create", bookingRegister)
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = res.data;
            if (response.code === 200) {
              setSelectedBooking(response.data);
              if (response.data.bookingStatus === "ACCEPTED")
                sendConfirmSMS(response.data);
              getActivityByCustomer();
              message.success("Booking added successfully");
            } else {
              message.error("Error in booking creation");
            }
          } else {
            message.error("Error in booking creation");
          }
        })
        .catch((err) => {
          console.error("Error on booking : ", err);
        });
    }
  };

  const sendConfirmSMS = (data) => {
    const {
      bookingId,
      branch,
      executive: {
        profile: { employeeName },
      },
      authentication,
      customer: { name, contacts },
      vehicle: { modelName },
    } = data;
    const phone = contacts[0].phone;
    const smsdata = {
      pdflink:
        authentication.afterVerification || authentication.beforeVerification,
      cname: name,
      vname: modelName,
      bkid: bookingId,
      slex: employeeName,
      clink: "-",
      dlr: branch.name,
    };
    platformApi
      .post("/api/sendSms/bookingconfirm", {
        phone,
        smsdata,
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          message.success("Message sent successfully");
        } else if (data.code === 401) {
          message.error(data.message);
        } else {
          message.error("Unable to send SMS");
        }
      });
  };

  const openCreateQuotation = () => {
    setQuotationEditable(true);
    setQuotationModal(true);
  };

  const openBookingModal = () => {
    //setting booking id
    platformApi.post("/api/idGenerate/booking").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        setBookingId(response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
    setBookingModal(true);
  };

  return (
    <div className="quotation-info">
      <Card style={{ height: "260px" }}>
        <Row type="flex" justify="space-between" gutter={[16, 16]}>
          <Col span={18}>
            <Row type="flex" justify="space-between" gutter={[16, 16]}>
              <Col span={24}>
                <Table
                  locale={{
                    emptyText: (
                      <Empty
                        imageStyle={{
                          height: 25,
                          fontSize: 30,
                        }}
                        description={
                          <Text disabled strong>
                            No Quotations Associated
                          </Text>
                        }
                      />
                    ),
                  }}
                  rowKey={(record) => record.id}
                  pagination={false}
                  size="small"
                  columns={column}
                  dataSource={dataSource}
                />
              </Col>
              <Col span={24}>
                <span
                  className="linkylink"
                  onClick={() => setAttachQuotationModal(true)}
                >
                  Attach Quotation
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={6}>
            <Row type="flex" gutter={[16, 16]} justify="space-between">
              <Col span={24}>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => openBookingModal()}
                >
                  Booking
                </Button>
              </Col>
              <Col span={24}>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => openCreateQuotation()}
                >
                  Quotation
                </Button>
              </Col>
              <Col span={24}>
                <Button
                  style={{ width: "100%" }}
                  onClick={() => setActivityModal(true)}
                >
                  Walk-In
                </Button>
              </Col>
              <Row >
                <Col span={8} >
                  <Button onClick={() => setCallModal(true)} style={{ marginLeft: "50px" }}>
                    <Icon type="phone" />
                  </Button>
                </Col>
                {/* <Col span={8}>
                  <Button onClick={() => setMessageModal(true)}>
                    <Icon type="message" />
                  </Button>
                </Col>
                <Col span={8}>
                  <Button onClick={() => setWhatsAppMessageModal(true)}>
                    <WhatsAppOutlined />
                  </Button>
                </Col> */}

              </Row>
            </Row>
          </Col>
          {/* <Col>
          </Col> */}
        </Row>
      </Card>

      <WrappedMessageInterface
        visibility={messageModal}
        customerDetail={customerDetail}
        getCustomerInfo={getCustomerInfo}
        getActivityByCustomer={getActivityByCustomer}
        setVisibility={setMessageModal}
      />
      <WrappedWhatsAppInterface
        visibility={whatsAppMessageModal}
        customerDetail={customerDetail}
        getCustomerInfo={getCustomerInfo}
        getActivityByCustomer={getActivityByCustomer}
        setVisibility={setWhatsAppMessageModal}
      />
      <WrappedCallingInterface
        visibility={callModal}
        createQuotation={createQuotation}
        customerDetail={customerDetail}
        setVisibility={setCallModal}
      />
      <AttachQuotation
        visibility={attachQuotationModal}
        setVisibility={setAttachQuotationModal}
        onSave={linkQuotation}
        customerDetail={customerDetail}
      />
      <QuotationModal
        leadData={null}
        editable={quotationEditable}
        emitData={createQuotation}
        setView={setView}
        view={view}
        cusData={customerDetail}
        // customerDetail={customerDetail}
        data={quotationDetail}
        open={quotationModal}
        close={() => {
          // setQuotationDetail(quotationDetail)
          setQuotationEditable(false);
          setQuotationModal(false);
        }}
        addData={async (quotation, callBack) => {
          let tmpQuotation = await getQuotation(quotation.id);
          tmpQuotation.pdfWithBrochure = quotation.pdfWithBrochure;
          tmpQuotation.pdfWithOutBrochure = quotation.pdfWithOutBrochure;
          callBack(tmpQuotation);
        }}
      />
      <BookingRegisterModal
        id={bookingId}
        currentData={selectedBooking}
        // customerDetail={customerDetail}
        emitData={(data, showMessage) => createBooking(data, showMessage)}
        editable={true}
        open={bookingModal}
        clearBookingData={(data) => setSelectedBooking(data)}
        close={() => {
          setBookingId("");
          setBookingModal(false);
        }}
      />

      <WrappedAddActivityModal
        visibility={activityModal}
        getActivityByCustomer={getActivityByCustomer}
        customerDetail={customerDetail}
        setVisibility={setActivityModal}
      />
    </div>
  );
};

export default QuotationInfo;
