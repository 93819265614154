import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Input,
  Button,
  message,
} from "antd";
import { platformApi } from "../../../../../api";
import { formatValue } from "../../../../../utils"
const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;

const CustomerForm = ({ form, validityRef, data, open, clearRef }) => {
  const [financers, setFinancers] = useState([]);
  const [executives, setExecutive] = useState([]);
  const [branches, setBranch] = useState([]);
  const [validNum, setValidNum] = useState(false);
  const [customers, setCustomer] = useState(null);
  const [booking, setBooking] = useState([]);
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });

  const populateData = async () => {
    setCustomer(data.customer);
    setFieldsValue({
      customerNumber: data.customerNumber,
      financer: data.financer,
      purchaseOption: data.purchaseOption,
      salesExecutive: data.salesExecutive,
      showroom: data.showroom,
    });
  };

  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.customer = customers;
      data.customerNumber = getFieldValue("customerNumber");
      data.salesExecutive = getFieldValue("salesExecutive");
      data.showroom = getFieldValue("showroom");
      data.financer = getFieldValue("financer");
      data.purchaseOption = getFieldValue("purchaseOption");
    }
    return isValid;
  };

  useEffect(() => {
    if (open) {
      platformApi.get("/api/financer").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setFinancers(response.data);
          }
        }
      });
      platformApi.get("/api/user/currentUser").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setFieldsValue({
              salesExecutive: response.data.id,
              showroom: response.data.profile.branch[0].id,
            });
          }
        }
      });

      platformApi.get("/api/user/branch").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          setBranch(data.data);
        }
      });
      platformApi.get("/api/user/all").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setExecutive(response.data);
          }
        }
      });
    }
    populateData();
  }, [open]);

  const searchCustomer = () => {
    const number = getFieldValue("customerNumber");
    platformApi.get("api/customer/phone/" + number).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          if (response.data[0]) {
            setCustomer(response.data[0]);
            setBooking(response.data[0].booking);
          } else message.error("No user found", 2);
        }
      }
    });
  };

  return (
    <Form>
      <Row type="flex" justify="space-between">
        <Col>
          <Text strong>Vehicle Purchase Challan No :</Text> {data.challanId}
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Item
            label={
              <span>
                <Text>Customer </Text>
                {customers && (
                  <span>
                    : <Text strong> {customers.name} </Text>
                  </span>
                )}
              </span>
            }
            colon={false}
          >
            {/* {getFieldDecorator("customer", {
              rules: [{ required: false, message: "Select a Customer" }],
            })(
              <Select
                showSearch={true}
                filterOption={(option, input) => {
                  option.props.children.some(
                    (obj) => obj.indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={handleCustomerChange}
                placeholder="Customer Associated"
              >
                {customers.map((customer) => (
                  <Option key={customer.id} value={customer.id}>
                    {`${customer.name} ${
                      customer.contacts[0] && ` - ${customer.contacts[0].phone}`
                    }`}
                  </Option>
                ))}
              </Select> */}
            <Row>
              <Col span={20}>
                {getFieldDecorator("customerNumber")(
                  <Input
                    addonBefore="+91"
                    min="0"
                    maxLength={10}
                    required
                    pattern="^\d{10}$"
                    onInput={(event) => {
                      if (event.target.checkValidity()) {
                        setValidNum(true);
                      } else {
                        setValidNum(false);
                      }
                    }}
                    onChange={() => customers && setCustomer(null)}
                    placeholder="Customer Number"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        customerNumber: formatValue(e, "onlyNo")
                      })}
                  />
                )}
              </Col>
              {validNum && (
                <Col span={4}>
                  <Button type="link" size="small" onClick={searchCustomer}>
                    Search
                  </Button>
                </Col>
              )}
            </Row>
            {/* )} */}
          </Item>
        </Col>
        <Col span={10}>
          <Item label={<Text>Sales Executive</Text>} colon={false}>
            {getFieldDecorator("salesExecutive", {
              rules: [{ required: false, message: "Select a Executive" }],
            })(
              <Select placeholder="Sales Executive">
                {executives.map((executive) => (
                  <Option key={executive.id} value={executive.id}>
                    {executive.profile.employeeName}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Item label="Booking Register" colon={false}>
            {getFieldDecorator("bookingReg")(
              <Select placeholder="Booking Register">
                {booking.map((bookings) => (
                  <Option key={bookings.id} >
                    {bookings.bookingId}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={10}>
          <Item label={<Text>Showroom Location</Text>} colon={false}>
            {getFieldDecorator("showroom", {
              rules: [{ required: false, message: "Select a Showroom" }],
            })(
              <Select placeholder="Showroom Location">
                {branches.map((branch) => (
                  <Option key={branch.id} value={branch.id}>
                    {branch.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Item label={<Text>Financer</Text>} colon={false}>
            {getFieldDecorator("financer", {
              rules: [{ required: false, message: "Select a Financer" }],
            })(
              <Select placeholder="Financer">
                {financers.map((financer) => (
                  <Option key={financer.id} value={financer.name}>
                    {financer.name}
                  </Option>
                ))}
              </Select>
            )}
          </Item>
        </Col>
        <Col span={10}>
          <Item label={<Text>Purchase Option</Text>} colon={false}>
            {getFieldDecorator("purchaseOption", {
              rules: [{ required: false, message: "Select a Purchase Option" }],
            })(
              <Select placeholder="Purchase Option">
                <Option key="CASH" value="CASH">
                  Cash
                </Option>
                <Option key="FINANCE" value="FINANCE">
                  Finance
                </Option>
              </Select>
            )}
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedCustomerForm = Form.create()(CustomerForm);

export default WrappedCustomerForm;
