import React, { useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Input,
  message,
  Slider,
  DatePicker,
} from "antd";
import moment from "moment";
import { platformApi } from "../../../../api";
const { Item } = Form;
const { Option } = Select;
const { RangePicker } = DatePicker;
function AdvancedFilters({
  form,
  filteropen,
  close,
  searchName,
  setId,
  id,
  setData,
  setSpinning,
  setCount,
  page,
  limit,
  setPageData,
  setSearch,
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [max, setMax] = useState("125000");
  const [min, setMin] = useState("25000");
  const diff = (parseInt(max) - parseInt(min)) / 100;
  const Filters = [
    { id: "1", name: "Model Name & Code" },
    //   { id: "2", name:'Price Range'},
    //   { id: "3", name:'Insurance types' },
    { id: "4", name: "Validity Range" },
  ];
  const insurance = [
    { key: "INSURANCE1plus5", title: "1+5" },
    { key: "INSURANCE5plus5", title: "5+5" },
    { key: "INSURANCE1plus5ZD", title: "1+5 Zero Dep" },
    { key: "INSURANCE5plus5ZD", title: "5+5 Zero Dep" },
  ];
  //price range

  function formatter(value) {
    return `${value * diff + parseInt(min)}`;
  }
  const marks = {
    0: "₹" + min,
    100: "₹" + max,
  };
  const selectFilter = (id) => {
    setId(id);
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const searchValue = () => {
    setId([]);
    resetFields();
    setPageData();
    setSearch([]);
  };
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
  };
  useEffect(() => {
    if (filteropen) {
      setFieldsValue({ selectfilter: id });
    }
  }, [filteropen]);
  // useEffect(() => {
  //   if (id.includes("1")) {
  //     setFieldsValue({ Name: searchName.name });
  //   }
  // }, [id, searchName, filteropen]);
  useEffect(() => {
    if (id.includes("2")) {
      setFieldsValue({ Pricerange: [25, 50] });
      platformApi.get("api/vehiclePrice/price/range").then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setMax(response.data.max.showroomPrice);
            setMin(response.data.min.showroomPrice);
          }
        }
      });
    }
  }, [id]);
  const branchSelect = (id) => {
  };
  const handleSubmit = () => {
    const price = [];
    const validity = [];
    if (id.includes("2")) {
      getFieldValue("Pricerange").map((range) => {
        price.push(range * diff + parseInt(min));
      });
    }
    if (id.includes("4")) {
      getFieldValue("range").map((range) => {
        validity.push(moment(range).format("YYYY-MM-DD"));
      });
    }
    const data = {
      name: getFieldValue("Name"),
      price: id.includes("2") ? price : undefined,
      // insurance:getFieldValue("insurance"),
      validity: id.includes("4") ? validity : undefined,
    };
    setSearch(data);
    setPageData(data);
    platformApi
      .post("/api/vehiclePrice/get", {
        page: page,
        size: limit,
        // searchString: search && search.name,
        validity: data.validity
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = res.data;
          if (response.code === 200) {
            setData(response.data.vehiclePrice);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch vehicle price", 2);
          }
        } else {
          message.error("Unable to fetch vehicle price", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to fetch vehicle price", 2);
        console.error("Error on Vehicle Price : ", error);
      });
  };
  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <Row type="flex" justify="start">
                  {id.map((element) =>
                    element === "1" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("Name")(
                                <Input placeholder="Model Name & Code" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "2" ? (
                      <Col span={14}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col
                              span={20}
                              style={{ padding: "0px 20px" }}
                              className="RangeSlider"
                            >
                              {getFieldDecorator("Pricerange")(
                                <Slider
                                  onChange={branchSelect}
                                  range
                                  marks={marks}
                                  tipFormatter={formatter}
                                />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                style={{ marginLeft: "0.5rem" }}
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "3" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("insurance")(
                                <Select
                                  placeholder="Insurance Types"
                                  filterOption={filterMethod}
                                  showSearch
                                  showAction={["click","focus"]}
                                >
                                  {insurance.map((type) => (
                                    <Select.Option
                                      value={type.title}
                                      key={type.key}
                                    >
                                      {type.title}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : (
                            <Col span={14}>
                              <Item colon={false}>
                                <Row type="flex">
                                  <Col span={20}>
                                    {getFieldDecorator("range")(
                                      <RangePicker onChange={branchSelect} />
                                    )}
                                  </Col>
                                  <Col span={2}>
                                    <Button
                                      type="ghost"
                                      shape="circle"
                                      size="small"
                                      onClick={() => deleteFilter(element)}
                                    >
                                      &#10005;
                              </Button>
                                  </Col>
                                </Row>
                              </Item>
                            </Col>
                          )
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex" justify="space-between">
                  <Col span={19}>
                    <Item colon={false}>
                      {getFieldDecorator("selectfilter")(
                        <Select
                        showAction={["click","focus"]}
                          placeholder="Select the Filter"
                          onChange={selectFilter}
                          mode="multiple"
                        >
                          {Filters.map((type) => (
                            <Option key={type.id}>{type.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={5} style={{ marginTop: "0.2rem" }}>
                    <Button type="primary" onClick={handleSubmit}>
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="end"
                  style={{ marginRight: "0.79rem" }}
                >
                  <Button
                    type="ghost"
                    style={{ width: "4.5rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
