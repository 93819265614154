import React from "react";
import { Table, Typography, Modal } from "antd";

const { Text } = Typography;

export default function FollowUpModal({ dataSource, open, close }) {
  const columns = [
    {
      title: <Text strong>Follow-Up Date</Text>,
      key: "followup",
      dataIndex: "followup",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Remarks</Text>,
      key: "price",
      dataIndex: "price",
      render: (record) => <span>&#8377; {record}</span>,
    },
    {
      title: <Text strong>Caller</Text>,
      key: "caller",
      dataIndex: "caller",
      render: (record) => <span>&#8377; {record}</span>,
    },
    {
      title: <Text strong>Actions</Text>,
      key: "actions",
      dataIndex: "actions",
      render: (record) => <span>&#8377; {record}</span>,
    },
  ];
  return (
    <div>
      <Modal
       title="Follow-Up Data"
        visible={open}
        onCancel={() => {
          close();
        }}
        okText="Save"
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Table
          size="small"
          tableLayout="auto"
          pagination={false}
          dataSource={dataSource}
          columns={columns}
        />
      </Modal>

    </div>
  )
}
