import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Divider, Popconfirm, Typography } from 'antd';
import { baseUrl } from '../../../../api';
const{Text}=Typography;

function InvoiceTable(props) {
  const {
    history,
    gst,
    pagination,
    dataSource,
    spinner,
    setModifyAccess,
    modify,
    deleteData,
    setEditable,
    setEditableData,
    deleteAccess,
    printAccess
  } = props;
  const column = [
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (record) => (<span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>),
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (record) => (<span>{record}</span>),
    },
    // {
    //   title: "Job Card",
    //   dataIndex: "jobOrder",
    //   key: "jobOrder",
    //   render: (record) => (<span>{record && record.jobNo ? record.jobNo : "-"}</span>),
    // },
    {
      title: "Party Name",
      dataIndex: "partyName",
      key: "partyName",
      render: (record) => (<span>{record && record.name}</span>),
    },
    {
      title: "Total Amount",
      dataIndex: "totalInvoice",
      key: "totalInvoice",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Payment Status",
      dataIndex: "jobOrder",
      key: "jobOrder",
      render: (record) => (<span>{record && record.jobStatus ? record.jobStatus : "-"}</span>),
    },
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div style={{display: "flex", gap: "0.5rem", justifyContent: "center", alignItems: "center"}}>
          { printAccess?
            <a href={`${baseUrl}api/saleSpareInvoice/generatePDF/${data.id}`} target="_blank" rel="noopener noreferrer" className="linkylink">Print</a>
            :
            <span></span>
          }
          {modify?
            <span
              onClick={(event) => {
                event.stopPropagation();
                // setModifyAccess(true)
                history.push({
                  pathname: `/autoadmin/counter_sale/${data.id}`,
                  state: {
                    gst: gst,
                    editable: true
                  }
                })
              }}
              className="linkylink"
            >
              Modify
                  </span>
                  :
                  <span></span>
          }
          { deleteAccess?
            <Popconfirm
              title="Do you want to delete SSI?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
              Delete
              </span>
            </Popconfirm>
            :
            <span></span>
          }
          {
             !modify && !deleteAccess && !printAccess
             ?
             <Text strong disabled>No Actions Given</Text>
             :
             <span></span>
          }
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => { record.id }}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      loading={spinner}
      style={{ cursor: "pointer", overflowX: "scroll", width: "100%" }}
      dataSource={dataSource}
      onRow={(record) => ({
        onClick: () => {
          history.push({
            pathname: `/autoadmin/counter_sale/${record.id}`,
            state: {
              gst: gst
            }
          })
          // history.push(`/autoadmin/sale_spares_invoice/${record.id}`)
        },
      })}
    />
  );
}


export default withRouter(InvoiceTable);