import React, { useEffect, useState } from "react";
import {
    Form,
    Select,
    Typography,
    Row,
    Col,
    Button,
    Input,
    DatePicker,
    Table,
    Divider,
    Popconfirm,
    message,
    Tabs
} from "antd";
import ServiceHistory from "./ServiceHistory";
import { formatValue } from '../../../../../utils'
import './index.less'
const { TabPane } = Tabs;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
import WrappedUploadDocuments from './UploadDocuments';
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { add, indexOf } from "lodash";
import empty from '../../../../../assets/empty.png'
import Documents from "./Documents";
import InsuranceForm from "./InsuranceForm";
import moment from "moment";
const Overview = ({
    form,
    validityRef,
    data,
    clearRef,
    open,
    editable,
    setDetails,
    Details,
    recvData,
    selectedNumber,
    selectedPhone,
    setLoading
}) => {
    const serviceTypeBeforeWarranty = [
        { key: 1, title: "Free" },
        { key: 2, title: "Extended Warranty" },
        { key: 3, title: "Paid (UW)" },
        { key: 4, title: "Paid (AW)" },
        { key: 5, title: "AMC" },
        { key: 6, title: "Accidental" },
        { key: 7, title: "Minor" },
    ];
    const serviceTypeAfterWarranty = [
			{ key: 1, title: "Extended Warranty" },
			{ key: 2, title: "Paid (AW)" },
			{ key: 3, title: "AMC" },
			{ key: 4, title: "Accidental" },
			{ key: 5, title: "Minor" },
		];
    const { getFieldDecorator, getFieldValue } = form;
    const [vehicleId, setVehicleImage] = useState()
    const [complaintList, setComplaintList] = useState([{ complaint: "" }])
    const [count, setCount] = useState(1)
    const [error, setError] = useState({});
    const [checkAll, setCheckAll] = useState(false);
    const [checkkms, setCheckkms] = useState(true);
    const [fuelLevel, setFuelLevel] = useState();
    const [couponNumber, setCouponNumber] = useState();
    //CHECK STATES
    const [PcheckedList, setPCheckedList] = useState([]);
    const [Pindeterminate, setPIndeterminate] = useState(true);
    const [PcheckAll, setPCheckAll] = useState(false);
    //IMAGES
    const [fileList, setFileList] = useState([]);
    const [RHSfileList, setRHSFileList] = useState([]);
    const [RearfileList, setRearFileList] = useState([]);
    const [LHSfileList, setLHSFileList] = useState([]);
    const [TopfileList, setTopFileList] = useState([]);
    //FORM DATA
    const [FrontForm, setFrontForm] = useState("");
    const [RHSForm, setRHSForm] = useState("");
    const [LHSForm, setLHSForm] = useState("");
    const [RearForm, setRearForm] = useState("");
    const [TopForm, setTopForm] = useState("");
    //IMAGES  
    const [frontImg, setFront] = useState();
    const [RHSImg, setRhs] = useState();
    const [LHSImg, setLhs] = useState();
    const [RearImg, setRear] = useState();
    const [TopImg, setTop] = useState();

    const [serviceType, setServiceType] = useState(serviceTypeBeforeWarranty);
    const [lastServiceNumber, setLastServiceNumber] = useState(0)

    useEffect(() => {
        clearRef.current = () => {
            form.resetFields();
        };
        validityRef.current = () => {
            return validatePage();
        };
        return () => {
            validityRef.current = null;
            clearRef.current = null;
        };
    });
    const validatePage = () => {
        let isValid;
        form.validateFields((validationError) => {
            isValid = validationError === null;
        });
        let temp = complaintList.filter(i => {
            return i.complaint.length > 0
        })
        if (complaintList.length === 0 || (complaintList.length > 0 && complaintList[0].complaint.length == 0)) {
            message.error("Please Add the Complaint!!!")
            isValid = false
        }
        if (isValid) {
            setComplaintList(temp)
            // data.fileList = fileList;
            // data.RHSfileList = RHSfileList;
            // data.RearfileList = RearfileList;
            // data.LHSfileList = LHSfileList;
            // data.TopfileList = TopfileList;
            data.kms = parseInt(getFieldValue("kms"));
            data.serviceType = getFieldValue("serviceType");
            data.serviceNo = getFieldValue("serviceNo")
            data.couponNo = getFieldValue("couponNo")
            data.complaintList = complaintList;
            data.vehicle = data.selectVehicle
            data.fuelLevel = parseInt(fuelLevel);
            data.PcheckedList = PcheckedList
            data.front = FrontForm;
            data.rhs = RHSForm;
            data.lhs = LHSForm;
            data.top = TopForm;
            data.rear = RearForm;
            data.vehicleId = vehicleId ? vehicleId : ""
        }
        if(error.serviceNo) isValid = false;
        return isValid;
    };
    useEffect(() => {
        if (complaintList.length > 0 && complaintList[complaintList.length - 1].complaint.length > 0) {
            addComplaint()
        }
    }, [complaintList.length > 0 && complaintList[complaintList.length - 1].complaint])

    useEffect(() => {
        console.log("SELECTED", data, selectedNumber, selectedPhone)
        selectedNumber && selectedPhone && data.selectVehicle.customer.map(item => {
            if (item.customer.id === selectedNumber) {
                let cusdata = {
                    phone: selectedPhone
                }
                item.customer.contacts.push(cusdata);
            }
            let contacts = item.customer.contacts
            data.customerPhone = selectedPhone
            // item.customer.contacts = item.customer.contacts.length > 0 && item.customer.contacts.filter(value => {
            //     return value.phone === selectedNumber
            // })
            if (item.customer.contacts.length === 0) {
                item.customer.contacts = contacts
            }
        })
        
        form.setFieldsValue({
            couponNo: data.selectVehicle &&
                data.selectVehicle.serviceCouponNumber ?
                data.selectVehicle.serviceCouponNumber : null
        })
        setCouponNumber(data.selectVehicle.serviceCouponNumber)
        if (data.selectVehicle.customer.length > 0) {
            data.customer = selectedNumber ?
                data.selectVehicle.customer.customer ?
                    data.selectVehicle.customer.customer.find(item => {
                        return item.customer.id === selectedNumber
                    })
                    : data.selectVehicle.customer.find(item => {
                        return item.customer.id === selectedNumber
                    })
                : data.selectVehicle.customer[0].customer
        }
        console.log("Data", data)
    }, [selectedNumber, selectedPhone])

    const isServiceAfterWarranty = ({ services, fromDate }) => {
      const lastService = services.slice(-1)[0];
      if (lastService == null) return false;
      if (moment(lastService.serviceDate).isBefore(fromDate)) return true;
      return false;
    };

    useEffect(() => {
        if (!data?.selectVehicle.jobOrder) return;
        let serviceNo = 0;
        for (const job of data.selectVehicle.jobOrder) {
          if (job.serviceNo && parseInt(job.serviceNo) > serviceNo)
            serviceNo = parseInt(job.serviceNo);
        }
        setLastServiceNumber(serviceNo);
        const fromDate = new Date();
        const isAfterWarranty = isServiceAfterWarranty({ services: data.selectVehicle.services, fromDate });
        if(isAfterWarranty) setServiceType(serviceTypeAfterWarranty);
    }, [data]);

    const columns = [
        {
            title: "Complaint No",
            render: (data) => (
                <h3>{complaintList.indexOf(data) + 1}</h3>
            )
        }
        ,
        {
            title: "Complaint",
            render: (data) => (<div>
                <TextArea disabled={!editable} onChange={(e) => saveComplaint(complaintList.indexOf(data), e)} value={data.complaint} rows={3} />
            </div>)
        }, {
            title: "Action",
            render: (data) => (
                <Button disabled={!editable} onClick={() => deleteComplaintData(data)}><MinusCircleOutlined /></Button>
            )
        }
    ]
    const saveComplaint = (index, e) => {
        let complaint = complaintList
        complaint[index].complaint = e.target.value
        setComplaintList([...complaint])
    }
    const addComplaint = () => {
        complaintList.push({ complaint: "" })
        setCount(count + 1)
        setComplaintList([...complaintList])
    }
    const deleteComplaintData = (complaint) => {
        let complaints = complaintList.filter(data => complaintList.indexOf(data) != complaintList.indexOf(complaint))
        setComplaintList(complaints)
    }
    const handleServiceType = (id) => {
        if (id === "Free" || id === "Paid (UW)" || id === "AMC") {
            setCheckAll(true);
        }
        else {
            setCheckAll(false);
        }
    };
    useEffect(() => {
        if (Object.keys(recvData).length > 0) {
            form.setFieldsValue({
                kms: recvData.kms,
                serviceNo: recvData.serviceNo,
                serviceType: recvData.serviceType,
                couponNo: recvData.couponNo,
            })
            let compz = [];
            recvData.complaint && recvData.complaint.map(obj => {
                let newObjz = {
                    complaint: obj.complaint,
                    id: obj.id
                }
                compz.push(newObjz);
            })
            if (compz.length > 0) {
                setComplaintList([...compz]);
            }
            setFuelLevel(recvData.fuelLevel)
            let partsResults = [];
            if (recvData.parts != null && recvData.parts.MirrorRH) partsResults.push("MirrorRH");
            if (recvData.parts != null && recvData.parts.MirrorLH) partsResults.push("MirrorLH");
            if (recvData.parts != null && recvData.parts.Toolkit) partsResults.push("Toolkit");
            if (recvData.parts != null && recvData.parts.FirstAdKit) partsResults.push("FirstAdKit");
            setPCheckedList([...partsResults]);
            setFront(recvData.vehicleImage && recvData.vehicleImage.frontView ? recvData.vehicleImage.frontView : empty);
            setRear(recvData.vehicleImage && recvData.vehicleImage.rearView ? recvData.vehicleImage.rearView : empty);
            setRhs(recvData.vehicleImage && recvData.vehicleImage.rhsView ? recvData.vehicleImage.rhsView : empty);
            setLhs(recvData.vehicleImage && recvData.vehicleImage.lhsView ? recvData.vehicleImage.lhsView : empty);
            setTop(recvData.vehicleImage && recvData.vehicleImage.topView ? recvData.vehicleImage.topView : empty);
            setFrontForm(recvData.vehicleImage && recvData.vehicleImage.frontView ? recvData.vehicleImage.frontView : empty);
            setRHSForm(recvData.vehicleImage && recvData.vehicleImage.rhsView ? recvData.vehicleImage.rhsView : empty);
            setLHSForm(recvData.vehicleImage && recvData.vehicleImage.lhsView ? recvData.vehicleImage.lhsView : empty);
            setRearForm(recvData.vehicleImage && recvData.vehicleImage.rearView ? recvData.vehicleImage.rearView : empty);
            setTopForm(recvData.vehicleImage && recvData.vehicleImage.topView ? recvData.vehicleImage.topView : empty);
            // setVehicleImage(recvData.vehicleImage && recvData.vehicleImage.id)
        }
    }, [recvData, editable]);

    return (
        <>
            <Row>
                <Col span={2}></Col>
                <Col span={20}>
                    <Tabs defaultActiveKey="1" centered style={{ backgroundColor: "white" }}>
                        <TabPane tab="Complaints" key="1">
                            <>
                                <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
                                    <Row type="flex" justify="space-around">
                                        <Col span={5}>
                                            <Form.Item colon={false}
                                                validateStatus={error.kms && error.kms.type}
                                                help={error.kms && error.kms.message}
                                                label="Kms"
                                            >
                                                {getFieldDecorator("kms", {
                                                    rules: [
                                                        {
                                                            required: true,
                                                            message: "Enter KMS",
                                                        },
                                                    ],
                                                })(
                                                    <Input
                                                        type="number"
                                                        disabled={!editable}
                                                        placeholder="KMs"
                                                        onKeyUp={e =>
                                                            form.setFieldsValue({
                                                                kms: formatValue(e, "onlyNo")
                                                            })}
                                                        onChange={(event) => {
                                                            if (!event.target.checkValidity()) {
                                                                setError({
                                                                    ...error,
                                                                    kms: {
                                                                        type: "error",
                                                                        message: "Enter Valid KMS",
                                                                    },
                                                                });
                                                            } else {
                                                                delete error.kms;
                                                                setError(error);
                                                            }
                                                        }} />
                                                )}
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                colon={false}
                                                label="Service Type"
                                            >
                                                {
                                                    form.getFieldDecorator("serviceType", {
                                                        rules: [{
                                                            required: true,
                                                            message: "Select ServiceType!"
                                                        }]
                                                    })(
                                                        <Select placeholder="Service Type" disabled={!editable} onChange={handleServiceType}>
                                                            {serviceType.map((data) => (
                                                                <Select.Option key={data.title}>{data.title}</Select.Option>
                                                            ))}
                                                        </Select>
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item colon={false}
                                                label="Service Number"
                                                validateStatus={error.serviceNo && error.serviceNo.type}
                                                help={error.serviceNo && error.serviceNo.message}>
                                                {
                                                    getFieldDecorator("serviceNo", {
                                                        rules: [{
                                                            required: checkAll,
                                                            message: "Enter Service Number!"
                                                        }]
                                                    })(
                                                        <Input type="number" placeholder="Service Number"
                                                            disabled={!editable}
                                                            onKeyUp={e =>
                                                                form.setFieldsValue({
                                                                    serviceNo: formatValue(e, "onlyNo")
                                                                })}
                                                            onChange={(event) => {
                                                                if (!event.target.checkValidity() || lastServiceNumber >= parseInt(event.target.value)) {
                                                                    setError({
                                                                        ...error,
                                                                        serviceNo: {
                                                                            type: "error",
                                                                            message: `ServiceNo must be greater than ${lastServiceNumber}`,
                                                                        },
                                                                    });
                                                                } else {
                                                                    delete error.serviceNo;
                                                                    setError(error);
                                                                }
                                                            }}
                                                        />
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item colon={false}
                                                label="Service Coupon Number"
                                                validateStatus={error.couponNo && error.couponNo.type}
                                                help={error.couponNo && error.couponNo.message}
                                            >
                                                {
                                                    getFieldDecorator("couponNo", {
                                                        rules: [{
                                                            required: checkAll,
                                                            message: "Enter Service Coupon Number.!"
                                                        }]
                                                    })(
                                                        <Input type="number" placeholder="Service Coupon Number."
                                                            disabled={!editable}
                                                            onKeyUp={e =>
                                                                form.setFieldsValue({
                                                                    couponNo: formatValue(e, "onlyNo")
                                                                })}
                                                            onChange={(event) => {
                                                                if (!event.target.checkValidity()) {
                                                                    setError({
                                                                        ...error,
                                                                        couponNo: {
                                                                            type: "error",
                                                                            message: "Enter Valid Service Coupon No",
                                                                        },
                                                                    });
                                                                } else {
                                                                    delete error.secouponNorviceNo;
                                                                    setError(error);
                                                                }
                                                            }} />
                                                    )
                                                }
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>
                                <br />
                                <div className="Complaints">
                                    <div style={{ display: 'inline' }}>
                                        <h3>Complaints</h3>
                                        <span style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-2rem', marginBottom: '1rem' }}> <Button id="complaintAdd" onClick={() => { addComplaint() }}><PlusCircleOutlined /></Button></span>
                                    </div>
                                    <div id="complaints">
                                        <Table
                                            columns={columns}
                                            dataSource={complaintList}
                                            disabled={!editable}
                                        />
                                    </div>
                                </div>
                                <WrappedUploadDocuments
                                    editable={editable}
                                    data={data}
                                    fuelLevel={fuelLevel}
                                    setFuelLevel={setFuelLevel}
                                    PcheckedList={PcheckedList}
                                    setPCheckedList={setPCheckedList}
                                    setPIndeterminate={setPIndeterminate}
                                    setPCheckAll={setPCheckAll}
                                    fileList={fileList}
                                    setFileList={setFileList}
                                    RHSfileList={RHSfileList}
                                    setRHSFileList={setRHSFileList}
                                    RearfileList={RearfileList}
                                    setRearFileList={setRearFileList}
                                    LHSfileList={LHSfileList}
                                    setLHSFileList={setLHSFileList}
                                    TopfileList={TopfileList}
                                    setTopFileList={setTopFileList}
                                    FrontForm={FrontForm}
                                    setFrontForm={setFrontForm}
                                    RHSForm={RHSForm}
                                    setRHSForm={setRHSForm}
                                    LHSForm={LHSForm}
                                    setLHSForm={setLHSForm}
                                    RearForm={RearForm}
                                    setRearForm={setRearForm}
                                    TopForm={TopForm}
                                    setTopForm={setTopForm}
                                    frontImg={frontImg}
                                    setFront={setFront}
                                    RHSImg={RHSImg}
                                    setRhs={setRhs}
                                    LHSImg={LHSImg}
                                    setLhs={setLhs}
                                    RearImg={RearImg}
                                    setRear={setRear}
                                    TopImg={TopImg}
                                    setTop={setTop}
                                    setLoading={setLoading}
                                />
                            </>
                        </TabPane>
                        <TabPane tab="Service History" key="2">
                            <div style={{ border: "1px solid lightgrey", padding: "40px 20px", borderRadius: '.5rem', marginRight: '.2rem' }}>
                                <ServiceHistory recvData={recvData} editable={false} />
                            </div>
                        </TabPane>
                        <TabPane tab="Documents" key="3">
                            <div style={{ border: "1px solid lightgrey", padding: "40px 20px", borderRadius: '.5rem', marginRight: '.2rem' }}>
                                <Documents
                                    data={recvData.vehicle && recvData.vehicle.vehicle}
                                    editable={false}
                                    insurance={recvData.vehicle && recvData.vehicle.insurance}
                                />

                                <InsuranceForm
                                    editable={false}
                                    modifyData={recvData.vehicle && recvData.vehicle.insurance}
                                />

                            </div>
                        </TabPane>
                    </Tabs>
                </Col>
                <Col span={2}></Col>
            </Row>
        </>
    )
}
const WrappedOverview = Form.create()(Overview);

export default WrappedOverview;
