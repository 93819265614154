import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker
} from "antd";
import { platformApi } from "../../../../../api";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;

const VehicleData = ({ form }) => {
  const [modelData, setModelData] = useState([]);
  const [colorData, setColorData] = useState([]);
  const [engineData, setEngineData] = useState([]);
  const [chassisData, setChassisData] = useState([]);
  const [mfgDate, setMfgDate] = useState();

  useEffect(() => {
    platformApi.get("/api/vehicleModel").then(res => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setModelData(response.data);
        }
      }
    });
    // platformApi.get("/api/user/saleChallan").then(res => {
    //   if (res.data.code !== 500) {
    //     let data = res.data.data;
    //     setBranchData(data);
    //   }
    // });
  }, []);

  const onChange = (date, dateString) => {
    setMfgDate(dateString);
  };

  const { getFieldDecorator } = form;
  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} required={true} label={<Text>Model</Text>}>
            {getFieldDecorator("model", {
              rules: [
                {
                  required: true,
                  message: "Model Required!"
                }
              ]
            })(
              <Select placeholder="Model">
                {modelData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item colon={false} label={<Text>Chassis No.</Text>}>
            {getFieldDecorator("chassisNo", {
              rules: [
                {
                  required: false,
                  message: "Please input your Chassis No.!"
                }
              ]
            })(
              <Select placeholder="Chassis No.">
                {chassisData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Color</Text>}>
            {getFieldDecorator("color")(
              <Select placeholder="Color">
                {colorData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item colon={false} label={<Text>MFG Date</Text>}>
            {getFieldDecorator("mfgDate")(
              <DatePicker
                allowClear={true}
                disabled={!true}
                format="MM-YYYY"
                onChange={onChange}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Engine No.</Text>}>
            {getFieldDecorator("engineNo")(
              <Select placeholder="Engine No.">
                {engineData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Key No.</Text>}>
            {getFieldDecorator("keyNo")(
              <Input disabled={!true} placeholder="Key No." />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Battery No.</Text>}>
            {getFieldDecorator("batteryNo")(
              <Input disabled={!true} placeholder="Battery No." />
            )}
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item colon={false} label={<Text>Warranty Back No.</Text>}>
            {getFieldDecorator("warrantyNo")(
              <Input disabled={!true} placeholder="Warranty Back No." />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedVehicleData = Form.create()(VehicleData);

export default WrappedVehicleData;
