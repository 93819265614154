import React, { useContext, useState, useEffect } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
} from "antd";
import { platformApi } from "../../../../api";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../../ContextAPI";
import { getUUID } from '../../../../_helpers/utils'

const VITable = ({
  dataSource,
  pagination,
  spinner,
  modify,
  deleteData,
  setVisible,
  setEditable,
  setEditableData
}) => {
  const [tableKey, setTableKey] = useState(getUUID())

  const columns = [
    {
      title: "Model Name",
      dataIndex: "modelName",
      key:"modelName",
      render: (record) => (
        <span>
          {record && record}
        </span>
      ),
    },
    {
      title: "Chassis No.",
      dataIndex: "chassisNo",
      key:"chassisNo",
      render: (record) => <span>{record&&record}</span>,
    },
    {
      title: "Engine No.",
      dataIndex: "engineNo",
      key:"engineNo",
      render: (record) => <span>{record&&record}</span>,
    },
    {
      title: "Color ",
      dataIndex: "color",
      key:"color",
      render: (record) => <span>{record&&record}</span>,
    },
    {
      title: "Branch",
      dataIndex: "branch",
      key:"branch",
      render: (record) => <span>{record&&record}</span>,
    },
    
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div>
          <span
            onClick={(event) => {
              event.stopPropagation();
              setEditable(true)
              setVisible(true)
              setEditableData(data)
            }}
            className="linkylink"
          >
            Modify
          </span>
          {
            <Popconfirm
              title="Are you sure delete this Vehicle From Inventory?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                <Divider type="vertical" />
                  Delete
                 </span>
            </Popconfirm>
          }
        </div>
      ),
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      spinner={spinner}
      onRow={(data) => ({
        onClick: () => {
          setVisible(true)
          setEditableData(data)
        }
      })}
    />
  )
}

export default withRouter(VITable)