import React, { useState, useEffect } from 'react'
import { Card, Row, Col, Table, Empty, message, Button, Typography, Avatar } from 'antd'
import {
  EyeFilled,
  EditFilled,
} from '@ant-design/icons';
import { platformApi } from '../../../api'
import moment from 'moment'
import VehicleModal from "../../TransactionMasters/Vehicles/VehicleForm";
import './style.less' 

const VehicleInfo = (props) => {

  const { Meta } = Card;
  const { dataSource } = props
  const [selectedRowKeys, setSelectedRowKeys] = useState(null) // FOR DEFAULT SELECTION
  const [vehicleDetails, setVehicleDetails] = useState(null)
  const [modal, setModal] = useState(false)
  const [editable, setEditable] = useState(false)
  const [mdfy, setMdfy] = useState(false)

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      getVehicleDetails(selectedRowKeys);
      setSelectedRowKeys(selectedRowKeys)
    }
  };

  useEffect(() => {
    if (dataSource.length) {
      setSelectedRowKeys(dataSource[0].id)
      getVehicleDetails(dataSource[0].id);
    }
    else {
      setVehicleDetails(null)
    }
  }, [dataSource])

  const viewVehicle = () => {
    setEditable(false);
    setModal(true);
  }
  const editVehicle = () => {
    setEditable(true);
    // setMdfy(true);
    setModal(true);
  }

  const getVehicleDetails = (id = selectedRowKeys) => {
    const vehicleId = id;
    platformApi.get(`/api/vehicle/${vehicleId}`)
      .then(res => {
        const { data } = res.data.response;
        setVehicleDetails(data);
      })
  }

  const updateVehicle = (formData, vehicle) => {
    if (vehicle.id) {
      platformApi
        .put("/api/vehicle/" + vehicle.id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              message.success("Vehicle saved successfully", 2);
              getVehicleDetails();
            } else {
              message.error("Unable to save vehicle", 2);
            }
          } else {
            message.error("Unable to save vehicle", 2);
          }
        })
        .catch((error) => {
          console.error("Error on Vehicles : ", error);
          message.error("Unable to save vehicle", 2);
        });
    }
  }

  const { Text, Title } = Typography
  const column = [
    {
      title: <Text strong>Register No</Text>,
      dataIndex: "registerNo",
      key: "registerNo",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Vehicle</Text>,
      dataIndex: "vehicle",
      key: "vehicle",
      render: (record) => <span>{record ? record.modelName : "-"}</span>,
    },
  ]

  return (
    <div className="vehicle-info">
      <Card title={<Title style={{ textAlign: 'center' }} level={4}>Vehicle Info</Title>}>
        <Row type="flex" justify="space-between" gutter={[16, 16]}>
          <Col span={vehicleDetails ? 12 : 24}>
            <Table
              rowSelection={{
                type: 'radio',
                ...rowSelection,
              }}
              locale={{
                emptyText: (
                  <Empty
                    imageStyle={{
                      height: 25,
                      fontSize: 30,
                    }}
                    description={(
                      <Text disabled strong>
                        No Vehicle
                      </Text>
                    )}
                  // image={<Icon type="ContainerOutlined" />}
                  />
                ),
              }}
              rowKey={(record) => record.id}
              pagination={false}
              size="small"
              columns={column}
              dataSource={dataSource}
            />
          </Col>
          {
            (vehicleDetails) ?
              <Col span={12}>
                <Card style={{ maxHeight: '200px' }}>
                  <Row type="flex" direction="column" justify="space-between" gutter={[16, 16]}>
                    <Col span={10}>
                      <Card >
                        <span style={{ padding: '2px' }}><img src={(vehicleDetails.color) ? vehicleDetails.color.url : ""} width="80%" height="auto" alt="" /></span>
                      </Card>
                      {/* <Meta description={`Color Code:${(vehicleDetails.color) ? vehicleDetails.color.code : "-"}`} /> */}
                      <span><strong>Color Code: {(vehicleDetails.color) ? vehicleDetails.color.code : "-"}</strong></span>
                    </Col>
                    <Col span={12}>
                      <Row>{(vehicleDetails) ? <strong>{vehicleDetails.vehicle.modelName}</strong> : "-"}</Row>
                      {
                        (vehicleDetails.registorNo) ?
                          <Row><strong>Registration No: </strong>{vehicleDetails.registerNo}</Row> : null
                      }
                      {/* <Row><strong>Mfg month & year: </strong></Row> */}
                      {
                        (vehicleDetails.dateOfSale) ?
                          <Row><strong>Date of Sale: </strong>{moment(vehicleDetails.dateOfSale).format("Do MMMM YYYY")}</Row> : null
                      }
                      {
                        (vehicleDetails) ?
                          <Row type="flex" justify="end" align="bottom" gutter={[16, 16]}>
                            <Col style={{ height: '80px' }} />
                            <Col>
                              <Button onClick={() => viewVehicle()}>
                                <EyeFilled />
                              </Button>
                            </Col>
                            <Col>
                              <Button onClick={() => editVehicle()}>
                                <EditFilled />
                              </Button>
                            </Col>
                          </Row> : null
                      }
                    </Col>
                  </Row>
                </Card>
              </Col> : null
          }
        </Row>
      </Card>
      <VehicleModal
        data={vehicleDetails}
        emitData={updateVehicle}
        open={modal}
        editable={editable}
        close={() => {
          setEditable(false)
          setModal(false);
          getVehicleDetails();
        }}
        modify={!editable}
        edit={mdfy}
        setEdit={setMdfy}
      />
    </div>
  )
}

export default VehicleInfo;
