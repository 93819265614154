import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Typography, Steps, Icon, message } from "antd";
import { platformApi } from "../../../../api";

import WrappedVehicleData from "./VehicleData";
import WrappedJobData from "./JobDetails";
import WrappedObservations from "./Observations";
import "../index.less";
import WrappedVehicleDetails from "./VehicleDetails";
import WrappedOverview from "./Overview";

const { Step } = Steps;
const { Text } = Typography;

const JobOrderModal = (props) => {
  const { open, close, setEditFlag, setLoading, loading, changed, setChange } = props;
  // const [loading, setLoading] = useState(false);

  const ChildRef = useRef();
  const ClearRef = useRef();
  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({});
  const [recvData, setRecvData] = useState({});
  const [Details, setDetails] = useState([])
  const [vFlag, setVFlag] = useState(true)
  const [selectedNumber, setSelectedNumber] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [vehicleList, setVehicleList] = useState([]);
  const [numberList, setNumberList] = useState([]);
  const [chassisList, setChassisList] = useState([]);
  const [SelectedVehicle, setSelectedVehicle] = useState([]);
  const [removedCustomer, setRemovedCustomer] = useState([]);
  const [NumberData, setNumberData] = useState();
  const [vehicleData, setVehicleData] = useState();
  const [vF, setVf] = useState(false);
  const [nF, setNf] = useState(false);
  const [cF, setCf] = useState(false);
  const [update, setUpdate] = useState(false);

  useEffect(() => {
    if (props.data) setRecvData(props.data);
  }, [open]);

  useEffect(() => {
    // setRecvData({});
    // setData({});
    // Details.map(item => item)
  }, [close]);

  const icons = [
    <Icon style={{ fontSize: "1rem" }} type="car" />,
    <Icon style={{ fontSize: "1rem" }} type="appstore" />,
    <Icon style={{ fontSize: "1rem" }} type="tool" />,
  ];

  const steps = [
    // { id: 1, title: "Vehicle Details" },
    // { id: 2, title: "Observations" },
    // { id: 3, title: "Job Details" },
  ];

  const forms = [
    <WrappedVehicleDetails
      // id={props.id}
      validityRef={ChildRef}
      clearRef={ClearRef}
      data={data}
      recvData={recvData}
      open={open}
      close={close}
      editable={props.editable}
      vehicleList={vehicleList}
      setVehicleList={setVehicleList}
      numberList={numberList}
      setNumberList={setNumberList}
      setChassisList={setChassisList}
      chassisList={chassisList}
      SelectedVehicle={SelectedVehicle}
      setSelectedVehicle={setSelectedVehicle}
      setDetails={setDetails}
      Details={Details}
      vF={vF}
      setVf={setVf}
      nF={nF}
      setNf={setNf}
      setCf={setCf}
      cF={cF}
      setCurrent={setCurrent}
      current={current}
      setVFlag={setVFlag}
      setSelectedNumber={setSelectedNumber}
      selectedNumber={selectedNumber}
      selectedPhone={selectedPhone}
      setSelectedPhone={setSelectedPhone}
      setUpdate={setUpdate}
      NumberData={NumberData}
      setNumberData={setNumberData}
      setVehicleData={setVehicleData}
      vehicleData={vehicleData}
      vehicleNo={vehicleNo}
      setVehicleNo={setVehicleNo}
      changed={changed}
    // setChange={setChange}
    />,
    <WrappedOverview
      // id={props.id}
      validityRef={ChildRef}
      clearRef={ClearRef}
      data={data}
      recvData={recvData}
      open={open}
      close={close}
      editable={props.editable}
      selectedNumber={selectedNumber}
      selectedPhone={selectedPhone}
      setDetails={setDetails}
      Details={Details}
      setLoading={setLoading}
    />
    // <WrappedVehicleData
    //   id={props.id}
    //   validityRef={ChildRef}
    //   clearRef={ClearRef}
    //   data={data}
    //   recvData={recvData}
    //   open={open}
    //   close={close}
    //   editable={props.editable}
    // />,
    // <WrappedObservations
    //   id={props.id}
    //   validityRef={ChildRef}
    //   clearRef={ClearRef}
    //   data={data}
    //   recvData={recvData}
    //   open={open}
    //   close={close}
    //   editable={props.editable}
    // />,

    // <WrappedJobData
    //   id={props.id}
    //   validityRef={ChildRef}
    //   clearRef={ClearRef}
    //   data={data}
    //   recvData={recvData}
    //   open={open}
    //   close={close}
    //   editable={props.editable}
    // />,
  ];

  const clearFields = () => {
    setCurrent(0);
    setData({});
    setRecvData({});
    setSelectedVehicle([])
    setNumberList([])
    setVehicleList([])
    setChassisList([])
    setSelectedNumber(null)
    setEditFlag(false)
    setNf(false);
    setVf(false);
    setCf(false);
    setUpdate(false);
    setRemovedCustomer([]);
    setVehicleNo(null);
    setNumberData();
    setVehicleData();
    setLoading(false);
    setChange(false);
  };

  const deletecustomer = () => {
    let arr = [];
    setRemovedCustomer([selectedNumber])
    let temp = SelectedVehicle[0].customer.filter(item => {
      return item.customer.id === selectedNumber
    })
    arr.push(temp[0].id)
    let formData = new FormData();
    const Vehicle = {
      deleteCustomer: arr,
    }
    formData.append("vehicleData", JSON.stringify(Vehicle))
    platformApi
      .put('/api/vehicle/' + SelectedVehicle[0].id, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            message.success('Vehicle saved successfully', 2);
            setUpdate(false)
          } else {
            message.error('Unable to save vehicle', 2);
          }
        } else {
          message.error('Unable to save vehicle', 2);
        }
      })
      .catch((error) => {
        console.error('Error on Vehicles : ', error);
        message.error('Unable to save vehicle', 2);
      });
  }

  const handleSubmit = async () => {
    console.log("handlesubmit", data, NumberData,)

    if (data.cusId) {
      let temp = false;
      if (NumberData) {
        for (let item of NumberData) {
          if (item.customer.id === data.cusId) {
            temp = true;
            break;
          }
          else {
            temp = false;
          }
        }
        console.log("temp", temp)
        if (!temp) {
          let selectcustomer = numberList.filter(item => {
            return item.id === data.cusId
          })
          let customer = {
            id: data.cusId
          }
          data.customer = selectcustomer[0] || customer;
          let cusData = []
          NumberData.map(item => {
            cusData.push({
              id: item.id,
              customer: item.customer.id
            })
          })
          cusData.push({
            id: '',
            customer: selectedNumber
          })
          let formData = new FormData()
          const vehicleData = {
            customer: cusData
          }
          formData.append("vehicleData", JSON.stringify(vehicleData))
          await platformApi
            .put("/api/vehicle/" + SelectedVehicle[0].id, formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((result) => {
              let { data } = result;
              if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  message.success("Vehicle saved successfully", 2);
                  setUpdate(true);
                } else {
                  message.error("Unable to save vehicle", 2);
                }
              } else {
                message.error("Unable to save vehicle", 2);
              }
            })
            .catch((error) => {
              console.error("Error on Vehicles : ", error);
              message.error("Unable to save vehicle", 2);
            });
        }
      }
    }

    if (data.vehicleNo) {
      let temp = false;
      console.log("vehicleno worked")
      for (let i = 0; i < vehicleData.length; i++) {
        if (vehicleData[i].id === data.vehicleNo) {
          temp = true;
          break;
        }
        else {
          temp = false;
        }
      }
      if (!temp) {
        let selectvehicle = vehicleList.filter(item => {
          return item.id === data.vehicleNo
        })
        data.vehicle = selectvehicle[0];
        let cusData = [];
        cusData.push({
          id: '',
          customer: selectedNumber,
        })
        let formData = new FormData();
        const vehicleData = {
          customer: cusData
        }
        formData.append("vehicleData", JSON.stringify(vehicleData))
        await platformApi
          .put("/api/vehicle/" + selectvehicle[0].id, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          })
          .then((result) => {
            let { data } = result;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                message.success("Vehicle saved successfully", 2);
                setUpdate(true);
              } else {
                message.error("Unable to save vehicle", 2);
              }
            } else {
              message.error("Unable to save vehicle", 2);
            }
          })
          .catch((error) => {
            console.error("Error on Vehicles : ", error);
            message.error("Unable to save vehicle", 2);
          });
      }

    }

    // const jobNo = await platformApi.post("/api/idGenerate/jobOrder")
    //   .then((result) => {
    //     let { data } = result;
    //     if (data.code === 200) {
    //       let { response } = data;
    //       if (response.code === 200) {
    //         return response.data
    //       }
    //     }
    //   })
    // // console.log("jobNo", jobNo)

    // data.jobNo = jobNo

    data.vehicleImage = {
      frontView: data.front,
      rhsView: data.rhs,
      lhsView: data.lhs,
      rearView: data.rear,
      topView: data.top,
    };

    data.parts = {
      MirrorRH: false,
      MirrorLH: false,
      Toolkit: false,
      FirstAdKit: false
    };

    let results = [];

    data.complaintList.map((obj) => {
      if (obj.complaint) {
        let newObj = {
          complaint: obj.complaint,
          id: obj.id,
        };
        results.push(newObj);
      }
    });

    data.complaint = results;

    data.PcheckedList.map((obj) => {
      if (obj === "MirrorRH") data.parts.MirrorRH = true;
      if (obj == "MirrorLH") data.parts.MirrorLH = true;
      if (obj === "Toolkit") data.parts.Toolkit = true;
      if (obj == "FirstAdKit") data.parts.FirstAdKit = true;
    })

    data.Vehicle = {}

    props.data ? (data.id = props.data.id) : {};
    props.emitData(data);
    clearFields();
    close();

    // data.documents = {
    //   rc: false,
    //   insurance: false,
    //   pollution: false,
    //   serviceBook: false,
    // };

    // data.checkedList.map((obj) => {
    //   if (obj === "RC") data.documents.rc = true;
    //   if (obj === "Insurance") data.documents.insurance = true;
    //   if (obj === "Pollution") data.documents.pollution = true;
    //   if (obj === "Service Book") data.documents.serviceBook = true;
    // });


  };

  return (
    <Modal
      width="75%"
      wrapClassName="Job-order-modal"
      title="Add Job order"
      visible={open}
      // okText={current < 1 ? SelectedVehicle.length>1? "":"Select Vehicle" : "Save"}
      // okButtonProps={{ style: { display:"none" } }}
      // cancelButtonProps={{ style: { display: 'none' } }}
      // footer=""
      onOk={() => {
        current < 1 ? setCurrent(current + 1) : handleSubmit();
      }}
      onCancel={() => {
        // if (update) {
        //   deletecustomer();
        // }
        clearFields();
        close();
      }}

      // okButtonProps={{ disabled=loading }}
      // wrapClassName="parts-modal"
      footer={[
        <Button
          onClick={() => {
            if (current > 0) {
              setCurrent(current - 1);
            } else {
              // if (update) {
              //   deletecustomer();
              // }
              close();
              clearFields();
            }
          }}
        >
          {current > 0 ? "Previous" : "Cancel"}
        </Button>,
        SelectedVehicle.length > 1 ?
          current == 1 &&
          <Button
            disabled={loading}
            onClick={() => {
              if (ChildRef.current())
                current < 1
                  ? setCurrent(current + 1)
                  : //  editable
                  // ?
                  handleSubmit();
              // : setEditable(true);
            }}
            type="primary"
          >
            Save
          </Button>
          :
          (current < 1 || props.editable) && (
            <Button
              disabled={loading}
              onClick={() => {
                if (ChildRef.current())
                  current < 1
                    ? setCurrent(current + 1)
                    : //  editable
                    // ?
                    handleSubmit();
                // : setEditable(true);
              }}
              type="primary"
            >
              {current < 1 ? props.editable ? "Select Vehicle" : "Next" : "Save"}
            </Button>
          )
      ]}
    >
      <Steps size="small" type="navigation" current={current}>
        {/* {steps.map((step, index) => (
          <Step
            key={step.id}
            icon={icons[index]}
            title={<Text>{step.title}</Text>}
          />
        ))} */}
      </Steps>
      <div>{forms[current]}</div>
    </Modal>

  );
};

export default JobOrderModal;
