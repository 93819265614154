import React, { useState, useEffect } from 'react'
import Moment from 'moment'
import { withRouter } from 'react-router-dom'

import { Form, Row, Input, Col, Tag, Card, Select, DatePicker, TimePicker } from 'antd'

const DiscardDetails = (props) => {
    const { editable, data, setData, form } = props
    const { Option } = Select
    const { TextArea } = Input
    const { Item } = Form;

    const {
        getFieldDecorator,
        resetFields,
        getFieldValue,
        setFieldsValue,
    } = form;
    return (
        <div>
            <Row type="flex" justify="space-between" gutter={[16, 16]}>
                <Col span={12}>
                    <strong>Activity Session ID: </strong>
                    {data ? data.activityId : "-"}</Col>
                <Col span={12}>
                    <p>
                        <strong>Session Date: </strong>
                        {data && data.createdAt ? Moment(data.createdAt).format('DD-MM-YYYY') : ' -'}
                    </p>
                </Col>
                {
                    (data && !data.booking) ?
                        <Col span={12}>
                            {data && (data.discardMsg) ?
                                <strong>Interaction Type: </strong>
                                :
                                null
                            }
                            {data ? data.interactionType : "-"}
                        </Col> : null
                }
                <Col span={12}>
                    <p>
                        <strong>Session Time: </strong>
                        {data && data.createdAt ? Moment(data.createdAt).format('HH:mm') : ' -'}
                    </p>
                </Col>
                {
                    (data && data.discardMsg) ?
                        <Col span={24}>
                            <p>
                                <strong>Remarks: </strong>
                            </p>
                            <TextArea
                                rows={4}
                                onChange={event => setData({ ...data, discardMsg: event.target.value })}
                                disabled={!editable}
                                value={data ? data.discardMsg : ""} />
                        </Col> : null
                }
            </Row>
        </div>
    )
}
const WrappedDiscardDetails = Form.create({ name: "discard_details" })(
    DiscardDetails
);

export default withRouter(WrappedDiscardDetails);