import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Input,
  Typography,
  Table,
  message,
  Select,
  Row,
  Tabs,
  Mentions
} from "antd";

const { Option } = Mentions;

const { Text } = Typography;
const { TabPane } = Tabs;

import { formatValue } from "../../../../../../utils";

const ImagesModal = ({
  form,
  open,
  close,
  emitData,
  modalData,
  accessoriesData,
}) => {
  // accessoriesData.filter((item) => {
  //   if (item.category === "ACC") {
  //     console.log(item);
  //   }
  // });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalDiscount, setTotalDiscount] = useState();
  const [totalAmount, setTotalAmount] = useState();
  const [render, setRender] = useState();

  const column = [
    {
      title: "Accessory Name",
      dataIndex: "partName",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Accessory Code",
      dataIndex: "partNumber",
      key: "code",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (record, data, index) => {
        return (
          <div className="discount" style={{ display: "flex" }}>
            <Form>
              <Form.Item>
                {form.getFieldDecorator(data.id)(
                  <>
                    <Input
                      id={data.id}
                      defaultValue={data.discount}
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          [data.id]: formatValue(e, "noWithDot"),
                        })
                      }
                      onWheel={(event) => event.currentTarget.blur()}
                      onChange={(e) => {
                        setDiscountValues(e, data);
                      }}
                    ></Input>
                  </>
                )}
              </Form.Item>
            </Form>
            <Select
              defaultValue={data.isPercent ? "percent" : "amount"}
              style={{ width: 60, marginTop: "4px" }}
              onChange={(e) => {
                handleSelect(e, data);
              }}
            >
              <Option value="amount">Rs.</Option>
              <Option value="percent">%</Option>
            </Select>
          </div>
        );
      },
    },
    {
      title: "Before Discount",
      dataIndex: "mrp",
      key: "price",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "After Discount",
      dataIndex: "mrp",
      key: "price",
      render: (record, data, index) => {
        let Rs_dis = record - data.discount < 0 ? 0 : record - data.discount;
        let Pr_dis =
          record - (record * (data.discount / 100)).toFixed(2) < 0
            ? 0
            : record - (record * (data.discount / 100)).toFixed(2);
        let dis_data = data.isPercent ? Pr_dis : Rs_dis;
        return <span>{!data.discount ? record : dis_data}</span>;
      },
    },
  ];

  const handleSelect = (value, data) => {
    let index = accessoriesData.indexOf(data);
    accessoriesData[index].isPercent = value == "amount" ? false : true;

    if (accessoriesData[index].discount >= 0) {
      let sample = accessoriesData.filter((accessories) =>
        selectedRowKeys.includes(accessories.id)
      );

      let totalDiscount = 0;
      let totalAccesory = 0;
      sample.map((obj) => {
        obj.discount = isNaN(obj.discount) ? 0 : obj.discount;
        totalDiscount += obj.isPercent
          ? (obj.mrp ? obj.mrp : 1) * (obj.discount / 100)
          : obj.discount;
        totalAccesory += obj.mrp ? obj.mrp : 1;
      });

      let totDis = totalDiscount ? String(totalDiscount) : "0";
      let totAcc = accessoriesTotal ? String(totalAccesory) : "0";
      let totAccd = accessoriesTotalAfterDiscount
        ? String(totalAccesory - totalDiscount)
        : "0";

      setTotalDiscount(totDis);
      setTotalAmount(totAccd);

      // to render the state
      setRender(Math.random());
    } else {
      accessoriesData[index].discount = 0;
    }
  };
  const setDiscountValues = (event, data) => {
    // console.log("SetDiscount", event.target.value, data)
    let index = accessoriesData.indexOf(data);
    // console.log("Index", index)
    accessoriesData[index].discount = parseFloat(
      event.target.value != "" ? event.target.value : 0
    );
    // console.log("accessoriesData", accessoriesData)
    let sample = accessoriesData.filter((accessories) =>
      selectedRowKeys.includes(accessories.id)
    );

    let totalDiscount = 0;
    let totalAccesory = 0;
    sample.map((obj) => {
      obj.discount = isNaN(obj.discount) ? 0 : obj.discount;
      totalDiscount += obj.isPercent
        ? (obj.mrp ? obj.mrp : 1) * (obj.discount / 100)
        : obj.discount;
      totalAccesory += obj.mrp ? obj.mrp : 1;
    });

    let totDis = totalDiscount ? String(totalDiscount) : "0";
    let totAcc = accessoriesTotal ? String(totalAccesory) : "0";
    let totAccd = accessoriesTotalAfterDiscount
      ? String(totalAccesory - totalDiscount)
      : "0";

    setTotalDiscount(totDis);
    setTotalAmount(totAccd);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      if (selectedRowKeys.length > 0) {
        accessoriesData.map((accessory) => {
          accessory.checked = selectedRowKeys.includes(accessory.id)
            ? true
            : false;
        });
      }
      setSelectedRowKeys(selectedRowKeys);
    },
  };
  useEffect(() => {
    if (accessoriesData.length > 0) {
      let updatedAccessoryKeys = [];
      accessoriesData.map((accessory) => {
        if (accessory.checked) {
          updatedAccessoryKeys.push(accessory.id);
          form.setFieldsValue({ [accessory.id]: accessory.discount });
        }
      });
      setSelectedRowKeys(updatedAccessoryKeys);
    }

    !modalData.price?.totalDiscount
      ? setTotalDiscount(modalData.price?.totalDiscount)
      : null;
    !modalData.price?.finalAmount
      ? setTotalAmount(modalData.price?.finalAmount)
      : null;
  }, [accessoriesData]);

  const handleSubmit = () => {
    let err = false;
    form.validateFields((validationError) => {
      accessoriesData.map((data) => {
        if (
          (parseFloat(data.discount) > 100 || parseFloat(data.discount) < 0) &&
          data.checked
        )
          err = true;
      });
      if (err) {
        message.warning(
          "Please enter a valid Percentage for discount!(within 100)!"
        );
      } else {
        if (validationError === null) {
          accessoriesData = accessoriesData.filter((accessories) =>
            selectedRowKeys.includes(accessories.id)
          );
          emitData(accessoriesData);
          close();
          clearFields();
        }
      }
    });
  };

  const clearFields = () => {
    form.resetFields();
  };

  return (
    <div>
      <div>
        <Modal
          title={<Text strong>Accessories Table</Text>}
          visible={open}
          onCancel={() => {
            clearFields();
            close();
          }}
          width="60vw"
          okText="Save"
          onOk={handleSubmit}
        >
          <Tabs defaultActiveKey="1">
            <TabPane tab={<span>Accessory</span>} key="1">
              <Form>
                <br />
                <Table
                  rowSelection={rowSelection}
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: 5 }}
                  columns={column}
                  style={{ cursor: "pointer", marginTop: "1vh" }}
                  tableLayout="fixed"
                  dataSource={accessoriesData.filter((item) => {
                    return item.category === "ACC";
                  })}
                />
              </Form>
            </TabPane>
            <TabPane tab={<span>Apparel</span>} key="2">
              <Form>
                <br />
                <Table
                  rowSelection={rowSelection}
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: 5 }}
                  columns={column}
                  style={{ cursor: "pointer", marginTop: "1vh" }}
                  tableLayout="fixed"
                  dataSource={accessoriesData.filter((item) => {
                    return item.category === "APP";
                  })}
                />
              </Form>
            </TabPane>
            <TabPane tab={<span>Helmet</span>} key="3">
              <Form>
                <br />
                <Table
                  rowSelection={rowSelection}
                  rowKey={(record) => record.id}
                  pagination={{ pageSize: 5 }}
                  columns={column}
                  style={{ cursor: "pointer", marginTop: "1vh" }}
                  tableLayout="fixed"
                  dataSource={accessoriesData.filter((item) => {
                    return item.category === "HEL";
                  })}
                />
              </Form>
            </TabPane>
          </Tabs>
          <Form>
            <Row type="flex">
              <Form.Item colon={false} label={<Text>Total Discount</Text>}>
                <Input
                  addonBefore="&#8377;"
                  disabled={true}
                  value={totalDiscount}
                  placeholder="Total Discount"
                />
              </Form.Item>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <Form.Item
                colon={false}
                label={<Text>Accessories Total (after Discount)</Text>}
              >
                <Input
                  addonBefore="&#8377;"
                  // disabled={!editable}
                  disabled={true}
                  value={totalAmount}
                  placeholder="Accessories Total(after Discount)"
                />
              </Form.Item>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

const AccessoryModal = Form.create({ name: "image" })(ImagesModal);

export default AccessoryModal;
