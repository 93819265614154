import React, { useContext, useState, useEffect } from "react";
import minimize from "../../../../assets/minimize.svg"
import edit from "../../../../assets/edit.svg"
import { formatValue } from "../../../../utils"
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
  Modal,
  Form,
  Select,
  Input,
  Icon,
  InputNumber,
} from "antd";
import "./index.less";
import { platformApi } from "../../../../api";
import { range } from "lodash";
const { Title } = Typography
const { Item } = Form
const { Option } = Select

const SIForm = ({
  visible,
  setVisible,
  emitData,
  editableData,
  editable,
  setEditable,
  setEditableData,
  setDataSource,
  form
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  const [vehicleSuitable, setVehicleSuitable] = useState(null)
  const [partsConsumable, setPartsConsumable] = useState(null)
  const [searchPartsConsumable, setSearchPartsConsumable] = useState(null)
  const [jobCode, setJobCode] = useState(null)
  const [deleteData1, setDeleteData1] = useState([])
  const [deleteData2, setDeleteData2] = useState([])
  const [deleteData3, setDeleteData3] = useState([])
  const [vid, setVid] = useState([])
  const [pid, setPid] = useState([])
  const [jid, setJid] = useState([])
  const [remParts,setRemParts]=useState([])
  const [error, setError] = useState({});


  const checkQuantity = (value, type) => {
    let err = {};
    let minStock =
      type === "minStock"
        ? value
        : form.getFieldValue("minStock") &&
        parseInt(form.getFieldValue("minStock"));
    let maxStock =
      type === "maxStock"
        ? value
        : form.getFieldValue("maxStock") &&
        parseInt(form.getFieldValue("maxStock"));
    let reorder =
      type === "reorderLevel"
        ? value
        : form.getFieldValue("reorderLevel") &&
        parseInt(form.getFieldValue("reorderLevel"));

    if (minStock > maxStock)
      err = {
        ...err,
        MAXSTOCK: {
          type: "error",
          message: "Should be greater than Min Stock",
        },
      };
    else if (reorder > maxStock)
      err = {
        ...err,
        MAXSTOCK: {
          type: "error",
          message: "Should be greater than Reorder Level",
        },
      };
    else delete err.MAXSTOCK;
    if (minStock > reorder)
      err = {
        ...err,
        REORDER: {
          type: "error",
          message: "Should be greater than Min Stock",
        },
      };
    else delete err.REORDER;
    delete error.MAXSTOCK;
    delete error.REORDER;
    setError({ ...error, ...err });
  };



  const clearFields = () => {
    setVisible(false)
    setEditableData([])
    setEditable(false)
    setError({})
    form.resetFields()
  }
  const handleSubmit = () => {
    if (!editable) {
      setEditable(true)
      return
    }
    validateFields(err => {
      if(!err && Object.keys(error).length==0){
      let data;
      if (Object.keys(editableData).length>0) {
        data = {
          id: editableData.id,
          partNo: getFieldValue("partNo"),
          branch: getFieldValue("branch"),
          stockQty: getFieldValue("stockQty"),
          minStock: getFieldValue("minStock"),
          maxStock: getFieldValue("maxStock"),
          binNum: getFieldValue("binNum"),
          reorderLevel: getFieldValue("reorderLevel"),
          reorderQuantity: getFieldValue("reorderQuantity"),
        }
      }
      else {
        data = {
          partNo: getFieldValue("partNo"),
          branch: getFieldValue("branch"),
          stockQty: getFieldValue("stockQty"),
          minStock: getFieldValue("minStock"),
          maxStock: getFieldValue("maxStock"),
          binNum: getFieldValue("binNum"),
          reorderLevel: getFieldValue("reorderLevel"),
          reorderQuantity: getFieldValue("reorderQuantity"),
        }
      }
      // console.log(data)
      emitData(data)
      form.resetFields()
      setError({})
      setEditable(false)
      setEditableData([])
      setVisible(false)
    }
    })
  }


  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branch",
      key: "branch",
      render: (record) =>{
        return (
          <Item
              colon={false}
            >
              {getFieldDecorator("branch", {
                rules: [{ required: false, message: "Enter Branch" }],
              })(
                <Input
                  disabled={!editable}
                  // type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Branch"
                />
              )}
            </Item>
        )
      },
    },
    {
      title:"Bin No",
      key:"",
      render:(data)=>{
        return (
          <Item
              validateStatus={error.BIN && error.BIN.type}
              help={error.BIN && error.BIN.message}
              colon={false}
            >
              {getFieldDecorator("binNum", {
                rules: [{ required: false, message: "Enter BIN No" }],
              })(
                <Input
                  pattern="^\S*$"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        BIN: {
                          type: "error",
                          message: "Enter Valid Bin Number",
                        },
                      });
                    } else {
                      delete error.BIN;
                      setError(error);
                    }
                  }}
                  disabled={!editable}
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      binNo: formatValue(e, "toUpperCase"),
                    })
                  }
                  placeholder="BIN No"
                />
              )}
            </Item>
        )
      }

    },
    {
      title: "MinStock",
      key: "minStock",
      render: (record, data) =>{
        return (<Item colon={false}>
              {getFieldDecorator("minStock", {
                rules: [{ required: false, message: "Enter Minimum Stock" }],
              })(
                <Input
                  disabled={!editable}
                  // type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Minimum Stock"
                  onInput={(event) =>
                    checkQuantity(parseInt(event.target.value), "minStock")
                  }
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      minStock: formatValue(e, "onlyNo"),
                    })
                  }
                />
              )}
            </Item>)
      },
    },
    {
      title: "MaxStock",
      dataIndex: "maxStock",
      key: "maxStock",
      render: (record, data) =>{
        return (<Item
          colon={false}
          help={error.MAXSTOCK && error.MAXSTOCK.message}
          validateStatus={error.MAXSTOCK && error.MAXSTOCK.type}
        >
          {getFieldDecorator("maxStock", {
            rules: [{ required: false, message: "Enter Maximum Stock" }],
          })(
            <Input
              disabled={!editable}
              // type="number"
              onWheel={(event) => event.currentTarget.blur()}
              placeholder="Maximum Stock"
              onInput={(event) =>
                checkQuantity(event.target.value, "maxStock")
              }
              onKeyUp={(e) =>
                form.setFieldsValue({
                  maxStock: formatValue(e, "onlyNo"),
                })
              }
            />
          )}
        </Item>)
      },
    },
    {
      title: "Qty",
      dataIndex: "",
      key: "",
      render: () =>{
        return (
          <Item
              colon={false}
            >
              {getFieldDecorator("stockQty", {
                rules: [{ required: false, message: "Enter Stock Quantity" }],
              })(
                <Input
                  disabled={!editable}
                  // type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Stock Quantity"
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      reorder: formatValue(e, "onlyNo"),
                    })
                  }
                />
              )}
            </Item>
        )
      },
    },
    {
      title: "Reorder Level",
      dataIndex: "",
      key: "",
      render: () =>{
        return (
          <Item
          colon={false}
          help={error.REORDER && error.REORDER.message}
          validateStatus={error.REORDER && error.REORDER.type}
        >
          {getFieldDecorator("reorderLevel", {
            rules: [{ required: false, message: "Enter Reorder level" }],
          })(
            <Input
              disabled={!editable}
              // type="number"
              onWheel={(event) => event.currentTarget.blur()}
              placeholder="Reorder level"
              onInput={(event) =>
                checkQuantity(event.target.value, "reorderLevel")
              }
              onKeyUp={(e) =>
                form.setFieldsValue({
                  reorder: formatValue(e, "onlyNo"),
                })
              }
            />
          )}
        </Item>
        )
      },
    },
    {
      title: "Reorder Qty",
      dataIndex: "",
      key: "",
      render: () =>{
        return (
          <Item
              colon={false}
            >
              {getFieldDecorator("reorderQuantity", {
                rules: [{ required: false, message: "Enter Reorder Quantity" }],
              })(
                <Input
                  disabled={!editable}
                  // type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Reorder Quantity"
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      reorder: formatValue(e, "onlyNo"),
                    })
                  }
                />
              )}
            </Item>
        )
      },
    },
  ]




  return (
    <Modal
      wrapClassName="pmc-form"
      visible={visible}
      title="Add Spares"
      okButtonProps={{}}
      onCancel={() => { clearFields() }}
      onOk={handleSubmit}
      okText={editable ? "OK" : "Modify"}
    >
      <Form>
        <Row>
        <Col span={7}>
            <Item
              validateStatus={error.PART && error.PART.type}
              help={error.PART && error.PART.message}
              label="Part No"
              colon={false}
              required
            >
              {getFieldDecorator("partNo", {
                rules: [{ required: true, message: "Enter Part No" }],
              })(
                <Input
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      partNo: formatValue(e, "toUpperCase"),
                    })
                  }
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        PART: {
                          type: "error",
                          message: "Enter valid Part No.",
                        },
                      });
                      setMessage({ PART: "Enter Valid No" });
                    } 
                  }}
                  disabled={!editable}
                  placeholder="Part No"
                />
              )}
            </Item>
          </Col>
          <Col span={1}/>
          <Col span={7}>
            <Item
              validateStatus={error.PART && error.PART.type}
              help={error.PART && error.PART.message}
              label="Part Name"
              colon={false}
              required
            >
              {getFieldDecorator("partName", {
                rules: [{ required: true, message: "Enter Part Name" }],
              })(
                <Input
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      partNo: formatValue(e, "toUpperCase"),
                    })
                  }
                  disabled={!editable}
                  placeholder="Part No"
                />
              )}
            </Item>
          </Col>
        </Row>
        <Row>
        <Table
            style={{ marginTop: '1rem' }}
            columns={columns}
            tableLayout="fixed"
            dataSource={dataSource}
          />
        </Row>
      </Form>
    </Modal>
  )
}

const WrappedSIForm = Form.create({ name: "SIForm" })(SIForm);
export default WrappedSIForm