import React, { useState, useEffect, useContext } from 'react';
import { Typography, Input, Button, Select, Icon, Dropdown, message, Menu, Form } from 'antd';
import { platformApi } from '../../../api';
import { ContextAPI } from '../../../ContextAPI';
import LedgerTable from './LedgerTable';
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const LedgerDetails = () => {
    const { loginCredintials } = useContext(ContextAPI);
    const [dataSource, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [editable, setEditable] = useState(false);
    const [limit, setLimit] = useState(10);
    const [createAccess, setCreateAccess] = useState(false);
    const [modifyAccess, setModifyAccess] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');

    const getMenu = () => {
        let size = count / 10 + (count % 10 ? 1 : 0);
        let menus = [];
        menus.push(10);
        size = size <= 10 ? size : 10;
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item
                onClick={() => {
                    setLimit(menu), setPage(1);
                }}
                key={menu}
            >
                {menu}
            </Item>
        ));
    };
    const menu = <Menu>{getMenu()}</Menu>;

    useEffect(() => {
        loginCredintials.roleAccess &&
            loginCredintials.roleAccess.map((each) => {
                if (each.subModule === 'vehicle_reminder') {
                    if (each.access.create) {
                        setCreateAccess(true);
                    }
                    if (each.access.update) {
                        setModifyAccess(true);
                    }
                    if (each.access.delete) {
                        setDeleteAccess(true);
                    }
                }
            });
        setPrimaryData(search);
    }, []);
    
    useEffect(
        () => {
            setPrimaryData(search);
        }, [page, limit, search]);

    const setPrimaryData = (string, val) => {
        setSpinning(true);
        platformApi
            .post('/api/vehicle/', {
                page: val ? val : page,
                size: limit,
                searchString: string,
            })
            .then(async (result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setData(response.data.SoldVehicle);
                        setCount(response.data.count)
                        setSpinning(false);
                    }
                }
                else {
                    message.error('Unable to fetch vehicles2', 2);
                }
            })
            .catch((error) => {
                setSpinning(false);
                console.error('Error on Vehicles : ', error);
                message.error('Unable to fetch vehicles', 2);
            });
    };

    return (
        <>
            <div className="page-head">
                <Title style={{ width: '60%' }} level={4}>
                    Ledger [{count}]
					<span style={{ margin: '.5rem' }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button>
                                {limit} &nbsp;
								<Icon type="down" />
                            </Button>
                        </Dropdown>
                    </span>
                </Title>
                <Search
                    style={{ width: '30%' }}
                    onSearch={(event) => {
                        setPage(1);
                        setSearch(event);
                    }}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setSearch(null);
                            setPage(1);
                        }
                    }}
                    placeholder="Search Ledger"
                />
            </div>
            <LedgerTable
                pagination={{
                    onChange: (page) => {
                        setPage(page);
                    },
                    pageSize: limit,
                    defaultCurrent: 1,
                    current: page,
                    total: count,
                    showQuickJumper: true
                }}
                openModal={(id, editable) => {
                    setSelected(id);
                    setOpen(true);
                    setEditable(editable);
                }}
                dataSource={dataSource}
                spinner={{ spinning }}
            />

        </>
    )
}
export default LedgerDetails;