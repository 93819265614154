import React from "react";
import { Table, Divider, Popconfirm, Typography } from "antd";
const { Text } = Typography
export default function VPRTable(props) {
  const { pagination, dataSource, openModal, spinner, modify, deleteAccess } = props;

  const column = [
    {
      title: "VP Invoice",
      dataIndex: "returnInvoiceNo",
      key: "returnInvoiceNo",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Vehicle List",
      dataIndex: "returnVehicle",
      key: "list",
      render: (records) => (
        <span>
          {records.map((record, index) => (
            <span>
              {record.vehicle.modelCode} - {record.vehicle.modelName}
            </span>
          ))}
        </span>
      ),
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Supplier",
      dataIndex: "purchaseChallan",
      key: "supplier",
      render: (record) => <span>{record && record.supplier.name}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {
            deleteAccess ?
              <Popconfirm
                title="Do you want to delete this Purchase Return?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  props.delete(data.id);
                }}
              >
                <span
                  onClick={(event) => event.stopPropagation()}
                  className="linkylink"
                >
                  Delete
            </span>
              </Popconfirm>
              :
              <Text strong disabled>No Actions Given</Text>
          }

        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      style={{ cursor: "pointer" }}
      tableLayout="fixed"
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props.id, false),
      })}
      loading={spinner}
    />
  );
}
