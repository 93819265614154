import React from "react";
import { Table, Divider, Popconfirm, Button,Typography } from "antd";
import { hasId, modules } from '../../../../JSONFiles/Submodule.json'
import moment from 'moment'
const{Text}=Typography;
const IDTable = (props) => {
  const {
    pagination,
    dataSource,
    openModal,
    spinner,
    history,
    deleteData: deleteData,
    modify,
    deleteAccess
  } = props;

  const column = [
    {
      title: "Module",
      dataIndex: "subModule",
      key: "module",
      render: (record) => <span>
        {modules.filter((obj) => hasId.filter((obj) => record === obj.key)[0].id === obj.key)[0].title}
      </span>,
    },
    {
      title: "Sub Module",
      dataIndex: "subModule",
      key: "submodule",
      render: (record) => <span>
        {hasId.filter((obj) => record === obj.key)[0].title}
      </span>,
    },
    {
      title: "Sample Receipt No",
      dataIndex: "text",
      key: "text",
      render: (record, data) => {
        const receipt = record + data.startCount;
        return <span>{receipt}</span>
      },
    },
    {
      title: "Current Id",
      dataIndex: "count",
      key: "id",
      render: (record, data) => {
        const receipt = data.text + data.count;
        return <span>{receipt}</span>
      },
    },
    {
      title: "Date of Creation",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          { modify?
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(data, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
          <span></span>
    }

          <Divider type="vertical" />
        { deleteAccess?
          <Popconfirm
            title="Do you want to delete this ID?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              deleteData(data.id);
            }}
          >
            <span
              onClick={(event) => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
          :
          <span></span>
        }
        {
          !modify && !deleteAccess
          ?
          <Text strong disabled> No Access Given</Text>
          :
          <span></span>
        }
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={spinner}
      columns={column}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props, false),
      })}
    />
  );
};

export default IDTable;
