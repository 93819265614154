import React, { useState } from 'react'
import { Button, Divider } from 'antd';
import CompanySettings from "./CompanySettings"
import CompanyBranchForm from "../../../CompanyMasters/Company/CompanyBranch/CompanyBranchForm"

export default function CompanyDetails(props) {
  
  const { emitData, validityRef,branch, setBranch }=props


  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [current, setCurrent] = useState(0);
  const [modifyType, setModifyType] = useState(false);
  return (
    <div>
      <CompanySettings addBranch={setVisible}
        setDataSource={setDataSource}
        branch={branch}
        setEditable={setEditable}
        validityRef={validityRef}
        current={props.current}
        setCurrent={props.setCurrent}
        emitData={emitData} />
      <CompanyBranchForm
        visible={visible}
        setBranch={setBranch}
        setVisible={setVisible}
        setDataSource={setDataSource}
        dataSource={dataSource}
        values={dataSource.length ? dataSource[0] : values}
        editable={editable}
        setEditable={setEditable}
        setValues={setValues}
        current={current}
        setCurrent={setCurrent}
        setTableLoading={setTableLoading}
        modifyType={modifyType}
      />
    </div>
  )
}
