import React, { useState, useEffect } from "react";
import { Form, Icon, Input, Button, Checkbox, Alert, message } from "antd";

import { platformApi, setAccessToken } from "../../../api";
import "./index.less";
const SetUsingSMSLink = (props) => {
  const { form, match, history } = props
  const [loader, setLoader] = useState(false);
  const [empId, setEmpId] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token');
    platformApi.post('/api/user/checkPassword', { token: token })
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setEmpId(response.data.id)
          }
          else if(response.code===400){
            history.push('/login');
            message.error('Password already set.Try forgot password')
          }
          else {
            history.push('/login');
            message.error('Link Expired or Incorrect Link')
          }
        }
        else {
          history.push('/login');
          message.error('Link Expired or Incorrect Link')
        }
      })
  }, [])

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        const newPassword = form.getFieldValue("newPassword")
        const confirmPassword = form.getFieldValue("confirmPassword")
        if (newPassword.length < 5) {
          message.error('Password length should be minimum 6 length')
        }
        else if (newPassword !== confirmPassword) {
          message.error('Password doesn\'t match');
        }
        if (newPassword === confirmPassword && newPassword.length > 5) {
          let obj = {
            id: empId,
            password: newPassword
          }
          setLoader(true)
          platformApi.post('/api/user/updatePassword', obj)
            .then(res => {
              setLoader(false)
              let { data } = res;
              if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  history.push('/login');
                  message.success('Password Set Successfully');
                }
                else {
                  message.error('Password Not Set');
                }
              }
              else {
                message.error('Password Not Set');
              }
            })
        }
      }
      else {
        message.error('Incomplete Fields');
      }
    });
  };


  return (
    <div className="login">
      <div className="loginform">
        <div className="login__foreground">
          <div className="login__title">
            <span className="heading-form">Set Password</span>
          </div>
          <div className="login-form">
            <Form className="login-form">
              <Form.Item>
                {form.getFieldDecorator("newPassword", {
                  rules: [
                    { required: true, message: "Please type your Password!" },
                  ],
                })(
                  <Input.Password
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="New Password"
                    size="large"
                    pattern="^[0-9A-Za-z]*$"
                  />
                )}
              </Form.Item>
              <Form.Item>
                {form.getFieldDecorator("confirmPassword", {
                  rules: [
                    { required: true, message: "Please Confirm your Password!" },
                  ],
                })(
                  <Input.Password
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Confirm New Password"
                    size="large"
                    pattern="^[0-9A-Za-z]*$"
                  />
                )}
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => handleSubmit()}
                  size="large"
                  loading={loader}
                >
                  Set Password
              </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

const WrappedSetUsingOTPForm = Form.create({ name: "SetwithOTP" })(SetUsingSMSLink);
export default WrappedSetUsingOTPForm;
