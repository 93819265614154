import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  message,
  Select,
  Modal,
  Row,
  Col,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import _ from 'lodash';
import { platformApi } from '../../../../../../../api';

function Settings({ form, validityRef, data, clearRef, editable, setData }) {
  const { getFieldDecorator } = form;

  return (
    <Form style={{marginTop:'3vh'}}>
      

    </Form>

  )
}
const WrappedSettingsForm = Form.create({ name: 'SettingsForm' })(Settings);
export default WrappedSettingsForm;