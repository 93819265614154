import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Card, Button, message } from "antd";
import Text from "antd/lib/typography/Text";
import moment from "moment";
import { platformApi } from "../../../../../api";
import WrappedVehicleForm from "../../../Vehicles/VehicleForm";
const AssociatedVehicle = ({ data }) => {
  const [open, setOpen] = useState(false);
  const [dataSource, setDataSource] = useState({});

  const viewVehicle = (id) => {
    platformApi.get(`/api/vehicle/${id}`).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setDataSource(response.data);
        } else {
          message.error("Unable to fetch vehicle detail", 2);
        }
      } else {
        message.error("Unable to fetch vehicle detail", 2);
      }
    });
    setOpen(true);
  };

  console.log(data);
  return (
    <div>
      {data &&
        data.length &&
        data.map((vehicle) => {
          return (
            <Card>
              <Row>
                <Col span={8}>
                  <Card style={{ width: "250px" }}>
                    <Row>
                      {vehicle.color ? (
                        <img src={vehicle.color.url} width="200px" />
                      ) : (
                        <div></div>
                      )}
                    </Row>
                    <Row>
                      {vehicle.color ? (
                        <Text style={{ fontWeight: "bold", fontSize: "20px" }}>
                          {vehicle.color.code} - {vehicle.color.color}
                        </Text>
                      ) : (
                        <div></div>
                      )}
                    </Row>
                  </Card>
                </Col>
                <Col
                  span={16}
                  style={{ textAlign: "center", paddingTop: "5%" }}
                >
                  <Row gutter={16}>
                    {vehicle.vehicle ? (
                      <Text style={{ fontWeight: "bold", fontSize: "20px" }}>
                        {vehicle.vehicle.modelCode} -{" "}
                        {vehicle.vehicle.modelName}
                      </Text>
                    ) : (
                      <div></div>
                    )}
                  </Row>
                  <Row gutter={16}>
                    <Text>Registration No: {vehicle.registerNo}</Text>
                  </Row>
                  <Row gutter={16}>
                    <Text>Chassis No: {vehicle.chassisNo}</Text>
                  </Row>
                  <Row gutter={16}>
                    <Text>
                      Date Of Sale:{" "}
                      {vehicle.dateOfSale
                        ? moment(vehicle.dateOfSale).format("DD-MM-YYYY")
                        : null}
                    </Text>
                  </Row>
                  <Row gutter={16}>
                    <Button onClick={() => viewVehicle(vehicle.id)}>
                      View Vehicle
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Card>
          );
        })}
      <WrappedVehicleForm
        data={dataSource}
        open={open}
        editable={false}
        close={() => {
          setOpen(false);
        }}
        modify={false}
        edit={open}
        setEdit={setOpen}
        vData={true}
      />
    </div>
  );
};
export default AssociatedVehicle;
