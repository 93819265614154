import React, { useState, useEffect, useRef } from "react";
import { Modal, Button, Typography, Steps, Icon, message, Divider, Form } from "antd";
import { platformApi } from "../../../../api";
import WrappedVehicleData from "./Message";
import WrappedPaymentData from "./Configuration";
import WrappedScheduleData from "./Schedule";

const { Step } = Steps;
const { Text } = Typography;

const VehicleSaleModal = (props) => {
  const { open, close, setSelected, form, setEditFlag, setFlag } = props;
  const [data, setData] = useState({});
  const [recvData, setRecvData] = useState({});
  const [groupCount, setGroupCount] = useState(0)
  const [current, setCurrent] = useState(0);
  const [validate, setValidate] = useState(new Set())
  const [count, setCount] = useState(0)
  const [id, setId] = useState();
  const [checked, setChecked] = useState(false)
  const [credits, setCredits] = useState(false)

  useEffect(() => {
    if (props.data) {
      setRecvData(props.data);
      // setData(props.data);
    }
  }, [open]);



  useEffect(() => {
    if (!props.data) {
      platformApi.post("/api/idGenerate/promotions").then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          setId(response.data);
        } else {
          message.error("Couldn't fetch id");
        }
      });
    } else {
      setId(props.data.taskId);
    }
  }, [open]);

  useEffect(() => {
    return () => {
      clearFields();
      close();
      setCurrent(0);
    }
  }, []);



  const checkClose = () => {
    clearFields();
    close();
  };

  const clearFields = () => {
    setSelected(-1);
    setCurrent(0);
    setData({});
    setRecvData({});
  };

  const handleSubmit = () => {
    let valid = true
    for (let item of Array.from(validate)) {
      if (item() === false) {
        valid = false
      }
    }
    if (valid) {
      let sentData = {
        taskId: data.taskId,
        executionDate: data.date,
        executionTime: data.time,
        group: data.group,
        message: {
          // id: "MESS001",
          promotionName: data.name,
          campaignName: data.campaign,
          sms: data.text,
        },
        senderId: data.senderId,
        // config: { senderId: data.senderId },
        status: "Task Added",
        start: checked
      };
      setChecked(false)

      clearFields();
      props.data ? (sentData.id = props.data.id) : {};
      props.emitData(sentData);
      close();
    }
  };

  return (
    // <div style={{ backgroundColor: "white" }}>
    <div>
      {open ? (
        <div>
          {/* <Steps size="small" type="navigation" current={current}>
            {steps.map((step, index) => (
              <Step
                key={step.id}
                icon={icons[index]}
                title={<Text>{step.title}</Text>}
              />
            ))}
          </Steps> */}
          <Form>
            <div>
              {/* <Divider plain>Schedule</Divider> */}

              <WrappedScheduleData
                validate={validate}
                setValidate={setValidate}
                data={data}
                recvData={props.data}
                id={id}
                checked={checked}
                setChecked={setChecked}
                setGroupCount={setGroupCount}
                editable={props.editable}
                count={count}
                setCount={setCount}
              />
              {/* <Divider plain>Message</Divider> */}
              <WrappedVehicleData
                validate={validate}
                setValidate={setValidate}
                data={data}
                recvData={props.data}
                id={id}
                editable={props.editable}
                count={count}
                setCount={setCount}
                groupCount={groupCount}
              />
              {/* <Divider plain>Configuration</Divider> */}
              <WrappedPaymentData
                validate={validate}
                setValidate={setValidate}
                data={data}
                groupCount={groupCount}
                // recvData={recvData}
                recvData={props.data}
                id={id}
                editable={props.editable}
                count={count}
                setCount={setCount}
                setCredits={setCredits}
              />

            </div>
            <div
              style={{
                // position: "absolute",
                // marginTop: "-3%",
                // marginLeft: "70%",

                float: "right",
              }}
            >
              <Button
                style={{ marginRight: "2rem" }}
                onClick={() => {
                  clearFields();
                  close();
                  setCurrent(0);
                  setFlag(false);
                }}
              >
                Cancel
              </Button>
              <Button
                // disabled={credits}
                onClick={() => { handleSubmit() }}
                type="primary"
              >
                Save
            </Button>
            </div>
          </Form>
        </div>
      ) : (
          <></>
        )}
    </div>
  );
};

export default VehicleSaleModal;
