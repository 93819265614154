import React, { useEffect } from "react";
import moment from "moment";
import { Table,Typography } from "antd";
const{Text}=Typography;

const PromotionTable = (props) => {
  const { modifyAccess } = props;
  const column = [
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Sub Module",
      dataIndex: "submodule",
      key: "submodule",
    },
    {
      title: "Template Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdOn",
      render: (text) => (
        <span>{moment(new Date(text)).format("DD/MM/YYYY")} </span>
      ),
    },

    {
      title: "Action",
      render: (data) => {
        return (
          <div>
          {
          modifyAccess?
          <span
            onClick={(e) => {
              e.stopPropagation();
              props.openData(data.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
          <span></span>
      }
      {
        !modifyAccess
        ?
        <Text strong disabled> No Access Given</Text>
        :
        <span></span>
      }

       
         
        </div>
        );
      },
    },
  ];

  return (
    <>
      <Table
        style={{ cursor: "pointer" }}
        dataSource={props.dataSource}
        loading={props.spinning}
        pagination={props.pagination}
        rowKey={(record) => record.id}
        onRow={(record) => ({
          onClick: () => props.openData(record.id, false),
        })}
        columns={column}
      />
    </>
  );
};

export default PromotionTable;
