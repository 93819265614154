import React, { useState, useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Typography, Input, Button, Select, Form, Col, Row, DatePicker, message, Table, Radio, Popconfirm } from "antd";
import moment from "moment";
import { platformApi } from "../../../../../api";
import { getUUID } from "../../../../../_helpers/utils";
import GSTTable from "./GSTTable";
import { GST18, GST28, GST12, GST5 } from "../../../../../utils";
import "./index.less";
import WrappedCustomerAddModal from "../AddCustomer";
import WrappedCustomerUpdateModal from "../../../JobOrder/JobOrderModal/VehicleDetails/UpdateCustomer";
import { ContextAPI } from "../../../../../ContextAPI";
import { useBranch } from "../../../../../ContextAPI/branch";

const { Item } = Form;
const { Option } = Select;
const { Group } = Radio;
const { Title, Text } = Typography;
const InvoiceForm = (props) => {
	const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
	const history = useHistory();
	const location = useLocation();
	const {
		customer,
		setValidCustomer,
		form,
		id,
		editData,
		editable,
		onEdit,
		modifyType,
		setSearch,
		setPartSearch,
		partsData,
		setPartsData,
		jobCode,
		setJobCode,
		jobNo,
		setJobNo,
		initialData,
	} = props;
	const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
	const partItemData = {
		id: getUUID(),
		partNumber: {},
		hsn: {},
		quantity: "",
		unitRate: "",
		discount: "",
		rate: "",
		igst: 0,
		cgst: 0,
		sgst: 0,
		gstRate: 0,
		grandTotal: 0,
		igstAmount: 0,
		cgstAmount: 0,
		sgstAmount: 0,
		discountAmount: 0,
		discountPercent: 0,
		remove: "",
	};

	const { loginCredintials } = useContext(ContextAPI);
	const { selectBranch } = useBranch();
	const [selectedPart, setSelectedPart] = useState("");
	const [itemList, setItemList] = useState([partItemData]);
	const [selectItemObj, setSelectItemObj] = useState({});
	const [invoiceNumber, setInvoiceNumber] = useState();
	const [gst, setGst] = useState();
	const [model, setModel] = useState();
	const [invoiceDate, setInvoiceDate] = useState();
	const [customerID, setCustomer] = useState();
	const [discountLevel, setDiscountLevel] = useState();
	const [tax, setTax] = useState("Tax Inclusive");
	const [discountType, setDiscountType] = useState("At Transaction Level");
	const [quantity, setquantity] = useState();
	const [unitRate, setUnitRate] = useState();
	const [rate, setRate] = useState(0);
	const [discountAmount, setDiscountAmount] = useState(0);
	const [discount, setDiscount] = useState(0);
	const [totalDiscount, setTotalDiscount] = useState(0);
	const [grandTtl, setGrandTotal] = useState(0);
	const [adjustments, setAdjustments] = useState(0);
	const [totalInvoice, setTotalInvoice] = useState();
	const [invoiceType, setInvoiceType] = useState("counterSale");
	const [stateName, setStateName] = useState();
	const [SGST, setSGST] = useState();
	const [CGST, setCGST] = useState();
	const [IGST, setIGST] = useState();
	const [GSTRate18, setGSTRate18] = useState(false);
	const [GSTRate28, setGSTRate28] = useState(false);
	const [GSTRate12, setGSTRate12] = useState(false);
	const [GSTRate5, setGSTRate5] = useState(false);
	const [SGSTAmount, setSGSTAmount] = useState();
	const [CGSTAmount, setCGSTAmount] = useState();
	const [IGSTAmount, setIGSTAmount] = useState();
	const [TCS, setTCS] = useState(0);
	const [deleteItem, setDeleteItem] = useState(false);
	const [formSubmit, setFormSubmit] = useState(false);
	const [error, setError] = useState({});
	const [number, setNumber] = useState();
	const [invoiceNo, setInvoiceNo] = useState("");
	const [modalOpen, setModalOpen] = useState(false);
	const [modalOpen2, setModalOpen2] = useState(false);
	const [temp, setTemp] = useState(false);
	const [selected, setSelected] = useState(null);
	const [cusId, setCusId] = useState("");
	const [newCustomer, setNewCustomer] = useState(null);
	const [selectedCustomer, setSelectedCustomer] = useState();
	const [selectPart, setSelectPart] = useState();
	const [convertedTCS, setConvertedTCS] = useState();
	const [branch, setBranch] = useState({ id: "", name: "" });
	const [dataTemp, setDataTemp] = useState([]);
	const [btnFlag, setBtnFlag] = useState(false);
	const [deleteData, setDeleteData] = useState([]);
	const [pid, setPid] = useState([]);
	const [jid, setJid] = useState([]);
	const [SAC, setSAC] = useState([]);
	const [deletePartData, setDeletePartData] = useState([]);
	const [quantityValidation, setQuantityValidation] = useState({});
	const [spareInventory, setSpareInventory] = useState({});
	const [initialPartDetails, setInitialPartDetails] = useState({});
	const [updatedSpareDetails, setUpdatedSpareDetails] = useState({});
	const partsRef = {};
	// console.log("selecting", selectPart);

	let totalAdjustments = 0;
	let grandTotal = 0;
	let tcs = 0;
	let sgstAmt = 0;
	let cgstAmt = 0;
	let igstAmt = 0;

	useEffect(() => {
		if (location.state) {
			setGst(location.state.gst);
		}
	}, [location.state]);

	useEffect(() => {
		if (partsData || jobCode) {
			let temp = [];
			partsData.map((item) => {
				temp.push({
					label: `${item.partNumber} - ${item.partName}`,
					value: item.partNumber,
					id: item.id,
				});
			});
			jobCode.map((item) => {
				temp.push({
					label: item.code,
					value: item.code,
					id: item.id,
					consumable: item.consumable,
				});
			});

			setDataTemp(temp);
		}
	}, [partsData, jobCode]);

	useEffect(() => {
		console.log("editData", editData);
		if (editData) {
			setInvoiceNumber(editData.invoiceNumber || null);
			setInvoiceNo(editData.invoiceNumber || null);
			setInvoiceDate(
				editData && editData.invoiceDate
					? moment(new Date(editData.invoiceDate && editData.invoiceDate)).format("YYYY-MM-DD")
					: null
			);
			setDiscountType(editData.discountType);
			setInvoiceType(editData.invoiceType || null);
			setTax(editData.itemRate);
			setTCS(editData.tcs);
			setAdjustments(editData.adjustment);
			setCustomer(editData && editData.partyName ? editData.partyName.name + "-" + editData.partyName.contacts[0].phone : "");
			// console.log("customer", customerID)
			setNewCustomer(editData && editData.partyName?.id);
			setSelectedCustomer(editData.partyName);
			setStateName(editData && editData.partyName.GSTNo ? editData.partyName.GSTNo : "");
			if (editData.discountType === "At Transaction Level") {
				if (editData.discountPercent !== 0) {
					setDiscountAmount(editData.discountPercent);
					setDiscountLevel("percent");
				}
				if (editData.discountRate !== 0) {
					setDiscountAmount(editData.discountRate);
					setDiscountLevel("rupee");
				}
			}
			setTotalDiscount(editData.totalDiscount);
			setTotalInvoice(editData.totalInvoice);

			const updateItemList = [...editData.saleItemInvoice];

			for (let i = 0; i < updateItemList.length; i++) {
				if (updateItemList[i].jobCode) {
					updateItemList[i].partNumber = { ...updateItemList[i] };
					updateItemList[i].partNumber.code = updateItemList[i].partName;
					updateItemList[i].rate = updateItemList[i].quantity * updateItemList[i].unitRate;
					(updateItemList[i].hsn = updateItemList[i].sac), (updateItemList[i].cgst = updateItemList[i].sac.cgst);
					updateItemList[i].sgst = updateItemList[i].sac.sgst;
					updateItemList[i].igst = updateItemList[i].sac.igst;
					updateItemList[i].gstRate = updateItemList[i].sac.cgst + updateItemList[i].sac.sgst;
					updateItemList[i].gstRate = updateItemList[i].igst;

					setUnitRate(updateItemList[i].unitRate);
					if (updateItemList[i].gstRate === GST28) {
						setGSTRate28(true);
					}
					if (updateItemList[i].gstRate === GST18) {
						setGSTRate18(true);
					}
					if (updateItemList[i].gstRate === GST12) {
						setGSTRate12(true);
					}
					if (updateItemList[i].gstRate === GST5) {
						setGSTRate5(true);
					}
				} else {
					updateItemList[i].rate = updateItemList[i].quantity * updateItemList[i].unitRate;
					updateItemList[i].gstRate = updateItemList[i].igst;
					setUnitRate(updateItemList[i].unitRate);
					setSelectedPart(updateItemList[i].partName);
					if (updateItemList[i].gstRate === GST28) {
						setGSTRate28(true);
					}
					if (updateItemList[i].gstRate === GST18) {
						setGSTRate18(true);
					}
					if (updateItemList[i].gstRate === GST12) {
						setGSTRate12(true);
					}
					if (updateItemList[i].gstRate === GST5) {
						setGSTRate5(true);
					}
				}
				// updateItemList[i].rate
				// setSelectedPart(updateItemList[i].partName)
			}
			setItemList([...updateItemList, partItemData]);
			// setItemList(updateItemList);

			form.setFieldsValue({
				totalInvoice: editData.totalInvoice,
				// JobNo: editData.jobOrder && editData.jobOrder.id
			});
			console.log("updateItemList", updateItemList);
			for (let i = 0; i < updateItemList.length; i++) {
				if (updateItemList[i].partNumber.number || updateItemList[i].partNumber.hsn) {
					pid.push(updateItemList[i].partNumber.id);
				}
			}
			console.log("partId", pid);
			setPid(pid);
		}
		if (editData?.branch) {
			setBranch({ id: editData.branch.id, name: editData.branch.name });
		} else {
			setBranch({ id: selectBranch[0].id, name: selectBranch[0].name });
		}
	}, [editData]);

	useEffect(() => {
		if (initialData?.saleItemInvoice) {
			const partDetails = {};
			for (const part of initialData.saleItemInvoice) {
				if(part.partNumber == null) continue ;
				const partNumber = part.partNumber.partNumber;
				partDetails[partNumber] = part;
			}
			setInitialPartDetails(partDetails);
		}
	}, [initialData]);

	// Logs
	useEffect(() => {
		console.log({ updatedSpareDetails });
	}, [updatedSpareDetails]);

	const onModify = () => {
		onEdit(false);
		// const newItemList = [...itemList, partItemData]
		// setItemList(newItemList)
	};

	useEffect(() => {
		if (!editData) {
			platformApi.post("/api/idGenerate/salesSpares").then((res) => {
				let { data } = res;
				if (data.code === 200) {
					let { response } = data;
					setInvoiceNo(response.data);
				} else {
					// message.error("Couldn't fetch id");
				}
			});
		}
		// platformApi.get("/api/user/currentUser").then((result) => {
		//   let { data } = result;
		//   if (data.code === 200) {
		//     let { response } = data;
		//     if (response.code === 200) {
		//       setBranch(response.data.profile.branch[0].id);
		//     }
		//   }
		// });
		platformApi.get("/api/sac/").then((res) => {
			setSAC(res.data.response.data);
			console.log("SAC", res.data.response.data);
		});
		platformApi.post("/api/sparesInventory/getAllByBranch").then((res) => {
			const spareObject = {};
			for (const spare of res.data.response.data) {
				const partNumber = spare.partNo.partNumber;
				spareObject[partNumber] = spare;
			}
			setSpareInventory(spareObject);
		});
	}, []);

	useEffect(() => {
		let newItemList = [...itemList];
		if (unitRate > 0 && quantity > 0) {
			if (newItemList.length > 0) {
				for (let i = 0; i < newItemList.length; i++) {
					if (parseInt(newItemList[i].quantity) > 0 && parseInt(newItemList[i].unitRate) > 0) {
						newItemList[i].rate = (parseFloat(newItemList[i].quantity) * parseFloat(newItemList[i].unitRate)).toFixed(2);
						newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
						newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
						newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
						// console.log(newItemList[i].rate, newItemList[i].sgstAmount, newItemList[i].cgstAmount, newItemList[i].igstAmount)
						sgstAmt = newItemList[i].sgstAmount;
						cgstAmt = newItemList[i].cgstAmount;
						igstAmt = newItemList[i].igstAmount;
					}
				}
			}
			setSGSTAmount(sgstAmt);
			setIGSTAmount(igstAmt);
			setCGSTAmount(cgstAmt);
			setItemList(newItemList);
			setquantity(undefined);

			// if(selectedCustomer.GSTType==="REGISTERED"){
			//     if()
			// }
		}
	}, [unitRate, quantity]);

	useEffect(() => {
		let val = 0;
		let discountedPrice = 0;
		let newItemList = [...itemList];
		// Transaction level Dicount Calculation
		if (discountType === "At Transaction Level") {
			// for (let i = 0; i < newItemList.length; i++) {
			//     let total = parseFloat(newItemList[i].unitRate * newItemList[i].quantity)
			//     let gstRateValue = (newItemList[i].gstRate / 100) + parseInt(1);
			//     if (discountAmount > 0 && newItemList[i].unitRate > 0 && newItemList[i].quantity) {
			//         if (tax === 'Tax Exclusive') {
			//             if (discountLevel === 'percent') {
			//                 discountedPrice = (parseFloat(discountAmount) / 100) * (total);
			//                 val = val + discountedPrice
			//                 val = Math.round(val)
			//                 setTotalDiscount(val || 0);
			//             } else if (discountLevel === 'rupee') {
			//                 val = val + parseFloat(discountAmount)
			//                 setTotalDiscount(val || 0);
			//             }
			//         }
			//         else if (tax === 'Tax Inclusive') {
			//             if (discountLevel === 'percent') {
			//                 discountedPrice = parseFloat(total / gstRateValue) * (parseFloat(discountAmount) / 100);
			//                 val = val + discountedPrice
			//                 val = Math.round(val)
			//                 setTotalDiscount(val || 0);
			//             } else if (discountLevel === 'rupee') {
			//                 discountedPrice = parseFloat(total / gstRateValue) - discountAmount;
			//                 val = val + parseFloat(discountAmount)
			//                 setTotalDiscount(val || 0);
			//             }
			//         }
			//     }
			// }
			for (let i = 0; i < newItemList.length; i++) {
				let total = parseFloat(newItemList[i].unitRate * newItemList[i].quantity);
				if (total > 0) {
					grandTotal = (parseFloat(grandTotal) + parseFloat(total)).toFixed(2);
				}
			}
			if (discountLevel === "percent") {
				discountedPrice = (parseFloat(discountAmount) / 100) * grandTotal;
				val = val + discountedPrice;
				val = Math.round(val);
				setTotalDiscount(val || 0);
			} else if (discountLevel === "rupee") {
				val = val + parseFloat(discountAmount);
				setTotalDiscount(val || 0);
			}
		}
		// Item level Discount calculation
		if (discountType === "At Item Level") {
			for (let i = 0; i < newItemList.length; i++) {
				let total = newItemList[i].unitRate * newItemList[i].quantity;
				let gstRateValue = newItemList[i].gstRate / 100 + parseInt(1);
				if (newItemList[i].discount > 0) {
					// if (tax === 'Tax Exclusive') {
					//     if (discountLevel === 'percent') {
					//         discountedPrice = (parseFloat(newItemList[i].discount) / 100) * (total);
					//         val = val + discountedPrice
					//         setTotalDiscount(val || 0);
					//     } else if (discountLevel === 'rupee') {
					//         val = val + parseFloat(newItemList[i].discount)
					//         setTotalDiscount(val || 0);
					//     }
					// }
					if (tax === "Tax Inclusive") {
						if (discountLevel === "percent") {
							discountedPrice = parseFloat(total / gstRateValue) * (parseFloat(newItemList[i].discount) / 100);
							val = val + discountedPrice;
							setTotalDiscount(val || 0);
						} else if (discountLevel === "rupee") {
							discountedPrice = parseFloat(total / gstRateValue) - newItemList[i].discount;
							val = val + parseFloat(newItemList[i].discount);
							setTotalDiscount(val || 0);
						}
					}
				}
			}
		}
		setItemList(newItemList);
	}, [discountLevel, discountAmount, discountType, discount, quantity, unitRate, rate, grandTtl]);

	useEffect(() => {
		//Calucate TAX at transaction level
		if (discountType === "At Transaction Level") {
			calculateTransactionTax();
		}
		//Calucate TAX at Item level
		if (discountType === "At Item Level") {
			calculateItemTax();
		}

		//calulate TCS
		if (TCS > 0) {
			let total = form.getFieldValue("totalInvoice");
			tcs = total * (TCS / 100);
			setConvertedTCS(tcs);
			total = parseFloat(total) + parseFloat(tcs);
			total = total.toFixed(2);
			setTotalInvoice(total);
			form.setFieldsValue({
				totalInvoice: total,
			});
		}
		//calculate adjustment
		if (adjustments > 0) {
			let total = form.getFieldValue("totalInvoice");
			totalAdjustments = (parseFloat(total) + parseFloat(adjustments)).toFixed(2);
			setTotalInvoice(totalAdjustments);
			form.setFieldsValue({
				totalInvoice: totalAdjustments,
			});
		}
		if (adjustments < 0) {
			let total = form.getFieldValue("totalInvoice");
			totalAdjustments = (parseFloat(total) - -parseFloat(adjustments)).toFixed(2);
			setTotalInvoice(totalAdjustments);
			form.setFieldsValue({
				totalInvoice: totalAdjustments,
			});
		}
	}, [
		unitRate,
		quantity,
		rate,
		tax,
		TCS,
		convertedTCS,
		totalInvoice,
		adjustments,
		discountAmount,
		discount,
		grandTtl,
		discountLevel,
		discountType,
		deleteItem,
	]);

	useEffect(() => {
		if (
			itemList &&
			itemList?.length > 0 &&
			(itemList[itemList?.length - 1].partNumber.partNumber ||
				itemList[itemList?.length - 1].partNumber?.code ||
				itemList[itemList?.length - 1].quantity?.length > 0)
		) {
			handleAdd();
			setSearch("");
		}
		const selectItem = {};
		for (const item of itemList) {
			selectItem[item.partNumber.id] = item.partNumber;
		}
		setSelectItemObj(selectItem);
	}, [itemList]);

	const calculateTransactionTax = () => {
		let taxExclusive = 0;
		let taxInclusive = 0;
		let newItemList = [...itemList];
		let grandTotal = 0;
		let pCharge = 0;
		let labourCharge = 0;
		let consumableCharge = 0;
		let estTotal = 0;
		let TotalCgst = 0;
		let TotalSgst = 0;
		let TotalIgst = 0;
		let temp = 0;
		for (let i = 0; i < newItemList.length; i++) {
			if (newItemList[i].rate > 0 && newItemList[i].gstRate > 0) {
				let total = parseFloat(newItemList[i].rate);
				let gstRateValue = newItemList[i].gstRate / 100 + parseInt(1);
				if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
					taxExclusive = (total * (newItemList[i].gstRate / 100)).toFixed(2);
					if (totalDiscount > 0 && !isNaN(totalDiscount)) {
						if (discountType === "percent") {
							total = total - total * (parseFloat(discountAmount) / 100);
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
						} else {
							total = total - parseFloat(discountAmount);
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
						}
						total = total + parseFloat((newItemList[i].gstRate / 100) * total);
						setSGSTAmount(sgstAmt);
						setIGSTAmount(igstAmt);
						setCGSTAmount(cgstAmt);
						newItemList[i].grandTotal = total;
					} else {
						// total = (parseFloat(total) + parseFloat(taxExlusive)).toFixed(2);
						total = total + parseFloat((newItemList[i].gstRate / 100) * total);
						newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
						newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
						newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
						sgstAmt = newItemList[i].sgstAmount;
						cgstAmt = newItemList[i].cgstAmount;
						igstAmt = newItemList[i].igstAmount;
						setSGSTAmount(sgstAmt);
						setIGSTAmount(igstAmt);
						setCGSTAmount(cgstAmt);
						newItemList[i].grandTotal = total;
					}
				} else if (newItemList[i].partNumber.partNumber || newItemList[i].partNumber.hsn) {
					// taxInclusive = (parseFloat(total) - parseFloat(((total) / gstRateValue))).toFixed(2);
					console.log("object", total);
					if (totalDiscount > 0 && !isNaN(totalDiscount)) {
						if (discountLevel === "percent") {
							// let val1 = total / gstRateValue;
							// let val2 = (total / gstRateValue) * (parseFloat(discountAmount) / 100)
							// total = (
							//     parseFloat(val1) - parseFloat(val2)
							// )
							// total = total - parseFloat(val2)
							total =
								(total / gstRateValue - (total / gstRateValue) * (parseFloat(discountAmount) / 100)) *
									(newItemList[i].gstRate / 100) +
								(total / gstRateValue - (total / gstRateValue) * (parseFloat(discountAmount) / 100));
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
						} else {
							// total = total - parseFloat(discountAmount);
							total = total / gstRateValue - parseFloat(discountAmount);
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
						}
						console.log("total", total);
						// total = total * gstRateValue
						setSGSTAmount(sgstAmt);
						setIGSTAmount(igstAmt);
						setCGSTAmount(cgstAmt);
						newItemList[i].grandTotal = total;
					} else {
						let Fintotal = parseFloat(total);
						total = (parseFloat(total) - parseFloat(taxInclusive)).toFixed(2);
						newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * newItemList[i].rate).toFixed(2);
						newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * newItemList[i].rate).toFixed(2);
						newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * newItemList[i].rate).toFixed(2);
						sgstAmt = newItemList[i].sgstAmount;
						cgstAmt = newItemList[i].cgstAmount;
						igstAmt = newItemList[i].igstAmount;
						setSGSTAmount(sgstAmt);
						setIGSTAmount(igstAmt);
						setCGSTAmount(cgstAmt);
						// newItemList[i].grandTotal = total
						newItemList[i].grandTotal = Fintotal;
					}
				}
			}
		}

		for (let i = 0; i < newItemList.length; i++) {
			grandTotal = (parseFloat(grandTotal) + parseFloat(newItemList[i].grandTotal)).toFixed(2);
			estTotal = parseFloat(estTotal) + parseFloat(newItemList[i].grandTotal);
			TotalCgst = parseFloat(TotalCgst) + parseFloat(newItemList[i].cgstAmount);
			TotalCgst = TotalCgst.toFixed(2);
			TotalSgst = parseFloat(TotalSgst) + parseFloat(newItemList[i].sgstAmount);
			TotalSgst = TotalSgst.toFixed(2);
			TotalIgst = parseFloat(TotalIgst) + parseFloat(newItemList[i].igstAmount);
			TotalIgst = TotalIgst.toFixed(2);
			setGrandTotal(grandTotal);
			setTotalInvoice(grandTotal);
			if (newItemList[i].rate) {
				if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
					labourCharge = parseFloat(labourCharge) + parseFloat(newItemList[i].rate);
					if (newItemList[i].partNumber?.consumable || newItemList[i].jobCode?.consumable) {
						consumableCharge = parseFloat(consumableCharge) + parseFloat(newItemList[i].rate);
					}
				} else if (newItemList[i].partNumber.hsn) {
					pCharge = parseFloat(pCharge) + parseFloat(newItemList[i].rate);
				}
			}
		}
		let roundoff = grandTotal.split(".");
		if (roundoff[1] === "00") {
			temp = "0.00";
		} else if (parseInt(roundoff[1]) >= 50) {
			temp = 100 - parseInt(roundoff[1]);
			temp = `+0.${temp}`;
		} else if (parseInt(roundoff[1]) < 50) {
			temp = 100 - parseInt(roundoff[1]);
			temp = `-0.${parseInt(roundoff[1])}`;
		}
		estTotal = estTotal.toFixed(0);
		form.setFieldsValue({
			grantTotal: estTotal,
			totalInvoice: estTotal,
			partCharge: pCharge,
			labourCharge: labourCharge,
			consumableCharge: consumableCharge,
			cgst: TotalCgst,
			sgst: TotalSgst,
			igst: TotalIgst,
			roundoff: temp,
		});

		setItemList(newItemList);
	};

	const calculateItemTax = () => {
		let taxExlusive = 0;
		let taxInclusive = 0;
		let newItemList = [...itemList];
		let grandTotal = 0;
		let pCharge = 0;
		let labourCharge = 0;
		let consumableCharge = 0;
		let estTotal = 0;
		let TotalCgst = 0;
		let TotalSgst = 0;
		let TotalIgst = 0;
		let temp = 0;
		if (tax === "Tax Inclusive") {
			for (let i = 0; i < newItemList.length; i++) {
				if (newItemList[i].rate > 0 && newItemList[i].gstRate > 0) {
					let total = newItemList[i].rate;
					let gstRateValue = newItemList[i].gstRate / 100 + parseInt(1);
					if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
						taxExclusive = (total * (newItemList[i].gstRate / 100)).toFixed(2);
						if (newItemList[i].discount > 0 && !isNaN(newItemList[i].discount)) {
							if (discountType === "percent") {
								total = total - total * (parseFloat(newItemList[i].discount) / 100);
								newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
								newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
								newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
								sgstAmt = newItemList[i].sgstAmount;
								cgstAmt = newItemList[i].cgstAmount;
								igstAmt = newItemList[i].igstAmount;
							} else {
								total = total - parseFloat(newItemList[i].discount);
								newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
								newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
								newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
								sgstAmt = newItemList[i].sgstAmount;
								cgstAmt = newItemList[i].cgstAmount;
								igstAmt = newItemList[i].igstAmount;
							}
							total = total + parseFloat((newItemList[i].gstRate / 100) * total);
							setSGSTAmount(sgstAmt);
							setIGSTAmount(igstAmt);
							setCGSTAmount(cgstAmt);
							newItemList[i].grandTotal = total;
						} else {
							let Fintotal = parseFloat(total);
							total = (parseFloat(total) - parseFloat(taxInclusive)).toFixed(2);
							total = total + parseFloat((newItemList[i].gstRate / 100) * total);
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * parseFloat(newItemList[i].rate)).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
							setSGSTAmount(sgstAmt);
							setIGSTAmount(igstAmt);
							setCGSTAmount(cgstAmt);
							// newItemList[i].grandTotal = total
							newItemList[i].grandTotal = Fintotal;
						}
					} else if (newItemList[i].partNumber.partNumber || newItemList[i].partNumber.hsn) {
						taxInclusive = (parseFloat(total) - parseFloat(total / gstRateValue)).toFixed(2);
						if (newItemList[i].discount > 0 && !isNaN(newItemList[i].discount)) {
							if (discountLevel === "percent") {
								let val1 = total / gstRateValue;
								let val2 = (total / gstRateValue) * (newItemList[i].discount / 100);
								// total = (
								//     parseFloat(val1) - parseFloat(val2)
								// )
								total = total - parseFloat(val2);
								newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
								newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
								newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
								sgstAmt = newItemList[i].sgstAmount;
								cgstAmt = newItemList[i].cgstAmount;
								igstAmt = newItemList[i].igstAmount;
							} else {
								total = total - parseFloat(discountAmount);
								// total = (total / gstRateValue) - parseFloat(newItemList[i].discount)
								newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
								newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
								newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
								sgstAmt = newItemList[i].sgstAmount;
								cgstAmt = newItemList[i].cgstAmount;
								igstAmt = newItemList[i].igstAmount;
							}
							// total = total * gstRateValue
							setSGSTAmount(sgstAmt);
							setIGSTAmount(igstAmt);
							setCGSTAmount(cgstAmt);
							newItemList[i].grandTotal = total;
						} else {
							let Fintotal = parseFloat(total);
							total = (parseFloat(total) - parseFloat(taxInclusive)).toFixed(2);
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
							setSGSTAmount(sgstAmt);
							setIGSTAmount(igstAmt);
							setCGSTAmount(cgstAmt);
							newItemList[i].grandTotal = Fintotal;
							// newItemList[i].grandTotal = total
						}
					}
				}
			}
			for (let i = 0; i < newItemList.length; i++) {
				grandTotal = (parseFloat(grandTotal) + parseFloat(newItemList[i].grandTotal)).toFixed(2);
				estTotal = parseFloat(estTotal) + parseFloat(newItemList[i].grandTotal);
				TotalCgst = parseFloat(TotalCgst) + parseFloat(newItemList[i].cgstAmount);
				TotalCgst = TotalCgst.toFixed(2);
				TotalSgst = parseFloat(TotalSgst) + parseFloat(newItemList[i].sgstAmount);
				TotalSgst = TotalSgst.toFixed(2);
				TotalIgst = parseFloat(TotalIgst) + parseFloat(newItemList[i].igstAmount);
				TotalIgst = TotalIgst.toFixed(2);
				if (newItemList[i].rate) {
					if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
						labourCharge = parseFloat(labourCharge) + parseFloat(newItemList[i].rate);
						if (newItemList[i]?.partNumber?.consumable || newItemList[i].jobCode?.consumable) {
							consumableCharge = parseFloat(consumableCharge) + parseFloat(newItemList[i].rate);
						}
					} else if (newItemList[i].partNumber.hsn) {
						pCharge = parseFloat(pCharge) + parseFloat(newItemList[i].rate);
					}
				}
			}
			let roundoff = grandTotal.split(".");
			if (parseInt(roundoff[1]) >= 50) {
				temp = 100 - parseInt(roundoff[1]);
				temp = `+0.${temp}`;
			} else if (parseInt(roundoff[1]) < 50) {
				temp = 100 - parseInt(roundoff[1]);
				temp = `-0.${parseInt(roundoff[1])}`;
			}
			estTotal = estTotal.toFixed(0);
			setGrandTotal(grandTotal);
			setTotalInvoice(grandTotal);
			form.setFieldsValue({
				grantTotal: estTotal,
				totalInvoice: estTotal,
				partCharge: pCharge,
				labourCharge: labourCharge,
				consumableCharge: consumableCharge,
				cgst: TotalCgst,
				sgst: TotalSgst,
				igst: TotalIgst,
				roundoff: temp,
			});
			setItemList(newItemList);
		}

		if (tax === "Tax Exclusive") {
			for (let i = 0; i < newItemList.length; i++) {
				if (newItemList[i].rate > 0 && newItemList[i].gstRate > 0) {
					let total = parseFloat(newItemList[i].rate);
					let gstRateValue = newItemList[i].gstRate / 100 + parseInt(1);
					taxExlusive = (total * (newItemList[i].gstRate / 100)).toFixed(2);
					if (newItemList[i].discount > 0 && !isNaN(newItemList[i].discount)) {
						if (discountLevel === "percent") {
							total = total - total * (parseFloat(newItemList[i].discount) / 100);
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
						} else {
							total = total - parseFloat(newItemList[i].discount);
							newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
							newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
							newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
							sgstAmt = newItemList[i].sgstAmount;
							cgstAmt = newItemList[i].cgstAmount;
							igstAmt = newItemList[i].igstAmount;
						}
						total = total + parseFloat((newItemList[i].gstRate / 100) * total);
						setSGSTAmount(sgstAmt);
						setIGSTAmount(igstAmt);
						setCGSTAmount(cgstAmt);
						newItemList[i].grandTotal = total;
					} else {
						total = (parseFloat(total) + parseFloat(taxExlusive)).toFixed(2);
						newItemList[i].sgstAmount = parseFloat((newItemList[i].sgst / 100) * total).toFixed(2);
						newItemList[i].igstAmount = parseFloat((newItemList[i].igst / 100) * total).toFixed(2);
						newItemList[i].cgstAmount = parseFloat((newItemList[i].cgst / 100) * total).toFixed(2);
						sgstAmt = newItemList[i].sgstAmount;
						cgstAmt = newItemList[i].cgstAmount;
						igstAmt = newItemList[i].igstAmount;
						setSGSTAmount(sgstAmt);
						setIGSTAmount(igstAmt);
						setCGSTAmount(cgstAmt);
						newItemList[i].grandTotal = total;
					}
				}
			}

			for (let i = 0; i < newItemList.length; i++) {
				grandTotal = (parseFloat(grandTotal) + parseFloat(newItemList[i].grandTotal)).toFixed(2);
				TotalCgst = parseFloat(TotalCgst) + parseFloat(newItemList[i].cgstAmount);
				TotalCgst = TotalCgst.toFixed(2);
				TotalSgst = parseFloat(TotalSgst) + parseFloat(newItemList[i].sgstAmount);
				TotalSgst = TotalSgst.toFixed(2);
				TotalIgst = parseFloat(TotalIgst) + parseFloat(newItemList[i].igstAmount);
				TotalIgst = TotalIgst.toFixed(2);
				if (newItemList[i].rate) {
					if (newItemList[i].partNumber.code || newItemList[i].jobCode) {
						labourCharge = parseFloat(labourCharge) + parseFloat(newItemList[i].rate);
						if (newItemList[i].partNumber?.consumable || newItemList[i].jobCode?.consumable) {
							consumableCharge = parseFloat(consumableCharge) + parseFloat(newItemList[i].rate);
						}
					} else if (newItemList[i].partNumber.hsn) {
						pCharge = parseFloat(pCharge) + parseFloat(newItemList[i].rate);
					}
				}
			}
			setGrandTotal(grandTotal);
			setTotalInvoice(grandTotal);
			form.setFieldsValue({
				grantTotal: estTotal,
				totalInvoice: grandTotal,
				partCharge: pCharge,
				labourCharge: labourCharge,
				consumableCharge: consumableCharge,
				cgst: TotalCgst,
				sgst: TotalSgst,
				igst: TotalIgst,
			});
			setItemList(itemList);
		}
	};

	const searchParts = async (search) => {
		let res = await platformApi.post("/api/options/get/", {
			module: search.module,
			column: search.column,
			searchString: search.searchString,
			fields: search.fields,
			size: 100,
			page: 1,
			except: null,
		});
		let parts = [];
		for (let i = 0; i < res.data.response?.length; i++) {
			const element = res.data.response[i];
			parts.push(element.partNo);
		}
		search.setValue(parts);
		return 1;
		// .then((res) => {
		//     let parts = [];
		//     for (let i = 0; i < res.data.response.length; i++) {
		//         const element = res.data.response[i];
		//         parts.push(element.partNo)
		//     }
		//     // search.setValue(() => {
		//     //     return parts;
		//     // })
		//     setPartsData(parts)
		//     return parts;
		// });
	};

	const validateQuantity = (event) => {
		if (event.target.value === "") return;
		const positiveNumber = /^[1-9]\d*$/;
		console.log(event.target.value);
		if (!positiveNumber.test(event.target.value)) {
			event.target.value = 1;
		}
	};

	const renderColumns = () => {
		const columns = [
			{
				title: "Part Details",
				dataIndex: "partNumber",
				key: "partNumber",
				width: 300,
				render: (record, data, key) => (
					<Form.Item>
						<Select
							dropdownStyle={{
								overflowY: "auto",
								overflowX: "hidden",
							}}
							onChange={(event) => {
								// setPartSearch(true)
								console.log({ data });
								handlePartDetail(event, data);
							}}
							onSearch={async (event) => {
								if (event) {
									if (event.includes("oclabour") || event.includes("OCLABOUR") || event.includes("ocLabour")) {
										const newItemList = [...itemList];
										if (event !== undefined) {
											const pName = event.split("-")[1];
											for (let item of newItemList) {
												if (item.id === data.id) {
													let part = {
														id: item.id,
														code: `OCLABOUR-${pName}`,
														sac: SAC[0],
														partName: `OCLABOUR-${pName}`,
													};
													(item.partNumber = part), (item.hsn = part.sac), (item.cgst = part.sac.cgst);
													item.igst = part.sac.igst;
													item.sgst = part.sac.sgst;
													item.gstRate = part.sac.cgst + part.sac.sgst;
													let price = part?.vehicleModel?.filter((item) => item.vehicle.id === model);
													item.unitRate = price?.length ? price[0].price : 0;
													item.quantity = 1;
													setquantity(1);
													setUnitRate(price?.length ? price[0].price : 0);
													if (item.gstRate === GST18) {
														setGSTRate18(true);
													}
													if (item.gstRate === GST28) {
														setGSTRate28(true);
													}
													if (item.gstRate === GST12) {
														setGSTRate12(true);
													}
													if (item.gstRate === GST5) {
														setGSTRate5(true);
													}
													setCGST(part.sac.cgst), setSGST(part.sac.sgst), setIGST(part.sac.igst);
												}
											}
										}
										setItemList(newItemList);
										getJobCodes();
									} else {
										const search = {
											module: "sparesInventories",
											column: "partNumber",
											searchString: event,
											searchColumns: ["partNumber", "partName"],
											setValue: setPartsData,
										};
										searchParts(search);
										const searchjobCode = {
											module: "jobCodes",
											column: "code",
											searchString: event,
											searchColumns: ["code"],
											setValue: setJobCode,
										};
										searchJobCode(searchjobCode);

										if (dataTemp?.length === 1) {
											handlePartDetail(dataTemp[0].value, data);
											partsRef[key].focus();
										}
									}
								}
								checkPartQuantity(data.quantity, data.partNumber.partNumber);
							}}
							value={
								record?.partNumber == undefined
									? record?.code
										? record.code
										: record?.jobCode
										? record.jobCode.code
										: "Part Number"
									: `${record.partNumber} - ${record.partName}`
							}
							// value={
							//     (record?.partNumber == undefined) ?
							//         record?.code ? record.code
							//             : record?.jobCode
							//                 ? record.jobCode.code
							//                 : "Part Number"
							//         : record.partNumber}
							disabled={editable}
							showSearch
							showAction={["click", "focus"]}
							placeholder="Part Number/Job Code"
							onSelect={(event) => {
								handlePartDetail(event, data);
							}}
						>
							{dataTemp.length &&
								dataTemp.map((part) => {
									if (part.id in selectItemObj) return null;
									return (
										<Select.Option key={part.id} value={part.value}>
											{part.label}
										</Select.Option>
									);
								})}
						</Select>
					</Form.Item>
				),
			},
			{
				title: "HSN",
				dataIndex: "hsn",
				key: "hsn",
				width: 130,
				render: (record, index) => (
					<Form.Item>
						<span>{record && record.code}</span>
					</Form.Item>
				),
			},
			{
				title: "GST%",
				dataIndex: "gstRate",
				key: "gstRate",
				width: 120,
				render: (data, record) => (
					<Form.Item>
						<span>{record && record.gstRate}</span>
					</Form.Item>
				),
			},
			{
				title: "Quantity",
				dataIndex: "quantity",
				key: "quantity",
				width: 100,
				render: (data, record, index) => {
					const partNumber = record.partNumber.partNumber;
					return (
						<Form.Item
							validateStatus={`${quantityValidation[partNumber] ? "error" : "success"}`}
							help={quantityValidation[partNumber]}
						>
							<Input
								key="quantity"
								disabled={editable}
								onInput={(event) => validateQuantity(event)}
								onChange={(event) => {
									handlequantity(event, record);
								}}
								ref={(el) => (partsRef[index] = el)}
								placeholder="quantity"
								value={record && record.quantity}
							/>
						</Form.Item>
					);
				},
			},
			{
				title: "Unit rate",
				dataIndex: "unitRate",
				key: "unitRate",
				width: 100,
				render: (data, record, index) => (
					<Form.Item>
						<Input
							key="unitRate"
							disabled={editable}
							onChange={(event) => {
								handleUnitrate(event, record);
							}}
							placeholder="Unit Rate"
							value={record && record.unitRate}
						/>
					</Form.Item>
				),
			},
			{
				title: "Discount",
				dataIndex: "discount",
				key: "discount",
				width: 100,
				render: (data, record) => (
					<Form.Item>
						<Input
							key="discount"
							onChange={(event) => {
								handleDiscount(event, record);
							}}
							disabled={editable}
							placeholder="Discount"
							value={record && record.discount}
						/>
					</Form.Item>
				),
			},
			{
				title: "Gross Amount",
				dataIndex: "rate",
				key: "rate",
				width: 120,
				render: (data, record) => (
					<Form.Item>
						<Input key="rate" disabled={editable} placeholder="Rate" value={record && record.rate} />
					</Form.Item>
				),
			},
			{
				title: "Action",
				dataIndex: "remove",
				key: "x",
				width: 100,
				render: (data, record) => (
					<Form.Item>
						{itemList.length >= 1 && !editable ? (
							<Popconfirm
								title="Sure to delete?"
								onConfirm={() => {
									handleDelete(record.id);
								}}
							>
								<a style={{ color: "#6c7b8a" }}>Delete</a>
							</Popconfirm>
						) : null}
					</Form.Item>
				),
			},
		];

		return columns.filter((col) => (discountType === "At Transaction Level" ? col.dataIndex !== "discount" : col));
	};

	const searchJobCode = (search) => {
		platformApi
			.post("/api/options/get/", {
				module: search.module,
				column: search.column,
				searchString: search.searchString,
				fields: search.fields,
				size: 100,
				page: 1,
				except: null,
			})
			.then((res) => {
				search.setValue(() => {
					return res.data.response;
				});
			});
	};

	const validateInventory = (itemList) => {
		console.log({ itemList });
		const keys = itemList
			.map((item) => {
				if (item.partNumber.partNumber) return { quantity: item.quantity, partNumber: item.partNumber.partNumber };
			})
			.filter((item) => item != null);
		console.log({ keys });
		for (const key of keys) {
			// console.log(checkPartQuantity(key.quantity, key.partNumber));
			if (handleInventory(key.quantity, key.partNumber) === false) return false;
		}
		return true;
	};

	const handleSubmit = async () => {
		setFormSubmit(true);
		const isValid = validateInventory(itemList);
		if (!isValid) return message.error("Stock Not available in Inventory");
		form.validateFields((validationError) => {
			let totalSGSTAmt = 0;
			let totalCGSTAmt = 0;
			let totalIGSTAmt = 0;
			let newItemList = [...itemList];
			// eslint-disable-next-line for-direction
			console.log("Selected Customer", selectedCustomer.GSTType, stateName);
			for (let i = 0; i < newItemList.length; i++) {
				if (newItemList[i].sgstAmount > 0 && newItemList[i].cgstAmount > 0 && newItemList[i].igstAmount > 0) {
					totalSGSTAmt = totalSGSTAmt + parseFloat(newItemList[i].sgstAmount);
					totalCGSTAmt = totalCGSTAmt + parseFloat(newItemList[i].cgstAmount);
					totalIGSTAmt = totalIGSTAmt + parseFloat(newItemList[i].igstAmount);
				}
				newItemList[i].discountPercent = discountLevel === "percent" ? newItemList[i].discount : 0;
				newItemList[i].discountRate = discountLevel === "rupee" ? newItemList[i].discount : 0;
			}
			if (newItemList.length > 1) {
				newItemList = newItemList.filter((item) => {
					return item.partNumber && item.quantity && item.hsn && item.rate && item.igst && item.cgst && item.sgst;
					// return item.partNumber && item.hsn && item.rate && item.igst && item.cgst && item.sgst
				});
			}
			console.log("NewItemList", newItemList);
			setItemList(newItemList);
			const data = {
				partsCharge: form.getFieldValue("partCharge"),
				labourCharge: form.getFieldValue("labourCharge"),
				consumableCharge: form.getFieldValue("consumableCharge"),
				invoiceNumber: invoiceNo,
				invoiceDate: moment(Date.now()),
				invoiceType,
				jobOrder: form.getFieldValue("JobNo"),
				partyName:
					newCustomer != null ? newCustomer : modifyType ? editData && editData.partyName && editData.partyName.id : customerID,
				itemrate: tax,
				discountType,
				discountPercent: discountLevel === "percent" ? discountAmount : 0,
				discountRate: discountLevel === "rupee" ? discountAmount : 0,
				cgst:
					selectedCustomer.GSTType === "Registered"
						? stateName && stateName.slice(0, 2) === gst.slice(0, 2)
							? totalCGSTAmt
							: null
						: totalCGSTAmt,
				igst:
					selectedCustomer.GSTType === "Registered"
						? stateName && stateName.slice(0, 2) !== gst.slice(0, 2)
							? totalIGSTAmt
							: null
						: null,
				sgst:
					selectedCustomer.GSTType === "Registered"
						? stateName && stateName.slice(0, 2) === gst.slice(0, 2)
							? totalSGSTAmt
							: null
						: totalSGSTAmt,
				tcs: TCS,
				totalDiscount,
				totalInvoice: form.getFieldValue("totalInvoice") ? form.getFieldValue("totalInvoice") : totalInvoice,
				adjustments,
				saleItemInvoice: newItemList,
				branch: branch.id,
				deleteData: deleteData,
				deletePartData: deletePartData,
			};
			console.log("handleSubmit", data, editData);
			if (validationError === null) {
				setBtnFlag(true);
				if (editData && editData?.id) {
					console.log("form submitting");
					platformApi
						.put(`/api/saleSpareInvoice/${id}`, data)
						.then((result) => {
							let { data } = result;
							if (data.code === 200) {
								let { response } = data;
								if (response.code === 200) {
									message.success("SSI Modified Successfully");
								} else {
									message.error("Unable to Modify SSI");
								}
								history.push("/autoadmin/counter_sale");
							}
						})
						.catch((err) => {
							console.error("SSI Add Error: ", err);
							message.error("Couldn't update SSI");
						});
				} else {
					platformApi
						.post("/api/saleSpareInvoice/", data)
						.then((result) => {
							let { data } = result;
							if (data.code === 200) {
								let { response } = data;
								if (response.code === 200) {
									message.success("SSI Added Successfully");
								} else {
									message.error("Unable to Add SSI");
								}
								history.push("/autoadmin/counter_sale");
							}
						})
						.catch((err) => {
							message.error("Couldn't Add SSI");
						});
				}
			} else {
				message.error("select atleast one item list");
			}
		});
	};

	const handleCancel = () => {
		form.resetFields();
		setInvoiceDate(null);
		// Setflag(false);
		setInvoiceNumber("");
		setCustomer("");
		setGrandTotal(0);
		setAdjustments(0);
		setTCS(0);
		setDiscountAmount(0);
		setSelectedPart("");
		//set//stateName("");
		setGst("");
		setDeletePartData([]);
		setItemList([partItemData]);
		history.push("/autoadmin/counter_sale");
	};

	const handleAdd = () => {
		let newArr = [...itemList, partItemData];
		setItemList(newArr);
	};

	const handleDelete = (selectedId) => {
		let grandTotal = 0;
		const updatedDataSource = itemList.filter((item) => item.id !== selectedId);
		for (let i = 0; i < updatedDataSource.length; i++) {
			grandTotal = (parseFloat(grandTotal) + parseFloat(updatedDataSource[i].grandTotal)).toFixed(2);
			setGrandTotal(grandTotal);
			setTotalInvoice(grandTotal);
			form.setFieldsValue({
				grantTotal: grandTotal,
				totalInvoice: grandTotal,
			});
		}
		setItemList(updatedDataSource);
		// console.log("DeleteSource", deleteSource[0].partNumber.id)
		deleteParts(selectedId);
	};
	const deleteParts = (e) => {
		const deleteSource = itemList.filter((item) => item.id == e);
		if (pid.indexOf(e) >= 0) {
			deleteData.push(e);
		}
		console.log("deleteData", deleteData);
		setDeleteData(deleteData);

		for (let i = 0; i < deleteSource.length; i++) {
			if (deleteSource[i].partNumber.number || deleteSource[i].partNumber.hsn) {
				deletePartData.push(deleteSource[i]);
			}
		}
		setDeletePartData(deletePartData);
	};
	const handleInvoiceDate = (e) => {
		if (e) {
			const date = new Date(e._d);
			setInvoiceDate(moment(date));
		}
	};

	const handleCustomerChange = (customerID) => {
		setCustomer(customerID);
		for (let item of customer) {
			if (item.id === customerID) {
				setSelectedCustomer(item);
				setStateName(item.GSTNo);
				break;
			}
		}
		customer.map((item) => {
			if (item.id === customerID) {
				if (!item.name) {
					setTemp(true);
				}
			}
		});
	};
	const handlePartDetail = (event, data) => {
		const newItemList = [...itemList];
		setSelectedPart(event);
		if (event !== undefined) {
			partsData.map((part) => {
				if (part.partNumber === event) {
					setSelectPart(part);
					for (let item of newItemList) {
						if (item.id === data.id) {
							item.unitRate = part.unitRate;
							// setUnitRate(part.unitRate)
							(item.partNumber = part),
								(item.hsn = part.hsn),
								(item.cgst = part.hsn.cgst),
								(item.igst = part.hsn.igst),
								(item.sgst = part.hsn.sgst),
								(item.gstRate = part.hsn.cgst + part.hsn.sgst);
							item.unitRate = part.mrp;
							item.quantity = 1;
							setquantity(1);
							setUnitRate(part.mrp);
							if (item.gstRate === GST18) {
								setGSTRate18(true);
							}
							if (item.gstRate === GST28) {
								setGSTRate28(true);
							}
							if (item.gstRate === GST12) {
								setGSTRate12(true);
							}
							if (item.gstRate === GST5) {
								setGSTRate5(true);
							}
							setCGST(part.hsn.cgst), setSGST(part.hsn.sgst), setIGST(part.hsn.igst);
						}
					}
				}
			});

			jobCode.map((part) => {
				if (part.code === event) {
					for (let item of newItemList) {
						if (item.id === data.id) {
							(item.partNumber = part), (item.hsn = part.sac), (item.cgst = part.sac.cgst);
							item.igst = part.sac.igst;
							item.sgst = part.sac.sgst;
							item.gstRate = part.sac.cgst + part.sac.sgst;
							let price = part.vehicleModel.filter((item) => item.vehicle.id === model);
							item.unitRate = price.length && price[0].price;
							item.quantity = 1;
							setquantity(1);
							setUnitRate(price.length && price[0].price);
							if (item.gstRate === GST18) {
								setGSTRate18(true);
							}
							if (item.gstRate === GST28) {
								setGSTRate28(true);
							}
							if (item.gstRate === GST12) {
								setGSTRate12(true);
							}
							if (item.gstRate === GST5) {
								setGSTRate5(true);
							}
							setCGST(part.sac.cgst), setSGST(part.sac.sgst), setIGST(part.sac.igst);
						}
					}
				}
			});
		}
		setItemList(newItemList);
		getPartsDetails();
		getJobCodes();
	};
	const getJobCodes = () => {
		platformApi
			.get("/api/jobCode/")
			.then((res) => {
				const { data } = res;
				if (data.code === 200) {
					setJobCode(data.response.data);
				}
			})
			.catch((err) => {
				console.error("JobCode Fetch Error: ", err);
				message.error("Couldn't Fetch JobCodes");
			});
	};
	const getPartsDetails = () => {
		platformApi
			.post(`/api/sparesInventory/branch/get/`, {
				page: 1,
				size: 100,
				searchString: "",
			})
			.then((res) => {
				const { data } = res;
				if (data.code === 200) {
					const { response } = data;
					if (response.code === 200) {
						let parts = [];
						for (let item of response.data) {
							let unitRate = 0;
							item.transactions &&
								item.transactions.sparesPurchase.map((item2) => {
									item2.purchaseItemInvoice.map((item3) => {
										if (item.partNo.id === item3.partNumber.id) {
											unitRate += item3.unitRate;
										}
									});
								});
							item.partNo.unitRate = unitRate;
							parts.push(item.partNo);
						}
						setPartsData(parts);
					}
				} else {
					message.error("Unable to fetch Parts", 2);
				}
			})
			.catch((error) => {
				console.error("Error on Parts Master : ", error);
				message.error("Unable to fetch Parts", 2);
			});
	};

	const checkPartQuantity = (quantity, partNumber) => {
		console.log({ quantity, partNumber });
		if (Number(quantity) > spareInventory[partNumber].phyQuantity) {
			setQuantityValidation((prev) => ({
				...prev,
				[partNumber]: `Available ${spareInventory[partNumber].phyQuantity}`,
			}));
			return false;
		}
		console.log({ quantityValidation });
		setQuantityValidation((prev) => ({
			...prev,
			[partNumber]: false,
		}));
		return true;
	};

	const handleInventoryForModify = (currentQuantity, partNumber) => {
		if (currentQuantity == null || partNumber == null) return;
		const inventory = spareInventory[partNumber];
		const prevPart = initialPartDetails[partNumber];
		const removeQuantity = currentQuantity - prevPart.quantity;
		if (prevPart.quantity >= currentQuantity || inventory.phyQuantity >= removeQuantity) {
			return true;
		} else {
			const availableQuantity = inventory.phyQuantity + parseFloat(prevPart.quantity);
			setQuantityValidation((prev) => ({
				...prev,
				[partNumber]: `Available ${availableQuantity}`,
			}));
			return false;
		}
	};

	const handleInventory = (quantity, partNumber) => {
		if (quantity === "" || partNumber == null) return;
		setQuantityValidation((prev) => ({ ...prev, [partNumber]: false }));
		if (initialData && initialPartDetails[partNumber]) {
			return handleInventoryForModify(Number(quantity), partNumber);
		} else {
			return checkPartQuantity(Number(quantity), partNumber);
		}
	};

	const handlequantity = (event, record) => {
		setquantity(event.target.value);
		let newItemList = [...itemList];
		for (let item of newItemList) {
			if (item.id === record.id) {
				item.quantity = event.target.value;
				break;
			}
		}
		setItemList(newItemList);
		handleInventory(event.target.value, record.partNumber.partNumber);
	};
	const handleDiscount = (event, record) => {
		setDiscount(event.target.value);
		let newItemList = [...itemList];
		for (let item of newItemList) {
			if (item.id === record.id) {
				item.discount = event.target.value;
				break;
			}
		}
	};

	const handleUnitrate = (event, record) => {
		setUnitRate(event.target.value);
		let newItemList = [...itemList];
		for (let item of newItemList) {
			if (item.id === record.id) {
				item.unitRate = event.target.value;
				item.rate = item.quantity * item.unitRate;
				break;
			}
		}
	};

	const validateEmptyField = (value) => {
		if (value === "" || value === undefined) {
			return false;
		}
		return true;
	};

	const getNumbers = (search) => {
		platformApi
			.post("/api/options/get/", {
				module: search.module,
				column: search.column,
				searchString: search.searchString,
				fields: search.fields,
				size: 100,
				page: 1,
				except: null,
			})
			.then((res) => {
				// console.log("response", res.data.response, customer)
				if (res.data.response) {
					search.setValue(res.data.response);
				} else {
					search.setValue(customer);
				}
			});
	};

	const searchJobNo = (search) => {
		let temp = [];
		platformApi
			.post("/api/options/get/", {
				module: search.module,
				column: search.column,
				searchString: search.searchString,
				fields: search.fields,
				size: 100,
				page: 1,
				except: null,
			})
			.then((res) => {
				temp = res.data.response.filter((item) => {
					return item.jobStatus != "Invoice" || item.jobStatus != "Payment Received" || item.jobStatus != "Gate Pass";
				});
				search.setValue(temp);
			});
	};

	const getJobOrder = (value) => {
		setFieldsValue({
			JobNo: value,
		});
		let grandTotal = 0;
		let TotalCgst = 0;
		let TotalSgst = 0;
		let TotalIgst = 0;
		let pCharge = 0;
		let labourCharge = 0;
		let consumableCharge = 0;
		let estTotal = 0;
		let temp = 0;
		platformApi
			.get("/api/materialIssue/jobMaterial/" + value)
			.then((resp) => {
				let { data } = resp;
				if (data.code === 200) {
					let { response } = data;
					if (response.code === 200) {
						if (response.data) {
							let partItemList = [];
							// console.log("response.data", response.data)
							if (response.data.length && response.data[0].parts) {
								for (let i = 0; i < response.data.length; i++) {
									const element = response.data[i];
									partItemList.push(...element.parts);
								}
								console.log(partItemList);
								// partItemList = [...response.data[0].parts]
							} else {
								message.info("No Materials in this JobOrder");
							}
							for (let i = 0; i < partItemList.length; i++) {
								const item = { ...partItemList[i].part };
								if (!dataTemp.some((tempItem) => item.partNumber === tempItem.value)) {
									platformApi.get("/api/partsMaster/" + item.id).then((res) => {
										let { data } = res;
										if (data.code === 200) {
											let { response } = data;
											if (response.code === 200) {
												const temp = {
													label: `${response.data.partNumber} - ${response.data.partName}`,
													value: response.data.partNumber,
													id: response.data.id,
												};
												setDataTemp([...dataTemp, temp]);
											}
										}
									});
								}

								partItemList[i].partNumber = item;
								partItemList[i].partNumber.partName = partItemList[i].partName;
								partItemList[i].hsn = item.hsn;
								partItemList[i].cgst = item.hsn && item.hsn.cgst;
								partItemList[i].sgst = item.hsn && item.hsn.sgst;
								partItemList[i].igst = item.hsn && item.hsn.igst;
								partItemList[i].gstRate = item.hsn && item.hsn.igst;
								// partItemList[i].quantity = partItemList[i].quantity;
								partItemList[i].unitRate = partItemList[i].mrp;
								partItemList[i].rate = partItemList[i].quantity * partItemList[i].unitRate;
								partItemList[i].sgstAmount = parseFloat((partItemList[i].sgst / 100) * partItemList[i].rate).toFixed(2);
								partItemList[i].igstAmount = parseFloat((partItemList[i].igst / 100) * partItemList[i].rate).toFixed(2);
								partItemList[i].cgstAmount = parseFloat((partItemList[i].cgst / 100) * partItemList[i].rate).toFixed(2);

								setSelectedPart(item.partNumber);

								let total = parseFloat(partItemList[i].rate);
								// let finTotal = parseFloat(partItemList[i].rate)
								// let gstRateValue = (partItemList[i].gstRate / 100) + parseInt(1);
								// taxExlusive = (total * (partItemList[i].gstRate / 100)).toFixed(2);
								// total = (parseFloat(total) + parseFloat(taxExlusive)).toFixed(2);
								// partItemList[i].grandTotal = finTotal

								partItemList[i].grandTotal = total;
								if (partItemList[i].gstRate === GST28) {
									setGSTRate28(true);
								}
								if (partItemList[i].gstRate === GST18) {
									setGSTRate18(true);
								}
								if (partItemList[i].gstRate === GST12) {
									setGSTRate12(true);
								}
								if (partItemList[i].gstRate === GST5) {
									setGSTRate5(true);
								}
							}
							let updateItemList = [...partItemList];

							// console.log("updateItemList", updateItemList)
							for (let i = 0; i < updateItemList.length; i++) {
								grandTotal = parseFloat(grandTotal) + parseFloat(updateItemList[i].grandTotal);
								grandTotal = grandTotal.toFixed(2);
								estTotal = parseFloat(estTotal) + parseFloat(updateItemList[i].grandTotal);
								estTotal = estTotal.toFixed(0);
								TotalCgst = parseFloat(TotalCgst) + parseFloat(updateItemList[i].cgstAmount);
								TotalCgst = TotalCgst.toFixed(2);
								TotalSgst = parseFloat(TotalSgst) + parseFloat(updateItemList[i].sgstAmount);
								TotalSgst = TotalSgst.toFixed(2);
								TotalIgst = parseFloat(TotalIgst) + parseFloat(updateItemList[i].igstAmount);
								TotalIgst = TotalIgst.toFixed(2);

								if (updateItemList[i].rate) {
									if (updateItemList[i].partNumber.code || updateItemList[i].jobCode) {
										labourCharge = parseFloat(labourCharge) + parseFloat(updateItemList[i].rate);
										if (updateItemList[i].partNumber?.consumable || updateItemList[i].jobCode?.consumable) {
											consumableCharge = parseFloat(consumableCharge) + parseFloat(updateItemList[i].rate);
										}
									} else if (updateItemList[i].partNumber.hsn) {
										pCharge = parseFloat(pCharge) + parseFloat(updateItemList[i].rate);
									}
								}
								let roundoff = grandTotal.split(".");
								if (roundoff[1] === "00") {
									temp = "0.00";
								} else if (parseInt(roundoff[1]) >= 50) {
									temp = 100 - parseInt(roundoff[1]);
									temp = `+0.${temp}`;
								} else if (parseInt(roundoff[1]) < 50) {
									temp = 100 - parseInt(roundoff[1]);
									temp = `-0.${parseInt(roundoff[1])}`;
								}
							}
							form.setFieldsValue({
								grantTotal: estTotal,
								totalInvoice: estTotal,
								partCharge: pCharge,
								labourCharge: labourCharge,
								consumableCharge: consumableCharge,
								cgst: TotalCgst,
								sgst: TotalSgst,
								igst: TotalIgst,
								roundoff: temp,
							});
							// setGrandTotal(grandTotal);
							// setTotalInvoice(estTotal);
							setItemList([...updateItemList, partItemData]);
						}
					}
				}
			})
			.catch((error) => {
				message.error("Unable to get Materials");
				console.error("Error on get Materials : ", error);
			});
		platformApi.get("/api/jobOrder/" + value).then((res) => {
			let { data } = res;
			if (data.code === 200) {
				let { response } = data;
				if (response.code === 200) {
					if (response.data) {
						// setJobNo([...jobNo, response.data]);
						setNewCustomer(response.data.customer && response.data.customer.id);
						setCustomer(
							response.data && response.data.customer
								? response.data.customer.name + "-" + response.data.customer?.contacts[0].phone
								: ""
						);
						setSelectedCustomer(response.data && response.data.customer);
						setStateName(response.data && response.data.customer.GSTNo);
						setModel(response.data.vehicle && response.data.vehicle?.vehicle.id);
					}
				}
			}
		});
	};

	const addCustomerData = async (customer) => {
		await platformApi
			.post("/api/customer", customer)
			.then((result) => {
				const { data } = result;
				if (data.code === 200) {
					const { response } = data;
					if (response.code === 200) {
						console.log("response customer", response.data);
						setCustomer(response && response.data ? response.data.name + "-" + response.data.contacts[0].phone : "");
						setNewCustomer(response.data.id);
						setSelectedCustomer(response.data);
						message.success("Customer added successfully", 2);
					} else {
						message.error("Unable to add customer", 2);
					}
				} else if (data.code === 500 && data.err.code === 500) {
					message.error("Customer name already exists", 2);
				} else {
					message.error("Unable to add customer", 2);
				}
			})
			.catch((error) => {
				message.error("Unable to add customer", 2);
				console.error("Error on customer : ", error);
			});
	};

	const updateCustomerData = (customer) => {
		platformApi
			.put(`/api/customer/${customer.id}`, customer)
			.then((result) => {
				const { data } = result;
				if (data.code === 200) {
					const { response } = data;
					if (response.code === 200) {
						message.success("Customer Updated successfully", 2);
						console.log(response.data.id);
						// let phone = response.data.contacts[response.data.contacts.length - 1].phone
						// let str = `${response.data.name} - ${phone}`;
						setCustomer(response && response.data ? response.data.name + "-" + response.data.contacts[0].phone : "");
						setNewCustomer(response.data.id);
						setSelectedCustomer(response.data);
						// setCustomer(response.data.id)
					} else {
						message.error("Unable to update customer.", 2);
					}
				} else {
					message.error("Unable to update customer", 2);
				}
			})
			.catch((error) => {
				message.error("Unable to update customer");
				console.error("Error on Customer : ", error);
			});
	};

	const checkCustomer = () => {
		let valid = false;
		let numberDatas;
		if (customerID) {
			customer.map((item) => {
				if (item.id === customerID) {
					valid = true;
					numberDatas = item;
				}
			});
		}
		// console.log("numberDatas", numberDatas)
		if (valid) {
			if (!numberDatas.name) {
				setSelected(numberDatas);
				setModalOpen2(true);
			} else {
				setSelected(null);
				setModalOpen2(false);
			}
		}
	};

	return (
		<>
			<Form className="purchase-form">
				<Row>
					<Col span={12}>
						{selectBranch.length < 2 ? (
							<Form.Item className="purchase-form-element" label="Branch" name="branch" required disabled={true}>
								<Input placeholder="Branch Name" className="form-input" disabled={true} value={branch.name} />
							</Form.Item>
						) : (
							<Form.Item className="purchase-form-element" label="Branch" name="branch" required>
								<Select
									dropdownStyle={{
										overflowY: "auto",
										overflowX: "hidden",
									}}
									// value={editable ? editData && editData.customer.name : partyName}
									value={branch.id}
									onChange={(id) => {
										console.log({ id });
										setBranch({ id, name: "" });
									}}
									showAction={["click", "focus"]}
									showSearch
									disabled={editable}
									placeholder="Branch"
									filterOption={null}
								>
									{loginCredintials.branch.map((eachBranch) => {
										return <Select.Option key={eachBranch.id}>{eachBranch.name}</Select.Option>;
									})}
								</Select>
							</Form.Item>
						)}
					</Col>
					<Col span={12}>
						<Form.Item className="purchase-form-element" label="Invoice Date" name="invoiceDate">
							<DatePicker disabled={true} placeholder={moment(Date.now()).format("LL")} />
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={12}>
						<Form.Item
							className="purchase-form-element"
							label="Invoice Number"
							name="invoiceNumber"
							required
							validateStatus={formSubmit && (validateEmptyField(invoiceNo) ? "success" : "error")}
							help={formSubmit && !validateEmptyField(invoiceNo) && "Enter Invoice Number"}
						>
							<Input placeholder="Enter invoice number" className="form-input" disabled={true} value={invoiceNo} />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							className="purchase-form-element"
							label="Customer"
							name="customer"
							required
							validateStatus={formSubmit && (validateEmptyField(customerID) ? "success" : "error")}
							help={formSubmit && !validateEmptyField(customerID) && "Select Customer"}
						>
							<Select
								dropdownStyle={{
									overflowY: "auto",
									overflowX: "hidden",
								}}
								// value={editable ? editData && editData.customer.name : partyName}
								value={customerID}
								onChange={(value) => {
									handleCustomerChange(value);
								}}
								showAction={["click", "focus"]}
								showSearch
								disabled={editable}
								placeholder="Customer"
								filterOption={null}
								onSearch={(e) => {
									const search = {
										module: "customers",
										column: "phone",
										searchString: e,
										fields: ["contacts{phone,id,valid}"],
										searchColumns: ["contacts"],
										setValue: setValidCustomer,
									};
									getNumbers(search);
									if (e.length === 10) {
										setNumber(e);
									}
								}}
								notFoundContent={
									<Button
										type="primary"
										style={{
											justifyContent: "center",
											width: "100%",
										}}
										onClick={() => setModalOpen(true)}
									>
										Add Customer
									</Button>
								}
							>
								{customer &&
									customer.map((cus) => (
										<Select.Option key={cus.id} value={cus.id}>
											{cus.name}-{cus && cus.contacts[0] && cus.contacts[0].phone}
										</Select.Option>
									))}
							</Select>
						</Form.Item>
						{customerID && temp && customer.length > 0 ? (
							<Button type="primary" style={{ width: "40%", marginLeft: "10.5rem" }} onClick={() => checkCustomer()}>
								Edit Customer
							</Button>
						) : null}
					</Col>
				</Row>
				<Row>
					{/* <Col span={6}>
                        <Form.Item
                            className="form-element-type"
                            label="Item Rates Are"
                            name="itemRates"
                            validateStatus={formSubmit && (validateEmptyField(tax) ? 'success' : 'error')}
                            help={formSubmit && (!validateEmptyField(tax) && 'Select Item rates')}
                        >
                            <Select
                                value={tax}
                                showSearch
                                showAction={["click", "focus"]}
                                style={{ width: 200 }}
                                placeholder='Select Item Rate'
                                disabled={editable}
                                onChange={(value) => setTax(value)}
                            >
                                <Option value="Tax Inclusive">Tax Inclusive</Option>
                                <Option value="Tax Exclusive">Tax Exclusive</Option>
                            </Select>
                        </Form.Item>
                    </Col> */}
					<Col span={6}>
						<Form.Item className="form-element-type" label="Discount Type" name="dicountType">
							<Select
								showSearch
								showAction={["click", "focus"]}
								style={{ width: 200 }}
								disabled={editable}
								placeholder="Select Discount Type"
								onChange={(value) => setDiscountType(value)}
								value={discountType}
							>
								<Option value="At Transaction Level">At Transaction Level</Option>
								<Option value="At Item Level">At Item Level</Option>
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Form.Item className="form-element-type" label="Discount" name="discountLevel">
							<Group
								onChange={(e) => {
									setDiscountLevel(e.target.value);
								}}
								value={discountLevel}
								disabled={editable}
							>
								<Radio value="percent"> % </Radio>
								<Radio value="rupee"> Rs </Radio>
							</Group>
						</Form.Item>
					</Col>
					{discountType === "At Transaction Level" && (
						<Col span={6}>
							<Form.Item
								className="form-element-type"
								label={discountLevel === "percent" ? "Discount Percent" : "Discount Rupee"}
								name="discountAmount"
								onChange={(e) => setDiscountAmount(e.target.value)}
								rules={[{}]}
							>
								<Input
									disabled={editable}
									placeholder={discountLevel === "percent" ? "Discount Percent" : "Discount Rupee"}
									value={discountType === "At Transaction Level" && discountAmount !== 0 ? discountAmount : discountAmount}
								/>
							</Form.Item>
						</Col>
					)}
				</Row>
				<Table
					className="parts-invoice-table"
					rowKey={(record) => {
						record.id;
					}}
					columns={renderColumns()}
					dataSource={itemList}
					tableLayout="fixed"
					pagination={false}
					style={{ marginTop: "1rem", marginBottom: "1rem" }}
				/>
				<br />
				<Row gutter={16}>
					<Col span={7}>
						<Form.Item className="form-element" label="Labour Charge">
							{getFieldDecorator("labourCharge", {
								rules: [
									{
										required: false,
										message: "Enter Labour Charge",
									},
								],
							})(<Input placeholder="Labour Charge" disabled={true} />)}
						</Form.Item>
						<Form.Item className="form-element" label="CGST">
							{getFieldDecorator("cgst", {
								rules: [
									{
										required: false,
										message: "Enter  CGST",
									},
								],
							})(<Input placeholder="CGST" disabled={true} />)}
						</Form.Item>
						{/* <Form.Item
                            className="form-element"
                            label="Total Discount"
                        >
                            {
                                getFieldDecorator("totalDiscount", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Total Discount"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Total Discount"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item> */}
					</Col>
					<Col span={10}>
						<Form.Item className="form-element" label="Consumable Charge">
							{getFieldDecorator("consumableCharge", {
								rules: [
									{
										required: false,
										message: "Enter Consumable Charge",
									},
								],
							})(<Input placeholder="Consumable Charge" disabled={true} />)}
						</Form.Item>
						<Form.Item className="form-element" label="SGST">
							{getFieldDecorator("sgst", {
								rules: [
									{
										required: false,
										message: "Enter SGST",
									},
								],
							})(<Input placeholder="SGST" disabled={true} />)}
						</Form.Item>
						{/* <Form.Item
                            className="form-element"
                            label="Round Off"
                        >
                            {
                                getFieldDecorator("roundoff", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Roundoff"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Round Off"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item> */}
					</Col>
					<Col span={6}>
						<Form.Item className="form-element" label="Part Charge">
							{getFieldDecorator("partCharge", {
								rules: [
									{
										required: false,
										message: "Enter Part Charge",
									},
								],
							})(<Input placeholder="Part Charge" disabled={true} />)}
						</Form.Item>
					</Col>
				</Row>
				<Row>
					<Col span={8}>
						<Form.Item className="form-element" label="Total Discount" name="totalDiscount" rules={[{}]}>
							<Input placeholder="Total Discount" value={totalDiscount} disabled={true} />
						</Form.Item>
					</Col>
					<Col span={8}>
						<div className="gst-table">
							<Title level={4} className="gst-title">
								GST Data
							</Title>
							<GSTTable
								gst={gst}
								SGST={SGST}
								CGST={CGST}
								IGST={IGST}
								itemList={itemList}
								GSTRate18={GSTRate18}
								GSTRate28={GSTRate28}
								GSTRate5={GSTRate5}
								GSTRate12={GSTRate12}
								stateName={stateName}
								selectedCustomer={selectedCustomer}
							/>
						</div>
					</Col>
					<Col span={8}>
						<Form.Item className="form-element" label="Grand Total" name="grandTotal">
							{getFieldDecorator("grantTotal", {
								rules: [{ required: false, message: "" }],
							})(
								<Input
									disabled={editable}
									placeholder="Grand Total"
									// value={grandTtl}
								/>
							)}
						</Form.Item>
						<Form.Item className="form-element" label="TCS" name="tcs" onInput={(e) => setTCS(e.target.value)} rules={[{}]}>
							<Input style={{ width: "80px" }} disabled={editable} placeholder="TCS (%)" value={TCS} />
							<Input style={{ width: "80px", marginLeft: "1rem" }} disabled={true} placeholder="TCS" value={convertedTCS} />
						</Form.Item>
						<Form.Item
							className="form-element"
							label="Adjustments"
							name="adjustments"
							onInput={(e) => setAdjustments(e.target.value)}
							rules={[{}]}
						>
							<Input disabled={editable} placeholder="Adjustment" value={adjustments} />
						</Form.Item>
						<Form.Item className="form-element" label="Total Invoice" name="totalInvoice" rules={[{}]}>
							{getFieldDecorator("totalInvoice", {
								rules: [{ required: false, message: "" }],
							})(
								<Input
									disabled={editable}
									placeholder="Total Invoice"
									// value={totalInvoice}
								/>
							)}
						</Form.Item>
					</Col>
				</Row>
				<div style={{ float: "right" }}>
					<Button style={{ marginRight: "1rem" }} size="large" onClick={() => handleCancel()}>
						Cancel
					</Button>
					<Button type="primary" size="large" disabled={btnFlag} onClick={() => (!editable ? handleSubmit() : onModify())}>
						{!editable ? "Save" : "Modify"}
					</Button>
				</div>
			</Form>
			<WrappedCustomerAddModal
				emitData={(data) => addCustomerData(data)}
				open={modalOpen}
				cusId={cusId}
				setCusId={setCusId}
				number={number}
				close={() => {
					setNumber("");
					// setSelected(null);
					setModalOpen(false);
				}}
				editable={true}
			/>
			<WrappedCustomerUpdateModal
				emitData={(data) => updateCustomerData(data)}
				open={modalOpen2}
				cusId={cusId}
				setCusId={setCusId}
				close={() => {
					setSelected(null);
					setModalOpen2(false);
				}}
				editable={true}
				setData={setSelected}
				data={selected}
			/>
		</>
	);
};

const WrappedInvoiceForm = Form.create()(InvoiceForm);

export default WrappedInvoiceForm;
