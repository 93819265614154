import React from 'react'
import nandi from '../../../../../assets/Logo.png'
import yamaha from "../../../../../assets/yamaha-logo.png"
import nandiyamaha from "../../../../../assets/Logo.png"
import '../index.less'
import {
    Form,
    Select,
    Typography,
    Row,
    Col,
    Input,
    Popconfirm,
    Divider,
    Upload,
    Icon,
    Modal,
    Checkbox,
    message,
} from "antd";
const StatusHeader = () => {
    return (
        <div className="statusHeader">
                <img src={nandiyamaha}  alt="" /><img src={yamaha} />
        </div>
    )
}
export default StatusHeader;