import React, { useState, useRef, useEffect } from "react";
import { Modal, Button, Steps, Icon, Row, Col } from "antd";

import "./index.less";
import ImagesForm from "./ImagesForm";
import PriceForm from "./PriceForm";
import { platformApi } from "../../../../../../api";

const { Step } = Steps;

const VehicleModal = ({
  open,
  close,
  editable,
  emitData,
  data: Data,
  dataColor,
}) => {
  const validityRef = useRef();
  const [data, setData] = useState({});

  const [current, setCurrent] = useState(0);

  useEffect(() => {
    if (open) {
      if (Data) {
        // console.log("Data", Data)
        setData({ ...Data });
      }
    }
  }, [open]);

  const clearFields = () => {
    setData({});
    setCurrent(0);
    close();
  };

  const handleSubmit = () => {
    data.id = "";
    console.log("Handle Submit ", data)
    emitData(data);
    clearFields();
  };

  const modalFooter = [
    <Button key={true}
      onClick={() => {
        if (current > 0) setCurrent(current - 1);
        else clearFields();
      }}
    >
      {editable ? (current < 1 ? "Cancel" : "Back") : "Close"}
    </Button>,
    editable && (
      <Button
        onClick={() => {
          if (validityRef.current()) {
            if (current < 1) setCurrent(current + 1);
            else handleSubmit();
          }
        }}
        type="primary"
      >
        {current > 0 ? "Save" : "Next"}
      </Button>
    ),
  ];

  const Forms = [
    {
      title: "Color",
      Component: (
        <ImagesForm
          data={data}
          dataColor={dataColor}
          validityRef={validityRef}
          open={open}
        />
      ),
    },
    {
      title: "Price",
      Component: (
        <PriceForm
          validityRef={validityRef}
          editable={editable}
          open={open}
          data={data}
        />
      ),
    },
  ];

  return (
    <Modal
      title="Select Vehicle Color"
      visible={open}
      onCancel={clearFields}
      wrapClassName="Vehicle-Select-Modal"
      footer={modalFooter}
    >
      {editable ? (
        <Col>
          <Row type="flex" justify="center">
            <Col span={8}>
              <Steps size="small" current={current} type="navigation">
                {Forms.map((form, index) => (
                  <Step
                    key={index}
                    icon={<Icon type="" />}
                    title={form.title}
                  />
                ))}
              </Steps>
            </Col>
          </Row>
          <Row>{Forms[current].Component}</Row>
        </Col>
      ) : (
          <PriceForm
            data={data}
            editable={editable}
            validityRef={validityRef}
            open={open}
          />
        )}
    </Modal>
  );
};

export default VehicleModal;
