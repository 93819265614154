import React, { useState, useEffect } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import {
    Modal,
    Select,
    Form,
    Row,
    Col,
    Typography,
    message,
    Button,
} from "antd";
import { platformApi } from "../../../../api";
import './index.less'

const { Text, Title } = Typography;
const { Option } = Select;
const MechanicAssign = ({
    form,
    open,
    close,
    data,
    mechanic,
    setMechanic,
    editData,
    editable,
    modify,
}) => {
    const history = useHistory();
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [error, setError] = useState({});
    const [mcid, setMcid] = useState('');
    //Edit Data
    useEffect(() => {
        if (data) {
            console.log("editData", data)
            if (data.mechanic) {
                setFieldsValue({
                    mechanic: data.mechanic && data.mechanic.id,
                })
                setMcid(data.mechanic && data.mechanic.id)
            }
        }

    }, [data]);
 
    const handleMechanic = (event) => {
        setMcid(event)
        setFieldsValue({
            mechanic: event
        })
    }
    const handleSubmit = () => {
        form.validateFields(async (validationError) => {
            if (validationError === null) {
                if (data) {
                    console.log("submit", data.id, getFieldValue("mechainc"), mcid)
                    platformApi
                        .put(`/api/jobOrder/setMech/${data.id}`, {
                            mechanic: getFieldValue("mechainc") ? getFieldValue("mechainc") : mcid
                        })
                        .then((res) => {
                            let { data } = res;
                            if (data.code === 200) {
                                let { response } = data;
                                if (response.code === 200) {
                                    message.success("Job Order Updated successfully", 2);
                                    close();
                                } else {
                                    message.error("Unable to update Job Order", 2);
                                }
                            } else {
                                message.error("Unable to update Job Order", 2);
                            }
                        })
                        .catch((err) => {
                            message.error("Unable to update Job Order", 2);
                            console.error("Error on Job Order : ", err);
                        });
                }
            }
        });
    };
    const clearFieldsAndClose = () => {
        form.resetFields();
        close();
        history.push('/autoadmin/job_order');
    };
    return (
        <Modal
            title={<Title level={4}>Mechanic Assign</Title>}
            visible={open}
            onCancel={clearFieldsAndClose}
            okText={!editable ? "Modify" : "Save"}
            okButtonProps={{ disabled: (!editable && !modify) }}
            onOk={() => (editable ? handleSubmit() : setEditable(true))}
        >
            <Form className="purchase-form">
                <Row>
                    <Col span={12}>
                        <Text strong>
                            JOB NO&nbsp;&nbsp;&nbsp;: {data && data.jobNo}
                        </Text>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between">
                    <Col span={24}>
                        <Form.Item
                            className="purchase-form-element"
                            colon={false}
                            required
                            validateStatus={error.MECHANIC && error.MECHANIC.type}
                            help={error.MECHANIC && error.MECHANIC.message}
                            label={<Text>Mechanic</Text>}
                            colon={false}
                        >
                            {getFieldDecorator("mechanic", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Mechanic",
                                    },
                                ],
                            })(
                                <Select
                                    dropdownStyle={{
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                    showSearch
                                    showAction={["click", "focus"]}
                                    disabled={!editable}
                                    onChange={(event) => {
                                        handleMechanic(event)
                                    }}
                                    placeholder="Mechanic"
                                >
                                    {mechanic.length && mechanic.map((obj) => (
                                        <Select.Option key={obj.id} value={obj.id}>
                                            {obj.profile && obj.profile.employeeName ? obj.profile.employeeName : obj.profile.employeeId}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const WrappedMechanicAssign = Form.create("mechanic_assign")(
    MechanicAssign
);

export default WrappedMechanicAssign;