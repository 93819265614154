import React, { useEffect, useState } from 'react'
import JobCardDashBoard from './JobCardDashboard'
import JobInvoiceReport from './JobInvoiceReport'
import ServiceRem from './ServiceRem/ServiceRem'
var moment = require("moment");



const ReportDashboard = () => {

    const [timeline, setTimeline] = useState("today")
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [showDate, setShowDate] = useState([])

    useEffect(() => {
        const { fromDate, toDate } = DateShow(timeline);

        if (timeline === "date") {
            setShowDate([]);
            setFromDate()
            setToDate()
        } else {
            let arr = [];
            arr.push(fromDate);
            arr.push(toDate)
            setShowDate(arr)
        }

    }, [timeline])

    const handleTimeline = (e) => {
        if (e !== "date") {
            const { fromDate, toDate } = DateShow(e)
            let arr = [];
            arr.push(fromDate);
            arr.push(toDate)
            setShowDate(arr)
        }
        else if (e === "date") {
            setShowDate([]);
            setFromDate(null)
            setToDate(null)
        }
        setTimeline(e)
    }

    const handleDateChange = async (date, dateString) => {
        await setFromDate(dateString[0])
        await setToDate(dateString[1])
        // getData(timeline, selectedEmployee)
    }


    const DateShow = (timeline, from, to) => {
        let toDate;
        let fromDate;
        let months;

        switch (timeline) {
            case "today":
                fromDate = moment(new Date()).startOf("day");
                toDate = moment(new Date()).endOf("day");
                months = [moment(new Date()).format("MMM")]
                break;
            case "week":
                fromDate = moment(new Date()).startOf("week");
                toDate = moment(new Date()).endOf("week");
                months = [moment(new Date()).format("MMM")]
                break;
            case "month":
                fromDate = moment(new Date()).startOf("month");
                toDate = moment(new Date()).endOf("month");
                months = [moment(new Date()).format("MMM")]
                break;
            case "quarterly":
                fromDate = moment(new Date()).startOf("quarter");
                toDate = moment(new Date()).endOf("quarter");
                months = [fromDate.format("MMM"), moment(fromDate).add(1, "months").format("MMM"), moment(toDate).format("MMM")]
                break;
            case "halfyearly":
                let tempDate;
                let tempEnd;
                if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") > 3 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") < 7
                ) {
                    tempDate = moment(new Date())
                        .subtract(3, "M")
                        .startOf("quarter");
                } else if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") > 9 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") <= 12
                ) {
                    tempDate = moment(new Date())
                        .subtract(3, "M")
                        .startOf("quarter");
                } else {
                    tempDate = moment(new Date()).startOf("quarter");
                }
                if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") >= 1 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") < 4
                ) {
                    tempEnd = moment(new Date())
                        .add(3, "M")
                        .endOf("quarter");
                } else if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") > 6 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") <= 9
                ) {
                    tempEnd = moment(new Date())
                        .add(3, "M")
                        .endOf("quarter");
                } else {
                    tempEnd = moment(new Date()).endOf("quarter");
                }
                fromDate = tempDate;
                toDate = tempEnd;
                months = [
                    fromDate.format("MMM"),
                    moment(fromDate).add(1, "months").format("MMM"),
                    moment(fromDate).add(2, "months").format("MMM"),
                    moment(fromDate).add(3, "months").format("MMM"),
                    moment(fromDate).add(4, "months").format("MMM"),
                    moment(toDate).format("MMM")
                ]
                break;
            case "year":
                fromDate = moment(new Date()).startOf("year");
                toDate = moment(new Date()).endOf("year");
                months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                break;
            case "date":
                fromDate = moment(from, "DD-MM-YYYY").startOf("day");
                toDate = moment(to, "DD-MM-YYYY").endOf("day");
                let difference = toDate.diff(fromDate, 'months')
                if (difference == 0) {
                    months = [moment(fromDate).format("MMM")]
                }
                else if (difference == 1) {
                    months = [moment(fromDate).format("MMM"), moment(toDate).format("MMM")]
                }
                else {
                    months = [moment(fromDate).format("MMM")]
                    for (let i = 0; i < difference; i = i + 1) {
                        months.push(moment(fromDate).add(i, "months").format("MMM"))
                    }
                    months.push(moment(toDate).format("MMM"))
                }
                break;
            default:
                fromDate = moment(new Date()).startOf("day");
                toDate = moment(new Date()).endOf("day");
                months = [moment(new Date()).format("MMM")]
        }

        return { fromDate, toDate, months }

    }


    return (
        <div>

            <div className="">

                <JobInvoiceReport
                    timeline={timeline}
                    fromDate={fromDate}
                    toDate={toDate}
                    handleTimeline={handleTimeline}
                    handleDateChange={handleDateChange}
                    showDate={showDate}
                />

                <br />
                <JobCardDashBoard
                    timeline={timeline}
                    fromDate={fromDate}
                    toDate={toDate}
                    showDate={showDate}
                />
                <br />

                <ServiceRem
                    timeline={timeline}
                    fromDate={fromDate}
                    toDate={toDate}
                    showDate={showDate}
                />

                {/* <div style={{display:"flex",gap:10,marginTop:"3rem"}}> 
              
            
            </div>*/}

            </div>

        </div>
    )
}

export default ReportDashboard
