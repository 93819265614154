import React, { useEffect, useState, useContext } from "react";
import {
  Input,
  Select,
  Tooltip,
  Button,
  Form,
  Col,
  Row,
  DatePicker,
  Table,
  Radio,
  Tag,
  Tabs,
  Typography,
  Modal,
  Checkbox,
  Icon,
  message,
  editData,
  Popconfirm,
} from "antd";
import { Link, useHistory, useLocation, withRouter } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
const { Group } = Radio;
import moment from "moment";
// import { Table, Tag, Space } from 'antd';
import { platformApi } from "../../../../api";
import { useBranch } from "../../../../ContextAPI/branch";
import { ContextAPI } from "../../../../ContextAPI";
import ColorSelection from "../ColorSelection";
import { getUUID } from "../../../../_helpers/utils";
import AddCustomer from "./AddCustomer/"
// ----------------------
import { GST18, GST28, GST12, GST5 } from "../../../../utils";

// -----------------

const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;
const { TabPane } = Tabs;

const Index = ({ form }) => {
  const { getFieldDecorator, setFieldsValue, getFieldValue, validateFields } = form;

  const partItemData = {
    id: getUUID(),
    partNumber: {},
    quantity: "",
    unitRate: "",
    grossAmount: "",
    grandTotal: 0,
    hsn: {},
    discount: "",
    rate: "",
    igst: 0,
    cgst: 0,
    sgst: 0,
    gstRate: 0,
    igstAmount: 0,
    cgstAmount: 0,
    sgstAmount: 0,
    discountAmount: 0,
    discountPercent: 0,
    remove: "",
  };

  const { selectBranch } = useBranch();
  const { loginCredintials } = useContext(ContextAPI);
  const location = useLocation();
  const history = useHistory();
  const url = location.pathname;
  const spareId = url.substring(url.lastIndexOf("/") + 1);
  // ----------------------

  const [partSearch, setPartSearch] = useState(false);
  const [selectedPart, setSelectedPart] = useState("");
  const [tax, setTax] = useState("Tax Inclusive");
  const [quantity, setquantity] = useState();
  const [unitRate, setUnitRate] = useState();
  const [totalInvoice, setTotalInvoice] = useState();
  const [SGSTAmount, setSGSTAmount] = useState();
  const [CGSTAmount, setCGSTAmount] = useState();
  const [IGSTAmount, setIGSTAmount] = useState();
  const [search, setSearch] = useState("");
  const [deletePartData, setDeletePartData] = useState([]);
  const [partsData, setPartsData] = useState([]);
  const [page, setPage] = useState(1);
  const partsRef = {};

  // New states
  const [itemList, setItemList] = useState([partItemData]);
  const [number, setNumber] = useState("");
  const [name, setName] = useState("");
  const [orderNo, setOrderNo] = useState(null);
  const [orderDate, setOrderDate] = useState(moment(Date.now()));
  const [customerId, setCustomerId] = useState(null);
  const [discountType, setDiscountType] = useState("At Transaction Level");
  const [discountLevel, setDiscountLevel] = useState();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [totalDiscount, setTotalDiscount] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [advance, setAdvance] = useState(0);
  const [balance, setBalance] = useState(0);
  const [vehicles, setVehicle] = useState([]);
  const [colors, setColors] = useState([]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [colorModal, setColorModal] = useState(false);
  const [editable, setEditable] = useState(location.state?.editable === true ? true : false);
  const [selectItemObj, setSelectItemObj] = useState({});
  const [branch, setBranch] = useState({ id: "", name: "" });
  const [vehicleData, setVehicleData] = useState(null);

  const [customer, setCustomer] = useState([])
  const [searchSupply, setSearchSupply] = useState();
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [cusId, setCusId] = useState("");
  const [newCustomer, setNewCustomer] = useState(null);
  const [custField, setCustField] = useState();

  // ---------------------

  useEffect(() => {
    if (spareId === ":id") {
      platformApi.post("/api/idGenerate/customerSpareOrder").then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          setOrderNo(response.data);
        }
      });
      setBranch({ id: selectBranch[0].id, name: selectBranch[0].name });
    }
  }, []);

  useEffect(() => {
    platformApi.get(`/api/vehicleMaster`).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setVehicle(response.data);
        }
      }
    });
    setFieldsValue({ category: "" });
  }, []);


  const handleModelChange = (id) => {
    vehicles.some((vehicle) => {
      if (vehicle.id === id) {
        setColors(vehicle.image);
        setFieldsValue({ category: vehicle.category });
        setSelectedColor(null);
        // setVehicleModal(vehicle.category)
        setVehicleData(vehicle.id);
      }
    });
  };

  // NEW ADD..............................

  useEffect(() => {

    if (spareId === ":id" || editable) {
      if (
        itemList &&
        itemList?.length > 0 &&
        (itemList[itemList?.length - 1].partNumber.partNumber || itemList[itemList?.length - 1].quantity?.length > 0)
      ) {
        handleAdd();
        setSearch("");
      }
      const selectItem = {};
      for (const item of itemList) {
        selectItem[item.partNumber.id] = item.partNumber;
      }
      setSelectItemObj(selectItem);
      // if(discountType === "At Transaction Level") calculateTransactionTax();
      // if(discountType === "At Item Level") calculateItemTax();
    }
  }, [itemList, editable]);

  // ------------updated

  useEffect(() => {
    if (search) {
      getPartsDetailOnSearch(search);
    }
    else {
      getPartsDetails();
    }
  }, [search]);

  const getPartsDetails = () => {
    platformApi
      .post(`/api/partsMaster/get/`, {
        page: page,
        size: 100,
        searchString: ''
      })
      .then((res) => {
        //setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            // console.log(response.data.partsMaster)
            setPartsData(response.data.partsMaster);
          }
        }
        else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        // setSpinning(false);
        message.error("Unable to fetch Parts", 2);
      });
  }

  const getPartsDetailOnSearch = async (search) => {
    await platformApi
      .post("/api/purchaseSpareInvoice/search", {
        search
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setPartsData(response.data.partsMasterData);
          }
        } else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        // setSpinning(false);
        message.error("Unable to fetch Parts", 2);
      });

  }
  // useEffect(() => {
  //   platformApi
  //     .post(`/api/partsMaster/get/`, {
  //       page: page,
  //       size: 100,
  //       // total:27065,
  //       searchString: "",
  //     })
  //     .then((res) => {
  //       //setSpinning(false);
  //       const { data } = res;
  //       if (data.code === 200) {
  //         const { response } = data;
  //         if (response.code === 200) {
  //           setPartsData(response.data.partsMaster);
  //         }
  //       } else {
  //         message.error("Unable to fetch Parts", 2);
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error on Parts Master : ", error);
  //       // setSpinning(false);
  //       message.error("Unable to fetch Parts", 2);
  //     });
  // }, []);

  useEffect(() => {
    let sgstAmt = 0;
    let cgstAmt = 0;
    let igstAmt = 0;
    const newItemList = [...itemList];
    if (unitRate == null || quantity == null) return;
    for (const index in newItemList) {
      if (newItemList[index].quantity < 1 || newItemList[index].unitRate < 1) continue;
      newItemList[index].grossAmount = roundOf(newItemList[index].quantity * newItemList[index].unitRate);
      newItemList[index].igstAmount = getGSTRateFromPrice({
        price: parseFloat(newItemList[index].grossAmount),
        gstPercentage: newItemList[index].igst,
      });
      const gstByTwo = parseFloat(newItemList[index].igstAmount / 2);
      newItemList[index].sgstAmount = roundOf(gstByTwo);
      newItemList[index].cgstAmount = roundOf(gstByTwo);
      sgstAmt = newItemList[index].sgstAmount;
      cgstAmt = newItemList[index].cgstAmount;
      igstAmt = newItemList[index].igstAmount;
    }
    setSGSTAmount(sgstAmt);
    setIGSTAmount(igstAmt);
    setCGSTAmount(cgstAmt);
    setItemList(newItemList);
  }, [unitRate, quantity]);

  useEffect(() => {
    if(itemList[0].quantity === "" || itemList[0].unitRate === "" ) return;
    console.log({ itemList });
    if (discountType === "At Transaction Level") calculateTransactionTax();
    if (discountType === "At Item Level") calculateItemTax();
  }, [unitRate, quantity, tax, totalInvoice, discount, discountAmount, discountLevel, discountType, advance, balance]);

  // useEffect(() => {
  //   if (itemList.length > 1) {
  //     if (discountType === "At Transaction Level") calculateTransactionTax();
  //     if (discountType === "At Item Level") calculateItemTax();
  //   }
  // }, [unitRate, quantity, tax, totalInvoice, discount, discountAmount, discountLevel, discountType, advance, balance,grandTotal]);

  // useEffect(() => {

  //   if (spareId === ":id") {
  //     if (number && number.length === 10) {
  //       platformApi
  //         .get("/api/customer/phone-no/" + number)
  //         .then((res) => {
  //           let { data } = res;
  //           setCustomerId(data?.response?.data?.customers[0]?.id);
  //           setName(data?.response?.data?.customers[0]?.name);



  //           message.success("Contact found", 2);
  //         })
  //         .catch((err) => {
  //           message.error("Contact Not found", 2);
  //         });
  //     }
  //   }
  // }, [number]);



  useEffect(() => {
    setBalance(grandTotal - advance);
  }, [grandTotal, advance]);


  useEffect(() => {
    if (spareId !== ":id") {
      platformApi.get("/api/customerSpareOrderRouter/" + spareId).then(res => {
        let { data } = res;
        if (data?.response?.code == 200) {
          let val = data?.response?.data
          setOrderNo(val.orderNumber);
          setCustomerId(val.partyName.id)
          setVehicleData(val?.vehicle?.id|| "")
          // setOrderDate(val.orderDate);
          setAdvance(val.Advance);
          setGrandTotal(val.GrandTotal);
          setBalance(val.Balance)
          setBranch({ id: val.branch.id, name: val.branch.name });
          setName(val.partyName.name);
          setNumber(val.partyName.contacts?.[0].phone)
          setCustField(val.partyName.name + '-' + val.partyName.contacts?.[0].phone)
          setDiscountType(val.discountType)
          setDiscountLevel(val.discountlevel)
          setDiscountAmount(val.discountAmount)
          setItemList(val.customerSpareOrderItem)
          if(val.vehicle)
          {
            setFieldsValue({ vehicle: val?.vehicle?.modelCode  + " - " + val.vehicle.modelName });
            setSelectedColor({ id: val?.color?.id, url: val?.color?.url, color: val?.color?.color, code: val?.color?.code })
  
          }
         
        }
        else {
          message.error("Something went wrong!!", 2)
        }
      }).catch(err => {
        message.error("Something went wrong!!", 2)
        console.log(err);
      })
    }
  }, [])

  const handlePartDetail = (event, data) => {
    const newItemList = [...itemList];
    setSelectedPart(event);
    if (event !== undefined) {
      partsData.map((part) => {
        if (part.partNumber === event) {
          for (let item of newItemList) {
            if (item.id === data.id) {
              item.partNumber = part;
              item.hsn = part.hsn;
              item.cgst = part.hsn.cgst;
              item.igst = part.hsn.igst;
              item.sgst = part.hsn.sgst;
              item.gstRate = part.hsn.cgst + part.hsn.sgst;
              item.unitRate = part.mrp;
              item.quantity = 1;
              setquantity(1);
              setUnitRate(part.mrp);
            }
          }
        }
      });
    }
    setItemList(newItemList);
  };

  const handleQuantity = (event, record) => {
    setquantity(event.target.value);
    let newItemList = [...itemList];
    for (let item of newItemList) {
      if (item.id === record.id) {
        item.quantity = event.target.value;
        item.grossAmount = item.quantity * item.unitRate;
        break;
      }
    }

    let grandTotal = 0;
    for (let item of newItemList) {
      grandTotal += item.grossAmount;
    }
    setGrandTotal(grandTotal);
    setItemList(newItemList);
  };

  const handleUnitRate = (event, record) => {
    setUnitRate(event.target.value);
    let newItemList = [...itemList];
    for (let item of newItemList) {
      if (item.id === record.id) {
        item.unitRate = event.target.value;
        item.grossAmount = item.quantity * item.unitRate;
        break;
      }
    }
    let grandTotal = 0;
    for (let item of newItemList) {
      grandTotal += item.grossAmount;
    }
    setGrandTotal(grandTotal);
  };

  const handleDiscount = (event, record) => {
    setDiscount(event.target.value);
    let newItemList = [...itemList];
    for (let item of newItemList) {
      if (item.id === record.id) {
        item.discount = event.target.value;
        break;
      }
    }
  };

  const renderColumns = () => {
    const columns = [
      {
        title: "Part Details",
        dataIndex: "partNumber",
        key: "partNumber",
        width: 230,
        render: (record, data, key) => (
          <Form.Item>
            <Select
              dropdownStyle={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
              onChange={(event) => {
                // setPartSearch(true);
                handlePartDetail(event, data);
              }}
              onSearch={(event) => {
                setSearch(event);
                if (partsData?.length === 1) {
                  handlePartDetail(partsData[0].partNumber, data);
                  partsRef[key].focus();
                }
              }}
              value={record?.partNumber == undefined ? "Part Number" : `${record.partNumber} - ${record.partName}`}
              disabled={!editable}
              showSearch
              showAction={["click", "focus"]}
              placeholder="Part Number"
              filterOption={(input, option) =>
                option.props.children.some((child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0)
              }
            >
              {partsData &&
                partsData.length > 0 &&
                partsData.map((part) => {
                  if (part.id in selectItemObj) return null;
                  return (
                    <Select.Option key={part.id} value={part.partNumber}>
                      {part.partNumber} - {part.partName}
                    </Select.Option>
                  );
                })}
            </Select>
          </Form.Item>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="quantity"
              disabled={!editable}
              onChange={(event) => {
                handleQuantity(event, record);
              }}
              ref={(el) => (partsRef[index] = el)}
              placeholder="quantity"
              value={record && record.quantity}
            />
          </Form.Item>
        ),
      },
      {
        title: "Unit rate",
        dataIndex: "unitRate",
        key: "unitRate",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="unitRate"
              disabled={!editable}
              onChange={(event) => {
                handleUnitRate(event, record);
              }}
              placeholder="Unit Rate"
              value={record && record.unitRate}
            />
          </Form.Item>
        ),
      },
      {
        title: "Discount",
        dataIndex: "discount",
        key: "discount",
        width: 100,
        render: (data, record) => (
          <Form.Item>
            <Input
              key="discount"
              onChange={(event) => {
                handleDiscount(event, record);
              }}
              disabled={!editable}
              placeholder="Discount"
              value={record && record.discount}
            />
          </Form.Item>
        ),
      },
      {
        title: "Gross Amount",
        dataIndex: "grossAmount",
        key: "grossAmount",
        width: 120,
        render: (data, record) => (
          <Form.Item>
            <Input
              key="grossAmount"
              disabled={!editable}
              placeholder="GrossAmount"
              value={record && record.grossAmount}
            />
          </Form.Item>
        ),
      },
      {
        title: "Action",
        dataIndex: "remove",
        key: "x",
        width: 100,
        render: (data, record) => (
          <Form.Item>
            {itemList.length > 0 ? (
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => {
                  handleDelete(record.id);
                }}
              >
                <a style={{ color: "#6c7b8a" }}>Delete</a>
              </Popconfirm>
            ) : null}
          </Form.Item>
        ),
      },
    ];

    return columns.filter((col) => (discountType === "At Transaction Level" ? col.dataIndex !== "discount" : col));
  };

  const handleAdd = () => {
    let newArr = [...itemList, partItemData];
    setItemList(newArr);
  };

  const handleDelete = (selectedId) => {
    let grandTotal = 0;
    const deleteSource = itemList.filter((item) => item.id == selectedId);
    const updatedDataSource = itemList.filter((item) => item.id !== selectedId);
    for (let i = 0; i < updatedDataSource.length; i++) {
      grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(updatedDataSource[i].grandTotal));
      setGrandTotal(grandTotal);
      setTotalInvoice(grandTotal);
      form.setFieldsValue({
        totalInvoice: grandTotal,
      });
    }

    for (let i = 0; i < deleteSource.length; i++) {
      if (deleteSource[i].partNumber.number || deleteSource[i].partNumber.hsn) {
        deletePartData.push(deleteSource[i].partNumber.id);
      }
    }
    setDeletePartData(deletePartData);
    setItemList(updatedDataSource);
  };

  const calculateTransactionTax = () => {
    if (discountType !== "At Transaction Level") return;
    const newItemList = [...itemList];
    let grandTotal = 0;
    let totalGstAmount = 0;
    let sgstAmt = 0;
    let cgstAmt = 0;
    let igstAmt = 0;
    if (tax === "Tax Inclusive") {
      for (const index in newItemList) {
        if (newItemList[index].unitRate && newItemList[index].quantity) {
          // let total = parseFloat(newItemList[index].rate);
          let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
          // taxInclusive = (parseFloat(total) - parseFloat(((total) / gstRateValue))).toFixed(2);
          const gstAmount = getGSTRateFromPrice({
            price: parseFloat(newItemList[index].grossAmount),
            gstPercentage: newItemList[index].igst,
          });
          newItemList[index].igstAmount = gstAmount;
          totalGstAmount += parseFloat(gstAmount);
          const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
          newItemList[index].sgstAmount = gstByTwo;
          newItemList[index].cgstAmount = gstByTwo;
          newItemList[index].grandTotal = roundOf(roundOf(newItemList[index].unitRate) * roundOf(newItemList[index].quantity));
          sgstAmt = newItemList[index].sgstAmount;
          cgstAmt = newItemList[index].cgstAmount;
          igstAmt = newItemList[index].igstAmount;
          setSGSTAmount(sgstAmt);
          setIGSTAmount(igstAmt);
          setCGSTAmount(cgstAmt);

          // newItemList[index].grandTotal = total;
          grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
        }
      }
      if (discountAmount > 0 && !isNaN(discountAmount)) {
        if (discountLevel === "percent") {
          const totalPriceWithoutGST = parseFloat(grandTotal) - parseFloat(totalGstAmount);
          const discountPrice = (totalPriceWithoutGST * parseFloat(discountAmount)) / 100;
          grandTotal = totalPriceWithoutGST - discountPrice + totalGstAmount;
          setTotalDiscount(roundOf(discountPrice));
        } else {
          grandTotal = parseFloat(grandTotal) - parseFloat(discountAmount);
        }
      }
      setGrandTotal(roundOf(grandTotal));
      setTotalInvoice(roundOf(grandTotal));
      form.setFieldsValue({
        totalInvoice: roundOf(grandTotal),
      });
    }
    if (tax === "Tax Exclusive") {
      for (const index in newItemList) {
        if (newItemList[index].grossAmount > 0 && newItemList[index].gstRate > 0) {
          let total = roundOf(newItemList[index].grossAmount);
          let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
          // taxExlusive = (total * (newItemList[index].gstRate / 100)).toFixed(2);
          newItemList[index].igstAmount = calculateGSTAmount({
            price: roundOf(newItemList[index].grossAmount),
            gstPercentage: newItemList[index].igst,
          });
          const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
          newItemList[index].sgstAmount = gstByTwo;
          newItemList[index].cgstAmount = gstByTwo;
          sgstAmt = newItemList[index].sgstAmount;
          cgstAmt = newItemList[index].cgstAmount;
          igstAmt = newItemList[index].igstAmount;
          if (totalDiscount > 0 && !isNaN(totalDiscount)) {
            total =
              discountLevel === "percent"
                ? total - total * (parseFloat(discountAmount) / 100)
                : total - parseFloat(discountAmount);
          }
          total = total + parseFloat(newItemList[index].igstAmount);
          setSGSTAmount(sgstAmt);
          setIGSTAmount(igstAmt);
          setCGSTAmount(cgstAmt);
          newItemList[index].grandTotal = total;
        }
        grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
      }
      setGrandTotal(grandTotal);
      setTotalInvoice(grandTotal);
      form.setFieldsValue({
        totalInvoice: grandTotal,
      });
    }
    setItemList(newItemList);
  };

  const calculateItemTax = () => {
    if (discountType !== "At Item Level") return;
    let taxExlusive = 0;
    let taxInclusive = 0;
    let newItemList = [...itemList];
    let grandTotal = 0;
    let totalGstAmount = 0;
    let sgstAmt = 0;
    let cgstAmt = 0;
    let igstAmt = 0;
    if (tax === "Tax Inclusive") {
      for (const index in newItemList) {
        if (newItemList[index].grossAmount > 0 && newItemList[index].gstRate > 0) {
          let total = newItemList[index].grossAmount;
          let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
          // taxInclusive = (parseFloat(total) - parseFloat(((total) / gstRateValue))).toFixed(2);
          newItemList[index].igstAmount = getGSTRateFromPrice({
            price: parseFloat(newItemList[index].grossAmount),
            gstPercentage: newItemList[index].igst,
          });
          const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
          newItemList[index].sgstAmount = gstByTwo;
          newItemList[index].cgstAmount = gstByTwo;
          sgstAmt = newItemList[index].sgstAmount;
          cgstAmt = newItemList[index].cgstAmount;
          igstAmt = newItemList[index].igstAmount;
          if (newItemList[index].discount > 0 && !isNaN(newItemList[index].discount)) {
            if (discountLevel === "percent") {
              const priceWithoutGST = parseFloat(total) - parseFloat(newItemList[index].igstAmount);
              const discountPrice = (priceWithoutGST * parseFloat(newItemList[index].discount)) / 100;
              total = parseFloat(total) - discountPrice;
            } else {
              total = total - parseFloat(newItemList[index].discount);
            }
          }
          setSGSTAmount(sgstAmt);
          setIGSTAmount(igstAmt);
          setCGSTAmount(cgstAmt);
          newItemList[index].grandTotal = parseFloat(total);
        }
        grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
      }
      setGrandTotal(grandTotal);
      setTotalInvoice(grandTotal);
      form.setFieldsValue({
        totalInvoice: grandTotal,
      });
    }
    if (tax === "Tax Exclusive") {
      for (const index in newItemList) {
        if (newItemList[index].grossAmount > 0 && newItemList[index].gstRate > 0) {
          let total = parseFloat(newItemList[index].grossAmount);
          let gstRateValue = newItemList[index].gstRate / 100 + parseInt(1);
          // taxExlusive = (total * (newItemList[index].gstRate / 100)).toFixed(2);
          newItemList[index].igstAmount = calculateGSTAmount({
            price: parseFloat(newItemList[index].grossAmount),
            gstPercentage: newItemList[index].igst,
          });
          const gstByTwo = roundOf(newItemList[index].igstAmount / 2);
          newItemList[index].sgstAmount = gstByTwo;
          newItemList[index].cgstAmount = gstByTwo;
          sgstAmt = newItemList[index].sgstAmount;
          cgstAmt = newItemList[index].cgstAmount;
          igstAmt = newItemList[index].igstAmount;
          if (newItemList[index].discount > 0 && !isNaN(newItemList[index].discount)) {
            total =
              discountLevel === "percent"
                ? total - total * (parseFloat(newItemList[index].discount) / 100)
                : total - parseFloat(newItemList[index].discount);

            // total = total + parseFloat((newItemList[index].gstRate / 100) * newItemList[index].rate)
          }
          total = total + parseFloat(newItemList[index].igstAmount);
          setSGSTAmount(sgstAmt);
          setIGSTAmount(igstAmt);
          setCGSTAmount(cgstAmt);
          newItemList[index].grandTotal = total;
        }
        grandTotal = roundOf(parseFloat(grandTotal) + parseFloat(newItemList[index].grandTotal));
        setGrandTotal(grandTotal);
        setTotalInvoice(grandTotal);
        form.setFieldsValue({
          totalInvoice: grandTotal,
        });
      }
    }
    setItemList(newItemList);
  };

  const roundOf = (number) => {
    if (number == null) return number;
    const roundedNumber = parseFloat(number).toFixed(2);
    return parseFloat(roundedNumber);
  };
  const getGSTRateFromPrice = ({ price = "", gstPercentage = "" }) => {
    if (price == null || gstPercentage == null) return null;
    const gstRate = parseFloat(price) - parseFloat(price) * (100 / (100 + parseFloat(gstPercentage)));
    const netRate = price - gstRate;
    return roundOf(gstRate);
  };


  const handleSubmit = () => {
    let newItemList = [...itemList];

    newItemList.splice(newItemList.length - 1, 1);

    // if (newItemList.length > 1) {
    //   newItemList = newItemList.filter(item => {
    //     return item.partNumber && item.quantity && item.grossAmount && item.unitRate
    //   })
    // }
    // setItemList(newItemList);

    const data = {
      orderNo,
      orderDate,
      customerId,
      discountType,
      discountLevel,
      discountAmount,
      grandTotal,
      advance,
      balance,
      selectedColor,
      branch,
      newItemList,
      vehicleData,
    };

    console.log(" customerId " + customerId);

    if (spareId === ":id") {
      platformApi
        .post("/api/customerSpareOrderRouter/", data)
        .then((res) => {
          if (res.data.response.code === 200) {
            message.success("Customer Spare Order Created Successfully");
            return history.push("/autoadmin/customerspare");
          }
          message.error("Unable to create Customer Spare Order");
        })
        .catch((err) => {
          message.error("Unable to create Customer Spare Order");
        });
    } else {
      platformApi
        .put(`/api/customerSpareOrderRouter/${spareId}`, data)
        .then((res) => {
          if (res.data.response.code === 200) {
            message.success("Customer Spare Order Updated Successfully");
            return history.push("/autoadmin/customerspare");
          }
          message.error("Unable to update Customer Spare Order");
        })
        .catch((err) => {
          message.error("Unable to update Customer Spare Order");
        });
    }
  };

  const onModify = () => {
    setEditable(true)
  }

  const handleManufacturerChange = (id) => {
    platformApi.get(`/api/vehicleMaster/man/${id}`).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setVehicleData(response.data);
          setSelectedColor(null);
        }
      }
    });
  };



  const handleCustomerChange = (customerID) => {
    setCustomerId(customerID);
    for (let item of customer) {
      if (item.id === customerID) {
        setName(item.name);
        setNumber(item.contacts[0].phone)
        setCustField(item.name + '-' + item.contacts[0].phone)
        break;
      }
    }
    customer.map((item) => {
      if (item.id === customerID) {
        if (!item.name) {
          // setTemp(true);
        }
      }
    });
  };

  useEffect(() => {
    platformApi
      .post('/api/customer/get', { page: 1, size: 10, searchString: searchSupply })
      .then((res) => {
        let { data } = res
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let activeCustomer = response.data.customer
            setCustomer(activeCustomer)
          }
        } else {
          message.error('Cannot fetch Customer Data');
        }

        //setTableLoading(false);
      })
      .catch((err) => {
        console.error('Customer fetch error', err);
        message.error('Cannot fetch Customer Data');
        //setTableLoading(false);
      });
  }, [])


  const getNumbers = (search) => {
    platformApi
      .post("/api/options/get/", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 100,
        page: 1,
        except: null,
      })
      .then((res) => {
        // console.log("response", res.data.response, customer)
        if (res.data.response) {
          search.setValue(res.data.response);
        } else {
          search.setValue(customer);
        }
      });
  };


  const addCustomerData = async (customer) => {
    await platformApi
      .post("/api/customer", customer)
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            console.log("response customer", response.data);
            setNumber(response && response.data ? response.data.contacts[0].phone : "");
            setName(response && response.data ? response.data.name : "");
            setCustField(name + '-' + number);
            setCustomerId(response.data.id);
            setSelectedCustomer(response.data);
            message.success("Customer added successfully", 2);
          } else {
            message.error("Unable to add customer", 2);
          }
        } else if (data.code === 500 && data.err.code === 500) {
          message.error("Customer name already exists", 2);
        } else {
          message.error("Unable to add customer", 2);
        }
      })
      .catch((error) => {
        message.error("Unable to add customer", 2);
        console.error("Error on customer : ", error);
      });
  };



  // ========================

  return (
    <div>
      {/* <Modal> */}
      <ColorSelection
        open={colorModal}
        close={() => setColorModal(false)}
        color={colors}
        selectedColor={setSelectedColor}
      />
      {/* </Modal> */}
      <Title level={4}>
        <Tooltip
          placement="topLeft"
        // title={"Back"}
        >
          <Button
            style={{ marginRight: "1rem" }}
          // onClick={() =>
          //     props.history.push(
          //         '/autoadmin/customerspare'
          //     )
          // }
          >
            <Link to="/autoadmin/customerspare">
              <LeftOutlined />
            </Link>
          </Button>
        </Tooltip>
        Customer Spare Order
      </Title>
      <Form className="purchase-form">
        <Row>
          <Col span={12}>
            <Form.Item className="purchase-form-element" label="Order Number" name="orderno">
              <Input value={orderNo} placeholder="" disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item className="purchase-form-element" label="Date" name="orderDate">
              <DatePicker disabled={true} value={orderDate} />
            </Form.Item>
          </Col>
          {selectBranch.length < 2 ? (
            <Form.Item className="purchase-form-element" label="Branch:" name="orderno">
              <Input placeholder="Branch Name" className="form-input" disabled={true} value={branch.name} />
            </Form.Item>
          ) : (
            <Form.Item className="purchase-form-element" label="Branch" name="branch" required>
              <Select
                dropdownStyle={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                // value={editable ? editData && editData.customer.name : partyName}
                value={branch.id}
                onChange={(id) => {
                  setBranch({ id, name: "" });
                }}
                showAction={["click", "focus"]}
                showSearch
                // disabled={editable}
                placeholder="Branch"
                filterOption={null}
              >
                {loginCredintials.branch.map((eachBranch) => {
                  return <Select.Option key={eachBranch.id}>{eachBranch.name}</Select.Option>;
                })}
              </Select>
            </Form.Item>
          )}
        </Row>



        {/*     */}
        <Row>
          {/* <Col span={12}>
            <Form.Item className="purchase-form-element" label="Customer Number" name="customerNumber" type="number">
              <Input placeholder="Enter The Number" disabled={!editable} value={number} onChange={(e) => setNumber(e.target.value)} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item className="purchase-form-element" label="Customer Name" name="customerName">
              <Input placeholder="" disabled={true} value={name} />
            </Form.Item>
          </Col> */}



          <Col span={12}>
            <Form.Item
              className="purchase-form-element"
              label="Customer Name"
              name="customer"
              required
            // validateStatus={formSubmit && (validateEmptyField(customerID) ? "success" : "error")}
            // help={formSubmit && !validateEmptyField(customerID) && "Select Customer"}
            >
              <Select
                dropdownStyle={{
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
                value={custField}
                // value={customerId}
                onChange={(value) => {
                  handleCustomerChange(value);
                }}

                // onSelect={(e) => {
                //   setCustomerId(e)
                // }}
                showAction={["click", "focus"]}
                showSearch
                disabled={!editable}
                placeholder="Customer"
                filterOption={null}
                onSearch={(e) => {
                  const search = {
                    module: "customers",
                    column: "phone",
                    searchString: e,
                    fields: ["contacts{phone,id,valid}"],
                    searchColumns: ["contacts"],
                    setValue: setCustomer,
                  };
                  getNumbers(search);
                  if (e.length === 10) {
                    setNumber(e);
                  }
                }}
                notFoundContent={
                  <Button
                    type="primary"
                    style={{
                      justifyContent: "center",
                      width: "100%",
                    }}
                    onClick={() => setModalOpen(true)}
                  >
                    Add Customer
                  </Button>
                }
              >
                {customer &&
                  customer.map((cus) => (
                    <Select.Option key={cus.id} value={cus.id}>
                      {cus.name} - {cus && cus.contacts[0] && cus.contacts[0].phone}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>


          {/*  */}
          <Col span={12}>
            <Form.Item colon={false} label="Vehicle model" className="purchase-form-element">
              {getFieldDecorator("vehicle", {
                rules: [{ required: false, message: "Select Model" }],
              })(
                <Select
                  disabled={!editable}
                  onChange={handleModelChange}
                  showSearch
                  showAction={["click", "focus"]}
                  placeholder="Select a Model"
                  // onSelect={handleManufacturerChange}
                  filterOption={(input, option) =>
                    option.props.children.some((child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0)
                  }
                >
                  {vehicles.map((vehicle) => (
                    <Select.Option key={vehicle.id} value={vehicle.id}>
                      {vehicle.modelCode} - {vehicle.modelName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={3}>
            <Item colon={false} label={<Text style={{ fontSize: "15px", fontWeight: "bold" }}>
              Select Color
            </Text>}>
              {getFieldDecorator("color", {
                rules: [{ required: false, message: "Select Color" }],
              })(
                <div>
                  {selectedColor ? (
                    <div>
                      <img style={{ height: "100px" }} src={selectedColor.url} />
                      <Tag >
                        {selectedColor.code !== undefined ? (
                          <Text>{selectedColor.code + " - " + selectedColor.color}</Text>
                        ) : (
                          <Text>No Color Chosen</Text>
                        )}
                      </Tag>
                    </div>
                  ) : (
                    <Tag>
                      <Text>No Color Chosen</Text>
                    </Tag>
                  )}
                </div>
              )}
            </Item>
          </Col>
          <Col span={9}>
            <Button
              disabled={!editable}
              onClick={() => setColorModal(true)}
              style={{ marginTop: "6.4vh" }}
              type="primary"
            >
              Select Color
            </Button>
          </Col>
          <div>
            <Col span={6}>
              <Form.Item className="form-element-type" label="Discount Type" name="dicountType">
                <Select
                  showSearch
                  showAction={["click", "focus"]}
                  style={{ width: 200 }}
                  disabled={!editable}
                  placeholder="Select Discount Type"
                  onChange={(value) => setDiscountType(value)}
                  value={discountType}
                >
                  <Option value="At Transaction Level">At Transaction Level</Option>
                  <Option value="At Item Level">At Item Level</Option>
                </Select>
              </Form.Item>
            </Col>
          </div>
          <div>
            <Col span={6}>
              <Form.Item className="form-element-type" label="Discount" name="discountLevel">
                <Group
                  onChange={(e) => {
                    setDiscountLevel(e.target.value);
                  }}
                  value={discountLevel}
                  disabled={!editable}
                >
                  <Radio value="percent"> % </Radio>
                  <Radio value="rupee"> Rs </Radio>
                </Group>
              </Form.Item>
            </Col>
          </div>
          <div style={{ marginLeft: "50%" }}>
            {discountType === "At Transaction Level" && (
              <Col span={6}>
                <Form.Item
                  className="form-element-type"
                  label={discountLevel === "percent" ? "Discount Percent" : "Discount Rupee"}
                  name="discountAmount"
                  onChange={(e) => setDiscountAmount(e.target.value)}
                  rules={[{}]}
                >
                  <Input
                    disabled={!editable}
                    placeholder={discountLevel === "percent" ? "Discount Percent" : "Discount Rupee"}
                    value={
                      discountType === "At Transaction Level" && discountAmount !== 0 ? discountAmount : discountAmount
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </div>
        </Row>
        {/* Table.. */}

        <Table
          className="parts-invoice-table"
          rowKey={(record) => {
            record.id;
          }}
          columns={renderColumns()}
          dataSource={itemList}
          tableLayout="fixed"
          pagination={false}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        />

        {/* ---------------- */}
        <div style={{ position: "relative", left: "45rem" }}>
          <Row>
            <Col span={8}>
              <Form.Item className="purchase-form-element" label="Grand Total:">
                <Input style={{ marginLeft: "1rem", width: "110%" }} disabled={true} placeholder="" value={grandTotal} />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item className="purchase-form-element" label="Advance:">
                <Input
                  style={{ marginLeft: "1rem", width: "110%" }}
                  placeholder=""
                  disabled={!editable}
                  value={advance}
                  onChange={(e) => setAdvance(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>
              <Form.Item className="purchase-form-element" label="Balance:">
                <Input style={{ marginLeft: "1rem", width: "110%" }} placeholder="" disabled={true} value={balance} />
              </Form.Item>
            </Col>
          </Row>
          <div style={{ position: "relative", left: "9rem" }}>
            <Button style={{ marginRight: "1rem" }} size="large" onClick={() => history.push("/autoadmin/customerspare")}>
              Cancel
            </Button>
            <Button
              type="primary"
              size="large"
              // disabled={btnFlag}
              onClick={() => (editable ? handleSubmit() : onModify())}
            // onClick={() => handleSubmit()}
            >
              {editable ? "Save" : "Modify"}
            </Button>
          </div>
        </div>
      </Form>

      <AddCustomer

        emitData={(data) => addCustomerData(data)}
        open={modalOpen}
        cusId={cusId}
        setCusId={setCusId}
        number={number}
        close={() => {
          setNumber("");
          // setSelected(null);
          setModalOpen(false);
        }}
        editable={true}

      />
    </div>
  );
};
const WrappedCustomerOrderForm = Form.create()(Index);

export default withRouter(WrappedCustomerOrderForm);
