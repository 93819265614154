import React from "react";
import { Table, Divider, Popconfirm, Typography } from "antd";
import moment from 'moment';
import { platformApi } from "../../../../../api";



const { Text } = Typography;



function StockCheckTable(props) {


    const {
			pagination,
			dataSource,
			openModal,
			loading,
			getPSI,
      deleteData,
			deleteAccess,
			modifyAccess,
		} = props;


    const column = [
			{
				title: "Created On",
				dataIndex: "createdAt",
				key: "createdAt",
				width: 125,
				render: (record) => (
					<span>
						{record ? moment(record).format("DD-MM-YYYY") : moment(Date.now()).format("DD/MM/YYYY")}
					</span>
				),
			},

			{
				title: <span>Created By</span>,
				dataIndex: "createdBy",
				key: "createdBy",
				render: (record) => <span>{record && record.profile?.employeeName}</span>,
			},

			{
				title: <span>Bin Location</span>,
				dataIndex: "binNum",
				key: "binNum",
				render: (record) => <span>{record}</span>,
			},

			{
				title: "Part No",
				dataIndex: "partNo",
				key: "partNo",
				render: (record) => <span>{record.partNumber}</span>,
			},

			{
				title: "Part Name",
				dataIndex: "partNo",
				key: "partNo",
				render: (record) => <span>{record.partName}</span>,
			},

			{
				title: "Quantity",
				dataIndex: "phyQuantity",
				key: "phyQuantity",
				render: (record) => <span>{record}</span>,
			},

			// {
			// 	title: "Part Status",
			// 	dataIndex: "partStatus",
			// 	key: "partStatus",
			// 	render: (record) => <span>{record}</span>,
			// },

			{
				title: <span>Actions</span>,
				key: "actions",
				render: (data) => (
					<div>
						{modifyAccess ? (
							<span
								onClick={(event) => {
									event.stopPropagation();
                  openModal(data, true);
								}}
								className="linkylink"
							>
								Modify
							</span>
						) : (
							<span></span>
						)}
						{deleteAccess ? (
							<Popconfirm
								title="Are you sure delete this Stock Check"
								okText="Yes"
								cancelText="No"
								onCancel={(event) => {
									event.stopPropagation();
								}}
								onConfirm={(event) => {
									event.stopPropagation();
									deleteData(data.id);
								}}
							>
								<span className="linkylink" onClick={(event) => event.stopPropagation()}>
									<Divider type="vertical" />
									Delete
								</span>
							</Popconfirm>
						) : (
							<span></span>
						)}
						{!modifyAccess && !deleteAccess ? (
							<Text strong disabled>
								{" "}
								No Access Given
							</Text>
						) : (
							<span></span>
						)}
					</div>
				),
			},
		];


    return (
			<Table
				pagination={pagination}
				rowKey={(record) => record.id}
				columns={column}
				tableLayout="fixed"
				loading={loading}
				style={{ cursor: "pointer" }}
				dataSource={dataSource}
				onRow={(props) => ({
					onClick: () => openModal(props, false),
				})}
			/>
		);



}


export default StockCheckTable;