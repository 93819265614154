import React from 'react';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import { Table, Divider, Popconfirm, Typography } from 'antd';

function InvoiceTable(props) {
  const {
    history,
    gst,
    pagination,
    dataSource,
    spinner,
    modify,
    deleteData,
    setEditable,
    setEditableData
  } = props;
  const column = [
    {
      title: "Invoice Created On",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (record) => (<span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>),
    },
    {
      title: "Invoice No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (record) => (<span>{record}</span>),
    },
    {
      title: "Supplier Name",
      dataIndex: "supplier",
      key: "supplier",
      render: (record) => (<span>{record && record.name}</span>),
    },
    {
      title: "Total Amount",
      dataIndex: "totalInvoice",
      key: "totalInvoice",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div>
          {  
            modify ?
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  history.push({
                    pathname: `/autoadmin/purchase_spares_invoice/${data.id}`,
                    state: {
                      gst: gst
                    }
                  })
                }}
                className="linkylink"
              >
                Modify
                    </span>
              :
              <span></span>
          }
          {
            <Popconfirm
              title="Do you want to delete PSI?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                <Divider type="vertical" />
                        Delete
                       </span>
            </Popconfirm>
          }
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => { record.id }}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      loading={spinner}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(record) => ({
        onClick: () => {
          history.push({
            pathname: `/autoadmin/purchase_spares_invoice/${record.id}`,
            state: {
              gst: gst
            }
          })
          // history.push(`/autoadmin/purchase_spares_invoice/${record.id}`)
        },
      })}
    />
  );
}


export default withRouter(InvoiceTable);