import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import PrivateRoute from "./PrivateRoute";
import Login from "../components/Authentication";
import HelloReact from "../components/hello-react";
import Main from "../components/Home/Modules/Main";
import NotFound from "../components/NotFound";
import ForgotPassword from "../components/Authentication/SendSMSLink";
import PasswordChange from "../components/Authentication/ResetUsingSMSLink";
import PasswordSet from "../components/Authentication/SetUsingSMSLink";
import OnBoard from "../components/OnBoard";
import FollowUp from "../components/FollowUp";
import { ContextAPI } from "../ContextAPI";

import { getAccessToken, platformApi } from "../api";
import Workspace from "../components/Workspace";
import StatusPage from "../components/TransactionMasters/JobOrder/StatusPage";
import BranchProvider from "../ContextAPI/branch";

const AppRouter = () => {
  const [loginCredintials, setLoginCredintials] = useState({});
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      platformApi
        .post("api/user/getUserRoleAccess", { token: token })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let obj = data.data;
            platformApi.get("/api/user/currentUser").then((result) => {
              let { data } = result;
              if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  if (response.data.profile)
                    obj = {
                      ...obj,
                      ...response.data.profile,
                      employeeId: response.data.id,
                    };
                  obj.profilePicture = response.data.profilePicture;
                  obj.phone2 = response.data.phone2;
                  setLoginCredintials(obj);
                }
              }
            });
          }
        });
    }
  }, []);

  return (
    <ContextAPI.Provider value={{ loginCredintials, setLoginCredintials }}>
      <BranchProvider>  
        <Router>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/get-started" component={OnBoard} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgotpassword" component={ForgotPassword} />
            <Route path="/reset_password" component={PasswordChange} />
            <Route path="/set_password" component={PasswordSet} />
            <Route path="/workspace" component={Workspace} />
            <PrivateRoute
              path="/autoadmin/:type/:category?"
              component={HelloReact}
            />
            <PrivateRoute exact path="/autoadmin" component={HelloReact} />
            <Route exact path="/job_status/:id" component={StatusPage} />
            {/* <Route exact path="/follow-ups/:phoneNo" component={FollowUp} /> */}
            <Route path="**" component={NotFound} />
          </Switch>
        </Router>
      </BranchProvider>
    </ContextAPI.Provider>
  );
};
export default AppRouter;
