import React, { useState, useEffect } from 'react'
import { platformApi } from '../../../../../api'
import { Row, Col, Card, Select, Form, Typography, Icon, Button, DatePicker, TimePicker, Input, Steps, message } from 'antd'
import { withRouter } from 'react-router-dom'
import CallModel from './CallModel';
import MessageModel from './MessageModel';
import EditLead from './EditLead';
import WrappedQuotationModal from '../../../../TransactionMasters/Quotations/QuotationsForm';
import moment from 'moment';
import "./index.less"

const { Item } = Form;
const { Text } = Typography;
const { TextArea } = Input;
const { Step } = Steps
const { Meta } = Card;

function SaleLead(props) {
  const { cusId, form } = props
  const [leadData, setLeadData] = useState(null);
  const [leadStateIndex, setLeadStateIndex] = useState(null);
  const [callModelShow, setCallModelShow] = useState(false)
  const [messageModelShow, setMessageModelShow] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [quotationView, setQuotationView] = useState(false);
  const [activityId, setActivityId] = useState('');
  const [quotationData, setQuotationData] = useState(null)
  const leadState = ["Enquiry", "Sold", "Not Intrested"];
  const interactionType = ["Walk- In", "Call", "Message"];
  const enquiryTypes = ["Hot", "Warm", "Cold"];
  const { getFieldDecorator, getFieldValue } = form;

  useEffect(() => {
    activityIdSet()
    platformApi.get(`/api/sales/lead/${cusId}`)
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setLeadData(response.data)
          }
        }
      })

  }, []);

  const activityIdSet = () => {
    platformApi.post('/api/idGenerate/activity')
      .then(res => {
        let { data } = res
        if (data.code === 200) {
          setActivityId(data.response.data);

        }
      })
  }

  const addQuotationData = (quotation) => {
    platformApi
      .post("/api/quotation", quotation)
      .then((result) => {
        setQuotationView(false)
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Quotation added successfully", 2);
            setQuotationData(response.data);
            setQuotationView(false)
          } else {
            message.error("Unable to add new quotation", 2);
          }
        } else {
          message.error("Unable to add quotation", 2);
        }
      })
      .catch((error) => {
        console.error("Error on quotation : ", error);
        message.error("Unable to add new quotation");
      });
  }

  const handleNotIntrested = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        platformApi.put(`api/sales/updateLeadStatus/${cusId}`, { status: 'NOTINTERESTED' })
          .then(res => {
            let { data } = res;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                message.success('Lead modified successfully')
                props.history.push('/autoadmin/sales');
              }
              else {
                message.error('Cannot modify lead')
              }
            }
            else {
              message.error('Cannot modify lead')
            }

          })
      }
    })
  }

  const submitActivity = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        const lead = leadData.id;
        const quotation = quotationData ? quotationData.id : null;

        const activityData = { ...form.getFieldsValue(), lead, activityId, quotation }
        platformApi.post('/api/sales/activity', activityData)
          .then(res => {
            let { data } = res;
            if (data.code === 200) {
              let { response } = data;
              if (response.code === 200) {
                let data = leadData;
                data.activity = [response.data, ...data.activity];
                setLeadData({ ...data })
              }
              else {
                message.error("Couldn't Add Activity")
              }
            }
            else {
              message.error("Couldn't Add Activity")
            }
          })
        activityIdSet()
        form.resetFields()
      }
    })
  }


  return (
    <Form>
      <div style={{ background: '#ECECEC', padding: '30px' }}>
        <Row gutter={16}>
          <Col span={7}>
            <Card title={leadData && leadData.customer && leadData.customer.name} extra={<Icon style={{ fontSize: '20px', cursor: 'pointer' }} theme="twoTone" onClick={() => { setEditModel(true) }} type="edit" />} bordered={true} >
              <Row gutter={16}>
                <Col span={7}>
                  Lead ID :
                </Col>
                <Col span={9}>
                  {leadData && leadData.leadId}
                </Col>
              </Row>
              <div style={{ display: 'flex' }}>
                <b><p>{`${leadData && leadData.customer.gender} |`}</p></b>
                <b> <p style={{ marginLeft: '5px' }}>{
                  leadData && leadData.customer.address ? ` ${leadData.customer.address.locality}` : ''
                }</p></b>
              </div>

              <Row gutter={16} style={{ marginTop: '0vh' }}>
                <Col span={11}>
                  Customer Type :
                </Col>
                <Col span={13} >
                  <span style={{ padding: '3px 8px ', backgroundColor: 'lightgreen' }}>
                    {leadData && leadData.customer.customerType}
                  </span>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: '1.5vh' }}>
                <Col span={11}>
                  Enquiry Type :
                </Col>
                <Col span={13} >
                  <span style={{ padding: '2px 10px ', backgroundColor: 'yellow' }}>
                    {leadData ? (leadData.activity[0] ? leadData.activity[0].enquiryType : 'Not Specified') : 'NA'}
                  </span>
                </Col>
              </Row>

            </Card>
            <Card bordered={true} style={{ marginTop: '.3vh' }} size="small">
              {/* <Row gutter={1} style={{ marginTop: '1vh' }}>
                <Col span={9}>
                  Lead Source :
                  </Col>
                <Col span={12}>
                  {
                    leadData && leadData.customer.quotation[0] ?
                      <span>
                        {leadData.customer.quotation[leadData.customer.quotation.length - 1].leadSource}
                      </span>
                      :
                      <Text strong disabled>NIL</Text>
                  }
                </Col>
              </Row> */}
              {/* <Row gutter={10} style={{ marginTop: '1.5vh' }}>
                <Col span={12}>
                  Campaign :
                  </Col>
                <Col span={12}>

                </Col>
              </Row> */}
              <Row gutter={10} style={{ marginTop: '1.5vh' }}>
                <Col span={19}>
                  Interested Products & Quotations :
                  </Col>
                {
                  leadData && leadData.quotation ?
                    <span></span>
                    :
                    <Col span={5}><Text strong disabled>NIL</Text></Col>
                }
              </Row>
              <Row gutter={10} style={{ marginTop: '1.5vh' }}>
                {
                  leadData && leadData.quotation[0] ?
                    leadData.quotation.map(each => {
                      return (
                        <Card size="small">
                          <span className="activity-text">{each.quotationId}- {each.vehicle[0].vehicleDetail.modelName} - {each.vehicle[0].vehicleDetail.modelCode}
                          </span>
                        </Card>
                      )
                    }) :
                    <span></span>
                }
              </Row>
              <Row gutter={10}>
                <Col span={12}></Col>
                <Col span={12}>
                  <Button type="primary" onClick={() => { setQuotationView(true) }} style={{ marginTop: '1.3vh' }}>
                    Create Quotation
                      </Button>
                </Col>
              </Row>
            </Card>
            <Card bordered={true} title="Schedule Follow-Up" size="small" headStyle={{ textAlign: 'center' }} style={{ marginTop: '.3vh' }}>
              <Row gutter={10} style={{ marginTop: '1vh' }}>
                <Col span={8}>Date & Time:</Col>
                <Col span={7}> {leadData && leadData.activity[0] ? moment(leadData.activity[0].scheduleDate).format('DD-MM-YYYY') : <Text strong disabled>NIL</Text>}</Col>
                <Col span={2}>{leadData && leadData.activity[0] ? '&' : ''}</Col>
                <Col span={7}>{leadData && leadData.activity[0] && moment(leadData.activity[0].scheduleTime).format('HH:mm')}</Col>
              </Row>
              <Row gutter={10} style={{ marginTop: '1.5vh' }}>
                <Col span={7}>Lead State:</Col>
                <Col span={17}>{leadData && leadData.activity[0] ? leadData.activity[0].leadState : <Text strong disabled>NIL</Text>}</Col>
              </Row>

            </Card>
            {/* <Card size="small" title="Customer Data" headStyle={{ textAlign: 'center' }} bordered={true} style={{ marginTop: '.3vh' }}>
              <Row gutter={10} style={{ marginTop: '1vh' }}>
                <Col span={9}>
                  Customer ID :
                  </Col>
                <Col span={15}>
                  {leadData && leadData.customer.customerId}
                </Col>
              </Row>
              <Row gutter={10} style={{ marginTop: '1.5vh' }}>
                <Col span={10}>
                  Customer Type :
                  </Col>
                <Col span={14}>
                  {leadData && leadData.customer.customerType}
                </Col>
              </Row>
              <Row gutter={10} style={{ marginTop: '1.5vh' }}>
                <Col span={12}>
                  Vehicle  Associated :
                  </Col>
                <Col span={9}>
                  <Text strong disabled>NIL</Text>
                </Col>
              </Row>
            </Card> */}
          </Col>
          <Col span={17}>

            <Card bordered={true}>
              <div>
                <Row gutter={12}>
                  <Col span={18}></Col>
                  <Col span={4}>
                    <Button type="primary" onClick={() => { }}>
                      Create Booking
                      </Button>
                  </Col>

                </Row>
                <Row style={{ marginTop: '2vh', backgroundColor: '#efefef', padding: '10px 10px' }} gutter={12}>
                  <Col span={18}>
                    <span>Activity Session Id :{activityId}</span>
                  </Col>
                  <Col span={2}>
                    <Icon style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => { setCallModelShow(true) }} theme="twoTone" type="phone" />
                  </Col>
                  <Col span={2}>
                    <Icon style={{ fontSize: '20px', cursor: 'pointer' }} onClick={() => { setMessageModelShow(true) }} theme="twoTone" type="message" />
                  </Col>
                  <Col span={2}>
                    <Icon style={{ fontSize: '20px', cursor: 'pointer' }} theme="twoTone" type="file" />
                  </Col>

                </Row>

                <Row gutter={16}>
                  <Col span={6}>
                    <Item label={<Text>Enquiry Type</Text>}>
                      {getFieldDecorator("enquiryType", {
                        rules: [
                          { required: true, message: "Select Enquiry Type" },
                        ],
                      })(
                        <Select
                          // disabled={!editable}
                          placeholder="Enquiry Type">
                          {enquiryTypes.map((type) => (
                            <Select.Option value={type} key={type}>
                              {type}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  {/* <Col span={6}>
                    <Item label="Interaction Type" colon={true}>
                      {getFieldDecorator("interactionType", {
                        rules: [
                          {
                            required: true,
                            message: "Select Interaction Type",
                          },
                        ],
                      })(
                        <Select
                          placeholder="Select Int. Type"
                        >
                          {interactionType.map((int) => (
                            <Select.Option key={int}>
                              {int}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col> */}
                  <Col span={6}>
                    <Item label="Lead State" colon={false}>
                      {getFieldDecorator("leadState", {
                        rules: [
                          { required: true, message: "Select Lead State" },
                        ],
                      })(
                        <Select
                          // disabled={!editable}
                          placeholder="Lead State"
                          onChange={(key) => setLeadStateIndex(key)}
                        >
                          {leadState.map((lead) => (
                            <Select.Option key={lead}>
                              {lead}
                            </Select.Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={6}>
                    {leadStateIndex === 'Not Intrested' ? <Button type="primary" onClick={() => { handleNotIntrested() }} style={{ marginTop: '6.3vh' }}>
                      Submit
                      </Button> : <span></span>
                    }
                  </Col>
                </Row>
                {
                  leadStateIndex !== "Not Intrested" ?
                    <div>
                      <Row gutter={16}>
                        <Col span={6}>
                          <Item label="Duration" colon={true}>
                            {getFieldDecorator("duration", {
                              rules: [
                                { required: true, message: "Select Duration!" },
                              ],
                            })(
                              <Input
                                placeholder="Enter Duration"
                              />

                            )}
                          </Item>
                        </Col>
                        <Col span={6}>
                          <Item label="Follow-Up Date" colon={true}>
                            {getFieldDecorator("scheduleDate", {
                              rules: [
                                {
                                  required: true,
                                  message: "Pick Follow Up-Date",
                                },
                              ],
                            })(
                              <DatePicker
                                style={{ width: "100%" }}
                                // disabled={!editable}
                                format="DD-MM-YYYY"
                                disabledDate={(moment) =>
                                  Date.now() - 1000 * 60 * 60 * 24 > moment._d
                                }
                                showToday={false}
                              />
                            )}
                          </Item>
                        </Col>

                        <Col span={6}>
                          <Item label="Follow-Up Time" colon={true}>
                            {getFieldDecorator("scheduleTime", {
                              rules: [
                                {
                                  required: true,
                                  message: "Pick Follow Up-Time",
                                },
                              ],
                            })(
                              <TimePicker
                                style={{ width: "100%" }}
                                use12Hours
                                // disabled={!editable}
                                format="HH:mm"
                                minuteStep={15}
                              />
                            )}
                          </Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col span={24}>
                          <Item label="Remarks" colon={false}>
                            {getFieldDecorator("remarks", {
                              rules: [
                                {
                                  required: false,
                                  message: "Remarks",
                                },
                              ],
                            })(
                              <TextArea rows={5} placeholder="Enter Customer Remarks here ....">
                              </TextArea>
                            )}
                          </Item>
                        </Col>
                      </Row>
                      <Row gutter={0}>
                        <Col span={18}></Col>
                        <Col span={3}>
                          <Button style={{ marginTop: '2vh' }}>
                            Clear
                      </Button>
                        </Col>
                        <Col span={3}>
                          <Button type="primary"
                            onClick={() => { submitActivity() }} style={{ marginTop: '2vh' }}>
                            Submit
                      </Button>
                        </Col>
                      </Row>
                    </div>
                    :
                    <span></span>
                }
              </div>
            </Card>
            <Card bordered={true} style={{ marginTop: '.3vh' }}>

              <Steps
                size="small"
                className="site-navigation-steps"
              >
                <Step status="finish" title="Enquiry" />
                <Step status="finish" title="Follow-Up" />
                <Step status="process" title="Pre-Booking" />
                <Step status="wait" title="Booking" />
                <Step status="wait" title="Sale" />
              </Steps>
            </Card>
            <Card bodyStyle={{ height: "60vh", overflowY: 'scroll' }} size="small" headStyle={{ textAlign: 'center' }} bordered={true} title="Activity" style={{ marginTop: '.3vh' }}>
              {leadData && leadData.activity.map(eachActivity => {

                const date = moment(eachActivity.scheduleDate).format('DD/MM/YYYY')
                const time = moment(eachActivity.scheduleTime).format('HH:mm')
                return (
                  <Card style={{ marginTop: '5px' }}>
                    <Row gutter={12}>
                      <Col span={9}>
                        <Row>
                          <Col span={9}>
                            <span className="activity-text"><b> Follow Up :</b></span>
                          </Col>
                          <Col span={15}>
                            <span className="activity-text">{date} - {time}</span>
                          </Col>
                          <Col span={4}>
                          </Col>
                        </Row>
                        <Row gutter={3}>
                          <Col span={11}>
                            <span className="activity-text"><b>Enquiry Type :</b></span>
                          </Col>
                          <Col span={10}>
                            <span className="activity-text">{eachActivity.enquiryType}</span>
                          </Col>
                        </Row>

                      </Col>
                      <Col span={8}>
                        <Row>
                          <Col span={14}>
                            <span className="activity-text"><b>Interaction Type :</b></span>
                          </Col>
                          <Col span={10}>
                            <span className="activity-text">{eachActivity.interactionType}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={10}>
                            <span className="activity-text"><b>Lead State :</b></span>
                          </Col>
                          <Col span={12}>
                            <span className="activity-text">{eachActivity.leadState}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col span={7}>
                        <Row>
                          <Col span={9}>
                            <span className="activity-text"><b>Duration :</b></span>
                          </Col>
                          <Col span={12}>
                            <span className="activity-text">{eachActivity.duration ? eachActivity.duration : 'NA'}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={12}>
                            <span className="activity-text"><b>{eachActivity.leadState === 'Enquiry' ? 'Quotation ID :' : ''}</b></span>
                          </Col>
                          <Col span={12}>
                            <span className="activity-text">{eachActivity.leadState === 'Enquiry' ? eachActivity.quotation && eachActivity.quotation.quotationId : ''}</span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span className="activity-text"><b>Remarks :</b></span>
                      </Col>
                    </Row>
                    <Row>
                      <span className="activity-text">{eachActivity.remarks ? eachActivity.remarks : 'NIL'}</span>
                    </Row>
                    <Row gutter={10}>
                      <Col span={21}>

                      </Col>
                      <Col span={1}>
                        <Icon style={{ fontSize: '20px', cursor: 'pointer' }} theme="twoTone" type="play-circle" />
                      </Col>
                      <Col span={1}>
                        <Icon style={{ fontSize: '20px', cursor: 'pointer' }} theme="twoTone" type="eye" />
                      </Col>
                      <Col span={1}>
                        <Icon style={{ fontSize: '20px', cursor: 'pointer' }} theme="twoTone" type="edit" />
                      </Col>
                    </Row>
                  </Card>
                )
              })}
            </Card>
          </Col>
        </Row>
      </div >

      <CallModel
        open={callModelShow}
        close={() => { setCallModelShow(false) }}
      />
      <MessageModel
        open={messageModelShow}
        close={() => setMessageModelShow(false)}
      />
      <EditLead
        open={editModel}
        close={() => setEditModel(false)}
        leadData={leadData}
        setLeadData={setLeadData}
      />
      <WrappedQuotationModal
        open={quotationView}
        close={() => setQuotationView(false)}
        editable={true}
        emitData={addQuotationData}
        leadData={leadData && leadData.customer}
      />
    </Form >
  )
}

const WrappedSaleLead = Form.create({ name: "Sale_Lead" })(
  SaleLead
);
export default withRouter(WrappedSaleLead);