import { useEffect, useState } from "react";

const useLocalStorage = (key, initialValue) => {
  const [value, setValue] = useState(() => {
      return getSavedValue(key, initialValue);
  });

  useEffect(() => {
      
      if(!value) return localStorage.removeItem(key)
      
      return localStorage.setItem(key, JSON.stringify(value));

  }, [key, value]);

  return [value, setValue];
};

const getSavedValue = (key, initialValue) => {

  if (initialValue instanceof Function) return initialValue();

  return JSON.parse(localStorage.getItem(key));
};

export {
  useLocalStorage
}