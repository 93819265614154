import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Button, Dropdown, Tooltip, Input, Menu, Icon, message } from "antd";

import DepartmentTable from "./DepartmentTable";
import WrappedAccessoriesModal from "./DepartmentForm";
import { platformApi } from "../../../../../api";
import { ContextAPI } from "../../../../../ContextAPI";
import {
  LeftOutlined
} from '@ant-design/icons';


const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

const HomeDepartment = (props) => {
  const { loginCredintials, setLoginCredintials } = useContext(ContextAPI)
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [spinning, setSpinning] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [dataSource, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [count, setCount] = useState(0);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false)
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [search, setSearch] = useState(null)
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map(menu => (
      <Item onClick={() => { setLimit(menu), setPrimaryData(), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  useEffect(() => {
    loginCredintials.roleAccess && loginCredintials.roleAccess.map(each => {
      if (each.subModule === 'department') {
        if (each.access.create) {
          setCreateAccess(true)
        }
        if (each.access.update) {
          setModifyAccess(true)
        }
        if (each.access.delete) {
          setDeleteAccess(true)
        }
      }
    })
    setPrimaryData()
  }, []);

  useEffect(() => {
    setPrimaryData(search);
  }, [page, limit, search])


  const setPrimaryData = (string, val) => {
    setSpinning(true);
    platformApi
      .post("/api/department/get", { page: val ? val : page, size: limit, searchString: string })
      .then(result => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.department);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch department", 2);
          }
        } else {
          message.error("Unable to fetch department", 2);
        }
      })
      .catch(error => {
        console.error("Error on Home Department : ", error);
        message.error("Unable to fetch department", 2);
        setSpinning(false);
      });
  }

  const addData = department => {
    setSpinning(true);
    if (department.id) {
      platformApi
        .put(`/api/department/${department.id}`, department)
        .then(result => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (department.id === obj.id) {
                  dataSource[index] = response.data;
                }
                if (obj.id === loginCredintials.id) {
                  setLoginCredintials(response.data)
                }
              });
              setData([]);
              setData(dataSource);
              message.success("Department saved successfully");
            }
            else if (response.code === 400) {
              message.error("The department name already exists")
            }
            else {
              message.error("Unable to save department");
            }
          } else {
            message.error("Unabe to save department");
          }
        })
        .catch(error => {
          message.error("Unabe to save department");
          console.error("Error on Home Department : ", error);
          setSpinning(false);
        });
    } else {
      platformApi
        .post("api/department", department)
        .then(result => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Department added sucessfully");
            }
            else if (response.code === 400) {
              message.error("The department name already exists")
            }
            else {
              message.error("Unable to add department");
            }
          } else {
            message.error("Unabe to add department");
          }
        })
        .catch(error => {
          message.error("Unabe to add department");
          console.error("Error on Home Department : ", error);
          setSpinning(false);
        });
    }
  };



  const deleteData = id => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete(`/api/department/${id}`)
      .then(result => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1);
              setPrimaryData(null, 1)
            }
            else {
              setPrimaryData();
            }
            message.success("Department deleted successfully", 2);
          } else {
            message.error("Unable to delete department", 2);
          }
        } else {
          message.error("Unable to delete department", 2);
        }
      })
      .catch(error => {
        console.error("Error on Department : ", error);
        message.error("Unable to delete department", 2);
        setSpinning(false);
      });
  };
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Department [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                  <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Departments"
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button
          onClick={() => setAddFlag(true)}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Department
        </Button>
      </div>
      <DepartmentTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          current: page,
          total: count,
          showQuickJumper: true
        }}
        openModal={id => {
          setAddFlag(true);
          setSelected(id);
        }}
        dataSource={dataSource}
        spinner={{ spinning }}
        delete={id => deleteData(id)}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <WrappedAccessoriesModal
        data={dataSource[dataSource.findIndex(obj => selected === obj.id)]}
        emitData={addData}
        open={addFlag}
        close={() => {
          setAddFlag(false);
          setSelected(null);
        }}
        modify={modifyAccess}
      />
    </div>
  );
}

export default withRouter(HomeDepartment)