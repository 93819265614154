import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router-dom'
import {
  Form,
  Typography,
  Button,
  Input
} from "antd";
const { Title, Text } = Typography;
import "./index.less"

import logo from "../../assets/logo-04.png"

const Workspace = ({ form, history }) => {

  const[workspace, setWorkSpace] = useState("")
  const handleChange = () => {
    form.validateFields((ValidationError) => {
      const workspace = form.getFieldValue("workspace")
      setWorkSpace(workspace)
    })
  }
  return (
    <div className="workspace">
      <div className="workspaceform" style={{ textAlign: "center" }}>
        <Title level={2} style={{ margin: "1rem 1.2rem", textAlign: "center" }}>
          {<img style={{ width: '35%' }} src={logo}></img>}
        </Title>
        <Text>
          <h1>
            Sign In to your Workspace
          </h1>
        </Text>
        <Text style={{ fontSize: "18px" }}>
          Enter your workspace's URL
        </Text>
      </div>
      <Form className="workspace-form">
        <Form.Item>
          {form.getFieldDecorator("workspace", {
            rules: [
              { required: true, message: "Please type your Workspace name!" },
            ],
          })(
            <Input
              addonAfter=".autocloud.in"
              placeholder="your- workspace"
              onChange={() => handleChange()}
              size="large"
            />
          )}
        </Form.Item>
        <Form.Item>
        <a
        href={`https://www.${workspace}.autocloud.in`}>
          <Button
            type="primary"
            // htmlType="submit"
            className="login-form-button"
            // onClick={() => handleSubmit()}
            size="large"
          >
            Continue
          </Button>
          </a>
        </Form.Item>
      </Form>
    </div>
  )
}
const WrappedWorkspaceForm = Form.create({ name: "workspace" })(Workspace);
export default WrappedWorkspaceForm
