import React, { useState, useEffect, useContext } from 'react';
import { Form, Modal, Row, Typography, message, Col, Input, DatePicker, TimePicker, Select, Button } from 'antd';
import QuotationModal from '../../../TransactionMasters/Quotations/QuotationsForm'
import { platformApi } from '../../../../api'
import { ContextAPI } from '../../../../ContextAPI'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import './style.less'
import DiscardModal from '../../DiscardModal';
const { Item } = Form;
const { Text, Title } = Typography;
const { TextArea } = Input
const { Option } = Select
const CallingInterface = (props) => {
  const { visibility, setVisibility, createQuotation, customerDetail, form, type } = props
  const [quotationModal, setQuotationModal] = useState(false)
  const [defaultPhoneNo, setDefaultPhoneNo] = useState(null)
  const [selectedNumber, setSelectedNumber] = useState(null)
  const [enquiryType, setEnquiryType] = useState(null)
  const [dateValue, setDateValue] = useState(null)
  const [date, setDate] = useState(null)
  const [timeValue, setTimeValue] = useState(null)
  const [time, setTime] = useState(null)
  const [remarks, setRemarks] = useState(null)
  const { loginCredintials } = useContext(ContextAPI);
  const [discardModal, setDiscardModal] = useState(false);
  const [qFlag, setQFlag] = useState(false);
  const enquiryTypes = ["Hot", "Warm", "Cold"];
  const {
    getFieldDecorator,
    resetFields,
    getFieldValue,
    setFieldsValue,
  } = form;
  // console.log(customerDetail)
  const getDefaultPhone = () => {
    const primaryContact = customerDetail.contacts.filter(contact => contact.type === 'Primary')
    if (primaryContact.length) {
      setDefaultPhoneNo(primaryContact[0].phone)
      setSelectedNumber(primaryContact[0].phone)
    }
  }

  const handleNumberChange = (value) => {
    setSelectedNumber(value)
  }

  const handleEnquiryChange = (value) => {
    setEnquiryType(value)
  }

  const handleDateChange = (date, dateString) => {
    setDateValue(date)
    setDate(dateString)
  }

  const handleTimeChange = (value, dateString) => {
    setTimeValue(value)
    setTime(dateString)
  }

  const handleRemarkChange = (event) => {
    setRemarks(event.target.value)
  }

  const handleCall = () => {
    let payload = {
      phone1: loginCredintials && loginCredintials.phone2,
      phone2: selectedNumber,
      customerId: customerDetail.id
    }
    if (type === 'serviceFollowup') {
      payload.type = "serviceFollowup"
    }
    platformApi.post("/api/cloudCall", payload)
      .then(res => {
        const { data } = res
        if (data.code === 200) {
          const { response } = data
          if (response.code === 200) {
            message.success("Call has been initiated. You will recieve the call shortly", 4)
          }
          else {
            message.error("Unable to make a call", 2)
          }
        }
        else if (data.code === 401) {
          message.error(data.message, 3)
        }
        else {
          message.error("Unable to make a call", 2)
        }
      })
      .catch(err => {
        message.error("Unable to make a call", 2)
      })
  }

  const handleSubmit = () => {
    if (enquiryType || date && time) {
      let payload = {
        enquiryType,
        followUpDate: date,
        followUpTime: time,
        remarks,
        customerId: customerDetail.id,
        phone: selectedNumber
      }
      if (type === 'serviceFollowup') {
        payload.type = "serviceFollowup"
      }
      platformApi.post("/api/activity/call", payload)
        .then(res => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = data
            if (response.code === 200) {
              message.success("Activity created successfully", 3);
              clearFields()
              close()
            }
            else {
              message.error("Unable to save activity", 3)
            }
          }
          else {
            message.error("Unable to save activity", 3)
          }
        })
        .catch(err => {
          message.error("Unable to save activity")
        })
    }
    else {
      message.error("Enter all the required fields", 4)
    }
  }

  const clearFields = () => {
    setDate(null)
    setTime(null)
    setEnquiryType(null)
    setDateValue(null)
    setTimeValue(null)
    setRemarks(null)
  }

  const getDisabledHours = () => {
    var hours = [];
    if (dateValue < new Date()) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    for (let i = 20; i < 24; i++) {
      hours.push(i)
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (dateValue < new Date()) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };


  useEffect(() => {
    if (visibility) {
      getDefaultPhone();
    }
  }, [visibility])

  const close = () => {
    setVisibility(false)
  }

  return (
    <Modal
      title={<Title level={4}>Session Calling Interface</Title>}
      visible={visibility}
      cancelText={"Cancel"}
      okText="Save"
      onCancel={() => {
        close();
        clearFields();
      }}
      onOk={() => handleSubmit()}
      wrapClassName="calling-modal"
    >
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={10}>
          <strong>Customer Name: </strong>
          {customerDetail.name}
        </Col>
        <Col span={14}>
          <Row type="flex" justify="space-between">
            <Col span={8}>
              <p>
                <strong>Followup Date & Time <span style={{ color: "rgb(220,0,0)" }}>*</span></strong>
              </p>
            </Col>
            <Col span={16}>
              <Row type="flex" gutter={[16, 16]}>
                <Col span={12}>
                  <DatePicker
                    value={dateValue}
                    onChange={handleDateChange}
                    format="DD-MM-YYYY"
                    disabledDate={(moment) =>
                      Date.now() - 1000 * 60 * 60 * 24 > moment._d
                    }
                  />
                </Col>
                <Col span={4}>
                  <TimePicker
                    value={timeValue}
                    onChange={handleTimeChange}
                    format="HH:mm"
                    disabledHours={getDisabledHours}
                    disabledMinutes={getDisabledMinutes}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={10}>
          <Row type="flex" gutter={[16, 16]}>
            <Col span={6}>
              <strong>Number: </strong>
            </Col>
            <Col span={12}>
              <Select
                onChange={handleNumberChange}
                defaultValue={defaultPhoneNo || ""}
              >{
                  customerDetail.contacts.length && customerDetail.contacts.map(contact => {
                    if (!contact.DND)
                      return <Select.Option key={contact.phone} value={contact.phone}>{contact.phone}</Select.Option>
                  })
                }
              </Select>
            </Col>
          </Row>
        </Col>
        {
          type === 'serviceFollowup' ?
            null
            :
            <Col span={14}>
              <Row type="flex" gutter={[16, 16]}>
                <Col span={8}>
                  <strong>Enquiry Type: </strong>
                </Col>
                <Col span={15}>
                  <Item>
                    {getFieldDecorator("enquiryType", {
                      // rules: [{ required: true, message: "Enter Enquiry Type" }],
                    })(
                      <Select
                        showAction={["click", "focus"]}
                        placeholder="Enquiry Type"
                        onChange={handleEnquiryChange}
                      >
                        {enquiryTypes.map((type) => (
                          <Select.Option value={type} key={type}>
                            {type}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
                {/* <Col span={10}>
              <Select
                showAction={["click", "focus"]}
                onChange={handleEnquiryChange}
                value={enquiryType}
                placeholder="Enquiry Type">
                {
                  enquiryTypes.map(source =>
                    <Select.Option key={source}>{source}</Select.Option>
                  )
                }
              </Select>
            </Col> */}
              </Row>
            </Col>
        }

      </Row>
      <Row type="flex" justify="space-between" style={{ marginTop: "10px" }} gutter={[16, 16]}>
        {
          type === 'serviceFollowup' ?
            null
            :
            <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <Button onClick={() => setQuotationModal(true)}>Create Quotation</Button>
            </Col>
        }
        <Col span={12} style={{ display: 'flex', justifyContent: 'center' }} >
          <Button onClick={() => handleCall()}>Call Customer</Button>
        </Col>
        <Col span={24}>
          <strong>Remarks: </strong>
          <TextArea rows={4} value={remarks} onChange={(e) => handleRemarkChange(e)} />
        </Col>
        {
          type === 'serviceFollowup' ?
            null
            :
            <Col span={24}>
              <span className="linkylink" onClick={() => setDiscardModal(true)}>Discard Followup</span>
            </Col>
        }
      </Row>
      <QuotationModal
        leadData={null}
        // leadData={customerDetail}
        editable={true}
        cusData={customerDetail}
        // data={{customer:customerDetail,sms:[]}}
        open={quotationModal}
        emitData={createQuotation}
        // addData={(quotation, callBack) => {
        //   dataSource.some((obj, index) => {
        //     if (obj.id === quotation.id) {
        //       dataSource[index].pdfWithBrochure = quotation.pdfWithBrochure;
        //       dataSource[index].pdfWithOutBrochure = quotation.pdfWithOutBrochure;
        //       callBack(dataSource[index]);
        //       return true;
        //     }
        //   });
        //   setData([...dataSource]);
        // }}
        close={() => {
          setQuotationModal(false)
        }}
      // printAccess={printAccess}
      />
      <DiscardModal
        visibility={discardModal}
        setVisibility={setDiscardModal}
        customerDetail={customerDetail}
      />
    </Modal>
  )
}
const WrappedCallingInterface = Form.create({ name: "calling_interface" })(
  CallingInterface
);

export default withRouter(WrappedCallingInterface);
// export default CallingInterface
