import React, { useState, useEffect } from "react";
import { Modal, Button, Steps, Icon, Form, Typography } from 'antd';
import { withRouter } from 'react-router-dom'
import { platformApi } from '../../api'
import CompanyDetails from './Forms/CompanyDetails'
import DepartmentDetails from './Forms/DepartmentDetails'
import EmployeeDetails from './Forms/EmployeeDetails'
import { useRef } from "react";

const OnBoard = (props) => {
  const { Step } = Steps;
  const { Text } = Typography;
  const childRef = useRef()

  const steps = [
    { id: 1, title: "Company Details" },
    { id: 2, title: "Department Details" },
    { id: 3, title: "Employee" },
  ];

  const icons = [
    <Icon key={true} style={{ fontSize: "1rem" }} type="car" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="apartment" />,
    <Icon key={true} style={{ fontSize: "1rem" }} type="user" />,
  ];

  window.onbeforeunload = function () {
    return "Data will be lost if you leave the page, are you sure?";
  };


  //States
  const [current, setCurrent] = useState(0);
  const [companyDetails, setCompanyDetails] = useState(null)
  const [departmentDetails, setDepartmentDetails] = useState(null)
  const [employeeDetails, setEmployeeDetails] = useState(null)
  const [branch, setBranch] = useState(false);


  const forms = [
    <CompanyDetails
      key="companydetails"
      validityRef={childRef}
      current={current}
      setCurrent={setCurrent}
      setBranch={setBranch}
      branch={branch}
      emitData={(data, datatype) => handleUpdate(data, datatype)}
      data={companyDetails}
    />,
    <DepartmentDetails
      key="departmentdetails"
      validityRef={childRef}
      current={current}
      setCurrent={setCurrent}
      emitData={(data, datatype) => handleUpdate(data, datatype)}
      data={departmentDetails}
    />,
    <EmployeeDetails
      key="employeedetails"
      validityRef={childRef}
      current={current}
      setCurrent={setCurrent}
      emitData={(data, datatype) => handleUpdate(data, datatype)}
      data={employeeDetails}
    />
  ]
  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.history.push('/autoadmin')
    }

    platformApi.get("/api/user/count").then(res => {
      if (res.data.count) {
        props.history.push("/login")
      }
    })
  }, [])

  const handleUpdate = (data, datatype) => {
    if (datatype === "company")
      setCompanyDetails(data)
    else if (datatype === "department")
      setDepartmentDetails(data)
    else
      setEmployeeDetails(data)
  }

  return (
    <div className="">
      <Modal
        closable={false}
        width="80%"
        title="On Board Screen"
        visible={true}
        footer={false}
      //     footer={[
      //         <Button
      //         key
      //         onClick={() => {
      //           if (current > 0) { 
      //              setCurrent(current - 1)
      //           }
      //     }}
      //   >
      //     Previous
      //         </Button>,   
      //   <Button
      //     key
      //     onClick={() => {
      //       if (current < 2) {
      //         setCurrent(current + 1)
      //       }
      //     }}
      //     type="primary"
      //     >
      //     {current===2?"Finish":"Next"}
      //   </Button>
      // ]}
      >
        <Steps size="small" type="navigation" current={current}>
          {steps.map((step, index) => (
            <Step
              key={step.id}
              icon={icons[index]}
              title={<Text>{step.title}</Text>}
            />
          ))}
        </Steps>
        <div className="">{forms[current]}</div>
      </Modal>

    </div>
  )
}

export default withRouter(OnBoard);