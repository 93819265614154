import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Avatar,
  Row,
  Col,
  message,
  Spin,
  Icon,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import { platformApi } from "../../../../../api";
import axios from "axios";

const Details = (props) => {
  const { data, setData, phone, loading, setLoading } = props;
  const [webData, setWebData] = useState(data.websites[0] || "");
  const [imgLoad, setImgLoad] = useState(false);
  const [edit, setEdit] = useState(false);
  const [imageUrl, setImageUrl] = useState(data.image || null);

  let clload = null;

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const values = data;
    values["websites"] = [webData];

    platformApi
      .patch("/api/whatsapp/update", values)
      .then((res) => {
        if (res.data.response.code === 200) {
          message.success("Updated successfully");
        } else {
          message.error("Updatation Failed");
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        message.error("Updatation Failed");
      });
  };

  const beforeUpload = (file) => {
    const dimension = true;

    // image type check
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/jpg" ||
      file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!", 3);
    }

    // image dimension check

    let image = new Image();
    image.src = window.URL.createObjectURL(file)
    image.onload = function () {
      let height = this.height;
      let width = this.width;

      if (height < 500 || width < 500) {
        message.error("Image Height and Width must not less than 500px", 3);
        return false;
      }
      return true;
    }

    // image size check

    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!", 3);
    }

    clload = (isJpgOrPng && isLt2M && dimension);
    return isJpgOrPng && isLt2M && dimension;
  };

  const handleChanger = (e) => {

    if (clload === false) {
      setImgLoad(false);
    }
    else {
      setImgLoad(true);
      let baseUrl = "";
      getBase64(e.file.originFileObj, (data) => {
        baseUrl = data;
      });
      const formData = new FormData();
      formData.append("file", e.file.originFileObj);

      platformApi
        .post("/api/whatsapp/uploadProfile", formData)
        .then((res) => {
          if (res.data.code === 200) {
            setImageUrl(baseUrl);
            message.success("Profile picture uploaded success", 2);
          } else {
            message.error("Profile picture uploaded Failed", 2);
          }
          setImgLoad(false);
        })
        .catch(() => {
          setImgLoad(false);
          message.error("something went wrong", 2);
        });
    }

  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  return (
    <div className="whatsapp_details">
      <div className="whatsapp_details_profile_photo">
        <Form layout="inline" className="whatsapp_details_photo">
          <Form.Item
            name="Profile Picture"
            label="Profile Picture"
            colon={false}
            style={{ fontWeight: "bold" }}
          ></Form.Item>

          <Upload
            name="avatar"
            listType="picture"
            showUploadList={false}
            beforeUpload={(e) => beforeUpload(e)}
            onChange={(e) => handleChanger(e)}
          >
            <div style={{ display: "flex" }}>
              {imgLoad && (
                <Spin
                  style={{
                    position: "absolute",
                    alignSelf: "center",
                    left: "22%",
                    zIndex: "2",
                  }}
                  size="large"
                  indicator={<Icon type="loading" />}
                />
              )}
              <Avatar
                shape="square"
                style={{ cursor: "pointer", border: ".5px solid #00000020" }}
                size={128}
                icon={imageUrl ? "" : "user"}
                src={imageUrl}
              />
            </div>
          </Upload>
        </Form>
      </div>

      <Form>
        <Row>
          <Col span={8}>
            <Form.Item name="Phone Number" label="Phone Number" colon={false}>
              <Input
                value={data.number}
                placeholder="Enter the Phone Number"
                disabled={true}
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ marginLeft: "30px" }}>
            <Form.Item name="Email" label="Email" colon={false}>
              <Input
                value={data.email}
                name="email"
                placeholder="Email"
                disabled={!edit}
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={8}>
            <Form.Item
              name="Business Address"
              label="Business Address"
              colon={false}
            >
              <Input
                value={data.address}
                disabled={!edit}
                name="address"
                placeholder="Business Address"
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
              />
            </Form.Item>
          </Col>
          <Col span={8} style={{ marginLeft: "30px" }}>
            <Form.Item name="Website" label="Website" colon={false}>
              <Input
                value={webData}
                disabled={!edit}
                onChange={(e) => setWebData(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={15}>
            <Form.Item name="description" label="Description" colon={false}>
              <TextArea
                name="description"
                value={data.description}
                autosize={{ minRows: 3 }}
                placeholder="Description"
                disabled={!edit}
                onChange={(e) =>
                  setData({ ...data, [e.target.name]: e.target.value })
                }
              />
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="end">
          <Col>
            <Button onClick={() => setEdit(false)}>Cancel</Button>
            <Button
              onClick={edit ? handleSubmit : () => setEdit(true)}
              type="primary"
              style={{ marginLeft: "1rem" }}
            >
              {edit ? "Save" : "Modify"}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Details;
