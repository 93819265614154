import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Tabs,
  List,
  Icon,
  Button,
  Popconfirm,
  message,
  Divider
} from "antd";
import WrappedFilesModal from "./FilesModal";
import { platformApi } from "../../../../../api";
import { CloudFilled } from "@ant-design/icons";

const { Text, Title } = Typography;
const { TabPane } = Tabs;
const { Item } = List;

const FilesViewModal = ({
  editable,
  data = [],
  emitData,
  open,
  close,
  vehicleDetail,
  setFileUploadData,
  addFile,
  deleteFile
}) => {
  const [addFileFlag, setAddFileFlag] = useState(false);
  const [type, setType] = useState(null);
  const [brochure, setBrochure] = useState([]);
  const [partsManual, setPartsManual] = useState([]);
  const [serviceManual, setServiceManual] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState({});

  useEffect(() => {
    if (data) {
      let dummyBrochure = [],
        dummyPartsManual = [],
        dummyServiceManual = [];
      console.log("File data", data)
      data.forEach((item) => {
        if (item.name.indexOf("Brochure$") === 0) {
          dummyBrochure.push(item);
        } else if (item.name.indexOf("Parts Manual$") === 0) {
          dummyPartsManual.push(item);
        } else if (item.name.indexOf("Service Manual$") === 0) {
          dummyServiceManual.push(item);
        }
      });
      setBrochure(dummyBrochure)
      setPartsManual(dummyPartsManual);
      setServiceManual(dummyServiceManual);
    }
  }, [data]);

  const removeFromSpaces = (url, callBack) => {
    platformApi
      .post("/api/upload/deleteFile", {
        url,
      })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          callBack();
        }
      })
      .catch((error) => {
        console.error("Error on Files View Modal : ", error);
        message.error("Unable to delete this File", 2);
      });
  };

  /*------ service to delete old image in the preview-------*/
  const deleteData = (image) => {
    deleteFile(image)
    if (image.id) {
      platformApi
        .delete(`/api/vehicleMaster/file/${image.id}`)
        .then(() => message.success("Deleted file"))
    }
  };
  /*- - - - - - - xxxxxxx- - - - - - - -*/

  const addData = (file, type) => {
    switch (type) {
      case "Brochure":
        let oldValue1=brochure;
        oldValue1.push(file)
        setBrochure(oldValue1);
        break;
      case "Parts Manual":
        let oldValue2=partsManual;
        oldValue2.push(file)
        setPartsManual(oldValue2);
        break;
      case "Service Manual":
        let oldValue3=serviceManual;
        oldValue3.push(file)
        setServiceManual(oldValue3);
        break;
    }
    setType(null);
    message.success("File added successfully");
  };
  const handleDownload = (url) => {
      if(url.includes('https://')) return window.open(url, "_blank");
      return window.open(`https://${url}`, "_blank")
  }
  

  console.log("brochure",brochure);

  return (
    <Modal
      title={<Title level={4}>Files</Title>}
      visible={open}
      onCancel={() => {
        // emitData([...brochure, ...serviceManual, ...partsManual]);
        close();
      }}
      footer={[
        <Button
          key="footer-close-vehicle-master"
          onClick={() => {
            // emitData([...brochure, ...serviceManual, ...partsManual]);
            close();
          }}
        >
          Close
        </Button>,
      ]}
    >
      <Tabs type="card">
        <TabPane tab="Brochure" key="1">
          {editable && (
            <Button
              onClick={() => {
                setType("Brochure");
                setAddFileFlag(true);
              }}
              icon="file-add"
              size="small"
            >
              New
            </Button>
          )}
          <List
            style={{ height: 170, overflowY: "auto" }}
            dataSource={brochure}
            renderItem={(item) => (
              <Item
                key="brocher-list"
                actions={[
                  <span key="brocher-list-icon">
                    {
                      (item.url) ?
                        <Icon
                          onClick={ (e) => handleDownload(item.url)}
                          type="download"
                        />
                        : null
                    }
                    {
                      editable && item.url && (
                        <span>
                          <Divider type="vertical" />
                        </span>
                      )
                    }
                    {editable && (
                      <span>

                        <Popconfirm
                          title="Do you want to delete?"
                          onConfirm={() => deleteData(item, "brochure")}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Icon type="delete" />
                        </Popconfirm>
                      </span>
                    )}
                  </span>,
                ]}
              >
                <Item.Meta
                  title={<Text strong>{item.name.split("$")[1]}</Text>}
                />
              </Item>
            )}
          />
        </TabPane>
        <TabPane tab="Parts Manual" key="2">
          {editable && (
            <Button
              onClick={() => {
                setType("Parts Manual");
                setAddFileFlag(true);
              }}
              icon="file-add"
              size="small"
            >
              New
            </Button>
          )}

          <List
            style={{ height: 170, overflowY: "auto" }}
            dataSource={partsManual}
            renderItem={(item) => (
              <Item
                key="parts-list"
                actions={[
                  <span key="parts-list-icon">
                    {
                      (item.url) ?
                        <Icon
                          onClick={() => handleDownload(item.url)}
                          type="download"
                        /> : null
                    }
                    {
                      editable && item.url && (
                        <span>
                          <Divider type="vertical" />
                        </span>
                      )
                    }
                    {editable && (
                      <span>
                        <Popconfirm
                          title="Do you want to delete?"
                          onConfirm={() => deleteData(item, "parts")}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Icon type="delete" />
                        </Popconfirm>
                      </span>
                    )}
                  </span>,
                ]}
              >
                <Item.Meta
                  title={<Text strong>{item.name.split("$")[1]}</Text>}
                />
              </Item>
            )}
          />
        </TabPane>
        <TabPane tab="Service Manual" key="3">
          {editable && (
            <Button
              onClick={() => {
                setType("Service Manual");
                setAddFileFlag(true);
              }}
              icon="file-add"
              size="small"
            >
              New
            </Button>
          )}

          <List
            style={{ height: 170, overflowY: "auto" }}
            dataSource={serviceManual}
            renderItem={(item) => (
              <Item
                key="service-list"
                actions={[
                  <span key="service-parts-list-icon">
                    {
                      (item.url) ?
                        <Icon
                          onClick={() => handleDownload(item.url)}
                          type="download"
                        /> : null
                    }
                    {
                      editable && item.url && (
                        <span>
                          <Divider type="vertical" />
                        </span>
                      )
                    }
                    {editable && (
                      <span>
                        <Popconfirm
                          title="Do you want to delete?"
                          onConfirm={() => deleteData(item, "service")}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Icon type="delete" />
                        </Popconfirm>
                      </span>
                    )}
                  </span>,
                ]}
              >
                <Item.Meta
                  title={<Text strong>{item.name.split("$")[1]}</Text>}
                />
              </Item>
            )}
          />
        </TabPane>
      </Tabs>
      <WrappedFilesModal
        vehicleDetail={vehicleDetail}
        type={type}
        open={addFileFlag}
        close={() => {
          setAddFileFlag(false);
        }}
        emitData={addFile}
        addData={addData}
        setFileUploadData={setFileUploadData}
      />
    </Modal>
  );
};

export default FilesViewModal;
