import React, { useEffect } from "react";
import { Table } from "antd";

const PriceTable = ({ pagination = { pageSize: 0 }, dataSource = [] }) => {
  const column = [
    {
      title: "Type",
      dataIndex: "title",
      key: "title",
      render: text => <span>{text}</span>
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: text => <span>&#8377; {text}</span>
    }
  ];
  useEffect(() => {}, [dataSource]);
  return (
    <div>
      <Table pagination={pagination} dataSource={dataSource} columns={column} />
    </div>
  );
};

export default PriceTable;
