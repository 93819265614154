import React, { useState, useEffect } from 'react'
import { Modal, Typography, Tabs, message } from 'antd'
import { platformApi } from '../../../../api'
import WrappedActivityDetails from './ActivityDetails'
import WrappedDiscardDetails from './DiscardDetails'
import Communications from './Communications'
import AssociatedDocuments from './AssociatedDocuments'
import Moment from 'moment'
import './style.less'

const ActivityEditor = (props) => {
  const { setVisibility, editable, getActivities, visible, activityId } = props
  const [activityDetail, setActivityDetail] = useState(null)
  const [messageDetails, setMessageDetails] = useState({})
  const { Title } = Typography
  const { TabPane } = Tabs

  const close = () => {
    setActivityDetail([])
    setMessageDetails({})
    setVisibility(false)
  }

  const getActivity = (id) => {
    platformApi.get(`/api/activity/${id}`).then(res => {
      const { data: { response: { data } } } = res;
      data.scheduleDate = data.scheduleDateAndTime ? Moment(data.scheduleDateAndTime) : null
      data.scheduleTime = data.scheduleDateAndTime ? Moment(data.scheduleDateAndTime) : null
      setActivityDetail(data);
      if (data.booking) {
        const { booking: { sms } } = data
        setMessageDetails(sms)
      }
      else if (data.quotation) {
        const { quotation: { sms } } = data
        if (sms.length)
          setMessageDetails(sms[0])
      }
      else if (data.sms) {
        setMessageDetails(data.sms)
      }
    }).catch(err => { })
  }

  const updateActivity = () => {
    console.log("Update func", activityDetail)
    platformApi.put(`/api/activity/${activityDetail.id}`, activityDetail).then(res => {
      const { data: { response: { data } } } = res;
      message.success("Activity Updated Successfully")
      getActivities();
    }).catch(err => {
      message.error("Activity Update Failed")
    })
  }

  useEffect(() => {
    if (visible)
      getActivity(activityId);
  }, [visible])

  return (
    <Modal
      title={<Title level={4}>Activity Editor</Title>}
      visible={visible}
      cancelText={editable ? "Cancel" : 'Close'}
      okButtonProps={{ style: { display: !editable ? 'none' : 'inline-block' } }}
      okText="Save"
      onOk={() => {
        updateActivity()
        close();
      }
      }
      onCancel={() => {
        close(); 
      }}
      wrapClassName="activity-modal"
    >
      <Tabs centered={true}>
        <TabPane tab="Activity Details" key="1">
          {activityDetail?.discardMsg != null ?
            <WrappedDiscardDetails
              data={activityDetail}
              setData={setActivityDetail}
              editable={editable}
            />
            :
            <WrappedActivityDetails
              data={activityDetail}
              setData={setActivityDetail}
              editable={editable} />
          }
        </TabPane>
        {
          messageDetails && Object.keys(messageDetails).length ?
            <TabPane tab="Communications" key="2">
              <Communications
                data={activityDetail}
                messageDetails={messageDetails}
                setData={setActivityDetail}
                editable={editable} />
            </TabPane> : null
        }
        {
          (activityDetail && (activityDetail.booking || activityDetail.quotation)) ?
            <TabPane tab="Associated Documents" key="3">
              <AssociatedDocuments
                data={activityDetail}
                setData={setActivityDetail}
                editable={editable} />
            </TabPane>
            : null
        }
      </Tabs>
    </Modal>
  )
}

export default ActivityEditor;
