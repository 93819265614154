import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, message, Menu, Dropdown, Icon } from "antd";
import VehiclePurchaseChallanTable from "./VehiclePurchaseChallanTable";
import WrappedVehiclePurchaseChallanForm from "./VehiclePurchaseChallanModal";
import { platformApi } from "../../../api";
const { Title } = Typography;
const { Search } = Input;
import { ContextAPI } from "../../../ContextAPI";
const { Item } = Menu;

function VehiclePurchaseChallan() {
  const [open, setOpen] = useState(false);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [selected, setSelected] = useState(false);
  const [editable, setEditable] = useState(false);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const getMenu = () => {
    const size = count / 10 + (count % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };
  const menu = <Menu>{getMenu()}</Menu>;
  const { loginCredintials } = useContext(ContextAPI);
  useEffect(() => {
    setSpinning(true);
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "vehicle_purchase_challan") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPageData();
  }, []);

  useEffect(() => {
    setPageData(search);
  }, [page, search, limit]);

  const setPageData = (string, val) => {
    platformApi
      .post("api/purchaseChallan/get", {
        page: val ? val : page,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: string,
      })
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(response.data.PurchaseChallan);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch Purchase Challan", 2);
          }
        } else {
          message.error("Unable to fetch Purchase Challan", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Vehicle Purchase Challan : ", error);
        message.error("Unable to fetch Purchase Challan", 2);
      });
  };
  const addData = (purchaseChallan) => {
    setSpinning(true);
    if (purchaseChallan.id) {
      platformApi
        .put("/api/purchaseChallan/" + purchaseChallan.id, purchaseChallan)
        .then((result) => {
          setSpinning(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === purchaseChallan.id) {
                  dataSource[index] = response.data;
                }
              });
              setData([]);
              setData(dataSource);
              message.success("Vehicle Purchase saved successfully", 2);
            } else {
              message.error("Unable to save Purchase Challan", 2);
            }
          } else {
            message.error("Unable to save Purchase Challan", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error("Error on Vehicle Purchase Challan : ", error);
          message.error("Unable to save Purchase Challan", 2);
        });
    }
    else {
      platformApi
        .post("/api/purchaseChallan", purchaseChallan)
        .then((result) => {
          setSpinning(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Purchase Challan added successfully", 2);
            } else {
              message.error("Unable to add Purchase Challan", 2);
            }
          } else {
            message.error("Unable to add Purchase Challan", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error("Error on Vehicle Purchase Challan : ", error);
          message.error("Unable to add Purchase Challan", 2);
        });
    }
  };

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete("/api/purchaseChallan/" + id)
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1)
              setPageData(null, 1)
            }
            else {
              setPageData();
            }
            message.success("Purchase Challan deleted successfully", 2);
          } else if (response.code === 400) {
            message.warning(
              "Purchase Challan already linked, cannot delete Purchase Challan",
              3
            );
          } else {
            message.error("Unable to delete Purchase challan", 2);
          }
        } else {
          message.error("Unable to delete Purchase challan", 2);
        }
      })
      .catch((error) => {
        message.error("Unable to delete Purchase Challan", 2);
        console.error("Error on Vehicle Purchase Challan : ", error);
        setSpinning(false);
      });
  };

  return (
    <div>
      <div className="page-head">
        <Title style={{ width: "60%" }} level={4}>
          <span style={{ marginRight: "2rem" }}>
            Vehicle Purchase Challan [{count}]
          </span>
          <Dropdown overlay={menu} trigger={["click"]}>
            <Button>
              {limit} &nbsp;
              <Icon type="down" />
            </Button>
          </Dropdown>
        </Title>
        <Search
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
          style={{ width: "30%" }}
          placeholder="Search Vehicle"
        />
        <Button
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          onClick={() => {
            setOpen(true);
            setEditable(true);
          }}
          disabled={!createAccess}
        >
          Add Purchase Challan
        </Button>
      </div>
      <VehiclePurchaseChallanTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          current: page,
          total: count,
          showQuickJumper: true,
        }}
        spinner={{ spinning }}
        openModal={(id, editable) => {
          setOpen(true);
          setSelected(id);
          setEditable(editable);
        }}
        delete={deleteData}
        dataSource={dataSource}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <WrappedVehiclePurchaseChallanForm
        data={dataSource[dataSource.findIndex((obj) => obj.id === selected)]}
        open={open}
        close={() => {
          setOpen(false);
          setSelected(null);
        }}
        editable={editable}
        emitData={addData}
        modify={modifyAccess}
      />
    </div>
  );
}

export default VehiclePurchaseChallan;
