import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Upload,
  Pagination,
  Tooltip
} from "antd";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';
import CSVPartsUpload from "./CSVPartsUpload";
import PartsModal from "./PartsModal";
import PartsTable from "./PartsTable";
import "./parts.less";
import { platformApi } from "../../../api";
import WrappedAdvancedFilter from "./AdvancedFilters";
import { ContextAPI } from "../../../ContextAPI";
const PartsMaster = (props) => {
  const { Title, Text } = Typography;
  const { Search } = Input;
  const { Item } = Menu;

  const [file, setfile] = useState(false);
  const [img, setImg] = useState(false);
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [dataSource, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [csvModal, setCsvModal] = useState(false);
  const [upload, setUpload] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [addStatus, setAddStatus] = useState(1);
  const [search, setSearch] = useState([]);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  //for filters
  const [filter, setFilter] = useState([]);
  const [filteropen, setFilteropen] = useState(false);
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete(`/api/partsMaster/${id}`)
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1)
              setPageData(null, 1)
            }
            else {
              setPageData();
            }
            message.success("Vehicle part deleted sucessfully", 2);
          } else {
            message.error("Unable to delete Vehicle Part", 2);
          }
        } else {
          message.error("Unable to delete Vehicle Part", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Error in deletion", 2);
        console.error("Error on PartsMaster : ", error);
      });
  }; 

  const setPageData = (search, val) => {
    setSpinning(true);
    // if (!search || search.name === "") {
    platformApi
      .post(`/api/partsMaster/get/`, {
        page: val ? val : page,
        size: limit,
        searchString: search && search.name,
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.partsMaster);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch Parts", 2);
          }
        } else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        setSpinning(false);
        message.error("Unable to fetch Parts", 2);
      });
  };

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "parts_master") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPageData();
  }, []);

  useEffect(() => {
    setPageData(search);
  }, [page, limit, search]);

  useEffect(() => {
    setPage(1);
  }, [search]);

  const addData = (part) => {
    setSpinning(true);
    if (part.id) {
      platformApi
        .put(`/api/partsMaster/${part.id}`, part)
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              message.success("Part saved successfully");
              dataSource.some((obj, index) => {
                if (part.id === obj.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
            } else {
              message.error("Unable to save part");
            }
          } else {
            message.error("Unable to save part");
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to save part");
          console.error("Error on Parts Master : ", error);
        });
    } else {
      platformApi
        .post("/api/partsMaster", part)
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              message.success("Part added successfully");
              setData([...dataSource, response.data]);
              setCount(count + 1);
            } else if (response.code == 400) {
              message.error("Part Number already exists", 2);
            } else {
              message.error("Unable to add part", 2);
            }
          } else {
            message.error("Unable to add part", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error(error);
        });
    }
  };

  const uploadCSV = (event, id) => {
    if (event.file.status === "uploading") {
      setfile(true);
    }
    else if (event.file.status === "done") {
      setSpinning(true);
      message.info("Your upload has been initiated")
      setUpload(true);
      setfile(false);
      setCsvModal(false);
      const formData = new FormData();
      formData.append("PARTS", event.file.originFileObj);
      formData.append("manufacturer", id);
      platformApi
        .post("/api/partsMaster/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setUpload(false);
          setSpinning(false);
          setPageData(null)
          if (res.data.code === 200) {
            message.success("CSV Data Uploaded");
            // window.location.reload();
          } else {
            message.error("CSV data not Uploaded");
          }
        })
        .catch((error) => {
          setSpinning(false);
        });
    }
  };

  return (
    <div>
      <div className="parts-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Parts [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Part Number"
          onSearch={(event) => {
            let searches = { name: event };
            setPage(1)
            setSearch(searches);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <div style={{ width: "20%", textAlign: "center" }}>
          <Button
            type="link"
            onClick={() => {
              filteropen ? setFilteropen(false) : setFilteropen(true);
            }}
          >
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button>
        </div>
        <Button
          onClick={() => {
            setEditFlag(true);
            setAddFlag(true);
            {
              addStatus === 1 ? setAddStatus(0) : setAddStatus(1);
            }
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Parts
        </Button>
      </div>
      <div style={{ margin: ".5rem" }}>
        {/* <Dropdown overlay={menu} trigger={["click"]}>
          <Button>
            {limit}&nbsp;
            <Icon type="down" />
          </Button>
        </Dropdown> */}
        <WrappedAdvancedFilter
          filteropen={filteropen}
          searchName={search}
          close={setFilteropen}
          id={filter}
          setId={setFilter}
          setData={setData}
          setCount={setCount}
          page={page}
          setPageData={setPageData}
          limit={limit}
          setSearch={setSearch}
        />

        <div style={{ marginLeft: "81.3%", marginBottom: "2%" }}>
          <Button
            onClick={() => {
              setCsvModal(true);
            }}
            type="primary"
            // disabled={upload}
            style={{ width: "95%" }}
            disabled={!createAccess}
          >
            <Icon type="upload" />
            <Text style={{ color: "white" }}>Upload CSV</Text>
          </Button>
        </div>
      </div>

      <PartsTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          current: page,
          showQuickJumper: true,
          total: count,
        }}
        openModal={(index, editable) => {
          setSelected(index);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        dataSource={dataSource}
        spinner={{ spinning }}
        delete={(id) => deleteData(id)}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <PartsModal
        add={addStatus}
        emitPart={(data) => addData(data)}
        open={addFlag}
        editable={editFlag}
        setImg={setImg}
        close={() => {
          if (img) {
            window.location.reload();
          }
          setAddFlag(false);
          setSelected(null);
        }}
        modify={modifyAccess}
        data={dataSource[dataSource.findIndex((obj) => obj.id === selected)]}
      />

      <CSVPartsUpload
        setfile={setfile}
        upload={(event, id) => uploadCSV(event, id)}
        open={csvModal}
        close={() => {
          if (file) {
            window.location.reload();
          }
          setCsvModal(false)
        }}
      />
    </div>
  );
};

export default withRouter(PartsMaster);
