import React, { useState, useEffect } from 'react'
import { Card, List, Row, Col, message, Table, Empty, Typography } from 'antd'
import {
  EyeFilled,
  EditFilled,
  PlayCircleFilled
} from '@ant-design/icons';
import { platformApi } from '../../../api'
import AudioPlayback from './AudioPlayback';
import ActivityEditor from './ActivityEditor';
import BookingActivity from './ActivityTemplate/BookingActivity'
import QuotationActivity from './ActivityTemplate/QuotationActivity';
import MessageActivity from './ActivityTemplate/MessageActivity';
import WalkInActivity from './ActivityTemplate/WalkInActivity';

import './style.less'
import moment from 'moment';
import CallActivity from './ActivityTemplate/CallActivity';
import DiscardActivity from './ActivityTemplate/DiscardActivity';
import ServiceFollowUpActivity from './ActivityTemplate/ServiceFollowupActivity';
import ServiceWhatsAppActivity from './ActivityTemplate/ServiceWhatsAppActivity';
import WhatsAppActivity from './ActivityTemplate/WhatsAppActivity';
const ActivityLog = (props) => {
  const { customerId, dataSource, setDataSource, getActivityByCustomer } = props
  const [visible, setVisibility] = useState(false);
  const [editorVisibility, setEditor] = useState(false)
  const [editable, setEditable] = useState(false)
  const [activityId, setActivityId] = useState(null)
  const defaultLimit = 15;
  const [limit, setLimit] = useState(defaultLimit);
  const [offset, setOffset] = useState(0);
  const { Text, Title } = Typography
  const { Meta } = Card

  const openEditor = (id, edit = false) => {
    setActivityId(id)
    setEditable(prev => edit)
    setEditor(true)
  }


  const renderAction = (item) => {
    return <PlayCircleFilled onClick={() => setVisibility(!visible)} />
  }

  const renderTitle = (item) => {
    if (item.booking) {
      return <BookingActivity item={item} openEditor={openEditor} />
    }
    else if (item.quotation) {
      return <QuotationActivity item={item} openEditor={openEditor} />
    }
    else if (item.interactionType === "Service Followup WhatsApp Message") {
      return <ServiceWhatsAppActivity item={item} openEditor={openEditor} />
    }
    else if (item.interactionType == "Service Followup Call" || item.interactionType === "Service Followup Message") {
      return <ServiceFollowUpActivity item={item} openEditor={openEditor} />
    }
    else if (item.interactionType === "WhatsApp Message") {
      return <WhatsAppActivity item={item} openEditor={openEditor} />
    }
    else if (item.sms) {
      return <MessageActivity item={item} openEditor={openEditor} />
    }
    else if (item.callId || item.interactionType == 'Call Follow') {
      return <CallActivity item={item} openEditor={openEditor} />
    }
    else if (item.discardMsg || item.interactionType == "Discard") {
      return <DiscardActivity item={item} openEditor={openEditor} />
    }
    else {
      return <WalkInActivity item={item} openEditor={openEditor} />
    }
  }


  return (
    <div className="activity-log">
      <Card title={<Title style={{ textAlign: 'center' }} level={4}>Activity</Title>}>
        <List
          itemLayout="horizontal"
          dataSource={dataSource}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                title={
                  renderTitle(item)
                }
              />
              <AudioPlayback
                audioSource={item}
                visible={visible}
                setVisibility={setVisibility}
              />
            </List.Item>
          )}
        />
      </Card>
      <ActivityEditor
        editable={editable}
        activityId={activityId}
        getActivities={getActivityByCustomer}
        visible={editorVisibility}
        setVisibility={setEditor}
      />
    </div>
  )
}

export default ActivityLog;
