import React, { useState } from "react";
import Header from "../Header";
import Carousal from "../Carousal";
import "./index.css";

import Card from "../../Common/Card";
import Services from "../Services";
import Feedback from "../Feedback";
import About from "../About";
import Enquiry from "../Enquiry";
import Partners from "../Partners";
import Footer from "../Footer";
import Fascino from '../../../../assets/Fasino.png'
import FZF1 from '../../../../assets/FZ-F1.png'
import FZSFI from '../../../../assets/FZS-FI.png'
import MT15 from '../../../../assets/mt-15.png'
import Rayzr125 from '../../../../assets/rayzr-125.png'
import Streetrally from '../../../../assets/street-rally-125fi.png'
import YZFR15 from '../../../../assets/YZF-R15.png'
function Main() {
  const [Motor, setMotor] = useState([
    {
      title: "Motorcycles",
      content: [
        {
          img: YZFR15,
          name: "YZF R15 V 3.0",
          price: "RS. 97,000/-*",
          links: "https://www.yamaha-motor-india.com/yamaha-yzf-r15-v3.html",
        },
        {
          img: MT15,
          name: "MT-15",
          price: "RS. 97,000/-*",
          links: "https://www.yamaha-motor-india.com/yamaha-mt-15.html",
        },
        {
          img: FZSFI,
          name: "FZS-FI",
          price: "RS. 97,000/-*",
          links: "https://www.yamaha-motor-india.com/yamaha-fzs-fi.html",
        },
        {
          img: FZF1,
          name: "FZ-FI",
          price: "RS. 97,000/-*",
          links: "https://www.yamaha-motor-india.com/yamaha-fz-fi.html",
        },
      ],
    },
    {
      title: "Scooters",
      content: [
        {
          img: Rayzr125,
          name: "RayZR 125 FI",
          price: "RS. 97,000/-*",
          links: "https://www.yamaha-motor-india.com/yamaha-ray-zr125fi.html",
        },
        {
          img: Streetrally,
          name: "RayZR Street Rally 125 Fi",
          price: "RS. 97,000/-*",
          links:
            "https://www.yamaha-motor-india.com/yamaha-ray-zr-streetrally125fi.html",
        },
        {
          img: Fascino,
          name: "FASCINO 125 FI",
          price: "RS. 97,000/-*",
          links: "https://www.yamaha-motor-india.com/yamaha-fascino125fi.html",
        },
      ],
    },
  ]);
  const [Icons, setIcons] = useState([
    { img: "", name: "Easy Financing" },
    { img: "", name: "Easy Exchange" },
    { img: "", name: "Insurance" },
    { img: "", name: "Accessories" },
    { img: "", name: "24/7 Customer Care" },
  ]);
  return (
    <div className="first">
      <div className="Main">
        <div className="head" id="head">
          <Header />
        </div>
        <div className="Image-carousal">
          <Carousal />
        </div>
      </div>
      <div className="Main-contxt">
        <div className="Main-con" id="check">
          <div className="Main-con-text">
            Experience Yamaha - Schedule an Appointment
          </div>
          <a
            href="https://www.yamaha-motor-india.com/"
            className="Main-con-btn"
          >
            BOOK A TEST RIDE NOW
          </a>
        </div>
        <div>
          {Motor.map((element) => (
            <Card title={element.title} content={element.content} />
          ))}
        </div>
        <div>
          <Services Icons={Icons} />
        </div>
        <div>
          <Feedback />
        </div>
        <div id="about">
          <About />
        </div>
        <div id="contact">
          <Enquiry />
        </div>
        <div id="partners">
          <Partners />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Main;
