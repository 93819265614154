import React, { useEffect, useState } from 'react'
import { Steps, Row, Col, message, Button, Icon } from 'antd';
import { VerticalAlignBottomOutlined, SolutionOutlined, SettingOutlined, SecurityScanOutlined, CheckOutlined } from '@ant-design/icons';
import "../index.less"
import { useHistory, withRouter } from "react-router-dom";
import { platformApi } from '../../../../../api';
import DeatilsCard from './DeatilsCard';
import JobSteps from './JobSteps';

const { Step } = Steps;
const StatusContainer = ({ id }) => {
    const history = useHistory();
    const [jobOrderData, setjobOrderData] = useState([])
    const [status, setStatus] = useState(0)
    const [current, setCurrent] = useState(0);
    useEffect(() => {
        platformApi
            .get("/api/jobOrder/" + id)
            .then((res) => {
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setjobOrderData(response.data);
                        console.log("JobOrder data", response.data)
                        switch (response.data.jobStatus) {
                            case "Vehicle Received":
                                setStatus(0)
                                setCurrent(0)
                                break
                            case "Estimation":
                                setStatus(1)
                                setCurrent(1)
                                break
                            case "Estimation Approved":
                                setStatus(1)
                                setCurrent(1)
                                break
                            case "Mechanic Allocated":
                                setStatus(2)
                                setCurrent(1)
                                break
                            case "Work In Progress":
                                setStatus(3)
                                
                                break
                            case "Final Inspection":
                                setStatus(4)
                                break
                            case "Proforma Invoice":
                                setStatus(4)
                                setCurrent(2)
                                break
                            case "Invoice":
                                setStatus(4)
                                setCurrent(2)
                                break
                            case "Payment Received":
                                setStatus(5)
                                setCurrent(2)
                                break
                            case "Gate Pass":
                                setStatus(6)
                                setCurrent(3)
                                break
                        }
                    } else {
                        message.error("Failed to fetch Job Order");
                    }
                }
                else if (data.code === 400) {
                    history.push("/notfound")
                }
                else {
                    message.error("Failed to fetch Job Order");
                }
            })
            .catch((error) => {
                console.error("Error on Job Order : ", error);
                message.error("Failed to fetch Job Order");
            });
    }, [])
    return (
        <div id="stepsBody">
            <div className="steps" id="steps">
                <Steps current={status}>
                    <Step key={0} icon={
                        <div className="stepsIcons"><span><VerticalAlignBottomOutlined /> </span>
                            <h6 style={{ fontSize: '10px' }}>Vehicle Received</h6>
                        </div>}
                        onClick={() => {
                            setCurrent(0)
                        }}
                    />
                    <Step key={1} icon={
                        <div className="stepsIcons"><span><Icon type="retweet" /> </span>
                            <h6 style={{ fontSize: '10px' }}>Estimation</h6>
                        </div>}
                        onClick={() => {
                            setCurrent(1)
                        }}
                    />
                    <Step icon={
                        <div className="stepsIcons"><span><SolutionOutlined /> </span>
                            <h6 style={{ fontSize: '10px' }}>Mechanic Allocated</h6>
                        </div>}
                        onClick={() => {
                            setCurrent(1)
                        }}
                    />
                    <Step icon={
                        <div className="stepsIcons"><span><SettingOutlined /> </span>
                            <h6 style={{ fontSize: '10px' }}>Work in Progress</h6>
                        </div>}
                    // onClick={() => {
                    //     setCurrent(key)
                    // }}
                    />
                    <Step icon={
                        <div className="stepsIcons"><span><SecurityScanOutlined /> </span>
                            <h6 style={{ fontSize: '10px' }}>Final Inspection</h6>
                        </div>}
                    // onClick={() => {
                    //     setCurrent(key)
                    // }}
                    />
                    {/* <Step icon={<div className="stepsIcons"><span><SecurityScanOutlined /> </span><h6 style={{ fontSize: '10px' }}>Proforma Invoice</h6></div>} /> */}
                    <Step key={2} icon={
                        <div className="stepsIcons"><span><Icon type="sync" /> </span>
                            <h6 style={{ fontSize: '10px' }}>Ready For Delivery</h6>
                        </div>}
                        onClick={() => {
                            setCurrent(2)
                        }}
                    />
                    <Step key={3} icon={
                        <div className="stepsIcons"><span><CheckOutlined /> </span>
                            <h6 style={{ fontSize: '10px' }}>Delivered</h6>
                        </div>}
                        onClick={() => {
                            setCurrent(3)
                        }}
                    />
                </Steps>
            </div>
            <hr />
            <div className="details">
                <h2 className="headings" style={{ color: '#c4172c' }} >Vehicle Details</h2>
                <DeatilsCard data={jobOrderData} />
            </div>
            <hr />
            <JobSteps data={jobOrderData} status={status} current={current} setCurrent={setCurrent} id={id} />
        </div>
    )
}
export default withRouter(StatusContainer);