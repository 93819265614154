import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Select,
  Typography,
} from "antd";
import { formatValue } from "../../../../utils"

import { platformApi } from "../../../../api";
import VehicleTable from "./VehicleTable";
import "./index.less";

const { Item } = Form;
const { Text } = Typography;

const JobCodeForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [SAC, setSAC] = useState([]);
  const [error, setError] = useState({});

  const [editable, setEditable] = useState(props.editable);
  const [dataSource, setDataSource] = useState([]);

  const { form, open, close, data, modify } = props;
  const { getFieldDecorator } = form;

  useEffect(() => {
    setEditable(props.editable);
    if (data) {
      data.sac && initializeSelect(data.sac.id);
      form.setFieldsValue({
        code: data.code,
        sac: data.sac && data.sac.id,
        desc: data.description,
        price: data.price,
        consumable: data.consumable,  
        group: data.group,
        marginType: data.marginType,
        mow: data.marginOnOutsideWork,
      });
    }
  }, [data || props.editable]);

  const initializeSelect = (sacId) => {
    platformApi.get("/api/sac/").then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setSAC(response.data);
          handleSACChange(sacId, response.data);
        }
      }
    });
  };

  useEffect(() => {
    setError({})
    if (open) {
      platformApi.get("/api/sac/").then((res) => {
        setSAC(res.data.response.data);
      });
      if (!data) {
        const Data = data;
        platformApi.get("/api/vehicleMaster/").then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              // console.log("Vehicles", response.data)
              let data = response.data.map((model) => {
                return {
                  id: "",
                  vehicle: {
                    id: model.id,
                    modelName: model.modelName,
                    modelCode: model.modelCode
                  },
                  price: 0,
                };
              });
              if (Data) {
                data = data.map((model, index) => {
                  if (
                    Data.vehicleModel[index] &&
                    model.vehicle.id === Data.vehicleModel[index].vehicle && Data.vehicleModel[index].vehicle.id
                  ) {
                    return Data.vehicleModel[index];
                  }
                  else {
                    return model;
                  }
                });

              }
              setDataSource([...data]);
            }
          }
        });
      }
      else {
        let temp = [];
        for (let i = 0; i < data.vehicleModel.length; i++) {
          const element = data.vehicleModel[i];
          const index = temp.some(item => item.vehicle.id === element.vehicle.id)
          if (!index) {
            temp.push(element)
          }
        }
        // console.log("Temp", temp)
        platformApi.get("/api/vehicleMaster/").then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              let data = response.data.map((model) => {
                return {
                  id: "",
                  vehicle: {
                    id: model.id,
                    modelName: model.modelName,
                    modelCode: model.modelCode
                  },
                  price: 0,
                };
              });
              if (temp) {
                let withPrice = [];
                let withOutPrice = [];
                data.map((model) => {
                  const index = temp.find(item => item.vehicle.id === model.vehicle.id)
                  if (index) {
                    withPrice.push(index)
                  }
                  else {
                    withOutPrice.push(model)
                  }
                });
                // console.log("withPrice", withOutPrice.length)
                setDataSource([...withPrice, ...withOutPrice]);
              }
            }
          }
        });
        // setDataSource(data.vehicleModel)
      }
    }
  }, [open]);

  const Group = [
    { key: "Service", title: "Service" },
    { key: "Periodic Maintenance", title: "Periodic Maintenance" },
    { key: "Engine", title: "Engine" },
    { key: "Electrical", title: "Electrical" },
    { key: "Frame", title: "Frame" },
    { key: "General", title: 'General' }
  ];

  const MarginType = [
    { key: 1, title: "Flat" },
    { key: 2, title: "Percent" },
  ];

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        setLoading(true);
        const code = form.getFieldValue("code");
        const description = form.getFieldValue("desc");
        const sac = form.getFieldValue("sac");
        const group = form.getFieldValue("group");
        const vehicleModel = dataSource;
        const marginType = form.getFieldValue("marginType");
        const marginOnOutsideWork = form.getFieldValue("mow");
        const consumable = form.getFieldValue("consumable");
        const Job = {
          code,
          description,
          sac,
          group,
          vehicleModel,
          marginType,
          marginOnOutsideWork,
          consumable
        };

        data ? (Job.id = data.id) : {};
        Job.vehicleModel = Job.vehicleModel.map(vehicle => ({ id: vehicle.id, price: vehicle.price, vehicle: { id: vehicle.vehicle.id } }))
        props.emitData(Job);

        clearFields();
        form.resetFields();
        setLoading(false);
        props.close();
      }
    });
  };

  const clearFields = () => {
    setLoading(false);
    setError({})
    form.resetFields();
    setDataSource([]);
  };

  const editTableData = React.useCallback((data) => {
    setDataSource([...data]);
  }, [data])

  const filterMethod = (input, option) =>
    option.props.children.toString().indexOf(input) >= 0;

  const handleSACChange = (id, SACCodes = []) => {
    const sac = SACCodes[0] ? SACCodes : SAC;
    const index = sac.findIndex((obj) => obj.id === id);
    form.setFieldsValue({
      cgst: sac[index].cgst,
      sgst: sac[index].sgst,
      igst: sac[index].igst,
      cess: sac[index].cess,
    });
  };

  return (
    <Modal
      title="Job Code"
      visible={open}
      onCancel={() => {
        clearFields();
        close();
      }}
      okText={editable ? "Save" : "Modify"}
      okButtonProps={{ loading, disabled: (!editable && !modify) }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      wrapClassName="Job-Form"
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Item
                  validateStatus={error.CODE && error.CODE.type}
                  help={error.CODE && error.CODE.message}
                  label="Job Code"
                  colon={false}>
                  {getFieldDecorator("code", {
                    rules: [{ required: true, message: "Enter Job Code" }],
                  })(<Input
                    onKeyUp={e =>
                      form.setFieldsValue({
                        code: formatValue(e, "toUpperCase")
                      })}
                    pattern="^[a-zA-Z0-9]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          CODE: {
                            type: "error",
                            message: "Enter Valid Code"
                          }
                        })
                      } else {
                        delete error.CODE
                        setError(error)
                      }
                    }}
                    disabled={!editable}
                    placeholder="Job Code" />)}
                </Item>
              </Col>
              <Col span={11}>
                <Item label="SAC" colon={false}>
                  {getFieldDecorator("sac", {
                    rules: [{ required: true, message: "Select SAC" }],
                  })(
                    <Select
                      disabled={!editable}
                      onChange={handleSACChange}
                      placeholder="SAC"
                      showSearch
                      showAction={["click", "focus"]}
                      filterOption={filterMethod}
                    >
                      {SAC.map((code) => (
                        <Select.Option key={code.id}>{code.code}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Item
                  validateStatus={error.DESC && error.DESC.type}
                  help={error.DESC && error.DESC.message}
                  label="Description"
                  colon={false}>
                  {getFieldDecorator("desc", {
                    rules: [{ required: true, message: "Enter Description" }],
                  })(<Input
                    // pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                    onKeyUp={e =>
                      form.setFieldsValue({
                        desc: formatValue(e, "firstCaps")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          DESC: {
                            type: "error",
                            message: "Enter Valid Description"
                          }
                        })
                      } else {
                        delete error.DESC
                        setError(error)
                      }
                    }}
                    disabled={!editable}
                    placeholder="Description" />)}
                </Item>
              </Col>
              <Col span={11}>
                <Item label="IGST" colon={false}>
                  {getFieldDecorator("igst", {
                    rules: [{ required: true, message: "Enter IGST" }],
                  })(<Input addonAfter="%" disabled placeholder="IGST" />)}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Item label="Group" colon={false}>
                  {getFieldDecorator("group", {
                    rules: [{ required: true, message: "Select a Group" }],
                  })(
                    <Select showAction={["click", "focus"]} disabled={!editable} placeholder="Group">
                      {Group.map((model) => (
                        <Select.Option value={model.key} key={model.key}>
                          {model.title}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={11}>
                <Item label="CGST" colon={false}>
                  {getFieldDecorator("cgst", {
                    rules: [{ required: true, message: "Enter CGST" }],
                  })(<Input addonAfter="%" disabled placeholder="CGST" />)}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Item label="Margin Type" colon={false}>
                  {getFieldDecorator("marginType", {
                    rules: [{ required: false }],
                  })(
                    <Select showAction={["click", "focus"]} disabled={!editable} placeholder="Margin Type">
                      {MarginType.map((model) => (
                        <Select.Option key={model.title}>
                          {model.title}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={11}>
                <Item label="SGST" colon={false}>
                  {getFieldDecorator("sgst", {
                    rules: [{ required: true, message: "Enter SGST" }],
                  })(<Input addonAfter="%" disabled placeholder="SGST" />)}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Item
                  label="Margin on Outside Work"
                  colon={false}
                  validateStatus={error.MOW && error.MOW.type}
                  help={error.MOW && error.MOW.message}
                >
                  {getFieldDecorator("mow", {
                    rules: [{ required: false }],
                  })(
                    <Input
                      // type="number"
                      addonBefore={
                        form.getFieldValue("marginType") === "Flat" && (
                          <span>&#8377;</span>
                        )
                      }
                      addonAfter={
                        form.getFieldValue("marginType") === "Percent" && "%"
                      }
                      disabled={!editable}
                      placeholder="Margin"
                      onKeyUp={(e) => {
                        form.setFieldsValue({
                          mow: formatValue(e, "noWithDot"),
                        })
                        if (form.getFieldValue("mow").length == 0) {
                          delete error.MOW
                        }
                      }

                      }
                      pattern="[0-9]+(\.[0-9][0-9][0-9]?)?"
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            MOW: {
                              type: "error",
                              message: "Enter Valid Margin On Outside Work"
                            }
                          })
                        } else {
                          delete error.MOW
                          setError(error)
                        }
                      }}
                    />
                  )}
                </Item>
              </Col>

              <Col span={11}>
                <Item label="CESS" colon={false}>
                  {getFieldDecorator("cess", {
                    rules: [{ required: true, message: "Enter Cess" }],
                  })(<Input disabled placeholder="Cess" />)}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={11}>
                <Item label="Consumable" colon={false}>
                  {getFieldDecorator("consumable", {
                    rules: [{ required: false }],
                  })(
                    <Select showAction={["click", "focus"]} disabled={!editable} placeholder="Consumable">
                      <Select.Option key={1} value={true}>True</Select.Option>
                      <Select.Option key={2} value={false}>False</Select.Option>
                    </Select>
                  )}
                </Item>
              </Col>

            </Row>
          </Col>
          <Col span={13} >
            <Item
              colon={false}
              label={
                <span>
                  <span>Vehicles</span>
                </span>
              }
            >
              <VehicleTable
                editData={editTableData}
                editable={editable}
                dataSource={dataSource}
              />
            </Item>
          </Col>
        </Row>
        <Row>
        </Row>
      </Form>
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: "normal_login" })(
  JobCodeForm
);

export default WrappedAccessoriesModal;
