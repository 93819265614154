import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import {
  Typography,
  Input,
  Button,
  Select,
  Form,
  Col,
  Row,
  DatePicker,
  message,
  Table,
  Radio,
  Popconfirm,
} from "antd";
import moment from "moment";
import { platformApi } from "../../../../../api";
import { getUUID } from "../../../../../_helpers/utils";
import { set } from "lodash";
const { Title, Text } = Typography;
const { Option } = Select;
const MaterialForm = (props) => {
  const {
    form,
    id,
    jobNo,
    setJobNo,
    partsData,
    setPartsData,
    mechanic,
    setMechanic,
    editData,
    editable,
    onEdit,
    modifyType,
    initialData
  } = props;

  const issueTypes = [
    { key: "Foc", title: "FOC" },
    { key: "Paid", title: "PAID" },
  ];
  const partItemData = {
    id: getUUID(),
    partNumber: {},
    // "mechanic": '',
    issueType: "PAID",
    issueDate: moment(Date.now()),
    quantity: "",
    mrp: "",
    grandTotal: 0,
    remove: "",
  };
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const history = useHistory();
  const location = useLocation();
  const [branch, setBranch] = useState("");
  const [itemList, setItemList] = useState([partItemData]);
  const [selectItemObj, setSelectItemObj] = useState({})
  const [customer, setCustomer] = useState("");
  const [mcid, setMcid] = useState("");
  const [grandTtl, setGrandTotal] = useState(0);
  const [quantity, setQuantity] = useState();
  const [mrp, setMrp] = useState();
  const [deleteData, setDeleteData] = useState([]);
  const [deletePartData, setDeletePartData] = useState([]);
  const [pid, setPid] = useState([]);
  const [pBranch, setPBranch] = useState("");
  const [btnFlag, setBtnFlag] = useState(false);
  const [quantityValidation, setQuantityValidation] = useState({})
  const [spareInventory, setSpareInventory] = useState({})
  const [initialPartDetails, setInitialPartDetails] = useState({})
  const [isJobOrderCompleted, setIsJobOrderCompleted] = useState(true)
  const partsRef = {};
  const [totalPartCharge, setTotalPartCharge] = useState(0)

  //Edit Data
  useEffect(() => {
    if (editData) {
      console.log("editData", editData);
      setGrandTotal(editData.netAmount ? editData.netAmount : 0);
      const updateItemList = [...editData.parts];
      const jobStatus = editData.job?.jobStatus === "Proforma Invoice" ? true : false;
      setIsJobOrderCompleted(jobStatus);
      for (let i = 0; i < updateItemList.length; i++) {
        if (
          !partsData.some(
            (tempItem) => updateItemList[i].part.id === tempItem.id
          )
        ) {
          platformApi
            .get("/api/partsMaster/" + updateItemList[i].part.id)
            .then((res) => {
              let { data } = res;
              if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  setPartsData([...partsData, response.data]);
                }
              }
            });
        }
        updateItemList[i].partNumber = updateItemList[i].part;
        updateItemList[i].partNumber.partName = updateItemList[i].partName;
        // updateItemList[i].part.partName = updateItemList[i].partName
        setMrp(updateItemList[i].part.mrp);
      }
      setItemList(updateItemList);
      if (!jobNo.some((tempItem) => editData.job.id === tempItem.id)) {
        platformApi.get("/api/jobOrder/" + editData.job.id).then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setJobNo([...jobNo, response.data]);
              form.setFieldsValue({
                branch: response.data.branch.name
              })
            }
          }
        });
      }
      let str = `${editData.job.customer && editData.job.customer.name} - ${editData.job.customerPhone
        }`;
      setFieldsValue({
        JobNo: editData.job.id,
        mechanic: editData.mechanic && editData.mechanic.id,
        customer: str,
        registerNo: editData.job.vehicle && editData.job.vehicle.registerNo,
        engineNo: editData.job.vehicle && editData.job.vehicle?.engineNo,
        chassisNo: editData.job.vehicle && editData.job.vehicle?.chassisNo,
        model:
          editData.job.vehicle && editData.job.vehicle?.vehicle.modelName
            ? editData.job.vehicle.vehicle.modelName
            : "",
        branch: editData?.branch?.name
      });
      setPBranch(editData.branch && editData.branch.id);
      for (let item of editData.parts) {
        pid.push(item.id);
      }
      setPid(pid);
    }
    // getParts();
  }, [editData]);
  //Props
  useEffect(() => {
    if (location.state) {
      console.log("Props", location.state.jobNo);
      setFieldsValue({
        JobNo: location.state.jobNo,
      });
      if (!jobNo.some((tempItem) => location.state.jobNo === tempItem.id)) {
        platformApi.get("/api/jobOrder/" + location.state.jobNo).then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setJobNo([...jobNo, response.data]);
              form.setFieldsValue({
                branch: response.data?.branch.name
              })
            }
          }
        });
      }
      fetchJobDetails(location.state.jobNo && location.state.jobNo);
    }
  }, [location.state]);

  const deleteParts = (e) => {
    const deleteSource = itemList.filter((item) => item.id == e);
    if (pid.indexOf(e) >= 0) {
      deleteData.push(e);
    }
    setDeleteData(deleteData);
    for (let i = 0; i < deleteSource.length; i++) {
      deletePartData.push(deleteSource[i]);
    }
    setDeletePartData(deletePartData);
  };
  //setBranch
  useEffect(() => {
    platformApi.get("/api/user/currentUser").then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setBranch(response.data.profile.branch[0].id);
          setPBranch(response.data.profile.branch[0].id);
        }
      }
    });
    platformApi.post("/api/sparesInventory/getAllByBranch").then((res) => {
      const spareObject = {}
      for (const spare of res.data.response.data) {
        const partNumber = spare.partNo.partNumber
        spareObject[partNumber] = spare
      }
      setSpareInventory(spareObject)
    })
  }, []);
  useEffect(() => {
    console.log({ initialData });
    if (initialData?.parts) {
      const partDetails = {}
      for (const part of initialData.parts) {
        const partNumber = part.part.partNumber
        partDetails[partNumber] = part
      }
      setInitialPartDetails(partDetails)
    }
  }, [initialData])
  //ItemList
  useEffect(() => {
    if (
      itemList &&
      itemList?.length > 0 &&
      (itemList[itemList?.length - 1].partNumber.partNumber ||
        itemList[itemList?.length - 1].quantity?.length > 0)
    ) {
      handleAdd();
    }

    const selectItem = {}
    let totalCharge=0
    console.log(itemList);
    for (const item of itemList) {
      selectItem[item.partNumber.id] = item.partNumber
      if(item.mrp!="") {
        totalCharge+=roundOf(item.mrp)*roundOf(item.quantity)
      }
      console.log(totalPartCharge,totalCharge);

    }
    setTotalPartCharge(roundOf(totalCharge))

    setSelectItemObj(selectItem)
  }, [itemList]);

  //Handle Add
  const handleAdd = () => {
    let newArr = [...itemList, partItemData];
    setItemList(newArr);
  };

  // Round function
  const roundOf = (number) => {
    if (number == null) return number;
    const roundedNumber = parseFloat(number).toFixed(2);
    return parseFloat(roundedNumber);
  };
  //Render Column
  const renderColumns = () => {
    const columns = [
      {
        title: "PartNumber",
        dataIndex: "partNumber",
        key: "partNumber",
        width: 220,
        render: (record, data, key) => (
          <Form.Item>
            <Select
              dropdownStyle={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
              onFocus={event => {
                console.log({ partNumber: data.partNumber.partNumber });
                setQuantityValidation(prev => ({ ...prev, [data.partNumber.partNumber]: false }))
                const search = {
                  module: "sparesInventories",
                  column: "partNumber",
                  searchString: "",
                  searchColumns: ["partNumber", "partName"],
                  setValue: setPartsData,
                };
                searchParts(search);
                if (partsData?.length === 1) {
                  handlePartDetail(partsData[0].id, data);
                  partsRef[key]?.focus();
                }
              }}

              onChange={(event) => {
                handlePartDetail(event, data);
              }}
              onSearch={async (event) => {
                if (event) {
                  if (event.includes("ocpart") || event.includes("OCPART")) {
                    const newItemList = [...itemList];
                    if (event !== undefined) {
                      const pName = event.split("-")[1];
                      for (let item of newItemList) {
                        if (item.id === data.id) {
                          let data = {
                            id: item.id,
                            partNumber: "OCPART",
                            partName: pName,
                          };
                          item.partNumber = data;
                          item.mrp = 1;
                          item.quantity = 1;
                          setQuantity(1);
                          setMrp(1);
                        }
                      }
                    }
                    // console.log("newItemList", newItemList)
                    setItemList(newItemList);
                    getParts();
                  } else {
                    const search = {
                      module: "sparesInventories",
                      column: "partNumber",
                      searchString: event,
                      searchColumns: ["partNumber", "partName"],
                      setValue: setPartsData,
                    };
                    searchParts(search);
                    if (partsData?.length === 1) {
                      handlePartDetail(partsData[0].id, data);
                      partsRef[key]?.focus();
                    }
                  }
                }
              }}
              value={
                record?.partNumber == undefined
                  ? "Part Number"
                  : `${record.partNumber} - ${record.partName}`
              }
              // value={(record?.id == undefined) ? "Part Number" : record.id}
              disabled={editable}
              showSearch
              showAction={["click", "focus"]}
              placeholder="Part Number"
              filterOption={(input, option) =>
                option.props.children.some(
                  (child) =>
                    child && child.toLowerCase().indexOf(input.toLowerCase()) >= 0
                )
              }
            >
              {partsData.length &&
                partsData.map((part) => {
                  if (part.id in selectItemObj) return null;
                  return (
                    <Select.Option key={part.id} value={part.id}>
                      {part.partNumber} - {part.partName}
                    </Select.Option>
                  )
                })}
            </Select>
          </Form.Item>
        ),
      },
      {
        title: "Issue Type",
        dataIndex: "issueType",
        key: "issueType",
        width: 125,
        render: (data, record) => (
          <Form.Item>
            <Select
              dropdownStyle={{
                overflowY: "auto",
                overflowX: "hidden",
              }}
              onChange={(event) => {
                handleTypehange(event, record);
              }}
              showSearch
              disabled={editable}
              placeholder="Issue Type"
              value={record && record.issueType ? record.issueType : "PAID"}
            >
              {issueTypes.map((issue) => (
                <Select.Option key={issue.key} value={issue.title}>
                  {issue.title}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        ),
      },
      {
        title: "Issue Date",
        dataIndex: "issueDate",
        key: "issueDate",
        width: 125,
        render: (record) => (
          <span>
            {record
              ? moment(record).format("DD-MM-YYYY")
              : moment(Date.now()).format("DD/MM/YYYY")}
          </span>
        ),
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: 110,
        render: (data, record, index) => (
          <Form.Item
            validateStatus={`${quantityValidation[record.partNumber.partNumber] ? "error" : "success"}`}
            help={quantityValidation[record.partNumber.partNumber]}
          >
            <Input
              key="quantity"
              disabled={editable}
              onChange={(event) => {
                handleQuantity(event, record);
              }}
              ref={(el) => (partsRef[index] = el)}
              placeholder="quantity"
              value={record && record.quantity}
            />
          </Form.Item>
        ),
      },
      {
        title: "MRP",
        dataIndex: "mrp",
        key: "mrp",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="mrp"
              disabled={editable}
              onChange={(event) => {
                handleMRP(event, record);
              }}
              placeholder="Mrp"
              value={record && record.mrp}
            />
          </Form.Item>
        ),
      },
      {
        title: "Action",
        dataIndex: "remove",
        key: "x",
        width: 100,
        render: (data, record) => (
          <Form.Item>
            {itemList.length >= 1 ? (
              editable ? null : (
                <Popconfirm
                  title="Sure to delete?"
                  onConfirm={() => {
                    handleDelete(record.id);
                  }}
                  >
                  <a style={{ color: "#6c7b8a" }}>Delete</a>
                </Popconfirm>
              )
            ) : null}
          </Form.Item>
        ),
      },
    ];
    return columns;
  };
  //Handle Delete
  const handleDelete = (selectedId) => {
    const updatedDataSource = itemList.filter((item) => item.id !== selectedId);
    let grandTotal = 0;
    for (let i = 0; i < updatedDataSource.length; i++) {
      if (
        updatedDataSource[i].mrp > 0 &&
        parseFloat(updatedDataSource[i].quantity) > 0
      ) {
        let total = (
          parseFloat(updatedDataSource[i].quantity) *
          parseFloat(updatedDataSource[i].mrp)
        ).toFixed(2);
        updatedDataSource[i].grandTotal = parseFloat(total);
      }
    }
    for (let i = 0; i < updatedDataSource.length; i++) {
      grandTotal =
        parseFloat(grandTotal) + parseFloat(updatedDataSource[i].grandTotal);
      grandTotal = grandTotal.toFixed(2);
    }
    setItemList(updatedDataSource);
    deleteParts(selectedId);
  };
  //Handle Quantity
  const checkPartQuantity = (quantity, partNumber) => {
    if (Number(quantity) > spareInventory[partNumber].phyQuantity) {
      setQuantityValidation(prev => ({
        ...prev,
        [partNumber]: `Available ${spareInventory[partNumber].phyQuantity}`
      }))
      return false
    }
    setQuantityValidation(prev => ({
      ...prev,
      [partNumber]: false
    }))
    return true
  }


  const handleInventoryForModify = (currentQuantity, partNumber) => {
    if (currentQuantity == null || partNumber == null) return;
    const inventory = spareInventory[partNumber]
    const prevPart = initialPartDetails[partNumber]
    const removeQuantity = currentQuantity - prevPart.quantity
    if (prevPart.quantity >= currentQuantity || inventory.phyQuantity >= removeQuantity) {
      return true
    } else {
      const availableQuantity = inventory.phyQuantity + parseFloat(prevPart.quantity)
      setQuantityValidation(prev => ({
        ...prev,
        [partNumber]: `Available ${availableQuantity}`
      }))
      return false
    }
  }


  const handleInventory = (quantity, partNumber) => {
    if (quantity === "" || partNumber == null || spareInventory[partNumber] == null) return;
    setQuantityValidation(prev => ({ ...prev, [partNumber]: false }))
    if (initialPartDetails[partNumber]) {
      return handleInventoryForModify(Number(quantity), partNumber)
    } else {
      return checkPartQuantity(Number(quantity), partNumber)
    }
  }
  const handleQuantity = (event, record) => {
    console.log({ record });
    setQuantity(event.target.value);
    let newItemList = [...itemList];
    for (let item of newItemList) {
      if (item.id === record.id) {
        item.quantity = event.target.value;
        break;
      }
    }
    setItemList(newItemList);
    handleInventory(event.target.value, record.partNumber.partNumber)
  };
  //HandleMRP
  const handleMRP = (event, record) => {
    setMrp(event.target.value);
    let newItemList = [...itemList];
    for (let item of newItemList) {
      if (item.id === record.id) {
        item.mrp = event.target.value;
        break;
      }
    }
    setItemList(newItemList);
  };
  //Handle Mechanic
  const handleMechanic = (event) => {
    setMcid(event);
    setFieldsValue({
      mechanic: event,
    });
    // let newItemList = [...itemList];

    // for (let item of newItemList) {
    //     if (item.id === data.id) {
    //         item.mechanic = event
    //         break;
    //     }
    // }
    // setItemList(newItemList);
  };
  //Handle typeChange
  const handleTypehange = (event, data) => {
    let newItemList = [...itemList];
    for (let item of newItemList) {
      if (item.id === data.id) {
        item.issueType = event;
        break;
      }
    }
    setItemList(newItemList);
  };
  //Handle PartDetail
  const handlePartDetail = async (event, data) => {
    const newItemList = [...itemList];
    if (event !== undefined) {
      partsData.map((part) => {
        if (part.id === event) {
          for (let item of newItemList) {
            if (item.id === data.id) {
              item.partNumber = part;
              item.mrp = part.mrp;
              item.quantity = 1;
              setQuantity(1);
              setMrp(part.mrp);
            }
          }
        }
      });
    }
    setItemList(newItemList);
    getParts();
  };
  //Handle Calculation
  useEffect(() => {
    let grandTotal = 0;
    let newItemList = [...itemList];
    // console.log(quantity)
    for (let i = 0; i < newItemList.length; i++) {
      if (quantity > 0 || mrp > 0) {
        if (newItemList[i].mrp > 0 && parseFloat(newItemList[i].quantity) > 0) {
          let total = (
            parseFloat(newItemList[i].quantity) * parseFloat(newItemList[i].mrp)
          ).toFixed(2);
          newItemList[i].grandTotal = parseFloat(total);
        }
      }
    }
    for (let i = 0; i < newItemList.length; i++) {
      grandTotal =
        parseFloat(grandTotal) + parseFloat(newItemList[i].grandTotal);
      grandTotal = grandTotal.toFixed(2);
    }
    console.log("newItemList", newItemList, grandTotal);
    setGrandTotal(grandTotal);
    setItemList(newItemList);
    setQuantity(undefined);
  }, [quantity, mrp]);
  //Handle Submit
  const validateInventory = (itemList) => {
    console.log({ itemList });
    const keys = itemList.map(item => { if (item.partNumber.partNumber) return { quantity: item.quantity, partNumber: item.partNumber.partNumber } }).filter(item => item != null)
    console.log({ keys });
    for (const key of keys) {
      // console.log(checkPartQuantity(key.quantity, key.partNumber));
      if (handleInventory(key.quantity, key.partNumber) === false) return false
    }
    return true
  }
  const handleSubmit = () => {
    const isValid = validateInventory(itemList)
    if (!isValid) return message.error("Stock Not available in Inventory");
    form.validateFields((validationError) => {
      let newItemList = [...itemList];
      // eslint-disable-next-line for-direction
      if (newItemList.length > 1) {
        newItemList = newItemList.filter((item) => {
          return item.partNumber && item.quantity;
        });
        // newItemList = newItemList.slice(0, newItemList.length - 1)
      }

      setItemList(newItemList);
      const data = {
        jobOrder: getFieldValue("JobNo"),
        currentDate: moment(Date.now()),
        vehicle: getFieldValue("registerNo") && getFieldValue("registerNo"),
        model: getFieldValue("model") && getFieldValue("model"),
        engineNo: getFieldValue("engineNo") && getFieldValue("engineNo"),
        chassisNo: getFieldValue("chassisNo") && getFieldValue("chassisNo"),
        customer: customer,
        mechanic: mcid,
        materialItemInvoice: newItemList,
        netAmount: grandTtl,
        branch: branch,
        deleteData: deleteData,
        deletePartData: deletePartData,
      };
      if (validationError === null) {
        setBtnFlag(true);
        if (editData && editData?.id) {
          platformApi
            .put(`/api/materialIssue/${id}`, data)
            .then((result) => {
              let { data } = result;
              if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  message.success("Material Issue Modified Successfully");
                  history.push("/autoadmin/material_issue");
                }
              }
            })
            .catch((err) => {
              console.error("Material Add Error: ", err);
              message.error("Couldn't update Material");
            });
        } else {
          platformApi
            .post("/api/materialIssue/", data)
            .then((result) => {
              let { data } = result;
              if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  message.success("Material Issue Added Successfully");
                  history.push("/autoadmin/material_issue");
                }
              }
            })
            .catch((err) => {
              console.log(err);
              message.error("Couldn't Add Material");
            });
        }
      } else {
        message.error("select atleast one item list");
      }
    });
  };
  //Handle Cancel
  const handleCancel = () => {
    form.resetFields();
    setItemList([partItemData]);
    setJobNo([]);
    setPartsData([]);
    setMechanic([]);
    setDeleteData([]);
    setDeletePartData([]);
    history.push("/autoadmin/material_issue");
  };

  const searchJobNo = (search) => {
    platformApi
      .post("/api/options/get/", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 100,
        page: 1,
        except: null,
      })
      .then((res) => {
        search.setValue(res.data.response);
      });
  };

  const searchMechanic = (search) => {
    platformApi
      .post("/api/options/get/", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 100,
        page: 1,
        except: null,
      })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let mechanic = data.response.filter((item) => {
            return (
              item.profile &&
              item.profile.department.role === "Mechanic" &&
              item.status === true
            );
          });
          search.setValue(mechanic);
          // setMechanic(mechanic);
        }
      });
  };

  const searchParts = async (search) => {
    const res = await platformApi.post("/api/options/get/", {
      module: search.module,
      column: search.column,
      searchString: search.searchString,
      fields: search.fields,
      size: 100,
      page: 1,
      except: null,
      branch: pBranch,
    });
    let parts = [];
    // console.log("Response", res.data)
    for (let i = 0; i < res.data.response?.length; i++) {
      const element = res.data.response[i];
      parts.push(element.partNo);
    }
    search.setValue(parts);
    return 1;
    // .then((res) => {
    //     let parts = [];
    //     for (let i = 0; i < res.data.response.length; i++) {
    //         const element = res.data.response[i];
    //         parts.push(element.partNo)
    //     }
    //     // search.setValue(() => {
    //     //     return parts;
    //     // })
    //     setPartsData(parts)
    //     return parts;
    // });
  };

  const fetchJobDetails = (value) => {
    setFieldsValue({
      JobNo: value,
    });
    platformApi.get("/api/jobOrder/" + value).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          // console.log("Response", response.data)
          if (response.data) {
            let str = `${response.data.customer && response.data.customer.name
              } - ${response.data.customerPhone && response.data.customerPhone}`;
            const jobStatus = response.data.jobStatus === "Proforma Invoice" ? true : false;
            setIsJobOrderCompleted(jobStatus);
            setFieldsValue({
              registerNo:
                response.data.vehicle && response.data.vehicle.registerNo,
              engineNo:
                response.data.vehicle && response.data.vehicle?.engineNo,
              chassisNo:
                response.data.vehicle && response.data.vehicle?.chassisNo,
              model:
                response.data.vehicle &&
                  response.data.vehicle?.vehicle.modelName
                  ? response.data.vehicle.vehicle.modelName
                  : "",
              customer: str,
              mechanic: response.data.mechanic && response.data.mechanic.id,
              branch: response.data?.branch.name
            });
            setMcid(response.data.mechanic && response.data.mechanic.id);
            setCustomer(response.data.customer && response.data.customer.id);
            setPBranch(response.data.branch && response.data.branch.id);
          }
        }
      }
    });
  };
  //Get Parts
  const getParts = () => {
    platformApi
      .post(`/api/sparesInventory/branch/get`, {
        page: 1,
        size: 100,
        searchString: "",
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            let parts = [];
            // console.log("response", response.data)
            for (let item of response.data) {
              if (item.phyQuantity > 0) {
                parts.push(item.partNo);
              }
            }
            // console.log("parts", parts)
            // setPartsData(response.data.partsMaster);
            setPartsData(parts);
          }
        } else {
          message.error("Unable to fetch Parts", 2);
        }
      });
    // let data = {
    //     module: 'partsMasters',
    //     size: 100,
    //     column: 'number',
    //     fields: ['number', 'name']
    // }
    // platformApi.post('/api/options/get', data)
    //     .then((res) => {
    //         const { data } = res;
    //         setPartsData(data.response);
    //     }).catch((err) => {
    //         console.error("Part Fetch Error: ", err);
    //         message.error("Couldn't Fetch Parts");
    //     });
  };
  return (
    <div>
      <Form className="material-form" form={form}>
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Form.Item
              colon={false}
              className="purchase-form-element"
              label={<Text>Ass.Job No</Text>}
            >
              {getFieldDecorator("JobNo", {
                rules: [
                  {
                    required: true,
                    message: "Select Job No",
                  },
                ],
              })(
                <Select
                  showAction={["click", "focus"]}
                  showSearch
                  filterOption={null}
                  disabled={editable}
                  placeholder="Job No"
                  onSearch={(e) => {
                    const search = {
                      module: "jobOrders",
                      column: "jobNo",
                      searchString: e,
                      searchColumns: ["jobNo"],
                      setValue: setJobNo,
                    };
                    searchJobNo(search);
                  }}
                  onSelect={fetchJobDetails}
                >
                  {jobNo.length &&
                    jobNo.map((item) => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.jobNo}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              colon={false}
              className="purchase-form-element"
              label={<Text>Branch</Text>}
            >
              {getFieldDecorator("branch", {
                rules: [
                  {
                    required: false,
                    message: "Select Branch",
                  },
                ],
              })(
                <Input placeholder="Branch" disabled={true} />
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item colon={false} label="Mechanic">
              {getFieldDecorator("mechanic", {
                rules: [
                  {
                    required: true,
                    message: "Mechanic",
                  },
                ],
              })(
                <Select
                  key="mechanic"
                  showAction={["click", "focus"]}
                  filterOption={null}
                  showSearch
                  placeholder="Mechanic"
                  disabled={editable}
                  onSearch={(e) => {
                    const search = {
                      module: "user",
                      column: "employeeName",
                      searchString: e,
                      searchColumns: ["employeeName"],
                      setValue: setMechanic,
                    };
                    searchMechanic(search);
                  }}
                  onSelect={(event) => {
                    handleMechanic(event);
                  }}
                >
                  {mechanic.length &&
                    mechanic.map((obj) => (
                      <Select.Option key={obj.id} value={obj.id}>
                        {obj.profile && obj.profile.employeeName
                          ? obj.profile.employeeName
                          : obj.profile.employeeId}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item colon={false} label="Customer">
              {getFieldDecorator("customer", {
                rules: [
                  {
                    required: false,
                    message: "Customer No",
                  },
                ],
              })(<Input placeholder="Customer." disabled={true} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={6}>
            <Form.Item colon={false} label="Vehicle">
              {getFieldDecorator("registerNo", {
                rules: [
                  {
                    required: false,
                    message: "Register No",
                  },
                ],
              })(<Input placeholder="Register No." disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item colon={false} label="Model">
              {getFieldDecorator("model", {
                rules: [
                  {
                    required: false,
                    message: "Model Name",
                  },
                ],
              })(<Input placeholder="Model" disabled={true}></Input>)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item colon={false} label="Engine No">
              {getFieldDecorator("engineNo", {
                rules: [
                  {
                    required: false,
                    message: "Engine No",
                  },
                ],
              })(<Input placeholder="EngineNo." disabled={true} />)}
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item colon={false} label="Chassis No">
              {getFieldDecorator("chassisNo", {
                rules: [
                  {
                    required: false,
                    message: "Chassis No",
                  },
                ],
              })(<Input placeholder="ChasisNo." disabled={true} />)}
            </Form.Item>
          </Col>
        </Row>

        <Table
          className="parts-invoice-table"
          rowKey={(record) => {
            record.id;
          }}
          columns={renderColumns()}
          dataSource={itemList}
          tableLayout="fixed"
          pagination={false}
          style={{ marginTop: "1rem", marginBottom: "1rem" }}
        />
        <br />
        <Row gutter={1} style={{position:"relative",left:"48rem"}}>
          <Col span={6}>
            <Form.Item
              className="form-element"
              label="Part Charge"
            >
           <Input
                    placeholder="Part Charge"
                    disabled={true}
                    value={totalPartCharge}
                  />
            </Form.Item>
          </Col>
        </Row>

        <div style={{ float: "right" }}>
          <Button
            style={{ marginRight: "1rem" }}
            size="large"
            onClick={() => handleCancel()}
          >
            Cancel
          </Button>
          {
            !isJobOrderCompleted && (
              <Button
                type="primary"
                size="large"
                disabled={btnFlag}
                onClick={() => (!editable ? handleSubmit() : onEdit(false))}
              >
                {!editable ? "Save" : "Modify"}
              </Button>
            )
          }
        </div>
      </Form>
    </div>
  );
};
const WrappedMaterialForm = Form.create()(MaterialForm);

export default WrappedMaterialForm;
