import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  DatePicker,
  Checkbox,
  Select,
  Row,
  Col,
  Typography,
} from "antd";
import "./price.less";
import moment from "moment";
import { platformApi } from "../../../../api";
import { formatValue } from '../../../../utils'
import { any } from "prop-types";
const { Item } = Form;
const { Text, Title } = Typography;

const VehiclePriceForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const [model, setModel] = useState([]);

  const [ins1, setIns1] = useState(true);
  const [ins2, setIns2] = useState(true);
  const [ins3, setIns3] = useState(true);
  const [ins4, setIns4] = useState(true);
  const [errorObj, setErrorObj] = useState({});
  const { form, open, close, data, modify } = props;
  const { getFieldDecorator } = form;
  useEffect(() => {
    // platformApi.get("/api/vehicleMaster/").then((res) => {
    //   if (res.data.response.data.length !== 0) setModel(res.data.response.data);
    // });
    platformApi
      .post("/api/options/get", {
        module: "vehicleMasters",
        column: "modelName",
        fields: ["modelCode"],
      })
      .then((res) => {
        console.log(res.data.response)
        setModel(res.data.response);
      });
    setEditable(props.editable);
    if (data) {
      data.insurance1plus5 && setIns1(false);
      data.insurance5plus5 && setIns2(false);
      data.insurance1plus5ZD && setIns3(false);
      data.insurance5plus5ZD && setIns4(false);
      form.setFieldsValue({
        model: data.vehicleModel && data.vehicleModel.id,
        rtax: data.roadTax,
        from: moment(data.priceValidFrom),
        to: moment(data.priceValidTill),
        hcharges: data.handlingCharges,
        rfee: data.registrationFee,
        rsa: data.rsa,
        amc: data.amc,
        exshow: data.showroomPrice,
        warrantyPrice: data.warrantyPrice,
        insuranceValue: data.insuranceValue,
        insuranceType: data.insurance,
        insurance1plus5: data.insurance1plus5,
        insurance5plus5: data.insurance5plus5,
        insurance1plus5ZD: data.insurance1plus5ZD,
        insurance5plus5ZD: data.insurance5plus5ZD,
      });
      if (data.insurance1plus5) {
        let total =
          data.insurance1plus5 +
          data.roadTax +
          data.handlingCharges +
          data.warrantyPrice +
          data.showroomPrice +
          data.registrationFee;
        form.setFieldsValue({
          t1: total,
        });
      }
      if (data.insurance5plus5) {
        let total =
          data.insurance5plus5 +
          data.roadTax +
          data.handlingCharges +
          data.warrantyPrice +
          data.showroomPrice +
          data.registrationFee;
        form.setFieldsValue({
          t2: total,
        });
      }
      if (data.insurance1plus5ZD) {
        let total =
          data.insurance1plus5ZD +
          data.roadTax +
          data.handlingCharges +
          data.warrantyPrice +
          data.showroomPrice +
          data.registrationFee;
        form.setFieldsValue({
          t3: total,
        });
      }
      if (data.insurance5plus5ZD) {
        let total =
          data.insurance5plus5ZD +
          data.roadTax +
          data.handlingCharges +
          data.warrantyPrice +
          data.showroomPrice +
          data.registrationFee;
        form.setFieldsValue({
          t4: total,
        });
      }
    }
  }, [data, open, props.editable]);

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        setLoading(true);
        const showroomPrice = parseFloat(form.getFieldValue("exshow"));
        const roadTax = parseFloat(form.getFieldValue("rtax"));
        const priceValidFrom = form.getFieldValue("from");
        const priceValidTill = form.getFieldValue("to");
        const insurance = form.getFieldValue("insuranceType");
        const registrationFee = parseFloat(form.getFieldValue("rfee"));
        const handlingCharges = parseFloat(form.getFieldValue("hcharges"));
        const rsa = parseFloat(form.getFieldValue("rsa"));
        const amc = parseFloat(form.getFieldValue("amc"));
        const warrantyPrice = parseFloat(form.getFieldValue("warrantyPrice"));
        const insuranceValue = parseFloat(form.getFieldValue("insuranceValue"));
        const vehicleModel = form.getFieldValue("model");
        const insurance1plus5 = parseFloat(
          form.getFieldValue("insurance1plus5")
        );
        const insurance5plus5 = parseFloat(
          form.getFieldValue("insurance5plus5")
        );
        const insurance1plus5ZD = parseFloat(
          form.getFieldValue("insurance1plus5ZD")
        );
        const insurance5plus5ZD = parseFloat(
          form.getFieldValue("insurance5plus5ZD")
        );

        const vehiclePrice = {
          vehicleModel,
          showroomPrice,
          roadTax,
          priceValidFrom,
          priceValidTill,
          insurance,
          warrantyPrice,
          insuranceValue,
          registrationFee,
          handlingCharges,
          rsa,
          amc,
          insurance1plus5,
          insurance5plus5,
          insurance1plus5ZD,
          insurance5plus5ZD,
        };
        data ? (vehiclePrice.id = data.id) : {};
        props.emitData(vehiclePrice);
        clearFields();
        setLoading(false);
        props.close();
      }
    });
  };

  const clearFields = () => {
    form.resetFields();
    setIns1(true);
    setIns2(true);
    setIns3(true);
    setIns4(true);
  };

  const checkDateValidity = (moment, type) => {
    if (type === "to") {
      return moment._d < form.getFieldValue("from");
    } else if (type === "from") {
      if (form.getFieldValue("to")) return form.getFieldValue("to") < moment._d;
      else return false;
    }
  };

  const getVehicleDetails = (id) => {
    platformApi.get(`/api/vehiclePrice/model/${id}`).then((res) => {
    });
  };

  const calculateOnRoad = (event, type) => {
    let showroomPrice = 0,
      roadTax = 0,
      registrationFee = 0,
      handlingCharges = 0,
      rsa = 0,
      amc = 0,
      warrantyPrice = 0,
      insurance1plus5 = 0,
      insurance5plus5 = 0,
      insurance1plus5ZD = 0,
      insurance5plus5ZD = 0;

    if (type === "exshow") {
      showroomPrice = event.target.value;
    } else {
      showroomPrice = form.getFieldValue("exshow");
    }

    if (type === "rtax") {
      roadTax = event.target.value;
    } else {
      roadTax = form.getFieldValue("rtax");
    }

    if (type === "hcharges") {
      handlingCharges = event.target.value;
    } else {
      handlingCharges = form.getFieldValue("hcharges");
    }

    if (type === "rsa") {
      rsa = event.target.value;
    } else {
      rsa = form.getFieldValue("rsa");
    }
    if (type === "amc") {
      amc = event.target.value;
    } else {
      amc = form.getFieldValue("amc");
    }

    if (type === "rfee") {
      registrationFee = event.target.value;
    } else {
      registrationFee = form.getFieldValue("rfee");
    }

    if (type === "warrantyPrice") {
      warrantyPrice = event.target.value;
    } else {
      warrantyPrice = form.getFieldValue("warrantyPrice");
    }

    if (type === "insurance1plus5") {
      insurance1plus5 = event.target.value;
    } else {
      insurance1plus5 = form.getFieldValue("insurance1plus5");
    }
    if (type === "insurance5plus5") {
      insurance5plus5 = event.target.value;
    } else {
      insurance5plus5 = form.getFieldValue("insurance5plus5");
    }
    if (type === "insurance1plus5ZD") {
      insurance1plus5ZD = event.target.value;
    } else {
      insurance1plus5ZD = form.getFieldValue("insurance1plus5ZD");
    }
    if (type === "insurance5plus5ZD") {
      insurance5plus5ZD = event.target.value;
    } else {
      insurance5plus5ZD = form.getFieldValue("insurance5plus5ZD");
    }
    let total = 0;
    if (showroomPrice) {
      showroomPrice = parseInt(showroomPrice);
      total = total + showroomPrice;
    }
    if (roadTax) {
      roadTax = parseInt(roadTax);
      total = total + roadTax;
    }
    if (registrationFee) {
      registrationFee = parseInt(registrationFee);
      total = total + registrationFee;
    }
    if (rsa) {
      rsa = parseInt(rsa);
      total = total + rsa;
    }
    if (amc) {
      amc = parseInt(amc);
      total = total + amc;
    }
    if (warrantyPrice) {
      warrantyPrice = parseInt(warrantyPrice);
      total = total + warrantyPrice;
    }
    if (handlingCharges) {
      handlingCharges = parseInt(handlingCharges);
      total = total + handlingCharges;
    }
    if (insurance1plus5) {
      insurance1plus5 = parseInt(insurance1plus5);
      let total1 = total + insurance1plus5;
      form.setFieldsValue({
        t1: total1,
      });
    } else {
      form.setFieldsValue({
        t1: null,
      });
    }
    if (insurance5plus5) {
      insurance5plus5 = parseInt(insurance5plus5);
      let total2 = total + insurance5plus5;
      form.setFieldsValue({
        t2: total2,
      });
    } else {
      form.setFieldsValue({
        t2: null,
      });
    }
    if (insurance1plus5ZD) {
      insurance1plus5ZD = parseInt(insurance1plus5ZD);
      let total3 = total + insurance1plus5ZD;
      form.setFieldsValue({
        t3: total3,
      });
    } else {
      form.setFieldsValue({
        t3: null,
      });
    }
    if (insurance5plus5ZD) {
      insurance5plus5ZD = parseInt(insurance5plus5ZD);
      let total4 = total + insurance5plus5ZD;
      form.setFieldsValue({
        t4: total4,
      });
    } else {
      form.setFieldsValue({
        t4: null,
      });
    }
  };
  const dropdownSearch = (search) => {
    platformApi
      .post("/api/options/get", {
        module: search.module,
        column: search.column,
        searchString: search.value,
        fields: search.fields,
        searchColumns: search.searchColumns,
      })
      .then((res) => {
        search.setValue(res.data.response);
      });
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return (
    <Modal
      wrapClassName="VehiclePriceModal"
      title={<Title level={4}>Vehicle Price</Title>}
      okText={editable ? "Save" : "Modify"}
      visible={open}
      onCancel={() => {
        clearFields();
        close();
      }}
      okButtonProps={{ loading, disabled: !editable && !modify }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
    >
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Row>
            <Item label={<Text>Vehicle Model</Text>}
              colon={false} required>
              {getFieldDecorator("model", {
                rules: [{ required: true, message: "Select Vehicle Model" }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  onChange={(id) => {
                    getVehicleDetails(id);
                  }}
                  disabled={!editable}
                  placeholder="Vehicle Model"
                  filterOption={null}
                  showSearch
                  onSearch={(e) => {
                    const search = {
                      module: "vehicleMasters",
                      column: "modelName",
                      fields: ["modelCode"],
                      value: e,
                      searchColumns: ["modelCode"],
                      setValue: setModel,
                    };
                    dropdownSearch(search);
                  }}
                >
                  {model.map((code, index) => (
                    <Select.Option key={index} value={code.id}>
                      {code.modelCode + " - " + code.modelName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Row>
          <Row>
            <Item label={<Text>Ex-Showroom Price</Text>}
              validateStatus={errorObj && errorObj.exshow ? "error" : ""}
              help={errorObj && errorObj.exshow}
              colon={false} required>
              {getFieldDecorator("exshow", {
                rules: [{ required: true, message: "Enter Ex-Showroom Price" }],
              })(
                <Input
                  // type="number"
                  pattern="[0-9]+(\.[0-9][0-9]?)?"
                  onWheel={(event) => event.currentTarget.blur()}
                  addonBefore="&#8377;"
                  disabled={!editable}
                  placeholder="Ex-Showroom Price"
                  onChange={(e) => calculateOnRoad(e, "exshow")}
                  onKeyUp={e =>
                    form.setFieldsValue({
                      exshow: formatValue(e, "noWithDot")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setErrorObj({
                        ...errorObj,
                        exshow: "Enter Valid Ex-Showroom Price"
                      })
                    } else {
                      delete errorObj.exshow
                    }
                  }}
                />
              )}
            </Item>
          </Row>
        </Col>
        <Col span={11}>
          <Row type="flex" align="middle" justify="space-between">
            <Col span={14}>
              <Checkbox
                disabled={!editable}
                checked={!ins1}
                onChange={() => {
                  setIns1(!ins1);
                  !ins1 && form.resetFields(["insurance1plus5"]);
                }}
              >
                <Text>1+5</Text>
              </Checkbox>
            </Col>
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance1plus5 ? "error" : ""}
                help={errorObj && errorObj.insurance1plus5}
              >
                {getFieldDecorator("insurance1plus5", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    addonBefore="&#8377;"
                    disabled={!editable || ins1}
                    id="insurance1plus5"
                    onChange={(e) => calculateOnRoad(e, "insurance1plus5")}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance1plus5: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance1plus5: "Enter Valid insurance1plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance1plus5
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={14}>
              <Checkbox
                disabled={!editable}
                checked={!ins2}
                onChange={() => {
                  setIns2(!ins2);
                  !ins2 && form.resetFields(["insurance5plus5"]);
                }}
              >
                <Text>5+5</Text>
              </Checkbox>
            </Col>
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance5plus5 ? "error" : ""}
                help={errorObj && errorObj.insurance5plus5}
              >
                {getFieldDecorator("insurance5plus5", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    addonBefore="&#8377;"
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!editable || ins2}
                    id="insurance5plus5"
                    onChange={(e) => calculateOnRoad(e, "insurance5plus5")}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance5plus5: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance5plus5: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance5plus5
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={14}>
              <Checkbox
                disabled={!editable}
                checked={!ins3}
                onChange={() => {
                  setIns3(!ins3);
                  !ins3 && form.resetFields(["insurance1plus5ZD"]);
                }}
              >
                <Text>1+5 Zero Dep</Text>
              </Checkbox>
            </Col>
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance1plus5ZD ? "error" : ""}
                help={errorObj && errorObj.insurance1plus5ZD}
              >
                {getFieldDecorator("insurance1plus5ZD", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    addonBefore="&#8377;"
                    // type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!editable || ins3}
                    id="insurance1plus5ZD"
                    onChange={(e) => calculateOnRoad(e, "insurance1plus5ZD")}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance1plus5ZD: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance1plus5ZD: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance1plus5ZD
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col span={14}>
              <Checkbox
                checked={!ins4}
                disabled={!editable}
                onChange={() => {
                  setIns4(!ins4);
                  !ins4 && form.resetFields(["insurance5plus5ZD"]);
                }}
              >
                <Text>5+5 Zero Dep</Text>
              </Checkbox>
            </Col>
            <Col span={10}>
              <Item colon={false}
                validateStatus={errorObj && errorObj.insurance5plus5ZD ? "error" : ""}
                help={errorObj && errorObj.insurance5plus5ZD}
              >
                {getFieldDecorator("insurance5plus5ZD", {
                  rules: [
                    { required: false, message: "Select Insurance Type" },
                  ],
                })(
                  <Input
                    addonBefore="&#8377;"
                    type="number"
                    pattern="[0-9]+(\.[0-9][0-9]?)?"
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!editable || ins4}
                    id="insurance5plus5ZD"
                    onChange={(e) => { calculateOnRoad(e, "insurance5plus5ZD") }}
                    onKeyUp={e =>
                      form.setFieldsValue({
                        insurance5plus5ZD: formatValue(e, "noWithDot")
                      })}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setErrorObj({
                          ...errorObj,
                          insurance5plus5ZD: "Enter Valid insurance5plus5 Price"
                        })
                      } else {
                        delete errorObj.insurance5plus5ZD
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Road Tax</Text>}
            validateStatus={errorObj && errorObj.rtax ? "error" : ""}
            help={errorObj && errorObj.rtax}
            colon={false}>
            {getFieldDecorator("rtax", {
              rules: [{ required: false, message: "Enter Road Tax" }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onKeyUp={e =>
                  form.setFieldsValue({
                    rtax: formatValue(e, "noWithDot")
                  })}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      rtax: "Enter Valid insurance5plus5 Price"
                    })
                  } else {
                    delete errorObj.rtax
                  }
                }}
                onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                disabled={!editable}
                placeholder="Road Tax"
                onChange={(e) => calculateOnRoad(e, "rtax")}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Warranty Price</Text>}
            validateStatus={errorObj && errorObj.warrantyPrice ? "error" : ""}
            help={errorObj && errorObj.warrantyPrice}
            colon={false}>
            {getFieldDecorator("warrantyPrice", {
              rules: [{ required: false, message: "Enter Warranty Price" }],
            })(
              <Input
                addonBefore="&#8377;"
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onKeyUp={e =>
                  form.setFieldsValue({
                    warrantyPrice: formatValue(e, "noWithDot")
                  })}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      warrantyPrice: "Enter Valid insurance5plus5 Price"
                    })
                  } else {
                    delete errorObj.warrantyPrice
                  }
                }}
                onWheel={(event) => event.currentTarget.blur()}
                style={{ width: "100%" }}
                disabled={!editable}
                placeholder="Warranty Price"
                onChange={(e) => calculateOnRoad(e, "warrantyPrice")}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Registration Fee</Text>}
            validateStatus={errorObj && errorObj.rfee ? "error" : ""}
            help={errorObj && errorObj.rfee}
            colon={false}>
            {getFieldDecorator("rfee", {
              rules: [{ required: false, message: "Enter Registration Fee" }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onKeyUp={e =>
                  form.setFieldsValue({
                    rfee: formatValue(e, "noWithDot")
                  })}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      rfee: "Enter Valid insurance5plus5 Price"
                    })
                  } else {
                    delete errorObj.rfee
                  }
                }}
                onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                disabled={!editable}
                placeholder="Registration Fee"
                onChange={(e) => calculateOnRoad(e, "rfee")}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Handling Charges</Text>}
            validateStatus={errorObj && errorObj.hcharges ? "error" : ""}
            help={errorObj && errorObj.hcharges}
            colon={false}>
            {getFieldDecorator("hcharges", {
              rules: [{ required: false, message: "Enter Handling Charges" }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onKeyUp={e =>
                  form.setFieldsValue({
                    hcharges: formatValue(e, "noWithDot")
                  })}
                onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                disabled={!editable}
                placeholder="Handling Charges"
                onChange={(e) => calculateOnRoad(e, "hcharges")}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      hcharges: "Enter Valid insurance5plus5 Price"
                    })
                  } else {
                    delete errorObj.hcharges
                  }
                }}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>RSA</Text>}
            validateStatus={errorObj && errorObj.rsa ? "error" : ""}
            help={errorObj && errorObj.rsa}
            colon={false}>
            {getFieldDecorator("rsa", {
              rules: [{ required: false, message: "Enter RSA" }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onKeyUp={e =>
                  form.setFieldsValue({
                    rsa: formatValue(e, "noWithDot")
                  })}
                onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                disabled={!editable}
                placeholder="Enter RSA"
                onChange={(e) => calculateOnRoad(e, "rsa")}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      rsa: "Enter Valid insurance5plus5 Price"
                    })
                  } else {
                    delete errorObj.rsa
                  }
                }}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>AMC</Text>}
            validateStatus={errorObj && errorObj.amc ? "error" : ""}
            help={errorObj && errorObj.amc}
            colon={false}>
            {getFieldDecorator("amc", {
              rules: [{ required: false, message: "Enter AMC" }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onKeyUp={e =>
                  form.setFieldsValue({
                    amc: formatValue(e, "noWithDot")
                  })}
                onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                disabled={!editable}
                placeholder="Enter AMC"
                onChange={(e) => calculateOnRoad(e, "amc")}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setErrorObj({
                      ...errorObj,
                      amc: "Enter Valid insurance5plus5 Price"
                    })
                  } else {
                    delete errorObj.amc
                  }
                }}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={5}>
          <Item label={<Text>Total - [1+5]</Text>} colon={false}>
            {getFieldDecorator("t1", {
              rules: [{ required: false }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onWheel={(event) => event.currentTarget.blur()}
                disabled={true}
                placeholder="On-Road"
              />
            )}
          </Item>
        </Col>
        <Col span={5}>
          <Item label={<Text>Total - [5+5]</Text>} colon={false}>
            {getFieldDecorator("t2", {
              rules: [{ required: false }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onWheel={(event) => event.currentTarget.blur()}
                disabled={true}
                placeholder="On-Road"
              />
            )}
          </Item>
        </Col>
        <Col span={5}>
          <Item label={<Text>Total - [1+5] ZD</Text>} colon={false}>
            {getFieldDecorator("t3", {
              rules: [{ required: false }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onWheel={(event) => event.currentTarget.blur()}
                disabled={true}
                placeholder="On-Road"
              />
            )}
          </Item>
        </Col>
        <Col span={5}>
          <Item label={<Text>Total - [5+5] ZD</Text>} colon={false}>
            {getFieldDecorator("t4", {
              rules: [{ required: false, message: "Enter Road Tax" }],
            })(
              <Input
                // type="number"
                pattern="[0-9]+(\.[0-9][0-9]?)?"
                onWheel={(event) => event.currentTarget.blur()}
                disabled={true}
                placeholder="On-Road"
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between" align="middle">
        <Col span={11}>
          <Item label={<Text>Valid From</Text>} colon={false} required>
            {getFieldDecorator("from", {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: "Select Valid Date",
                },
              ],
            })(
              <DatePicker
                format="DD/MM/YYYY"
                allowClear={true}
                style={{ width: "100%" }}
                disabled={!editable}
                disabledDate={(moment) => checkDateValidity(moment, "from")}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Valid To</Text>} colon={false} required>
            {getFieldDecorator("to", {
              rules: [
                {
                  type: "object",
                  required: true,
                  message: "Select Valid Date",
                },
              ],
            })(
              <DatePicker
                format="DD/MM/YYYY"
                style={{ width: "100%" }}
                allowClear={true}
                disabled={!editable}
                disabledDate={(moment) => checkDateValidity(moment, "to")}
              />
            )}
          </Item>
        </Col>
      </Row>
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: "normal_login" })(
  VehiclePriceForm
);

export default WrappedAccessoriesModal;
