import React from "react";
import { withRouter, Route } from "react-router-dom";
import "./index.less";

// Form and Table Imports
import DepartmentType from "./DepartmentType";

function Department() {
  return (
    <div>
      <Route exact path="/autoadmin/department">
        <DepartmentType />
      </Route>
      <Route path="/autoadmin/department/:deptType">
        <DepartmentType />
      </Route>
    </div>
  );
}

export default withRouter(Department);
