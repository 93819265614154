import React, { useState, useEffect, useContext } from "react";
import { Icon, Tabs } from "antd";
import { platformApi } from "../../../api";
import CompanyBranch from "./CompanyBranch";
import CompanySettings from "./CompanySettings";
import { ContextAPI } from "../../../ContextAPI";
import CompanyMaster from "./CompanyMaster";

const { TabPane } = Tabs;

const Company = () => {
  const { loginCredintials } = useContext(ContextAPI);
  const [companyReadAccess, setCompanyReadAccess] = useState(false);
  const [companySettingsAccess, setCompanySettingsAccess] = useState(false);

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "company") {
          if (each.access.read) {
            setCompanyReadAccess(true);
          }
        }
        if (each.subModule === "company_settings") {
          if (each.access.read) {
            setCompanySettingsAccess(true);
          }
        }
      });
  }, [loginCredintials]);

  return (
    <div>
      {companyReadAccess && companySettingsAccess ? (
        <Tabs defaultActiveKey="3">
          <TabPane
            tab={
              <span>
                <Icon type="setting" theme="filled" />
                Company
              </span>
            }
            key="1"
          >
            <CompanySettings />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="apartment" />
                Company Branch
              </span>
            }
            key="2"
          >
            <CompanyBranch />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="setting" theme="filled" />
                Company Master
              </span>
            }
            key="3"
          >
            <CompanyMaster />
          </TabPane>
        </Tabs>
      ) : companySettingsAccess ? (
        <Tabs defaultActiveKey="2">
          <TabPane
            tab={
              <span>
                <Icon type="setting" theme="filled" />
                Company
              </span>
            }
            key="1"
          >
            <CompanySettings />
          </TabPane>

          <TabPane
            tab={
              <span>
                <Icon type="setting" theme="filled" />
                Company Master
              </span>
            }
            key="2"
          >
            <CompanyMaster />
          </TabPane>
        </Tabs>
      ) : companyReadAccess ? (
        <Tabs defaultActiveKey="2">
          <TabPane
            tab={
              <span>
                <Icon type="setting" theme="filled" />
                Company Branch
              </span>
            }
            key="1"
          >
            <CompanyBranch />
          </TabPane>
          <TabPane
            tab={
              <span>
                <Icon type="setting" theme="filled" />
                Company Master
              </span>
            }
            key="2"
          >
            <CompanyMaster />
          </TabPane>
        </Tabs>
      ) : (
        <CompanyMaster />
      )}
    </div>
  );
};

export default Company;
