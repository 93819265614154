import React, { useEffect, useState } from 'react'
import CustomerDetails from "../CustomerDetails"
import { withRouter } from 'react-router-dom'
import { platformApi } from '../../../api'


const ReferredCustomerPage = (props) => {

  const [customerId, setCustomerId] = useState(null)
  const [cusData, setCusData] = useState(null)
  useEffect(() => {
    let query = props.location.search;
    let id = query.substring(4)
    setCustomerId(id);
    platformApi.get(`api/customer/${id}`)
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setCusData(response.data)
          }
        }
      })
  }, [])


  return (
    <div>
      {customerId && cusData &&
        <CustomerDetails customerId={customerId} cusData={cusData} />
      }
    </div>
  )
}


export default withRouter(ReferredCustomerPage)