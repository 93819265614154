import React, { useState } from "react";
import { Table, Divider, Popconfirm, Select, Typography } from "antd";
import moment from "moment";
const { Text } = Typography
export default function CustomerDetailsTable(props) {
  const { pagination, dataSource, spinner, openModal, modify, deleteAccess } = props;

  const column = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "customerName",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Mobile Number",
      dataIndex: "contacts",
      key: "phonenumber",
      render: (record) => <span>{record ? record[0] && record[0].phone : undefined}</span>,
    },
    {
      title: "Locality",
      dataIndex: "address",
      key: "locality",
      render: (record) => <span>{record ? record.locality : undefined}</span>,
    },
    {
      title: "DOB",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (record) => <span>{record ? moment(record).format("DD-MM-YYYY") : undefined}</span>,
    },
    {
      title: "Grouping",
      dataIndex: "customerGrouping",
      key: "grouping",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Type",
      dataIndex: "customerType",
      key: "type",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Creation On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => <span>{record ? moment(record).format("DD-MM-YYYY") : undefined}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {
            modify ? 
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  openModal(data.id, true);
                }}
                className="linkylink"
              >
                Modify
          </span>
              :
              <span></span>
          }
          {
            deleteAccess ?
              <Popconfirm
                title="Do you want to delete this Customer?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  props.delete(data.id);
                }}
              >
                <span
                  onClick={(event) => event.stopPropagation()}
                  className="linkylink"
                >
                  <Divider type="vertical" />
                  Delete
            </span>
              </Popconfirm>
              :
              <span></span>
          }
          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }


        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props.id, false),
      })}
      loading={spinner}
    />
  );
}