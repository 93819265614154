import { LeftOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, message, Spin, Tooltip, Upload } from 'antd';
import Search from 'antd/lib/input/Search';
import Title from 'antd/lib/typography/Title';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { withRouter } from 'react-router';
import { platformApi } from '../../../api';
import './index.less';
import UploadFileTable from './UploadFileTable';
import WrappedUploadFormModel from './UploadFormModel';

const Index = (props) => {
	const [count, setCount] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const [addFlag, setAddFlag] = useState(false);
	const [editFlag, setEditFlag] = useState(false);
	const [spinning, setSpinning] = useState(false);
	const [dataSource, setDataSource] = useState([]);

	useEffect(() => {
		setPrimaryData();
	}, [page, limit]);

	const setPrimaryData = () => {
		setSpinning(true);
		platformApi
			.post('/api/fileUpload/generate/get', {
				page: page,
				size: limit,
				searchString: '',
			})
			.then((res) => {
				setSpinning(false);
				const { data } = res;
				if (data.code === 200) {
					const { response } = data;
					if (response.code === 200) {
						console.log(response.data);
						setDataSource(response.data);
						setCount(response.data.length);
						// setCount(response.data.count);
					} else {
						message.error('Failed to fetch upload files');
					}
				} else {
					message.error('Failed to fetch upload files');
				}
			})
			.catch((error) => {
				setSpinning(false);
				console.error('Error on upload files : ', error);
				message.error('Failed to fetch upload files');
			});
	};

	return (
		<div>
			<div className='pmc-head'>
				<Title style={{ width: '60%' }} level={4}>
					<Tooltip placement='topLeft' title={'Back to Company Master'}>
						<Button
							style={{ margin: '0 20px' }}
							onClick={() => props.history.push('/autoadmin/company')}
						>
							<LeftOutlined />
						</Button>
					</Tooltip>
					Upload File [{count}]
					{/* <span style={{ marginLeft: '1rem' }}>
						<Dropdown overlay={menu} trigger={['click']}>
							<Button>
								{limit}
								&nbsp;
								<Icon type='down' />
							</Button>
						</Dropdown>
					</span> */}
				</Title>

				{/* <Search
					placeholder='Search PMC'
					className='searched-div-manufacturer'
					style={{ width: '30%' }}
					onSearch={(event) => {
						setPage(1);
						setSearch(event);
					}}
					onChange={(event) => {
						if (event.target.value === '') {
							setSearch(null);
							setPage(1);
						}
					}}
				/> */}
				<Button
					type='primary'
					className='add-target-button'
					// disabled={!createAccess}
					style={{ margin: '0 3%', width: '20%' }}
					onClick={() => {
						setAddFlag(true);
					}}
					// disabled={!createAccess}
				>
					Create Link
				</Button>
			</div>
			<UploadFileTable
				pagination={{
					onChange: (page) => {
						setPage(page);
					},
					pageSize: limit,
					defaultCurrent: 1,
					current: page,
					total: count,
					showQuickJumper: true,
				}}
				// delete={(id) => deleteData(id)}
				dataSource={dataSource}
				spinner={{
					spinning,
				}}
				// modify={modifyAccess}
				// deleteAccess={deleteAccess}
			/>
			<WrappedUploadFormModel
				// data={dataSource[dataSource.findIndex((obj) => selected === obj.id)]}
				emitData={(data) => addData(data)}
				editable={editFlag}
				open={addFlag}
				close={() => {
					setAddFlag(false);
				}}
				setSpinning={setSpinning}
				setPrimaryData={setPrimaryData}
				// modify={modifyAccess}
			/>
		</div>
	);
};

export default withRouter(Index);
