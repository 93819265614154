import React, { useState, useEffect, useContext } from "react";
import {
    Typography,
    Input,
    Button,
    Dropdown,
    Menu,
    Icon,
    message,
    Tabs,
} from "antd";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../ContextAPI";
import { useHistory } from "react-router-dom";


import CustomerSpareTable from "./CustomerSpareTable"
import { platformApi } from "../../../api";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs;

function index(props) {
    const history = useHistory();
    const { loginCredintials } = useContext(ContextAPI);
    const [spinning, setSpinning] = useState(false);
    const [dataSource, setData] = useState([]);
    const [createAccess, setCreateAccess] = useState(false);
    const [modifyAccess, setModifyAccess] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);
    const [editableData, setEditableData] = useState({})
    const [redirect, setRedirect] = useState(false);
    const [selected, setSelected] = useState();


    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);


    const [search, setSearch] = useState('');
    const [gst, setGst] = useState();

    const getMenu = () => {
        let size = count / 10 + (count % 10) ? 1 : 0;
        const menus = [];
        menus.push(10);
        size = size <= 10 ? size : 10;
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
                {menu}
            </Item>
        ));
    };
    const menu = <Menu>{getMenu()}</Menu>;


    useEffect(() => {
        setGst(loginCredintials.branch[0].gst)
        loginCredintials.roleAccess &&
            loginCredintials.roleAccess.map((each) => {
                if (each.subModule === "customer_spare_order") {
                    if (each.access.create) {
                        setCreateAccess(true);
                    }
                    if (each.access.update) {
                        setModifyAccess(true);
                    }
                    if (each.access.delete) {
                        setDeleteAccess(true);
                    }
                }
            });
        setPageData();
        //getPSI();
    }, []);


    const setPageData = (search, val) => {
        setSpinning(true)
        if (search) {
            platformApi
                .post(`/api/customerSpareOrderRouter/get`, {
                    page: val ? val : page,
                    size: limit,
                    searchString: search,
                })
                .then((result) => {
                    setSpinning(false);
                    let { data } = result;
                    if (data.code === 200) {
                        setData(data.response.data);
                    } else {
                        message.error("Unable to fetch Customer Spare Order", 2);
                    }
                })
                .catch((error) => {
                    console.error("Error on Vehicle purchase spare invoice : ", error);
                    message.error("Unable to fetch Customerss Spare Order", 2);
                });
        }
        else {
            platformApi
                .get("/api/customerSpareOrderRouter", {
                    page: val ? val : page,
                    size: limit,
                    searchString: search,
                })
                .then((result) => {
                    setSpinning(false);
                    let { data } = result;
                    if (data.code === 200) {
                        setData(data.response.data);
                    } else {
                        message.error("Unable to fetch Customer Spare Order", 2);
                    }
                })
                .catch((error) => {
                    console.error("Error on Vehicle purchase spare invoice : ", error);
                    message.error("Unable to fetch Customerss Spare Order", 2);
                });
        }
    };


    useEffect(() => {
        setPageData(search);
    }, [page, limit, search]);

    const deleteData = (id) => {
        setSpinning(true)
        let temp = page != 1 && Tasks.length === 1;
        // if (page != 1 && Tasks.length === 1) {
        //   setPage(1)
        // }
        platformApi.delete(`/api/customerSpareOrderRouter/${id}`).then(result => {
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    message.success(" Deleted Successfully")
                    setPageData();
                }
                else {
                    message.error("Couldn't Remove Customer Spare Order");
                }
            }
            else {
                message.error("Couldn't Remove Customer Spare Order");
            }
            setSpinning(false)
        }).catch((err) => {
            setSpinning(false)
            message.error("Unable to Delete Customer Spare Order");
        });
    }



    return (
        <div>
            <div className="accessories-head">
                <Title style={{ width: "60%" }} level={4}>
                    Customer Spare Order [{dataSource.length}]
                    <span style={{ margin: ".5rem" }}>
                        <Dropdown
                            overlay={menu}
                            trigger={["click"]}
                        >
                            <Button>
                                {limit} &nbsp;
                                &nbsp;
                                <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </span>
                </Title>
                <Search
                    style={{ width: "30%" }}
                    placeholder="Search Customer Spare"
                    onSearch={(event) => {
                        setPage(1);
                        setSearch(event);
                    }}
                    onChange={(event) => {
                        if (event.target.value === "") {
                            setSearch(null);
                            setPage(1);
                        }
                    }}
                />

                <Button
                    // onClick={() => {
                    //     setAddFlag(true);
                    //     setEditFlag(true);
                    // }}
                    onClick={() =>
                        history.push({
                            pathname: `/autoadmin/customerspare/:id`,
                            state: {
                                editable: true,
                            }
                        })
                    }
                    style={{ margin: "0 3%", width: "20%" }}
                    type="primary"
                    disabled={!createAccess}
                >
                    Add CustomerSpareOrder
                </Button>
            </div>
            <CustomerSpareTable
                pagination={{
                    onChange: (page) => {
                        setPage(page);
                    },
                    pageSize: limit,
                    defaultCurrent: 1,
                    current: page,
                    total: count,
                    showQuickJumper: true,
                }}
                dataSource={dataSource}
                spinner={{ spinning }}
                delete={deleteData}
                deleteAccess={deleteAccess}
                modifyAccess={modifyAccess}
            />
        </div>
    )
}

export default withRouter(index)
