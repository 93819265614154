import React, { useEffect, useState, useContext } from "react";
import { useHistory, withRouter } from "react-router-dom";
import {
    Row,
    Col,
    Table,
    Progress,
    Select,
    message,
    Button,
    Divider,
    Tooltip,
    Icon,
    Popconfirm,
    DatePicker,
    Spin,
    Form
} from "antd";
import { LoadingOutlined } from '@ant-design/icons';
const { RangePicker } = DatePicker
import Text from "antd/lib/typography/Text";
import "./index.less";
import { platformApi } from "../../api";
import { ContextAPI } from "../../ContextAPI";
import Chart from "react-apexcharts";
import moment from "moment";
import JobOrderModal from "../TransactionMasters/JobOrder/JobOrderModal"
const ServiceDashboard = (props) => {
    const { history } = props
    const { loginCredintials } = useContext(ContextAPI);
    const [spinning, setSpinning] = useState(false);
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);
    const [jobView, setJobView] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState([]);
    const [timeline, setTimeline] = useState("month");
    const [editable, setEditable] = useState(false)
    const [employee, setEmployee] = useState([]);
    const [activeJobsData, setActiveJobsData] = useState([]);
    const [initialData, setInitialData] = useState(false)
    const [fromDate, setFromDate] = useState(null)
    const [toDate, setToDate] = useState(null)
    const [jobData, setJobData] = useState([]);
    const [jobDetail, setJobDetail] = useState(null)
    const [jobEditable, setJobEditable] = useState(false)
    const [showDate, setShowDate] = useState([])



    const serviceType = [
        { key: 1, title: "Free" },
        { key: 2, title: "Extended Warranty" },
        { key: 3, title: "Paid (UW)" },
        { key: 4, title: "Paid (AW)" },
        { key: 5, title: "AMC" },
        { key: 6, title: "Accidental" },
        { key: 7, title: "Minor" },
    ];
    const [mechanics, setMechanics] = useState(0)
    const [count, setCount] = useState({
        totalJobsCount: 0,
        totalPaidServiceCount: 0,
        vehicleReceivedCount: 0,
        EstimationCount: 0,
        MechanicAllocationCount: 0,
        WIPCount: 0,
        finalInspectionCount: 0,
        ReadyforDeliveryCount: 0,
        deliveredCount: 0,
        freeServiceCount: 0,
        paidAWServiceCount: 0,
        paidUWServiceCount: 0,
        extendedWarrantyCount: 0,
        amcCount: 0,
        minorCount: 0,
        accidentialCount: 0,
        upComingJobsCount: 0,
        upComingFreeJobsCount: 0,
        upComingPaidJobsCount: 0,
        missedOppurturnitiesCount: 0,
        labourCharge: 0,
    });
    const [pieChartSeries, setPieChartSeries] = useState([])
    const [pieChartOptions, setPieChartOptions] = useState({
        chart: {
            width: 320,
            type: 'pie',
        },
        labels: [],
        legend: {
            show: false
        },
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 100
                },
            }
        }]
    })
    const [serviceNoSeries, setServiceNoSeries] = useState([
        {
            name: "No.of Services",
            data: [
                {
                    x: '',
                    y: ''
                },
            ],
        },
    ]);

    const serviceNoOptions = {
        chart: {
            id: "apexchart-example",
            type: "bar",
            stacked: false,
            stackType: '100%',
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [
                "Free",
                "Extended Warranty",
                "Paid (UW)",
                "Paid (AW)",
                "AMC",
                "Accidental",
                "Minor"
            ],
            labels: {
                style: {
                    colors: ['#33b2df', '#f48024', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e'],
                }
            }
        },
        colors: ['#33b2df', '#f48024', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e'],
        plotOptions: {
            bar: {
                distributed: true
            }
        }
    };

    const [jobCodeSeries, setJobCodeSeries] = useState([
        {
            name: "JobCodes",
            data: [
                {
                    x: '',
                    y: ''
                },
            ],
        },
    ]);

    const jobCodeOptions = {
        chart: {
            id: "apexchart-example",
            type: "bar",
            stacked: false,
            stackType: '100%',
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [],
            labels: {
                style: {
                    colors: ['#33b2df', '#f48024', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e'],
                }
            }
        },
        colors: ['#33b2df', '#f48024', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e'],
        plotOptions: {
            bar: {
                distributed: true
            }
        }
    };

    useEffect(() => {
        getData(timeline, selectedEmployee);
    }, [timeline, selectedEmployee]);

    useEffect(() => {
        getData(timeline, selectedEmployee);
    }, [fromDate, toDate]);


    useEffect(() => {
        if (initialData) {
            getData(timeline, selectedEmployee)
            setInitialData(false)
        }
    }, [initialData])

    useEffect(() => {
        platformApi.get("/api/user")
            .then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    let tempUser = data.data.users.filter(item => {
                        return item.profile &&
                            item.profile?.department?.role === "Mechanic"
                    })
                    let mechanic = tempUser.map((user) => user.id)
                    setEmployee(tempUser)
                    setSelectedEmployee(mechanic)
                }
            })
    }, []);

    const getData = (timeline, employee) => {
        setSpinning(true);
        platformApi
            .post("/api/jobOrder/dashboard", {
                timeline,
                from: fromDate,
                to: toDate,
                employee,
                current: loginCredintials.employeeId,
            })
            .then((res) => {
                if (res.data.code === 200) {
                    // console.log("response", res.data.response.data)
                    setCount(res.data.response.data);
                    setActiveJobsData(res.data.response.data.jobOrders)
                    //Service No Bar Graph
                    const data = res.data.response.data;
                    setServiceNoSeries([
                        {
                            data: [
                                {
                                    x: "Free",
                                    y: data.freeServiceCount,
                                },
                                {
                                    x: "Extended Warrenty",
                                    y: data.extendedWarrantyCount,

                                },
                                {
                                    x: "Paid (UW)",
                                    y: data.paidUWServiceCount,
                                },
                                {
                                    x: "Paid (AW)",
                                    y: data.paidAWServiceCount,
                                },
                                {
                                    x: "AMC",
                                    y: data.amcCount,
                                },
                                {
                                    x: "Accidential",
                                    y: data.accidentialCount,
                                },
                                {
                                    x: "Minor",
                                    y: data.minorCount
                                }
                            ],
                        },
                    ]);

                    //PieChart
                    const labour = res.data.response.data.labourData;
                    for (let itr = 0; itr < labour.length; itr++) {
                        const jobOrder = labour[itr];
                        var count = 0;
                        for (let index = 0; index < labour.length;) {
                            const element = labour[index];
                            if (index <= itr) {
                                index++;
                                continue;
                            }
                            if (element.mechanic.id === jobOrder.mechanic.id) {
                                count = count + element.total;
                                labour.splice(index, 1);
                            }
                            else {
                                index++;
                            }
                        }
                        jobOrder.Overall = jobOrder.total + count;
                    }
                    let labourtotal = [], mechanic = [];
                    labour.map(item => (
                        labourtotal.push(item.Overall),
                        mechanic.push(item.mechanic?.profile?.employeeName)
                    ))

                    setMechanics(mechanic.length)
                    setPieChartSeries(labourtotal)
                    setPieChartOptions({
                        labels: mechanic,
                        legend: {
                            show: false
                        }
                    })

                    // console.log(res.data.response.data.jobCodes)
                    //JobCodes Bar Graph
                    const jobCodes = res.data.response.data.jobCodes
                    for (let itr = 0; itr < jobCodes.length; itr++) {
                        const jobOrder = jobCodes[itr];
                        var count = 0;
                        for (let index = 0; index < jobCodes.length;) {
                            const element = jobCodes[index];
                            if (index <= itr) {
                                index++;
                                continue;
                            }
                            if (element.jobCode === jobOrder.jobCode) {
                                count = count + element.count;
                                jobCodes.splice(index, 1);
                            }
                            else {
                                index++;
                            }
                        }
                        jobOrder.Overall = jobOrder.count + count;
                    }
                    console.log("jobCodes", jobCodes)
                    // jobCodes
                    jobCodes.sort((a, b) => b.Overall - a.Overall);
                    console.log("jobCodes", jobCodes)

                    let topfive = jobCodes.slice(0, 10)
                    let others = jobCodes.slice(10)

                    others = others.reduce((obj, item) => {
                        if (!obj) {
                            obj = {
                                x: "Others",
                                y: 0
                            }
                        }
                        obj.y += item.Overall
                        return obj
                    }, null)

                    let value = topfive.map(jobCode => (
                        {
                            x: jobCode.jobCode,
                            y: jobCode.Overall
                        }
                    ))
                    if (others) {
                        value = [...value, others]
                    }
                    setJobCodeSeries([
                        {
                            name: "JobCodes",
                            data: value,
                        },
                    ]);

                    setSpinning(false);
                } else if (res.data.code === 500) {
                    message.error("Unable to fetch data", 2)
                }
            });
    };

    const handleDateChange = async (date, dateString) => {
        await setFromDate(dateString[0])
        await setToDate(dateString[1])
        //  getData(timeline, selectedEmployee)
    }
    const filterMethod = (input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const activeJobsColumn = [
        {
            title: "Customer Details",
            dataIndex: "customer",
            key: "customer",
            render: (record, data) => <span>{record ? record.name : data.customerName} - {record ? record.contacts[0].phone : data.customerPhone}</span>,
        },
        {
            title: "Model",
            dataIndex: "vehicle",
            key: "vehicle",
            render: (record, data) =>
                <span>
                    {record?.vehicle?.modelCode} - {record?.vehicle?.modelName}
                </span>
            ,
        },
        {
            title: "Color",
            dataIndex: "vehicle",
            key: "vehicle",
            render: (record, data) => <span>{record.color?.code} - {record.color?.color}
            </span>
            ,
        },
        {
            title: "Service Type",
            dataIndex: "serviceType",
            key: "serviceType",
            render: (record) => <span>{record}</span>,
        },
        {
            title: "Job ID",
            dataIndex: "jobNo",
            key: "jobNo",
            render: (record) => <span className="linkylink">{record}</span>,
        },
        {
            title: "Mechanic",
            dataIndex: "mechanic",
            key: "mechanic",
            render: (record) => <span>{record?.profile?.employeeName} - {record?.profile?.employeeId}</span>,
        },
        {
            title: "Status",
            dataIndex: "jobStatus",
            key: "jobStatus",
            render: (record) => <span>{record}</span>,
        },
    ];
    const openJobOrder = (edit = false, record = null) => {
        setJobEditable(edit);
        if (record) {
            let jobId = record.id
            getJobOrder(jobId).then(() => setJobView(true))
        }
        else {
            setJobView(true);
        }
    }
    const getJobOrder = (id) => {
        return new Promise(async (resolve, reject) => {
            platformApi.get("/api/jobOrder/" + id).then((res) => {
                const { data: { response: { data } } } = res;
                setJobDetail(data)
                resolve(data)
            })
        })
    }

    // --------------------------------------------------



    useEffect(() => {
        const { fromDate, toDate } = DateShow(timeline);

        if (timeline === "date") {
            setShowDate([]);
            setFromDate()
            setToDate()
        } else {
            let arr = [];
            arr.push(fromDate);
            arr.push(toDate)
            setShowDate(arr)
        }

    }, [timeline])

    const handleTimeline = (e) => {
        if (e !== "date") {
            const { fromDate, toDate } = DateShow(e)
            let arr = [];
            arr.push(fromDate);
            arr.push(toDate)
            setShowDate(arr)
        }
        else if (e === "date") {
            setShowDate([]);
            setFromDate(null)
            setToDate(null)
        }
        setTimeline(e)
    }


    const DateShow = (timeline, from, to) => {
        let toDate;
        let fromDate;
        let months;

        switch (timeline) {
            case "today":
                fromDate = moment(new Date()).startOf("day");
                toDate = moment(new Date()).endOf("day");
                months = [moment(new Date()).format("MMM")]
                break;
            case "week":
                fromDate = moment(new Date()).startOf("week");
                toDate = moment(new Date()).endOf("week");
                months = [moment(new Date()).format("MMM")]
                break;
            case "month":
                fromDate = moment(new Date()).startOf("month");
                toDate = moment(new Date()).endOf("month");
                months = [moment(new Date()).format("MMM")]
                break;
            case "quarterly":
                fromDate = moment(new Date()).startOf("quarter");
                toDate = moment(new Date()).endOf("quarter");
                months = [fromDate.format("MMM"), moment(fromDate).add(1, "months").format("MMM"), moment(toDate).format("MMM")]
                break;
            case "halfyearly":
                let tempDate;
                let tempEnd;
                if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") > 3 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") < 7
                ) {
                    tempDate = moment(new Date())
                        .subtract(3, "M")
                        .startOf("quarter");
                } else if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") > 9 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") <= 12
                ) {
                    tempDate = moment(new Date())
                        .subtract(3, "M")
                        .startOf("quarter");
                } else {
                    tempDate = moment(new Date()).startOf("quarter");
                }
                if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") >= 1 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") < 4
                ) {
                    tempEnd = moment(new Date())
                        .add(3, "M")
                        .endOf("quarter");
                } else if (
                    moment(new Date())
                        .startOf("month")
                        .format("MM") > 6 &&
                    moment(new Date())
                        .startOf("month")
                        .format("MM") <= 9
                ) {
                    tempEnd = moment(new Date())
                        .add(3, "M")
                        .endOf("quarter");
                } else {
                    tempEnd = moment(new Date()).endOf("quarter");
                }
                fromDate = tempDate;
                toDate = tempEnd;
                months = [
                    fromDate.format("MMM"),
                    moment(fromDate).add(1, "months").format("MMM"),
                    moment(fromDate).add(2, "months").format("MMM"),
                    moment(fromDate).add(3, "months").format("MMM"),
                    moment(fromDate).add(4, "months").format("MMM"),
                    moment(toDate).format("MMM")
                ]
                break;
            case "year":
                fromDate = moment(new Date()).startOf("year");
                toDate = moment(new Date()).endOf("year");
                months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
                break;
            case "date":
                fromDate = moment(from, "DD-MM-YYYY").startOf("day");
                toDate = moment(to, "DD-MM-YYYY").endOf("day");
                let difference = toDate.diff(fromDate, 'months')
                if (difference == 0) {
                    months = [moment(fromDate).format("MMM")]
                }
                else if (difference == 1) {
                    months = [moment(fromDate).format("MMM"), moment(toDate).format("MMM")]
                }
                else {
                    months = [moment(fromDate).format("MMM")]
                    for (let i = 0; i < difference; i = i + 1) {
                        months.push(moment(fromDate).add(i, "months").format("MMM"))
                    }
                    months.push(moment(toDate).format("MMM"))
                }
                break;
            default:
                fromDate = moment(new Date()).startOf("day");
                toDate = moment(new Date()).endOf("day");
                months = [moment(new Date()).format("MMM")]
        }

        return { fromDate, toDate, months }

    }



    return (
        <>
            <div style={{ background: "#f4f7fb" }}>
                <Row gutter={16} style={{ marginBottom: "10px" }}>
                    <Col span={7}>
                        <Text style={{ fontWeight: "bold" }}>
                            Team Statistics
                        </Text>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between" style={{ border: "1px solid black" }}>
                    <Col span={8}>
                        <Row gutter={20}>
                            <Chart
                                options={serviceNoOptions}
                                series={serviceNoSeries}
                                type="bar"
                                width={320}
                                height={320}
                            />
                        </Row>
                        <Row type="flex" justify="center">
                            <Text style={{ fontWeight: "bold", textAlign: 'center' }}>
                                Service Type
                            </Text>
                        </Row>
                    </Col>
                    <Col span={16} style={{ marginTop: "25px" }}>
                        <Row gutter={20}>
                            {/* <Col span={8} style={{ marginBottom: '20px' }}>
                                <Text style={{
                                    padding: "5px 4px ",
                                    textAlign: "center",
                                    fontSize: '16px',
                                    width: "100%",
                                }}>Labour by Mechanic:</Text>
                                <Row style={{
                                    background: "white",
                                    // padding: "0px 10px",
                                    textAlign: "center",
                                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    height: "50vh",

                                }}
                                >
                                    <Chart
                                        options={pieChartOptions}
                                        series={pieChartSeries}
                                        type="pie"
                                        width={220}
                                        height={160}
                                    />

                                    <Text style={{
                                        padding: "5px 4px ",
                                        color: "#a8afb3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                    >
                                        Total Labour Gen : <p style={{
                                            fontSize: "24px",
                                            paddingTop: "10px",
                                            color: "#6c7b8a",
                                        }}
                                        >
                                            {count.labourCharge}
                                        </p>
                                                    Mechanics : <p
                                            style={{
                                                fontSize: "24px",
                                                // paddingTop: "10px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {mechanics}
                                        </p>
                                    </Text>
                                </Row>
                            </Col> */}
                            <Col span={24}>
                                <Row gutter={20}>
                                    <Col
                                        span={7}
                                        style={{
                                            height: "140px",
                                            background: "white",
                                            padding: "15px 15px 0px 15px",
                                            textAlign: "center",
                                            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: "5px 4px ",
                                                color: "#a8afb3",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            Vehicle Received
                                        </Text>
                                        <p
                                            style={{
                                                fontSize: "26px",
                                                paddingTop: "10px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {count.vehicleReceivedCount}
                                        </p>
                                    </Col>
                                    <Col
                                        span={7}
                                        style={{
                                            height: "140px",
                                            background: "white",
                                            marginLeft: "15px",
                                            padding: "15px 15px 0px 15px",
                                            textAlign: "center",
                                            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: "5px 4px ",
                                                color: "#a8afb3",
                                                textAlign: "center",
                                                width: "100%",
                                            }}
                                        >
                                            Estimation Gen/App.
                                        </Text>
                                        <p
                                            style={{
                                                fontSize: "26px",
                                                paddingTop: "10px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {count.EstimationCount}
                                        </p>
                                    </Col>
                                    <Col
                                        span={7}
                                        style={{
                                            height: "140px",
                                            background: "white",
                                            marginLeft: "15px",
                                            marginRight: "5px",
                                            padding: "15px 15px 0px 15px",
                                            textAlign: "center",
                                            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                // padding: "5px 4px ",
                                                color: "#a8afb3",
                                                textAlign: "left",
                                                width: "100%",
                                            }}
                                        >
                                            Mechanic Allocated
                                        </Text>
                                        <p
                                            style={{
                                                fontSize: "26px",
                                                paddingTop: "10px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {count.MechanicAllocationCount}
                                        </p>
                                    </Col>
                                </Row>
                                <Row style={{
                                    backgroundColor: "white",
                                    textAlign: "center",
                                    marginTop: "30px",
                                    marginLeft: "-8px",
                                    marginRight: "3%",
                                    paddingTop: "10px",
                                }}>
                                    <Col
                                        span={5}
                                        style={{
                                            background: "white",
                                            marginLeft: "15px",
                                            marginRight: "5px",
                                            padding: "15px 15px 0px 15px",
                                            textAlign: "center",
                                            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: "5px 4px ",
                                                color: "#a8afb3",
                                                textAlign: "center",
                                                width: "100%",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Work In Progress
                                        </Text>
                                        <p
                                            style={{
                                                fontSize: "26px",
                                                paddingTop: "4px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {count.WIPCount}
                                        </p>
                                    </Col>
                                    <Col
                                        span={5}
                                        style={{
                                            background: "white",
                                            marginLeft: "15px",
                                            marginRight: "5px",
                                            padding: "15px 15px 0px 15px",
                                            textAlign: "center",
                                            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: "5px 4px ",
                                                color: "#a8afb3",
                                                textAlign: "center",
                                                width: "100%",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Final Inspection
                                        </Text>
                                        <p
                                            style={{
                                                fontSize: "26px",
                                                paddingTop: "4px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {count.finalInspectionCount}
                                        </p>
                                    </Col>
                                    <Col
                                        span={5}
                                        style={{
                                            background: "white",
                                            marginLeft: "15px",
                                            marginRight: "5px",
                                            padding: "15px 15px 0px 15px",
                                            textAlign: "center",
                                            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: "5px 4px ",
                                                color: "#a8afb3",
                                                textAlign: "center",
                                                width: "100%",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Ready for Delivery
                                        </Text>
                                        <p
                                            style={{
                                                fontSize: "26px",
                                                paddingTop: "4px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {count.ReadyforDeliveryCount}
                                        </p>
                                    </Col>
                                    <Col
                                        span={5}
                                        style={{
                                            background: "white",
                                            // marginLeft: "15px",
                                            marginRight: "5px",
                                            padding: "15px 15px 0px 15px",
                                            textAlign: "center",
                                            boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                        }}
                                    >
                                        <Text
                                            style={{
                                                padding: "5px 4px ",
                                                color: "#a8afb3",
                                                textAlign: "center",
                                                width: "100%",
                                                fontSize: "12px",
                                            }}
                                        >
                                            Delivered Jobs
                                        </Text>
                                        <p
                                            style={{
                                                fontSize: "26px",
                                                paddingTop: "4px",
                                                color: "#6c7b8a",
                                            }}
                                        >
                                            {count.deliveredCount}
                                        </p>
                                    </Col>
                                </Row>


                                <Row gutter={[16, 16]}>
                                    <Col span={12} style={{ marginTop: "25px" }}>
                                        <Select
                                            style={{ width: 280, position: "relative", right: "0.5rem" }}
                                            placeholder="Employee"
                                            mode="multiple"
                                            showSearch
                                            value={selectedEmployee}
                                            filterOption={filterMethod}
                                            onChange={(e) => {
                                                setSelectedEmployee(e);
                                            }}
                                        >
                                            {
                                                employee.map((data) => (
                                                    <Select.Option key={data.id}>
                                                        {data && data?.profile && data.profile?.employeeName}
                                                    </Select.Option>
                                                ))
                                            }
                                        </Select>
                                    </Col>
                                    <Col span={11} style={{ marginTop: "25px" }}>
                                        <Select
                                            style={{ width: 200, position: "relative", right: "5rem" }}
                                            value={timeline}
                                            onChange={(e) => {
                                                setTimeline(e);
                                                handleTimeline(e)
                                            }}
                                            placeholder="Timeline"
                                        >
                                            <Select.Option value="today">Today</Select.Option>
                                            <Select.Option value="week">This Week</Select.Option>
                                            <Select.Option value="month">This Month</Select.Option>
                                            <Select.Option value="quarterly">This Quarter</Select.Option>
                                            <Select.Option value="halfyearly">Half-Yearly</Select.Option>
                                            <Select.Option value="year">This Year</Select.Option>
                                            <Select.Option value="date">Select a Date Range</Select.Option>
                                        </Select>
                                    </Col>

                                </Row>
                                {(timeline === "date") && (
                                    <Row style={{ padding: "10px", position: "relative", left: "30rem", bottom: "2.6rem", width: 220 }}>
                                        <RangePicker onChange={handleDateChange} format={"DD/MM/YYYY"} />
                                    </Row>
                                )}
                                {(timeline !== "date") && (
                                    <Row style={{ padding: "0px", marginTop: "0rem", position: 'relative', left: "31rem", bottom: "2.5rem",width: 200 }}>
                                        <RangePicker disabled={true} value={showDate} format={"DD/MM/YYYY"} />
                                    </Row>
                                )}
                            </Col>
                        </Row>

                    </Col>
                </Row>
            </div>
            <br />

            <div style={{ background: "#f4f7fb", marginTop: '1%' }}>
                <Row gutter={16} style={{ marginBottom: "10px" }}>
                    <Col span={7}>
                        <Text style={{ fontWeight: "bold" }}>
                            Labour by Job code
                        </Text>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between" style={{ border: "1px solid black" }}>
                    <Col span={12}>
                        <Chart
                            options={jobCodeOptions}
                            series={jobCodeSeries}
                            type="bar"
                            width={500}
                            height={320}
                        />
                    </Col>
                    <Col span={12} style={{ marginTop: "25px" }}>
                        <Col span={24}>
                            <Row style={{
                                marginTop: "10px",
                                marginLeft: "-8.5px",
                                marginRight: "4%",
                            }}>
                                <Col span={10}
                                    style={{
                                        background: "white",
                                        marginLeft: "15px",
                                        marginRight: "5px",
                                        padding: "15px 15px 0px 15px",
                                        textAlign: "center",
                                        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    }}
                                >
                                    <Text
                                        style={{
                                            // padding: "5px 4px ",
                                            color: "#a8afb3",
                                            textAlign: "left",
                                            width: "100%",
                                        }}
                                    >
                                        Paid Service (%)
                                    </Text>
                                    <p
                                        style={{
                                            fontSize: "26px",
                                            paddingTop: "10px",
                                            color: "#6c7b8a",
                                        }}
                                    >
                                        {((count.totalPaidServiceCount / count.totalJobsCount) * 100).toFixed(2)}
                                    </p>
                                </Col>
                                <Col span={10}
                                    style={{
                                        background: "white",
                                        marginLeft: "15px",
                                        marginRight: "5px",
                                        padding: "15px 15px 0px 15px",
                                        textAlign: "center",
                                        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#a8afb3",
                                            textAlign: "center",
                                            width: "100%",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Free Service(%)
                                    </Text>
                                    <p
                                        style={{
                                            fontSize: "26px",
                                            paddingTop: "4px",
                                            color: "#6c7b8a",
                                        }}
                                    >
                                        {((count.freeServiceCount / count.totalJobsCount) * 100).toFixed(2)}
                                    </p>
                                </Col>
                            </Row>
                            <Row style={{
                                backgroundColor: "white",
                                textAlign: "center",
                                marginTop: "30px",
                                marginLeft: "-8px",
                                marginRight: "4%",
                                paddingTop: "10px",
                            }}>
                                <Text
                                    style={{
                                        color: "#a8afb3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    Upcoming Service
                                </Text>{" "}
                            </Row>
                            <Row style={{
                                marginTop: "0px",
                                marginLeft: "-8.5px",
                                marginRight: "4%",
                            }}>
                                <Col span={8}
                                    style={{
                                        background: "white",
                                        padding: "5px 15px 0px 15px",
                                        textAlign: "center",
                                        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#a8afb3",
                                            textAlign: "center",
                                            width: "100%",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Total
                                    </Text>
                                    <p
                                        style={{
                                            fontSize: "26px",
                                            paddingTop: "4px",
                                            color: "#6c7b8a",
                                        }}
                                    >
                                        {count.upComingJobsCount}
                                    </p>
                                </Col>
                                <Col span={8}
                                    style={{
                                        background: "white",
                                        padding: "5px 15px 0px 15px",
                                        textAlign: "center",
                                        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    }}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/autoadmin/service_reminder',
                                            state: {
                                                status: "PAID"
                                            }
                                        })
                                    }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#a8afb3",
                                            textAlign: "center",
                                            width: "100%",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Paid
                                    </Text>
                                    <p
                                        style={{
                                            fontSize: "26px",
                                            paddingTop: "4px",
                                            color: "#6c7b8a",
                                        }}
                                    >
                                        {count.upComingPaidJobsCount}
                                    </p>
                                </Col>
                                <Col span={8}
                                    style={{
                                        background: "white",
                                        padding: "5px 15px 0px 15px",
                                        textAlign: "center",
                                        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    }}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/autoadmin/service_reminder',
                                            state: {
                                                status: "FREE"
                                            }
                                        })
                                    }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#a8afb3",
                                            textAlign: "center",
                                            width: "100%",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Free
                                    </Text>
                                    <p
                                        style={{
                                            fontSize: "26px",
                                            paddingTop: "4px",
                                            color: "#6c7b8a",
                                        }}
                                    >
                                        {count.upComingFreeJobsCount}
                                    </p>
                                </Col>

                            </Row>
                            <Row style={{
                                justifyContent: "center",
                                textAlign: "center",
                                marginTop: "30px",
                                padding: "15px",
                                marginLeft: "20%",
                            }}>
                                <Col span={12}
                                    style={{
                                        background: "white",
                                        padding: "5px 15px 0px 15px",
                                        textAlign: "center",
                                        boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    }}
                                    onClick={() => {
                                        history.push({
                                            pathname: '/autoadmin/service_reminder',
                                            state: {
                                                status: "MISSED"
                                            }
                                        })
                                    }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#a8afb3",
                                            textAlign: "center",
                                            width: "100%",
                                            fontSize: "12px",
                                        }}
                                    >
                                        Missed Oppurturnities
                                    </Text>
                                    <p
                                        style={{
                                            fontSize: "26px",
                                            paddingTop: "4px",
                                            color: "#6c7b8a",
                                        }}
                                    >
                                        {count.missedOppurturnitiesCount}
                                    </p>
                                </Col>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            </div>
            <Row style={{ marginTop: "25px" }}>
                <Col span={24}>
                    <Text style={{ padding: "10px 0px 20px 10px", fontWeight: "bold" }}>Active Jobs [{activeJobsData.length}]</Text>
                    <Table
                        // size="small"
                        columns={activeJobsColumn}
                        dataSource={activeJobsData}
                        className="todaysTaskTable"
                        pagination={{
                            pageSize: 6,
                            defaultCurrent: 1,
                            showQuickJumper: true,
                        }}
                        onRow={(record, index) => {
                            return {
                                onClick: () => {
                                    openJobOrder(false, record)
                                }
                            }
                        }
                        }
                        // title={() => 'Todays Tasks'}
                        style={{ cursor: "pointer", margin: "20px 0px 0px 0px" }}
                    />
                </Col>
            </Row>







            <JobOrderModal
                open={jobView}
                data={jobDetail}
                emitData={(data) => addData(data)}
                editable={editable}
                setEditFlag={setEditable}
                changed={change}
                setChange={setChange}
                loading={loading}
                setLoading={setLoading}
                close={() => {
                    setJobView(false)
                    setJobDetail(null)
                }}
            />{" "}
        </>
    )
}


export default withRouter(ServiceDashboard);