import React, { useContext, useState, useEffect } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
} from "antd";
import { platformApi } from "../../../../api";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../../ContextAPI";
import { getUUID } from '../../../../_helpers/utils'

const SITable = ({
  dataSource,
  pagination,
  spinner,
  modify,
  deleteData,
  setVisible,
  setEditable,
  setEditableData
}) => {
  const [tableKey, setTableKey] = useState(getUUID())

  const columns = [
    {
      title: "Part No",
      dataIndex: "partNo",
      key:"partNo",
      render: (record) => (
        <span>
          {record && record}
        </span>
      ),
    },
    {
      title: "Part Name",
      dataIndex: "partName",
      key: "partName",
      // render: (record, data) => <span>{record}</span>,
    },
    {
      title: "Part Status",
      dataIndex: "partNumber",
      key: "partNumber",
      // render: (record, data) => <span>{record.partStatus}</span>,
    },
    {
      title: "Manufacturer",
      dataIndex: "partNumber",
      key: "partNumber",
      // render: (record, data) => <span>{record.manufacturer.name ? record.manufacturer.name : ""}</span>,
    },
    {
      title: "SQuantity ",
      dataIndex: "stockQty",
      key:"stockQty",
      render: (record) => <span>{record&&record}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      // render: (record, data) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div>
          <span
            onClick={(event) => {
              event.stopPropagation();
              setEditable(true)
              setVisible(true)
              setEditableData(data)
            }}
            className="linkylink"
          >
            Modify
          </span>
          {
            <Popconfirm
              title="Are you sure delete this Spares Inventory?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                <Divider type="vertical" />
                  Delete
                 </span>
            </Popconfirm>
          }
        </div>
      ),
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      spinner={spinner}
      onRow={(data) => ({
        onClick: () => {
          setVisible(true)
          setEditableData(data)
        }
      })}
    />
  )
}

export default withRouter(SITable)