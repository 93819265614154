import React, { useEffect, useState } from 'react';
import Sidebar from './Components/Sidebar/Sidebar';
import Chat from './Components/Chat/Chat';
import { platformApi } from "../../../../api";

const WhatsappWeb = () => {

    const [usrId, setUsrId] = useState(null);
    const [chats, setChats] = useState([])

    const onFetchChat = (id) => {
        setUsrId(id)
        platformApi.get("/api/whatsapp/getChatMessage/" + id).then(res => {
            let { data } = res;
            let msg = data.msg.data[0];
            let chatData = [msg]
            setChats(chatData);
        }).catch(err => {
            console.log(err);
        })
    }


    // every 10 secs update
    useEffect(() => {
        let id;
        if (usrId) {
            id = setInterval(() => {
                platformApi.get("/api/whatsapp/getChatMessage/" + usrId).then(res => {
                    let { data } = res;
                    let msg = data.msg.data[0];
                    let chatData = [msg]
                    setChats(chatData);
                }).catch(err => {
                    console.log(err);
                })
            }, 7000);
        }
        return () => clearInterval(id);

    }, [chats])



    return (
        <div className="app">
            <div className="app-body" style={{ display: 'flex' }}>
                <Sidebar
                    onFetchChat={onFetchChat}
                    setUsrId={setUsrId}
                    usrId={usrId}
                />

                {chats.length > 0 && <Chat chats={chats} />}
            </div>

        </div>
    )
}

export default WhatsappWeb
