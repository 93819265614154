import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";

const { Text } = Typography;
import moment from 'moment'
const reminderTable = ({
    dataSource,
    openModal,
    spinner,
    pagination,
    firstDate,
    lastDate
}) => {
    const column = [
        {
            title: <span> Model Name</span>,
            dataIndex: "vehicle",
            key: "name",
            render: (record) => (
                <span>{record && `${record.modelCode} - ${record.modelName}`}</span>
            ),
        },
        {
            title: <span>Registration No</span>,
            dataIndex: "registerNo",
            key: "regno",
            render: (record) => <span>{record}</span>,
        },
        {
            title: <span>Service No</span>,
            dataIndex: "services",
            key: "serviceNo",
            render: (record, data) => {
                if (data.activeService) {
                    return <span>{data.activeService.serviceNo}</span>
                }
                return (
                    record.map(item => {
                        if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                            return (
                                <span>
                                    {item.serviceNo}
                                </span>
                            )
                        }
                    })
                )
            }
        },
        {
            title: <span>Service Type</span>,
            dataIndex: "services",
            key: "serviceType",
            render: (record, data) => {
                if (data.activeService) {
                    return <span>{data.activeService.serviceType}</span>
                }
                return (
                    record.map(item => {
                        if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                            return (
                                <span>
                                    {item.serviceType}
                                </span>
                            )
                        }
                    })
                )
            },
        },
        {
            title: <span>Service Kms</span>,
            dataIndex: "services",
            key: "serviceKms",
            render: (record, data) => {
                if (data.activeService) {
                    return <span>{data.activeService.serviceKms}</span>
                }
                return (
                    record.map(item => {
                        if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                            return (
                                <span>
                                    {item.serviceKms}
                                </span>
                            )
                        }
                    })
                )
            },
        },
        {
            title: <span>Service Date</span>,
            dataIndex: "services",
            key: "serviceDate", 
            render: (record, data) => {
                if (data.activeService) {
                    return <span>{moment(data.activeService.serviceDate).format("DD/MM/YYYY")}</span>
                }
                return (
                    record.map(item => {
                        if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                            return (
                                <span>
                                    {moment(item.serviceDate).format("DD/MM/YYYY")}
                                </span>
                            )
                        }
                    })
                )
            }
        },
        {
            title: <span>Owner</span>,
            dataIndex: "customer",
            key: "owner",
            render: (record) => (
                <span>
                    {record[0] && record[0].customer && record[0].customer.name}
                </span>
            ),
        },
        {
            title: <span>Active</span>,
            dataIndex: "active",
            key: "active",
            render: (record) => <span>{record === true ? "Yes" : "No"}</span>,
        },
        {
            title: <span>Mobile No</span>,
            dataIndex: "customer",
            key: "phone",
            render: (record) => (
                <span>
                    {record[0] && record[0].customer && record[0].customer.contacts[0]?.phone}
                </span>
            ),
        }
    ];
    return (
        <div>
            <Table
                rowKey={(record) => record.id}
                pagination={pagination}
                columns={column}
                style={{ cursor: "pointer" }}
                dataSource={dataSource}
                onRow={(props) => ({
                    onClick: () => {
                        openModal(props.id, false);
                    },
                })}
                loading={spinner}
            />
        </div>
    )
}
export default reminderTable;