import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  message,
  Select,
  Modal,
  Row,
  Col,
  Icon
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import _ from 'lodash';
import { platformApi } from '../../../../../api';
import { formatValue } from "../../../../../utils"

function SupplierData({ form, validityRef, data, clearRef, editable, setData, error, setError }) {
  const { getFieldDecorator } = form;

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [booleanstate, setbooleanstate] = useState(true);
  const [booleancity, setbooleancity] = useState(true);

  const [gstType, setGstType] = useState(false);
  const [gstName, setGstName] = useState('')
  const [gstStatus, setGstStatus] = useState('')
  useEffect(() => {
    platformApi.get('/api/csc/country')
      .then((res) => {
        setCountry(res.data.data);
      })
      .catch((err) => {
        message.error('Coudn\'t fetch Countries');
        console.error('Countries fetch error:', err);
      });
    setState([]);
    setCity([]);
    if (data && data.name) {
      platformApi.post('/api/csc/states', { id: data.address && data.address.country.id })
        .then((res) => {
          setState(res.data.data);
          setbooleanstate(false);
        })
        .catch((err) => {
          message.error('Couldn\'t fetch States');
          console.error('State fetch error :', err);
        });
      platformApi.post('/api/csc/cities', { id: data.address && data.address.state.id })
        .then((res) => {
          setCity(res.data.data);
          setbooleancity(false);
        })
        .catch((err) => {
          message.error('Couldn\'t fetch Cities');
          console.error('Cities fetch error :', err);
        });

      if (data.dealerType === "UnregisteredDealer") {
        setGstType(true);
      }
      form.setFieldsValue({
        name: data.name,
        dealerType: data.dealerType,
        supplierType: data.supplierType,
        status: data.status,
        GSTIN: data.GSTIN,
        remarks: data.remarks,
        phone: data.phone,
        whatsApp: data.whatsApp,
        address: {
          state: data.address && data.address.state.id,
          country: data.address && data.address.country.id,
          district: data.address && data.address.district.id,
          pincode: data.address && data.address.pincode,
          locality: data.address && data.address.locality,
          // street: data.address && data.address.street,
          line1: data.address && data.address.line1,
          line2: data.address && data.address.line2,
          line3: data.address && data.address.line3,
        },
      });
    }
    clearRef.current = () => {
      setGstName('')
      setGstStatus('')
      form.resetFields();
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  }, [data]);

  function checkGST(gst) {
    platformApi.post('/api/gstVerify', { gst })
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (response.data.data.error) {
              message.error('GST Invalid')
              setGstName('')
              setGstStatus('')
              return false;
            }
            else {
              message.success('GST Verified')
              delete error.GST;
              setError(error);
              setGstName(response.data.data.taxpayerInfo.tradeNam)
              setGstStatus(response.data.data.taxpayerInfo.sts)
              return true;
            }
          }
        }
      })
  }


  const selectParams = (e, type) => {
    if (type === 'country') {
      platformApi.post('/api/csc/states', { id: e })
        .then((res) => {
          setState(res.data.data);
          setbooleanstate(false);
        })
        .catch((err) => {
          message.error('Couldn\'t fetch States');
          console.error('State fetch error :', err);
        });
    }
    if (type === 'state') {
      platformApi.post('/api/csc/cities', { id: e })
        .then((res) => {
          setCity(res.data.data);
          setbooleancity(false);
        })
        .catch((err) => {
          message.error('Couldn\'t fetch Cities');
          console.error('Cities fetch error :', err);
        });
    }
  };


  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null && !error.GST;
    });
    if (isValid) {
      const allData = form.getFieldsValue();
      let obj1 = {
        id: allData.address.district
      }
      allData.address.district = obj1;
      let obj2 = {
        id: allData.address.state
      }
      allData.address.state = obj2;
      let obj3 = {
        id: allData.address.country
      }
      allData.address.country = obj3;
      if (data && data.contact) {
        allData.contact = data.contact
      }
      if (data && data.bank) {
        allData.bank = data.bank
      }
      setGstName('')
      setData(allData)
    }
    return isValid;
  };
  const filterMethod = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  return (
    <Form style={{ marginTop: '3vh' }}>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            validateStatus={error.SUPPLIERNAME && error.SUPPLIERNAME.type}
            help={error.SUPPLIERNAME && error.SUPPLIERNAME.message}
            label="Supplier Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Enter Supplier Name!',
                },
              ],
            })(<Input
              pattern="^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
              onKeyUp={e =>
                form.setFieldsValue({
                  name: formatValue(e, "allCaps")
                })}
              onInput={(event) => {
                if (!event.target.checkValidity()) {
                  setError({
                    ...error,
                    SUPPLIERNAME: {
                      type: "error",
                      message: "Enter Valid Name"
                    }
                  })
                } else {
                  delete error.SUPPLIERNAME
                  setError(error)
                }
              }}
              placeholder="Supplier Name"
              disabled={editable} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="GST Dealer Type">
            {getFieldDecorator('dealerType', {
              rules: [
                {
                  required: true,
                  message: 'Select GST Dealer Type!',
                },
              ],
            })(<Select
              placeholder="GST Dealer Type"
              disabled={editable}
              showAction={["click", "focus"]}
              onChange={(e) => {
                e === "UnregisteredDealer" ? (setGstType(true), setGstName(''), form.setFieldsValue({ GSTIN: undefined }), setError({})) : setGstType(false)
              }}
            >
              <Select.Option value="RegisteredDealer">Registered Dealer</Select.Option>
              <Select.Option value="UnregisteredDealer">Unregistered Dealer</Select.Option>
              <Select.Option value="UINHolder">UIN Holder</Select.Option>
              <Select.Option value="CompositionDealer">Composition Dealer</Select.Option>
            </Select>)}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Status">
            {getFieldDecorator('status', {
              rules: [
                {
                  required: true,
                  message: 'Select Status!',
                },
              ],
            })(<Select
              showAction={["click", "focus"]}
              placeholder="Status"
              disabled={editable}
            >
              <Select.Option value="ACTIVE">Active</Select.Option>
              <Select.Option value="INACTIVE">Inactive</Select.Option>
            </Select>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="GSTIN"
            validateStatus={error.GST && error.GST.type}
            help={error.GST && error.GST.message}
            required={!gstType}
          >
            {getFieldDecorator('GSTIN', {
              rules: [
                {
                  required: !gstType,
                  message: 'Enter GST number!',
                },
              ],
            })(<Input
              placeholder="GSTIN"
              maxLength={15}
              onKeyUp={event => {
                form.setFieldsValue({
                  GSTIN: formatValue(event, "toUpperCase")
                })
              }}
              disabled={editable || gstType}
              // pattern="^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[Zz]{1}[0-9A-Za-z]{1}$"
              onInput={(event) => {
                if (event.target.value.length === 15) {
                  let flag = checkGST(event.target.value)
                  if (flag) {
                    delete error.GST;
                    setError(error);
                  }
                  else {
                    setError({
                      ...error,
                      GST: {
                        type: "error",
                        message: "Enter Valid GSTIN",
                      },
                    });
                  }
                }
                else if (gstStatus != "Active") {
                  setGstStatus('')
                  setGstName('')
                  setError({
                    ...error,
                    GST: {
                      type: "error",
                      message: "The GSTIN entered is Inactive"
                    }
                  })
                }
                else {
                  setGstName('')
                  setError({
                    ...error,
                    GST: {
                      type: "error",
                      message: "Enter Valid GSTIN",
                    },
                  });
                }
              }}
            />)}
          </Form.Item>
          {gstName && (
            <Row type="flex" align="middle">
              <Icon
                style={{ fontSize: 18, marginRight: ".5rem" }}
                type={
                  "check-circle"
                }
                twoToneColor={
                  "#D00"
                }
              />
              <span style={{ color: 'green', fontSize: '12px' }}>{gstName} ({gstStatus})</span>
            </Row>
          )}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            validateStatus={error.LINE1 && error.LINE1.type}
            help={error.LINE1 && error.LINE1.message}
            label="Address Line 1">
            {getFieldDecorator('address.line1', {
              rules: [
                {
                  required: true,
                },
              ],
            })(<Input
              onKeyUp={e =>
                form.setFieldsValue({
                  address: {
                    line1: formatValue(e, "allCaps")
                  }
                })}
              onInput={(event) => {
                if (!event.target.checkValidity()) {
                  setError({
                    ...error,
                    LINE1: {
                      type: "error",
                      message: "Enter Valid Address"
                    }
                  })
                } else {
                  delete error.LINE1
                  setError(error)
                }
              }}
              pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
              maxLength={60}
              placeholder="Address Line 1"
              disabled={editable} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            validateStatus={error.LINE2 && error.LINE2.type}
            help={error.LINE2 && error.LINE2.message}
            label="Address Line 2">
            {getFieldDecorator('address.line2', {
              rules: [
                {
                  required: false,
                },
              ],
            })(<Input
              onKeyUp={e =>
                form.setFieldsValue({
                  address: {
                    line2: formatValue(e, "allCaps")
                  }
                })}
              onInput={(event) => {
                if (!event.target.checkValidity()) {
                  setError({
                    ...error,
                    LINE2: {
                      type: "error",
                      message: "Enter Valid Address Line 2"
                    }
                  })
                } else {
                  delete error.LINE2
                  setError(error)
                }
              }}
              pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
              maxLength={60}
              placeholder="Address Line 2"
              disabled={editable} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            validateStatus={error.LINE3 && error.LINE3.type}
            help={error.LINE3 && error.LINE3.message}
            label="Address Line 3">
            {getFieldDecorator('address.line3', {
              rules: [
                {
                  required: false,
                },
              ],
            })(<Input
              onKeyUp={e =>
                form.setFieldsValue({
                  address: {
                    line3: formatValue(e, "allCaps")
                  }
                })}
              onInput={(event) => {
                if (!event.target.checkValidity()) {
                  setError({
                    ...error,
                    LINE3: {
                      type: "error",
                      message: "Enter Valid Address"
                    }
                  })
                } else {
                  delete error.LINE3
                  setError(error)
                }
              }}
              pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
              maxLength={60}
              placeholder="Address Line 3"
              disabled={editable} />)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            validateStatus={error.LOCALITY && error.LOCALITY.type}
            help={error.LOCALITY && error.LOCALITY.message}
            label="Locality">
            {getFieldDecorator('address.locality', {
              rules: [
                {
                  required: true,
                  message: 'Enter Locality!',
                },
              ],
            })(<Input
              pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
              onKeyUp={e =>
                form.setFieldsValue({
                  address: {
                    locality: formatValue(e, "allCaps")
                  }
                })}
              onInput={(event) => {
                if (!event.target.checkValidity()) {
                  setError({
                    ...error,
                    LOCALITY: {
                      type: "error",
                      message: "Enter Valid Locality"
                    }
                  })
                } else {
                  delete error.LOCALITY
                  setError(error)
                }
              }}
              maxLength={50}
              placeholder="Locality"
              disabled={editable} />)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <FormItem label="Country:">
            {getFieldDecorator('address.country', {
              rules: [
                { required: true, message: 'Select Country!' },
              ],
            })(
              <Select
                showAction={["click", "focus"]}
                placeholder="Select Country"
                filterOption={filterMethod}
                onSelect={e => {
                  selectParams(e, 'country');
                  form.setFieldsValue({
                    address: { state: undefined, district: undefined },
                  })
                }
                }
                showSearch
                style={{ width: '100%' }}
                disabled={editable}
              >
                {country.map(d => (
                  <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <Form.Item label="State">
            {getFieldDecorator('address.state', {
              rules: [
                {
                  required: true,
                  message: 'Select State!',
                },
              ],
            })(<Select
              showAction={["click", "focus"]}
              placeholder="Select State"
              disabled={editable || booleanstate}
              filterOption={filterMethod}
              onSelect={e => {
                selectParams(e, 'state'),
                  form.setFieldsValue({
                    address: { district: undefined },
                  })
              }
              }
              showSearch
              style={{ width: '100%' }}
            >
              {state.map(d => (
                <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
              ))}
            </Select>)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <FormItem label="City:">
            {getFieldDecorator('address.district', {
              rules: [
                { required: true, message: 'Select City!' },
              ],
            })(
              <Select
                showAction={["click", "focus"]}
                placeholder="Select City"
                disabled={editable || booleancity}
                filterOption={filterMethod}
                showSearch
                style={{ width: '100%' }}
              >
                {city.map(d => (
                  <Select.Option key={d.id} value={d.id}>{d.name}</Select.Option>
                ))}
              </Select>,
            )}
          </FormItem>
        </Col>
        <Col span={12}>
          <Form.Item
            validateStatus={error.PIN && error.PIN.type}
            help={error.PIN && error.PIN.message}
            label="Pincode">
            {getFieldDecorator('address.pincode', {
              rules: [
                {
                  required: true,
                  message: 'Enter PIN Code!',
                },
              ],
            })(<Input
              maxLength={7}
              placeholder="Pincode"
              disabled={editable}
              onKeyUp={e =>
                form.setFieldsValue({
                  "address.pincode": formatValue(e, "onlyNo")
                })}
              pattern="([0-9]{6}|[0-9]{3}\s[0-9]{3})"
              onInput={(event) => {
                if (!event.target.checkValidity()) {
                  setError({
                    ...error,
                    PIN: {
                      type: "error",
                      message: "Enter Valid PIN Code",
                    },
                  });
                } else {
                  delete error.PIN;
                  setError(error);
                }
              }}
            />)}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Supplier Type">
            {getFieldDecorator('supplierType', {
              rules: [
                {
                  required: true,
                  message: 'Select Supplier Type!',
                },
              ],
            })(<Select
              showAction={["click", "focus"]}
              placeholder="Supplier Type"
              disabled={editable}
              mode="multiple"
            >
              <Select.Option value="Vehicles">Vehicles</Select.Option>
              <Select.Option value="Accessories">Accessories</Select.Option>
              <Select.Option value="Spares">Spares</Select.Option>
              <Select.Option value="Battery">Battery</Select.Option>
              <Select.Option value="Tyre">Tyre</Select.Option>
              <Select.Option value="Consumables">Consumables</Select.Option>
            </Select>)}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Remarks">
            {getFieldDecorator('remarks', {
              rules: [
                {
                  required: false,
                },
              ],
            })(<Input
              onKeyUp={e =>
                form.setFieldsValue({
                  remarks: formatValue(e, "firstCaps")
                })}
              placeholder="Remarks" disabled={editable} />)}
          </Form.Item>
        </Col>
      </Row>


    </Form>

  )
}
const WrappedSupplierDataForm = Form.create({ name: 'SupplierDataForm' })(SupplierData);
export default WrappedSupplierDataForm;