import React, { useState, useEffect } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import {
    Modal,
    Select,
    Form,
    Row,
    Col,
    Input,
    Typography,
    message,
    Button,
} from "antd";
import { platformApi } from "../../../../api";
const { Text, Title } = Typography;
const OtpSend = ({
    form,
    open,
    close,
    data,
    editable,
    modify,
    referenceid
}) => {
    const history = useHistory();
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [error, setError] = useState({});
    const [code, setCode] = useState('');


    const handleSubmit = () => {
        form.validateFields(async (validationError) => {
            if (validationError === null) {
                if (referenceid) {
                    console.log("submit", data.id, getFieldValue("otp"), code)
                    let payload = {
                        referenceId: referenceid,
                        passcode: form.getFieldValue("otp") ? form.getFieldValue("otp") : code,
                        jobOrder: data.id
                    }
                    platformApi.post("/api/jobOrder/verifyOtp", payload)
                        .then(res => {
                            const { data } = res
                            console.log(res)
                            if (data.code === 200) {
                                const { response } = data
                                if (response.code === 200) {
                                    const { data } = response
                                    const { isValid } = data
                                    console.log("object", data)
                                    if (isValid) {
                                        message.success("Otp verified successfully", 3)
                                        close();
                                    }
                                    else {
                                        message.error("Entered OTP is invalid", 3)
                                    }
                                }
                                else {
                                    message.error("Unable to verify OTP", 2)
                                }
                            }
                            else {
                                message.error("Unable to verify OTP", 2)
                            }
                        })
                        .catch(e => {
                            message.error("Unable to verify OTP", 2)
                        })
                }
            }
        });
    };


    const clearFieldsAndClose = () => {
        form.resetFields();
        close();
        history.push('/autoadmin/job_order');
    };
    return (
        <Modal
            title={<Title level={4}>Approval OTP Verification</Title>}
            visible={open}
            onCancel={clearFieldsAndClose}
            okText={!editable ? "Modify" : "Submit"}
            okButtonProps={{ disabled: (!editable && !modify) }}
            onOk={() => (editable ? handleSubmit() : setEditable(true))}
        >
            <Form className="purchase-form">
                <Row>
                    <Col span={12}>
                        <Text strong>
                            JOB NO&nbsp;&nbsp;&nbsp;: {data && data.jobNo}
                        </Text>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between">
                    <Col span={24}>
                        <Form.Item
                            className="purchase-form-element"
                            colon={false}
                            required
                            validateStatus={error.OTP && error.OTP.type}
                            help={error.OTP && error.OTP.message}
                            label={<Text>Approval OTP</Text>}
                            colon={false}
                        >
                            {getFieldDecorator("otp", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Enter Approval OTP",
                                    },
                                ],
                            })(
                                <Input
                                    disabled={!editable}
                                    onChange={(event) => {
                                        if (!event.target.value) {
                                            setCode(event.target.value)
                                        }
                                    }}
                                    placeholder="Approval Otp"
                                />

                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
const WrappedOtpSend = Form.create("otp_verification")(
    OtpSend
);

export default WrappedOtpSend;