import React, { useEffect } from "react";
import { Typography, Form, Row, Col, Input, DatePicker } from "antd";
import { formatValue} from "../../../../../utils"
const { Item } = Form;
const { Text } = Typography;

const PaymentForm = ({ form, clearRef }) => {
  const { getFieldDecorator } = form;

  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
    };

    return () => {
      clearRef.current = null;
    };
  });

  return (
    <Form>
      {/* <Row type="flex" justify="space-between">
        <Col span={11}>
          <Text>File No:</Text>
        </Col>
        <Col span={11}>
          <Text>Chassis No:</Text>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Text>Model Code:</Text>
        </Col>
        <Col span={11}>
          <Text>Engine No:</Text>
        </Col>
      </Row> */}
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>HP</Text>} colon={false}>
            {getFieldDecorator("hp")(<Input type="number"
                    onWheel={(event) => event.currentTarget.blur()} placeholder="HP" />)}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>EMI Start Date</Text>} colon={false}>
            {getFieldDecorator("emiData")(
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="EMI Start Date"
                style={{ width: "100%" }}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Affidavit Amount</Text>} colon={false}>
            {getFieldDecorator("affidavit")(
              <Input
                type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                placeholder="Affidavit Amount"
                onKeyUp={e =>
                          form.setFieldsValue({                            
                            affidavit:formatValue(e,"noWithDot")
                          })}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>EMI Amount</Text>} colon={false}>
            {getFieldDecorator("emi")(
              <Input addonBefore="&#8377;" placeholder="EMI Amount"
               onKeyUp={e =>
                          form.setFieldsValue({                            
                            emi:formatValue(e,"noWithDot")
                          })}
              
               />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Road Tax and Handling</Text>} colon={false}>
            {getFieldDecorator("roadTax")(
              <Input
                // type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                placeholder="Road Tax and Handling"
                onKeyUp={e =>
                          form.setFieldsValue({                            
                            roadTax:formatValue(e,"noWithDot")
                          })}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Tenure</Text>} colon={false}>
            {getFieldDecorator("tenure")(
              <Input
                // type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                placeholder="Tenure"
                addonBefore="&#8377;"
                onKeyUp={e =>
                          form.setFieldsValue({                            
                            tenure:formatValue(e,"onlyNo")
                          })}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Insurance Amount</Text>} colon={false}>
            {getFieldDecorator("insurance")(
              <Input
                // type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                placeholder="Insurance Amount"
                onKeyUp={e =>
                          form.setFieldsValue({                            
                            insurance:formatValue(e,"noWithDot")
                          })}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Down Payment</Text>} colon={false}>
            {getFieldDecorator("downPayment")(
              <Input
                placeholder="Down Payment"
                addonBefore="&#8377;"
                // type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyUp={e =>
                          form.setFieldsValue({                            
                            downPayment:formatValue(e,"noWithDot")
                          })}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Ex-Showroom Price</Text>} colon={false}>
            {getFieldDecorator("showroomPrice")(
              <Input
                type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                placeholder="Ex-Show Room Price"
                disabled={true}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Finance Charge</Text>} colon={false}>
            {getFieldDecorator("financeCharge")(
              <Input
                placeholder="Finance Charge"
                addonBefore="&#8377;"
                // type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyUp={e =>
                          form.setFieldsValue({                            
                            financeCharge:formatValue(e,"noWithDot")
                          })}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Discount</Text>} colon={false}>
            {getFieldDecorator("discount")(
              <Input
                // type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                placeholder="Discount"
                onKeyUp={e =>
                          form.setFieldsValue({                            
                            discount:formatValue(e,"noWithDot")
                          })}
              />
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Item label={<Text>Financer Disbursement Amount</Text>} colon={false}>
            {getFieldDecorator("financerDisbursement")(
              <Input
                placeholder="Financer Disbursement Amount"
                addonBefore="&#8377;"
                // type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                    onKeyUp={e =>
                          form.setFieldsValue({                            
                            financerDisbursement:formatValue(e,"noWithDot")
                          })}
              />
            )}
          </Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={11}>
          <Item label={<Text>Total Cost</Text>} colon={false}>
            {getFieldDecorator("total")(
              <Input
                type="number"
                    onWheel={(event) => event.currentTarget.blur()}
                addonBefore="&#8377;"
                placeholder="Total Cost"
                disabled={true}
              />
            )}
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedPaymentForm = Form.create()(PaymentForm);

export default WrappedPaymentForm;
