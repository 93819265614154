import React, { useState, useEffect } from "react";
import { Table, Select, Input } from "antd";

export default function ServicesTable(props) {
  const [dataSource, setData] = useState(props.dataSource);

  const { editable } = props;

  const handleChange = (value, id, type) => {
    const data = [...dataSource];
    dataSource.some((obj, index) => {
      if (obj.serviceNo === id) {
        data[index][type] = value;
      }
    });
    setData(data);
    props.emitData(data);
  };

  useEffect(() => {
    setData([]);
    let data;
    let datas = [];
    for (let itr = 0; itr < props.count; itr++) {
      data =
        itr + 1 <= props.dataSource.length
          ? props.dataSource[itr]
          : {
            id: "",
            serviceNo: itr + 1,
            serviceType: "FREE",
            serviceKm: 0,
            serviceDays: 0,
            amount: 0
          };
      datas = [...datas, data];
    }
    setData(datas);
  }, [props.dataSource]);

  useEffect(() => {
    setData([]);
    let data;
    let datas = [];
    for (let itr = 0; itr < props.count; itr++) {
      data =
        itr + 1 <= props.dataSource.length
          ? props.dataSource[itr]
          : {
            id: "",
            serviceNo: itr + 1,
            serviceType: "FREE",
            amount: 0,
            serviceKm: 0,
            serviceDays: 0
          };
      datas = [...datas, data];
    }
    setData(datas);
    props.emitData(datas);
  }, [props.count]);

  const types = [
    { id: "FREE", title: "Free" },
    { id: "PAID", title: "Paid" },
    { id: "BONUS", title: "Bonus" }
  ];

  const column = [
    {
      title: "Service No",
      dataIndex: "serviceNo",
      key: "serviceNo",
      render: text => <span>{text}</span>
    },
    {
      title: "Service Type",
      dataIndex: "serviceType",
      key: "serviceType",
      render: (text, record) => (
        <Select
          showAction={["click", "focus"]}
          onChange={key => handleChange(key, record.serviceNo, "serviceType")}
          disabled={!editable}
          value={text}
        >
          {types.map(obj => (
            <Select.Option key={obj.id} value={obj.id}>
              {obj.title}
            </Select.Option>
          ))}
        </Select>
      )
    },
    {
      title: "Service KMs",
      dataIndex: "serviceKm",
      key: "kms",
      render: (text, record) => (
        <Input
          key="kms"
          disabled={!editable}
          onInput={event => {
            handleChange(
              parseInt(event.target.value) || 0,
              record.serviceNo,
              "serviceKm"
            );
          }}
          value={text}
        />
      )
    },
    {
      title: "Service Days",
      dataIndex: "serviceDays",
      key: "days",
      render: (text, record) => (
        <Input
          key="days"
          disabled={!editable}
          maxLength={4}
          onInput={event => {
            handleChange(
              parseInt(event.target.value) || 0,
              record.serviceNo,
              "serviceDays"
            );
          }}
          value={text}
        />
      )
    },
    // {
    //   title: "Price",
    //   dataIndex: "amount",
    //   key: "amount",
    //   render: (text, record) => (
    //     <Input
    //       key="amount"
    //       onInput={event => {
    //         handleChange(
    //           parseFloat(event.target.value) || 0,
    //           record.serviceNo,
    //           "amount"
    //         );
    //       }}
    //       disabled={!editable}
    //       value={text}
    //     />
    //   )
    // }
  ];

  return (
    <Table
      pagination={{ pageSize: 3 }}
      columns={column}
      dataSource={dataSource}
    />
  );
}
