import React from "react";
import {
  Table,
  Divider,
  Popconfirm,
  Typography,
  Row,
  Col,
  Avatar,
  Button,
  Icon,
} from "antd";
import { withRouter } from "react-router-dom";
import moment from 'moment'

const { Text, Paragraph } = Typography;

const EmployeeTable = ({
  pagination,
  dataSource,
  openModal,
  spinner,
  user,
  history,
  delete: deleteData,
}) => {
  const { goBack } = history;

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={() => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Profile",
      dataIndex: "profilePicture",
      key: "profile",
      render: (url) => (
        <span>
          <Avatar icon={!url && 'user'} src={url && url} size={48} />
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "profile.employeeName",
      key: "name",
      render: (text) => <span>{text ? text : 'XXXXX'}</span>,
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      key: "number",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Alternate Number",
      dataIndex: "phone2",
      key: "number",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "joinedOn",
      render: (text) => <span>{moment(new Date(text)).format('DD/MM/YYYY')}</span>,
    },
    // {
    //   render: () => <span></span>,
    // },
  ];

  const expandedRow = (record) => {
    let aadhaar = record.profile.documents[2] && record.profile.documents[2].typeValue;
    aadhaar &&
      (aadhaar = `${aadhaar.slice(0, 4)} ${aadhaar.slice(4, 8)} ${aadhaar.slice(
        8,
        12
      )}`);
    return (
      <div>
        <Row type="flex" justify="space-between">
          <Col span={4}>
            <Text type="secondary">
              Father's Name
            </Text>
            <Paragraph>{record.profile.fatherName ? record.profile.fatherName : 'XXXXX'}</Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary" >
              DOB
            </Text>
            <Paragraph>
              {record.profile.dateOfBirth ? new Date(record.profile.dateOfBirth).toLocaleDateString() : 'XXXXX'}
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary" >
              Blood Group
            </Text>
            <Paragraph>{record.profile.bloodGroup ? record.profile.bloodGroup : 'XXXXX'}</Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary">Employee Id</Text>
            <Text className="align-employeeDetails">
              {record && record.profile?.employeeId}
            </Text>
          </Col>
          <Col span={5}>
            <Text type="secondary" >Status</Text>
            <Text className="align-employeeDetails">
              {record && record.status ? "Active" : "Inactive"}
            </Text>
          </Col>
        </Row>
        <Row gutter={16} className="row-employeeSpace">
          <Col span={4}>
            <Text type="secondary"  >
              Pan Number
            </Text>
            <Paragraph
              // copyable={values.profile.documents[0] && values.profile.documents[1].typeValue !== null}
              // disabled={values.profile.documents[0] && values.profile.documents[1].typeValue === null}
              copyable={record.profile.documents[0] && record.profile.documents[1].typeValue !== null}
              disabled={record.profile.documents[0] && record.profile.documents[1].typeValue === null}
              style={{ textTransform: "uppercase" }}
            >
              {record.profile.documents[1] && record.profile.documents[1].typeValue || "XXXXXXXXXX"}
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary" >
              Aadhaar Number
            </Text>
            <Paragraph
              type="secondary"
              copyable={record.profile.documents[0] && record.profile.documents[2].typeValue !== null}
              disabled={record.profile.documents[0] && record.profile.documents[2].typeValue === null}
            >
              {record.profile.documents[2] && record.profile.documents[2].typeValue || "XXXX XXXX XXXX"}
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary" >
              Driving License
            </Text>
            <Paragraph
              type="secondary"
              style={{ textTransform: "uppercase" }}
              copyable={record.profile.documents[0] && record.profile.documents[0].typeValue !== null}
              disabled={record.profile.documents[0] && record.profile.documents[0].typeValue === null}
            // copyable={record.profile.documents[0] && record.profile.documents[0].typeValue ? false : true}
            // disabled={record.profile.documents[0] && record.profile.documents[0].typeValue ? false : true}
            >
              {record.profile.documents[0] && record.profile.documents[0].typeValue || "XXXXXXXXXXX"}
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary" >
              Bank Account Details
            </Text>
            <Paragraph >
              <Row>
                <Text>Name : {record.profile.bankDetails ? record.profile.bankDetails.accountName : 'XXXXX'}</Text>
              </Row>
              <Row>
                <Text>A/C No : {record.profile.bankDetails ? record.profile.bankDetails.accountNumber : 'XXXXX'}</Text>
              </Row>
              <Row>
                <Text style={{ textTransform: "uppercase" }}>IFSC : {record.profile.bankDetails ? record.profile.bankDetails.ifsc : 'XXXXX'}</Text>
              </Row>
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary" >
              Address
            </Text>
            <Paragraph>
              {
                `${record.profile.address ? record.profile.address.street : "XXXXX"},
              ${record.profile.address ? record.profile.address.locality : 'XXXXX'},
            ${record.profile.address ? record.profile.address.district.name : "XXXXX"},
            ${record.profile.address ? record.profile.address.state.name : "XXXXX"},
            ${record.profile.address ? record.profile.address.country.name : 'XXXXX'}`}
            </Paragraph>
          </Col>
        </Row>
        <Row gutter={16} className="row-employeeSpace">
          <Col span={5}>
            <Text type="secondary"> Aadhaar Card</Text>
            <a
              href={
                record &&
                record.profile &&
                record.profile.documents[2] &&
                record.profile.documents[2].files &&
                record.profile.documents[2].files.url
              }
              target="_blank"
              className="align-employeeDetails"
              download
            >
              <Button
                disabled={record.profile.documents[2] && record.profile.documents[2].files.url ? false : true}
                type="primary"
                icon="download"
                size="small"
              >
                Download
              </Button>
            </a>
            {/* <Button
              className="align-employeeDetails"
              icon="download"
              size="small"
              type="ghost"
              onClick={() => {
                window.open(record.profile.documents[2] && record.profile.documents[2].files.url, "_blank");
              }}
              disabled={record.profile.documents[2] && record.profile.documents[2].files.url ? false : true}
            >
              Aadhaar Card
            </Button> */}
          </Col>
          <Col span={5}>
            <Text type="secondary">Pan Card</Text>
            <a
              href={
                record &&
                record.profile &&
                record.profile.documents[1] &&
                record.profile.documents[1].files &&
                record.profile.documents[1].files.url
              }
              target="_blank"
              className="align-employeeDetails"
              download
            >
              <Button
                disabled={record.profile.documents[1] && record.profile.documents[1].files.url ? false : true}
                type="primary"
                icon="download"
                size="small"
              >
                Download
              </Button>
            </a>
          </Col>
          <Col span={5}>
            <Text type="secondary"> Driving License</Text>
            <a
              href={
                record &&
                record.profile &&
                record.profile.documents[0] &&
                record.profile.documents[0].files &&
                record.profile.documents[0].files.url
              }
              target="_blank"
              className="align-employeeDetails"
              download
            >
              <Button
                disabled={record.profile.documents[0] && record.profile.documents[0].files.url ? false : true}
                type="primary"
                icon="download"
                size="small"
              >
                Download
              </Button>
            </a>
          </Col>
          <Col span={5}>
            <Text type="secondary"> Pass Book</Text>
            <a
              href={
                record &&
                record.profile &&
                record.profile.documents[3] &&
                record.profile.documents[3].files &&
                record.profile.documents[3].files.url
              }
              target="_blank"
              className="align-employeeDetails"
              download
            >
              <Button
                disabled={record.profile.documents[3] && record.profile.documents[3].files.url ? false : true}
                type="primary"
                icon="download"
                size="small"
              >
                Download
              </Button>
            </a>
          </Col>
          <Col span={4}>
            <span
              onClick={(event) => {
                openModal(record.id, true);
              }}
              className="linkylink"
            >
              Modify
            </span>
            {user !== record.id && (
              <span>
                <Divider type="vertical" />
                <Popconfirm
                  title="Do you want to delete this Deparment?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={(event) => {
                    deleteData(record.id);
                  }}
                >
                  <span className="linkylink">Delete</span>
                </Popconfirm>
              </span>
            )}
          </Col>
        </Row>
      </div >
    );
  };

  const CustomIcon = (props) => {
    const toggleExpand = (event) => {
      props.onExpand(props.record, event);
    };
    return (
      <Button type="ghost" size="small" shape="circle" onClick={toggleExpand}>
        ...
      </Button>
    );
  };

  return (
    <Table
      rowKey={(record) => record.id}
      style={{ cursor: "pointer" }}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      expandRowByClick={true}
      expandIcon={CustomIcon}
      expandIconColumnIndex={5}
      expandIconAsCell={false}
      expandedRowRender={expandedRow}
      loading={spinner}
    />
  );
};

export default withRouter(EmployeeTable);
