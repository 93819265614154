import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Tooltip, Dropdown, Menu, Icon, message } from "antd";
import "./index.less";
import { ContextAPI } from "../../../ContextAPI";
import ImportExportData from "./ImportExportData";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined 
} from '@ant-design/icons';

// Components
import JobCodeTable from "./JobCodeTable";
import { platformApi } from "../../../api";
import WrappedAccessoriesModal from "./JobCodeForm";
import WrappedAdvancedFilter from "./AdvancedFilters";
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

const JobCode = (props) => {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  //filters
  const [filter, setFilter] = useState([]);
  const [csvModal, setCsvModal] = useState(false);
  const [upload, setUpload] = useState(false);
  const [filteropen, setFilteropen] = useState(false);
  const [search, setSearch] = useState("");
  const [exportFile, setExportFile] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete(`/api/jobCode/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Job Code deleted successfully");
            if (temp) {
              setPage(1)
              setPrimaryData(null, 1)
            }
            else {
              setPrimaryData();
            }
          } else {
            message.error("Unable to delete this Job Code");
          }
        } else {
          message.error("Unable to delete this Job Code");
        }
      })
      .catch((error) => {
        console.error("Error on Job Code : ", error);
        message.error("Cannot delete this Job Code");
        setSpinning(false);
      });
  };

  useEffect(() => {
    platformApi.get("/api/jobCode/data/export").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        setExportFile(`${ENDPOINT}${data.response}`);
      }
    });
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "job_code") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPrimaryData();
  }, []);


  const setPrimaryData = (string, val) => {
    setSpinning(true);
    platformApi
      .post("/api/jobCode/get", {
        page: val ? val : page,
        size: limit,
        searchString: string,
      })
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setCount(response.data.count);
            setData(response.data.jobCode);
          } else {
            message.error("Unable to fetch Job Code", 2);
          }
        } else {
          message.error("Unable to fetch Job Code", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Job Code : ", error);
        message.error("Unable to fetch Job Code", 2);
      });
  };



  useEffect(() => {
    setPrimaryData(search)
  }, [search, page, limit]);


  const addData = (job) => {
    setSpinning(true);
    if (job.id) {
      platformApi
        .put(`/api/jobCode/${job.id}`, job)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === job.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success("Job Code saved successfully", 2);
            } else {
              message.error("Unable to save Job Code", 2);
            }
          } else {
            message.error("Unable to save Job Code", 2);
          }
        })
        .catch((error) => {
          console.error("Error on Job Code : ", error);
          setSpinning(false);
          message.error("Unable to save Job Code", 2);
        });
    } else {
      platformApi
        .post("/api/jobCode", job)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Job Code added successfully", 2);
            } else {
              message.error("Unable to add new Job Code", 2);
            }
          } else if (data.code == 500 && data.err.code == 501) {
            message.error("JobCode already exists", 2);
          } else {
            message.error("Unable to add Job Code", 2);
          }
        })
        .catch((error) => {
          console.error("Error on Job Code : ", error);
          message.error("Unable to add new Job Code");
          setSpinning(false);
        });
    }
  };

  const uploadCSV = (event) => {
    if (event.file.status === "done") {
      setSpinning(true);
      setUpload(true);
      setCsvModal(false);
      const formData = new FormData();
      formData.append("JOBCODE", event.file.originFileObj);
      platformApi
        .post("/api/jobCode/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => { 
          setUpload(false);
          setSpinning(false);
          if (res.data.code === 200) {
            message.success("Import Data Done");
            window.location.reload();
            setPrimaryData(null)
          } else {
            message.error("Cannot Import Data");
          }
        })
        .catch((error) => {
          setSpinning(false);
        });
    }
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Job Code [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Codes"
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        {/* <div style={{width:"20%",textAlign:"center"}}>
          <Button type="link"onClick={()=>{filteropen?setFilteropen(false):setFilteropen(true)}}>
            { filteropen?"Back":"Advanced  Filters"}
          </Button>
        </div> */}
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Codes
        </Button>
      </div>
      <div className="accessories-head" style={{ margin: "0 80.4% 2%" }}>
        <Button
          onClick={() => {
            setCsvModal(true);
          }}
          type="primary"
          disabled={upload}
          disabled={!createAccess}
        >
          Import / Export CSV
        </Button>
      </div>
      <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        close={setFilteropen}
        id={filter}
        setId={setFilter}
        setData={setData}
        setPrimaryData={setPrimaryData}
      />
      <ImportExportData
        upload={(event) => uploadCSV(event)}
        open={csvModal}
        close={() => setCsvModal(false)}
        exportFile={exportFile}
        createAccess={createAccess}
      />
      <JobCodeTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          total: count,
          current: page,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{ spinning }}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <WrappedAccessoriesModal
        editable={editFlag}
        data={dataSource[dataSource.findIndex((data) => data.id === selected)]}
        open={addFlag}
        emitData={(data) => addData(data)}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
        modify={modifyAccess}
      />
    </div>
  );
}

export default withRouter(JobCode)