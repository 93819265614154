import React, { useEffect, useState } from "react";
import { Form, Select, Typography, Row, Col, Input, DatePicker } from "antd";
import { platformApi } from "../../../../../api";
import moment from "moment";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;

const JobData = ({
  form,
  validityRef,
  data,
  clearRef,
  id,
  open,
  editable,
  recvData,
}) => {
  const [serviceManager, setServiceManager] = useState([]);
  const [mechanic, setMechanic] = useState([]);
  const [jobStartDate, setStartDate] = useState();
  const [jobEndDate, setEndDate] = useState();

  useEffect(() => {
    platformApi.get("/api/user/").then((result) => {
      let { data } = result;
      if (data.code === 200) {
        setServiceManager(data.data.users);
        setMechanic(data.data.users);
      }
    });
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      serviceType: data.serviceType,
      serviceManager: data.serviceManager,
      mechanic: data.mechanic,
      serviceNo: data.serviceNo,
      couponNo: data.couponNo,
      eAmount: data.estAmount,
      mfgDate: data.jobStartDateTime,
      endDate: data.jobEndDateTime,
    });
  }, [open]);

  useEffect(() => {
    if (recvData.serviceManager) {
      let date1 = moment(recvData.jobStartDateTime);
      let date2 = moment(recvData.jobEndDateTime);
      form.setFieldsValue({
        serviceType: recvData.serviceType,
        // serviceManager: recvData.serviceManager.profile.employeeName,
        serviceManager: recvData.serviceManager.id,
        // mechanic: recvData.mechanic.profile.employeeName,
        mechanic: recvData.mechanic.id,
        serviceNo: recvData.serviceNo,
        couponNo: recvData.couponNo,
        eAmount: recvData.estAmount,
        mfgDate: date1,
        endDate: date2,
      });
      setStartDate(recvData.jobStartDateTime);
      setEndDate(recvData.jobEndDateTime);
    }
  }, [recvData, editable]);

  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
    };
    validityRef.current = () => validatePage();
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });
  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.jobNo = id;
      data.dateTime = new Date();
      data.serviceType = getFieldValue("serviceType");
      data.serviceManager = getFieldValue("serviceManager");
      data.mechanic = getFieldValue("mechanic");
      data.serviceNo = getFieldValue("serviceNo");
      data.couponNo = getFieldValue("couponNo");
      data.estAmount = parseFloat(getFieldValue("eAmount"));
      data.jobStartDateTime = getFieldValue("mfgDate");
      data.jobEndDateTime = getFieldValue("endDate");
    }
    return isValid;
  };

  const checkDateValidity = (moment, type) => {
    if (type === "endDate") {
      return moment._d < form.getFieldValue("mfgDate");
    } else if (type === "mfgDate") {
      if (form.getFieldValue("endDate"))
        return form.getFieldValue("endDate") < moment._d;
      else return false;
    }
  };

  const onChangeStart = (date, dateString) => {
    setStartDate(dateString);
  };

  const onChangeEnd = (date, dateString) => {
    setEndDate(dateString);
  };

  const onStartOk = (value) => {
  };

  const onEndOk = (value) => {
  };

  const serviceType = [
    { key: 1, title: "Free" },
    { key: 2, title: "Extended Warranty" },
    { key: 3, title: "Paid (UW)" },
    { key: 4, title: "Paid (AW)" },
    { key: 5, title: "AMC" },
    { key: 6, title: "Accidental" },
  ];
  const jobStatus = [
    { key: 1, title: "Pending" },
    { key: 2, title: "Executed" },
  ];
  const { getFieldDecorator, getFieldValue } = form;
  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={7}>Job Order No. : {id}</Col>
        <Col span={7}></Col>
        <Col span={7}>
          Date : {new Date().toLocaleDateString()}
          <br />
          Time : {new Date().toLocaleTimeString()}
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item colon={false} label={<Text>Service Type</Text>}>
            {getFieldDecorator("serviceType", {
              rules: [
                {
                  required: true,
                  message: "Please Enter ServiceType",
                },
              ],
            })(
              <Select disabled={!editable} placeholder="Service Type">
                {serviceType.map((data) => (
                  <Select.Option key={data.title}>{data.title}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item colon={false} label={<Text>Service Manager</Text>}>
            {getFieldDecorator("serviceManager", {
              rules: [
                {
                  required: true,
                  message: "Please Select ServiceManager",
                },
              ],
            })(
              <Select disabled={!editable} placeholder="Service Manager">
                {serviceManager.map((data) => (
                  <Select.Option key={data.id}>
                    {data.profile.employeeName}
                    {/* - {data.department.role} */}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item colon={false} label={<Text>Mechanic</Text>}>
            {getFieldDecorator("mechanic", {
              rules: [
                {
                  required: true,
                  message: "Please select Mechanic",
                },
              ],
            })(
              <Select disabled={!editable} placeholder="Mechanic">
                {mechanic.map((data) => (
                  <Select.Option key={data.id}>
                    {data.profile.employeeName}
                    {/* - {data.department.role} */}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item colon={false} label={<Text>Service No.</Text>}>
            {getFieldDecorator("serviceNo", {
              rules: [
                {
                  required: false,
                  message: "Please input your Service No.!",
                },
              ],
            })(<Input disabled={!editable} placeholder="Service No." />)}
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item colon={false} label={<Text>Coupon No.</Text>}>
            {getFieldDecorator("couponNo", {
              rules: [
                {
                  required: false,
                  message: "Please input your Coupon No.!",
                },
              ],
            })(<Input disabled={!editable} placeholder="Coupon No." />)}
          </Form.Item>
        </Col>

        <Col span={7}>
          <Form.Item colon={false} label={<Text>Estimated Amount</Text>}>
            {getFieldDecorator("eAmount", {
              rules: [
                {
                  required: true,
                  message: "Please Enter Estimated Amount!",
                },
              ],
            })(
              <Input
                type="number"
                disabled={!editable}
                addonBefore="&#8377;"
                placeholder="Estimated Amount"
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Form.Item
            colon={false}
            label={
              <Text>
                Job Start Date
                {"&"} Time
              </Text>
            }
          >
            {getFieldDecorator("mfgDate")(
              <DatePicker
                allowClear
                disabled={!editable}
                format="DD-MM-YYYY HH:mm"
                showTime={{ format: "HH:mm" }}
                onChange={onChangeStart}
                onOk={onStartOk}
              // disabledDate={(moment) => checkDateValidity(moment, "mfgDate")}
              />
            )}
          </Form.Item>
        </Col>

        <Col span={7}>{""}</Col>

        <Col span={7}>
          <Form.Item
            colon={false}
            label={
              <Text>
                Job End Date
                {"&"} Time
              </Text>
            }
          >
            {getFieldDecorator("endDate")(
              <DatePicker
                allowClear
                disabled={!editable}
                format="DD-MM-YYYY HH:mm"
                showTime={{ format: "HH:mm" }}
                onChange={onChangeEnd}
                onOk={onEndOk}
                // disabledDate={(moment) => checkDateValidity(moment, "endDate")}
                disabledDate={(current) => {
                  return moment().add(-1, 'days') >= current;

                }}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedJobData = Form.create()(JobData);

export default WrappedJobData;
