import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, message, Slider } from "antd";
import { platformApi } from "../../../../api";
const { Item } = Form;
const { Option } = Select;
function AdvancedFilters({
  form,
  filteropen,
  setId,
  id,
  setPageData,
  setSearch,
  setData,
  setCount,
  limit,
  page,
  setSpinning
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [model, setModels] = useState([]);
  const Filters = [
    { id: "1", name: "Accesories Name & Code" },
    //   { id: "2", name:'Code'},
    { id: "3", name: "Vehicles Associated" },
    //   { id: "4", name:'Price Range'},
  ];
  //price range

  function formatter(value) {
    return `${value * 1000 + 25000}`;
  }
  const marks = {
    0: "₹25000",
    25: "₹50000",
    50: "₹75000",
    75: "₹100000",
    100: "₹125000",
  };
  const selectFilter = (id) => {
    setId(id);
  };
  const searchValue = () => {
    setId([]);
    resetFields();
    setPageData();
    setSearch([]);
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
  };
  useEffect(() => {
    if (filteropen) {
      setFieldsValue({ selectfilter: id });
    }
  }, [filteropen]);

  useEffect(() => {
    if (id.includes("3")) {
      platformApi.get(`/api/vehicleMaster`).then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setModels(response.data);
          }
        }
      });
    }
  }, [id]);
  const branchSelect = (id) => {
  };
  const handleSubmit = () => {
    const data = {
      name: getFieldValue("Name"),
      code: getFieldValue("code"),
      model: getFieldValue("vehicle"),
    };
    setSearch(data);
    platformApi
      .post("/api/accessories/get", { page: page, size: limit, vehicle: data.model })
      .then(res => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(res.data.response.data.accessory);
            setCount(res.data.response.data.count);
          } else {
            message.error("Unable to fetch Accessories");
          }
        } else {
          message.error("Unable to fetch Accessories");
        }
      })
      .catch(error => {
        console.error("Error on Accessories : ", error);
        message.error("Unable to fetch Accessories");
        setSpinning(false);
      });
  };
  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <Row type="flex" justify="start">
                  {id.map((element) =>
                    element === "1" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("Name")(
                                <Input placeholder="Accesories Name & Code" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "2" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("code")(
                                <Input placeholder="Accessories code" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "3" ? (
                      <Col span={16}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("vehicle")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Vehicles Associated"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                  mode="multiple"
                                >
                                  {model.map((vehicle) => (
                                    <Select.Option
                                      value={vehicle.id}
                                      key={vehicle.id}
                                    >
                                      {vehicle.modelCode + '-' + vehicle.modelName}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : (
                            <Col span={14}>
                              <Item colon={false}>
                                <Row type="flex">
                                  <Col
                                    span={20}
                                    style={{ padding: "0px 20px" }}
                                    className="RangeSlider"
                                  >
                                    {getFieldDecorator("Pricerange")(
                                      <Slider
                                        onChange={branchSelect}
                                        range
                                        marks={marks}
                                        tipFormatter={formatter}
                                        defaultValue={[25, 50]}
                                      />
                                    )}
                                  </Col>
                                  <Col span={2}>
                                    <Button
                                      type="ghost"
                                      shape="circle"
                                      size="small"
                                      style={{ marginLeft: "0.5rem" }}
                                      onClick={() => deleteFilter(element)}
                                    >
                                      &#10005;
                              </Button>
                                  </Col>
                                </Row>
                              </Item>
                            </Col>
                          )
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex" justify="space-between">
                  <Col span={19}>
                    <Item colon={false}>
                      {getFieldDecorator("selectfilter")(
                        <Select
                        showAction={["click","focus"]}
                          placeholder="Select the Filter"
                          onChange={selectFilter}
                          mode="multiple"
                        >
                          {Filters.map((type) => (
                            <Option key={type.id}>{type.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={5} style={{ marginTop: "0.2rem" }}>
                    <Button type="primary" onClick={handleSubmit}>
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="end"
                  style={{ marginRight: "0.79rem" }}
                >
                  <Button
                    type="ghost"
                    style={{ width: "4.5rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
