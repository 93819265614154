import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";

const { Text } = Typography;
import moment from 'moment'
const InspectionTable = ({
    dataSource,
    openModal,
    spinner,
    pagination,
}) => {
    const column = [
        {
            title: <span>Job Card No</span>,
            dataIndex: "jobNo",
            key: "jobNo",
            render: (record) => <span>{record}</span>,
        },
        {
            title: <span>Owner</span>,
            dataIndex: "customer",
            key: "owner",
            render: (record) => (
                <span>
                    {record && record.name}
                </span>
            ),
        },
        {
            title: <span>Status</span>,
            dataIndex: "jobStatus",
            key: "jobStatus",
            render: (record) => <span>{record}</span>,
        },
        {
            title: <span>Mobile No</span>,
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (record) => (
                <span>
                    {moment(record).format("DD/MM/YYYY")}
                </span>
            ),
        }
    ];
    return (
        <div>
            <Table
                rowKey={(record) => record.id}
                pagination={pagination}
                columns={column}
                style={{ cursor: "pointer" }}
                dataSource={dataSource}
                onRow={(props) => ({
                    // onClick: () => {
                    //     openModal(props.id, false);
                    // },
                })}
                loading={spinner}
            />
        </div>
    )
}
export default InspectionTable