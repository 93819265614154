import React, { useState, useEffect } from "react";
import { Modal, Button, Typography, Steps, Icon, message } from "antd";
import { platformApi } from "../../../../api";

import WrappedCustomerData from "./CustomerDetails";
import WrappedVehicleData from "./VehicleData";
import WrappedPaymentData from "./PaymentData";

const { Step } = Steps;
const { Text } = Typography;

const VehicleSaleModal = props => {
  const { open, close } = props;

  const [current, setCurrent] = useState(0);
  const icons = [
    <Icon style={{ fontSize: "1rem" }} type="user" />,
    <Icon style={{ fontSize: "1rem" }} type="car" />,
    <Icon style={{ fontSize: "1rem" }} type="transaction" />
  ];

  const steps = [
    { id: 1, title: "Customer Data" },
    { id: 2, title: "Vehicle Data" },
    { id: 3, title: "Payment Data" }
  ];

  const forms = [
    <WrappedCustomerData />,
    <WrappedVehicleData />,
    <WrappedPaymentData />
  ];

  const clearFields = () => {
    setCurrent(0);
  };

  const handleSubmit = () => {};

  return (
    <Modal
      width="75%"
      title="Add Vehicle Sale Invoice"
      visible={open}
      okText={current < 2 ? "Next" : "Save"}
      onOk={() => {
        current < 2 ? setCurrent(current + 1) : handleSubmit();
      }}
      onCancel={() => {
        clearFields();
        close();
      }}
      // okButtonProps={{ }}
      // wrapClassName="vehicle-creation-modal"
      footer={[
        <Button
          onClick={() => {
            current > 0 ? setCurrent(current - 1) : close();
          }}
        >
          {current > 0 ? "Previous" : "Cancel"}
        </Button>,
        <Button
          onClick={() => {
            current < 2 ? setCurrent(current + 1) : handleSubmit();
          }}
          type="primary"
        >
          {current < 2 ? "Next" : "Save"}
        </Button>
      ]}
    >
      <Steps size="small" type="navigation" current={current}>
        {steps.map((step, index) => (
          <Step
            key={step.id}
            icon={icons[index]}
            title={<Text>{step.title}</Text>}
          />
        ))}
      </Steps>
      <div>{forms[current]}</div>
    </Modal>
  );
};

export default VehicleSaleModal;
