import React, { useState, useEffect, useContext } from "react";
import {
    Input,
    Form,
    Row,
    Col,
    DatePicker,
    Typography,
    Select,
    Table,
    InputNumber,
    message,
    Tooltip,
    Button,
    Popconfirm
} from "antd";

import moment from "moment";
import "./form.less";
import { platformApi } from "../../../../../api";
import { LeftOutlined } from '@ant-design/icons';
import WrappedVehiclePurchaseForm from "./VehicleDetails";
import { useBranch } from "../../../../../ContextAPI/branch";
import { ContextAPI } from "../../../../../ContextAPI";
import { useHistory } from "react-router-dom";
import { round } from "lodash";
const { Item } = Form;

const { Text, Title } = Typography;

const VPIModal = (props) => {
    const history = useHistory()
    const { form, open, close, editData, editable: isEditable, modify } = props;
    const { loginCredintials } = useContext(ContextAPI)
    const { selectBranch } = useBranch();
    const [branch, setBranch] = useState({id: "", name: ""});
    const [loading, setLoading] = useState(false);
    const [editable, setEditable] = useState(isEditable);
    const [openVehicle, setOpenVehicle] = useState(false);
    const [invoiceDate, setInvoiceDate] = useState();
    const [currentDate, setCurrentDate] = useState(new Date());
    const [purchaseInvoiceNo, setPurchaseInvoice] = useState(props.id);
    const [challan, setChallan] = useState({});
    const [challanData, setChallanData] = useState({date: moment(new Date()), challanDate: moment(Date.now()), id : null})
    const [vehicle, setVehicle] = useState([]);
    const [vehicleDetail, setVehicleDetail] = useState([]);
    const [deletedVehicle, setDeletedVehicle] = useState([]);
    const [quantity, setQuantity] = useState();
    const [amount, setAmt] = useState();
    const [displayIGST, setDisplayIGST] = useState(false);
    const [editChallanNo, setEditChallan] = useState();
    const [dataSource, setDataSource] = useState([]);
    const [tableData, setTable] = useState([]);
    const [invoiceAmount, setInvoiceAmount] = useState([]);
    const [selected, setSelected] = useState(-1);
    const [suppliers, setSuppliers] = useState([]);
    const [invoiceId, setInvoiceId] = useState();
    const { getFieldDecorator, setFieldsValue, getFieldValue } = form;
    
    useEffect(() => {
      platformApi.get("/api/vehicleMaster").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let results = [];
          data.response.data.map((obj) => {
            if (obj.vehicleStatus === "AVAILABLE") results.push(obj);
          });
          // setVehicle(data.response.data);
          setVehicle(results);
        }
      });
    }, []);

    useEffect(() => {
      if (editData?.id && editData.purchaseChallan) {
				const purchaseChallan = editData.purchaseChallan;
				setInvoiceId(editData.id);
				setQuantity(editData.quantity);
				setAmt(editData.amount);
        setVehicleDetail((prevData) => [...prevData, ...purchaseChallan.vehicleDetail]);
				form.setFieldsValue({
					creationDate: moment(editData.creationDate),
					supplier: purchaseChallan.supplier.id,
					invoiceDate: moment(editData.invoiceDate),
					invoiceNo: editData.invoiceNo,
					others: editData.others,
					roundOff: editData.roundOff,
					netAmount: editData.netAmount,
					grossTotal: editData.grossTotal,
					supplierInvoiceNo: purchaseChallan.supplierChallanNo,
				});
				setChallanData({
					id: purchaseChallan.id,
					challanNo: purchaseChallan.challanNo,
					supplierChallanNo: purchaseChallan.supplierChallanNo,
					supplier: purchaseChallan.supplier.id,
					location: purchaseChallan.branch.id,
					date: purchaseChallan.date === null ? moment(Date.now()) : moment(purchaseChallan.date),
					challanDate: moment(purchaseChallan.challanDate),
				});
				setPurchaseInvoice(editData.invoiceNo);
				setEditChallan(purchaseChallan.id);
				setInvoiceDate(editData.invoiceDate);
				
			} else {
				setFieldsValue({ creationDate: moment(new Date()), invoiceDate: moment(new Date()) });
				platformApi.get("api/supplier").then((result) => {
					let { data } = result;
					if (data.code === 200) {
						let { response } = data;
						if (response.code === 200) {
							let supplier = response.data.filter((obj) => obj.supplierType.includes("Vehicles") && obj.status === "ACTIVE");
							setSuppliers(supplier);
							if (!data) setFieldsValue({ supplier: supplier[0].id });
						}
					}
				});
			}
      if (editData?.branch) {
        setBranch(editData.branch[0]);
      } else {
        setBranch(loginCredintials.branch[0]);
      }
    }, [editData]);

    useEffect(() => {
			if (vehicle[0] && vehicleDetail[0]) {
				let results = [];
				let index = {};

				vehicleDetail.forEach((point) => {
					let key = "" + point.vehicle.modelCode + "||" + point.vehicle.modelName;
					if (key in index) {
						index[key].quantity++;
					} else {
						let newEntry = {
							modelCode: point.vehicle.modelCode,
							modelName: point.vehicle.modelName,
							modelColor: point.color?.color,
							hsnCode: point.vehicle?.hsn?.code,
							chassisNo: point.chassisNo,
							engineNo: point.engineNo,
							quantity: 1,
							amount: 0,
							price: point.invoiceAmount,
							priceZ: {
								price: point.invoiceAmount,
								code: point.vehicle.modelCode,
								challanId: editData?.purchaseChallan?.id || "",
								vehicleId: point.id,
							},
						};
						index[key] = newEntry;
						results.push(newEntry);
					}
				});

				let gross = 0;
				let igst = 0;
				let cess = 0;
				let quant = 0;
				results.map((obj) => {
					obj.price /= obj.quantity;
					obj.amount = obj.price * obj.quantity;
					gross += obj.amount;
					igst += vehicle[vehicle.findIndex((o) => o.modelCode === obj.modelCode)].hsn.igst * obj.quantity;
					cess += vehicle[vehicle.findIndex((o) => o.modelCode === obj.modelCode)].hsn.cess;
					form.setFieldsValue({ grossTotal: gross });
					quant += obj.quantity;
				});

				let grost = form.getFieldValue("grossTotal");
				igst /= results.length;
				cess /= results.length;
				let nett = grost + grost * (igst / 2 / 100) * 2 + cess;

				form.setFieldsValue({
					igst: roundOf(igst + " % " + " - " + (igst / 100) * grost),
					cgst: roundOf(igst / 2 + " % " + " - " + (igst / 2 / 100) * grost),
					sgst: roundOf(igst / 2 + " % " + " - " + (igst / 2 / 100) * grost),
					cess: roundOf(cess),
					netAmount: nett,
					roundOff: Math.round(nett),
				});

				setDataSource(results);
				setQuantity(quant);
				setAmt(nett);
			}
		}, [vehicle, vehicleDetail]);

    // useEffect(() => {
		// 	if (vehicle[0] && vehicleDetail[0]) {
		// 		handleChallanChange(vehicleDetail);
		// 	}
		// }, [vehicle, vehicleDetail]);

    useEffect(() => {
      setDataSource([]);
      setInvoiceAmount([]);
    }, [close]);

    useEffect(() => {
      if (open) {
        form.setFieldsValue({ creationDate: moment(new Date()), invoiceDate: moment(new Date()) });
        setTable(props.tableData);
        platformApi.get("/api/user/currentUser").then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setBranch(response.data.profile.branch[0]);
            }
          }
        });

        platformApi.get("/api/purchaseChallan/").then((res) => {
          let result = [];
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            response.data.map((resp) => {
              if (props.tableData.findIndex((obj) => obj.purchaseChallan.id === resp.id) === -1) {
                let obj = {
                  id: resp.id,
                  name: resp.challanNo,
                  SupplierinvoiceNo: resp.supplierChallanNo,
                  supplier: resp.supplier,
                  location: resp.branch,
                  vehicle: resp.vehicleDetail,
                };
                result.push(obj);
              }
            });
            setChallan(result);
          } else {
            message.error("Couldn't fetch id");
          }
        });

        platformApi.get("/api/vehicleMaster").then((result) => {
          let { data } = result;
          if (data.code === 200) {
            setVehicle(data.response.data);
          }
        });

        form.setFieldsValue({ invoiceNo: props.id });
      }
    }, [open]);

    const handlePriceChange = (value, id, type, cid, vid) => {
      let grossTotal = 0;
      const data = [...dataSource];
        for (const index in data) {
          if (data[index].priceZ.vehicleId === vid) {
						data[index]["amount"] = roundOf(data[index]["quantity"] * roundOf(value));
					}
          grossTotal += data[index]["amount"];
        }

        const cgst = roundOf(form.getFieldValue("cgst"));
        const cess = roundOf(form.getFieldValue("cess"));
        const netAmount = grossTotal + (cgst / 100) * 2 * grossTotal + cess;
        const roundOff = Math.round(netAmount);

        form.setFieldsValue({
					grossTotal: grossTotal,
					netAmount: netAmount,
					roundOff
				});

        setDataSource(data);
        setAmt(netAmount);
    };

    const deleteVehicleData = (modelCode) => {
      const updatedDataSource = []
      const deletedVehicleDetail = []
      const updatedVehicleDetail = [];
      for (const item of dataSource) {
        if(item.modelCode !== modelCode) {
          updatedDataSource.push(item);
        }
      }
      for (const item of vehicleDetail) {
        if(item.vehicle.modelCode === modelCode) {
          deletedVehicleDetail.push(item);
        }else {
          updatedVehicleDetail.push(item);
        }
      }
      setDataSource(updatedDataSource);
      setDeletedVehicle(deletedVehicleDetail);
      setVehicleDetail(updatedVehicleDetail)
    }

    const column = [
			// {
			//     title: "Model Code",
			//     dataIndex: "modelCode",
			//     key: "modelCode",
			//     render: (record) => <span>{record}</span>,
			// },
			{
				title: "Vehicle Model",
				dataIndex: "modelName",
				key: "modelName",
				render: (record) => <span>{record}</span>,
			},
			{
				title: "Vehicle Color",
				dataIndex: "modelColor",
				key: "modelColor",
				render: (record) => <span>{record}</span>,
			},
			// {
			//     title: "Quantity",
			//     dataIndex: "quantity",
			//     key: "quantity",
			//     render: (record) => <span>{record}</span>,
			// },
			{
				title: "Chassis Number",
				dataIndex: "chassisNo",
				key: "chassisNo",
				render: (record) => <span>{record}</span>,
			},
			{
				title: "Engine Number",
				dataIndex: "engineNo",
				key: "engineNo",
				render: (record) => <span>{record}</span>,
			},
			{
				title: "HSN Code",
				dataIndex: "hsnCode",
				key: "hsnCode",
				render: (record) => <span>{record}</span>,
			},
			{
				title: "Price",
				dataIndex: "priceZ",
				key: "price",
				render: (record) => (
					<Input
						min={0}
						type="number"
						addonBefore="&#8377;"
						key="price"
						disabled={!editable}
						onChange={(event) => {
							handlePriceChange(parseInt(event.target.value) || 0, record.code, "price", record.challanId, record.vehicleId);
						}}
						placeholder={record.price}
					/>
				),
			},
			{
				title: "Action",
				dataIndex: "modelCode",
				key: "modelCode",
				render: (record) => (
					<div>
						{/* {
							<span
								onClick={(event) => {
									event.stopPropagation();
									// setModifyAccess(true)
									history.push({
										pathname: `/autoadmin/job_invoice/${data.id}`,
										state: {
											gst: gst,
											editable: true,
										},
									});
								}}
								className="linkylink"
							>
								Modify
							</span>
						} */}
						{
							<Popconfirm
								title="Do you want to delete SSI?"
								okText="Yes"
								cancelText="No"
								onCancel={(event) => {
									event.stopPropagation();
								}}
                disabled={!editable}
								onConfirm={(event) => {
									event.stopPropagation();
								  deleteVehicleData(record);
								}}
							>
								<span className="linkylink" onClick={(event) => event.stopPropagation()}>
									Delete
								</span>
							</Popconfirm>
						}
					</div>
				),
			},
		];

    const roundOf = (number) => {
      if (number == null) return number;
      const roundedNumber = parseFloat(number).toFixed(2);
      return parseFloat(roundedNumber);
    }; 

    const handleModify = () => {
      setEditable(true);
    }

    const handleSubmit = () => {
        if(vehicleDetail.length < 1) return message.error("Select at least one vehicle") ;
        form.validateFields((validationError) => {
            if (validationError === null) {
                setLoading(true);
                const invoiceNo = purchaseInvoiceNo || props.id;
                const invoiceDate = form.getFieldValue("invoiceDate");
                const date = form.getFieldValue("creationDate")
                const purchaseChallan = editChallanNo || form.getFieldValue("challanNo");
                const grossTotal = form.getFieldValue("grossTotal");
                const others = parseInt(form.getFieldValue("others"));
                const netAmount = form.getFieldValue("netAmount");
                const roundOff = form.getFieldValue("roundOff");
                const supplier = form.getFieldValue("supplier");
                const supplierInvoiceNo = form.getFieldValue("supplierInvoiceNo");
                let results = [];
                for (const index in dataSource) {
                    vehicleDetail[index].invoiceAmount = dataSource[index].amount;
                    let newObj = {
											id: dataSource[index].priceZ.vehicleId,
											amount: dataSource[index].amount,
										};
                    results.push(newObj);
                }
                setInvoiceAmount(results);

                let invoiceAmount = results;
                const vpi = {
                  id: invoiceId,
									invoiceNo,
									invoiceDate,
									date,
									purchaseChallan,
									grossTotal,
									others,
									quantity,
									amount,
									netAmount,
									roundOff,
									invoiceAmount,
									branch: branch.id,
									vehicleDetail,
                  deletedVehicle,
									challan,
									challanData,
									supplier,
									supplierInvoiceNo,
								};
                props.emitData(vpi);
                setLoading(false);
                dataSource.length = 0;
                props.close();
                // clearFields();
            }
        });
    };

    const handleCancel = () => {
      form.resetFields();
      history.push("/autoadmin/vehicle_purchase_invoice")
    }

    const emitVehicleData = (data) => {
      // setChallan(data)
      handleChallanChange(data);
    };

    const clearFields = () => {
        form.resetFields();
    };

    const onChange = (date, dateString) => {
        setInvoiceDate(dateString);
    };

    const handleChallanChange = (selectedVehicles) => {
        const updatedVehicles = selectedVehicles;
        for (const index in updatedVehicles) {
          updatedVehicles[index].invoiceAmount =
						vehicle[vehicle.findIndex((obj) => obj.modelCode === updatedVehicles[index].vehicle.modelCode)]?.price[0]
							?.showroomPrice || null;
				}
        setVehicleDetail((prevData) => [...prevData, ...updatedVehicles]);
    };

    const othersChange = (valuez) => {
        if (valuez === "-") return;
        let value = valuez;
        let nett = amount;
        const netAmount = roundOf(amount) + round(valuez);
        form.setFieldsValue({
					netAmount: roundOf(netAmount),
					roundOff: Math.round(netAmount),
				});
    };

    return (
			<Form>
				<Row type="flex" justify="space-between">
					<Row type="flex" justify="space-between">
						<Col span={10}>
							<Item label={<Text> Internal VPI Number.</Text>} colon={false} required={false}>
								{getFieldDecorator("invoiceNo", {
									rules: [
										{
											// required: true,
											message: "Enter Internal Vehicle Purchase Invoice",
										},
									],
								})(
									<Input
										disabled={true}
										placeholder={props.id}
										// value={props.id}
										style={{ width: "27.5rem" }}
									/>
								)}
							</Item>
						</Col>
						<Col span={11}></Col>
					</Row>
					<Col span={10}>
						<Item label="Invoice Date" colon={false}>
							{getFieldDecorator("invoiceDate", {
								rules: [{ required: true, message: "Enter Invoice Date" }],
							})(
								<DatePicker
									disabled={!editable}
									allowClear={true}
									disabledDate={(moment) => moment._d > Date.now()}
									format="DD-MMM-YYYY"
									style={{ width: "100%" }}
								/>
							)}
						</Item>
					</Col>
				</Row>
				<Row type="flex" justify="space-between">
					<Col span={10}>
						<Item
							label={<Text>Current Date</Text>}
							colon={false}
							// required={false}
						>
							{getFieldDecorator("creationDate", {
								rules: [
									{
										required: true,
										message: "Enter Creation Date",
									},
								],
							})(
								<DatePicker
									disabled={true}
									style={{ width: "100%" }}
									// disabledDate={(moment) => moment._d > Date.now()}
									placeholder="Select Date"
									format="DD-MMM-YYYY"
									allowClear={true}
								/>
							)}
						</Item>
					</Col>
					<Col span={10}>
						{selectBranch.length < 2 ? (
							<Form.Item className="purchase-form-element" label="Location" name="branch" required disabled={true}>
								<Input placeholder="Branch Name" className="form-input" disabled={true} value={branch.name} />
							</Form.Item>
						) : (
							<Form.Item className="purchase-form-element" label="Location" name="branch" required>
								<Select
									dropdownStyle={{
										overflowY: "auto",
										overflowX: "hidden",
									}}
									// value={editable ? editData && editData.customer.name : partyName}
									value={branch.id}
									onChange={(eachBranch) => {
										setBranch(eachBranch);
									}}
									showAction={["click", "focus"]}
									showSearch
									disabled={!editable}
									placeholder="Branch"
									filterOption={null}
								>
									{loginCredintials.branch.map((eachBranch) => {
										return <Select.Option key={eachBranch}>{eachBranch.name}</Select.Option>;
									})}
								</Select>
							</Form.Item>
						)}
					</Col>
				</Row>
				<Row type="flex" justify="space-between">
					<Col span={10}>
						<Item colon={false} label="Supplier">
							{getFieldDecorator("supplier", {
								rules: [{ required: true, message: "Select Supplier " }],
							})(
								<Select disabled={!editable} placeholder="Select the supplier">
									{suppliers.map((supplier) => (
										<Select.Option key={supplier.id}>{supplier.name}</Select.Option>
									))}
								</Select>
							)}
						</Item>
					</Col>
					<Col span={10}>
						<Item label={<Text>Supplier Invoice Number.</Text>} colon={false} required={false}>
							{getFieldDecorator("supplierInvoiceNo", {
								rules: [
									{
										// required: true,
										message: "Enter Supplier Invoice Number.",
									},
								],
							})(
								<Input
									disabled={!editable}
									placeholder="Supplier Invoice Number"
									// value={props.id}
								/>
							)}
						</Item>
						{/*  */}
					</Col>
				</Row>
				<div>
					<strong>Add vehicle details</strong>
					<Button
						style={{ margin: "0 2rem" }}
						type="primary"
						onClick={() => {
							setOpenVehicle(true);
							setEditable(editable);
						}}
						disabled={!editable}
					>
						Add
					</Button>
				</div>
				<br />
				<Table
					rowKey={(record) => record.id}
					pagination={{ pageSize: 3 }}
					columns={column}
					style={{ cursor: "pointer" }}
					tableLayout="fixed"
					dataSource={dataSource}
				/>
				<Row type="flex" justify="space-between">
					<Col span={4}>
						<Item label={<Text>Gross Total</Text>} colon={false} required={false}>
							{getFieldDecorator(
								"grossTotal"
								// , {
								//   rules: [
								//     {
								//       // required: true,
								//       message: "Enter Gross Total",
								//     },
								//   ],
								// }
							)(<Input addonBefore="&#8377;" disabled={true} placeholder="Gross Total" />)}
						</Item>
					</Col>
					<Col span={4}>
						<Item label={<Text>Others</Text>} colon={false} required={false}>
							{getFieldDecorator(
								"others"
								// , {
								//   rules: [
								//     {
								//       // required: true,
								//       message: "Enter Others",
								//     },
								//   ],
								// }
							)(<InputNumber disabled={!editable} placeholder="Others" onChange={othersChange} />)}
						</Item>
					</Col>
					<Col span={4}>
						{displayIGST && (
							<Item label={<Text>IGST</Text>} colon={false} required={false}>
								{getFieldDecorator(
									"igst"
									// , {
									//   rules: [
									//     {
									//       // required: true,
									//       message: "Enter IGST",
									//     },
									//   ],
									// }
								)(
									<Input
										// addonAfter="%"
										disabled={true}
										placeholder="IGST"
									/>
								)}
							</Item>
						)}
					</Col>
					<Col span={4}>
						<Item label={<Text>CGST</Text>} colon={false} required={false}>
							{getFieldDecorator(
								"cgst"
								// , {
								//   rules: [
								//     {
								//       // required: true,
								//       message: "Enter CGST",
								//     },
								//   ],
								// }
							)(
								<Input
									// addonAfter="%"
									disabled={true}
									placeholder="CGST"
								/>
							)}
						</Item>
					</Col>
				</Row>
				<Row type="flex" justify="space-between">
					<Col span={4}>
						<Item label={<Text>SGST</Text>} colon={false} required={false}>
							{getFieldDecorator(
								"sgst"
								// , {
								//   rules: [
								//     {
								//       // required: true,
								//       message: "Enter SGST",
								//     },
								//   ],
								// }
							)(
								<Input
									// addonAfter="%"
									disabled={true}
									placeholder="SGST"
								/>
							)}
						</Item>
					</Col>
					<Col span={4}>
						<Item label={<Text>Cess</Text>} colon={false} required={false}>
							{getFieldDecorator(
								"cess"
								// , {
								//   rules: [
								//     {
								//       // required: true,
								//       message: "Enter Cess",
								//     },
								//   ],
								// }
							)(<Input disabled={true} placeholder="Cess" />)}
						</Item>
					</Col>
					<Col span={4}>
						<Item label={<Text>Round Off</Text>} colon={false} required={false}>
							{getFieldDecorator(
								"roundOff"
								// , {
								//   rules: [
								//     {
								//       // required: true,
								//       message: "Enter Round Off",
								//     },
								//   ],
								// }
							)(
								<Input
									addonBefore="&#8377;"
									// disabled={true}
									placeholder="Round Off"
								/>
							)}
						</Item>
					</Col>
					<Col span={4}>
						<Item label={<Text>Net Amount</Text>} colon={false} required={false}>
							{getFieldDecorator(
								"netAmount"
								// , {
								//   rules: [
								//     {
								//       // required: true,
								//       message: "Enter Net Amount",
								//     },
								//   ],
								// }
							)(<Input addonBefore="&#8377;" disabled={true} placeholder="Net Amount" />)}
						</Item>
					</Col>
				</Row>
				<div style={{ float: "right" }}>
					<Button style={{ marginRight: "1rem" }} size="large" onClick={() => handleCancel()}>
						Cancel
					</Button>
					<Button type="primary" size="large" onClick={editable ? () => handleSubmit() : () => handleModify()}>
						{editable ? "Save" : "Modify"}
					</Button>
				</div>
				<WrappedVehiclePurchaseForm
					open={openVehicle}
					close={() => {
						setOpenVehicle(false);
						setSelected(null);
					}}
					emitData={emitVehicleData}
				/>
			</Form>
		);
};

const VehiclePurchaseInvoiceForm = Form.create({ name: "normal_login" })(VPIModal);

export default VehiclePurchaseInvoiceForm;
