import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Modal,
  Input,
  Icon,
  Checkbox,
  Collapse,
  Upload,
  Divider,
  Empty,
  message
} from "antd";
const { Panel } = Collapse;
const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
const CheckboxGroup = Checkbox.Group;
import { platformApi } from '../../../../../../api';
import '../index.less'
import { ENDPOINT } from "../../../../../../constants";

const UploadDocuments = ({
  form,
  editable,
  id,
  data,
  fuelLevel,
  setFuelLevel,
  PcheckedList, 
  setPCheckedList,
  setPIndeterminate,
  setPCheckAll,
  fileList,
  setFileList,
  RHSfileList,
  setRHSFileList,
  RearfileList,
  setRearFileList,
  LHSfileList,
  setLHSFileList,
  TopfileList,
  setTopFileList,
  FrontForm,
  setFrontForm,
  RHSForm,
  setRHSForm,
  LHSForm,
  setLHSForm,
  RearForm,
  setRearForm,
  TopForm,
  setTopForm,
  frontImg,
  setFront,
  RHSImg,
  setRhs,
  LHSImg,
  setLhs,
  RearImg,
  setRear,
  TopImg,
  setTop,
  setLoading
}) => {
  const { getFieldDecorator, getFieldValue } = form;

  //IMAGES
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [frontVisible, setFrontVisible] = useState(false)

  //CHECKBOX STATES

  // const documents = ["RC", "Insurance", "Pollution", "Service Book"];
  const parts = ["MirrorRH", "MirrorLH", "Toolkit", "FirstAdKit"];

  const uploadButton = (
    <div>
      <Icon type="plus" />
      <div className="ant-upload-text">Upload</div>
    </div>
  );
  const handleChange = ({ fileList }, type, e) => {
    if (type === "Front") {
      setLoading(true);
      setFileList(fileList);
      const formData = new FormData();
      formData.append("profile", e.file.originFileObj);
      formData.append("master", "Transaction Master");
      formData.append("module", "Job Order");
      formData.append("id", id);
      formData.append("type", "front");
      platformApi
        .post("/api/upload/image", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.front = data.data.Location;
            setLoading(false);
            setFrontForm(data.response.data.Location);
            // message.success("Uploaded", 0)
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error on Observation Form : ", error);
          message.error("Unable to upload image");
        });
    }
    if (type === "RHS") {
      setRHSFileList(fileList);
      const RHSformData = new FormData();
      RHSformData.append("profile", e.file.originFileObj);
      RHSformData.append("master", "Transaction Master");
      RHSformData.append("module", "Job Order");
      RHSformData.append("id", id);
      RHSformData.append("type", "rhs");
      setLoading(true);
      platformApi
        .post("/api/upload/image", RHSformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            setLoading(false);
            // data.RHS = data.data.Location;
            setRHSForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error on Observation Form : ", error);
          message.error("Unable to upload image");
        });
    }
    if (type === "Rear") {
      setRearFileList(fileList);
      const RearformData = new FormData();
      RearformData.append("profile", e.file.originFileObj);
      RearformData.append("master", "Transaction Master");
      RearformData.append("module", "Job Order");
      RearformData.append("id", id);
      RearformData.append("type", "rear");
      setLoading(true);
      platformApi
        .post("/api/upload/image", RearformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            setLoading(false);
            // data.rear = data.data.Location;
            setRearForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error on Observation Form : ", error);
          message.error("Unable to upload image");
        });
    }
    if (type === "LHS") {
      setLHSFileList(fileList);
      const LHSformData = new FormData();
      LHSformData.append("profile", e.file.originFileObj);
      LHSformData.append("master", "Transaction Master");
      LHSformData.append("module", "Job Order");
      LHSformData.append("id", id);
      LHSformData.append("type", "lhs");
      setLoading(true);
      platformApi
        .post("/api/upload/image", LHSformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.LHS = data.data.Location;
            setLoading(false);
            setLHSForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error on Observation Form : ", error);
          message.error("Unable to upload image");
        });
    }
    if (type === "Top") {
      setTopFileList(fileList);
      const TopformData = new FormData();
      TopformData.append("profile", e.file.originFileObj);
      TopformData.append("master", "Transaction Master");
      TopformData.append("module", "Job Order");
      TopformData.append("id", id);
      TopformData.append("type", "top");
      setLoading(true);
      platformApi
        .post("/api/upload/image", TopformData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            // data.top = data.data.Location;
            setLoading(false);
            setTopForm(data.response.data.Location);
          } else {
            message.error("Upload Not Done");
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error on Observation Form : ", error);
          message.error("Unable to upload image");
        });
    }
  };
  const handlePreview = async (file) => {
    // console.log(file);

    // if (file.substring(0, 19) === "https://nandiyamaha") {
    //   setPreviewImage(file), setPreviewVisible(true);
    // } else {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview), setPreviewVisible(true);
    // }
  };
  const handleCancel = () => setPreviewVisible(false);
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  // const checkChange = (checked) => {
  //     setCheckedList(checked);
  //     setIndeterminate(!!checked.length && checked.length < checkedList.length);
  //     setCheckAll(checked.length === checkedList.length);
  // };
  const PcheckChange = (checked) => {
    setPCheckedList(checked);
    setPIndeterminate(!!checked.length && checked.length < PcheckedList.length);
    setPCheckAll(checked.length === PcheckedList.length);
  };
  useEffect(() => {
    if (fuelLevel) {
      form.setFieldsValue({
        fuel: fuelLevel ? fuelLevel : ""
      })
    }
  }, [fuelLevel])
  return (
    <div className="UploaDocuments">
      <Collapse
        // defaultActiveKey={['1']}
        expandIconPosition="right"
      >
        <Panel header="Optional" key="1" >
          <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ width: '100%' }}>
              <h3>Upload Images:</h3>
              <Form style={{ marginTop: "4%", marginBottom: "4%", padding: '0px 20px 0px 40px' }}>
                <Row type="flex" justify="space-around">
                  <Col span={12}>
                    {
                      editable && !frontImg ?
                        (<div>
                          Front View
                          <Upload
                            // disabled={loading}
                            action={`${ENDPOINT}api/upload/mocky`}
                            listType="picture-card"
                            fileList={fileList}
                            onPreview={handlePreview}
                            onChange={(e) => {
                              handleChange(e, "Front", e);
                            }}
                            accept=".jpg,.png,.jpeg,.gif"
                            disabled={!editable}
                          >
                            {fileList.length >= 1 ? null : uploadButton}
                          </Upload>
                          <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{ width: "100%" }}
                              src={previewImage}
                            />
                          </Modal>
                        </div>)
                        :
                        (
                          <div className="clearFix">
                            Front View
                            <br />
                            <img
                              src={frontImg}
                              alt="Front View"
                              style={{ width: "80%" }}
                              onClick={(e) => setFrontVisible(true)}
                            />
                            <Modal
                              visible={frontVisible}
                              footer={null}
                              onCancel={(e) => setFrontVisible(false)}
                            >
                              <img alt="example" style={{ width: "80%" }} src={frontImg} />
                            </Modal>
                          </div>
                        )
                    }
                  </Col>
                  <Col span={12}>
                    {
                      editable && !RHSImg ? (
                        <div>
                          RHS View
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            action={`${ENDPOINT}api/upload/mocky`}
                            listType="picture-card"
                            fileList={RHSfileList}
                            accept=".jpg,.png,.jpeg,.gif"
                            onPreview={handlePreview}
                            onChange={(e) => {
                              handleChange(e, "RHS", e);
                            }}
                            disabled={!editable}
                          >
                            {RHSfileList.length >= 1 ? null : uploadButton}
                          </Upload>
                          <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{ width: "100%" }}
                              src={previewImage}
                            />
                          </Modal>
                        </div>

                      ) : (
                          <div className="clearFix">
                            RHS View
                            <br />
                            <img
                              src={RHSImg}
                              alt="RHS View"
                              style={{ width: "80%" }}
                              onClick={(e) => setFrontVisible(true)}
                            />
                            <Modal
                              visible={frontVisible}
                              footer={null}
                              onCancel={(e) => setFrontVisible(false)}
                            >
                              <img alt="example" style={{ width: "80%" }} src={RHSImg} />
                            </Modal>
                          </div>
                        )
                    }
                  </Col>
                  <Col span={12}>
                    {
                      editable && !RearImg ? (
                        <div>
                          Rear View
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            action={`${ENDPOINT}api/upload/mocky`}
                            listType="picture-card"
                            fileList={RearfileList}
                            accept=".jpg,.png,.jpeg,.gif"
                            onPreview={handlePreview}
                            onChange={(e) => {
                              handleChange(e, "Rear", e);
                            }}
                            disabled={!editable}
                          >
                            {RearfileList.length >= 1 ? null : uploadButton}
                          </Upload>
                          <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{ width: "100%" }}
                              src={previewImage}
                            />
                          </Modal>
                        </div>
                      ) : (
                          <div className="clearFix">
                            Rear View
                            <br />
                            <img
                              src={RearImg}
                              alt="Rear View"
                              style={{ width: "80%" }}
                              onClick={(e) => setFrontVisible(true)}
                            />
                            <Modal
                              visible={frontVisible}
                              footer={null}
                              onCancel={(e) => setFrontVisible(false)}
                            >
                              <img alt="example" style={{ width: "80%" }} src={RearImg} />
                            </Modal>
                          </div>
                        )
                    }
                  </Col>
                  <Col span={12}>
                    {
                      editable && !LHSImg ? (
                        <div>
                          LHS View
                          <Upload
                            // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                            action={`${ENDPOINT}api/upload/mocky`}
                            listType="picture-card"
                            fileList={LHSfileList}
                            accept=".jpg,.png,.jpeg,.gif"
                            onPreview={handlePreview}
                            onChange={(e) => {
                              handleChange(e, "LHS", e);
                            }}
                            disabled={!editable}
                          >
                            {LHSfileList.length >= 1 ? null : uploadButton}
                          </Upload>
                          <Modal
                            visible={previewVisible}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img
                              alt="example"
                              style={{ width: "100%" }}
                              src={previewImage}
                            />
                          </Modal>
                        </div>

                      ) : (
                          <div className="clearFix">
                            LHS View
                            <br />
                            <img
                              src={LHSImg}
                              alt="LHS View"
                              style={{ width: "80%" }}
                              onClick={(e) => setFrontVisible(true)}
                            />
                            <Modal
                              visible={frontVisible}
                              footer={null}
                              onCancel={(e) => setFrontVisible(false)}
                            >
                              <img alt="example" style={{ width: "80%" }} src={LHSImg} />
                            </Modal>
                          </div>
                        )
                    }
                  </Col>
                  <Col span={12}>
                    {
                      editable && !TopImg ?
                        (
                          <div>
                            Top View
                            <Upload
                              // action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              action={`${ENDPOINT}api/upload/mocky`}
                              listType="picture-card"
                              fileList={TopfileList}
                              onPreview={handlePreview}
                              accept=".jpg,.png,.jpeg,.gif"
                              onChange={(e) => {
                                handleChange(e, "Top", e);
                              }}
                              disabled={!editable}
                            >
                              {TopfileList.length >= 1 ? null : uploadButton}
                            </Upload>
                            <Modal
                              visible={previewVisible}
                              footer={null}
                              onCancel={handleCancel}
                            >
                              <img
                                alt="example"
                                style={{ width: "100%" }}
                                src={previewImage}
                              />
                            </Modal>
                          </div>)
                        :
                        (<div className="clearFix">
                          Top View
                          <br />
                          <img
                            src={TopImg}
                            alt="Top View"
                            style={{ width: "80%" }}
                            onClick={(e) => setFrontVisible(true)}
                          />
                          <Modal
                            visible={frontVisible}
                            footer={null}
                            onCancel={(e) => setFrontVisible(false)}
                          >
                            <img alt="example" style={{ width: "80%" }} src={TopImg} />
                          </Modal>
                        </div>
                        )
                    }

                  </Col>
                </Row>
              </Form>
            </div>
            <Divider type="vertical" />
            <div style={{ width: '100%' }}>
              <h3>CheckList :</h3>
              <div style={{ padding: '20px' }}>
                <CheckboxGroup
                  options={parts}
                  value={PcheckedList}
                  onChange={PcheckChange}
                  disabled={!editable}
                />
                <br />
                <Row type="flex" justify="space-between">
                  <Col span={12}>
                    <Form.Item colon={false} label={<Text>Fuel Level</Text>}>
                      {getFieldDecorator(
                        "fuel",
                        {
                          rules: [
                            {
                              required: false,
                              message: "Please Enter Fuel Level",
                            },
                          ],
                        }
                      )(<Input
                        type="number"
                        max="100"
                        onKeyUp={e => {
                          if (parseInt(e.target.value) > 100) {
                            form.setFieldsValue({
                              fuel: "100"
                            })
                          }
                          else {
                            form.setFieldsValue({
                              fuel: e.target.value
                            })
                          }
                        }}
                        disabled={!editable}
                        suffix="%"
                        placeholder="Fuel Level"
                        onChange={(e) => {
                          if (e.target.value) {
                            if (parseInt(e.target.value) > 100) {
                              setFuelLevel(e.target.value)
                            }
                            else {
                              setFuelLevel(e.target.value)
                            }
                          }
                          // setFuelLevel(form.getFieldValue("fuel"))
                        }}
                      />)}
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        </Panel>
      </Collapse >
    </div>
  )
}
const WrappedUploadDocuments = Form.create()(UploadDocuments);

export default WrappedUploadDocuments;