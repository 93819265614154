import React, { useEffect, useState } from 'react'
import {
    Typography,
    Tabs,
    Input,
    Button,
    Select,
    Form,
    Col,
    Row,
    DatePicker,
    message,
    Table,
    Radio,
    Popconfirm
} from 'antd';
import { number } from 'prop-types';
import './index.less'
import { platformApi } from '../../../../../../../api';
const { TabPane } = Tabs;
const { Title, Text } = Typography;
const { Option } = Select;
const { Group } = Radio;
const Estimates = ({
    form,
    total,
    Estimate,
    data1,
    totalComplaints,
    setTotalComplaints,
    prevComplaint,
    setPreviousComplaint,
    pdf,
    status
}) => {
    const partItemData = {
        "partNumber": {},
        "quantity": '',
        "unitRate": '',
        "rate": '',
        "remove": '',
        "code": ''
    }
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

    const [totalEstimate, setTotalEstimate] = useState(0)
    const [dataSource, setDataSource] = useState([])
    const [estId, setEstId] = useState('');
    // var dataSource = [prevComplaint, totalComplaints]
    // useEffect(() => {
    //     let data = dataSource[0]
    //     let tot = 0
    //     for (let item of data) {
    //         if (item.amount) {
    //             tot += parseInt(item.amount)
    //         }
    //     }
    //     setTotalEstimate(tot)
    // }, [])

    useEffect(() => {
        if (Estimate) {
            console.log("Data", Estimate)
            setEstId(Estimate.id)

            const updateItemList = [...Estimate.estimateItemInvoice]
            for (let i = 0; i < updateItemList.length; i++) {
                if (updateItemList[i].jobCode) {
                    updateItemList[i].partNumber = { ...updateItemList[i] }
                    updateItemList[i].rate = (updateItemList[i].quantity * updateItemList[i].unitRate)
                }
                else {
                    updateItemList[i].partNumber = { ...updateItemList[i].partNumber }
                    updateItemList[i].rate = (updateItemList[i].quantity * updateItemList[i].unitRate)
                    updateItemList[i].gstRate = updateItemList[i].igst
                }
            }
            console.log(updateItemList)
            setFieldsValue({
                labourCharge: Estimate.labourCharge ? Estimate.labourCharge : 0,
                partCharge: Estimate.partCharge ? Estimate.partCharge : 0,
                consumableCharge: Estimate.consumableCharge ? Estimate.consumableCharge : 0,
            })
            let total = Estimate.estTotalAmount ? Estimate.estTotalAmount : 0
            total = total.toFixed(0)
            setTotalEstimate(total)
            setDataSource(updateItemList)
        }
    }, [Estimate])

    const setEstimate = (type) => {
        let data = {
            type: type,
            jobOrder: data1.id
        }
        // console.log(data)
        if (type === "APPROVED") {
            platformApi.put(`/api/estimate/setEst/${estId}`, data)
                .then((result) => {
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            message.success("Estimate Approved Successfully");
                            window.location.reload();
                            // history.push('/autoadmin/estimate');
                        }
                    }
                }).catch((err) => {
                    console.error("Estimate Add Error: ", err);
                    message.error("Couldn't approve Estimate");
                });
        }
        else if (type === "REJECTED") {
            platformApi.put(`/api/estimate/setEst/${estId}`, data)
                .then((result) => {
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            message.success("Estimate Rejected Successfully");
                            window.location.reload();
                            // history.push('/autoadmin/estimate');
                        }
                    }
                }).catch((err) => {
                    console.error("Estimate Add Error: ", err);
                    message.error("Couldn't approvep;p Estimate");
                });
        }
    }
    const renderColumns = () => {
        const columns = [
            {
                title: "Details",
                dataIndex: "partNumber",
                key: "partNumber",
                width: 220,
                render: (record, data) => (

                    <Form.Item>
                        <span>{record.jobCode ? record.jobCode.code : `${record.partNumber} - ${record.partName}`}</span>
                    </Form.Item>
                )
            },
            {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
                width: 100,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="quantity"
                            disabled={true}
                            placeholder="quantity"
                            value={record && record.quantity}
                        />
                    </Form.Item>
                )
            },
            {
                title: "Unit rate",
                dataIndex: "unitRate",
                key: "unitRate",
                width: 100,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="unitRate"
                            disabled={true}
                            placeholder="Unit Rate"
                            value={record && record.unitRate}
                        />
                    </Form.Item>
                ),
            },
            {
                title: "Amount",
                dataIndex: "rate",
                key: "rate",
                width: 120,
                render: (data, record) => (
                    <Form.Item>
                        <Input
                            key="rate"
                            disabled={true}
                            placeholder="Amount"
                            value={record && record.rate}
                        />
                    </Form.Item>
                ),
            },
        ];
        return columns
    }

    const handleTabChange = (e) => {
        let index = parseInt(e)
        let data = dataSource[index - 1]
        let tot = 0
        for (let item of data) {
            if (item.amount) {
                tot += parseInt(item.amount)
            }
        }
        setTotalEstimate(tot)
    }

    return (
        <div style={{ padding: '40px' }}>
            {/* <Tabs
                onChange={handleTabChange}
                defaultActiveKey="1">
                <TabPane tab="Estimate-1" key="1">
                    <Table
                        columns={columns}
                        dataSource={dataSource[0]}
                        pagination={false}
                    />
                </TabPane>
                {prevComplaint.length < totalComplaints.length ?
                    <TabPane tab="Estimate-2" key="2">
                        <Table
                            columns={columns}
                            dataSource={dataSource[1]}
                            pagination={false}
                        />
                    </TabPane>
                    : null}
            </Tabs> */}
            <Table
                className="parts-invoice-table"
                columns={renderColumns()}
                dataSource={dataSource}
                pagination={false}
                tableLayout="fixed"
                style={{ marginTop: '1rem' }}
            />
            {Estimate && <div style={{ padding: '10px' }}>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            className="form-element"
                            label="Part Charge"
                        >
                            {
                                getFieldDecorator("partCharge", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Part Charge"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Part Charge"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>

                        <Form.Item
                            className="form-element"
                            label="Labour Charge"
                        >
                            {
                                getFieldDecorator("labourCharge", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Labour Charge"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Labour Charge"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>
                        <Form.Item
                            className="form-element"
                            label="Consumable Charge"
                        >
                            {
                                getFieldDecorator("consumableCharge", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Enter Consumable Charge"
                                        }
                                    ]
                                })(
                                    <Input
                                        placeholder="Consumable Charge"
                                        disabled={true}
                                    />
                                )
                            }
                        </Form.Item>

                    </Col>
                </Row>
            </div>}
            <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '1rem', marginRight: '80px' }}>
                <table style={{ fontSize: '20px', fontWeight: 'bold' }}>
                    <tr>
                        <td >Total</td>
                        <td>:</td>
                        <td>{totalEstimate}/-</td>
                    </tr>
                </table>
            </div>
            {
                Estimate && Estimate.estimateStatus == "PENDING" &&
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
                    <Button type="primary" ><a target="_blank" rel="noopener noreferrer" href={Estimate.estimatePdf}>Download PDF</a></Button>
                    <div>
                        <Button type="primary" onClick={() => {
                            setEstimate("APPROVED");
                        }} >Approve Estimate</Button>
                        <Button type="primary" style={{ marginLeft: '20px' }} onClick={() => {
                            setEstimate("REJECTED");
                        }}>Reject Estimate</Button>
                    </div>
                </div>

            }
            {
                Estimate && Estimate.estimateStatus == "APPROVED" &&
                <div style={{ display: 'flex', justifyContent: 'space-between', margin: '1rem' }}>
                    <Button type="primary" ><a target="_blank" rel="noopener noreferrer" href={Estimate.estimatePdf}>Download PDF</a></Button>
                    {/* <div>
                        <Button type="primary" style={{ marginLeft: '20px' }} onClick={() => {
                            setEstimate("REJECTED");
                        }}>Reject Estimate</Button>
                    </div> */}
                </div>
            }

        </div>
    )
}
const WrappedEstimates = Form.create()(
    Estimates
);

export default WrappedEstimates;
// export default Estimates;