import React, { useState, useEffect, useContext } from "react";
import { platformApi } from "../../../api";
import {
  Button,
  Input,
  Typography,
  Dropdown,
  Row,
  Menu,
  Col,
  Icon,
  message,
  Tooltip
} from "antd";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';
import WrappedForm from "./RTOForm";
import RTOTable from "./RTOTable";
const { Search } = Input;
const { Title } = Typography;
const { Item } = Menu;
import { ContextAPI } from "../../../ContextAPI";
import WrappedModalForm from "./RtoModal";
const RTOMaster = (props) => {
  const [visible, setVisible] = useState(false);
  const [limit, setLimit] = useState(10);
  const [dataSource, setdataSource] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [spinning, setSpinning] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editable, setEditable] = useState(true)
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const [search, setSearch] = useState('')
  const [open, setOpen] = useState(false);



  const addData = (data) => {
    if (data.id) {
      platformApi
        .put(`/api/rto/${data.id}`, data)
        .then((res) => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setSelected(null);
              const modi = dataSource;
              modi.some((obj, index) => {
                if (obj.id === response.data.id) modi[index] = response.data;
              });
              setdataSource([...modi]);

              message.success("RTO saved successfully", 2);
            } else if (response.code === 400) {
              message.error("", 2);
            } else {
              message.error("Unable to save RTO", 2);
            }
          } else {
            message.error("Unable to save RTO", 2);
          }
        })
        .catch((err) => {
          message.error("Unable to save RTO", 2);
          console.error("Error on RTO : ", err);
        });
    } else {
      platformApi
        .post("/api/rto/", data)
        .then((res) => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = res.data;
            if (response.code === 200) {
              setdataSource([...dataSource, response.data]);
              setCount(count + 1);
              message.success("RTO added successfully");
            }
            else if (response.code === 500) {
              message.error("RTO Code already exists", 2)
            }
            else {
              message.error("Error in RTO creation");
            }
          } else {
            message.error("Error in RTO creation");
          }
        })
        .catch((err) => {
          console.error("Error on RTO : ", err);
        });
    }
  };
  useEffect(() => {
    setPageData(search)
  }, [page, search, limit])


  const setPageData = (string, val) => {
    setSpinning(true);
    platformApi
      // .get("/api/rto")
      .post("/api/rto/get", { page: val ? val : page, size: limit, searchString: string })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setdataSource(response.data.rto);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch RTO", 2);
          }
        } else {
          message.error("Unable to fetch RTO", 2);
        }
      })
      .catch((err) => {
        setSpinning(false);
        console.error("Error on RTO : ", err);
        message.error("Unable to fetch RTO", 2);
      });
  };
  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "rtomaster") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPageData();
  }, []);
  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    platformApi
      .delete(`/api/rto/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1);
              setPageData(null, 1)
            }
            else {
              setPageData();
            }
            message.success("Deleted Sucessfully", 2);
          } else {
            message.error("Unable to delete RTO", 2);
          }
        } else {
          message.error("Unable to delete RTO", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to delete RTO", 2);
        console.error("Error on RTO : ", error);
      });
  };
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          RTO Master [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          placeholder="Search RTO Code"
          className="searchbar-div-manufacturer"
          style={{ width: "30%" }}
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button
          onClick={() => {
            setOpen(true);
            setEditable(true)
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add RTO Data
        </Button>
      </div>
      {/* <WrappedForm
        addData={addData}
        // modalData={selected}
        create={createAccess}
      // setSelected={setSelected}
      /> */}
      <WrappedModalForm
        addData={addData}
        modalData={selected}
        create={createAccess}
        setSelected={setSelected}
        open={open}
        setOpen={setOpen}
        editable={editable}
        setEditable={setEditable}
      />
      <div>
        <RTOTable
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: limit,
            defaultCurrent: 1,
            total: count,
            current: page,
            showQuickJumper: true,
          }}
          dataSource={dataSource}
          spinner={spinning}
          openModal={(data, edit) => {
            setSelected(data);
            setOpen(true);
            setEditable(edit)
          }}
          deleteData={deleteData}
          deleteAccess={deleteAccess}
          modify={modifyAccess}
        />
      </div>
    </div>
  );
};
export default withRouter(RTOMaster);
