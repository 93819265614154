import React, { useEffect, useState, useContext } from 'react'
import './JobInvoice.css'
import Text from "antd/lib/typography/Text";
import {
  Row,
  Col,
  Progress,
  Select,
  DatePicker
} from "antd";
const { RangePicker } = DatePicker

import { PieChart } from 'react-minimal-pie-chart';
import { platformApi } from "../../../../api";
import Chart from "react-apexcharts";
import { ContextAPI } from '../../../../ContextAPI';
import { constant, set } from 'lodash';
function New(props) {
  const { loginCredintials } = useContext(ContextAPI);
  const { timeline, handleTimeline, handleDateChange, fromDate, toDate, showDate } = props;
  const [motorCycle, setMotorCycle] = useState(0);
  const [scooter, setScooter] = useState(0);
  const [total, setTotal] = useState(0);
  const [graphdata, setGraphData] = useState([])
  const [totalLabour, settotalLabour] = useState(0)
  const [labourPerVehicle, setlabourPerVehicle] = useState(0)
  const [totalPartsSold, settotalPartsSold] = useState(0)
  const [partsSalePerVehicle, setpartsSalePerVehicle] = useState(0)
  const [consumableSold, setconsumableSold] = useState(0)
  const [TotalConsumableSold, setTotalConsumableSold] = useState(0)
  const [labourPerMechanic, setlabourPerMechanic] = useState(0)
  const [activeMechanic, setactiveMechanic] = useState(0)
  const [invrepGraph, setinvrepGraph] = useState(0)
  const [pieChartSeries, setPieChartSeries] = useState([])
  const [pieChartOptions, setPieChartOptions] = useState({
    chart: {
      width: 320,
      type: 'pie',
    },
    labels: [],
    legend: {
      show: false
    },
    responsive: [{
      breakpoint: 480,
      options: {
        chart: {
          width: 100
        },
      }
    }]
  })

  useEffect(() => {
    const getData = (timeline) => {

      //"/api/jobOrder/dashboard"

      platformApi
        .post("/api/reports/mechanicAllocations", {
          timeline,
          from: fromDate,
          to: toDate
          // current: loginCredintials.employeeId,
        })
        .then((res) => {
          if (res.data.code === 200) {
            // console.log("LABOUr JOB COde   " + res.data)
            //PieChar
            const labour = res.data.response.data.labourData;
            for (let itr = 0; itr < labour.length; itr++) {
              const jobOrder = labour[itr];
              var count = 0;
              for (let index = 0; index < labour.length;) {
                const element = labour[index];
                if (index <= itr) {
                  index++;
                  continue;
                }
                if (element.mechanic.id === jobOrder.mechanic.id) {
                  count = count + element.total;
                  labour.splice(index, 1);
                }
                else {
                  index++;
                }
              }
              jobOrder.Overall = jobOrder.total + count;
            }
            let labourtotal = [], mechanic = [];
            labour.map(item => (
              labourtotal.push(item.Overall),
              mechanic.push(item.mechanic?.profile?.employeeName)
            ))

            // setMechanics(mechanic.length)
            setPieChartSeries(labourtotal)
            setPieChartOptions({
              labels: mechanic,
              legend: {
                show: false
              }
            })
          } else if (res.data.code === 500) {
            message.error("Unable to fetch data", 2)
          }
        });
    };
    getData(timeline)
  }, [timeline, fromDate, toDate])
  useEffect(() => {
    platformApi.post("/api/reports/vehicleRetailSale",
      {
        timeline,
        from: fromDate,
        to: toDate
      }
    ).then(res => {
      let { data } = res;
      setMotorCycle(data.response.data['motorCycle'] || 0);
      setScooter(data.response.data['scooter'] || 0)
      setTotal(data.response.data['total'])
      let arr = [];
      arr.push({ title: 'Scooter', value: data.response.data['scooter'] || 0, color: '#E38627' })
      arr.push({ title: 'Motorcycle', value: data.response.data['motorCycle'] || 0, color: '#ffb6c1' })
      setGraphData(arr);
    }).catch(err => {
      console.log(err);
    })
  }, [timeline, fromDate, toDate])
  //
  useEffect(() => {

    console.log("Date chnged");
    platformApi.post("/api/reports/partCharges", {
      timeline,
      from: fromDate,
      to: toDate
    }).then(res => {
      let { data } = res;
      settotalLabour(data?.response?.response?.labourCharge || 0)
      settotalPartsSold(data?.response?.response?.parts || 0)
      setlabourPerVehicle(data?.response?.response?.labourPerVehicle || 0)
      setpartsSalePerVehicle(data?.response?.response?.partsPerSold || 0)
      setconsumableSold(data?.response?.response?.consumableCharge || 0)
      setTotalConsumableSold(data?.response?.response?.consumablePerVehicle || 0)
      setactiveMechanic(data?.response?.response?.mechanicCount || 0)
      setlabourPerMechanic((data?.response?.response?.labourCharge || 0) / (data?.response?.response?.mechanicCount || 0))

      // let val = [];
      // if(data?.response?.response?.labourCharge != 0){
      //   val.push({ title: 'Total Labour Charges', value: data?.response?.response?.labourCharge || 0, color: '#3CB371' })
      // }
      // if (data?.response?.response?.consumableCharge != 0) {
      //   val.push({ title: 'Total Consumable Charges', value: data?.response?.response?.consumableCharge || 0, color: '#39A2DB' })
      // }
      // if (data?.response?.response?.parts != 0) {
      //   val.push({ title: 'Total Parts Sold', value: data?.response?.response?.parts || 0, color: '#E38627' })
      // }
      // setinvrepGraph(val);

      let val = [];
      val.push({ title: 'Total Labour Charges', value: data?.response?.response?.labourCharge || 0, color: '#3CB371' })
      val.push({ title: 'Total Consumable Charges', value: data?.response?.response?.consumableCharge || 0, color: '#39A2DB' })
      val.push({ title: 'Total Parts Sold', value: data?.response?.response?.parts || 0, color: '#E38627' })
      setinvrepGraph(val);

    }).catch(err => {
      console.log(err);
    })
  }, [timeline, fromDate, toDate])


  return (
    <div>
      <div style={{ width: '100%' }} >
        <div style={{ width: '200px' }}>
          {/* <Col span={6} style={{}}> */}
          <Select value={timeline} onChange={(e) => handleTimeline(e)} >
            <Select.Option value="today">Today</Select.Option>
            <Select.Option value="week">This Week</Select.Option>
            <Select.Option value="month">This Month</Select.Option>
            <Select.Option value="quarterly">This Quarter</Select.Option>
            <Select.Option value="halfyearly">Half-Yearly</Select.Option>
            <Select.Option value="year">This Year</Select.Option>
            <Select.Option value="date">Select by Date</Select.Option>
          </Select>
          {/* </Col> */}
          {(timeline === "date") && (
            <Row style={{ padding: "0px", marginTop: "0rem", position: 'relative', left: "13rem", bottom: "2rem" }}>
              <RangePicker onChange={handleDateChange} format={"DD/MM/YYYY"} />
            </Row>
          )}
          {(timeline !== "date") && (
            <Row style={{ padding: "0px", marginTop: "0rem", position: 'relative', left: "13rem", bottom: "2rem" }}>
              <RangePicker disabled={true} value={showDate} format={"DD/MM/YYYY"} />
            </Row>
          )}
        </div>
      </div>

      <div className="overall">
        <div className="Job">
          <Text style={{ position: 'relative', bottom: '1.5rem' }}>
            <strong>Invoice Report</strong>
          </Text>
          <div style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              {/* pichart */}
              <div style={{ width: '10rem', height: '8.5rem', position: "relative", top: "3rem", left: "-0.5rem" }}>
                {/* <PieChart
                  style={{ marginLeft: "1.9rem", marginTop: "-3rem" ,width:"12rem",height:"18rem", fontSize: "10px"}}
                  segmentsShift={1}
                  label={(props) => { return props.dataEntry.value; }}
                  data={
                    invrepGraph
                  } /> */}
                <Chart
                  options={pieChartOptions}
                  series={pieChartSeries}
                  type="pie"
                  width={220}
                  height={220}
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column", marginRight: "20px" }}>
              </div>
            </div>
            {/*  */}
            <div>
              <div className="Job-1">
                <div className="box-1">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      background: "white",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginLeft: "2rem",
                      marginTop: "1rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Total Labour
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {totalLabour}
                    </p>
                  </Col>
                </div>
                <div className="box-1">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      background: "white",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginLeft: "2rem",
                      marginTop: "1rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Total Parts Sold
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {totalPartsSold}
                    </p>
                  </Col>
                </div>
                <div className="box-2">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      background: "white",
                      marginLeft: "15px",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginTop: "1rem",
                      marginLeft: "2rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {/* Parts Sale Per Vehicle */}
                      Total Consumables
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {/* {isNaN(partsSalePerVehicle) ? 0 : partsSalePerVehicle} */}
                      {consumableSold}
                    </p>
                  </Col>
                </div>
                <div className="box-2">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      background: "white",
                      marginLeft: "15px",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginTop: "1rem",
                      marginLeft: "2rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      {/* Labour Per Vehicle */}
                      Active Mechanic
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {/* {isNaN(labourPerVehicle) ? 0 : labourPerVehicle} */}
                      {activeMechanic}
                    </p>
                  </Col>
                </div>
              </div>
              <div className="Job-2" style={{ marginLeft: '6rem' }}>
                <div className="box-3">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      height: "110px",
                      background: "white",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginLeft: "2rem",
                      marginTop: "1rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Labour Per Vehicle
                      {/* Active Mechanic */}
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {isNaN(labourPerVehicle) ? 0 : labourPerVehicle}
                      {/* {activeMechanic} */}
                    </p>
                  </Col>
                </div>
                <div className="box-3">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      height: "110px",
                      background: "white",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginLeft: "2rem",
                      marginTop: "1rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Parts Sale Per Vehicle
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {isNaN(partsSalePerVehicle) ? 0 : partsSalePerVehicle}
                      {/* {consumableSold} */}
                    </p>
                  </Col>
                </div>
                <div className="box-3">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      height: "110px",
                      background: "white",
                      marginLeft: "15px",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginTop: "1rem",
                      marginLeft: "2rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Consumables Per Vehicle
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {isNaN(TotalConsumableSold) ? 0 : TotalConsumableSold}
                    </p>
                  </Col>
                </div>
                {/* <div className="box-3">
                  <Col
                    span={25}
                    style={{
                      width: "180px",
                      height: "110px",
                      background: "white",
                      marginLeft: "15px",
                      padding: "15px 15px 0px 15px",
                      textAlign: "center",
                      boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                      marginTop: "1rem",
                      marginLeft: "2rem"
                    }}
                  >
                    <Text
                      style={{
                        padding: "5px 4px ",
                        color: "#A8AFB3",
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Labour Per Mechanic
                    </Text>
                    <p
                      style={{
                        fontSize: "26px",
                        paddingTop: "10px",
                        color: "#6C7B8A",
                      }}
                    >
                      {isNaN(labourPerMechanic) ? 0 : labourPerMechanic}
                    </p>
                  </Col>
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="container-11">
          <Text style={{ position: 'relative', bottom: '1.5rem' }}>
            <strong>Booking Registered</strong>
          </Text>
          <div style={{ width: '15rem', height: '12rem', position: "relative", top: "3rem", left: "0rem" }}>
            <PieChart
              segmentsShift={1}
              label={(props) => { return props.dataEntry.value; }}
              data={graphdata} />
          </div>
          <div style={{ position: 'relative', left: '17rem', display: "flex", top: '-4rem' }}>
            <Row
              style={{
                background: "white",
                padding: "10px 20px",
                textAlign: "center",
                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                height: "7rem",
                position: "relative",
                bottom: "7rem",
                left: "8rem",
                width: "18rem",
                marginRight: "2rem"
              }}
            >
              <Text
                style={{
                  padding: "2px 4px ",
                  color: "#A8AFB3",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Total Vehicle Retail
              </Text>
              <br />
              {/* <Progress
                        strokeColor="#6ADEC2"
                        style={{
                          textAlign: "center",
                          position:"relative",
                          left:"0rem"
                        }}
                        width={70}
                        type="circle"
                        percent=""
                        status="active"
                      /> */}
              <p
                style={{
                  fontSize: "26px",
                  paddingTop: "10px",
                  color: "#6C7B8A",
                }}
              >
                {total}
              </p>
            </Row>
          </div>
          <div style={{ position: 'relative', left: '8rem', display: "flex", top: '-4rem' }}>
            <Row
              style={{
                background: "white",
                padding: "10px 20px",
                textAlign: "center",
                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                height: "7rem",
                position: "relative",
                bottom: "7rem",
                left: "8rem",
                width: "18rem",
                marginRight: "2rem",
                marginTop: "2rem"
              }}
            >
              <Text
                style={{
                  padding: "2px 4px ",
                  color: "#A8AFB3",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Motorcycle
              </Text>
              <br />
              {/* <Progress
                        strokeColor="#6ADEC2"
                        style={{
                          textAlign: "center",
                          position:"relative",
                          left:"0rem"
                        }}
                        width={70}
                        type="circle"
                        percent=""
                        status="active"
                      /> */}
              <p
                style={{
                  fontSize: "26px",
                  paddingTop: "10px",
                  color: "#6C7B8A",
                }}
              >
                {motorCycle}
              </p>
            </Row>
            <Row
              style={{
                background: "white",
                padding: "10px 20px",
                textAlign: "center",
                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                height: "7rem",
                position: "relative",
                bottom: "7rem",
                left: "8rem",
                width: "18rem",
                marginRight: "2rem",
                marginTop: "2rem"
              }}
            >
              <Text
                style={{
                  padding: "2px 4px ",
                  color: "#A8AFB3",
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Scooter
              </Text>
              <br />
              {/* <Progress
                        strokeColor="#6ADEC2"
                        style={{
                          textAlign: "center",
                          position:"relative",
                          left:"0rem"
                        }}
                        width={70}
                        type="circle"
                        percent=""
                        status="active"
                      /> */}
              <p
                style={{
                  fontSize: "26px",
                  paddingTop: "10px",
                  color: "#6C7B8A",
                }}
              >
                {scooter}
              </p>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}
export default New
