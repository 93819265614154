import React, { useState} from 'react'
import { Row, Card, Table, Input, Empty, Typography, Col, Select } from 'antd'
import Moment from 'moment'

const RecordingsAndMessages = (props) => {
    const { editable, data, setData, messageDetails } = props
    const { Option } = Select
    const { TextArea } = Input
    const { Text } = Typography

    return (
        <div>
            <Row type="flex" justify="space-between" gutter={[16,16]}>
                <Col span={12}>
                    <strong>Activity Session ID: </strong>
                {data ? data.activityId : "-"}</Col> 
                <Col span={12}>
                    
                        <strong>Session Date: </strong>
                     {data && data.createdAt? Moment(data.createdAt).format('DD-MM-YYYY'):' -'}
                </Col>
                <Col span={12}>
                    {
                        data && data.interationType ?
                            <>
                            <strong>Interaction Type: </strong>{data.interactionType}</>
                        :null
                    }
                </Col>
                <Col span={12}>
                        <strong>Session Time: </strong>
                     {data && data.createdAt? Moment(data.createdAt).format('HH:mm'):' -'}
                    
                </Col>
                {(data && data.callId) ?
                <>
                <Col  span={12}>
                    <p>
                        <strong>Called Number: </strong> 
                    </p>
                </Col>
                <Col span={12}>
                    <strong>Duration: </strong>
                10 Min</Col> 
            <Col span={24}>
            <Card title={<Text>
                    <strong>Session Recording: </strong>
                </Text>}>
                <audio style={{width:'100%'}} controls/>
            </Card>
                    </Col></>
                : null
                }
                {
                    messageDetails.id ?
                        <>
            <Col span={24}>
            <Text>
                <strong>Messages: </strong>
                </Text>
                    <TextArea
                        disabled={!editable}
                        value={messageDetails.smsText}
                        rows={4}/>
                </Col>
                            <Col span={24}>
                                <strong>SMS: </strong>{messageDetails.smsStatus} at {Moment(messageDetails.smsDeliveredTime).format("DD-MM-YYYY HH:mm")}
                            </Col>
                            </>
                        :null
                }
            </Row>
        </div>
    )
}

export default RecordingsAndMessages