import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";
import { withRouter } from 'react-router-dom';
import moment from 'moment';


const { Text } = Typography;

const CustomerSpareTable = ({
    dataSource,
    openModal,
    delete: deleteData,
    spinner,
    history,
    pagination,
    deleteAccess,
    modifyAccess
}) => {
    const column = [
        {
            title: "Order Date",
            dataIndex: "orderDate",
            key: "orderDate",
            width: 125,
            render: (record) => (
                <span>
                    {record
                        ? moment(record).format("DD-MM-YYYY")
                        : moment(Date.now()).format("DD/MM/YYYY")}
                </span>
            ),
        },
        {
            title: <span>Order Number</span>,
            dataIndex: "orderNumber",
            key: "orderNumber",
            render: (record) => <span>{record}</span>,
        },
        {
            title: <span>Customer Name</span>,
            dataIndex: "partyName",
            key: "partyName",
            render: (record) => (
                <span>
                    {record && record.name}
                </span>
            ),
        },
        {
            title: "Grand Total",
            dataIndex: "GrandTotal",
            key: "GrandTotal",
            render: (record) => <span>{record}</span>,
        },
        {
            title: "Advance Amount",
            dataIndex: "Advance",
            key: "Advance",
            render: (record) => <span>{record}</span>,
        },
        {
            title: "Balance Amount",
            dataIndex: "Balance",
            key: "Balance",
            render: (record) => <span>{record}</span>,
        },
        {
            title: <span>Actions</span>,
            key: "actions",
            render: (data) => (
                <div>
                    { modifyAccess?

                        <span
                            onClick={(event) => {
                                event.stopPropagation();

                                history.push({
                                    pathname: `/autoadmin/customerspare/${data.id}`,
                                    state: {
                                        editable: true,
                                    }
                                })
                            }}
                            className="linkylink"
                        >
                            Modify
                        </span>
                        :
                        <span></span>
                    }
                    {deleteAccess?

                        <Popconfirm
                            title="Are you sure delete this Spare Order"
                            okText="Yes"
                            cancelText="No"
                            onCancel={(event) => {
                                event.stopPropagation();
                            }}
                            onConfirm={(event) => {
                                event.stopPropagation();
                                deleteData(data.id);
                            }}
                        >
                            <span
                                className="linkylink"
                                onClick={(event) => event.stopPropagation()}
                            >
                                <Divider type="vertical" />
                                Delete
                            </span>
                        </Popconfirm>
                        :
                        <span></span>

                    }
                    {
                        !modifyAccess && !deleteAccess
                        ?
                        <Text strong disabled> No Access Given</Text>
                        :
                        <span></span>
                    }

                </div>
            ),
        },
    ];

    return (
        <Table
            rowKey={(record) => record.id}
            pagination={pagination}
            columns={column}
            style={{ cursor: "pointer" }}
            dataSource={dataSource}
            onRow={(record) => ({
                onClick: () => {
                    history.push({
                        pathname: `/autoadmin/customerspare/${record.id}`,
                        state: {
                            editable: false
                        }
                    })
                },
            })}
            loading={spinner}
        />
    );
};

export default withRouter(CustomerSpareTable);
