import React, { useState, useEffect, useContext } from "react";
import { Modal, Row, Col, Typography, Form, Select, message } from "antd";
import moment from "moment";

import { platformApi } from "../../../../../api";
import { withRouter, Link } from "react-router-dom";
import { ContextAPI } from "../../../../../ContextAPI";
import "./index.less";
const { Title, Text } = Typography;


const TaskStatus = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const { loginCredintials } = useContext(ContextAPI);
  const [statusData, setStatusData] = useState({});

  const { form, open, close, id } = props;
  useEffect(() => {
    if (open) {
      platformApi
        .post("/api/promotions/status", { id: id })
        .then((res) => {
          if (res.data.code === 200) {
            let resp = res.data.response;
            if (resp.code === 200) {
              setStatusData(resp.data);
            } else {
              message.error("Error in sending SMS");
            }
          } else {
            message.error("Error in sending SMS");
          }
        });
    }
  }, [open]);

  const handleSubmit = () => {
    platformApi.get(`/api/promotions/report/${id}`).then((res) => {
      if (res.data.code === 200) {
        message.success("Report Generated");
        window.open(`${ENDPOINT}${res.data.response}`);
      }
    });
  };

  const clearFields = () => {
    form.resetFields();
  };

  return (
    <Modal
      title={<Title level={4}>Task Status</Title>}
      visible={open}
      onCancel={() => {
        clearFields();
        close();
      }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      //   okButtonProps={
      //     !editable ? { loading, style: { display: "none" } } : { loading }
      //   }
      //   cancelText={editable ? "Cancel" : "Close"}
      cancelText="Cancel"
      //   okText={cType ? "Save" : "Convert"}
      okText="Download Reports"
      wrapClassName="task-status-modal"
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Text strong>Task Status</Text> : {" "}
            {statusData != null ? statusData.taskStatus : null}
          </Col>
          <Col span={11}>
            <Text strong>Last Scheduled Run</Text> :{" "}
            {statusData.lastScheduledRun != "Not Run Yet" ? moment(statusData.lastScheduledRun).format("DD-MM-YYYY hh:mm") : statusData.lastScheduledRun}
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Text strong>Progress</Text> :{" "}
            {statusData != null ? statusData.completed : 0}/
            {statusData != null
              ? statusData.total === null
                ? 0
                : statusData.total
              : 0}{" "}
            Completed
          </Col>
          <Col span={11}>
            <Text strong>Total Messages Sent</Text> :{" "}
            {statusData != null ? statusData.completed : 0}
          </Col>
        </Row>
        <br />
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Text strong>Failed </Text>:{" "}
            {statusData != null ? statusData.failed : 0}
          </Col>
          {/* <Col span={11}>
            <Text strong>Delivery Failed</Text> :{" "}
            {statusData != null ? statusData.failed : 0}
          </Col> */}
        </Row>
      </Form>
      <br />
    </Modal>
  );
};
const WrappedAccessoriesModal = Form.create({ name: "test_sms" })(TaskStatus);
export default withRouter(WrappedAccessoriesModal);
