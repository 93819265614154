import Joi from "@hapi/joi";
import {
  phoneNoSpecialPattern,
  numberSpecialPattern,
  stringSpecialPattern,
  nameCapPattern,
  agePattern
} from "../../../../../src/constants";
const joiOptions = {
  abortEarly: false,
};
export const customerDataJoi = (data) => {
  let schema;
  schema = Joi.object().keys({
    phone: Joi.string().regex(phoneNoSpecialPattern).required().messages({
      "string.base": "Enter Mobile Number",
      "string.empty": "Enter Mobile Number",
      "string.pattern.base":
        'Must satisfy:No leading and trailing spaces, Only numbers',
    }),
    pincode: Joi.string().required().regex(numberSpecialPattern).messages({
      "string.base": "Enter Pincode",
      "string.empty": "Enter Pincode",
      "string.pattern.base": "Enter valid pincode",
    }),
    customer: Joi.string().allow("", null),
    fatherName: Joi.string()
      .required()
      .regex(nameCapPattern)
      .messages({
        "string.base": "Enter Father Name",
        "string.empty": "Enter Father Name",
        "string.pattern.base": "Enter valid Name",
      }),
    // street: Joi.string().required().messages({
    //   "string.base": "Enter Street",
    //   "string.empty": "Enter Street",
    //   "string.pattern.base": "Enter valid Street",
    // }),
    line1: Joi.string().required().messages({
      "string.base": "Enter Line 1",
      "string.empty": "Enter Line1",
      "string.pattern.base": "Enter valid Address Line 1",
    }),
    line2: Joi.string().allow("", null),
    line3: Joi.string().allow("", null),
    // line2: Joi.string().required().messages({
    //   "string.base": "Enter Line 2",
    //   "string.empty": "Enter Line 2",
    //   "string.pattern.base": "Enter valid Address Line 2",
    // }),
    // line3: Joi.string().required().messages({
    //   "string.base": "Enter Line 3",
    //   "string.empty": "Enter Line3",
    //   "string.pattern.base": "Enter valid Address Line3",
    // }),
    locality: Joi.string()
      .regex(localityPattern)
      .required()
      .messages({
        "string.base": "Enter Locality",
        "string.empty": "Enter Locality",
        "string.pattern.base": "Enter valid Locality",
      }),
    country: Joi.string().allow("", null),
    state: Joi.string().allow("", null),
    city: Joi.string().allow("", null),
    quotationsAssociated: Joi.array().allow("", null),
    linkQuotation: Joi.any().allow("", null),
    DOB: Joi.any().allow("", null),
    refferedBy: Joi.string().allow("", null),
    nominee: Joi.string().required().regex(nameCapPattern).messages({
      "string.base": "Enter Nominee name",
      "string.empty": "Enter Nominee name",
      "string.pattern.base": "Enter valid Name",
    }),
    nomineeAge: Joi.string()
      .regex(agePattern)
      .required()
      .messages({
        "string.base": "Enter Nominee Age",
        "string.empty": "Enter Nominee Age",
        "string.pattern.base": "Enter valid Age (Only numbers) more than 0",
      }),
    quotation: Joi.array().allow("", null),
    relationship: Joi.string().allow("", null),
    salesExecutive: Joi.string().allow("", null),
    branch: Joi.string().allow("", null),
    email: Joi.string().allow("", null),
  });
  if (schema) return schema.validate(data, joiOptions);
  else return { error: false };
};

export const vehicleDataJoi = (data) => {
  let schema;
  schema = Joi.object().keys({
    manufacturer: Joi.string().allow("", null),
    model: Joi.string().allow("", null),
    rto: Joi.string().allow("", null),
    insuranceType: Joi.string().allow("", null),
    totalDiscount: Joi.string().allow("", null),
    accessoriesTotal: Joi.string().allow("", null),
    accessoriesTotalAfterDiscount: Joi.string().allow("", null),
    vehicleManufacturer: Joi.string()
      .allow("", null)
      .regex(stringSpecialPattern)
      .messages({
        "string.pattern.base": "Enter valid Manufacturer",
      }),
    vehicleModel: Joi.string()
      .allow("", null)
      .regex(stringSpecialPattern)
      .messages({
        "string.pattern.base": "Enter valid Model",
      }),
    vehiclePrice: Joi.string()
      .allow("", null)
      .regex(numberSpecialPattern)
      .messages({
        "string.pattern.base": "Enter Valid Price (Only numbers)",
      }),
    onRoadPrice: Joi.string().allow("", null),
    TRcharges: Joi.string()
      .allow("", null)
      .regex(numberSpecialPattern)
      .messages({
        "string.pattern.base": "Enter Valid Price (Only numbers)",
      }),
    hypothetication: Joi.string()
      .allow("", null)
      .regex(numberSpecialPattern)
      .messages({
        "string.pattern.base": "Enter Valid Price (Only numbers)",
      }),
    numberPlate: Joi.string()
      .allow("", null)
      .regex(numberSpecialPattern)
      .messages({
        "string.pattern.base": "Enter Valid Price (Only numbers)",
      }),
    affidavitAmount: Joi.string()
      .allow("", null)
      .regex(numberSpecialPattern)
      .messages({
        "string.pattern.base": "Enter Valid Price (Only numbers)",
      }),
    specialNoCharges: Joi.string()
      .allow("", null)
      .regex(numberSpecialPattern)
      .messages({
        "string.pattern.base": "Enter Valid Price (Only numbers)",
      }),
    onRoadDiscount: Joi.string()
      .allow("", null)
      .regex(numberSpecialPattern)
      .messages({
        "string.pattern.base": "Enter Valid Price (Only numbers)",
      }),
    finalAmount: Joi.string().allow("", null),
  });
  if (schema) return schema.validate(data, joiOptions);
  else return { error: false };
};

export const paymentDataJoi = (data) => {
  let schema;
  schema = Joi.object().keys({
    paymentMode: Joi.string().allow('', null),
    loanType: Joi.string().allow('', null),
    financerName: Joi.string().allow('', null),
    financerBranch: Joi.string()
      .when('paymentMode', {
        is: 'finance',
        then: Joi.string().required()
          .messages({
            "string.base": "Enter Financier Branch",
            "string.empty": "Enter Financier Branch",
          }),
        otherwise: Joi.allow('', null)
      }),
    downPayment: Joi.string()
      .when('loanType', {
        is: 'companyAssist',
        then: Joi.string().required()
          .regex(numberSpecialPattern)
          .messages({
            "string.base": "Enter Downpayment",
            "string.empty": "Enter Downpayment",
            "string.pattern.base": "Enter Valid Price (Only numbers)",
          }),
        otherwise: Joi.allow('', null)
      }),
    tenure: Joi.string()
      .when('loanType', {
        is: 'companyAssist',
        then: Joi.string().required()
          .messages({
            "string.base": "Enter Valid Tenure",
            "string.empty": "Enter Tenure",
          }),
        otherwise: Joi.allow('', null),
      }),
    loan: Joi.string()
      .when('loanType', {
        is: 'companyAssist',
        then: Joi.string().required()
          .regex(numberSpecialPattern)
          // .min(Joi.ref('loan'))
          .messages({
            // 'string.min': 'Loan Amount must be greter than Loan Disbursement Amount',
            "string.base": "Enter Loan Amount",
            "string.empty": "Enter Loan Amount",
            "string.pattern.base": "Enter Valid Price (Only numbers)",
          }),
        otherwise: Joi.allow('', null)
      }),
    emiAmount: Joi.string()
      .when('loanType', {
        is: 'companyAssist',
        then: Joi.string().required()
          .regex(numberSpecialPattern)
          .messages({
            "string.base": "Enter EMI Amount",
            "string.empty": "Enter EMI Amount",
            "string.pattern.base": "Enter Valid Price (Only numbers)",
          }),
        otherwise: Joi.allow('', null)
      }),
    emiDate: Joi.number().allow('', null),
    emiStartDate: Joi.object()
      .when('loanType', {
        is: 'companyAssist',
        then: Joi.object().required()
          .messages({
            "object.base": "Select EMI Date",
            "object.empty": "Select EMI Date",
          }),
        otherwise: Joi.allow('', null)
      }),
    loanDis: Joi.string()
      .when('loanType', {
        is: 'companyAssist',
        then: Joi.string().required()
          .regex(numberSpecialPattern)
          .messages({
            "string.base": "Enter Disbursement Amount",
            "string.empty": "Enter Disbursement Amount",
            "string.pattern.base": "Enter Valid Price (Only numbers)",
          }),
        otherwise: Joi.allow('', null)
      }),
    showroomFinanceCharges: Joi.string()
      .when('loanType', {
        is: 'companyAssist',
        then: Joi.string().required()
          .regex(numberSpecialPattern)
          .messages({
            "string.base": "Enter Showroom Finance Charges",
            "string.empty": "Enter Showroom Finance Charges",
            "string.pattern.base": "Enter Valid Price (Only numbers)",
          }),
        otherwise: Joi.allow('', null)
      }),
    remarks: Joi.string().allow("", null),
    netRecieveables: Joi.string().allow("", null),
  });
  if (schema) return schema.validate(data, joiOptions);
  else return { error: false };
};
