import React, { useEffect, useState } from 'react'
import { Avatar, IconButton } from '@material-ui/core'
import { AttachFile, CheckBoxOutlineBlankRounded, SearchOutlined } from '@material-ui/icons'
import MoodIcon from '@material-ui/icons/Mood';
import MicIcon from '@material-ui/icons/Mic'
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SendIcon from '@material-ui/icons/Send';
import moment from 'moment';
import { platformApi } from '../../../../../../api';
import { message, Image } from 'antd';
import File from './file';
import "./chat.css"

const Chat = ({ chats }) => {
    const [chatMsg, setChatMsg] = useState(chats[0].text || [])
    const [msg, setMsg] = useState(null)
    const [date, setDate] = useState(null);
    const [open, setOpen] = useState(false);
    const [baseUrl, setBaseUrl] = useState(null)

    useEffect(() => {
        setChatMsg(chats[0].text)
    }, [chats])

    const sendMsg = (data) => {
        const obj = {
            "number": chats[0].number,
            "text": data
        }
        platformApi.post('/api/whatsapp/sendConversion', obj).then(res => {
            console.log(res.data)

            message.success("Message sent successfully", 2)
        }).catch(err => {
            console.log("error", error);
            message.error("Something went wrong", 2)
        })
    }


    const handleEvent = (e) => {
        e.preventDefault()
        let obj = {
            text: msg,
            direction: 'outbound',
            createdAt: new Date()
        }
        const val = [...chatMsg];
        val.push(obj)
        // sendMsg(msg)


        let objs = {
            "number": chats[0].number,
            "text": msg
        }
        platformApi.post('/api/whatsapp/sendConversion', objs).then(res => {
            console.log(res.data)
            if (res.data.code == 200) {
                message.success("Message sent successfully", 2)
                setChatMsg(val)
            }
            else {
                message.error("Something went wrong", 2)
            }
        }).catch(err => {
            console.log("error", error);
            message.error("Something went wrong", 2)
        })

        setMsg("")
    }


    const dateFunctionality = (chatDate) => {
        if (date == null) {
            setDate(chatDate);
            return date;
        }
        else {
            if (date !== chatDate) {
                setDate(chatDate);
                return date;
            }
            else {
                return false;
            }
        }
    }


    const download = e => {
        console.log(e);
        fetch(e, {
            method: "GET",
            headers: {}
        })
            .then(response => {
                response.arrayBuffer().then(function (buffer) {
                    const url = window.URL.createObjectURL(new Blob([buffer]));
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", "image.png"); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                });
            })
            .catch(err => {
                console.log(err);
            });
    };


    const fileUploadHandler = (e) => {
        setBaseUrl(e.file.originFileObj)
    }


    const fileupload = () => {
        setOpen(!open)

        console.log(baseUrl);

        const formData = new FormData();
        formData.append("file", baseUrl);
        formData.append("number", chats[0].number);


        console.log(formData);
        platformApi.post("/api/whatsapp/sendMediaConversion", formData).
            then(res => {
                let { data } = res;
                console.log(data);
                let obj = {
                    "url": data?.msg?.data,
                    text: null,
                    direction: 'outbound',
                    createdAt: new Date()
                }

                console.log(obj);
                const val = [...chatMsg];
                val.push(obj)
                setChatMsg(val)
                message.success("Message sent successfully!!", 2)
            }).catch(err => {
                message.error("Internal server error", 2)
            })

    }

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '-1.5rem'
        }} >
            <div className="chat-header" style={{
                padding: '8px',
                display: 'flex',
                alignItems: 'center',
                borderBottom: '1px solid lightgray'
            }}>
                <Avatar />
                <div className="header-info" style={{
                    paddingLeft: '20px',
                    flex: '1'
                }}>
                    <h3 style={{
                        marginBottom: '3px',
                        fontWeight: '500'
                    }}>{chats[0].name!=null ?chats[0].name : `Customer ${chats[0].number}`}</h3>
                    {/* <p>Last seen at 00:00</p> */}
                </div>
                <div className="chat-headright" style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    minWidth: '100px'
                }}>
                    {/* <IconButton>
                        <SearchOutlined />
                    </IconButton>
                    <IconButton>
                        <AttachFile />
                    </IconButton>
                    <IconButton>
                        <MoreVertIcon />
                    </IconButton> */}
                </div>
            </div>



            <div className="chat" style={{
                paddingTop: '1rem',
                // flex: '1',
                backgroundRepeat: 'repeat',
                backgroundPosition: 'center',
                overflowY: 'scroll',
                height: '30rem',
                backgroundImage: "URL(https://user-images.githubusercontent.com/15075759/28719144-86dc0f70-73b1-11e7-911d-60d70fcded21.png)"
            }}>

                {chatMsg.map((chat) => {
                    // dateFunctionality(chat.createdAt)
                    return <>


                        {chat['direction'] === "inbound" && chat.url !== null &&
                            <>
                                <embed
                                    style={{
                                        // position: 'relative',
                                        fontSize: '14px',
                                        padding: '5px',
                                        borderRadius: '9px',
                                        margin: '1rem',
                                        backgroundColor: '#ffffff',
                                        marginBottom: '5px',
                                        display: 'table',
                                        marginLeft: '20px!important',
                                        width: "350px",
                                        wordSpacing: "1px",
                                        overflow: "hidden"
                                    }}

                                    src={chat.url} alt="Image url not loaded" />

                                <a style={{ display: 'block', width: "350px", color: "black", fontSize: "10px", textAlign: "right" }} href={chat.url} target="_blank" download>Click to download</a>

                                <span style={{
                                    fontSize: "xx-small",
                                    fontWeight: '800',
                                    marginLeft: '330px',
                                    textAlign: "right",
                                    display: "flex",
                                    alignItems: "flex-end"
                                }} className="chat-timestamp">{moment(chat.createdAt).format('LT')}</span>
                            </>
                        }



                        {chat['direction'] === "outbound" && chat.url !== null &&
                            <>
                                <embed
                                    style={{
                                        float: 'right',
                                        fontSize: '14px',
                                        padding: '10px',
                                        borderRadius: '9px',
                                        margin: '1rem',
                                        marginBottom: '5px',
                                        marginLeft: '20px!important',
                                        width: "350px",
                                        wordSpacing: "1px",
                                        display: "block"
                                    }}
                                    src={chat.url} alt="Image url not loaded" />
                                <span style={{
                                    fontSize: "xx-small",
                                    fontWeight: '800',
                                    marginLeft: '330px',
                                    float: 'right',
                                    paddingRight: "20px"
                                }} className="chat-timestamp">{moment(chat.createdAt).format('LT')}</span>
                            </>
                        }


                        {chat['direction'] === "inbound" && chat.text !== null &&
                            <div className="chat_inner2">
                                <p>{chat.text}
                                    <div style={{ textAlign: "right" }}>
                                        <span style={{
                                            fontSize: "xx-small",
                                            fontWeight: '800',
                                            marginLeft: '10px',
                                            textAlign: "right"
                                        }} className="chat-timestamp">{moment(chat.createdAt).format('lll')}</span>
                                    </div>
                                </p>
                            </div>
                        }


                        {chat['direction'] === "outbound" && chat.text !== null &&
                            <div className="chat_inner">
                                <p>
                                    {chat.text}
                                    <div style={{ textAlign: "right" }}>
                                        <span style={{
                                            fontSize: "xx-small",
                                            fontWeight: '800',
                                            marginLeft: '10px'
                                        }} className="chat-timestamp">{moment(chat.createdAt).format('lll')}</span>
                                        <span style={{ fontSize: "xx-small", marginLeft: "10px", color: "blue" }}>
                                            {chat.status}
                                        </span>
                                    </div>
                                </p>

                            </div>

                        }
                    </>
                })
                }
            </div>

            <div className="chat-footer" style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                height: '62px',
                borderTop: '1px solid lightgray'
            }}>

                <form
                    onSubmit={handleEvent}
                    style={{
                        flex: '1',
                        display: 'flex',
                        justifyContent: "center",
                        paddingLeft: "11px"
                    }}>
                    <span onClick={() => setOpen(!open)}>
                        <AttachFile
                            style={
                                {
                                    marginLeft: "5px",
                                    marginRight: "10px",
                                    cursor: "pointer"
                                }}
                        />
                    </span>

                    <input name="msg" type="text" placeholder="  Send a message"
                        onChange={
                            (e) => setMsg(e.target.value)
                        }
                        value={msg}
                        style={{
                            borderRadius: '3px',
                            border: '0.5px solid #d3d3d3',
                            outline: 'none',
                            width: "100%",
                            height: "2rem"
                        }} />
                    <button
                        style={
                            {
                                display: 'flex',
                                alignItems: "center",
                                justifyContent: "center",
                                marginLeft: "10px",
                                width: "60px",
                                cursor: "pointer"
                            }
                        }><SendIcon /></button>
                </form>
            </div>

            <File visible={open}
                close={() => {
                    setOpen(!open)
                }}
                fileUploadHandler={fileUploadHandler}
                fileupload={fileupload}
            />

        </div>
    )
}

export default Chat
