import React, { useContext, useState, useEffect } from "react";
import minimize from "../../../../assets/minimize.svg"
import edit from "../../../../assets/edit.svg"
import { formatValue } from "../../../../utils"
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
  Modal,
  Form,
  Select,
  Input,
  Icon,
  InputNumber,
} from "antd";
import "./index.less";
import { platformApi } from "../../../../api";
import { range } from "lodash";
const { Title } = Typography
const { Item } = Form
const { Option } = Select

const VIForm = ({
  dataSource,
  visible,
  setVisible,
  emitData,
  editableData,
  editable,
  setEditable,
  setEditableData,
  setDataSource,
  form
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  const [error, setError] = useState({});
  const [MFGId, setMFGId] = useState("");

  useEffect(() => {
    if (Object.keys(editableData).length > 0) {
      setFieldsValue({
          modelName:editableData.modelName,
          branch:editableData.branch,
          chassisNo:editableData.chassisNo,
          engineNo:editableData.engineNo,
          color:editableData.color,
      })
    }
  }, [editableData])

  useEffect(() => {
    

  }, [editableData])


  const clearFields = () => {
    setVisible(false)
    setEditableData([])
    setEditable(false)
    setError({})
    form.resetFields()
  }
  const handleSubmit = () => {
    if (!editable) {
      setEditable(true)
      return
    }
    validateFields(err => {
      if(!err && Object.keys(error).length==0){
      let data;
      if (Object.keys(editableData).length>0) {
        data = {
          id: editableData.id,
          modelName:getFieldValue("modelName"),
          branch:getFieldValue("branch"),
          chassisNo:getFieldValue("chassisNo"),
          engineNo:getFieldValue("engineNo"),
          color:getFieldValue("color"),
        }
      }
      else {
        data = {
          modelName:getFieldValue("modelName"),
          branch:getFieldValue("branch"),
          chassisNo:getFieldValue("chassisNo"),
          engineNo:getFieldValue("engineNo"),
          color:getFieldValue("color"),
        }
      }
      console.log(data)
      emitData(data)
      form.resetFields()
      setError({})
      setEditable(false)
      setEditableData([])
      setVisible(false)
    }
    })
  }


  const checkEngineNumber = (engineNo, id) => {
    platformApi
      .post(`/api/vehicle/checkEngineNo`, { engineNo, id })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 400) {
            setError({
              ...error,
              ENO: {
                type: "error",
                message: "Engine Number already exists",
              },
            });
          }
        }
      });
  };

  const checkChassisNumber = (chassisNo, manId, id) => {
    platformApi
      .post(`/api/purchaseChallan/frameNumber`, {
        chassisNo,
        manufacturer: manId,
        id,
      })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            const date = new Date(response.data).toLocaleDateString("en-us", {
              month: "short",
              year: "numeric",
            });
            if (!modify) {
              message.success("Chassis Number is Valid");
            }
            delete error.CHS;
            setError({ ...error });
          } else if (response.code === 401) {
            const date = new Date(response.data).toLocaleDateString("en-us", {
              month: "short",
              year: "numeric",
            });
            setError({
              ...error,
              CHS: {
                type: "error",
                message: "Chassis Number already exists",
              },
            });
          } else if (response.code === 400) {
            setError({
              ...error,
              CHS: {
                type: "error",
                message: "Enter Valid Chassis Number",
              },
            });
            if (!id) {
              message.error("Enter valid Chassis Number");
            }
          }
        }
      });
  };




  return (
    <Modal
      wrapClassName="pmc-form"
      visible={visible}
      title="Add Vehicles"
      okButtonProps={{}}
      onCancel={() => { clearFields() }}
      onOk={handleSubmit}
      okText={editable ? "OK" : "Modify"}
    >
      <Form>
        <Row>
        <Col span={7}>
            <Item
              label="Branch"
              colon={false}
            >
              {getFieldDecorator("branch", {
                rules: [{ required: false, message: "Enter Branch" }],
              })(
                <Input
                  pattern="^[a-zA-Z0-9]+(([' ][a-zA-Z])?[a-zA-Z]*)*$"
                  disabled={!editable}
                  placeholder="Branch"
                />
              )}
            </Item>
          </Col>
          <Col span={1}/>
          <Col span={7}>
            <Item
              label="Model Name"
              colon={false}
            >
              {getFieldDecorator("modelName", {
                rules: [{ required: false, message: "Enter Model Name" }],
              })(
                <Input
                  pattern="^[a-zA-Z0-9]+(([' ][a-zA-Z])?[a-zA-Z]*)*$"
                  disabled={!editable}
                  placeholder="Model Name"
                />
              )}
            </Item>
          </Col>
          <Col span={1}/>
          <Col span={7}>
                  <Item
                    name="Chassis Number"
                    colon={false}
                    label="Chassis Number"
                    validateStatus={error.CHS && error.CHS.type}
                    help={error.CHS && error.CHS.message}
                  >
                    {getFieldDecorator("chassisNo", {
                      rules: [{ required: true, message: "Enter Chassis Number" }],
                    })(
                      <Input
                        // style={{ textTransform: "uppercase" }}
                        disabled={!editable}
                        placeholder="Chassis Number"
                        maxLength={17}
                        pattern="^[A-Z0-9]{17}$"
                        onKeyUp={(e) => {
                          form.setFieldsValue({
                            chassisNo: e.target.value.toUpperCase(),
                          });
                        }}
                        // onInput={(event) => {
                        //   if (event.target.value.length === 17) {
                        //     checkChassisNumber(
                        //       event.target.value,
                        //       MFGId,
                        //     );
                        //   } else {
                        //     if (!event.target.checkValidity()) {
                        //       setError({
                        //         ...error,
                        //         CHS: {
                        //           type: "error",
                        //           message: "Enter Valid Chassis Number",
                        //         },
                        //       });
                        //     } else {
                        //       delete error.CHS;
                        //       setError(error);
                        //     }
                        //   }
                        // }}
                      />
                    )}
                  </Item>
                </Col>
        </Row>
        <Row>
        <Col span={7}>
                  <Item
                    name="Engine Number"
                    colon={false}
                    label="Engine Number"
                    validateStatus={error.ENO && error.ENO.type}
                    help={error.ENO && error.ENO.message}
                  >
                    {getFieldDecorator("engineNo", {
                      rules: [{ required: false, message: "Enter Engine Number" }],
                    })(
                      <Input
                        // style={{ textTransform: "uppercase" }}
                        disabled={!editable}
                        placeholder="Engine Number"
                        minLength={5}
                        maxLength={25}
                        onKeyUp={(e) => {
                          form.setFieldsValue({
                            engineNo: e.target.value.toUpperCase(),
                          });
                        }}
                        pattern="^[a-zA-Z0-9]+(([a-zA-Z])?[a-zA-Z]*)*$"
                        onInput={(event) => {
                          if (event.target.value.length > 7) {
                            checkEngineNumber(
                              event.target.value,
                            );
                          }
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              ENO: {
                                type: "error",
                                message: "Enter Valid Engine Number",
                              },
                            });
                          } else {
                            delete error.ENO;
                            setError(error);
                          }
                        }}
                      />
                    )}
                  </Item>
                </Col>
                <Col span={1}/>
                <Col span={7}>
            <Item
              validateStatus={error.COLOR && error.COLOR.type}
              help={error.COLOR && error.COLOR.message}
              label="Color"
              colon={false}
            >
              {getFieldDecorator("color", {
                rules: [{ required: false, message: "Enter Color" }],
              })(
                <Input
                  pattern="^[a-zA-Z0-9]+(([' ][a-zA-Z])?[a-zA-Z]*)*$"
                  // onKeyUp={e =>
                  //           form.setFieldsValue({
                  //                 color:formatValue(e,"allCaps")
                  //           })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        COLOR: {
                          type: "error",
                          message: "Enter Valid Color",
                        },
                      });
                    } else {
                      delete error.COLOR;
                      setError(error);
                    }
                  }}
                  disabled={!editable}
                  placeholder="Color"
                />
              )}
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  )
}

const WrappedVIForm = Form.create({ name: "VIForm" })(VIForm);
export default WrappedVIForm