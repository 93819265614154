import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, message } from "antd";
import { platformApi } from "../../../../api";
import "./index.less";
const { Item } = Form;
const { Option } = Select;
function AdvancedFilters({
  form,
  filteropen,
  close,
  searchName,
  setId,
  id,
  setData,
  setPrimaryData,
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [branch, setBranch] = useState([]);
  const [department, setDepartment] = useState([]);

  const Filters = [
    { id: "1", name: "Name" },
    { id: "2", name: "Mobile Number" },
    { id: "3", name: "Branch" },
    { id: "4", name: "Department" },
  ];
  const selectFilter = (id) => {
    setId(id);
  };
  const searchValue = () => {
    setId([]);
    resetFields();
    setPrimaryData();
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
  };
  useEffect(() => {
    if (filteropen) {
      platformApi.get("/api/user/branch").then((res) => {
        setBranch(res.data.data);
      });
      platformApi.get("/api/user/department").then((res) => {
        setDepartment(res.data.data);
      });
    }
    setFieldsValue({ selectfilter: id });
  }, [filteropen]);
  useEffect(() => {
    id.forEach((element) => {
      if (element === "1") setFieldsValue({ Name: searchName });
    });
  }, [id, searchName, filteropen]);

  const handleSubmit = () => {
    const data = {
      name: getFieldValue("Name"),
      phone: getFieldValue("number"),
      branch: getFieldValue("branch"),
      department: getFieldValue("department"),
    };
    platformApi
      .post("/api/user/search", data)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(response.data.users);
            message.success("Filtered successfully", 2);
          } else {
            message.error("Unable to fetch", 2);
          }
        } else {
          message.error("Unable to fetch", 2);
        }
      })
      .catch((error) => {
        console.error("Error in filtering : ", error);
        message.error("Unable to fetch", 2);
      });
  };
  const branchSelect = (id) => {
  };
  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <Row type="flex" justify="start">
                  {id.map((element) =>
                    element === "1" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("Name")(
                                <Input placeholder="Name" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "2" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("number")(
                                <Input placeholder="Mobile Number" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "3" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("branch")(
                                <Select
                                  placeholder="Branch"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                  showAction={["click","focus"]}
                                >
                                  {branch.map((branch) => (
                                    <Select.Option
                                      value={branch.name}
                                      key={branch.id}
                                    >
                                      {branch.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("department")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Department"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                  mode="multiple"
                                >
                                  {department.map((dept) => (
                                    <Select.Option
                                      value={dept.role}
                                      key={dept.id}
                                    >
                                      {dept.role}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex" justify="space-between">
                  <Col span={19}>
                    <Item colon={false}>
                      {getFieldDecorator("selectfilter")(
                        <Select
                        showAction={["click","focus"]}
                          placeholder="Select the Filter"
                          onChange={selectFilter}
                          mode="multiple"
                        >
                          {Filters.map((type) => (
                            <Option key={type.id}>{type.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={5} style={{ marginTop: "0.2rem" }}>
                    <Button type="primary" onClick={handleSubmit}>
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="end"
                  style={{ marginRight: "0.79rem" }}
                >
                  <Button
                    type="ghost"
                    style={{ width: "4.5rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
