import React, { useState } from "react";
import { Table, Divider, Popconfirm, Button, Checkbox } from "antd";
import { withRouter } from "react-router-dom";
import { platformApi } from '../../../../../../../api'

function AccessControlTable(props) {
  const { dataSource, setDataSource, removeKeys, setRemoveKeys, spinner, selectedRowKeys } = props;
  const deleteRow = (submodule) => {
    let data = [...dataSource];
    const deleteRow = data.filter(d => d.subModule === submodule)
    data = data.filter(d => d.subModule !== submodule)
    let tmpRemoveKeys = [...removeKeys]
    tmpRemoveKeys = tmpRemoveKeys.concat(deleteRow)
    setRemoveKeys(tmpRemoveKeys)
    setDataSource(data)
  }

  function onChange(checkedValues, ind, value) {
    let data = dataSource;
    if (value.length === 5) {
      props.setSelectedRowKeys([...selectedRowKeys, ind.subModule])
    }
    else {
      if (selectedRowKeys.some(data => data === ind.subModule)) {
        let tmp = selectedRowKeys.filter(data => data !== ind.subModule)
        props.setSelectedRowKeys(tmp)
      }
    }
    data[data.indexOf(ind)].rights = value;
    setDataSource([...data])
  }

  const options = [
    { label: 'Create', value: 'C' },
    { label: 'Read', value: 'R' },
    { label: 'Update', value: 'U' },
    { label: 'Delete', value: 'D' },
    { label: 'Print', value: 'P' },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys) => {
      props.setSelectedRowKeys(selectedRowKeys)
    },
    onSelect: (record, selected, selectedRows, nativeEvent) => {
      props.selectAll({ all: false, record, selected, selectedRows })
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      props.selectAll({ all: true, selected, selectedRows, changeRows })
    },
  };

  const column = [
    {
      title: "Module",
      dataIndex: "master",
      key: "master",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Sub Module",
      dataIndex: "subModule",
      key: "subModule",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "CRUD Access Rights",
      dataIndex: "rights",
      key: "rights",
      render: (record, data) => <span>
        <Checkbox.Group
          options={options}
          onChange={(value) => { onChange(record, data, value) }}
          value={record}
        /></span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          <span
            onClick={(event) => deleteRow(data.subModule)}
            className="linkylink"
          >
            Remove
            </span>
        </div>
      ),
    }

  ];

  return (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      rowKey={(props) => props.subModule}
      columns={column}
      dataSource={dataSource}
      loading={spinner}
    />
  );
}

export default withRouter(AccessControlTable);
