import React, { useState, useContext, useEffect } from "react";
import {
	Modal,
	Button,
	Select,
	Col,
	Form,
	Row,
	Input,
	Table,
	Popconfirm,
	DatePicker,
	message,
} from "antd";
import { getUUID } from "../../../../../_helpers/utils";
import moment from "moment";
import { ContextAPI } from "../../../../../ContextAPI";
import { platformApi } from "../../../../../api";

const StockCheckForm = (props) => {
	const {
		createAccess,
		close,
		editable,
		open,
		form,
		data,
		partsData,
		setPartsData,
		getPageData,
		modelType,
	} = props;

	const partItemData = {
		id: getUUID(),
		partNo: {},
		phyQuantity: null,
	};
	const { loginCredintials } = useContext(ContextAPI);
	const { getFieldDecorator, setFieldsValue, getFieldValue, validateFields } = form;

	const [createdByUser, setCreatedByUser] = useState(loginCredintials);
	const [itemList, setItemList] = useState([partItemData]);
	const [dataTemp, setDataTemp] = useState([]);
	const [selectItemObj, setSelectItemObj] = useState({});
	const partsRef = {};

	useEffect(() => {
		let temp = [];
		partsData.map((item) => {
			temp.push({
				label: item.partNumber,
				value: item.partNumber,
				id: item.id,
			});
		});
		setDataTemp(temp);
	}, [partsData]);

	useEffect(() => {
		if (data?.id) {
			setFieldsValue({
				createdAt: moment(data.createdAt),
				binNum: data.binNum,
			});
			const modifyItemList = {
				id: getUUID(),
				partNo: data.partNo,
				phyQuantity: data.phyQuantity,
			};
			setCreatedByUser({ ...data.createdBy, employeeName: data.createdBy.profile.employeeName });
			setItemList([modifyItemList]);
		} else {
			setFieldsValue({
				createdAt: moment(Date.now()),
			});
		}
	}, [data]);

	// useEffect(() => {
	//   const selectItem = {};
	//   for (const item of itemList) {
	//     if (item.partNo?.id) selectItem[item.partNo.id] = item.partNo?.partName;
	//   }
	//   setSelectItemObj(selectItem);
	// }, [itemList])

	const searchParts = async (search) => {
		// const res = await platformApi
		//     .post("/api/options/get/", {
		//         module: search.module,
		//         column: search.column,
		//         searchString: search.searchString,
		//         fields: search.fields,
		//         size: 100,
		//         page: 1,
		//         except: null,
		//         branch: pBranch
		//     })
		// let parts = [];
		// // console.log("Response", res.data)
		// for (let i = 0; i < res.data.response?.length; i++) {
		//     const element = res.data.response[i];
		//     parts.push(element.partNo)
		// }
		// search.setValue(parts)
		// return 1
		// .then((res) => {
		//     let parts = [];
		//     for (let i = 0; i < res.data.response.length; i++) {
		//         const element = res.data.response[i];
		//         parts.push(element.partNo)
		//     }
		//     setPartsData(parts)
		//     return parts;
		// });

		const res = await platformApi.post("/api/options/get/", {
			module: search.module,
			column: search.column,
			searchString: search.searchString,
			fields: search.fields,
			size: 100,
			page: 1,
			except: null,
		});
		search.setValue(res.data.response);
		return 1;
	};

	const getParts = () => {
		let data = {
			module: "partsMasters",
			size: 100,
			column: "partNumber",
			fields: ["partNumber", "partName"],
		};
		platformApi
			.post("/api/options/get", data)
			.then((res) => {
				const { data } = res;
				setPartsData(data.response);
			})
			.catch((err) => {
				console.error("Part Fetch Error: ", err);
				message.error("Couldn't Fetch Parts");
			});
	};

	const handlePartDetail = async (event, data) => {
		const newItemList = [...itemList];
		if (event !== undefined) {
			partsData.map((part) => {
				if (part.partNumber === event) {
					for (let item of newItemList) {
						if (item.id === data.id) {
							item.partNo = part;
							item.phyQuantity = 1;
						}
					}
				}
			});
		}
		if (modelType === "CREATE" && newItemList[newItemList.length - 1].partNo.id)
			setItemList([...newItemList, partItemData]);
		else setItemList([...newItemList]);
		getParts();
	};

	const handlePartDelete = (data) => {
		if (data.partNo?.id == null || modelType === "MODIFY") return;
		setItemList((prev) => prev.filter((item) => item.id !== data.id));
	};

	const handleQuantity = (event, record) => {
		const quantity = parseInt(event.target.value);
		if (quantity < 1) return;
		let newItemList = [...itemList];
		for (let item of newItemList) {
			if (item.id === record.id) {
				item.phyQuantity = parseInt(event.target.value);
				break;
			}
		}
		setItemList(newItemList);
	};

	const handleSubmit = () => {
		validateFields((err) => {
			if (err == null) {
				if (modelType === "CREATE") {
					if (itemList.length < 2) return message.error("Add at least one record");
					const removedItem = itemList.pop();
					const uniqueItems = {};
					for (const item of itemList) {
						if (item.partNo.id in uniqueItems) {
							const itemObj = uniqueItems[item.partNo.id];
							uniqueItems[itemObj.partNo.id].phyQuantity = itemObj.phyQuantity + item.phyQuantity;
						} else {
							uniqueItems[item.partNo.id] = item;
						}
					}
					const requestObj = {
						stockChecks: Object.values(uniqueItems),
						binNum: getFieldValue("binNum"),
					};
					platformApi.post("/api/stockCheck", requestObj).then((res) => {
						console.log(res);
						const { data } = res;
						if (data?.response?.code === 200) message.success("Stock Check Created Successfully");
						else message.error("Unable to create Stock Check");

						handleCancel();
					});
				} else {
					const requestObj = {
						partNo: itemList[0].partNo,
						phyQuantity: itemList[0].phyQuantity,
						binNum: getFieldValue("binNum"),
					};
					platformApi.put(`/api/stockCheck/${data.id}`, requestObj).then((res) => {
						console.log(res);
						const { data } = res;
						if (data?.response?.code === 200) message.success("Stock Check modified Successfully");
						else message.error("Unable modify Stock Check");

						handleCancel();
					});
				}
			}
		});
	};

	const handleOk = () => {
		if (editable) return handleSubmit();
		handleCancel();
	};

	const handleCancel = () => {
		setItemList([partItemData]);
		form.resetFields();
		setFieldsValue({ createdAt: moment(Date.now()) });
		close();
		getPageData();
	};

	const columns = [
		{
			title: "Part Number",
			dataIndex: "partNo",
			key: "partNo",
			render: (record, data, key) => (
				<Form.Item>
					<Select
						dropdownStyle={{
							overflowY: "auto",
							overflowX: "hidden",
						}}
						onChange={(event) => {
							handlePartDetail(event, data);
						}}
						onSearch={async (event) => {
							const search = {
								module: "partsMasters",
								column: "partNumber",
								searchString: event,
								searchColumns: ["partNumber", "partName"],
								setValue: setPartsData,
							};
							searchParts(search);
							if (dataTemp?.length === 1) {
								console.log("dataTemp", dataTemp);
								handlePartDetail(dataTemp[0].value, data);
								partsRef[key].focus();
							}
						}}
						onFocus={() => {
							console.log("Focus");
						}}
						value={record?.partNumber ? record.partNumber : "Part Number"}
						disabled={!editable}
						showSearch
						showAction={["click", "focus"]}
						placeholder="Part Number"
						// filterOption={(input, option) =>
						//     option.props.children.some(
						//         (child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0
						//     )
						// }
					>
						{dataTemp.map((part) => {
							// if (part.id in selectItemObj) return null;
							return (
								<Select.Option key={part.id} value={part.value}>
									{part.label}
								</Select.Option>
							);
						})}
					</Select>
				</Form.Item>
			),
		},
		{
			title: "Part Name",
			dataIndex: "partNo",
			key: "partName",
			render: (record, data, index) => (
				<Form.Item>
					<Input key="partName" placeholder="Part Name" value={record?.partName} disabled={true} />
				</Form.Item>
			),
		},
		{
			title: "Physical Quantity",
			dataIndex: "phyQuantity",
			key: "phyQuantity",
			render: (record, data, index) => (
				<Form.Item>
					<Input
						type="number"
						key="phyQuantity"
						placeholder="Quantity"
						value={record}
						onChange={(e) => handleQuantity(e, data)}
						disabled={!editable}
						style={{ width: "7rem" }}
					/>
				</Form.Item>
			),
		},
		{
			title: "Action",
			dataIndex: "action",
			key: "action",
			render: (record, data) => (
				<Form.Item>
					<Popconfirm
						title="Sure to delete?"
						disabled={!editable}
						onConfirm={() => handlePartDelete(data)}
					>
						<a style={{ color: "#6c7b8a" }}>Delete</a>
					</Popconfirm>
				</Form.Item>
			),
		},
	];

	return (
		<div>
			<Modal
				title="Add Spares Bin Location"
				visible={open}
				onOk={handleOk}
				onCancel={handleCancel}
				width={1150}
			>
				<Form className="purchase-form">
					<Row>
						<Col span={12}>
							<Form.Item className="purchase-form-element" label="Created On">
								{getFieldDecorator("createdAt", {
									rules: [
										{
											required: false,
											message: "Created At Required",
										},
									],
								})(<DatePicker placeholder="Created At" disabled={true} />)}
							</Form.Item>
						</Col>
						<Col span={12}>
							<Form.Item className="purchase-form-element" label="Created By" name="orderDate">
								<Input disabled={true} value={createdByUser?.employeeName} />
							</Form.Item>
						</Col>
					</Row>
					<Row>
						<Col span={12}>
							<Form.Item className="purchase-form-element" label="Bin Location">
								{getFieldDecorator("binNum", {
									rules: [
										{
											required: true,
											message: "Bin Location required",
										},
									],
								})(<Input placeholder="Bin Location" disabled={!editable} />)}
							</Form.Item>
						</Col>
					</Row>
					<Table
						className="parts-invoice-table"
						dataSource={itemList}
						columns={columns}
						tableLayout="fixed"
						pagination={false}
						style={{ marginTop: "1rem", marginBottom: "1rem" }}
					/>
				</Form>
			</Modal>
		</div>
	);
};

const WrappedStockCheckForm = Form.create({ name: "StockCheckForm" })(StockCheckForm);
export default WrappedStockCheckForm;
