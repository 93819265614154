import React, { useState, useEffect } from "react";
import { Form, Icon, Input, Button, Checkbox, Alert, message } from "antd";

import { platformApi, setAccessToken } from "../../../api";
import "./index.less";
const ResetUsingSMSLink = (props) => {
  const { form, match, history } = props
  const [loader, setLoader] = useState(false);
  const [empId, setEmpId] = useState(null);

  useEffect(() => {
    handleCheck();
  }, [])
  const handleCheck = () => {
    const query = new URLSearchParams(props.location.search);
    const token = query.get('token');
    platformApi.post('/api/user/checkForgotPassword', { token: token })
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setEmpId(response.data.id)
          }
          else {
            history.push('/login');
            message.error('Link Expired or Incorrect Link')
          }
        }
        else {
          history.push('/login');
          message.error('Link Expired or Incorrect Link')
        }
      })
  }

  const handleSubmit = async () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        const newPassword = form.getFieldValue("newPassword")
        const confirmPassword = form.getFieldValue("confirmPassword")
        if (newPassword.length < 5) {
          message.error('Password length should be minimum 6 length')
        }
        else if (newPassword !== confirmPassword) {
          message.error('Password doesn\'t match');
        }
        if (newPassword === confirmPassword && newPassword.length > 5) {
          let obj = {
            id: empId,
            password: newPassword
          }
          setLoader(true)
          platformApi.post('/api/user/updatePassword', obj)
            .then(res => {
              setLoader(false)
              let { data } = res;
              if (data.code === 201) {
                message.error("Old password and New password Should be Same!");
                handleCheck();
              }
              else if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  history.push('/login');
                  message.success('Password Updated');
                }
                else {
                  message.error('Password Not Updated');
                }
              }
              else {
                message.error('Password Not Updated');
              }
            })
        }
      }
      else {
        message.error('Incomplete Fields');
      }
    });
  };


  return (
    <div className="login">
      <div className="loginform">
        <div className="login_foreground">
          <div className="login__title">
            <span className="heading-form">Reset Password</span>
          </div>
          <div >
            <Form className="login-form">
              <Form.Item>
                {form.getFieldDecorator("newPassword", {
                  rules: [
                    { required: true, message: "Please type your Password!" },
                  ],
                })(
                  <Input.Password
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="New Password"
                    size="large"
                    pattern="^[0-9A-Za-z]*$"
                  />
                )}
              </Form.Item> 
              <Form.Item>
                {form.getFieldDecorator("confirmPassword", {
                  rules: [
                    { required: true, message: "Please Confirm your Password!" },
                  ],
                })(
                  <Input.Password
                    prefix={
                      <Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />
                    }
                    placeholder="Confirm New Password"
                    size="large"
                    pattern="^[0-9A-Za-z]*$"
                  />
                )}
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="login-form-button"
                  onClick={() => handleSubmit()}
                  size="large"
                  loading={loader}
                >
                  Change Password
              </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
  )
}

const WrappedResetUsingOTPForm = Form.create({ name: "ResetwithOTP" })(ResetUsingSMSLink);
export default WrappedResetUsingOTPForm;
