import React, { useState, useEffect } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Result
} from "antd";
import { platformApi } from "../../../api";

import VehicleSaleInvoiceTable from "./VehicleSaleInvoiceTable";
import VehicleSaleModal from "./VehicleSaleModal";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

export default function VehicleSaleInvoice() {
  const [limit, setLimit] = useState(10);
  const [dataSource, setData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);

  const getMenu = () => {
    const size = dataSource.length / 10 + (dataSource.length % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map(menu => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Vehicle Sale Invoice [{dataSource.length}]
        </Title>
        <Search style={{ width: "30%" }} placeholder="Search Vehicle" />
        <Button
          onClick={() => {
            setModalVisible(true);
            // setAddFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
        >
          Add Vehicle Sale Invoice
        </Button>
      </div>
      <div style={{ margin: ".5rem" }}>
        <Dropdown overlay={menu} trigger={["click"]}>
          <Button>
            {limit} &nbsp;
            <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
      <VehicleSaleInvoiceTable />
      <VehicleSaleModal
        open={modalVisible}
        close={() => setModalVisible(false)}
      />
    </div>
  );
}
