import React, { useState, useEffect } from 'react'
import { Form, Input, Row, Select, Col, Button, Typography, message } from 'antd'
import AccessControlTable from "../../../CompanyMasters/Departments/DepartmentType/HomeDepartment/DepartmentForm/AccessControlTable"
import { submodules } from '../../../../JSONFiles/Submodule.json'
import { formatValue } from '../../../../utils'
import { Divider } from 'antd'
import { platformApi } from "../../../../api";

const DepartmentDetails = (props) => {
  const { form, data, emitData, validityRef } = props
  const { Item } = Form
  const { Text } = Typography;
  const { getFieldDecorator } = form;
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const Master = [
    { name: 'Company Master', key: 'COMPANYMASTER' },
    { name: 'Transaction Master', key: 'TRANSACTIONMASTER' }
  ]

  //states
  const [moduleId, setModuleId] = useState(null)
  const [subModuleId, setSubModuleId] = useState([])
  const [dataSource, setDataSource] = useState([])
  const [deleteRowIds, setDeleteRowIds] = useState([])
  const [subModules, setSubModules] = useState([])
  const [buttonLoading, setButtonLoading] = useState(false)

  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;


  useEffect(() => {
    validityRef.current = handleSubmit;
    return () => {
      validityRef.current = null;
    };
  });

  const changePage = (pos) => {
    const { setCurrent, current } = props
    if (pos === "prev") {
      setCurrent(current - 1)
    }
    else {
      setCurrent(current + 1)
    }
  }

  const handleSubmit = (pos) => {

    form.validateFields((validationError) => {
      if (validationError === null) {
        const role = form.getFieldValue('role');
        const departmentType = form.getFieldValue('departmentType')
        let roleAccess = [];
        dataSource.map(each => {
          let mod = ''
          let subMod = '';

          Master.some(e => {
            if (e.name === each.master) {
              mod = e.key
            }
          })
          submodules.map(e => {
            if (e.title === each.subModule) {
              subMod = e.key
            }
          })
          roleAccess.push({
            master: mod,
            subModule: subMod,
            id: each.id,
            access: {
              create: false,
              read: false,
              update: false,
              delete: false,
              print: false
            }
          })
        })
        dataSource.map((each, index) => {
          each.rights.map(eachRights => {
            if (eachRights === 'C') {
              roleAccess[index].access.create = true
            }
            else if (eachRights === 'R') {
              roleAccess[index].access.read = true
            }
            else if (eachRights === 'U') {
              roleAccess[index].access.update = true
            }
            else if (eachRights === 'D') {
              roleAccess[index].access.delete = true
            }
            else if (eachRights === 'P') {
              roleAccess[index].access.print = true
            }
          })
        })
        const deparment = {
          role,
          departmentType,
          roleAccess
        };
        data ? (deparment.id = data.id) : {};
        deparment.removeIds = deleteRowIds;
        emitData(deparment, "department")
        if (pos === "next") {
          departmentSave(deparment, pos)
        }
        else {
          changePage(pos)
        }
        setDeleteRowIds([])
        // form.resetFields();
        // setDataSource([])
      }
      else {
      }
    });
  };

  const departmentSave = (department, pos) => {
    setButtonLoading(true)
    platformApi
      .post("api/department", department)
      .then(result => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setButtonLoading(false)
            changePage(pos)
            message.success("Department added sucessfully");
          }
          else if (response.code === 400) {
            departmentUpdate(response.data, department)
            setButtonLoading(false)
            changePage(pos)
            // message.error("The department name already exists")
          }
          else {
            setButtonLoading(false)
            message.error("Unable to add department");
          }
        } else {
          setButtonLoading(false)
          message.error("Unable to add department");
        }
      })
      .catch(error => {
        setButtonLoading(false)
        message.error("Unabe to add department");
        console.error("Error on Home Department : ", error);
      });
  }

  const departmentUpdate = (id, department) => {
    setButtonLoading(true)
    platformApi
      .put(`/api/department/${id}`, department)
      .then(result => {
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setButtonLoading(false)
            message.success("Department saved successfully");
          }
          else if (response.code === 400) {
            setButtonLoading(false)
            message.error("The department name already exists")
          }
          else {
            setButtonLoading(false)
            message.error("Unable to save department");
          }
        } else {
          setButtonLoading(false)
          message.error("Unabe to save department");
        }
      })
      .catch(error => {
        setButtonLoading(false)
        message.error("Unabe to save department");
        console.error("Error on Home Department : ", error);
      });
  }

  // to select all Modules
  const addAllTableRow = () => {
    if (submodules.length > dataSource.length) {
      let tmpSubModules = submodules.filter(module => !dataSource.some(data => data.subModule.toLowerCase() === module.title.toLowerCase()))
      let tmpData = []
      tmpSubModules.map(module => {
        let obj = {}
        obj.master = Master.find(master => module.id === master.key).name
        obj.subModule = module.title
        obj.rights = []
        obj.id = ''
        tmpData.push(obj)
      }
      )
      setDataSource([...dataSource, ...tmpData])
      setDeleteRowIds([])
    }
    else {
      message.error("No More fields to add !!!")
    }
  }

  const selectAllActions = (selection) => {
    let tmpData = [...dataSource]
    if (selection.all) {
      const { selected, changeRows } = selection
      tmpData = tmpData.map(data => {
        if (selected) {
          setSelectedRowKeys(tmpData.map(data => data.subModule))
          return { ...data, rights: ['C', 'R', 'U', 'P', 'D'] }
        }
        else {
          setSelectedRowKeys([])
          return { ...data, rights: [] }
        }
      })
    }
    else {
      const { record, selected } = selection
      tmpData = tmpData.map(data => {
        if (record.subModule === data.subModule) {
          if (selected) {
            return { ...data, rights: ['C', 'R', 'U', 'P', 'D'] }
          }
          else {
            return { ...data, rights: [] }
          }
        }
        else {
          return data
        }
      })
    }
    setDataSource(tmpData)
  }

  useEffect(() => {
    let tmpDataSource = [];
    let obj = {}
    obj.master = "Company Master"
    obj.subModule = "Department"
    obj.rights = ['C', 'R', 'U', 'D', 'P']
    obj.id = ''
    tmpDataSource.push(obj)
    setSelectedRowKeys(["Department"])
    setDataSource([...dataSource, ...tmpDataSource])
  }, [])


  const addTableRow = () => {
    if (moduleId && subModuleId) {
      let tmpDataSource = []
      subModuleId.map(id => {
        let obj = {}
        Master.map(eachModule => {
          if (moduleId === eachModule.key) {
            obj.master = eachModule.name
          }
        })
        submodules.map(eachSubModule => {
          if (id === eachSubModule.key) {
            obj.subModule = eachSubModule.title
          }
        })
        obj.rights = []
        obj.id = ''
        tmpDataSource.push(obj)
      })
      setDataSource([...dataSource, ...tmpDataSource])
      setModuleId(null)
      setSubModuleId([])
      setSubModules([])
      form.setFieldsValue({
        module: undefined,
        subModule: undefined
      })
    }
    else {
      message.error('Select both Fields')
    }
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        role: data.role,
      });
      let array = []
      data.roleAccess.map(each => {
        let obj = {}
        Master.some(e => {
          if (e.key === each.master) {
            obj.master = e.name
          }
        })
        submodules.some(e => {
          if (e.key === each.subModule) {
            obj.subModule = e.title
          }
        })
        obj.id = each.id;
        let rights = []
        if (each.access.create) {
          rights.push('C')
        }
        if (each.access.read) {
          rights.push('R')
        }
        if (each.access.update) {
          rights.push('U')
        }
        if (each.access.delete) {
          rights.push('D')
        }
        if (each.access.print) {
          rights.push('P')
        }
        obj.rights = rights
        array.push(obj)
      })
      setDataSource([...array])
    }
  }, [data]);

  const handleModuleChange = (id) => {
    setModuleId(id);
    form.setFieldsValue({
      subModule: undefined
    })
    setSubModules(submodules.filter((obj) => id === obj.id));
  };

  return (
    <div>
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <Item label="Department Name" colon={false} required>
              {getFieldDecorator('role', {
                rules: [{ required: true, message: 'Enter Department Name' }],
              })(<Input
                pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                placeholder="Department Name" />)}
            </Item>
          </Col>
          <Col span={10}>
            <Item label={<Text>Department Type</Text>} colon={false} required>
              {getFieldDecorator("departmentType", {
                rules: [{ required: true, message: "Select Department Type" }]
              })(
                <Select
                  // disabled={!editable}
                  placeholder="Select Department Type"
                  filterOption={filterMethod}
                  showSearch
                  mode="multiple"
                >
                  <Select.Option value="Sales">Sales</Select.Option>
                  <Select.Option value="Service">Service</Select.Option>
                  <Select.Option value="Spares">Spares</Select.Option>
                  <Select.Option value="General">General</Select.Option>
                </Select>
              )}
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: '2vh' }} type="flex" justify="space-between">
          <Col span={24}>
            <AccessControlTable
              setRemoveKeys={setDeleteRowIds}
              removeKeys={deleteRowIds}
              setSelectedRowKeys={setSelectedRowKeys}
              selectedRowKeys={selectedRowKeys}
              selectAll={selectAllActions}
              dataSource={dataSource}
              setDataSource={setDataSource}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: '2vh' }} type="flex" justify="space-between">
          <Col span={6}>
            <Item label={<Text> Select Module</Text>} colon={false} required>
              {getFieldDecorator("module", {
                rules: [{ required: false, message: "Select Module" }],
              })(
                <Select
                  // disabled={!editable}
                  onChange={handleModuleChange}
                  placeholder="Select Module"
                  filterOption={filterMethod}
                  showSearch
                >
                  {Master.map((eachModule) => (
                    <Select.Option key={eachModule.key}>
                      {eachModule.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={6}>
            <Item label={<Text> Select Sub Module</Text>} colon={false} required>
              {getFieldDecorator("subModule", {
                rules: [{ required: false, message: "Select Sub Module" }],
              })(
                <Select
                  // disabled={!editable}
                  placeholder="Select Sub Module"
                  filterOption={filterMethod}
                  showSearch
                  mode="multiple"
                  onChange={(ids) => { setSubModuleId(ids) }}
                >
                  {subModules.map((eachModule) => {
                    if (!dataSource.some(eachData => {
                      return eachData.subModule === eachModule.title
                    }))
                      return (
                        < Select.Option key={eachModule.key} >
                          {eachModule.title}
                        </Select.Option>
                      )
                  })}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={2}>
            <Button type="primary" style={{ marginTop: '6vh' }} onClick={() => { addTableRow() }}>
              Add Row
            </Button>
          </Col>
          <Col span={4}>
            <Button type="primary" style={{ marginTop: '6vh' }} onClick={() => { addAllTableRow() }}>
              Add All Modules
            </Button>
          </Col>
        </Row>
        <Divider />
        <Row gutter={20}>
          <Col span={19} />
          <Col span={3}>
            {/* <Button onClick={() => { handleSubmit("prev") }} style={{ marginTop: '0%' }}>
          Previous
        </Button> */}
          </Col>
          <Col span={2}>
            <Button onClick={() => { handleSubmit("next") }} loading={buttonLoading} type="primary" >
              Next
        </Button>
          </Col>
        </Row>
      </Form>
    </div>
  )
}

const WrappedDepartmentDetails = Form.create({ name: 'department_details' })(
  DepartmentDetails
)

export default WrappedDepartmentDetails;