import axios from "axios";
import { ENDPOINT } from "../constants";

const getAccessToken = () => localStorage.getItem("token");
const setAccessToken = token => localStorage.setItem("token", token);

//and interceptor comes here
const accessToken = getAccessToken();
const platformApi = axios.create({
  baseURL: ENDPOINT,
  headers: {
    "content-Type": "application/json"
  }
});
platformApi.interceptors.request.use(config => {
  const token = getAccessToken();
  if (token) {
    config.headers["x-access-token"] = token;
  }
  return config;
});
platformApi.interceptors.response.use(
  config => config,
  error => {
    console.log(error)
    if (error.response.status === 401) {

      const originalRequest = error.config;
      const API_KEY = "API_KEY";
      const URL = "REPLACE_WITH_YOUR_ENDPOINT";
      // axios
      //   .post(URL, {
      //     headers: {
      //       "content-type": "application/x-www-form-urlencoded"
      //     }
      //   })
      //   .then(res => {
      //     if (res.data.token) {
      //       setAccessToken(res.data.token);
      //       axios.defaults.headers.common["Authorization"] =
      //         "Bearer " + res.data.token;
      //       return axios(originalRequest);
      //     }
      //   });
    }
  }
);
export { platformApi, setAccessToken, ENDPOINT as baseUrl, getAccessToken };
