import React, { useState, useEffect } from 'react'
import { Collapse, Button, Tabs, Table, Input, Select, Divider, Form, message } from 'antd';

const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}
const { TabPane } = Tabs;
import './index.less'
import { baseUrl, platformApi } from '../../../../../../api';
import moment from 'moment'
const ServiceHistory = (props) => {
    const { recvData, editable } = props
    const partItemData = {
        "partNumber": {},
        "jobCode": {},
        "quantity": '',
        "unitRate": '',
        "rate": '',
        "remove": '',
        "code": ''
    }
    const [historyData, setHistoryData] = useState([]);
    const [labourData, setLabourData] = useState([{}]);
    const [partData, setPartData] = useState([{}]);
    const [invoiceData, setInvoiceData] = useState([]);
    useEffect(() => {
        console.log("recvData", recvData)
        let partList = [];
        let codeList = [];
        if (recvData.vehicle) {
            platformApi.post('/api/jobOrder/history', {
                vehicle: recvData.vehicle.id,
                dateTime: recvData.createdAt
            })
                .then((result) => {
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            // console.log("Response", response.data.Invoice)
                            let temp = response.data.History.filter(item => {
                                return item.jobNo != recvData.jobNo
                            })
                            setHistoryData(temp)
                            setInvoiceData(response.data.Invoice)
                            for (let i = 0; i < response.data.Invoice.length; i++) {
                                const updateItemList = [...response.data.Invoice[i].saleItemInvoice];
                                // console.log("updateItemList", updateItemList)
                                for (let j = 0; j < updateItemList.length; j++) {
                                    if (updateItemList[j].jobCode) {
                                        updateItemList[j].jobNo = response.data.Invoice[i].jobOrder?.jobNo
                                        updateItemList[j].jobCode = { ...updateItemList[j].jobCode }
                                        updateItemList[j].rate = (updateItemList[j].quantity * updateItemList[j].unitRate)
                                    }
                                    else {
                                        updateItemList[j].jobNo = response.data.Invoice[i].jobOrder?.jobNo
                                        updateItemList[j].partNumber = { ...updateItemList[j].partNumber }
                                        updateItemList[j].rate = (updateItemList[j].quantity * updateItemList[j].unitRate)
                                    }
                                    if (updateItemList[j].jobCode) {
                                        codeList.push(updateItemList[j])
                                    }
                                    else {
                                        partList.push(updateItemList[j])
                                    }
                                }
                            }
                            // console.log("Lists", codeList, partList)
                            setPartData(partList)
                            setLabourData(codeList)
                        }
                    }
                })
                .catch((err) => {
                    console.error("Couldn't get Job History: ", err);
                    message.error("Couldn't get Job History");
                });
        }
    }, [recvData])

    const renderPartColumns = () => {
        const columns = [
            {
                title: "Details",
                dataIndex: "partNumber",
                key: "partNumber",
                width: 220,
                render: (record, data) => (
                    <Form.Item>
                        <span>{`${record?.partNumber} - ${record?.partName}`}</span>
                    </Form.Item>
                )
            },
            {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
                width: 70,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="quantity"
                            disabled={true}
                            placeholder="quantity"
                            value={record && record.quantity}
                        />
                    </Form.Item>
                )
            },
            {
                title: "Unit rate",
                dataIndex: "unitRate",
                key: "unitRate",
                width: 80,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="unitRate"
                            disabled={true}
                            placeholder="Unit Rate"
                            value={record && record.unitRate}
                        />
                    </Form.Item>
                ),
            },
            {
                title: "Amount",
                dataIndex: "rate",
                key: "rate",
                width: 100,
                render: (data, record) => (
                    <Form.Item>
                        <Input
                            key="rate"
                            disabled={true}
                            placeholder="Amount"
                            value={record && record.rate}
                        />
                    </Form.Item>
                ),
            },
        ];
        return columns
    }

    const renderLabourColumns = () => {
        const columns = [
            {
                title: "Details",
                dataIndex: "jobCode",
                key: "jobCode",
                width: 220,
                render: (record, data) => (
                    <Form.Item>
                        <span>{record?.code}</span>
                    </Form.Item>
                )
            },
            {
                title: "Quantity",
                dataIndex: "quantity",
                key: "quantity",
                width: 100,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="quantity"
                            disabled={true}
                            placeholder="quantity"
                            value={record && record.quantity}
                        />
                    </Form.Item>
                )
            },
            {
                title: "Unit rate",
                dataIndex: "unitRate",
                key: "unitRate",
                width: 100,
                render: (data, record, index) => (
                    <Form.Item>
                        <Input
                            key="unitRate"
                            disabled={true}
                            placeholder="Unit Rate"
                            value={record && record.unitRate}
                        />
                    </Form.Item>
                ),
            },
            {
                title: "Amount",
                dataIndex: "rate",
                key: "rate",
                width: 120,
                render: (data, record) => (
                    <Form.Item>
                        <Input
                            key="rate"
                            disabled={true}
                            placeholder="Amount"
                            value={record && record.rate}
                        />
                    </Form.Item>
                ),
            },
        ];
        return columns
    }


    return (
        <>
            <Collapse
                defaultActiveKey={['1']}
                expandIconPosition="right"
            >
                <Panel header="Show More" key="1" >
                    <>
                        {
                            historyData.length ? historyData.map((item, index) => {
                                return (
                                    <>  
                                        <div className="ServiceHistory" key={index}>
                                            <div>
                                                <h3>Job Number:{item?.jobNo}</h3>
                                                <h3>Servie Type:{item?.serviceType}</h3>
                                                <h3>Kms: {item?.kms}</h3>
                                                <h3>Job Date:{moment(item.createdAt).format("DD/MM/YYYY")}</h3>
                                                <h3>Branch :{item.branch && item.branch.name}</h3>
                                            </div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                                                <div>
                                                    <h3>Customer Name: {item.customer && item.customer.name}</h3>
                                                    <h3>Customer Number: {item.customerPhone}</h3>
                                                    <h3>Register No :{item.vehicle && item.vehicle.registerNo}</h3>
                                                    <h3>Mechanic: {item && item.mechanic ?
                                                        item.mechanic.profile && item.mechanic.profile.employeeName
                                                        : "-"} </h3>
                                                    <Button typ="primary" ><a target="_blank" rel="noopener noreferrer" href={`/api/jobOrder/generatePDF/${item.id}`}>Download PDF</a></Button>
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <h3 style={{ textAlign: 'right', fontWeight: 'bolder', marginRight: "2rem"  }}>Total Invoice: &nbsp;
                                                {
                                                    invoiceData.map((value, index) => {
                                                        if (value.jobOrder.jobNo === item?.jobNo) {
                                                            return (
                                                                <span
                                                                    style={{ textAlign: 'right', fontWeight: 'bolder' }}
                                                                    key={index}
                                                                    name={index}
                                                                >
                                                                    {value.totalInvoice} /-
                                                                </span>
                                                            )
                                                        }
                                                    })
                                                }
                                            </h3>
                                        </div>
                                        {/*  */}
                                        <Collapse 
                                            className="site-collapse-custom-collapse" 
                                            onChange={callback} 
                                        >
                                        <Panel className="site-collapse-custom-panel" header="Details" key="1" >
                                        <Tabs defaultActiveKey="1" style={{ width: "100%" }}>
                                            <TabPane tab="Parts Details" key="1">
                                                <Table
                                                    // scroll={{ y: 200 }}
                                                    pagination={false}
                                                    columns={renderPartColumns()}
                                                    style={{ cursor: "pointer" }}
                                                    // tableLayout="fixed"
                                                    dataSource={partData.filter(val => { return val.jobNo === item?.jobNo })}
                                                />
                                            </TabPane>
                                            <TabPane tab="Labour Detail" key="2">
                                                <Table
                                                    scroll={{ y: 200 }}
                                                    pagination={false}
                                                    columns={renderLabourColumns()}
                                                    style={{ cursor: "pointer" }}
                                                    tableLayout="fixed"
                                                    dataSource={labourData.filter(code => { return code?.jobNo === item?.jobNo })}
                                                />
                                            </TabPane>
                                        </Tabs>
          </Panel>
                                        </Collapse>
                                        
                                        <Divider />
                                    </>
                                )
                            })
                                : null
                        }

                    </>
                </Panel>
            </Collapse>
        </>
    )
}
export default ServiceHistory