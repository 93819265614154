import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { message, Tooltip, Button, Typography } from "antd";
import { LeftOutlined } from '@ant-design/icons';
import { platformApi } from '../../../../api';
import WrappedEstimateForm from './EstimateForm';
const { Title } = Typography;

const Invoice = (props) => {
    const { redirect } = props;
    const [editable, setEditable] = useState(false);
    const [shouldEdit, setShouldEdit] = useState(false)
    const [editData, setEditData] = useState();
    const [modifyType, setModifyType] = useState(false);
    const [jobNo, setJobNo] = useState([]);
    const [partsData, setPartsData] = useState([]);
    const [jobCode, setJobCode] = useState([]);
    const [estimateNo, setEstimateNo] = useState('');
    const location = useLocation();
    const history = useHistory();
    let url = location.pathname;
    let id = url.substring(url.lastIndexOf('/') + 1);


    useEffect(() => {

        let value = (window.location.href);
        let queryData = value?.split("?")
        let val = queryData && queryData.length > 0 ? queryData[1]?.split("=")[1] : null

        if (val && val === "true") {
            setModifyType(true)
            setEditable(() => false);
        } else {
            setModifyType(false)
            setEditable(() => true);
        }

        if(id ==":id"){
            setEditable(() => false);
        }

        getJobNo();
        getPartsDetails();
        getJobCodes();
        getEstimateNo();
        getSelectedEstimate();
    }, []);

    const getJobNo = () => {
        platformApi
            .post("/api/jobOrder/getJobNo", {
                size: 100,
                page: 1,
            })
            .then((res) => {
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setJobNo(response.data);
                    } else {
                        message.error("Failed to fetch Job Order");
                    }
                }
                else {
                    message.error("Failed to fetch Job Order");
                }
            })
            .catch((error) => {
                console.error("Error on Job Order : ", error);
                message.error("Failed to fetch Job Order");
            });
    }
    const getPartsDetails = () => {
        // console.log("2")
        // platformApi
        //     .post(`/api/sparesInventory/branch/get`, {
        //         page: 1,
        //         size: 100,
        //         searchString: ""
        //     })
        //     .then((res) => {
        //         const { data } = res;
        //         if (data.code === 200) {
        //             const { response } = data;
        //             if (response.code === 200) {
        //                 let parts = []
        //                 // console.log("Parts", response.data)
        //                 // for (let item of response.data) {
        //                 //     if (item.phyQuantity > 0) {
        //                 //         parts.push(item.partNo)
        //                 //     }
        //                 // }
        //                 for (let i = 0; i < response.data.length; i++) {
        //                     const element = response.data[i];
        //                     if (element.phyQuantity > 0) {
        //                         parts.push(element.partNo)
        //                     }
        //                 }
        //                 setPartsData(parts)
        //             }
        //         }
        //         else {
        //             message.error("Unable to fetch Parts", 2);
        //         }
        //     })
        //     .catch((error) => {
        //         console.error("Error on Parts Master : ", error);
        //         message.error("Unable to fetch Parts", 2);
        //     });
        platformApi
            .post(`/api/partsMaster/get/`, {
                page: 1,
                size: 100,
            })
            .then((res) => {
                const { data } = res;
                if (data.code === 200) {
                    const { response } = data;
                    if (response.code === 200) {
                        setPartsData(response.data.partsMaster);
                    }
                }
                else {
                    message.error("Unable to fetch Parts", 2);
                }
            })
            .catch((error) => {
                console.error("Error on Parts Master : ", error);
                message.error("Unable to fetch Parts", 2);
            });
    }
    const getJobCodes = () => {
        // console.log("3")
        platformApi
            .post("/api/jobCode/getJobCodes", {
                size: 100,
                page: 1,
            })
            .then((res) => {
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setJobCode(response.data);
                    } else {
                        message.error("Failed to fetch JobCodes");
                    }
                }
                else {
                    message.error("Failed to fetch JobCodes");
                }
            })
            .catch((error) => {
                console.error("Error on JobCodes : ", error);
                message.error("Failed to fetch JobCodes");
            });
    }
    const getEstimateNo = () => {
        platformApi.post("/api/idGenerate/estimate").then((res) => {
            let { data } = res;
            if (data.code === 200) {
                let { response } = data;
                setEstimateNo(response.data);
            } else {
                message.error("Couldn't fetch id");
            }
        })
    };
    const getSelectedEstimate = () => {
        if (id !== ':id') {
            platformApi
                .get(`/api/estimate/${id}`)
                .then((res) => {
                    const { data } = res;
                    if (data.code === 200) {
                        const { response } = data;
                        if (response.code === 200) {
                            setEditData(response.data)
                        } else {
                            message.error("Unable to fetch Estimate Item", 2);
                        }
                    } else {
                        message.error("Unable to fetch Estimate Item", 2);
                    }
                })
                .catch((error) => {
                    console.error("Error on Estimate Item : ", error);
                    message.error("Unable to fetch  Estimate Item", 2);
                });
        }
    }

    return (
        <>
            <Title level={4}>
                <Tooltip placement="topLeft" title={"Back"}>
                    <Button
                        style={{ marginRight: '1rem' }}
                        onClick={() => history.push('/autoadmin/estimate')}
                    >
                        <LeftOutlined />
                    </Button>
                </Tooltip>
                Estimate
            </Title>
            <WrappedEstimateForm
                editable={editable}
                modifyType={modifyType}
                estimateNo={estimateNo}
                jobNo={jobNo}
                setJobNo={setJobNo}
                partsData={partsData}
                setPartsData={setPartsData}
                setJobCode={setJobCode}
                jobCode={jobCode}
                id={id}
                onEdit={(editable) => {
                    setEditable(editable)
                    setModifyType(!editable)
                }}
                editData={editData}
            />
        </>
    )
}
export default Invoice;