import React, { useState, useEffect } from 'react';
import { Modal, Upload, message, Select, Button, Icon, Typography, Row, Col } from 'antd';
import { platformApi } from '../../../../../../api';

export default function CallModel({
  open,
  close
}) {
  return (
    <Modal
      width="50vw"
      title="Session Calling Interface"
      visible={open}
      onCancel={() => {
        close()
      }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div>
        <Row>
          <span>Call ID :</span>
        </Row>
        <Row gutter={2}>
          <Col span={12}>
            Lead Name : <b>Vignesh</b>
          </Col>
          <Col span={9}>
            <Row>
              <Col span={7}>
                Number :
              </Col>
              <Col span={15} style={{ marginTop: '-5px' }}>
                <Select placeholder="Phone Number">

                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={3} style={{ marginTop: '-5px' }}>
            <Button type="primary">
              Call
            </Button>
          </Col>
        </Row>
        <Row>
          <div style={{ marginTop: '12px', border: '1px solid gray' }}>
            <Row gutter={10}>
              <Col span={2}>
                <Icon style={{ fontSize: '25px', padding: '12px' }} onClick={() => { }} type="phone" />
              </Col>
              <Col span={17}>
                <p style={{ marginLeft: '10px',paddingTop:'12px'}}>Call Status : Answered In Progress</p>
                <p style={{marginLeft:'10px'}}>
                  Time Elapsed : 10:56
                </p>
              </Col>
              <Col span={5}>
                <Button style={{top:'24px',left:'30px'}} type="danger">
                  End
                </Button>
              </Col>
            </Row>
          </div>
        </Row>

      </div>
    </Modal>
  )
}
