import React from 'react';
import { Dropdown } from 'antd';
import classNames from 'classnames';
import './index.less';

const HeaderDropdown = ({ overlayClassName: cls, ...restProps }) => (
  <Dropdown overlayClassName={classNames('container', cls)} {...restProps} />
);

export default HeaderDropdown;
