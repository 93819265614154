import React from "react";
import { Modal, Typography, Row, Col, Card } from "antd";

const { Title } = Typography;

export default function ColorSelection({ open, close, color, selectedColor }) {
  console.log({ open, close, color, selectedColor });
  return (
    <Modal
      visible={open}
      title={<Title level={4}>Color Selection</Title>}
      onCancel={close}
      width="70vw"
      style={{ top: "40px", maxHeight: "65vh" }}
      okButtonProps={{ style: { display: "none" } }}
    >
      <div
        style={{
          background: "#ECECEC",
          padding: "30px",
          maxHeight: "65vh",
          overflowY: "scroll",
        }}
      >
        <Row gutter={16}>
          {color.map((element) => {
            return (
              <Col span={8} style={{ marginTop: "3vh" }}>
                <Card
                  onClick={() => {
                    selectedColor(element), close();
                  }}
                  style={{ cursor: "pointer" }}
                  title={(element.code && element.code + " - ") + element.color}
                  bordered={false}
                >
                  <img
                    style={{ marginLeft: '11px', width: "12.8vw", height: "20vh" }}
                    src={element.url}
                  ></img>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Modal>
  );
}
