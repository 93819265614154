import React, { useEffect } from 'react';
import {
    Input,
    Form,
} from 'antd';

import './index.less';
import { GST18, GST28, GST12, GST5 } from '../../../../../utils';

const GSTTable = (props) => {
    let supgst = 0;
    let branchgst = 0;
    const { SGST, CGST, IGST, itemList, GSTRate18, GSTRate28, GSTRate12, GSTRate5, stateName, gst } = props;
    useEffect(() => {
        if (gst && stateName) {
            console.log("StateName", stateName, "Gst", gst)
            supgst = stateName.slice(0, 2);
            branchgst = gst.slice(0, 2);
        }
    }, [gst, stateName])
    const renderGSTData = (itemList, gstValue) => {
        let totalCGST14 = 0;
        let totalSGST14 = 0;
        let totalIGST14 = 0;
        let totalCGST9 = 0;
        let totalSGST9 = 0;
        let totalIGST9 = 0;
        let totalCGST6 = 0;
        let totalSGST6 = 0;
        let totalIGST6 = 0;
        let totalCGST2 = 0;
        let totalSGST2 = 0;
        let totalIGST2 = 0;

        for (let item of itemList) {
            if (item.gstRate === GST28) {
                totalCGST14 = (parseFloat(totalCGST14) + parseFloat(item.cgstAmount)).toFixed(2);
                totalSGST14 = (parseFloat(totalSGST14) + parseFloat(item.sgstAmount)).toFixed(2);
                totalIGST14 = (parseFloat(totalIGST14) + parseFloat(item.igstAmount)).toFixed(2);
            }
            if (item.gstRate === GST18) {
                totalCGST9 = (parseFloat(totalCGST9) + parseFloat(item.cgstAmount)).toFixed(2);
                totalSGST9 = (parseFloat(totalSGST9) + parseFloat(item.sgstAmount)).toFixed(2);
                totalIGST9 = (parseFloat(totalIGST9) + parseFloat(item.igstAmount)).toFixed(2);
            }
            if (item.gstRate === GST12) {
                totalCGST6 = (parseFloat(totalCGST6) + parseFloat(item.cgstAmount)).toFixed(2);
                totalSGST6 = (parseFloat(totalSGST6) + parseFloat(item.sgstAmount)).toFixed(2);
                totalIGST6 = (parseFloat(totalIGST6) + parseFloat(item.igstAmount)).toFixed(2);
            }
            if (item.gstRate === GST5) {
                totalCGST2 = (parseFloat(totalCGST2) + parseFloat(item.cgstAmount)).toFixed(2);
                totalSGST2 = (parseFloat(totalSGST2) + parseFloat(item.sgstAmount)).toFixed(2);
                totalIGST2 = (parseFloat(totalIGST2) + parseFloat(item.igstAmount)).toFixed(2);
            }
        }
        return (
            <>
                {

                    stateName && stateName?.slice(0, 2) === gst && gst?.slice(0, 2) ? (

                        <Form.Item
                            className="form-element"
                            label="CGST"
                            name="cgst"
                        >
                            <div className="gst">
                                <span>{gstValue}%</span>
                                <Input
                                    style={{marginLeft: "10px"}}
                                    placeholder="CGST"
                                    disabled={true}
                                    value={
                                        (gstValue === (GST18 / 2) ? totalCGST9 : 0) ||
                                        (gstValue === (GST28 / 2) ? totalCGST14 : 0) ||
                                        (gstValue === (GST12 / 2) ? totalCGST6 : 0) ||
                                        (gstValue === parseFloat(GST5 / 2) ? totalCGST2 : 0)
                                    }
                                />
                            </div>
                        </Form.Item>
                    )
                        :
                        (
                            <Form.Item
                                className="form-element"
                                label="CGST"
                                name="cgst"
                            >
                                <div className="gst">
                                    <span>{gstValue}%</span>
                                    <Input
                                    style={{marginLeft: "10px"}}
                                        placeholder="CGST"
                                        disabled={true}
                                        value={
                                            (gstValue === (GST18 / 2) ? totalCGST9 : 0) ||
                                            (gstValue === (GST28 / 2) ? totalCGST14 : 0) ||
                                            (gstValue === (GST12 / 2) ? totalCGST6 : 0) ||
                                            (gstValue === parseFloat(GST5 / 2) ? totalCGST2 : 0)
                                        }
                                    />
                                </div>
                            </Form.Item>)
                }

                {
                    stateName && stateName?.slice(0, 2) === gst && gst?.slice(0, 2) ?
                        (

                            <Form.Item
                                className="form-element"
                                label="SGST"
                                name="sgst"
                            >
                                <div className="gst">
                                    <span>{gstValue}%</span>
                                    <Input
                                    style={{marginLeft: "10px"}}
                                        disabled={true}
                                        placeholder="SGST"
                                        value={
                                            (gstValue === (GST18 / 2) ? totalSGST9 : 0) ||
                                            (gstValue === (GST28 / 2) ? totalSGST14 : 0) ||
                                            (gstValue === (GST12 / 2) ? totalSGST6 : 0) ||
                                            (gstValue === parseFloat(GST5 / 2) ? totalSGST2 : 0)
                                        }
                                    />
                                </div>
                            </Form.Item>
                        )
                        :
                        (

                            <Form.Item
                                className="form-element"
                                label="SGST"
                                name="sgst"
                            >
                                <div className="gst">
                                    <span>{gstValue}%</span>
                                    <Input
                                    style={{marginLeft: "10px"}}
                                        disabled={true}
                                        placeholder="SGST"
                                        value={
                                            (gstValue === (GST18 / 2) ? totalSGST9 : 0) ||
                                            (gstValue === (GST28 / 2) ? totalSGST14 : 0) ||
                                            (gstValue === (GST12 / 2) ? totalSGST6 : 0) ||
                                            (gstValue === parseFloat(GST5 / 2) ? totalSGST2 : 0)
                                        }
                                    />
                                </div>
                            </Form.Item>
                        )
                }
                {
                    stateName && stateName?.slice(0, 2) !== gst && gst?.slice(0, 2) &&
                    (
                        <Form.Item
                            className="form-element"
                            label="IGST"
                            name="igst"
                        >
                            <div className="gst">
                                <span>{IGST}%</span>
                                <Input
                                    style={{marginLeft: "10px"}}
                                    disabled={true}
                                    placeholder="IGST"
                                    value={
                                        (gstValue === (GST18 / 2) ? totalIGST9 : 0) ||
                                        (gstValue === (GST28 / 2) ? totalIGST14 : 0) ||
                                        (gstValue === (GST12 / 2) ? totalIGST6 : 0) ||
                                        (gstValue === parseFloat(GST5 / 2) ? totalIGST2 : 0)
                                    }
                                />
                            </div>
                        </Form.Item>
                    )
                }
            </>
        )
    }
    return (
        <div>
            {GSTRate18 && renderGSTData(itemList, (GST18 / 2))}
            {GSTRate28 && renderGSTData(itemList, (GST28 / 2))}
            {GSTRate12 && renderGSTData(itemList, (GST12 / 2))}
            {GSTRate5 && renderGSTData(itemList, parseFloat(GST5 / 2))}
        </div>

    )
}
export default GSTTable