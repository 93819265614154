import React from "react";
import { Table, Typography, Button, Popconfirm } from "antd";

const { Text } = Typography;

export default function AccessoriesTable() {
  const dataSource = [
    { id: "1", accessoryName: "Save", discount: 10, price: 1000 },
    { id: "2", accessoryName: "Save", discount: 10, price: 1000 },
    { id: "3", accessoryName: "Save", discount: 10, price: 1000 },
    { id: "3", accessoryName: "Save", discount: 10, price: 1000 },
    { id: "3", accessoryName: "Save", discount: 10, price: 1000 },
  ];
  const Columns = [
    {
      title: <Text strong>Accessory</Text>,
      key: "accessoryName",
      dataIndex: "accessoryName",
      render: (data) => <span>{data}</span>,
    },
    {
      title: <Text strong>Discount</Text>,
      key: "discount",
      dataIndex: "discount",
      render: (data) => <span>{data}</span>,
    },
    {
      title: <Text strong>Price</Text>,
      key: "price",
      dataIndex: "price",
      render: (data) => (
        <span>
          {data}
          <Popconfirm
            title="Remove this accessory?"
            okText="Yes"
            cancelText="No"
          >
            <Button shape="circle" type="link" icon="minus" size="small" />
          </Popconfirm>
        </span>
      ),
    },
  ];
  return (
    <Table
      size="small"
      pagination={false}
      rowKey={(record) => record.id}
      dataSource={dataSource}
      scroll={{
        y: 120,
      }}
      columns={Columns}
    />
  );
}
