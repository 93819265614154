import React, { useState, useEffect } from 'react';
import { Modal, Upload, message, Select, Button, Icon, Typography, Row, Col } from 'antd';
import { platformApi } from '../../../../../../api';
import TextArea from 'antd/lib/input/TextArea';

export default function MessageModel({
  open,
  close
}) {
  return (
    <Modal
      width="50vw"
      title="Session Message Interface"
      visible={open}
      onCancel={() => {
        close()
      }}
      okText='Send'
    >
      <div  style={{height:'38vh'}}>
        <Row>
          <span>Message ID :</span>
        </Row>
        <Row gutter={10} style={{top:'10px'}}>
          <Col span={12}>
            <Row>
              <Col span={7}>
                Template :
              </Col>
              <Col span={15} style={{ marginTop: '-5px' }}>
                <Select placeholder="Select Template">

                </Select>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row>
              <Col span={7}>
                Number :
              </Col>
              <Col span={15} style={{ marginTop: '-5px' }}>
                <Select placeholder="Phone Number">

                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{top:'20px'}}>
            <TextArea rows={6}>

            </TextArea>
        </Row>
        <Row style={{top:'35px'}}>
          <Button>
            Customise
          </Button>
        </Row>

      </div>
    </Modal>
  )
}