import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import { Table, Divider, Popconfirm, Col, Row, Steps, Button, message, Popover,Typography } from "antd";
import moment from "moment";
import './index.less'
import { InfoCircleOutlined } from "@ant-design/icons";
const { Step } = Steps;
const {Text} =  Typography;
const EstimateTable = (props) => {
    const {
        history,
        gst,
        pagination,
        dataSource,
        openModal,
        spinner,
        modify,
        deleteData,
        deleteAccess,
        printAccess
    } = props;
    const column = [
        {
            // title: "Action",
            render: (data, key) => (
                <div className="box" style={{ backgroundColor: 'white' }} key={1} >
                    <Row key={1}>
                        <Col span={8} style={{ borderRight: '0.1px solid rgba(0, 0, 0, 0.1)', padding: '1rem' }}>
                            <table>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Estimate No</td>
                                        <td>:</td>
                                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.estimateNo ? data.estimateNo : ""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Job. No</td>
                                        <td>:</td>
                                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.jobOrder && data.jobOrder.jobNo ? data.jobOrder.jobNo : ""}</td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Model</td>
                                        <td>:</td>
                                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.jobOrder && data.jobOrder.vehicle?.vehicle.modelName ? data.jobOrder.vehicle.vehicle.modelName : ""}</td>
                                    </tr>
                                </thead>
                            </table>
                        </Col>
                        <Col span={16}>
                            <div style={{ margin: '1rem' }}>
                                <Row>
                                    <Col span={10}>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Customer</td>
                                                    <td>:</td>
                                                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.jobOrder.customer && data.jobOrder.customer.name ? data.jobOrder.customer.name : ""}</td>
                                                </tr>
                                                {/* <tr>
                                                    <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Service Type</td>
                                                    <td>:</td>
                                                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.serviceType && data.serviceType}</td>
                                                </tr> */}
                                                <tr>
                                                    <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Mechanic</td>
                                                    <td>:</td>
                                                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.jobOrder.mechanic && data.jobOrder.mechanic.profile ? data.jobOrder.mechanic.profile.employeeName : "-"}</td>
                                                </tr>
                                                <tr>
                                                    <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Supervisor</td>
                                                    <td>:</td>
                                                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>John </td>
                                                </tr>
                                                {/* <tr>
                                                    <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Kms</td>
                                                    <td>:</td>
                                                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.kms ? data.kms : "-"}</td>
                                                </tr> */}
                                            </thead>
                                        </table>
                                        <Row>
                                            <div style={{ border: '0.1px solid rgba(0, 0, 0, 0.3)', borderRadius: '.2rem', margin: '.5rem' }}>
                                                <Row>
                                                    <h1 style={{ textAlign: 'center', marginTop: '.5rem' }}>Status :{data.estimateStatus && data.estimateStatus}</h1>
                                                </Row>
                                            </div>
                                        </Row>
                                    </Col>
                                    <Col span={14}>
                                        <Row>
                                            <Col span={12}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Date</td>
                                                            <td>:</td>
                                                            <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.createdAt && moment(new Date()).format("DD-MM-YYYY")}</td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </Col>
                                            <Col span={12}>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <td style={{ textAlign: 'right', fontWeight: 'bolder' }}>Time</td>
                                                            <td>:</td>
                                                            <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.createdAt && new Date().toLocaleTimeString()}</td>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </Col>
                                        </Row>
                                        <div style={{ border: '0.1px solid rgba(0, 0, 0, 0.3)', borderRadius: '.2rem', margin: '3.4rem' }}>
                                            <Row>
                                                <h1 style={{ textAlign: 'center', marginTop: '.5rem' }}>Estimate Amount:{data.estTotalAmount ? data.estTotalAmount : "-"} &nbsp;
                                                    <Popover placement="bottom" content={
                                                        <div>
                                                            {data.labourCharge > 0 && <p>Labour Charge:{data.labourCharge}</p>}
                                                            {data.partCharge > 0 && <p>Part Charge:{data.partCharge}</p>}
                                                            {data.consumableCharge > 0 && <p>Consumable Charge:</p>}
                                                            {data.cgstAmount && data.sgstAmount && <p>Tax:{data.cgstAmount + data.sgstAmount}</p>}
                                                            {data.totalDiscount > 0 && <p>Discount:{data.totalDiscount}</p>}
                                                        </div>
                                                    }>
                                                        <InfoCircleOutlined />
                                                    </Popover>
                                                </h1>
                                            </Row>
                                        </div>

                                        <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '.5rem' }}>
                                            { modify?
                                            data.estimateStatus === "PENDING" &&
                                                <span
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        history.push({
                                                            pathname: `/autoadmin/estimate/${data.id}?edit=true`,
                                                            state: {
                                                                gst: gst
                                                            }
                                                        })
                                                    }}
                                                    className="linkylink"
                                                    style={{marginRight:"5px"}}
                                                >
                                                    Modify
                                                </span>
                                                :
                                                <span></span>
                                            }
                                            <Divider type="vertical" />
                                            { printAccess?
                                                data.estimatePdf &&
                                                (
                                                    <a className="linkylink" target="_blank" rel="noopener noreferrer" href={data.estimatePdf} onClick={(event) => {
                                                        event.stopPropagation();
                                                    }}>
                                                        Download PDF
                                                    </a>

                                                )
                                                :
                                                <span></span>
                                            }
                                            <Divider type="vertical" />
                                            { deleteAccess?
                                            <Popconfirm
                                                title="Do you want to delete this Job Order?"
                                                okText="Yes"
                                                cancelText="No"
                                                onCancel={(event) => {
                                                    event.stopPropagation();
                                                }}
                                                onConfirm={(event) => {
                                                    event.stopPropagation();
                                                    deleteData(data.id); (data.id && data.id);
                                                }}
                                            >
                                                <span
                                                    onClick={(event) => event.stopPropagation()}
                                                    className="linkylink"
                                                >
                                                    Delete
                                                </span>
                                            </Popconfirm>
                                            :
                                            <span></span>
                                            }
                                            {
                                                 
                                                    !modify && !deleteAccess && !printAccess
                                                      ?
                                                      <Text strong disabled>No Actions Given</Text>
                                                      :
                                                      <span></span>
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </div >
            ),
        },
    ];

    return (
        <div id="estimate">
            <Table
                rowKey={(record) => record.id}
                pagination={pagination}
                columns={column}
                tableLayout="auto"
                style={{ cursor: "pointer" }}
                dataSource={dataSource}
                onRow={(record) => ({
                    onClick: () => {
                        history.push({
                            pathname: `/autoadmin/estimate/${record.id}`,
                            state: {
                                gst: gst
                            }
                        })
                    },
                })}
                loading={spinner}
            />
        </div>
    )
}
export default withRouter(EstimateTable);