import React, { useState, useEffect } from "react";
import { Form, Modal, Row, Input, Select, Button, Col, Typography } from "antd";
import { platformApi } from "../../../../api";

import "./vehicleModal.less";
import ServicesTable from "./ServicesTable";
import PriceTable from "./PriceTable";
import ImagesViewModal from "./ImagesViewModal";
import FilesViewModal from "./FilesViewModal";
import moment from "moment";
import { formatValue } from "../../../../utils";

const { Item } = Form;

const { Option } = Select;
const { Text } = Typography;

const VehicleModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);

  const [priceTableLimit, setPriceTableLimit] = useState(0);

  const [HSN, setHSN] = useState([]);
  const [Manufacturer, setManufacturer] = useState([]);
  const [file, setFile] = useState([]);
  const [addImageFlag, setAddImageFlag] = useState(false);
  const [addFileFlag, setAddFileFlag] = useState(false);
  const [serviceCount, setServiceCount] = useState(0);
  const [services, setService] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [image, setImages] = useState([]);
  const [serviceDelete, setServiceDelete] = useState(null);
  const [error, setError] = useState({});
  const [vehicleImgData, setVehicleImgData] = useState({});
  const [vehicleFileData, setVehicleFileData] = useState({});

  const filterMethod = (input, option) =>
    option.props.children.toString().indexOf(input) >= 0;

  useEffect(() => {
    platformApi
      .post("/api/options/get", { module: "hsns", column: "code", size: 0 })
      .then((res) => {
        // console.log(res.data.response)
        setHSN(res.data.response);
      });
    platformApi.get("/api/manufacturer/branch").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setManufacturer(response.data);
          form.setFieldsValue({
            manufacturerName:
              response.data && response.data[0] && response.data[0].id,
          });
        }
      }
    });
  }, []);

  const addImages = (data) => {
    setImages(data);
  };

  const handleServiceTable = (event) => {
    if (data) {
      let array = [];
      data.services.map(() => {
        array.push(eachService.id);
      });
      setServiceDeleeachServicete(array);
    }
    const count = event.target.value;
    setServiceCount(parseInt(count));
  };

  const { form, open, close, data, modify } = props;
  const { getFieldDecorator } = form;

  const vehicleCategory = [
    { key: "SCOOTER", title: "Scooter" },
    { key: "MOTORCYCLE", title: "Motorcycle" },
  ];

  const vehicleStatus = [
    { key: "AVAILABLE", title: "Available" },
    { key: "NOTAVAILABLE", title: "Not Available" },
  ];

  const priceTableData = [
    { key: "showroomPrice", title: "Ex Showroom" },
    { key: "registrationFee", title: "Registration" },
    { key: "roadTax", title: "Road Tax" },
    { key: "handlingCharges", title: "Handling Charges" },
    { key: "warrantyPrice", title: "Warranty Price" },
  ];

  const insuranceData = [
    { title: "1+5", key: "insurance1plus5" },
    { title: "5+5", key: "insurance5plus5" },
    { title: "1+5 Zero Dep", key: "insurance1plus5ZD" },
    { title: "5+5 Zero Dep", key: "insurance5plus5ZD" },
  ];
  
  useEffect(() => { }, [Manufacturer]);

  const getCurrentPriceIndex = (prices) => {
    if (prices.length > 0) {
      let currentDate = moment();
      let index = prices.findIndex(
        (price) =>
          moment(price.priceValidFrom) <= currentDate &&
          currentDate <= moment(price.priceValidTill)
      );
      return index;
    }
    return 0;
  };

  useEffect(() => {
    setEditable(props.editable);
    if (data) {
      let index = getCurrentPriceIndex(data.price);
      if (data.price && data.price[index]) {
        let prices = [],
          list = [],
          total = 0;
        priceTableData.forEach((priceData) => {
          if (data.price[index][priceData.key]) {
            prices.push({
              title: priceData.title,
              price: data.price[index][priceData.key],
            });
            total += data.price[index][priceData.key];
          }
        });
        setPriceTableLimit(prices.length + 2);
        insuranceData.forEach((insurance) => {
          if (data.price[index][insurance.key]) {
            list.push(
              ...prices,
              {
                title: insurance.title,
                price: data.price[index][insurance.key],
              },
              {
                title: "On Road Price",
                price: total + data.price[index][insurance.key],
              }
            );
          }
        });
        setPriceList(list);
      }
      setFile([...data.file]);
      setImages([...data.image]);
      setService([...data.services]);
      setServiceCount(data.noOfServices);
      form.setFieldsValue({
        name: data.modelName,
        manufacturerName: data.manufacturer && data.manufacturer.id,
        modelCode: data.modelCode,
        category: data.category,
        status: data.vehicleStatus,
        ServiceDays: data.ServiceDays,
        noServices: data.noOfServices,
        hsn: data.hsn && data.hsn.id,
        warrantyPeriod: data.warrentyPeriodMonths,
        kmsWarrantyPeriod: data.warrentyPeriodKm,
        // amount: data.currentPrice,
        serviceIntervalTime: data.serviceIntervalTime,
        serviceIntervalKms: data.serviceIntervalKm,
        ServiceKms: data.ServiceKms,
      });
    }
  }, [data, props.editable]);
  
  useEffect(() => {
    if (!data) {
      form.setFieldsValue({
        manufacturerName: Manufacturer[0] && Manufacturer[0].id,
      });
    }
  }, [open]);

  /*----------to delete file || image service------- */
  const deleteService = (id, type) => {
    if (id)
      platformApi
        .delete(`/api/vehicleMaster/${type}/${id}`)
        .then(() => message.success("Deleted image"));
  };

  /*------------------helper -------------*/
  /**
   * Removing base url from the file data
   * @param {object} file
   * @returns {object}
   */
  const removeBase64Url = (file) => {
    let fs = file.map((val) => {
      return { ...val, url: val.url.includes("https://") ? val.url : "" };
    });
    return fs;
  };

  /**
   * Coverting state object to formData to addVehicle
   * @param {object} data
   * @returns {fromData}
   */
  const objToFormData = (data) => {
    let formData = new FormData();
    Object.keys(data).map((key) => formData.append(key, data[key]));
    return formData;
  };

  /*------------------------------------ To handle Image Foramdata Manipulation  --------------------*/
  const addImage = (data) => {
    // console.log("Add Data", image, data)
    setImages([...image, data]);
  };

  const editImage = (data, id) => {
    // console.log("prev", image)
    // console.log("EditData", data, id)
    image.some((obj, index) => {
      if (obj.id === id) {
        image[index] = data;
      }
    });
    setImages(image)
  };

  const deleteImage = (item, index) => {
    let filteredImage = null;
    if (item.id) {
      if (!item.add) {
        deleteService(item.id, "image");
      }
      filteredImage = image.filter((data) => data.code !== item.code);
    }
    else {
      filteredImage = image.splice(index)
    }
    setImages(filteredImage);

    // To remove color infor obj from vehileImgstate to be cnvrtd as form data
    let vid = vehicleImgData;
    delete vid[item.color];
    setVehicleImgData(vid);
  };

  // File from Image Modal handled in state here and finally converted to formData
  const setImageUploadData = (data) => {
    let obj = { ...vehicleImgData, ...data };
    setVehicleImgData(obj);
  };

  /*---------------------------------------- To handle File Foramdata Manipulation--------------------------------*/
  const addFile = (data, type) => {
    let resetFile = [...file],
      resetFlag = false;
    console.log("Before_resetFile", resetFile)
    file.map((val, index) => {
      // checking obj with appended type$ and replacing the file & before that old file deleted using id

      // if (val.name.includes(`${type}$`)) {
      //   val.id && deleteService(val.id, "file");
      //   resetFlag = true;
      //   resetFile[index] = data;
      // }
    });

    if (!resetFlag) resetFile.push(data);
    console.log("after_resetFile", resetFile)
    setFile([...resetFile]);
  };

  const deleteFile = (item) => {
    const filteredFile = file.filter((data) => data.name !== item.name);
    setFile(filteredFile);
    // To remove file infor obj from vehileImgstate to be cnvrtd as form data
    let nameTypSplit = item.name.split("$")[1];
    let vfd = vehicleFileData;
    delete vfd[nameTypSplit];
    setVehicleFileData(vfd);
  };
  // File from File Modal handled in state here and finally converted to formData
  const setFileUploadData = (data) => {
    let obj = {
      ...vehicleFileData,
      ...data,
    };
    setVehicleFileData(obj);
  };
  /* - - - - - - - xxxxxxxxxxxxxxxxx- - - - - - - */

  const getServiceDetails = (services) => {
    setService(services);
  };
  const dropdownSearch = (search) => {
    platformApi
      .post("/api/options/get", {
        module: search.module,
        column: search.column,
        searchString: search.value,
        fields: search.fields,
      })
      .then((res) => {
        search.setValue(res.data.response);
      });
  };

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        setLoading(true);
        const modelName = form.getFieldValue("name");
        const manufacturer = form.getFieldValue("manufacturerName");
        const modelCode = form.getFieldValue("modelCode");
        const warrentyPeriodMonths = parseInt(
          form.getFieldValue("warrantyPeriod")
        );
        const warrentyPeriodKm = parseInt(
          form.getFieldValue("kmsWarrantyPeriod")
        );
        const noOfServices = parseInt(form.getFieldValue("noServices"));
        const category = form.getFieldValue("category");
        const vehicleStatus = form.getFieldValue("status");
        const serviceDays = parseInt(form.getFieldValue("ServiceDays"));
        const serviceKm = parseInt(form.getFieldValue("ServiceKms"));
        // const currentPrice = parseFloat(form.getFieldValue("amount"));
        const serviceIntervalKm = parseInt(
          form.getFieldValue("serviceIntervalKms")
        );
        const serviceIntervalTime = parseInt(
          form.getFieldValue("serviceIntervalTime")
        );
        const hsn = form.getFieldValue("hsn");
        const Vehicle = {
          modelName,
          manufacturer,
          category,
          modelCode,
          serviceDays,
          serviceKm,
          vehicleStatus,
          serviceIntervalKm,
          serviceIntervalTime,
          warrentyPeriodMonths,
          warrentyPeriodKm,
          noOfServices,
          hsn,
          // currentPrice,
          file: removeBase64Url(file),
          image: removeBase64Url(image),
          services,
        };
        data ? (Vehicle.id = data.id) : {};
        let fd = objToFormData({ ...vehicleImgData, ...vehicleFileData });
        fd.append("dataObj", JSON.stringify(Vehicle));
        if (serviceDelete) {
          platformApi
            .post("/api/vehicleMaster/deleteService", { ids: serviceDelete })
            .catch((err) => console.error(err));
        }

        form.resetFields();
        // setLoading(false);
        setService([]);
        setServiceCount(0);
        setPriceList([]);
        setImages([]);
        setFile([]);
        setServiceDelete(null);
        setVehicleImgData({});
        // close();
        // clearFields();
        props.emitData(fd);
      }
    });
  };

  const getTrimmedUrl = (url) => {
    let pieces = url.split("/");
    if (pieces[3] != "nandiyamaha")
      url = pieces.reduce((prev, current, index) => {
        if (index > 2) return prev + "/" + current;
        return "";
      });
    else
      url = pieces.reduce((prev, current, index) => {
        if (index > 3) return prev + "/" + current;
        return "";
      });
    return url.substring(1);
  };

  const verifyUploads = () => {
    if (data) {
      if (data.image.length < image.length) {
        image.forEach((imageFile) => {
          if (
            data.image.findIndex((nextData) => nextData.url === imageFile.url) <
            0
          ) {
            platformApi.post("/api/upload/deleteFile", {
              url: getTrimmedUrl(imageFile.url),
            });
          }
        });
      }
    } else {
      if (image.length > 0) {
        image.forEach((imageFile) =>
          platformApi.post("/api/upload/deleteFile", {
            url: getTrimmedUrl(imageFile.url),
          })
        );
      }
    }
  };

  const clearFields = () => {
    form.resetFields();
    setError({})
    setLoading(false);
    setService([]);
    setServiceCount(0);
    setPriceList([]);
    setImages([]);
    setFile([]);
    setServiceDelete(null);
    setVehicleImgData({});
  };

  return (
    <Modal
      key={props.key}
      title="Vehicle Master"
      visible={open}
      okText={editable ? "Save" : "Modify"}
      onCancel={() => {
        verifyUploads();
        clearFields();
        close();
      }}
      okButtonProps={{ loading, disabled: !editable && !modify }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      wrapClassName="vehicle-creation-modal"
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={15}>
            <Row type="flex" justify="space-between">
              <Col span={10}>
                <Item
                  validateStatus={error.NAME && error.NAME.type}
                  help={error.NAME && error.NAME.message}
                  label="Model Name"
                  colon={false}
                  required
                >
                  {getFieldDecorator("name", {
                    rules: [{ required: true, message: "Enter Model Name" }],
                  })(
                    <Input
                      pattern="^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9 ])?[a-zA-Z0-9]*)*$"
                      onKeyUp={(e) => {
                        form.setFieldsValue({
                          name: formatValue(e),
                        })
                      }
                      }
                      onInput={(event) => {
                        if (event.target.value.length === 0) {
                          delete error.NAME
                        }
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            NAME: {
                              type: "error",
                              message: "Enter Valid Model Name",
                            },
                          });
                        } else {
                          delete error.NAME;
                          setError(error);
                        }
                      }}
                      disabled={!editable}
                      placeholder="Model Name"
                    />
                  )}
                </Item>
              </Col>

              <Col span={10}>
                <Item label="Category" colon={false}>
                  {getFieldDecorator("category", {
                    rules: [{ required: true, message: "Select a Category" }],
                  })(
                    <Select disabled={!editable} showAction={["click", "focus"]} placeholder="Category">
                      {vehicleCategory.map((category) => (
                        <Option value={category.key} key={category.title}>
                          {category.title}
                        </Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={10}>
                <Item label="Manufacturer Name" colon={false} required>
                  {getFieldDecorator("manufacturerName", {
                    rules: [
                      { required: true, message: "Enter Manufacturer Name" },
                    ],
                  })(
                    <Select
                      disabled={!editable}
                      placeholder="Manufacturer"
                      filterOption={null}
                      showSearch
                      showAction={["click", "focus"]}
                      onSearch={(e) => {
                        const search = {
                          module: "manufacturers",
                          column: "name",
                          value: e,
                          setValue: setManufacturer,
                        };
                        dropdownSearch(search);
                      }}
                    >
                      {Manufacturer.map((code, index) => (
                        <Select.Option key={code.id}>{code.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={10}>
                <Item label="Vehicle Status" colon={false}>
                  {getFieldDecorator("status", {
                    rules: [
                      { required: true, message: "Select Vehicle Status" },
                    ],
                  })(
                    <Select disabled={!editable} showAction={["click", "focus"]} placeholder="Vehicle Status">
                      {vehicleStatus.map((category) => (
                        <Option key={category.key}>{category.title}</Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={10}>
                <Item
                  validateStatus={error.CODE && error.CODE.type}
                  help={error.CODE && error.CODE.message}
                  label="Model Code"
                  colon={false}
                  required
                >
                  {getFieldDecorator("modelCode", {
                    rules: [{ required: true, message: "Enter Model Code" }],
                  })(
                    <Input
                      pattern="^[a-zA-Z0-9]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          modelCode: formatValue(e, "toUpperCase"),
                        })
                      }
                      onInput={(event) => {
                        if (event.target.value.length === 0) {
                          delete error.CODE
                        }
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            CODE: {
                              type: "error",
                              message: "Enter Valid Model Code",
                            },
                          });
                        } else {
                          delete error.CODE;
                          setError(error);
                        }
                      }}
                      disabled={!editable}
                      placeholder="Model Code"
                    />
                  )}
                </Item>
              </Col>
              <Col span={10}>
                <Item
                  label="Service Interval Days After Warranty"
                  colon={false}
                >
                  {getFieldDecorator("serviceIntervalTime", {
                    rules: [
                      {
                        required: false,
                        message: "Enter Service Interval Days After Warranty",
                      },
                    ],
                  })(
                    <Input
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Service Interval Time"
                      onKeyUp={(e) => {
                        form.setFieldsValue({
                          serviceIntervalTime: formatValue(e, "onlyNo"),
                        });
                      }}
                    />
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={10}>
                <Item label="Warranty Period in Days" colon={false}>
                  {getFieldDecorator("warrantyPeriod", {
                    rules: [
                      {
                        required: false,
                        message: "Enter Warranty Period in Days",
                      },
                    ],
                  })(
                    <Input
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Warranty Period"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          warrantyPeriod: formatValue(e, "onlyNo"),
                        })
                      }
                    />
                  )}
                </Item>
              </Col>
              <Col span={10}>
                <Item label="Service Interval KMs After Warranty" colon={false}>
                  {getFieldDecorator("serviceIntervalKms", {
                    rules: [
                      {
                        required: false,
                        message: "Enter Service Interval Kms",
                      },
                    ],
                  })(
                    <Input
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Service Interval KMs After Warranty"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          serviceIntervalKms: formatValue(e, "onlyNo"),
                        })
                      }
                    />
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={10}>
                <Item label="Warranty Period in KMs" colon={false}>
                  {getFieldDecorator("kmsWarrantyPeriod", {
                    rules: [
                      {
                        required: false,
                        message: "Enter Warranty Period in KMS",
                      },
                    ],
                  })(
                    <Input
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Warranty Period in KMs"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          kmsWarrantyPeriod: formatValue(e, "onlyNo"),
                        })
                      }
                    />
                  )}
                </Item>
              </Col>
              <Col span={10}>
                <Item label="No. of Services in Warranty" colon={false}>
                  {getFieldDecorator("noServices", {
                    rules: [
                      {
                        required: false,
                        message: "Enter No. of Services in Warranty",
                      },
                    ],
                  })(
                    <Input
                      onInput={handleServiceTable}
                      disabled={!editable}
                      maxLength={3}
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="No. of Services in Warranty"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          noServices: formatValue(e, "onlyNo"),
                        })
                      }
                    />
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={10}>
                <Item label="HSN" colon={false} required>
                  {getFieldDecorator("hsn", {
                    rules: [{ required: true, message: "Select HSN" }],
                  })(
                    <Select
                      showSearch
                      filterOption={null}
                      disabled={!editable}
                      placeholder="HSN"
                      showAction={["click", "focus"]}
                      onSearch={(e) => {
                        const search = {
                          module: "hsns",
                          column: "code",
                          value: e,
                          setValue: setHSN,
                        };
                        dropdownSearch(search);
                      }}
                    >
                      {HSN.map((code, index) => (
                        <Select.Option key={code.id}>{code.code}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
            </Row>
            <Row>
              <Item label="Service Table" colon={false}>
                <ServicesTable
                  emitData={getServiceDetails}
                  editable={editable}
                  dataSource={services}
                  count={serviceCount >= 0 ? serviceCount : 0}
                />
              </Item>
            </Row>
          </Col>
          <Col span={8}>
            <Row type="flex" justify="space-between">
              <Col span={12}>
                <Button
                  disabled={
                    !form.getFieldValue("name") ||
                    !form.getFieldValue("modelCode")
                  }
                  icon="file-image"
                  onClick={() => setAddImageFlag(true)}
                  shape="round"
                >
                  Images
                </Button>
              </Col>
              <Col span={12}>
                <Button
                  disabled={
                    !form.getFieldValue("name") ||
                    !form.getFieldValue("modelCode")
                  }
                  icon="file-pdf"
                  onClick={() => setAddFileFlag(true)}
                  shape="round"
                >
                  Files
                </Button>
              </Col>
            </Row>

            {priceList.length > 0 && (
              <Item label="Price Table" colon={false}>
                <PriceTable
                  pagination={{ pageSize: priceTableLimit }}
                  dataSource={priceList}
                />
              </Item>
            )}
          </Col>
        </Row>
      </Form>

      <FilesViewModal
        vehicleDetail={{
          name:
            form.getFieldValue("name") + "-" + form.getFieldValue("modelCode"),
        }}
        editable={editable}
        data={file}
        emitData={addFile}
        addFile={addFile}
        deleteFile={deleteFile}
        open={addFileFlag}
        close={() => setAddFileFlag(false)}
        setFileUploadData={setFileUploadData}
      />

      <ImagesViewModal
        vehicleDetail={{
          name:
            form.getFieldValue("name") + "-" + form.getFieldValue("modelCode"),
        }}
        editable={editable}
        data={image}
        open={addImageFlag}
        deleteImage={deleteImage}
        addImage={addImage}
        editImage={editImage}
        setImageUploadData={setImageUploadData}
        close={() => setAddImageFlag(false)}
      />
    </Modal>
  );
};

const WrappedVehicleModal = Form.create({ name: "vehicle" })(VehicleModal);

export default WrappedVehicleModal;
