import React, { useState, useEffect } from "react";
import { Row, Col, Typography, Button, Table, Divider, Popconfirm } from "antd";
const { Text } = Typography


const Documents = (props) => {
    let { data, editable, insurance } = props
    const [file, setFile] = useState([])
    useEffect(() => {
        if (data) {
            data.file.sort((a, b) => {
                const aName = a.name.split("$")[0];
                const bName = b.name.split("$")[0];
                switch (aName) {
                    case "Service Manual":
                        if (bName == "Brochure") return -1;
                        if (bName == "Parts Manual") return 1;
                        return 0;
                    case "Brochure":
                        if (bName == "Parts Manual") return 1;
                        if (aName == "Service Manual") return 1;
                        return 0;
                    case "Parts Manual":
                        if (bName == "Brochure") return -1;
                        if (bName == "Service Manual") return -1;
                        return 0;
                }
            })
            let temp = data.file.filter(item => {
                return item.name.split("$")[0] != "Brochure";
            })
            setFile(temp)
        }
        if (insurance) {
            console.log("insurance", insurance)
        }
    }, [data, insurance])
    const modelColumns = [
        {
            title: 'Name',
            // dataIndex: 'modelCode',
            key: 'name',
            render: () => <span>{data.modelCode} - {data.modelName}</span>
        },
        {
            title: 'Type',
            dataIndex: 'name',
            key: 'type',
            render: (record) => <span>{record.split("$")[0]}</span>
        },
        {
            title: 'Actions',
            dataIndex: 'url',
            render: (record) => <span>
                <a
                    // download="filename"
                    href={record}
                    target="_blank"
                >
                    <Button >View</Button>
                </a>
            </span>
        },
    ]
    return (
        <div>
            <h3>Model Documents</h3>
            <Row>
                <Col span={24}>
                    <Table dataSource={file} columns={modelColumns} />
                </Col>
            </Row>
        </div>
    )
}
export default Documents;