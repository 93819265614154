import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs } from "antd";
// import "./index.less";

// Components
import FollowUpTable from './FollowUpTable'
import { platformApi } from "../../../api";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs

function FollowUpHomePage() {

  const activeTab = JSON.parse(localStorage.getItem('activeFollowUpTab'));
  const activeTabElement = {}
  if (activeTab === null) {
    activeTabElement.tab = "ACTIVE";
    activeTabElement.key = 1;
    activeTabElement.page = 1;
  } else {
    activeTabElement.tab = activeTab.tab;
    activeTabElement.key = activeTab.key;
    activeTabElement.page = activeTab.page;
  }

  const [limit, setLimit] = useState(10);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [search, setSearch] = useState({ searchString: "" });
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(activeTabElement.page || 1);
  const [filterData, setFilterData] = useState({})
  const [tab, setTab] = useState(activeTabElement.tab || "ACTIVE")
  const [activeKey, setActivekey] = useState(activeTabElement.key || 1)

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  useEffect(() => {
    setPageData(search);
  }, [page, limit, filterData, search]);

  const setPageData = (search, status) => {
    setSpinning(true);
    // if (!searches) {
    if (!status) {
      status = tab
    }
    let obj = {
      page: page,
      size: limit,
      searchString: search.searchString,
      status
    }
    platformApi 
      .post("/api/customer/unique/phone", obj)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.customers);
            setCount(response.data.count)
          } else {
            message.error("Unable to fetch Quotation", 2);
          }
        } else {
          message.error("Unable to fetch Quotation", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Quotation : ", error);
        message.error("Unable to fetch Quotation", 2);
      });
  };
 
  const handleTabChange = (activeKey) => {
    setActivekey(activeKey)
    switch (activeKey) {
      case "1": setPageData({ searchString: "" }, "ACTIVE")
        setTab("ACTIVE")
        setPage(1)
        setCurrentTab(2)
        break;
      case "2": setPageData({ searchString: "" }, "BOOKED")
        setTab("BOOKED")
        setPage(1)
        setCurrentTab(3)
        break;
      case "3": setPageData({ searchString: "" }, "REJECTED")
        setTab("REJECTED")
        setPage(1)
        break;
      case "4": setPageData({ searchString: "" }, "ALL")
        setTab("ALL")
        setPage(1)
        break;
      default: setPageData({ searchString: "" }, "ALL")
        setTab("ALL")
        setPage(1)
    }
  }

  const activeFollowUpTab = {
    'tab': tab,
    'key': activeKey,
    'page': page
  }
  localStorage.setItem('activeFollowUpTab', JSON.stringify(activeFollowUpTab))

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Follow-Ups [{count}]
          <span style={{ margin: ".5rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Quotations/Phone"
          onSearch={(event) => {
            let searches = { searchString: event };
            setPage(1)
            setSearch(searches);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch({ searchString: "" })
              setPage(1)
            }
          }}
        />
        {/* <div style={{ width: "12%", textAlign: "center" }}>
          <Button
            type="link"
            onClick={() => {
              collapseFilter()
            }}
          >
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button>
        </div> */}
      </div>
      {/* <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        setId={setFilter}
        setPageData={setPageData}
        setSearch={setSearch}
        setFilterData={setFilterData}
        setPage={setPage}
      /> */}
      <Tabs defaultActiveKey={activeKey} onChange={handleTabChange}>
        <TabPane
          tab={
            <span>
              Quoted
            </span>
          }
          key="1"
        >
          <FollowUpTable
            spinner={spinning}
            dataSource={dataSource}
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              Booked
            </span>
          }
          key="2"
        >
          <FollowUpTable
            spinner={spinning}
            dataSource={dataSource}
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              Discarded
            </span>
          }
          key="3"
        >
          <FollowUpTable
            spinner={spinning}
            dataSource={dataSource}
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              All
            </span>
          }
          key="4"
        >
          <FollowUpTable
            spinner={spinning}
            dataSource={dataSource}
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
          />
        </TabPane>
      </Tabs>
    </div>
  );
}

export default FollowUpHomePage