import React, { useEffect, useState } from 'react';
import { Avatar } from '@material-ui/core';
import './SidebarChat.css';
import { Badge } from 'antd';

const SidebarChat = (props) => {


    const { data, onFetchChat, setUsrId, usrId } = props;
    const [notf, setNotf] = useState(data?.notification || false);
    // console.log(data);


    useEffect(() => {
        setNotf(data?.notification || false)
    }, [data])

    return (
        <div style={{
            display: 'flex',
            padding: '5px',
            cursor: 'pointer',
            borderBottom: '1px solid #f6f6f6',
            alignItems: "center",
            // justifyContent: 'center'
        }} className='sidebarchat'
            onClick={() => {
                onFetchChat(data.id)
                setNotf(false)
            }}
        >
            <Avatar />
            <div style={{ marginLeft: '25px', width: "100%", display: "flex", alignItems: "center", justifyContent: 'space-between' }} className="chat-info">
                <h2 style={{
                    fontSize: '14px',
                    marginBottom: '8px'
                }}>{data.name != null ? data.name : `Customer ( ${data.number} ) `}</h2>
                {/* <h5 style={{marginRight: "10px"}}>1000</h5> */}

                {data.id !== usrId && notf &&
                    <Badge style={{ backgroundColor: '#66DE93', color: 'FFFFF' }} count={data.count} />
                }

            </div>
        </div>
    )
}

export default SidebarChat
