import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker
} from "antd";
import { platformApi } from "../../../../../api";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;

const CustomerData = ({ form }) => {
  const [customerData, setCustomerData] = useState([]);
  const [challanData, setChallanData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [countryData, setCountryData] = useState([]);

  useEffect(() => {
    platformApi.get("/api/customer").then(res => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setCustomerData(response.data);
        }
      }
    });
    platformApi.get("/api/user/saleChallan").then(res => {
      if (res.data.code !== 500) {
        let data = res.data.data;
        setBranchData(data);
      }
    });
  }, []);
  const { getFieldDecorator } = form;
  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item
            colon={false}
            required={true}
            label={<Text>Invoice No.</Text>}
          >
            {getFieldDecorator("invoiceNo", {
              rules: [
                {
                  required: true,
                  message: "Invoice No. Required!"
                }
              ]
            })(<Input placeholder="Invoice No." />)}
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item colon={false} label={<Text>Customer Id</Text>}>
            {getFieldDecorator("addressLine", {
              rules: [
                {
                  required: false,
                  message: "Please input your Address Line!"
                }
              ]
            })(<Input placeholder="Address Line" />)}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Customer Name</Text>}>
            {getFieldDecorator("customerName")(
              <Select placeholder="Customer Name">
                {customerData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Pincode</Text>}>
            {getFieldDecorator("pincode")(
              <Input disabled={!true} placeholder="Pincode" />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Sale Challan</Text>}>
            {getFieldDecorator("saleChallan")(
              <Select placeholder="Sale Challan">
                {challanData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item colon={false} label={<Text>City</Text>}>
            {getFieldDecorator("city")(
              <Select placeholder="City">
                {cityData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>State</Text>}>
            {getFieldDecorator("state")(
              <Select placeholder="State">
                {stateData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item colon={false} label={<Text>Country</Text>}>
            {getFieldDecorator("country")(
              <Select placeholder="Country">
                {countryData.map(data => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedCustomerData = Form.create()(CustomerData);

export default WrappedCustomerData;
