import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Typography,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Input,
  Button,
  message,
} from "antd";
import WrappedChallanTable from "./ChallanTable";
import moment from "moment";
import { platformApi } from "../../../../api";
import "./index.less";

const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;

function VehiclePurchaseChallanModal({
  editable: editData,
  form,
  open,
  close,
  data,
  emitData,
  modify
}) {
  const ref = useRef();
  const [id, setId] = useState(null);
  const [suppliers, setSupplier] = useState([]);
  const [branches, setBranch] = useState([]);
  const [challanData, setChallanData] = useState([]);
  const [editable, setEditable] = useState(null);
  const [challanLoading, setChallanLoading] = useState(false);
  const [error, setError] = useState({});
  const [branchBasedManufacturer, setBranchBasedManufacturer] = useState([])
  const [locationDisabled, setLocationDisabled] = useState(false)

  const tabledata = {
    vehicle: {},
    color: {},
    chassisNo: "",
    engineNo: "",
    keyNo: "",
    id: "",
    batteryNo: "",
    warrantyBookNo: "",
    manMonthYear: "",
    // quantity: ""
  };
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;

  useEffect(() => {
    if (open) {
      form.setFieldsValue({ date: moment(new Date()), challanDate: moment(new Date()) })
      setEditable(editData);
      addVehicle(tabledata);
      if (!data)
        platformApi.post("/api/idGenerate/purchaseChallan/").then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setId(response.data);
            }
          }
        });
      platformApi.get("api/supplier").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let supplier = response.data.filter(
              (obj) =>
                obj.supplierType.includes("Vehicles") && obj.status === "ACTIVE"
            );
            setSupplier(supplier);
            if (!data)
              setFieldsValue({ supplier: supplier[0].id });
          }
        }
      });
      platformApi.get("/api/user/branch").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          setBranch(data.data);
          setManufacturer(data.data[0].id)
        }
      });
      if (!data) {
        platformApi.get("/api/user/currentUser").then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setFieldsValue({ location: response.data.profile.branch[0].id });
            }
          }
        });
      }
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setId(data.challanNo);
      setChallanData(data.vehicleDetail);
      setFieldsValue({
        supplierChallanNo: data.supplierChallanNo,
        supplier: data.supplier.id,
        location: data.branch.id,
        date: data.date === null ? moment(Date.now()) : moment(data.date),
        challanDate: moment(data.challanDate),
      });
    }
  }, [data]);

  const addVehicle = (data) => {
    console.log("Add vehicle", data)
    setChallanData([...challanData, data]);
  };

  const clearFields = () => {
    ref.current();
    resetFields();
    setSupplier([]);
    setId(null);
    setBranch([]);
    setChallanData([]);
  };
  const modifyData = () => {
    addVehicle(tabledata);
    setEditable(true);
  };
  const handleSubmit = () => {
    const chalan = challanData.filter((mockChallan) => {
      return mockChallan.vehicle.id;
    });
    validateFields((validationError) => {
      if (validationError === null && !error.INVNO) {
        if (chalan[0]) {
          chalan.forEach((mockData, index) => {
            chalan[index].vehicle = mockData.vehicle.id;
            chalan[index].color = mockData.color.id;
          });
          const Challan = {
            vehicleDetail: [...chalan],
            date: new Date(getFieldValue("date")).toLocaleDateString(),
            challanDate: new Date(
              getFieldValue("challanDate")
            ).toLocaleDateString(),
            supplierChallanNo: getFieldValue("supplierChallanNo"),
            challanNo: id,
            supplier: getFieldValue("supplier"),
            branch: getFieldValue("location"),
          };
          data ? (Challan.id = data.id) : (Challan.id = null);
          emitData(Challan);
          clearFields();
          close();
        } else {
          message.error("Select atleast one vehicle", 2);
        }
      }
    });
  };

  const setManufacturer = (id) => {
    platformApi.post('/api/purchaseChallan/manufacturer', { branch: id })
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setBranchBasedManufacturer(response.data.manufacturer)
          }
        }
      })
  }

  const deleteChallanVehicle = (id, index) => {
    if (id) {
      setChallanLoading(true);
      platformApi
        .delete("api/purchaseChallan/vehicle/" + id)
        .then((result) => {
          setChallanLoading(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setChallanData(challanData.filter((chData) => chData.id !== id));
              message.success("Vehicle Deleted Successfully", 2);
            } else {
              message.error("Unable to delete Vehicle");
            }
          } else {
            message.error("Unable to delete Vehicle");
          }
        })
        .catch((error) => {
          console.error("Error on Vehicle Purchase Challan : ", error);
          message.error("Unable to delete Vehicle");
          setChallanLoading(false);
        });
    } else {
      setChallanData(challanData.filter((chData, ind) => ind !== index));
    }
  };

  return (
    <div>
      <Modal
        wrapClassName="purchase-challan"
        title={<Title level={4}>Vehicle Purchase Challan</Title>}
        visible={open}
        okText={editable ? "Save" : "Modify"}
        onCancel={() => {
          clearFields();
          close();
        }}
        okButtonProps={{ disabled: (!editable && !modify) }}
        onOk={() => (editable ? handleSubmit() : modifyData())}
      >
        <Form>
          <Row>
            <Text strong>Vehicle Purchase Challan No :</Text> {id}
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Row type="flex" justify="space-between">
                <Col span={10}>
                  <Item colon={false} label="Creation Date">
                    {getFieldDecorator("date", {
                      rules: [{ required: true, message: "Select a Date" }],
                    })(
                      <DatePicker
                        style={{ width: "100%" }}
                        disabled={true}
                        disabledDate={(moment) => moment._d > Date.now()}
                        format="DD-MMM-YYYY"
                        placeholder="Creation Date"
                      />
                    )}
                  </Item>
                </Col>
                <Col span={10}>
                  <Item name="Challan Date" colon={false} label="Challan Date">
                    {getFieldDecorator("challanDate")(
                      <DatePicker
                        disabled={!editable}
                        style={{ width: "100%" }}
                        disabledDate={(moment) => moment._d > Date.now()}
                        format="DD-MMM-YYYY"
                        placeholder="Challan Date"
                      />
                    )}
                  </Item>
                </Col>
              </Row>
            </Col>
            <Col span={10}>
              <Item
                colon={false}
                label="Supplier Invoice Number"
                validateStatus={error.INVNO && error.INVNO.type}
                help={error.INVNO && error.INVNO.message}
              >
                {getFieldDecorator("supplierChallanNo", {
                  rules: [
                    { required: true, message: "Enter Supplier Invoice Number " },
                  ],
                })(
                  <Input
                    disabled={!editable}
                    placeholder="Supplier Invoice Number "
                    pattern="^[0-9A-Za-z]*$"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          INVNO: {
                            type: "error",
                            message: "Enter valid Invoice No.",
                          },
                        });
                      } else {
                        delete error.INVNO;
                        setError(error)
                      }
                      // if (event.target.value.trim()) {
                      //   delete error.INVNO
                      //   setError(error);
                      // }
                      // else {
                      //   setError({
                      //     ...error,
                      //     INVNO: {
                      //       type: "error",
                      //       message: "Enter Valid Invoice No",
                      //     },
                      //   });
                      // }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={10}>
              <Item colon={false} label="Supplier">
                {getFieldDecorator("supplier", {
                  rules: [
                    { required: true, message: "Select Supplier " },
                  ],
                })(
                  <Select
                    disabled={!editable}
                    placeholder="Select the supplier"
                  >
                    {suppliers.map((supplier) => (
                      <Option key={supplier.id}>{supplier.name}</Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={10}>
              <Item colon={false} label="Location">
                {getFieldDecorator("location", {
                  rules: [
                    { required: true, message: "Select Location " },
                  ],
                })(
                  <Select onChange={(id) => { setManufacturer(id) }} disabled={!editable || locationDisabled} placeholder="Select a location">
                    {branches.map((obj) => (
                      <Option key={obj.id}>{obj.name}</Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Row>
            <Item colon={false}>
              <WrappedChallanTable
                open={open}
                clearRef={ref}
                loading={{ spinning: challanLoading }}
                delete={deleteChallanVehicle}
                toDelete={editable}
                error={error}
                setError={setError}
                dataSource={challanData}
                editData={setChallanData}
                addVehicle={() => addVehicle(tabledata)}
                manufacturer={branchBasedManufacturer}
                setLocationDisabled={setLocationDisabled}
              />

            </Item>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

const WrappedVehiclePurchaseChallanForm = Form.create()(
  VehiclePurchaseChallanModal
);

export default WrappedVehiclePurchaseChallanForm;
