import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  message,
  Select,
  Typography,
  Row,
  Col,
  Table,
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import _ from 'lodash';
import { platformApi } from '../../../../../../../api';
const { Text } = Typography;
const { Item } = Form;

function ActivityHistory({ form, validityRef, data, clearRef, editable, setData }) {
  const [dataSource,setDataSource]=useState([])
  const { getFieldDecorator } = form;

  const columns = [
    {
      title: <Text strong>Date & Time</Text>,
      dataIndex: "phone",
      key: "phone",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Enquiry Type</Text>,
      dataIndex: "type",
      key: "type",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Processing Status</Text>,
      dataIndex: "valid",
      key: "valid",
      render: (record) => (record ? "Valid" : "Invalid"),
    },
    {
      title: <Text strong>Action</Text>,
      dataIndex: "DND",
      key: "DND",
      render: (record) => (record ? "Yes" : "No"),
    },
  ]

  return (
    <Form style={{ marginTop: '3vh' }}>
      <Row type="flex" justify="space-around">
        <Col span={11}>
          <Item>
            <Text strong>Lead Id:</Text>
          </Item>
        </Col>
        <Col span={11}>
        </Col>
      </Row>

      <Row type="flex" justify="space-around">
        <Col span={20}>

          <Table
            rowKey={(record) => record.id}
            pagination={false}
            size="small"
            columns={columns}
            style={{ cursor: "pointer" }}
            dataSource={dataSource}
          />
        </Col>
      </Row>
    </Form>

  )
}
const WrappedActivityHistory = Form.create({ name: 'ActivityHistoryForm' })(ActivityHistory);
export default WrappedActivityHistory;