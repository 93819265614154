import React, { useState } from "react";
import {
  Table,
  Popconfirm,
  Input,
  Select,
  Menu,
  DatePicker,
  Divider,
  Icon,
  Row,
  Col,
  Form,
  message,
} from "antd";
import { useEffect } from "react";
import moment from "moment";
// import "./index.less";
import { platformApi } from "../../../../api";
const { Item } = Form;
const { Search } = Input;
function PartsTable({
  toDelete,
  dataSource,
  delete: deleteData,
  loading,
  editData,
  clearRef,
  addParts,
  open,
  form,
  mechanic,
  date
}) {
  const [data, setData] = useState(dataSource);
  const [part, setPart] = useState([]);
  const [partData, setPartData] = useState([]);
  const [count, setCount] = useState(0);
  // const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [search, setSearch] = useState([]);
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };
  const menu = <Menu>{getMenu()}</Menu>;
  useEffect(() => {
    clearRef.current = () => {
      setData([]);
      resetFields();
    };
    return () => {
      clearRef.current = null;
    };
  });
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  useEffect(() => {
    setPageData();
  }, []);
  useEffect(() => {
    if (search.length !== 0)
      setPageData(search);
  }, []);
  const setPageData = (searches) => {
    setSpinning(true);

    if (!searches) {
      platformApi
        .get("/api/partsMaster/")
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData(response.data.partsMaster);
            } else {
              message.error("Unable to fetch partsMaster", 2);
            }
          } else {
            message.error("Unable to fetch partsMaster", 2);
          }
        })
        .catch((err) => {
          setSpinning(false);
          console.error("Error on partsMaster : ", err);
          message.error("Unable to fetch partsMaster", 2);
        });
    }
    else {
      platformApi
        .post("/api/partsMaster/search", {
          name: searches.name,
          size: limit,
          from: (page - 1) * limit,
        })
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData(response.data.parts);

            } else {
              message.error("Unable to fetch parts", 2);
            }
          } else {
            message.error("Unable to fetch parts", 2);
          }
        })
        .catch((err) => {
          setSpinning(false);
          console.error("Error on partsMaster : ", err);
          message.error("Unable to fetch partsMaster", 2);
        });
    }
  }
  const handlePartChange = (id, index) => {
    let Parts = partData;
    part.some((parts) => {
      if (parts.id === id) {
        Parts[index] = parts;
        setPartData([...Parts])
      }
    })
  }
  const isuueTypes = [
    { key: "Foc", title: "FOC" },
    { key: "Paid", title: "PAID" },
    { key: "Uw", title: "Under-Warranty" },
  ];
  const handleChange = (value, index, type) => {
    let data = dataSource;
    data[index][type] = value;
    editData([...data]);
  };
  useEffect(() => {
    setData(dataSource);
  }, [dataSource]);
  useEffect(() => {
    if (open) {
      platformApi.get("/api/partsMaster").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setPart(response.data);
          }
        }
      });
    }
    // if(dataSource)
  }, [open]);
  const column = [
    {
      title: " Part No",
      dataIndex: "number",
      key: "part",
      render: (data, record, index) => (
        <Search

          onChange={(event, key) => {
            let searches = { name: event.target.value };
            setSearch(searches), setPageData(searches);
            handleChange(event, index, "part");
            handlePartChange(key.key, index);
            handleChange(partData[index].name, index, "description");
          }}

          disabled={!toDelete}
          placeholder="Part Number"

        />


      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (data, record, index) => (
        <Input
          key="description"
          onWheel={(event) => event.currentTarget.blur()}
          disabled={true}
          placeholder="Description"
          value={data}
        />
      ),
    },
    {
      title:"Mechanic",
      dataIndex:"",
      key:"",
      render:()=>(
        <Select placeholder="mechanic" >
            {mechanic.map((obj) => (
              <Select.Option key={obj.id}>
                {obj.profile.employeeName}
              </Select.Option>
            ))}
          </Select>
      )
    },
    {
      title: "Issue Type",
      dataIndex: "issuetype",
      key: "issuetype",
      render: (data, record, index) => (
        <Select
          className="ChallanTable-Manufacturers"
          dropdownStyle={{
            overflowY: "auto",
            overflowX: "hidden",
          }}
          onChange={(event) => {
            handleChange(event, index, "issuetype");
          }}
          showSearch
          // defaultValue={
          //   data.modelName ? data.modelCode + " - " + data.modelName : undefined
          // }
          disabled={!toDelete}
          placeholder="Issue Type"
          filterOption={(input, option) =>
            option.props.children.some(
              (child) => child.toLowerCase().indexOf(input.toLowerCase()) >= 0
            )
          }
        >
          {isuueTypes.map((issue) => (
            <Select.Option key={issue.key} value={issue.title}>
              {issue.title}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title:"Issue Date",
      dataIndex:"",
      key:"",
      render:()=>(
                <DatePicker
                      style={{ width: "100%" }}
                      size={date}
                      format="DD/MM/YYYY"
                      allowClear
                      onChange={(e) => dateChange(e)}
                      disabledDate={(moment) => Date.now() < moment._d}
                    />
      )
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity",
      render: (data, record, index) => (
        <Input
          key="quantity"
          type="number"
          onChange={(event) => {
            handleChange(parseInt(event.target.value), index, "quantity");
            handleChange(parseInt(parseInt(event.target.value) * partData[index].mrp), index, "mrpquantity");
          }}
          onFocus={() => {
            if (index === dataSource.length - 1) {
              addParts();
            }
          }}
          onWheel={(event) => event.currentTarget.blur()}
          disabled={!toDelete || !partData[index]}
          value={data}
          placeholder="Quantity"
        />
      ),
    },
    {
      title: "MRP",
      dataIndex: "mrpquantity",
      key: "mrp",
      render: (data, record, index) => (
        <Input
          key="mrp"
          onWheel={(event) => event.currentTarget.blur()}
          disabled={true}
          placeholder="MRP"
          value={data}
        />
      ),
    },
    {
      key: "actions",
      width: 30,
      render: (data1, data2, index) =>
        toDelete && (
          <div>
            <Popconfirm
              title="Are you sure delete this Part?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => { }}
              onConfirm={(event) => {
                if (
                  dataSource.length === 1 &&
                  dataSource.length - 1 === index
                ) {
                  message.error("Select atleast one part", 2);
                } else {
                  deleteData(data1.id, index);
                }
              }}
            >
              <Icon type="delete" />
            </Popconfirm>
          </div>
        ),
    },
  ];

  return (
    <div>
      <span>Parts Details</span>
      <Table
        size="small"
        loading={loading}
        pagination={false}
        scroll={{ y: 160 }}
        className="Challantable"
        columns={column.filter((obj) => obj.title !== "Action" || toDelete)}
        style={{ cursor: "pointer" }}
        dataSource={data}
      />
    </div>
  );
}
const WrappedPartsTable = Form.create()(PartsTable);

export default WrappedPartsTable;
