import React, { useState, useEffect } from 'react'

import { withRouter } from "react-router-dom";
import StatusHeader from './header'
import StatusContainer from './StatusContainer';
const StatusPage = (props) => {
    const id = props.match.params.id;
    return (
        <div className="statusBody">
            <StatusHeader />
            <StatusContainer id={id} />
        </div>
    )
}
export default withRouter(StatusPage); 