import React from 'react'

const index = () => {
    return (
        <div>
            hello whastappp promo
        </div>
    )
}

export default index
