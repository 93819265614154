import React, { useEffect, useState } from "react";
import { Table, Divider, Popconfirm, Typography, message } from "antd";

import { platformApi } from "../../../../api";
import WrappedAccessoriesModal from "./StatusModal";
import moment from 'moment'
const { Text } = Typography;

export default function TaskTable(props) {
  const { pagination, dataSource, openModal, loading,modify,deleteAccess } = props;

  const [testFlag, setTestFlag] = useState(false);
  const [dataId, setDataId] = useState();

  const column = [
    {
      title: "Task Id",
      dataIndex: "taskId",
      key: "taskname",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Scheduled Date",
      dataIndex: "executionTime",
      key: "schedule",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (record) => <span>{record}</span>,
    },
    // {
    //   title: "Campaign",
    //   dataIndex: "message",
    //   key: "campaign",
    //   render: (record) => <span>{record && record.campaignName ? record.campaignName.name : null}</span>,
    // },
    {
      title: "Action",
      render: (data) => (
        <div>
          {/* <span
            onClick={(event) => {
              event.stopPropagation();
              // openModal(data.id, true);
              platformApi
                .post("/api/promotions/start", { id: data.id })
                .then((res) => {
                  message.info("Event has been started", 2)
                  // if (res.data.code === 200) {
                  //   let resp = res.data.response;
                  //   if (resp.code === 200) {
                  //     message.success(resp.msg);
                  //     close();
                  //   } else {
                  //     message.error("Error in sending SMS");
                  //     close();
                  //   }
                  // } 
                  // else {
                  if (res.data.code === 500) {
                    message.error("Error in sending SMS");
                    close();
                  }
                  // }
                });
            }}
            className="linkylink"
          >
            Start Now
          </span>
          <Divider type="vertical" /> */}
          <span
            onClick={(event) => {
              event.stopPropagation();
              // openModal(data.id, true);
              setTestFlag(true);
              setDataId(data.id);
            }}
            className="linkylink"
          >
            Status
          </span>
          <Divider type="vertical" />
          {
            modify ? 
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(data.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
              <span></span>
          } 
          <Divider type="vertical" />
           {
            deleteAccess ? 
          <Popconfirm
            title="Do you want to delete this Task?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              props.delete(data.id);
            }}
          >
            <span
              onClick={(event) => {
                event.stopPropagation();
              }}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>

          :
              <span></span>
          } 
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        pagination={pagination}
        columns={column}
        loading={loading}
        // tableLayout="fixed"
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        onRow={(props) => ({
          onClick: () => openModal(props.id, false),
        })}
      />
      <WrappedAccessoriesModal
        open={testFlag}
        close={() => {
          setTestFlag(false);
          setDataId(null);
        }}
        id={dataId}
      />
    </div>
  );
}
