import React, { useState, useEffect,useContext } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs } from "antd";


const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs;

import InventoryTable from './InventoryTable';
import WrappedVehicleModal from './VehicleModal';
import '../index.less';
import { platformApi } from '../../../../api'
import WrappedAddVehicleInventory from './AddVehicleInventory';
import SupplierMasterForm from '../../../CompanyMasters/SupplierMaster/SupplierMasterForm';
import { ContextAPI } from '../../../../ContextAPI';

const VehicleInventory = () => {
  const [dataSource, setData] = useState([]);
  const [filteropen, setFilteropen] = useState(false);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState('');
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [branchData, setBranchData] = useState([]);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [sup, setSup] = useState(false);
  const [supData, setSupData] = useState('')
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item
        onClick={() => {
          setLimit(menu), setPage(1);
        }}
        key={menu}
      >
        {menu}
      </Item>
    ));
  };
  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "report") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    // setPageData();
    // if (props.customerId) {
    //   setAddFlag(true);
    //   setSelected({ ...props.cusData });
    // }
  }, []);
  const menu = <Menu>{getMenu()}</Menu>;

  useEffect(() => {
    getvehicleInventroy(search, null);
  }, [])

  useEffect(() => {
    getvehicleInventroy(search);
  }, [page, limit, search]);

  const getvehicleInventroy = (searches, val) => {
    setSpinning(true)
    platformApi.post("/api/vehicleInventory/get", {
      page: val ? val : page,
      size: limit,
      searchString: searches && searches.name
    }).then((result) => {
      setSpinning(false)
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          console.log("Response", response.data.VehicleInventory)
          setCount(response.data.count)
          setTotal(response.data.total)
          const inventory = response.data.VehicleInventory;
          for (let itr = 0; itr < inventory.length; itr++) {
            const vehicle = inventory[itr];
            var count = 1;
            for (let index = 0; index < inventory.length;) {
              const element = inventory[index];
              if (index <= itr) {
                index++;
                continue;
              }
              if (element.vehicle.id === vehicle.vehicle.id && element.color.id === vehicle.color.id) {
                count++;
                inventory.splice(index, 1);
              } else {
                index++;
              }
            }
            vehicle.count = count;
          }
          setData(inventory)
        } else {
          message.error('Unable to fetch vehicles', 2);
        }
      } else {
        message.error('Unable to fetch vehicles', 2);
      }
    })
      .catch((err) => {
        setSpinning(false)
        console.error("Vehicle Inventories Fetch Error: ", err);
        message.error("Couldn't Fetch Vehicle Inventories");
      });
  }

  const collapseFilter = () => {
    filteropen ? setFilteropen(false) : setFilteropen(true);
    // let obj = {
    //   name: "",
    // };
    // setSearch(obj);
  };
  const addData = (purchaseChallan) => {
    setSpinning(true);
    if (purchaseChallan.id) {
      platformApi
        .put("/api/vehicleInventory/" + purchaseChallan.id, purchaseChallan)
        .then((result) => {
          setSpinning(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              message.success("Vehicle Inventory saved successfully", 2);
              getvehicleInventroy()
            } else {
              message.error("Unable to save Vehicle Inventory", 2);
            }
          } else {
            message.error("Unable to save Vehicle Inventory", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error("Error on Vehicle Inventory : ", error);
          message.error("Unable to save Vehicle Inventory", 2);
        });
    }
    else {
      platformApi
        .post("/api/vehicleInventory", purchaseChallan)
        .then((result) => {
          setSpinning(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              message.success("Vehicle Inventory Added successfully", 2);
              getvehicleInventroy()
            } else {
              message.error("Unable to add Vehicle Inventory", 2);
            }
          } else {
            message.error("Unable to add Vehicle Inventory", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error("Error on Vehicle Inventory : ", error);
          message.error("Unable to add Vehicle Inventory", 2);
        });
    }
  };
  
  return (
    <>
      <div className="accessories-head">
        <div className="add-inventory">
          <Title className="heading" style={{ width: "60%" }} level={4}>
            Vehicle Inventory
          </Title>
          <Button
            onClick={() => {
              setAddFlag(true);
              setEditFlag(true);
            }}
            type="primary"
          disabled={!createAccess}
          >
            Add to Inventory
          </Button>
        </div>
        <div className="add-inventory">
          <h3 level={4}>
            Total Cost:&nbsp;{total}
          </h3>
        </div>
        <div className="search-inventory">
          <Search
            style={{ width: "50%" }}
            placeholder="Search Inventory"
            onSearch={(event) => {
              let searches = { name: event };
              setPage(1)
              setSearch(searches);
            }}
            onChange={(event) => {
              if (event.target.value === "") {
                setSearch(null)
                setPage(1)
              }
            }}
          />
          <div style={{ width: "12%", textAlign: "center" }}>
            <Button
              type="link"
              onClick={() => {
                collapseFilter();
              }}
            >
              {filteropen ? "Back" : "Advanced  Filters"}
            </Button>
          </div>
        </div>
      </div>
      <InventoryTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          current: page,
          total: count,
          showQuickJumper: true
        }}
        openModal={(id) => {
          setSelected(id);
          setOpen(true);
        }}
        dataSource={dataSource}
        spinner={{ spinning }}
      />

      <WrappedVehicleModal
        data={selected}
        open={open}
        branchData={branchData}
        close={() => {
          setOpen(false);
          setSelected(false);
        }}
        editable={false}
        modify={false}
        setSupData={setSupData}
        setSup={setSup}
      />
      <WrappedAddVehicleInventory
        open={addFlag}
        editable={editFlag}
        close={() => {
          setAddFlag(false);
          setEditFlag(false);
        }}
        toDelete={editFlag}
        modify={false}
        emitData={addData}
      />
      <SupplierMasterForm
        open={sup}
        close={() => {
          setSup(false);
          setSupData('')
        }}
        supData={supData}
        editable={false}
      />

    </>
  )
}


export default withRouter(VehicleInventory);