import React, { useState, useEffect } from "react";
import {
  Modal,
  Row,
  Typography,
  Col,
  Input,
  Form,
  Upload,
  Icon,
  Select,
  message,
  Checkbox,
} from "antd";
import { platformApi } from "../../../../api";
import "./index.less";
import { formatValue } from "../../../../utils";
import { ENDPOINT } from "../../../../constants";

const { Item } = Form;
const { Text } = Typography;
const { Dragger } = Upload;

const ManufacturerForm = ({
  form,
  open,
  close,
  data,
  emitData,
  editable: editData,
  modify,
  setImg
}) => {
  const [error, setError] = useState({});

  const [loading, setLoading] = useState(false);
  const [imgLoading, setImgLoading] = useState(false);
  const [logo, setLogo] = useState();
  const [editable, setEditable] = useState(editData);
  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [formData, setFormData] = useState(null);
  const [gstCompany, setGstCompany] = useState(null);
  const [gstName, setGstName] = useState("");
  const [gstStatus, setGstStatus] = useState("");
  const [vehicleManufacturer, setVehicleManufacturer] = useState(false);
  const { getFieldDecorator, getFieldValue } = form;
  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (
        validationError === null &&
        !error.EMAIL &&
        !error.GST
        // &&
        // gstCompany &&
        // gstCompany.valid
      ) {
        setLoading(true);
        const name = form.getFieldValue("name");
        const gst = form.getFieldValue("gst");
        const email = form.getFieldValue("email");
        const line1 = form.getFieldValue("line1");
        const line2 = form.getFieldValue("line2");
        const line3 = form.getFieldValue("line3");
        const locality = form.getFieldValue("locality");
        const district = form.getFieldValue("city");
        const state = form.getFieldValue("state");
        const country = form.getFieldValue("country");
        const pincode = form.getFieldValue("pincode");
        const code = form.getFieldValue("code");
        const manufacturer = {
          name,
          code,
          address: {
            line1,
            line2,
            line3,
            locality,
            district,
            state,
            country,
            pincode,
          },
          gst,
          email,
          vehicleManufacturer
        };
        data ? (manufacturer.id = data.id) : {};
        if (formData) {
          formData.append("id", manufacturer.name);

          platformApi
            .post("/api/upload/image", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              setImgLoading(false);
              let { data } = res;
              if (data.code === 200) {
                manufacturer.logo = data.response.data.Location;
                resolveData(manufacturer);
              } else {
                message.error("Upload Not Done");
              }
            })
            .catch((error) => {
              console.error("Error on Manufacturer Form", error);
              message.error("Unable to Upload Image", 2);
              setImgLoading(false);
            });
        } else {
          resolveData(manufacturer);
        }
      } else {
        message.error("Enter All Input Fields");
      }
    });
  };

  const resolveData = (manufacturer) => {
    emitData(manufacturer);
    setLoading(false);
    clearFieldsAndClose();
  };

  function checkGST(gst) {
    platformApi.post("/api/gstVerify", { gst }).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          if (response.data.data.error) {
            message.error("GST Invalid");
            setGstName("");
            return false;
          } else {
            message.success("GST Verified");
            delete error.GST;
            setError(error);
            setGstName(response.data.data.taxpayerInfo.tradeNam);
            setGstStatus(response.data.data.taxpayerInfo.sts);
            return true;
          }
        }
      }
    });
    // return true
  }

  useEffect(() => {
    platformApi
      .get("/api/csc/country")
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setCountry(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Manufaturer form : ", error);
        message.error("Unable to fetch country", 2);
      });
  }, []);

  useEffect(() => {
    setEditable(editData);
    if (data) {
      setLogo(data.logo);
      handleStateChange(data.address.state.id);
      handleCountryChange(data.address.country.id);
      setVehicleManufacturer(data.vehicleManufacturer);
      form.setFieldsValue({
        name: data.name,
        code: data.code,
        line1: data.address.line1,
        line2: data.address.line2,
        line3: data.address.line3,
        country: data.address.country && data.address.country.id,
        locality: data.address.locality,
        state: data.address.state && data.address.state.id,
        city: data.address.district && data.address.district.id,
        pincode: data.address.pincode,
        gst: data.gst,
        email: data.email,
      });
    }
  }, [editData, data]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const handleCountryChange = (id) => {
    platformApi
      .post("api/csc/states", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setState(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Manufaturer form : ", error);
        message.error("Unable to fetch state", 2);
      });
  };

  const handleStateChange = (id) => {
    form.setFieldsValue({
      city: undefined,
    });
    platformApi
      .post("api/csc/cities", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setCity(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Manufaturer form : ", error);
        message.error("Unable to fetch cities", 2);
      });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      setImgLoading(true);
      setImg(true)
      setLogo(null);
    } else if (info.file.status === "done") {
      const formData = new FormData();
      formData.append("profile", info.file.originFileObj);
      formData.append("module", "Manufacturer");
      formData.append("master", "Company Master");
      formData.append("type", "Logo");
      setFormData(formData);
      getBase64(info.file.originFileObj, (url) => {
        setImgLoading(false);
        setLogo(url);
        setImg(false);
      });
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const clearFieldsAndClose = () => {
    form.resetFields();
    setLogo("");
    setError({});
    setLoading(false);
    close();
    setGstCompany(null);
    setGstName("");
    setState([]);
    setCity([]);
  };

  return (
    <Modal
      title="Manufacturer Details"
      visible={open}
      onCancel={clearFieldsAndClose}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      okButtonProps={{
        loading,
        disabled:
          imgLoading ||
          (gstCompany && gstCompany.loading) ||
          (!editable && !modify),
      }}
      okText={!editable ? "Modify" : loading ? "Saving" : "Save"}
      wrapClassName="manufacturer-modal"
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={13}>
            <Row>
              <Item
                validateStatus={error.NAME && error.NAME.type}
                help={error.NAME && error.NAME.message}
                label="Company Name"
                colon={false}
              >
                {getFieldDecorator("name", {
                  rules: [{ required: true, message: "Enter Company Name" }],
                })(
                  <Input
                    pattern="^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        name: formatValue(e, "allCaps"),
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          NAME: {
                            type: "error",
                            message: "Enter Valid Company Name",
                          },
                        });
                      } else {
                        delete error.NAME;
                        setError(error);
                      }
                    }}
                    disabled={!editable}
                    placeholder="Company Name"
                  />
                )}
              </Item>
            </Row>
            <Row>
              <Item
                validateStatus={error.CODE && error.CODE.type}
                help={error.CODE && error.CODE.message}
                label="Manufacturer Code"
                colon={false}
              >
                {getFieldDecorator("code", {
                  rules: [{ required: false, message: "Enter Code" }],
                })(
                  <Input
                    pattern="^[a-zA-Z0-9]+(([ ][a-zA-Z])?[a-zA-Z]*)*$"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          CODE: {
                            type: "error",
                            message: "Enter Valid Manufacturer Code",
                          },
                        });
                      } else {
                        delete error.CODE;
                        setError(error);
                      }
                    }}
                    disabled={!editable}
                    placeholder="Manufacturer Code"
                  />
                )}
              </Item>
            </Row>
          </Col>
          <Col span={9}>
            <Row>
              <Item label="Manufacturer Logo" colon={false}>
                {getFieldDecorator("dragger", {
                  valuepropname: "fileList",
                  getvaluefromevent: normFile,
                })(
                  <Dragger
                    style={{ width: "50%" }}
                    disabled={!editable}
                    name="files"
                    listType="picture-card"
                    accept=".jpeg,.jpg,.png"
                    showUploadList={false}
                    beforeUpload={(e) => beforeUpload(e)}
                    action={`${ENDPOINT}api/upload/mocky`}
                    onChange={handleUpload}
                    accept=".jpg,.png,.jpeg,.gif"
                  >
                    {logo ? (
                      <img style={{ maxWidth: "100%" }} src={logo} alt="logo" />
                    ) : (
                        <div>
                          <p className="ant-upload-drag-icon">
                            <Icon type={imgLoading ? "loading" : "inbox"} />
                          </p>
                          <p
                            className="ant-upload-hint"
                            style={{ padding: "0 10px" }}
                          >
                            {imgLoading
                              ? "Uploading"
                              : "Click or drag file to this area to upload"}
                          </p>
                        </div>
                      )}
                  </Dragger>
                )}
              </Item>
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Item
              validateStatus={error.GST && error.GST.type}
              help={error.GST && error.GST.message}
              label="GST Number"
              colon={false}
              required
            >
              {getFieldDecorator("gst", {
                rules: [{ required: true, message: "Enter GST Number" }],
              })(
                <Input
                  style={{ textTransform: "uppercase" }}
                  maxLength={15}
                  // pattern="^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[Zz]{1}[0-9A-Za-z]{1}$"
                  disabled={!editable}
                  onKeyUp={(event) => {
                    if (event.target.value) {
                      setFieldsValue({
                        gst: formatValue(event, "toUpperCase")
                      })
                    }
                  }}
                  placeholder="GST Number"
                  onInput={(event) => {
                    setGstCompany(null);
                    if (event.target.value.length === 15) {
                      let flag = checkGST(event.target.value);
                      // verifyGST(event.target.value)
                      if (flag) {
                        delete error.GST;
                        setError(error);
                      } else {
                        setError({
                          ...error,
                          GST: {
                            type: "error",
                            message: "Enter Valid GSTIN",
                          },
                        });
                      }
                    } else if (gstStatus != "Active") {
                      setGstStatus("");
                      setGstName("");
                      setError({
                        ...error,
                        GST: {
                          type: "error",
                          message: "The GSTIN entered is Inactive",
                        },
                      });
                    } else {
                      setGstName("");
                      setError({
                        ...error,
                        GST: {
                          type: "error",
                          message: "Enter Valid GSTIN",
                        },
                      });
                    }
                  }}
                />
              )}
            </Item>
            {gstName && (
              <Row type="flex" align="middle">
                <Icon
                  style={{ fontSize: 18, marginRight: ".5rem" }}
                  type={"check-circle"}
                  twoToneColor={"#D00"}
                />
                <span style={{ color: "green", fontSize: "12px" }}>
                  {gstName} ({gstStatus})
                </span>
              </Row>
            )}
          </Col>
          <Col span={11}>
            <Item
              required
              help={error.EMAIL && error.EMAIL.message}
              validateStatus={error.EMAIL && error.EMAIL.type}
              label="E-Mail"
              colon={false}
            >
              {getFieldDecorator("email", {
                rules: [{ required: true, message: "Enter E-Mail" }],
              })(
                <Input
                  pattern="^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z_-]+?\.[a-zA-Z.]{2,6}$"
                  disabled={!editable}
                  placeholder="E-Mail"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        EMAIL: {
                          type: "error",
                          message: "Enter Valid Email Address",
                        },
                      });
                    } else {
                      delete error.EMAIL;
                      setError(error);
                    }
                  }}
                />
              )}
            </Item>
          </Col>
        </Row>

        <Text strong>Address</Text>
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Item
              validateStatus={error.LINE1 && error.LINE1.type}
              help={error.LINE1 && error.DOOR.message}
              label="Address Line 1"
              colon={false}
            >
              {getFieldDecorator("line1", {
                rules: [
                  { required: true, message: "Enter Valid Address Line1" },
                ],
              })(
                <Input
                  pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      line1: formatValue(e, "allCaps"),
                    })
                  }
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        LINE1: {
                          type: "error",
                          message: "Enter Valid Address Line 1",
                        },
                      });
                    } else {
                      delete error.LINE1;
                      setError(error);
                    }
                  }}
                  disabled={!editable}
                  maxLength={50}
                  placeholder="Address Line 1"
                />
              )}
            </Item>
          </Col>
          <Col span={11}>
            <Item
              validateStatus={error.LINE2 && error.LINE2.type}
              help={error.LINE2 && error.LINE2.message}
              label="Address Line 2"
              colon={false}
            >
              {getFieldDecorator("line2", {
                rules: [
                  { required: false, message: "Enter Valid Address Line2" },
                ],
              })(
                <Input
                  pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      line2: formatValue(e, "allCaps"),
                    })
                  }
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        LINE2: {
                          type: "error",
                          message: "Enter Valid Address Line 2",
                        },
                      });
                    } else {
                      delete error.LINE2;
                      setError(error);
                    }
                  }}
                  disabled={!editable}
                  maxLength={50}
                  placeholder="Address Line 2"
                />
              )}
            </Item>
          </Col>
          <Col span={11}>
            <Item
              validateStatus={error.LINE3 && error.LINE3.type}
              help={error.LINE3 && error.LINE3.message}
              label="Address Line 3"
              colon={false}
            >
              {getFieldDecorator("line3", {
                rules: [
                  { required: false, message: "Enter Valid Address Line 3" },
                ],
              })(
                <Input
                  pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      line3: formatValue(e, "allCaps"),
                    })
                  }
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        LINE3: {
                          type: "error",
                          message: "Enter Valid Address Line 3",
                        },
                      });
                    } else {
                      delete error.LINE3;
                      setError(error);
                    }
                  }}
                  disabled={!editable}
                  maxLength={50}
                  placeholder="Address Line 3"
                />
              )}
            </Item>
          </Col>
          <Col span={11}>
            <Item
              validateStatus={error.LOCALITY && error.LOCALITY.type}
              help={error.LOCALITY && error.LOCALITY.message}
              label="Locality"
              colon={false}
            >
              {getFieldDecorator("locality", {
                rules: [{ required: false, message: "Enter Locality" }],
              })(
                <Input
                  pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      locality: formatValue(e, "allCaps"),
                    })
                  }
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        LOCALITY: {
                          type: "error",
                          message: "Enter Valid Locality",
                        },
                      });
                    } else {
                      delete error.LOCALITY;
                      setError(error);
                    }
                  }}
                  maxLength={50}
                  disabled={!editable}
                  placeholder="Locality"
                />
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Item label="Country" colon={false}>
              {getFieldDecorator("country", {
                rules: [{ required: true, message: "Select Country" }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  showSearch
                  disabled={!editable}
                  placeholder="Country"
                  onChange={handleCountryChange}
                  filterOption={filterMethod}
                >
                  {country.map((object) => (
                    <Select.Option value={object.id} key={object.id}>
                      {object.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={11}>
            <Item label="State" colon={false}>
              {getFieldDecorator("state", {
                rules: [{ required: true, message: "Select State" }],
              })(
                <Select
                  showSearch
                  showAction={["click", "focus"]}
                  disabled={!editable || state.length == 0}
                  placeholder="State"
                  filterOption={filterMethod}
                  onChange={handleStateChange}
                >
                  {state.map((object) => (
                    <Select.Option value={object.id} key={object.id}>
                      {object.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Item label="City" colon={false}>
              {getFieldDecorator("city", {
                rules: [{ required: true, message: "Select City" }],
              })(
                <Select
                  showSearch
                  showAction={["click", "focus"]}
                  disabled={!editable || city.length == 0}
                  placeholder="City"
                  filterOption={filterMethod}
                >
                  {city.map((object) => (
                    <Select.Option key={object.id} value={object.id}>
                      {object.name}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={11}>
            <Item
              label="Pincode"
              colon={false}
              validateStatus={error.PIN && error.PIN.type}
              help={error.PIN && error.PIN.message}
            >
              {getFieldDecorator("pincode", {
                rules: [{ required: true, message: "Enter Pincode" }],
              })(
                <Input
                  disabled={!editable}
                  placeholder="Pincode"
                  maxLength={7}
                  pattern="([0-9]{6}|[0-9]{3}\s[0-9]{3})"
                  onKeyUp={(e) =>
                    form.setFieldsValue({
                      pincode: formatValue(e, "onlyNo"),
                    })
                  }
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        PIN: {
                          type: "error",
                          message: "Enter Valid PIN Code",
                        },
                      });
                    } else {
                      delete error.PIN;
                      setError(error);
                    }
                  }}
                />
              )}
            </Item>
          </Col>
        </Row>

        <Row>
          <Item name="vehicle-manufacturer" label="Vehicle Manufacturer">
            <Checkbox disabled={!editable} checked={vehicleManufacturer} value={vehicleManufacturer} onChange={() => {
              setVehicleManufacturer(!vehicleManufacturer)
            }}>Yes</Checkbox>
          </Item>
        </Row>

      </Form>
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: "normal_login" })(
  ManufacturerForm
);

export default WrappedAccessoriesModal;
