import React, { useState, useEffect } from 'react'
import { Row, Card, Table, Input, Empty, Typography, Col, Select } from 'antd'
import Moment from 'moment'

const AssociatedDocuments = (props) => {
  const { editable, data, setData } = props
  const [dataSource, setDataSource] = useState([])
  const { Option } = Select
  const { TextArea } = Input
  const { Text } = Typography

  useEffect(() => {
    if (data.quotation) {
      const { quotation } = data;
      let tmpQuotation = [];
      Object.keys(quotation).map(key => {
        if (key === "pdfWithBrochure" || key === "pdfWithOutBrochure")
          tmpQuotation.push({
            ...quotation, pdf: quotation[key],
            docID: quotation.quotationId,
            type: key
          })
      })
      setDataSource(tmpQuotation)
    }
    else if (data.booking) {
      const { booking } = data
      let tmpArr = [{
        docID: booking.bookingId,
        type: "Before Verification",
        pdf: booking.authentication.beforeVerification
      },
      {
        docID: booking.bookingId,
        type: "After Verification",
        pdf: booking.authentication.afterVerification
      }]
      setDataSource(tmpArr)
    }
  }, [data])

  const column = [
    {
      title: <Text strong>Document Type</Text>,
      dataIndex: "type",
      key: "type",
      render: (record) => <span>{record.toUpperCase()}</span>,
    },
    {
      title: <Text strong>Document ID</Text>,
      dataIndex: "docID",
      key: "docID",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Status</Text>,
      dataIndex: "pdf",
      key: "pdf",
      render: (record) => <span>{record ? "Generated" : "Initiated"}</span>,
    },
    {
      title: <Text strong>Action</Text>,
      dataIndex: "pdf",
      key: "pdf",
      render: (record) => <a href={record} className="linkylink" target="_blank" disabled={!record}>View</a>,
    },
  ]


  return (
    <div>
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <strong>Activity Session ID: </strong>
          {data ? data.activityId : "-"}</Col>
        <Col span={12}>
          <p>
            <strong>Session Date: </strong>
            {data && data.createdAt ? Moment(data.createdAt).format('DD-MM-YYYY') : ' -'}
          </p>
          <p>
            <strong>Session Time: </strong>
            {data && data.createdAt ? Moment(data.createdAt).format('HH:mm') : ' -'}
          </p>
        </Col>
        <Col span={24}>
          <Text>
            <strong>Documents Generated: </strong>
          </Text>

          <Table
            locale={{
              emptyText: (
                <Empty
                  imageStyle={{
                    height: 25,
                    fontSize: 30,
                  }}
                  description={(
                    <Text disabled strong>
                      No Messages
                    </Text>
                  )}
                // image={<Icon type="ContainerOutlined" />}
                />
              ),
            }}
            rowKey={(record) => record.id}
            pagination={false}
            size="small"
            columns={column}
            dataSource={dataSource}
          />
        </Col>
      </Row>
    </div>
  )
}

export default AssociatedDocuments;