import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
  Modal,
  Form,
  Select,
  Input,
  Icon,
  InputNumber,
} from "antd";
import "./index.less";
import { platformApi } from "../../../../../api";
const { Title } = Typography
const { Item } = Form
const { Option } = Select

const SIForm = ({
  visible,
  setVisible,
  getPSI,
  form
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  const [error, setError] = useState({});
  const [searchPart, setSearchPart] = useState()
  const [selectedPart, setSelectedPart] = useState()
  const [branches, setBranches] = useState()
  const [branchData, setBranchData] = useState([])
  // const partRef = useRef(null);
  let partsRef = {};
  useEffect(() => {
    if (visible) {
      const search = {
        module: "partsMasters",
        column: "partNumber",
        fields: ["partNumber, partName"],
        searchString: "",
        searchColumns: ["partNumber"],
        setValue: setSearchPart,
      };
      searchParts(search)
      getBranches()
    }
  }, [visible])
  // console.log("branches", branchData)

  const getBranches = () => {
    platformApi.get("/api/sparesInventory/branch/all").then((res) => {
      const { data } = res;
      if (data.code === 200) {
        setBranches(data.response.data)
        console.log("All branch", data.response.data)
        let branches = []
        for (let item of data.response.data) {
          let d = {
            branch: item.id,
            branchName: item.name,
            accQuantity: "",
            phyQuantity: "",
            binNum: ""
          }
          branches.push(d)
        }
        setBranchData(branches)
      }
    })
      .catch((err) => {

        console.error("Branch Fetch Error: ", err);
        message.error("Couldn't Fetch Branch");
      });
  }



  const clearFields = () => {
    setVisible(false)
    setError({})
    form.resetFields()
    setSelectedPart()
    setBranchData([])
    setBranches()
  }
  const handleSubmit = () => {
    validateFields((err) => {
      if (err == null) {
        platformApi.post("/api/sparesInventory", {
          part: selectedPart,
          branch: branchData
        }).then((res) => {
          console.log(res)
          message.success("Spare Created Succesfuly")
          getPSI()
          clearFields()
        })
      }
    })
  }


  const columns = [
    {
      title: "Branch Name",
      dataIndex: "branchName",
      key: "branchName",
      render: (record, index) => {
        return (
          <span>{record}</span>
        )
      },
    },
    {
      title: "Bin Location",
      dataIndex: "binNum",
      key: "binNum",
      render: (record, data, index) => {
        return (
          <Input
            placeholder="BIN Location"
            value={record}
            ref={el => partsRef[index] = el}
            name="binNum"
            onChange={(e) => { editBranchData(e, data) }}
          />
        )
      }
    },
    {
      title: "Accounting Qty",
      dataIndex: "accQuantity",
      key: "accQuantity",
      render: (record, data) => {
        return (
          <Input
            placeholder="Accounting Qty"
            value={record}
            name="accQuantity"
            onChange={(e) => { editBranchData(e, data) }}
          />
        )
      }
    },
    {
      title: "Physical Qty",
      dataIndex: "phyQuantity",
      key: "phyQuantity",
      render: (record, data) => {
        return (
          <Input
            placeholder="Physical Qty"
            value={record}
            name="phyQuantity"
            onChange={(e) => { editBranchData(e, data) }}
          />
        )
      }
    },
  ]

  const editBranchData = (e, data) => {
    let allData = [...branchData]
    for (let item of allData) {
      if (data.branch == item.branch) {
        data[e.target.name] = e.target.value
        break;
      }
    }
    setBranchData(allData)
  }

  const searchParts = (search) => {
    // let data = { module: 'partsMasters', size: 100, column: 'number', fields: ['number', 'name'], searchString: e }
    platformApi.post('/api/options/get', {
      module: search.module,
      column: search.column,
      searchString: search.searchString,
      fields: search.fields,
      size: 100,
      searchColumns: search.searchColumns,
    }).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        search.setValue(data.response);
      }
    })
      .catch((err) => {
        console.error("Part Fetch Error: ", err);
        message.error("Couldn't Fetch Parts");
      });
  }

  const selectingPart = (e) => {
    for (let item of searchPart) {
      if (item.id === e) {
        setSelectedPart(item)
        break
      }
    }
    platformApi.post("/api/sparesInventory/getPart", {
      partNo: e
    })
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let data = []
            // console.log(response.data)

            for (let item of response.data.sparesInventory) {
              let filterData = response.data.sparesInventory.filter((i) => i.partNo.id == item.partNo.id)
              let fl = true;
              for (let item of data) {
                for (let item2 of filterData) {
                  if (item2.id == item[0].id) {
                    fl = false
                    break
                  }
                }
                if (fl == false) {
                  break
                }
              }
              if (fl) {
                data.push(filterData)
              }
            }
            let finalData = []
            if (data.length) {
              for (let item of data) {
                if (item.length == 1) {
                  let newData = item[0]
                  newData.branch = [{
                    partId: newData.partNo.id,
                    sparesId: item[0].id,
                    branch: item[0].branch,
                    binNum: item[0].binNum,
                    reorderQuantity: item[0].reorderQuantity,
                    maxStock: item[0].maxStock,
                    minStock: item[0].minStock,
                    reorderLevel: item[0].reorderLevel,
                    phyQuantity: item[0].phyQuantity,
                    accQuantity: item[0].accQuantity,
                    transactions: item[0].transactions
                  }]
                  finalData.push(newData)
                }
                else {
                  let newData = item[0]
                  let branch = []
                  let transactions = {
                    sparesPurchase: [],
                    sparesSale: []
                  }
                  for (let d of item) {
                    branch.push({
                      partId: d.partNo.id,
                      sparesId: d.id,
                      branch: d.branch,
                      binNum: d.binNum,
                      reorderQuantity: d.reorderQuantity,
                      maxStock: d.maxStock,
                      minStock: d.minStock,
                      reorderLevel: d.reorderLevel,
                      phyQuantity: d.phyQuantity,
                      accQuantity: d.accQuantity,
                      transactions: d.transactions
                    })
                    if (d.transactions) {
                      transactions.sparesPurchase = [...transactions.sparesPurchase, ...d.transactions.sparesPurchase]
                      transactions.sparesSale = [...transactions.sparesSale, ...d.transactions.sparesSale]
                    }
                  }
                  newData.transactions = transactions
                  newData.branch = branch
                  finalData.push(newData)
                }
              }
            }
            // console.log("NewData", finalData[0].branch)
            let branches = []
            if (finalData.length) {
              for (let item of finalData[0].branch) {
                let d = {
                  branch: item.branch.id,
                  branchName: item.branch.name,
                  accQuantity: item.accQuantity ? item.accQuantity : null,
                  phyQuantity: item.phyQuantity ? item.phyQuantity : null,
                  binNum: item.binNum ? item.binNum : null
                }
                branches.push(d)
              }
              setBranchData(branches)
            }
            // setData(finalData)
          } else {
            message.error('Unable to fetch partdetails', 2);
          }
        } else {
          message.error('Unable to fetch partdetails', 2);
        }
      })
      .catch((err) => {
        console.error("PSI Fetch Error: ", err);
        message.error("Couldn't Fetch PSI");
      });
  }



  return (
    <Modal
      wrapClassName="pmc-form"
      visible={visible}
      title="Add Spares"
      okButtonProps={{}}
      onCancel={() => { clearFields() }}
      onOk={handleSubmit}
      okText={"Save"}
    >
      <Form>
        <Row>
          <Col span={11}>
            <Item
              label="Part No"
            >
              {getFieldDecorator("part", {
                rules: [{ required: true, message: "Select Part" }]
              })(
                <Select
                  dropdownStyle={{
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                  showSearch
                  // menuIsOpen={menuIsOpen}
                  showAction={["click", "focus"]}
                  filterOption={null}
                  onSearch={(e) => {
                    const search = {
                      module: "partsMasters",
                      column: "partNumber",
                      fields: ["partNumber, partName"],
                      searchString: e,
                      searchColumns: ["partNumber"],
                      setValue: setSearchPart,
                    };
                    searchParts(search)
                    if (searchPart?.length === 1) {
                      selectingPart(searchPart[0].id)
                      // partRef?.current?.focus()
                      console.log(partsRef)
                      partsRef[0].focus()
                    }
                  }}
                  onSelect={(e) => { selectingPart(e) }}

                  placeholder="Part Number"
                  style={{ width: '100%' }}
                >
                  {searchPart && searchPart.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.partNumber}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={1} />
          <Col span={11}>
            <Item
              label="Part Name"
            >
              <Input
                onKeyUp={(e) =>
                  form.setFieldsValue({
                    partNo: formatValue(e, "toUpperCase"),
                  })
                }
                disabled={true}
                value={selectedPart && selectedPart.partName}
                placeholder="Part No"
              />
            </Item>
          </Col>
        </Row>
        <Row>
          <Table
            style={{ marginTop: '1rem' }}
            columns={columns}
            tableLayout="fixed"
            rowKey={(record, _, index) => index}
            dataSource={branchData}
          />
        </Row>
      </Form>
    </Modal>
  )
}

const WrappedSIForm = Form.create({ name: "SIForm" })(SIForm);
export default WrappedSIForm