import React, { useContext, useState, useEffect } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
} from "antd";
import "./index.less";
import { platformApi } from "../../../../api";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../../ContextAPI";
import { getUUID } from '../../../../_helpers/utils'

const { Text, Paragraph } = Typography;

const EmployeeTable = ({
  pagination,
  dataSource,
  history,
  loggedIn,
  setPrimaryData,
  spinner,
  setModalData,
  setVisible,
  printAccess
}) => {
  const { goBack } = history;
  const { loginCredintials } = useContext(ContextAPI);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [tableKey, setTableKey] = useState(getUUID())

  useEffect(() => {
    // console.log("loginCredintials", loginCredintials)
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "employee") {
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
  }, []);

  const showDetails = (values, index) => {
    const deleteData = (id, index) => {
      platformApi
        .delete(`/api/user/${id}`)
        .then((res) => {
          if (res.data.code === 200) {
            setPrimaryData();
            message.success("Employee Deleted Successfully");
          }
        })
        .catch((err) => {
          message.error("Employee couldnt be deleted");
          console.error("Delete Employee Error :", err);
        });
    };
    return (
      <div>
        <Row gutter={16}>
          <Col span={4}>
            <Text type="secondary">Father's Name</Text>
            <Text className="align-employeeDetails">
              {values && values.profile && values.profile.fatherName ? values.profile.fatherName : 'XXXXXXXXXXX'}
            </Text>
          </Col>
          <Col span={5}>
            <Text type="secondary">DOB</Text>
            <Text className="align-employeeDetails">
              {values &&
                values.profile && values.profile.dateOfBirth ?
                new Date(values.profile.dateOfBirth).toLocaleDateString() : 'XXXXX'}
            </Text>
          </Col>
          <Col span={5}>
            <Text type="secondary">Blood Group</Text>
            <Text className="align-employeeDetails">
              {values && values.profile && values.profile.bloodGroup ? values.profile.bloodGroup : 'XXXX'}
            </Text>
          </Col>
          <Col span={5}>
            <Text type="secondary">Employee Id</Text>
            <Text className="align-employeeDetails">
              {values && values.profile?.employeeId}
            </Text>
          </Col>
          <Col span={5}>
            <Text type="secondary">Status</Text>
            <Text className="align-employeeDetails">
              {values && values.status ? "Active" : "Inactive"}
            </Text>
          </Col>
        </Row>
        <Row gutter={16} className="row-employeeSpace">
          <Col span={4}>
            <Text type="secondary">Pan Number</Text>
            <Paragraph
              copyable={values.profile.documents[0] && values.profile.documents[1].typeValue !== null}
              disabled={values.profile.documents[0] && values.profile.documents[1].typeValue === null}
            >
              {(values &&
                values.profile &&
                values.profile.documents[1] &&
                values.profile.documents[1].typeValue) ||
                "XXXXXXXXXXX"}
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary">Aadhar Card</Text>

            <Paragraph
              copyable={values.profile.documents[0] && values.profile.documents[2].typeValue !== null}
              disabled={values.profile.documents[0] && values.profile.documents[2].typeValue === null}
            >
              {(values &&
                values.profile &&
                values.profile.documents[2] &&
                values.profile.documents[2].typeValue) ||
                "XXXXXXXXXXXX"}
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary">Driving License</Text>
            <Paragraph
              copyable={values.profile.documents[0] && values.profile.documents[0].typeValue !== null}
              disabled={values.profile.documents[0] && values.profile.documents[0].typeValue === null}
            >
              {(values &&
                values.profile &&
                values.profile.documents[0] &&
                values.profile.documents[0].typeValue) ||
                "XXXXXXXXXXX"}
            </Paragraph>
          </Col>
          <Col span={5}>
            <Text type="secondary">Bank Account Details</Text>

            <Text className="align-employeeDetails">
              Name :{" "}
              {values &&
                values.profile &&
                values.profile.bankDetails &&
                values.profile.bankDetails.accountName ?
                values.profile.bankDetails.accountName :
                "XXXXX"}
            </Text>
            <Text className="align-employeeDetails">
              A/C No :{" "}
              {values &&
                values.profile &&
                values.profile.bankDetails &&
                values.profile.bankDetails.accountNumber ?
                values.profile.bankDetails.accountNumber :
                "XXXXX"}
            </Text>
            <Text className="align-employeeDetails" style={{ textTransform: "uppercase" }}>
              IFSC :{" "}
              {values &&
                values.profile &&
                values.profile.bankDetails &&
                values.profile.bankDetails.ifsc ?
                values.profile.bankDetails.ifsc :
                'XXXXX'}
            </Text>
          </Col>
          <Col span={5}>
            <Text type="secondary">Address</Text>

            <Text className="align-employeeDetails">
              {`${values &&
                values.profile &&
                values.profile.address &&
                values.profile.address.street
                ?
                values.profile.address.street
                :
                'XXXXX'
                },${values &&
                  values.profile &&
                  values.profile.address &&
                  values.profile.address.locality ?
                  values.profile.address.locality :
                  "XXXXX"
                },${values &&
                  values.profile &&
                  values.profile.address &&
                  values.profile.address.district.name ?
                  values.profile.address.district.name :
                  'XXXXX'
                },${values &&
                  values.profile &&
                  values.profile.address &&
                  values.profile.address.state.name ?
                  values.profile.address.state.name :
                  "XXXXX"
                },${values &&
                  values.profile &&
                  values.profile.address &&
                  values.profile.address.country.name ?
                  values.profile.address.country.name :
                  "XXXXX"
                }`}
            </Text>
          </Col>
        </Row>
        <Row gutter={16} className="row-employeeSpace">
          <Col span={4}>
            <Text type="secondary">Aadhar Card</Text>
            <a
              download="filename"
              href={
                values &&
                values.profile &&
                values.profile.documents[2] &&
                values.profile.documents[2].files &&
                values.profile.documents[2].files.url
              }
              target="_blank"
              className="align-employeeDetails"
            >
              <Button
                disabled={values.profile.documents[2] && values.profile.documents[2].files.url ? false : true}
                disabled={!printAccess}
                type="primary"
                icon="download"
                size="small"
                target="_blank"
              >
                Download
              </Button>
            </a>
          </Col>
          <Col span={5}>
            <Text type="secondary">Pan Card</Text>
            <a
              href={
                values &&
                values.profile &&
                values.profile.documents[1] &&
                values.profile.documents[1].files &&
                values.profile.documents[1].files.url
              }
              target="_blank"
              className="align-employeeDetails"
              download
            >
              <Button
                disabled={values.profile.documents[1] && values.profile.documents[1].files.url ? false : true}
                disabled={!printAccess}
                type="primary"
                icon="download"
                size="small"
              >
                Download
              </Button>
            </a>
          </Col>
          <Col span={5}>
            <Text type="secondary">Driving License</Text>
            <a
              href={
                values &&
                values.profile &&
                values.profile.documents[0] &&
                values.profile.documents[0].files &&
                values.profile.documents[0].files.url
              }
              target="_blank"
              className="align-employeeDetails"
              download
            >
              <Button
                disabled={values.profile.documents[0] && values.profile.documents[0].files.url ? false : true}
                disabled={!printAccess}
                type="primary"
                icon="download"
                size="small"
              >
                Download
              </Button>
            </a>
          </Col>
          <Col span={5}>
            <Text type="secondary">Pass Book</Text>
            <a
              href={
                values &&
                values.profile &&
                values.profile.documents[3] &&
                values.profile.documents[3].files &&
                values.profile.documents[3].files.url
              }
              target="_blank"
              className="align-employeeDetails"
              download
            >
              <Button
                disabled={values.profile.documents[3] && values.profile.documents[3].files.url ? false : true}
                disabled={!printAccess}
                type="primary"
                icon="download"
                size="small"
              >
                Download
              </Button>
            </a>
          </Col>

          <Col span={4}>
            {modifyAccess ? (
              <span
                className="linkylink"
                onClick={(e) => {
                  setModalData(values);
                  setVisible(true);
                }}
              >
                Modify
              </span>
            ) : (
                <span></span>
              )}
            {modifyAccess && deleteAccess ? (
              <Divider type="vertical" />
            ) : null}
            {loggedIn === values.id ? (
              <span></span>
            ) : deleteAccess ? (
              <span>
                {/* {" "} */}
                <Popconfirm
                  title="Are you sure delete this Employee?"
                  onConfirm={() => deleteData(values.id)}
                >
                  <span className="linkylink">Delete</span>
                </Popconfirm>
              </span>
            ) : (
                  <span></span>
                )}
            {!modifyAccess && !deleteAccess ? (
              <Text strong disabled>
                No Actions Given
              </Text>
            ) : (
                <span></span>
              )}
          </Col>
          {/* {loginCredintials.department && loginCredintials.department.othersAccess && loginCredintials.department && loginCredintials.department.role != "CEO" && <div>
            <Popconfirm
              title="Are you sure Deactivate this Employee?"
              onConfirm={() => {
                setAssign(true)
                setAssignData(values.id)
              }}
            >
              <Button type="danger" size="small">
                Deactivate
              </Button>
            </Popconfirm>
          </div>} */}
        </Row>
      </div>
    );
  };

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Profile Picture",
      dataIndex: "profilePicture",
      key: "image",
      render: (url) => (
        <span>
          <Avatar icon={!url && "user"} src={url && url} size={48} />
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "profile",
      key: "name",
      render: (text) => <span>{text && text.employeeName ? text.employeeName : 'XXXXXXXXX'}</span>,
    },
    {
      title: "Mobile Number",
      dataIndex: "phone",
      key: "phone",
      render: (text) => <span>{text && text}</span>,
    },
    {
      title: "Alternate Number",
      dataIndex: "phone2",
      key: "number",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Branch",
      dataIndex: "profile",
      key: "branch",
      render: (text) => text && text.branch.map((value) => <Tag color="#006a71">{value.name}</Tag>),
    },
    {
      title: "Department",
      dataIndex: "profile",
      key: "department",
      render: (text) => <span>{text && text.department && text.department.role}</span>,
    },
    {
      title: "Joined On",
      dataIndex: "profile.dateOfJoining",
      key: "createdOn",
      render: (text) => (
        <span>
          {new Date(text).toLocaleDateString("en-GB", {
            day: "numeric",
            month: "short",
            year: "numeric",
          })}
        </span>
      ),
    },
    // {},
  ];

  function onExpandChangeRemoveChild(expanded, record) {
    if (!expanded) {
      const EventStyleEle = document?.querySelectorAll(`tr.ant-table-expanded-row.ant-table-expanded-row-level-1`)
      EventStyleEle.length && Array(EventStyleEle.length).fill().map((_, i) => {
        if (EventStyleEle.item(i).getAttribute('data-row-key').split('-')[0] === record.id) {
          EventStyleEle.item(i).remove()
          setTableKey(getUUID())
        }
      });
    } else {
      const EventStyleEle = document?.querySelectorAll(`tr.ant-table-expanded-row.ant-table-expanded-row-level-1`)
      EventStyleEle.length && Array(EventStyleEle.length).fill().map((_, i) => EventStyleEle.item(i).style.display = 'none')
      const EventStyleEleStyle = document?.querySelectorAll(`tr.ant-table-row.ant-table-row-level-0`)
      EventStyleEleStyle.length && Array(EventStyleEleStyle.length).fill().map((_, i) => {
        if (i % 2) {
          EventStyleEleStyle.item(i).style.backgroundColor = '#a8afb3';
        } else {
          EventStyleEleStyle.item(i).style.backgroundColor = '#f4f7fb'
        }
      })
    }
  }

  return (
    <Table
      rowKey={(record) => record.id}
      key={tableKey}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      // expandIcon={CustomIcon}
      loading={spinner}
      expandIconAsCell={false}
      expandIconColumnIndex={7}
      expandedRowRender={(values, index) => showDetails(values, index)}
      expandRowByClick
      onExpand={(expanded, record) => onExpandChangeRemoveChild(expanded, record)}
      style={{ cursor: "pointer" }}
    />
  );
};

export default withRouter(EmployeeTable);
