import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button, Input, Typography, message, Tooltip, Icon, Dropdown, Menu
} from 'antd';
import {
  LeftOutlined
} from '@ant-design/icons';
import './index.less';
import { platformApi } from '../../../api';
import SupplierMasterTable from './SupplierMasterTable';
import SupplierMasterForm from './SupplierMasterForm';
import WrappedAdvancedFilter from "./AdvancedFilters";
import { ContextAPI } from "../../../ContextAPI";
import { setConfig } from 'react-hot-loader';

const { Search } = Input;
const { Title } = Typography;
const { Item } = Menu;

const SupplierMaster = (props) => {
  const [visible, setVisible] = useState(false);
  const [values, setValues] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [editable, setEditable] = useState(false);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false)
  const [deleteAccess, setDeleteAccess] = useState(false)
  const { loginCredintials } = useContext(ContextAPI)
  //for filters
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState([]);
  const [filteropen, setFilteropen] = useState(false);
  const [delData, setDelData] = useState([]);
  const [delbankData, setBankDelData] = useState([]);


  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  useEffect(() => {
    loginCredintials.roleAccess && loginCredintials.roleAccess.map(each => {
      if (each.subModule === 'supplier_master') {
        if (each.access.create) {
          setCreateAccess(true)
        }
        if (each.access.update) {
          setModifyAccess(true)
        }
        if (each.access.delete) {
          setDeleteAccess(true)
        }
      }
    })
    setPrimaryData()
  }, []);

  useEffect(() => {
    setPrimaryData(search)
  }, [page, limit, search])

  const setPrimaryData = (search, val) => {
    setTableLoading(true);
    platformApi
      .post('/api/supplier/get', { page: val ? val : page, size: limit, searchString: search })
      .then((res) => {
        let { data } = res
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {

            setDataSource(response.data.supplier);
            setCount(response.data.count);
          }
        } else {
          message.error('Cannot fetch Supplier Data');
        }

        setTableLoading(false);
      })
      .catch((err) => {
        console.error('Supplier fetch error', err);
        message.error('Cannot fetch Supplier Data');
        setTableLoading(false);
      });
  }




  const deleteSupplier = (id) => {
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    setTableLoading(true);
    platformApi.delete(`/api/supplier/${id}`)
      .then((res) => {
        if (res.data.code === 200) {
          if (temp) {
            setPage(1)
            setPrimaryData(null, 1)
          }
          else {
            setPrimaryData();
          }
          message.success('Supplier Data Deleted');
          setTableLoading(false);
        } else {
          message.error('Supplier Data not Deleted');
          setTableLoading(false);
        }
      })
      .catch((err) => {
        console.error('Supplier Delete Error:', err);
        message.error('Connection Error');
        setTableLoading(false);
      });
  };

  return (
    <div>
      <div className="supplierMaster-top">
        <Title style={{ width: '60%' }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Supplier Master[{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          placeholder="Search Supplier"
          className="searchbar-div-manufacturer"
          style={{ width: '30%' }}
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        {/* <div style={{width:"20%",textAlign:"center"}}>
          <Button type="link"onClick={()=>{filteropen?setFilteropen(false):setFilteropen(true)}}>
            { filteropen?"Back":"Advanced  Filters"}
          </Button>
        </div> */}
        <Button
          type="primary"
          className="add-manufacturer-button"
          onClick={() => { setVisible(true); setValues(null); setEditable(false); }}
          style={{ margin: '0 3%', width: '14%' }}
          disabled={!createAccess}
        >
          Add Supplier
        </Button>
      </div>
      <SupplierMasterForm
        open={visible}
        close={() => {
          setVisible(false) 
          setDelData([]);
          setBankDelData([]);
        }}
        setDelData={setDelData}
        delData={delData}
        setBankDelData={setBankDelData}
        delbankData={delbankData}
        setDataSource={setDataSource}
        dataSource={dataSource}
        values={values}
        editable={editable}
        setValues={setValues}
        setCount={setCount}
        count={count}
      />
      <div style={{ margin: '.5rem' }} />
      <div style={{ margin: '.5rem' }}>
        <WrappedAdvancedFilter
          filteropen={filteropen}
          searchName={search}
          close={setFilteropen}
          id={filter}
          setId={setFilter}
          setData={setDataSource}
          setPrimaryData={setPrimaryData}
        />
        <SupplierMasterTable
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: limit,
            defaultCurrent: 1,
            total: count,
            current: page,
            showQuickJumper: true,
          }}
          dataSource={dataSource}
          deleteSupplier={deleteSupplier}
          tableLoading={tableLoading}
          setVisible={setVisible}
          setValues={setValues}
          setEditable={setEditable}
          modify={modifyAccess}
          deleteAccess={deleteAccess}

        />
      </div>
    </div>
  );
};

export default withRouter(SupplierMaster);
