import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { Row, Col } from 'antd';
import Text from "antd/lib/typography/Text";
import { platformApi } from "../../../../api";
import "./service-rem-report.css"

const ServiceRem = (props) => {

    const { timeline, fromDate, toDate } = props;

    const [service1done, setService1done] = useState(0);
    const [service6done, setService6done] = useState(0);
    const [service1due, setService1due] = useState(0);
    const [service6due, setService6due] = useState(0);

    const [overAllServiceDone, setOverAllServiceDone] = useState(0);
    const [overAllServiceDue, setOverAllServiceDue] = useState(0);

    const [overAllRatio, setOverAllRatio] = useState(0);
    const [firstServiceRatio, setFirstServiceRatio] = useState(0);
    const [sixthServiceRatio, setSixthServiceRatio] = useState(0);


    useEffect(() => {
        platformApi.post("/api/reports/vehicleServiceData", {
            timeline: timeline,
            from: fromDate,
            to: toDate
        }).then(res => {
            let { data } = res
            setService1done(data.response?.data?.service_1_done || 0)
            setService6done(data.response?.data?.service_6_done || 0)
            setService1due(data.response?.data?.service_1_due || 0)
            setService6due(data.response?.data?.service_6_due || 0)
            setOverAllServiceDone(data.response?.data?.OverAll_service_done || 0)
            setOverAllServiceDue(data.response?.data?.overAll_Service_due || 0)

            let overAlldue = data.response?.data?.overAll_Service_due || 0;
            let overAlldone = data.response?.data?.OverAll_service_done || 0;
            let totalRatio = (overAlldone / overAlldue) * 100;

            setOverAllRatio(Math.floor(totalRatio))



            let firstServicedue = data.response?.data?.service_1_due || 0;
            let firstServicedone = data.response?.data?.service_1_done || 0;
            let firstRatio = (firstServicedone / firstServicedue) * 100;

            setFirstServiceRatio(Math.floor(firstRatio))


            let sixtServicedue = data.response?.data?.service_6_due || 0;
            let sixtServicedone = data.response?.data?.service_6_done || 0;
            let sixtRatio = (sixtServicedone / sixtServicedue) * 100;

            setSixthServiceRatio(Math.floor(sixtRatio))


            let arr = []
            arr.push({
                name: "service done",
                data: [
                    data.response?.data?.service_1_done || 0,
                    data.response?.data?.service_6_done || 0
                ],
            })

            arr.push({
                name: "service due",
                data: [
                    data.response?.data?.service_1_due || 0,
                    data.response?.data?.service_6_due || 0
                ],
            })

            setserviceSeries(arr)
        }).catch(err => {
            console.log(err);
        })
    }, [timeline, fromDate, toDate])

    const [serviceSeries, setserviceSeries] = useState([
        {
            name: "service done",
            data: [0, 0],
        },
        {
            name: "service due",
            data: [0, 0],
        },
    ]);
    const serviceOptions = {
        chart: {
            id: "apexchart-example",
            type: "line",
            stacked: false,
            stackType: '100%',
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [
                "1st service",
                "6th service"
            ]
        },
    };
    return (
        <div>
            <div style={{ marginBottom: '5px' }}>
                <Text style={{ fontWeight: "bold" }}>
                    Service Remainder Report
                </Text>
            </div>
            <div className="service-rem-report-main" style={{ border: "1px solid black", display: "flex", gap: 20 }}>

                <div>
                    <Row gutter={20}>
                        <Chart
                            options={serviceOptions}
                            series={serviceSeries}
                            type="bar"
                            width={320}
                            height={340}
                        />
                    </Row>
                </div>

                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", gap: 10 }}>


                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                        <div style={{ marginTop: '10px' }}>
                            <Col span={12} style={{ marginBottom: '20px', marginTop: '10px', display: 'flex', gap: '30px', flexDirection: 'column' }}>
                                <Row style={{
                                    background: "white",
                                    // padding: "0px 10px",
                                    textAlign: "center",
                                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    height: "14vh",
                                    width: "30vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: 'center',
                                    justifyContent: "center"
                                }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#A8AFB3",
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    >
                                        Overall Service Due
                                    </Text>
                                    {overAllServiceDue}
                                </Row>

                                <Row style={{
                                    background: "white",
                                    // padding: "0px 10px",
                                    textAlign: "center",
                                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    height: "14vh",
                                    width: "30vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: 'center',
                                    justifyContent: "center"
                                }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#A8AFB3",
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    >
                                        Overall Service Done
                                    </Text>
                                    {overAllServiceDone}
                                </Row>

                                <Row style={{
                                    background: "white",
                                    // padding: "0px 10px",
                                    textAlign: "center",
                                    boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                    height: "14vh",
                                    width: "30vh",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: 'center',
                                    justifyContent: "center"
                                }}
                                >
                                    <Text
                                        style={{
                                            padding: "5px 4px ",
                                            color: "#A8AFB3",
                                            textAlign: "center",
                                            width: "100%",
                                        }}
                                    >
                                        Overall Service Ratio %

                                    </Text>

                                    {overAllRatio}
                                </Row>
                            </Col>
                        </div>
                    </div>


                    <div>

                        <Row type="flex" style={{ marginBottom: '30px', marginTop: '10px', gap: '10px' }}>

                            <Col style={{
                                background: "white",
                                // padding: "0px 10px",
                                textAlign: "center",
                                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                height: "14vh",
                                width: "25vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'center',
                                justifyContent: "center"

                            }}
                            >
                                <Text
                                    style={{
                                        padding: "5px 4px ",
                                        color: "#A8AFB3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    First Service Due
                                </Text>

                                {service1due}
                            </Col>

                            <Col style={{
                                background: "white",
                                // padding: "0px 10px",
                                textAlign: "center",
                                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                height: "14vh",
                                width: "25vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'center',
                                justifyContent: "center"
                            }}
                            >
                                <Text
                                    style={{
                                        padding: "5px 4px ",
                                        color: "#A8AFB3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    First Service Done
                                </Text>

                                {service1done}
                            </Col>

                            <Col style={{
                                background: "white",
                                // padding: "0px 10px",
                                textAlign: "center",
                                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                height: "14vh",
                                width: "25vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'center',
                                justifyContent: "center"
                            }}
                            >
                                <Text
                                    style={{
                                        padding: "5px 4px ",
                                        color: "#A8AFB3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    First FSCR Ratio %
                                </Text>
                                {firstServiceRatio || 0}
                            </Col>

                        </Row>

                        <Row type="flex" style={{ marginBottom: '20px', marginTop: '10px', gap: '10px' }}>

                            <Col style={{
                                background: "white",
                                // padding: "0px 10px",
                                textAlign: "center",
                                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                height: "14vh",
                                width: "25vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'center',
                                justifyContent: "center"
                            }}
                            >
                                <Text
                                    style={{
                                        padding: "5px 4px ",
                                        color: "#A8AFB3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    Sixth Service Due
                                </Text>
                                {service6due}
                            </Col>

                            <Col style={{
                                background: "white",
                                // padding: "0px 10px",
                                textAlign: "center",
                                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                height: "14vh",
                                width: "25vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'center',
                                justifyContent: "center"
                            }}
                            >
                                <Text
                                    style={{
                                        padding: "5px 4px ",
                                        color: "#A8AFB3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    Sixth Service Done
                                </Text>
                                {service6done}
                            </Col>

                            <Col style={{
                                background: "white",
                                // padding: "0px 10px",
                                textAlign: "center",
                                boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.13)",
                                height: "14vh",
                                width: "25vh",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: 'center',
                                justifyContent: "center"
                            }}
                            >
                                <Text
                                    style={{
                                        padding: "5px 4px ",
                                        color: "#A8AFB3",
                                        textAlign: "center",
                                        width: "100%",
                                    }}
                                >
                                    Sixth FSCR Ratio %
                                </Text>
                                {sixthServiceRatio || 0}
                            </Col>
                        </Row>


                    </div>

                </div>

            </div>
        </div>
    )
}
export default ServiceRem
