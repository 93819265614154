import React, { useContext, useState, useEffect } from "react";
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
} from "antd";
import { platformApi } from "../../../../api";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../../ContextAPI";
import { getUUID } from '../../../../_helpers/utils'
const{Text}=Typography;

const EmployeeTableSales = ({
  dataSource,
  pagination,
  spinner,
  modify,
  deleteData,
  setVisible,
  setEditable,
  setEditableData,modifyAccess,deleteAccess
}) => {
  const [tableKey, setTableKey] = useState(getUUID())

  const calculateTarget = (data) => {
    let ans = ""
    for (let item of data) {
      ans += item.targetType.slice(0, 3) + "-"
      if (item.timeline == "Monthly") {
        let s = 0
        for (let mdata of Object.keys(item.monthly)) {
          s += parseInt(item.monthly[mdata])
        }
        ans += s + " "
        if (item.value == "Units") {
          ans += "Units"
        }
        else if (item.value == "Value") {
          ans += "Value"
        }
        ans += "/M "
      }
      else {
        let s = 0
        for (let ydata of Object.keys(item.yearly)) {
          s += parseInt(item.yearly[ydata])
        }
        ans += s + " "
        if (item.value == "Units") {
          ans += "Units"
        }
        else if (item.value == "Value") {
          ans += "Value"
        }
        ans += "/Y"

      }
      ans += " , "
    }
    return ans.slice(0, ans.length - 2)
  }

  const columns = [
    {
      title: "Employee ID",
      dataIndex: "employee",
      key: "employee",
      render: (record) => (
        <span>
          {record && record.profile?.employeeId}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "employee",
      key: "name",
      render: (text) => <span>{text && text.profile && text.profile.employeeName ? text.profile.employeeName : 'XXXXXXXXX'}</span>,
    },
    {
      title: "Department",
      dataIndex: "employee",
      key: "department",
      render: (text) => <span>{text && text.profile && text.profile.department && text.profile.department.role}</span>,
    },
    {
      title: "Target",
      dataIndex: "targetData",
      key: "targetData",
      render: (record) => <span>{calculateTarget(record)}</span>
    },
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div>
          {modifyAccess?

          
          <span
            onClick={(event) => {
              event.stopPropagation();
              setEditable(true)
              setVisible(true)
              setEditableData(data)
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
          <span></span>
    }
          {deleteAccess?
            <Popconfirm
              title="Are you sure delete this Vehicle?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                <Divider type="vertical" />
                  Delete
                 </span>
            </Popconfirm>
            :
            <span></span>
          }
          {
            !modifyAccess && !deleteAccess
            ?
            <Text strong disabled> No Access Given</Text>
            :
            <span></span>
          }
        </div>
      ),
    },
  ];
  return (
    <Table
      dataSource={dataSource}
      columns={columns}
      pagination={pagination}
      loading={spinner}
      onRow={(data) => ({
        onClick: () => {
          setVisible(true)
          setEditableData(data)
        }
      })}
    />
  )
}

export default withRouter(EmployeeTableSales)