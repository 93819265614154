import React, { useState, useEffect } from 'react'
import { useHistory, withRouter } from "react-router-dom";
import {
    Modal,
    Select,
    Form,
    Row,
    Col,
    Typography,
    message,
    Button,
} from "antd";
import { platformApi } from "../../../../api";
// import './index.less'

const { Text, Title } = Typography;
const { Option } = Select;

const EmployeeTerminate = ({
    form,
    open,
    close,
    data,
    employee,
    setNewEmployee,
    editData,
    editable,
    modify,
}) => {
    const history = useHistory();
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [error, setError] = useState({});
    const [ecid, setEcid] = useState('');

    useEffect(() => {
        if (employee) {
            // console.log("Get Data", data, employee)
        }
    }, [employee, data])

    const handleEmployee = (event) => {
        setEcid(event)
        setFieldsValue({
            employee: event
        })
    }

    const handleSubmit = () => {
        form.validateFields(async (validationError) => {
            if (validationError === null) {
                if (data) {
                    // console.log("submit", data.id, getFieldValue("employee"), ecid)
                    setNewEmployee(getFieldValue("employee") ? getFieldValue("employee") : mcid)
                    close();
                }
            }
        });
    };
    const clearFieldsAndClose = () => {
        form.resetFields();
        setEcid('');
        close();
    };
    return (
        <Modal
            title={<Title level={4}>Employee ReAssignment</Title>}
            visible={open}
            onCancel={clearFieldsAndClose}
            okText={!editable ? "Modify" : "Save"}
            okButtonProps={{ disabled: (!editable && !modify) }}
            onOk={() => (editable ? handleSubmit() : setEditable(true))}
        >
            <Form className="purchase-form">
                <Row>
                    <Col span={12}>
                        <Text strong>

                        </Text>
                    </Col>
                </Row>
                <Row type="flex" justify="space-between">
                    <Col span={24}>
                        <Form.Item
                            className="purchase-form-element"
                            colon={false}
                            required
                            validateStatus={error.EMPLOYEE && error.EMPLOYEE.type}
                            help={error.EMPLOYEE && error.EMPLOYEE.message}
                            label={<Text>Employee</Text>}
                            colon={false}
                        >
                            {getFieldDecorator("employee", {
                                rules: [
                                    {
                                        required: true,
                                        message: "Select Employee",
                                    },
                                ],
                            })(
                                <Select
                                    dropdownStyle={{
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                    showSearch
                                    showAction={["click", "focus"]}
                                    disabled={!editable}
                                    onChange={(event) => {
                                        handleEmployee(event)
                                    }}
                                    placeholder="Employee"
                                >
                                    {employee.length && employee.map((obj) => (
                                        <Select.Option key={obj.id} value={obj.id}>
                                            {obj.profile && obj.profile.employeeName ? obj.profile.employeeName : obj.profile.employeeId}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}

const WrappedEmployeeTerminate = Form.create("employee_terminate")(
    EmployeeTerminate
);

export default WrappedEmployeeTerminate;