import React, { useEffect, useState, useContext } from "react";
import {
    Form,
    Select,
    Typography,
    Row,
    Col,
    Button,
    message,
    Input,
    AutoComplete,
} from "antd";
import WrappedVehicleList from "./VehicleList";
import { platformApi } from "../../../../../api";
import WrappedVehicleForm from "../../../Vehicles/VehicleForm";
import AddCustomerModal from "../../../CustomerDetails/CustomerDetailsForm";
import WrappedCustomerUpdateModal from "./UpdateCustomer";
import WrappedCustomerAddModal from './AddCustomer';
import { ContextAPI } from "../../../../../ContextAPI";
import { useBranch } from "../../../../../ContextAPI/branch";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;
const VehicleDetails = ({
    form,
    validityRef,
    data,
    clearRef,
    open,
    editable,
    setVehicleList,
    vehicleList,
    setCurrent,
    current,
    setVFlag,
    recvData,
    setSelectedNumber,
    selectedNumber,
    selectedPhone,
    setSelectedPhone,
    numberList,
    setNumberList,
    chassisList,
    setChassisList,
    SelectedVehicle,
    setSelectedVehicle,
    vF,
    setVf,
    nF,
    setNf,
    setCf,
    cF,
    setUpdate,
    NumberData,
    setNumberData,
    setDetails,
    Details,
    vehicleData,
    setVehicleData,
    vehicleNo,
    setVehicleNo,
    changed
    // onSelect
}) => {
    const { loginCredintials } = useContext(ContextAPI)
    const { selectBranch } = useBranch()
    const { getFieldDecorator, setFieldsValue,getFieldValue } = form;
    const [uploading, setUploading] = useState(false)
    const [branch, setBranch] = useState({id: "", name: ""})
    const [cid, setCid] = useState('');
    const [jobNo, setjobNo] = useState("");
    const [customer, setCustomer] = useState("");
    const [Open, setOpen] = useState("");
    const [bname, setBname] = useState(false);
    const [flag, setflag] = useState(false);
    const [pflag, setpFlag] = useState(false);
    const [searchflag, setSearchFlag] = useState(false);
    const [searchVflag, setsearchVflag] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpen2, setModalOpen2] = useState(false);
    const [selected, setSelected] = useState(null);
    const [cusId, setCusId] = useState("");
    const [dataSource, setData] = useState([]);
    const [vehicle, setVehicle] = useState("");
    const [number, setNumber] = useState("");
    const [chassis, setChassis] = useState("");
    const [item, setItem] = useState();
    const [dFlag, setDflag] = useState(true)
    const [valid, setValid] = useState(null)
    const [temp, setTemp] = useState(false)
    const [name, setName] = useState("")
    const [mdfy, setMdfy] = useState(false);

    useEffect(() => {
        Details.push(setSelectedVehicle([]));
        Details.push(setNumberList([]));
        Details.push(setVehicleList([]));
        setDetails(Details);
    }, [Details]);

    useEffect(() => {
        clearRef.current = () => {
            form.resetFields();
        };
        validityRef.current = () => {
            return validatePage();
        };
        return () => {
            validityRef.current = null;
            clearRef.current = null;
        };
    });
    const validatePage = () => {
        let isValid;
        form.validateFields((validationError) => {
            isValid = validationError === null;
        });
        if (isValid) {
            data.cusId = data.cusId ? data.cusId : cid ? cid : selectedNumber
            data.branch = branch.id
            data.Vehicle = SelectedVehicle;
            // data.jobNo = id;
            data.dateTime = new Date();
            if (data.Vehicle.length > 1) {
                setVFlag(false);
            }
            else {
                data.selectVehicle = SelectedVehicle[0];
                setVFlag(true);
            }
        }
        return isValid;
    };
    useEffect(() => {
        form.setFieldsValue({
            vehicleNo: data.vehicle,
            mobileNo: data.id,
            ChassisNo: data.chassisNo
        });
        getVehicles();
        const search = {
            module: "customers",
            column: "phone",
            fields: ["contacts{phone,id,valid}"],
            setValue: setNumberList,
        };
        getNumbers(search);
    }, [open]);
    useEffect(() => {
        if (recvData.vehicle) {
            console.log("Receive Data", recvData)
            setVf(!editable);
            setNf(!editable);
            setCf(!editable);
            setjobNo(recvData.jobNo);
            setDflag(false)
            setSelectedNumber(recvData.customer.id)
            setSelectedPhone(recvData.customerPhone)
            // setCid(recvData.customer && recvData.customer.id)
            let str = `${recvData.customer.name} - ${recvData.customerPhone}`;
            form.setFieldsValue({
                vehicleNo: recvData.vehicle.registerNo,
                mobileNo: str,
                ChassisNo: recvData.vehicle.chassisNo
            });
            setSelectedVehicle([recvData.vehicle]);
        }
        if(recvData.branch) {
          setBranch({
            id: recvData.branch.id,
            name: recvData.branch.name
          })
        }else {
          setBranch({
            id: selectBranch[0].id,
            name: selectBranch[0].name
          })
        }
    }, [recvData, editable]);
    useEffect(() => {
        // console.log("178----->", data);
        if (data.selectVehicle) {
            setSelectedVehicle([data.selectVehicle]);
            form.setFieldsValue({
                vehicleNo: data.selectVehicle.id,
                ChassisNo: data.selectVehicle.id
            });
            if (data.cusId) {
                // let selectcustomer = numberList.filter(item => {
                //     return item.id === data.cusId
                // })
                // console.log("87687", selectcustomer)
                form.setFieldsValue({
                    mobileNo: data.cusId
                })
            }
            else {
                let arr = [];
                data.selectVehicle.customer.map((data) => {
                    arr.push(data.customer);
                });
                setNumberList(arr);
                form.setFieldsValue({
                    mobileNo: data.customer ?
                        data.customer.customer ?
                            data.customer.customer.id
                            : data.customer.id
                        : data.selectVehicle.customer[0].customer.id,
                });
            }
            setNf(true);
            setVf(true);
            setCf(true);
        } else if (data.Vehicle) {
            setSelectedVehicle(data.Vehicle);
            form.setFieldsValue({
                vehicleNo: data.selectVehicle.id,
            });
            let arr = [];
            data.Vehicle.map((data) => {
                data.customer.map((item) => {
                    arr.push(item.customer);
                });
            });
            setNumberList(arr);
            if (arr.length === 1) {
                form.setFieldsValue({
                    mobileNo: arr[0].id,
                });
            } else {
                let temp = [];
                arr.map((item) => {
                    temp.push(item.contacts[0].phone);
                });
                form.setFieldsValue({
                    mobileNo: arr[0].id,
                });
            }
        }
    }, [data]);

    const handleVehicleChange = (e) => {
        platformApi.get("/api/vehicle/vehicleNum/" + e).then((result) => {
        });
        let vehicle = vehicleList[vehicleList.findIndex((obj) => obj.id === e)];
    };
    const getVehicles = () => {
        platformApi
            .post("/api/vehicle/getSome", {
                size: 100,
                page: 1,
            })
            .then(async (result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        await setVehicleList(response.data);
                        setChassisList(response.data);
                        data.vehicleNo && handleVehicleChange(data.vehicleNo);
                    }
                }
            });
    };
    const getNumbers = (search) => {
        platformApi
            .post("/api/options/get/", {
                module: search.module,
                column: search.column,
                searchString: search.searchString,
                fields: search.fields,
                size: 100,
                page: 1,
                except: null,
            })
            .then((res) => {
                search.setValue(res.data.response);
            });
    };
    const getChassis = (search) => {
        platformApi
            .post("/api/options/get/", {
                module: search.module,
                column: search.column,
                searchString: search.searchString,
                fields: search.fields,
                size: 100,
                page: 1,
                except: null,
            })
            .then((res) => {
                search.setValue(res.data.response);
            });
    }
    const searchVehicle = (search) => {
        platformApi
            .post("/api/options/get/", {
                module: search.module,
                column: search.column,
                searchString: search.searchString,
                fields: search.fields,
                size: 100,
                page: 1,
                except: null,
            })
            .then((res) => {
                search.setValue(res.data.response);
            });
    };
    const addData = (formData, vehicle) => {
        setSpinning(true);
        if (vehicle.id) {
            // console.log("Inside Update");
            for (let item of Array.from(formData.keys())) {
                // console.log(item, formData.get(item), "item");
            }
            platformApi
                .put("/api/vehicle/" + vehicle.id, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((result) => {
                    setSpinning(false);
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            setVehicleList([response.data, ...vehicleList]);
                            setVehicleData([response.data]);
                            form.setFieldsValue({
                                vehicleNo: response.data.id
                            })
                            setVf(true)
                            // setSelectedNumber(arr[0].contacts[0].phone);
                            setNf(true)
                            data.vehicleNo = vehicleNo
                            message.success("Vehicle saved successfully", 2);
                        } else {
                            message.error("Unable to save vehicle", 2);
                        }
                    } else {
                        message.error("Unable to save vehicle", 2);
                    }
                })
                .catch((error) => {
                    setSpinning(false);
                    console.error("Error on Vehicles : ", error);
                    message.error("Unable to save vehicle", 2);
                });
        } else {
            // console.log(vehicle);
            platformApi
                .post("/api/vehicle", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((result) => {
                    setSpinning(false);
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        // console.log("Response!!!", response)
                        if (response.code === 200) {
                            setSelectedVehicle([response.data]);
                            setVehicleList([response.data, ...vehicleList]);
                            setVehicleData([response.data])
                            setChassisList([response.data])
                            let arr = [];
                            response.data.customer.map((item) => {
                                arr.push(item.customer);
                            });
                            setNumberList(arr);
                            setNumberData(response.data.customer);
                            form.setFieldsValue({
                                vehicleNo: response.data.id,
                                mobileNo: arr[0].id,
                                ChassisNo: response.data.id
                            })
                            setVf(true)
                            setSelectedNumber(arr[0].id);
                            setNf(true)
                            setCf(true)
                            data.vehicleNo = vehicleNo;
                            message.success("Vehicle added successfully", 2);
                        } else {
                            message.error("Unable to add vehicle", 2);
                        }
                    } else {
                        message.error("Unable to add vehicle", 2);
                    }
                })
                .catch((error) => {
                    setSpinning(false);
                    console.error("Error on Vehicles : ", error);
                    message.error("Unable to add vehicle", 2);
                });
        }
    };
    const addCustomerData = async (customer) => {
        await platformApi
            .post("/api/customer", customer)
            .then((result) => {
                const { data } = result;
                if (data.code === 200) {
                    const { response } = data;
                    if (response.code === 200) {
                        setNumberList([response.data, ...numberList]);
                        let phone = response.data.contacts[0].phone
                        setSelectedPhone(phone);
                        form.setFieldsValue({
                            mobileNo: response.data.id
                        })
                        setCid(response.data.id)
                        setSelectedNumber(response.data.id);
                        if (!SelectedVehicle.length > 0) {
                            fetchnewVehicle(form.getFieldValue("mobileNo"));
                        }
                        else {
                            // let cusData = []
                            // NumberData.map(item => {
                            //     cusData.push({
                            //         id: item.id,
                            //         customer: item.customer.id
                            //     })
                            // })
                            // cusData.push({
                            //     id: '',
                            //     customer: response.data.id
                            // })
                            // let formData = new FormData()
                            // const vehicleData = {
                            //     customer: cusData
                            // }
                            // formData.append("vehicleData", JSON.stringify(vehicleData))
                            // platformApi
                            //     .put("/api/vehicle/" + SelectedVehicle[0].id, formData, {
                            //         headers: { "Content-Type": "multipart/form-data" },
                            //     })
                            //     .then((result) => {
                            //         setSpinning(false);
                            //         let { data } = result;
                            //         if (data.code === 200) {
                            //             let { response } = data;
                            //             if (response.code === 200) {
                            //                 message.success("Vehicle saved successfully", 2);
                            //                 platformApi
                            //                     .post("/api/vehicle/getVehicle", {
                            //                         vehicle: form.getFieldValue("vehicleNo"),
                            //                     })
                            //                     .then((result) => {
                            //                         let { data } = result;
                            //                         if (data.code === 200) {
                            //                             let { response } = data;
                            //                             if (response.code === 200) {
                            //                                 let arr = [];
                            //                                 response.data.customer.map((item) => {
                            //                                     arr.push(item.customer);
                            //                                 });
                            //                                 // setNumberList(arr);
                            //                                 setNumberData(response.data.customer);
                            //                                 setChassisList([response.data])
                            //                                 setSelectedVehicle([response.data]);
                            //                             }
                            //                         } else {
                            //                             message.error("No Vehicles Found!!!");
                            //                         }
                            //                     });
                            //                 // setSelectedNumber(response.data.id);
                            //                 setNf(true);
                            //                 setpFlag(true);
                            //                 data.cusId(selectedNumber)
                            //                 setTemp(false);
                            //             } else {
                            //                 message.error("Unable to save vehicle", 2);
                            //             }
                            //         } else {
                            //             message.error("Unable to save vehicle", 2);
                            //         }
                            //     })
                            //     .catch((error) => {
                            //         setSpinning(false);
                            //         console.error("Error on Vehicles : ", error);
                            //         message.error("Unable to save vehicle", 2);
                            //     });
                            // fetchnewVehicle(form.getFieldValue("mobileNo"));
                        }
                        message.success("Customer added successfully", 2);
                    } else {
                        message.error("Unable to add customer", 2);
                    }
                } else if (data.code === 500 && data.err.code === 500) {
                    message.error("Customer name already exists", 2);
                } else {
                    message.error("Unable to add customer", 2);
                }
            })
            .catch((error) => {
                message.error("Unable to add customer", 2);
                console.error("Error on customer : ", error);
            });
    };
    const updateCustomerData = (customer) => {
        platformApi
            .put(`/api/customer/${customer.id}`, customer)
            .then((result) => {
                setSpinning(false);
                const { data } = result;
                if (data.code === 200) {
                    const { response } = data;
                    if (response.code === 200) {
                        message.success("Customer Updated successfully", 2);
                        let phone = response.data.contacts[response.data.contacts.length - 1].phone
                        let str = `${response.data.name} - ${phone}`;
                        setSelectedPhone(phone);
                        console.log(response.data.id)
                        setSelectedNumber(response.data.id);
                        data.cusId = response.data.id;
                        platformApi
                            .post("/api/vehicle/getVehicle", {
                                vehicle: form.getFieldValue("vehicleNo"),
                            })
                            .then((result) => {
                                let { data } = result;
                                if (data.code === 200) {
                                    let { response } = data;
                                    if (response.code === 200) {
                                        let arr = [];
                                        response.data.customer.map((item) => {
                                            item.customer.contacts = item.customer.contacts.reverse();
                                            arr.push(item.customer);
                                        });
                                        setNumberList([arr, ...numberList]);
                                        setNumberData(response.data.customer);
                                        // setSelectedVehicle([response.data]);
                                        form.setFieldsValue({
                                            mobileNo: str,
                                        });
                                        setNf(true);
                                        setpFlag(true);
                                        setTemp(false);
                                    }
                                } else {
                                    message.error("No Vehicles Found!!!");
                                }
                            });
                    } else {
                        message.error("Unable to update customer.", 2);
                    }
                } else {
                    message.error("Unable to update customer", 2);
                }
            })
            .catch((error) => {
                setSpinning(false);
                message.error("Unable to update customer");
                console.error("Error on Customer : ", error);
            });
    }
    const fetchnewVehicle = (value) => {
        // setSelectedNumber(value)
        platformApi
            .post("/api/vehicle/getNumberVehicle", {
                mobileNo: value,
            })
            .then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        let arr = [];
                        response.data.map((item) => {
                            arr.push(item);
                        });
                        if (arr.length === 1) {
                            form.setFieldsValue({
                                vehicleNo: arr[0].id,
                                ChassisNo: arr[0].id
                            });
                        } else if (arr.length > 1) {
                            form.setFieldsValue({
                                vehicleNo: arr[0].id,
                                ChassisNo: arr[0].id
                            });
                        }
                        setNf(true);
                        setVehicleList(response.data);
                        setChassisList(response.data)
                        setSelectedVehicle(response.data);
                        setsearchVflag(true);
                    }
                } else {
                    message.error("No Vehicles Found!!!");
                }
            });
    }
    const fetchVehicle = (value) => {
        setVehicleNo(value);
        if (nF) {
            let temp = false;
            for (let i = 0; i < vehicleData.length; i++) {
                if (vehicleData[i].id === value) {
                    temp = true;
                    break;
                }
                else {
                    temp = false;
                }
            }
            if (!temp) {
                data.vehicleNo = value;
            }
        }
        if (SelectedVehicle.length > 0 || !editable) {
            return;
        }
        platformApi
            .post("/api/vehicle/getVehicle", {
                vehicle: value,
            })
            .then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        let arr = [];
                        response.data.customer.map((item) => {
                            item.customer.contacts = item.customer.contacts.reverse();
                            arr.push(item.customer);
                        });
                        setNumberList(arr);
                        setChassisList([response.data])
                        setNumberData(response.data.customer);
                        form.setFieldsValue({
                            ChassisNo: response.data.chassisNo
                        })
                        if (arr.length === 1) {
                            if (arr[0].contacts[0].valid) {
                                setValid(true)
                            }
                            else {
                                setValid(false)
                            }
                            form.setFieldsValue({
                                mobileNo: arr[0].id
                            });
                        }
                        else if (arr.length > 1) {
                            form.setFieldsValue({
                                mobileNo: arr[0].id,
                            });
                        }
                        setSelectedVehicle([response.data]);
                        setVf(true);
                        // setSearchFlag(true)
                    }
                } else {
                    message.error("No Vehicles Found!!!");
                }
            });
    };
    const fetchnumberVehicle = (value) => {
        setSelectedNumber(value)
        setCustomer(value)
        let nameCheck = numberList.find(item => item.id === value)
        if (!nameCheck.name) {
            setTemp(true);
            setName(true)
        }
        if (vF) {
            let temp = false;
            for (let i = 0; i < NumberData.length; i++) {
                if (NumberData[i].customer.id === value) {
                    temp = true;
                    break;
                }
                else {
                    temp = false;
                }
            }
            if (!temp) {
                data.cusId = value;
                let valid;
                for (let item of numberList) {
                    if (item.id === value) {
                        valid = item.contacts[0].valid
                        break
                    }
                }
                if (!valid) {
                    setTemp(true);
                    setBname(true);
                }
                // else {
                //     setBname(false);
                // }
            }
        }
        if (SelectedVehicle.length > 0 || !editable) {
            return;
        }
        platformApi
            .post("/api/vehicle/getNumberVehicle", {
                mobileNo: value,
            })
            .then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        let arr = [];
                        response.data.map((item) => {
                            arr.push(item);
                        });
                        if (arr.length === 1) {
                            form.setFieldsValue({
                                vehicleNo: arr[0].id,
                                ChassisNo: arr[0].id
                            });
                        } else if (arr.length > 1) {
                            form.setFieldsValue({
                                vehicleNo: arr[0].id,
                                ChassisNo: arr[0].id
                            });
                        }
                        setNf(true);
                        console.log("response.data", response.data)
                        setVehicleData(response.data)
                        setVehicleList(response.data);
                        setChassisList(response.data)
                        setSelectedVehicle(response.data);
                        // setsearchVflag(true);
                        let valid;
                        for (let item of numberList) {
                            if (item.id === value) {
                                valid = item.contacts[0].valid
                                break
                            }
                        }
                        if (!valid) {
                            setTemp(true);
                            setBname(true);
                        }
                        // else {
                        //     setBname(false);
                        // }
                    }
                } else {
                    message.error("No Vehicles Found!!!");
                }
            });
    };
    const fetchChassis = (value) => {
        if (SelectedVehicle.length > 0 || !editable) {
            return;
        }
        platformApi
            .post("/api/vehicle/getChassis", {
                vehicle: value,
            })
            .then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        let arr = [];
                        response.data.customer.map((item) => {
                            item.customer.contacts = item.customer.contacts.reverse();
                            arr.push(item.customer);
                        });
                        setNumberList(arr);
                        setNumberData(response.data.customer);
                        if (arr.length === 1) {
                            if (arr[0].contacts[0].valid) {
                                setValid(true)
                            }
                            else {
                                setValid(false)
                            }
                            form.setFieldsValue({
                                mobileNo: arr[0].id,
                                vehicleNo: response.data.id
                            });
                        }
                        else if (arr.length > 1) {
                            form.setFieldsValue({
                                mobileNo: arr[0].id,
                                vehicleNo: response.data.id
                            });
                        }
                        if (!response.data.registerNo) {
                            setChassis(response.data)
                        }
                        else {
                            setChassis("")
                        }
                        setVehicleData([response.data])
                        setVehicleList([response.data])
                        setSelectedVehicle([response.data]);
                        setCf(true);
                    }
                } else {
                    message.error("No Vehicles Found!!!");
                }
            });
    }
    const addNewCustomer = (value) => {
        let cusData = []
        NumberData.map(item => {
            cusData.push({
                id: item.id,
                customer: item.customer.id
            })
        })
        cusData.push({
            id: '',
            customer: value
        })
        let formData = new FormData()
        const vehicleData = {
            customer: cusData
        }
        formData.append("vehicleData", JSON.stringify(vehicleData))
        platformApi
            .put("/api/vehicle/" + SelectedVehicle[0].id, formData, {
                headers: { "Content-Type": "multipart/form-data" },
            })
            .then((result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        message.success("Vehicle saved successfully", 2);
                        setUpdate(true);
                        platformApi
                            .post("/api/vehicle/getVehicle", {
                                vehicle: form.getFieldValue("vehicleNo"),
                            })
                            .then((result) => {
                                let { data } = result;
                                if (data.code === 200) {
                                    let { response } = data;
                                    if (response.code === 200) {
                                        let arr = [];
                                        response.data.customer.map((item) => {
                                            arr.push(item.customer);
                                        });
                                        setNumberList(arr);
                                        setNumberData(response.data.customer);
                                        setSelectedVehicle([response.data]);
                                    }
                                } else {
                                    message.error("No Vehicles Found!!!");
                                }
                            });
                        // setSelectedNumber(response.data.id);
                        setNf(true);
                    } else {
                        message.error("Unable to save vehicle", 2);
                    }
                } else {
                    message.error("Unable to save vehicle", 2);
                }
            })
            .catch((error) => {
                console.error("Error on Vehicles : ", error);
                message.error("Unable to save vehicle", 2);
            });
    }
    const clearImmediate = () => {
        const search = {
            module: "customers",
            column: "phone",
            fields: ["contacts{phone,id,valid}"],
            setValue: setNumberList
        };
        form.resetFields();
        setSelectedVehicle([]);
        setNumberList([]);
        setVehicleList([]);
        setChassisList([]);
        setNf(false);
        setVf(false);
        setCf(false);
        getVehicles();
        getNumbers(search);
        setSelected(null)
        setflag(false)
        setpFlag(false)
        setTemp(false)
        setSearchFlag(false)
        setsearchVflag(false)
        setVehicle("");
        setCustomer("");
        setChassis("");
        setBname(false);
        setName(false);
        setCid('');
    };
    const onSelect = (item) => {
        data.selectVehicle = item;
        setItem(item);
        validatePage();
        setCurrent(current + 1);
    };
    const checkCustomer = () => {
        let valid
        let numberDatas
        if (selectedNumber) {
            for (let item of numberList) {
                if (item.id == selectedNumber) {
                    valid = item.contacts[0].valid
                    numberDatas = item
                    break
                }
            }
        }
        else {
            valid = numberList[0].contacts[0].valid
            numberDatas = numberList[0]
        }
        if (!valid) {
            setSelected(numberDatas)
            setModalOpen2(true)
        }
        else {
            if (!numberDatas.name) {
                setSelected(numberDatas)
                setModalOpen2(true)
            }
            else {
                setSelected(null)
                setModalOpen(true)
            }
        }
    };

    return (
        <>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "Center" }}>
                <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
                    <Row type="flex">
                      <Col span={8}>
                        {
                          selectBranch.length < 2 ? (
                          <Form.Item
                            className="purchase-form-element"
                            label="Branch"
                            name="branch"
                            required
                            disabled={true}
                          >
                            <Input
                              placeholder="Branch Name"
                              className="form-input"
                              disabled={true}
                              value={branch.name}
                            />
                          </Form.Item>
                        ) : (
                          <Form.Item
                            className="purchase-form-element"
                            label="Branch"
                            name="branch"
                            required
                          >
                            <Select
                              dropdownStyle={{
                                overflowY: "auto",
                                overflowX: "hidden",
                              }}
                              // value={editable ? editData && editData.customer.name : partyName}
                              value={branch.id}
                              onChange={(id) => {
                                console.log({id});
                                setBranch({id, name: ""});
                              }}
                              showAction={["click", "focus"]}
                              showSearch
                              disabled={nF || changed}
                              placeholder="Branch"
                              filterOption={null}
                            >
                            {selectBranch.map((eachBranch) => {
                              return (
                                <Select.Option key={eachBranch.id}>
                                  {eachBranch.name}
                                </Select.Option>
                              );
                            })}
                          </Select>
                        </Form.Item>
                          )
                        }
                      </Col>
                    </Row>
                    <Row type="flex" gutter={[8, 8]}>
                        <Col span={8}>
                            <Form.Item colon={false} label={<Text>Vehicle No.</Text>}>
                                {getFieldDecorator("vehicleNo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Select Vehicle No",
                                        },
                                    ],
                                })(
                                    <Select
                                        showAction={["click", "focus"]}
                                        showSearch
                                        filterOption={null}
                                        placeholder="Vehicle No."
                                        onSearch={(e) => {
                                            if (!searchVflag) {
                                                const search = {
                                                    module: "vehicles",
                                                    column: "registerNo",
                                                    searchString: e,
                                                    searchColumns: ["registerNo"],
                                                    setValue: setVehicleList
                                                };
                                                setVehicle(e)
                                                searchVehicle(search);
                                            }
                                        }}
                                        disabled={vF || changed}
                                        onSelect={fetchVehicle}
                                        notFoundContent={
                                            <Button
                                                type="primary"
                                                style={{
                                                    justifyContent: 'center',
                                                    width: "100%"
                                                }}
                                                onClick={() => {
                                                    setOpen(true);
                                                    setMdfy(true);
                                                }}
                                            >
                                                Add Vehicle
                                            </Button>
                                        }
                                    >
                                        {vehicleList &&
                                            vehicleList.map(
                                                (data) =>
                                                    (data.registerNo != null && data.registerNo.length > 0)
                                                    && (
                                                        <Select.Option
                                                            key={data.registerNo}
                                                            value={data.id}
                                                        >
                                                            {data.registerNo}
                                                        </Select.Option>
                                                    )

                                            )}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item colon={false} label={<Text>Mobile No.</Text>}>
                                {getFieldDecorator("mobileNo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Select Mobile No",
                                        },
                                    ],
                                })(
                                    <Select
                                        showSearch
                                        showAction={["click", "focus"]}
                                        // style={{ width: "300px" }}
                                        placeholder="Mobile No."
                                        filterOption={null}
                                        onSearch={(e) => {
                                            if (!searchflag) {
                                                const search = {
                                                    module: "customers",
                                                    column: "phone",
                                                    searchString: e,
                                                    fields: ["contacts{phone,id,valid}"],
                                                    searchColumns: ["contacts"],
                                                    setValue: setNumberList,
                                                };
                                                getNumbers(search);
                                            }
                                            if (e.length === 10) {
                                                setNumber(e)
                                            }
                                        }}
                                        disabled={nF || changed}
                                        onSelect={fetchnumberVehicle}
                                        notFoundContent={
                                            <Button
                                                type="primary"
                                                style={{
                                                    justifyContent: 'center',
                                                    width: "100%"
                                                }}
                                                onClick={() => setModalOpen(true)}
                                            >
                                                Add Customer
                                                </Button>
                                        }
                                    >
                                        {numberList.map(
                                            (data) =>
                                                data.contacts && (
                                                    <Select.Option
                                                        value={data ? data.id : ""}
                                                        key={data.id}
                                                    >
                                                        {data.name}
                                                        {data.name && data.contacts && data.contacts[0]
                                                            ? " - "
                                                            : ""}{" "}
                                                        {data.contacts && data.contacts[0]
                                                            ? data.contacts[0].phone
                                                            : ""}
                                                    </Select.Option>
                                                )
                                        )}
                                    </Select>
                                )}
                            </Form.Item>
                            {SelectedVehicle.length > 0 && temp && numberList.length > 0 ?
                                <Button
                                    type="primary"
                                    style={{ width: "60%", marginTop: '.5rem' }}
                                    onClick={() => checkCustomer()}

                                >
                                    {
                                        name ? "Update Name"
                                            : bname ? "Update Customer" : "Add Customer"
                                    }
                                </Button> : null}
                        </Col>
                        <Col span={8}>
                            <Form.Item colon={false} label={<Text>Chassis No.</Text>}>
                                {
                                    getFieldDecorator("ChassisNo", {
                                        rules: [
                                            {
                                                required: false,
                                                message: "Select Chassis No"
                                            }
                                        ]
                                    })(
                                        <Select
                                            showAction={["click", "focus"]}
                                            showSearch
                                            placeholder="Chassis No."
                                            filterOption={null}
                                            onSearch={(e) => {
                                                if (!searchflag) {
                                                    const search = {
                                                        module: "vehicles",
                                                        column: "chassisNo",
                                                        searchString: e,
                                                        searchColumns: ["chassisNo"],
                                                        setValue: setChassisList
                                                    };
                                                    getChassis(search);
                                                }
                                            }}
                                            disabled={cF || changed}
                                            onSelect={fetchChassis}
                                            notFoundContent={
                                                <Button
                                                    type="primary"
                                                    style={{
                                                        justifyContent: 'center',
                                                        width: "100%"
                                                    }}
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setMdfy(true);
                                                    }}
                                                >
                                                    Add Vehicle
                                                </Button>
                                            }
                                        >
                                            {chassisList &&
                                                chassisList.map(
                                                    (data) =>
                                                        (data.chassisNo != null && data.chassisNo.length > 0)
                                                        && (
                                                            <Select.Option
                                                                key={data.chassisNo}
                                                                value={data.id}
                                                            >
                                                                {data.chassisNo}
                                                            </Select.Option>
                                                        )
                                                )}
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
                <div style={{ display: "flex", marginLeft: '20px' }}>
                    {!changed && editable
                        ?
                        <Button
                            type="primary"
                            style={{ width: "130px", marginRight: "20px" }}
                            onClick={clearImmediate}
                        >
                            Clear
                            </Button>
                        : null}
                    {/* {editable ? (
                        <Button
                            style={{ width: "130px", marginRight: "20px" }}
                            type="primary"
                        >
                            Scan QR
                        </Button>
                    ) : null} */}
                </div>
            </div>
            <br />
            {!(SelectedVehicle && SelectedVehicle.length === 0) &&
                (
                    <WrappedVehicleList
                        Vehicle={SelectedVehicle}
                        clearRef={clearRef}
                        onSelect={onSelect}
                        editable={editable}
                    />
                )}
            {/* <WrappedVehicleForm
                open={Open}
                editable={editable}
                emitData={addData}
                vehicle={vehicle}
                customer={customer}
                data={chassis}
                close={() => {
                    setOpen(false);
                    setChassis("");
                    setVehicle("");
                    setCustomer("")
                    getVehicles();
                }}
                edit={editable} 
                setEdit={editable}
            /> */}
            <WrappedVehicleForm
                emitData={addData}
                open={Open}
                editable={editable}
                vehicle={vehicle}
                customer={customer}
                setUploading={setUploading}
                uploading={uploading}
                data={chassis}
                close={() => {
                    setOpen(false);
                    setChassis("");
                    setVehicle("");
                    setCustomer("")
                    getVehicles();
                    setUploading(false);
                }}
                modify={mdfy}
                edit={mdfy}
                setEdit={setMdfy}
                vData={false}
            />
            {/* <AddCustomerModal
                emitData={(data) => addCustomerData(data)}
                open={modalOpen}
                cusId={cusId}
                setCusId={setCusId}
                close={() => {
                    setSelected(null);
                    setModalOpen(false);
                    setpFlag(false);
                }}
                editable={true}
            /> */}
            <WrappedCustomerAddModal
                emitData={(data) => addCustomerData(data)}
                open={modalOpen}
                cusId={cusId}
                setCusId={setCusId}
                number={number}
                close={() => {
                    setNumber("")
                    setSelected(null);
                    setModalOpen(false);
                    setpFlag(false);
                }}
                editable={true}
            />
            <WrappedCustomerUpdateModal
                emitData={(data) => updateCustomerData(data)}
                open={modalOpen2}
                cusId={cusId}
                setCusId={setCusId}
                close={() => {
                    setSelected(null);
                    setModalOpen2(false);
                    setpFlag(false);
                }}
                editable={true}
                setData={setSelected}
                data={selected}
            />
        </>
    );
};
const WrappedVehicleDetails = Form.create()(VehicleDetails);

export default WrappedVehicleDetails;
