import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { message, Tooltip, Button, Typography } from "antd";
import { LeftOutlined } from '@ant-design/icons';

import { platformApi } from '../../../../api';
import WrappedInvoiceForm from './InvoiceForm';

const Invoice = (props) => {
  const { redirect } = props;

  const { Title } = Typography;
  const [customer, setCustomer] = useState([]);
  const [partsData, setPartsData] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editData, setEditData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [searchSupply, setSearchSupply] = useState();
  const [partSearch, setPartSearch] = useState(false);
  const [modifyType, setModifyType] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [jobNo, setJobNo] = useState([]);
  const [jobCode, setJobCode] = useState([]);
  const [initialData, setInitialData] = useState({})
  let url = location.pathname;
  let id = url.substring(url.lastIndexOf('/') + 1);

  useEffect(() => {
    // console.log("id", location, id)
    if (id != ':id') {
      platformApi
        .get(`/api/saleSpareInvoice/${id}`)
        .then((res) => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setInitialData(response.data)
            } else {
              message.error("Unable to fetch Sale Spare Item", 2);
            }
          } else {
            message.error("Unable to fetch Sale Spare Item", 2);
          }
        })
        .catch((error) => {
          // setSpinning(false);
          message.error("Unable to fetch Sale Spare Item", 2);
        });
      if (location.state && location.state.editable) {
        setEditable(false)
      }
      else {
        setEditable(true);
      }
    }
    else {
      setEditable(false);
    }
    getCustomerData();
    getPartsDetails();
    getJobCodes();
    getJobNo();
    getSelectedSpareInvoice();
  }, [id]);

  useEffect(() => {
    if (search) {
      getPartsDetailOnSearch(search);
    }
    else {
      getPartsDetails();
    }
  }, [search]);

  const getCustomerData = () => {
    platformApi
      .post('/api/customer/get', { page: page, size: limit, searchString: searchSupply })
      .then((res) => {
        let { data } = res
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let activeCustomer = response.data.customer
            setCustomer(activeCustomer)
          }
        } else {
          message.error('Cannot fetch Customer Data');
        }

        //setTableLoading(false);
      })
      .catch((err) => {
        console.error('Customer fetch error', err);
        message.error('Cannot fetch Customer Data');
        //setTableLoading(false);
      });
  }

  const getPartsDetails = () => {
    platformApi
      .post(`/api/sparesInventory/branch/get/`, {
        page: page,
        size: 100,
        searchString: ''
      })
      .then((res) => {
        //setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            // console.log("response",response.data)
            let parts = []
            for (let item of response.data) {
              let unitRate = 0
              item.transactions && item.transactions.sparesPurchase.map(item2 => {
                item2.purchaseItemInvoice.map(item3 => {
                  if (item.partNo.id === item3.partNumber.id) {
                    unitRate += item3.unitRate
                  }
                })
              })
              item.partNo.unitRate = unitRate
              parts.push(item.partNo)
            }
            setPartsData(parts);
          }
        }
        else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        // setSpinning(false);
        message.error("Unable to fetch Parts", 2);
      });
  }

  const getPartsDetailOnSearch = async (search) => {
    await platformApi
      .post("/api/saleSpareInvoice/search", {
        search
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            console.log("search", response.data.sparesInventory)
            // setPartsData(response.data.sparesInventory);
          }
        } else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        // setSpinning(false);
        message.error("Unable to fetch Parts", 2);
      });

  }

  const getJobCodes = () => {
    platformApi
      .post("/api/jobCode/getJobCodes", {
        size: 100,
        page: 1,
      })
      .then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setJobCode(response.data);
          } else {
            message.error("Failed to fetch JobCodes");
          }
        }
        else {
          message.error("Failed to fetch JobCodes");
        }
      })
      .catch((error) => {
        console.error("Error on JobCodes : ", error);
        message.error("Failed to fetch JobCodes");
      });
  }

  const getJobNo = () => {
    platformApi
      .post("/api/jobOrder/getJobNo", {
        size: 100,
        page: 1,
        status: "ALL"
      })
      .then((res) => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            // setJobNo(response.data);
            let temp = [];
            temp = response.data.filter(item => {
              return item.jobStatus != "Invoice" || item.jobStatus != "Payment Received" || item.jobStatus != "Gate Pass";
            })
            setJobNo(temp)
          } else {
            message.error("Failed to fetch Job Order");
          }
        }
        else {
          message.error("Failed to fetch Job Order");
        }
      })
      .catch((error) => {
        console.error("Error on Job Order : ", error);
        message.error("Failed to fetch Job Order");
      });
  }

  const getSelectedSpareInvoice = () => {
    if (id === ':id') return;
    platformApi
      .get(`/api/saleSpareInvoice/${id}`)
      .then((res) => {
        //setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setEditData(response.data)
          } else {
            message.error("Unable to fetch Sale Spare Item", 2);
          }
        } else {
          message.error("Unable to fetch Sale Spare Item", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Sale Spare Item : ", error);
        // setSpinning(false);
        message.error("Unable to fetch Sale Spare Item", 2);
      });
  }

  return (
    <>
      <Title level={4}>
        <Tooltip placement="topLeft" title={"Back"}>
          <Button
            style={{ marginRight: '1rem' }}
            onClick={() => history.push('/autoadmin/counter_sale')}
          >
            <LeftOutlined />
          </Button>
        </Tooltip>
        Counter Sale
      </Title>
      <WrappedInvoiceForm
        customer={customer}
        setValidCustomer={setCustomer}
        partsData={partsData}
        setPartsData={setPartsData}
        editable={editable}
        modifyType={modifyType}
        id={id}
        setSearch={setSearch}
        onEdit={(editable) => {
          setEditable(editable)
          setModifyType(!editable)
        }}
        editData={editData}
        setPartSearch={setPartSearch}
        jobNo={jobNo}
        setJobNo={setJobNo}
        setJobCode={setJobCode}
        jobCode={jobCode}
        initialData={initialData}
      />
    </>
  )
}

export default Invoice