import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  Select,
  Typography,
  Checkbox,
  Upload,
  message,
  Icon,
} from "antd";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { ENDPOINT } from "../../../../constants";

const { Item } = Form;

const { Title, Text } = Typography;

const AccessoriesModal = ({
  form,
  open,
  close,
  data,
  editable: edit,
  emitData,
  modify,
}) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(edit);
  const [error, setError] = useState({});
  const [models, setModel] = useState([]);
  const [vehicleDeleted, setVehicleDeleted] = useState([]);
  const [all, setAll] = useState(false);
  const [loadImage,setLoadImage]=useState(false)
  const [imageUrl,setImageUrl]=useState("")
  const [imageFile,setImageFile]=useState(null)
  const [formData,setFormData]=useState(null)
  const [image,setImage]=useState(null)
  useEffect(() => {
    setEditable(edit);
    if (data) {
      setImage(data.image)
      data.vehicleSuit.map((obj, i) => {
        if (obj.id === models[i] && models[i].id) {
          if (models.length === i + 1) {
            setAll(true);
          }
        }
        else {
          setAll(false);
        }
      })
      form.setFieldsValue({
        code: data.code,
        price: data.price,
        name: data.name,
        model: data.vehicleSuit.map((obj) => obj.id),
      });
    }
  }, [data, edit]);

  useEffect(() => {
    const search = {
      module: "vehicleMasters",
      column: "modelName",
      fields: ["modelCode"],
      searchColumns: ["modelCode"],
      setValue: setModel,
    };
    dropdownSearch(search);
  }, []);


  const dropdownSearch = (search) => {
    platformApi
      .post("/api/options/get", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 0,
        searchColumns: search.searchColumns,
      })
      .then((res) => {
        search.setValue(res.data.response);
      });
  };
  const { getFieldDecorator } = form;
  const deleteVehicle = (id) => {
    const modify = vehicleDeleted;
    modify.push(id);
    setVehicleDeleted(modify);
  };
  const handleSubmit = () => {
    // if(imageFile==null || imageFile.length==0){
    //   message.warning("Please Choose a Accessory Image")
    //   return
    // }
    form.validateFields(async (validationError) => {
      if (validationError === null && !error.CODE) {
        setLoading(true);
        const idmodel = [];
        models.map((model) => idmodel.push(model.id))
        const name = form.getFieldValue("name");
        const code = form.getFieldValue("code");
        const price = parseFloat(form.getFieldValue("price"));
        const createdAt = new Date().toLocaleDateString();
        const vehicleSuit = all ? idmodel : form.getFieldValue("model");
        const vehicleDelete = editable ? vehicleDeleted : undefined;
        const Accessory = {
          code,
          name,
          price,
          vehicleSuit,
          createdAt,
          vehicleDelete,
        };
        data ? (Accessory.id = data.id) : {};
        if(formData && imageFile!=null){
          formData.append("name",name)
          formData.append("code",code)
          formData.append("price",price)
          formData.append("vehicleSuit",vehicleSuit)
          formData.append("createdAt",createdAt)
          formData.append("vehicleDelete",vehicleDelete)

          
          platformApi
            .post("/api/upload/image", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            })
            .then((res) => {
              let { data } = res;
              if (data.code === 200) {
                Accessory.image = data.response.data.Location;
                resolveData(Accessory);
              } else {
                message.error("Upload Not Done");
              }
            })
            .catch((error) => {
              setLoading(false);
              console.error("Error on Accessory Form : ", error);
              message.error("Unable to upload image");
            });

        }
        else {
          resolveData(Accessory);
        }
      }
    });
  };

  const resolveData = (Accessory) => {
      emitData(Accessory);
      clearFields();
      close();
  };

  const clearFields = () => {
    setAll(false)
    setLoading(false);
    form.resetFields();
    setError({})
    setVehicleDeleted([]);
    setImageUrl("");
    setLoadImage(false);
    setImageFile(null);
    setImage(null)
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const selectAll = (event) => {
    setAll(event.target.checked);
    if (event.target.checked) {
      let model = models.map((model) => model.id)
      form.setFieldsValue({
        "model": model
      })
    }
    else {
      form.setFieldsValue({
        "model": undefined
      })
    }
  }

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }
  
  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  }

  const handleChange = info => {
    if (info.file.status === 'uploading') {
      setLoadImage(true)
      return;
    }
    if (info.file.status === 'done') {
      let formData=new FormData()
      setImageFile(info.file)
      formData.append("profile", info.file.originFileObj);
      formData.append("master", "Company Master");
      formData.append("module", "Accessories");
      formData.append("type", "image");
      getBase64(info.file.originFileObj, imageUrl =>{
        setImageUrl(imageUrl)
        setLoadImage(false)
      }
      );
      setFormData(formData);
    }
  };

  const uploadButton = image ? 
                   loadImage ? <LoadingOutlined />: <img style={{ maxWidth: "100%" }} src={image} />
                   : (
                    <div>
                    {loadImage ? <LoadingOutlined /> : <PlusOutlined />}
                    <div style={{ marginTop: 8 }}>Upload</div>
                  </div>
                  )


  return (
    <Modal
      title={<Title level={4}>Accessory Details</Title>}
      visible={open}
      okText={editable ? "Save" : "Modify"}
      onCancel={() => {
        clearFields();
        close();
      }}
      okButtonProps={{ loading, disabled: !editable && !modify || loadImage}}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
    >
      <Form>
        <Row>
              <Col span={7}></Col>
              <Col span={10}>
              <Item
              label="Accessory Image"
              >
              {getFieldDecorator("image", {
                rules: [{ required:false,message: "Choose a Accessory Image" }],
              })(
              <Upload.Dragger
                disabled={!editable}
                name="image"
                listType="picture-card"
                className="image-uploader"
                showUploadList={false}
                action={`${ENDPOINT}api/upload/mocky`}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                accept=".jpg,.png,.jpeg,.gif"
              >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload.Dragger>
              )}
              </Item>
              </Col>
              <Col span={7}></Col>
          </Row>
        <Row type="flex" justify="space-between">
          <Col span={11}>
            <Item
              validateStatus={error.CODE && error.CODE.type}
              help={error.CODE && error.CODE.message}
              label={<Text>Accessory Code</Text>}
              colon={false}
              maxLength={50}
              required
            >
              {getFieldDecorator("code", {
                rules: [{ required: true, message: "Enter Accessory code" }],
              })(
                <Input
                  pattern="^[a-zA-Z0-9]*$"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        CODE: {
                          type: "error",
                          message: "Enter valid Code",
                        },
                      });
                    } else {
                      delete error.CODE;
                      setError(error);
                    }
                  }}
                  disabled={!editable}
                  placeholder="Accessory code"
                />
              )}
            </Item>
          </Col>
          <Col span={11}>
            <Item
              label={<Text>Accessory Price</Text>}
              validateStatus={error.PRICE && error.PRICE.type}
              help={error.PRICE && error.PRICE.message}
              colon={false}
              required>
              {getFieldDecorator("price", {
                rules: [{ required: true, message: "Enter Accessory price" }],
              })(
                <Input
                  addonBefore=" &#8377;"
                  // type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  disabled={!editable}
                  placeholder="Accessory price"
                  pattern="[0-9]+(\.[0-9][0-9][0-9]?)?"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      price: formatValue(e, "noWithDot")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        PRICE: {
                          type: "error",
                          message: "Enter Valid Price"
                        }
                      })
                    } else {
                      delete error.PRICE
                      setError(error)
                    }
                  }}
                />
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={24}>
            <Item
              validateStatus={error.NAME && error.NAME.type}
              help={error.NAME && error.NAME.message}
              label={<Text>Accessory Name</Text>}
              colon={false}
              required
            >
              {getFieldDecorator("name", {
                rules: [{ required: true, message: "Enter Accessory name" }],
              })(<Input
                pattern="^[a-zA-Z]+(([ ][a-zA-Z])?[a-zA-Z]*)*$"
                onKeyUp={e =>
                  form.setFieldsValue({
                    name: formatValue(e, "allCaps")
                  })}
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setError({
                      ...error,
                      NAME: {
                        type: "error",
                        message: "Enter Valid Name"
                      }
                    })
                  } else {
                    delete error.NAME
                    setError(error)
                  }
                }}
                disabled={!editable}
                placeholder="Accessory Name" />)}
            </Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Item
              label={
                <span>
                  <Text>Vehicle Model</Text>{" "}
                  <Checkbox
                    checked={all}
                    onChange={selectAll}
                    // style={{ marginLeft: '17%' }}
                    disabled={!editable}
                  >
                    Select All
                  </Checkbox>
                </span>
              }
              colon={false}
              required
            >
              {getFieldDecorator("model", {
                rules: [{ required: !all, message: "Select a Vehicle model" }],
              })(
                <Select
                  mode="multiple"
                  disabled={!editable || all}
                  filterOption={null}
                  showSearch
                  showAction={["click","focus"]}
                  placeholder="Vehicle Model"
                  onDeselect={(event) => editable && deleteVehicle(event)}
                  onSearch={(e) => {
                    const search = {
                      module: "vehicleMasters",
                      column: "modelName",
                      fields: ["modelCode"],
                      searchString: e,
                      searchColumns: ["modelCode"],
                      setValue: setModel,
                    };
                    dropdownSearch(search);
                  }}
                >
                  {models.map((model) => (
                    <Select.Option key={model.id}>
                      {model.modelCode + "-" + model.modelName}
                    </Select.Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>
        
      </Form>
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: "accessories" })(
  AccessoriesModal
);

export default WrappedAccessoriesModal;
