import React, { useState } from 'react'
import { Form, Row, Col, Modal, Select, Typography, Input, DatePicker, TimePicker } from 'antd';
import { platformApi } from '../../../../api'
import './style.less'
import moment from 'moment'
import { message } from 'antd';
import { withRouter } from 'react-router-dom'

import DiscardModal from '../../DiscardModal'

const { Item } = Form;
const { Text, Title } = Typography;
const { TextArea } = Input
const { Option } = Select
const AddActivityModal = (props) => {
  const { visibility, setVisibility, customerDetail, getActivityByCustomer, form } = props

  const defaultValue = {
    remarks: "",
    enquiryType: "",
    interactionType: "WALK IN",
    followUpDate: null,
    followUpTime: null,
  }
  const [activityData, setActivityData] = useState(defaultValue)
  const [discardModal, setDiscardModal] = useState(false);
  const {
    getFieldDecorator,
    resetFields,
    getFieldValue,
    setFieldsValue,
  } = form;

  const createActivity = () => {
    activityData.followUpDate = moment(activityData.followUpDate).format('DD-MM-YYYY')
    activityData.followUpTime = moment(activityData.followUpTime).format('HH:mm')
    platformApi.post('/api/activity/create', { ...activityData, customerId: customerDetail.id })
      .then(res => {
        const { data: { response: { data } } } = res;
        getActivityByCustomer();
        message.success("Activity Added Successfully")
        close();
      })
      .catch(err => message.error("Activity Creation Failed"))
  }

  const close = () => {
    setActivityData(defaultValue)
    setVisibility(false)
  }

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < moment().startOf('day');
  }
  const getDisabledHours = () => {
    var hours = [];
    if (activityData.followUpDate._d < new Date()) {
      for (var i = 0; i < moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (activityData.followUpDate._d < new Date()) {
      if (selectedHour === moment().hour()) {
        for (var i = 0; i < moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };
  const leadSources = [
    { key: "WALK IN", title: "Walk In" },
  ];

  const enquiryTypes = ["Hot", "Warm", "Cold"];

  return (
    <Modal
      title={<Title level={4}>Add Walk-In Activity</Title>}
      visible={visibility}
      onOk={() => createActivity()}
      cancelText={"Cancel"}
      onCancel={() => {
        close();
      }}
      okText="Create"
      wrapClassName="add-activity-modal"
    >
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          <Row gutter={[16, 16]}>
            <Col span={10}>
              <strong>Customer Name: </strong>
              {customerDetail.name}
            </Col>
            <Col span={6}>
              <strong>Followup Date & Time : </strong>
            </Col>
            <Col span={4}>
              <DatePicker
                onChange={followUpDate => setActivityData({ ...activityData, followUpDate })}
                format="DD-MM-YYYY"
                disabledDate={disabledDate}
                value={activityData.followUpDate}
              />
            </Col>
            <Col span={4}>
              <TimePicker
                onChange={followUpTime => setActivityData({ ...activityData, followUpTime })}
                format="HH:mm"
                value={activityData.followUpTime}
                disabledHours={getDisabledHours}
                disabledMinutes={getDisabledMinutes}
              />
            </Col>

          </Row>
        </Col>

        <Col span={24} style={{marginTop:'20px'}}>
          <Row gutter={[16, 16]}>
            <Col span={6} >
              <strong>Interaction Type</strong>
            </Col>
            <Col span={8}>
              <Select
                disabled={true}
                value={activityData.interactionType}
                defaultValue={"WALK IN"}
              >
                {
                  leadSources.map(source =>
                    <Select.Option key={source.key}>{source.title}</Select.Option>
                  )
                }
              </Select>
            </Col>
            {/* <Col span={10}>
              <strong>Enquiry Type</strong>
            </Col>
            <Col span={14}>
              <Select
                onChange={enquiryType => setActivityData({ ...activityData, enquiryType })}
                value={activityData.enquiryType}
              >
                {
                  enquiryTypes.map(source =>
                    <Select.Option key={source}>{source}</Select.Option>
                  )
                }
              </Select>
            </Col> */}
            <Col span={10} style={{marginTop:'-42px'}}>
              <Item label={<Text>Enquiry Type</Text>}>
                {getFieldDecorator("enquiryType", {
                  // rules: [{ required: true, message: "Enter Enquiry Type" }],
                })(
                  <Select
                    onChange={enquiryType => setActivityData({ ...activityData, enquiryType })}
                    // value={activityData.enquiryType}
                    showAction={["click", "focus"]}
                    placeholder="Enquiry Type">
                    {enquiryTypes.map((type) => (
                      <Select.Option value={type} key={type}>
                        {type}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <strong>Remarks:</strong>
          <TextArea rows={4}
            onChange={event => setActivityData({ ...activityData, remarks: event.target.value })}
            value={activityData.remarks}
          >{activityData.remarks}</TextArea>
        </Col>
        <Col span={24}>
          <span className="linkylink" onClick={() => setDiscardModal(true)}>Discard Followup</span>
        </Col>
      </Row>
      <DiscardModal
        visibility={discardModal}
        setVisibility={setDiscardModal}
        customerDetail={customerDetail}
      />
    </Modal>
  )
}
const WrappedAddActivityModal = Form.create({ name: "walkin_interface" })(
  AddActivityModal
);

export default withRouter(WrappedAddActivityModal);
// export default AddActivityModal;
