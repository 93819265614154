import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, Button, message } from "antd";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils"
import "./index.less";
const { Item } = Form;
const { Option } = Select;
const FrameForm = ({ form, addData, modalData, create, setSelected }) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;

  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const [modify, setModify] = useState(false);
  const [manufacturer, setManufacturer] = useState([]);
  const [data, setData] = useState({});

  useEffect(() => {
    platformApi.get("/api/manufacturer/branch").then((res) => {
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          console.log("res", response.data)
          setManufacturer(response.data);
          form.setFieldsValue({
            manufacturer: response.data[0].id
          })
        }
      }
    });
  }, []);

  const targetValue =
    data && data.inferredField === 'Month' ?
      [
        "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"
      ]
      :
      [
        "2000", "2001", "2002", "2003", "2004", "2005", "2006", "2007", "2008", "2009", "2010", "2011", "2012", "2013", "2014", "2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022", "2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"
      ]

  // useEffect(() => {
  //   if (modalData) {
  //     setModify(true);
  //     const stateId = modalData.city.state.id
  //     platformApi
  //       .post("api/csc/cities", { id: stateId })
  //       .then((result) => {
  //         const { data } = result;
  //         if (data.code === 200) {
  //           setCity(data.data);
  //         }
  //       });
  //     setFieldsValue({
  //       code: modalData.code,
  //       area: modalData.area,
  //       city: modalData.city.id,
  //       state: modalData.city.state.id,
  //     });
  //   }
  // }, [modalData]);
  const clearFields = () => {
    form.resetFields();
    setModify(false);
    setSelected(undefined);
  };
  const handleSubmit = () => {
    setModify(false);
    let isValid;
    validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {

      addData(data);
      clearFields();
    }
  };
  return (
    <div>
      <Form className="rto-master">
        <Row gutter={16}>
          <Col span={7}>
            <Item colon={false} label="Manufacturer">
              {getFieldDecorator("manufacturer", {
                rules: [{ required: true, message: "Manufacturer Required" }],
              })(
                <Select
                  placeholder="Select a Manufacturer"
                  filterOption={filterMethod}
                  showSearch
                  showAction={["click", "focus"]}
                  onChange={(id) => setData({ ...data, manufacturer: id })}
                // disabled={!create}
                >
                  {manufacturer.map((m) => (
                    <Option key={m.id}>{m.name}</Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item colon={false} label="Position">
              {getFieldDecorator("position", {
                rules: [{ required: true, message: "Position Required" }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  placeholder="Select a Position"
                  onChange={(id) => { setData({ ...data, position: id }), form.setFieldsValue({ targetValue: undefined }) }}
                >
                  <Option key={9}>9</Option>
                  <Option key={10}>10</Option>
                </Select>
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item colon={false} label="Input Value">
              {getFieldDecorator("inputVal", {
                rules: [{ required: true, message: "Input Value Required" }],
              })
                (
                  <Input
                    maxLength={1}
                    onChange={(e) =>
                      setData({ ...data, inputValue: e.target.value.toUpperCase() })
                    }
                    onInput={e =>
                      form.setFieldsValue({
                        inputVal: e.target.value.toUpperCase()
                      })
                    }
                    placeholder="Input Value"
                  />
                )}
            </Item>
          </Col>
          <Col span={4}>
            <Item colon={false} label="Inferred Field">
              {getFieldDecorator("inferredField", {
                rules: [{ required: true, message: "Inferred Field Required" }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  placeholder="Select Inferred Field"
                  onChange={(id) => { setData({ ...data, inferredField: id }), form.setFieldsValue({ targetValue: undefined }) }}
                >
                  <Option key="Month">Month</Option>
                  <Option key="Year">Year</Option>
                </Select>
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item colon={false} label="Target Value">
              {getFieldDecorator("targetValue", {
                rules: [{ required: true, message: "Target Value Required" }],
              })(
                <Select
                  placeholder="Select a Target Value"
                  filterOption={filterMethod}
                  showSearch
                  showAction={["click", "focus"]}
                  onChange={(id) => setData({ ...data, targetValue: id })}
                  disabled={!(data && data.inferredField)}
                >
                  {targetValue.map((m) => (
                    <Option key={m}>{m}</Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <div style={{ float: "right" }}>
            <Button style={{ marginRight: "1rem" }} onClick={clearFields}>
              {!modify ? "Clear" : "Cancel"}
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              {!modify ? "Add" : "Save"}
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};
const WrappedForm = Form.create({ name: "FrameForm" })(FrameForm);

export default WrappedForm;
