import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, Button } from "antd";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils"
import "./index.less";
const { Item } = Form;
const { Option } = Select;
const RTOForm = ({ form, addData, modalData, create, setSelected }) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [error, setError] = useState({});

  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  useEffect(() => {
    let country = [];
    platformApi
      .get("/api/csc/country")
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          country = data.data;
          data.data.map((coun) => {
            if (coun.name === "India") {
              const id = coun.id;
              platformApi
                .post("api/csc/states", { id })
                .then((result) => {
                  const { data } = result;
                  if (data.code === 200) {
                    setStates(data.data);
                  }
                })
                .catch((error) => {
                  console.error("Error on RTO form : ", error);
                  message.error("Unable to fetch state", 2);
                });
            }
          });
        }
      })
      .catch((error) => {
        console.error("Error on RTO form : ", error);
        message.error("Unable to fetch country", 2);
      });
  }, []);
  const handleStateChange = (id) => {
    setFieldsValue({ city: undefined })
    platformApi
      .post("api/csc/cities", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setCity(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Manufaturer form : ", error);
        message.error("Unable to fetch cities", 2);
      });
  };
  const [modify, setModify] = useState(false);
  const [state, setStates] = useState([]);
  const [city, setCity] = useState([]);
  useEffect(() => {
    if (modalData) {
      setModify(true);
      const stateId = modalData.city.state.id
      platformApi
        .post("api/csc/cities", { id: stateId })
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setCity(data.data);
          }
        });
      setFieldsValue({
        code: modalData.code,
        area: modalData.area,
        city: modalData.city.id,
        state: modalData.city.state.id,
      });
    }
  }, [modalData]);
  const clearFields = () => {
    resetFields();
    setCity([]);
    setModify(false);
    setSelected(undefined);
  };
  const handleSubmit = () => {
    setModify(false);
    let isValid;
    validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      const code = form.getFieldValue("code");
      const area = form.getFieldValue("area");
      const state = form.getFieldValue("state");
      const city = form.getFieldValue("city");
      const id = modalData ? modalData.id : undefined;
      const data = {
        id,
        code,
        area,
        city,
      };
      addData(data);
      clearFields();
    }
  };
  return (
    <div>
      <Form className="rto-master">
        <Row type="flex" justify="space-between">
          <Col span={5}>
            <Item
              validateStatus={error.CODE && error.CODE.type}
              help={error.CODE && error.CODE.message}
              colon={false}
              label="RTO Code">
              {getFieldDecorator("code", {
                rules: [{ required: true, message: "Enter RTO Code" }],
              })(<Input
                pattern="/[^0-9a-zA-Z]/g"
                onKeyUp={e => {
                  form.setFieldsValue({
                    area: formatValue(e, "toUpperCase")
                  })
                  form.setFieldsValue({
                    area: formatValue(e, "code")
                  })
                }
                }
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setError({
                      ...error,
                      CODE: {
                        type: "error",
                        message: "Enter Valid Code"
                      }
                    })
                  } else {
                    delete error.CODE
                    setError(error)
                  }
                }}
                placeHolder="RTO Code" />)}
            </Item>
          </Col>
          <Col span={5}>
            <Item
              validateStatus={error.AREA && error.AREA.type}
              help={error.AREA && error.AREA.message}
              colon={false}
              label="Area">
              {getFieldDecorator("area", {
                rules: [{ required: true, message: "Enter RTO Area" }],
              })(<Input
                pattern="^[a-zA-Z]+(([', -][a-zA-Z ])?[a-zA-Z]*)*$"
                onKeyUp={e => {
                  form.setFieldsValue({
                    area: formatValue(e, "allCaps")
                  })
                  form.setFieldsValue({
                    area: formatValue(e, "code")
                  })
                }
                }
                onInput={(event) => {
                  if (!event.target.checkValidity()) {
                    setError({
                      ...error,
                      AREA: {
                        type: "error",
                        message: "Enter Valid Area"
                      }
                    })
                  } else {
                    delete error.AREA
                    setError(error)
                  }
                }}
                placeholder="RTO Area" />)}
            </Item>
          </Col>
          <Col span={5}>
            <Item colon={false} label="State">
              {getFieldDecorator("state", {
                rules: [{ required: true, message: "Select a State " }],
              })(
                <Select
                  showAction={["click", "focus"]}
                  placeholder="Select a State"
                  filterOption={filterMethod}
                  showSearch
                  onChange={handleStateChange}
                  disabled={!create}
                >
                  {state.map((state) => (
                    <Option key={state.id}>{state.name}</Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>

          <Col span={5}>
            <Item colon={false} label="City">
              {getFieldDecorator("city", {
                rules: [{ required: true, message: "Select City" }],
              })(
                <Select
                  placeholder="Select a City"
                  filterOption={filterMethod}
                  showSearch
                  showAction={["click", "focus"]}
                  disabled={!create}
                >
                  {city.map((obj) => (
                    <Option key={obj.id}>{obj.name}</Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row>

        <Row>
          <div style={{ float: "right" }}>
            <Button style={{ marginRight: "1rem" }} onClick={clearFields}>
              {!modify ? "Clear" : "Cancel"}
            </Button>
            <Button type="primary" onClick={handleSubmit}>
              {!modify ? "Add" : "Save"}
            </Button>
          </div>
        </Row>
      </Form>
    </div>
  );
};
const WrappedForm = Form.create()(RTOForm);

export default WrappedForm;
