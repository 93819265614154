import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

import {
    Typography,
    Input,
    Button,
    Select,
    Form,
    Col,
    Row,
    DatePicker,
    message,
    Table,
    Tooltip,
    Radio,
    Popconfirm
} from 'antd';
import moment from 'moment';
import { LeftOutlined } from '@ant-design/icons';
import { platformApi } from '../../../../api';
const { Title, Text } = Typography;
const { Option } = Select;
const FinalInspectionForm = (props) => {
    const { form } = props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const location = useLocation();
    const history = useHistory()
    const [jobNo, setJobNo] = useState([]);
    const [editable, setEditable] = useState(false);
    const [editData, setEditData] = useState();
    const [modifyType, setModifyType] = useState(false);
    const [dataSource, setDataSource] = useState([]);
    const [btnFlag, setBtnFlag] = useState(false)
    let url = location.pathname;
    let id = url.substring(url.lastIndexOf('/') + 1);

    useEffect(() => {
        if (id !== ':id') {
            setEditable(true);
        } else {
            setEditable(false);
        }
        getJobNo();
    }, []);

    const getJobNo = () => {
        platformApi
            .post("/api/jobOrder/getJobNo", {
                size: 100,
                page: 1,
            })
            .then((res) => {
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setJobNo(response.data);
                    } else {
                        message.error("Failed to fetch Job Order");
                    }
                }
                else {
                    message.error("Failed to fetch Job Order");
                }
            })
            .catch((error) => {
                console.error("Error on Job Order : ", error);
                message.error("Failed to fetch Job Order");
            });
    }

    const fetchJobDetails = (value) => {
        setFieldsValue({
            JobNo: value
        })
        platformApi.get('/api/jobOrder/' + value).then((res) => {
            let { data } = res;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    console.log("Response", response.data)
                    if (response.data) {
                        setDataSource(response.data.complaint)
                        setFieldsValue({
                            registerNo: response.data.vehicle && response.data.vehicle.registerNo,
                        })
                    }
                }
            }
        })
    }

    const searchJobNo = (search) => {
        platformApi
            .post("/api/options/get/", {
                module: search.module,
                column: search.column,
                searchString: search.searchString,
                fields: search.fields,
                size: 100,
                page: 1,
                except: null,
            })
            .then((res) => {
                search.setValue(res.data.response);
            });
    }

    const renderColumns = () => {
        const columns = [
            {
                title: "S.No",
                render: (record, data, index) => (<span>{index + 1}</span>)
            },
            {
                title: "Complaints",
                dataIndex: "complaint",
                key: "complaint",
                render: (record, data) => (<div>
                    <p>{record}</p>
                </div>)
            },
        ];
        return columns
    }

    const handleSubmit = () => {
        form.validateFields((validationError) => {
            let newItemList = [...dataSource];
            // eslint-disable-next-line for-direction
            if (newItemList.length > 1) {
                newItemList = newItemList.filter(item => {
                    return item.complaint
                })
            }
            setDataSource(newItemList)
            const data = {
                type: "Final Inspection",
                jobOrder: getFieldValue("JobNo"),
            };
            console.log("handleSubmit", data)
            if (validationError === null) {
                // setBtnFlag(true)
                platformApi.post("/api/jobOrder/setStatus", data).then((result) => {
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            message.success("Final Inspection Added Successfully");
                            history.push('/autoadmin/final_inspection');
                        }
                    }
                }).catch((err) => {
                    console.log(err)
                    message.error("Couldn't Add Inspection");
                });
            }
            else {
                message.error('select atleast one item list')
            }
        })
    }

    //Handle Cancel
    const handleCancel = () => {
        form.resetFields();
        setDataSource([]);
        setJobNo([]);
        history.push('/autoadmin/final_inspection');
    }
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
        },
        onSelect: (record, selected, selectedRows) => {
            console.log(record, selected, selectedRows);
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log(selected, selectedRows, changeRows);
        },
    }
    return (
        <div>
            <Title level={4}>
                <Tooltip placement="topLeft" title={"Back"}>
                    <Button
                        style={{ marginRight: '1rem' }}
                        onClick={() => history.push('/autoadmin/final_inspection')}
                    >
                        <LeftOutlined />
                    </Button>
                </Tooltip>
                    Final InspectionForm
          </Title>
            <div>
                <Form className="material-form" form={form}>
                    <Row gutter={[8, 8]}>
                        <Col span={8}>
                            <Form.Item colon={false} className="purchase-form-element" label={<Text>Ass.Job No</Text>}>
                                {getFieldDecorator("JobNo", {
                                    rules: [
                                        {
                                            required: true,
                                            message: "Select Job No",
                                        },
                                    ],
                                })(
                                    <Select
                                        showAction={["click", "focus"]}
                                        showSearch
                                        filterOption={null}
                                        disabled={editable}
                                        placeholder="Job No"
                                        onSearch={(e) => {
                                            const search = {
                                                module: "jobOrders",
                                                column: "jobNo",
                                                searchString: e,
                                                searchColumns: ["jobNo"],
                                                setValue: setJobNo
                                            };
                                            searchJobNo(search);
                                        }}
                                        onSelect={fetchJobDetails}
                                    >
                                        {jobNo.length && jobNo.map((item) => (
                                            <Select.Option key={item.id} value={item.id}>
                                                {item.jobNo}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                colon={false}
                                label="Vehicle"
                            >
                                {getFieldDecorator("registerNo", {
                                    rules: [
                                        {
                                            required: false,
                                            message: "Register No",
                                        },
                                    ],
                                })(
                                    <Input
                                        placeholder="Register No."
                                        disabled={true}
                                    />
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Table
                        className="parts-invoice-table"
                        rowKey={(record) => { record.id }}
                        columns={renderColumns()}
                        dataSource={dataSource}
                        tableLayout="fixed"
                        pagination={false}
                        rowSelection={{ ...rowSelection }}
                        style={{ marginTop: '1rem', marginBottom: '1rem' }}
                    />
                    <br />
                    <div style={{ float: "right" }}>
                        <Button
                            style={{ marginRight: "1rem" }}
                            size="large"
                            onClick={() => handleCancel()}
                        >
                            Cancel
                    </Button>
                        <Button
                            type="primary"
                            size="large"
                            // disabled={btnFlag}
                            onClick={() => (!editable && handleSubmit())}
                        >
                            {!editable && 'Save'}
                        </Button>
                    </div>
                </Form>
            </div >
        </div >
    )
}
const WrappedFinalInspectionForm = Form.create()(
    FinalInspectionForm
);

export default WrappedFinalInspectionForm;