import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs,  } from "antd";
import AddButton from "./StockCheckForm";
import StockCheckTable from './StockCheckTable';


const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs

import { platformApi } from '../../../../api'
import { ContextAPI } from '../../../../ContextAPI';
import StockCheckForm from './StockCheckForm';
import { set } from 'lodash';



const StockCheck = () => {


    const [dataSource, setDataSource] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [open, setOpen] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [editable, setEditable] = useState(false);
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [total, setTotal] = useState(0);
    const [searches, setSearch] = useState('');
    const [branch, setBranch] = useState([]);
    const [filteropen, setFilteropen] = useState(false);
    const [visible, setVisible] = useState(false)
    const [createAccess, setCreateAccess] = useState(false);
    const [modifyAccess, setModifyAccess] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);
    const { loginCredintials } = useContext(ContextAPI);
    const [partsData, setPartsData] = useState([]);
    const [modelType, setModelType] = useState("CREATE");
    // 
    useEffect(() => {
      console.log({ loginCredintials });
        loginCredintials.roleAccess &&
          loginCredintials.roleAccess.map((each) => {
            if (each.subModule === "report") {
              if (each.access.create) {
                setCreateAccess(true);
              }
              if (each.access.update) {
                setModifyAccess(true);
              }
              if (each.access.delete) {
                setDeleteAccess(true);
              }
            }
          });
      }, []);

    useEffect(() => {
      getPageData();
      platformApi
        .post(`/api/partsMaster/get/`, {
          page: 1,
          size: 100,
        })
        .then((res) => {
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setPartsData(response.data.partsMaster);
            }
          } else {
            message.error("Unable to fetch Parts", 2);
          }
        })
        .catch((error) => {
          console.error("Error on Parts Master : ", error);
          message.error("Unable to fetch Parts", 2);
        });
    }, [])

    const getPageData = async () => {
      setSpinning(true);
      const response = await platformApi.get("/api/stockCheck");
      const { data } = response;
      setDataSource(data.response?.data || []);
      setSpinning(false);
    };

    const getMenu = () => {
        let size = count / 10 + (count % 10) ? 1 : 0;
        const menus = [];
        menus.push(10);
        size = size <= 10 ? size : 10;
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
                {menu}
            </Item>
        ));
    };

    const deleteData = async (id) => {
      setSpinning(true);
      const response = await platformApi.delete(`/api/stockCheck/${id}`);
      const { data } = response;
      if(data.code) {
        getPageData();
        message.success("Stock record deleted Successfully")
      }else {
        message.error("Unable to delete stock !")
      }
      setSpinning(false);
    }

    const handleModelOpen = () => {
      setModelType("CREATE")
      setEditable(true);
      setOpen(true);
    }

    const menu = <Menu>{getMenu()}</Menu>;


    
    return (
			<div>
				<div className="accessories-head">
					<div className="add-inventory">
						<Title className="heading" style={{ width: "100%" }} level={4}>
							Stock Check
						</Title>
						{/* <AddButton /> */}
					</div>
					<div className="search-inventory">
						<Search
							style={{ width: "100%" }}
							placeholder="Search Inventory"
							onSearch={(event) => {
								let searches = { name: event };
								setPage(1);
								setSearch(searches);
							}}
							onChange={(event) => {
								if (event.target.value === "") {
									setSearch(null);
									setPage(1);
								}
							}}
						/>
						{/* <div style={{ width: "12%", textAlign: "center" }}>
                        <Button
                            type="link"
                            onClick={() => {
                                collapseFilter();
                            }}
                        >
                            {filteropen ? "Back" : "Advanced  Filters"}
                        </Button>
                    </div> */}
					</div>
				</div>
				<Button
					type="primary"
					onClick={() => handleModelOpen()}
					disabled={!createAccess}
					style={{ margin: "1rem 0" }}
				>
					Add Spare Bin Location
				</Button>
				<StockCheckTable
					pagination={{
						onChange: (page) => {
							setPage(page);
						},
						pageSize: limit,
						defaultCurrent: 1,
						current: page,
						total: count,
						showQuickJumper: true,
					}}
					loading={spinning}
					deleteData={deleteData}
					dataSource={dataSource}
					openModal={(data, editable) => {
						console.log({ data });
            setModelType("MODIFY")
						setSelected(data);
						setSelectedData(data);
						setEditable(editable);
						setOpen(true);
					}}
					deleteAccess={deleteAccess}
					modifyAccess={modifyAccess}
				/>
				<StockCheckForm
					data={selectedData}
					open={open}
					editable={editable}
					createAccess={createAccess}
					modifyAccess={modifyAccess}
					deleteAccess={deleteAccess}
					currentUser={{
						name: loginCredintials && loginCredintials.employeeName,
					}}
					close={() => {
						setOpen(false);
						setSelected(false);
						setSelectedData(null);
					}}
					partsData={partsData}
					setPartsData={setPartsData}
					getPageData={getPageData}
					modelType={modelType}
				/>
			</div>
		);

}


export default withRouter(StockCheck);


