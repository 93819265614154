import React from "react";
import { Table, Divider, Popconfirm, Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
const { Text } = Typography
const PartsTable = ({
  pagination,
  dataSource,
  spinner,
  openModal,
  delete: deleteData,
  history,
  modify,
  deleteAccess
}) => {
  const { goBack } = history;
  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Part No",
      dataIndex: "partNumber",
      key: "partNo",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Part Name",
      dataIndex: "partName",
      key: "partName",
      render: (record) => <span>{record}</span>,
    },
    // {
    //   title: "Part Status",
    //   dataIndex: "partStatus",
    //   key: "partStatus",
    //   render: (text) => {
    //     switch (text) {
    //       case "AVAILABLE":
    //         text = "Available";
    //         break;
    //       case "NOTAVAILABLE":
    //         text = "Not Available";
    //         break;
    //       default:
    //         text = "-";
    //     }
    //     return <span>{text}</span>;
    //   },
    // },
    // {
    //   title: "Manufacturer",
    //   dataIndex: "manufacturer",
    //   key: "manufacturer",
    //   render: (data) => <span>{data && data.name || '-'}</span>,
    // },
    // {
    //   title: "Created On",
    //   dataIndex: "createdAt",
    //   key: "createdOn",
    //   render: (record) => <span>{new Date(record).toLocaleDateString()}</span>,
    // },
    {
      title: "Action",
      render: (data) => (
        <div>
          {
            modify ?
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  openModal(data.id, true);
                }}
                className="linkylink"
              >
                Modify
          </span>
              :
              <span></span>
          }


          {
            deleteAccess
              ?
              <Popconfirm
                title="Do you want to delete this Part?"
                okText="Yes"
                cancelText="No"
                onCancel={(e) => {
                  e.stopPropagation();
                }}
                onConfirm={(e) => {
                  e.stopPropagation();
                  deleteData(data.id);
                }}
              >

                <span onClick={(e) => e.stopPropagation()} className="linkylink">
                  <Divider type="vertical" />
                  Delete
           </span>
              </Popconfirm>
              :
              <span></span>
          }
          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }
          {/* <Divider type="vertical" />
          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
    // tableLayout="fixed"
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      loading={spinner}
      style={{ cursor: "pointer" }}
      onRow={(props) => ({
        onClick: () => {
          openModal(props.id, false);
        },
      })}
    />
  );
};

export default withRouter(PartsTable);
