import React, { useState, useEffect } from 'react';
import { Modal, Upload, message, Select, Button, Icon, Typography, Row, Col } from 'antd';
import { platformApi } from '../../../../api';

const { Text } = Typography;

export default function ImportExportData(props) {
  const { open, close, upload, exportFile, createAccess, setfile } = props;
  const [templateLink, setTemplateLink] = useState('');

  useEffect(() => {
    platformApi
      .get('/api/vehicleMaster/template')
      .then(res => { 
        let { data } = res;
        if (data.code === 200) {
          setTemplateLink(`${ENDPOINT}` + data.response)
        }
      })
  }, [])

  const beforeDocUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("Document must smaller than 50MB!");
    }
    return isLt2M;
  };

  return (
    <Modal
      title="Import / Export Data"
      visible={open}
      onCancel={() => {
        close()
      }}
      okButtonProps={{ style: { display: 'none' } }}
    >
      <div >
        <Row type="flex" justify="space-between">
          <Col span={12}>
            <Upload
              beforeUpload={e => beforeDocUpload(e)}
              onChange={event => {
                setfile(true)
                upload(event)
              }}
              action={`${ENDPOINT}/api/upload/mocky`}
              accept=".xlsx"
            >
              <Button type="primary">
                <Icon type="upload" />
                <Text style={{ color: 'white' }}>Import Data</Text>
              </Button>
            </Upload>
          </Col>
          <Col span={12} >
            <a className="linkylink" style={{ marginLeft: '7vw' }}
             onClick={e => e.stopPropagation()} href={exportFile} download target="_blank">
              <Button
                type="primary"
                disabled={!createAccess}
                icon={<Icon type="upload" />}
              >
                <Icon type="download" />
                <Text style={{ color: 'white' }}>Export Data</Text>
              </Button>
            </a>
          </Col>
        </Row>
        <div style={{ marginTop: '8%' }}>
          <a href={templateLink} target="_blank" download onClick={e => e.stopPropagation()} className="linkylink">Download Template Here for Vehicle Master</a>
        </div>
      </div>
    </Modal>
  );
}
