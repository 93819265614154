import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Tabs,
} from "antd";
import "./index.less";
import { platformApi } from "../../../api";

import CustomerDetailsTable from "./CustomerDetailsTable";
import DigitalLeadsTable from "./DigitalLeadsTable";
import WrappedAccessoriesModal from "./CustomerDetailsForm";
// import Customer from './CustomerDetailsForm'
import { ContextAPI } from "../../../ContextAPI";

const { Text, Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs;

export default function Customers(props) {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [dataSource, setData] = useState([]);
  const [digitalDataSource, setDigitalDataSource] = useState([]);
  const [selected, setSelected] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [cusId, setCusId] = useState("");
  const [search, setSearch] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const [tab, setTab] = useState(1);
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item
        onClick={() => {
          setLimit(menu), setPage(1);
        }}
        key={menu}
      >
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "customer_details") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPageData();
    if (props.customerId) {
      setAddFlag(true);
      setSelected({ ...props.cusData });
    }
  }, []);

  useEffect(() => {
    if (tab == 1) {
      setPageData(search);
    } else if (tab == 2) {
      setDigitalLeadData(search);
    }
  }, [page, limit, search]);

  const setDigitalLeadData = (search) => {
    setSpinning(true);
    platformApi
      .post("/api/customer/digitalLead", {
        page: 1,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: search && search.name,
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setDigitalDataSource(response.data.digitalLeads);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch DigitalLeads", 2);
          }
        } else {
          message.error("Unable to fetch DigitalLeads", 2);
        }
      })
      .catch((err) => {
        setSpinning(false);
        console.error("Error on digital leads : ", err);
        message.error("Unable to fetch Digital Leads", 2);
      });
  };

  const setPageData = (search, val) => {
    setSpinning(true);
    platformApi
      .post("/api/customer/get", {
        page: val ? val : page,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: search && search.name,
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.customer);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch Customers", 2);
          }
        } else {
          message.error("Unable to fetch Customers", 2);
        }
      })
      .catch((err) => {
        setSpinning(false);
        console.error("Error on customer : ", err);
        message.error("Unable to fetch Customer", 2);
      });
  };
  const setCustomerId = () => {
    platformApi.post("/api/idGenerate/customer").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        setCusId(response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
  };
  const addData = (customer) => {
    setSpinning(true);
    if (customer.id) {
      platformApi
        .put(`/api/customer/${customer.id}`, customer)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (customer.id === obj.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success("Customer saved successfully", 2);
            } else {
              message.error("Unable to save customer.", 2);
            }
          } else {
            message.error("Unable to save customer", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to save customer");
          console.error("Error on Customer : ", error);
        });
    } else {
      platformApi
        .post("/api/customer", customer)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Customer added successfully", 2);
            } else {
              message.error("Unable to add customer", 2);
            }
          } else if (data.code === 500 && data.err.code === 500) {
            message.error("Customer name already exists", 2);
          } else {
            message.error("Unable to add customer", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to add customer", 2);
          console.error("Error on customer : ", error);
        });
    }
  };

  const deleteData = (id) => {
    let temp = page != 1 && dataSource.length === 1;
    setSpinning(true);
    platformApi
      .delete(`/api/customer/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Customer deleted successfully", 2);
            if (temp) {
              setPage(1);
              setPageData(null, 1);
            } else {
              setPageData();
            }
          } else {
            message.error("Unable to delete customer", 2);
          }
        } else {
          message.error("Unable to delete customer", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to delete customer", 2);
        console.error("Error on customer : ", error);
      });
  };

  const handleTabChange = (activeKey) => {
    if (activeKey == 1) {
      setTab(1);
      setPageData();
    } else if (activeKey == 2) {
      setTab(2);
      setDigitalLeadData();
    }
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          {"Customer Details"} [{count}]
          <span style={{ margin: ".5rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Customer"
          onSearch={(event) => {
            let searches = { name: event };
            setPage(1);
            setSearch(searches);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null);
              setPage(1);
            }
          }}
        />
        <Button
          onClick={() => {
            setEditFlag(true);
            setAddFlag(true);
            setCustomerId();
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Customer
        </Button>
        
      </div>
      
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab={<span>Customer Details</span>} key="1">
          <CustomerDetailsTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            openModal={(id, editable) => {
              setSelected(
                dataSource[dataSource.findIndex((obj) => id === obj.id)]
              );
              setEditFlag(editable);
              setAddFlag(true);
            }}
            delete={(id) => deleteData(id)}
            dataSource={dataSource}
            spinner={{
              spinning,
            }}
            loginCredintials={loginCredintials}
            modify={modifyAccess}
            deleteAccess={deleteAccess}
          />
        </TabPane>
        <TabPane tab={<span>Digital Leads</span>} key="2">
          <DigitalLeadsTable
            pagination={{
              onChange: (page) => {
                setPage(page);
              },
              pageSize: limit,
              defaultCurrent: 1,
              total: count,
              current: page,
              showQuickJumper: true,
            }}
            dataSource={digitalDataSource}
            spinner={{
              spinning,
            }}
          />
        </TabPane>
      </Tabs>

      <WrappedAccessoriesModal
        data={selected}
        setData={setSelected}
        emitData={(data) => addData(data)}
        open={addFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
        editable={editFlag}
        cusId={cusId}
        setCusId={setCusId}
        setCustomerId={setCustomerId}
        modify={modifyAccess}
      />
    </div>
  );
}
