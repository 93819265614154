import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";

const { Text } = Typography;

const SalesTable = (props) => {

  const { dataSource } = props
  const columns = [
    {
      title: <span>Lead ID</span>,
      dataIndex: "leadId",
      key: "leadId",
      render: (id) => <span>{id}</span>,
    },
    {
      title: <span>Customer Name</span>,
      dataIndex: "customer",
      key: "customer",
      render: (customer) => <span>{customer ? customer.name : null}</span>,
    },
    {
      title: <span>Enquiry Status</span>,
      dataIndex: "activity",
      key: "enquiry",
      render: (enquiry) => {
        if (enquiry[0])
          return <span>{enquiry[0].enquiryType}</span>
        else return <Text strong disabled>NA</Text>
      }
    },
    {
      title: <span>Follow-Up Date</span>,
      dataIndex: "activity",
      key: "followup",
      render: (followup) => {
        if (followup[0])
          return <span>{moment(followup[0].scheduleDate).format('DD-MM-YYYY')}</span>
        else return <Text strong disabled>NA</Text>
      }
    },
    {
      title: <span>Actions</span>,
      render: (data) => (
        <div>

          <Popconfirm
            title="Do you want to delete this Lead?"
            okText="Yes"
            cancelText="No"
            onConfirm={(event) => {
              event.stopPropagation();
            }}
            onCancel={(event) => {
              event.stopPropagation();
            }}
          >
            <span
              className="linkylink"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              Delete
            </span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  return <Table
    // pagination={pagination}
    dataSource={dataSource}
    onRow={(record) => ({
      onClick: () => {
        props.history.push({
          pathname: `/autoadmin/sales/${record.id}`,
          state: {
            name: record.customer.name,
          },
        })
      }

    })}
    style={{ cursor: "pointer" }}
    // loading={spinner}
    rowKey={(record) => record.id}
    columns={columns} />;
};
export default withRouter(SalesTable);
