import { Modal, Button, Form, Select, Typography } from "antd";
const { Text } = Typography;

import React, { useState, useEffect } from "react";
var dat = [];
export default function LinkQuotationModal({
  handleSave,
  form,
  dataSource,
  visibility,
  existQuotations,
}) {
  useEffect(() => {
    dat = dataSource;
    if (dataSource) {
      dat = dataSource.filter(f => {
        for (var i = 0; i < existQuotations && existQuotations.length; i++) {
          if (existQuotations[i].quotationId == f.quotationId)
            return false;
        }
        return true;
      }

      );
    }


  }, [dataSource])
  const filterMethod = (input, option) =>
    option.props.children.toString().toLowerCase().indexOf(input) >= 0;

  const onSave = (save = true) => {
    handleSave(form.getFieldValue("linkQuotation"), save);
  };

  return (
    <Modal
      title="Link Quotation Modal"
      visible={visibility}
      centered={true}
      onOk={() => onSave()}
      onCancel={() => onSave(false)}
    >
      <Form>
        <Form.Item colon={false} label={<Text>Link Quotation</Text>}>
          {form.getFieldDecorator("linkQuotation", {
            rules: [{ required: false, message: "Enter Quotation" }],
          })(
            <Select
              showSearch
              mode="multiple"
              placeholder="Enter Quotation"
              filterOption={filterMethod}
            >
              {dat.map((object) => (
                <Select.Option key={object.id} value={object.id}>
                  {object.quotationId}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>
      </Form>
    </Modal>
  );
}
