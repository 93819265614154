import React from "react";
import { Table, Divider, Popconfirm, Button, Typography, message } from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";
const { Text } = Typography;
function DepartmentTable(props) {
  const {
    history,
    pagination,
    dataSource,
    openModal,
    spinner,
    deleteAccess,
    modify,
  } = props;
  const { goBack } = history;
  const column = [
    // {
    //   render: () => (
    //     <Button
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Department Name",
      dataIndex: "role",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Employee Count",
      dataIndex: "count",
      key: "count",
      render: (record) => <span>{record || 0}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdOn",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {modify ? (
            <span
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, true);
              }}
              className="linkylink"
            >
              Modify
            </span>
          ) : (
              <span></span>
            )}
 
          {deleteAccess ? (
            <Popconfirm
              title="Do you want to delete this Deparment?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                if (data.count === 0) {
                  props.delete(data.id);
                }
                else {
                  message.error("Employee Associated ,You can't Delete this department ")
                }
              }}
            >
              <span onClick={(e) => e.stopPropagation()} className="linkylink">
                <Divider type="vertical" />
                Delete
              </span>
            </Popconfirm>
          ) : (
              <span></span>
            )}
          {!modify && !deleteAccess ? (
            <Text strong disabled>
              No Actions Given
            </Text>
          ) : (
              <span></span>
            )}

          {/* <Divider type="vertical" />
          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(props) => props.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      onRow={(record) => ({
        onClick: () =>
          props.history.push({
            pathname: `/autoadmin/department/${record.role.replace(" ", "")}`,
            state: {
              id: record.id,
            },
          }),
      })}
      style={{ cursor: "pointer" }}
      loading={spinner}
    />
  );
}

export default withRouter(DepartmentTable);
