import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Dropdown, Menu, Icon, message } from "antd";
import "./index.less";
import { platformApi } from "../../../api";

// Import Table and Form
import VPRTable from "./PurchaseReturnTable";
import WrappedAccessoriesModal from "./PurchaseReturnForm";
import { ContextAPI } from "../../../ContextAPI";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

export default function PurchaseReturn() {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [search, setSearch] = useState("");
  const { loginCredintials } = useContext(ContextAPI);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const getMenu = () => {
    const size = count / 10 + (count % 10) ? 1 : 0;
    let menus = [];
    menus.push(10);
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };

  const setBooking = () => {
    platformApi.post("/api/idGenerate/purchaseReturn").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        setBookingId(response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
  };

  const menu = <Menu>{getMenu()}</Menu>;

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "purchase_return") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setSpinning(true);
    setPrimaryData();
  }, []);

  const setPrimaryData = (string, val) => {
    platformApi
      .post("/api/purchaseReturn/get", {
        page: val ? val : page,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: string,
      })
      .then((res) => {
        setSpinning(false);
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setCount(response.data.count);
            setData(response.data.PurchaseReturn);
          } else {
            message.error("Failed to fetch Purchase Return");
          }
        } else {
          message.error("Failed to fetch Purchase Return");
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Purchase Return : ", error);
        message.error("Failed to fetch Purchase Return");
      });
  };

  useEffect(() => {
    setPrimaryData(search);
  }, [page, search, limit]);

  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete("/api/purchaseReturn/" + id)
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1)
              setPrimaryData(null, 1)
            }
            else {
              setPrimaryData();
            }
            message.success("Deleted Sucessfully", 2);
          } else {
            message.error("Unable to delete Purchase Return", 2);
          }
        } else {
          message.error("Unable to delete Purchase Return", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Purchase Return : ", error);
        message.error("Unable to delete Purchase Return", 2);
      });
  };

  const addData = (purchaseReturn) => {
    setSpinning(true);
    if (purchaseReturn.id) {
      platformApi
        .put(`/api/purchaseReturn/${purchaseReturn.id}`, purchaseReturn)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === purchaseReturn.id)
                  dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success("Purchase Return saved successfully", 2);
            } else {
              message.error("Unable to save Purchase Return", 2);
            }
          } else {
            message.error("Unable to save Purchase Return", 2);
          }
        })
        .catch((err) => {
          setSpinning(false);
          message.error("Unable to save Purchase Return", 2);
          console.error("Error on Purchase Return : ", err);
        });
    } else
      platformApi
        .post("/api/purchaseReturn/", purchaseReturn)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = res.data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Purchase Return added successfully");
            } else {
              message.error("Error in Purchase Return creation");
            }
          } else {
            message.error("Error in Purchase Return creation");
          }
        })
        .catch((err) => {
          console.error("Error on Purchase Return : ", err);
        });
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Purchase Return [{count}]
          <span style={{ margin: "2rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
          style={{ width: "30%" }}
          placeholder="Search Vehicle"
        />
        <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
            setBooking();
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Purchase Return
        </Button>
      </div>
      <VPRTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          total: count,
          current: page,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{
          spinning,
        }}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <WrappedAccessoriesModal
        id={bookingId}
        data={dataSource[dataSource.findIndex((obj) => selected === obj.id)]}
        emitData={(data) => addData(data)}
        editable={editFlag}
        open={addFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
        modify={modifyAccess}
      />{" "}
    </div>
  );
}
