import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Typography, message, Spin } from "antd";
import axios from 'axios';
import { baseUrl, platformApi } from "../../../../../api";
import { withRouter } from "react-router-dom";
import moment from "moment";

const { Title } = Typography;

const PDFGenerator = ({
  open,
  close,
  pdfData,
  sms,
  sentStatus,
  sentWhatsApp,
  editable,
  smsTime,
  setSmsTime,
  setFlag,
  flag,
  whatsapp,
  whatsappTime,
  setWhatsAppTime,
  wFlag,
  setWFlag,
}) => {
  const [loading, setLoading] = useState({ print: false, sms: false, whatsApp: false });
  const sendSMS = (data, type) => {
    if (type === "SMS") {
      data.type = type;
      setLoading({ ...loading, sms: true });
      platformApi
        .post("api/sendSms/quotation", data)
        .then((result) => {
          setLoading({ ...loading, sms: false });
          const { data } = result;
          if (data.code === 200) {
            message.success("Message sent successfully");
            setFlag(true);
            setSmsTime(new Date());
          } else if (data.code === 401) {
            message.error(data.message);
          } else {
            message.error("Unable to send SMS");
          }
        })
        .catch((error) => {
          console.error("Error in PDF Generator : ", error);
          setLoading({ ...loading, sms: false });
          message.error("Unable to send SMS");
        });
    } else if (type === "WhatsApp") {
      // console.log("Whatsapp"+JSON.stringify(data));
      data.type = type;
      setLoading({ ...loading, whatsApp: true });
      platformApi
        .post("api/sendSms/quotation", data)
        .then((result) => {
          setLoading({ ...loading, whatsApp: false });
          const { data } = result;
          if (data.code === 200) {
            message.success("WhatsApp Message sent successfully");
            setWFlag(true);
            setWhatsAppTime(new Date());
          } else if (data.code === 400) {
            message.error(data.message);
          } else if (data.code === 401) {
            message.error(data.message);
          } else {
            message.error("Unable to send WhatsApp SMS");
          }
        })
        .catch((error) => {
          console.error("Error in PDF Generator : ", error);
          setLoading({ ...loading, whatsApp: false });
          message.error("Unable to send SMS");
        });
    }
  };

  useEffect(() => {
    console.log(whatsapp, whatsappTime, setWhatsAppTime, wFlag, setWFlag);
  }, []);

  const triggerSMS = () => sendSMS({ ...pdfData }, "SMS");
  const triggerWhatsAppSMS = () => sendSMS({ ...pdfData }, "WhatsApp");
  const clearFields = () => {
    setLoading({ print: false, sms: false });
    setFlag(false);
  };
  return (

    
    <Modal
      title={<Title level={4}>PDF Generation</Title>}
      visible={open}
      width="60vw"
      onCancel={!loading.sms && !loading.print && !loading.generation && close}
      footer={[
        <Button
          onClick={() => {
            clearFields();
            close();
          }}
          disabled={loading.sms || loading.print || loading.generation}
        >
          Close
        </Button>,
      ]}
    >
      {pdfData.id && (
        <div>
          <Row gutter={16}>
            <Col span={5}>
              <Button
                icon="message"
                type="dashed"
                loading={loading.sms}
                onClick={() => {
                  triggerSMS();
                }}
              >
                Send via SMS
              </Button>
            </Col>

            <Col span={6}>
              <a
                href={`/api/quotation/generatePdf/${pdfData.id}?withBrochure=true`}
                style={{ color: "#636363", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer" 
                className="linkylink"
              >
                <Button loading={loading.print} type="dashed" icon="file-pdf">
                  Print PDF [Brochure]
                </Button>
              </a>
            </Col>

            <Col span={7}>
              <a
                href={`/api/quotation/generatePdf/${pdfData.id}`}
                style={{ color: "#636363", textDecoration: "none" }}
                target="_blank"
                rel="noopener noreferrer" 
                className="linkylink"
              >
                <Button loading={loading.print} type="dashed" icon="file-pdf">
                  Print PDF [No Brochure]
                </Button>
              </a>
            </Col>
            <Col span={6}>
              <Button
                icon="message"
                type="dashed"
                loading={loading.whatsApp}
                onClick={() => {
                  triggerWhatsAppSMS();
                }}
                // disabled={true}
              >
                Send via WhatsApp
              </Button>
            </Col>
          </Row>
          <Row gutter={16} style={{ marginTop: '20px', marginLeft: '5px' }}>
            <Col span={23} type="flex">
              <span>
                SMS Status :
                {sms === null ? (
                  flag ? (
                    <span style={{ color: "Green", padding: "2px 15px" }}>
                      Sent
                    </span>
                  ) : sentStatus ? (
                    <span style={{ color: "Green", padding: "2px 15px" }}>
                      Sent
                    </span>
                  ) : (
                    <span style={{ color: "red", padding: "2px 15px" }}>
                      Not Sent
                    </span>
                  )
                ) : flag ? (
                  <span style={{ color: "Green", padding: "2px 15px" }}>
                    Sent
                  </span>
                ) : sms === "DELIVRD" ? (
                  <span style={{ color: "green", padding: "2px 15px" }}>
                    {sms} -{" "}
                    {smsTime
                      ? moment(smsTime.toLocaleString()).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      : null}
                  </span>
                ) : (
                  <span style={{ color: "red", padding: "2px 15px" }}>
                    {sms}
                  </span>
                )}
              </span>
            </Col>
            <Col span={23} type="flex">
              <span>
                WhatsApp Message Status :
                {whatsapp === null ? (
                  wFlag ? (
                    <span style={{ color: "Green", padding: "2px 15px" }}>
                      Sent
                    </span>
                  ) : sentWhatsApp ? (
                    <span style={{ color: "Green", padding: "2px 15px" }}>
                      Sent
                    </span>
                  ) : (
                    <span style={{ color: "red", padding: "2px 15px" }}>
                      Not Sent
                    </span>
                  )
                ) : wFlag ? (
                  <span style={{ color: "Green", padding: "2px 15px" }}>
                    Sent
                  </span>
                ) : whatsapp ? (
                  <span style={{ color: "green", padding: "2px 15px" }}>
                    {whatsapp} -{" "}
                    {whatsappTime
                      ? moment(whatsappTime.toLocaleString()).format(
                          "DD-MM-YYYY HH:mm"
                        )
                      : null}
                  </span>
                ) : (
                  <span style={{ color: "red", padding: "2px 15px" }}>
                    {whatsapp}
                  </span>
                )}
              </span>
            </Col>
          </Row>
        </div>
      )}
    </Modal>


  );
};

export default withRouter(PDFGenerator);
