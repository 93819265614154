import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  TimePicker,
  message,
  Checkbox,
} from "antd";
import { platformApi } from "../../../../../api";
import moment from "moment";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;

const ScheduleData = ({
  form,
  data,
  id,
  editable,
  recvData,
  setGroupCount,
  checked,
  setChecked,
  validate,
  setValidate
}) => {
  // const [id, setId] = useState("");
  const [group, setGroup] = useState([]);
  // const [groupCount, setGroupCount] = useState(0)
  const { getFieldDecorator, getFieldValue } = form;


  useEffect(() => {
    form.setFieldsValue({
      date: data.date,
      time: data.time,
      group: data.group,
    });

    validate.add(validatePage)
    setValidate(validate)

    platformApi.get("/api/promotions").then((res) => {
      if (res.status === 200) {
        let data = res.data;
        if (data.code === 200) {
          let resp = data.response;
          if (resp.code === 200) {
            let response = resp.data;
          }
        }
      }
    });

    platformApi
      .get("/api/promotions/group/")
      .then((res) => {
        // setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setGroup(response.data);

          } else {
            message.error("Unable to fetch Groups", 2);
          }
        } else {
          message.error("Unable to fetch Groups", 2);
        }
      })
      .catch((err) => {
        // setSpinning(false);
        console.error("Error on Group : ", err);
        message.error("Unable to fetch Groups", 2);
      });
  }, []);

  useEffect(() => {
    if (recvData) {
      form.setFieldsValue({
        date: moment(recvData.executionTime),
        time: moment(recvData.executionTime),
        group: recvData.group.id,
      });
      // setStartDate(recvData.execution);
      // setStartTime(recvData.execution);
    }
  }, [recvData, editable]);


  const onCheck = (event) => {
    if (event.target.checked) {
      setChecked(true)
    }
    else {
      setChecked(false)
    }
  }


  const getDisabledHours = () => {
    var hours = [0, 1, 2, 3, 4, 5, 6, 7, 20, 21, 22, 23];

    return hours;
  }


  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.date = getFieldValue("date");
      data.time = getFieldValue("time");
      data.group = getFieldValue("group");
      data.taskId = id;
    }
    return isValid;
  };

  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }} onLoad >
      <Row>
        <Col>
          <Text strong>Promotional Task ID :</Text>
          &nbsp;{id}
        </Col>
      </Row>
      <Row type="flex">
        <Col span={7}>
          <Item label={<Text>Execution Date</Text>} colon={false}>
            {getFieldDecorator("date", {
              rules: [{ required: !checked, message: "Execution Date Required" }],
            })(<DatePicker 
              format="DD/MM/YYYY"
              disabledDate={(moment) =>
                Date.now() - 1000 * 60 * 60 * 24 > moment._d
              }
              disabled={!editable || checked} />)}
          </Item>
        </Col>
        <Col span={7}>
          <Item label={<Text>Execution Time</Text>} colon={false}>
            {getFieldDecorator("time", {
              rules: [{ required: !checked, message: "Execution Time Required" }],
            })(
              <TimePicker 
                // use12Hours
                disabledHours={getDisabledHours}
                format="HH:mm"
                style={{ width: "13rem" }}
                disabled={!editable || checked}
              />
            )}
          </Item>
        </Col>
        <Col span={5}>
          <Item label="Select Group" colon={false}>
            {getFieldDecorator("group", {
              rules: [{ required: true, message: "Group Required" }],
            })(
              <Select 
              showAction={["click", "focus"]}
                disabled={!editable}
                placeholder="Select Group"
                style={{ width: "13rem" }}
                onChange={(id) => {
                  group.map((each) => {
                    if (each.id === id) {
                      setGroupCount(each.count)
                    }
                  })
                }}
              >
                {group.map((grp) => (
                  <Option key={grp.id}>{grp.groupName}</Option>
                ))}
              </Select>
            )}
            <Button type="link">Create New Group</Button>
          </Item>
        </Col>
      </Row>
      {editable &&
        <Row>
          <Col span={7}>
            <Checkbox
              onChange={(event) => { onCheck(event) }}
            >Start Now</Checkbox>
          </Col>
        </Row>
      }
    </Form>
  );
};

const WrappedScheduleData = Form.create()(ScheduleData);

export default WrappedScheduleData;
