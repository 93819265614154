import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Dropdown, Menu, Icon, message } from "antd";
import "./index.less";
import { platformApi } from "../../../api";
import { ContextAPI } from "../../../ContextAPI";
import { useHistory } from "react-router-dom";
// Import Table and Form
import VPITable from "./VPITable";
import WrappedAccessoriesModal from "./VehiclePurchaseInvoiceForm";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

export default function VehiclePurchaseInvoice() {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false)
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [search, setSearch] = useState('')
  const { loginCredintials } = useContext(ContextAPI)
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [gst, setGst] = useState();

  const history = useHistory();


  // 
  useEffect(() => {
    // console.log("Login", loginCredintials.branch[0].gst)
    setGst(loginCredintials.branch[0].gst)
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "vehicle_purchase_invoice") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    //getSSI();
  }, []);
  // 
  const getMenu = () => {
    const size = count / 10 + (count % 10) ? 1 : 0;
    let menus = [];
    menus.push(10);
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => setLimit(menu)} key={menu}>
        {menu}
      </Item>
    ));
  };

  const setBooking = () => {
    platformApi.post("/api/idGenerate/purchaseInvoice").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        console.log("response.data", response.data)
        setBookingId(response.data);
      } else {
        message.error("Couldn't fetch id");
      }
    });
  };

  const menu = <Menu>{getMenu()}</Menu>;

  useEffect(() => {
    loginCredintials.roleAccess && loginCredintials.roleAccess.map(each => {
      if (each.subModule === 'vehicle_purchase_invoice') {
        if (each.access.create) {
          setCreateAccess(true)
        }
        if (each.access.update) {
          setModifyAccess(true)
        }
        if (each.access.delete) {
          setDeleteAccess(true)
        }
      }
    })
    setSpinning(true);
    setPrimaryData();
  }, []);

  const setPrimaryData = (string, val) => {
    platformApi
      .post("/api/purchaseInvoice/get", {
        page: val ? val : page,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: string
      })
      .then((res) => {
        setSpinning(false);
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            console.log(response.data)
            setData(response.data.PurchaseInvoice);
            setCount(response.data.count);
          } else {
            message.error("Failed to fetch Vehicle Purchase Invoice");
          }
        } else {
          message.error("Failed to fetch Vehicle Purchase Invoice");
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Vehicle Purchase Invoice : ", error);
        message.error("Failed to fetch Vehicle Purchase Invoice");
      });
  }

  useEffect(() => {
    setPrimaryData(search)
  }, [page, search, limit])

  const deleteData = (id) => {
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    setSpinning(true);
    // if (dataSource.filter((obj) => id === obj.id)[0].dependency.length === 0)
      platformApi
        .delete("/api/purchaseInvoice/" + id)
        .then((result) => {
          setSpinning(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              if (temp) {
                setPage(1)
                setPrimaryData(null, 1)
              }
              else {
                setPrimaryData();
              }
              message.success("Deleted Sucessfully", 2);
            } else {
              message.error("Unable to delete Vehicle Purchase Invoice", 2);
            }
          } else {
            message.error("Unable to delete Vehicle Purchase Invoice", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error("Error on Vehicle Purchase Invoice : ", error);
          message.error("Unable to delete Vehicle Purchase Invoice", 2);
        });
    // else {
    //   setSpinning(false);
    //   message.error("Unable to delete Vehicle Purchase Invoice. There are dependencies", 2);
    // }
  };

  const addData = (vpi) => {
    setSpinning(true);
    if (vpi.id) {
      platformApi
        .put(`/api/purchaseInvoice/${vpi.id}`, vpi)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === vpi.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success(
                "Vehicle Purchase Invoice saved successfully",
                2
              );
            } else {
              message.error("Unable to save Vehicle Purchase Invoice", 2);
            }
          } else {
            message.error("Unable to save Vehicle Purchase Invoice", 2);
          }
        })
        .catch((err) => {
          setSpinning(false);
          message.error("Unable to save Vehicle Purchase Invoice", 2);
          console.error("Error on Vehicle Purchase Invoice : ", err);
        });
    } else
      platformApi
        .post("/api/purchaseInvoice/", vpi)
        .then((res) => {
          setSpinning(false);
          let { data } = res;
          if (data.code === 200) {
            let { response } = res.data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Vehicle Purchase Invoice added successfully");
            } else {
              message.error("Error in Vehicle Purchase Invoice creation");
            }
          } else {
            message.error("Error in Vehicle Purchase Invoice creation");
          }
        })
        .catch((err) => {
          console.error("Error on Vehicle Purchase Invoice : ", err);
        });
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          Vehicle Purchase Invoice [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
            <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Vehicle Purchase Invoice"
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button 
          onClick={() => {
            history.push({
              pathname: '/autoadmin/vehicle_purchase_invoice/:id',
              state: {
                gst: gst,
                editable: true
              }
            })
            // history.push('/autoadmin/sale_spares_invoice/:id');
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add vehicle Purchase Invoice
        </Button>
        {/* <Button
          onClick={() => {
            setAddFlag(true);
            setEditFlag(true);
            setBooking();
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add new VPI
        </Button> */}
      </div>

      <VPITable
        pagination={{
          pageSize: limit,
          defaultCurrent: 1,
          total: count,
          current: page,
          showQuickJumper: true,
          onChange: (page) => setPage(page)
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
          // if (dataSource.filter((obj) => id === obj.id)[0].dependency.length === 0) {
          //   setSelected(id);
          //   setEditFlag(editable);
          //   setAddFlag(true);
          // }
          // else {
          //   message.error("Unable to modify Vehicle Purchase Invoice. There are dependencies", 2);
          // }
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{
          spinning,
        }}
        deleteAccess={deleteAccess}
        modify={modifyAccess}
      />
    </div>
  );
}
