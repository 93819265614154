import React, { useState, useEffect, useRef } from "react";
import {
  Modal,
  Typography,
  Form,
  Select,
  Row,
  Col,
  DatePicker,
  Input,
  Button,
  message,
} from "antd";
import WrappedChallanTable from "./VehicleTable";
import moment from "moment";
import "./index.less";
import { platformApi } from "../../../../../../api";
const { Title, Text } = Typography;
const { Option } = Select;
const { Item } = Form;

function VehiclePurchaseModal({
  editable: isEditable,
  form,
  open,
  close,
  data,
  emitData,
  modify
}) {
  const ref = useRef();
  const [id, setId] = useState(null);
  const [suppliers, setSupplier] = useState([]);
  const [branches, setBranch] = useState([]);
  const [vehicleDetails, setVehicleDetails] = useState([]);
  const [challanData, setChallanData] = useState({date: moment(new Date()), challanDate: moment(Date.now()), id : null, vehicleDetails})
  const [editable, setEditable] = useState(isEditable);
  const [challanLoading, setChallanLoading] = useState(false);
  const [error, setError] = useState({});
  const [branchBasedManufacturer, setBranchBasedManufacturer] = useState([])
  const [locationDisabled, setLocationDisabled] = useState(false)

  const tableData = {
    vehicle: {},
    color: {},
    chassisNo: "",
    engineNo: "",
    keyNo: "",
    id: "",
    batteryNo: "",
    warrantyBookNo: "",
    manMonthYear: "",
    // quantity: ""
  };
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;

  useEffect(() => {
    if (open) {
      // setEditable(editData);
      setEditable(true)
      addVehicle(tableData);
      if (!data)
        platformApi.post("/api/idGenerate/purchaseChallan/").then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setId(response.data);
            }
          }
        });
      platformApi.get("api/supplier").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let supplier = response.data.filter(
              (obj) =>
                obj.supplierType.includes("Vehicles") && obj.status === "ACTIVE"
            );
            setSupplier(supplier);
            if (!data)
              setChallanData((prevData) => ({ ...prevData, supplier: supplier[0].id }));
          }
        }
      });
      platformApi.get("/api/user/branch").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          setBranch(data.data);
          setManufacturer(data.data[0].id)
        }
      });
      // if (!data) {
      //   platformApi.get("/api/user/currentUser").then((result) => {
      //     let { data } = result;
      //     if (data.code === 200) {
      //       let { response } = data;
      //       if (response.code === 200) {
      //         setFieldsValue({ location: response.data.profile.branch[0].id });
      //       }
      //     }
      //   });
      // }
    }
  }, [open]);

  useEffect(() => {
    if (data?.id) {
      setId(data.challanNo);
      setVehicleDetails(data.vehicleDetail);
      setChallanData((prevData) => ({
				...prevData,
        id: data.id,
        challanNo: data.challanNo,
				supplierChallanNo: data.supplierChallanNo,
				supplier: data.supplier.id,
				location: data.branch.id,
				date: data.date === null ? moment(Date.now()) : moment(data.date),
				challanDate: moment(data.challanDate),
			}));
    }
  }, [data]);

  const addVehicle = (data) => {
    console.log("Add vehicle", data)
    setVehicleDetails([...vehicleDetails, data]);
  };

  const clearFields = () => {
    ref.current();
    resetFields();
    setSupplier([]);
    setId(null);
    setBranch([]);
    setVehicleDetails([]);
  };
  const modifyData = () => {
    addVehicle(tableData);
    setEditable(true);
  };
  const handleSubmit = () => {
    const challan = vehicleDetails.filter((mockChallan) => {
      return mockChallan.vehicle.id;
    });
    console.log({ challanData });
    emitData(challan);
    clearFields();
    close();
  };

  const setManufacturer = (id) => {
    platformApi.post('/api/purchaseChallan/manufacturer', { branch: id })
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setBranchBasedManufacturer(response.data.manufacturer)
          }
        }
      })
  }

  const deleteChallanVehicle = (id, index) => {
    if (id) {
      setChallanLoading(true);
      platformApi
        .delete("api/purchaseChallan/vehicle/" + id)
        .then((result) => {
          setChallanLoading(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setVehicleDetails(vehicleDetails.filter((chData) => chData.id !== id));
              message.success("Vehicle Deleted Successfully", 2);
            } else {
              message.error("Unable to delete Vehicle");
            }
          } else {
            message.error("Unable to delete Vehicle");
          }
        })
        .catch((error) => {
          console.error("Error on Vehicle Purchase Challan : ", error);
          message.error("Unable to delete Vehicle");
          setChallanLoading(false);
        });
    } else {
      setVehicleDetails(vehicleDetails.filter((chData, ind) => ind !== index));
    }
  };

  return (
    <div>
      <Modal
        wrapClassName="purchase-challan"
        title={<Title level={4}>Vehicle Details</Title>}
        visible={open}
        okText={editable ? "Save" : "Save"}
        onCancel={() => {
          clearFields();
          close();
        }}
        okButtonProps={{ disabled: (!editable && !modify) }}
        onOk={() => (editable ? handleSubmit() : modifyData())}
      >
        <Form>
          <Row>
            <Item colon={false}>
              <WrappedChallanTable
                open={open}
                clearRef={ref}
                loading={{ spinning: challanLoading }}
                delete={deleteChallanVehicle}
                // toDelete={editable}
                toDelete={true}
                editable={editable}
                error={error}
                setError={setError}
                dataSource={vehicleDetails}
                editData={setVehicleDetails}
                addVehicle={() => addVehicle(tableData)}
                manufacturer={branchBasedManufacturer}
                setLocationDisabled={setLocationDisabled}
              />
            </Item>
          </Row>
        </Form>
      </Modal>
    </div>
  );
}

const WrappedVehiclePurchaseForm = Form.create()(
  VehiclePurchaseModal
);

export default WrappedVehiclePurchaseForm;
