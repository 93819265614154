import React from 'react';
import { hot } from 'react-hot-loader/root';

import AppRouter from './routes/AppRouter';

import 'antd/dist/antd.css';
import './index.less';

const App = () => (
  <AppRouter />
);

export default hot(App);
