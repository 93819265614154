//Here comes the error..... Abracadabra 🔮✨
export const generateErrorObj = (validation, errorObj) => {
  let error = errorObj;
  validation.error.details.map((err) => {
    error[err.context.key] = err.message;
  })
  return error;
};
export const defaultToNull = (toConvert) => {
  const data = Object.keys(toConvert);
  data.map((value) => {
    toConvert[value] = toConvert[value] || null;
  });
  return toConvert;
};
export const GST18 = 18;
export const GST28 = 28;
export const GST12 = 12;
export const GST5 = 5;
 
//formats the value 
export const formatValue = (event, type, subType = false) => {
  let value = event.target.value
  if (type === 'allCaps') {
    value = value.split(' ').map(v => v.substring(0, 1).toUpperCase() + v.substring(1).toLowerCase()).join(' ')
    if (subType)
      value = value.replace(/[^0-9a-zA-Z]/g, () => {
        return '';
      });
  }
  else if (type === "firstCaps") {
    value = value.replace(/^./, value[0].toUpperCase());
    if (subType)
      value = value.replace(/[^0-9a-zA-Z]/g, () => {
        return '';
      });
  }
  else if (type === "onlyNo")
    value = value.replace(/[^0-9]/g, () => {
      return '';
    });
  else if (type === "noWithDot")
    value = value.replace(/[^0-9.]/g, () => {
      return '';
    });
  else if (type === "code")
    value = value.replace(/[^0-9a-zA-Z]/g, () => {
      return '';
    });
  else if (type === "toUpperCase")
    value = value.toUpperCase()
  else if (type === "toLowerCase")
    value = value.toLowerCase()
  return value
};