import React, { useState, useEffect } from "react";
import moment from "moment";
import { Table, Typography, message} from "antd";
import { platformApi } from "../../../../api";
import PromotionTable from "./PromotionTable/index";
import ModalForm from "./Form/index";
const { Text } = Typography;
const index = (props) => {
    // 
    const [data, setData] = useState([]);
    const [edit, setEdit] = useState(false);
    const [index, setIndex] = useState(null);
    const [open, setOpen] = useState(false);



    useEffect(() => {
        // setSpinning(true);
        platformApi
            .get("/api/whatsapp/promoWhatsapp")
            .then((res) => {
                // setSpinning(false);
                const { data } = res;
                if (data.code === 200) {
                    if (data.msg.data.length > 0) {
                        let arr = [];
                        let templateData = data.msg.data;
                        console.log("xxxx", data.msg.data)
                        for (let i = 0; i < templateData.length; i++) {
                            if (templateData[i].name != "") {
                                arr.push(templateData[i]);
                            }
                        }
                        setData(arr);
                    }
                } else {
                    message.error("Unable to fetch templates", 2);
                }
            })
            .catch(() => {
                message.error("Unable to fetch templates", 2);
            });
    }, []);
    // 
    const column = [
        {
            title: "Module",
            dataIndex: "module",
            key: "module",
        },
        {
            title: "Sub Module",
            dataIndex: "submodule",
            key: "submodule",
        },
        {
            title: "Template Name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Created On",
            dataIndex: "createdAt",
            key: "createdOn",
            render: (text) => (
                <span>{moment(new Date(text)).format("DD/MM/YYYY")} </span>
            ),
        },

        {
            title: "Action",
            render: (data) => {
                return (
                    <div>
                        {
                            modifyAccess ?
                                <span
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props.openData(data.id, true);
                                    }}
                                    className="linkylink"
                                >
                                    Modify
                                </span>
                                :
                                <span></span>
                        }
                        {
                            !modifyAccess
                                ?
                                <Text strong disabled> No Access Given</Text>
                                :
                                <span></span>
                        }



                    </div>
                );
            },
        },
    ];
    return (
        <div>
            <PromotionTable
                style={{ cursor: "pointer" }}
                dataSource={data}
                loading={props.spinning}
                pagination={props.pagination}
                rowKey={(record) => record.id}
                // onRow={(record) => ({
                //     onClick: () => props.openData(record.id, false),
                // })}
                openData={(index, edit) => {
                    setEdit(edit);
                    setIndex(index);
                    setOpen(true);
                }}
                columns={column}
            />
            <ModalForm
                close={() => {
                    setOpen(false);
                    setIndex(null);
                }}
                visible={open}
                edit={edit}
                setEdit={setEdit}
                data={data[data.findIndex((item) => item.id === index)]}
                index={index}
                setData={setData}
                // updateTemplate={updateTemplate}
            />
        </div>
    )
}

export default index
