import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  Collapse,
  Icon,
} from "antd";
import { platformApi } from "../../../../../api";
const { TextArea } = Input;
const { Item } = Form;
const { Panel } = Collapse;
const { Text } = Typography;
const { Option } = Select;

const VehicleData = ({
  form,
  data,
  recvData,
  editable,
  validate,
  setValidate,
  count,
  groupCount,
  setCount
}) => {
  const { getFieldDecorator, getFieldValue } = form;
  const [legends, setLegends] = useState([]);
  const [addCampaign, setAddCampaign] = useState(null);
  const [campaigns, setCampaigns] = useState([]);
  const [textCount, setTextCount] = useState(0);
  const [maxLength, setMaxLength] = useState()
  const filterMethod = (input, option) => {
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    setAddCampaign(input);
  };

  useEffect(() => {
    form.setFieldsValue({
      name: data.name,
      campaign: data.campaign,
      text: data.text,
    });
    validate.add(validatePage)
    setValidate(validate)
    data.textCount != null ? setTextCount(data.textCount) : setTextCount(0);
    platformApi.get("/api/promotions/campaign").then((res) => {
      setCampaigns(res.data.response.data);
    });
    platformApi.get("/api/promotions/legends").then((res) => {
      setLegends(res.data.response.split(","));
    });
  }, []);

  useEffect(() => {
    if (recvData) {
      form.setFieldsValue({
        name: recvData.message.promotionName,
        campaign: recvData.message.campaignName ? recvData.message.campaignName.id : undefined,
        text: recvData.message.sms,
      });
      data.id = recvData.id;
      setTextCount(recvData.message.sms.length);
    }
  }, [recvData, editable]);

  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.name = getFieldValue("name");
      data.campaign = getFieldValue("campaign");
      data.text = getFieldValue("text");
      data.textCount = textCount;
    }
    return isValid;
  };

  const textAreaChange = (e) => {
    data.text = e.target.value;
    if (data.text) {
      let tempText = data.text;
      let multilingual = false;
      for (let i = 0; i < tempText.length; i = i + 1) {
        if (tempText.charCodeAt(i) > 255) {
          multilingual = true;
          break;
        }
      }
      if (multilingual) {
        let grpCount = parseInt((tempText.length / 70).toFixed());
        grpCount += 1;
        grpCount = grpCount * groupCount
        setCount(grpCount)
      }
      else {
        let grpCount = parseInt((tempText.length / 160).toFixed());
        grpCount += 1;
        grpCount = grpCount * groupCount
        setCount(grpCount)
      }
    }
    e.target.value ? setTextCount(e.target.value.length) : setTextCount(0);
    let flag = false
    for (let i = 0; i < e.target.value.length; i = i + 1) {
      if (e.target.value.charCodeAt(i) > 255) {
        flag = true;
        break;
      }
    }
    if (flag) {
      setMaxLength(70)
    }
    else {
      setMaxLength(459)
    }
  };

  return (
    // <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
    <Form style={{ height: "40vh" }}>
      <Row type="flex" justify="space-between">
        <Col span={7}>
          <Item label={<Text>Promotion Name</Text>} colon={false}>
            {getFieldDecorator("name")(
              <Input disabled={!editable} placeholder="Promotion Name" />
            )}
          </Item>
        </Col>
        {/* <Col span={7}>
          <Item label={<Text>Campaign Name</Text>} colon={false}>
            {getFieldDecorator("campaign")(
              <Select
                placeholder="Campaign Name"
                showSearch
                filterOption={filterMethod}
                disabled={!editable}
              >
                {campaigns.map((grp) => (
                  <Option key={grp.id}>{grp.name}</Option>
                ))}
              </Select>
            )}
          </Item>
        </Col> */}
        {/* <Col span={5}>
          <Button
            disabled={!editable}
            style={{ marginTop: "2.5rem" }}
            onClick={() => {
              if (addCampaign != null) {
                platformApi
                  .post("/api/promotions/campaign/", {
                    name: addCampaign,
                  })
                  .then((res) => {
                    setCampaigns([...campaigns, res.data.response.data]);
                    // campaigns.push(res.data.response.data);
                    form.setFieldsValue({
                      campaign: res.data.response.data.id,
                    });
                    setAddCampaign(null);
                  });
              }
            }}
            type="primary"
          >
            Add
          </Button>
        </Col> */}
      </Row>
      <Row style={{ marginBottom: "0%", marginTop: "-1%" }}>
        <Item label="SMS Text" colon={false}>
          {getFieldDecorator("text", {
            rules: [{ required: true, message: "Enter SMS Text" }],
          })(
            <TextArea
              disabled={!editable}
              placeholder="SMS Text"
              autoSize={{ minRows: 3 }}
              // maxLength={maxLength}
              onChange={(e) =>
                textAreaChange(e)
              }
            />
          )}
          No. of characters : {textCount}/{maxLength ? maxLength : 459}
        </Item>
      </Row>
      <Row>
        <Col span={10}>
          <Item>
            <Collapse
              destroyInactivePanel={true}
              bordered={false}
              expandIcon={({ isActive }) => (
                <Icon type="caret-right" rotate={isActive ? 90 : 0} />
              )}
              style={{
                marginTop: "-7%",
                backgroundColor: "transparent",
                width: "60vw",
              }}
            >
              <Panel
                style={{
                  marginTop: "1rem",
                  borderBottom: "none",
                  // height: "200px",
                }}
                header={<Text strong>Legends</Text>}
              >
                {legends.map((text, index) => (
                  <div key={index}> {text} </div>
                ))}
              </Panel>
            </Collapse>
            {/* {legends.map((text, index) => (
              <div key={index}> {text} </div>
            ))} */}
          </Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedVehicleData = Form.create()(VehicleData);

export default WrappedVehicleData;
