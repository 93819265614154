import React, { useState, useEffect } from 'react'
import Moment from 'moment'
import { withRouter } from 'react-router-dom'

import { Form, Row, Input, Col, Tag, Card, Select, DatePicker, TimePicker } from 'antd'
const ActivityDetails = (props) => {
  const { editable, data, setData, form } = props
  const { Option } = Select
  const { TextArea } = Input
  const { Item } = Form;

  const {
    getFieldDecorator,
    resetFields,
    getFieldValue,
    setFieldsValue,
  } = form;
  useEffect(() => {
    if (data) {
      // console.log("data", data)
      form.setFieldsValue({
        enquiryType: data.enquiryType ? data.enquiryType : undefined
      })
    }
  }, [data])

  const renderDetails = (data) => {
    if (data.booking) {
      const { booking } = data;
      return (
        <>
          {(booking.bookingId) ?
            <Col span={12}>
              <strong>Booking ID: </strong>{booking.bookingId}
            </Col>
            : null
          }
          {(booking.bookingStatus) ?
            <Col span={12}>
              <strong>Supervisor Authorisation: </strong>{booking.bookingStatus}
            </Col>
            : null
          }
          {
            (booking.authentication) ?
              <Col span={12}>
                <strong>Customer Authorisation: </strong>{booking.authentication && booking.authentication?.verifiedAt ? "Verified" : "Not Verified"}
              </Col> : null
          }
          <Col span={12}>
            <strong>Booked Vehicle:</strong>
            <Card style={{ maxHeight: '100px' }}>
              <Row type="flex" justify="space-between" gutter={[16, 8]}>
                <Col span={7}>
                  <span><img src={(booking.color) ? booking.color.url : ""} width="100%" height="auto" alt="" /></span>
                </Col>
                <Col span={13} style={{ fontSize: '10px' }}>
                  {(booking.vehicle) ? <strong>{booking.vehicle.modelName} - {booking.vehicle.modelCode}</strong> : null}<br />
                  {(booking.color) ? <><strong>Color Code: </strong>{booking.color.code}</> : null}
                </Col>
              </Row>
            </Card>
          </Col>
        </>
      )
    }
    else if (data.quotation) {
      const { quotation } = data;
      return (
        <>
          {(quotation.quotationId) ?
            <Col span={12}>
              <strong>Quotation ID: </strong>{quotation.quotationId}
            </Col>
            : null
          }
          {
            (quotation.vehicle.length) ?
              <><Col span={12}>
                <strong>Vehicles: </strong>
                {
                  quotation.vehicle.map(vehicle => <Tag>{vehicle.vehicleDetail.modelName}</Tag>)
                }</Col>
              </>
              : null
          }
        </>
      )
    }
  }
  const getDisabledHours = () => {
    var hours = [];
    // console.log(new Date(), new Date(data.scheduleDate))
    if (new Date(data.scheduleDate) < new Date()) {
      for (var i = 0; i < Moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (new Date(data.scheduleDate) < new Date()) {
      if (selectedHour === Moment().hour()) {
        for (var i = 0; i < Moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };

  return (
    <div>
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={12}>
          <strong>Activity Session ID: </strong>
          {data ? data.activityId : "-"}</Col>
        <Col span={12}>
          <p>
            <strong>Session Date: </strong>
            {data && data.createdAt ? Moment(data.createdAt).format('DD-MM-YYYY') : ' -'}
          </p>
        </Col>
        {
          (data && !data.booking) ?
            <Col span={12}>
              {data && (data.sms || !(data.sms || data.quotation)) ?
                <strong>Interaction Type: </strong>
                : <strong>Lead Source: </strong>}
              {data ? data.interactionType : "-"}
            </Col> : null
        }
        <Col span={12}>
          <p>
            <strong>Session Time: </strong>
            {data && data.createdAt ? Moment(data.createdAt).format('HH:mm') : ' -'}
          </p>
        </Col>

        {data ? renderDetails(data) : null}
        {data && !data.sms ?
          <Col span={12}>
            {/* <Row> */}
            <Row type="flex" gutter={[16, 16]}>
              <Col span={8}>
                <strong>Enquiry Type: </strong>
              </Col>
              <Col span={15}>
                <Item>
                  {getFieldDecorator("enquiryType", {
                    // rules: [{ required: true, message: "Enter Enquiry Type" }],
                  })(
                    <Select
                      showAction={["click", "focus"]}
                      placeholder="Enquiry Type"
                      // onChange={handleEnquiryChange}
                      onChange={type => setData({ ...data, enquiryType: type })}
                      // value={data ? data.enquiryType : ""}
                      disabled={!editable}
                    >
                      <Option value="Cold">Cold</Option>
                      <Option value="Warm">Warm</Option>
                      <Option value="Hot">Hot</Option>
                    </Select>
                  )}
                </Item>
              </Col>
            </Row>
            {/* <Col span={12}>
                <p>
                  <strong>Enquiry Type: </strong>
                </p>
              </Col>
              <Col span={10}>
                <Select
                  onChange={type => setData({ ...data, enquiryType: type })}
                  value={data ? data.enquiryType : ""}
                  disabled={!editable}
                >
                  <Option value="Cold">Cold</Option>
                  <Option value="Warm">Warm</Option>
                  <Option value="Hot">Hot</Option>
                </Select>
              </Col> */}
            {/* </Row> */}
          </Col>
          : null}
        <Col>
          <Row type="flex" gutter={[16, 16]}>
            <Col span={10}>
              <p>
                <strong>Next Followup Date & Time :</strong>
              </p>
            </Col>
            <Col span={7}>
              <DatePicker
                onChange={scheduleDate => setData({
                  ...data, scheduleDate: Moment(scheduleDate).format('DD-MM-YYYY')
                })}
                value={(data && data.scheduleDate) ? Moment(data.scheduleDate, 'DD-MM-YYYY') : null}
                disabled={!editable}
                disabledDate={(moment) =>
                  Date.now() - 1000 * 60 * 60 * 24 > moment._d
                }
                // showToday={false}
                format="DD-MM-YYYY"
              />
            </Col>
            <Col span={7}>
              <TimePicker
                onChange={scheduleTime => setData({
                  ...data, scheduleTime: Moment(scheduleTime).format('HH:mm')
                })}
                value={(data && data.scheduleTime) ? Moment(data.scheduleTime, 'HH:mm') : null}
                format="HH:mm"
                disabled={!editable}
                disabledHours={getDisabledHours}
                disabledMinutes={getDisabledMinutes}
              />
            </Col>
          </Row>
        </Col>
        {
          (data && !data.sms) ?
            <Col span={24}>
              <p>
                <strong>Remarks: </strong>
              </p>
              <TextArea
                rows={4}
                onChange={event => setData({ ...data, remarks: event.target.value })}
                disabled={!editable}
                value={data ? data.remarks : ""} />
            </Col> : null
        }
      </Row>
    </div>
  )
}

const WrappedActivityDetails = Form.create({ name: "calling_interface" })(
  ActivityDetails
);

export default withRouter(WrappedActivityDetails);
// export default ActivityDetails