import { SyncOutlined } from '@ant-design/icons';
import { Col, Divider, Row } from 'antd';
import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import QRCode from 'qrcode'
import moment from 'moment'

const GatePass = ({
  data,
  unum,
  setUnum,
  uniqueNumber
}) => {


  const [QR, setQR] = useState("")


  const generateQR = async (data) => {
    try {
      let url = await QRCode.toDataURL(data)
      setQR(url)
    } catch (err) {
      console.error(err)
    }
  }

  useEffect(() => {
    const payload = "Model: " + data.vehicle.vehicle.modelCode + "-" + data.vehicle.vehicle.modelName +
      '\n' + "Color: " + data.vehicle.color.code + "-" + data.vehicle.color.color +
      '\n' + "Engine No: " + data.vehicle.engineNo +
      '\n' + "Chassis No: " + data.vehicle.chassisNo +
      '\n' + "RegisterNo :" + data.vehicle.registerNo +
      '\n' + "Code: " + unum
    generateQR(payload)
  }, [unum])

  return (
    <>
      {
        data.jobStatus === "Gate Pass" &&
        <div style={{ margin: '3rem' }}>
          <div style={{ display: 'inline' }}>
            <h3>Gate Pass:</h3>
          </div>
          <Row className="box" style={{ padding: '1rem' }} >
            <Col span={3}></Col>
            <Col span={8}>
              <div>
                <img
                  className="image" src={data.vehicle && data.vehicle.color?.url} style={{ height: '200px', width: '200px', objectFit: 'cover' }} />
              </div>
            </Col>
            <Col span={13} style={{ padding: '1rem' }}>
              <Row>
                <Col span={12}>
                  <table>
                    <thead>
                      <tr>
                        <td><h2>{data.vehicle && data.vehicle.vehicle.modelName}</h2></td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>Customer Name</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.customer && data.customer.name}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>Register Number</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.vehicle && data.vehicle.registerNo}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>Payment Status</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}> Payment Recieved</td>
                      </tr>
                    </thead>
                  </table>
                </Col>
                <Col span={12}>
                  <table>
                    <thead>
                      <tr>
                        <td rowSpan={4} style={{ textAlign: 'left', paddingLeft: '.5rem' }}>
                          <img style={{ height: '150px', width: '150px' }} src={QR} />
                        </td>
                      </tr>
                    </thead>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <h2 style={{ fontWeight: 'bold' }}>Gate Code: {unum}
            {/* <span style={{color:'grey',cursor:'pointer'}} onClick={()=>{uniqueNumber()}}><SyncOutlined /></span> */}
          </h2>
        </div>
      }
      {
        data.jobStatus === "Delivered" &&
        <div style={{ margin: '3rem' }}>
          <div style={{ display: 'inline' }}>
            <h3>Delivered:</h3>
          </div>
          <Row className="box" style={{ padding: '1rem' }} >
            <Col span={3}></Col>
            <Col span={8}>
              <div>
                <img
                  className="image" src={data.vehicle && data.vehicle.color?.url} style={{ height: '200px', width: '200px', objectFit: 'cover' }} />
              </div>
            </Col>
            <Col span={13} style={{ padding: '1rem' }}>
              <Row>
                <Col span={24}>
                  <table>
                    <thead>
                      <tr>
                        <td><h2>{data.vehicle && data.vehicle.vehicle.modelName}</h2></td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>Customer Name</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.customer && data.customer.name}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>Register Number</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.vehicle && data.vehicle.registerNo}</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>Payment Status</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}> Payment Completed</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 'bolder' }}>Delivered time</td>
                        <td>:</td>
                        <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}> {data.updatedAt && moment(data.updatedAt).format("DD/MM/YYYY")}</td>
                      </tr>
                    </thead>
                  </table>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          {/* <h2 style={{ fontWeight: 'bold' }}>Gate Code: {unum}</h2> */}
        </div>
      }
    </>


  )
}

export default GatePass