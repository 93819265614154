import React, { useState } from 'react'
import { Modal, Button, Typography, Steps, Icon, message } from "antd";
import JobComplaint from './JobComplaint';
import WrappedEstimates from './Estimates';
import WrappedPayments from './Payments';
import GatePass from './GatePass';
import { useEffect } from 'react';
const { Step } = Steps;
const { Text } = Typography;
const JobSteps = ({
    data,
    status,
    id,
    current,
    setCurrent
}) => {
    const [totalData, setTotalData] = useState(null)
    const [prevComplaint, setPreviousComplaint] = useState([])
    const [totalComplaints, setTotalComplaints] = useState([])
    const [allow, setAllow] = useState(null)
    const [unum, setUnum] = useState(0)
    const steps = [
        { id: 1, title: "Job Card" },
        { id: 2, title: "Estimates" },
        { id: 3, title: "Payments" },
        { id: 4, title: "Gate Pass" },
    ];

    useEffect(() => {
        console.log("data,status,id", data, status, id)
        if (status == 0) {
            setAllow(1)
        }
        else if (status == 1) {
            setAllow(1)
        }
        else if (status == 2 || status == 3 || status == 4) {
            setAllow(1)
        }
        else if (status == 5) {
            setAllow(2)
        }
        else if (status == 6) {
            setAllow(3)
        }

    }, [status])

    const uniqueNumber = () => {
        var minm = 100000;
        var maxm = 999999;
        setUnum(Math.floor(Math.random() * (maxm - minm + 1)) + minm);
    }

    useEffect(() => {
        uniqueNumber()
    }, [])

    useEffect(() => {
        setPreviousComplaint(data.complaint)
    }, [data])

    const forms = [
        <JobComplaint
            total={totalData}
            setTotal={setTotalData}
            totalComplaints={totalComplaints}
            setTotalComplaints={setTotalComplaints}
            data={data}
            status={status}
        />,
        <WrappedEstimates
            total={totalData}
            data1={data}
            Estimate={data.Estimate}
            setTotal={setTotalData}
            prevComplaint={prevComplaint}
            setPreviousComplaint={setPreviousComplaint}
            totalComplaints={totalComplaints}
            setTotalComplaints={setTotalComplaints}
            pdf={data.jobOrderPdf}
            status={status}
        />,
        <WrappedPayments
            total={totalData}
            setTotal={setTotalData}
            status={status}
            data={data}
            status={status}
        />,
        <GatePass
            total={totalData}
            setTotal={setTotalData}
            data={data}
            status={status}
            unum={unum}
            setUnum={setUnum}
            uniqueNumber={uniqueNumber}
        />
    ];
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };
    const stepSelect = (index) => {
        setCurrent(index)
    }


    return (
        <div>
            {/* <Steps size="small" type="navigation" current={current}>
                {steps.map((step, index) => (
                    <Step
                        key={step.id}
                        onClick={() => {
                            stepSelect(index)
                        }}
                        // icon={icons[index]}
                        title={<Text>{step.title}</Text>}
                    />
                ))}
            </Steps> */}
            <div className="steps-content">
                {
                    forms[current]
                }
            </div>
            {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {
                    current > 0 ? <Button style={{ marginRight: '1rem' }} onClick={() => { prev() }}>Previous</Button> : null
                }
                {
                    current < allow && current < 3 ? <Button type="primary" onClick={() => { next() }}>Next</Button> : null
                }
            </div> */}
        </div>
    )
}
export default JobSteps;