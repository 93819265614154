import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";

const { Text } = Typography;

const VehicleTable = ({
  dataSource,
  openModal,
  delete: deleteData,
  spinner,
  pagination,
  modify,
  deleteAccess
}) => {
  const column = [
    {
      title: <span> Model Name</span>,
      dataIndex: "vehicle",
      key: "name",
      render: (record) => (
        <span>{record && `${record.modelCode} - ${record.modelName}`}</span>
      ),
    },
    {
      title: <span>Registration No</span>,
      dataIndex: "registerNo",
      key: "regno",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <span>Owner</span>,
      dataIndex: "customer",
      key: "owner",
      render: (record) => (
        <span>
          {record[0] && record[0].customer && record[0].customer.name}
        </span>
      ),
    },
    {
      title: <span>Active</span>,
      dataIndex: "active",
      key: "status",
      render: (record) => <span>{record === true ? "YES" : "NO"}</span>,
    },
    {
      title: <span>Actions</span>,
      key: "actions",
      render: (data) => (
        <div>
          {
            modify ?
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  openModal(data.id, true);
                }}
                className="linkylink"
              >
                Modify
          </span>
              : <span></span>
          }
          {
            deleteAccess ?
              <Popconfirm
                title="Are you sure delete this Vehicle?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  deleteData(data.id);
                }}
              >
                <span
                  className="linkylink"
                  onClick={(event) => event.stopPropagation()}
                >
                  <Divider type="vertical" />
                  Delete
                 </span>
              </Popconfirm>
              :
              <span></span>
          }


          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }

        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => {
          openModal(props.id, false);
        },
      })}
      loading={spinner}
    />
  );
};

export default VehicleTable;
