import React, { useState, useEffect,useContext } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import { Typography, Input, Button, Dropdown, Menu, Icon, message, Tabs } from "antd";


const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const { TabPane } = Tabs

import SparesTable from './SparesTable';
import WrappedSparesModal from './SparesModal';
import '../index.less';
import { platformApi } from '../../../../api'
// import { set } from 'lodash';
import WrappedSIForm from './SIForm';
import { ContextAPI } from '../../../../ContextAPI';

const SparesInventory = () => {
    const [dataSource, setData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [selectedData, setSelectedData] = useState(null);
    const [open, setOpen] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [editable, setEditable] = useState(false);

    const [createAccess, setCreateAccess] = useState(false);
    const [modifyAccess, setModifyAccess] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);

    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);

    const [total, setTotal] = useState(0);
    const [searches, setSearch] = useState('');
    const [branch, setBranch] = useState([]);
    const [filteropen, setFilteropen] = useState(false);
    const [visible, setVisible] = useState(false)
    const { loginCredintials } = useContext(ContextAPI);

    const getMenu = () => {
        let size = count / 10 + (count % 10) ? 1 : 0;
        const menus = [];
        menus.push(10);
        size = size <= 10 ? size : 10;
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
                {menu}
            </Item>
        ));
    };
    const menu = <Menu>{getMenu()}</Menu>;

    useEffect(() => {
        getPSI(searches, null);
    }, []) 
    useEffect(() => {
        getPSI(searches);
    }, [page, limit, searches]);
    useEffect(() => {
        loginCredintials.roleAccess &&
          loginCredintials.roleAccess.map((each) => {
            if (each.subModule === "report") {
              if (each.access.create) {
                setCreateAccess(true);
              }
              if (each.access.update) {
                setModifyAccess(true);
              }
              if (each.access.delete) {
                setDeleteAccess(true);
              }
            }
          });
        // setPageData();
        // if (props.customerId) {
        //   setAddFlag(true);
        //   setSelected({ ...props.cusData });
        // }
      }, []);
    

    // const getAllPSI = () => {
    //     platformApi.get("/api/sparesInventory").then((result) => {
    //         setSpinning(false)
    //         let { data } = result;
    //         if (data.code === 200) {
    //             let { response } = data;
    //             if (response.code === 200) {
    //                 console.log(response.data, 'res1')
    //                 let data = []
    //                 for (let item of response.data) {
    //                     let filterData = response.data.filter((i) => i.partNo.id == item.partNo.id)
    //                     let fl = true;
    //                     for (let item of data) {
    //                         for (let item2 of filterData) {
    //                             if (item2.id == item[0].id) {
    //                                 fl = false
    //                                 break
    //                             }
    //                         }
    //                         if (fl == false) {
    //                             break
    //                         }
    //                     }
    //                     if (fl) {
    //                         data.push(filterData)
    //                     }
    //                 }
    //                 let finalData = []

    //                 for (let item of data) {
    //                     if (item.length == 1) {
    //                         let newData = item[0]
    //                         newData.branch = [{
    //                             partId: newData.partNo.id,
    //                             sparesId: item[0].id,
    //                             branch: item[0].branch,
    //                             binNum: item[0].binNum,
    //                             reorderQuantity: item[0].reorderQuantity,
    //                             maxStock: item[0].maxStock,
    //                             minStock: item[0].minStock,
    //                             reorderLevel: item[0].reorderLevel,
    //                             phyQuantity: item[0].phyQuantity,
    //                             accQuantity: item[0].accQuantity,
    //                             transactions: item[0].transactions
    //                         }]
    //                         finalData.push(newData)
    //                     }
    //                     else {
    //                         let newData = item[0]
    //                         let branch = []
    //                         let transactions = {
    //                             sparesPurchase: [],
    //                             sparesSale: []
    //                         }
    //                         for (let d of item) {
    //                             branch.push({
    //                                 partId: d.partNo.id,
    //                                 sparesId: d.id,
    //                                 branch: d.branch,
    //                                 binNum: d.binNum,
    //                                 reorderQuantity: d.reorderQuantity,
    //                                 maxStock: d.maxStock,
    //                                 minStock: d.minStock,
    //                                 reorderLevel: d.reorderLevel,
    //                                 phyQuantity: d.phyQuantity,
    //                                 accQuantity: d.accQuantity,
    //                                 transactions: d.transactions
    //                             })
    //                             if (d.transactions) {
    //                                 transactions.sparesPurchase = [...transactions.sparesPurchase, ...d.transactions.sparesPurchase]
    //                                 transactions.sparesSale = [...transactions.sparesSale, ...d.transactions.sparesSale]
    //                             }
    //                         }
    //                         newData.transactions = transactions
    //                         newData.branch = branch
    //                         finalData.push(newData)
    //                     }
    //                 }
    //                 console.log("final", finalData)
    //                 // setData(finalData)
    //             } else {
    //                 message.error('Unable to fetch vehicles', 2);
    //             }
    //         } else {
    //             message.error('Unable to fetch vehicles', 2);
    //         }
    //     })
    //         .catch((err) => {
    //             console.error("PSI Fetch Error: ", err);
    //             message.error("Couldn't Fetch PSI");
    //         });
    // }
    const collapseFilter = () => {
        filteropen ? setFilteropen(false) : setFilteropen(true);
        // let obj = {
        //   name: "",
        // };
        // setSearch(obj);
    }; 
    const getPSI = (searches, val) => {
        setSpinning(true)
        platformApi.post("/api/sparesInventory/get", {
            page: val ? val : page,
            size: limit,
            searchString: searches && searches.name
        }).then((result) => {
            setSpinning(false)
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    console.log(response.data, 'res2')
                    setCount(response.data.count);
                    setTotal(response.data.total)
                    let data = [] 
                    for (let item of response.data.sparesInventory) {
                        let filterData = response.data.sparesInventory.filter((i) => i?.partNo?.id == item?.partNo?.id)
                        let fl = true;
                        for (let item of data) {
                            for (let item2 of filterData) {
                                if (item2?.id == item[0]?.id) {
                                    fl = false
                                    break
                                }
                            }
                            if (fl == false) {
                                break
                            }
                        }
                        if (fl) {
                            data.push(filterData)
                        }
                    }
                    let finalData = []

                    for (let item of data) {
                        if (item.length == 1) {
                            let newData = item[0]
                            // let q=0;
                            // let q2=0
                            // newData.transactions.sparesPurchase.map(item=>{
                            //     item.purchaseItemInvoice.map(dt=>{
                            //     // console.log("item",d.id,record.partNo.id)
                            //     if(dt.partNumber.id===newData.partNo.id){
                            //         q+=dt.quantity
                            //     }
                            //     })
                            // })
                            // newData.transactions.sparesSale.map(item=>{
                            //     item.saleItemInvoice.map(dt=>{
                            //         // console.log("item",d.id,record.partNo.id)
                            //         if(dt.partNumber.id===newData.partNo.id){
                            //             q2+=dt.quantity
                            //         }
                            //         })
                            // })
                            newData.branch = [{
                                partId: newData.partNo.id,
                                sparesId: item[0].id,
                                branch: item[0].branch,
                                binNum: item[0].binNum,
                                reorderQuantity: item[0].reorderQuantity,
                                maxStock: item[0].maxStock,
                                minStock: item[0].minStock,
                                reorderLevel: item[0].reorderLevel,
                                phyQuantity: item[0].phyQuantity,
                                accQuantity: item[0].accQuantity,
                                transactions: item[0].transactions
                            }]
                            finalData.push(newData)
                        }
                        else {
                            let newData = item[0]
                            let branch = []
                            let transactions = {
                                sparesPurchase: [],
                                sparesSale: []
                            }
                            // console.log("New Data", newData)
                            // total = total + newData.phyQuantity
                            for (let d of item) {
                                // let q=0;
                                // d.transactions.sparesPurchase.map(item=>{
                                //   item.purchaseItemInvoice.map(dt=>{
                                //     // console.log("item",d.id,record.partNo.id)
                                //     if(dt.partNumber.id===d.partNo.id){
                                //         q+=dt.quantity
                                //     }
                                //   })
                                // })

                                // let q2=0;
                                // d.transactions.sparesSale.map(item=>{
                                //   item.saleItemInvoice.map(dt=>{
                                //     // console.log("item",d.id,record.partNo.id)
                                //     if(dt.partNumber.id===d.partNo.id){
                                //         q2+=dt.quantity
                                //     }
                                //   })
                                // })
                                // console.log("quantity",q,q2)

                                branch.push({
                                    partId: d.partNo.id,
                                    sparesId: d.id,
                                    branch: d.branch,
                                    binNum: d.binNum,
                                    reorderQuantity: d.reorderQuantity,
                                    maxStock: d.maxStock,
                                    minStock: d.minStock,
                                    reorderLevel: d.reorderLevel,
                                    phyQuantity: d.phyQuantity,
                                    accQuantity: d.accQuantity,
                                    transactions: d.transactions
                                })
                                if (d.transactions) {
                                    transactions.sparesPurchase = [...transactions.sparesPurchase, ...d.transactions.sparesPurchase]
                                    transactions.sparesSale = [...transactions.sparesSale, ...d.transactions.sparesSale]
                                    // console.log("tr",d.transactions)
                                }
                            }
                            // console.log("oi", branch, transactions)
                            newData.transactions = transactions
                            newData.branch = branch
                            console.log(newData)
                            finalData.push(newData)
                        }
                    }
                    setData(finalData)
                    //setData(val);
                } else {
                    message.error('Unable to fetch vehicles', 2);
                }
            } else {
                message.error('Unable to fetch vehicles', 2);
            }
        })
            .catch((err) => {
                setSpinning(false)
                console.error("PSI Fetch Error: ", err);
                message.error("Couldn't Fetch PSI");
            });
    }
    return (
        <div>
            <div className="accessories-head">
                <div className="add-inventory">
                    <Title className="heading" style={{ width: "60%" }} level={4}>
                        Spares Inventory
                    </Title>
                    <Button
                        type="primary"
                        onClick={() => {
                            setVisible(true)
                        }}
                    // style={{ margin: "0 3%", width: "20%" }}
                    // type="primary"
                    disabled={!createAccess}
                    >
                        Add to Inventory
                    </Button>
                </div>
                <div className="add-inventory">
                    <h3 level={4}>
                        Total Cost:&nbsp;{total}
                    </h3>
                </div>
                <div className="search-inventory">
                    <Search
                        style={{ width: "50%" }}
                        placeholder="Search Inventory"
                        onSearch={(event) => {
                            let searches = { name: event };
                            setPage(1)
                            setSearch(searches);
                        }}
                        onChange={(event) => {
                            if (event.target.value === "") {
                                setSearch(null)
                                setPage(1)
                            }
                        }}
                    // onSearch={(event) => {
                    //     let searches = { name: event };
                    //     setPage(1);
                    //     setSearch(searches);
                    // }}
                    // onChange={(event) => {
                    //     if (event.target.value === "") {
                    //     setSearch(null);
                    //     setPage(1);
                    //     }
                    // }}
                    />
                    <div style={{ width: "12%", textAlign: "center" }}>
                        <Button
                            type="link"
                            onClick={() => {
                                collapseFilter();
                            }}
                        >
                            {filteropen ? "Back" : "Advanced  Filters"}
                        </Button>
                    </div>
                </div>
            </div>
            <SparesTable
                pagination={{
                    onChange: (page) => {
                        setPage(page);
                    },
                    pageSize: limit,
                    defaultCurrent: 1,
                    current: page,
                    total: count,
                    showQuickJumper: true,
                }}
                openModal={(data) => {
                    setSelected(data)
                    setSelectedData(data)
                    setOpen(true);
                }}
                dataSource={dataSource}
                spinner={spinning}
                getPSI={getPSI}
            // delete={deleteData}
            // deleteAccess={deleteAccess}
            // modify={modifyAccess}
            />

            <WrappedSIForm
                visible={visible}
                setVisible={setVisible}
                getPSI={getPSI}
                close={() => {
                    setVisible(false)
                }}
            />
 
            <WrappedSparesModal
                data={selectedData}
                open={open}
                editable={editable}
                close={() => {
                    setOpen(false);
                    setSelected(false);
                    setSelectedData(null)
                }}
                getPSI={getPSI}
                modify={modifyAccess}
            />
        </div>
    )
}

export default withRouter(SparesInventory);