import { Col, Progress, Row } from 'antd';
import React, { useState, useEffect } from 'react'
import { withRouter } from "react-router-dom";
import { Collapse, Select } from 'antd';
import { CheckCircleTwoTone, SettingOutlined } from '@ant-design/icons';
import empty from '../../../../../../assets/empty.png'
import cross from '../../../../../../assets/cross.png'
import './index.less'
const { Panel } = Collapse;
const { Option } = Select;


const DetailsCard = ({ data }) => {
  return (
    <div style={{ backgroundColor: 'white' }}  >
      <Row className="box" style={{ padding: '1rem', marginBottom: '1rem' }} >
        <Col span={6}>
          <div>
            <img
              className="image" src={data.vehicle && data.vehicle.color?.url} style={{ height: '200px', width: '220px', objectFit: 'cover' }} />
          </div>
        </Col>
        <Col span={18} style={{ padding: '1rem' }}>
          <h2>{data.vehicle && data.vehicle.vehicle.modelName}</h2>
          <Row>
            <Col span={12}>
              <table>
                <thead>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Customer Name</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.customer && data.customer.name}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Register Number</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data.vehicle && data.vehicle.registerNo}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Service Advisor</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>-</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Odometer Reading</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data && data.kms}</td>
                  </tr>
                </thead>
              </table>
            </Col>
            <Col span={12}>
              <table>
                <thead>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Mechanic</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>
                      {
                        data && data.mechanic ?
                          data.mechanic.profile && data.mechanic.profile.employeeName
                          : "-"
                      }
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Contact Number</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data && data.customerPhone}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Job Card</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>{data && data.jobNo}</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 'bolder' }}>Branch</td>
                    <td>:</td>
                    <td style={{ textAlign: 'left', paddingLeft: '.5rem' }}>Devanahali</td>
                  </tr>

                </thead>
              </table>
            </Col>
          </Row>
        </Col>
      </Row>
      <Collapse
        expandIconPosition={'right'}
      >
        <Panel header="More Details" style={{ fontSize: '18px', fontWeight: 'bold' }} key="1" >
          <Row>
            <Col span={8} style={{ borderRight: '0.1px solid rgba(0, 0, 0, 0.1)' }}>
              <h3>Pictures</h3>
              <div className="flexing">
                {data && data.vehicleImage && data.vehicleImage.frontView != null ?
                  <img
                    className="image"
                    src={data.vehicleImage && data.vehicleImage.frontView}
                    alt="Front View"
                  />
                  : <img
                    className="image"
                    src={empty}
                  />}
                {data && data.vehicleImage && data.vehicleImage.rearView != null ?
                  <img
                    className="image"
                    style={{ marginLeft: '1rem' }}
                    src={data.vehicleImage && data.vehicleImage.rearView}
                    alt="Front View"
                  />
                  : <img
                    style={{ marginLeft: '1rem' }}
                    className="image"
                    src={empty}
                  />}

              </div>

              <div className="flexing">
                {data && data.vehicleImage && data.vehicleImage.lhsView != null ?
                  <img
                    className="image"
                    src={data.vehicleImage && data.vehicleImage.lhsView}
                    alt="Front View"
                  />
                  : <img
                    className="image"
                    src={empty}
                  />}
                {data && data.vehicleImage && data.vehicleImage.rhsView != null ?
                  <img
                    className="image"
                    style={{ marginLeft: '1rem' }}
                    src={data.vehicleImage && data.vehicleImage.rhsView}
                    alt="Front View"
                  />
                  : <img
                    style={{ marginLeft: '1rem' }}
                    className="image"
                    src={empty}
                  />}
              </div>
              <div className="flexing">
                {data && data.vehicleImage && data.vehicleImage.topView != null ?
                  <img
                    className="image"
                    src={data.vehicleImage && data.vehicleImage.topView}
                    alt="Front View"
                  />
                  : <img
                    className="image"
                    src={empty}
                  />}
              </div>
            </Col>
            <Col style={{ display: 'flex', justifyContent: 'center', lineHeight: '25px', borderRight: '0.1px solid rgba(0, 0, 0, 0.1)', padding: '1rem', height: '350px' }} span={8}>
              <ul style={{ listStyleType: 'none', fontSize: '16px' }}>
                <li>{data.parts && data.parts.MirrorLH ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <img src={cross} style={{ height: '25px', width: '20px' }} />} Left Mirror</li>
                <li>{data.parts && data.parts.MirrorRH ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <img src={cross} style={{ height: '25px', width: '20px' }} />} Right Mirror</li>
                <li>{data.parts && data.parts.Toolkit ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <img src={cross} style={{ height: '25px', width: '20px' }} />} Tool Kit</li>
                <li>{data.parts && data.parts.FirstAdKit ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <img src={cross} style={{ height: '25px', width: '20px' }} />} First Aid Kit</li>
              </ul>
            </Col>
            <Col span={8} style={{ padding: '1rem' }}>
              <h3>Fuel Level :</h3>
              {data && data.fuelLevel != null ? <Progress percent={data && (data.fuelLevel / 100) * 100} /> : <Progress percent={0} status="exception" />}
            </Col>
          </Row>
        </Panel>
      </Collapse>
    </div >
  )
}
export default withRouter(DetailsCard); 