import React from "react";
import { Table, Typography } from "antd";

const { Text } = Typography;

const TaxTable = ({ dataSource }) => {
  console.log("dataSource", dataSource)
  const columns = [
    {
      title: <Text strong>Total</Text>,
      dataIndex: "total",
      key: "total",
      render: (record) => <span>&#8377; {record ? record : ""}</span>,
    },
    {
      title: <Text strong>1+5</Text>,
      dataIndex: "insurance1plus5",
      key: "insurance1plus5",
      render: (record) => <span>&#8377; {record}</span>,
    },
    {
      title: <Text strong>5+5</Text>,
      dataIndex: "insurance5plus5",
      key: "insurance5plus5",
      render: (record) => <span>&#8377; {record}</span>,
    },
    {
      title: <Text strong>1+5 ZD</Text>,
      dataIndex: "insurance1plus5ZD",
      key: "insurance1plus5ZD",
      render: (record) => <span>&#8377; {record}</span>,
    },
    {
      title: <Text strong>5+5 ZD</Text>,
      dataIndex: "insurance5plus5ZD",
      key: "insurance5plus5ZD",
      render: (record) => <span>&#8377; {record}</span>,
    },
  ];
  return (
    <Table
      tableLayout="fixed"
      size="small"
      rowKey={(record) => record.insurance1plus5}
      pagination={false}
      dataSource={dataSource}
      columns={columns.filter((column) => dataSource[0][column.key])}
    />
  );
};

export default TaxTable;
