import React from 'react';
import { Table, Divider, Popconfirm, Button, Typography, message } from 'antd';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
const { Text } = Typography;
const UploadFileTable = (props) => {
	const {
		pagination,
		dataSource,
		openModal,
		spinner,
		history,
		delete: deleteData,
		modify,
		deleteAccess,
	} = props;

	const { goBack } = history;

	const clickToCopy = (url) => {
		navigator.clipboard.writeText(url);
		message.success('Copied To ClipBoard');
	};

	const column = [
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (record) => <span>{record}</span>,
		},
		{
			title: 'Module',
			dataIndex: 'module',
			key: 'module',
			render: (record) => <span>{record}</span>,
		},
		{
			title: 'URL',
			dataIndex: 'url',
			key: 'url',
			render: (record) => <span>{record.substring(0, 25)}...</span>,
		},
		{
			title: 'Created On',
			dataIndex: 'createdAt',
			key: 'createdAt',
			render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
		},
		{
			title: 'Action',
			dataIndex: 'url',
			key: 'url',
			render: (record) => <Button onClick={() => clickToCopy(record)}>Click to Copy</Button>,
		},
	];

	return (
		<Table
			rowKey={(record) => record.id}
			pagination={pagination}
			columns={column}
			style={{ cursor: 'pointer' }}
			dataSource={dataSource}
			loading={spinner}
		/>
	);
};

export default withRouter(UploadFileTable);
