import React, { useState } from "react";
import { Table, Divider, Popconfirm, Typography } from "antd"
const { Text } = Typography
import { withRouter } from 'react-router-dom'
import moment from "moment";

const FollowUpTable = (props) => {
  const { pagination, dataSource, spinner, history } = props;

  const column = [
    {
      title: "Quotation Id",
      dataIndex: "quotationId",
      key: "quotationId",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      title: "Mobile No",
      dataIndex: "phone",
      key: "phone",
      render: (record) => {
        return <span>{record}</span>;
      },
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Next Followup Date",
      dataIndex: "scheduleDate",
      key: "scheduleDate",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    // {
    //   title: "Next Followup Time",
    //   dataIndex: "scheduleDateAndTime",
    //   key: "scheduleDateAndTime",
    //   render: (record) => <span>{moment(new Date(record)).format("HH:MM:SS")}</span>,
    // }
    // {
    //     title: "Action",
    //     dataIndex: "phone",
    //     key: "phone",
    //   render: (record) => (
    //     <div>
    //       {
    //             <span
    //               onClick={() => history.push(`/autoadmin/follow-ups/${record}`)}
    //               className="linkylink"
    //             >
    //               Follow-Up
    //         </span>
    //       }

    //     </div>
    //   ),
    // },
  ];

  return (
    <Table
      // rowKey={(record) => record.id}
      onRow={(record, index) => {
        return {
          onClick: () => {
            history.push(`/autoadmin/follow-ups/${record.phone}/fupdate/${record.scheduleDateAndTime}`)
          }
        }
      }
      }
      style={{ cursor: "pointer" }}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      loading={spinner}
    />
  );
}

export default withRouter(FollowUpTable)