import React, { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router-dom";

import SparesInventory from './SparesInventory';
import VehicleInventory from './VehicleInventory';



const Reports = (props) => {

    const { type } = props
    const [sparesInventory, setSparesInventory] = useState(false);
    const [vehicleInventory, setVehicleInventory] = useState(false);
    

    useEffect(() => {
      if (type === "spares") {
        setSparesInventory(true);
        setVehicleInventory(false);
      }
      else {
        setSparesInventory(false);
        setVehicleInventory(true);
      }
    }, type)

    return (
       <div>
          {sparesInventory && <SparesInventory />}
          {vehicleInventory && <VehicleInventory />}
       </div> 
    )
}

export default withRouter(Reports);