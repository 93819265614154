import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";
import SalesHome from "./SalesHome"
import SaleLead from "./SaleLead"
import { platformApi } from "../../../../api";

const SaleType = (props) => {
  const [leadName, setLeadName] = useState(null)

  const Template = () => {
    let Component;
    const {
      match: {
        params: { cusId },
      },
    } = props;
    setLeadName(props.location.state && props.location.state.name)
    if (!cusId) Component = <SalesHome />;
    else {
        Component = <SaleLead cusId={cusId} />;
    }
    return Component;
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "1rem 0" }} separator=">">
        <Breadcrumb.Item
          onClick={(e) => props.history.push("/autoadmin/sales")}
        >
          Sale Lead
        </Breadcrumb.Item>
        <Breadcrumb.Item>{leadName}</Breadcrumb.Item>
      </Breadcrumb>
      <Template />
    </div>
  );
};

export default withRouter(SaleType);