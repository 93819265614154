import React, { useEffect, useState } from 'react'
import {
    Form,
    Select,
    Typography,
    Row,
    Col,
    Button,
    Input,
    Table,
    Tabs,
    message,
    Divider
} from "antd";
import './index.less'
const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;
import { platformApi } from '../../../../../../../api'
const JobComplaint = ({
    data,
    totalComplaints,
    setTotalComplaints,
    status
}) => {
    const [dataSource, setDataSource] = useState([])
    const [complaintList, setComplaintList] = useState([])
    const [complaint, setComplaint] = useState("")
    useEffect(() => {
        if (data) {
            setDataSource(data.complaint)
        }
    }, [data && data.complaint])
    useEffect(() => {
        let tot = dataSource
        setTotalComplaints(tot)
    }, dataSource)
    // console.log("dataSource", dataSource)
    const columns = [
        {
            title: "S.No", 
            render: (d) => (
                <h3>{totalComplaints.indexOf(d) + 1}</h3>
            )
        },
        {
            title: "Complaints",
            render: (data) => (<div>
                <p>{data.complaint}</p>
            </div>)
        }
    ]

    const handleComplaint = (e) => {
        let v = e.target.value
        setComplaint(v)
    }

    const addComplaint = async () => {
        complaintList.push({ complaint: complaint })
        setComplaintList([...complaintList])
        let tot = [...dataSource, ...complaintList]
        setTotalComplaints(tot)
        setComplaint("")
        data.complaint = tot;
        platformApi
            .put(`/api/jobOrder/${data.id}`, data)
            .then((res) => {
                let { data } = res;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        console.log("Updated", response)
                        message.success("Job Order Updated successfully", 2);
                    } else {
                        message.error("Unable to update Job Order", 2);
                    }
                } else {
                    message.error("Unable to update Job Order", 2);
                }
            })
            .catch((err) => {
                message.error("Unable to update Job Order", 2);
                console.error("Error on Job Order : ", err);
            });
    }
    const deleteComplaintData = (complaint) => {
        let complaints = complaintList.filter(data => data.complaint != complaint)
        setComplaintList(complaints)
    }

    return (
        <>
            <div className="Complaints">
                <div style={{ display: 'inline' }}>
                    <h3>Job Details:</h3>
                </div>
                <div id="complaints">
                    <Table
                        pagination={false}
                        columns={columns}
                        dataSource={totalComplaints}
                    />
                </div>
                {/* {
                    status == 0 || status == 1 ?
                        <span>
                            <Divider />
                            <div>
                                <Row>
                                    <Col span={20}>
                                        <TextArea id="cp" onChange={(e) => handleComplaint(e)} value={complaint} placeholder="Enter Complaint" rows={3} />
                                    </Col>
                                    <Col span={4} style={{ display: 'flex', justifyContent: 'space-around', marginTop: '1rem' }}>
                                        <Button type="primary" onClick={() => { addComplaint() }}>Add</Button>
                                    </Col>
                                </Row>
                            </div>
                        </span>
                        : null
                } */}
            </div>
        </>
    )
}
export default JobComplaint;