import React, { useContext, useState, useEffect } from "react";
import minimize from "../../../../assets/minimize.svg"
import edit from "../../../../assets/edit.svg"
import { formatValue } from "../../../../utils"
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
  Modal,
  Form,
  Select,
  Input,
  Icon,
  InputNumber,
} from "antd";
import "./index.less";
import { platformApi } from "../../../../api";
import { getUUID } from '../../../../_helpers/utils';
import { range } from "lodash";
const { Title } = Typography
const { Item } = Form
const { Option } = Select

const PMCForm = ({
  dataSource,
  visible,
  setVisible,
  emitData,
  editableData,
  editable,
  setEditable,
  setEditableData,
  setDataSource,
  form
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  const partItemData = {
    "id": getUUID(),
    "partNumber": {},
    "quantity": '',
  }
  const jobCodeItemData = {
    "id": getUUID(),
    "code": {},
    "quantity": '',
  }
  const [partList, setPartList] = useState([partItemData])
  const [codeList, setCodeList] = useState([jobCodeItemData])
  const [quantity, setquantity] = useState('')
  const [selectedPart, setSelectedPart] = useState("");
  const [vehicleSuitable, setVehicleSuitable] = useState(null)
  const [partsConsumable, setPartsConsumable] = useState(null)
  const [searchPartsConsumable, setSearchPartsConsumable] = useState(null)
  const [jobCode, setJobCode] = useState(null)
  const [deleteData1, setDeleteData1] = useState([])
  const [deleteData2, setDeleteData2] = useState([])
  const [deleteData3, setDeleteData3] = useState([])
  const [vid, setVid] = useState([])
  const [pid, setPid] = useState([])
  const [jid, setJid] = useState([])
  const [remParts, setRemParts] = useState([])
  const [error, setError] = useState({});

  useEffect(() => {
    if (Object.keys(editableData).length > 0) {
      let { vehicleSuitable, partsConsumable, jobCodes } = editableData
      // console.log("partsConsumable", partsConsumable)
      const updatePartItemList = [...partsConsumable]
      const updateCodeItemList = [...jobCodes]
      //updatePartList
      if (updatePartItemList.length) {
        for (let i = 0; i < updatePartItemList.length; i++) {
          if (!searchPartsConsumable.some(tempItem => updatePartItemList[i].partNumber.id === tempItem.id)) {
            platformApi.get("/api/partsMaster/" + updatePartItemList[i].partNumber.id).then((res) => {
              let { data } = res;
              if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                  setSearchPartsConsumable([...searchPartsConsumable, response.data])
                }
              }
            })
          }
          updatePartItemList[i].partNumber = { ...updatePartItemList[i].partNumber }
        }
        setPartList([...updatePartItemList, partItemData])
      }

      //updateCodeList
      if (updateCodeItemList.length) {
        for (let j = 0; j < updateCodeItemList.length; j++) {
          updateCodeItemList[j].code = { ...updateCodeItemList[j].jobCode }

        }
        setCodeList([...updateCodeItemList, jobCodeItemData])
      } 

      for (let item of editableData.partsConsumable) {
        pid.push(item.id)
      }
      for (let item of jobCodes) {
        jid.push(item.id)
      }
      setPid(pid)
      setJid(jid)
      for (let item of vehicleSuitable) {
        vid.push(item.id)
      }
      setVid(vid)

      setFieldsValue({
        "serviceType": editableData.serviceType,
        "serviceNo": editableData.serviceNo,
        "serviceKms": editableData.serviceKm,
        "vehicleSuitable": vid,
        // "partsConsumable": pid,
        // "jobCodes": jid
      })
    }
  }, [editableData])

  const getJobCodes = () => {
    platformApi.get('/api/jobCode/').then((res) => {
      const { data } = res;
      if (data.code === 200) {
        setJobCode(data.response.data);
      }
    })
      .catch((err) => {

        console.error("JobCode Fetch Error: ", err);
        message.error("Couldn't Fetch JobCodes");
      });
  }

  const getParts = () => {
    let data = { module: 'partsMasters', size: 100, column: 'partNumber', fields: ['partNumber', 'partName'] }
    platformApi.post('/api/options/get', data).then((res) => {
      const { data } = res;
      setSearchPartsConsumable(data.response);
    })
      .catch((err) => {
        console.error("Part Fetch Error: ", err);
        message.error("Couldn't Fetch Parts");
      });
  }

  useEffect(() => {
    platformApi.get('/api/jobCode/')
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          setJobCode(data.response.data);
        }
      })
      .catch((err) => {
        console.error("JobCode Fetch Error: ", err);
        message.error("Couldn't Fetch JobCodes");
      });

    if (Object.keys(editableData).length == 0) {
      let data = {
        module: 'partsMasters',
        size: 100,
        column: 'partNumber',
        fields: ['partNumber', 'partName']
      }
      platformApi.post('/api/options/get', data)
        .then((res) => {
          const { data } = res;
          setSearchPartsConsumable(data.response);
        })
        .catch((err) => {
          console.error("Part Fetch Error: ", err);
          message.error("Couldn't Fetch Parts");
        });
    }
    else {
      let data = {
        module: 'partsMasters',
        size: 10,
        column: 'partNumber',
        fields: ['partNumber', 'partName']
      }
      platformApi.post('/api/options/get', data)
        .then((res) => {
          const { data } = res;
          let temp = data.response
          let ans = []
          for (let item of temp) {
            let fl = false
            for (let item2 of editableData.partsConsumable) {
              if (item.id == item2.partNumber.id) {
                fl = true
                break
              }
            }
            if (fl == false) {
              ans.push(item)
            }
          }
          // console.log("ans", ans)
          // setSearchPartsConsumable([...editableData.partsConsumable, ...ans]);
          // setSearchPartsConsumable(temp)
        })
        .catch((err) => {
          console.error("Part Fetch Error: ", err);
          message.error("Couldn't Fetch Parts");
        });
      // setSearchPartsConsumable(editableData.partsConsumable)
    }

    platformApi.get('/api/vehicleMaster/')
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          // console.log(data.response.data)
          setVehicleSuitable(data.response.data);
        }
      })
      .catch((err) => {
        console.error("Vehicle Fetch Error: ", err);
        message.error("Couldn't Fetch Vehicles");
      });

  }, [editableData])

  const clearFields = () => {
    setVisible(false)
    setEditableData([])
    setEditable(false)
    setDeleteData1([])
    setDeleteData2([])
    setDeleteData3([])
    setSearchPartsConsumable([])
    setCodeList([jobCodeItemData])
    setPartList([partItemData])
    setVid([])
    setError({})
    setPid([])
    setJid([])
    form.resetFields()
  }
  const handleAdd = (type) => {
    if (type == "Part") {
      let newArr = [...partList, partItemData];
      setPartList(newArr);
    }
    else if (type == "Code") {
      let newArr = [...codeList, jobCodeItemData];
      setCodeList(newArr);
    }
  }
  useEffect(() => {
    if (partList && partList?.length > 0 && (partList[partList?.length - 1].partNumber.partNumber || partList[partList?.length - 1].quantity?.length > 0)) {
      handleAdd("Part");
    }
    if (codeList && codeList?.length > 0 && (codeList[codeList?.length - 1]).code.id || codeList[codeList?.length - 1].quantity?.length > 0) {
      handleAdd("Code");
    }
  }, [partList, codeList])

  const handleSubmit = () => {
    if (!editable) {
      setEditable(true)
      return
    }
    validateFields(err => {
      let newPartItemList = [...partList];
      let newCodeItemList = [...codeList];
      if (newPartItemList.length >= 1 && newCodeItemList.length >= 1) {
        if (!err && Object.keys(error).length == 0) {
          if (newPartItemList.length > 1) { 
            newPartItemList = newPartItemList.filter(item => {
              return item.partNumber && item.quantity > 0
            })
          }
          setPartList(newPartItemList)
          if (newCodeItemList.length > 1) {
            newCodeItemList = newCodeItemList.filter(item => {
              return item.code && item.quantity > 0
            })
          }
          setCodeList(newCodeItemList)
          // console.log("newPartItemList", newPartItemList)
          // console.log("newCodeItemList", newCodeItemList)
          let data;
          if (Object.keys(editableData).length > 0) {
            data = {
              id: editableData.id,
              serviceType: getFieldValue("serviceType"),
              serviceNo: getFieldValue("serviceNo"),
              serviceKms: getFieldValue("serviceKms"),
              vehicleSuitables: getFieldValue("vehicleSuitable"),
              partsConsumables: newPartItemList,
              jobCodes: newCodeItemList,
              deleteData1: deleteData1,
              // partsConsumables: getFieldValue("partsConsumable"),
              // jobCodes: getFieldValue("jobCodes"),
              deleteData2: deleteData2,
              deleteData3: deleteData3,
            }
            console.log("Editdata", data)
          }
          else {
            data = {
              serviceType: getFieldValue("serviceType"),
              serviceNo: getFieldValue("serviceNo"),
              serviceKms: getFieldValue("serviceKms"),
              vehicleSuitables: getFieldValue("vehicleSuitable"),
              partsConsumables: newPartItemList,
              jobCodes: newCodeItemList,
              deleteData1: deleteData1,
              // partsConsumables: getFieldValue("partsConsumable"),
              // jobCodes: getFieldValue("jobCodes"),
              deleteData2: deleteData2,
              deleteData3: deleteData3,
            }
            console.log("Adddata", data)
          }
          emitData(data)
          form.resetFields()
          setDeleteData1([])
          setDeleteData2([])
          setDeleteData3([])
          setCodeList([jobCodeItemData])
          setPartList([partItemData])
          setError({})
          setVid([])
          setPid([])
          setJid([])
          setEditable(false)
          setEditableData([])
          setVisible(false)
        }
      } else {
        message.error("select atleast one PartsConsumable/JobCodeConsumable list ")
      }

    })
  }

  const deleteVehicle = (e) => {
    if (vid.indexOf(e) >= 0) {
      deleteData1.push(e)
    }
    setDeleteData1(deleteData1)
  }
  const deleteParts = (e) => {
    if (pid.indexOf(e) >= 0) {
      deleteData2.push(e)
    }
    setDeleteData2(deleteData2)
  }
  const deleteJobCode = (e) => {
    if (jid.indexOf(e) >= 0) {
      deleteData3.push(e)
    }
    setDeleteData3(deleteData3)
  }

  const searchParts = (search) => {
    // let data = { module: 'partsMasters', size: 100, column: 'number', fields: ['number', 'name'], searchString: e }
    platformApi.post('/api/options/get', {
      module: search.module,
      column: search.column,
      searchString: search.searchString,
      fields: search.fields,
      size: 100,
      searchColumns: search.searchColumns,
    }).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        search.setValue(data.response);
      }
    })
      .catch((err) => {
        console.error("Part Fetch Error: ", err);
        message.error("Couldn't Fetch Parts");
      });
  }

  const handleDelete = (selectedId, type) => {
    if (type == "Code") {
      const updatedDataSource = codeList.filter(item => item.id !== selectedId);
      setCodeList(updatedDataSource)
      deleteJobCode(selectedId)
    }
    else if (type == "Part") {
      const updatedDataSource = partList.filter(item => item.id !== selectedId);
      setPartList(updatedDataSource)
      deleteParts(selectedId)
    }
  }

  const handleQuantity = (event, record, type) => {
    if (type === "Part") {
      let newItemList = [...partList];
      for (let item of newItemList) {
        if (item.id === record.id) {
          item.quantity = event.target.value
          break;
        }
      }
      // console.log("Added PartList", newItemList)
      setPartList(newItemList);
    }
    else if (type = "Code") {
      let newItemList = [...codeList];
      for (let item of newItemList) {
        if (item.id === record.id) {
          item.quantity = event.target.value
          break;
        }
      }
      // console.log("Added CodeList", newItemList)
      setCodeList(newItemList);
    }
  }

  const handleDetail = (event, data, type) => {
    console.log("event", event, data)
    if (type == "Part") {
      const newItemList = [...partList];
      setSelectedPart(event)
      if (event !== undefined) {
        searchPartsConsumable.map(part => {
          if (part.id === event) {
            for (let item of newItemList) {
              if (item.id === data.id) {
                item.partNumber = part
                item.quantity = 1
              }
            }
          }
        })
      }
      setPartList(newItemList)
      getParts();
    }
    else if (type == "Code") {
      const newItemList = [...codeList];
      setSelectedPart(event)
      if (event !== undefined) {
        jobCode.map(part => {
          if (part.id === event) {
            for (let item of newItemList) {
              if (item.id === data.id) {
                item.code = part
                item.quantity = 1
              }
            }
          }
        })
      }
      setCodeList(newItemList)
      getJobCodes();
    }
  }
  //PartConsumable
  const renderPartColumns = () => {
    const columns = [
      {
        title: "PartsConsumable",
        dataIndex: "partNumber",
        key: "partNumber",
        width: 220,
        render: (record, data) => (
          <Form.Item>
            <Select
              disabled={!editable}
              showSearch
              showAction={["click", "focus"]}
              filterOption={null}
              // onDeselect={(event) => editable && deleteParts(event)}
              onSearch={(e) => {
                const search = {
                  module: "partsMasters",
                  column: "partNumber",
                  fields: ["partNumber, partName"],
                  searchString: e,
                  searchColumns: ["partNumber"],
                  setValue: setSearchPartsConsumable,
                };
                searchParts(search)
              }}
              onChange={(event) => {
                handleDetail(event, data, "Part")
              }}
              placeholder="Select Parts Consumables"
              style={{ width: '100%' }}
              value={(record ? record.id : "Part Number")}
            >
              {searchPartsConsumable && searchPartsConsumable.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.partNumber}-{item.partName}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="quantity"
              disabled={!editable}
              onChange={(event) => {
                handleQuantity(event, record, "Part")
              }}
              placeholder="quantity"
              value={record && record.quantity}
            />
          </Form.Item>
        )
      },
      {
        title: 'Action',
        dataIndex: 'remove',
        key: 'x',
        width: 100,
        render: (data, record) => (
          <Form.Item>
            {
              partList.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => { handleDelete(record.id, "Part") }}>
                  <a style={{ color: '#6c7b8a' }}>Delete</a>
                </Popconfirm>
              ) : null
            }
          </Form.Item>
        ),
      },
    ];
    return columns;
  }


  //Job Code Consumable
  const renderCodeColumns = () => {
    const columns = [
      {
        title: "Job Codes",
        dataIndex: "code",
        key: "code",
        width: 220,
        render: (record, data) => (
          <Form.Item>
            <Select
              disabled={!editable}
              showSearch
              showAction={["click", "focus"]}
              filterOption={null}
              // onDeselect={(event) => editable && deleteJobCode(event)}
              onSearch={(e) => {
                const search = {
                  module: "jobCodes",
                  column: "code",
                  fields: ["code"],
                  searchString: e,
                  searchColumns: ["code"],
                  setValue: setJobCode,
                };
                searchParts(search)
              }}
              onChange={(event) => {
                handleDetail(event, data, "Code")
              }}
              placeholder="Select Job Codes"
              value={(record ? record.id : "Code")}
              style={{ width: '100%' }}
            >
              {jobCode && jobCode.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.code}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="quantity"
              disabled={!editable}
              onChange={(event) => {
                handleQuantity(event, record, "Code")
              }}
              placeholder="quantity"
              value={record && record.quantity}
            />
          </Form.Item>
        )
      },
      {
        title: 'Action',
        dataIndex: 'remove',
        key: 'x',
        width: 100,
        render: (data, record) => (
          <Form.Item>
            {
              codeList.length >= 1 ? (
                <Popconfirm title="Sure to delete?" onConfirm={() => { handleDelete(record.id, "Code") }}>
                  <a style={{ color: '#6c7b8a' }}>Delete</a>
                </Popconfirm>
              ) : null
            }
          </Form.Item>
        ),
      },
    ];
    return columns;
  }


  return (
    <Modal
      wrapClassName="pmc-form"
      visible={visible}
      title="Add PMC"
      okButtonProps={{}}
      onCancel={() => { clearFields() }}
      onOk={handleSubmit}
      okText={editable ? "OK" : "Modify"}
    >
      <Form>
        <Row>
          <Col span={11}>
            <Item label="Service Type" >
              {getFieldDecorator("serviceType", {
                rules: [{ required: true, message: "Select Service Type" }],
              })(
                <Select showAction={["click", "focus"]} placeholder="Select Service Type" disabled={!editable} allowClear>
                  <Option value="FREE">FREE</Option>
                  <Option value="PAID">PAID</Option>
                </Select>
              )}
            </Item>
          </Col>
          <Col span={2} />
          <Col span={11} >
            <Item
              label="Service No"
              validateStatus={error.serviceNo && error.serviceNo.type}
              help={error.serviceNo && error.serviceNo.message}
              colon={false}
              required
            >
              {getFieldDecorator("serviceNo", {
                rules: [{ required: true, message: "Enter Service Number" }]
              })(
                <Input
                  style={{ width: '100%' }}
                  disabled={!editable}
                  placeholder="Enter Service Number"
                  pattern="^[0-9]*$"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      serviceNo: e.target.value
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        serviceNo: {
                          type: "error",
                          message: "Enter Valid Service Number"
                        }
                      })
                    } else {
                      delete error.serviceNo
                      setError(error)
                    }
                  }}
                />
              )}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={11} >
            <Item
              label="Service KMs"
              validateStatus={error.serviceKms && error.serviceKms.type}
              help={error.serviceKms && error.serviceKms.message}
            >
              {getFieldDecorator("serviceKms", {
                rules: [{ required: true, message: "Enter Service KMs" }]
              })(
                <Input
                  style={{ width: '100%' }}
                  disabled={!editable}
                  placeholder="Enter Service KMs"
                  pattern="^[0-9]*$"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      serviceKms: e.target.value
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        serviceKms: {
                          type: "error",
                          message: "Enter Valid Service Kms"
                        }
                      })
                    } else {
                      delete error.serviceKms
                      setError(error)
                    }
                  }}
                />
              )}
            </Item>
          </Col>
          <Col span={2} />
          <Col span={11}>
            <Item label="Vehicle Suitable" >
              {getFieldDecorator("vehicleSuitable", {
                rules: [{ required: true, message: "Select Vehicle Suitable" }],
              })(
                <Select
                  disabled={!editable}
                  mode="multiple"
                  showSearch
                  showAction={["click", "focus"]}
                  filterOption={null}
                  onDeselect={(event) => editable && deleteVehicle(event)}
                  onSearch={(e) => {
                    const search = {
                      module: "vehicleMasters",
                      column: "modelCode",
                      fields: ["modelCode, modelName"],
                      searchString: e,
                      searchColumns: ["modelCode"],
                      setValue: setVehicleSuitable,
                    };
                    searchParts(search)
                  }
                  }
                  placeholder="Select Vehicle Suitable"
                  style={{ width: '100%' }}
                >
                  {vehicleSuitable && vehicleSuitable.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.modelCode}-{item.modelName}
                    </Option>
                  ))}
                </Select>
              )}

            </Item>
          </Col>
        </Row>
        <Row>
          <Col span={11}>
            <Table
              className="parts-invoice-table"
              rowKey={(record) => { record.id }}
              dataSource={partList}
              columns={renderPartColumns()}
              tableLayout="fixed"
              pagination={false}
              style={{ marginTop: '1rem' }}
            />
          </Col>
          <Col span={2}></Col>
          <Col span={11}>
            <Table
              className="parts-invoice-table"
              tableLayout="fixed"
              rowKey={(record) => { record.id }}
              dataSource={codeList}
              columns={renderCodeColumns()}
              pagination={false}
              style={{ marginTop: '1rem' }}
            />
          </Col>
        </Row>
        {/* <Row>
          <Col span={11} >
            <Item
              label="Parts Consumable"
            >
              {getFieldDecorator("partsConsumable", {
                rules: [{ required: true, message: "Select Parts Consumable" }]
              })(
                <Select
                  disabled={!editable}
                  mode="multiple"
                  showSearch
                  showAction={["click", "focus"]}
                  filterOption={null}
                  onDeselect={(event) => editable && deleteParts(event)}
                  onSearch={(e) => {
                    const search = {
                      module: "partsMasters",
                      column: "number",
                      fields: ["number, name"],
                      searchString: e,
                      searchColumns: ["number"],
                      setValue: setSearchPartsConsumable,
                    };
                    searchParts(search)
                  }}
                  placeholder="Select Parts Consumables"
                  style={{ width: '100%' }}
                >
                  {searchPartsConsumable && searchPartsConsumable.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.number}-{item.name}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={2} />
          <Col span={11} >
            <Item
              label="Job Codes"
            >
              {getFieldDecorator("jobCodes", {
                rules: [{ required: true, message: "Select Job Codes" }]
              })(
                <Select
                  disabled={!editable}
                  showSearch
                  showAction={["click", "focus"]}
                  filterOption={null}
                  onDeselect={(event) => editable && deleteJobCode(event)}
                  onSearch={(e) => {
                    const search = {
                      module: "jobCodes",
                      column: "code",
                      fields: ["code"],
                      searchString: e,
                      searchColumns: ["code"],
                      setValue: setJobCode,
                    };
                    searchParts(search)
                  }
                  }
                  mode="multiple"
                  placeholder="Select Job Codes"
                  style={{ width: '100%' }}
                >
                  {jobCode && jobCode.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.code}
                    </Option>
                  ))}
                </Select>
              )}
            </Item>
          </Col>
        </Row> */}
      </Form>
    </Modal>
  )
}

const WrappedPMCForm = Form.create({ name: "PMCForm" })(PMCForm);
export default WrappedPMCForm