import {
  Typography,
  Input,
  Button,
  Select,
  Icon,
  Dropdown,
  message,
  Menu,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import WrappedVehicleForm from "./VehicleForm";
import VehicleTable from "./VehicleTable";
import { platformApi } from "../../../api";
import { ContextAPI } from "../../../ContextAPI";

const { Title } = Typography;
const { Search } = Input;
const { Option } = Select;
const { Item } = Menu;

function Vehicles() {
  const [dataSource, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [open, setOpen] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [editable, setEditable] = useState(false);
  const [limit, setLimit] = useState(10);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const [mdfy, setMdfy] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const { loginCredintials } = useContext(ContextAPI);
  const [uploading, setUploading] = useState(false);

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item
        onClick={() => {
          setLimit(menu), setPage(1);
        }}
        key={menu}
      >
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const setPrimaryData = (string, val) => {
    setSpinning(true);
    platformApi
      .post("/api/vehicle/get", {
        page: val ? val : page,
        size: limit,
        searchString: string,
      })
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(response.data.SoldVehicle);
            setCount(response.data.count);
            console.log("res", response.data);
          } else {
            message.error("Unable to fetch vehicles", 2);
          }
        } else {
          message.error("Unable to fetch vehicles", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Vehicles : ", error);
        message.error("Unable to fetch vehicles", 2);
      });
  };

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "vehicles") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    // setSpinning(true);
    setPrimaryData(null, null);
  }, []);

  useEffect(() => {
    setPrimaryData(search);
  }, [search, page, limit]);

  const addData = (formData, vehicle) => {
    setSpinning(true);
    if (vehicle.id) {
      console.log("Modify" + vehicle.insuranceData);

      platformApi
        .put("/api/vehicle/" + vehicle.id, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          setSpinning(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === vehicle.id) {
                  dataSource[index] = response.data;
                }
              });
              setData([]);
              setData(dataSource);
              message.success("Vehicle saved successfully", 2);
              // setPrimaryData();
            } else {
              message.error("Unable to save vehicle", 2);
            }
          } else {
            message.error("Unable to save vehicle", 2);
          }
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response.data);
            console.log(error.response.data.code);
            console.log(error.response.data.code.msg);
            message.error("error check", 2);
            return;
          }
          setSpinning(false);
          console.error("Error on Vehicles : ", error);
          message.error("Unable to save vehicle", 2);
        });
    } else {
      console.log("Create");
      platformApi
        .post("/api/vehicle", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((result) => {
          setSpinning(false);
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Vehicle added successfully", 2);
              // setPrimaryData();
            } else {
              message.error("Unable to add vehicle", 2);
            }
          } else {
            message.error("Unable to add vehicle", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error("Error on Vehicles : ", error);
          message.error("Unable to add vehicle", 2);
        });
    }
  };

  const deleteData = (id) => {
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1);
    // }
    setSpinning(true);
    platformApi
      .delete("/api/vehicle/" + id)
      .then((result) => {
        setSpinning(false);
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            console.log("Temp", temp);
            if (temp) {
              setPage(1);
              setPrimaryData(null, 1);
            } else {
              setPrimaryData();
            }
            message.success("Vehicle deleted successfully", 2);
          } else {
            message.error("Unable to delete vehicle", 2);
          }
        } else {
          message.error("Unable to delete vehicle", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Vehicles : ", error);
        message.error("Unable to delete vehicle", 2);
      });
  };

  return (
    <div>
      <div className="page-head">
        <Title style={{ width: "60%" }} level={4}>
          Vehicles[{count}]
          <span style={{ margin: ".5rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit} &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          onSearch={(event) => {
            setPage(1);
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null);
              setPage(1);
            }
          }}
          placeholder="Search Vehicle"
        />
        <Button
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          onClick={() => {
            setOpen(true);
            setEditable(true);
            setMdfy(true);
          }}
          disabled={!createAccess}
        >
          Add new Vehicle
        </Button>
      </div>

      <VehicleTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          current: page,
          total: count,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setOpen(true);
          setEditable(editable);
        }}
        dataSource={dataSource}
        spinner={{ spinning }}
        delete={deleteData}
        deleteAccess={deleteAccess}
        modify={modifyAccess}
      />

      <WrappedVehicleForm
        data={dataSource[dataSource.findIndex((obj) => obj.id === selected)]}
        emitData={addData}
        open={open}
        editable={editable}
        setUploading={setUploading}
        uploading={uploading}
        close={() => {
          setOpen(false);
          setSelected(false);
          setUploading(false);
        }}
        modify={modifyAccess}
        edit={mdfy}
        setEdit={setMdfy}
        vData={false}
      />
    </div>
  );
}

export default Vehicles;
