import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, Radio, DatePicker, message } from "antd";
import { platformApi } from "../../../../api";
import moment from "moment";
const { Item } = Form;
const { Option } = Select;
function AdvancedFilters({
  form,
  filteropen,
  setId,
  setPageData,
  setSearch,
  setFilterData,
  setPage
}) {
  const {
    getFieldDecorator,
    getFieldValue,
    resetFields,
  } = form;
  const [model, setModels] = useState([]);
  const [dateFilter, setDateFilter] = useState(false);
  const [type, setType] = useState(null);
  const [fromDate, setFromDate] = useState(null)


  //price range


  const searchValue = () => {
    setId([]);
    setPageData();
    resetFields();
    setSearch([]);
    setFilterData({})
  };

  const handleSubmit = () => {
    if (!dateFilter || getFieldValue("from") && getFieldValue("to")) {
      const data = {};
      if (dateFilter) {
        data.fromDate = moment(getFieldValue("from").startOf('day').toISOString());
        data.toDate = moment(getFieldValue("to").endOf('day').toISOString());
        data.status = null
      }
      else {
        data.status = type;
        if (getFieldValue("from") && getFieldValue("to")) {
          data.fromDate = moment(getFieldValue("from").startOf('day').toISOString());
          data.toDate = moment(getFieldValue("to").endOf('day').toISOString());
        }
      }
      setFilterData(data)
      // setSearch(data);
      // setPageData(data);
    }
    else {
      message.error('Enter all fields')
    }
  }

  const radioOnChange = (e) => {
    setPage(1)
    form.setFieldsValue({
      from: undefined,
      to: undefined
    })
    setType(e.target.value);
    e.target.value === "date" ? setDateFilter(true) : setDateFilter(false)
  }

  return ( 
    <div>
      {filteropen && (
        <div className="AdvancedFilters" >
          <Form>
            <Row>
              <h3>Advanced Filters</h3>
            </Row>
            <Row gutter={20} style={{ marginTop: '0vh' }}>
              <Col span={12}>
                <Radio.Group size="middle" onChange={(e) => { radioOnChange(e) }} buttonStyle="solid"  >
                  <Radio.Button value="SOLD">Sold</Radio.Button>
                  <Radio.Button value="ACTIVE">Enquiry</Radio.Button>
                  <Radio.Button value="NOTINTERESTED">Not Interested</Radio.Button>
                  <Radio.Button value="date">Date Filter</Radio.Button>
                </Radio.Group>
              </Col>
              <Col span={6}>

              </Col>
            </Row>
            <Row gutter={25} style={{ marginTop: '0vh' }}>
              <Col span={8}>
                <Item label="Date Range From" colon={true}>
                  {getFieldDecorator("from", {
                    rules: [
                      {
                        required: dateFilter,
                        message: "Select Date From!",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{ width: "100%" }}
                      // disabled={!dateFilter}
                      format="DD/MM/YYYY"
                      onChange={(m) => setFromDate(m)}
                      // disabledDate={(moment) =>
                      //   Date.now() - 1000 * 60 * 60 * 24 > moment._d
                      // }

                      showToday={false}
                    />
                  )}
                </Item>
              </Col>
              <Col span={8}>
                <Item label="Date Range To" colon={true}>
                  {getFieldDecorator("to", {
                    rules: [
                      {
                        required: dateFilter,
                        message: "Select Date To!",
                      },
                    ],
                  })(
                    <DatePicker
                      style={{ width: "100%" }}
                      disabled={!fromDate}
                      format="DD/MM/YYYY"
                      disabledDate={(moment) =>
                        fromDate._d > moment._d
                      }
                      showToday={true}
                    />
                  )}
                </Item>
              </Col>
              <Col span={2}></Col>
              <Col span={3} style={{ marginTop: '6.4vh' }}>
                <Button type="primary" onClick={handleSubmit}>
                  Search
                    </Button>
              </Col>
              <Col span={3} style={{ marginTop: '6.4vh' }}>
                <Button
                  type="ghost"
                  onClick={searchValue}
                >
                  Clear
                  </Button>
              </Col>
            </Row>

          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
