import { Button, Form, Modal } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import React from 'react'

const WhatsappMessageModal = (props) => {
    return (
        <>
            <Modal
                title="Message Content"
                visible={props.open}
                onCancel={() => props.close()}
                footer={
                    [<Button onClick={props.close}>
                        Cancel
                </Button>]
                }
            >
                <Form>
                    <TextArea disabled={true} autoSize={{ minRows: 3 }} value={props.message} />
                </Form>
            </Modal>
        </>
    )
}

export default WhatsappMessageModal
