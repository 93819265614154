import React from "react";
import { Table, Divider, Popconfirm, Typography, Form, Popover, Icon } from "antd";
import { InfoCircleOutlined } from '@ant-design/icons';
import imagePlaceHolder from "../../../../../assets/vehicle-placeholder.jpg";

const { Text } = Typography;

function SparesTable(props) {
  const { pagination, dataSource, openModal, spinner } = props;
  const column = [
    {
      title: "Model Name",
      dataIndex: "vehicle",
      key: "modelName",
      width: 250,
      render: (record) => <span>{`${record.modelCode} - ${record.modelName}`}</span>,
    },
    {
      title: "Category",
      dataIndex: "vehicle",
      key: "category",
      width: 150,
      render: (record, data) => <span>{record.category}</span>,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      width: 200,
      render: (record, data) =>
        <span>
          {record.color} &nbsp;
          <span>
            {
              <Popover placement="bottom" content={
                <div>
                  <img
                    src={record ? record?.url : imagePlaceHolder}
                    style={{ width: "250px", height: '150px' }}
                  />
                </div>
              }>
                <InfoCircleOutlined />
              </Popover>
            }
          </span>
        </span>
    },
    {
      title: "Quantity",
      dataIndex: "count",
      key: "count",
      width: 100,
      render: (count, masterData) => {
        return <span>{count}</span>
      },
    },
    {
      title: "Action",
      width: 100,
      render: (data) => (
        <div>
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(data.id, true);
            }}
            className="linkylink"
          >
            View
          </span>
          {/* <Divider type="vertical" />
          <Popconfirm
            title="Do you want to delete this Group?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              deleteData(data.id);
              event.stopPropagation();
            }}
          >
            <span
              onClick={(event) => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
          <Divider type="vertical" />
          <span
            onClick={(event) => {
              event.stopPropagation();
              refreshData(data.id)
            }}
            className="linkylink"
          >
            Refresh
          </span> */}
        </div>
      ),
    },
  ];

  React.useEffect(() => {

    return () => {

    }
  }, []);

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      loading={spinner}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal({
          vehicle: props.vehicle.id,
          color: props.color.id,
        }, false),
      })}
    />
  );
}


export default SparesTable;