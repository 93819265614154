import React from "react";
import { Table, Divider, Popconfirm, Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
import moment from 'moment'
const { Text } = Typography;
const VehiclePriceTable = ({
  pagination,
  dataSource,
  openModal,
  spinner,
  history,
  delete: deleteData,
  modify,
  deleteAccess,
}) => {
  const { goBack } = history;

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },

    {
      title: "Model",
      dataIndex: "vehicleModel",
      key: "vehicleModel",
      render: (record) =>
        record && (
          <span>
            {record.modelCode} - {record.modelName}
          </span>
        ),
    },
    {
      title: "Validity From",
      dataIndex: "priceValidFrom",
      key: "priceValidFrom",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Valid To",
      dataIndex: "priceValidTill",
      key: "priceValidTill",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Warranty",
      dataIndex: "warrantyPrice",
      key: "warranty",
      render: (record) => record && <span>&#8377; {record}</span>,
    },
    {
      title: "Ex-Showroom Price",
      dataIndex: "showroomPrice",
      key: "showroomPrice",
      render: (record) => record && <span>&#8377; {record}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {modify ? (
            <span
              className="linkylink"
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, true);
              }}
            >
              Modify
            </span>
          ) : (
            <span></span>
          )}

          {deleteAccess ? (
            <Popconfirm
              title="Are you sure delete this task?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Divider type="vertical" />
                Delete
              </span>
            </Popconfirm>
          ) : (
            <span></span>
          )}
          {!modify && !deleteAccess ? (
            <Text strong disabled>
              No Actions Given
            </Text>
          ) : (
            <span></span>
          )}

          {/* <Divider type="vertical" />
          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      style={{ cursor: "pointer" }}
      onRow={(props) => ({
        onClick: () => openModal(props.id, false),
      })}
      loading={spinner}
    />
  );
};

export default withRouter(VehiclePriceTable);
