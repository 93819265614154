import React, { useContext, useEffect, useState } from "react";
import { Table, Divider, Popconfirm, Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../../ContextAPI";
import moment from "moment";
const { Text } = Typography;

const ManufacturerTable = ({
  pagination,
  dataSource,
  spinner,
  openModal,
  delete: deleteData,
  history,
}) => {
  const { goBack } = history;
  const { loginCredintials } = useContext(ContextAPI);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);

  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "manufacturer") {
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
  }, []);

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {modifyAccess ? (
            <span
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, true);
              }}
              className="linkylink"
            >
              Modify
            </span>
          ) : (
            <span></span>
          )}

          {deleteAccess ? (
            <Popconfirm
              title="Do you want to delete this Manufacturer?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                onClick={(event) => event.stopPropagation()}
                className="linkylink"
              >
                <Divider type="vertical" />
                Delete
              </span>
            </Popconfirm>
          ) : (
            <span></span>
          )}
          {!modifyAccess && !deleteAccess ? (
            <Text strong disabled>
              No Actions Given
            </Text>
          ) : (
            <span></span>
          )}

          {/* <Divider type="vertical" />
          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props.id, false),
      })}
      loading={spinner}
    />
  );
};

export default withRouter(ManufacturerTable);
