import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Row,
  Col,
  Table,
  Empty,
  Typography,
  Icon,
  Popconfirm,
  Divider,
  Button,
  message,
} from "antd";
import _ from "lodash";
import { platformApi } from "../../../../../api";
import { formatValue } from "../../../../../utils";
const { Text } = Typography;

function BankDetails({
  form,
  validityRef,
  data,
  clearRef,
  editable,
  values,
  setData,
  error,
  setError,
  setBankDelData,
  delbankData
}) {
  const { getFieldDecorator } = form;
  const [dataSource, setDataSource] = useState([]);
  const [editBank, setEditBank] = useState(null);
  // const [error, setError] = useState({});

  useEffect(() => {
    if (data.bank) {
      setDataSource(data.bank);
    }
    clearRef.current = () => {
      form.resetFields();
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  }, []);

  const validatePage = () => {
    return true;
  };

  const addData = () => {
    form.validateFields((validationError) => {
      if (validationError === null && !error.IFSC) {
        let dataObj = form.getFieldsValue();
        if (values) {
          dataObj.id = "";
        }
        let dataBank = dataSource;
        if (editBank) {
          let index = 0;
          dataBank.map((e, i) => {
            if (e.id === editBank) {
              index = i;
            }
          });
          dataObj.id = editBank;
          dataBank[index] = dataObj;
          setEditBank(null);
        } else {
          dataBank.push(dataObj);
        }

        setDataSource([]);
        setDataSource(dataBank);
        let dataNew = data;
        dataNew.bank = dataBank;
        setData([]);
        setData(dataNew);
        form.setFieldsValue({
          name: undefined,
          accountName: undefined,
          accountNumber: undefined,
          ifsc: undefined,
        });
      }
    });
  };

  const deleteData = (dataDelete) => {
    if (!dataDelete.id) {
      setDataSource([]);
      const dataBank = dataSource.filter(
        (d) => d.accountNumber !== dataDelete.accountNumber
      );
      setDataSource(dataBank);
      let dataNew = data;
      dataNew.bank = dataBank;
      setData([]);
      setData(dataNew);
    } else {
      let dataBank = dataSource.filter((data) => data.id !== dataDelete.id);
      setDataSource(dataBank);
      setBankDelData([dataDelete])
      let dataNew = data;
      dataNew.bank = dataBank;
      setData([]);
      setData(dataNew);
      // platformApi.delete(`/api/supplier/bank/${dataDelete.id}`).then((res) => {
      //   if (res.data.response.code === 200) {
      //     setDataSource([]);
      //     let dataBank = dataSource.filter((data) => data.id !== dataDelete.id);
      //     setDataSource(dataBank);
      //     let dataNew = data;
      //     dataNew.bank = dataBank;
      //     setData([]);
      //     setData(dataNew);
      //   } else {
      //     message.error("Account number is not deleted");
      //   }
      // });
    }
  };

  const column = [
    {
      title: <Text strong>Bank Name</Text>,
      dataIndex: "name",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Account Holder</Text>,
      dataIndex: "accountName",
      key: "accountName",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Account Number</Text>,
      dataIndex: "accountNumber",
      key: "accountNumber",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>IFSC</Text>,
      dataIndex: "ifsc",
      key: "ifsc",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Action</Text>,
      render: (data) => (
        <div>
          {data.id ? (
            <span>
              <Icon
                onClick={(event) => {
                  event.stopPropagation();
                  setEditBank(data.id);
                  form.setFieldsValue({
                    name: data.name,
                    accountName: data.accountName,
                    accountNumber: data.accountNumber,
                    ifsc: data.ifsc,
                  });
                }}
                type="edit"
              />
              <Divider type="vertical" />
            </span>
          ) : (
              <span />
            )}
          <Popconfirm
            title="Do you want to delete this Account Number?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              deleteData(data);
            }}
          >
            <Icon type="delete" onClick={(event) => event.stopPropagation()} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Table
        tableLayout="fixed"
        locale={{
          emptyText: (
            <Empty
              imageStyle={{ height: 25, fontSize: 30 }}
              description={
                <Text disabled strong>
                  No Accounts
                </Text>
              }
              image={<Icon type="contacts" />}
            />
          ),
        }}
        rowKey={(record) => record.id}
        pagination={false}
        size="small"
        columns={column.filter(
          (columns) => columns.title.props.children !== "Action" || !editable
        )}
        style={{ cursor: "pointer", marginTop: "5vh" }}
        dataSource={dataSource}
      />
      <Form
        style={{
          marginTop: "3vh",
          marginBottom: "3vh",
          display: !editable ? "" : "none",
        }}
      >
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              validateStatus={error.BANK && error.BANK.type}
              help={error.BANK && error.BANK.message}
              label="Bank Name">
              {getFieldDecorator("name", {
                rules: [
                  {
                    required: true,
                    message: "Enter your Bank Name!",
                  },
                ],
              })(
                <Input
                  pattern="^[a-zA-Z.\s]*[a-zA-Z.]+$"
                  // onKeyUp={e =>
                  //   form.setFieldsValue({
                  //     name: formatValue(e, "allCaps")
                  //   })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        BANK: {
                          type: "error",
                          message: "Enter Valid Bank Name"
                        }
                      })
                    } else {
                      delete error.BANK
                      setError(error)
                    }
                  }}
                  maxLength={30}
                  placeholder="Bank Name"
                  disabled={editable}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              validateStatus={error.HOLDER && error.HOLDER.type}
              help={error.HOLDER && error.HOLDER.message}
              label="Account Holder Name">
              {getFieldDecorator("accountName", {
                rules: [
                  {
                    required: true,
                    message: "Enter your Account Holder Name!",
                  },
                ],
              })(
                <Input
                  pattern="^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      accountName: formatValue(e, "allCaps")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        HOLDER: {
                          type: "error",
                          message: "Enter Valid Account Holder Name"
                        }
                      })
                    } else {
                      delete error.HOLDER
                      setError(error)
                    }
                  }}
                  maxLength={60}
                  placeholder="Account Holder Name"
                  disabled={editable}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              label="Account Number"
              validateStatus={error.AN && error.AN.type}
              help={error.AN && error.AN.message}
            >
              {getFieldDecorator("accountNumber", {
                rules: [
                  {
                    required: true,
                    message: "Enter your Account Number!",
                  },
                ],
              })(
                <Input
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Account Number"
                  pattern="^[0-9]{9,18}$"
                  // pattern="^[A-Z|a-z]{4}[0][A-Z|a-z|0-9]{6}$"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      accountNumber: formatValue(e, "onlyNo")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        AN: {
                          type: "error",
                          message: "Enter Valid Account Number",
                        },
                      });
                    } else {
                      delete error.AN;
                      setError(error);
                    }
                  }}
                  disabled={editable}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="IFSC"
              validateStatus={error.IFSC && error.IFSC.type}
              help={error.IFSC && error.IFSC.message}
              colon={false}
              required={true}
            >
              {getFieldDecorator("ifsc", {
                rules: [
                  {
                    required: true,
                    message: "Enter your IFSC!",
                  },
                ],
              })(
                <Input
                  placeholder="IFSC"
                  // style={{ textTransform: "uppercase" }}
                  disabled={editable}
                  maxLength={11}
                  pattern="^[A-Z|a-z|0-9]{11}$"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      ifsc: formatValue(e, "toUpperCase")
                    })}
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        IFSC: {
                          type: "error",
                          message: "Enter Valid IFSC Code",
                        },
                      });
                    } else {
                      delete error.IFSC;
                      setError(error);
                    }
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Button
          type="primary"
          style={{ marginTop: "5vh" }}
          onClick={() => addData()}
        >
          Save
        </Button>
      </Form>
    </div>
  );
}

const WrappedSupplierBankForm = Form.create({ name: "SupplierBankForm" })(
  BankDetails
);
export default WrappedSupplierBankForm;
