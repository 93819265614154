import React, { useState } from "react";
import { withRouter, Route } from "react-router-dom";
import TemplatePage from "./SMSActivity/TemplatePage";
import { Typography, Tooltip, Button } from "antd";
import "./SMS.less";
import { LeftOutlined } from "@ant-design/icons";

const { Title } = Typography;

// Form and Table Imports

const SMS = (props) => {
  const [length, setLength] = useState(0);
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Templates[{length}]
        </Title>
      </div>
      <TemplatePage setLength={setLength} />
    </div>
  );
};
export default withRouter(SMS);
