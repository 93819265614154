import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Breadcrumb } from "antd";
import HomeDepartment from "./HomeDepartment";
import SpecificDepartment from "./SpecificDepartment";

import "./DepartmentType.less";

const DepartmentType = (props) => {
  const Template = () => {
    const {
      match: {
        params: { deptType },
      },
    } = props;
    const id = props.location.state && props.location.state.id;
    let Component;
    if (!deptType) Component = <HomeDepartment />;
    else Component = <SpecificDepartment id={id} deptType={deptType} />;
    return Component;
  };

  return (
    <div>
      <Breadcrumb style={{ margin: "1rem 0" }} separator=">">
        <Breadcrumb.Item
          onClick={(e) => props.history.push("/autoadmin/department")}
        >
          {/* Department */}
        </Breadcrumb.Item>
        {/* <Breadcrumb.Item>{props.match.params.deptType}</Breadcrumb.Item> */}
      </Breadcrumb>
      <Template />
    </div>
  );
};

export default withRouter(DepartmentType);
