import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Button, Tooltip, Dropdown, Menu, Icon, message } from "antd";
import "./index.less";
import { platformApi } from "../../../api";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';

// Components
import VehiclePriceTable from "./VehiclePriceTable";
import WrappedAccessoriesModal from "./VehiclePriceModal";
import WrappedAdvancedFilter from "./AdvancedFilters";
import { ContextAPI } from "../../../ContextAPI";
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

const VehiclePrice = (props) => {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(-1);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [exportFile, setExportFile] = useState("");
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);

  //filters
  const [filter, setFilter] = useState([]);
  const [filteropen, setFilteropen] = useState(false);
  const [search, setSearch] = useState([]);
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  const addData = (vehiclePrice) => {
    setSpinning(true);
    if (vehiclePrice.id) {
      platformApi
        .put(`/api/vehiclePrice/${vehiclePrice.id}`, vehiclePrice)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (vehiclePrice.id === obj.id) {
                  dataSource[index] = response.data;
                }
              });
              setData([]);
              setData(dataSource);
              message.success("Vehicle price saved successfully", 2);
            } else {
              message.error("Unable to save Vehicle price", 2);
            }
          } else {
            message.error("Unable to save Vehicle price", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to save Vehicle price", 2);
          console.error("Error on Vehicle Price : ", error);
        });
    } else {
      platformApi
        .post("/api/vehiclePrice/", vehiclePrice)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              // setData([...dataSource, ]); 
              // setCount(count + 1);
              setPageData();
              message.success("Vehicle price added successfully", 2);
            } else if (response.code === 500) {
              message.error("Date range already exists", 2);
            } else {
              message.error("Unable to add vehicle price", 2);
            }
          } else {
            message.error("Unable to add vehicle price", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to add vehicle price", 2);
          console.error("Error on vehicle price : ", error);
        });
    }
  };
  const deleteData = (id) => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    platformApi
      .delete(`/api/vehiclePrice/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1)
              setPageData(null, 1)
            }
            else {
              setPageData();
            }
            message.success("Vehicle price deleted successfully", 2);
          } else {
            message.error("Unable to delete vehicle price", 2);
          }
        } else {
          message.error("Unable to delete vehicle price", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to delete vehicle price", 2);
        console.error("Error on Vehicle Price : ", error);
      });
  };
  const setPageData = (search, val) => {
    setSpinning(true);
    // if (!search) {
    platformApi
      .post("/api/vehiclePrice/get", {
        page: val ? val : page,
        size: limit,
        searchString: search && search.name,
      })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = res.data;
          if (response.code === 200) {
            setData(response.data.vehiclePrice);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch vehicle price", 2);
          }
        } else {
          message.error("Unable to fetch vehicle price", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to fetch vehicle price", 2);
        console.error("Error on Vehicle Price : ", error);
      });
    // } else {
    //   platformApi
    //     .post(`/api/vehiclePrice/search`, {
    //       name: search.name,
    //       vehiclestatus: search.vehiclestatus,
    //       size: limit,
    //       from: (page - 1) * limit,
    //     })
    //     .then((res) => {
    //       setSpinning(false);
    //       const { data } = res;
    //       if (data.code === 200) {
    //         const { response } = data;
    //         if (response.code === 200) {
    //           setData(res.data.response.data.vehiclePrice);
    //           setCount(res.data.response.data.count);
    //           // message.success("Filtered Successfully");
    //         } else {
    //           message.error("Unable to fetch Price", 2);
    //         }
    //       } else {
    //         message.error("Unable to fetch Price", 2);
    //       }
    //     });
    // }
  };
  useEffect(() => {
    platformApi.get("/api/vehiclePrice/data/export").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        setExportFile(`${ENDPOINT}${data.response}`);
      }
    });
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "vehicle_price") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPageData();
  }, []);
  useEffect(() => {
    if (search.length !== 0) setPageData(search);
    else setPageData();
  }, [page, limit]);
  useEffect(() => {
    setPage(1);
  }, [search]);
  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Vehicle Price List [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Vehicle"
          onSearch={(event) => {
            let searches = { name: event };
            setSearch(searches);
            if (searches.name === "") setPageData();
            else setPageData(searches);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch([]);
              setPageData();
            }
          }}
        />
        {/* <div style={{ width: "20%", textAlign: "center" }}>
          <Button
            type="link"
            onClick={() => {
              filteropen ? setFilteropen(false) : setFilteropen(true);
            }}
          >
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button>
        </div> */}
        <Button
          onClick={() => {
            setEditFlag(true);
            setAddFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Vehicle Price
        </Button>
      </div>
      <div className="accessories-head">
        <a
          className="linkylink"
          style={{ margin: "0 84%", width: "20%" }}
          onClick={(e) => e.stopPropagation()}
          href={exportFile}
          download
          target="_blank"
        >
          <Button type="dashed" disabled={!createAccess}>
            Export Data
          </Button>
        </a>
      </div>

      <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        close={setFilteropen}
        id={filter}
        setId={setFilter}
        setData={setData}
        setCount={setCount}
        page={page}
        setPageData={setPageData}
        setSpinning={setSpinning}
        limit={limit}
        setSearch={setSearch}
      />
      <VehiclePriceTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          total: count,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{ spinning }}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <WrappedAccessoriesModal
        data={dataSource[dataSource.findIndex((obj) => selected === obj.id)]}
        emitData={(data) => addData(data)}
        editable={editFlag}
        open={addFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
          setModel([]);
        }}
        modify={modifyAccess}
      />
    </div>
  );
}

export default withRouter(VehiclePrice)