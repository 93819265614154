import React from "react";
import { Table, Select, Input, Typography } from "antd";

const { Text } = Typography;

const VehicleTable = ({ editable, dataSource, editData }) => {

  const handleChange = (value, index, type) => {
    let data = [...dataSource];
    data[index][type] = value;
    editData(data);
  }

  const column = [
    {
      width: "65%",
      title: <Text strong>Model</Text>,
      dataIndex: "vehicle",
      key: "vehicle",
      render: (text) => (
        <Input disabled value={text && `${text.modelCode} - ${text.modelName}` || 'Vehicle Deleted'} placeholder="Model" />
      )
    },
    {
      width: "35%",
      title: <Text strong>Price</Text>,
      dataIndex: "price",
      key: "price",
      render: (text, record, index) => (
        <Input
          addonBefore="&#8377;"
          key="price"
          onInput={(event) => {
            handleChange(parseFloat(event.target.value) || 0, index, "price");
          }}
          onWheel={(event) => event.currentTarget.blur()}
          disabled={!editable}
          value={text}
        />
      ),
    },
  ]

  return (
    <Table
      pagination={false}
      scroll={{ y: 380 }}
      columns={column.filter(
        (columns) => columns.title.props.children !== "Action" || editable
      )}
      size="small"
      rowKey={dataSource => dataSource && dataSource.vehicle && dataSource.vehicle.id}
      dataSource={dataSource}
      locale={{
        emptyText: (
          <Text disabled strong>
            No Vehicles 
          </Text>
        ),
      }}
    />
  );
};

export default React.memo(VehicleTable);
