import React, { useState, useEffect } from "react";
import {
  Modal,
  Typography,
  Upload,
  Button,
  Icon,
  Input,
  Row,
  Col,
  Form,
  message,
} from "antd";

const { Text } = Typography;
const { Item } = Form;

const FilesModal = ({ form, open, type, close, emitData, vehicleDetail, setFileUploadData, addData }) => {
  const [formData, setFormData] = useState(null);
  const [info, setInfo] = useState({})

  const [loading, setLoading] = useState(false);
  const [filename, setFileName] = useState(null)
  const [url, setUrl] = useState(null)
  const [valid, setValid] = useState(false)

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;



  useEffect(() => {
    console.log(valid);
    if (filename != null && url != null && url != "" && filename != "") {
      setValid(true)
    } else {
      setValid(false)
    }
  }, [filename, url])

  const handleChange = (info) => {

    /*
    setInfo(info)
    if (!getFieldValue("name")) {
      setFieldsValue({ fileName: info.file.name.replace(/\s/g, "_") });
    }
    */
    setUrl(info.target.value)
    const fd = {
      "master": "Company Master",
      "module": "Vehicle Master",
      "id": vehicleDetail.name || "Vehicle",
      "type": type
    }
    setFormData(fd)
  };

  // this method is deprecated
  const beforeDocUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("Document must smaller than 50MB!");
    }
    return isLt2M;
  };


  const clearFields = () => {
    form.resetFields();
    setLoading(false);
    setFormData(null)
  };

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        let fileName = `${type}$${filename}`
        let fd = { ...formData, url: url, [fileName]: info ? info.file?.originFileObj : "" }
        setFileUploadData(fd)
        const eData = {
          id: "",
          name: fileName,
          entity: "Vehicle model",
          url: url
        }
        emitData(eData, type);
        addData(eData, type)
        form.resetFields();
        close();
      }
    });
  };

  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <Modal
      title={<Text strong> {type} Upload</Text>}
      visible={open}
      onOk={handleSubmit}
      confirmLoading={loading}
      okButtonProps={{ disabled: !valid }}
      onCancel={() => {
        clearFields();
        close();
      }}
      okText="Save"
    >
      <Form>
        <Row>
          <Item label={type + " Name"} colon={false} required>
            {getFieldDecorator("fileName", {
              rules: [{ required: true, message: "Enter " + type + " Name" }],
            })(<Input placeholder={`${type} name`} onChange={(e) => setFileName(e.target.value)} />)}
          </Item>
        </Row>
        <Row>

          <Item label={type + " Url"} colon={false} required>
            {getFieldDecorator("file", {
              rules: [{ required: true, message: `Enter ${type} Url ` }],
            })(<Input placeholder={`Paste ${type} Url`} onChange={handleChange} />)}
          </Item>

          {/* <Item>
              {getFieldDecorator("file", {
                rules: [{ required: true, message: "Choose a file" }],
              })(
                <Upload
                  beforeUpload={e => beforeDocUpload(e)}
                  showUploadList={false}
                  customRequest={dummyRequest}
                  onChange={handleChange}
                  multiple={false}
                  listType="text"
                  accept=".pdf,.doc,.docx"
                >
                  <Button type="dashed">Choose File</Button>
                </Upload>
              )}
            </Item> */}

        </Row>
        <Row>
          {/* <span>*(Accepts only .pdf,.doc,.docx files)</span> */}
        </Row>
      </Form>
    </Modal>
  );
};

const WrappedFilesModal = Form.create()(FilesModal);

export default WrappedFilesModal;
