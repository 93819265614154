import React, { useState, useEffect } from 'react';
import {
  Modal, Input, Form, Row, Col, Typography,
} from 'antd';
import { formatValue } from '../../../../utils'

const { Item } = Form;
const { Text, Title } = Typography;

const HSNForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const [error, setError] = useState({})

  const {
    form, open, close, data, modify
  } = props;
  const { getFieldDecorator } = form;

  useEffect(() => {
    setEditable(props.editable);
    if (data) {
      form.setFieldsValue({
        code: data.code,
        description: data.description,
        cess: data.cess,
        cgst: data.cgst,
        sgst: data.sgst,
        igst: data.igst,
      });
    }
  }, [data, props.editable]);

  const calculateGST = (event) => {
    const IGST = event.target.value;
    form.setFieldsValue({
      cgst: IGST / 2,
      sgst: IGST / 2,
    });
  };

  const handleSubmit = () => {
    form.validateFields(async (validationError) => {
      if (validationError === null && !error.IGST && !error.CESS && !error.HSN) {
        setLoading(true);
        const code = form.getFieldValue('code');
        const igst = parseFloat(form.getFieldValue('igst'));
        const sgst = parseFloat(form.getFieldValue('sgst'));
        const cgst = parseFloat(form.getFieldValue('cgst'));
        const cess = parseFloat(form.getFieldValue('cess'));
        const description = form.getFieldValue('description');
        const createdAt = new Date().toLocaleDateString();

        const HSN = {
          code,
          description,
          cess,
          cgst,
          sgst,
          igst,
          createdAt,
        };
        data ? (HSN.id = data.id) : {};
        await props.emitData(HSN);
        setLoading(false);
        form.resetFields();
        props.close();
      }
    });
  };

  return (
    <Modal
      title={<Title level={4}>HSN Code</Title>}
      visible={open}
      okText={editable ? 'Save' : 'Modify'}
      onCancel={() => {
        form.resetFields();
        setError({})
        close();
      }}
      okButtonProps={{ loading, disabled: (!editable && !modify) }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item
              label={<Text>HSN Code</Text>}
              colon={false}
              validateStatus={error.HSN && error.HSN.type}
              help={error.HSN && error.HSN.message}
            >
              {getFieldDecorator('code', {
                rules: [{ required: true, message: 'Enter HSN code' }],
              })(
                <Input
                  // maxLength={15}
                  onWheel={(event) => event.currentTarget.blur()}
                  disabled={!editable}
                  placeholder="HSN code"
                  pattern="^[0-9]{1,12}$"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        HSN: {
                          type: "error",
                          message: "Enter Valid HSN!"
                        }
                      })
                    } else {
                      delete error.HSN
                      setError(error)
                    }
                  }}
                />,
              )}
            </Item>
          </Col>
          <Col span={10}>
            <Item
              label={<Text>IGST</Text>}
              colon={false}
              validateStatus={error.IGST && error.IGST.type}
              help={error.IGST && error.IGST.message}
            >
              {getFieldDecorator('igst', {
                rules: [{ required: true, message: 'Enter IGST' }],
              })(
                <Input
                  addonAfter="%"
                  onChange={calculateGST}
                  disabled={!editable}
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="IGST"
                  // maxLength={5}
                  maxLength={6}
                  onKeyUp={e => {
                    form.setFieldsValue({
                      igst: formatValue(e, "noWithDot")
                    })
                    if (form.getFieldValue("igst").length == 0) {
                      delete error.IGST
                    }
                  }}
                  pattern="[0-9]+(\.[0-9][0-9]?)?"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        IGST: {
                          type: "error",
                          message: "Enter Valid IGST!"
                        }
                      })
                    } else {
                      delete error.IGST
                      setError(error)
                    }
                  }}
                />,
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item
              label={<Text>Description</Text>}
              colon={false}
            >
              {getFieldDecorator('description', {
                rules: [{ required: false, message: 'Enter Description' }],
              })(
                <Input
                  onKeyUp={e => {
                    if (e.target.value) {
                      form.setFieldsValue({
                        description: formatValue(e, "firstCaps")
                      })
                    }
                  }
                  }
                  disabled={!editable}
                  placeholder="Description"
                  maxLength={50}
                />)}
            </Item>
          </Col>
          <Col span={10}>
            <Item label={<Text>CGST</Text>} colon={false}>
              {getFieldDecorator('cgst', {
                rules: [{ required: true, message: 'Enter CGST' }],
              })(
                <Input
                  addonAfter="%"
                  disabled
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="CGST"
                  maxLength={6}
                />,
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item
              label={<Text>CESS</Text>}
              colon={false}
              validateStatus={error.CESS && error.CESS.type}
              help={error.CESS && error.CESS.message}
            >
              {getFieldDecorator('cess', {
                rules: [{ required: false, message: 'Enter Cess' }],
              })(
                <Input
                  addonAfter="%"
                  disabled={!editable}
                  // type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="Cess"
                  maxLength={6}
                  onKeyUp={e => {
                    form.setFieldsValue({
                      cess: formatValue(e, "noWithDot")
                    })
                    if (form.getFieldValue("cess").length == 0) {
                      delete error.CESS
                    }
                  }}
                  pattern="[0-9]+(\.[0-9][0-9]?)?"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        CESS: {
                          type: "error",
                          message: "Enter Valid CESS!"
                        }
                      })
                    } else {
                      delete error.CESS
                      setError(error)
                    }
                  }}
                />,
              )}
            </Item>
          </Col>
          <Col span={10}>
            <Item label={<Text>SGST</Text>} colon={false}>
              {getFieldDecorator('sgst', {
                rules: [{ required: true, message: 'Enter SGST' }],
              })(
                <Input
                  addonAfter="%"
                  disabled
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  placeholder="SGST"
                  maxLength={6}
                />,
              )}
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: 'normal_login' })(HSNForm);

export default WrappedAccessoriesModal;
