import React, { useState, useEffect } from 'react'
import { Row, Col, Icon, Card, Typography, message } from 'antd';
import { Button } from 'antd';
import {
  EyeFilled,
  EditFilled,
} from '@ant-design/icons';
import CustomerModal from '../../TransactionMasters/CustomerDetails/CustomerDetailsForm'
import { platformApi } from '../../../api'
import Moment from 'moment'
import { Divider } from 'antd';

const CustomerInfo = (props) => {
  const { customer, setCustomer, enquiryType, updateCustomerData } = props
  const { Text, Title } = Typography;
  //CUSTOMER ADD MODAL STATE
  const [modalOpen, setModalOpen] = useState(false)
  const [cusId, setCusId] = useState("");
  const [editFlag, setEditable] = useState(false);

  const viewCustomer = () => {
    setEditable(false)
    setModalOpen(true)
  }

  useEffect(() => {
    let tempCustomer = { ...customer }
    setCustomer(tempCustomer)
  }, [modalOpen])

  const editCustomer = () => {
    setEditable(true)
    setModalOpen(true)
  }

  return (
    <div>
      <Card style={{ height: '220px' }}>
        <Title level={3} style={{ color: 'black' }}>{customer.name}</Title>
        <Row type='flex' justify="space-between" gutter={[8, 8]}>
          <Col span={24}>
            {
              customer.customerId ?
                <Text><strong>Customer ID: </strong>{customer.customerId}</Text>
                : null
            }
          </Col>
          <Col span={24}>
            {
              customer.customerType ?
                <Text><strong>Customer Type: </strong>{customer.customerType}</Text>
                : null
            }
          </Col>
          <Col span={24}>
            <Text>{customer.gender}{customer.dateOfBirth ? " | " + Moment().diff(customer.dateOfBirth, 'years') + "Y" : ""}{customer.address && customer.address.locality && customer.address.locality.length !== 0 ? " | " + customer.address.locality : ""}</Text>
          </Col>
        </Row>
        {/* <Row>
          <Col>
            <Text><strong>Enquiry State: </strong></Text> <span>{enquiryType}</span>
          </Col>
        </Row> */}
        <Row type='flex' justify="end" gutter={[16, 16]}>
          <Col>
            <Button onClick={() => viewCustomer()}>
              <EyeFilled />
            </Button>
          </Col>
          <Col>
            <Button onClick={() => editCustomer()}>
              <EditFilled />
            </Button>
          </Col>
        </Row>
      </Card>
      <CustomerModal
        data={customer}
        setData={setCustomer}
        emitData={updateCustomerData}
        open={modalOpen}
        close={() => {
          setModalOpen(false);
        }}
        editable={editFlag}
        cusId={cusId}
        setCusId={setCusId}
      />
    </div>
  )
}

export default CustomerInfo;