import React, { useEffect } from "react";
import { Table, Divider, Popconfirm, Typography } from "antd";

const { Text } = Typography;

export default function FilterTable(props) {
  const { pagination, dataSource, setDataSource, editable, removeData, setRemoveData } = props;
  //   deletedAt: null
  //   name: "mnbvc"
  //   updatedAt: "2020-06-04T09:03:08.540Z"
  //   fatherName: "kjhgfd"
  //   GSTType: "Unregistered"
  //   dateOfBirth: "2020-06-01T18:30:00.000Z"
  //   customerType: "Sales Customer"
  //   customerGrouping: "Individual"
  //   customerId: "CUS0009"
  //   id: "ckb0jy1i100ds0804rlpaoher"
  //   salutation: "Mr"
  //   createdAt: "2020-06-04T09:03:08.540Z"
  //   GSTNo: null
  //   gender: "Female"

  // useEffect(() => {
  // }, [dataSource]);

  const column = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Number",
      dataIndex: "contacts",
      key: "contacts",
      render: (record, data) => (
        <span>{record && record[0] ? record[0].phone : "-"}</span>
      ),
    },
    // {
    //   title: "Vehicle Name",
    //   dataIndex: "vehicle",
    //   key: "vehicle",
    //   render: (record) => <span>{record}</span>,
    // },
    {
      title: "Customer Type",
      dataIndex: "customerType",
      key: "customerType",
      render: (record) => <span>{record}</span>,
    },

    {
      title: "Action",
      render: (data) => (
        <div>
          {/* {
            modify ? */}
          {/* <span
            onClick={(event) => {
              event.stopPropagation();
              //   openModal(data.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span> */}
          {/* :
              <span></span>
          } */}

          {/* {
            deleteAccess ? */}
          {editable ? (
            <Popconfirm
              title="Do you want to delete this Provisional Customer?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                setDataSource(dataSource.filter((obj) => data.id !== obj.id));
                setRemoveData([...removeData, data.id])
                //   event.stopPropagation();
              }}
            >
              {/* <Divider type="vertical" /> */}
              <span className="linkylink">Remove</span>
            </Popconfirm>
          ) : (
              <span>Remove</span>
            )}
          {/* :
              <span></span>
          } */}
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        // onClick: () => openModal(props.id, false),
      })}
    />
  );
}
