import React, { useState, useEffect } from "react";
import { Form, Input, Row, Col, Select, Radio, Button, Modal, message } from "antd";
import { hasId } from "../../../../JSONFiles/Submodule.json";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils"
import "../IDForm/index.less";
const { Item } = Form;
const { Option } = Select;
const IDForm = ({ form, addData, modalData, create, setSelected, open, setOpen, editable, setEditable }) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const Modules = [
    { key: "COMPANYMASTER", title: "Company Master" },
    { key: "TRANSACTIONMASTER", title: "Transaction Master" },
  ];
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const [submodule, setSubmodule] = useState([]);
  const [branchflag, setBranchflag] = useState(false);
  const [branch, setbranch] = useState([]);
  const [modify, setModify] = useState(false);
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState({})
  const [Scope, setScope] = useState([
    { key: "COMPANY", title: "Company level" },
    { key: "BRANCH", title: "Branch level" },
  ])
  useEffect(() => {
    if (modalData) {
      // setModify(true);
      modalData.scope === 'BRANCH' ? setBranchflag(true) : setBranchflag(false)
      setSubmodule(hasId);
      setFieldsValue({
        count: modalData.count,
        module: hasId.filter((obj) => modalData.subModule === obj.key)[0].id,
        submodule: hasId.filter((obj) => modalData.subModule === obj.key)[0]
          .key,
        text: modalData.text,
        scope: modalData.scope,
        resetid: modalData.resetAnnually,
        branch: modalData.branch ? modalData.branch.id : undefined,
      });
    }
  }, [modalData]);


  useEffect(() => {
    platformApi.get("/api/user/branch").then((res) => {
      setbranch(res.data.data);
    });
  }, []);

  const handleModulechange = (id) => {
    form.setFieldsValue({ submodule: undefined, scope: undefined })
    setBranchflag(false)
    setSubmodule(hasId.filter((obj) => id === obj.id));
  };
  const handleSubmodule = (id) => {
    setBranchflag(false);
    form.setFieldsValue({ scope: undefined })
    if (id === 'EMPLOYEE' || id === 'CUSTOMER') {
      setScope([
        { key: "COMPANY", title: "Company level" },
      ])
    }
    else {
      setScope([
        { key: "COMPANY", title: "Company level" },
        { key: "BRANCH", title: "Branch level" },
      ])
    }
  };
  const handleScopeChange = (id) => {
    if (id === "BRANCH") setBranchflag(true);
    if (id === "COMPANY") setBranchflag(false);
  };
  const clearFields = () => {
    resetFields();
    setSubmodule([]);
    setError({})
    // setModify(false);
    setBranchflag(false);
    setSelected(undefined);
  };
  const handleSubmit = () => {
    // setModify(false); 
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
      let flag = form.getFieldValue("scope") === "COMPANY"
      let check = validationError
      if (flag) {
        delete check.branch
      }
      if (check != null && check != undefined) {
        if (Object.keys(check).length === 0) {
          isValid = true
        }
      }
      else if (check == null || check == undefined) {
        isValid = true
      }
      if (isValid) {
        setLoading(true)
        const subModule = form.getFieldValue("submodule");
        const text = form.getFieldValue("text");
        const count = form.getFieldValue("count");
        const scope = form.getFieldValue("scope");
        const resetAnnually = form.getFieldValue("resetid");
        const branch = getFieldValue("branch");
        const id = modalData ? modalData.id : undefined;
        const data = {
          id,
          subModule,
          text,
          count,
          scope,
          resetAnnually,
          branch,
        };
        addData(data);
        setOpen(false)
        setLoading(false)
        clearFields();
      }
      else {
        message.error("Enter all fields correctly")
      }
    });

  };
  return (
    <Modal
      title="ID Generator"
      visible={open}
      onCancel={() => {
        setOpen(false);
        clearFields();
      }}
      okButtonProps={{ loading }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      okText={editable ? 'Save' : 'Modify'}
    >
      <div>
        <Form>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item colon={false} label="Module">
                {getFieldDecorator("module", {
                  rules: [{ required: true, message: "Select Module" }],
                })(
                  <Select
                    placeholder="Select a module"
                    onChange={handleModulechange}
                    filterOption={filterMethod}
                    showSearch
                    showAction={["click", "focus"]}
                    disabled={!create || !editable}
                  >
                    {Modules.map((obj) => (
                      <Option key={obj.key}>{obj.title}</Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item colon={false} label="Sub Module">
                {getFieldDecorator("submodule", {
                  rules: [{ required: true, message: " Select Submodule" }],
                })(
                  <Select
                    placeholder="Select a submodule"
                    filterOption={filterMethod}
                    onChange={handleSubmodule}
                    showSearch
                    showAction={["click", "focus"]}
                    disabled={!create || !editable}
                  >
                    {submodule.map((obj) => (
                      <Option key={obj.key}>{obj.title}</Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item colon={false} label="Static text parameter"
                validateStatus={error.text && error.text.type}
                help={error.text && error.text.message}
              >
                {getFieldDecorator("text", {
                  rules: [{ required: true, message: "Enter Text Parameter" }],
                })(
                  <Input
                    onKeyUp={e =>
                      form.setFieldsValue({
                        text: formatValue(e, "toUpperCase")
                      })}
                    // pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                    // pattern="^[^0-9][^0-9]+$"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          text: {
                            type: "error",
                            message: "Enter Valid Text Parameter",
                          },
                        });
                      } else {
                        delete error.text;
                        setError(error);
                      }
                    }}
                    placeholder="Static text parameter" disabled={!create || !editable} />
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item colon={false} label={modalData ? "Upcoming ID" : "Numeric starting point"}>
                {getFieldDecorator("count", {
                  rules: [{ required: true, message: "Enter Numeric" }],
                })(
                  <Input
                    type="number"
                    placeholder={modalData ? "Upcoming ID" : "Numeric starting point"}
                    disabled={!create || modalData || !editable}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item colon={false} label="Scope of ID">
                {getFieldDecorator("scope", {
                  rules: [{ required: true, message: "Select Scope " }],
                })(
                  <Select
                    placeholder="Scope of ID"
                    filterOption={filterMethod}
                    showSearch
                    showAction={["click", "focus"]}
                    onChange={handleScopeChange}
                    disabled={!create || !editable}
                  >
                    {Scope.map((obj) => (
                      <Option key={obj.key}>{obj.title}</Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={11} >
              <Item colon={false} label="Select Branch" style={{ visibility: branchflag ? 'visible' : 'hidden' }}>
                {getFieldDecorator("branch", {
                  rules: [{ required: true, message: "Select Branch " }],
                })(
                  <Select
                    placeholder="Select a branch"
                    filterOption={filterMethod}
                    showSearch
                    showAction={["click", "focus"]}
                    disabled={!create || !editable}
                  >
                    {branch.map((obj) => (
                      <Option key={obj.id} value={obj.id}>
                        {obj.name}
                      </Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={7}>
              <Row type="flex" justify="space-around">
                <Item colon={false} label="Reset ID annually">
                  {getFieldDecorator("resetid", {
                    rules: [
                      { required: false, message: "Select one of the above" },
                    ],
                  })(
                    <Radio.Group disabled={!editable}>
                      <Radio value={true}>Yes</Radio>
                      <Radio value={false}>No</Radio>
                    </Radio.Group>
                  )}
                </Item>
              </Row>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
const WrappedForm = Form.create()(IDForm);

export default WrappedForm;
