import { Tabs, Button, Tooltip, message,Icon ,Table} from "antd";
import React, { useEffect, useState,useContext } from "react";
import { withRouter } from "react-router-dom";
import WhatsupProfile from "./WhatsupProfile";
import WhatsTable from "./WhatsupTemplate/Table";
import { LeftOutlined, MessageOutlined, UserOutlined, HistoryOutlined } from "@ant-design/icons";
import "./whatsup.less";
import ModalForm from "./WhatsupTemplate/Form";
import { platformApi } from "../../../api";
import WhatsappHistoryLogs from "./whatsappHistoryLogs/whatsappHistoryLogs";
import WhatsappWeb from "./WhatsappWeb/index";
import WhatsappPromotion from "./whatsappPromotion/index";
import { ContextAPI } from "../../../ContextAPI";
const { TabPane } = Tabs;
const Whatsup = (props) => {
  const [limit, setLimit] = useState(10);
  const [spinning, setSpinning] = useState(false);
  const [data, setData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [index, setIndex] = useState(null);
  const [open, setOpen] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  // 
  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "whatsapp") {
          if (each.access.update) {
            setModifyAccess(true);
          }
        }
      });
    // setPageData();
    // if (props.customerId) {
    //   setAddFlag(true);
    //   setSelected({ ...props.cusData });
    // }
  }, []);
  // 
  useEffect(() => {
    setSpinning(true);
    platformApi
      .get("/api/whatsapp")
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          if (data.response.data.length > 0) {
            let arr = [];
            let templateData = data.response.data;
            for (let i = 0; i < templateData.length; i++) {
              if (templateData[i].name != "") {
                arr.push(templateData[i]);
              }
            }
            setData(arr);
          }
        } else {
          message.error("Unable to fetch templates", 2);
        }
      })
      .catch(() => {
        message.error("Unable to fetch templates", 2);
      });
  }, []);
  const updateTemplate = (dataTemplate, i) => {
    setSpinning(true);
    setOpen(false);
    platformApi
      .put(`/api/whatsapp/updateTemplate/${dataTemplate.id}`, dataTemplate)
      .then((res) => {
        setSpinning(false);
        if (res.data.code === 200) {
          message.success("Templated Updated Successfully", 2);
        } else {
          message.error("Templated Updated Failed", 2);
        }
      })
      .catch((err) => {
        message.error("Templated Updated Failed", 2);
      });
  };
  return (
    <>
      <div className="whatsup_header">
        <Tooltip title="Back to Company Master">
          <Button onClick={() => props.history.push("/autoadmin/company")}>
            <LeftOutlined />
          </Button>
        </Tooltip>
      </div>
      <Tabs defaultActiveKey="1">
        <TabPane
          tab={
            <span>
              <UserOutlined />
              Profile Information
            </span>
          }
          key="1"
        >
          <WhatsupProfile />
        </TabPane>
        <TabPane
          tab={
            <span>
              <MessageOutlined />
              Whatsapp Transaction
            </span>
          }
          key="2"
        >
          <WhatsTable
            pagination={{
              pageSize: limit,
              defaultCurrent: 1,
              showQuickJumper: true,
            }}
            spinning={spinning}
            data={data}
            openData={(index, edit) => {
              setEdit(edit);
              setIndex(index);
              setOpen(true);
            }}
            modifyAccess={modifyAccess  }
          />
        </TabPane>
        <TabPane
          tab={
            <span>
              <MessageOutlined />
              Whatsapp Promotion
            </span>
          }
          key="4"
        >
         <WhatsappPromotion
          pagination={{
            pageSize: limit,
            defaultCurrent: 1,
            showQuickJumper: true,
          }}
          spinning={spinning}
          // openData={(index, edit) => {
          //   setEdit(edit);
          //   setIndex(index);
          //   setOpen(true);
          // }}
          modifyAccess={modifyAccess  }
         />
        </TabPane>
        <TabPane
          tab={<span>
            <HistoryOutlined />
          Whatsapp logs </span>}
          key="3"
        >
          <WhatsappHistoryLogs />
        </TabPane>
        {/* <TabPane
          tab={<span>
          <Icon type="phone" />
          Whatsapp Web </span>}
          key="4"
        >
          <WhatsappWeb />
        </TabPane> */}
      </Tabs>
      <ModalForm
        close={() => {
          setOpen(false);
          setIndex(null);
        }}
        visible={open}
        edit={edit}
        setEdit={setEdit}
        data={data[data.findIndex((item) => item.id === index)]}
        index={index}
        setData={setData}
        updateTemplate={updateTemplate}
      />
    </>
  );
};
export default withRouter(Whatsup);