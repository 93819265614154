import React from "react";
import { Table, Divider, Popconfirm, Button,Typography } from "antd";
import { hasId, modules } from "../../../../JSONFiles/Submodule.json";
import moment from 'moment'
const{Text}=Typography;
const RTOTable = (props) => {
  const {
    pagination,
    dataSource,
    openModal,
    spinner,
    history,
    deleteData,
    modify,
    deleteAccess
  } = props;

  const column = [
    {
      title: "RTO Code",
      dataIndex: "code",
      key: "code",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      render: (record) => record && <span>{record.name}</span>,
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      render: (record, data) => data.city && <span>{data.city.state.name}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
          {modify?
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(data, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
          <span></span>
    }

          <Divider type="vertical" />
        {deleteAccess?
          <Popconfirm
            title="Do you want to delete this RTO?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              deleteData(data.id);
            }}
          >
            <span
              onClick={(event) => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
          :
          <span></span>
    }
      {
        !modify && !deleteAccess
        ?
        <Text strong disabled> No Access Given</Text>
        :
        <span></span>
      }
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      loading={spinner}
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      onRow={(props) => ({
        onClick: () => openModal(props, false),
      })}
    />
  );
};

export default RTOTable;
