import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Row,
  Button,
  Collapse,
  Icon,
  Typography,
  Col,
  Radio,
} from "antd";
import "./TemplateForm.less";
import { platformApi } from "../../../../../../api";

const { Item } = Form;
const { TextArea } = Input;
const { Panel } = Collapse;
const { Text } = Typography;

const TemplateForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const [collapsed, setCollapsed] = useState(false);
  const [legends, setLegends] = useState([]);
  const [maxLength, setMaxLength] = useState();

  const { form, open, close, data } = props;
  const { getFieldDecorator } = form;

  // const legends = [
  //   "Dynamic Content should start with : ${ }",
  //   "Quotation No: ${ qno }",
  //   "Customer Name: ${ name }",
  //   "Quotation Link: ${ url }",
  // ];

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        setLoading(true);
        const template = form.getFieldsValue();
        data ? (template.id = data.id) : {};
        props.emitData(template);
        form.resetFields();
        setLoading(false);
        props.close();
      }
    });
  };

  useEffect(() => {
    setEditable(props.editable);
    if (data) {
      data.legends && setLegends(JSON.parse(data.legends));
      form.setFieldsValue({
        name: data.name,
        text: data.text,
        status: data.status,
      });
      if (data.whatsAppId) {
        console.log("data.whatsAppId", data.whatsAppId);
        platformApi
          .get(`/api/sms/whatsAppTemplate/${data.whatsAppId}`)
          .then((result) => {
            const { data } = result;
            console.log("Data", data);
            if (data.code === 200) {
              const { response } = data;
              if (response.code === 200) {
                console.log("Response");
                if (response.status === "approved") {
                  form.setFieldsValue({
                    approved: "YES",
                  });
                } else {
                  form.setFieldsValue({
                    approved: "NO",
                  });
                }
              }
            }
          });
      } else {
        form.setFieldsValue({
          approved: "NO",
        });
      }
    }
  }, [data, props.editable]);

  // useEffect(() => { }, []);

  return (
    <Modal
      title="Add Template"
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
      okButtonProps={{ loading }}
      onCancel={() => {
        form.resetFields();
        close();
      }}
      okText={editable ? "Save" : "Modify"}
      wrapClassName="Template-modal"
      visible={open}
    >
      <Form>
        <Row justify="center" type="flex">
          <Text strong>{data && data.module + " - " + data.submodule}</Text>
        </Row>
        <Row>
          <Item label="Template Name" colon={false}>
            {getFieldDecorator("name", {
              rules: [
                { required: false, message: "Please enter a template name" },
              ],
            })(<Input disabled={!editable} placeholder="Template Name" />)}
          </Item>
        </Row>
        <Row>
          <Item label="SMS Text" colon={false}>
            {getFieldDecorator("text", {
              rules: [{ required: false, message: "Enter SMS Text" }],
            })(
              <TextArea
                placeholder="SMS Text"
                disabled={!editable}
                autoSize={{ minRows: 3 }}
                maxLength={maxLength}
                onChange={(event) => {
                  let flag = false;
                  for (let i = 0; i < event.target.value.length; i = i + 1) {
                    if (event.target.value.charCodeAt(i) > 255) {
                      flag = true;
                      break;
                    }
                  }
                  if (flag) {
                    setMaxLength(70);
                  } else {
                    setMaxLength(459);
                  }
                }}
              />
            )}
          </Item>
        </Row>

        <Row type="flex" justify="space-between">
          <Col span={15}>
            <Item>
              <Collapse
                destroyInactivePanel={true}
                bordered={false}
                expandIcon={({ isActive }) => (
                  <Icon type="caret-right" rotate={isActive ? 90 : 0} />
                )}
              >
                <Panel
                  style={{
                    borderBottom: "none",
                  }}
                  header={<Text strong>Legends</Text>}
                >
                  {legends.map((text, index) => (
                    <div key={index}> {text} </div>
                  ))}
                </Panel>
              </Collapse>
            </Item>
          </Col>

          <Col span={9}>
            <Item label="Message Status" colon={false}>
              {getFieldDecorator("status")(
                <Radio.Group disabled={!editable} buttonStyle="solid">
                  <Radio.Button value="YES">Yes</Radio.Button>
                  <Radio.Button value="NO">No</Radio.Button>
                </Radio.Group>
              )}
            </Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const WrappedTemplateModal = Form.create({ name: "Template modal" })(
  TemplateForm
);

export default WrappedTemplateModal;
