// @ts-nocheck
import React, { useState, useEffect } from "react";
import {
  Form,
  Icon,
  Input,
  Upload,
  message,
  DatePicker,
  Select,
  Button,
  Modal,
  Row,
  Col,
  Typography,
  Avatar,
  Spin,
  Popconfirm,
} from "antd";
import _, { assign, toUpper, upperCase } from "lodash";
import "./index.less";
import FormItem from "antd/lib/form/FormItem";
import moment from "moment";
import { setConfig } from "react-hot-loader";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils";

const { Option } = Select;

const { Text, Title } = Typography;

let urls = process.env.NODE_ENV === "development" ? "http://localhost:4000/" : "/";


const EmployeeForms = ({
  form,
  empId,
  setEmpId,
  setdataSource,
  editable,
  setCount,
  count,
  modalData,
  setModalData,
  setVisible,
  visible,
  dataSource,
  dept,
  setAssign,
  setAssignData,
  newEmployee,
}) => {
  const [error, setError] = useState({});

  const [imageUrl, imageUrlChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setconfirmLoading] = useState(false);
  const [fileLoading, setFileLoading] = useState([false, false, false, false]);
  const [date, setDate] = useState("default");
  const [country, setcountry] = useState([]);
  const [state, setstate] = useState([]);
  const [booleanstate, setbooleanstate] = useState(true);
  const [city, setcity] = useState([]);
  const [booleancity, setbooleancity] = useState(true);
  const [branch, setbranch] = useState([]);
  const [department, setdepartment] = useState([]);
  const [dp, setDp] = useState("");
  const [aadhar, setAadhar] = useState("");
  const [pan, setPan] = useState("");
  const [passbook, setPassbook] = useState("");
  const [license, setLicense] = useState("");
  const [aadharFile, setAadharFile] = useState([]);
  const [panFile, setPanFile] = useState([]);
  const [licenseFile, setLicenseFile] = useState([]);
  const [passbookFile, setPassbookFile] = useState([]);
  const [formData, setFormData] = useState(null);
  const [removeLogo, setRemoveLogo] = useState(false);
  const [reAssign, setReassign] = useState("");
  const [upText1, setUpText1] = useState("No documents Uploaded");
  const [upText2, setUpText2] = useState("No documents Uploaded");
  const [upText3, setUpText3] = useState("No documents Uploaded");
  const [upText4, setUpText4] = useState("No documents Uploaded");

  const bloodGroups = [
    "O+ve",
    "O-ve",
    "A+ve",
    "A-ve",
    "B+ve",
    "B-ve",
    "AB+ve",
    "AB-ve",
  ];
  useEffect(() => {
    platformApi.get("/api/csc/country").then((res) => {
      setcountry(res.data.data);
    });
    platformApi.get("/api/user/branch").then((res) => {
      setbranch(res.data.data);
    });
    platformApi.get("/api/user/department").then((res) => {
      setdepartment(res.data.data);
    });
    imageUrlChange(false);
    setLicense("");
    setPan("");
    setAadhar("");
    setPassbook("");
  }, []);

  // useEffect(() => {
  //   if (license !== "")
  //     setUpText1(<div style={{ color: "green" }}>License Uploaded</div>);
  // }, [license]);

  // useEffect(() => {
  //   if (pan !== "")
  //     setUpText2(<div style={{ color: "green" }}>PAN Card Uploaded</div>);
  // }, [pan]);

  // useEffect(() => {
  //   if (aadhar !== "")
  //     setUpText3(<div style={{ color: "green" }}>Aadhar Card Uploaded</div>);
  // }, [aadhar]);

  // useEffect(() => {
  //   if (passbook !== "")
  //     setUpText4(<div style={{ color: "green" }}>Passbook Uploaded</div>);
  // }, [passbook]);

  useEffect(() => {
    let formdata = new FormData();
    setFormData(formdata);
    if (!modalData) {
      setUpText1("No documents Uploaded");
      setUpText2("No documents Uploaded");
      setUpText3("No documents Uploaded");
      setUpText4("No documents Uploaded");
    }
    if (dept) {
      form.setFieldsValue({
        profile: {
          department: {
            role: dept,
          },
        },
      });
    }
  }, [visible]);

  useEffect(() => {
    if (modalData) {
      const data = modalData;
      // console.log("Get data", data)
      if (data.profile.address) {
        platformApi
          .post("/api/csc/states", { id: data.profile.address.country.id })
          .then((res) => {
            setstate(res.data.data);
            setbooleanstate(false);
          })
          .catch((err) => {
            message.error("Couldn't fetch States");
            console.error("State fetch error :", err);
          });

        platformApi
          .post("/api/csc/cities", { id: data.profile.address.state.id })
          .then((res) => {
            setcity(res.data.data);
            setbooleancity(false);
          })
          .catch((err) => {
            message.error("Couldn't fetch Cities");
            console.error("Cities fetch error :", err);
          });
      }

      data.profile.dateOfBirth = moment(data.profile.dateOfBirth);
      const displayPic = data.profilePicture;
      imageUrlChange(displayPic);
      setDp(displayPic);
      data.profile.documents[0] &&
        setLicense(data.profile.documents[0].files.url);
      data.profile.documents[1] && setPan(data.profile.documents[1].files.url);
      data.profile.documents[2] &&
        setAadhar(data.profile.documents[2].files.url);
      data.profile.documents[3] &&
        setPassbook(data.profile.documents[3].files.url);

      if (data.profile.documents[0] && data.profile.documents[0].files.url)
        setUpText1(<div style={{ color: "green" }}>License Uploaded</div>);
      if (data.profile.documents[1] && data.profile.documents[1].files.url)
        setUpText2(<div style={{ color: "green" }}>PAN Card Uploaded</div>);
      if (data.profile.documents[2] && data.profile.documents[2].files.url)
        setUpText3(<div style={{ color: "green" }}>Aadhar Card Uploaded</div>);
      if (data.profile.documents[3] && data.profile.documents[3].files.url)
        setUpText4(<div style={{ color: "green" }}>Passbook Uploaded</div>);

      setEmpId(data.profile.employeeId);
      form.setFieldsValue({
        phone: data.phone,
        phone2: data.phone2,
        status: data.status,
        profile: {
          employeeName: data.profile.employeeName,
          bloodGroup: data.profile.bloodGroup
            ? data.profile.bloodGroup
            : undefined,
          fatherName: data.profile.fatherName,
          dateOfBirth: data.profile.dateOfBirth
            ? data.profile.dateOfBirth
            : undefined,
          dateOfJoining: new moment(data.profile.dateOfJoining),
          branch: {
            name: data.profile.branch && data.profile.branch.map((c) => c.id),
          },
          department: {
            role: data.profile.department && data.profile.department.id,
          },
          bankDetails: {
            name: data.profile.bankDetails && data.profile.bankDetails.name,
            accountName:
              data.profile.bankDetails && data.profile.bankDetails.accountName,
            accountNumber:
              data.profile.bankDetails &&
              data.profile.bankDetails.accountNumber,
            ifsc: data.profile.bankDetails && data.profile.bankDetails.ifsc,
          },
          address: data.profile.address
            ? {
                // street: data.profile.address.street,
                line1: data.profile.address.line1,
                line2: data.profile.address.line2,
                line3: data.profile.address.line3,
                district: data.profile.address.district.id,
                locality: data.profile.address.locality,
                pincode: data.profile.address.pincode,
                country: data.profile.address.country.id,
                state: data.profile.address.state.id,
              }
            : null,
          documents: [
            {
              typeValue:
                data.profile.documents[0] &&
                data.profile.documents[0].typeValue,
            },
            {
              typeValue:
                data.profile.documents[1] &&
                data.profile.documents[1].typeValue,
            },
            {
              typeValue:
                data.profile.documents[2] &&
                data.profile.documents[2].typeValue,
            },
          ],
        },
      });
    }
  }, [modalData, editable]);

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const dateChange = (e) => {
    if (e) {
      const date = new Date(e._d);
      setDate(date.toLocaleString());
    }
  };

  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const clearFields = () => {
    form.resetFields();
    setFormData(null);
    setDp("");
    setstate([]);
    setcity([]);
    setbooleanstate(true);
    setbooleancity(true);
    setModalData(null);
    imageUrlChange(false);
    setconfirmLoading(false);
    setError({});
    setAadhar(null);
    setPan(null);
    setPassbook(null);
    setLicense(null);
    setAadharFile([]);
    setPanFile([]);
    setPassbookFile([]);
    setLicenseFile([]);
    setEmpId("");
    setUpText1("No documents Uploaded");
    setUpText2("No documents Uploaded");
    setUpText3("No documents Uploaded");
    setUpText4("No documents Uploaded");
    setRemoveLogo(false);
  };

  const handleOk = () => {
    form.validateFields((validationError) => {
      if (
        validationError === null &&
        !error.DL &&
        !error.PAN &&
        !error.AADHAAR &&
        !error.NAME &&
        !error.FNAME &&
        !error.PIN &&
        !error.IFSC
      ) {
        // console.log("OnSubmit")
        setconfirmLoading(true);
        const finalData = form.getFieldsValue();
        if (newEmployee) {
          finalData.newEmployee = newEmployee;
        }
        let obj = {
          type: "passbook",
        };
        console.log("finalData", finalData);
        const dob = finalData.profile.dateOfBirth;
        finalData.profile.dateOfBirth = dob.format("DD-MM-YYYY");
        finalData.profile.dateOfJoining = finalData.profile.dateOfJoining.format(
          "DD-MM-YYYY"
        );
        if (removeLogo) {
          finalData.profilePicture = null;
        } else {
          finalData.profilePicture = dp;
        }
        finalData.profile.documents[0].type = "license";
        finalData.profile.documents[1].type = "pan";
        finalData.profile.documents[2].type = "aadhar";
        finalData.profile.documents.push(obj);
        finalData.profile.documents[0].files = {
          name: "license",
          url: license,
        };
        finalData.profile.documents[1].files = { name: "pan", url: pan };
        finalData.profile.documents[2].files = { name: "aadhar", url: aadhar };
        finalData.profile.documents[3].files = {
          name: "passbook",
          url: passbook,
        };

        if (modalData) {
          finalData.profile.documents[0].id = modalData.profile.documents[0].id;
          finalData.profile.documents[1].id = modalData.profile.documents[1].id;
          finalData.profile.documents[2].id = modalData.profile.documents[2].id;
          finalData.profile.documents[3].id = modalData.profile.documents[3].id;
        }
        finalData.profile.department = finalData.profile.department.role;
        finalData.profile.branch = finalData.profile.branch.name;
        if (!modalData) {
          finalData.password = finalData.phone;
        }

        finalData.employeeId = empId;
        formData.append("module", "Employee");
        formData.append("id", empId);
        formData.append("master", "Company Master");
        formData.append("type", "EmployeeModules");
        if (!modalData) {
          platformApi.post("/api/idGenerate/employee").then((res) => {
            let { data } = res;
            if (data.code === 200) {
              let { response } = data;
              finalData.employeeId = response.data;
              formData.append("id", response.data);
              formData.delete("finalData");
              formData.append("finalData", JSON.stringify(finalData));
              platformApi
                .post("/api/user/register", formData, {
                  headers: { "Content-Type": "multipart/form-data" },
                })
                .then((res) => {
                  setconfirmLoading(false);
                  if (res.data.code === 200) {
                    const obj = res.data.data;
                    message.success("Employee Added Successfully");
                    setVisible(false);
                    setdataSource([...dataSource, obj.createUser]);
                    setCount(count + 1);
                    setModalData(null);
                    clearFields();
                  } else if (res.data.code === 501) {
                    setconfirmLoading(false);
                    message.error(
                      "Alternate Number Associated to Another User"
                    );
                  } else {
                    setconfirmLoading(false);
                    console.log(JSON.stringify(res.data));
                    if (
                      res.data.err &&
                      res.data.err.message &&
                      res.data.err.code === 502
                    ) {
                      message.error(res.data.err.message);
                      setVisible(false);
                      const obj = res.data.err.usr;
                      setdataSource([...dataSource, obj.createUser]);
                      setCount(count + 1);
                      setModalData(null);
                      clearFields();
                    } else {
                      message.error("Employee Is Not Added");
                    }
                  }
                })
                .catch((err) => {
                  console.error("User register error :", err);
                  setconfirmLoading(false);
                });
            } else {
              setconfirmLoading(false);
              message.error("Couldn't fetch id to create employee", 2);
            }
          });
        } else {
          let formdata = new FormData();
          formdata.append("finalData", JSON.stringify(finalData));
          platformApi
            .put(`/api/user/updateUser/${modalData.id}`, formdata, {
              headers: { "Content-Type": "multipart/form-data" },
              body: finalData,
            })
            .then((res) => {
              setconfirmLoading(false);
              if (res.data.code === 200) {
                setVisible(false);
                message.success("Employee Saved Successfully");
                const index = _.findIndex(dataSource, {
                  id: modalData.id,
                });
                setdataSource([]);
                const data = dataSource;
                data[index] = res.data.data;
                setModalData(null);
                setdataSource(data);
                clearFields();
              } else if (res.data.code === 501) {
                message.error("Number Associated to Another User");
              } else {
                message.error("Employee Not Saved");
              }
            })
            .catch((err) => {
              message.error("Employee Not Saved");
              console.error("User Update Error :", err);
              setconfirmLoading(false);
            });
        }
      } else {
        message.error("Enter All Input Fields correctly");
      }
    });
  };

  const handleChanger = (info, type) => {
    let fileLoader = fileLoading;
    if (info.file.status === "uploading") {
      if (type === "dp") {
        setLoading(true);
      } else if (type === "aadhar") {
        setAadharFile([info.file]);
        fileLoader[0] = true;

        setFileLoading(fileLoader);
      } else if (type === "pan") {
        setPanFile([info.file]);
        fileLoader[1] = true;
        setFileLoading(fileLoader);
      } else if (type === "license") {
        setLicenseFile([info.file]);
        fileLoader[2] = true;
        setFileLoading(fileLoader);
      } else if (type === "pass") {
        setPassbookFile([info.file]);
        fileLoader[3] = true;
        setFileLoading(fileLoader);
      }
    } else if (info.file.status === "done") {
      if (type === "dp") {
        formData.append("profilePicture", info.file.originFileObj);
        setLoading(false);
        // formData.append("module", "Employee");
        // formData.append("id", empId);
        // formData.append("master", "Company Master");
        // formData.append("type", "DP");
        getBase64(info.file.originFileObj, (imageUrl) => {
          imageUrlChange(imageUrl);
          setRemoveLogo(false);
          setDp(imageUrl);
        });
      } else if (type === "aadhar") {
        setAadharFile([info.file]);
        formData.append("aadhar", info.file.originFileObj);
        fileLoader[0] = false;
        setFileLoading(fileLoader);
        setUpText3(<div style={{ color: "green" }}>Aadhar Card Uploaded</div>);
        getBase64(info.file.originFileObj, (aadharUrl) => {
          setAadhar(aadharUrl);
        });
      } else if (type === "pan") {
        setPanFile([info.file]);
        formData.append("panCard", info.file.originFileObj);
        fileLoader[1] = false;
        setFileLoading(fileLoader);
        setUpText2(<div style={{ color: "green" }}>PAN Card Uploaded</div>);
        getBase64(info.file.originFileObj, (panUrl) => {
          setPan(panUrl);
        });
      } else if (type === "license") {
        setLicenseFile([info.file]);
        formData.append("license", info.file.originFileObj);
        fileLoader[2] = false;
        setFileLoading(fileLoader);
        setUpText1(<div style={{ color: "green" }}>License Uploaded</div>);
        getBase64(info.file.originFileObj, (licenseUrl) => {
          setLicense(licenseUrl);
        });
      } else if (type === "pass") {
        setPassbookFile([info.file]);
        formData.append("passBook", info.file.originFileObj);
        fileLoader[3] = false;
        setFileLoading(fileLoader);
        setUpText4(<div style={{ color: "green" }}>Passbook Uploaded</div>);
        getBase64(info.file.originFileObj, (passbookUrl) => {
          setPassbook(passbookUrl);
        });
      }
    }
  };

  const selectParams = (e, type) => {
    if (type === "country") {
      platformApi
        .post("/api/csc/states", { id: e })
        .then((res) => {
          setstate(res.data.data);
          setbooleanstate(false);
        })
        .catch((err) => {
          message.error("Couldn't fetch States");
          console.error("State fetch error :", err);
        });
    }
    if (type === "state") {
      form.setFieldsValue({
        profile: {
          address: {
            district: undefined,
          },
        },
      });
      platformApi
        .post("/api/csc/cities", { id: e })
        .then((res) => {
          setcity(res.data.data);
          setbooleancity(false);
        })
        .catch((err) => {
          message.error("Couldn't fetch Cities");
          console.error("Cities fetch error :", err);
        });
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const beforeDocUpload = (file) => {
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Document must smaller than 10MB!");
    }
    return isLt2M;
  };
  const { getFieldDecorator } = form;
  return (
    <Modal
      title={<Title level={4}>Employee</Title>}
      visible={visible}
      onCancel={() => {
        setVisible(false);
        clearFields();
        imageUrlChange(false);
        setModalData(null);
      }}
      wrapClassName="employee-form"
      width="70%"
      footer={[
        <Button
          key="back"
          onClick={() => {
            setVisible(false);
            clearFields();
            imageUrlChange(false);
            setModalData(null);
          }}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={confirmLoading}
          disabled={
            loading ||
            fileLoading[0] ||
            fileLoading[1] ||
            fileLoading[2] ||
            fileLoading[3]
          }
          onClick={handleOk}
        >
          Save
        </Button>,
      ]}
    >
      <div>
        <div className="dp-upload" />
        {/* <div> */}
        <Form className="login-form">
          {modalData ? (
            <Row>
              <Form.Item>
                <Text strong>Employee ID:</Text>
                {empId}
              </Form.Item>
            </Row>
          ) : (
            <span />
          )}
          <Text strong>Employee Details</Text>
          <Row type="flex" justify="space-between">
            <Col span={18}>
              <Row type="flex" justify="space-between">
                <Col span={7}>
                  <Form.Item
                    validateStatus={error.NAME && error.NAME.type}
                    help={error.NAME && error.NAME.message}
                    required
                    colon={false}
                    label={<Text>Name</Text>}
                  >
                    {getFieldDecorator("profile.employeeName", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Employee Name!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Name"
                        onKeyUp={(e) =>
                          form.setFieldsValue({
                            profile: {
                              employeeName: formatValue(e, "allCaps"),
                            },
                          })
                        }
                        pattern="^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              NAME: {
                                type: "error",
                                message: "Enter Valid Name",
                              },
                            });
                          } else {
                            delete error.NAME;
                            setError(error);
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    validateStatus={error.FNAME && error.FNAME.type}
                    help={error.FNAME && error.FNAME.message}
                    colon={false}
                    required
                    label={<Text>Father's Name</Text>}
                  >
                    {getFieldDecorator("profile.fatherName", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Father Name!",
                        },
                      ],
                    })(
                      <Input
                        placeholder="Father's Name"
                        onKeyUp={(e) =>
                          form.setFieldsValue({
                            profile: { fatherName: formatValue(e, "allCaps") },
                          })
                        }
                        pattern="^[A-Z][a-zA-Z\s]*[a-zA-Z]+$"
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              FNAME: {
                                type: "error",
                                message: "Enter Valid Name",
                              },
                            });
                          } else {
                            delete error.FNAME;
                            setError(error);
                          }
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item colon={false} required label={<Text>DOB</Text>}>
                    {getFieldDecorator("profile.dateOfBirth", {
                      rules: [{ required: true, message: "Enter DOB!" }],
                    })(
                      <DatePicker
                        size={date}
                        format="DD/MM/YYYY"
                        allowClear
                        onChange={(e) => dateChange(e)}
                        disabledDate={(moment) => Date.now() < moment._d}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row type="flex" justify="space-between">
                <Col span={7}>
                  <Form.Item
                    validateStatus={error.PNO && error.PNO.type}
                    help={error.PNO && error.PNO.message}
                    colon={false}
                    required
                    label={<Text>Official Phone Number</Text>}
                  >
                    {getFieldDecorator("phone", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Mobile Number!",
                        },
                      ],
                    })(
                      <Input
                        addonBefore="+91"
                        pattern="^[0-9]{10}$"
                        onKeyUp={(e) => {
                          form.setFieldsValue({
                            phone: formatValue(e, "onlyNo"),
                          });
                          if (form.getFieldsValue().phone.length === 0) {
                            delete error.PNO;
                          }
                        }}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              PNO: {
                                type: "error",
                                message: "Enter Valid Phone Number",
                              },
                            });
                          } else {
                            delete error.PNO;
                            setError(error);
                          }
                        }}
                        maxLength={10}
                        placeholder="Official Number"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    validateStatus={error.PNO2 && error.PNO2.type}
                    help={error.PNO2 && error.PNO2.message}
                    colon={false}
                    label={<Text>Alternate Number</Text>}
                  >
                    {getFieldDecorator("phone2", {
                      rules: [
                        {
                          required: true,
                          message: "Enter Mobile Number!",
                        },
                      ],
                    })(
                      <Input
                        addonBefore="+91"
                        pattern="^[0-9]{10}$"
                        onKeyUp={(e) => {
                          form.setFieldsValue({
                            phone2: formatValue(e, "onlyNo"),
                          });
                          if (form.getFieldsValue().phone2.length === 0) {
                            delete error.PNO2;
                          }
                        }}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              PNO2: {
                                type: "error",
                                message: "Enter Valid Phone Number",
                              },
                            });
                          } else {
                            delete error.PNO2;
                            setError(error);
                          }
                        }}
                        maxLength={10}
                        placeholder="Alternate Number"
                      />
                    )}
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item
                    colon={false}
                    required
                    label={<Text>Blood Group</Text>}
                  >
                    {getFieldDecorator("profile.bloodGroup", {
                      rules: [
                        {
                          required: true,
                          message: "Select your Blood Group!",
                        },
                      ],
                    })(
                      <Select
                        showAction={["click", "focus"]}
                        placeholder="Blood Group"
                      >
                        {bloodGroups &&
                          bloodGroups.map((group) => (
                            <Select.Option key={group}>{group}</Select.Option>
                          ))}
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col span={5}>
              <Upload
                name="avatar"
                listType="picture"
                showUploadList={false}
                action={`${urls}api/upload/mocky`}
                beforeUpload={(e) => beforeUpload(e)}
                onChange={(e) => handleChanger(e, "dp")}
              >
                <div style={{ display: "flex" }}>
                  {loading && (
                    <Spin
                      style={{
                        position: "absolute",
                        alignSelf: "center",
                        left: "25%",
                        zIndex: "2",
                      }}
                      size="large"
                      indicator={<Icon type="loading" />}
                    />
                  )}
                  <Avatar
                    shape="round"
                    style={{ cursor: "pointer" }}
                    size={128}
                    icon={imageUrl ? "" : "user"}
                    src={imageUrl}
                  />
                </div>
              </Upload>

              {modalData && imageUrl && imageUrl.length > 0 ? (
                <Popconfirm
                  title="Do you want to delete this Employee Avatar?"
                  okText="Yes"
                  cancelText="No"
                  onCancel={(event) => {
                    event.stopPropagation();
                  }}
                  onConfirm={(event) => {
                    event.stopPropagation();
                    setRemoveLogo(true);
                    imageUrlChange(null);
                  }}
                >
                  <Button type="danger" style={{ marginTop: ".5rem" }}>
                    <Icon type="delete" />
                  </Button>
                </Popconfirm>
              ) : null}
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={5}>
              <Form.Item colon={false} required label={<Text>Department</Text>}>
                {getFieldDecorator("profile.department.role", {
                  rules: [
                    {
                      required: true,
                      message: "Select a department",
                    },
                  ],
                })(
                  <Select
                    disabled={dept !== undefined}
                    placeholder="Select Department"
                    filterOption={filterMethod}
                    showSearch
                    showAction={["click", "focus"]}
                    style={{ width: "100%" }}
                  >
                    {department &&
                      department.map((d) => (
                        <Option key={d.id} value={d.id}>
                          {d.role}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item colon={false} required label={<Text>Branch</Text>}>
                {getFieldDecorator("profile.branch.name", {
                  rules: [
                    {
                      required: true,
                      message: "Select a branch",
                    },
                  ],
                })(
                  <Select
                    placeholder="Select Branch"
                    filterOption={filterMethod}
                    showSearch
                    showAction={["click", "focus"]}
                    style={{ width: "100%" }}
                    mode="multiple"
                  >
                    {branch &&
                      branch.map((d) => (
                        <Option key={d.id} value={d.id}>
                          {d.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item
                validateStatus={error.AADHAAR && error.AADHAAR.type}
                help={error.AADHAAR && error.AADHAAR.message}
                colon={false}
                required={false}
                label={<Text>Aadhaar Number</Text>}
              >
                {getFieldDecorator("profile.documents[2].typeValue", {
                  rules: [
                    {
                      required: false,
                      message: "Enter Aadhaar Number!",
                    },
                  ],
                })(
                  <Input
                    maxLength={12}
                    pattern="^[0-9]{12}$"
                    placeholder="Aadhaar Number"
                    onKeyUp={(e) => {
                      form.setFieldsValue({
                        "profile.documents[2].typeValue": formatValue(
                          e,
                          "onlyNo"
                        ),
                      });
                      if (
                        form.getFieldsValue().profile.documents[2].typeValue
                          .length === 0
                      ) {
                        delete error.AADHAAR;
                      }
                    }}
                    onInput={(event) => {
                      if (
                        !event.target.checkValidity() &&
                        form.getFieldsValue().profile.documents[2].typeValue
                          .length > 0
                      ) {
                        setError({
                          ...error,
                          AADHAAR: {
                            type: "error",
                            message: "Enter Valid Aadhaar Number",
                          },
                        });
                      } else {
                        delete error.AADHAAR;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                validateStatus={error.PAN && error.PAN.type}
                help={error.PAN && error.PAN.message}
                colon={false}
                required={false}
                label={<Text>PAN Number</Text>}
              >
                {getFieldDecorator("profile.documents[1].typeValue", {
                  rules: [
                    {
                      required: false,
                      message: "Enter PAN Number!",
                    },
                  ],
                })(
                  <Input
                    style={{ textTransform: "uppercase" }}
                    maxLength={10}
                    pattern="^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}$"
                    placeholder="PAN Number"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          PAN: {
                            type: "error",
                            message: "Enter Valid PAN Number",
                          },
                        });
                      } else {
                        delete error.PAN;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={5}>
              <Form.Item
                validateStatus={error.DL && error.DL.type}
                help={error.DL && error.DL.message}
                colon={false}
                required={false}
                label={<Text>Driving License</Text>}
              >
                {getFieldDecorator("profile.documents[0].typeValue", {
                  rules: [
                    {
                      required: false,
                      message: "Enter Driving License!",
                    },
                  ],
                })(
                  <Input
                    style={{ textTransform: "uppercase" }}
                    // maxLength={17}
                    placeholder="Driving License"
                    // pattern="^[a-zA-Z]{2}[0-9]{11}$"
                    // onInput={(event) => {
                    //   if (!event.target.checkValidity()) {
                    //     setError({
                    //       ...error,
                    //       DL: {
                    //         type: "error",
                    //         message: "Enter Valid License Number",
                    //       },
                    //     });
                    //   } else {
                    //     delete error.DL;
                    //     setError(error);
                    //   }
                    // }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item colon={false} label={<Text>Date of Joining</Text>}>
                {getFieldDecorator("profile.dateOfJoining", {
                  rules: [
                    {
                      required: true,
                      message: "Select a Date of Joining",
                    },
                  ],
                  initialValue: new moment(),
                })(
                  <DatePicker
                    format="DD/MM/YYYY"
                    placeholder="Date of Joining"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item colon={false} label={<Text>Status</Text>}>
                {getFieldDecorator("status", {
                  rules: [
                    {
                      required: true,
                    },
                  ],
                  initialValue: true,
                })(
                  <Select
                    showAction={["click", "focus"]}
                    placeholder="Status"
                    onChange={(value) => {
                      if (!value) {
                        setReassign(true);
                        setAssign(true);
                        setAssignData(modalData.id);
                      }
                    }}
                  >
                    <Select.Option value>Active</Select.Option>
                    <Select.Option value={false}>Inactive</Select.Option>
                  </Select>
                )}
              </Form.Item>
              {/* {reAssign &&
                <Popconfirm
                  title="Do you want to deactivate this Employee ?"
                  okText="Yes"
                  cancelText="No"
                  onCancel={(event) => {
                    event.stopPropagation();
                  }}
                  onConfirm={(event) => {
                    event.stopPropagation();
                  }}
                >

                </Popconfirm>
              } */}
            </Col>
            <Col span={5} />
            <Col span={5} />
          </Row>
          <Text strong>Address Details</Text>
          <Row type="flex" justify="space-between">
            <Col span={7}>
              <Form.Item
                validateStatus={error.LINE1 && error.LINE1.type}
                help={error.LINE1 && error.LINE1.message}
                colon={false}
                required
                label={<Text>Adderess Line 1</Text>}
              >
                {getFieldDecorator("profile.address.line1", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Address Line 1!",
                    },
                  ],
                })(
                  <Input
                    pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        profile: {
                          address: {
                            line1: formatValue(e, "allCaps"),
                          },
                        },
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          LINE1: {
                            type: "error",
                            message: "Enter Valid Address Line 1",
                          },
                        });
                      } else {
                        delete error.LINE1;
                        setError(error);
                      }
                    }}
                    maxLength={50}
                    placeholder="Line 1"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                validateStatus={error.LINE2 && error.LINE2.type}
                help={error.LINE2 && error.LINE2.message}
                colon={false}
                // required
                label={<Text>Address Line 2</Text>}
              >
                {getFieldDecorator("profile.address.line2", {
                  rules: [
                    {
                      required: false,
                      message: "Enter Address Line 2!",
                    },
                  ],
                })(
                  <Input
                    pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        profile: {
                          address: {
                            line2: formatValue(e, "allCaps"),
                          },
                        },
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          LINE2: {
                            type: "error",
                            message: "Enter Valid Address Line 2",
                          },
                        });
                      } else {
                        delete error.LINE2;
                        setError(error);
                      }
                    }}
                    maxLength={50}
                    placeholder="Line 2"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                validateStatus={error.LINE3 && error.LINE3.type}
                help={error.LINE3 && error.LINE3.message}
                colon={false}
                // required
                label={<Text>Address Line 3</Text>}
              >
                {getFieldDecorator("profile.address.line3", {
                  rules: [
                    {
                      required: false,
                      message: "Enter Address Line 3!",
                    },
                  ],
                })(
                  <Input
                    pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        profile: {
                          address: {
                            line3: formatValue(e, "allCaps"),
                          },
                        },
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          LINE3: {
                            type: "error",
                            message: "Enter Valid Address Line 3",
                          },
                        });
                      } else {
                        delete error.LINE3;
                        setError(error);
                      }
                    }}
                    maxLength={50}
                    placeholder="Line 3"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={7}>
              <Form.Item
                validateStatus={error.LOCALITY && error.LOCALITY.type}
                help={error.LOCALITY && error.LOCALITY.message}
                required
                colon={false}
                label={<Text>Locality</Text>}
              >
                {getFieldDecorator("profile.address.locality", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Locality!",
                    },
                  ],
                })(
                  <Input
                    pattern="^([a-zA-Z0-9-/,]+[ -/,])*\w+.?$"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        profile: {
                          address: {
                            locality: formatValue(e, "allCaps"),
                          },
                        },
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          LOCALITY: {
                            type: "error",
                            message: "Enter Valid Locality",
                          },
                        });
                      } else {
                        delete error.LOCALITY;
                        setError(error);
                      }
                    }}
                    maxLength={50}
                    placeholder="Locality"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item required colon={false} label={<Text>Country</Text>}>
                {getFieldDecorator("profile.address.country", {
                  rules: [{ required: true, message: "Select Country!" }],
                })(
                  <Select
                    placeholder="Select Country"
                    filterOption={filterMethod}
                    onSelect={(e) => selectParams(e, "country")}
                    showSearch
                    showAction={["click", "focus"]}
                    style={{ width: "100%" }}
                  >
                    {country &&
                      country.map((d) => (
                        <Option key={d.id} value={d.id}>
                          {d.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>

            <Col span={7}>
              <FormItem required colon={false} label={<Text>State</Text>}>
                {getFieldDecorator("profile.address.state", {
                  rules: [{ required: true, message: "Select State!" }],
                })(
                  <Select
                    disabled={booleanstate}
                    showSearch
                    showAction={["click", "focus"]}
                    placeholder="Select State"
                    filterOption={filterMethod}
                    onSelect={(e) => selectParams(e, "state")}
                    style={{ width: "100%" }}
                  >
                    {state &&
                      state.map((d) => (
                        <Option key={d.id} value={d.id}>
                          {d.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={7}>
              <Form.Item required colon={false} label={<Text>City</Text>}>
                {getFieldDecorator("profile.address.district", {
                  rules: [{ required: true, message: "Select City!" }],
                })(
                  <Select
                    showAction={["click", "focus"]}
                    disabled={booleancity}
                    placeholder="Select City"
                    filterOption={filterMethod}
                    showSearch
                    style={{ width: "100%" }}
                  >
                    {city &&
                      city.map((d) => (
                        <Option key={d.id} value={d.id}>
                          {d.name}
                        </Option>
                      ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                validateStatus={error.PIN && error.PIN.type}
                help={error.PIN && error.PIN.message}
                colon={false}
                required
                label={<Text>Pincode</Text>}
              >
                {getFieldDecorator("profile.address.pincode", {
                  rules: [{ required: true, message: "Enter Pin code!" }],
                })(
                  <Input
                    placeholder="Pincode / Zipcode"
                    maxLength={7}
                    pattern="([0-9]{6}|[0-9]{3}\s[0-9]{3})"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        "profile.address.pincode": formatValue(e, "onlyNo"),
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          PIN: {
                            type: "error",
                            message: "Enter Valid PIN Code",
                          },
                        });
                      } else {
                        delete error.PIN;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Col>

            {/* <Col span={5} /> */}
            <Col span={7} />
          </Row>
          <Text strong>Account Details</Text>
          <Row type="flex" justify="space-between">
            <Col span={5}>
              <Form.Item
                validateStatus={error.BANK && error.BANK.type}
                help={error.BANK && error.BANK.message}
                required
                colon={false}
                label={<Text>Bank</Text>}
              >
                {getFieldDecorator("profile.bankDetails.name", {
                  rules: [{ required: true, message: "Enter Bank!" }],
                })(
                  <Input
                    pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        profile: {
                          bankDetails: {
                            name: e.target.value,
                          },
                        },
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          BANK: {
                            type: "error",
                            message: "Enter Valid Bank",
                          },
                        });
                      } else {
                        delete error.BANK;
                        setError(error);
                      }
                    }}
                    placeholder="Bank"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                validateStatus={error.HOLDER && error.HOLDER.type}
                help={error.HOLDER && error.HOLDER.message}
                required
                colon={false}
                label={<Text>Account Holder</Text>}
              >
                {getFieldDecorator("profile.bankDetails.accountName", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Account Holder!",
                    },
                  ],
                })(
                  <Input
                    pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                    onKeyUp={(e) =>
                      form.setFieldsValue({
                        profile: {
                          bankDetails: {
                            accountName: formatValue(e, "allCaps"),
                          },
                        },
                      })
                    }
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          HOLDER: {
                            type: "error",
                            message: "Enter Valid Account Holder",
                          },
                        });
                      } else {
                        delete error.HOLDER;
                        setError(error);
                      }
                    }}
                    placeholder="Account Holder"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                validateStatus={error.AN && error.AN.type}
                help={error.AN && error.AN.message}
                colon={false}
                required
                label={<Text>Account Number</Text>}
              >
                {getFieldDecorator("profile.bankDetails.accountNumber", {
                  rules: [
                    {
                      required: true,
                      message: "Enter Account Number!",
                    },
                  ],
                })(
                  <Input
                    onWheel={(event) => event.currentTarget.blur()}
                    placeholder="Account Number"
                    pattern="^[0-9]{9,18}$"
                    // pattern="^[A-Z|a-z]{4}[0][A-Z|a-z|0-9]{6}$"
                    onKeyUp={(e) => {
                      form.setFieldsValue({
                        "profile.bankDetails.accountNumber": formatValue(
                          e,
                          "onlyNo"
                        ),
                      });
                      if (
                        form.getFieldsValue().profile.bankDetails.accountNumber
                          .length === 0
                      ) {
                        delete error.AN;
                      }
                    }}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          AN: {
                            type: "error",
                            message: "Enter Valid Account Number",
                          },
                        });
                      } else {
                        delete error.AN;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                validateStatus={error.IFSC && error.IFSC.type}
                help={error.IFSC && error.IFSC.message}
                colon={false}
                required
                label={<Text>IFSC Code</Text>}
              >
                {getFieldDecorator("profile.bankDetails.ifsc", {
                  rules: [
                    {
                      required: true,
                      message: "Enter IFSC Code!",
                    },
                  ],
                })(
                  <Input
                    style={{ textTransform: "uppercase" }}
                    maxLength={11}
                    minLength={11}
                    pattern="^[A-Z|a-z|0-9]{11}$"
                    placeholder="IFSC Code"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          IFSC: {
                            type: "error",
                            message: "Enter Valid IFSC Code",
                          },
                        });
                      } else {
                        delete error.IFSC;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <br />
          <Text strong>Employee Documents</Text>
          <Row type="flex" justify="space-between">
            <Col span={5}>
              <Form.Item colon={false} required={false}>
                <Upload
                  fileList={aadharFile}
                  beforeUpload={(e) => beforeDocUpload(e)}
                  onChange={(e) => handleChanger(e, "aadhar")}
                  action={`${urls}api/upload/mocky`}
                  onRemove={() => {
                    setAadharFile([]), setAadhar(null);
                    setUpText3("No documents Uploaded");
                    formData.delete("aadhar");
                  }}
                >
                  <Button>
                    <Icon type="upload" /> <Text>Aadhaar Card</Text>
                  </Button>
                </Upload>
                {upText3}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                <Upload
                  fileList={panFile}
                  beforeUpload={(e) => beforeDocUpload(e)}
                  onChange={(e) => handleChanger(e, "pan")}
                  action={`${urls}api/upload/mocky`}
                  onRemove={() => {
                    setPanFile([]), setPan(null);
                    setUpText2("No documents Uploaded");
                    formData.delete("panCard");
                  }}
                >
                  <Button>
                    <Icon type="upload" /> <Text>Pan Card</Text>
                  </Button>
                </Upload>
                {upText2}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                <Upload
                  fileList={licenseFile}
                  beforeUpload={(e) => beforeDocUpload(e)}
                  onChange={(e) => handleChanger(e, "license")}
                  action={`${urls}api/upload/mocky`}
                  onRemove={() => {
                    setLicenseFile([]), setLicense(null);
                    setUpText1("No documents Uploaded");
                    formData.delete("license");
                  }}
                >
                  <Button>
                    <Icon type="upload" /> <Text>Driving License</Text>
                  </Button>
                </Upload>
                {upText1}
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item>
                <Upload
                  fileList={passbookFile}
                  beforeUpload={(e) => beforeDocUpload(e)}
                  onChange={(e) => handleChanger(e, "pass")}
                  action={`${urls}api/upload/mocky`}
                  onRemove={() => {
                    setPassbookFile([]), setPassbook(null);
                    setUpText4("No documents Uploaded");
                    formData.delete("passBook");
                  }}
                >
                  <Button>
                    <Icon type="upload" />
                    <Text>Pass Book</Text>
                  </Button>
                </Upload>
                {upText4}
              </Form.Item>
            </Col>
          </Row>
        </Form>
        {/* </div> */}
      </div>
    </Modal>
  );
};

const WrappedForm = Form.create({ name: "EmployeeForm" })(EmployeeForms);
export default WrappedForm;
