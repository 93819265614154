import React, { useEffect, useState } from 'react';
import { Form, Modal, Row, Typography, TimePicker, DatePicker, Col, Input, Select } from 'antd';
import { Button } from 'antd';
import Moment from 'moment'
import './style.less'
import { platformApi } from '../../../../api'
import { message } from 'antd';
import { withRouter } from 'react-router-dom'

import DiscardModal from '../../DiscardModal';
const { Item } = Form;
const { Text, Title } = Typography;
const { TextArea } = Input
const { Option } = Select
const MessageInterface = (props) => {
  const { visibility, setVisibility, getCustomerInfo, getActivityByCustomer, customerDetail, form, type } = props
  const [templates, setTemplates] = useState([]);
  // const [canCustomise, setCustomise] = useState(false)
  const [phoneNo, setPhoneNo] = useState(null)
  const [templateId, setTemplateId] = useState(0)
  const [templateText, setTemplateText] = useState("")
  // const [tempTemplateText, setTempTemplateText] = useState("")
  const [discardModal, setDiscardModal] = useState(false);
  const [date, setDate] = useState(null)
  const [time, setTime] = useState(null)
  const [enquiryType, setEnquiryType] = useState(null)
  const enquiryTypes = ["Hot", "Warm", "Cold"];
  const {
    getFieldDecorator,
    resetFields,
    getFieldValue,
    setFieldsValue,
  } = form;



  const close = () => {
    setDate(null)
    setTime(null)
    setTemplateText("")
    setVisibility(false)
  }

  const getDefaultPhone = () => {
    const primaryContact = customerDetail.contacts.filter(contact => contact.type === 'Primary')
    if (primaryContact.length) {
      setPhoneNo(primaryContact[0].phone)
    }
  }

  const settingUpForDataSMS = () => {
    const smsData = {
      phoneNo: phoneNo,
      enquiryType: enquiryType,
      customerId: customerDetail.id,
      template: templateText,
      followUpDate: date ? Moment(date).format('DD-MM-YYYY') : "",
      followUpTime: time ? Moment(time).format('HH:mm:ss a') : "",
    }
    if (type === 'serviceFollowup') {
      smsData.type = "serviceFollowup"
    }
    return smsData;
  }

  const includedTemplates = [];

  // useEffect(() => {
  //     const value = templates.filter(template => template.id === templateId)
  //     const cname = customerDetail.name || "-"
  //     const vname = currentQuotation.vehicle.length ? currentQuotation.vehicle[0].vehicleDetail.modelName : "-"
  //     const slex = currentQuotation.executive ? currentQuotation.executive.profile.employeeName : "-"
  //     const dlr = currentQuotation.branch ? currentQuotation.branch.name : "-"
  //     const link = "${link}"
  //     const bkid = "${bkid}"
  //     if (value.length)
  //     setTemplateText(eval("`"+value[0].text+"`"))
  // }, [templateId])

  useEffect(() => {
    if (visibility)
      platformApi.get('/api/sms/')
        .then(res => {
          const { data: { response: { data } } } = res
          setTemplates(data)
        })
    getDefaultPhone();
  }, [visibility])

  const disabledDate = (current) => {
    // Can not select days before today and today
    return current < Moment().startOf('day');
  }
  const getDisabledHours = () => {
    var hours = [];
    // console.log(new Date(), new Date(data.scheduleDate))
    if (new Date(date) < new Date()) {
      for (var i = 0; i < Moment().hour(); i++) {
        hours.push(i);
      }
    }
    return hours;
  };

  const getDisabledMinutes = (selectedHour) => {
    var minutes = [];
    if (new Date(date) < new Date()) {
      if (selectedHour === Moment().hour()) {
        for (var i = 0; i < Moment().minute(); i++) {
          minutes.push(i);
        }
      }
    }
    return minutes;
  };
  const handleEnquiryChange = (value) => {
    setEnquiryType(value)
  }
  const sendSMS = (channel) => {
    let smsData = settingUpForDataSMS();
    console.log("SmsData", smsData)
    smsData.channel = channel
    if (smsData.template.trim()) {
      platformApi.post('/api/sendsms/all', { smsData })
        .then(res => {
          const { data: { response: { data } } } = res
          message.success("Message sent")
          getActivityByCustomer()
          getCustomerInfo();
          close();
        }).catch(err => message.error(err.message))
    }
    else {
      message.error("Empty Message Is Not Allowed !!!")
    }
  }


  return (
    <Modal
      title={<Title level={4}>Session Message Interface</Title>}
      visible={visibility}
      onOk={() => sendSMS("SMS")}
      cancelText={"Cancel"}
      onCancel={() => {
        close();
      }}
      okText="Send"
      wrapClassName="messaging-modal"
    >
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        {/* <Col span={24}>
                    Quotation ID: {currentQuotation.quotationId||'-'}
                </Col> */}
        {/* <Col span={11}>
                    <Row>
                        <Col span={12}>
                            Select Template:
                        </Col>
                        <Col span={12}>
                            <Select
                                onChange={value=>setTemplateId(value)}
                            >
                                {
                                    templates.map(template => <Select.Option key={template.id}>{template.name}</Select.Option>)
                                }
                        </Select>
                        </Col>
                    </Row>
                </Col> */}
        <Col span={8}>
          <Row>
            <Col span={8}>
              <strong>Number: </strong>
            </Col>
            <Col span={16}>
              <Select
                onChange={phone => setPhoneNo(phone)}
                defaultValue={phoneNo || ""}
              >{
                  customerDetail && customerDetail.contacts.length && customerDetail.contacts.map(contact => {
                    if (!contact.DND)
                      return <Select.Option key={contact.phone}>{contact.phone}</Select.Option>
                  })
                }
              </Select>
            </Col>
          </Row>
        </Col>

        <Col>
          <strong>Followup Date & Time : </strong>
        </Col>
        <Col span={4}>
          <DatePicker
            onChange={Date => setDate(Date)}
            format="DD-MM-YYYY"
            value={date}
            disabledDate={disabledDate}
          />
        </Col>
        <Col span={5}>
          <TimePicker
            onChange={Time => setTime(Time)}
            format={'HH:mm'}
            value={time}
            disabledHours={getDisabledHours}
            disabledMinutes={getDisabledMinutes}
          />
        </Col>
        {
          type === 'serviceFollowup' ?
            null
            :
            < Col span={12}>
              <Row>
                <Col>
                  <Item label={<Text>Enquiry Type</Text>}>
                    {getFieldDecorator("enquiryType",)(
                      <Select
                        showAction={["click", "focus"]}
                        placeholder="Enquiry Type"
                        onChange={handleEnquiryChange}
                      >
                        {enquiryTypes.map((type) => (
                          <Select.Option value={type} key={type}>
                            {type}
                          </Select.Option>
                        ))}
                      </Select>
                    )}
                  </Item>
                </Col>
              </Row>
            </Col>
        }
        <Col span={24}>
          <strong>Message:</strong>
          <TextArea rows={4}
            onChange={event => setTemplateText(event.target.value)}
            value={templateText}
          // disabled={!canCustomise}
          >{templateText}</TextArea>
        </Col>
        {/* <Col span={24}>
          <Button onClick={() => customise()}>{canCustomise ? "Cancel" : "Customise"}</Button>
        </Col>
        <Col span={24}>
          <span className="linkylink" onClick={() => setDiscardModal(true)}>Discard Followup</span>
        </Col> */}
      </Row>
      {/* <DiscardModal
        visibility={discardModal}
        setVisibility={setDiscardModal}
        customerDetail={customerDetail}
      /> */}
    </Modal >
  )
}
const WrappedMessageInterface = Form.create({ name: "message_interface" })(
  MessageInterface
);

export default withRouter(WrappedMessageInterface);
// export default MessageInterface
