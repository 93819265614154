import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, message } from "antd";
import { platformApi } from "../../../../api";
const { Item } = Form;
const { Option } = Select;
function AdvancedFilter({
  form,
  filteropen,
  close,
  searchName,
  id,
  setId,
  setData,
  setPrimaryData,
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);
  const Filters = [
    { id: "1", name: "Name" },
    { id: "2", name: "Email" },
    { id: "3", name: "Locality" },
    { id: "4", name: "State" },
    { id: "5", name: "City" },
    { id: "6", name: "Pincode" },
  ];
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const selectFilter = (id) => {
    setId(id);
  };
  const searchValue = () => {
    setId([]);
    resetFields();
    setPrimaryData();
  };
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
  };
  const handleStateChange = (event, key) => {
    let id = key.key;
    platformApi
      .post("api/csc/cities", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setCity(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Manufaturer form : ", error);
        message.error("Unable to fetch cities", 2);
      });
  };
  useEffect(() => {
    if (filteropen) {
      platformApi
        .get("/api/csc/country")
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setCountry(data.data);
          }
        })
        .catch((error) => {
          console.error("Error on Manufaturer form : ", error);
          message.error("Unable to fetch country", 2);
        });
    }
    setFieldsValue({ selectfilter: id });
  }, [filteropen]);

  useEffect(() => {
    id.forEach((element) => {
      if (element === "1") setFieldsValue({ Name: searchName });
    });
  }, [id, searchName, filteropen]);
  useEffect(() => {
    if (id.includes("4")) {
      let id;
      country.forEach((element) => {
        if (element.name === "India") {
          id = element.id;
        }
      });
      platformApi
        .post("api/csc/states", { id })
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setState(data.data);
          }
        })
        .catch((error) => {
          console.error("Error on Manufaturer form : ", error);
          message.error("Unable to fetch state", 2);
        });
    }
  }, [id]);
  const handleSubmit = () => {
    const data = {
      name: getFieldValue("Name"),
      email: getFieldValue("email"),
      locality: getFieldValue("Locality"),
      state: getFieldValue("State"),
      city: getFieldValue("City"),
      pincode: getFieldValue("Pincode"),
    };
    platformApi
      .post("/api/manufacturer/search", data)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(response.data);
            message.success("Filtered successfully", 2);
          } else {
            message.error("Unable to fetch", 2);
          }
        } else {
          message.error("Unable to fetch", 2);
        }
      })
      .catch((error) => {
        console.error("Error in filtering : ", error);
        message.error("Unable to fetch", 2);
      });
  };

  const branchSelect = (id) => {
  };
  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <Row type="flex" justify="start">
                  {id.map((element) => {
                    switch (element) {
                      case "1":
                        return (
                          <Col span={8}>
                            <Item colon={false}>
                              <Row type="flex">
                                <Col span={20}>
                                  {getFieldDecorator("Name")(
                                    <Input placeholder="Name" />
                                  )}
                                </Col>
                                <Col span={2}>
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    size="small"
                                    onClick={() => deleteFilter(element)}
                                  >
                                    &#10005;
                                  </Button>
                                </Col>
                              </Row>
                            </Item>
                          </Col>
                        );
                        break;
                      case "2":
                        return (
                          <Col span={8}>
                            <Item colon={false}>
                              <Row type="flex">
                                <Col span={20}>
                                  {getFieldDecorator("email")(
                                    <Input placeholder="Email" />
                                  )}
                                </Col>
                                <Col span={2}>
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    size="small"
                                    onClick={() => deleteFilter(element)}
                                  >
                                    &#10005;
                                  </Button>
                                </Col>
                              </Row>
                            </Item>
                          </Col>
                        );
                        break;
                      case "3":
                        return (
                          <Col span={8}>
                            <Item colon={false}>
                              <Row type="flex">
                                <Col span={20}>
                                  {getFieldDecorator("Locality")(
                                    <Input placeholder="Locality" />
                                  )}
                                </Col>
                                <Col span={2}>
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    size="small"
                                    onClick={() => deleteFilter(element)}
                                  >
                                    &#10005;
                                  </Button>
                                </Col>
                              </Row>
                            </Item>
                          </Col>
                        );
                        break;
                      case "5":
                        if (id.includes("4")) {
                          deleteFilter("4");
                        }
                        return (
                          <Col span={16}>
                            <Col span={12}>
                              <Item colon={false}>
                                <Row type="flex">
                                  <Col span={20}>
                                    {getFieldDecorator("State")(
                                      <Select
                                      showAction={["click","focus"]}
                                        placeholder="State"
                                        showSearch
                                        onChange={(event, key) =>
                                          handleStateChange(event, key)
                                        }
                                        filterOption={filterMethod}
                                      >
                                        {state.map((state) => (
                                          <Select.Option
                                            value={state.id}
                                            key={state.id}
                                          >
                                            {state.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  </Col>
                                  <Col span={2}>
                                    <Button
                                      type="ghost"
                                      shape="circle"
                                      size="small"
                                      onClick={() => deleteFilter(element)}
                                    >
                                      &#10005;
                                    </Button>
                                  </Col>
                                </Row>
                              </Item>
                            </Col>
                            <Col span={12}>
                              <Item colon={false}>
                                <Row type="flex">
                                  <Col span={20}>
                                    {getFieldDecorator("City")(
                                      <Select
                                        placeholder="City"
                                        showSearch
                                        showAction={["click","focus"]}
                                        onChange={branchSelect}
                                        filterOption={filterMethod}
                                      >
                                        {city.map((city) => (
                                          <Select.Option
                                            value={city.id}
                                            key={city.id}
                                          >
                                            {city.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  </Col>
                                  <Col span={2}>
                                    <Button
                                      type="ghost"
                                      shape="circle"
                                      size="small"
                                      onClick={() => {
                                        let showfilter = id;
                                        showfilter.push("4");
                                        setId(showfilter);
                                        deleteFilter(element);
                                      }}
                                    >
                                      &#10005;
                                    </Button>
                                  </Col>
                                </Row>
                              </Item>
                            </Col>
                          </Col>
                        );
                      case "4":
                        if (!id.includes("5"))
                          return (
                            <Col span={8}>
                              <Item colon={false}>
                                <Row type="flex">
                                  <Col span={20}>
                                    {getFieldDecorator("State")(
                                      <Select
                                        placeholder="State"
                                        showSearch
                                        showAction={["click","focus"]}
                                        onChange={(event, key) =>
                                          handleStateChange(event, key)
                                        }
                                        filterOption={filterMethod}
                                      >
                                        {state.map((state) => (
                                          <Select.Option
                                            value={state.id}
                                            key={state.id}
                                          >
                                            {state.name}
                                          </Select.Option>
                                        ))}
                                      </Select>
                                    )}
                                  </Col>
                                  <Col span={2}>
                                    <Button
                                      type="ghost"
                                      shape="circle"
                                      size="small"
                                      onClick={() => deleteFilter(element)}
                                    >
                                      &#10005;
                                    </Button>
                                  </Col>
                                </Row>
                              </Item>
                            </Col>
                          );
                        break;
                      default:
                        return (
                          <Col span={8}>
                            <Item colon={false}>
                              <Row type="flex">
                                <Col span={20}>
                                  {getFieldDecorator("Pincode")(
                                    <Input placeholder="Pincode" />
                                  )}
                                </Col>
                                <Col span={2}>
                                  <Button
                                    type="ghost"
                                    shape="circle"
                                    size="small"
                                    onClick={() => deleteFilter(element)}
                                  >
                                    &#10005;
                                  </Button>
                                </Col>
                              </Row>
                            </Item>
                          </Col>
                        );
                        break;
                    }
                  })}
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex" justify="space-between">
                  <Col span={19}>
                    <Item colon={false}>
                      {getFieldDecorator("selectfilter")(
                        <Select
                          placeholder="Select the Filter"
                          onChange={selectFilter}
                          mode="multiple"
                          showSearch
                          showAction={["click","focus"]}
                          filterOption={filterMethod}
                        >
                          {Filters.map((type) => (
                            <Option key={type.id} value={type.id}>
                              {type.name}
                            </Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={5} style={{ marginTop: "0.2rem" }}>
                    <Button type="primary" onClick={handleSubmit}>
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="end"
                  style={{ marginRight: "0.79rem" }}
                >
                  <Button
                    type="ghost"
                    style={{ width: "4.5rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilter);
export default WrappedAdvancedFilter;
