import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, message } from "antd";
import { platformApi } from "../../../../api";
const { Item } = Form;
const { Option } = Select;
function AdvancedFilters({
  form,
  filteropen,
  close,
  searchName,
  setId,
  id,
  setData,
  setPrimaryData,
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;

  const status = [
    { id: "ACTIVE", name: "Active" },
    { id: "INACTIVE", name: "Inactive" },
  ];
  const Filters = [
    { id: "1", name: "Supplier Name" },
    { id: "2", name: "Status" },
    { id: "3", name: "Supplier Type" },
    { id: "4", name: "GST Dealer Type" },
    { id: "5", name: "State" },
    { id: "6", name: "City" },
    { id: "7", name: "Locality" },
    { id: "8", name: "Supplier Contact Name" },
    { id: "9", name: "Supplier Contact Cell Phone No" },
  ];
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [country, setCountry] = useState([]);
  const [contact, setContact] = useState([]);
  // const [number, setNumber] = useState([]);
  const [supplier, setSupplier] = useState([]);
  const Type = [
    { id: "1", name: "Vehicles" },
    { id: "2", name: "Accessories" },
    { id: "3", name: "Spares" },
    { id: "4", name: "Battery" },
    { id: "5", name: "Tyre" },
    { id: "6", name: "Consumables" },
  ];
  const Dealer = [
    { key: "RegisteredDealer", name: "Registered Dealer" },
    { key: "UnregisteredDealer", name: "Unregistered Dealer" },
    { key: "UINHolder", name: "UIN Holder" },
    { key: "CompositionDealer", name: "Composition Dealer" },
  ];
  const selectFilter = (id) => {
    setId(id);
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  const searchValue = () => {
    setId([]);
    resetFields();
    setPrimaryData();
  };
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
  };
  useEffect(() => {
    if (filteropen) {
      setFieldsValue({ selectfilter: id });

      platformApi
        .get("/api/supplier")
        .then((res) => {
          let { data } = res;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              setSupplier(response.data);
            }
          } else {
            message.error("Cannot fetch Supplier Data");
          }
        })
        .catch((err) => {
          console.error("Supplier fetch error", err);
          message.error("Cannot fetch Supplier Data");
        });
      platformApi
        .get("/api/csc/country")
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setCountry(data.data);
          }
        })
        .catch((error) => {
          console.error("Error on Manufaturer form : ", error);
          message.error("Unable to fetch country", 2);
        });
    }
  }, [filteropen]);
  useEffect(() => {
    id.forEach((element) => {
      if (element === "1") setFieldsValue({ Name: searchName });
    });
  }, [id, searchName, filteropen]);
  useEffect(() => {
    if (id.includes("5")) {
      let id;
      country.forEach((element) => {
        if (element.name === "India") {
          id = element.id;
        }
      });
      platformApi
        .post("api/csc/states", { id })
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setState(data.data);
          }
        })
        .catch((error) => {
          console.error("Error on Manufaturer form : ", error);
          message.error("Unable to fetch state", 2);
        });
    }
    if (id.includes("8") || id.includes("9")) {
      let contacts = [];
      supplier.forEach((supplier) => {
        supplier.contact.map((contact) => {
          contacts.push(contact);
        });
      });
      setContact(contacts);
    }
  }, [id]);
  const handleStateChange = (id) => {
    let cities = [];
    id.forEach((id) => {
      platformApi
        .post("api/csc/cities", { id })
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            data.data.forEach((element) => {
              cities.push(element);
            });
          }
        })
        .catch((error) => {
          console.error("Error on Manufaturer form : ", error);
          message.error("Unable to fetch cities", 2);
        });
    });
    setCity(cities);
  };
  const branchSelect = (id) => {
  };
  const handleSubmit = () => {
    const data = {
      name: getFieldValue("name"),
      status: getFieldValue("status"),
      stype: getFieldValue("suppliertype"),
      gstDealerType: getFieldValue("GST"),
      state: getFieldValue("State"),
      city: getFieldValue("City"),
      locality: getFieldValue("Locality"),
      scname: getFieldValue("contactname"),
      sphone: getFieldValue("suppliercontactnumber"),
    };
    platformApi
      .post("/api/supplier/search", data)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData(response.data);
            message.success("Filtered successfully", 2);
          } else {
            message.error("Unable to fetch", 2);
          }
        } else {
          message.error("Unable to fetch", 2);
        }
      })
      .catch((error) => {
        console.error("Error in filtering : ", error);
        message.error("Unable to fetch", 2);
      });
  };
  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <Row type="flex" justify="start">
                  {id.map((element) =>
                    // {
                    //     switch (element) {
                    //         case "1":
                                
                    //             break;
                        
                    //         default:
                    //             break;
                    //     }
                    // }

                    element === "1" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("name")(
                                <Input placeholder="Name" />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "2" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("status")(
                                <Select
                                  placeholder="Status"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                  showAction={["click","focus"]}
                                >
                                  {status.map((status) => (
                                    <Select.Option
                                      value={status.name}
                                      key={status.id}
                                    >
                                      {status.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "3" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("suppliertype")(
                                <Select
                                  placeholder="Supplier type"
                                  showSearch
                                  showAction={["click","focus"]}
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  mode="multiple"
                                >
                                  {Type.map((state) => (
                                    <Select.Option
                                      value={state.name}
                                      key={state.id}
                                    >
                                      {state.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "4" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("GST")(
                                <Select
                                  placeholder="GST dealer type"
                                  showSearch
                                  showAction={["click","focus"]}
                                  filterOption={filterMethod}
                                  mode="multiple"
                                >
                                  {Dealer.map((gstdealer) => (
                                    <Select.Option
                                      value={gstdealer.key}
                                      key={gstdealer.key}
                                    >
                                      {gstdealer.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "5" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("State")(
                                <Select
                                  placeholder="State"
                                  showSearch
                                  showAction={["click","focus"]}
                                  onChange={(event, key) =>
                                    handleStateChange(event, key)
                                  }
                                  filterOption={filterMethod}
                                  mode="multiple"
                                >
                                  {state.map((state) => (
                                    <Select.Option
                                      value={state.id}
                                      key={state.id}
                                    >
                                      {state.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "6" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("City")(
                                <Select
                                  placeholder="City"
                                  showSearch
                                  showAction={["click","focus"]}
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  mode="multiple"
                                >
                                  {city.map((city) => (
                                    <Select.Option
                                      value={city.id}
                                      key={city.id}
                                    >
                                      {city.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "7" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("Locality")(
                                <Select
                                  placeholder="Locality"
                                  showSearch
                                  showAction={["click","focus"]}
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  mode="multiple"
                                >
                                  {supplier.map((supplier) => (
                                    <Select.Option
                                      value={supplier.address.locality}
                                      key={supplier.id}
                                    >
                                      {supplier.address.locality}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "8" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("contactname")(
                                <Select
                                  placeholder="Contact Name"
                                  showSearch
                                  onChange={branchSelect}
                                  showAction={["click","focus"]}
                                  filterOption={filterMethod}
                                >
                                  {contact.map((contact) => (
                                    <Select.Option
                                      value={contact.name}
                                      key={contact.id}
                                    >
                                      {contact.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("suppliercontactnumber")(
                                <Select
                                  placeholder="Supplier Number"
                                  showSearch
                                  showAction={["click","focus"]}
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                >
                                  {contact.map((contact) => (
                                    <Select.Option
                                      value={contact.number}
                                      key={contact.id}
                                    >
                                      {contact.number}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    )
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex" justify="space-between">
                  <Col span={19}>
                    <Item colon={false}>
                      {getFieldDecorator("selectfilter")(
                        <Select
                          placeholder="Select the Filter"
                          onChange={selectFilter}
                          mode="multiple"
                          showAction={["click","focus"]}
                        >
                          {Filters.map((type) => (
                            <Option key={type.id}>{type.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={5} style={{ marginTop: "0.2rem" }}>
                    <Button type="primary" onClick={handleSubmit}>
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="end"
                  style={{ marginRight: "0.79rem" }}
                >
                  <Button
                    type="ghost"
                    style={{ width: "4.5rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
