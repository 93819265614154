import React from "react";
import { Table, Divider, Popconfirm, Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
import moment from 'moment'
const { Text } = Typography;
const VehicleTable = ({
  pagination,
  dataSource,
  spinner,
  openModal,
  delete: deleteData,
  history,
  modify,
  deleteAccess,
}) => {
  const { goBack } = history;



  const getCurrentPrice = (prices) => { 
    if (prices.length > 0) {
      let currentDate=moment()
      let price = prices.filter(price => moment(price.priceValidFrom) <= currentDate && currentDate <= moment(price.priceValidTill))
      if (price.length) { 
        return price[0].showroomPrice
      }
    }
    return false
  }

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Model",
      dataIndex: "modelName",
      key: "modelName",
      render: (text, record) => (
        <span>
          {record.modelCode} - {text}
        </span>
      ),
    },
    {
      title: "Manufacturer",
      dataIndex: "manufacturer",
      key: "manufacturer",
      render: (text) => <span>{text && text.name}</span>,
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      render: (text) => {
        switch (text) {
          case "MOTORCYCLE":
            text = "Motorcycle";
            break;
          case "SCOOTER":
            text = "Scooter";
            break;
        }
        return <span>{text}</span>;
      },
    },
    {
      title: "Status",
      dataIndex: "vehicleStatus",
      key: "status",
      render: (text) => {
        switch (text) {
          case "AVAILABLE":
            text = "Available";
            break;
          case "NOTAVAILABLE":
            text = "Not Available";
            break;
        }
        return <span>{text}</span>;
      },
    },
    // {
    //   title: "Current Price",
    //   dataIndex: "price",
    //   key: "currentPrice",
    //   render: (text) => (
    //     <span>
    //       &#8377; {getCurrentPrice(text) || "NA"}
    //     </span>
    //   ),
    // },
    {
      title: "Action",
      render: (data) => (
        <div>
          {modify ? (
            <span
              className="linkylink"
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, true);
              }}
            >
              Modify
            </span>
          ) : (
            <span></span>
          )}
          {deleteAccess ? (
            <Popconfirm
              title={
                (data &&
                  data.price &&
                  data.price[0] &&
                  "This vehicle has some dependencies. Are You sure you want to delete it") ||
                "Are you sure delete this Vehicle?"
              }
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(data.id);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                <Divider type="vertical" />
                Delete
              </span>
            </Popconfirm>
          ) : (
            <span></span>
          )}
          {!modify && !deleteAccess ? (
            <Text strong disabled>
              No Actions Given
            </Text>
          ) : (
            <span></span>
          )}

          {/* <Divider type="vertical" />
          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      loading={spinner}
      style={{ cursor: "pointer" }}
      onRow={(props) => ({
        onClick: () => openModal(props.id, false),
      })}
    />
  );
};

export default withRouter(VehicleTable);
