import React, { useState, useEffect } from "react";
import { Form, Row, Col, Select, Button, Input, Slider, message } from "antd";
import { platformApi } from "../../../../api";
import "./index.less";
const { Item } = Form;
const { Option } = Select;
function AdvancedFilters({
  form,
  filteropen,
  close,
  setPage,
  setId,
  id,
  setPageData,
  setSearch,
}) {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
    resetFields,
  } = form;
  const [model, setModels] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [color, setColor] = useState([]);
  const status = [
    { key: "AVAILABLE", name: "Available" },
    { key: "NOTAVAILABLE", name: "Not Available" },
  ];
  const category = [
    { id: "1", name: "Motorcycle" },
    { id: "2", name: "Scooter" },
  ];
  const Filters = [
    { id: "1", name: "Model Name & Code" },
    //   { id: "2", name:'Model Code'},
    //   { id: "3", name:'Manufacturer'},
    //   { id: "4", name:'Category' },
    { id: "5", name: "Status" },
    //   { id: "6", name:'Price Range'},
    //   { id: "7", name:'Vehicle Color'},
  ];

  //price range

  function formatter(value) {
    return `${value * 1000 + 25000}`;
  }
  const marks = {
    0: "₹25000",
    25: "₹50000",
    50: "₹75000",
    75: "₹100000",
    100: "₹125000",
  };
  const selectFilter = (id) => {
    setId(id);
  };
  const searchValue = () => {
    setId([]);
    setPageData();
    resetFields();
    setSearch([]);
  };
  const deleteFilter = (element) => {
    let changedfilter = [];
    id.map((filter) => {
      if (filter !== element) {
        changedfilter.push(filter);
      }
    });
    setId(changedfilter);
    setFieldsValue({ selectfilter: changedfilter });
  };
  useEffect(() => {
    setFieldsValue({ selectfilter: id });
    // platformApi.get(`/api/vehicleMaster`).then((res) => {
    //   const { data } = res;
    //   if (data.code === 200) {
    //     const { response } = data;
    //     if (response.code === 200) {
    //       setModels(response.data);
    //     }
    //   }
    // });
  }, [filteropen]);

  // useEffect(() => {
  //   if (id.includes("1")) {
  //     setFieldsValue({ Name: searchName.name });
  //   }
  // }, [id, searchName, filteropen]);

  useEffect(() => {
    // if (id.includes("3")) {
    //   platformApi.get("/api/manufacturer").then((res) => {
    //     const { data } = res;
    //     if (data.code === 200) {
    //       const { response } = data;
    //       if (response.code === 200) {
    //         setManufacturer(response.data);
    //       }
    //     }
    //   });
    // }
    if (id.includes("7")) {
      let image = [];
      model.forEach((vehicle) => {
        vehicle.image.map((contact) => {
          image.push(contact);
        });
      });
      setColor(image);
    }
  }, [id]);

  const handleSubmit = () => {
    const data = {
      name: getFieldValue("Name"),
      model: getFieldValue("code"),
      manufacturer: getFieldValue("manufacturer"),
      category: getFieldValue("category"),
      vehiclestatus: getFieldValue("Status"),
      color: getFieldValue("VehicleColor"),
    };
    setSearch(data);
    setPage(1)
  };
  const branchSelect = (id) => {
  };
  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  // const handleManufacturer = (id, key) => {
  //   platformApi.get(`/api/vehicleMaster/man/${key.key}`).then((res) => {
  //     const { data } = res;
  //     if (data.code === 200) {
  //       const { response } = data;
  //       if (response.code === 200) {
  //         setModels(response.data);
  //       }
  //     }
  //   });
  // };
  return (
    <div>
      {filteropen && (
        <div className="AdvancedFilters">
          <Form>
            <Row type="flex" justify="space-between">
              <Col span={14}>
                <Row type="flex" justify="start">
                  {id.map((element) =>
                    element === "1" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("Name")(
                                <Input placeholder="Model Name & Code" />
                                // <Select
                                //     placeholder="Model Name"
                                //     onChange={branchSelect}
                                //     filterOption={filterMethod}
                                //     showSearch
                                // >
                                //    {model.map((model)=>(
                                //     <Select.Option value={model.modelName} key={model.id}>
                                //         {model.modelName}
                                //     </Select.Option>
                                //    ))
                                //    }
                                // </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "2" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("code")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Model Code"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                >
                                  {model.map((model) => (
                                    <Select.Option
                                      value={model.modelCode}
                                      key={model.id}
                                    >
                                      {model.modelCode}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "3" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("manufacturer")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Manufacturer"
                                  onChange={(event, key) =>
                                    handleManufacturer(event, key)
                                  }
                                  filterOption={filterMethod}
                                  showSearch
                                >
                                  {manufacturer.map((manufacturer) => (
                                    <Select.Option
                                      value={manufacturer.name}
                                      key={manufacturer.id}
                                    >
                                      {manufacturer.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "4" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("category")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Category"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                >
                                  {category.map((category) => (
                                    <Select.Option
                                      value={category.name}
                                      key={category.id}
                                    >
                                      {category.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "5" ? (
                      <Col span={8}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col span={20}>
                              {getFieldDecorator("Status")(
                                <Select
                                showAction={["click","focus"]}
                                  placeholder="Status"
                                  onChange={branchSelect}
                                  filterOption={filterMethod}
                                  showSearch
                                >
                                  {status.map((status) => (
                                    <Select.Option
                                      value={status.key}
                                      key={status.key}
                                    >
                                      {status.name}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : element === "6" ? (
                      <Col span={14}>
                        <Item colon={false}>
                          <Row type="flex">
                            <Col
                              span={20}
                              style={{ padding: "0px 20px" }}
                              className="RangeSlider"
                            >
                              {getFieldDecorator("Pricerange")(
                                <Slider
                                  onChange={branchSelect}
                                  range
                                  marks={marks}
                                  tipFormatter={formatter}
                                  defaultValue={[25, 50]}
                                />
                              )}
                            </Col>
                            <Col span={2}>
                              <Button
                                type="ghost"
                                shape="circle"
                                size="small"
                                style={{ marginLeft: "0.5rem" }}
                                onClick={() => deleteFilter(element)}
                              >
                                &#10005;
                              </Button>
                            </Col>
                          </Row>
                        </Item>
                      </Col>
                    ) : (
                                  <Col span={8}>
                                    <Item colon={false}>
                                      <Row type="flex">
                                        <Col span={20}>
                                          {getFieldDecorator("VehicleColor")(
                                            <Select
                                            showAction={["click","focus"]}
                                              placeholder="Vehicle Color"
                                              onChange={branchSelect}
                                              mode="multiple"
                                              filterOption={filterMethod}
                                              showSearch
                                            >
                                              {color.map((color) => (
                                                <Select.Option
                                                  value={color.color}
                                                  key={color.id}
                                                >
                                                  {color.color}
                                                </Select.Option>
                                              ))}
                                            </Select>
                                          )}
                                        </Col>
                                        <Col span={2}>
                                          <Button
                                            type="ghost"
                                            shape="circle"
                                            size="small"
                                            onClick={() => deleteFilter(element)}
                                          >
                                            &#10005;
                              </Button>
                                        </Col>
                                      </Row>
                                    </Item>
                                  </Col>
                                )
                  )}
                </Row>
              </Col>
              <Col span={10}>
                <Row type="flex" justify="space-between">
                  <Col span={19}>
                    <Item colon={false}>
                      {getFieldDecorator("selectfilter")(
                        <Select
                          placeholder="Select the Filter"
                          onChange={selectFilter}
                          mode="multiple"
                          showAction={["click","focus"]}
                        >
                          {Filters.map((type) => (
                            <Option key={type.id}>{type.name}</Option>
                          ))}
                        </Select>
                      )}
                    </Item>
                  </Col>
                  <Col span={5} style={{ marginTop: "0.2rem" }}>
                    <Button type="primary" onClick={handleSubmit}>
                      Search
                    </Button>
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="end"
                  style={{ marginRight: "0.79rem" }}
                >
                  <Button
                    type="ghost"
                    style={{ width: "4.5rem" }}
                    onClick={searchValue}
                  >
                    Clear
                  </Button>
                </Row>
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
}

const WrappedAdvancedFilter = Form.create()(AdvancedFilters);
export default WrappedAdvancedFilter;
