import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { Col, Row, Typography, Input, Button, Select, Dropdown, message, Menu, Form, Icon } from 'antd';
import { platformApi } from '../../../api';
import { ContextAPI } from '../../../ContextAPI';
import WrappedVehicleForm from '../Vehicles/VehicleForm';
import ReminderTable from './ReminderTable';
import moment from 'moment';
import {
    EyeFilled,
    EditFilled,
    WhatsAppOutlined
} from '@ant-design/icons';
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
const ServiceReminder = ({ form }) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const history = useHistory();
    const location = useLocation();
    const Month = [
        { id: 0, title: "January" },
        { id: 1, title: "February" },
        { id: 2, title: "March" },
        { id: 3, title: "April" },
        { id: 4, title: "May" },
        { id: 5, title: "June" },
        { id: 6, title: "July" },
        { id: 7, title: "August" },
        { id: 8, title: "September" },
        { id: 9, title: "October" },
        { id: 10, title: "November" },
        { id: 11, title: "December" }
    ]
    const date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    firstDay = firstDay.toISOString()
    lastDay = lastDay.toISOString()

    const [month, setMonth] = useState([]);
    const [dataSource, setData] = useState([]);
    const [smsData, setSmsData] = useState([]);
    const [selected, setSelected] = useState(null);
    const [open, setOpen] = useState(false);
    const [spinning, setSpinning] = useState(false);
    const [editable, setEditable] = useState(false);
    const [limit, setLimit] = useState(10);
    const [createAccess, setCreateAccess] = useState(false);
    const [modifyAccess, setModifyAccess] = useState(false);
    const [deleteAccess, setDeleteAccess] = useState(false);
    const [mdfy, setMdfy] = useState(false)
    const [page, setPage] = useState(1);
    const [count, setCount] = useState(0);
    const [search, setSearch] = useState('');
    const [status, setStatus] = useState("ALL");
    const [firstDate, setFirstDate] = useState(firstDay);
    const [lastDate, setLastDate] = useState(lastDay)
    const { loginCredintials } = useContext(ContextAPI);
    const [uploading, setUploading] = useState(false)
    const [dateValue, setDateValue] = useState('')
    const [newData, setNewData] = useState([]);
    const [loading, setLoading] = useState({ print: false, sms: false, whatsApp: false });

    const getMenu = () => {
        let size = count / 10 + (count % 10 ? 1 : 0);
        let menus = [];
        menus.push(10);
        size = size <= 10 ? size : 10;
        for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
        return menus.map((menu) => (
            <Item
                onClick={() => {
                    setLimit(menu), setPage(1);
                }}
                key={menu}
            >
                {menu}
            </Item>
        ));
    };
    const menu = <Menu>{getMenu()}</Menu>;


    useEffect(() => {
        let temp = [];
        const d = new Date();
        var currentDay = d.getDate()
        var total = d.getMonth()
        setFieldsValue({
            month: Month[total].title
        })

        if (currentDay >= 25) {
            total = total + 1
        }
        if (total > 0) {
            if (total >= 2) {
                var prev = d.getMonth() - 2
                for (let i = prev; i <= total; i++) {
                    temp.push(Month[i])
                }
                setMonth(temp)
            }
            else if (total == 1) {
                var prev = d.getMonth() - 1
                for (let i = prev; i <= total; i++) {
                    temp.push(Month[i])
                }
                setMonth(temp)
            }
        }
        else {
            setMonth(Month[total])
        }

        loginCredintials.roleAccess &&
            loginCredintials.roleAccess.map((each) => {
                if (each.subModule === 'vehicle_reminder') {
                    if (each.access.create) {
                        setCreateAccess(true);
                    }
                    if (each.access.update) {
                        setModifyAccess(true);
                    }
                    if (each.access.delete) {
                        setDeleteAccess(true);
                    }
                }
            });
        // setPrimaryData(search);

    }, []);

    useEffect(() => {
        if (location.state?.status) {
            setStatus(location.state.status)
            setPrimaryData(null, null, location.state.status)
        }
        else {
            setPrimaryData(search);
        }
    }, [page, limit, search, firstDate, lastDate]);

    const setPrimaryData = (string, val, propStatus) => {
        setSpinning(true);
        platformApi
            .post('/api/vehicle/getReminder', {
                page: val ? val : page,
                size: limit,
                searchString: string,
                fromDate: firstDate && firstDate,
                toDate: lastDate && lastDate,
                status: propStatus ? propStatus : status
            })
            .then(async (result) => {
                let { data } = result;
                if (data.code === 200) {
                    let { response } = data;
                    if (response.code === 200) {
                        setData(response.data.SoldVehicle);
                        setCount(response.data.count)
                        setSpinning(false);
                        let temp = []
                        for (let i = 0; i < response.data.SoldVehicle.length; i++) {
                            const element = response.data.SoldVehicle[i];
                            let tempData = {
                                modelName: '',
                                regNo: '',
                                serviceNo: '',
                                serviceType: '',
                                serviceKms: '',
                                serviceDate: '',
                                phone: ''
                            }
                            tempData.modelName = `${element.vehicle.modelCode}-${element.vehicle.modelName}`
                            tempData.regNo = element.registerNo
                            tempData.customerId = element.customer[0]?.customer.id
                            tempData.customer = element.customer[0]?.customer?.name
                            tempData.phone = element.customer[0]?.customer?.contacts[0]?.phone
                            if (element.activeService) {
                                tempData.serviceNo = element.activeService.serviceNo
                                tempData.serviceType = element.activeService.serviceType
                                tempData.serviceKms = element.activeService.serviceKms
                                tempData.serviceDate = moment(element.activeService.serviceDate).format("DD/MM/YYYY")
                            }
                            else {
                                element.services.map(item => {
                                    if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                                        tempData.serviceNo = item.serviceNo
                                        tempData.serviceType = item.serviceType
                                        tempData.serviceKms = item.serviceKms
                                        tempData.serviceDate = moment(item.serviceDate).format("DD/MM/YYYY")
                                    }
                                })
                            }
                            temp.push(tempData)
                        }
                        setSmsData(temp)
                    }
                }
                else {
                    message.error('Unable to fetch vehicles2', 2);
                }
            })
            .catch((error) => {
                setSpinning(false);
                message.error('Unable to fetch vehicles', 2);
            });
    };

    const addData = (formData, vehicle) => {
        setSpinning(true);
        if (vehicle.id) {
            platformApi
                .put('/api/vehicle/' + vehicle.id, formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((result) => {
                    setSpinning(false);
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            // dataSource.some((obj, index) => {
                            //     if (obj.id === vehicle.id) {
                            //         dataSource[index] = response.data;
                            //     }
                            // });
                            // setData([]);
                            // setData(dataSource);
                            message.success('Vehicle saved successfully', 2);
                            setPrimaryData();
                        } else {
                            message.error('Unable to save vehicle', 2);
                        }
                    } else {
                        message.error('Unable to save vehicle', 2);
                    }
                })
                .catch((error) => {
                    setSpinning(false);
                    message.error('Unable to save vehicle', 2);
                });
        }
        else {
            platformApi
                .post('/api/vehicle', formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                })
                .then((result) => {
                    setSpinning(false);
                    let { data } = result;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            // setData([...dataSource, response.data]);
                            // setCount(count + 1);
                            message.success('Vehicle added successfully', 2);
                            setPrimaryData();
                        } else {
                            message.error('Unable to add vehicle', 2);
                        }
                    } else {
                        message.error('Unable to add vehicle', 2);
                    }
                })
                .catch((error) => {
                    setSpinning(false);
                    message.error('Unable to add vehicle', 2);
                });
        }
    };
    
    const selectMonth = (value) => {
        setFieldsValue({
            month: value
        })
        const date = new Date();
        var firstDay = new Date(date.getFullYear(), value, 1);
        var lastDay = new Date(date.getFullYear(), value + 1, 0);

        firstDay = firstDay.toISOString()
        lastDay = lastDay.toISOString()
        setFirstDate(firstDay)
        setLastDate(lastDay)
    }
    
    const sendMessage = (type) => {
        let data = {
            smsData: smsData
        }
        if (type === "SMS") {
            data.type = type
            setLoading({ ...loading, sms: true });
            platformApi
                .post("api/sendSms/serviceReminder", data)
                .then((result) => {
                    setLoading({ ...loading, sms: false });
                    const { data } = result;
                    if (data.code === 200) {
                        message.success("Message sent successfully");
                    }
                    else if (data.code === 401) {
                        message.error(data.message)
                    }
                    else {
                        message.error("Unable to send SMS");
                    }
                })
                .catch((error) => {
                    setLoading({ ...loading, sms: false });
                    message.error("Unable to send SMS");
                });
        }
        else if (type === "WhatsApp") {
            data.type = type
            setLoading({ ...loading, whatsApp: true });
            platformApi
                .post("api/sendSms/serviceReminder", data)
                .then((result) => {
                    setLoading({ ...loading, whatsApp: false });
                    const { data } = result;
                    if (data.code === 200) {
                        message.success("WhatsApp Message sent successfully");
                    }
                    else if (data.code === 400) {
                        message.error(data.message)
                    }
                    else if (data.code === 401) {
                        message.error(data.message)
                    }
                    else {
                        message.error("Unable to send WhatsApp SMS");
                    }
                })
                .catch((error) => {
                    setLoading({ ...loading, whatsApp: false });
                    message.error("Unable to send SMS");
                });
        }
    }

    return (
        <div>
            <div className="page-head">
                <Title style={{ width: '60%' }} level={4}>
                    Service Reminder [{count}]
					<span style={{ margin: '.5rem' }}>
                        <Dropdown overlay={menu} trigger={['click']}>
                            <Button>
                                {limit} &nbsp;
								<Icon type="down" />
                            </Button>
                        </Dropdown>
                    </span>
                </Title>
       
                <div style={{ width: "30%", textAlign: "center", marginRight: "1%", marginTop: '1.5rem' }}>
                    {/* <Row type="flex" gutter={[16, 16]} justify='space-between'> */}
                    <Col span={10}>
                        <Form.Item>
                            {getFieldDecorator("month")
                                (
                                    <Select dropdownStyle={{
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                    }}
                                        showSearch
                                        showAction={["click", "focus"]}
                                        placeholder="Month"
                                        onChange={selectMonth}
                                    >

                                        {month.length && month.map((obj) => (
                                            <Select.Option key={obj.id} value={obj.id}>
                                                {obj.title}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                        </Form.Item>
                    </Col>
                    <Col span={7} style={{ marginTop: '5px' }}>
                        <Button loading={loading.sms} onClick={() => sendMessage("SMS")}>
                            <Icon type="message" />
                        </Button>
                    </Col>
                    <Col span={7} style={{ marginTop: '5px' }}>
                        <Button loading={loading.whatsApp} onClick={() => sendMessage("WhatsApp")}>
                            <WhatsAppOutlined />
                        </Button>
                    </Col>
                    {/* </Row> */}
                </div>
                <Search
                    style={{ width: '30%' }}
                    onSearch={(event) => {
                        setPage(1);
                        setSearch(event);
                    }}
                    onChange={(event) => {
                        if (event.target.value === '') {
                            setSearch(null);
                            setPage(1);
                        }
                    }}
                    placeholder="Search Vehicle"
                />

            </div>

            <ReminderTable
                pagination={{
                    onChange: (page) => {
                        setPage(page);
                    },
                    pageSize: limit,
                    defaultCurrent: 1,
                    current: page,
                    total: count,
                    showQuickJumper: true
                }}
                openModal={(id, editable) => {
                    setSelected(id);
                    setOpen(true);
                    setEditable(editable);
                }}
                firstDate={firstDate}
                lastDate={lastDate}
                dataSource={dataSource}
                spinner={{ spinning }}
            />

            <WrappedVehicleForm
                data={dataSource[dataSource.findIndex((obj) => obj.id === selected)]}
                emitData={addData}
                open={open}
                editable={editable}
                setUploading={setUploading}
                uploading={uploading}
                close={() => {
                    setOpen(false);
                    setSelected(false);
                    setUploading(false);
                }}
                modify={modifyAccess}
                edit={mdfy}
                setEdit={setMdfy}
                vData={false}
            />
        </div>
    )
}
const WrappedServiceReminder = Form.create("service_reminder")(
    ServiceReminder
);

export default WrappedServiceReminder; 