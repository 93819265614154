import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Row,
  Col,
  DatePicker,
  Typography,
  Select,
  Table,
  Button,
  Popconfirm,
  InputNumber,
  message,
} from "antd";
import { platformApi, baseUrl } from "../../../../api";

const { Item } = Form;

const { Text, Title } = Typography;

const VPRModal = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const [invoiceDate, setInvoiceDate] = useState();
  const [currentDate, setCurrentDate] = useState(new Date());
  const [purchaseInvoice, setInvoiceId] = useState("");

  const [invoice, setInvoice] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [challanId, setChallan] = useState();

  const [dataSource, setDataSource] = useState([]);
  const [dataSourcez, setDataSourcez] = useState([]);

  const [MinvoiceNo, setMinvoice] = useState();
  const [amount, setAmount] = useState();

  const { form, open, close, data, modify } = props;
  const { getFieldDecorator } = form;

  const column = [
    // {
    //   title: "Chassis No.",
    //   dataIndex: "priceZ",
    //   key: "chassisNo",
    //   render: (record, rowData, ind) => (
    //     <Select
    //       disabled={!editable}
    //       placeholder="Chassis No."
    //       onChange={(e) => {
    //         handleChassisChange(e, ind);
    //       }}
    //     >
    //       {dataSourcez.map((item) => {
    //         let flag = true;
    //         dataSource.map((checkItem) => {
    //           if (checkItem.retId === item.retId) {
    //             flag = false;
    //           }
    //         });
    //         if (flag)
    //           return (
    //             <Select.Option key={item.chassisNo}>
    //               {item.chassisNo}
    //             </Select.Option>
    //           );
    //       })}
    //     </Select>
    //   ),
    // },
    {
      title: "Chassis No.",
      dataIndex: "chassisNo",
      key: "chassisNo",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Model Code - Name",
      dataIndex: "name",
      key: "name",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Engine No.",
      dataIndex: "engineNo",
      key: "engineNo",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Color",
      dataIndex: "color",
      key: "color",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "VPI",
      dataIndex: "vpi",
      key: "vpi",
      render: (record) => <span>{record}</span>,
    },
    // {
    //   title: "Action",
    //   render: (data) => (
    //     <div>
    //       <Popconfirm
    //         title="Do you want to delete this Vehicle?"
    //         okText="Yes"
    //         cancelText="No"
    //         onCancel={(event) => {
    //           event.stopPropagation();
    //         }}
    //         onConfirm={(event) => {
    //           event.stopPropagation();
    //           deleteDataz(data);
    //         }}
    //       >
    //         <span
    //           onClick={(event) => event.stopPropagation()}
    //           className="linkylink"
    //         >
    //           Delete
    //         </span>
    //       </Popconfirm>
    //     </div>
    //   ),
    // },
  ];

  useEffect(() => {
    platformApi.get("/api/purchaseInvoice/").then((res) => {
      let results = [];
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        response.data.map((resp) => {
          let obj = {
            id: resp.id,
            name: resp.invoiceNo,
            challanId: resp.purchaseChallan.id,
            supplier: resp.purchaseChallan.supplier,
            location: resp.purchaseChallan.branch,
            vehicle: resp.purchaseChallan.vehicleDetail,
            key: resp.id,
            date: resp.date,
          };
          results.push(obj);
          setInvoice(results);
        });
      } else {
        message.error("Couldn't fetch id");
      }
    });

    platformApi.get("/api/vehicleMaster").then((result) => {
      let { data } = result;
      if (data.code === 200) {
        setVehicle(data.response.data);
      }
    });

    form.setFieldsValue({ invoiceNo: props.id });
  }, []);

  useEffect(() => {
    setDataSource([]);
    setDataSourcez([]);
  }, [close]);

  useEffect(() => {
    setEditable(props.editable);
    if (data) {
      platformApi.get("/api/purchaseReturn/" + data.id).then((result) => {
        let getData = result.data.response.data;

        setInvoiceDate(getData.date);
        setMinvoice(data.returnInvoiceNo);

        let igst = 0;
        let cess = 0;

        getData.returnVehicle.map((obj) => {
          igst +=
            vehicle[
              vehicle.findIndex((o) => o.modelCode === obj.vehicle.modelCode)
            ].hsn.igst * getData.quantity;
          cess +=
            vehicle[
              vehicle.findIndex((o) => o.modelCode === obj.vehicle.modelCode)
            ].hsn.cess;
        });

        let grost = form.getFieldValue("grossTotal");
        igst /= getData.returnVehicle.length;
        cess /= getData.returnVehicle.length;
        let nett = grost + grost * (igst / 2 / 100) * 2 + cess;

        form.setFieldsValue({
          igst: igst,
          cgst: igst / 2,
          sgst: igst / 2,
          cess: cess,
          net: nett,
          round: Math.round(nett),
        });

        let results = [];

        getData.returnVehicle.map((obj) => {
          let newObj = {
            chassisNo: obj.chassisNo,
            engineNo: obj.engineNo,
            color: obj.color.color,
            vpi: getData.purchaseInvoice.invoiceNo,
            name: obj.vehicle.modelCode + "-" + obj.vehicle.modelName,
          };
          results.push(newObj);
        });

        setDataSource(results);

        form.setFieldsValue({
          invoiceNo: getData.returnInvoiceNo,
          AinvoiceNo: getData.purchaseInvoice.invoiceNo,
          invoiceDate: new Date(getData.date).toLocaleDateString(),
          supplier: getData.purchaseChallan.supplier.name,
          location: getData.purchaseChallan.branch.name,
          grossTotal: getData.grossTotal,
          others: getData.others,
          net: getData.netAmount,
          round: getData.roundOff,
          chassisNo: getData.returnVehicle[0].chassisNo,
        });
      });
    }
  }, [data, props.editable]);

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        setLoading(true);
        const returnInvoiceNo = MinvoiceNo || props.id;
        const date = currentDate;
        const grossTotal = form.getFieldValue("grossTotal");
        const others = form.getFieldValue("others");
        const netAmount = form.getFieldValue("net");
        const amount = form.getFieldValue("net");
        const roundOff = form.getFieldValue("round");
        const quantity = dataSource.length;
        const purchaseChallan = challanId;

        let returnVehicle = [];

        dataSource.map((obj) => {
          returnVehicle.push(obj.id);
        });

        let vpi = {
          returnInvoiceNo,
          date,
          purchaseInvoice,
          purchaseChallan,
          quantity,
          amount,
          grossTotal,
          netAmount,
          roundOff,
          others,
          returnVehicle,
        };
        data ? (vpi.id = data.id) : {};
        props.emitData(vpi);
        setLoading(false);
        props.close();
        clearFields();
      }
    });
  };

  const clearFields = () => {
    form.resetFields();
  };

  const othersChange = (valuez) => {
    if (valuez === "-") return;
    let value = valuez;
    let nett = amount;
    form.setFieldsValue({
      net: nett + parseInt(value),
      round: Math.round(nett + parseInt(value)),
    });
  };

  const searchVehicle = () => {
    setDataSource([]);
    form.setFieldsValue({ others: 0 });

    const chassisNo = form.getFieldValue("chassisNo");
    platformApi.get(`/api/purchaseInvoice/chassis/${chassisNo}`).then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          if (response.data[0]) {
            let vehicleData = response.data[0];
            setInvoiceId(vehicleData.id);
            let setVehicleData = [];
            setVehicleData = vehicleData.purchaseChallan.vehicleDetail.filter(
              (data) => data.chassisNo === chassisNo
            );
            let modelC =
              setVehicleData[0].vehicle.modelCode +
              " - " +
              setVehicleData[0].vehicle.modelName;
            setDataSource([
              {
                name: modelC,
                engineNo: setVehicleData[0].engineNo,
                vpi: vehicleData.invoiceNo,
                color: setVehicleData[0].color.color,
                chassisNo: setVehicleData[0].chassisNo,
                id: setVehicleData[0].id,
              },
            ]);

            setChallan(vehicleData.purchaseChallan.id);
            setInvoiceDate(new Date(vehicleData.invoiceDate));

            let igst = 0;
            let cess = 0;

            igst +=
              vehicle[
                vehicle.findIndex(
                  (o) => o.modelCode === setVehicleData[0].vehicle.modelCode
                )
              ].hsn.igst;
            cess +=
              vehicle[
                vehicle.findIndex(
                  (o) => o.modelCode === setVehicleData[0].vehicle.modelCode
                )
              ].hsn.cess;

            let results = [];
            let index = {};

            vehicleData.purchaseChallan.vehicleDetail.forEach((point) => {
              let key =
                "" + point.vehicle.modelCode + "||" + point.vehicle.modelName;
              if (key in index) {
                index[key].quantity++;
              } else {
                let newEntry = {
                  modelCode: point.vehicle.modelCode,
                  modelName: point.vehicle.modelName,
                  quantity: 1,
                  amount: 0,
                  price: point.invoiceAmount,
                };
                index[key] = newEntry;
                results.push(newEntry);
              }
            });

            let quant =
              results[
                results.findIndex(
                  (obj) => obj.modelCode === setVehicleData[0].vehicle.modelCode
                )
              ].quantity;

            let grost = setVehicleData[0].invoiceAmount / quant;
            let nett = grost + grost * (igst / 2 / 100) * 2 + cess;
            form.setFieldsValue({
              supplier: vehicleData.purchaseChallan.supplier.name,
              location: vehicleData.purchaseChallan.branch.name,
              invoiceDate: new Date(
                vehicleData.invoiceDate
              ).toLocaleDateString(),
              AinvoiceNo: vehicleData.invoiceNo,
              // grossTotal: setVehicleData[0].invoiceAmount,
              grossTotal: grost,
              igst: igst,
              cgst: igst / 2,
              sgst: igst / 2,
              cess: cess,
              net: nett,
              round: Math.round(nett),
            });

            setAmount(nett);
          } else {
            message.error("Chassis No. Associated Vehicle Not Found", 3);
            form.setFieldsValue({
              supplier: undefined,
              location: undefined,
              invoiceDate: undefined,
              AinvoiceNo: undefined,
            });
            setChallan(undefined);
            setInvoiceDate(undefined);
            setDataSource([]);
          }
        }
      }
    });
  };

  return (
    <Modal
      title={<Title level={4}>Vehicle Purchase Return</Title>}
      visible={open}
      onCancel={() => {
        close();
        clearFields();
      }}
      okText={"Save"}
      okButtonProps={editable ? {} : { style: { display: "none" }, loading, disabled: (!editable && !modify) }}
      onOk={() => handleSubmit()}
      wrapClassName="parts-modal"
    >
      <Form>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item
              label={<Text>Vehicle Return Invoice No.</Text>}
              colon={false}
              required={false}
            >
              {getFieldDecorator(
                "invoiceNo"
                // , {
                //   rules: [
                //     {
                //       required: true,
                //       message: "Enter Vehicle Return Invoice No.",
                //     },
                //   ],
                // }
              )(<Input disabled={true} placeholder={props.id} />)}
            </Item>
          </Col>
          <Col span={10}>
            <Item label={<Text>Date</Text>} colon={false} required={false}>
              {getFieldDecorator(
                "currDate"
                // , {
                //   rules: [
                //     {
                //       required: true,
                //       message: "Enter Current Date",
                //     },
                //   ],
                // }
              )(
                <Input
                  disabled={true}
                  placeholder={currentDate.toLocaleDateString()}
                />
              )}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item label="Chassis No" colon={false}>
              <Row type="flex" justify="space-around">
                <Col span={18}>
                  {getFieldDecorator("chassisNo")(
                    <Input
                      disabled={!editable}
                      placeholder="Enter Chassis Number"
                    />
                  )}
                </Col>
                <Col span={6}>
                  {editable && (
                    <Button
                      onClick={() => {
                        searchVehicle();
                      }}
                      type="link"
                    >
                      Search
                    </Button>
                  )}
                </Col>
              </Row>
            </Item>
          </Col>
          <Col span={10}>
            <Row gutter={16}>
              <Col span={12}>
                <Item label="Associated Invoice No." colon={false}>
                  {getFieldDecorator("AinvoiceNo", {
                    rules: [
                      {
                        required: false,
                        message: "Enter Associated Invoice No.",
                      },
                    ],
                  })(
                    // <Select
                    //   disabled={!editable}
                    //   placeholder="Associated Invoice No."
                    //   onChange={(e) => handleInvoiceChange(e)}
                    // >
                    //   {invoice.map((item, index) => (
                    //     <Select.Option key={item.id}>{item.name}</Select.Option>
                    //   ))}
                    // </Select>
                    <Input
                      disabled={true}
                      placeholder="Associated Invoice No."
                    />
                  )}
                </Item>
              </Col>
              <Col span={12}>
                <Item label="Invoice Date" colon={false}>
                  {getFieldDecorator("invoiceDate")(
                    <Input disabled={true} placeholder="Invoice Date" />
                  )}
                </Item>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={10}>
            <Item label="Supplier" colon={false}>
              {getFieldDecorator("supplier", {
                rules: [{ required: false, message: "Enter Supplier" }],
              })(<Input disabled={true} placeholder="Supplier" />)}
            </Item>
          </Col>
          <Col span={10}>
            <Item label="Location" colon={false}>
              {getFieldDecorator("location", {
                rules: [{ required: false, message: "Enter Location" }],
              })(<Input disabled={true} placeholder="Location" />)}
            </Item>
          </Col>
        </Row>
        <br />
        <Table
          rowKey={(record) => record.id}
          pagination={{ style: { display: "none" } }}
          columns={column}
          style={{ cursor: "pointer" }}
          tableLayout="fixed"
          dataSource={dataSource}
        />
        {/* <Button
          onClick={() => {
            addRow();
          }}
          style={{ marginLeft: "83%", width: "17%", marginTop: "1%" }}
          type="primary"
          disabled={dataSource.length === dataSourcez.length}
        >
          Add Vehicle
        </Button> */}
        <Row type="flex" justify="space-between">
          <Col span={4}>
            <Item
              label={<Text>Gross Total</Text>}
              colon={false}
              required={false}
            >
              {getFieldDecorator("grossTotal")(
                <Input
                  addonBefore="&#8377;"
                  disabled={true}
                  placeholder="Gross Total"
                />
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item label={<Text>Others</Text>} colon={false} required={false}>
              {getFieldDecorator(
                "others"
                // , {
                //   rules: [
                //     {
                //       // required: true,
                //       message: "Enter Others",
                //     },
                //   ],
                // }
              )(
                <InputNumber
                  disabled={!editable}
                  placeholder="Others"
                  onChange={othersChange}
                />
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item label={<Text>IGST</Text>} colon={false} required={false}>
              {getFieldDecorator(
                "igst"
                // , {
                //   rules: [
                //     {
                //       // required: true,
                //       message: "Enter IGST",
                //     },
                //   ],
                // }
              )(<Input addonAfter="%" disabled={true} placeholder="IGST" />)}
            </Item>
          </Col>
          <Col span={4}>
            <Item label={<Text>CGST</Text>} colon={false} required={false}>
              {getFieldDecorator(
                "cgst"
                // , {
                //   rules: [
                //     {
                //       // required: true,
                //       message: "Enter CGST",
                //     },
                //   ],
                // }
              )(<Input addonAfter="%" disabled={true} placeholder="CGST" />)}
            </Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={4}>
            <Item label={<Text>SGST</Text>} colon={false} required={false}>
              {getFieldDecorator(
                "sgst"
                // , {
                //   rules: [
                //     {
                //       // required: true,
                //       message: "Enter SGST",
                //     },
                //   ],
                // }
              )(<Input addonAfter="%" disabled={true} placeholder="SGST" />)}
            </Item>
          </Col>
          <Col span={4}>
            <Item label={<Text>Cess</Text>} colon={false} required={false}>
              {getFieldDecorator(
                "cess"
                // , {
                //   rules: [
                //     {
                //       // required: true,
                //       message: "Enter Cess",
                //     },
                //   ],
                // }
              )(<Input disabled={true} placeholder="Cess" />)}
            </Item>
          </Col>
          <Col span={4}>
            <Item label={<Text>Round Off</Text>} colon={false} required={false}>
              {getFieldDecorator(
                "round"
                // , {
                //   rules: [
                //     {
                //       // required: true,
                //       message: "Enter Round Off",
                //     },
                //   ],
                // }
              )(
                <Input
                  addonBefore="&#8377;"
                  disabled={true}
                  placeholder="Round Off"
                />
              )}
            </Item>
          </Col>
          <Col span={4}>
            <Item
              label={<Text>Net Amount</Text>}
              colon={false}
              required={false}
            >
              {getFieldDecorator(
                "net"
                // , {
                //   rules: [
                //     {
                //       // required: true,
                //       message: "Enter Net Amount",
                //     },
                //   ],
                // }
              )(
                <Input
                  addonBefore="&#8377;"
                  disabled={true}
                  placeholder="Net Amount"
                />
              )}
            </Item>
          </Col>

        </Row>
      </Form>
    </Modal>
  );
};

const WrappedAccessoriesModal = Form.create({ name: "normal_login" })(VPRModal);

export default WrappedAccessoriesModal;
