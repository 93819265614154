import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker
} from "antd";
import { platformApi } from "../../../../../api";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;

const PaymentData = ({ form }) => {
  useEffect(() => {
    // platformApi.get("/api/vehicleModel").then(res => {
    //   let { data } = res;
    //   if (data.code === 200) {
    //     let { response } = data;
    //     if (response.code === 200) {
    //       setModelData(response.data);
    //     }
    //   }
    // });
    //
    // platformApi.get("/api/user/saleChallan").then(res => {
    //   if (res.data.code !== 500) {
    //     let data = res.data.data;
    //     setBranchData(data);
    //   }
    // });
  }, []);

  const onChange = (date, dateString) => {
    setMfgDate(dateString);
  };

  const { getFieldDecorator } = form;
  return (
    <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Price</Text>}>
            {getFieldDecorator("price")(
              <Input disabled={!true} placeholder="Price" />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item colon={false} label={<Text>CGST</Text>}>
            {getFieldDecorator("cgst")(
              <Input disabled={!true} placeholder="CGST" />
            )}
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item colon={false} label={<Text>SGST</Text>}>
            {getFieldDecorator("sgst")(
              <Input disabled={!true} placeholder="SGST" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Hypothetication HP</Text>}>
            {getFieldDecorator("hypothetication")(
              <Input disabled={!true} placeholder="Hypothetication HP" />
            )}
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item colon={false} label={<Text>IGST</Text>}>
            {getFieldDecorator("igst")(
              <Input disabled={!true} placeholder="IGST" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Form.Item colon={false} label={<Text>Net Amount</Text>}>
            {getFieldDecorator("net")(
              <Input disabled={!true} placeholder="Net Amount" />
            )}
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item colon={false} label={<Text>Cess</Text>}>
            {getFieldDecorator("cess")(
              <Input disabled={!true} placeholder="Cess" />
            )}
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

const WrappedPaymentData = Form.create()(PaymentData);

export default WrappedPaymentData;
