import React, { useEffect } from "react";
import {
  Modal,
  Row,
  message,
  Typography,
  Col,
  Select,
  Input,
  DatePicker,
  TimePicker,
  Button,
} from "antd";
import { useState } from "react";
import { platformApi } from "../../../../api";
const AttachQuotation = (props) => {
  const { visibility, setVisibility, onSave } = props;
  const [quotations, setQuotations] = useState([]);
  const [quotationData, setQuotationData] = useState([]);
  const { Title } = Typography;
  const close = () => {
    setQuotations([]);
    setQuotationData([]);
    setVisibility(false);
  };

  const getQuotations = () => {
    platformApi
      .get(`/api/quotation`)
      .then((result) => {
        const {
          data: {
            code,
            response: { data },
          },
        } = result;
        if (code === 200) {
          setQuotationData(data);
        }
      })
      .catch((error) => {
        message.error("Unable to get quotations", 2);
      });
  };

  useEffect(() => {
    if (visibility) getQuotations();
  }, [visibility]);

  const onSearchQuotation = (search) => {
    platformApi
      .post("/api/quotation/quotationSearch", {
        module: search.module,
        column: search.column,
        searchString: search.searchString,
        fields: search.fields,
        size: 100,
        page: 1,
        except: null,
      })
      .then((res) => {
        search.setValue(res.data.response.data);
      });
  };

  // const handleQuotations = (event) => {
  //   platformApi.get("/api/quotation/quotationSearch", search).then().catch();
  // };

  // const filterMethod = (input, option) =>
  //   option.props.children.toString().toLowerCase().indexOf(input) >= 0;

  return (
    <Modal
      title={<Title level={4}>Attach Quotation Modal</Title>}
      visible={visibility}
      cancelText={"Cancel"}
      okText="Attach"
      onOk={() => {
        onSave(quotations), close();
      }}
      onCancel={() => {
        close();
      }}
    >
      <Row type="flex" justify="space-around" gutter={[16, 16]}>
        <Col span={12}>
          <Select
            showSearch
            showAction={["click", "focus"]}
            mode="multiple"
            placeholder="Enter Quotation"
            filterOption={null}
            onSearch={(e) => {
              const search = {
                module: "quotations",
                column: "quotationId",
                searchString: e,
                searchColumns: ["quotationId"],
                setValue: setQuotationData,
              };
              onSearchQuotation(search);
            }}
            onChange={(data) => setQuotations(data)}
          >
            {quotationData.length > 0 &&
              quotationData.map((object) => (
                <Select.Option key={object.id} value={object.id}>
                  {object.quotationId}
                </Select.Option>
              ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
};

export default AttachQuotation;
