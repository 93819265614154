import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Result,
  Tooltip
} from "antd";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';
import "./index.less";
import { platformApi } from "../../../api";
// Form and Table Imports
import ManufacturerTable from "./ManufacturerTable";
import WrappedAccessoriesModal from "./ManufacturerForm";
import WrappedAdvancedFilter from "./AdvancedFilter";
import { ContextAPI } from "../../../ContextAPI";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

const Manufacturers = (props) => {
  const [filteropen, setFilteropen] = useState(false);
  const [limit, setLimit] = useState(10);
  const [img, setImg] = useState(false);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [dataSource, setData] = useState([]);
  const [selected, setSelected] = useState(null);
  const [spinning, setSpinning] = useState(false);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false)
  const { loginCredintials } = useContext(ContextAPI)

  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  useEffect(() => {
    loginCredintials.roleAccess && loginCredintials.roleAccess.map(each => {
      if (each.subModule === 'manufacturer') {
        if (each.access.create) {
          setCreateAccess(true)
        }
        if (each.access.update) {
          setModifyAccess(true)
        }
      }
    })
    setPrimaryData();
  }, []);

  useEffect(() => {
    setPrimaryData(search)
  }, [search, page, limit])

  const setPrimaryData = (string, val) => {
    setSpinning(true);
    platformApi
      .post("/api/manufacturer/get", { page: val ? val : page, size: limit, searchString: string })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(response.data.manufacturer);
            setCount(response.data.count);
          } else {
            message.error("Unable to fetch Manufacturers", 2);
          }
        } else {
          message.error("Unable to fetch Manufacturer", 2);
        }
      })
      .catch((err) => {
        setSpinning(false);
        console.error("Error on financer : ", err);
        message.error("Unable to fetch Manufacturer", 2);
      });
  };

  const addData = (manufacturer) => {
    setSpinning(true);
    if (manufacturer.id) {
      platformApi
        .put(`/api/manufacturer/${manufacturer.id}`, manufacturer)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (manufacturer.id === obj.id)
                  dataSource[index] = response.data;
              });
              setData([...dataSource]);

              message.success("Manufacturer saved successfully", 2);
            } else {
              message.error("Unable to save manufacturer", 2);
            }
          } else {
            message.error("Unable to save manufacturer", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to save manufacturer");
          console.error("Error on Manufacturer : ", error);
        });
    } else {
      platformApi
        .post("/api/manufacturer", manufacturer)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Manufacturer added successfully", 2);
            } else {
              message.error("Unable to add manufacturer", 2);
            }
          } else if (data.code == 500 && data.err.code == 500) {
            message.error("Manufacturer name already exists", 2);
          } else {
            message.error("Unable to add manufacturer", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to add manufacturer", 2);
          console.error("Error on manufacturer : ", error);
        });
    }
  };

  const deleteData = (id) => {
    let tamp = page != 1 && dataSource.length === 1;
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    setSpinning(true);
    platformApi
      .delete(`/api/manufacturer/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            message.success("Manufacturer deleted successfully", 2);
            if (temp) {
              setPage(1);
              setPrimaryData(null, 1)
            }
            else {
              setPrimaryData();
            }
          } else {
            message.error("Unable to delete manufacturer", 2);
          }
        } else {
          message.error("Unable to delete manufacturer", 2);
        }
      })
      .catch((error) => {
        message.error("Unable to delete manufacturer", 2);
        console.error("Error on manufacturer : ", error);
      });
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Manufacturer [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                  <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Manufacturer"
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        {/* <div style={{width:"20%",textAlign:"center"}}>
          <Button type="link"onClick={()=>{filteropen?setFilteropen(false):setFilteropen(true)}}>
            { filteropen?"Back":"Advanced  Filters"}
          </Button>
        </div> */}
        <Button
          onClick={() => {
            setEditFlag(true);
            setAddFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Manufacturer
        </Button>
      </div>
      <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        close={setFilteropen}
        id={filter}
        setId={setFilter}
        setPrimaryData={setPrimaryData}
        setData={setData}
      />
      <ManufacturerTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          total: count,
          current: page,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        delete={(id) => deleteData(id)}
        dataSource={dataSource}
        spinner={{
          spinning,
        }}
      />
      <WrappedAccessoriesModal
        data={dataSource[dataSource.findIndex((obj) => selected === obj.id)]}
        emitData={(data) => addData(data)}
        open={addFlag}
        setImg={setImg}
        close={() => {
          if (img) {
            window.location.reload();
          }
          setSelected(null);
          setAddFlag(false);
        }}
        editable={editFlag}
        modify={modifyAccess}
      />
    </div>
  );
}

export default withRouter(Manufacturers)