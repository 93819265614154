import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";
import { Row, Col, Progress } from 'antd';
import Text from "antd/lib/typography/Text";
import { platformApi } from "../../../../api";
import { PieChart } from 'react-minimal-pie-chart';
const JobCardDashBoard = (props) => {
    const { timeline, fromDate, toDate } = props;
    const [free, setFree] = useState(0);
    const [paid, setPaid] = useState(0);
    const [Acci, setAcci] = useState(0);
    const [Quick, setQuick] = useState(0);
    const [total, setTotal] = useState(0);
    useEffect(() => {
        platformApi.post("/api/reports/vehicleService", {
            timeline: timeline,
            from: fromDate,
            to: toDate
        }).then(res => {
            let { data } = res;
            let val = [];
            val.push(data.response.data['freeService'])
            val.push(data.response.data['paidService'])
            val.push(data.response.data['accidentalRepair'])
            val.push(data.response.data['quickRepair'])
            setFree(data.response.data['freeService']);
            setPaid(data.response.data['paidService']);
            setAcci(data.response.data['accidentalRepair']);
            setQuick(data.response.data['quickRepair'])

            setTotal(data.response.data['total'])

            setjobcardSeries([{ name: "Services", data: val }])
        }).catch(err => {
            console.log(err);
        })




        platformApi.post("/api/reports/totalVehicleCategory", {
            timeline: timeline,
            from: fromDate,
            to: toDate
        }).then(res => {
            let { data } = res;

            console.log(data)
            let val = [];





            val.push({
                title: 'SCOOTER',
                label: data.response.data.scooter,
                value: data.response.data.scooter,
                color: '#E38627'
            })
            val.push({
                title: 'MOTORCYCLE',
                label: data.response.data.motorCycle,
                value: data.response.data.motorCycle,
                color: '#C13C37'
            })

            settotalSeries(val)
        }).catch(err => {
            console.log(err);
        })



    }, [timeline, fromDate, toDate])


    const [jobcardSeries, setjobcardSeries] = useState([
        {
            name: "Services",
            data: [],
        },
    ]);


    const jobcardOptions = {
        chart: {
            id: "apexchart-example",
            type: "bar",
            stacked: false,
            stackType: '100%',
        },
        legend: {
            show: false
        },
        xaxis: {
            categories: [
                "Free",
                "Paid",
                "Accidental",
                "Quick"
            ],
            labels: {
                style: {
                    colors: ['#33B2DF', '#F48024', '#5ECC39', '#3999CC'],
                }
            }
        },
        colors: ['#33B2DF', '#F48024', '#5ECC39', '#3999CC'],
        plotOptions: {
            bar: {
                distributed: true
            }
        }
    };



    const [totalSeries, settotalSeries] = useState([
        { title: 'SCOOTER', value: 0, color: '#E38627' },
        { title: 'MOTORCYCLE', value: 0, color: '#C13C37' }
    ]);



    return (
        <div style={{ position: "relative", right: "0rem" }}>
            <div style={{ marginBottom: "10px" }}>


            </div>
            <Text style={{ fontWeight: "bold", position: "relative", right: "0rem" }}>
                Job Card Report
            </Text>

            <Row type="flex" justify="space-between" style={{ border: "1px solid black", width: "66.5rem", height: "20rem" }}>

                <Col span={4}>
                    <Row gutter={20}>
                        <Chart
                            options={jobcardOptions}
                            series={jobcardSeries}
                            type="bar"
                            width={300}
                            height={320}
                        />
                    </Row>
                </Col>

                <Col span={18} style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>


                    <div style={{ width: '10rem', height: '10rem', position: "relative", top: "5rem", left: "18rem" }}>
                        <PieChart
                            segmentsShift={1}
                            label={(props) => { return props.dataEntry.value; }}
                            data={totalSeries}
                            
                        />
                    </div>
                    <div style={{ marginTop: '5px', position: "relative", right: "14rem", bottom: "4rem" }}>

                        <Col span={12} style={{ marginBottom: '20px', marginTop: '10px', marginRight: '90px' }}>
                            <Row style={{
                                background: "white",
                                padding: "0px 10px",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "16vh",
                                width: "30vh",
                                boxShadow: "0px 10px 20px rgba(0,0,0,0.13)",
                                borderRadius: '10px'
                            }}
                            >
                                <div style={{ marginBottom: "10px", fontSize: "15px", color: "#A8AFB3" }}>
                                    Total Vehicle Reporting
                                </div>
                                <div>{total} </div>
                            </Row>
                        </Col>
                    </div>
                    <div style={{ display: "flex", gap: 10, marginTop: "5px", marginLeft: "20px", position: "relative", bottom: "15rem" }}>
                        <div style={{
                            background: "white",
                            boxShadow: "0px 5px 10px rgba(0,0,0,0.13)",
                            height: "15vh",
                            width: "160px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: '5px'
                        }}
                        >
                            <div style={{ fontSize: "16px", color: "#A8AFB3" }}>Free Repair </div>
                            <div style={{ marginTop: "8px" }}>{free} </div>
                        </div>
                        <div style={{
                            background: "white",
                            boxShadow: "0px 5px 10px rgba(0,0,0,0.13)",
                            height: "15vh",
                            width: "160px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: '5px'
                        }}
                        >
                            <div style={{ fontSize: "16px", color: "#A8AFB3" }}>Paid Repair </div>
                            <div style={{ marginTop: "8px" }}>{paid} </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", gap: 10, marginTop: "5px", marginLeft: "20px", position: "relative", bottom: "15rem" }}>
                        <div style={{
                            background: "white",
                            boxShadow: "0px 5px 10px rgba(0,0,0,0.13)",
                            height: "15vh",
                            width: "160px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: '5px'
                        }}
                        >
                            <div style={{ fontSize: "16px", color: "#A8AFB3" }}>Accidental Repair</div>
                            <div style={{ marginTop: "8px" }}>{Acci} </div>
                        </div>
                        <div style={{
                            background: "white",
                            boxShadow: "0px 5px 10px rgba(0,0,0,0.13)",
                            height: "15vh",
                            width: "160px",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: '5px'
                        }}
                        >
                            <div style={{ fontSize: "16px", color: "#A8AFB3" }}>Quick Repair </div>
                            <div style={{ marginTop: "8px" }}>{Quick} </div>
                        </div>
                    </div>

                    {/*  */}







                </Col>
            </Row>
        </div>
    )
}
export default JobCardDashBoard




