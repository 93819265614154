import React, { useState } from "react";
import { withRouter } from 'react-router-dom';
import { Table, Divider, Popconfirm, Select ,Typography} from "antd";
import moment from "moment";
const {Text} = Typography;
const MaterialIssueTable = (props) => {
  // const { pagination, dataSource, spinner, openModal } = props;
  const { history, pagination, dataSource, openModal, spinner, modify, deleteData ,deleteAccess} = props
  const column = [
    {
      title: "Job No",
      dataIndex: "job",
      key: "jobNo",
      render: (record) => (
        record && <span>
          {record.jobNo}
        </span>
      ),
    },
    {
      title: "Customer Name",
      dataIndex: "job",
      key: "customer",
      render: (record) => (
        record.customer && <span>{record.customer.name}</span>
      ),
    },
    {
      title: "Model",
      dataIndex: "job",
      key: "vehicle",
      render: (record) => (
        record.vehicle && <span>{record.vehicle.vehicle && record.vehicle.vehicle.modelName}</span>
      ),
    },
    {
      title: "Issue Date",
      dataIndex: "issueDate",
      key: "issueDate",
      render: (record) => <span>{moment(record).format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Net Amount",
      dataIndex: "netAmount",
      key: "netAmount",
      render: (record) => <span>{record}</span>,
    },

    {
      title: "Action",
      render: (data) => (
        <div >

          { modify?
            data.job.jobStatus !== "Proforma Invoice" &&
            <span
              onClick={(event) => {
                event.stopPropagation();
                // openModal(data.id, true);
                history.push({
                  pathname: `/autoadmin/material_issue/${data.id}`,
                })
              }}
              className="linkylink"
            >
              Modify
            </span>
            :
            <span></span>
          }

          <Divider type="vertical" />
          { deleteAccess?
          <Popconfirm
            title="Do you want to delete this MaterialIssue?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              deleteData(data.id); (data.id && data.id);
            }}
          >
            <span
              onClick={(event) => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
          :
          <span></span>
    }
     {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }
        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      tableLayout="fixed"
      style={{ cursor: "pointer" }}
      dataSource={dataSource}
      // onRow={(props) => ({
      //   onClick: () => openModal(props.id, false),
      // })}
      onRow={(record) => ({
        onClick: () => {
          history.push({
            pathname: `/autoadmin/material_issue/${record.id}`,
          })
        },
      })}
      loading={spinner}
    />
  );
}
export default withRouter(MaterialIssueTable);