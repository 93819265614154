import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  message,
  Modal,
} from "antd";
import { platformApi } from "../../../../../api";
const { Item } = Form;
const { Text, Title } = Typography;
import moment from "moment";

const AuthenticationData = ({
  form,
  validityRef,
  clearRef,
  data,
  open,
  close,
  saveHere,
  editable,
  reload,
  id,
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const status = [
    { key: "ACCEPTED", title: "Accept" },
    { key: "PENDING", title: "Pending" },
    { key: "REJECTED", title: "Reject" },
  ];
  const [accessories, setAccessories] = useState([]);
  const [authorisedTime, setAuthorisedTime] = useState(null);
  const [isAuthorised, toggleAuthorise] = useState(false);
  const [errorObj, setErrorObj] = useState({});
  if (Object.keys(data).length === 0) {
    data = null
  }

  useEffect(() => {
    if (open) {
      if (data.price) {
        setAuthorisedTime(
          data.authorisedTime
            ? moment(data.authorisedTime).format("DD/MM/YYYY, h:mm:ss a")
            : null
        );
        toggleAuthorise(
          data.bookingStatus === "ACCEPTED" && data.authorisedTime ? false : true
        );
        setFieldsValue({
          onRoadPrice: data.price.onRoadPrice,
          tempRegister: data.price.tempRegister,
          onRoadDiscount: data.price.onRoadDiscount,
          netRecieveables: data.price.netRecieveables,
          numberPlate: data.price.numberPlate,
          specialNoCharges: data.price.specialNoCharges || 0,
          hypothetication: data.price.hp || 0,
          affidavit: data.price.affidavit || 0,
          loan: data.loan.loanAmount || 0,
          loanDisbursementAmount: data.loan.disbursementAmount || 0,
          downPayment: data.loan.downPayment || 0,
          finalAmount: data.price.finalAmount,
          accessoriesTotalDiscount: data.price.totalDiscount || 0,
          accessoriesTotalAfterDiscount: data.price.accessoriesTotalAfterDiscount || 0,
          accessoriesTotalPrice: data.price.accessoriesTotal,
          bookingStatus: data.bookingStatus ?? "PENDING",
        });
        setAccessories(data.accessories);
        setFieldsValue({});
      }
    }
  }, [open]);
  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    // if (isValid) {
    //   modalData.digital = digital;
    // }
    return isValid;
  };
  const sendConfirmSMS = () => {
    if (getFieldValue("bookingStatus") === "ACCEPTED")
      platformApi.post("/api/sendSms/bookingconfirm",
        {
          phone: data.customer ? data.customer.contacts[0].phone : data.customerPhone,
          type: "WhatsApp",
          smsData: {
            link: data.authentication.beforeVerification,
            cname: data.customer ? data.customer.name : data.customerName,
            slex: data.executive,
            bkid: data.bookingId,
            vname: data.vehicle.modelName,
            dlr: data.branch
          }
        })
        .then(res => {
          const { data } = res;
          if (data.code === 200) {
            message.success("Message sent successfully");
          }
          else if (data.code === 401) {
            message.error(data.message)
          }
          else if (data.code === 400) {
            message.error(data.message)
          }
          else {
            message.error("Unable to send SMS");
          }
        })
  }
  const clearFields = () => {
    form.resetFields();
    setErrorObj({});
    setAuthorisedTime(null)
    toggleAuthorise(false)
    setAccessories([])
  };
  const authorise = () => {
    if (getFieldValue("password") === "" || !getFieldValue("password")) {
      setErrorObj({
        ...errorObj,
        password: {
          type: "error",
          message: "Enter Password",
        },
      });
    } else {
      delete errorObj.password;
      setErrorObj(errorObj);
      platformApi
        .post("/api/booking/authorise", {
          password: getFieldValue("password"),
          status: getFieldValue("bookingStatus"),
          bookingId: data.id
        })
        .then((res) => {
          if (res.data.code === 200) {
            message.success("Booking Status Updated", 2);
            data.authorisedTime = moment(new Date()).format(
              "DD/MM/YYYY, h:mm:ss a"
            );
            data.bookingStatus = getFieldValue("bookingStatus");
            setAuthorisedTime(
              moment(new Date()).format("DD/MM/YYYY, h:mm:ss a")
            );
            // saveHere();
            sendConfirmSMS();
            clearFields();
            close()
            reload()
          } else {
            message.error(res.data.message, 2);
            setErrorObj({
              ...errorObj,
              password: {
                type: "error",
                message: "Password seems to be wrong!!",
              },
            });
          }
        });
    }
  };
  return data ? (
    <div>
      <Modal
        wrapClassName="vehicle-form"
        visible={open}
        title={<Title level={4}>Supervisor Authentication</Title>}
        onCancel={() => {
          clearFields();
          close();
        }}
        okButtonProps={{ disabled: !editable }}
        okText={"Authorise"}
        onOk={() => (authorise())}
      >
        <Form style={{ marginTop: "4%", marginBottom: "4%" }}>
          <Row>
            <Text level={2} strong>
              Booking Authorisation
        </Text>
            <br />
            <Text>I here by authorise the Booking of the Vehicle.</Text>
            <br />
            <Text strong>Vehicle Model</Text>:
        {data
              ? data.vehicle.modelName
              : data.selectedVehicle[0].vehicleDetail.modelName}
            <br />
            <Text strong>Vehicle Manufacturer</Text>:
        {data
              ? data.vehicle.manufacturer.name
              : data.selectedVehicle[0].vehicleDetail.manufacturer.name}
            <br />
            <Text>Accesories List: </Text>
            {accessories.map((data, key) => {
              return (
                <Text key={true}>{data.accessory.name + (accessories[key + 1] ? ", " : ".")}</Text>
              );
            })}
          </Row>
          <Row type="flex" justify="space-around">
            <Col span={7}>
              <Form.Item colon={false} label={<Text>Accesories Total</Text>}>
                {getFieldDecorator("accessoriesTotalPrice")(
                  <Input
                    min="0"
                    addonBefore="&#8377;"
                    maxLength={10}
                    disabled={true}
                    placeholder="0"
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item colon={false} label={<Text>Accesories Total Discount</Text>}>
                {getFieldDecorator("accessoriesTotalDiscount")(
                  <Input
                    min="0"
                    addonBefore="&#8377;"
                    maxLength={10}
                    disabled={true}
                    placeholder="0"
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item colon={false} label={<Text>Accesories Total (after discount)</Text>}>
                {getFieldDecorator("accessoriesTotalAfterDiscount")(
                  <Input
                    min="0"
                    addonBefore="&#8377;"
                    maxLength={10}
                    disabled={true}
                    placeholder="0"
                  />
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ margin: "10px 0px 10px 0px" }}>
            <br />
            {
              <Text>
                To Customer <Text strong>{data.customer ? data.customer.name : data.customerName}</Text> at the
            Following Prices
          </Text>
            }
            <br />
            <Text strong>
              Payment Mode:{" "}
              <Text>{data.price && data.price.paymentMode.toUpperCase()}</Text>
            </Text>
            <br />
          </Row>
          <Row
            style={{
              display:
                data.loan && data.loan.loanType !== "companyAssist"
                  ? "none"
                  : "block",
            }}
          >
            <Row>
              <Text strong>Finance Charges</Text>
            </Row>
            <Row type="flex" justify="space-between">
              <Col span={7}>
                <Form.Item
                  colon={false}
                  label={<Text>Loan Disbursement Amount</Text>}
                >
                  {getFieldDecorator("loanDisbursementAmount")(
                    <Input addonBefore="&#8377;" disabled={true} placeholder="0" />
                  )}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item colon={false} label={<Text>Loan Amount</Text>}>
                  {getFieldDecorator("loan")(
                    <Input addonBefore="&#8377;" disabled={true} placeholder="0" />
                  )}
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item colon={false} label={<Text>Down Payment</Text>}>
                  {getFieldDecorator("downPayment")(
                    <Input addonBefore="&#8377;" disabled={true} placeholder="0" />
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Row>
          <Row>
            <br />
            <Text strong>Overall Charges</Text>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={7}>
              <Form.Item colon={false} label={<Text>On Road Price</Text>}>
                {getFieldDecorator("onRoadPrice")(
                  <Input addonBefore="&#8377;" disabled={true} placeholder="0" />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                colon={false}
                label={<Text>Temporary Registration Charges</Text>}
              >
                {getFieldDecorator("tempRegister")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    placeholder="0"
                    onBlur={(e) => {
                      // addFinalAmount(e);
                      addFinalAmount({
                        target: { value: 0 },
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item colon={false} label={<Text>Hypothetication</Text>}>
                {getFieldDecorator("hypothetication")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    placeholder="0"
                    onBlur={(e) => {
                      // addFinalAmount(e);
                      addFinalAmount({
                        target: { value: 0 },
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item colon={false} label={<Text>Number Plate Charges</Text>}>
                {getFieldDecorator("numberPlate")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    placeholder="0"
                    onBlur={(e) => {
                      addFinalAmount(e);
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item colon={false} label={<Text>Affidavit Amount</Text>}>
                {getFieldDecorator("affidavit")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    placeholder="0"
                    onBlur={(e) => {
                      // addFinalAmount(e);
                      addFinalAmount({
                        target: { value: 0 },
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>

            <Col span={7}>
              <Form.Item colon={false} label={<Text>Special No. Charges</Text>}>
                {getFieldDecorator("specialNoCharges")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    placeholder="0"
                    onBlur={(e) => {
                      // addFinalAmount(e);
                      addFinalAmount({
                        target: { value: 0 },
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={7}>
              <Form.Item colon={false} label={<Text>On Road Discount</Text>}>
                {getFieldDecorator("onRoadDiscount")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    placeholder="0"
                    onBlur={(e) => {
                      // decFinalAmount(e);
                      addFinalAmount({
                        target: { value: 0 },
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item colon={false} label={<Text>Net Receivables</Text>}>
                {getFieldDecorator("netRecieveables")(
                  <Input
                    addonBefore="&#8377;"
                    disabled={true}
                    placeholder="0"
                    onBlur={(e) => {
                      // decFinalAmount(e);
                      addFinalAmount({
                        target: { value: 0 },
                      });
                    }}
                  />
                )}
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item colon={false} label={<Text>Final Amount</Text>}>
                <Row>
                  <Col span={24}>
                    {getFieldDecorator("finalAmount")(
                      <Input
                        addonBefore="&#8377;"
                        disabled={true}
                        placeholder="0"
                      />
                    )}
                  </Col>
                </Row>
              </Form.Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={4}>
              <Item label="Status" colon={false}>
                {getFieldDecorator("bookingStatus")(
                  <Select disabled={!editable} showAction={["click", "focus"]} placeholder="Status">
                    {status.map((data) => (
                      <Select.Option key={data.key}>{data.title}</Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            {isAuthorised && (
              <Col span={19}>
                <Form.Item
                  validateStatus={errorObj.password && errorObj.password.type}
                  help={errorObj.password && errorObj.password.message}
                  colon={false}
                  label={<Text>Password</Text>}
                >
                  <Row type="flex" justify="space-between">
                    <Col span={8}>
                      {getFieldDecorator("password")(
                        <Input.Password
                          disabled={!editable}
                          placeholder="Password"
                          onBlur={(event) => {
                            if (event.target.value === '') {
                              setErrorObj({
                                ...errorObj,
                                password: {
                                  type: "error",
                                  message: "Enter Password",
                                },
                              });
                            } else {
                              setErrorObj({});
                            }
                          }}
                        />
                      )}
                    </Col>
                    <Col span={15}>
                      {/* <Button type="primary" onClick={authorise}>
                        Authorise
                  </Button> */}
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col span={15} marginTop="15px">
              {authorisedTime && (
                <Text>
                  <Text strong>Authorized Time</Text>:{authorisedTime}
                </Text>
              )}
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  ) : (
      ""
    );
};

const WrappedAuthenticationData = Form.create()(AuthenticationData);

export default WrappedAuthenticationData;
