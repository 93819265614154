import React, { useState, useEffect } from "react";
import { Form, Typography, Row, Col, Checkbox, Radio } from "antd";
import placeholder from "../../../../../../../assets/vehicle-placeholder.jpg";
import PriceTable from "./PriceTable";
import TaxTable from "./TaxTable";
import { isString, isArray } from "lodash";

const { Item } = Form;
const { Text } = Typography;

const PriceModal = ({ form, open, editable, data, validityRef }) => {
  const [priceList, setPriceList] = useState({ total: undefined });
  const [vehicle, setVehicle] = useState(null);
  const [price, setPrice] = useState(null);
  const [dataSource, setDataSource] = useState([]);
  // const [colorSelected, setColorSelected] = useState([]);
  const [otherChecked, setOthersChecked] = useState([]);
  const [colorSelect, setColorSelect] = useState({});

  const { getFieldDecorator, resetFields, setFieldsValue } = form;

  const insTypes = [
    { key: "insurance1plus5", title: "1+5" },
    { key: "insurance5plus5", title: "5+5" },
    { key: "insurance1plus5ZD", title: "1+5 Zero Dep" },
    { key: "insurance5plus5ZD", title: "5+5 Zero Dep" },
  ];

  const others = [
    { key: "amc", title: "Amc" },
    { key: "rsa", title: "Rsa" },
  ]
  const priceType = [
    { key: "showroomPrice", title: "Showroom Price" },
    { key: "roadTax", title: "Road Tax" },
    { key: "warrantyPrice", title: "Warranty Price" },
    { key: "registrationFee", title: "Registration Fee" },
    { key: "handlingCharges", title: "Handling Charges" },
  ];

  useEffect(() => {
    validityRef.current = () => {
      return handleSubmit();
    };
    return () => {
      validityRef.current = null;
    };
  });

  useEffect(() => {
    if (open) {
      if (data) {
        const { vehicleDetail, insuranceType, price, color } = data;
        if (vehicleDetail) {
          setVehicle(vehicleDetail);
          if (color) setColorSelect(color);
          if (price) {
            setPrice(price);
            const total = fetchPrice(price);
            if (insuranceType) {
              const insurance = insuranceType.map((insure) => {
                return insure.type;
              });
              getFieldDecorator('insurance', { initialValue: insurance })
              calculateTotal(insurance, price, { total });
            }
          }
        }
      }
    } else {
      form.resetFields();
      setPriceList({});
      setVehicle(null);
      setDataSource([]);
      resetFields();
    }
  }, [open, data]);

  const handleSubmit = () => {
    let error;
    form.validateFields((validationError) => {
      error = validationError === null;
      if (error) {
        const insurance = [];
        const optional = [];
        if (!data.color) {
          data.color = vehicle.image[0].id;
        }
        // console.log("insTypes", insTypes)
        // console.log("others", others)
        insTypes.forEach((types) => {
          if (priceList[types.key]) {
            insurance.push({
              type: types.key,
              amount: priceList[types.key] - priceList.total,
              onRoad: priceList[types.key],
            });
          }
        });
        others.forEach((val) => {
          if (priceList[val.key]) {
            optional.push({
              type: val.key,
              amount: priceList[val.key]
            })
          }
        })
        data.optionalType = optional;
        data.insuranceType = insurance;
      }
    });
    return error;
  };

  const calculateTotal = (checked, mockPrice = {}, mockTotal = {}) => {
    let arr = isArray(checked) ? [checked[0]] : [checked.target.value];
    isArray(checked) ? getFieldDecorator('insurance', { initialValue: checked[0] })
      : null;
    const loadedPrice = mockPrice.id ? mockPrice : price;
    let prices = mockTotal.total ? mockTotal : priceList;
    insTypes.forEach((types) => {
      if (prices[types.key]) {
        delete prices[types.key];
      }
      if (arr.includes(types.key)) {
        // if (checked.includes(types.key)) {
        prices = {
          ...prices,
          [types.key]: prices.total + loadedPrice[types.key],
        };
      }
    });
    // if (optional) {
    //   others.forEach((type) => {
    //     if (prices[type.key]) {
    //       delete prices[type.key];
    //     }
    //     if (optional.includes(type.key)) {
    //       prices = {
    //         ...prices,
    //         total: prices.total + loadedPrice[type.key],
    //         // [type.key]: loadedPrice[type.key]
    //       }
    //       if (arr.includes("insurance1plus5")) {
    //         prices = {
    //           ...prices,
    //           insurance1plus5: prices.total + loadedPrice.insurance1plus5,
    //         }
    //       }
    //       if (arr.includes("insurance5plus5")) {
    //         prices = {
    //           ...prices,
    //           insurance5plus5: prices.total + loadedPrice.insurance5plus5,
    //         }
    //       }
    //       if (arr.includes("insurance1plus5ZD")) {
    //         prices = {
    //           ...prices,
    //           insurance1plus5ZD: prices.total + loadedPrice.insurance1plus5ZD
    //         }
    //       }
    //       if (arr.includes("insurance5plus5ZD")) {
    //         prices = {
    //           ...prices,
    //           insurance5plus5ZD: prices.total + loadedPrice.insurance5plus5ZD
    //         }
    //       }
    //     }
    //   })
    // }
    setPriceList({ ...prices });
  };

  const calculateAMCRSATotal = (checked, mockPrice = {}, mockTotal = {}) => {
    setOthersChecked(checked)
    const loadedPrice = mockPrice.id ? mockPrice : price;
    let prices = mockTotal.total ? mockTotal : priceList;
    others.forEach((type) => {
      if (prices[type.key]) {
        delete prices[type.key];
      }
      if (checked.includes(type.key)) {
        prices = {
          ...prices,
          total: priceList.total + loadedPrice[type.key],
          [type.key]: loadedPrice[type.key]
        }
        if (priceList.insurance1plus5) {
          prices = {
            ...prices,
            insurance1plus5: priceList.insurance1plus5 + loadedPrice[type.key],
          }
        }
        if (priceList.insurance5plus5) {
          prices = {
            ...prices,
            insurance5plus5: priceList.insurance5plus5 + loadedPrice[type.key],
          }
        }
        if (priceList.insurance1plus5ZD) {
          prices = {
            ...prices,
            insurance1plus5ZD: priceList.insurance1plus5ZD + loadedPrice[type.key],
          }
        }
        if (priceList.insurance5plus5ZD) {
          prices = {
            ...prices,
            insurance5plus5ZD: priceList.insurance5plus5ZD + loadedPrice[type.key],
          }
        }
      }
    })
    setPriceList({ ...prices });
  };

  const fetchPrice = (data) => {
    let total = 0,
      source = [];

    priceType.forEach((type) => {
      if (data[type.key]) {
        source.push({ type: type.title, price: data[type.key] });
        total += data[type.key];
      }
    });
    setPriceList({ total });
    setDataSource(source);
    return total;
  };

  return (
    <Form>
      <Row type="flex" justify="space-around">
        <Col span={11}>
          <Item>
            {vehicle &&
              (vehicle.image ? (
                (vehicle.image.url && (
                  <img
                    // src={vehicle.image.url}
                    src={colorSelect.url}
                    alt="Vehicle"
                    width="100%"
                    onError={(event) => (event.target.src = placeholder)}
                  />
                )) ||
                (vehicle.image[0] && (
                  <img
                    // src={vehicle.image[0].url}
                    src={colorSelect.url}
                    alt="Vehicle"
                    width="100%"
                    onError={(event) => (event.target.src = placeholder)}
                  />
                ))
              ) : (
                  <img width="100%" src={placeholder} alt="Vehicle" />
                ))}

            {/* <Carousel afterChange={carouselChange}>
              {vehicle &&
                vehicle.image.map((obj) => (
                  <div>
                    <img
                      src={obj.url}
                      alt={obj.color}
                      style={{ width: "100%", height: "50vh" }}
                    />
                    <span style={{ color: "white" }}>Color : {obj.color}</span>
                  </div>
                ))}
            </Carousel> */}
          </Item>
          <Item>
            {vehicle && (
              <div>
                <Text strong>Selected Model</Text>
                <div
                  style={{
                    border: "1px solid gray",
                    borderRadius: "1%",
                    padding: "1%",
                  }}
                >
                  <Row>
                    <Text strong>Model Name : </Text>
                    <Text>{vehicle.modelName}</Text>
                  </Row>
                  <Row>
                    <Text strong>Model Code : </Text>
                    <Text>{vehicle.modelCode}</Text>
                  </Row>
                  <Row>
                    <Text strong>Selected Color : </Text>
                    <Text>{colorSelect.color}</Text>
                  </Row>
                </div>
              </div>
            )}
          </Item>
        </Col>
        <Col span={11}>
          <Col span={12}>
            <Row>
              {/* {vehicle && price && (
              <Item label={<Text>Insurance Type</Text>} colon={false}>
                {getFieldDecorator("insurance")(
                  <Checkbox.Group
                    disabled={!editable}
                    onChange={(checked) => {
                      calculateTotal(checked);
                    }}
                  >
                    <Row type="flex" justify="space-between">
                      {insTypes.map((types) => {
                        if (price[types.key]) {
                          return (
                            <Col span={12}>
                              <Checkbox key={types.key} value={types.key}>
                                {types.title} - &#8377;
                                {price[types.key]}
                              </Checkbox>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Checkbox.Group>
                )}
              </Item>
            )} */}

              {vehicle && price && (
                <Item label={<Text>Insurance Type</Text>} colon={false}>
                  {getFieldDecorator("insurance", {
                    rules: [{ required: true, message: "Select Insurance Type" }],
                  })(
                    <Radio.Group
                      disabled={!editable}
                      onChange={(checked) => {
                        calculateTotal(checked);
                      }}
                    >
                      <Row type="flex" justify="space-between">
                        {insTypes.map((types) => {
                          if (price[types.key]) {
                            return (
                              <Col span={12}>
                                <Radio value={types.key}>
                                  {types.title} - &#8377;
                                {price[types.key]}
                                </Radio>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </Radio.Group>
                  )}
                </Item>
              )}
            </Row>

          </Col>
          <Col span={12}>
            <Row>
              {vehicle && price && (
                <Item label={<Text>Others</Text>} colon={false}>
                  <Checkbox.Group
                    disabled={!editable}
                    onChange={(checked) => {
                      calculateAMCRSATotal(checked);
                    }}
                    value={otherChecked}
                  >
                    <Row type="flex" justify="space-between">
                      {others.map((types) => {
                        if (price[types.key]) {
                          return (
                            <Col span={22}>
                              <Checkbox key={types.key} value={types.key}>
                                {types.title} - &#8377;
                              {price[types.key]}
                              </Checkbox>
                            </Col>
                          );
                        }
                      })}
                    </Row>
                  </Checkbox.Group>
                </Item>
              )}
            </Row>
          </Col>
          {/* <Row>
            {vehicle&&vehicle.image  && (
              <Item label={<Text>Color Selection</Text>} colon={false}>
               
                  <Checkbox.Group
                    disabled={!editable}
                    onChange={(checked) => {
                      let id=checked[0];
                      setColorSelected(id)
                    }}
                    value={[colorSelected]}
                  >
                    <Row type="flex" justify="space-between">
                      {vehicle.image.map((types) => {
                          return (
                            <Col span={12}>
                              <Checkbox key={types.id} value={types.id}>
                                {types.color}
                              </Checkbox>
                            </Col>
                          );
                        
                      })}
                    </Row>
                  </Checkbox.Group>
                
              </Item>
            )}
          </Row> */}
          {!editable ? (
            <Row>
              <Item label={<Text>Color Select</Text>} colon={false}>
                <span>
                  <b>
                    {data.color
                      ? data.color.color
                      : "No color Selected for this Model"}
                  </b>
                </span>
              </Item>
            </Row>
          ) : (
              <span></span>
            )}
          <Row>
            <Item>
              <PriceTable dataSource={dataSource} />
            </Item>

            <Item>
              <TaxTable dataSource={[priceList]} />
            </Item>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

const PriceForm = Form.create({ name: "image" })(PriceModal);

export default PriceForm;
