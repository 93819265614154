import React, { useContext, useState, useEffect } from "react";
import { formatValue } from "../../../../utils"
import {
  Table,
  Typography,
  Row,
  Col,
  Button,
  Popconfirm,
  message,
  Avatar,
  Divider,
  Tag,
  Modal,
  Form,
  Select,
  Input,
  Icon,
  DatePicker,
  InputNumber,
} from "antd";
import { platformApi } from "../../../../api";
import plus from "../../../../assets/plus.svg"
import close from "../../../../assets/close.svg"
const { Title, Text } = Typography
const { Item } = Form
const { Option } = Select

const TargetModal = ({
  targetModal,
  setTargetModal,
  setTargetDataList,
  targetDataList,
  editTarget,
  setEditTarget,
  editTargetData,
  setEditTargetData,
  form
}) => {
  const {
    getFieldDecorator,
    setFieldsValue,
    getFieldValue,
    validateFields,
  } = form;
  let years = [1990, 1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999, 2000, 2001, 2002, 2003, 2004, 2005, 2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021, 2022, 2023, 2024, 2025, 2026, 2027, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039, 2040, 2041, 2042, 2043, 2044, 2045, 2046, 2047, 2048, 2049, 2050]
  const [targetType, setTargetType] = useState([])
  const [yearData, setYearData] = useState({})
  const [error, setError] = useState({});

  useEffect(() => {
    if (editTarget) {
      setFieldsValue({ "targetType": editTargetData.targetType })
      setFieldsValue({ "timeline": editTargetData.timeline })
      setFieldsValue({ "value": editTargetData.value })
      setYearData(editTargetData.yearly)
    }
  }, [editTarget])

  const addYearData = () => {
    let year = parseInt(getFieldValue("yearData"))
    let value = parseInt(getFieldValue("yearValue"))
    if (year && value) {
      if (Object.keys(yearData).indexOf("" + year) == -1) {
        yearData[year] = value
        setYearData(yearData)
        setFieldsValue({ "yearData": "", "yearValue": "" })
      }
      else {
        message.warning("Year Already Chosen.")
      }
    }
    else {
      message.warning("Fill All The Inputs")
    }

  }

  const removeYearData = (data) => {
    let newData = {}
    for (let key of Object.keys(yearData)) {
      if (key != data) {
        newData[key] = yearData[key]
      }
    }
    setYearData(newData)
  }

  const addTargetData = () => {
    validateFields((err) => {
      if (err == null && Object.keys(error).length == 0) {
        let monthly = {}
        let yearly = {}
        if (getFieldValue("timeline") == "Monthly") {
          monthly = {
            Jan: getFieldValue("Jan"),
            Feb: getFieldValue("Feb"),
            Mar: getFieldValue("Mar"),
            Apr: getFieldValue("Apr"),
            May: getFieldValue("May"),
            Jun: getFieldValue("Jun"),
            Jul: getFieldValue("Jul"),
            Aug: getFieldValue("Aug"),
            Sep: getFieldValue("Sep"),
            Oct: getFieldValue("Oct"),
            Nov: getFieldValue("Nov"),
            Dec: getFieldValue("Dec"),
          }
        }
        else {
          if (Object.keys(yearData).length == 0) {
            message.warning("Add atleast one year target")
            return
          }
          yearly = yearData
        }
        if (editTarget) {
          for (let item of targetDataList) {
            if (item === editTargetData) {
              item.targetType = getFieldValue("targetType")
              item.timeline = getFieldValue("timeline")
              item.value = getFieldValue("value")
              item.monthly = getFieldValue("timeline") === "Monthly" ? monthly : {}
              item.yearly = getFieldValue("timeline") === "Yearly" ? yearly : {}
            }
          }
        }
        else {
          let Data = {
            targetType: getFieldValue("targetType"),
            timeline: getFieldValue("timeline"),
            value: getFieldValue("value"),
            monthly: getFieldValue("timeline") === "Monthly" ? monthly : {},
            yearly: getFieldValue("timeline") === "Yearly" ? yearly : {},
          }
          targetDataList.push(Data)
        }
        setTargetDataList(targetDataList)
        form.resetFields()
        setTargetModal(false)
        setEditTargetData({})
        setEditTarget(false)
        setError({})
        setYearData({})
      }
    })

  }

  const checkTargetType = (s) => {
    for (let item of targetDataList) {
      if (item.targetType === s) {
        return false
      }
    }
    return true
  }

  return (
    <Modal
      wrapClassName="target-form"
      visible={targetModal}
      title="Add Target Data"
      onCancel={() => { setTargetModal(false); form.resetFields(); setTargetDataList(targetDataList); setYearData({}); setEditTargetData({}); setEditTarget(false) }}
      onOk={addTargetData}
      okText="Save"
    >
      <Form>
        <Row>
          <Col span={7}>
            <Item label="Target Type" >
              {getFieldDecorator("targetType", {
                rules: [{ required: true, message: "Select Target Type" }],
              })(
                <Select placeholder="Select Target Type" showAction={["click", "focus"]} allowClear>
                  {checkTargetType("Sales") ? <Option value="Sales">Sales</Option> : null}
                  {checkTargetType("Mechanical") ? <Option value="Mechanical">Mechanical</Option> : null}
                  {checkTargetType("Accesories") ? <Option value="Accesories">Accesories</Option> : null}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={1} />
          <Col span={7} >
            <Item
              label="Timeline"
            >
              {getFieldDecorator("timeline", {
                initialValue: "Monthly",
                rules: [{ required: true, message: "Select TimeLine" }]
              })(
                <Select showAction={["click", "focus"]} onChange={() => { if (!editTarget) { setYearData({}) } }} placeholder="Select Timeline" allowClear>
                  <Option value="Monthly">Monthly</Option>
                  {/* <Option value="Yearly">Yearly</Option> */}
                </Select>
              )}
            </Item>
          </Col>
          <Col span={1} />
          <Col span={7}>
            <Item
              label="Unit/Value"
            >
              {getFieldDecorator("value", {
                rules: [{ required: true, message: "Select Unit/Value" }],
              })(
                <Select showAction={["click", "focus"]} placeholder="Select Unit/Value" allowClear>
                  <Option value="Units">Units</Option>
                  <Option value="Value">Value</Option>
                </Select>
              )}
            </Item>
          </Col>
        </Row>
        <Row style={{ marginTop: '1rem' }}>
          {/* <Divider /> */}
          {getFieldValue("timeline") == "Monthly" ?
            <span>
              <Row>
                <Col span={3}>
                  <Item
                    label="January"
                    validateStatus={error.Jan && error.Jan.type}
                    help={error.Jan && error.Jan.message}
                  >

                    {getFieldDecorator("Jan", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Jan : "",
                      rules: [{ required: true, message: "Enter Jan value" }],
                    })(
                      <Input
                        min={0}
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Jan: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            form.setFieldsValue({ Jan: "" })
                            setError({
                              ...error,
                              Jan: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Jan
                            setError(error)
                          }
                        }}
                        placeholder="Jan" />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>

                  <Item
                    label="February"
                    validateStatus={error.Feb && error.Feb.type}
                    help={error.Feb && error.Feb.message}
                  >
                    {getFieldDecorator("Feb", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Feb : "",
                      rules: [{ required: true, message: "Enter Feb value" }],
                    })(
                      <Input min={0}
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Feb: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Feb: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Feb
                            setError(error)
                          }
                        }}
                        placeholder="Feb" />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="March"
                    validateStatus={error.Mar && error.Mar.type}
                    help={error.Mar && error.Mar.message}
                  >
                    {getFieldDecorator("Mar", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Mar : "",
                      rules: [{ required: true, message: "Enter Mar value" }],
                    })(
                      <Input min={0}
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Mar: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Mar: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Mar
                            setError(error)
                          }
                        }}
                        placeholder="Mar" />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="April"
                    validateStatus={error.Apr && error.Apr.type}
                    help={error.Apr && error.Apr.message}
                  >
                    {getFieldDecorator("Apr", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Apr : "",
                      rules: [{ required: true, message: "Enter Apr value" }],
                    })(
                      <Input min={0} placeholder="Apr"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Apr: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Apr: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Apr
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="May"
                    validateStatus={error.May && error.May.type}
                    help={error.May && error.May.message}
                  >
                    {getFieldDecorator("May", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.May : "",
                      rules: [{ required: true, message: "Enter May value" }],
                    })(
                      <Input min={0} placeholder="May"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     May: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              May: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.May
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="June"
                    validateStatus={error.Jun && error.Jun.type}
                    help={error.Jun && error.Jun.message}
                  >
                    {getFieldDecorator("Jun", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Jun : "",
                      rules: [{ required: true, message: "Enter Jun value" }],
                    })(
                      <Input min={0} placeholder="Jun"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Jun: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Jun: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Jun
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
              </Row>
              <Row>
                <Col span={3}>
                  <Item label="July"
                    validateStatus={error.Jul && error.Jul.type}
                    help={error.Jul && error.Jul.message}
                  >
                    {getFieldDecorator("Jul", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Jul : "",
                      rules: [{ required: true, message: "Enter Jul value" }],
                    })(
                      <Input min={0} placeholder="Jul"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Jul: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Jul: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Jul
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="August"
                    validateStatus={error.Aug && error.Aug.type}
                    help={error.Aug && error.Aug.message}
                  >
                    {getFieldDecorator("Aug", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Aug : "",
                      rules: [{ required: true, message: "Enter Aug value" }],
                    })(
                      <Input min={0} placeholder="Aug"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Aug: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Aug: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Aug
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="September"
                    validateStatus={error.Sep && error.Sep.type}
                    help={error.Sep && error.Sep.message}
                  >
                    {getFieldDecorator("Sep", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Sep : "",
                      rules: [{ required: true, message: "Enter Sept value" }],
                    })(
                      <Input min={0} placeholder="Sept"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Sep: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Sep: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Sep
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="October"
                    validateStatus={error.Oct && error.Oct.type}
                    help={error.Oct && error.Oct.message}
                  >
                    {getFieldDecorator("Oct", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Oct : "",
                      rules: [{ required: true, message: "Enter Oct value" }],
                    })(
                      <Input min={0} placeholder="Oct"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Oct: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Oct: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Oct
                            setError(error)
                          }
                        }}
                      />
                    )}</Item ></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="November"
                    validateStatus={error.Nov && error.Nov.type}
                    help={error.Nov && error.Nov.message}
                  >
                    {getFieldDecorator("Nov", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Nov : "",
                      rules: [{ required: true, message: "Enter Nov value" }],
                    })(
                      <Input min={0} placeholder="Nov"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Nov: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Nov: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Nov
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
                <Col span={3}>
                  <Item label="December"
                    validateStatus={error.Dec && error.Dec.type}
                    help={error.Dec && error.Dec.message}
                  >
                    {getFieldDecorator("Dec", {
                      initialValue: editTargetData.monthly != undefined ? editTargetData.monthly.Dec : "",
                      rules: [{ required: true, message: "Enter Dec value" }],
                    })(
                      <Input min={0} placeholder="Dec"
                        pattern="^[0-9]*$"
                        // onKeyUp={e =>
                        //   form.setFieldsValue({
                        //     Dec: formatValue(e, "onlyNo")
                        //   })}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              Dec: {
                                type: "error",
                                message: "Enter Valid Input"
                              }
                            })
                          } else {
                            delete error.Dec
                            setError(error)
                          }
                        }}
                      />
                    )}</Item></Col>
                <Col span={1}>
                </Col>
              </Row>
            </span>
            : getFieldValue("timeline") == "Yearly" ? <span>
              <Row>
                <Col span={7}>
                  <Item label="Year">
                    {
                      getFieldDecorator("yearData")(
                        <Select showAction={["click", "focus"]} placeholder="Select Year" allowClear>
                          {years.map(data => <Option key={data} value={data} >{data}</Option>)}
                        </Select>
                      )
                    }
                  </Item>
                </Col>
                <Col span={1}></Col>
                <Col span={7}>
                  <Item label="Year Target">
                    {
                      getFieldDecorator("yearValue")(
                        <InputNumber style={{ width: '100%' }} placeholder="Enter Target" />
                      )
                    }
                  </Item>
                </Col>
                <Col span={1}></Col>
                <Col span={3}>
                  <Button type="ghost" onClick={() => { addYearData() }} style={{ marginTop: '2.7rem' }}><Icon component={plus} /> Add</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  {Object.keys(yearData).length > 0 ? Object.keys(yearData).map(item => <Text key={item} style={{ fontSize: '18px' }} code><span style={{ marginTop: '10px' }}>{item + " - " + yearData[item]} <Icon onClick={() => { removeYearData(item) }} style={{ cursor: 'pointer' }} component={close} /></span></Text>) : null}
                </Col>
              </Row>
            </span> : <span></span>}
        </Row>

      </Form>
    </Modal>
  )
}

const WrappedTargetModal = Form.create({ name: "TargetModal" })(TargetModal);
export default WrappedTargetModal;