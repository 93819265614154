import React from "react";
import { Table, Typography, Divider, Popconfirm } from "antd";

const { Text } = Typography;
const LedgerTable = ({
    dataSource,
    openModal,
    spinner,
    pagination,
}) => {
    const column = [
        {
            title: <span> Ledger Type</span>,
            dataIndex: "vehicle",
            key: "name",
            render: (record) => (
                <span>{record && `${record.modelCode} - ${record.modelName}`}</span>
            ),
        },
        {
            title: <span>Voucher Number</span>,
            dataIndex: "services",
            key: "serviceType",
            render: (record, data) => {
                return (
                    record.map(item => {
                        if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                            return (
                                <span>
                                    {item.serviceType}
                                </span>
                            )
                        }
                    })
                )
            },
        },
        {
            title: <span>Amount</span>,
            dataIndex: "registerNo",
            key: "regno",
            render: (record) => <span>{record}</span>,
        },
        {
            title: <span>Transcation Type</span>,
            dataIndex: "services",
            key: "serviceKms",
            render: (record, data) => {
                return (
                    record.map(item => {
                        if (item.serviceDate >= firstDate && item.serviceDate <= lastDate) {
                            return (
                                <span>
                                    {item.serviceKms}
                                </span>
                            )
                        }
                    })
                )
            },
        },
        {
            title: <span>Date</span>,
            dataIndex: "customer",
            key: "owner",
            render: (record) => (
                <span>
                    {record[0] && record[0].customer && record[0].customer.name}
                </span>
            ),
        },
        {
            title: <span>Branch</span>,
            dataIndex: "customer",
            key: "phone",
            render: (record) => (
                <span>
                    {record[0] && record[0].customer && record[0].customer.contacts[0]?.phone}
                </span>
            ),
        }
    ];
    return (
        <div>
            <Table
                rowKey={(record) => record.id}
                pagination={pagination}
                columns={column}
                style={{ cursor: "pointer" }}
                dataSource={dataSource}
                onRow={(props) => ({
                    onClick: () => {
                        openModal(props.id, false);
                    },
                })}
                loading={spinner}
            />
        </div>
    )
}

export default LedgerTable;