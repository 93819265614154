import React, { useState, useEffect,useContext } from "react";
import { withRouter } from "react-router-dom";
import { Typography, Button, Dropdown, Input, Menu, Icon, message } from "antd";
import WrappedTemplateModal from "./TemplateForm";

import TemplateTable from "./TemplateTable";
import { platformApi } from "../../../../../api";
import { ContextAPI } from "../../../../../ContextAPI";


const { Search } = Input;
const { Title } = Typography;
const { Item } = Menu;

const TemplatePage = ({ id, setLength }) => {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [selected, setSelected] = useState(null);
  const [editFlag, setEditFlag] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const [dataSource, setData] = useState([]);
  const [modifyAccess, setModifyAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);

  // useEffect(() => {
  //   modelRef.current = (bool) => setAddFlag(bool);
  //   return () => {
  //     modelRef.current = null;
  //   };
  // });
  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "sms") {
          if (each.access.update) {
            setModifyAccess(true);
          }
         
        }
      });
    // setPageData();
    // if (props.customerId) {
    //   setAddFlag(true);
    //   setSelected({ ...props.cusData });
    // }
  }, []);

  useEffect(() => {
    setSpinning(true);
    platformApi
      .get(`/api/sms`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            console.log("Templates", response.data);
            setData(response.data);
            let len = response.data.length;
            setLength(len);
          } else {
            message.error("Unable to fetch templates", 2);
          }
        } else {
          message.error("Unable to fetch templates", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error on Template Page : ", error);
        message.error("Unable to fetch template", 2);
      });
  }, []);

  const deleteData = (id) => {
    setSpinning(true);
    platformApi
      .delete(`/api/template/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(dataSource.filter((data) => data.id !== id));
            message.success("Template Deleted Sucessfully", 2);
          } else {
            message.error("Unable to delete template", 2);
          }
        } else {
          message.error("Unable to delete template", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        message.error("Unable to delete template", 2);
        console.error("Error on template : ", error);
      });
  };

  const addData = (template) => {
    setSpinning(true);
    if (template.id) {
      platformApi
        .put(`/api/sms/template/${template.id}`, template)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (obj.id === template.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success("Template  saved successfully", 2);
            } else {
              message.error("Unable to save template", 2);
            }
          } else {
            message.error("Unable to save template", 2);
          }
        })
        .catch((error) => {
          message.error("Unable to save template", 2);
          console.error("Error on template : ", error);
          setSpinning(false);
        });
    } else {
      platformApi
        .post(`/api/sms/template/${id}`, template)
        .then((result) => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData(response.data.template);
              message.success("Template added successfully", 2);
            } else {
              message.error("Unable to add template", 2);
            }
          } else {
            message.error("Unable to add template", 2);
          }
        })
        .catch((error) => {
          message.error("Unable to add template", 2);
          console.error("Error on template : ", error);
          setSpinning(false);
        });
    }
  };

  return (
    <div>
      <TemplateTable
        pagination={{
          pageSize: limit,
          defaultCurrent: 1,

          showQuickJumper: true,
        }}
        openModal={(index, editable) => {
          setSelected(index);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        dataSource={dataSource}
        delete={(id) => deleteData(id)}
        spinner={{ spinning }}
        modifyAccess={modifyAccess}
      />
      <WrappedTemplateModal
        editable={editFlag}
        data={dataSource[dataSource.findIndex((data) => data.id === selected)]}
        open={addFlag}
        emitData={(data) => addData(data, selected)}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
      />
    </div>
  );
};

export default withRouter(TemplatePage);
