import React, { useState, useEffect } from 'react';
import {
  Typography,
  Tabs,
  Input,
  Button,
  Select,
  Form,
  Col,
  Row,
  message,
  Table
} from 'antd';
import moment from 'moment';
import { platformApi } from '../../../../../../../api';
const { TabPane } = Tabs;
const { Title, Text } = Typography;
const Payments = ({
  form,
  data,
  status
}) => {
  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
  const [dataSource, setDataSource] = useState([])
  const [tableData, setTableData] = useState([])
  const [totalEstimate, setTotalEstimate] = useState(0)

  useEffect(() => {
    if (data) {
      let labourCharge = 0;
      let partCharge = 0;
      let consumableCharge = 0;
      let TotalCgst = 0;
      let TotalSgst = 0;
      let TotalIgst = 0;
      platformApi.get(`/api/jobInvoice/getJob/${data.id}`)
        .then((result) => {
          let { data } = result;
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              console.log("Response.data", response.data)
              if (response.data) {
                setTableData([response.data])
                const updateItemList = [...response.data.saleItemInvoice]
                for (let i = 0; i < updateItemList.length; i++) {
                  if (updateItemList[i].jobCode) {
                    updateItemList[i].partNumber = { ...updateItemList[i] }
                    updateItemList[i].rate = (updateItemList[i].quantity * updateItemList[i].unitRate)
                  }
                  else {
                    updateItemList[i].partNumber = { ...updateItemList[i].partNumber }
                    updateItemList[i].rate = (updateItemList[i].quantity * updateItemList[i].unitRate)
                    updateItemList[i].gstRate = updateItemList[i].igst
                  }
                }
                console.log(updateItemList)
                for (let j = 0; j < updateItemList.length; j++) {
                  TotalCgst = parseFloat(TotalCgst) + parseFloat(updateItemList[j].cgstAmount);
                  TotalCgst = TotalCgst.toFixed(2)
                  TotalSgst = parseFloat(TotalSgst) + parseFloat(updateItemList[j].sgstAmount);
                  TotalSgst = TotalSgst.toFixed(2)
                  TotalIgst = parseFloat(TotalIgst) + parseFloat(updateItemList[j].igstAmount);
                  TotalIgst = TotalIgst.toFixed(2)
                  if (updateItemList[j].rate) {
                    if (updateItemList[j].partNumber.code || updateItemList[j].jobCode) {
                      if (updateItemList[j].partNumber.code?.consumable || updateItemList[j].jobCode?.consumable) {
                        consumableCharge = parseFloat(consumableCharge) + parseFloat(updateItemList[j].rate);
                      }else {
                        labourCharge = parseFloat(labourCharge) + parseFloat(updateItemList[j].rate);
                      }
                    }
                    else if (updateItemList[j].partNumber.hsn) {
                      partCharge = parseFloat(partCharge) + parseFloat(updateItemList[j].rate)
                    }
                  }

                }
                setFieldsValue({
                  labourCharge: labourCharge,
                  partCharge: partCharge,
                  consumableCharge: consumableCharge,
                  cgst: TotalCgst,
                  sgst: TotalSgst,
                  igst: TotalIgst,
                })
                let total = response.data.totalInvoice ? response.data.totalInvoice : 0
                total = total.toFixed(0)
                setTotalEstimate(total)
                setDataSource(updateItemList)
              }
              else {
                message.info("In this Job Card No Sale Spare Invoice");
              }
            }
          }
        }).catch((err) => {
          console.error("Sale Spare Invoice get Error: ", err);
          message.error("Couldn't get Sale Spare Invoice");
        })
    }
  }, [data])

  const renderColumns = () => {
    const columns = [
      {
        title: "Details",
        dataIndex: "partNumber",
        key: "partNumber",
        width: 220,
        render: (record, data) => (

          <Form.Item>
            <span>{record.jobCode ? record.jobCode.code : `${record.partNumber} - ${record.partName}`}</span>
          </Form.Item>
        )
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        key: "quantity",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="quantity"
              disabled={true}
              placeholder="quantity"
              value={record && record.quantity}
            />
          </Form.Item>
        )
      },
      {
        title: "GST%",
        dataIndex: "gstRate",
        key: "gstRate",
        width: 120,
        render: (data, record) => (
          <Form.Item>
            <span>{record && record.gstRate}%</span>
          </Form.Item>
        ),
      },
      {
        title: "Unit rate",
        dataIndex: "unitRate",
        key: "unitRate",
        width: 100,
        render: (data, record, index) => (
          <Form.Item>
            <Input
              key="unitRate"
              disabled={true}
              placeholder="Unit Rate"
              value={record && record.unitRate}
            />
          </Form.Item>
        ),
      },
      {
        title: "Amount",
        dataIndex: "rate",
        key: "rate",
        width: 120,
        render: (data, record) => (
          <Form.Item>
            <Input
              key="rate"
              disabled={true}
              placeholder="Amount"
              value={record && record.rate}
            />
          </Form.Item>
        ),
      },
    ];
    return columns
  }

  const columns = [
    {
      title: "Invoice No",
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
      render: (record, data) => (
        <Form.Item>
          <span>{record}</span>
        </Form.Item>
      )
    },
    {
      title: "Invoice Date",
      dataIndex: "invoiceDate",
      key: "invoiceDate",
      render: (record, data) => (
        <Form.Item>
          <span>{moment(record).format("DD-MM-YYYY")}</span>
        </Form.Item>
      )
    },
    {
      title: "Total Invoice",
      dataIndex: "totalInvoice",
      key: "totalInvoice",
      render: (record, data) => (
        <Form.Item>
          <span>{record.toFixed(0)}</span>
        </Form.Item>
      )
    },
    {
      title: "Payment",
      dataIndex: "link",
      key: "link",
      render: (record, data) => (
        <Form.Item>
          <span className="linkylink">Pay Now</span>
        </Form.Item>
      ),
    },
  ];
  return (
    <>
      <Table
        className="parts-invoice-table"
        columns={renderColumns()}
        dataSource={dataSource}
        pagination={false}
        tableLayout="fixed"
        style={{ marginTop: '1rem' }}
      />
      <div style={{ padding: '10px' }}>
        <Row>
          <Col span={12}>
            <Form.Item
              className="form-element"
              label="Part Charge"
            >
              {
                getFieldDecorator("partCharge", {
                  rules: [
                    {
                      required: false,
                      message: "Enter Part Charge"
                    }
                  ]
                })(
                  <Input
                    placeholder="Part Charge"
                    disabled={true}
                  />
                )
              }
            </Form.Item>
            <Form.Item
              className="form-element"
              label="CGST"
            >
              {
                getFieldDecorator("cgst", {
                  rules: [
                    {
                      required: false,
                      message: "Enter  CGST"
                    }
                  ]
                })(
                  <Input
                    placeholder="CGST"
                    disabled={true}
                  />
                )
              }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="form-element"
              label="Labour Charge"
            >
              {
                getFieldDecorator("labourCharge", {
                  rules: [
                    {
                      required: false,
                      message: "Enter Labour Charge"
                    }
                  ]
                })(
                  <Input
                    placeholder="Labour Charge"
                    disabled={true}
                  />
                )
              }
            </Form.Item>
            <Form.Item
              className="form-element"
              label="SGST"
            >
              {
                getFieldDecorator("sgst", {
                  rules: [
                    {
                      required: false,
                      message: "Enter SGST"
                    }
                  ]
                })(
                  <Input
                    placeholder="SGST"
                    disabled={true}
                  />
                )
              }
            </Form.Item>
            <Form.Item
              className="form-element"
              label="Consumable Charge"
            >
              {
                getFieldDecorator("consumableCharge", {
                  rules: [
                    {
                      required: false,
                      message: "Enter Consumable Charge"
                    }
                  ]
                })(
                  <Input
                    placeholder="Consumable Charge"
                    disabled={true}
                  />
                )
              }
            </Form.Item>
          </Col>
        </Row>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: '80px' }}>
        <table style={{ fontSize: '20px', fontWeight: 'bold' }}>
          <tr>
            <td >Total</td>
            <td>:</td>
            <td>{totalEstimate}/-</td>
          </tr>
        </table>
      </div>
      <div className="Complaints">
        <div style={{ display: 'inline' }}>
          <h2>Payments:</h2>
        </div>
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <a className="linkylink">Payment History</a>
        </div> */}
        <div >
          <Table
            className="parts-invoice-table"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            tableLayout="fixed"
            style={{ marginTop: '1rem' }}
          />
        </div>
      </div>
    </>
  )
}

const WrappedPayments = Form.create()(
  Payments
);

export default WrappedPayments;