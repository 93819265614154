import React, { useState, useEffect } from 'react';
import { useHistory, withRouter } from "react-router-dom";
import {
    Modal,
    Select,
    Form,
    Row,
    Col,
    Typography,
    message,
    Table,
    Button,
} from "antd";
import moment from "moment";
import { platformApi } from '../../../../api';
const { Text, Title } = Typography;
const JobOrderLogs = ({
    form,
    open,
    close,
    data,
    editable,
    modify,
    setVData,
    setVOpen
}) => {
    const jobItemData = {
        "event": '',
        "data": '',
        "dateTime": '',
    }
    const history = useHistory();
    const { getFieldDecorator, getFieldValue, setFieldsValue } = form;
    const [dataSource, setDataSource] = useState([jobItemData])
    useEffect(() => {
        if (data) {
            console.log("editData", data)
            platformApi.get('/api/jobOrderlog/getLogs/' + data.id)
                .then((res) => {
                    let { data } = res;
                    if (data.code === 200) {
                        let { response } = data;
                        if (response.code === 200) {
                            let updateItemList = [...response.data]
                            for (let i = 0; i < updateItemList.length; i++) {
                                if (updateItemList[i].event === "Job Order Created") {
                                    updateItemList[i].data = updateItemList[i].jobOrder.jobNo
                                }
                                else if (updateItemList[i].event === "Vehicle Received") {
                                    updateItemList[i].data = updateItemList[i].jobOrder.vehicle && updateItemList[i].jobOrder.vehicle.registerNo
                                }
                                else if (updateItemList[i].event === "Mechanic Allocated") {
                                    updateItemList[i].data = `${updateItemList[i].jobOrder.mechanic &&
                                        updateItemList[i].jobOrder.mechanic.profile.employeeId} - 
                                        ${updateItemList[i].jobOrder.mechanic &&
                                        updateItemList[i].jobOrder.mechanic.profile?.employeeName}`
                                }
                                else if (updateItemList[i].event === "Work In Progress") {
                                    updateItemList[i].data = updateItemList[i].jobOrder.jobNo
                                }
                                // else if (updateItemList[i].event === "Estimate Generated") {
                                //     platformApi.get('/api/estimate/' + updateItemList[i].data)
                                //         .then(async (res) => {
                                //             let { data } = res;
                                //             if (data.code === 200) {
                                //                 let { response } = data;
                                //                 if (response.code === 200) {
                                //                     // updateItemList[i].data = response.data && response.data.estimateNo
                                //                 }
                                //                 // updateItemList[i].data = response.data && response.data.estimateNo
                                //             }
                                //         })
                                // }
                                // else if (updateItemList[i].event === "Material Issued") {
                                //     platformApi.get('/api/materialIssue/' + updateItemList[i].data)
                                //         .then(async (res) => {
                                //             let { data } = res;
                                //             if (data.code === 200) {
                                //                 let { response } = data;
                                //                 if (response.code === 200) {
                                //                     console.log("response.data && response.data", response.data)
                                //                     // updateItemList[i].data = "View Details"
                                //                 }
                                //             }
                                //         })
                                //     // updateItemList[i].data = "View Details"
                                // }
                                updateItemList[i].dateTime = updateItemList[i].createdAt
                            }
                            setDataSource(updateItemList)
                        }
                    }
                    else {
                        message.error("Unable to get Job Order Logs", 2);
                    }
                })
                .catch((err) => {
                    message.error("Unable to get Job Order Logs", 2);
                    console.error("Error on Job Order Logs : ", err);
                });



        }
    }, [data]);

    const clearFieldsAndClose = () => {
        form.resetFields();
        close();
        history.push('/autoadmin/job_order');
    };
    const columns = [
        {
            title: "Event",
            dataIndex: "event",
            key: "event",
            render: (record) => {
                return (
                    <span>{record}</span>
                )
            },
        },
        {
            title: "Data",
            dataIndex: "data",
            key: "data",
            render: (record, data) => {
                return (
                    <span className="linkylink" onClick={() => {
                        if (data.event === "Vehicle Received") {
                            setVData(data.jobOrder.vehicle)
                            setVOpen(true)
                        }
                        else if (data.event === "Material Issued") {
                            history.push(`/autoadmin/material_issue/${record}`)
                        }
                        else if (data.event === "Estimate Generated") {
                            history.push(`/autoadmin/estimate/${record}`)
                        }
                    }}>
                        {data.event === "Material Issued" ? "View Material Issued" :
                            data.event === "Estimate Generated" ? "View Estimation" :
                                record && record}
                    </span>
                )
            }
        },
        {
            title: "Date & Time",
            dataIndex: "dateTime",
            key: "dateTime",
            render: (record) => <span>{moment(record).format("DD-MM-YYYY HH:MM:ss")}</span>,
        },
    ]
    return (
        <Modal
            width="60%"
            title={<Title level={4}>JobOrder Logs</Title>}
            visible={open}
            onCancel={clearFieldsAndClose}
            onOk={clearFieldsAndClose}
        >
            <Form className="logs-form">
                <Row>
                    <Col span={12}>
                        <Text strong>
                            JOB NO&nbsp;&nbsp;&nbsp;: {data && data.jobNo}
                        </Text>
                    </Col>
                </Row>
                <Row>
                    <Table
                        style={{ marginTop: '1rem' }}
                        columns={columns}
                        tableLayout="fixed"
                        dataSource={dataSource}
                    />
                </Row>
            </Form>
        </Modal>
    )
}
const WrappedJobOrderLogs = Form.create("joborder_logs")(
    JobOrderLogs
);

export default WrappedJobOrderLogs;