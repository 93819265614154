import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { ContextAPI } from "../../../ContextAPI";
import {
  Button,
  Input,
  Typography,
  Dropdown,
  Tooltip,
  Row,
  Menu,
  Col,
  Icon,
  message,
  Divider,
} from "antd";
import {
  LeftOutlined
} from '@ant-design/icons';
import "./index.less"
import { platformApi } from "../../../api";
import SITable from "./SITable";
import WrappedSIForm from "./SIForm";
const { Search } = Input;
const { Title } = Typography;
const { Item } = Menu;

const SparesInventory = (props) => {

  const [search, setSearch] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [dataSource, setdataSource] = useState([]);
  const [createAccess, setCreateAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const [loggedIn, setLoggedIn] = useState("");
  const [filter, setFilter] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [SIForm, setSIForm] = useState(false)
  const [editable, setEditable] = useState(false)
  const [editableData, setEditableData] = useState({})
  const [modify, setModify] = useState(false)

  // useEffect(() => {
  //   //setPrimaryData();
  //   loginCredintials.roleAccess &&
  //     loginCredintials.roleAccess.map((each) => {
  //       if (each.subModule === "SI") {
  //         if (each.access.create) {
  //           setCreateAccess(true);
  //         }
  //       }
  //     });
  // }, []);

  useEffect(() => {
    setPrimaryData(search);
  }, [page, limit, search]);

  // console.log("data", dataSource)

  const setPrimaryData = (string, val) => {
    setSpinning(true)
    platformApi
      .post("/api/sparesInventory/get", {
        page: val ? val : page,
        size: limit,
        branch: loginCredintials.branch.id,
        searchString: string,
      })
      .then((res) => {
        setSpinning(false)
        const { data } = res;
        if (data.code === 200) {
          setdataSource(data.response.data.sparesInventory);
          // setLoggedIn(data.response.data.user);
          // message.success(data.response.msg)
          setCount(data.response.data.count);
        }
      })
      .catch((err) => {
        setSpinning(false)
        console.error("Spares Inventory Fetch Error: ", err);
        message.error("Couldn't Fetch Spares Inventory");
      });
  };



  const getSparesInventory = () => {
    setSpinning(true)
    platformApi.get("/api/sparesInventory/").then((res) => {
      setSpinning(false)
      const { data } = res;
      if (data.code === 200) {
        setdataSource(data.response.data);
        setLoggedIn(data.response.data.user);
        setEditable(false)
        setCount(data.response.data.length);
      }
    })
      .catch((err) => {
        setSpinning(false)
        console.error("Spares Inventory Fetch Error: ", err);
        message.error("Couldn't Fetch Spares Inventories");
      });
  }
  const addData = (data) => {
    setSpinning(true)
    if (data.id) {
      platformApi.put("/api/sparesInventory/" + data.id, data).then(result => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            message.success("PMC Modified Successfully")
            getSparesInventory()
          }
        }
      }).catch((err) => {
        setSpinning(false)
        console.error("SparesInventory Add Error: ", err);
        message.error("Couldn't Add SparesInventory");
      });
    }
    else {
      platformApi.post("/api/sparesInventory/", data).then(result => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            message.success("SparesInventory Added Successfully")
            getSparesInventory()
          }
          else {
            setSpinning(false)
            message.error("Couldn't Add SparesInventory");
          }
        } else {
          setSpinning(false)
          message.error("Couldn't Add SparesInventory");
        }
      }).catch((err) => {
        setSpinning(false)
        console.error("SparesInventory Add Error: ", err);
        message.error("Couldn't Add SparesInventory");
      });
    }
  }

  const deleteData = (id) => {
    platformApi.delete("/api/sparesInventory/" + id).then(result => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          message.success("SparesInventory Deleted Successfully")
          getSparesInventory()
        }
        else {
          setSpinning(false)
          message.error("Couldn't Remove SparesInventory");
        }
      }
      else {
        setSpinning(false)
        message.error("Couldn't Remove SparesInventory");
      }
    }).catch((err) => {
      setSpinning(false)
      console.error("SparesInventory Add Error: ", err);
      message.error("Couldn't Add SparesInventory");
    });
  }

  const getMenu = () => {
    let size = count / 10 + (count % 10) ? 1 : 0;
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };
  const menu = <Menu>{getMenu()}</Menu>;

  return (
    <div>
      <div className="pmc-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Spares Inventory [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>

        <WrappedSIForm
          visible={SIForm}
          dataSource={dataSource}
          setDataSource={setdataSource}
          setVisible={setSIForm}
          emitData={addData}
          editableData={editableData}
          editable={editable}
          setEditable={setEditable}
          setEditableData={setEditableData}
          getSparesInventory={getSparesInventory}
        />
        <Search
          placeholder="Search User"
          className="searchbar-div-manufacturer"
          style={{ width: "30%" }}
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button
          type="primary"
          className="add-pmc-button"
          // disabled={!createAccess}
          style={{ margin: "0 3%", width: "20%" }}
          onClick={() => { setEditable(true); setSIForm(true) }}
        >
          Add Spares
        </Button>

      </div>
      {/* <Divider /> */}
      <div>
        <SITable
          dataSource={dataSource}
          spinner={spinning}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: limit,
            defaultCurrent: 1,
            current: page,
            total: count,
            showQuickJumper: true,
          }}
          modify={modify}
          deleteData={deleteData}
          setVisible={setSIForm}
          setEditableData={setEditableData}
          setEditable={setEditable}
        />

      </div>
    </div>
  );
}
export default withRouter(SparesInventory)