import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  message,
  Modal,
  Typography,
  Steps,
  Icon
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import _ from 'lodash';
import { platformApi } from '../../../../api';
import WrappedSupplierDataForm from './SupplierData';
import WrappedSupplierContactsForm from './SupplierContacts';
import WrappedSupplierBankForm from './BankDetails';
import { setConfig } from 'react-hot-loader';

const { Step } = Steps;
const { Text } = Typography;

function SupplierMasterForm({
  open, close, values,
  editable, setValues,
  dataSource, setDataSource,
  setCount, count,
  setDelData, delData,
  setBankDelData, delbankData,
  supData, setSupData
}) {
  const ChildRef = useRef();
  const ClearRef = useRef();

  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({})
  const [error, setError] = useState({});

  const icons = [
    <Icon style={{ fontSize: "1rem" }} type="home" />,
    <Icon style={{ fontSize: "1rem" }} type="contacts" />,
    <Icon style={{ fontSize: "1rem" }} type="bank" />
  ];

  const steps = [
    { id: 1, title: "Supplier Data" },
    { id: 2, title: "Supplier Contacts" },
    { id: 3, title: "Bank Details" },
  ];

  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    if (values) {
      console.log("Values", values)
      setData(values)
    }
  }, [values])
  useEffect(() => {
    if (supData) {
      console.log("suplier data", supData)
      platformApi.get(`/api/supplier/${supData}`)
        .then((res) => {
          let { data } = res
          if (data.code === 200) {
            let { response } = data;
            if (response.code === 200) {
              console.log("Suplier Data", response.data)
              setData(response.data)
            }
          } else {
            message.error('Cannot fetch Supplier Data');
          }
        })
        .catch((err) => {
          console.error('Supplier fetch error', err);
          message.error('Cannot fetch Supplier Data');
        });
    }
  }, [supData])


  const forms = [
    <WrappedSupplierDataForm
      data={data}
      setData={setData}
      editable={editable}
      validityRef={ChildRef}
      clearRef={ClearRef}
      error={error}
      setError={setError}
    />,
    <WrappedSupplierContactsForm
      data={data}
      setData={setData}
      values={values}
      editable={editable}
      validityRef={ChildRef}
      clearRef={ClearRef}
      error={error}
      setError={setError}
      setDelData={setDelData}
      delData={delData}
    />,
    <WrappedSupplierBankForm
      data={data}
      setData={setData}
      values={values}
      editable={editable}
      validityRef={ChildRef}
      clearRef={ClearRef}
      error={error}
      setError={setError}
      setBankDelData={setBankDelData}
      delbankData={delbankData}
    />

  ]


  const clearFields = () => {
    if (!supData) {
      setData({});
      setCurrent(0);
      ClearRef.current();
      setValues(null)
      setDelData([]);
      setBankDelData([]);
      close()
      setError({})
    }
    else {
      setData({});
      setCurrent(0);
      ClearRef.current();
      close()
      setError({})
    }
  };




  const handleSubmit = () => {
    if (!values) {
      setConfirmLoading(true);
      data.address.country = data.address.country.id;
      data.address.state = data.address.state.id;
      data.address.district = data.address.district.id

      platformApi.post('/api/supplier', data)
        .then((res) => {
          const previousData = dataSource;
          previousData.push(res.data.response.data);
          setDataSource(previousData);
          setCount(count + 1);
          close();
          clearFields()
        })
        .catch((err) => {
          message.error('Supplier Data not Uploaded');
          console.error('Supplier creation Error:', err);
        });
    } else if (!editable) {
      data.id = values.id;
      data.address.country = data.address.country.id;
      data.address.state = data.address.state.id;
      data.address.district = data.address.district.id;
      if (data.GSTIN === undefined) {
        data.GSTIN = ''
      }
      if (delData && delData.length > 0) {
        for (let i = 0; i < delData.length; i++) {
          const ele = delData[i];
          console.log("delete", ele)
          platformApi.delete(`/api/supplier/phone/${ele.id}`).then((res) => {
            if (res.data.response.code === 200) {
              message.success("Mobile number is Deleted Successfully");
            } else {
              message.error("Phone number is not deleted");
            }
          });
        }
      }
      if (delbankData && delbankData.length > 0) {
        for (let i = 0; i < delbankData.length; i++) {
          platformApi.delete(`/api/supplier/bank/${delbankData[i].id}`).then((res) => {
            if (res.data.response.code === 200) {
              message.success("Account number is Deleted Successfully");
            } else {
              message.error("Account number is not deleted");
            }
          });
        }
      }
      platformApi.put(`/api/supplier/${values.id}`, data)
        .then((res) => {
          if (res.data.response.code === 200) {
            close()
            const data = dataSource;
            dataSource.some((obj, index) => {
              if (obj.id === res.data.response.data.id) {
                data[index] = res.data.response.data;
              }
            });
            setDataSource([]);
            setDataSource(data);
            message.success('Supplier Saved Successfully');
            clearFields();
          } else {
            message.error('Supplier save Failed');
          }
        })
        .catch((err) => {
          message.error('Supplier Not Saved');
        });
    }

    setConfirmLoading(false)
  };

  return (
    <Modal
      width="60%"
      confirmLoading={confirmLoading}
      title="Supplier Master"
      visible={open}
      onCancel={() => {
        clearFields();
      }}
      footer={[
        <Button
          onClick={() => {
            current > 0 ? setCurrent(current - 1) : clearFields();
          }}
        >
          {current > 0 ? "Previous" : "Cancel"}
        </Button>,
        <Button
          onClick={() => {
            if (ChildRef.current())
              current < 2 ? setCurrent(current + 1) : (editable ? clearFields() : handleSubmit());
          }}
          type="primary"
        >
          {current < 2 ? "Next" : (editable ? "Cancel" : "Save")}
        </Button>
      ]}
    >
      <div>
        <Steps size="small" type="navigation" current={current}>
          {steps.map((step, index) => (
            <Step
              key={step.id}
              icon={icons[index]}
              title={<Text>{step.title}</Text>}
            />
          ))}
        </Steps>
        <div>{forms[current]}</div>

      </div>
    </Modal>
  );
}
export default SupplierMasterForm;
