import React, { useState, useEffect, useContext } from 'react';
import {
    Typography,
    Input,
    Form,
    Col,
    Row,
    Table
} from 'antd';

import './index.less';
import { ContextAPI } from '../../../../../ContextAPI';
import { platformApi } from '../../../../../api';

const { Title } = Typography;
const { Item } = Form;

const SparesInventory = (props) => {
    const { form, data, editable } = props
    const { getFieldDecorator, getFieldValue } = form;
    const [dataSource, setData] = useState(null);
    const user = useContext(ContextAPI);
    // console.log("recieved", data)
    useEffect(() => {
        if (data) {
            form.setFieldsValue({
                partName: data.partNo.partName,
                partNumber: data.partNo.partNumber,
            });
            setData(data.branch)
        }

    }, [open])
    // console.log("branch", data && data.branch)
    const columns = [
        {
            title: "Branch Name",
            dataIndex: "branch",
            key: "branch",
            render: (record) => { return <span>{record && record.name}</span> },
        },
        {
            title: "Physical Qty",
            render: (record, data) => {
                return (user && user.loginCredintials && user.loginCredintials.role == "CEO" ? <Input  disabled={!editable} placeholder="Physical Quantity" name="phyQuantity" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.phyQuantity} /> : <span>{record.phyQuantity}</span>)
            },
        },
        {
            title: "Accounting Qty",
            render: (record, data) => {
                return (user && user.loginCredintials && user.loginCredintials.role == "CEO" ?
                    <Input placeholder="Accounting Quantity" name="accQuantity" disabled={!editable} onChange={(e) => { editStocks(record.sparesId, e) }} value={record.accQuantity} />
                    : <span>{record.accQuantity ? record.accQuantity : 0}</span>)
            },
        },
        {
            title: "Bin Location",
            render: (record, data) =>
                <Input placeholder="Bin Number" disabled={!editable} name="binNum" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.binNum} />
        },
        {
            title: "Min Stock",
            render: (record) => {
                // <Input placeholder="Min Stock" value={record}/>
                return (
                    <Input placeholder="Min Stock" disabled={!editable} name="minStock" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.minStock} />
                )
            },
        },
        {
            title: "Max Stock",
            render: (record) => {
                return (
                    <Input placeholder="Max Stock" disabled={!editable} name="maxStock" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.maxStock} />
                )
            },
        },
        {
            title: "Reorder Level",
            render: (record) => {
                return (<Input placeholder="Reorder Level" disabled={!editable} name="reorderLevel" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.reorderLevel} />)
            },
        },
        {
            title: "Reorder Qty",
            dataIndex: "",
            key: "",
            render: (record) => {
                return (
                    <Input placeholder="reorderQuantity" disabled={!editable} name="reorderQuantity" onChange={(e) => { editStocks(record.sparesId, e) }} value={record.reorderQuantity} />
                )
            },
        },
    ]
    const editStocks = (id, e) => {
        let data = [...dataSource]
        for (let item of data) {
            if (item.sparesId == id) {
                item[e.target.name] = e.target.value
                break
            }
        }
        // console.log("data", data)
        setData(data)
    }
    return (
        <div>
            <Form>
                <Row>
                    <Col span={8}>
                        <Form.Item
                            className="form-element"
                            label="Part No"
                        // name="partNo"
                        >
                            {getFieldDecorator("partNumber", {
                            })(
                                <Input placeholder="Part No" disabled={!editable} />
                            )}
                        </Form.Item>
                    </Col>
                    <Col span={1} />
                    <Col span={12}>
                        <Form.Item
                            className="form-element"
                            label="Part Name"
                            name="partName"
                        >
                            {getFieldDecorator("partName", {
                            })(

                                <Input style={{ width: '180%' }} placeholder="Part Name" disabled={!editable} />
                            )}
                        </Form.Item>
                    </Col>

                </Row>
                <div id="resetForm">
                    <Table
                        style={{ marginTop: '1rem' }}
                        columns={columns}
                        tableLayout="fixed"
                        dataSource={dataSource}
                    />
                </div>

            </Form>
        </div>
    )
}

const WrappedSparesInventory = Form.create()(
    SparesInventory
);

export default WrappedSparesInventory;