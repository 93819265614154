import React, { useState, useEffect } from 'react';
import { Modal, Upload, message, Select, Button, Icon, Typography, Row, Col } from 'antd';
import { platformApi } from '../../../../api';

const { Text } = Typography;

let urls = process.env.NODE_ENV === "development" ? "http://localhost:4000/" : "/";

export default function CSVPartsUpload(props) {
  const { open, close, upload, setfile } = props;

  const [manufacturer, setManufacturer] = useState([]);
  const [id, setId] = useState(undefined);
  const [templateLink, setTemplateLink] = useState('');

  useEffect(() => {

    platformApi
      .get("/api/manufacturer/")
      .then(res => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setManufacturer(response.data);
          } else {
            message.error("Unable to fetch Manufacturers", 2);
          }
        } else {
          message.error("Unable to fetch Manufacturer", 2);
        }
      })
    platformApi
      .get('/api/partsMaster/template')
      .then(res => {
        let { data } = res;
        if (data.code === 200) {
          console.log("asdasd", urls);
          setTemplateLink(data.response)
        }
      })
  }, [])

  const beforeDocUpload = file => {
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("Document must smaller than 50MB!");
    }
    return isLt2M;
  };

  return (
    <div>
      <Modal
        bodyStyle={{ width: '10vw' }}
        title="CSV Upload"
        visible={open}
        onCancel={() => {
          close()
          setId(undefined)
        }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <Select
          placeholder="Manufacturer"
          style={{ width: '18vw' }}
          onChange={(id) => setId(id)}
          value={id}
          showAction={["click", "focus"]}
        >
          {manufacturer.map((item, index) => (
            <Select.Option key={item.id}>{item.name}</Select.Option>
          ))}
        </Select>
        <br></br>
        <br></br>
        <br></br>
        <div style={{ display: 'flex', width: '100%' }}>
          <Upload
            beforeUpload={e => beforeDocUpload(e)}
            onChange={event => {
              setfile(true);
              upload(event, id)
            }}
            action={`${urls}api/upload/mocky`}
            accept=".xlsx"
            disabled={!id}
          >
            <Button type="primary" disabled={!id}>
              <Icon type="upload" />
              <Text style={{ color: "lightgray" }}>
                Upload CSV Data
                </Text>
            </Button>
          </Upload>
        </div>

        <br></br>
        <div style={{ width: '400%' }}>
          <span style={{ width: '100%',marginBottom:'10px' }}>*(Accepts only .xlsx files)</span><br></br>
          <a href={templateLink} target="_blank" download style={{ minWidth: '16.7rem', marginTop: '5px' }}
            onClick={e => e.stopPropagation()} className="linkylink">
            Download Template Here for Parts Master
          </a>
        </div>

      </Modal>
    </div>
  );
}
