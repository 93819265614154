import React, { useEffect, useState, useContext } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  InputNumber,
  Input,
  DatePicker,
  Button,
  message,
} from "antd";
import { platformApi } from "../../../../../api";
import TextArea from "antd/lib/input/TextArea";
import { ContextAPI } from "../../../../../ContextAPI";
import { paymentDataJoi } from "../../BookingRegisterJoi";
import { generateErrorObj, defaultToNull, formatValue } from "../../../../../utils";
import moment from "moment";
const { Text } = Typography;
const { Option } = Select;

const PaymentData = ({ form, validityRef, clearRef, data, open, editable }) => {
  const [bookingAuthStatus, updateBookingAuthStatus] = useState(false);
  const [financierData, setFinancierData] = useState([]);
  const [allow, setAllow] = useState(true);
  const [finAllow, setFinAllow] = useState(true);
  const [errorObj, setErrorObj] = useState({});
  const paymentData = [
    { id: "cash", name: "Cash" },
    { id: "finance", name: "Finance" },
  ];
  const loanData = [
    { id: "self", name: "Self" },
    { id: "companyAssist", name: "Company-Assisted" },
  ];

  const [emiNums, setEmiNums] = useState([]);

  const [currentFinancer, setCurrentFinancer] = useState();
  const { loginCredintials } = useContext(ContextAPI);

  const { getFieldDecorator, getFieldValue, setFieldsValue } = form;

  const filterMethod = (input, option) => {
    setAddFinancier(input);
    return option.props.children.toString().toLowerCase().indexOf(input) >= 0;
  };
  const [addFinancier, setAddFinancier] = useState(null);

  useEffect(() => {
    let numArr = [];
    for (let i = 0; i < 28; i++) {
      let obj = {
        id: i + 1,
        name: i + 1,
      };
      numArr.push(obj);
    }

    setEmiNums(numArr);

    platformApi.get("/api/financer").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setFinancierData(response.data);

          data.loan?.financer && setCurrentFinancer(response.data.find((d) => d.id == data.loan?.financer.id));
        }
      }
    });
  }, []);

  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });

  useEffect(() => {
    if (data.loan) {
      updateBookingAuthStatus((data.authentication && data.authentication.verifiedAt) || false)
      setFieldsValue({
        financerName: data.loan.financer && data.loan.financer.id,
        loan: String(data.loan.loanAmount || "0"),
        tenure: String(data.loan.tenure || "1"),
        downPayment: String(data.loan.downPayment || "0"),
        emiDate: data.loan.emiDate,
        emiStartDate: data.loan.emiStartDate ? moment(data.loan.emiStartDate) : null,
        paymentMode: data.price.paymentMode,
        loanType: data.loan.loanType,
        financerBranch: data.loan.financerBranch,
        netRecieveables: String(data.price.netRecieveables),
        emiAmount: String(data.loan.emiAmount || "0"),
        remarks: data.remarks,
        loanDis: String(data.loan.disbursementAmount || "0"),
        showroomFinanceCharges: String(data.loan.showroomFinanceCharges || "0"),
      });
      if (data.price.paymentMode === "cash" || data.loan.loanType === "self")
        setFieldsValue({ netRecieveables: String(data.price.finalAmount - ((data.exchange && data.exchange.vehiclePrice) || 0)) })
      data.price.paymentMode !== "cash" &&
        addLoanDisAmount({ target: { value: data.loan.downPayment ?? 0 } });
      setFinAllow(!(data.loan.loanType === "companyAssist" && data.price.paymentMode === "finance"));
      setAllow(data.price.paymentMode === "cash");
    }
  }, [open]);

  const validatePage = () => {
    let isValid = false;
    form.validateFields((validationError) => {
      isValid = validationError === null;
      //heck for error and empty values
      let paymentValidation = paymentDataJoi(
        defaultToNull(form.getFieldsValue())
      );
      if (paymentValidation.error) {
        message.warning("Please enter valid data in the fields!");
        setErrorObj(generateErrorObj(paymentValidation, errorObj));
        isValid = false;
      }
    });
    if (isValid) {

      // data.branch = loginCredintials.branch;
      data.loan = {
        ...data.loan,
        financer: { id: getFieldValue("financerName") },
        financerBranch: getFieldValue("financerBranch"),
        loanAmount: getFieldValue("loan"),
        tenure: getFieldValue("tenure"),
        downPayment: getFieldValue("downPayment"),
        emiAmount: getFieldValue("emiAmount"),
        emiDate: getFieldValue("emiDate"),
        emiStartDate: getFieldValue("emiStartDate"),
        remarks: getFieldValue("remarks"),
        loanType: getFieldValue("loanType"),
        disbursementAmount: getFieldValue("loanDis"),
        showroomFinanceCharges: getFieldValue("showroomFinanceCharges"),
      };
      data.finName = currentFinancer && currentFinancer.name,
        data.price = {
          ...data.price,
          paymentMode: getFieldValue("paymentMode"),
          netRecieveables: getFieldValue("netRecieveables"),
        };
      data.remarks = getFieldValue("remarks");
    }
    return isValid;
  };

  const addLoanDisAmount = (e) => {
    let downPayment = parseFloat(e.target.value === "" ? 0 : e.target.value);
    let loan = parseFloat(
      data.loan && data.loan.loanAmount ? data.loan.loanAmount : 0
    );
    let exchangePrice = parseFloat(
      data.exchange && data.exchange.vehiclePrice ? data.exchange.vehiclePrice : 0
    );
    let onRoadDiscount = parseFloat(
      data.price.onRoadDiscount ? data.price.onRoadDiscount : 0
    );
    let showroomFinanceCharges = parseFloat(
      form.getFieldValue('showroomFinanceCharges') || 0
    );
    // let accessoriesTotal = parseFloat(
    //   data.price.accessoriesTotal ? data.price.accessoriesTotal : 0
    // );
    let accessoriesTotalAfteDicount = parseFloat(
      data.price.accessoriesTotalAfterDiscount ? data.price.accessoriesTotalAfterDiscount : 0
    );
    let affidavitAmount = parseFloat(
      data.price.affidavit ? data.price.affidavit : 0
    );
    let TRcharges = parseFloat(
      data.price.tempRegister ? data.price.tempRegister : 0
    );
    let specialNoCharges = parseFloat(
      data.price.specialNoCharges ? data.price.specialNoCharges : 0
    );
    let hypothetication = parseFloat(data.price.hp ? data.price.hp : 0);
    let onRoadPrice = parseFloat(
      data.price.onRoadPrice ? data.price.onRoadPrice : 0
    );
    let numberPlate = parseFloat(
      data.price.numberPlate ? data.price.numberPlate : 0
    );
    let res =
      (accessoriesTotalAfteDicount +
        affidavitAmount +
        TRcharges +
        specialNoCharges +
        hypothetication +
        // showroomFinanceCharges+
        numberPlate +
        downPayment) -
      (
        exchangePrice +
        onRoadDiscount);
    loan = getFieldValue('loan') ? getFieldValue('loan') : loan
    // if (loan < ((onRoadPrice+showroomFinanceCharges) -downPayment)) {
    //   setErrorObj({
    //     ...errorObj,
    //     loan:"Loan Amount must be greater than Loan Disbursement Amount!",
    //   });
    // }
    // else{
    //   delete errorObj.loan;
    //   setErrorObj(errorObj);
    // }

    let LoanDisAmount = (onRoadPrice + showroomFinanceCharges) - downPayment
    setFieldsValue({
      loanDis: String(LoanDisAmount),
      netRecieveables: String(res),
    });
  };
  const validateOnBlur = () => {
    let paymentValidation = paymentDataJoi(
      defaultToNull(form.getFieldsValue())
    );
    if (paymentValidation.error) {
      if (errorObj.loanDis)
        setErrorObj({ loanDis: errorObj.loanDis })
      else
        setErrorObj({})
      let generatedError = generateErrorObj(paymentValidation, {})
      // setErrorObj(generatedError)
      setErrorObj(prev => ({ ...prev, ...generatedError }));
    }
    else {
      setErrorObj({})
    }
  }
  const resetBasedonPaymentMode = (cond) => {
    setFieldsValue({
      financerBranch: undefined,
      financerName: undefined,
      loanDis: "0",
      showroomFinanceCharges: "0",
      loan: "0",
      tenure: "1",
      downPayment: "0",
      emiAmount: "0",
      emiDate: 1,
      emiStartDate: null,
    })
    if (cond)
      setFieldsValue({
        loanType: undefined
      });
    setErrorObj({})
  };

  return (
    <Form style={{ marginTop: "1%", marginBottom: "4%" }}>
      <Row type="flex" justify="space-between">
        <Col span={5}>
          <Form.Item
            required
            colon={false}
            label={<Text>Payment Mode</Text>}
          >
            {getFieldDecorator("paymentMode", {
              rules: [
                {
                  required: true,
                  message: "Payment Mode Required!",
                },
              ],
            })(
              <Select
              showAction={["click", "focus"]}
                onSelect={(id) => {
                  resetBasedonPaymentMode(true);
                  id === "cash" ? setAllow(true) : setAllow(false);
                  id === "cash"
                    ? (setFieldsValue({
                      netRecieveables: String(data.price && data.price.finalAmount - (data.exchange.vehiclePrice || 0)),
                    }),
                      setFinAllow(true))
                    : setFieldsValue({
                      netRecieveables: "0",
                    })
                }}
                disabled={!editable || bookingAuthStatus}
                placeholder="Payment Mode"
              >
                {paymentData.map((data) => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            style={{ visibility: allow ? "hidden" : "visible" }}
            colon={false}
            label={<Text>Loan Type</Text>}
          >
            {getFieldDecorator("loanType", {
              rules: [
                {
                  required: !allow,
                  message: "Loan Type Required!",
                },
              ],
            })(
              <Select
              showAction={["click", "focus"]}
                onSelect={(id) => {
                  resetBasedonPaymentMode(false);
                  id === "self" ? setFinAllow(true) : setFinAllow(false);
                  id === "self"
                    ? setFieldsValue({
                      netRecieveables: String(data.price && data.price.finalAmount - (data.exchange.vehiclePrice || 0)),
                    })
                    : setFieldsValue({
                      netRecieveables: "0",
                    })
                  id === "self" ? delete errorObj.financerBranch : null
                }}
                disabled={!editable || allow || bookingAuthStatus}
                placeholder="Loan Type"
              >
                {loanData.map((data) => (
                  <Select.Option key={data.id}>{data.name}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            style={{ visibility: allow ? "hidden" : "visible" }}
            colon={false}
            label={<Text>Financier Name</Text>}
          >
            <Row type="flex" justify="space-between">
              <Col span={18}>
                {getFieldDecorator("financerName", {
                  rules: [
                    {
                      required: !allow ? true : false,
                      message: "Financier Name Required!",
                    },
                  ],
                })(<Select
                  disabled={!editable || allow || bookingAuthStatus}
                  showSearch
                  showAction={["click", "focus"]}
                  placeholder="Financier"
                  optionFilterProp="children"
                  filterOption={filterMethod}
                  onChange={(d) => {
                    setCurrentFinancer(financierData.find((j) => j.id == d));
                  }}
                >
                  {financierData.map((data) => (
                    <Option key={data.id} value={data.id}>
                      {data.name}
                    </Option>
                  ))}
                </Select>
                )}
              </Col>
              {!(
                addFinancier === null ||
                financierData.some((financier) =>
                  financier.name
                    .toLowerCase()
                    .includes(addFinancier.toLowerCase())
                )
              ) && (
                  <Col span={5}>
                    <Button
                      disabled={!editable || bookingAuthStatus}
                      // style={{ marginTop: "2.5rem" }}
                      onClick={() => {
                        if (addFinancier != null) {
                          platformApi
                            .post("/api/financer/", {
                              name: addFinancier,
                            })
                            .then((res) => {
                              setFinancierData([
                                ...financierData,
                                res.data.response.data,
                              ]);
                              // campaigns.push(res.data.response.data);
                              form.setFieldsValue({
                                financierName: res.data.response.data.id,
                              });
                              message.success("Financier Added");
                              setAddFinancier(null);
                            });
                        }
                      }}
                      type="primary"
                    >
                      Add
                  </Button>
                  </Col>
                )}
            </Row>
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item
            validateStatus={errorObj && errorObj.financerBranch ? "error" : ""}
            help={errorObj && errorObj.financerBranch}
            style={{ visibility: allow ? "hidden" : "visible" }}
            colon={false}
            required={!allow ? true : false}
            // required
            label={<Text>Financier Branch</Text>}
          >
            {getFieldDecorator("financerBranch")(
              <Input
                disabled={!editable || allow || bookingAuthStatus}
                placeholder="Financier Branch"
                onBlur={() => validateOnBlur()}
                onKeyUp={e =>
                  form.setFieldsValue({
                    financerBranch: formatValue(e, "allCaps", true)
                  })}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
      <br />
      <div
        style={{
          border: "1px solid gray",
          borderRadius: "1%",
          padding: "1%",
          visibility:
            finAllow
              ? "hidden"
              : "visible",
        }}
      >
        <Text strong>Financial Assistance Data</Text>
        <Row type="flex" justify="space-between">
          <Col span={7}>
            <Form.Item
              colon={false}
              label={<Text>Down Payment</Text>}
              validateStatus={errorObj && errorObj.downPayment ? "error" : ""}
              help={errorObj && errorObj.downPayment}
            >
              {getFieldDecorator("downPayment", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Down Payment!",
                  },
                ],
              })(
                <Input
                  addonBefore="&#8377;"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      downPayment: formatValue(e, "noWithDot")
                    })}
                  disabled={!editable || allow || bookingAuthStatus}
                  placeholder="Down Payment"
                  onBlur={(e) => { validateOnBlur(), addLoanDisAmount(e) }}
                // pattern="^[0-9]*$"
                // onInput={(event) => {
                //   if (event.target.value.trim() !== "")
                //     if (!event.target.checkValidity()) {
                //       setError({
                //         ...error,
                //         downPayment: {
                //           type: "error",
                //           message: "Enter Valid Amount",
                //         },
                //       });
                //     } else {
                //       delete error.downPayment;
                //       setError(error);
                //     }
                // }}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              colon={false}
              label={<Text>Tenure</Text>}
              validateStatus={errorObj && errorObj.tenure ? "error" : ""}
              help={errorObj && errorObj.tenure}
            >
              {getFieldDecorator("tenure", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Tenure!",
                  },
                ],
              })(
                <Input
                  disabled={!editable || allow || bookingAuthStatus}
                  placeholder="Tenure (in Months)"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      tenure: formatValue(e, "onlyNo")
                    })}
                  onBlur={() => validateOnBlur()}
                // pattern="^[1-9]*$"
                // onInput={(event) => {
                //   if (!event.target.checkValidity()) {
                //     setError({
                //       ...error,
                //       tenure: {
                //         type: "error",
                //         message: "Enter Valid Tenure in Month",
                //       },
                //     });
                //   } else {
                //     delete error.tenure;
                //     setError(error);
                //   }
                // }}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              colon={false}
              label={<Text>Loan Amount</Text>}
              validateStatus={errorObj && errorObj.loan ? "error" : ""}
              help={errorObj && errorObj.loan}
            >
              {getFieldDecorator("loan", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Loan Amount!",
                  },
                ],
              })(
                <Input
                  addonBefore="&#8377;"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      loan: formatValue(e, "noWithDot")
                    })}
                  disabled={!editable || allow || bookingAuthStatus}
                  placeholder="Loan Amount"
                // onBlur={()=>validateOnBlur()}
                // onInput={(event) => {
                //   if (event.target.value < (parseFloat(getFieldValue("loanDis")))) {
                //     setErrorObj({
                //       ...errorObj,
                //       loan:"Loan Amount must be greater than Loan Disbursement!"
                //     });
                //   } else {
                //     delete errorObj.loan;
                //     setErrorObj(errorObj);
                //   }
                // }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row type="flex" justify="space-between">
          <Col span={7}>
            <Form.Item
              validateStatus={errorObj && errorObj.emiAmount ? "error" : ""}
              help={errorObj && errorObj.emiAmount}
              colon={false}
              label={<Text>EMI Amount</Text>}
            >
              {getFieldDecorator("emiAmount", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Emi Amount!",
                  },
                ],
              })(
                <Input
                  addonBefore="&#8377;"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      emiAmount: formatValue(e, "noWithDot")
                    })}
                  disabled={!editable || allow || bookingAuthStatus}
                  placeholder="EMI Amount"
                  onBlur={() => validateOnBlur()}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={7}>
            <Form.Item
              // validateStatus={errorObj && errorObj.emiDate ? "error" : ""}
              // help={errorObj && errorObj.emiDate}
              colon={false}
              label={<Text>EMI Date - Day Only</Text>}
            >
              {getFieldDecorator("emiDate", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Emi Date!",
                  },
                ],
              })(
                // <InputNumber
                //   min={1}
                //   max={28}
                //   style={{ width: "100%" }}
                //   disabled={!editable || allow}
                //   placeholder="EMI Date - Day Only"
                // />

                // <DatePicker
                //   style={{ width: "100%" }}
                //   disabled={!editable || allow}
                //   placeholder="EMI Date - Day Only"
                //   format="DD"
                // />

                <Select
                showAction={["click", "focus"]}
                  disabled={!editable || allow || bookingAuthStatus}
                  placeholder="EMI Date - Day Only"
                >
                  {emiNums.map((data) => (
                    <Select.Option key={data.id}>{data.name}</Select.Option>
                  ))}
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              // validateStatus={errorObj && errorObj.emiStartDate ? "error" : ""}
              // help={errorObj && errorObj.emiStartDate}
              colon={false}
              label={<Text>EMI Start Date</Text>}
            >
              {getFieldDecorator("emiStartDate", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Emi Start Date!",
                  },
                ],
              })(
                <DatePicker
                  style={{ width: "100%" }}
                  disabled={!editable || allow || bookingAuthStatus}
                  placeholder="EMI Start Date"
                  format="DD/MM/YYYY"
                  disabledDate={current => {
                    return (current && current < moment().startOf('day') || (current > moment(new Date()).add(2, 'M')));
                  }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row type="flex" justify="space-between">
          <Col span={7}>
            <Form.Item
              validateStatus={errorObj && errorObj.loanDis ? "error" : ""}
              help={errorObj && errorObj.loanDis}
              colon={false}
              label={<Text>Loan Disbursement Amount</Text>}
            >
              {getFieldDecorator("loanDis", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Loan Disbursement Amount!",
                  },
                ],
              })(
                <Input
                  disabled={!editable || allow || bookingAuthStatus}
                  addonBefore="&#8377;"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      loanDis: formatValue(e, "noWithDot")
                    })}
                  placeholder="Loan Disbursement Amount"
                  onBlur={() => validateOnBlur()}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={7}>
            <Form.Item
              validateStatus={
                errorObj && errorObj.showroomFinanceCharges ? "error" : ""
              }
              help={errorObj && errorObj.showroomFinanceCharges}
              colon={false}
              label={<Text>Showroom Finance Charges</Text>}
            >
              {getFieldDecorator("showroomFinanceCharges", {
                rules: [
                  {
                    required: !finAllow,
                    message: "Enter Showroom Finance",
                  },
                ],
              })(
                <Input
                  disabled={!editable || allow || bookingAuthStatus}
                  placeholder="Enter Showroom Finance"
                  onKeyUp={e =>
                    form.setFieldsValue({
                      showroomFinanceCharges: formatValue(e, "noWithDot")
                    })}
                  onBlur={() => { validateOnBlur(), addLoanDisAmount({ target: { value: form.getFieldValue('downPayment') || 0 } }) }}
                // onInput={(event) => {
                //   if ((event.target.value+parseFloat(getFieldValue("loan")))< (parseFloat(getFieldValue("loanDis")))) {
                //     setErrorObj({
                //       ...errorObj,
                //       loan:"Loan Amount must be greater than Loan Disbursement!"
                //     });
                //   } else {
                //     delete errorObj.loan;
                //     setErrorObj(errorObj);
                //   }
                // }}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </div>
      <Form.Item colon={false} label={<Text>Remarks</Text>}>
        {getFieldDecorator("remarks")(
          <TextArea disabled={!editable || bookingAuthStatus} rows={4} placeholder="Remarks"
            onKeyUp={e =>
              form.setFieldsValue({
                remarks: formatValue(e, "firstCaps")
              })
            }
          />
        )}
      </Form.Item>
      <Form.Item
        style={{ marginLeft: "auto", width: "20%" }}
        colon={false}
        label={<Text>Net Recieveables</Text>}
      >
        {getFieldDecorator("netRecieveables")(
          <Input addonBefore="&#8377;" disabled={true} placeholder="Total" />
        )}
      </Form.Item>
    </Form>
  );
};

const WrappedPaymentData = Form.create()(PaymentData);

export default WrappedPaymentData;
