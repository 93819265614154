import React, { useState, useEffect, useContext } from 'react';
import {
  Form,
  Input,
  message,
  Select,
  Modal,
  Radio,
  Typography,
  DatePicker,
  Row,
  Col,
  Table,
  Empty,
  Icon,
  Divider,
  Popconfirm,
  Button,
  TimePicker,
  Tag
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import _ from 'lodash';
import { platformApi } from '../../../../../../../api';
import moment from 'moment';
import { ContextAPI } from '../../../../../../../ContextAPI';
import { Link } from 'react-router-dom';
const { Item } = Form;
const { Text, Title } = Typography;
const { TextArea } = Input

function LeadDetails(props) {
  let { cType, setCustomerId, deleteProvisional } = props;
  const [referredList, setReferredList] = useState([]);
  const [gstType, setGstType] = useState(true);
  const [state, setState] = useState([]);
  const [district, setDistrict] = useState([]);
  const [country, setCountry] = useState([]);
  const [date, setDate] = useState("default");
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [editPhone, setEditPhone] = useState("");
  const [error, setError] = useState({});
  const [checkAll, setCheckAll] = useState(true);
  const [checkNameNo, setCheckNameNo] = useState(true);
  const types = [
    { key: "Primary", title: "Primary" },
    { key: "Alternate", title: "Alternate" },
  ];

  const { loginCredintials } = useContext(ContextAPI);

  const column = [
    {
      title: <Text strong>Phone Number</Text>,
      dataIndex: "phone",
      key: "phone",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Type</Text>,
      dataIndex: "type",
      key: "type",
      render: (record) => <span>{record}</span>,
    },
    {
      title: <Text strong>Validity</Text>,
      dataIndex: "valid",
      key: "valid",
      render: (record) => (record ? "Valid" : "Invalid"),
    },
    {
      title: <Text strong>DND</Text>,
      dataIndex: "DND",
      key: "DND",
      render: (record) => (record ? "Yes" : "No"),
    },

    {
      title: <Text strong>Action</Text>,
      render: (data) => (
        <div>
          {data.id ? (
            <span>
              <Icon
                onClick={(event) => {
                  event.stopPropagation();
                  setEditPhone(data.id);
                  form.setFieldsValue({
                    phone: data.phone,
                    type: data.type,
                    valid: data.valid,
                    DND: data.DND,
                  });
                }}
                type="edit"
              />
              <Divider type="vertical" />
            </span>
          ) : (
              <span />
            )}
          <Popconfirm
            title="Do you want to delete this Mobile Number?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              deleteData(data);
            }}
          >
            <Icon type="delete" onClick={(event) => event.stopPropagation()} />
          </Popconfirm>
        </div>
      ),
    },
  ];

  const deleteData = (dData) => {
    if (dData.id) {
      platformApi.delete(`api/customer/phone/${dData.id}`).then((res) => {
        if (res.data.response.code === 200) {
          setDataSource([]);
          let data = dataSource.filter((data) => data.phone !== dData.phone);
          setDataSource(data);
        } else {
          message.error("Phone Not Deleted");
        }
      });
    } else {
      setDataSource([]);
      const data = dataSource.filter((data) => data.phone !== dData.phone);
      setDataSource(data);
    }
  };

  const salutations = [
    { key: "Mr", title: "Mr" },
    { key: "Mrs", title: "Mrs" },
    { key: "Ms", title: "Ms" },
    { key: "Dr", title: "Dr" },
    { key: "Prof", title: "Prof" },
    { key: "Rev", title: "Rev" },
  ];
  // let types = [
  //   { key: "Primary", title: "Primary" },
  //   { key: "Alternate", title: "Alternate" }
  // ];
  const customerTypes = [
    { key: "Sales Customer", title: "Sales Customer" },
    { key: "Service Customer", title: "Service Customer" },
    { key: "Non Customer", title: "Non Customer" },
  ];
  const customerGrouping = [
    { key: "Individual", title: "Individual" },
    { key: "Non-Individual", title: "Non-Individual" },
  ];

  const { form, open, close, data, setData } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const handleSubmit = () => {
    // setLoading(true);
    form.validateFields(async (validationError) => {
      if (
        validationError === null &&
        JSON.stringify({}) === JSON.stringify(error)
      ) {
        if ((dataSource.length !== 0) || (!checkAll) && (form.getFieldValue("name"))) {
          const salutation = form.getFieldValue("salutation");
          const name = form.getFieldValue("name");
          const fatherName = form.getFieldValue("fatherName");
          const gender = form.getFieldValue("gender");
          let dob = form.getFieldValue("dateOfBirth");
          const dateOfBirth = dob ? dob.format("DD-MM-YYYY") : undefined;
          const country = form.getFieldValue("country");
          const district = form.getFieldValue("district");
          const state = form.getFieldValue("state");
          const street = form.getFieldValue("street");
          const locality = form.getFieldValue("locality");
          const pincode = form.getFieldValue("pincode");
          const customerType = form.getFieldValue("customerType");
          const GSTNo = form.getFieldValue("GSTNo") || null;
          const GSTType = form.getFieldValue("GSTType");
          const customerGrouping = form.getFieldValue("customerGrouping");
          const refferedBy = form.getFieldValue("referredBy") || 0;
          const reffered = form.getFieldValue("referred");
          let customerId = "";
          const IDbranch = loginCredintials.branch.id;
          customerId = data.customerId;
          const customer = {
            customerId,
            salutation,
            name,
            fatherName,
            gender,
            contacts: dataSource,
            dateOfBirth,
            address: {
              district,
              state,
              country,
              street,
              locality,
              pincode,
            },
            customerType,
            GSTNo,
            GSTType,
            customerGrouping,
            refferedBy,
            reffered,
            IDbranch,
          };
          let quotationId = "";
          if (cType) {
            data ? (customer.id = data.id) : {};
          } else {
            deleteProvisional(data.id);
            quotationId = data.quotation && data.quotation.id;
          }
          props.emitData(customer, quotationId);
          form.resetFields();
          // setCusId("");
          setLoading(false);
          props.close();
          setState([]);
          setDistrict([]);
          setDataSource([]);
          setCheckAll(true);
          setCheckNameNo(true);
        }
        else if (!form.getFieldValue("name")) {
          message.error("Enter Name or number");
        }
        else {
          message.error("Enter Phone Number");
        }
      }
    });
  };
  useEffect(() => {
    if (open) {
      platformApi
        .get("/api/csc/country")
        .then((result) => {
          const { data } = result;
          if (data.code === 200) {
            setCountry(data.data);
          }
        })
        .catch((error) => {
          message.error("Unable to fetch country", 2);
        });
    }
  }, [open]);

  const setReferredCustomer = (id) => {
    platformApi.get("api/customer/reffered/" + id).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setReferredList(response.data);
        }
      }
    });
  };

  useEffect(() => {

    if (data) {
      data.phone &&
        setDataSource([
          {
            id: "",
            phone: data.phone,
            type: "Primary",
            valid: true,
            DND: false,
          },
        ]);
      data && handleCustomerType(data.customerType)
      data && data.contacts && setDataSource(data.contacts);
      data &&
        data.address &&
        handleStateChange(data.address && data.address.state && data.address.state.id);
      data &&
        data.address &&
        handleCountryChange(data.address && data.address.country && data.address.country.id);

      // setCusId(data.customerId)
      const dob = moment(data.dateOfBirth);
      data.GSTType !== "Unregistered" ? setGstType(false) : setGstType(true);
      form.setFieldsValue({
        salutation: data.salutation,
        name: data.name,
        fatherName: data.fatherName,
        gender: data.gender,
        dateOfBirth: dob,
        country:
          data.address && data.address.country && data.address.country.id,
        state: data.address && data.address.state && data.address.state.id,
        district:
          data.address && data.address.district && data.address.district.id,
        locality: data.locality || (data.address && data.address.locality),
        street: data.address && data.address.street,
        pincode: data.address && data.address.pincode,
        customerType: data.customerType,
        GSTNo: data.GSTNo,
        GSTType: data.GSTType,
        customerGrouping: data.customerGrouping,
        referredBy: data.refferedBy && data.refferedBy.id,
        referred: data.reffered && data.reffered,
      });
    }
  }, [data]);

  const filterMethod = (input, option) =>
    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

  const filterMethodReferred = (input, option) =>
    option.props.children.toString().indexOf(input) >= 0;

  const handleCountryChange = (id) => {
    platformApi
      .post("api/csc/states", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setState(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Customer form : ", error);
        message.error("Unable to fetch state", 2);
      });
  };

  const handleStateChange = (id) => {
    platformApi
      .post("api/csc/cities", { id })
      .then((result) => {
        const { data } = result;
        if (data.code === 200) {
          setDistrict(data.data);
        }
      })
      .catch((error) => {
        console.error("Error on Customer form : ", error);
        message.error("Unable to fetch cities", 2);
      });
  };
  const dateChange = (e) => {
    if (e) {
      const date = new Date(e._d);
      setDate(date.toLocaleString());
    }
  };

  const editingPhone = () => {
    if (form.getFieldValue("phone").length === 10 && !error.PNO) {
      const obj = {
        phone: form.getFieldValue("phone"),
        type: form.getFieldValue("type"),
        id: editPhone,
      };
      let i = 0;
      dataSource.map((element, index) => {
        if (element.id === editPhone) {
          i = index;
        }
      });
      setDataSource([]);
      const data = dataSource;
      data[i] = obj;
      setDataSource(data);
      form.setFieldsValue({ phone: undefined });
      form.setFieldsValue({ type: undefined });

      setEditPhone("");
    } else {
      message.error("Enter Valid Phone Number");
    }
  };
  const handleCustomerType = (id) => {
    if (id === "Service Customer") {
      setCheckNameNo(true);
      setCheckAll(false);
    }
    if (id === "Sales Customer") {
      setCheckNameNo(true);
      setCheckAll(true);
    }
    if (id === "Non Customer") {
      setCheckNameNo(false);
      setCheckAll(false);
    }
  };
  const clearFields = () => {
    form.resetFields();
    setDataSource([]);
    // setCusId("");
    setState([]);
    setDistrict([]);
    setError({});
    setGstType(true);
    setCheckNameNo(true);
    setCheckAll(true);
  };

  return (
    <Form>
      <Row>
        <Item>
          <Text strong>Customer ID:</Text> {data && data.customerId}
        </Item>
      </Row>
      <Row type="flex" justify="space-around">

        <Col span={11}>
          <Item label="Customer Type" colon={false}>
            {getFieldDecorator("customerType", {
              rules: [
                { required: true, message: "Select Customer Type" },
              ],
            })(
              <Select
                disabled={!editable}
                placeholder="Customer Type"
                onChange={handleCustomerType}
              >
                {customerTypes.map((ct) => (
                  <Select.Option key={ct.key}>{ct.title}</Select.Option>
                ))}
              </Select>
            )}
          </Item>
          <Row type="flex" justify="space-between">
            <Col span={4}>
              <Item label="Salutation" colon={false}>
                {getFieldDecorator("salutation", {
                  rules: [
                    { required: checkAll, message: "Select Salutation" },
                  ],
                })(
                  <Select disabled={!editable} placeholder="Sal.">
                    {salutations.map((sal) => (
                      <Select.Option key={sal.key}>{sal.title}</Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>

            <Col span={19}>
              <Item
                label="Customer Name"
                colon={false}
                validateStatus={error.NAME && error.NAME.type}
                help={error.NAME && error.NAME.message}
              // required
              >
                {getFieldDecorator("name", {
                  rules: [
                    {
                      required: checkNameNo,
                      message: "Enter Customer  Name",
                    },
                  ],
                })(
                  <Input
                    disabled={!editable}
                    placeholder="Customer Name"
                    pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          NAME: {
                            type: "error",
                            message: "Enter Valid Name",
                          },
                        });
                      } else {
                        delete error.NAME;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row>
            <Item
              label="Father's Name"
              colon={false}
              validateStatus={error.FATHERNAME && error.FATHERNAME.type}
              help={error.FATHERNAME && error.FATHERNAME.message}
            >
              {getFieldDecorator("fatherName", {
                rules: [{ required: checkAll, message: "Enter Father Name" }],
              })(
                <Input
                  disabled={!editable}
                  placeholder="Father's Name"
                  pattern="^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                  onInput={(event) => {
                    if (!event.target.checkValidity()) {
                      setError({
                        ...error,
                        FATHERNAME: {
                          type: "error",
                          message: "Enter Valid Name",
                        },
                      });
                    } else {
                      delete error.FATHERNAME;
                      setError(error);
                    }
                  }}
                />
              )}
            </Item>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label="Gender" colon={false}>
                {getFieldDecorator("gender", {
                  rules: [
                    { required: checkAll, message: "Please Select Gender" },
                  ],
                })(
                  <Radio.Group disabled={!editable}>
                    <Radio value="Male">Male</Radio>
                    <Radio value="Female">Female</Radio>
                  </Radio.Group>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item label="DOB" colon={false}>
                {getFieldDecorator("dateOfBirth", {
                  rules: [
                    {
                      required: checkAll,
                      message: "please Enter dateOfBirth",
                    },
                  ],
                })(
                  <DatePicker
                    style={{ width: "100%" }}
                    size={date}
                    format="DD/MM/YYYY"
                    disabled={!editable}
                    allowClear
                    onChange={(e) => dateChange(e)}
                    disabledDate={(moment) => Date.now() < moment._d}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Item label="Contacts" colon={false}>
            <Table
              locale={{
                emptyText: (
                  <Empty
                    imageStyle={{
                      height: 25,
                      fontSize: 30,
                    }}
                    description={
                      <Text disabled strong>
                        No Contacts
                        </Text>
                    }
                    image={<Icon type="contacts" />}
                  />
                ),
              }}
              rowKey={(record) => record.id}
              pagination={false}
              size="small"
              columns={column.filter(
                (columns) =>
                  columns.title.props.children !== "Action" || editable
              )}
              style={{ cursor: "pointer" }}
              dataSource={dataSource}
            />
          </Item>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item
                label="Phone"
                colon={false}
                validateStatus={error.PNO && error.PNO.type}
                help={error.PNO && error.PNO.message}
              // required
              >
                {getFieldDecorator("phone", {
                  rules: [{ required: false, message: "Enter Phone" }],
                })(
                  <Input
                    onWheel={(event) => event.currentTarget.blur()}
                    disabled={!editable}
                    placeholder="Phone"
                    pattern="^[0-9]{10}$"
                    onChange={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          PNO: {
                            type: "error",
                            message: "Enter Valid Phone Number",
                          },
                        });
                      } else {
                        delete error.PNO;
                        setError(error);
                      }
                    }}
                    addonBefore="+91"
                    maxLength={10}
                  />
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item label="Type" colon={false}>
                {getFieldDecorator("type", {
                  initialValue:
                    dataSource.length > 1 ? "Alternate" : "Primary",
                  rules: [{ required: false, message: "Enter type" }],
                })(
                  <Select placeholder="Type" disabled={!editable}>
                    {types.map((obj) => (
                      <Select.Option key={obj.key} value={obj.key}>
                        {obj.title}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>

          {!editPhone ? (
            <Button
              style={{ marginTop: "3vh" }}
              type="primary"
              disabled={!editable}
              onClick={() => {
                if (form.getFieldValue("phone").length === 10 && !error.PNO) {
                  const obj = {
                    id: "",
                    phone: form.getFieldValue("phone"),
                    type: form.getFieldValue("type"),
                    valid: true,
                    DND: false,
                  };
                  if (obj.type === "Primary") {
                    types.shift();
                  }
                  setDataSource([...dataSource, obj]);
                  form.setFieldsValue({ phone: undefined });
                  form.setFieldsValue({ type: "Alternate" });
                } else {
                  message.error("Enter valid Phone number");
                }
              }}
            >
              Add Contact
            </Button>
          ) : (
              <Button
                style={{ marginTop: "3vh" }}
                type="primary"
                onClick={() => editingPhone()}
              >
                Save
              </Button>
            )}
        </Col>
        <Col span={11}>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item
                label="Door No. / Street Name"
                colon={false}
                validateStatus={error.DOOR && error.DOOR.type}
                help={error.DOOR && error.DOOR.message}
              >
                {getFieldDecorator("street", {
                  rules: [
                    {
                      required: checkAll,
                      message: "Enter Door No. / Street Name",
                    },
                  ],
                })(
                  <Input
                    disabled={!editable}
                    placeholder="Door No. / Street Name"
                    pattern="^[a-zA-Z0-9-/,._]+[a-zA-Z0-9-/ ,._]+$"
                    maxLength={50}
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          DOOR: {
                            type: "error",
                            message: "Enter Valid Data",
                          },
                        });
                      } else {
                        delete error.DOOR;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item
                label="Locality"
                colon={false}
                validateStatus={error.LOCALITY && error.LOCALITY.type}
                help={error.LOCALITY && error.LOCALITY.message}
              >
                {getFieldDecorator("locality", {
                  rules: [{ required: checkAll, message: "Enter Locality" }],
                })(
                  <Input
                    disabled={!editable}
                    maxLength={50}
                    pattern="^[a-zA-Z0-9]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$"
                    placeholder="Locality"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          LOCALITY: {
                            type: "error",
                            message: "Enter Valid Locality",
                          },
                        });
                      } else {
                        delete error.LOCALITY;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label="Country" colon={false}>
                {getFieldDecorator("country", {
                  rules: [{ required: checkAll, message: "Enter Country" }],
                })(
                  <Select
                    showSearch
                    disabled={!editable}
                    placeholder="Country"
                    onChange={handleCountryChange}
                    filterOption={filterMethod}
                  >
                    {country.map((object) => (
                      <Select.Option value={object.id} key={object.id}>
                        {object.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item label="State" colon={false}>
                {getFieldDecorator("state", {
                  rules: [{ required: checkAll, message: "Enter State" }],
                })(
                  <Select
                    showSearch
                    disabled={!editable || state.length == 0}
                    placeholder="State"
                    filterOption={filterMethod}
                    onChange={handleStateChange}
                  >
                    {state.map((object) => (
                      <Select.Option value={object.id} key={object.id}>
                        {object.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label="District" colon={false}>
                {getFieldDecorator("district", {
                  rules: [{ required: checkAll, message: "Enter District" }],
                })(
                  <Select
                    showSearch
                    disabled={!editable || district.length == 0}
                    placeholder="District"
                    filterOption={filterMethod}
                  >
                    {district.map((object) => (
                      <Select.Option key={object.id} value={object.id}>
                        {object.name}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item
                label="Pincode"
                colon={false}
                validateStatus={error.PIN && error.PIN.type}
                help={error.PIN && error.PIN.message}
              // required
              >
                {getFieldDecorator("pincode", {
                  rules: [{ required: checkAll, message: "Enter pincode" }],
                })(
                  <Input
                    disabled={!editable}
                    placeholder="Pincode"
                    onWheel={(event) => event.currentTarget.blur()}
                    maxLength={7}
                    pattern="([0-9]{6}|[0-9]{3}\s[0-9]{3})"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          PIN: {
                            type: "error",
                            message: "Enter Valid PIN Code",
                          },
                        });
                      } else {
                        delete error.PIN;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>

          <Row type="flex" justify="space-between">
            <Col span={11}>
              <Item label="GST Type" colon={false}>
                {getFieldDecorator("GSTType", {
                  initialValue: "Unregistered",
                })(
                  <Select
                    placeholder="GST Type"
                    disabled={!editable}
                    onChange={(event) => {
                      event === "Unregistered"
                        ? (setGstType(true),
                          form.setFieldsValue({ GSTNo: undefined }),
                          delete error.GST,
                          setError(error))
                        : setGstType(false);
                    }}
                  >
                    <Select.Option key="Registered" value="Registered">
                      Registered
                      </Select.Option>
                    <Select.Option key="Unregistered" value="Unregistered">
                      Unregistered
                      </Select.Option>
                    <Select.Option key="UINHolder" value="UINHolder">
                      UIN Holder
                      </Select.Option>
                    <Select.Option key="Composition" value="Composition">
                      Composition
                      </Select.Option>
                  </Select>
                )}
              </Item>
            </Col>
            <Col span={11}>
              <Item
                label="GSTIN"
                colon={false}
                validateStatus={error.GST && error.GST.type}
                help={error.GST && error.GST.message}
              >
                {getFieldDecorator("GSTNo", {
                  rules: [
                    {
                      required: !gstType,
                      message: "Enter GST",
                    },
                  ],
                })(
                  <Input
                    style={{ textTransform: "uppercase" }}
                    onKeyUp={event => {
                      setFieldsValue({
                        GSTNo: formatValue(event, "toUpperCase")
                      })
                    }}
                    pattern="^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}[Zz]{1}[0-9A-Za-z]{1}$"
                    maxLength={15}
                    disabled={gstType || !editable}
                    placeholder="GST"
                    onInput={(event) => {
                      if (!event.target.checkValidity()) {
                        setError({
                          ...error,
                          GST: {
                            type: "error",
                            message: "Enter Valid GSTIN",
                          },
                        });
                      } else {
                        delete error.GST;
                        setError(error);
                      }
                    }}
                  />
                )}
              </Item>
            </Col>
          </Row>
          <Row type="flex" justify="space-between">
            <Col span={24}>
              <Item label="Customer Grouping" colon={false}>
                {getFieldDecorator("customerGrouping", {
                  rules: [
                    {
                      required: checkAll,
                      message: "Select Customer Grouping",
                    },
                  ],
                })(
                  <Select
                    disabled={!editable}
                    placeholder="Customer Grouping"
                  >
                    {customerGrouping.map((Grouping) => (
                      <Select.Option key={Grouping.key}>
                        {Grouping.title}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Item>
            </Col>
            {/* <Col span={11}>
                <Item label="Referred By" colon={false}>
                  {getFieldDecorator("referredBy", {
                    rules: [{ required: false, message: "Select Referred By" }],
                  })(
                    <Select
                      showSearch
                      disabled={!editable}
                      placeholder="Referred By"
                      filterOption={filterMethodReferred}
                      onChange={handleReferenceChange}
                    >
                      <Select.Option value={null}>None</Select.Option>
                      {reference.map((object) =>
                        data ? (
                          data.id !== object.id &&
                          !referredList.some(
                            (referrer) => referrer.id === object.id
                          ) && (
                            <Select.Option value={object.id} key={object.id}>
                              {object.contacts[0]
                                ? object.contacts[0].phone
                                : "No Contact Added"}{" "}
                              - {object.name}
                            </Select.Option>
                          )
                        ) : (
                          <Select.Option value={object.id} key={object.id}>
                            {object.contacts[0]
                              ? object.contacts[0].phone
                              : "No Contact Added"}{" "}
                            - {object.name}
                          </Select.Option>
                        )
                      )}
                    </Select>
                  )}
                </Item>
              </Col> */}
          </Row>
          {data && (
            <Row>
              <Col span={24}>
                <Item label="Referred Customers" colon={false}>
                  {referredList.length > 0 ? (
                    referredList.map((referred) => {
                      return (
                        <Tag style={{ cursor: "pointer" }} color="#006a71" key={true}>
                          <Link key={true}
                            style={{ color: "#2db7f5" }}
                            target="_blank"
                            to={`referred_customer/?id=${referred.id}`}
                          >
                            {referred.name} -{" "}
                            {referred.contacts && referred.contacts[0].phone}
                          </Link>
                        </Tag>
                      );
                    })
                  ) : (
                      <Tag color="#006a71">No Referred Customer</Tag>
                    )}
                </Item>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Form>
  )
}
const WrappedLeadDetails = Form.create({ name: 'LeadDetailsForm' })(LeadDetails);
export default WrappedLeadDetails;