import React, { useState, useEffect, useContext } from "react";
import { Typography, Input, Tooltip, Button, Dropdown, Menu, Icon, message } from "antd";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';
import AccessoriesTable from "./AccessoriesTable";
import "./accessories.less";
import AccessoriesModal from "./AccessoriesModal";
import { platformApi } from "../../../api";
import WrappedAdvancedFilter from "./AdvancedFilters";
const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;
import { ContextAPI } from "../../../ContextAPI";

const Accessories = (props) => {
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [selected, setSelected] = useState(null);
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false)
  const [deleteAccess, setDeleteAccess] = useState(false)
  const { loginCredintials } = useContext(ContextAPI)
  //filters
  const [filter, setFilter] = useState([]);
  const [filteropen, setFilteropen] = useState(false);
  const [search, setSearch] = useState([])
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    let menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;

  const setPageData = (search, val) => {
    setSpinning(true);
    platformApi
      .post("/api/accessories/get", {
        page: val ? val : page,
        size: limit,
        searchString: search && search.name
      })
      .then(res => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setData(res.data.response.data.accessory);
            setCount(res.data.response.data.count);
          } else {
            message.error("Unable to fetch Accessories");
          }
        } else {
          message.error("Unable to fetch Accessories");
        }
      })
      .catch(error => {
        console.error("Error on Accessories : ", error);
        message.error("Unable to fetch Accessories");
        setSpinning(false);
      });
  };
  useEffect(() => {
    loginCredintials.roleAccess && loginCredintials.roleAccess.map(each => {
      if (each.subModule === 'accessories') {
        if (each.access.create) {
          setCreateAccess(true)
        }
        if (each.access.update) {
          setModifyAccess(true)
        }
        if (each.access.delete) {
          setDeleteAccess(true)
        }
      }
    })
    setPageData();
  }, []);



  useEffect(() => {

    setPageData(search);

  }, [search, page, limit])


  const deleteData = id => {
    setSpinning(true);
    let temp = page != 1 && dataSource.length === 1
    platformApi
      .delete(`/api/accessories/${id}`)
      .then(result => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1)
              setPageData(null, 1)
            } else {
              setPageData();
            }
            message.success("Accessories deleted Successfully");
          } else {
            message.error("Unable to delete Accessory", 2);
          }
        } else {
          message.error("Unable to delete Accessory", 2);
        }
      })
      .catch(error => {
        setSpinning(false);
        message.error("Unable to delete Accessory", 2);
        console.error("Error on Accessory : ", error);
      });
  };
  const addData = accessory => {
    setSpinning(true);
    if (accessory.id) {
      platformApi
        .put(`/api/accessories/${accessory.id}`, accessory)
        .then(res => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (accessory.id === obj.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);

              message.success("Accessory saved successfully");
            } else {
              message.error("Unable to save Accessory");
            }
          } else {
            message.error("Unable to save Accessory");
          }
        })
        .catch(error => {
          setSpinning(false);
          message.error("Unable to save Accessory");
          console.error("Error on Accessories : ", error);
        });
    } else {
      platformApi
        .post("/api/accessories/", accessory)
        .then(result => {
          setSpinning(false);
          const { data } = result;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("Accessory added successfully");
            } else {
              message.error("Unable to add Accessory");
            }
          } else if (data.code == 500 && data.err.code == 501) {
            message.error("Accessory code already exists", 2);
          } else {
            message.error("Unable to add accessory", 2);
          }
        })
        .catch(error => {
          setSpinning(false);
          message.error("Unable to add Accessory");
          console.error("Error on Accessories : ", error);
        });
    }
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          Accessories [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                  <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search style={{ width: "30%" }}
          placeholder="Search Accessory"
          onSearch={(event) => {
            let searches = { name: event };
            setPage(1)
            setSearch(searches);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        {/* <div style={{ width: "20%", textAlign: "center" }}>
          <Button type="link" onClick={() => { filteropen ? setFilteropen(false) : setFilteropen(true) }}>
            {filteropen ? "Back" : "Advanced  Filters"}
          </Button>
        </div> */}
        <Button
          onClick={() => {
            setEditFlag(true);
            setAddFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Accessories
        </Button>
      </div>
      <WrappedAdvancedFilter
        filteropen={filteropen}
        searchName={search}
        close={setFilteropen}
        id={filter}
        setId={setFilter}
        setData={setData}
        setCount={setCount}
        page={page}
        setPageData={setPageData}
        limit={limit}
        setSearch={setSearch}
        setSpinning={setSpinning}
      />
      <AccessoriesTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          current: page,
          defaultCurrent: 1,
          total: count,
          showQuickJumper: true
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        dataSource={dataSource}
        delete={id => deleteData(id)}
        spinner={{ spinning }}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <AccessoriesModal
        data={dataSource[dataSource.findIndex(obj => obj.id === selected)]}
        emitData={data => addData(data)}
        open={addFlag}
        editable={editFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
        modify={modifyAccess}
      />
    </div>
  );
}


export default withRouter(Accessories)