import React from 'react'
import { Modal} from 'antd'

const AudioPlayback = ({ visible,setVisibility }) => {
    
    const handleCancel = () => {
        setVisibility(!visible)
    }

    return (
         <Modal
            title="Session Recording Playback"
            footer={false}
          visible={visible}
          onCancel={handleCancel}
        >
            <audio style={{width:'100%'}} autoPlay src='' controls />
        </Modal>
    )
}

export default AudioPlayback;
