import React, { useState } from "react";
import { Table, Divider, Tag, Popconfirm, Typography } from "antd"
const { Text } = Typography
import moment from "moment";

export default function QuotationTable(props) {
  const { pagination, dataSource, spinner, openModal, deleteAccess } = props;

  const column = [
    {
      title: "Quotation Id",
      dataIndex: "quotationId",
      key: "quotationId",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customer",
      render: (record, data) => {
        const name = data.customerName
          || (data.proCustomer
            ? data.proCustomer.name
            : record && record.name)
        return <span>{name}</span>;
      },
    },
    {
      title: "Vehicle",
      dataIndex: "vehicle",
      key: "vehicle",
      render: (record) => record && record.map((value) => <Tag color="blue">{value.vehicleDetail.modelName}</Tag>),
      // render: (record) => <span>{record && record.length ? record[0].vehicleDetail.modelName : "-"}</span>,
    },
    {
      title: "Mobile No",
      dataIndex: "customer",
      key: "phone",
      render: (record, data) => {
        const phone = data.quotationPhone || (data.proCustomer
          ? data.proCustomer.phone ||
          data.proCustomer.refferedBy.contacts[0].phone
          : record && record.contacts && record.contacts[0].phone);
        return <span>{phone}</span>;
      },
    },

    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "joinedOn",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          {
            deleteAccess ?
              <Popconfirm
                title="Do you want to delete this Quotation?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  props.delete(record.id);
                }}
              >
                <span
                  onClick={(event) => event.stopPropagation()}
                  className="linkylink"
                >
                  Delete
            </span>
              </Popconfirm>
              :
              <Text strong disabled>No Actions Given</Text>
          }

        </div>
      ),
    },
  ];

  return (
    <Table
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      loading={spinner}
      style={{ cursor: "pointer" }}
      onRow={(record) => ({
        onClick: () => openModal(record.id, false),
      })}
    />
  );
}
