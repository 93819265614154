import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Input,
  Upload,
  Icon,
  Typography,
  Row,
  message,
  Col,
  Spin
} from "antd";

import "./index.less";
import { formatValue } from '../../../../../../utils'
import { getUUID } from '../../../../../../_helpers/utils';
const { Item } = Form;

const { Text } = Typography;

const ImagesModal = ({
  form,
  open,
  close,
  emitData,
  data: Data,
  vehicleDetail,
  setImageUploadData,
  editFlag,
}) => {
  const [formData, setFormData] = useState(null);
  const [url, setUrl] = useState(null);
  const [id, setId] = useState();
  const [loading, setLoading] = useState(false);
  const [info, setInfo] = useState({});
  const [error, setError] = useState({});
  const { getFieldDecorator } = form;
  const [spin, setSpin] = useState(false)

  useEffect(() => {
    if (Data) {
      console.log("Data", Data)
      setUrl(Data.url);
      setId(Data.id && Data.id)
      form.setFieldsValue({
        color: Data.color,
        code: Data.code,
      });
    }
  }, [Data]);

  const handleSubmit = () => {
    form.validateFields((validationError) => {
      if (validationError === null) {
        if (url) {
          const file = {
            color: form.getFieldValue("color"),
            code: form.getFieldValue("code"),
            add: id ? false : true,
            id: id ? id : getUUID(),
          };
          console.log("file", file)
          let fd = {
            ...formData,
            color: file.code + " - " + file.color,
            [file.color]: info ? info.file?.originFileObj : "",

          };
          setFormData(fd);
          //setImageuploadData  tranfers the file value to super parent & update state
          setImageUploadData(fd);
          file.url = url;
          //editflg bypassed by parent component to switch btw addData & emitData
          editFlag ? emitData(file, file.id) : emitData(file);
          clearFields();
          close();
        } else {
          message.error("Select an Image");
        }
      }
    });
  };

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const clearFields = () => {
    setUrl();
    setLoading(false);
    form.resetFields();
    setError({});
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange = (info) => {
    setSpin(true)
    // setUrl(null);
    setInfo(info);
    let fd = {
      master: "Company Master",
      module: "Vehicle Master",
      id: vehicleDetail.name || "Vehicle",
      type: "Vehicle Image",
    };
    setFormData(fd);
    getBase64(info.file.originFileObj, (url) => {
      setUrl(url);
      setSpin(false)
    });
  };

  return (
    <div>
      <div>
        <Modal
          wrapClassName="Image-Upload-Modal"
          title={<Text strong>Image Upload</Text>}
          visible={open}
          onCancel={() => {
            clearFields();
            setSpin(false)
            close();
          }}
          okButtonProps={{ loading }}
          okText={loading ? "Uploading" : "Upload"}
          onOk={handleSubmit}
        >
          <Form>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={8}>
                <Item>
                  {getFieldDecorator("image")(
                    <Upload.Dragger
                      listType="picture-card"
                      showUploadList={false}
                      name="files"
                      beforeUpload={(e) => beforeUpload(e)}
                      // action={`${ENDPOINT}/api/upload/mocky`}
                      onChange={(e) => handleChange(e)}
                    >
                      {
                        url ? (
                          <img width="100%" src={url} />
                        ) : (
                            <span>
                              <Row justify="center" type="flex">
                                <Icon type={"plus"} />
                              </Row>
                              <Text type="secondary">{"Upload"}</Text>
                            </span>
                          )}
                    </Upload.Dragger>
                  )}
                </Item>
              </Col>

              <Col span={12}>
                <Row>
                  <Item
                    validateStatus={error.NAME && error.NAME.type}
                    help={error.NAME && error.NAME.message}
                    label={<Text>Color Name</Text>}
                    colon={false}
                  >
                    {getFieldDecorator("color", {
                      rules: [{ required: true, message: "Enter Color Name" }],
                    })(
                      <Input
                        pattern="^[A-Z][a-zA-Z.\s]*[a-zA-Z.]+$"
                        onKeyUp={(e) => {
                          if (e.target.value != "") {
                            form.setFieldsValue({
                              color: formatValue(e, "allCaps")
                            })
                          }
                        }}
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              NAME: {
                                type: "error",
                                message: "Enter Valid Color Name",
                              },
                            });
                          }
                          else {
                            delete error.NAME;
                            setError(error);
                          }
                        }}
                        placeholder="Color Name"
                      />
                    )}
                  </Item>
                </Row>
                <Row>
                  <Item
                    validateStatus={error.COLOR && error.COLOR.type}
                    help={error.COLOR && error.COLOR.message}
                    label={<Text>Color Code</Text>}
                    colon={false}
                  >
                    {getFieldDecorator("code", {
                      rules: [{ required: true, message: "Enter Color Code" }],
                    })(
                      <Input
                        pattern="^[a-zA-Z0-9]+(([',.-][a-zA-Z ])?[a-zA-Z]*)*$"
                        maxLength={10}
                        onKeyUp={(e) =>
                          form.setFieldsValue({
                            code: formatValue(e, "toUpperCase")
                          })
                        }
                        onInput={(event) => {
                          if (!event.target.checkValidity()) {
                            setError({
                              ...error,
                              COLOR: {
                                type: "error",
                                message: "Enter Valid Color Code",
                              },
                            });
                          }
                          else {
                            delete error.COLOR;
                            setError(error);
                          }
                        }}
                        placeholder="Color Code"
                      />
                    )}
                  </Item>
                </Row>
              </Col>
            </Row>
          </Form>
        </Modal>
      </div>
    </div>
  );
};

const WrappedImageUpload = Form.create({ name: "image" })(ImagesModal);

export default React.memo(WrappedImageUpload);
