import React, { useState, useRef, useEffect } from "react";
import { Modal, Typography, Steps, Button, Icon } from "antd";
import WrappedCustomerForm from "./CustomerForm";
import WrappedVehicleForm from "./VehicleForm";

import "./index.less";
import WrappedPaymentForm from "./PaymentForm";
import { platformApi } from "../../../../api";

const { Step } = Steps;

const { Text } = Typography;

const ChallanModal = ({ open = false, close = () => {} }) => {
  const ChildRef = useRef();
  const ClearRef = useRef();

  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({});

  const icons = [
    <Icon style={{ fontSize: "1rem" }} type="user" />,
    <Icon style={{ fontSize: "1rem" }} type="car" />,
    <Icon style={{ fontSize: "1rem" }} type="transaction" />,
  ];

  const steps = [
    { id: 1, title: "Customer Details" },
    { id: 2, title: "Vehicle Details" },
    { id: 3, title: "Payment Details" },
  ];

  const handleSubmit = () => {
    close();
    clearFields();
  };

  const forms = [
    <WrappedCustomerForm
      open={open}
      validityRef={ChildRef}
      clearRef={ClearRef}
      data={data}
    />,
    <WrappedVehicleForm
      validityRef={ChildRef}
      clearRef={ClearRef}
      data={data}
    />,
    <WrappedPaymentForm clearRef={ClearRef} data={data} />,
  ];

  useEffect(() => {
    if (open) {
      platformApi.post("/api/idGenerate/saleChallan").then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            setData({ ...data, challanId: response.data });
          }
        }
      });
    }
  }, [open]);

  const clearFields = () => {
    setData({});
    setCurrent(0);
    ClearRef.current();
  };

  return (
    <Modal
      wrapClassName="Challan-Modal"
      cancelText={current > 0 ? "Previous" : "Cancel"}
      onCancel={() => {
        clearFields();
        close();
      }}
      footer={[
        <Button
          onClick={() => {
            if (current > 0) {
              setCurrent(current - 1);
            } else {
              close();
              clearFields();
            }
          }}
        >
          {current > 0 ? "Previous" : "Cancel"}
        </Button>,
        <Button
          onClick={() => {
            if (ChildRef.current())
              current < 2 ? setCurrent(current + 1) : handleSubmit();
          }}
          type="primary"
        >
          {current < 2 ? "Next" : "Save"}
        </Button>,
      ]}
      title={<Text strong>Sale Challan</Text>}
      visible={open}
    >
      <Steps size="small" type="navigation" current={current}>
        {steps.map((step, index) => (
          <Step
            key={step.id}
            icon={icons[index]}
            title={<Text>{step.title}</Text>}
          />
        ))}
      </Steps>
      <div>{forms[current]}</div>
    </Modal>
  );
};

export default ChallanModal;
