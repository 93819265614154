import React, { useState } from 'react'
import { Row, Col, Modal, message, Typography, Input } from 'antd';
import { platformApi } from '../../../api'
import { withRouter } from 'react-router-dom';
// import Moment from 'moment'


const DiscardModal = (props) => {
  const { visibility, setVisibility, customerDetail } = props
  const { Title } = Typography;
  const { TextArea } = Input

  const [remark, setRemark] = useState("");

  const discardFollowUp = () => {
    // console.log(customer)
    platformApi.post("/api/customer/followup/discard", {
      customerId: customerDetail.id,
      remarks: remark
    })
      .then(res => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data
          if (response.code === 200) {
            message.success("Follow Up discarded successfully", 2)
          }
          else {
            message.error("Error in discarding follow up", 2)
          }
        }
        else {
          message.error("Error in discarding follow up", 2)
        }
      })
      .catch(err => {
        message.error("Error in discarding follow up", 2)
      })
    props.history.push("/autoadmin/follow-ups")
    close()
  }

  const close = () => {
    setRemark("")
    setVisibility(false)
  } 

  return (
    <Modal
      title={<Title level={4}>Discard Followup</Title>}
      visible={visibility}
      onOk={() => discardFollowUp()}
      cancelText={"Cancel"}
      onCancel={() => {
        close();
      }}
      okText="Discard"
      wrapClassName="discard-followup-modal"
    >
      <Row type="flex" justify="space-between" gutter={[16, 16]}>
        <Col span={24}>
          Are you sure you want to Discard this Followup?
        </Col>
        <Col span={24}>
          <strong>Reason: </strong>
          <TextArea rows={4}
            onChange={event => setRemark(event.target.value)}
            value={remark}
          >{remark}</TextArea>
        </Col>
      </Row>
    </Modal>
  )
}

export default withRouter(DiscardModal);
