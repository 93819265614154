import React, { useState } from "react";
import { Table, Divider, Popconfirm, Button, Typography } from "antd";
import { withRouter } from "react-router-dom";
const { Text } = Typography
import moment from 'moment'

const JobCodeTable = ({
  pagination,
  dataSource,
  spinner,
  openModal,
  delete: deleteData,
  history,
  modify,
  deleteAccess
}) => {
  const { goBack } = history;

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (record) => <span>{record}</span>,
    },
    {
      title: "CGST",
      dataIndex: "sac",
      key: "cgst",
      render: (record) => <span>{record && record.cgst}</span>,
    },
    {
      title: "SGST",
      dataIndex: "sac",
      key: "sgst",
      render: (record) => <span>{record && record.sgst}</span>,
    },
    {
      title: "IGST",
      dataIndex: "sac",
      key: "igst",
      render: (record) => <span>{record && record.igst}</span>,
    },
    {
      title: "Cess",
      dataIndex: "sac",
      key: "cess",
      render: (record) => <span>{record && record.cess}</span>,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdOn",
      render: (record) => <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (record) => (
        <div>
          {
            modify
              ?
              <span
                onClick={(event) => {
                  event.stopPropagation();
                  openModal(record.id, true);
                }}
                className="linkylink"
              >
                Modify
          </span>
              : <span></span>
          }

          {
            deleteAccess
              ?
              <Popconfirm
                title="Do you want to delete this Job?"
                okText="Yes"
                cancelText="No"
                onCancel={(event) => {
                  event.stopPropagation();
                }}
                onConfirm={(event) => {
                  event.stopPropagation();
                  deleteData(record.id);
                }}
              >

                <span
                  onClick={(event) => event.stopPropagation()}
                  className="linkylink"
                >
                  <Divider type="vertical" />
                  Delete
            </span>
              </Popconfirm>
              :
              <span></span>
          }
          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }


          {/* <Divider type="vertical" />
          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
        </div>
      ),
    },
  ];

  return (
    <Table
    // tableLayout="fixed"
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      loading={spinner}
      style={{ cursor: "pointer" }}
      onRow={(record) => ({
        onClick: () => openModal(record.id, false),
      })}
    />
  );
};

export default withRouter(JobCodeTable);
