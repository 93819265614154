import React, { useState, useEffect, useContext } from "react";
import {
	Typography,
	Input,
	Button,
	Dropdown,
	Menu,
	Icon,
	Form,
	Row,
	Col,
	DatePicker,
	Select,
	Table,
	InputNumber,
	message,
	Tooltip,
} from "antd";
import { useHistory, useLocation } from "react-router-dom";
// Import Table and Form
import { ContextAPI } from "../../../../ContextAPI";
import { platformApi } from "../../../../api";
import VehiclePurchaseInvoiceForm from "./PurchaseInvoice";
import { LeftOutlined } from "@ant-design/icons";
import moment from "moment";

const { Title } = Typography;
const { Search } = Input;
const { Item } = Menu;

export default function PurchaseInvoice() {
  const location = useLocation();
  const history = useHistory();

  let url = location.pathname;
  let id = url.substring(url.lastIndexOf("/") + 1);
  const isEditable = location.state?.editable === true ? true : false;
  console.log({isEditable});
	const [addFlag, setAddFlag] = useState(false);
	const [editFlag, setEditFlag] = useState(false);
  const [editable, setEditable] = useState(isEditable);
	const [selected, setSelected] = useState(-1);
	const [dataSource, setData] = useState([]);
  const [vehiclePurchaseData, setVehiclePurchaseData] = useState({createDate: moment(new Date())});
	const [spinning, setSpinning] = useState(false);
	const [bookingId, setBookingId] = useState("");
	const [createAccess, setCreateAccess] = useState(false);
	const [modifyAccess, setModifyAccess] = useState(false);
	const [deleteAccess, setDeleteAccess] = useState(false);
	const { loginCredintials } = useContext(ContextAPI);

  useEffect(() => {
    if(id === ":id"){
      setBooking();
      // setVehiclePurchaseData(prev => ({...prev, createDate: createDate}))
    }else {
      getPurchaseInvoice(id);
    }
  },[id])

	const setBooking = () => {
		platformApi.post("/api/idGenerate/purchaseInvoice").then((res) => {
			let { data } = res;
			if (data.code === 200) {
				let { response } = data;
				console.log("response.data", response.data);
				setBookingId(response.data);
			} else {
				message.error("Couldn't fetch id");
			}
		});
	};

	useEffect(() => {
		loginCredintials.roleAccess &&
			loginCredintials.roleAccess.map((each) => {
				if (each.subModule === "vehicle_purchase_invoice") {
					if (each.access.create) {
						setCreateAccess(true);
					}
					if (each.access.update) {
						setModifyAccess(true);
					}
					if (each.access.delete) {
						setDeleteAccess(true);
					}
				}
			});
		setSpinning(true);
	}, []);

  const getPurchaseInvoice = async (id) => {
    try {
      const response = await platformApi
      .get(`/api/purchaseInvoice/${id}`)
      let { data } = response;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          console.log(response.data);
          setVehiclePurchaseData(response.data);
        } else {
          message.error("Failed to fetch Vehicle Purchase Invoice");
        }
      } else {
        message.error("Failed to fetch Vehicle Purchase Invoice");
      }
    } catch (error) {
      console.error("Error on Vehicle Purchase Invoice : ", error);
      message.error("Failed to fetch Vehicle Purchase Invoice");
    }
  }

	const addData = (vpi) => {
		setSpinning(true);
		if (vpi.id) {
			platformApi
				.put(`/api/purchaseInvoice/${vpi.id}`, vpi)
				.then((res) => {
					setSpinning(false);
					let { data } = res;
					if (data.code === 200) {
						let { response } = data;
						if (response.code === 200) {
							message.success("Vehicle Purchase Invoice saved successfully", 2);
              history.push("/autoadmin/vehicle_purchase_invoice");
						} else {
							message.error("Unable to save Vehicle Purchase Invoice", 2);
						}
					} else {
						message.error("Unable to save Vehicle Purchase Invoice", 2);
					}
				})
				.catch((err) => {
          setSpinning(false);
					message.error("Unable to save Vehicle Purchase Invoice", 2);
					console.error("Error on Vehicle Purchase Invoice : ", err);
				});
		} else
			platformApi
				.post("/api/purchaseInvoice/", vpi)
				.then((res) => {
					setSpinning(false);
					let { data } = res;
					if (data.code === 200) {
						let { response } = res.data;
						if (response.code === 200) {
							message.success("Vehicle Purchase Invoice added successfully");
              history.push("/autoadmin/vehicle_purchase_invoice");
						} else {
							message.error("Error in Vehicle Purchase Invoice creation");
						}
					} else {
						message.error("Error in Vehicle Purchase Invoice creation");
					}
				})
				.catch((err) => {
					console.error("Error on Vehicle Purchase Invoice : ", err);
				});
	};

	return (
		<div>
			<Title style={{ width: "60%" }} level={4}>
				<Tooltip placement="topLeft" title={"Back"}>
					<Button style={{ marginRight: "1rem" }} onClick={() => history.push("/autoadmin/vehicle_purchase_invoice")}>
						<LeftOutlined />
					</Button>
				</Tooltip>
				Add Vehicle Purchase Invoice
			</Title>
			<VehiclePurchaseInvoiceForm
				id={bookingId}
				editData={vehiclePurchaseData}
				emitData={(data) => addData(data)}
				editable={editable}
				open={addFlag}
				close={() => {
					setSelected(null);
					setAddFlag(false);
				}}
				tableData={dataSource}
				modify={modifyAccess}
			/>
		</div>
	);
}
