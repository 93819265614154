import React from 'react';
import { Modal } from "antd";
import { Upload, Button, Row, Col, Typography } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ENDPOINT } from '../../../../../../constants';
const { Title } = Typography;


const File = (props) => {
    const { close, visible, fileUploadHandler, fileupload } = props;

    return (
        <Modal

            title={<Title level={4}>File Uploading</Title>}
            visible={visible}
            width="30vw"
            onCancel={close}
            onOk={fileupload}
        >
            <Row gutter={16} type="flex" align="center">
                <Col span={16}>
                    <Upload
                        listType="picture-card"
                        className="avatar-uploader"
                        action={`${ENDPOINT}api/upload/mocky`}
                        listType="picture"
                        onChange={fileUploadHandler}
                    >
                        <Button icon={<UploadOutlined />}>Upload</Button>
                    </Upload>
                </Col>
            </Row>
        </Modal>
    )
}

export default File;
