import React, { useState, useEffect, useRef } from 'react';
import {
  Button,
  message,
  Modal,
  Typography,
  Steps,
  Icon
} from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import _ from 'lodash';
import { platformApi } from '../../../../../../api';
import WrappedLeadDetails from './LeadDetails';
import WrappedActivityHistory from './ActivityHistory';
import WrappedSettingsForm from './Settings';


const { Step } = Steps;
const { Text } = Typography;

function EditLead({ open, close, values, editable, setValues, dataSource, leadData, setLeadData }) {
  const ChildRef = useRef();
  const ClearRef = useRef();

  const [current, setCurrent] = useState(0);
  const [data, setData] = useState({})

  const icons = [
    <Icon style={{ fontSize: "1rem" }} type="home" />,
    <Icon style={{ fontSize: "1rem" }} type="contacts" />,
    <Icon style={{ fontSize: "1rem" }} type="bank" />
  ];

  const steps = [
    { id: 1, title: "Lead Details" },
    { id: 2, title: "Activity History" },
    { id: 3, title: "Settings" },
  ];

  const [confirmLoading, setConfirmLoading] = useState(false);
  useEffect(() => {
    setData(values)
  }, [values])

  const forms = [
    <WrappedLeadDetails
      data={leadData && leadData.customer}
      setData={setLeadData}
      editable={editable}
      validityRef={ChildRef}
      clearRef={ClearRef} />,
    <WrappedActivityHistory
      data={data}
      setData={setData}
      values={values}
      editable={editable}
      validityRef={ChildRef}
      clearRef={ClearRef} />,
    <WrappedSettingsForm
      data={data}
      setData={setData}
      values={values}
      editable={editable}
      validityRef={ChildRef}
      clearRef={ClearRef}
    />

  ]


  const clearFields = () => {
    setData({});
    setCurrent(0);
    // ClearRef.current();
    setValues(null)
    close()
  };

  const handleSubmit = () => {

  }



  return (
    <Modal
      style={{ top: '10vh' }}
      width="74%"
      confirmLoading={confirmLoading}
      title="Lead Editor"
      visible={open}
      onCancel={() => {
        clearFields();
      }}
      footer={[
        <Button
          onClick={() => {
            current > 0 ? setCurrent(current - 1) : close();
          }}
        >
          {current > 0 ? "Previous" : "Cancel"}
        </Button>,
        <Button
          onClick={() => {
            // if (ChildRef.current())
            current < 2 ? setCurrent(current + 1) : (editable ? clearFields() : handleSubmit());
          }}
          type="primary"
        >
          {current < 2 ? "Save & Next" : (editable ? "Cancel" : "Save")}
        </Button>
      ]}
    >
      <div>
        <Steps size="small"
          className="site-navigation-steps" current={current}>
          {steps.map((step, index) => (
            <Step
              key={step.id}
              // icon={icons[index]}
              title={<Text>{step.title}</Text>}
            />
          ))}
        </Steps>
        <div>{forms[current]}</div>

      </div>
    </Modal>
  );
}
export default EditLead;
