import React from "react";
import { Table, Divider, Popconfirm, Button,Typography } from "antd";
import { withRouter } from "react-router-dom";
import moment from 'moment'
const{Text}=Typography;
const TemplateTable = ({
  pagination,
  dataSource,
  spinner,
  openModal,
  delete: deleteData,
  history,
  modifyAccess
}) => {
  const { goBack } = history;

  const column = [
    // {
    //   render: () => (
    //     <Button
    //       type="ghost"
    //       size="small"
    //       shape="circle"
    //       onClick={(event) => {
    //         event.stopPropagation();
    //         goBack();
    //       }}
    //     >
    //       ...
    //     </Button>
    //   ),
    // },
    {
      title: "Module",
      dataIndex: "module",
      key: "module",
    },
    {
      title: "Sub Module",
      dataIndex: "submodule",
      key: "submodule",
    },
    {
      title: "Template Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdOn",
      render: (text) => <span>{moment(new Date(text)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>{modifyAccess?
          <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(data.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          :
          <span></span>
    }

          {/* <Divider type="vertical" />

          <Button type="ghost" size="small" shape="circle">
            ...
          </Button> */}
          {
              !modifyAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>

          }
        </div>
      ),
    },
  ];
  return (
    <Table
      loading={spinner}
      rowKey={(record) => record.id}
      pagination={pagination}
      columns={column}
      dataSource={dataSource}
      style={{ cursor: "pointer" }}
      onRow={(record) => ({
        onClick: () => openModal(record.id, false),
      })}
    />
  );
};

export default withRouter(TemplateTable);
