import React, { useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { message, Tooltip, Button, Typography } from "antd";
import { LeftOutlined } from '@ant-design/icons';

import { platformApi } from '../../../../api';
import WrappedInvoiceForm from './InvoiceForm';

const Invoice = (props) => {
  const { redirect } = props;

  const { Title } = Typography;
  const [supplier, setSupplier] = useState([]);
  const [partsData, setPartsData] = useState([]);
  const [editable, setEditable] = useState(false);
  const [editData, setEditData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [searchSupply, setSearchSupply] = useState();
  const [partSearch, setPartSearch] = useState(false);
  const [modifyType, setModifyType] = useState(false);
  const [spinning, setSpinning] = useState(false);
  const location = useLocation();
  const history = useHistory();
  let url = location.pathname;
  let id = url.substring(url.lastIndexOf('/') + 1);

  useEffect(() => {
    if (id !== ':id') {
      setEditable(true);
    } else {
      setEditable(false);
    }
    getSupplierData();
    getPartsDetails();
    getSelectedSpareInvoice();
  }, []);

  useEffect(() => {
    if (search) {
      getPartsDetailOnSearch(search);
    }
    else {
      getPartsDetails();
    }
  }, [search]);

  const getSupplierData = () => {
    platformApi
      .get('/api/supplier', { page: page, size: limit, searchString: searchSupply })
      .then((res) => {
        let { data } = res
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            let activeSupplier = response.data
            setSupplier(activeSupplier.filter((supplier) => supplier.status === "ACTIVE"))
          }
        } else {
          message.error('Cannot fetch Supplier Data');
        }

        //setTableLoading(false);
      })
      .catch((err) => {
        console.error('Supplier fetch error', err);
        message.error('Cannot fetch Supplier Data');
        //setTableLoading(false);
      });
  }

  const getPartsDetails = () => {
    platformApi
      .post(`/api/partsMaster/get/`, {
        page: page,
        size: 100,
        searchString: ''
      })
      .then((res) => {
        //setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            // console.log(response.data.partsMaster)
            setPartsData(response.data.partsMaster);
          }
        }
        else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        // setSpinning(false);
        message.error("Unable to fetch Parts", 2);
      });
  }

  const getPartsDetailOnSearch = async (search) => {
    await platformApi
      .post("/api/purchaseSpareInvoice/search", {
        search
      })
      .then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setPartsData(response.data.partsMasterData);
          }
        } else {
          message.error("Unable to fetch Parts", 2);
        }
      })
      .catch((error) => {
        console.error("Error on Parts Master : ", error);
        // setSpinning(false);
        message.error("Unable to fetch Parts", 2);
      });

  }

  const getSelectedSpareInvoice = () => {
    if (id !== ':id') {
      platformApi
        .get(`/api/purchaseSpareInvoice/${id}`)
        .then((res) => {
          //setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setEditData(response.data)
            } else {
              message.error("Unable to fetch Purchase Spare Item", 2);
            }
          } else {
            message.error("Unable to fetch Purchase Spare Item", 2);
          }
        })
        .catch((error) => {
          console.error("Error on Purchase Spare Item : ", error);
          // setSpinning(false);
          message.error("Unable to fetch Purchase Spare Item", 2);
        });
    }
  }

  return (
    <>
      <Title level={4}>
        <Tooltip placement="topLeft" title={"Back"}>
          <Button
            style={{ marginRight: '1rem' }}
            onClick={() => history.push('/autoadmin/purchase_spares_invoice')}
          >
            <LeftOutlined />
          </Button>
        </Tooltip>
            Purchase Spare Invoice
          </Title>
      <WrappedInvoiceForm
        supplier={supplier}
        partsData={partsData}
        editable={editable}
        modifyType={modifyType}
        id={id}
        setSearch={setSearch}
        onEdit={(editable) => {
          setEditable(editable)
          setModifyType(!editable)
        }}
        editData={editData}
        setPartSearch={setPartSearch}
      />
    </>
  )
}

export default Invoice