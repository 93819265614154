import React, { useState, useEffect, useContext } from "react";
import { Modal, Row, Typography, Input, Form, Select, message } from "antd";
import moment from "moment";

import { platformApi } from "../../../../../../api";
import { withRouter, Link } from "react-router-dom";
import { ContextAPI } from "../../../../../../ContextAPI";

// import "./index.less";

const { Item } = Form;
const { Title, Text } = Typography;
const { Option } = Select;

const TestSMSForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [editable, setEditable] = useState(props.editable);
  const { loginCredintials } = useContext(ContextAPI);

  const { form, open, close, data, setData, sms, id, checkCredits } = props;
  const { getFieldDecorator, getFieldValue } = form;

  const handleSubmit = () => {
    platformApi
      .post("/api/sendSms/test", {
        phone: form.getFieldValue("number"),
        sms: sms,
        senderId: id,
      })
      .then((res) => {
        if (res.data.code === 200) {
          let resp = res.data.response;
          if (resp.code === 200) {
            message.success(resp.message);
            checkCredits();
            close();
          } else {
            message.error("Error in sending test SMS");
            close();
          }
        }
        else if (res.data.code === 401) {
          message.error(res.data.message)
        }
        else {
          message.error("Error in sending test SMS");
          close();
        }
      });
  };

  const clearFields = () => {
    form.resetFields();
  };

  return (
    <Modal
      title={<Title level={4}>Test SMS</Title>}
      visible={open}
      onCancel={() => {
        clearFields();
        close();
      }}
      onOk={() => (handleSubmit())}
      cancelText="Cancel"
      okText="Send"
    >
      <Form>
        <Row>
          <Item label="Number" colon={false}>
            {getFieldDecorator("number", {
              rules: [{ required: true, message: "Enter Number" }],
            })(<Input placeholder="Number" />)}
          </Item>
        </Row>
      </Form>
      <br />
    </Modal>
  );
};
const WrappedAccessoriesModal = Form.create({ name: "test_sms" })(TestSMSForm);
export default withRouter(WrappedAccessoriesModal);
