import React, { useState, useEffect } from "react";
import {
  Form,
  Modal,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Upload,
  Icon,
  Typography,
  Checkbox,
  message as msg,
  Button,
  Popconfirm,
  Tabs,
  Table
} from "antd";
import { platformApi } from "../../../../api";
import { formatValue } from "../../../../utils";
import "./PartsModal.less";
import moment from "moment";
import WrappedSparesInventory from "./SparesInventory";
import { ENDPOINT } from "../../../../constants";
const { Item } = Form;
const { TabPane } = Tabs;
const { Text, Title } = Typography;

const PartsModal = (props) => {
  const { add } = props;
  const [loading, setLoading] = useState(false);
  const [wefDate, setWEFDate] = useState();
  const [HSN, setHSN] = useState([]);
  const [models, setModels] = useState([]);
  const [manufacturer, setManufacturer] = useState([]);
  const [editable, setEditable] = useState(props.editable);
  const [error, setError] = useState({});
  const [message, setMessage] = useState({});
  const [image, setImage] = useState(null);
  const [imgLoading, setImgLoading] = useState(false);
  const [showInConsumer, setShowInConsumer] = useState(false);
  const [showInAutoCloud, setShowInAutoCloud] = useState(false);
  const [formData, setFormData] = useState(null);
  const [multiObjects, setMultiObjects] = useState([])
  const [multiFileList, setMultiFileList] = useState([])
  const [urls, setUrls] = useState([]);
  const [upload, setUpload] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [HistoryData, setHistoryData] = useState([]);
  const [removeLogo, setRemoveLogo] = useState(false);

  const partsAvailabity = [
    { key: "AVAILABLE", title: "Available" },
    { key: "NOTAVAILABLE", title: "Not Available" },
  ];

  const { form, open, close, data, modify } = props;
  const { getFieldDecorator, setFieldsValue } = form;

  const handleSubmit = async () => {
    console.log({form});
    form.validateFields(async (validationError) => {
      if (
        validationError === null &&
        JSON.stringify({}) === JSON.stringify(error)
      ) {
        let vehicles = form.getFieldValue("vehicleModel");
        let vehiclesArray = [];
        if (vehicles)
          if (!data) {
            vehicles.map((element) => {
              vehiclesArray.push({
                id: "",
                vehicle: element,
              });
            });
          } else {
            vehicles.map((element) => {
              let flag = false;
              data.vehicleSuit.map((prevElement) => {
                if (prevElement.vehicle.id === element) {
                  vehiclesArray.push({
                    id: prevElement.id,
                    vehicle: element,
                  });
                  flag = true;
                }
              });
              if (!flag) {
                vehiclesArray.push({
                  id: "",
                  vehicle: element,
                });
              }
            });
          }
        setLoading(true);
        const number = form.getFieldValue("partNo");
        const oldPartNum = form.getFieldValue("oldPartNo");
        const name = form.getFieldValue("partName");
        const category = form.getFieldValue("category");
        const displayName = form.getFieldValue("displayName")
        // const minStock = parseInt(form.getFieldValue("minStock"));
        // const maxStock = parseInt(form.getFieldValue("maxStock"));
        // const reorderLevel = parseInt(form.getFieldValue("reorder"));
        const moq = parseInt(form.getFieldValue("MOQ"));
        const manufacturer = form.getFieldValue("manufacturer");
        const vehicleSuit = vehiclesArray;
        const color = form.getFieldValue("color");
        const partStatus = form.getFieldValue("partStatus");
        const hsn = form.getFieldValue("HSN");
        const binNum = form.getFieldValue("binNo");
        const ndp = parseFloat(form.getFieldValue("NDP"));
        const mrp = parseFloat(form.getFieldValue("MRP"));
        const createdOn = new Date().toLocaleDateString();
        const largeCategoryName = form.getFieldValue("largeCategoryName");
        const size = form.getFieldValue("size");
        const mainPartNumber = form.getFieldValue("mainPartNumber");

        // const reorderPoint = parseInt(form.getFieldValue("reorderPoint"))
        // const reorderQuantity = parseInt(form.getFieldValue("reorderQuantity"))



        const parts = {
          number,
          oldPartNum,
          name,
          category,
          // minStock,
          // maxStock,
          // reorderLevel,
          moq,
          manufacturer,
          vehicleSuit,
          color,
          partStatus,
          hsn,
          binNum,
          ndp,
          mrp,
          displayName,
          // reorderPoint,
          // reorderQuantity,
          size,
          mainPartNumber,
          wefDate,
          createdOn,
          largeCategoryName,
          showInConsumer,
          showInAutoCloud,
          url: urls
        };
        console.log({parts});
        data ? (parts.id = data.id) : {};
        if (multiFileList.length > 0) {
          for (let item of multiFileList) {
            let formData = new FormData();
            formData.append("profile", item.originFileObj);
            formData.append("master", "Company Master");
            formData.append("module", "PartsMaster");
            formData.append("type", "image");
            formData.append("id", name);
            await platformApi
              .post("/api/upload/image", formData, {
                headers: { "Content-Type": "multipart/form-data" },
              })
              .then((res) => {
                let { data } = res;
                if (data.code === 200) {
                  parts.url.push(data.response.data.Location)
                  setRemoveLogo(false);

                } else {
                  message.error("Upload Not Done");
                }
              })
              .catch((error) => {
                console.error("Error on Form : ", error);
                message.error("Unable to upload image");
              });
          }
          props.emitPart(parts);
          // console.log("Images", parts.url)
        } else {
          console.log("No form data");
          if (removeLogo) {
            parts.url = null;
          }
          props.emitPart(parts);
        }
        form.resetFields();
        setUrls([]);
        setMultiFileList([]);
        setMessage({});
        setError({});
        setLoading(false);
        setImage(null);
        setShowInConsumer(false);
        setShowInAutoCloud(false);
        setUpload(false);
        close();
      } else {
        msg.error("Enter All Fields correctly");
      }
    });
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleUpload = (info) => {
    if (info.file.status === "uploading") {
      let FileList = [...info.fileList]
      setMultiFileList(FileList)
      setUpload(true)
      setImage(null);
      setImgLoading(true);
      props.setImg(true);
    } else if (info.file.status === "done") {
      props.setImg(false);
      setUpload(false)
      setImgLoading(false);
      getBase64(info.file.originFileObj, (url) => {
        setImage(url);
      });
    }
  };
  const handleDelete = (data) => {
    let filteredData = multiFileList.filter((item) => { return item !== data })
    setMultiFileList(filteredData)
  }

  const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
  };

  const getSpares = (id) => {
    platformApi.get(`/api/sparesInventory/getSpares/${id}`).then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          // console.log(response.data, 'res')
          let data = []
          for (let item of response.data) {
            let filterData = response.data.filter((i) => i.partNo.id == item.partNo.id)
            let fl = true;
            for (let item of data) {
              for (let item2 of filterData) {
                if (item2.id == item[0].id) {
                  fl = false
                  break
                }
              }
              if (fl == false) {
                break
              }
            }
            if (fl) {
              data.push(filterData)
            }
          }
          let finalData = []

          for (let item of data) {
            if (item.length == 1) {
              let newData = item[0]
              // let q=0;
              // let q2=0
              // newData.transactions.sparesPurchase.map(item=>{
              //     item.purchaseItemInvoice.map(dt=>{
              //     // console.log("item",d.id,record.partNo.id)
              //     if(dt.partNumber.id===newData.partNo.id){
              //         q+=dt.quantity
              //     }
              //     })
              // })
              // newData.transactions.sparesSale.map(item=>{
              //     item.saleItemInvoice.map(dt=>{
              //         // console.log("item",d.id,record.partNo.id)
              //         if(dt.partNumber.id===newData.partNo.id){
              //             q2+=dt.quantity
              //         }
              //         })
              // })
              newData.branch = [{
                partId: newData.partNo.id,
                sparesId: item[0].id,
                branch: item[0].branch,
                binNum: item[0].binNum,
                reorderQuantity: item[0].reorderQuantity,
                maxStock: item[0].maxStock,
                minStock: item[0].minStock,
                reorderLevel: item[0].reorderLevel,
                phyQuantity: item[0].phyQuantity,
                accQuantity: item[0].accQuantity,
                transactions: item[0].transactions
              }]
              finalData.push(newData)
            }
            else {
              let newData = item[0]
              let branch = []
              let transactions = {
                sparesPurchase: [],
                sparesSale: []
              }
              for (let d of item) {
                // let q=0;
                // d.transactions.sparesPurchase.map(item=>{
                //   item.purchaseItemInvoice.map(dt=>{
                //     // console.log("item",d.id,record.partNo.id)
                //     if(dt.partNumber.id===d.partNo.id){
                //         q+=dt.quantity
                //     }
                //   })
                // })

                // let q2=0;
                // d.transactions.sparesSale.map(item=>{
                //   item.saleItemInvoice.map(dt=>{
                //     // console.log("item",d.id,record.partNo.id)
                //     if(dt.partNumber.id===d.partNo.id){
                //         q2+=dt.quantity
                //     }
                //   })
                // })
                // console.log("quantity",q,q2)

                branch.push({
                  partId: d.partNo.id,
                  sparesId: d.id,
                  branch: d.branch,
                  binNum: d.binNum,
                  reorderQuantity: d.reorderQuantity,
                  maxStock: d.maxStock,
                  minStock: d.minStock,
                  reorderLevel: d.reorderLevel,
                  phyQuantity: d.phyQuantity,
                  accQuantity: d.accQuantity,
                  transactions: d.transactions
                })
                if (d.transactions) {
                  transactions.sparesPurchase = [...transactions.sparesPurchase, ...d.transactions.sparesPurchase]
                  transactions.sparesSale = [...transactions.sparesSale, ...d.transactions.sparesSale]
                  // console.log("tr",d.transactions)
                }
              }
              console.log("oi", branch, transactions)
              newData.transactions = transactions
              newData.branch = branch
              console.log(newData)
              finalData.push(newData)
            }
          }
          console.log("final", finalData)
          setSelectedData(finalData[0])
          //setData(val);
          // setCount(val.length);
        }
      }
    })
      .catch((err) => {
        console.error("PSI Fetch Error: ", err);
        // message.error("Couldn't Fetch PSI");
      });
  }
  const getSparesHistory = (id) => {
    platformApi.get(`/api/sparesInventory/getSparesHistory/${id}`)
      .then((result) => {
        let { data } = result;
        if (data.code === 200) {
          let { response } = data;
          if (response.code === 200) {
            console.log("HistoryData", response.data)
            setHistoryData(response.data)
          }
        }
      })
      .catch((err) => {
        console.error("Couldn't get Part History: ", err);
      });
  }
  useEffect(() => {
    setImage(null);
    setShowInConsumer(false);
    setShowInAutoCloud(false);
    setEditable(props.editable);
    if (data) {
      platformApi.get("/api/hsn").then((res) => {
        const { response } = res.data;
        if (response.code === 200) {
          setHSN(response.data);
        }
      });
      platformApi.get("/api/manufacturer/branch").then((res) => {
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setManufacturer(response.data);
          }
        }
      });
      data.hsn &&
        handleHSNChange(HSN.findIndex((obj) => obj.id === data.hsn.id));
      handleManufacturer(data.manufacturer && data.manufacturer.id);
      data.wefDate = moment(data.wefDate);
      const modelKeyName = fetchModalName();
      data.url && setImage(data.url);
      data.showInConsumer && setShowInConsumer(data.showInConsumer);
      data.showInAutoCloud && setShowInAutoCloud(data.showInAutoCloud);
      console.log("ReceiveData", data)
      data.id && getSpares(data.id)
      data.id && getSparesHistory(data.id)

      form.setFieldsValue({
        partNo: data.partNumber,
        oldPartNo: data.oldPartNum ? data.oldPartNum : null,
        partName: data.partName,
        category: data.category,
        displayName: data.displayName ? data.displayName : "",
        // minStock: data.minStock,
        // maxStock: data.maxStock,
        // reorder: data.reorderLevel,
        MOQ: data.moq,
        manufacturer: data.manufacturer && data.manufacturer.id,
        // vehicleModel: data.modal ? data.modal && modelKeyName.map(model => model) :
        //   data.vehicleSuit && data.vehicleSuit.map((obj) => obj.vehicle.id),
        vehicleModel: data.vehicleSuit && data.vehicleSuit.map((obj) => obj.vehicle.id),
        color: data.remarks || data.color,
        partStatus: data.partStatus,
        HSN: data.hsn && data.hsn.id,
        binNo: data.binNum,
        NDP: data.ndp,
        MRP: data.mrp,
        WEFDate: data.wefDate,
        largeCategoryName: data.largeCategoryName,
        mainPartNumber: data.mainPartNumber,
        size: data.size,
        // reorderPoint: data.reorderPoint,
        // reorderQuantity: data.reorderQuantity
      });
      setUrls([...data.url])
    }
  }, [data, props.editable]);

  useEffect(() => {
    // if (data) {
    platformApi.get("/api/hsn").then((res) => {
      const { response } = res.data;
      if (response.code === 200) {
        // console.log(response.data)
        setHSN(response.data);
      }
    });

    platformApi.get("/api/manufacturer/branch").then((res) => {
      let { data } = res;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setManufacturer(response.data);
          form.setFieldsValue({
            manufacturer:
              response.data && response.data[0] && response.data[0].id,
          });
        }
      }
    });
    // }
  }, []);

  const filterType = (input, option) => {
    return option.props.children.toString().toLowerCase().indexOf(input) >= 0;
  };

  useEffect(() => {
    let today = new Date();
    form.setFieldsValue({
      WEFDate: moment(today),
      partStatus: "AVAILABLE",
      manufacturer: manufacturer[0] && manufacturer[0].id,
    });
    handleManufacturer(manufacturer[0] && manufacturer[0].id);
    let date = moment(today).format("DD-MM-YYYY");
    setWEFDate(date);
  }, [add]);

  const handleManufacturer = (id) => {
    platformApi.get(`/api/vehicleMaster/man/${id}`).then((res) => {
      const { data } = res;
      if (data.code === 200) {
        const { response } = data;
        if (response.code === 200) {
          setModels(response.data);
        }
      }
    });
  };

  const fetchModalName = () => {
    const modalKey = [];
    const modelCodeAndName = []
    for (const [key, value] of Object.entries(data && data.modal || {})) {
      if (value !== '') {
        modalKey.push(key);
      }
    }
    if (open && models.length > 0) {
      modalKey.map((key) => {
        models.map((model) => {
          if (key == model.modelCode) {
            modelCodeAndName.push(key + ' - ' + model.modelName);
          }
        })
      })
    }
    return modelCodeAndName;
  }

  const handleHSNChange = (index) => {
    if (index) {
      setTimeout(() => {
        form.setFieldsValue({
          cgst: HSN[index].cgst,
          cess: HSN[index].cess,
          sgst: HSN[index].sgst,
          igst: HSN[index].igst,
        });
      }, 1000)
    }
  };

  const onChange = (date, dateString) => {
    setWEFDate(dateString);
  };

  const checkQuantity = (value, type) => {
    let err = {};
    let minStock =
      type === "minStock"
        ? value
        : form.getFieldValue("minStock") &&
        parseInt(form.getFieldValue("minStock"));
    let maxStock =
      type === "maxStock"
        ? value
        : form.getFieldValue("maxStock") &&
        parseInt(form.getFieldValue("maxStock"));
    let reorder =
      type === "reorder"
        ? value
        : form.getFieldValue("reorder") &&
        parseInt(form.getFieldValue("reorder"));

    if (minStock > maxStock)
      err = {
        ...err,
        MAXSTOCK: {
          type: "error",
          message: "Should be greater than Min Stock",
        },
      };
    else if (reorder > maxStock)
      err = {
        ...err,
        MAXSTOCK: {
          type: "error",
          message: "Should be greater than Reorder Level",
        },
      };
    else delete err.MAXSTOCK;
    if (minStock > reorder)
      err = {
        ...err,
        REORDER: {
          type: "error",
          message: "Should be greater than Min Stock",
        },
      };
    else delete err.REORDER;
    delete error.MAXSTOCK;
    delete error.REORDER;
    setError({ ...error, ...err });
  };

  const onVehicleChange = (id) => {
    if (data) {
      let deleteVehicle = "";
      data.vehicleSuit.map((e) => {
        if (e.vehicle.id === id) {
          deleteVehicle = e.id;
        }
      });
      platformApi
        .delete(`/api/partsmaster/vehicle/${deleteVehicle}`)
        .then((res) => {
        });
    }
  };

  // console.log("Images", urls)
  const handlePartsImageDelete = (data) => {
    let filteredData = urls.filter((item) => {
      return item != data
    })
    setUrls(filteredData)
  }

  const filterMethod = (input, option) =>
    option.props.children.toString().indexOf(input) >= 0;

  const checkType = (text, otherType, errorField, errorMsg) => {
    if (otherType === "MRP") {
      if (parseFloat(text) >= parseFloat(form.getFieldValue(otherType))) {
        setError({
          ...error,
          [errorField]: "error",
        });
        setMessage({ [errorField]: errorMsg });
      } else {
        delete error[errorField]
        delete message[errorField]
      }
    } else if (otherType === "NDP") {
      if (parseFloat(text) <= parseFloat(form.getFieldValue(otherType))) {
        setError({
          ...error,
          [errorField]: "error",
        });
        setMessage({ [errorField]: errorMsg });
      } else {
        delete error[errorField]
        delete message[errorField]
      }
    } else if (text === form.getFieldValue(otherType)) {
      setError({
        ...error,
        [errorField]: "error",
      });
      setMessage({ [errorField]: errorMsg });
    } else {
      delete error[errorField]
      delete message[errorField]
    }
  };
  const HistoryColumn = [
    {
      title: "Part Details",
      dataIndex: "Part",
      key: "Part",
      render: record => <span disabled="true">{
        `${record.partNumber} - ${record.partName}`
      }</span>
    },
    {
      title: "Transcation Type",
      dataIndex: "type",
      key: "type",
      render: (record, data) => (<span>{record}</span>)
    },
    {
      title: "JOB Card",
      dataIndex: "sparesMaterialSale",
      key: "sparesMaterialSale",
      render: (record, data) => (<span className={record && record.job ? "linkylink" : ""}>{record && record.job ? record.job.jobNo : "-"}</span>)
    },
    {
      title: "Sale Invoice",
      dataIndex: "sparesSale",
      key: "sparesSale",
      render: (record, data) => (<span className={record && record.invoiceNumber ? "linkylink" : ""}>{record && record.invoiceNumber ? record.invoiceNumber : "-"}</span>)
    },
    {
      title: "Purchase Invoice",
      dataIndex: "sparesPurchase",
      key: "sparesPurchase",
      render: (record, data) => (<span className={record && record.invoiceNumber ? "linkylink" : ""}>{record && record.invoiceNumber ? record.invoiceNumber : "-"}</span>)
    },
    // {
    //   title: "Txn Details",
    //   dataIndex: "x",
    //   key: "x",
    //   render: (data, record) => {
    //     return (
    //       <>
    //         { record?.sparesMaterialSale != null &&
    //           <span className="linkylink">
    //             {record?.job?.jobNo}
    //           </span>
    //         }
    //         {
    //           record?.sparesSale &&
    //           <span className="linkylink">
    //             {record.invoiceNumber}
    //           </span>
    //         }
    //         {
    //           record?.sparesPurchase &&
    //           <span>
    //             {record.invoiceNumber}
    //           </span>
    //         }
    //       </>
    //     )
    //   }
    // },
    {
      title: "Quantity",
      dataIndex: "x",
      key: "x",
      render: (data, record) => {
        return (
          <>
            { record?.sparesMaterialSale &&
              record?.sparesMaterialSale && record?.sparesMaterialSale?.parts.map(item => {
                if (item.part.id === record.Part.id) {
                  return (
                    <span>
                      {item.quantity}
                    </span>
                  )
                }
              })
            }
            {
              record?.sparesSale &&
              record?.sparesSale && record?.sparesSale?.saleItemInvoice.map(item => {
                if (item.partNumber)
                  if (item.partNumber.id === record.Part.id) {
                    return (
                      <span>
                        {item.quantity}
                      </span>
                    )
                  }
              })
            }
            {
              record?.sparesPurchase &&
              record?.sparesPurchase && record?.sparesPurchase?.purchaseItemInvoice.map(item => {
                if (item.partNumber)
                  if (item.partNumber.id === record.Part.id) {
                    return (
                      <span>
                        {item.quantity}
                      </span>
                    )
                  }
              })
            }
          </>
        )
      }
    },
    {
      title: "Transcation Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record, data) => (<span>{record && moment(new Date(record)).format("DD-MM-YYYY")}</span>
      )
    }
  ];
  return (
    <Modal
      wrapClassName="parts-modal"
      bodyStyle={{ width: "50vw" }}
      title="Parts Master"
      visible={open}
      onCancel={() => {
        close();
        form.resetFields();
        setMessage({});
        setUrls([]);
        setMultiFileList([]);
        setError({});
        setUpload(false);
      }}
      okText={editable ? "Save" : "Modify"}
      okButtonProps={{ loading, disabled: imgLoading || !editable && !modify }}
      onOk={() => (editable ? handleSubmit() : setEditable(true))}
    >
      <Tabs defaultActiveKey="1" >
        <TabPane
          tab={
            <span>
              Parts Details
            </span>
          }
          key="1"
        >
          <Form>
            <Row gutter={16}>
              <Col span={6}>
                <Item
                  validateStatus={error.PART && error.PART.type}
                  help={error.PART && error.PART.message}
                  label="Part No"
                  colon={false}
                  required
                >
                  {getFieldDecorator("partNo", {
                    rules: [{ required: true, message: "Enter Part No" }],
                  })(
                    <Input
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          partNo: formatValue(e, "toUpperCase"),
                        })
                      }
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            PART: {
                              type: "error",
                              message: "Enter valid Part No.",
                            },
                          });
                          setMessage({ PART: "Enter Valid No" });
                        } else {
                          delete error.PART;
                          if (
                            event.target.value ===
                            form.getFieldValue("oldPartNo") &&
                            event.target.value.length > 0
                          ) {
                            setError({
                              ...error,
                              OLDPART: {
                                type: "error",
                                message: "Same as Part No",
                              },
                            });
                          } else {
                            error.OLDPART && delete error.OLDPART;
                          }
                        }
                      }}
                      disabled={!editable}
                      placeholder="Part No"
                      pattern="^[0-9A-Za-z]*$"
                    />
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  validateStatus={error.OLDPART && error.OLDPART.type}
                  help={error.OLDPART && error.OLDPART.message}
                  label="Old Part No"
                  colon={false}
                >
                  {getFieldDecorator("oldPartNo", {
                    rules: [{ required: false, message: "Enter Old Part No" }],
                  })(
                    <Input
                      placeholder="Old Part No"
                      pattern="^[0-9A-Za-z]*$"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          oldPartNo: formatValue(e, "toUpperCase"),
                        })
                      }
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            OLDPART: {
                              type: "error",
                              message: "Enter valid Part No",
                            },
                          });
                        } else {
                          delete error.OLDPART;
                          if (
                            form.getFieldValue("partNo") === event.target.value &&
                            event.target.value.length > 0
                          ) {
                            setError({
                              ...error,
                              OLDPART: {
                                type: "error",
                                message: "Same as Part No",
                              },
                            });
                          }
                        }
                      }}
                      disabled={!editable}
                      placeholder="Old Part No"
                    />
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  validateStatus={error.PARTNAME && error.PARTNAME.type}
                  help={error.PARTNAME && error.PARTNAME.message}
                  label="Part Name"
                  colon={false}
                  required
                >
                  {getFieldDecorator("partName", {
                    rules: [{ required: true, message: "Enter Part Name" }],
                  })(
                    <Input
                      pattern="^[a-zA-Z0-9]+(([',. -][a-zA-Z0-9])?[a-zA-Z0-9]*)*$"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          partName: formatValue(e, "allCaps"),
                        })
                      }
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            PARTNAME: {
                              type: "error",
                              message: "Enter Valid Part Name",
                            },
                          });
                        } else {
                          delete error.PARTNAME;
                          setError(error);
                        }
                      }}
                      disabled={!editable}
                      placeholder="Part Name"
                    />
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item label="Category"
                  colon={false}
                  validateStatus={error.category && error.category.type}
                  help={error.category && error.category.message}
                >
                  {getFieldDecorator("category", {
                    rules: [{ required: false, message: "Enter Category" }],
                  })(
                    <Input
                      onKeyUp={(e) =>
                        setFieldsValue({
                          category: formatValue(e, "toUpperCase")
                        })
                      }
                      pattern="^[a-zA-Z]*$"
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            category: {
                              type: "error",
                              message: "Enter Valid Category",
                            },
                          });
                        } else {
                          delete error.category;
                          setError(error);
                        }
                      }}
                      disabled={!editable}
                      placeholder="Category"
                    />
                  )}
                </Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Item label="Large Category Name"
                  colon={false}
                  validateStatus={error.largeCategory && error.largeCategory.type}
                  help={error.largeCategory && error.largeCategory.message}
                >
                  {getFieldDecorator("largeCategoryName", {
                    rules: [{ required: false, message: "Enter Category" }],
                  })(
                    <Input
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          largeCategoryName: formatValue(e, "allCaps"),
                        })
                      }
                      pattern="^[a-zA-Z]+((['][a-zA-Z])?[a-zA-Z]*)*$"
                      disabled={!editable}
                      placeholder="Large Category Name"
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            largeCategory: {
                              type: "error",
                              message: "Enter Valid Large Category",
                            },
                          });
                        } else {
                          delete error.largeCategory;
                          setError(error);
                        }
                      }}
                    />
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  label="Size"
                  colon={false}
                  validateStatus={error.size && error.size.type}
                  help={error.size && error.size.message}
                >
                  {getFieldDecorator("size", {
                    rules: [{ required: false, message: "Enter Size" }],
                  })(
                    <Input
                      disabled={!editable}
                      // pattern="^[0-9]{10}$"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Enter Size"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          size: formatValue(e, "toUpperCase"),
                        })
                      }
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            size: {
                              type: "error",
                              message: "Enter Size",
                            },
                          });
                        } else {
                          delete error.size;
                          setError(error);
                        }
                      }}
                    />
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  label="Main Part No."
                  colon={false}
                  validateStatus={error.mainPartNumber && error.mainPartNumber.type}
                  help={error.mainPartNumber && error.mainPartNumber.message}
                >
                  {getFieldDecorator("mainPartNumber", {
                    rules: [{ required: false, message: "Enter Main Part Number" }],
                  })(
                    <Input
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      pattern="^[0-9A-Za-z]*$"
                      placeholder="Enter Main Part Number"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          mainPartNumber: formatValue(e, "toUpperCase"),
                        })
                      }
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            mainPartNumber: {
                              type: "error",
                              message: "Enter valid Part No.",
                            },
                          });
                          setMessage({ mainPartNumber: "Enter Valid No" });
                        } else {
                          delete error.mainPartNumber;
                          setError(error);
                        }
                      }}
                    />
                  )}
                </Item>
              </Col>
              {/* new */}
              <Col span={6}>
                <Item
                  label="Display name"
                  colon={false}
                  validateStatus={error.displayName && error.displayName.type}
                  help={error.displayName && error.displayName.message}
                >
                  {getFieldDecorator("displayName", {
                    rules: [{ required: false, message: "Enter Display Name" }],
                  })(
                    <Input
                      disabled={!editable}
                      type="text"
                      onWheel={(event) => event.currentTarget.blur()}
                      // pattern="^[0-9A-Za-z]*$"
                      placeholder="Enter Display Name"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          displayName: formatValue(e, "firstCaps"),
                        })
                      }
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            displayName: {
                              type: "error",
                              message: "Enter valid Name",
                            },
                          });
                          setMessage({ displayName: "Enter valid Name" });
                        } else {
                          delete error.displayName;
                          setError(error);
                        }
                      }}
                    />
                  )}
                </Item>
              </Col>



            </Row>

            <Row gutter={16}>
              <Col span={6} validateStatus={error.mainPartNumber && error.mainPartNumber.type}
                help={error.mainPartNumber && error.mainPartNumber.message}>
                <Item label="MOQ" colon={false}>
                  {getFieldDecorator("MOQ", {
                    rules: [{ required: false, message: "Enter MOQ" }],
                  })(
                    <Input
                      min="0"
                      pattern="^[0-9A-Za-z]*$"
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Minimum Order Quantity"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          MOQ: formatValue(e, "onlyNo"),
                        })
                      }
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            MOQ: {
                              type: "error",
                              message: "Enter Main Part Number",
                            },
                          });
                        } else {
                          delete error.MOQ;
                          setError(error);
                        }
                      }}
                    />
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="Manufacturer" colon={false} required>
                  {getFieldDecorator("manufacturer", {
                    rules: [{ required: true, message: "Enter Manufacturer" }],
                  })(
                    <Select
                      disabled={!editable}
                      placeholder="Manufacturer"
                      onChange={handleManufacturer}
                      showAction={["click", "focus"]}
                    >
                      {manufacturer.map((item, index) => (
                        <Select.Option key={item.id}>{item.name}</Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item label="Vehicle Model">
                  {getFieldDecorator("vehicleModel", {
                    rules: [{ required: false, message: "Select a Vehicle Model" }],
                  })(
                    <Select
                      mode="multiple"
                      disabled={!editable}
                      placeholder="Vehicle Model"
                      colon={false}
                      showSearch
                      showAction={["click", "focus"]}
                      filterOption={filterType}
                      onDeselect={(e) => {
                        onVehicleChange(e);
                      }}
                    >
                      {models.map((model, index) => (
                        <Select.Option key={model.id}>
                          {model.modelCode} - {model.modelName}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  validateStatus={error.COLOR && error.COLOR.type}
                  help={error.COLOR && error.COLOR.message}
                  label="Color"
                  colon={false}
                >
                  {getFieldDecorator("color", {
                    rules: [{ required: false, message: "Enter Color" }],
                  })(
                    <Input
                      pattern="^[a-zA-Z0-9]+((['][a-zA-Z])?[a-zA-Z]*)*$"
                      // onKeyUp={e =>
                      //           form.setFieldsValue({
                      //                 color:formatValue(e,"allCaps")
                      //           })}
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            COLOR: {
                              type: "error",
                              message: "Enter Valid Color",
                            },
                          });
                        } else {
                          delete error.COLOR;
                          setError(error);
                        }
                      }}
                      disabled={!editable}
                      placeholder="Color"
                    />
                  )}
                </Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Item label="Part Status" colon={false}>
                  {getFieldDecorator("partStatus", {
                    rules: [{ required: false, message: "Selece a Part Status" }],
                  })(
                    <Select showAction={["click", "focus"]} disabled={!editable} placeholder="Part Status">
                      {partsAvailabity.map((model) => (
                        <Select.Option value={model.key} key={model.key}>
                          {model.title}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="HSN" colon={false} required>
                  {getFieldDecorator("HSN", {
                    rules: [{ required: true, message: "Select HSN" }],
                  })(
                    <Select
                      showAction={["click", "focus"]}
                      showSearch
                      filterOption={filterMethod}
                      disabled={!editable}
                      placeholder="HSN"
                    >
                      {HSN.map((code, index) => (
                        <Select.Option
                          onClick={() => handleHSNChange(index)}
                          value={code.id}
                          key={code.id}
                        >
                          {code.code}
                        </Select.Option>
                      ))}
                    </Select>
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item label="CGST" colon={false}>
                  {getFieldDecorator("cgst")(
                    <Input addonAfter="%" disabled placeholder="CGST" />
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item label="SGST" colon={false}>
                  {getFieldDecorator("sgst")(
                    <Input addonAfter="%" disabled placeholder="SGST" />
                  )}
                </Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Item label="IGST" colon={false}>
                  {getFieldDecorator("igst")(
                    <Input addonAfter="%" disabled placeholder="IGST" />
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="CESS" colon={false}>
                  {getFieldDecorator("cess")(
                    <Input
                      disabled
                      type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      addonAfter="%"
                      placeholder="Cess"
                    />
                  )}
                </Item>
              </Col>

              <Col span={6}>
                <Item
                  validateStatus={error.NDP}
                  help={message.NDP}
                  label="NDP"
                  colon={false}>
                  {getFieldDecorator("NDP", {
                    rules: [{ required: false, message: "Enter NDP" }],
                  })(
                    <Input
                      addonBefore=" &#8377;"
                      onChange={(event) => {
                        checkType(
                          event.target.value,
                          "MRP",
                          "MRP",
                          "Should be greater than NDP"
                        )
                      }
                      }
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Net Dealer Price"
                      pattern="[0-9]+(\.[0-9][0-9]?)?"
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            NDP: "error"
                          })
                          setMessage({
                            ...message,
                            NDP: "Enter Valid Price"
                          })
                        } else {
                          delete error.NDP
                          delete message.NDP
                          setError(error)
                          setMessage(message)
                        }
                      }}
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          NDP: formatValue(e, "noWithDot"),
                        })
                      }
                    />
                  )}
                </Item>
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={6}>
                <Item
                  validateStatus={error.MRP}
                  help={message.MRP}
                  label="MRP"
                  colon={false}
                  required
                >
                  {getFieldDecorator("MRP", {
                    rules: [{ required: true, message: "Enter MRP" }],
                  })(
                    <Input
                      min="0"
                      addonBefore=" &#8377;"
                      onChange={(event) =>
                        checkType(
                          event.target.value,
                          "NDP",
                          "MRP",
                          "Should be greater than NDP"
                        )
                      }
                      disabled={!editable}
                      // type="number"
                      onWheel={(event) => event.currentTarget.blur()}
                      placeholder="Maximum Retailer Price"
                      onKeyUp={(e) =>
                        form.setFieldsValue({
                          MRP: formatValue(e, "noWithDot"),
                        })
                      }
                      pattern="[0-9]+(\.[0-9][0-9]?)?"
                      onInput={(event) => {
                        if (!event.target.checkValidity()) {
                          setError({
                            ...error,
                            MRP: "error"
                          })
                          setMessage({
                            ...message,
                            MRP: "Enter Valid Price"
                          })
                        } else {
                          delete error.MRP
                          delete message.MRP
                          setError(error)
                          setMessage(message)
                        }
                      }}
                    />
                  )}
                </Item>
              </Col>
              <Col span={6}>
                <Item label="WEF Date" colon={false}>
                  {getFieldDecorator("WEFDate", {
                    rules: [{ required: false, message: "Enter WEF Date" }],
                  })(
                    <DatePicker
                      allowClear={true}
                      disabled={!editable}
                      format="DD/MM/YYYY"
                      onChange={onChange}
                    />
                  )}
                </Item>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" />
            <Row gutter={16}>
              <Col span={9}>
                <Item name="Show in consumer" >
                  <Checkbox disabled={!editable} checked={showInConsumer} value={showInConsumer} onChange={() => {
                    setShowInConsumer(!showInConsumer)
                  }}>Show in Consumer</Checkbox>
                </Item>
              </Col>
              <Col span={9}>
                <Item name="Show in AutoCloud" >
                  <Checkbox disabled={!editable} checked={showInAutoCloud} value={showInAutoCloud} onChange={() => {
                    setShowInAutoCloud(!showInAutoCloud)
                  }}>Show in AutoCloud</Checkbox>
                </Item>
              </Col>
              <Col span={6} style={{ marginBottom: '20px' }} >
                <Item label={<Text>Upload Image</Text>}>
                  <Upload
                    name="files"

                    action={`${ENDPOINT}api/upload/mocky`}
                    onChange={(e) => handleUpload(e)}
                    accept=".jpg,.png,.jpeg,.gif"
                    onRemove={(data) => handleDelete(data)}
                    fileList={multiFileList}>
                    <Button disabled={!editable || upload}>
                      <Icon type="upload" /> Upload
              </Button>
                  </Upload>
                </Item>
                {/* <Item label={<Text>Upload Image</Text>} colon={false}>
              {getFieldDecorator("dragger", {
                valuepropname: "fileList",
                getvaluefromevent: normFile,
              })(
                <Upload
                  disabled={!editable}
                  listType="picture-card"
                  showUploadList={false}
                  fileList={fileList}
                  name="files"
                  action={`${ENDPOINT}api/upload/mocky`}
                  onChange={(e) => handleUpload(e)}
                  accept=".jpg,.png,.jpeg,.gif"
                >
                  {image ? (
                    <img style={{ maxWidth: "100%" }} src={image} />
                  ) : (
                      <div>
                        <p className="ant-upload-drag-icon">
                          <Icon type={imgLoading ? "loading" : "inbox"} />
                        </p>
                        {imgLoading ? (
                          <p className="ant-upload-text">loading</p>
                        ) : (
                            <p className="ant-upload-hint">
                              Click or drag file to this area to upload
                            </p>
                          )}
                      </div>
                    )}
                </Upload>
              )}
              {data && data.url && data.url.length > 0 && editable ?
                <Popconfirm
                  title="Do you want to delete this parts image?"
                  okText="Yes"
                  cancelText="No"
                  onCancel={(event) => {
                    event.stopPropagation();
                  }}
                  onConfirm={(event) => {
                    event.stopPropagation();
                    form.setFieldsValue({ dragger: null })
                    setRemoveLogo(true);
                    setImage(null);
                  }}
                >
                  <Button type="danger" style={{ marginTop: '.5rem' }}><Icon type="delete" /></Button>
                </Popconfirm>
                : null}
            </Item> */}
              </Col>
            </Row>
            <Row>
              <div style={{ display: 'flex', justifyContent: "flex-end" }}>
                {urls.map((item) => {
                  return <span style={{ margin: '0.5rem' }}>
                    <img src={item} style={{ height: '100px', width: '100px' }} />
                    <br />
                    {editable ? <Popconfirm
                      title="Do you want to delete this parts image?"
                      okText="Yes"
                      cancelText="No"
                      onCancel={(event) => {
                        event.stopPropagation();
                      }}
                      onConfirm={(event) => {
                        event.stopPropagation();
                        handlePartsImageDelete(item)
                      }}
                    >
                      <Button type="danger" style={{ marginTop: '.5rem' }}><Icon type="delete" /></Button>
                    </Popconfirm> : null}
                  </span>
                })}
              </div>
            </Row>
          </Form>
        </TabPane>
        {selectedData && selectedData?.partNo ? <TabPane
          tab={
            <span>Spares Inventroy</span>
          }
          key="2"
        >
          <WrappedSparesInventory
            data={selectedData}
            editable={false}
            modify={false}
          />
        </TabPane> : null}
        {
          HistoryData.length &&
          <TabPane tab={<span>Spares History</span>} key="3">
            <Table
              style={{ marginBottom: "1rem" }}
              rowKey={(record) => record.id}
              columns={HistoryColumn}
              dataSource={HistoryData}
              pagination={false}
            />
          </TabPane>
        }

      </Tabs>

    </Modal>
  );
};

const WrappedPartsModal = Form.create({ name: "parts" })(PartsModal);

export default WrappedPartsModal;
