import React, { useEffect } from "react";
import { Table, Divider, Popconfirm, Typography  } from "antd";
import moment from "moment";

export default function BooingRegisterTable(props) {
  const { pagination, dataSource, openModal, spinner, onDelete, modify, deleteAccess } = props;
  const { Text } = Typography
  const column = [
    {
      title: "Booking ID",
      dataIndex: "bookingId",
      key: "bookingId",
      render: (record) => record && <span>{record}</span>,
    },
    {
      title: "Customer Name",
      dataIndex: "customer",
      key: "customerName",
    render: (record,data) => record ? <span>{record.name}</span> : <span>{data.customerName}</span>,
    },
    {
      title: "Mobile Number",
      dataIndex: "customer",
      key: "contacts",
      render: (record,data) =>
        record ? (
          <span>
            {record.contacts && record.contacts[0] && record.contacts[0].phone}
          </span>
        )
        : (
          <span>
            {data.customerPhone}
          </span>
        ),
    },
    {
      title: "Booked Vehicle",
      dataIndex: "vehicle",
      key: "model",
      render: (record) => record && <span>{record.modelName}</span>,
    },
    {
      title: "Mode of Payment",
      dataIndex: "price",
      key: "paymentMode",
      render: (record) => (
        <span>
          {record &&
            record.paymentMode &&
            record.paymentMode.charAt(0).toUpperCase() +
            record.paymentMode.slice(1)}
        </span>
      ), //record && <span>{record.name}</span>,
    },
    {
      title: "Date of Creation",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (record) => record && <span>{moment(new Date(record)).format('DD/MM/YYYY')}</span>,
    },
    {
      title: "Action",
      render: (data) => (
        <div>
       
          {    
            (data.bookingStatus == "ACCEPTED" && (data.customerAuth==false) || (data.bookingStatus == 'PENDING')) ?
            modify?
            <span
              onClick={(event) => {
                event.stopPropagation();
                openModal(data.id, true);
              }}
              className="linkylink"
            >
              Modify
              <Divider type="vertical" />
            </span> :
            null
            :
            <span></span>
          }
     
          {
            deleteAccess ?
          <Popconfirm
            title="Do you want to delete this Booking Register?"
            okText="Yes"
            cancelText="No"
            onCancel={(event) => {
              event.stopPropagation();
            }}
            onConfirm={(event) => {
              event.stopPropagation();
              onDelete(data.id);
            }}
          >
            <span
              onClick={(event) => event.stopPropagation()}
              className="linkylink"
            >
              Delete
            </span>
          </Popconfirm>
          :
          <span></span>
          }
          {
            !modify && !deleteAccess
              ?
              <Text strong disabled>No Actions Given</Text>
              :
              <span></span>
          }
        </div>
      ),
    },
  ];
  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        pagination={pagination}
        columns={column}
        tableLayout="auto"
        style={{ cursor: "pointer" }}
        dataSource={dataSource}
        onRow={(props) => ({
          onClick: () => openModal(props.id, false),
        })}
        loading={spinner}
      
      />
    </div>
  );
}
