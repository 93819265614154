import React, { useEffect, useState } from "react";
import {
  Form,
  Select,
  Typography,
  Row,
  Col,
  Button,
  Input,
  DatePicker,
  Table,
  Divider,
  Popconfirm,
  message,
} from "antd";
import { platformApi } from "../../../../../api";

const { Item } = Form;
const { Text } = Typography;
const { Option } = Select;

const VehicleData = ({
  form,
  validityRef,
  data,
  clearRef,
  id,
  open,
  editable,
  recvData,
}) => {
  const [customerData, setCustomerData] = useState([]);
  // const [dataSource, setDataSource] = useState([
  //   {
  //     desc: "Description",
  //     price: "Price",
  //   },
  // ]);
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [vehicleList, setVehicleList] = useState([]);
  const [jobCode, setJobCode] = useState([]);

  const [editId, setEditId] = useState(null);

  const column = [
    {
      title: "Complaint",
      dataIndex: "complaint",
      key: "complaint",
      render: (data, record, index) => (
        // <span>{record}</span>;
        <Input
          key="complaint"
          disabled={!editable}
          onChange={(event) => {
            handleDescChange(
              event.target.value || "None",
              index
              // record.code,
              // "price",
              // record.challanId,
              // record.vehicleId
            );
          }}
          value={data}
          placeholder="Description"
        />
      ),
    },
    {
      title: "Job Code",
      dataIndex: "code",
      key: "jcode",
      render: (data, record, index) => (
        <Select
          onChange={(event) => {
            handleComplaintChange(event, index);
          }}
          value={data}
          placeholder="Job Code"
          disabled={!editable}
        >
          {jobCode.map((code) => (
            <Select.Option key={code.id} value={code.id}>
              {code.description}-{code.code}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Description",
      dataIndex: "desc",
      key: "desc",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Action",
      render: (record) =>
        editable ? (
          <div>
            {/* <span
            onClick={(event) => {
              event.stopPropagation();
              openModal(record.id, true);
            }}
            className="linkylink"
          >
            Modify
          </span>
          <Divider type="vertical" /> */}
            <Popconfirm
              title="Do you want to delete this Job Code?"
              okText="Yes"
              cancelText="No"
              onCancel={(event) => {
                event.stopPropagation();
              }}
              onConfirm={(event) => {
                event.stopPropagation();
                deleteData(record);
              }}
            >
              <span
                className="linkylink"
                onClick={(event) => event.stopPropagation()}
              >
                Delete
              </span>
            </Popconfirm>
          </div>
        ) : (
            <span>Delete</span>
          ),
    },
  ];

  const handleDescChange = (e, index) => {
    const data = [...dataSource];
    data[index]["complaint"] = e;
    setDataSource(data);
  };

  const handleComplaintChange = (e, index) => {
    const data = [...dataSource];

    let obj = jobCode[jobCode.findIndex((obj) => obj.id === e)];
    let vehicleId = form.getFieldValue("vehicleNo");
    let cond1 =
      vehicleList[vehicleList.findIndex((obj) => obj.id === vehicleId)].vehicle
        .id;

    let newObj = {
      complaint: obj.description,
      code: obj.code,
      price:
        obj.vehicleModel[
          obj.vehicleModel.findIndex((o) => o.vehicle.id === cond1)
        ].price,
      id: obj.id,
    };


    // data[index]["complaint"] = e;
    data[index]["desc"] = newObj.complaint;
    data[index]["code"] = newObj.id;
    data[index]["price"] = newObj.price;

    setDataSource(data);
    addComplaint();

  };

  const addComplaint = () => {
    // let result = [];

    let newObj = {
      desc: "Description",
      price: "Price",
    };

    dataSource.push(newObj);
    setDataSource([...dataSource]);
    // form.setFieldsValue({ jobCode: "" });
  };

  const deleteData = (data) => {
    let result = dataSource;
    if (result.length === 1) {
      message.error("Cannot delete the only row in the table", 2);
      return;
    }
    result.splice(
      dataSource.findIndex((obj) => obj.code === data.code),
      1
    );
    setDataSource([...result]);
  };

  useEffect(() => {
    addComplaint();

    form.setFieldsValue({
      customer: data.customer,
      vehicleNo: data.vehicle,
      engineNo: data.engineNo,
      chassisNo: data.chassisNo,
      model: data.model,
      vehicleSoldDate: data.vehicleSoldDate,
      color: data.color,
      kms: data.kms,
      jobCode: data.jobCode,
    });
    data.complaints && setDataSource(data.complaints);
    // platformApi.get("api/customer/").then((result) => {
    //   let { data } = result;
    //   if (data.code === 200) {
    //     let { response } = data;
    //     if (response.code === 200) {
    //       if (response.data[0]) {
    //         setCustomerData(response.data);
    //       } else message.error("No user found", 2);
    //     }
    //   }
    // });
    platformApi.get("/api/vehicle/").then(async (result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          await setVehicleList(response.data);
          data.vehicleNo && handleVehicleChange(data.vehicleNo);
        }
      }
    });
    platformApi.get("/api/jobCode/").then((result) => {
      let { data } = result;
      if (data.code === 200) {
        let { response } = data;
        if (response.code === 200) {
          setJobCode(response.data);
        }
      }
    });
  }, [open]);

  useEffect(() => {
    if (recvData.vehicle) {
      platformApi
        .get("/api/vehicle/vehicleNum/" + recvData.vehicle.registerNo)
        .then((result) => {
          setCustomerData([...result.data.response.data[0].customer]);

          form.setFieldsValue({
            // vehicleNo: recvData.vehicle.registerNo,
            vehicleNo: result.data.response.data[0].id,
            customer: recvData.customer.id,
            // customer: recvData.customer.name,
            chassisNo: recvData.vehicle.chassisNo,
            engineNo: recvData.vehicle.engineNo,
            model: recvData.vehicle.vehicle.modelName,
            vehicleSoldDate: new Date(
              recvData.vehicle.dateOfSale
            ).toLocaleDateString(),
            color: recvData.vehicle.color.color,
            kms: recvData.kms,
          });
        });
      setEditId(recvData.vehicle.id);
      let compz = [];
      let newObj = {
        desc: "Description",
        price: "Price",
      };

      recvData.complaint.map((obj) => {
        let newObjz = {
          complaint: obj.complaint,
          code: obj.jobCode.code,
          price:
            obj.jobCode.vehicleModel[
              obj.jobCode.vehicleModel.findIndex(
                (obj) =>
                  obj.vehicle.modelName === recvData.vehicle.vehicle.modelName
              )
            ].price,
          desc: obj.jobCode.description,
        };
        compz.push(newObjz);
      });
      compz.push(newObj);

      setDataSource([...compz]);
    }
  }, [recvData, editable]);

  useEffect(() => {
    clearRef.current = () => {
      form.resetFields();
      setDataSource([]);
    };
    validityRef.current = () => {
      return validatePage();
    };
    return () => {
      validityRef.current = null;
      clearRef.current = null;
    };
  });
  const validatePage = () => {
    let isValid;
    form.validateFields((validationError) => {
      isValid = validationError === null;
    });
    if (isValid) {
      data.customer = getFieldValue("customer");
      data.engineNo = getFieldValue("engineNo");
      data.vehicle = getFieldValue("vehicleNo");
      data.chassisNo = getFieldValue("chassisNo");
      data.model = getFieldValue("model");
      data.vehicleSoldDate = getFieldValue("vehicleSoldDate");
      data.color = getFieldValue("color");
      data.kms = parseInt(getFieldValue("kms"));
      data.jobCode = getFieldValue("jobCode");
      data.complaints = dataSource;
    }
    return isValid;
  };

  const handleVehicleChange = (e) => {
    platformApi.get("/api/vehicle/vehicleNum/" + e).then((result) => {
    });
    let vehicle = vehicleList[vehicleList.findIndex((obj) => obj.id === e)];
    setCustomerData([...vehicle.customer]);
    form.setFieldsValue({
      // customer: vehicle.customer[0].customer.id,
      chassisNo: vehicle.chassisNo,
      engineNo: vehicle.engineNo,
      model: vehicle.vehicle.modelName,
      color: vehicle.color.color,
      vehicleSoldDate: new Date(vehicle.dateOfSale).toLocaleDateString(),
    });
  };

  const { getFieldDecorator, getFieldValue } = form;

  return (
    <Form
      style={{ marginTop: "4%", marginBottom: "4%" }}
    >
      <Row type="flex" justify="space-between">
        <Col span={5}>Job Order No. : {id}</Col>
        <Col span={5}></Col>
        <Col span={5}></Col>
        <Col span={5}>
          Date : {new Date().toLocaleDateString()}
          <br />
          Time : {new Date().toLocaleTimeString()}
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={5}>
          <Form.Item colon={false} label={<Text>Vehicle No.</Text>}>
            {getFieldDecorator("vehicleNo", {
              rules: [
                {
                  required: true,
                  message: "Please Enter Vehicle No",
                },
              ],
            })(
              <Select
                placeholder="Vehicle No."
                onChange={(e) => handleVehicleChange(e)}
                disabled={!editable}
              >
                {vehicleList.map((data) => (
                  <Select.Option key={data.id}>{data.registerNo}</Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item colon={false} label={<Text>Customer</Text>}>
            {
              getFieldDecorator("customer", {
                rules: [
                  {
                    required: true,
                    message: "Please Enter Customer No",
                  },
                ],
              })(
                <Select placeholder="Customer" disabled={!editable}>
                  {customerData.map((data) => (
                    <Select.Option key={data.customer.id}>
                      {data.customer.name}
                      {/* -{
                        data.contacts[
                          data.contacts.findIndex(
                            (obj) => obj.DND === false && obj.valid === true
                          )
                        ].phone
                      } */}
                    </Select.Option>
                  ))}
                </Select>
              )
              // <Input disabled={true} placeholder="Customer"></Input>
            }
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item colon={false} label={<Text>Chassis No</Text>}>
            {getFieldDecorator(
              "chassisNo"
              //  {
              //   rules: [
              //     {
              //       required: false,
              //       message: "Please input your Model!",
              //     },
              //   ],
              // }
            )(<Input disabled={true} placeholder="Chassis No" />)}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item colon={false} label={<Text>Engine No.</Text>}>
            {getFieldDecorator(
              "engineNo"
              // {
              //   rules:
              //   [
              //     {
              //       required: false,
              //       message: "Please input your Engine No.!",
              //     },
              //   ],
              // }
            )(<Input disabled={true} placeholder="Engine No." />)}
          </Form.Item>
        </Col>
      </Row>
      <Row type="flex" justify="space-between">
        <Col span={5}>
          <Form.Item colon={false} label={<Text>Model</Text>}>
            {getFieldDecorator(
              "model"
              //  {
              //   rules: [
              //     {
              //       required: false,
              //       message: "Please input your Model!",
              //     },
              //   ],
              // }
            )(<Input disabled={true} placeholder="Model" />)}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item colon={false} label={<Text>Vehicle Sold Date</Text>}>
            {getFieldDecorator(
              "vehicleSoldDate"
              // {
              //   rules: [
              //     {
              //       required: false,
              //       message: "Please input your Vehicle Sold Date!",
              //     },
              //   ],
              // }
            )(<Input disabled={true} placeholder="Vehicle Sold Date" />)}
          </Form.Item>
        </Col>
        <Col span={5}>
          <Form.Item colon={false} label={<Text>Color</Text>}>
            {getFieldDecorator(
              "color"
              //  {
              //   rules: [Hai
              //     {
              //       required: false,
              //       message: "Please input your Color!",
              //     },
              //   ],
              // }
            )(<Input disabled={true} placeholder="Color" />)}
          </Form.Item>
        </Col>

        <Col span={5}>
          <Form.Item colon={false} label={<Text>KMs</Text>}>
            {getFieldDecorator("kms", {
              rules: [
                {
                  required: true,
                  message: "Please Enter KMS",
                },
              ],
            })(
              <Input
                type="number"
                disabled={!editable}
                placeholder="KMs" />
            )}
          </Form.Item>
        </Col>
      </Row>
      <br />
      <Table
        scroll={{ y: 200 }}
        pagination={false}
        columns={column}
        style={{ cursor: "pointer" }}
        tableLayout="fixed"
        dataSource={dataSource}
      />

      <br />
      {/* <Button
        onClick={() => {
          addComplaint();
        }}
        type="primary"
        disabled={!editable}
      >
        Add Complaint
      </Button> */}
    </Form>
  );
};

const WrappedVehicleData = Form.create()(VehicleData);

export default WrappedVehicleData;
