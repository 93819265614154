import React, { useState, useEffect } from "react";
import moment from "moment";
import minimize from "../../../../../../assets/minimize.svg"
import {
    Input,
    Modal,
    Button,
    Select,
    Form,
    Col,
    Row,
    Icon,
    Table,
    Divider,
    Popconfirm
} from "antd";
import { platformApi } from "../../../../../../api";
const InsuranceForm = (
    {
        editable,
        modifyData,
    }
) => {
    const [dataSource, setDataSource] = useState([])
    const [insurances, setInsurances] = useState([])
    const [fileList, setFileList] = useState([])
    const [insurancePair, setInsurancePair] = useState({})
    const [newData, setNewData] = useState([])



    useEffect(() => {
        platformApi.get("/api/insurance").then((result) => {
            let { data } = result;
            if (data.code === 200) {
                let { response } = data;
                if (response.code === 200) {
                    setInsurances(response.data);
                    for (let item of response.data) {
                        insurancePair[item.id] = item.name
                    }
                    setInsurancePair(insurancePair)
                }
            }
        });
    }, []);


    const columns = [
        {
            title: 'Insurer',
            dataIndex: modifyData && modifyData.insurance ? 'insurance' : 'insurer',
            key: 'insurer',
            render: (record, data) => <span>{record ? insurancePair[record] : data && data.insurance && data.insurance.name}</span>
        },
        {
            title: 'Policy No',
            dataIndex: 'policyNumber',
            key: 'policyNumber',
            render: (record) => <span>{record}</span>
        },
        {
            title: 'Type',
            dataIndex: 'insuranceType',
            key: 'insuranceType',
            render: (record) => <span>{record}</span>
        },
        {
            title: 'Valid From',
            dataIndex: 'validFrom',
            key: 'validFrom',
            render: (record) => <span>{
                moment(record).format(
                    "DD-MM-YYYY"
                )}</span>
        },
        {
            title: 'Valid To',
            dataIndex: 'validTo',
            key: 'validTo',
            render: (record) => <span>{
                moment(record).format(
                    "DD-MM-YYYY"
                )}</span>
        },
        {
            title: 'Actions',
            render: (record) => <span>
                {record && record.file && record.file.url ?
                    <a
                        className="linkylink"
                        target="_blank"
                        href={record.file.url}
                    >
                        View
                    </a>
                    : <span className="linkylink"
                        onClick={() => { showPDF(record.pdf.file.originFileObj) }}
                    >View
                    </span>
                }
                {editable ?
                    <>
                        <Divider type="vertical" />
                        <Popconfirm
                            title="Are you sure remove this Insurance?"
                            okText="Yes"
                            cancelText="No"
                            onCancel={(event) => {
                                event.stopPropagation();
                            }}
                            onConfirm={(event) => {
                                if (record && record.id) {
                                    event.stopPropagation();
                                    setRemovedData([...removedData, record])
                                    removeInsurance(record)
                                }
                                else {
                                    event.stopPropagation();
                                    removeInsurance(record)
                                }
                            }}
                        >
                            <span
                                className="linkylink"
                                onClick={(event) => event.stopPropagation()}
                            >
                                <Icon style={{ marginLeft: '0.5rem' }} component={minimize} />
                            </span>
                        </Popconfirm>
                    </>
                    : null}


            </span>
        },

    ];



    useEffect(() => {
        if (modifyData && modifyData.length > 0) {
            setNewData(modifyData)
        }
    }, [modifyData])

    const getBase64 = (pdf, callback) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => callback(reader.result));
        reader.readAsDataURL(pdf);
    };

    const showPDF = (data) => {
        getBase64(data, (url) => {
            setPDFdata(data)
            let pdfWindow = window.open("")
            pdfWindow.document.write('<title>' + data.name + '</title><iframe type="application/pdf"  frameBorder="0"  width="100%" height="100%" title="' + data.name + '" src="' + url + '"></iframe>')
        })

    }



    const removeInsurance = (data) => {
        if (data.id) {
            let filteredAry = modifyData.filter(function (e) { return e !== data })
            setNewData(filteredAry)
        }
        else {
            formData.delete(dataSource.indexOf(data))
            var filteredAry = dataSource.filter(function (e) { return e !== data })
            setDataSource(filteredAry)
            setData(filteredAry)
            setFormDataList(filteredAry)
        }
    }




    return (
        <>
            <Form>
                <h3>Adding New Insurance Docs:</h3>
                <Row type="flex" justify="space-between">
                    <Col span={24}>
                        <Table dataSource=
                            {
                                modifyData &&
                                    Object.keys(modifyData).length > 0 ? [...newData, ...dataSource] : dataSource
                            }
                            columns={columns}
                        />
                    </Col>
                </Row>
            </Form>
        </>

    )
}


export default InsuranceForm;