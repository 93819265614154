import React, { useState, useEffect, useContext } from "react";
import {
  Typography,
  Input,
  Button,
  Dropdown,
  Menu,
  Icon,
  message,
  Upload,
  Tooltip
} from "antd";
import { withRouter } from "react-router-dom";
import {
  LeftOutlined
} from '@ant-design/icons';
import "./index.less";
import { platformApi } from "../../../api";
import { ContextAPI } from "../../../ContextAPI";

// Components
import HSNTable from "./HSNTable";
import WrappedAccessoriesModal from "./HSNForm";
import { ENDPOINT } from "../../../constants";

const { Title, Text } = Typography;
const { Search } = Input;
const { Item } = Menu;

const HSNCode = (props) => {
  const [file, setfile] = useState(false);
  const [limit, setLimit] = useState(10);
  const [addFlag, setAddFlag] = useState(false);
  const [editFlag, setEditFlag] = useState(false);
  const [selected, setSelected] = useState(null);
  const [dataSource, setData] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [upload, setUpload] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState(null)
  const [createAccess, setCreateAccess] = useState(false);
  const [modifyAccess, setModifyAccess] = useState(false);
  const [deleteAccess, setDeleteAccess] = useState(false);
  const { loginCredintials } = useContext(ContextAPI);
  const getMenu = () => {
    let size = count / 10 + (count % 10 ? 1 : 0);
    const menus = [];
    menus.push(10);
    size = size <= 10 ? size : 10;
    for (let itr = 2; itr <= size; itr++) menus.push(itr * 10);
    return menus.map((menu) => (
      <Item onClick={() => { setLimit(menu), setPage(1) }} key={menu}>
        {menu}
      </Item>
    ));
  };

  const menu = <Menu>{getMenu()}</Menu>;
  useEffect(() => {
    loginCredintials.roleAccess &&
      loginCredintials.roleAccess.map((each) => {
        if (each.subModule === "hsn") {
          if (each.access.create) {
            setCreateAccess(true);
          }
          if (each.access.update) {
            setModifyAccess(true);
          }
          if (each.access.delete) {
            setDeleteAccess(true);
          }
        }
      });
    setPrimaryData();
  }, []);

  useEffect(() => {
    setPrimaryData(search);
  }, [page, limit, search]);

  const setPrimaryData = (string, val) => {
    setSpinning(true);
    platformApi
      .post("/api/hsn/get", { page: val ? val : page, size: limit, searchString: string })
      .then((res) => {
        setSpinning(false);
        const { data } = res;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            setCount(response.data.count);
            setData(response.data.hsn);
          } else {
            message.error("Unable to fetch HSN", 2);
          }
        } else {
          message.error("Unable to fetch HSN", 2);
        }
      })
      .catch((error) => {
        setSpinning(false);
        console.error("Error in HSN Code : ", error);
        message.error("Unable to fetch HSN", 2);
      });
  };

  const deleteData = (id) => {
    let temp = page != 1 && dataSource.length === 1
    // if (page != 1 && dataSource.length === 1) {
    //   setPage(page - 1)
    // }
    setSpinning(true);
    platformApi
      .delete(`/api/hsn/${id}`)
      .then((result) => {
        setSpinning(false);
        const { data } = result;
        if (data.code === 200) {
          const { response } = data;
          if (response.code === 200) {
            if (temp) {
              setPage(1)
              setPrimaryData(null, 1)
            }
            else {
              setPrimaryData();
            }
            message.success("HSN Code deleted Sucessfully", 2);
          } else {
            message.error("Unable to delete HSN Code");
          }
        } else {
          message.error("Unable to delete HSN Code");
        }
      })
      .catch((error) => {
        console.error("Error on HSN Code : ", error);
        setSpinning(false);
        message.error("Unable to delete HSN Code");
      });
  };


  const addData = (hsn) => {
    setSpinning(true);
    if (hsn.id) {
      platformApi
        .put(`/api/hsn/${hsn.id}`, hsn)
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              dataSource.some((obj, index) => {
                if (hsn.id === obj.id) dataSource[index] = response.data;
              });
              setData([]);
              setData(dataSource);
              message.success("HSN Code saved successfully");
            } else {
              message.error("Unable to save HSN Code");
            }
          } else {
            message.error("Unable to save HSN Code");
          }
        })
        .catch((error) => {
          setSpinning(false);
          message.error("Unable to save HSN Code");
          console.error("Error on HSN Code : ", error);
        });
    } else {
      platformApi
        .post("/api/hsn/", hsn)
        .then((res) => {
          setSpinning(false);
          const { data } = res;
          if (data.code === 200) {
            const { response } = data;
            if (response.code === 200) {
              setData([...dataSource, response.data]);
              setCount(count + 1);
              message.success("HSN Code added successfully");
            } else {
              message.error("Unable to add HSN Code");
            }
          } else if (data.code == 500 && data.err.code == 500) {
            message.error("HSN Code already exists", 2);
          } else {
            message.error("Unable to add HSN Code", 2);
          }
        })
        .catch((error) => {
          setSpinning(false);
          console.error("Error on HSN Code : ", error);
          message.error("Unable to add HSN Code");
        });
    }
  };


  const uploadCSV = (event) => {
    if (event.file.status === "uploading") {
      setfile(true);
    }
    else if (event.file.status === "done") {
      setSpinning(true);
      setUpload(true);
      setfile(false);
      const formData = new FormData();
      formData.append("HSN", event.file.originFileObj);
      platformApi
        .post("/api/hsn/upload", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          setUpload(false);
          setSpinning(false);
          if (res.data.code === 200) {
            message.success("CSV Data Uploaded");
            window.location.reload();
            setPrimaryData(null)
          } else {
            message.error("CSV data not Uploaded");
          }
        });
    }
  };
  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isJpgOrPng) {
      message.error("You can only upload xlsx file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 10;
    if (!isLt2M) {
      message.error("Image must smaller than 10MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  return (
    <div>
      <div className="accessories-head">
        <Title style={{ width: "60%" }} level={4}>
          <Tooltip placement="topLeft" title={"Back to Company Master"}>
            <Button
              style={{ margin: "0 20px" }}
              onClick={() => props.history.push("/autoadmin/company")}
            >
              <LeftOutlined />
            </Button>
          </Tooltip>
          HSN Code [{count}]
          <span style={{ marginLeft: "1rem" }}>
            <Dropdown overlay={menu} trigger={["click"]}>
              <Button>
                {limit}
                &nbsp;
                <Icon type="down" />
              </Button>
            </Dropdown>
          </span>
        </Title>
        <Search
          style={{ width: "30%" }}
          placeholder="Search Codes"
          onSearch={(event) => {
            setPage(1)
            setSearch(event);
          }}
          onChange={(event) => {
            if (event.target.value === "") {
              setSearch(null)
              setPage(1)
            }
          }}
        />
        <Button
          onClick={() => {
            setEditFlag(true);
            setAddFlag(true);
          }}
          style={{ margin: "0 3%", width: "20%" }}
          type="primary"
          disabled={!createAccess}
        >
          Add Codes
        </Button>
      </div>
      <div style={{ margin: ".5rem" }}>
        {/* <Dropdown overlay={menu} trigger={['click']}>
          <Button>
            {limit}
            {' '}
            &nbsp;
            <Icon type="down" />
          </Button>
        </Dropdown> */}
        <div style={{ marginLeft: "80%" }}>
          <Upload
            onChange={(event) => {
              setfile(true);
              uploadCSV(event)
            }}
            action={`${ENDPOINT}api/upload/mocky`}
            beforeUpload={(e) => beforeUpload(e)}
            accept=".xlsx"
          >
            <Button type="primary" disabled={upload} disabled={!createAccess}>
              <Icon type="upload" />
              <Text style={{ color: "white" }}>Upload CSV Data</Text>
            </Button>
          </Upload>
        </div>
      </div>
      <HSNTable
        pagination={{
          onChange: (page) => {
            setPage(page);
          },
          pageSize: limit,
          defaultCurrent: 1,
          total: count,
          current: page,
          showQuickJumper: true,
        }}
        openModal={(id, editable) => {
          setSelected(id);
          setEditFlag(editable);
          setAddFlag(true);
        }}
        dataSource={dataSource}
        delete={(id) => deleteData(id)}
        spinner={{ spinning }}
        modify={modifyAccess}
        deleteAccess={deleteAccess}
      />
      <WrappedAccessoriesModal
        data={dataSource[dataSource.findIndex((obj) => obj.id === selected)]}
        emitData={(data) => addData(data, selected)}
        open={addFlag}
        editable={editFlag}
        close={() => {
          setSelected(null);
          setAddFlag(false);
        }}
        modify={modifyAccess}
      />
    </div>
  );
}
// diff=max-min/100;
// index*diff+min;
export default withRouter(HSNCode)